import { stylesheet } from "typestyle";

const styles = stylesheet({
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },

  icon: {
    width: "30px",
    cursor: "pointer",
  },
  radioContainer: {
    marginRight: "3em",
    padding: "0.5em 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  wrapper: {
    padding: "0em 2em",
  },
  mcontainer: {
    $nest: {
      "div > div": {
        alignItems: "center !important",
      },
    },
  },
});

export default styles;
