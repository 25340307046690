import React from "react";
import { useTranslation } from "react-i18next";
import Item from "./Item";
import { Action } from "@innomius/ravent-typescript-types";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { List } from "../List";

interface StateProps {
  data: Action[];
  event?: boolean;
  onAddState: (data: Action) => void;
  loadingActions: string;
  onDelete: (id: string) => void;
  update: (item: Action) => void;
  onDeleteAction: (id: string) => void;
  loading: string;
}

const OrderActionList: React.FC<StateProps> = ({
  data,
  event,
  loadingActions,
  update,
  onDelete,
  onAddState,
  onDeleteAction,
  loading,
}) => {
  const { t } = useTranslation();

  const header = (
    <div>
      <ListHeader template="1fr 2fr 1fr">
        <ListHeaderLabel
          justifyContent="flex-start"
          value={
            event
              ? t("component.orderStateList.eventName")
              : t("component.orderlineStateList.name")
          }
        />

        <ListHeaderLabel
          value={t("component.orderStateList.description")}
          textAlign="center"
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={""}
          textAlign="center"
          justifyContent="flex-start"
        />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <List
        data={data}
        keyExtractor={(item) => item.name}
        renderHeader={() => header}
        renderItem={(item) => (
          <Item
            loadingActions={loadingActions}
            onDeleteAction={onDeleteAction}
            onAdd={onAddState}
            loading={loading}
            index={data.indexOf(item)}
            onEdit={(item) => update(item)}
            onDelete={() => onDelete(item.id)}
            data={item}
            key={item.name + Math.random()}
          />
        )}
      />
    </div>
  );
};

export default OrderActionList;
