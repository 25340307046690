import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  orderListFullText,
  TextSearchListEvent,
  OrderListParamsFT,
} from "../services/orders";

type TextSearchSlice = {
  loading: boolean;
  error: string;
  orders: TextSearchListEvent;
};

const initialState: TextSearchSlice = {
  loading: true,
  error: "",
  orders: {
    page: 0,
    count: 0,
    hits: [],
    facets: [],
  },
};

export const textSearchList = createAsyncThunk(
  "textSearch/list",
  async (params: OrderListParamsFT) => {
    const response = await orderListFullText(params);
    return response;
  }
);

const textSearchSlice = createSlice({
  name: "textSearch",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(textSearchList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(textSearchList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.orders = action.payload;
    });
    builder.addCase(textSearchList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An unexpected error ocurred";
    });
  },
});

export default textSearchSlice.reducer;
