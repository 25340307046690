import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { Action, Location } from "@innomius/ravent-typescript-types";
import { Card } from "../Card/Card";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import acceptIcon from "../../assets/officialMedia/default-checkmark.svg";
import quitIcon from "../../assets/quit.svg";

import styles from "./styles";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { ListHeader } from "../List/ListHeader";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { List } from "../List";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";
import { InputText } from "../Inputs/InputText";
import { InputSwitch } from "../Inputs/InputSwitch";
import { InputSelect } from "../Inputs/InputSelect";

interface StateProps {
  data: Location[];
  actions: Action[];
  onEdit: (
    id: string,
    checkinFlowUrl: string,
    checkinPeriod: string,
    publicCheckin: boolean
  ) => void;
}

const CheckinListInt: React.FC<StateProps> = ({ data, onEdit, actions }) => {
  const { t } = useTranslation();

  const [edit, setEdit] = useState("");
  const [checkinFlowUrl, setFlow] = useState("");
  const [checkinPeriod, setPeriod] = useState("");
  const [publicCheckin, setPublic] = useState(false);

  const header = (
    <div>
      <ListHeader template="1fr 2fr 1fr 1fr 1fr">
        <ListHeaderLabel
          justifyContent="flex-start"
          value={t("component.checkinListInt.locationName")}
        />
        <ListHeaderLabel
          value={t("component.checkinListInt.checkinFlowUrl")}
          textAlign="center"
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.checkinListInt.checkinPeriod")}
          textAlign="center"
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.checkinListInt.public")}
          textAlign="center"
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={""}
          textAlign="center"
          justifyContent="flex-start"
        />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <Card containerClassname={styles.wrapperCard}>
        <FlexContainer padding="0 0 2em 0" justifyContent="space-between">
          <ContainerTitle
            size="small"
            label={t("component.checkinListInt.checkin")}
          />
        </FlexContainer>
        <List
          data={data}
          keyExtractor={(item) => item.name}
          renderHeader={() => header}
          renderItem={(item) => (
            <ListRow template="1fr 2fr 1fr 1fr 1fr" hover={false} key={item.id}>
              {edit === item.id ? (
                <>
                  <ListLabel value={item.name || "-"} textAlign="left" />
                  <div className={styles.spaceBetween}>
                    <InputSelect
                      showError={false}
                      showLabel={false}
                      containerClassname={styles.width}
                      value={checkinFlowUrl}
                      options={actions.map((item) => ({
                        value: item.url,
                        label: item.name,
                      }))}
                      onChange={(value) => setFlow(value)}
                    />
                  </div>
                  <div className={styles.spaceBetween}>
                    <InputText
                      showError={false}
                      showLabel={false}
                      value={checkinPeriod}
                      onChange={(value) => setPeriod(value)}
                    />
                  </div>
                  <div className={styles.spaceBetween}>
                    <InputSwitch
                      showError={false}
                      showLabel={false}
                      value={publicCheckin}
                      onChange={(value) => setPublic(value)}
                    />
                  </div>

                  <div className={styles.iconContainerEdit}>
                    <img
                      src={acceptIcon}
                      alt="accept"
                      className={styles.iconShare}
                      onClick={() => {
                        onEdit(
                          item.id,
                          checkinFlowUrl,
                          checkinPeriod,
                          publicCheckin
                        );
                      }}
                    />
                    <img
                      src={quitIcon}
                      alt="quit"
                      className={styles.iconQuit}
                      onClick={() => {
                        setEdit("");
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <ListLabel value={item.name || "-"} textAlign="left" />
                  <ListLabel
                    value={item.checkinFlowUrl || "-"}
                    textAlign="left"
                  />
                  <ListLabel
                    value={item.checkinPeriodinMinutes || "-"}
                    textAlign="left"
                  />
                  <ListLabel
                    value={item.publicCheckin ? "Public" : "Private"}
                    textAlign="left"
                  />
                  <div className={styles.iconContainer}>
                    <EditIcon
                      className={styles.icon}
                      onClick={() => {
                        setFlow(item.checkinFlowUrl);
                        setPeriod(item.checkinPeriodinMinutes);
                        setPublic(item.publicCheckin);
                        setEdit(item.id);
                      }}
                    />
                  </div>
                </>
              )}
            </ListRow>
          )}
        />
      </Card>
    </div>
  );
};

export default CheckinListInt;
