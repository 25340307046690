import { FlexContainer } from "../FlexContainer/FlexContainer";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Text from "../Text";
import styles from "./styles.module.css";

interface Props {
  onLogout: () => void;
  open: boolean;
}

const UserMenu: React.FC<Props> = (props: Props) => {
  const { onLogout, open } = props;
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div
      className={styles.usermenu}
      style={{ display: open ? "block" : "none" }}
    >
      <FlexContainer
        alignItems="flex-start"
        flexDirection="column"
        justifyContent="flex-start"
        padding="0 0 0.5em 0"
      >
        <div className={styles.usermenuButton}>
          <Text
            fontSize="1.1em"
            fontWeight={400}
            text={t("component.userMenu.profile")}
            padding="1em"
            hover
            onClick={() => history.push("/profile")}
          />
        </div>
        <div className={styles.usermenuButton}>
          <Text
            fontSize="1.1em"
            fontWeight={400}
            text={t("component.userMenu.companyProfile")}
            padding="1em"
            hover
            onClick={() => history.push("/organization")}
          />
        </div>

        <div className={styles.usermenuButton}>
          <Text
            fontSize="1.1em"
            fontWeight={400}
            text={t("component.userMenu.billing")}
            padding="1em"
            hover
            onClick={() => history.push("/organization/billing")}
          />
        </div>

        <button onClick={onLogout} className={styles.usermenuButton}>
          <Text
            fontSize="1.1em"
            fontWeight={400}
            text={t("component.userMenu.logout")}
            hover
            padding="1em"
          />
        </button>
      </FlexContainer>
    </div>
  );
};

export default UserMenu;
