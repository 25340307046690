import React, { useCallback, useEffect, useState } from "react";
import { formatDate } from "../../../utils/times";
import styles from "./styles";
import checkClearBlue from "../../../assets/check-clearBlue.svg";
import checkBlue from "../../../assets/checkBlue.svg";
import checkGray from "../../../assets/checkGray.svg";
import MapLoader from "../../MapInput/MapLoader";
import view from "../../../assets/media.svg";
import map from "../../../assets/map.svg";

import FileModal from "./FileModal";
import { ListRow } from "../../List/ListRow";
import { ListLabel } from "../../List/ListLabel";
import { defaultW3W, w3wToCoordinates } from "../../../services/maps";

interface HistoryProps {
  timestamp: Date | null | string;
  status: string;
  w3w: string;
  user: string;
  fulfillmentName: string;
  current?: boolean;
  numberOfFiles: number;
  id: string;
}

const HistoryItem: React.FC<HistoryProps> = ({
  timestamp,
  status,
  user,
  w3w,
  fulfillmentName,
  current,
  numberOfFiles,
  id,
}) => {
  const [locationW3W, setLocationW3W] = useState(defaultW3W);
  const [open, setOpen] = useState("");
  const [file, setFile] = useState("");
  const getcoordinates = useCallback(async () => {
    if (w3w) {
      try {
        const res = await w3wToCoordinates(w3w);
        setLocationW3W(res);
        return;
      } catch (err) {
        console.error(err);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [w3w]);

  useEffect(() => {
    if (open === id) {
      getcoordinates();
    }
  }, [getcoordinates, open, id]);

  return (
    <>
      {file === id ? (
        <FileModal id={id} open={file} onClose={() => setFile("")} />
      ) : null}
      <div>
        <ListRow
          showDetail={open === id}
          template="0.05fr 0.6fr 1fr 1fr 1fr 1fr 0.3fr 0.3fr"
          key={formatDate(timestamp, "UTC")}
          detail={
            <MapLoader height="250px" value={locationW3W.coordinates} onChange={() => {}} />
          }
        >
          <div className={styles.center}>
            <img
              src={current ? checkBlue : timestamp ? checkClearBlue : checkGray}
              alt="check"
            />
          </div>
          <ListLabel value={status} fontFormat="bold" textAlign="left" />
          <ListLabel value={formatDate(timestamp, "MIDDLE")} textAlign="left" />
          <ListLabel value={`///${w3w}`} textAlign="left" />
          <ListLabel value={user} textAlign="left" />
          <ListLabel value={fulfillmentName} textAlign="left" />
          <div
            className={styles.center}
            onClick={(e) => {
              e.stopPropagation();
              setFile(id);
            }}
          >
            {numberOfFiles}

              <img src={view} alt="" className={styles.icon} />
          </div>
          <div
            onClick={() => {
              if(open === id) {
                setOpen("");
              }
              else {setOpen(id);}
            }}
            className={styles.center}
          >
            <img src={map} alt="" className={styles.icon} />
          </div>
        </ListRow>
      </div>
    </>
  );
};

export default HistoryItem;
