const translation = {
  en: {
    cardBrand: "Card Brand",
    last4Digits: "Last 4 Digits",
    customer: "Customer",
    employee: "Employee",
    venue: "Venue",
    cancel: "Cancel",
    delete: "Delete",
    deleteMessage: "Are you sure you want to Delete this payment? ",
    paymentId: "Payment ID",
    paymentType: "Payment Type",
    supplier: "Supplier",
    paymentDate: "Payment Date",
    amount: "Amount",
    close: "Close",
    requestId: "Request ID",
    customerReference: "Customer Reference",
    bankName: "Bank Name",
    bankAccount: "Bank Account",
    paymentDetails: "Payment Details",
    paymentData: "Payment Data",
  },
  es: {
    cardBrand: "Marca de la tarjeta",
    last4Digits: "Últimos 4 dígitos",
    customer: "Cliente",
    employee: "Empleado",
    venue: "Recinto",
    cancel: "Cancelar",
    delete: "Eliminar",
    deleteMessage: "¿Está seguro de que desea eliminar este pago?",
    paymentId: "ID de pago",
    paymentType: "Tipo de pago",
    supplier: "Proveedor",
    paymentDate: "Fecha de pago",
    amount: "Cantidad",
    close: "Cerrar",
    requestId: "ID de solicitud",
    customerReference: "Referencia del cliente",
    bankName: "Nombre del banco",
    bankAccount: "Cuenta Bancaria",
  },
  de: {},
};

export default translation;
