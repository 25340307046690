
import React, { useState } from "react";
import "react-phone-input-2/lib/style.css";
import download from "../../assets/download.svg";
import Text from "../Text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import { bluishGrey } from "../../utils/colors";
import { useTranslation } from "react-i18next";
import styles from "./styles";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Modal } from "../Modal/Modal";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
import InputRadio from "../Inputs/InputRadio";
import { InputText } from "../Inputs/InputText";

interface Props {
  onCancel: () => void;
  open: boolean;
  title: string;
  id: string;
  onSend: (medium: string, contact: string, lang: string) => void;
  contactEmail?: string;
  onDownload?: (lang: string) => void;
  loading?: boolean;
  loadingDownload?: boolean;
  onOpenPdf: (lang: string) => void;
}

const SendMail: React.FC<Props> = ({
  onCancel,
  onSend,
  open,
  contactEmail,
  title,
  onDownload,
  loading,
  loadingDownload,
  onOpenPdf
}) => {
  const [email, setEmail] = useState(contactEmail ? contactEmail : "");
  const [lang, setLang] = useState(localStorage.getItem("language") || "en");

  const validPhone = (inputtxt: string) => {
    const numbers = /^[-+.0-9]{8,}$/;
    if (inputtxt.match(numbers)) {
      return true;
    } else {
      return false;
    }
  };

  const { t } = useTranslation();

  return (
    <Modal
      width="650px"
      buttons={
        <FlexContainer alignItems="center" justifyContent="flex-end">
          <ButtonsContainer alignItems="flex-end">
            <Button
              onClick={onCancel}
              theme="white"
              label={t("component.sendModal.cancel")}
            />
            <Button
              onClick={() => {
                if (loading) {
                  return;
                }
                onSend("email", email, lang);
              }}
              theme="blue"
              label={loading ? "..." : `${t("component.sendModal.send")}`}
              disable={!validPhone}
            />
          </ButtonsContainer>
        </FlexContainer>
      }
      open={open}
      onClose={onCancel}
    >
      <div className={styles.container}>
        <FlexContainer padding="0 0 1em 0" justifyContent="space-between">
          <Text text={`Share ${title}`} fontSize="1.9em" fontWeight={700} />
          {loadingDownload ? (
            <FontAwesomeIcon
              color={bluishGrey}
              icon={faSpinner as IconProp}
              width={"2em"}
              spin
            />
          ) : (
            <ButtonsContainer>
              <Button
                onClick={() => onOpenPdf?.(lang)}
                label={t("component.sendModal.open")}
                theme="white"
              />
              <Button
                icon={download}
                onClick={() => onDownload?.(lang)}
                label={t("component.sendModal.download")}
                theme="white"
              />
            </ButtonsContainer>
          )}
        </FlexContainer>

        <InputLabel label={t("component.sendModal.language")} />
        <FlexContainer padding="0.5em 0 1.5em 0" justifyContent="flex-start">
          <div className={styles.radioContainer}>
            <InputRadio
              name="language"
              checked={lang === "en"}
              value="en"
              text={t("component.sendModal.en")}
              onChange={() => setLang("en")}
            />
          </div>
          <div className={styles.radioContainer}>
            <InputRadio
              name="language"
              value="es"
              checked={lang === "es"}
              text={t("component.sendModal.es")}
              onChange={() => setLang("es")}
            />
          </div>
        </FlexContainer>

        <InputText
          containerClassname={styles.email}
          placeholder="Email"
          label={t("component.sendModal.email")}
          value={email}
          onChange={(value) => setEmail(value)}
        />
      </div>
    </Modal>
  );
};

export default SendMail;
