const translation = {
  en: {
    back: "Back to Pagos",
    cancel: "Cancel",
    save: "Save",
    edit: "Edit",
    globalPreferences: "User Preferences",
  },
  es: {
    back: "Regresar a Pagos",
    cancel: "Cancelar",
    save: "Guardar",
    edit: "Editar",
    globalPreferences: "Preferencias de Usuario",
  },
  de: {
    back: "Back to Pagos",
    cancel: "Cancel",
    save: "Save",
    edit: "Edit",
    globalPreferences: "Global Preferences",
  },
};

export default translation;
