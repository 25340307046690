import React from "react";
import Text from "../Text";
import { capitalize } from "lodash";
import { useTranslation } from "react-i18next";
import { FlexContainer } from "../FlexContainer/FlexContainer";

interface Props {
  type: string;
}

const FulfilmentType: React.FC<Props> = ({ type }) => {
  const { t } = useTranslation();

  const typeDescriptionMap: Record<string, string> = {
    service: t("component.fulfillmentDetail.service"),
    delivery: t("component.fulfillmentDetail.delivery"),
    locker: t("component.fulfillmentDetail.locker"),
    digital: t("component.fulfillmentDetail.digital"),
    "pick-up": t("component.fulfillmentDetail.pick-up"),
    access: t("component.fulfillmentDetail.access"),
    transportation: t("component.fulfillmentDetail.transportation"),
    hospitalty: t("component.fulfillmentDetail.hospitality"),
  };

  return (
    <FlexContainer
      justifyContent="center"
      alignItems="flex-start"
      flexDirection="column"
    >
      <Text text={capitalize(type)} fontSize="1.1em" fontWeight={700} />
      <Text text={typeDescriptionMap[type]} fontSize="1.1em" fontWeight={400} />
    </FlexContainer>
  );
};

export default FulfilmentType;
