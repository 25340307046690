const translation = {
  en: {
    periodicity: "Peiodicity",
    checkedInPeriod: "Checked-in period",
    description: "Description",
    diveShop: "Dive Shop",
    uploadImage: "Upload Image",
    availability: "Availability",
    freeSell: "Free Sell",
    confirmationDiveShop: "Get Confirmation from Dive Shop",
    ticketing: "Ticketing",
    customerName: "Customer name for ticket is mandatory",
    locationOrVenueRelated: "Location or venue related to this offer",
    availableLanguages: "Available languages for this offer",
    addLanguage: "Add a Language",
    cancel: "Cancel",
    add: "Add",
    selectLanguage: "Select language",
    search: "Search",
    tagsForThisOffer: "Tags for this Offer",
    addLabelEnter: "Add label and press enter",
    details: "Details",
    editButtonLabel: "Edit",
    labelDescription: "Description",
    languages: "Available Languages",
    ticketingTitle: "Ticketing",
    tags: "Tags",
    back: "Back",
    continue: "Continue",
    save: "Save",
    enableTickets: "Enable Tickets for this Offer",
    enableTicketInstructions: "Tickets enabled for this Offer",
    checkinPeriod: "Checkin-in period (min)",
    lifeBased: "Life Based",
    lifeBasedDescription: "(How many times the ticket can be used)",
    totalLives: "Total Lives",
    timeBased: "Time Based",
    lifeTime: "Life Time",
    minutes: "minutes",
    activationRequired: "Activation Required",
    properties: "Properties",
    addPropertyLabel: "+ Add Property",
    propertyName: "Property Key",
    propertyValue: "Property Value",
    valueType: "Value Type",
    addNewModifier: "Add New Modifier",
    modifierName: "Modifier Name",
    selectionTypeLabel: "Selection Type",
    minSelection: "Min Selection",
    maxSelection: "Max Selection",
    addValue: "+ Add Value",
    defaultValue: "Default Value",
    valueName: "Value Name",
    amount: "Amount",
    modifiers: "Modifiers",
    location: "Location",
    valueNamePlaceHolder: "Name",
    placeHolderOperation: "+",
    editModifier: "Edit Modifier",
    selectVenue: "Select Venue",
    selectLocation: "Select Location",
    startingPrice: "Starting Price",
    addFrequencyList: "Add Frequency List",
    editFrequencyList: "Edit Frequency List",
    listName: "List Name",
    startDate: "Start Date",
    endDate: "End Date",
    to: "to",
    yearLong: "Year long",
    addHour: "Add Hour",
    monday: "MON",
    tuesday: "TUE",
    wednesday: "WED",
    thursday: "THU",
    friday: "FRI",
    saturday: "SAT",
    sunday: "SUN",
    delete: "Delete",
    edit: "Edit",
    schedule: "Weekly Schedule",
    activation: "Activation",
    validFrom: "Valid From",
    validUntil: "Valid Until",
    maxEvents: "Max Usage Events",
    unit: "Unit",
    value: "Value",
    propertyDescription:
    "The properties may be set from this interface assuming that a hard coded value is needed for all orders containing this offer. If your offering system generates a dynamic value and you would like to overwrite it for each order, please set a value here and make sure your api sends the externally generated value",
  },
  es: {},
  de: {},
};

export default translation;
