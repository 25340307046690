import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import deleteIcon from "../../assets/officialMedia/default-delete.svg";
import DeletePopUp from "../DeletePopUp";
import { ReactComponent as LogIcon } from "../../assets/log.svg";
import { Popover, Tooltip, message } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./styles";
import ActionForm from "./ActionForm";
import { ReactComponent as CalIcon } from "../../assets/cal.svg";
import { ReactComponent as RunIcon } from "../../assets/run-script.svg";
import { ReactComponent as RunIconDisable } from "../../assets/run-script-disable.svg";
import { Action, ScheduleLog } from "@innomius/ravent-typescript-types";
import { useHistory, useLocation } from "react-router-dom";
import { listSchedulesLogs } from "../../services/schedules";
import APIError from "../../utils/APIError";
import Text from "../Text";
import { runAction } from "../../services/actions";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";

interface Props {
  data: Action;
  index: number;
  onDelete: (id: number) => void;
  loading: string;
  onAdd: (data: Action) => void;
  onDeleteAction: (id: string) => void;
  onEdit: (data: Action) => void;
  loadingActions: string;
}

const Item: React.FC<Props> = ({
  data,
  onDelete,
  onEdit,
  index,
  loading,
  onDeleteAction,
}) => {
  const [deleteOrderState, setDelete] = useState("");
  const [logs, setLogs] = useState<ScheduleLog[]>([]);
  const [open, setOpen] = useState("");
  const [newLoading, setNewLoading] = useState(false);

  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();

  const loadLogs = useCallback(async () => {
    if (
      location.pathname === `/integrations/actions/${data.id}` ||
      location.pathname === `/integrations/actions/${data.id}/logs`
    )
      try {
        setNewLoading(true);
        const res = await listSchedulesLogs({ actionId: data.id });
        setLogs(res.data);
        setNewLoading(false);
      } catch (err) {
        setNewLoading(false);

        if (err instanceof APIError) {
          message.error(`${err.message}: ${err.messages}`);
        }
      }
  }, [data.id, location.pathname]);

  useEffect(() => {
    loadLogs();
  }, [loadLogs]);

  const content = (
    <div style={{ padding: "0.5em" }}>
      <Text text={data.url} />
    </div>
  );

  return (
    <>
      <ActionForm
        data={data}
        logs={logs}
        newLoading={newLoading}
        onSaveAction={(data) => onEdit(data)}
        onDeleteAction={onDeleteAction}
      />
      <ListRow template="1fr 2fr 1fr" hover={false} key={data.name}>
        <Popover
          placement="right"
          content={content}
          title={t("component.orderlineStateList.flowUrl")}
        >
          <div className={styles.center}>{data.name || "-"}</div>
        </Popover>

        <ListLabel value={data.description || "-"} textAlign="left" />

        <div className={styles.iconContainer}>
          {data.orderOnly ? (
            <RunIconDisable className={styles.icon} />
          ) : (
            <Tooltip title="Run Script">
              <Popover
                placement="left"
                content={
                  <div onClick={() => setDelete("")}>
                    <DeletePopUp
                      loading={loading === "run"}
                      value={`${t("component.orderActionList.run")}?`}
                      onClose={(e) => {
                        e.stopPropagation();
                        setOpen("");
                      }}
                      onDelete={async (e) => {
                        e.stopPropagation();
                        try {
                          await runAction({ actionId: data.id });
                          message.success("Action was run successfully");
                          setOpen("");
                        } catch (err) {
                          if (err instanceof APIError)
                            message.error(err.messages);
                        }
                      }}
                    />
                  </div>
                }
                trigger="click"
                open={open === "run"}
                onOpenChange={() => setDelete(deleteOrderState)}
              >
                <RunIcon
                  className={styles.icon}
                  onClick={() => setOpen("run")}
                />
              </Popover>
            </Tooltip>
          )}

          <LogIcon
            className={styles.icon3}
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/integrations/actions/${data.id}/logs`);
            }}
          />
          <CalIcon
            className={styles.icon}
            onClick={() => {
              history.push(`/integrations/schedules/new`, {
                actionId: data.id,
                name: data.name,
              });
            }}
          />
          <EditIcon
            className={styles.icon}
            onClick={() => history.push(`/integrations/actions/${data.id}`)}
          />
          <Popover
            placement="left"
            content={
              <div onClick={() => setDelete("")}>
                <DeletePopUp
                  loading={loading === "delete"}
                  value={`${t("component.orderlineStateList.deleteMessage")} ${
                    data.name
                  } ?`}
                  onClose={(e) => {
                    e.stopPropagation();
                    setDelete("");
                  }}
                  onDelete={(e) => {
                    e.stopPropagation();
                    onDelete(index);
                  }}
                />
              </div>
            }
            trigger="click"
            open={deleteOrderState === "delete"}
            onOpenChange={() => setDelete(deleteOrderState)}
          />
          <img
            src={deleteIcon}
            alt="userDelete"
            onClick={(e) => {
              e.stopPropagation();
              setDelete("delete");
            }}
          />
        </div>
      </ListRow>
    </>
  );
};

export default Item;
