export const languagesOptions = [
  { label: "English", value: "en" },
  { label: "Spanish", value: "es" },
  { label: "German", value: "de-de" },
];

export const numberFormatingOptions = [
  { label: "Comma  (1,234.45)", value: "en-US" },
  { label: "Period  (1.234,45)", value: "de-DE" },
];

export const dateFormatingOptions = [
  { label: "mm.dd.yyyy (month, day, year)", value: "mm.dd.yyyy" },
  { label: "dd.mm.yyyy (day, month, year)", value: "dd.mm.yyyy" },
  { label: "yyyy.mm.dd (year, month, day)", value: "yyyy.mm.dd" },
];
