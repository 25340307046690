import React from 'react';
import { ReactComponent as Empty } from '../../assets/no-results.svg';
import styles from './styles.module.css';
import { Button } from '../Button';

export interface Props {
  containerClassname?: string;
  onClick?: () => void;
  goBackButton: boolean;
}

export const ErrorNotFound: React.FC<Props> = ({ containerClassname, onClick, goBackButton }) => {
  return (
    <div className={containerClassname}>
      <div className={styles.notFoundContainer}>
        <Empty className={styles.noResultsIcon} />
        <h1 className={styles.title}>Oops!</h1>
        <p>We can&apos;t find the page you&apos;re looking for</p>
        <p className={styles.medium}>Error code: 404</p>
        {goBackButton ? <Button label="Go Back" onClick={() => onClick?.()} /> : null}
      </div>
    </div>
  );
};
