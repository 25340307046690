import React, { useState } from "react";
import AssetItem from "./AssetItem";
import { OrderListSkeleton } from "../ListSkeletons";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { PaginationRavent } from "../Pagination/Pagination";
import { Asset } from "../../utils/types";
import Checkbox, { CheckboxChangeEvent } from "antd/es/checkbox";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { ListHeader } from "../List/ListHeader";
import { List } from "../List";

interface EntityProps {
  error: string;
  loading: boolean;
  data: Asset[];
  page: number;
  total: number;
  hitsPerPage: number;
  onPageChange?: (page: number) => void;
  onHitsPerPageChange?: (hitsPerPage: number) => void;
  onChangeSortOrder?: (value: string) => void;
  sortable: boolean;
  pagination?: boolean;
  onDetails: (id: string) => void;
  path: string;
  selectedLocations: { id: string; name: string }[];
  setSelectedLocations: (value: any) => void;
  onDelete: (id: string, replace: string) => void;
}

const AssetList: React.FC<EntityProps> = ({
  error,
  loading,
  data,
  pagination,
  page,
  total,
  hitsPerPage,
  onHitsPerPageChange,
  onPageChange,
  onDetails,
  path,
  onDelete,
  selectedLocations,
  setSelectedLocations,
  onChangeSortOrder,
  sortable,
}) => {
  const [order, setOrder] = useState<string>("asc");
  const [sort, setSort] = useState<string | null>(null);
  const { t } = useTranslation();

  const [selectAll, setSelectAll] = useState(false);
  const handleCheckboxChange = (locationId: string, locationName: string) => {
    const isSelected = selectedLocations.some((loc) => loc.id === locationId);

    if (selectAll) {
      setSelectedLocations((prevSelected: any[]) => {
        if (isSelected) {
          return prevSelected.filter((loc) => loc.id !== locationId);
        } else {
          return [...prevSelected, { id: locationId, name: locationName }];
        }
      });
    } else {
      setSelectedLocations((prevSelected: any[]) => {
        if (isSelected) {
          return prevSelected.filter((loc) => loc.id !== locationId);
        } else {
          return [...prevSelected, { id: locationId, name: locationName }];
        }
      });
    }
  };

  const handleSelectAllChange = (e: CheckboxChangeEvent) => {
    setSelectAll(e.target.checked);
    setSelectedLocations(
      e.target.checked
        ? data.map((item) => ({ id: item.id, name: item.name }))
        : []
    );
  };

  const header = (
    <div>
      <ListHeader template="2em 0.7fr 0.6fr 1fr 0.5fr 1.2fr 0.2fr 0.6fr">
        <div style={{ padding: "0.4em" }}>
          <Checkbox checked={selectAll} onChange={handleSelectAllChange} />
        </div>
        <ListHeaderLabel
          value={t("component.assetList.externalId")}
          justifyContent="flex-start"
          sortable
          sort={
            sort === "externalId" ? (order === "desc" ? "asc" : "desc") : null
          }
          onClick={() => {
            setSort("externalId");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`externalId:${order}`);
            }
          }}
        />
        <ListHeaderLabel
          value={t("component.assetList.createdAt")}
          justifyContent="flex-start"
          sortable={sortable}
          sort={
            sort === "createdAt" ? (order === "asc" ? "asc" : "desc") : null
          }
          onClick={() => {
            setSort("createdAt");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`createdAt:${order}`);
            }
          }}
        />
        <ListHeaderLabel
          value={t("component.assetList.name")}
          justifyContent="flex-start"
          sortable={sortable}
          sort={sort === "name" ? (order === "asc" ? "asc" : "desc") : null}
          onClick={() => {
            setSort("name");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`name:${order}`);
            }
          }}
        />

        <ListHeaderLabel
          value={t("component.assetList.subtype")}
          justifyContent="flex-start"
          sortable={sortable}
          sort={sort === "type" ? (order === "desc" ? "asc" : "desc") : null}
          onClick={() => {
            setSort("subtype");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`subtype:${order}`);
            }
          }}
        />
        <ListHeaderLabel
          value={t("component.assetList.tags")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.assetList.status")}
          justifyContent="flex-start"
          sortable={sortable}
          sort={sort === "enabled" ? (order === "desc" ? "asc" : "desc") : null}
          onClick={() => {
            setSort("enabled");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`enabled:${order}`);
            }
          }}
        />
        <ListHeaderLabel value={""} justifyContent="center" />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <div className={styles.listContainerB}>
        <List
          loading={loading}
          loadingElement={() => <OrderListSkeleton />}
          error={error}
          data={data}
          keyExtractor={(item) => item.id}
          renderHeader={() => header}
          renderItem={(item) => (
            <AssetItem
              onCheckboxChange={() => handleCheckboxChange(item.id, item.name)}
              isSelected={selectedLocations?.some((loc) => loc.id === item.id)}
              key={item.id}
              onDelete={onDelete}
              onDetails={onDetails}
              path={path}
              data={item}
              assets={data}
            />
          )}
        />
      </div>

      {pagination ? (
        <div className={styles.paginationContainerFixed}>
          <PaginationRavent
            current={page + 1}
            defaultCurrent={page + 1}
            total={total}
            showSizeChanger
            onShowSizeChange={(page, size) => {
              if (onHitsPerPageChange) {
                onHitsPerPageChange(size);
              }
            }}
            onChange={(page) => {
              if (onPageChange) {
                onPageChange(page - 1);
              }
            }}
            pageSize={hitsPerPage}
          />
        </div>
      ) : null}
    </div>
  );
};

export default AssetList;
