const translation = {
  en: {
    supplierType: "Supplier Type",
    supplierName: "Supplier Name",
    intType: "Integration Type",
    intName: "Integration Name",
    enabled: "Enabled",
    deleteWarning: "Are you sure you want to delete",
  },
  es: {
    supplierType: "Tipo de proveedor",
    supplierName: "Nombre del proveedor",
    intType: "Tipo de integración",
    intName: "Nombre de la integración",
    enabled: "Habilitado",
    deleteWarning: "¿Está seguro de que desea eliminar?",
  },
  de: {},
};

export default translation;
