const translation = {
  en: {
    noTickets: "You don't have tickets yet.",
    definition: "Certificate Definition",
    issueTicket: "Issue Tickets",
    share: "Share Ticket",
    shareTicket: "Share Ticket",
    tickets: "Tickets",
    setDef: "Set Definition",
    noDefi: "You don't have a definition assigned to this orderline",
    untilDef: "The list is empty until you set a Definition",
  },
  es: {
    noTickets: "Todavía no tienes entradas.",
    definition: "Definición del Certificado",
    issueTicket: "Emitir certificado",
    share: "Compartir Boleto",
    shareTicket: "Compartir boleto",
    tickets: "Boletos",
    setDef: "Eleguir Definición",
    noDefi: "No tiene ninguna definición asignada",
    untilDef: "La lista está vacía ya que no se ha asignado una definición",
  },
  de: {},
};

export default translation;
