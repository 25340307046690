const translation = {
  en: {
    password: "Password",
    confirmCurrent: "Confirm current Password",
    newPassword: "New Password",
    confirmNew: "Confirm New Password",
  },
  es: {
    password: "Contraseña",
    confirmCurrent: "Confirmar contraseña actual",
    newPassword: "Nueva contraseña",
    confirmNew: "Confirmar nueva contraseña",
  },
  de: {},
};

export default translation;
