import { stylesheet } from "typestyle";
import { black, linkBlue } from "../../utils/colors";

const styles = stylesheet({
  container: {
    padding: "2em",
  },
  title: {
    fontSize: "1.5em",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "left",
    color: "#141322",
    paddingTop: "1em",
  },
  width: {
    width: "100%",
  },

  left: {
    color: "#333333",
    fontStyle: "normal",
    justifyContent: "flex-start",
    padding: "0em 0.5em 0 0.5em",
    display: "flex",
    alignItems: "center",
    marginRight: "0.5em",
  },
  shareContainer: {
    cursor: "pointer",
    width: "23px",
    $nest: {
      "&:hover > path": {
        fill: linkBlue,
      },
    },
  },

  searchRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "1em",
  },
  searchContainer: {
    width: "38em",
    $nest: {
      "& > labelContainer": {
        height: "0 !important",
      },
    },
  },

  listContainer: {
    padding: "0em",
  },

  noResultsContainer: {
    padding: "5em 0",
    borderBottom: `1px solid #e4e8f0`,
    color: black,
    textAlign: "center",
    $nest: {
      p: {
        textAlign: "center",
        marginBottom: "0px",
      },
    },
  },
  noResults: {
    margin: "1em auto",
    display: "flex",
  },
  paginationContainer: {
    paddingBottom: "2em",
    paddingRight: "2em",
    display: "flex",
    justifyContent: "flex-end",
  },
  paginationText: {
    color: black,
    fontSize: "0.9em",
  },
  filtersContainer: {
    paddingBottom: "2em",
  },
  filtersContainerW: {
    paddingBottom: "1.5em",
  },

  loading: {
    color: black,
    fontWeight: 600,
    padding: "6em 1em",
  },
  item: {
    fontSize: "1em",
    width: "100%",
    boxSizing: "border-box",
    breakInside: "avoid",
    textDecoration: "none",
    color: black,
    display: "flex",
    paddingLeft: "0.5em",
    flexWrap: "wrap",
    alignItems: "center",
    minHeight: "4em",
    height: "auto",
    $nest: {
      "&:hover": {
        backgroundColor: "#e4e9f0",
        color: black,
      },
      "& > div": {
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        color: black,
        padding: "1em",
      },
      "& > div > div": {
        display: "flex",
        alignItems: "center",
      },
    },
  },
  tags: {
    fontSize: "0.8em",
    margin: "0.1em",
    color: linkBlue + " !important",
    width: "auto",
    borderRadius: "5px",
    padding: "0 !important",
  },
  center: {
    fontSize: "0.9em",
    color: black,
    fontStyle: "normal",
    justifyContent: "center",
    padding: "1em",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    marginRight: "0.5em",
  },
  normal: {
    fontSize: "1em",
    justifyContent: "flex-start",
    fontWeight: 500,
    color: "#333333",
    alignItems: "center",
    display: "flex",
    padding: "1em",
    cursor: "pointer",
    textAlign: "left",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    textDecoration: "underline",
    fontWeight: "bold",
    color: black,
    fontSize: "1em",
    paddingLeft: "1em",
  },
  icon: {
    width: 23,
    marginRight: "1em",
  },
  link: {
    textDecoration: "none",
  },
  actionContainer: {
    cursor: "pointer",
    $nest: {
      "&:hover  > g > g > g": {
        fill: linkBlue,
      },
    },
  },
  receiptContainer: {
    cursor: "pointer",
    $nest: {
      "&:hover  > g > g > g > g": {
        fill: linkBlue,
      },
    },
  },
});

export default styles;
