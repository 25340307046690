import React from "react";

import styles from "./styles";
import { Venue } from "@innomius/ravent-typescript-types";
import { FOPSet } from "../../utils/types";
import CustomError from "../../utils/CustomError";
import get from "lodash/get";
import InputTextDropdown from "../Inputs/InputTextDropdown";
import { timezones } from "../../constants/timezones";
import {
  dateFormatingOptions,
  languagesOptions,
  numberFormatingOptions,
} from "../../constants/settings";
import { useTranslation } from "react-i18next";
import { Card } from "../Card/Card";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputText } from "../Inputs/InputText";
import { InputTextarea } from "../Inputs/InputTextArea";
import { InputSelect } from "../Inputs/InputSelect";
import Editor from "@monaco-editor/react";

interface Props {
  data: Venue;
  fopSets: FOPSet[];
  onChange: <P extends keyof Venue>(prop: P, value: Venue[P]) => void;
  error?: CustomError;
  edit?: boolean;
}

const VenueForm: React.FC<Props> = ({
  data,
  onChange,
  fopSets,
  error,
  edit,
}) => {
  const requestsOptions = fopSets.map((item) => ({
    label: `${item.name}`,
    value: item.id,
  }));

  const stringBody = JSON.stringify(data.data);


  const { t } = useTranslation();

  return (
    <div>
      <Card containerClassname={styles.container}>
        <div className={styles.containerDetails}>
          <ContainerTitle
            size="small"
            padding="0 0 1em 0"
            label={t("component.venueForm.venueInfo")}
          />

          <GridContainer gap="2em" columns="1fr 1fr 0.5fr">
            <InputText
              value={data.venueCapacity}
              label={t("component.venueForm.venueCapacity")}
              onChange={(value) => onChange("venueCapacity", value)}
            />
          </GridContainer>
          <GridContainer gap="2em" columns="2fr 0.85fr">
            <InputTextarea
              label={t("component.venueForm.description")}
              value={data.description}
              onChange={(value) => onChange("description", value)}
            />
          </GridContainer>

          <ContainerTitle
            padding="0em 0 1em 0"
            size="small"
            label={t("component.venueDetail.config")}
          />

          <GridContainer columns={edit ? "1fr 2em 1fr 1fr" : "1fr 2em 1fr 1em"}>
            <InputSelect
              label={t("component.venueForm.fopSet")}
              value={data.fopSetId}
              options={requestsOptions}
              onChange={(value) => onChange("fopSetId", value)}
              error={!data.fopSetId && get(error, "errors.venue-fopSet", "")}
            />
            <div />
            <></>

            <InputSelect
              label={t("component.venueForm.invoice")}
              value={data.invoiceIssuance}
              options={[
                {
                  value: "payment",
                  label: "payment",
                },
                {
                  value: "payment-request",
                  label: "payment-request",
                },
              ]}
              onChange={(value) => onChange("invoiceIssuance", value)}
              error={
                !data.invoiceIssuance &&
                get(error, "errors.venue-invoiceIssuance", "")
              }
            />
            <div />
          </GridContainer>

          <GridContainer
            padding={edit ? "0em 0 5em 0" : "5em 0 5em 0"}
            columns="1fr 2em 1fr 1fr"
          >
            <InputTextDropdown
              label={t("component.venueDetail.timezone")}
              value={data.timezone || ""}
              top={"5em"}
              options={timezones.map((item) => ({
                value: item,
                label: item,
              }))}
              onChange={(value) => onChange("timezone", value)}
              error={!data.timezone && get(error, "errors.venue-timezone", "")}
            />
            <div />
            <InputSelect
              label={t("component.venueDetail.dateFormatting")}
              value={data.dateFormatting}
              options={dateFormatingOptions}
              onChange={(value) => onChange("dateFormatting", value)}
              error={
                !data.dateFormatting &&
                get(error, "errors.venue-dateFormatting", "")
              }
            />
            <div />

            <InputSelect
              label={t("component.venueDetail.numberFormatting")}
              value={data.numberFormatting}
              options={numberFormatingOptions}
              onChange={(value) => onChange("numberFormatting", value)}
              error={
                !data.numberFormatting &&
                get(error, "errors.venue-numberFormatting", "")
              }
            />
            <div />
            <InputSelect
              label={t("component.venueForm.language")}
              value={data.defaultLanguage}
              options={languagesOptions}
              onChange={(value) => onChange("defaultLanguage", value)}
            />
          </GridContainer>

          <ContainerTitle
            size="small"
            padding="0 0 1em 0"
            label={t("component.entityForm.data")}
          />

          <Editor
            onChange={(value) => onChange("data", JSON.parse(value as string))}
            className={styles.editorBox}
            height="100%"
            // theme="vs-dark"
            defaultLanguage="json"
            defaultValue={stringBody}
            options={{
              selectOnLineNumbers: true,
              // minimap: { enabled: false },
              scrollbar: {
                vertical: "hidden",
              },
              overviewRulerLanes: 0,
              hideCursorInOverviewRuler: true,
              overviewRulerBorder: false,
              foldingHighlight: false,
            }}
          />
        </div>
      </Card>
    </div>
  );
};

export default VenueForm;
