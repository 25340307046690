import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AssetListParamsFT, assetFullTextSearch } from "../services/assets";
import { TextSearchListEvent } from "../services/orders";

type TextSearchSlice = {
  loading: boolean;
  error: string;
  list: TextSearchListEvent;
};

const initialState: TextSearchSlice = {
  loading: true,
  error: "",
  list: {
    page: 0,
    count: 0,
    hits: [],
    facets: [],
  },
};

export const assetTextSearchList = createAsyncThunk(
  "assetTextSearch/list",
  async (params: AssetListParamsFT) => {
    const response = await assetFullTextSearch(params);
    return response;
  }
);

const assetTextSearchSlice = createSlice({
  name: "assetTextSearch",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(assetTextSearchList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(assetTextSearchList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.list = action.payload;
    });
    builder.addCase(assetTextSearchList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An unexpected error ocurred";
    });
  },
});

export default assetTextSearchSlice.reducer;
