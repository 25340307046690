import { WeekDays, WeeklySchedule } from "@innomius/ravent-typescript-types";

import { TimePicker } from "antd";
import React, { useState } from "react";
import { headerDays } from "../../constants/days";
import styles from "./styles.module.css";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { CircleButton } from "../Calendar/CircleButton";

interface Props {
  onAddHour?: (frequencies: WeeklySchedule[]) => void;
  editItem?: WeeklySchedule | null;
  onCancel: () => void;
  onUpdate: (id: string) => void;
  onDelete: () => void;
}

const WeeklyHourForm: React.FC<Props> = ({
  onAddHour,
  editItem,
  onCancel,
  onUpdate,
  onDelete,
}) => {
  const [hour, setHour] = useState<string>("");
  const [selected, setSelected] = useState<WeekDays[]>([]);

  return (
    <>
      {editItem ? (
        <div className={styles.headerContainer}>
          <ButtonsContainer justifyContent="flex-start">
            <TimePicker
              format="HH:mm"
              onChange={(value, timeString) => {
                console.log(value);
                setHour(timeString);
              }}
            />
            <Button label="Update" onClick={() => onUpdate(hour)} />
            <Button
              label="Delete"
              theme="red"
              onClick={() => {
                onDelete();
                setSelected([]);
              }}
            />
            <Button
              label="Cancel"
              theme="white"
              onClick={() => {
                onCancel();
                setSelected([]);
              }}
            />
          </ButtonsContainer>
        </div>
      ) : (
        <div className={styles.headerContainer}>
          <TimePicker
            format="HH:mm"
            onChange={(value, timeString) => {
              setHour(timeString);
            }}
          />
          {headerDays.map((day, index) => (
            <CircleButton
              key={day.id + index}
              day={day.value}
              onClick={() => {
                if (selected.includes(day.id)) {
                  setSelected(selected.filter((item) => item !== day.id));
                }
                if (!selected.includes(day.id)) {
                  setSelected((selected) => [...selected, day.id]);
                }
              }}
              selected={selected.includes(day.id)}
            />
          ))}
          <Button
            label={"Add hour"}
            onClick={() => {
              if (hour) {
                onAddHour?.(
                  selected.map((item) => ({
                    weekday: item,
                    hour: hour,
                  }))
                );
                setSelected([]);
              }
            }}
          />
        </div>
      )}
    </>
  );
};

export default WeeklyHourForm;
