const translation = {
  en: {
    setName: "Set Name",
    fops: "FOPs",
    set: "set",
    deleteWarning: "Are you sure you want to delete this",
  },
  es: {
    setName: "Nombre del Set",
    fops: "FDP",
    set: "set",
    deleteWarning: "¿Está seguro de que desea eliminar esto?",
  },
  de: {},
};

export default translation;
