import React from "react";
import { formatDate } from "../../utils/times";
import styles from "./styles";
import { FlexContainer } from "../FlexContainer/FlexContainer";

export interface Props {
  content: string;
  visitor?: boolean;
  timestamp: Date | null | string;
}

export const ChatMessage: React.FC<Props> = ({
  content,
  timestamp,
  visitor,
}) => {
  return (
    <FlexContainer
      padding="0 0 2em 0"
      flexDirection={visitor ? "row" : "row-reverse"}
      justifyContent={visitor ? "flex-start" : ""}
    >
      <div
        className={styles.container}
        style={
          visitor
            ? { background: "#C9FCCC", marginRight: "2em" }
            : { background: "#FFEBC3", marginLeft: "2em" }
        }
      >
        {content}
      </div>
      <p>{formatDate(timestamp, "HOUR")}</p>
    </FlexContainer>
  );
};
