import { Discount, Surcharge, Tax } from "@innomius/ravent-typescript-types";
import React, { useState } from "react";
import styles from "./styles";
import { numberFormat } from "../../utils/format";
import fulfillerIcon from "../../assets/checkDisable.svg";
import fulfillerGreen from "../../assets/checkGreen.svg";
import { Popover, Tooltip, message } from "antd";
import { useTranslation } from "react-i18next";
import lock from "../../assets/lock.svg";
import copy from "../../assets/copy.svg";
import { ReactComponent as EditIcon } from "../../assets/editBlack.svg";

import DeletePopUp from "../DeletePopUp";
import AddOrderline from "../AddOrderline";
import { CreateOrderline } from "../../utils/types";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { GridContainer } from "../GridContainer/GridContainer";

interface OrderLineItemProps {
  description: string;
  details?: string;
  id: string;
  currency: string;
  quantity: number;
  unitPrice: number;
  discounts: Discount[];
  surcharges: Surcharge[];
  total: number;
  subTotal: number;
  taxes: Tax[];
  fulfillmentLevel: string;
  state: string;
  onCloseOrderline: (id: string) => void;
  sku: string;
  onEditOrderline: (ol: CreateOrderline) => void;
}

const Item: React.FC<OrderLineItemProps> = ({
  id,
  description,
  quantity,
  details,
  discounts,
  surcharges,
  total,
  unitPrice,
  taxes,
  fulfillmentLevel,
  state,
  currency,
  sku,
  onCloseOrderline,
  onEditOrderline,
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState("");
  const [edit, setEdit] = useState("");

  return (
    <div>
      <AddOrderline
        open={edit}
        oline={{
          id: id,
          currency: currency,
          unitPrice: unitPrice.toString(),
          discounts: discounts,
          surcharges: surcharges,
          taxes: taxes,
          details: details || "",
          description: description,
          sku: sku,
          quantity: quantity.toString(),
        }}
        onClose={() => setEdit("")}
        onSave={onEditOrderline}
      />
      <FlexContainer justifyContent="flex-start" alignItems="flex-start">
        <div style={{ width: "100%" }}>
          <GridContainer gap="0.5em" padding="0 0 0.2em 0" columns={fulfillmentLevel === "order" ? "0em 1fr" : "2em 1fr"}>
            {fulfillmentLevel === "order" || fulfillmentLevel === "none" ? (
              <div />
            ) : (
              <div style={{ marginRight: "0.5em" }}>
                {state === "fulfilled" ? (
                  <img
                    style={{ marginRight: "0.5em" }}
                    src={fulfillerGreen}
                    alt=""
                  />
                ) : (
                  <Tooltip
                    title={t("component.orderDetailItemList.markAsDone")}
                  >
                    <Popover
                      placement="left"
                      content={
                        <div onClick={() => setOpen("")}>
                          <DeletePopUp
                            value={`${t(
                              "component.orderDetailItemList.warning"
                            )}`}
                            onClose={(e) => {
                              e.stopPropagation();
                              setOpen("");
                            }}
                            onDelete={(e) => {
                              e.stopPropagation();
                              onCloseOrderline(id);
                            }}
                          />
                        </div>
                      }
                      trigger="click"
                      open={open === "item"}
                    >
                      <div
                        style={{ width: "1.2em", marginRight: "1em" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpen("item");
                        }}
                      >
                        <img src={fulfillerIcon} alt="" />
                      </div>
                    </Popover>
                  </Tooltip>
                )}
              </div>
            )}
            <FlexContainer
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <p className={styles.title}>{description}</p>
              <EditIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setEdit(id);
                }}
                style={{ width: "1.5em", height: "1.5em", cursor: "pointer" }}
              />
            </FlexContainer>

            <div />
            <GridContainer
              gap="1em"
              alignItems="flex-start"
              columns="3fr 2em 1.5fr"
            >
              <div>
                <FlexContainer
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <img src={lock} className={styles.lock} alt="" />
                  <span className={styles.info}>{details}</span>
                </FlexContainer>

                <FlexContainer
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <div className={styles.lock} />

                  <div className={styles.infoSmallGray}>{`SKU: ${sku}`}</div>

                  <img
                    onClick={(e) => {
                      e.stopPropagation();

                      if (sku) {
                        navigator.clipboard.writeText(sku);
                        message.success("Sku was copied successfully");
                      }
                    }}
                    style={{ marginLeft: "1em" }}
                    src={copy}
                    className={styles.lock}
                    alt=""
                  />
                </FlexContainer>
              </div>
              <div className={styles.quantity}>x{quantity}</div>
              <div className={styles.sectionRight}>
                {currency} {numberFormat(unitPrice || 0)}
              </div>
            </GridContainer>
          </GridContainer>

          {discounts.length >= 1
            ? discounts.map((item, index) => (
                <GridContainer key={index} gap="1em" columns="3fr 2em 1.5fr">
                  <p className={styles.infoGray}>{item.description}</p>
                  <div />
                  {item.value ? (
                    <p className={styles.sectionGray}>
                      - {currency} {numberFormat(item.value || 0)}
                    </p>
                  ) : null}
                </GridContainer>
              ))
            : null}
          {surcharges.length >= 1
            ? surcharges.map((item, index) => (
                <GridContainer key={index} gap="1em" columns="3fr 2em 1.5fr">
                  <p className={styles.infoBlack}>{item.description}</p>
                  <div />
                  {item.value ? (
                    <p className={styles.sectionRed}>
                      + {currency} {numberFormat(item.value || 0)}
                    </p>
                  ) : null}
                </GridContainer>
              ))
            : null}

          {taxes.length >= 1
            ? taxes.map((item, index) => (
                <GridContainer key={index} gap="1em" columns="3fr 2em 1.5fr">
                  <p className={styles.infoBlack}>{item.name}</p>
                  <div />
                  {item.value ? (
                    <p className={styles.sectionRed}>
                      + {currency} {numberFormat(item.value || 0)}
                    </p>
                  ) : null}
                </GridContainer>
              ))
            : null}
          <FlexContainer justifyContent="flex-end">
            <p className={styles.sectionBold}>
              {currency} {numberFormat(total)}
            </p>
          </FlexContainer>
        </div>
      </FlexContainer>
    </div>
  );
};

export default Item;
