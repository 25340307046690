import React from 'react';
import { classes } from 'typestyle';
import styles from './styles.module.css';
export interface Props {
  size: 'xsmall' | 'small' | 'medium' | 'big';
  label: string;
  count?: string;
  containerClassname?: string;
  padding?: string;
}

export const ContainerTitle: React.FC<Props> = ({ label, count, size = 'small', containerClassname, padding }) => {
  return (
    <div
      className={classes(styles.container, containerClassname, {
        [styles.extraSmall]: size === 'xsmall',
        [styles.small]: size === 'small',
        [styles.medium]: size === 'medium',
        [styles.big]: size === 'big'
      })}
      style={{ padding }}
    >
      <p className={styles.titleStyle}>{label}</p>
      {count ? <p className={styles.countStyle}>({count})</p> : null}
    </div>
  );
};
