
import React, { useState } from "react";
import Text from "../Text";
import card from "../../assets/card_reader.svg";
import cash from "../../assets/cash.svg";
import credit from "../../assets/credit.svg";
import store from "../../assets/convenience_store.svg";
import paymentLink from "../../assets/payment_link.svg";
import wiretransfer from "../../assets/paymentsMethods/wiretransferazul.svg";
import styles from "./styles";
import { capitalize } from "../../utils/format";
import IntegrationModal from "../PaymentSupplierList/IntegrationModal";
import AccountItem from "../AccountList/AccountItem";
import { BankAccount } from "../../utils/types";
import AccountForm from "../AccountList/AccountForm";
import FOPDetail from "../FOPDetail";
import { useTranslation } from "react-i18next";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { InputSwitch } from "../Inputs/InputSwitch";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";

interface Props {
  type: string;
  id: string;
  supplierName: string;
  supplierService: string;
  onEnabled?: (id: string, enabled: boolean) => void;
  active: boolean;
  integrationName?: string;
  integrationRequired: boolean;
  bankAccountData: BankAccount;
  onEditBank: (id: string, data: BankAccount) => void;
  setSupplierName: (name: string) => void;
  onRemove: (id: string) => void;
  loading: string;
  list: boolean;
  noBorder: boolean;
  round: string;
}

const FOPCard: React.FC<Props> = ({
  onEnabled,
  active,
  type,
  supplierName,
  id,
  supplierService,
  integrationRequired,
  integrationName,
  bankAccountData,
  onEditBank,
  setSupplierName,
  onRemove,
  loading,
  list,
  noBorder,
  round,
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState("");
  const [bankAccount, setAccount] = useState<BankAccount>(bankAccountData);

  const onChange = <P extends keyof BankAccount>(
    prop: P,
    value: BankAccount[P]
  ) => {
    setAccount({ ...bankAccount, [prop]: value });
  };

  const fopIconTypeMap: Record<string, string> = {
    "card-reader": card,
    netd: credit,
    cash: cash,
    "in-store": store,
    "payment-link": paymentLink,
    "wire-transfer": wiretransfer,
  };

  const fopTitleMap: Record<string, string> = {
    "card-reader": "Card Reader",
    netd: "Credit(NetD Payments)",
    cash: "Cash",
    "in-store": "In Store",
    "payment-link": "Payment Link",
    "wire-transfer": "Wiretransfer",
  };

  return (
    <div
      className={styles.container}
      style={{ border: noBorder ? "transparent" : "1px solid #DCE1E6" }}
    >
      <AccountForm
        loading={loading === "account"}
        onSave={() => onEditBank(id, bankAccount)}
        onClose={() => setOpen("")}
        onChange={onChange}
        open={open === "edit"}
        data={bankAccount}
        editing
      />
      <IntegrationModal
        loading={loading === "update-integration"}
        onClose={() => setOpen("")}
        open={open === "integration"}
      />
      <FOPDetail
        round={round}
        id={id}
        setOpen={() => setOpen("details")}
        onChange={onChange}
        loading={loading === "account"}
        integrationName={integrationName}
        addAccount={(id: string) => {
          onEditBank(id, bankAccount);
        }}
        account={bankAccount}
        type={type}
        integrationRequired={integrationRequired}
        integrations={integrationName ? 1 : 0}
        onClose={() => setOpen("")}
        open={open === "details"}
        supplierName={supplierName}
        supplierService={supplierService}
      />
      <div className={styles.header} onClick={() => setOpen("details")}>
        <FlexContainer justifyContent="space-between" alignItems="center">
          <FlexContainer alignItems="flex-start" justifyContent="flex-start">
            <img src={fopIconTypeMap[type]} alt="" className={styles.icon} />
            <FlexContainer
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Text
                text={fopTitleMap[type]}
                fontSize="1.3em"
                fontWeight={600}
              />
              <Text
                text={`${capitalize(supplierName)} - ${supplierService} - ${
                  integrationRequired
                    ? "integration-required"
                    : "integration-not-required"
                }`}
                fontSize="1.1em"
                fontWeight={400}
              />
            </FlexContainer>
          </FlexContainer>

          <ButtonsContainer justifyContent="flex-end">
            <Button
              label={
                loading === id
                  ? "..."
                  : list
                  ? t("component.fopCard.remove")
                  : t("component.fopCard.delete")
              }
              theme="white"
              onClick={(e) => {
                e.stopPropagation();
                if (loading) {
                  return;
                }
                onRemove(id);
              }}
            />
            {list ? (
              <div onClick={(e) => e.stopPropagation()}>
                <InputSwitch
                  value={active}
                  onChange={(value, e) => {
                    e?.stopPropagation();
                    if (
                      integrationRequired &&
                      integrationName &&
                      integrationName.length < 1
                    ) {
                      setOpen("integration");
                      setSupplierName(`${supplierName}`);
                      return;
                    }
                    onEnabled?.(id, value);
                  }}
                />
              </div>
            ) : null}
          </ButtonsContainer>
        </FlexContainer>
      </div>

      {bankAccount.bank ? (
        <div className={styles.bottm}>
          <AccountItem
            onEdit={() => setOpen("edit")}
            bankAccount={bankAccountData.bankAccount}
            id={id}
            bank={bankAccountData.bank}
            qrCodeType={bankAccountData.qrCodeType}
            alias={bankAccountData.alias}
            legalName={bankAccountData?.legalName}
          />
        </div>
      ) : null}
    </div>
  );
};

export default FOPCard;
