import { stylesheet } from "typestyle";

const styles = stylesheet({
  wrapperCard: {
    cursor: "pointer",
  },
  icon: {
    width: "45px",
    marginRight: "1em",
    cursor: "pointer",
  },
  card: {},
  active: {
    border: "1px solid #1FA0FF !important",
    boxShadow: "none !important",
  },
});

export default styles;
