import React from "react";
import { RegisterPaymentRequest } from "../../utils/types";

import CustomError from "../../utils/CustomError";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { InputText } from "../Inputs/InputText";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputSelect } from "../Inputs/InputSelect";

interface Props {
  registerPayment: RegisterPaymentRequest;
  onChange: <P extends keyof RegisterPaymentRequest>(
    prop: P,
    value: RegisterPaymentRequest[P]
  ) => void;
  error?: CustomError;
}

const CardForm: React.FC<Props> = ({ onChange, registerPayment, error }) => {
  const { t } = useTranslation();

  return (
    <div>
      <GridContainer gap="1em" columns="1fr 1fr">
        <InputSelect
          label={t("component.registerPaymentForm.cardBrand")}
          options={[
            { label: "Visa", value: "visa" },
            { label: "MasterCard", value: "master-card" },
            { label: "American Express", value: "amex" },
            { label: "Maestro", value: "maestro" },
            { label: "VPay", value: "v-pay" },
            { label: "China Union Pay", value: "china-union-pay" },
            { label: "Japan Credit Bureau", value: "japan-credit-bureua" },
          ]}
          value={registerPayment.cardBrand}
          onChange={(value) => onChange("cardBrand", value)}
          error={
            !registerPayment.cardBrand && get(error, "errors.card-brand", "")
          }
        />
        <InputText
          value={`${registerPayment.lastFourDigits}`}
          label={t("component.registerPaymentForm.last4Digits")}
          onChange={(value) => onChange("lastFourDigits", value)}
          error={
            !registerPayment.lastFourDigits &&
            get(error, "errors.last-four-digits", "")
          }
        />
      </GridContainer>
      <GridContainer gap="1em" columns="1fr 1fr">
        <InputText
          label={t("component.registerPaymentForm.customerName")}
          value={registerPayment.customerName}
          onChange={(value) => onChange("customerName", value)}
        />
        <InputText
          label={t("component.registerPaymentForm.authNumber")}
          value={registerPayment.cardAuthorization}
          onChange={(value) => onChange("cardAuthorization", value)}
          error={
            !registerPayment.cardAuthorization &&
            get(error, "errors.card-authorization", "")
          }
        />
      </GridContainer>
    </div>
  );
};

export default CardForm;
