const translation = {
  en: {
    fulfillment: "Fulfillment",
    states: "States",
    fulfiller: "Fulfiller",
    historic: "Historic",
    setState: "Set State",
    fulfillmentState: "Definition",
    noDefinition: "The list is empty until you configure a definition",
    definitions: "State Definitions",
    instructions: "You don't have a definition assigned to this order",
    select: "Select a Fulfillment State",
    noDefinitions:
      "No definitions are configured for this venue. Please create state definitions.",
  },
  es: {
    fulfillment: "Entrega",
    states: "Estados",
    historic: "Historial",
    fulfillmentState: "Estado de Entrega",
    fulfiller: "Fulfiller",
    select: "Seleccione un Estado de Entrega",

    setState: "Establecer estado",
    noDefinition: "La lista está vacía hasta que configure una definición",
    definitions: "Definiciones de Estado",
    instructions: "No tiene asignado niguna definición a esta orden",
    noDefinitions:
      "No hay definiciones configuradas para este lugar. Cree definiciones de entradas.",
  },
  de: {},
};

export default translation;
