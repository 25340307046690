import React, { useState } from "react";

import { Offer } from "@innomius/ravent-typescript-types";
import deleteIcon from "../../assets/officialMedia/default-delete.svg";
import editIcon from "../../assets/officialMedia/default-edit.svg";
import styles from "./styles";
import { useHistory } from "react-router-dom";
import { Popover } from "antd";
import DeletePopUp from "../DeletePopUp";
import OfferDetail from "../OfferDetail";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ListLabel } from "../List/ListLabel";
import { Tag } from "../Tag/Tag";
import { ListRow } from "../List/ListRow";

interface OffersListProps {
  data: Offer;
  path: string;
  onChange: <P extends keyof Offer>(prop: P, value: Offer[P]) => void;
}

const OfferListItem: React.FC<OffersListProps> = ({ data, path, onChange }) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [deleteOfferSet, setDelete] = useState("");
  const [variant, setVariant] = useState<Offer>(data);

  const onChangeVariant = <P extends keyof Offer>(prop: P, value: Offer[P]) => {
    setVariant({ ...variant, [prop]: value });
  };

  return (
    <>
      <OfferDetail
        onChange={onChangeVariant}
        key={`${data.id}data`}
        path={`${path}`}
        data={variant}
        open={open}
        onClose={() => {
          setOpen(false);
          history.push(`${path}`);
        }}
      />
      <ListRow
        onClick={() => {
          setOpen(true);
          history.push(`${path}/${data.id}/modifiers`);
        }}
        template="2fr 1fr 1.5fr 1fr 1fr"
        hover={true}
        key={data.id}
      >
        <div className={styles.spaceBetween}>
          <img src={data.image} className={styles.imageIcon} alt="" />
          <div>{data.name}</div>
        </div>
        <ListLabel
          value={data.ticketingEnabled ? "Enabled" : "Disabled"}
          textAlign="left"
        />
        <div className={styles.labelContainer}>
          {data.tags?.map((item, index) => (
            <div key={index} className={styles.label}>
              <Tag theme="blue" label={item} />
            </div>
          ))}
        </div>
        <div className={styles.center}>{data.modifiers.length.toString()}</div>
        <FlexContainer justifyContent="space-between" alignItems="center">
          <img src={editIcon} className={styles.icon} alt="editOffer" />
          <Popover
            placement="right"
            content={
              <div onClick={() => setDelete("")}>
                <DeletePopUp
                  value={`Are you sure you want to delete ${data.name} Offer?`}
                  onClose={(e) => {
                    e.stopPropagation();
                    setDelete("");
                  }}
                  onDelete={(e) => {
                    e.stopPropagation();
                    setDelete("delete");
                  }}
                />
              </div>
            }
            trigger="click"
            open={deleteOfferSet === data.id}
            onOpenChange={() => setDelete(deleteOfferSet)}
          />
          <img
            src={deleteIcon}
            className={styles.icon}
            alt="deleteOffer"
            onClick={(e) => {
              e.stopPropagation();
              setDelete(data.id);
            }}
          />
        </FlexContainer>
      </ListRow>
    </>
  );
};

export default OfferListItem;
