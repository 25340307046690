export const venueTypes = [
  { label: "aquariums", value: "aquariums" },
  { label: "aquatic-park", value: "aquatic-park" },
  { label: "arena", value: "arena" },
  { label: "art-galleries", value: "art-gallery" },
  { label: "attraction", value: "attraction" },
  { label: "auditorium", value: "auditorium" },
  { label: "bar", value: "bar" },
  { label: "breweries", value: "breweries" },
  { label: "cafeteria", value: "cafeteria" },
  { label: "center", value: "center" },
  { label: "clinic", value: "clinic" },
  { label: "college", value: "college" },
  { label: "complex", value: "complex" },
  { label: "conference-centers", value: "conference-centers" },
  { label: "convention-centers", value: "convention-centers" },
  { label: "course", value: "course" },
  { label: "court", value: "court" },
  { label: "dmc", value: "dmc" },
  { label: "factory", value: "factory" },
  { label: "gallery", value: "gallery" },
  { label: "golf-course", value: "golf-course" },
  { label: "hospital", value: "hospital" },
  { label: "hotel", value: "hotel" },
  { label: "industrial-complex", value: "industrial-complex" },
  { label: "laboratory", value: "laboratory" },
  { label: "lift", value: "lift" },
  { label: "locker", value: "locker" },
  { label: "lounge", value: "lounge" },
  { label: "meeting-room", value: "meeting-room" },
  { label: "movie-theater", value: "movie-theater" },
  { label: "museum", value: "museum" },
  { label: "museums", value: "museums" },
  { label: "night-club", value: "night-club" },
  { label: "ship", value: "ship" },
  { label: "port", value: "port" },
  { label: "marina", value: "marina" },
  { label: "park", value: "park" },
  { label: "race", value: "race" },
  { label: "region", value: "region" },
  { label: "resort", value: "resort" },
  { label: "restaurant", value: "restaurant" },
  { label: "retreat-centers", value: "retreat-centers" },
  { label: "school", value: "school" },
  { label: "social-clubs", value: "social-clubs" },
  { label: "sporting-facility", value: "sporting-facility" },
  { label: "stadium", value: "stadium" },
  { label: "stage", value: "stage" },
  { label: "store", value: "store" },
  { label: "supermarket", value: "supermarket" },
  { label: "theater", value: "theater" },
  { label: "theme-park", value: "theme-park" },
  { label: "university", value: "university" },
  { label: "wineyard", value: "wineyard" },
  { label: "zoo", value: "zoo" },
  { label: "other", value: "other" },
];

export const officialMediaType = [
  { label: "public", value: true },
  { label: "private", value: false },
];
