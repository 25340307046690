import { stylesheet } from "typestyle";

const styles = stylesheet({
  container: {
    marginBottom: "1em",
  },
  containerDetails:{
    padding: '1em',

  },
  officeHourRow: {
    width: '70%'
  
  },
  text:{
    paddingBottom: '1em',
    paddingTop: '1em',
  },
  bold: {
    fontWeight: 600,
    textTransform: 'capitalize'
  }
});

export default styles;
