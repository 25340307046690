import React from "react";

import QRCode from "qrcode.react";

interface Props {
  url: string;
}

const QR: React.FC<Props> = ({ url }) => {
  return <QRCode value={url} size={193} renderAs="canvas" />;
};

export default QR;
