const translation = {
  en: {
    service: "A system supplying needs such as communications, or utilities.",
    access: "It includes access to venues (e.g. concerts, shows)",
    delivery: "A specified product is delivered to a certain location",
    locker:
      "The customer is expected to go to the specified location and follow certain instructions to get the product",
    digital:
      "Electronic/digital item that can be delivered with a QR code, an email, a message via SMS/Whatsapp , etc.",
    "pick-up": "The customer requires to go to pick-up points to collect items",
    enabledLocations: "Locations Enabled",
    locations: "Locations",
    type: "Type",
    transportation: "A system supplying transfers and transports",
    hospitaly:
      "Hospitality is used when the customer is given temporarily access to housing facilities. Hotels, AirBnBs and other facilities are considered this type",
  },
  es: {
    service:
      "Un sistema que satisface necesidades, las comunicaciones o los servicios públicos",
    access: "Incluye acceso a recintos (por ejemplo, conciertos, espectáculos)",
    delivery: "Un producto específico se entrega en un lugar determinado",
    locker:
      "Se espera que el cliente vaya a la ubicación especificada y siga ciertas instrucciones para obtener el producto",
    digital:
      "Artículo electrónico/digital que se puede entregar con un código QR, un correo electrónico, un mensaje a través de SMS/Whatsapp, etc.",
    "pick-up":
      "El cliente requiere ir a los puntos de recogida para recoger los artículos",
    enabledLocations: "Ubicaciones habilitadas",
    locations: "Ubicaciones",
    type: "Tipo",
    transportation: "Un sistema que proporciona transferencias y transportes",
    hospitaly:
      "La hospitalidad se utiliza cuando al cliente se le da acceso temporal a las instalaciones de la vivienda. Se consideran de este tipo hoteles, AirBnBs y otras instalaciones",
  },
  de: {},
};

export default translation;
