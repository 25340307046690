import { stylesheet } from "typestyle";
import { darkBlack, lightGrey, linkBlue } from "../../utils/colors";

const styles = stylesheet({
  container: {
    borderRadius: "5px",
    padding: "1em 1em 6em 1em",
  },
  alertContainer: {
    padding: "0 1em",
  },
  notEnrollContainer: {
    background: "rgba(250, 173, 21, 0.1)",
    border: "1px solid #FAAD15",
    padding: "1em",
    borderRadius: "6px",
    margin: "1em 0 0 0",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "1em 2em",
    background: "rgba(220, 225, 230, 0.3)",
    margin: "0 !important",
    width: "100%",
    justifyContent: "center",
  },
  none: {},
  focus: {
    border: "1px solid #40a9ff",
    boxShadow: "0 0 0 2px rgb(24 144 255 / 20%)",
  },

  focusEmail: {
    border: "1px solid #40a9ff",
    boxShadow: "0 0 0 2px rgb(24 144 255 / 20%)",
  },
  marginBottom: {
    marginBottom: "1em",
  },

  iconContainerActive: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "1em 2em",
    background: "white",
    margin: "0 !important",
    width: "100%",
    justifyContent: "center",
  },
  iconActive: {
    width: 26,
    cursor: "pointer",
  },
  icon: {
    width: 23,
    cursor: "pointer",
  },
  iconBig: {
    width: 40,
    cursor: "pointer",
  },
  iconDelivery: {
    width: 30,
    marginRight: "1em",
  },
  buttonContainer: {
    width: "60%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  title: {
    fontSize: "1.1em",
    fontWeight: 700,
    color: darkBlack,
    marginRight: "1em",
  },
  link: {
    fontSize: "1em",
    fontWeight: 500,
    color: linkBlue,
  },
  margin: {
    margin: "1.5em 0 2em 0",
  },
  inputContainer2: {
    background: "#FEFEFE",
    borderColor: "#E4E8F0",
    borderRadius: "3px",
    borderStyle: "solid",
    borderWidth: "1px",
    fontSize: "1em",
    height: "3em !important",
    padding: "0.6em",
    width: "100%",
  },

  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  containerBox: {
    padding: "0 0 0 0",
  },
  marginLow: {
    margin: "0.5em 0 2em 0",
  },
  lineDown: {
    width: "100%",
    height: "1px",
    margin: "1em 0 1em 0",
    background: lightGrey,
  },
  radioContainer: {
    marginRight: "2em",
    display: "flex",
    padding: "0em 0",
  },
  box: {
    border: "1px solid #DCE1E6",
    borderRadius: "2px",
    marginBottom: "1em",
  },
  email: {
    width: "100%",
  },
  phone: {
    width: "100% !important",
    $nest: {
      ".form-control": {
        width: "100% !important",
        // ".&focus": {
        //   border: "1px solid #40a9ff",
        //   boxShadow: "0 0 0 2px rgb(24 144 255 / 20%)",
        // },
      },
    },
  },
  select: {
    width: "100%",
    padding: "1em 0 0 0",
  },
  other: {
    padding: "0 0 0 2em",
    width: "80%",
  },
  pad: {
    padding: "1em 0",
  },
});

export default styles;
