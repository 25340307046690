import { WeekDays } from "@innomius/ravent-typescript-types";

export const weekdays = [
  { day: "monday", value: "MON" },
  { day: "tuesday", value: "TUE" },
  { day: "wednesday", value: "WED" },
  { day: "thursday", value: "THU" },
  { day: "friday", value: "FRI" },
  { day: "saturday", value: "SAT" },
  { day: "sunday", value: "SUN" },
];


export const headerDays = [
    { id: "monday" as WeekDays, value: "M" },
    { id: "tuesday" as WeekDays, value: "T" },
    { id: "wednesday" as WeekDays, value: "W" },
    { id: "thursday" as WeekDays, value: "T" },
    { id: "friday" as WeekDays, value: "F" },
    { id: "saturday" as WeekDays, value: "S" },
    { id: "sunday" as WeekDays, value: "S" },
  ];