import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FOPListParams } from "../services/fop";
import {
  fopSetList as fopSetsListService,
  FOPSetSearchResponse,
} from "../services/fop";

type FOPSetsSliceState = {
  loading: boolean;
  error: string;
  list: FOPSetSearchResponse;
};

const initialState: FOPSetsSliceState = {
  loading: true,
  error: "",
  list: {
    page: 0,
    total: 0,
    data: [],
  },
};

export const fopSetsList = createAsyncThunk(
  "fopSets/list",
  async (params: FOPListParams) => {
    const response = await fopSetsListService(params);
    return response;
  }
);

const fopSetsSlice = createSlice({
  name: "fopSets",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fopSetsList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(fopSetsList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.list = action.payload;
    });
    builder.addCase(fopSetsList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An unexpected error ocurred";
    });
  },
});

export default fopSetsSlice.reducer;
