const translation = {
  en: {
    offers: "Offer Sets",
    labels: "Labels",
    instructions: "Start creating your offers",
    addOfferList: "Add your offers list here",
    newOffer: "+ Create New Offer Set",
    cancel: "Cancel",
    save: "Save",
    name: "Name",
    from: "From",
    search: "Search"
  },
  es: {
    offers: "Conjunto de Ofertas",
    labels: "Etiquetas",
    instructions: "Empieza a crear tus ofertas",
    addOfferList: "Agrega tu lista de ofertas aquí",
    newOffer: "+ Crear nuevo Conjunto de Ofertas",
    cancel: "Cancelar",
    save: "Guardar",
    name: "Nombre",
    from: "De",
    search: "Buscar"
  },
  de: {},
};

export default translation;
