import crossFetch from "cross-fetch";
import CustomError from "./CustomError";
import APIError from "./APIError";

type HTTP_METHODS = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export function createFetch(baseApiUrl: string) {
  return async function customFetch(
    url: string,
    token?: string | null,
    method?: HTTP_METHODS,
    body?: unknown,
    query?: any
  ) {
    try {
      const res = await crossFetch(
        query
          ? `${baseApiUrl}${url}?${new URLSearchParams(query).toString()}`
          : `${baseApiUrl}${url}`,
        {
          method: method || "GET",
          headers: {
            "Content-Type": "application/json",
            "cache-control": "no-cache",
            Authorization: token ? `Bearer ${token}` : "",
          },
          body: body ? JSON.stringify(body) : undefined,
        }
      );

      if (res.ok && res.status !== 204) {
        const json = await res.json();
        return { status: res.status, body: json };
      }

      // if (res.status === 404) {
      //   throw new CustomError("NOT FOUND", "404");
      // }

      if (res.status >= 400) {
        const json = await res.json();
        throw new APIError(json.error, json.message, res.status.toString());
      }

      return { status: res.status, body: undefined };
    } catch (error) {
      if (
        error instanceof Error &&
        error.message === "Network request failed"
      ) {
        throw new CustomError("network", "newtwork");
      }
      throw error;
    }
  };
}

const fetch = createFetch("/api/v1");

export default fetch;
