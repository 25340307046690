import React from "react";

import { Asset } from "../../utils/types";
import { useTranslation } from "react-i18next";
import { InputTags } from "../Inputs/InputTags";
import Editor from "@monaco-editor/react";
import styles from "./styles";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputTextarea } from "../Inputs/InputTextArea";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
import { Input, Switch } from "antd";
import { InputError } from "../Inputs/InputError/InputError";

interface Props {
  data: Asset;
  onChange: <P extends keyof Asset>(prop: P, value: Asset[P]) => void;
}

const AssetDetailsForm: React.FC<Props> = ({ data, onChange }) => {
  const { t } = useTranslation();
  const stringBody = JSON.stringify(data.data);

  return (
    <div>
      <FlexContainer justifyContent="space-between">
        <ContainerTitle size="xsmall" label="Details" padding="0 0 1em 0" />
      </FlexContainer>
      <GridContainer gap="1em" columns="1fr 0.3fr">
        <div>
          <GridContainer alignItems="flex-start" columns="1fr 0.2fr 0.5fr">
            <div>
              <InputLabel label={t("component.assetForm.externalId")} />
              <Input
                value={data.externalID}
                onChange={(value) => onChange("externalID", value.target.value)}
              />
              <InputError
                error={
                  data.externalID === ""
                    ? "This field is required"
                    : data.externalID.length > 6
                    ? "This field cannot have more than 6 characters"
                    : ""
                }
              />
            </div>

            <div style={{ marginLeft: "2em", paddingTop: "0.5em" }}>
              <p style={{ fontWeight: 700, paddingBottom: "0.5em" }}>
                {t("component.assetDetail.status")}
              </p>

              <Switch
                checked={data.status}
                onChange={(value) => onChange("status", value)}
              />
            </div>
          </GridContainer>
        </div>
      </GridContainer>

      <InputTags
        label={t("component.assetForm.tags")}
        value={data.tags}
        onChange={(value) => onChange("tags", value)}
      />
      <div style={{ marginTop: "1em" }}>
        <InputTextarea
          value={data.notes}
          showError={false}
          label={t("component.assetForm.notes")}
          onChange={(value) => onChange("notes", value)}
        />
      </div>
      <div style={{ marginTop: "1em" }}>
        <InputTextarea
          value={data.description}
          showError={false}
          label={t("component.assetForm.description")}
          onChange={(value) => onChange("description", value)}
        />
      </div>

      <InputLabel label={t("component.assetForm.data")} />
      <Editor
        onChange={(value) => onChange("data", JSON.parse(value as string))}
        className={styles.editorBox}
        height="100%"
        // theme="vs-dark"
        defaultLanguage="json"
        defaultValue={stringBody}
        options={{
          selectOnLineNumbers: true,
          // minimap: { enabled: false },
          scrollbar: {
            vertical: "hidden",
          },
          overviewRulerLanes: 0,
          hideCursorInOverviewRuler: true,
          overviewRulerBorder: false,
          foldingHighlight: false,
        }}
      />
    </div>
  );
};

export default AssetDetailsForm;
