import React from "react";
import { Entity } from "@innomius/ravent-typescript-types";
import { Card } from "../Card/Card";

import styles from "./styles";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import MapForm from "../MapForm";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
import { InputText } from "../Inputs/InputText";
import { GridContainer } from "../GridContainer/GridContainer";
import { ContainerTitle } from "../ContainerTitle";

interface Props {
  entity: Entity;
  onChange: <P extends keyof Entity>(prop: P, value: Entity[P]) => void;
  setLocationW3W: (location: any) => void;
  locW3W: any;
  loading: string;
  setEdit: (edit: boolean) => void;
  load: () => void;
  onEditOrg: () => void;
}

const OrgForm: React.FC<Props> = ({
  entity,
  onChange,
  setLocationW3W,
  locW3W,
  setEdit,
  loading,
  load,
  onEditOrg,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Card>
        <div className={styles.wrapperCard}>
          <FlexContainer padding="0 0 1.5em 0" justifyContent="space-between">
            <ContainerTitle
              size="small"
              label={t("component.entityDetail.details")}
            />

            <ButtonsContainer justifyContent="flex-end">
              <Button
                label={t("container.profileDetail.cancel")}
                onClick={() => {
                  setEdit(false);
                  load();
                }}
                theme="white"
              />
              <Button
                label={
                  loading === "edit" ? "..." : t("container.profileDetail.save")
                }
                onClick={() => onEditOrg()}
                theme="blue"
              />
            </ButtonsContainer>
          </FlexContainer>

          <GridContainer gap="1em" columns={entity.logo ? "4fr 1fr" : "1fr"}>
            <div>
              <GridContainer
                gap="1em"
                columns="1fr 1fr 1fr"
                justifyContent="flex-start"
              >
                <InputText
                  value={entity.email}
                  onChange={(value) => onChange("email", value)}
                  label={t("component.entityDetail.email")}
                />
                <FlexContainer
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  flexDirection="column"
                >
                  <InputLabel label={t("component.entityDetail.phone")} />
                  <PhoneInput
                    value={entity.phone || ""}
                    onChange={(value) => onChange("phone", value)}
                  />
                </FlexContainer>
              </GridContainer>
              <div className={styles.addressContainer}>
                <ContainerTitle
                  padding="0 0 1em 0"
                  size="small"
                  label={t("component.entityDetail.address")}
                />
                <GridContainer gap="1em" columns="1fr 1fr 1fr">
                  <InputText
                    value={entity.address.reference}
                    label={t("component.orderLocation.reference")}
                    onChange={(value) =>
                      onChange("address", {
                        id: entity.address.id,
                        reference: value,
                        chronologicalIndex: 0,
                        name: entity.address.line1,
                        line1: entity.address.line1,
                        type: "w3w",
                        w3w: entity.address.w3w,
                        coordinates: {
                          latitude: entity.address.coordinates?.latitude || "",
                          longitude:
                            entity.address.coordinates?.longitude || "",
                        },
                      })
                    }
                  />
                </GridContainer>
                <MapForm
                  line1={entity.address.line1}
                  setW3W={(value) => {
                    onChange("address", {
                      type: "w3w",
                      id: "",
                      chronologicalIndex: 0,
                      w3w: value,
                      reference: value,
                      name: entity.address.line1,
                      line1: entity.address.line1,
                      coordinates: {
                        latitude: entity.address.coordinates?.latitude || "",
                        longitude: entity.address.coordinates?.longitude || "",
                      },
                    });
                  }}
                  w3w={locW3W}
                  setData={(data, name) => {
                    onChange("address", {
                      type: "w3w",
                      id: "",
                      chronologicalIndex: 0,
                      w3w: data.words,
                      name: name,
                      line1: name,
                      reference: entity.address.reference,
                      coordinates: {
                        latitude: data.coordinates.lat.toString(),
                        longitude: data.coordinates.lng.toString(),
                      },
                    });
                    setLocationW3W(data);
                  }}
                />
              </div>
            </div>
          </GridContainer>
        </div>
      </Card>
    </div>
  );
};

export default OrgForm;
