import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { GridContainer } from "../GridContainer/GridContainer";
import { OrderInvoice, Payment } from "@innomius/ravent-typescript-types";
import { message } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createInvoice, defaultInvoice } from "../../services/invoices";
import APIError from "../../utils/APIError";
import { numberFormat } from "../../utils/format";
import InputDate from "../Inputs/Date";
import { InputTime } from "../Inputs/InputTime/InputTime";
import AddFile from "./AddFile";
import styles from "./styles";
import { SideMenu } from "../SideMenu/SideMenu";
import Text from "../Text";
import { InputText } from "../Inputs/InputText";
import { InputSelect } from "../Inputs/InputSelect";

interface Props {
  orderAmount: string;
  requestsPayments: Payment[];
  payments: Payment[];
  onCancel: () => void;
  open: boolean;
  orderId: string;
  load: () => void;
  currency: string;
}

const AddInvoice: React.FC<Props> = ({
  orderAmount,
  requestsPayments,
  payments,
  open,
  onCancel,
  orderId,
  load,
  currency,
}) => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState(orderAmount);
  const [next, setNext] = useState("");
  const [loading, setLoading] = useState(false);

  const [invoice, setInvoice] = useState<OrderInvoice>(defaultInvoice);

  const onChange = <P extends keyof OrderInvoice>(
    prop: P,
    value: OrderInvoice[P]
  ) => {
    setInvoice({ ...invoice, [prop]: value });
  };

  const requestsOptions = requestsPayments.map((item) => ({
    label: `${item.shortId} - ${item.paymentType} - ${
      item.amount.currency
    } ${numberFormat(item.amount.value)}`,
    value: item.id,
  }));

  const paymentOptions = payments.map((item) => ({
    label: `${item.shortId} - ${item.paymentType} - ${
      item.amount.currency
    } ${numberFormat(item.amount.value)}`,
    value: item.id,
  }));
  const onSave = async () => {
    try {
      setLoading(true);
      const res = await createInvoice(invoice, orderId, amount, currency);
      setLoading(false);
      message.success(t("component.addInvoice.successCreation"));
      setInvoice(res);
      setNext("file");
    } catch (err) {
      setLoading(false);
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  return (
    <SideMenu width="50%" open={open} onClose={onCancel}>
      {next !== "file" ? (
        <div className={styles.container}>
          <FlexContainer padding="1em 0 2em 0" justifyContent="space-between">
            <ContainerTitle
              label={t("component.addInvoice.addInvoice")}
              size="medium"
            />
            <ButtonsContainer justifyContent="flex-end">
              <Button
                onClick={onCancel}
                theme="white"
                label={t("component.addInvoice.cancel")}
              />
              <Button
                onClick={async () => onSave()}
                theme="blue"
                label={loading ? "..." : `${t("component.addInvoice.save")}`}
              />
            </ButtonsContainer>
          </FlexContainer>

          <InputText
            label={t("component.addInvoice.description")}
            value={invoice.description}
            onChange={(value) => onChange("description", value)}
          />
          <GridContainer gap="1em" columns="1fr 1fr">
            <InputText
              label={t("component.addInvoice.issuer")}
              value={invoice.issuer}
              onChange={(value) => onChange("issuer", value)}
            />
            <InputText
              label={t("component.addInvoice.issuerId")}
              value={invoice.issuerId}
              onChange={(value) => onChange("issuerId", value)}
            />
            <InputText
              label={t("component.addInvoice.authority")}
              value={invoice.authority}
              onChange={(value) => onChange("authority", value)}
            />
            <InputText
              label={t("component.addInvoice.authorityId")}
              value={invoice.authorityId}
              onChange={(value) => onChange("authorityId", value)}
            />
          </GridContainer>

          <GridContainer gap="1em" columns="1fr 1fr 1fr 0.5fr">
            <InputDate
              id="expiredDate"
              value={(invoice.issuedDate as Date) || new Date()}
              label={t("component.addInvoice.issuedDate")}
              onChange={(value) => onChange("issuedDate", value)}
            />
            <InputTime
              id="time"
              value={(invoice.issuedDate as Date) || new Date()}
              onChange={(value) => onChange("issuedDate", value.value)}
            />
            <InputText
              label={t("component.addInvoice.amount")}
              value={amount}
              onChange={(amount) => setAmount(amount)}
            />
            <Text text={currency} />
          </GridContainer>
          <GridContainer columns="1fr">
            <InputSelect
              label={t("component.addInvoice.requestId")}
              options={[
                ...requestsOptions,
                { value: "", label: "Not Assigned" },
              ]}
              value={invoice.paymentRequestID}
              onChange={(value) => onChange("paymentRequestID", value)}
            />
            <InputSelect
              label={t("component.addInvoice.paymentId")}
              options={[
                ...paymentOptions,
                { value: "", label: "Not Assigned" },
              ]}
              value={invoice.paymentID}
              onChange={(value) => onChange("paymentID", value)}
            />
          </GridContainer>
        </div>
      ) : (
        <AddFile
          id={invoice.id}
          description={invoice.description}
          onSkip={onCancel}
          orderId={orderId}
          open={next === "file"}
          load={load}
        />
      )}
    </SideMenu>
  );
};

export default AddInvoice;
