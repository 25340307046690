import { stylesheet } from "typestyle";

const styles = stylesheet({
  icon: {
    width: "90px",
  },

  noCard: {
    border: "none",
    boxShadow: "none !important",
    padding: 0,
  },
  box: {
    // background: "#F6F8FA",
  },
});

export default styles;
