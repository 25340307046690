import React from "react";

import styles from "./styles";
import { useTranslation } from "react-i18next";
import { TicketingDef } from "../../utils/types";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { InputSwitch } from "../Inputs/InputSwitch";
import { ContainerTitle } from "../ContainerTitle";
import InputRadio from "../Inputs/InputRadio";
import { InputText } from "../Inputs/InputText";

interface Props {
  data: TicketingDef;
  onChange: (data: TicketingDef) => void;
}

const TicketingForm: React.FC<Props> = ({ data, onChange }) => {
  const { t } = useTranslation();

  return (
    <div>
      <FlexContainer padding="0em 0 2em 0" justifyContent="flex-start">
        <InputSwitch
          value={data.customerNameRequired}
          onChange={(customerNameRequired) =>
            onChange({ ...data, customerNameRequired })
          }
        />
        <p>{t("component.offerVariantForm.customerName")}</p>
      </FlexContainer>

      <ContainerTitle
        size="xsmall"
        label={t("component.offerVariantForm.activation")}
        padding="0 0 1em 0"
      />

      <FlexContainer padding="0 0 2em 0" justifyContent="flex-start">
        <div className={styles.radioContainer}>
          <InputRadio
            name="activationType"
            text="At Issuance"
            value="at-issuance"
            onChange={() =>
              onChange({ ...data, activationType: "at-issuance" })
            }
          />
        </div>
        <div className={styles.radioContainer}>
          <InputRadio
            name="activationType"
            defaultChecked={true}
            value="at-date-time"
            text="At Date Time"
            onChange={() =>
              onChange({ ...data, activationType: "at-datetime" })
            }
          />
        </div>
        <div className={styles.radioContainer}>
          <InputRadio
            name="activationType"
            text="At First Use (Check-in)"
            value="at-first-use"
            onChange={() =>
              onChange({ ...data, activationType: "on-first-use" })
            }
          />
        </div>
      </FlexContainer>

      {data.activationType === "on-first-use" ? (
        <InputText
          containerClassname={styles.maxEventsContainer}
          label={t("component.offerVariantForm.maxEvents")}
          value={data.maxEvents}
          onChange={(maxEvents) => onChange({ ...data, maxEvents })}
        />
      ) : null}
      {/* <TicketLifeTime
        data={data.l as LifeTime}
        onChange={(lifeTime) => onChange({ ...data, lifeTime })}
      /> */}

      {/* <PropertyForm
        data={data.properties}
        onChange={(properties) => onChange({ ...data, properties })}
      /> */}
    </div>
  );
};

export default TicketingForm;
