const translation = {
  en: {
    line1: "Línea de dirección 1",
    line2: "Línea de dirección 2",
    city: "Ciudad",
    state: "Estado",
    zipCode: "Código Postal",
    country: "País",
    notes: "Notas",
  },
  es: {
    line1: "Línea de dirección 1",
    line2: "Línea de dirección 2",
    city: "Ciudad",
    state: "Estado",
    zipCode: "Código Postal",
    country: "País",
    notes: "Notas",
  },
  de: {},
};

export default translation;
