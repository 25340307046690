import React from "react";

import CustomError from "../../utils/CustomError";
import { BankAccount } from "../../utils/types";
import { useTranslation } from "react-i18next";
import { SideMenu } from "../SideMenu/SideMenu";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputText } from "../Inputs/InputText";
import { InputSelect } from "../Inputs/InputSelect";

interface Props {
  editing: boolean;
  data: BankAccount;
  onChange: <P extends keyof BankAccount>(
    prop: P,
    value: BankAccount[P]
  ) => void;
  error?: CustomError;
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  loading: boolean;
}

const AccountForm: React.FC<Props> = ({
  data,
  editing,
  onChange,
  onClose,
  open,
  onSave,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <SideMenu width="55%" open={open} onClose={() => onClose()}>
      <FlexContainer
        padding="1.5em 0 3em 0"
        alignItems="center"
        justifyContent="space-between"
      >
        {editing ? (
          <ContainerTitle
            label={t("component.accountList.bankAccount")}
            size="medium"
          />
        ) : (
          <ContainerTitle
            label={t("component.accountList.addOrgAccount")}
            size="medium"
          />
        )}
        <ButtonsContainer>
          <Button
            label={t("component.accountList.cancel")}
            theme="white"
            onClick={() => onClose()}
          />
          <Button
            label={loading ? "..." : t("component.accountList.save")}
            theme="blue"
            onClick={() => {
              if (loading) {
                return;
              }
              onSave();
            }}
          />
        </ButtonsContainer>
      </FlexContainer>
      <GridContainer columns="1fr">
        <InputText
          label={t("component.accountList.bankName")}
          value={data.bank}
          disabled={editing}
          onChange={(value) => onChange("bank", value)}
        />
        <InputText
          label={t("component.accountList.bankAccount")}
          value={data.bankAccount}
          onChange={(value) => onChange("bankAccount", value)}
        />
      </GridContainer>
      <GridContainer gap="1em" columns="1fr 1fr">
        <InputText
          label={t("component.accountList.legalName")}
          value={data.legalName}
          onChange={(value) => onChange("legalName", value)}
        />
        <InputText
          label={t("component.accountList.alias")}
          value={data.alias}
          onChange={(value) => onChange("alias", value)}
        />
        <InputSelect
          label={t("component.accountList.qrCodeType")}
          options={[
            { label: t("component.accountList.none"), value: "none" },
            { label: t("component.accountList.epc"), value: "epc-qrCode" },
            { label: t("component.accountList.codi"), value: "codi" },
          ]}
          value={data.qrCodeType}
          onChange={(value) => onChange("qrCodeType", value)}
        />
      </GridContainer>
    </SideMenu>
  );
};

export default AccountForm;
