const translation = {
  en: {
    successStatusUpdated: "FOP status successfully updated.",
    successBankUpdated: "FOP Bank successfully updated.",
    successFOPCreated: "FOP successfully created.",
    successFOPadded: "FOP successfully added to FOP Set.",
    successDeleted: "FOP successfully deleted.",
    nameUpdated: "FOP Set Name successfully updated.",
    back: "Go Back",
    error: "Error",
    backFOP: "Back to Form of Payments Sets",
    cancel: "Cancel",
    save: "Save",
    delete: "Delete",
    edit: "Edit FOP Set Name",
    deleteWarning: "Are you sure you want to delete this set permanently?",
    fopLabel: "Form of Payments",
    instructions: "No Forms of Payments are added",
    add: "+ Add FOP",
  },
  es: {
    successStatusUpdated: "Estado de FDP actualizado con éxito.",
    successBankUpdated: "FDP Bank se actualizó con éxito.",
    successFOPCreated: "FDP creado con éxito.",
    successFOPadded: "FDP se agregó con éxito al conjunto de FOP",
    successDeleted: "FDP eliminado con éxito.",
    nameUpdated: "Nombre del conjunto FDP actualizado con éxito.",
    back: "Regresar",
    error: "Error",
    backFOP: "Volver a Conjuntos de Formas de Pago",
    cancel: "Cancelar",
    save: "Guardar",
    delete: "Eliminar",
    edit: "Editar",
    deleteWarning:
      "¿Está seguro de que desea eliminar este conjunto de forma permanente?",
    fopLabel: "Forma de Pagos",
    instructions: "No existen Formas de Pagos",
    add: "+ Agregar FDP",
  },
  de: {
    successStatusUpdated: "FOP status successfully updated.",
    successBankUpdated: "FOP Bank successfully updated.",
    successFOPCreated: "FOP successfully created.",
    successFOPadded: "FOP successfully added to FOP Set.",
    successDeleted: "FOP successfully deleted.",
    nameUpdated: "FOP Set Name successfully updated.",
    back: "Go Back",
    error: "Error",
    backFOP: "Back to Form of Payments Sets",
    cancel: "Cancel",
    save: "Save",
    delete: "Delete",
    edit: "Edit",
    deleteWarning: "Are you sure you want to delete this set permanently?",
    fopLabel: "Form of Payments",
    instructions: "No Forms of Payments are added",
    add: "+ Add FOP",
  },
};

export default translation;
