import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/pro-light-svg-icons";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { linkBlue } from "../../../utils/colors";
import CreatePlacement from "../OrderEventForm/CreatePlacement";
import Text from "../../Text";
import {
  FulfillmentEvent,
  Load,
  Placement,
} from "@innomius/ravent-typescript-types";
import { defaultPlacement, defaultW3W } from "../../../services/venues";
import { ReactComponent as WarningIcon } from "../../../assets/white-warning.svg";
import { ReactComponent as WhiteCheckIcon } from "../../../assets/white-check.svg";
import styles from "./styles";
import { Checkbox, Dropdown, Popover, Tag, Tooltip } from "antd";
import FulfillerContent from "../../OrderFulfillment/FulfillerContent";
import AssetModal from "../../AssetModal";
import { Asset } from "../../../utils/types";
import { InputSelect } from "../../Inputs/InputSelect";
import PlacementCard from "../OrderEventList/PlacementCard";
import EditPlacement from "../OrderEventForm/EditPlacement";
import { coordinatesToW3w, w3wToCoordinates } from "../../../services/maps";
import directions from "../../../assets/directions.svg";
import PlacementSideMenu from "../PlacementList/PlacementSideMenu";
import { ReactComponent as WhitePlayIcon } from "../../../assets/white-play.svg";
import { ReactComponent as Play } from "../../../assets/playEvent.svg";
import { ReactComponent as LoadIcon } from "../../../assets/load.svg";
import { ArrowUpOutlined } from "@ant-design/icons";
import { ReactComponent as Mark } from "../../../assets/markEvent.svg";
import { ReactComponent as EventFailed } from "../../../assets/eventFailed.svg";
import { ReactComponent as EventCompleted } from "../../../assets/eventCompleted.svg";
import { ReactComponent as Stop } from "../../../assets/reset.svg";

import { Card } from "../../Card/Card";

import Editor from "@monaco-editor/react";
import LoadModal from "../../LoadModal";
import fontColorContrast from "font-color-contrast";
import { FlexContainer } from "../../FlexContainer/FlexContainer";
import { ContainerTitle } from "../../ContainerTitle";
import { SectionProperty } from "../../Section/SectionProperty";
import { GridContainer } from "../../GridContainer/GridContainer";
import { InputLabel } from "../../Inputs/InputLabel/InputLabel";
import { ButtonsContainer } from "../../ButtonsContainer";

var utc = require("dayjs/plugin/utc");

dayjs.extend(utc);

interface Props {
  onClose: () => void;
  id: string;
  loading?: string;
  event: FulfillmentEvent;
  onDeletePlacement: (id: string) => void;
  onEditPlacement: (placement: Placement) => void;
  path: string;
  onChangeEvent: <P extends keyof FulfillmentEvent>(
    prop: P,
    value: FulfillmentEvent[P]
  ) => void;
  onSaveLoad: (data: Load[]) => void;
  teams: { label: string; value: string }[];
  updateFulfiller: (eventId: string, fulfillerId: string) => void;
  updateTeam: (eventId: string, teamId: string) => void;
  updateAsset: (eventId: string, assetId: string) => void;
  assets: Asset[];
  saveLoc: (p: Placement, type: string, group: string) => void;
  onChangeEventState: (eventId: string) => void;
  venues: { value: string; label: string }[];
  fulfillerName: string;
  loadEvent: () => void;
  setNew: (id: string, state: string) => void;
  notShow?: boolean;
}

const OrderEventDetail: React.FC<Props> = ({
  event,
  onClose,
  onDeletePlacement,
  onEditPlacement,
  onSaveLoad,
  teams,
  updateFulfiller,
  updateTeam,
  updateAsset,
  assets,
  saveLoc,
  venues,
  fulfillerName,
  setNew,
  notShow,
  loadEvent,
}) => {
  const [openPlacement, setOpenPlacement] = useState<string>("");
  const filterMakers = event.placements.filter(
    (item) => item.coordinates?.latitude || item.coordinates?.longitude
  );

  const [locationW3W, setLocationW3W] = useState(defaultW3W);

  const [open, setOpen] = useState<string>("");
  // const [map, setMap] = useState<boolean>(false);
  // const [coords, setCoords] = useState<{ lat: number; lng: number }>({
  //   lat: 0,
  //   lng: 0,
  // });
  const [placement, setPlacement] = useState(defaultPlacement);

  const [openModal, setOpenModal] = useState<boolean>(false);

  const markers = filterMakers.map((item) => ({
    lat: parseFloat(item.coordinates?.latitude || "0"),
    lng: parseFloat(item.coordinates?.longitude || "0"),
    title: item.name || "",
  }));

  const { t } = useTranslation();

  const formatOptionKey = (key: string, color: string) => {
    if (key.includes("::")) {
      const [firstPart, secondPart] = key.split("::");
      return (
        <Tooltip title={firstPart}>
          <Tag
            style={{
              color: color
                ? color === "blue"
                  ? "blue"
                  : fontColorContrast(color, 0.5)
                : "blue",
            }}
            color={color || "blue"}
          >
            {secondPart}
          </Tag>
        </Tooltip>
      );
    } else {
      return <Tag color="blue">{key}</Tag>;
    }
  };

  const [edit, setEdit] = useState(false);

  const items = event.loads.map((item, key) => ({
    label: `${item.name}}`,
    key: key,
    icon: <LoadIcon width="2em" />,
  }));

  const menuProps = {
    items,
    onClick: () => {},
  };

  const load = useCallback(async () => {
    if (placement.coordinates?.latitude) {
      const res = await coordinatesToW3w(
        parseFloat(placement.coordinates.latitude || ""),
        parseFloat(placement.coordinates.longitude || "")
      );
      setLocationW3W(res);
      onChange("w3w", res.words);
      return;
    }
    if (placement.w3w) {
      const res = await w3wToCoordinates(placement.w3w);
      setLocationW3W(res);
      onChange("coordinates", {
        latitude: res.coordinates.lat.toString(),
        longitude: res.coordinates.lng.toString(),
      });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);
  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    loadEvent();
  }, [loadEvent]);

  const onChange = <P extends keyof Placement>(
    prop: P,
    value: Placement[P]
  ) => {
    setPlacement({ ...placement, [prop]: value });
  };

  return (
    <div>
      {open === "directions" ? (
        <PlacementSideMenu
          onClose={() => setOpen("")}
          open={open === "directions"}
          markers={markers}
        />
      ) : null}

      <EditPlacement
        onSave={() => onEditPlacement(placement)}
        onDelete={() => onDeletePlacement(placement.id)}
        open={edit}
        onChange={setPlacement}
        locW3W={locationW3W}
        setLocationW3W={setLocationW3W}
        placement={placement}
        saveLoc={saveLoc}
        onClose={() => setEdit(false)}
      />
      <LoadModal
        data={event.loads}
        onSave={(load) => onSaveLoad(load)}
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
      <AssetModal
        assetId={event.assetId}
        assets={assets}
        updateAsset={(value: string) => updateAsset(event.id, value)}
        open={open === "asset"}
        onClose={() => setOpen("")}
      />
      <CreatePlacement
        saveLoc={saveLoc}
        venues={venues}
        data={defaultPlacement(event.placements.length)}
        setOpen={(value) => {
          if (!value) {
            setOpenPlacement("");
          }
        }}
        index={event.placements.length}
        onCreatePlacement={(placement) => {
          onEditPlacement(placement);
          setOpen("");
          setOpenPlacement("");
        }}
        open={openPlacement === "placement"}
      />

      <div>
        <div className={styles.containerDetail}>
          <FlexContainer padding="0 0 1em 0" justifyContent="space-between">
            <ContainerTitle
              size="xsmall"
              label={t("component.orderLocation.details")}
            />
            <FlexContainer>
              <FulfillerContent
                fulfillerId={event.fulfillerId || ""}
                orderlineId={event.id}
                fulfillerName={fulfillerName || "-"}
                team={event.fulfillmentTeamId}
                teams={teams}
                updateFulfiller={(id: string, fId) => updateFulfiller(id, fId)}
                updateTeam={(id: string, teamId) => updateTeam(id, teamId)}
              />
              <div>
                <div style={{ marginRight: "0.5em" }}>
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Popover
                      placement="left"
                      trigger="click"
                      open={open === "mark"}
                      content={
                        <div>
                          <Text
                            padding="0 0 0.5em 0"
                            color="#000"
                            fontWeight={600}
                            text={"Mark event as:"}
                          />
                          <FlexContainer justifyContent="space-evenly">
                            <div
                              className={styles.yellow}
                              style={{ marginRight: "0.5em" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setNew(event.id, "failed");
                                setOpen("");
                              }}
                            >
                              <WarningIcon
                                style={{ marginRight: "0.2em" }}
                                className={styles.smallIcon}
                              />

                              <Text
                                color="#fff"
                                fontWeight={600}
                                text={"Failed"}
                              />
                            </div>
                            <div
                              className={styles.blue}
                              onClick={(e) => {
                                e.stopPropagation();
                                setNew(event.id, "completed");
                                setOpen("");
                              }}
                            >
                              <WhiteCheckIcon
                                style={{ marginRight: "0.2em" }}
                                className={styles.smallIcon}
                              />

                              <Text
                                color="#fff"
                                fontWeight={600}
                                text={"Fulfilled"}
                              />
                            </div>
                          </FlexContainer>
                        </div>
                      }
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpen("mark");
                        }}
                      >
                        {event.state === "pending" ||
                        event.state === "running" ? (
                          <FlexContainer justifyContent="flex-start">
                            <Mark
                              style={{ marginRight: "0.2em" }}
                              className={styles.icon}
                            />
                            <Text text="Mark Event" />
                          </FlexContainer>
                        ) : (
                          <FlexContainer justifyContent="flex-end">
                            {event.state === "completed" ? (
                              <EventCompleted
                                style={{ marginRight: "0.2em" }}
                                className={styles.icon}
                              />
                            ) : null}
                            {event.state === "failed" ? (
                              <EventFailed
                                style={{ marginRight: "0.2em" }}
                                className={styles.icon}
                              />
                            ) : null}
                            <Text
                              text={`${t("component.orderLocation.event")} ${
                                event.state
                              }`}
                            />
                          </FlexContainer>
                        )}
                      </div>
                    </Popover>
                    {event.state === "completed" ? null : event.state ===
                      "running" ? (
                      <Popover
                        placement="left"
                        trigger="click"
                        open={open === "pending"}
                        content={
                          <div>
                            <Text
                              padding="0 0 0.5em 0"
                              color="#000"
                              fontWeight={600}
                              text={"Stop running this event:"}
                            />

                            <div
                              className={styles.white}
                              onClick={(e) => {
                                e.stopPropagation();
                                setNew(event.id, "pending");
                                setOpen("");
                              }}
                            >
                              <WhitePlayIcon
                                style={{ marginRight: "0.2em" }}
                                className={styles.smallIcon}
                              />
                              <Text
                                color="#333"
                                fontWeight={600}
                                text={"Reset"}
                              />
                            </div>
                          </div>
                        }
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpen("pending");
                          }}
                        >
                          <FlexContainer justifyContent="flex-start">
                            <Stop
                              style={{ marginRight: "0.2em" }}
                              className={styles.icon}
                            />

                            <Text text="Reset" />
                          </FlexContainer>
                        </div>
                      </Popover>
                    ) : (
                      <Popover
                        placement="left"
                        trigger="click"
                        open={open === "run"}
                        content={
                          <div>
                            <Text
                              padding="0 0 0.5em 0"
                              color="#000"
                              fontWeight={600}
                              text={"Start running this event:"}
                            />

                            <div
                              className={styles.green}
                              onClick={(e) => {
                                e.stopPropagation();
                                setNew(event.id, "running");
                                setOpen("");
                              }}
                            >
                              <WhitePlayIcon
                                style={{ marginRight: "0.2em" }}
                                className={styles.smallIcon}
                              />
                              <Text
                                color="#fff"
                                fontWeight={600}
                                text={"Run Now"}
                              />
                            </div>
                          </div>
                        }
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpen("run");
                          }}
                        >
                          <FlexContainer justifyContent="flex-start">
                            <Play
                              style={{ marginRight: "0.2em" }}
                              className={styles.icon}
                            />

                            <Text text="Run Now" />
                          </FlexContainer>
                        </div>
                      </Popover>
                    )}
                  </div>
                </div>
              </div>
            </FlexContainer>
          </FlexContainer>

          {event.placements.length ? (
            <div style={{ marginTop: "1em", position: "relative" }}>
              <div>
                <div
                  className={styles.marker}
                  style={{ display: open === "directions" ? "none" : "block" }}
                >
                  {event.placements.find(
                    (item) =>
                      item.coordinates?.latitude && event.placements.length > 1
                  ) ? (
                    <img
                      style={{ cursor: "pointer" }}
                      className={styles.icon}
                      src={directions}
                      alt=""
                      onClick={() => {
                        setOpen("directions");
                      }}
                    />
                  ) : null}
                </div>
                <div style={{ padding: "0 1em", width: "95%" }}>
                  {event.placements.map((item, i) => (
                    <PlacementCard
                      edit
                      lastIndex={i === event.placements.length - 1}
                      key={item.id}
                      data={item}
                      onClickEdit={() => {
                        setPlacement(item);
                        setEdit(true);
                      }}
                    />
                  ))}
                </div>

                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpenPlacement("placement")}
                >
                  <Text
                    padding="0.5em 0 0 0"
                    color="#858D8F"
                    fontWeight={600}
                    text={t("component.orderLocation.addPlacement")}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div style={{ marginTop: "1em", position: "relative" }}>
              <Card>
                <Text
                  padding="1em"
                  text={t("component.orderLocation.noPlacements")}
                />
              </Card>
            </div>
          )}

          <GridContainer
            alignItems="flex-start"
            justifyContent="flex-start"
            columns="1fr 1fr"
            gap="1em"
            padding="2em 0 0 0"
          >
            <FlexContainer
              alignItems="flex-start"
              justifyContent="flex-start"
              flexDirection="column"
            >
              <div className={styles.label}>Tags</div>

              <div className={styles.center}>
                {event.colorizedTags.length >= 1 ? (
                  <></>
                ) : (
                  <div>{t("component.orderLocation.notTags")}</div>
                )}
                {event.colorizedTags?.map(
                  (
                    item: {
                      key: string;
                      settings: { tagClassification: string; color: string };
                    },
                    index
                  ) => (
                    <div style={{ margin: "0 0.2em 0.2em 0" }} key={index}>
                      {formatOptionKey(
                        item.key,
                        item.settings && item.settings.color
                          ? item.settings.color
                          : "blue"
                      )}
                    </div>
                  )
                )}
              </div>

              {/* <div className={styles.center}>
                {event.tags.length > 1 ? (
                  event.tags?.map((item, index) => (
                    <div style={{ margin: "0 0.5em 0.5em 0" }} key={index}>
                      {formatOptionKey(item)}
                    </div>
                  ))
                ) : (
                  <div>{t("component.orderLocation.notTags")}</div>
                )}
              </div> */}
            </FlexContainer>

            <SectionProperty value={event.notes || "-"} label={"Notes"} />

            <GridContainer columns="1fr 1fr 1fr">
              <InputSelect
                containerClassname={styles.select}
                value={event.assetId}
                placeholder={event.assetName ? event.assetName : "Set Asset"}
                onClick={() => {
                  setOpen("asset");
                }}
                label={t("component.orderLocation.asset")}
              />
            </GridContainer>

            <FlexContainer
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <InputLabel
                label={`${t("component.orderLocation.load")} (${
                  event.loads.length
                })`}
              />
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <Dropdown.Button
                  className={styles.width}
                  menu={menuProps}
                  onClick={() => setOpenModal(true)}
                  icon={<ArrowUpOutlined />}
                >
                  {t("component.orderLocation.addEdit")}
                </Dropdown.Button>
              </div>
            </FlexContainer>
          </GridContainer>
          <Checkbox checked={event.considerTime}>
            {t("component.orderLocation.considerTime")}
          </Checkbox>
          {event.data ? (
            <div style={{ marginTop: "1em" }}>
              <InputLabel label="Data" />
              <Editor
                className={styles.editorBox}
                height="100%"
                defaultLanguage="json"
                value={JSON.stringify(event.data)}
                options={{
                  readOnly: true,
                  selectOnLineNumbers: true,
                  scrollbar: {
                    vertical: "hidden",
                  },
                  overviewRulerLanes: 0,
                  hideCursorInOverviewRuler: true,
                  overviewRulerBorder: false,
                  foldingHighlight: false,
                }}
              />
            </div>
          ) : null}
          {/* <Text
            padding="1em 0 0 0"
            fontWeight={700}
            text={t("component.orderLocation.related")}
          />
          {event.relatedEvents.length > 0 ? (
            <div>
              {event.relatedEvents.map((item) => (
                <div className={styles.link}>
                  <FontAwesomeIcon icon={faLink as IconProp} color={linkBlue} />
                  <Text
                    text={item}
                    padding="0 0 0 0.5em"
                    color="#005EA2"
                    onClick={() => history.push(`/orders/${item}`)}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div>-</div>
          )} */}
        </div>
      </div>

      {notShow ? null : (
        <ButtonsContainer padding="1em 0 0 0" justifyContent="flex-end">
          <div className={styles.closeButton} onClick={() => onClose()}>
            {t("component.paymentDetail.close")}
            <FontAwesomeIcon
              icon={faChevronUp as IconProp}
              className={styles.chevron}
              color={linkBlue}
            />
          </div>
        </ButtonsContainer>
      )}
    </div>
  );
};

export default OrderEventDetail;
