import { stylesheet } from "typestyle";
import { black } from "../../utils/colors";

const styles = stylesheet({
  wrapperCard: {
    padding: "2em",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1em",
  },
  icon: {
    width: 23,
    cursor: "pointer",
    marginRight: "1em",
  },
  iconQuit: {
    width: "2em",
  },
  iconContainerEdit: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center !important",
    cursor: "pointer",
  },
  iconShare: {},
  spaceBetween: {
    color: black,
    fontStyle: "normal",
    justifyContent: "flex-start",
    padding: "1em",
    display: "flex",
    alignItems: "center",
    marginRight: "0.5em",
    fontSize: "0.9em",
    flexWrap: "wrap",
  },
  width: {
    width: "100%",
  },
});

export default styles;
