const translation = {
  en: {
    userInfo: "User Information",
    name: "First Name",
    lastName: "Last Name",
    phone: "Phone Number",
    email: "Email",
    selectRole: "Select Role",
    assignVenue: "Please select a Venue to assign it to User.",
    assign: "Assign",
    externalId: "External ID",
    venue: "Venue",
    superUser: "Super User",
    superUserInfo:
      "This role has full access to all system features, settings, and data, overriding permissions of any other role. Super Users can manage, view, and modify all user accounts, settings, and system configurations, regardless of other users’ permissions. Use this access responsibly, as actions taken under this role affect all users and system components.”",
    instructions:
      "Only if your role grants you the proper permission, are you allowed to change a user's role.",
    cancel: "Cancel",
    roles: "Roles",
    assignTeam: "Please select a Team to associate to User",
    teams: "Teams",
    loginWithEmail: "Login with Email",
    loginWithPhone: "Login with Phone",
    userType: "User Type",
    adminRole: "Admin Role",
    noRoles:
      "No roles are configured in your organization. Please create roles in the Company Section.",
  },
  es: {
    superUser: "Super Usuario",
    superUserInfo:
      "Este rol tiene acceso completo a todas las funciones, configuraciones y datos del sistema, anulando los permisos de cualquier otro rol. Los Super Usuarios pueden gestionar, ver y modificar todas las cuentas de usuario, configuraciones y configuraciones del sistema, independientemente de los permisos de otros usuarios. Utilice este acceso de manera responsable, ya que las acciones tomadas bajo este rol afectan a todos los usuarios y componentes del sistema.",
    adminRole: "Rol de Administrador",
    externalId: "ID Externo",
    userType: "Tipo de Usuario",
    loginWithPhone: "Iniciar sesión con teléfono",
    loginWithEmail: "Iniciar sesión con correo electrónico",
    assignTeam: "Selecctionar Equipo para asociarlo al usuario",
    teams: "Equipos",
    userInfo: "Información del usuario",
    name: "Nombres",
    roles: "Roles",
    noRoles:
      "No hay roles configurados en tu organización. Por favor crea roles en la sección de Compañía.",
    lastName: "Apellidos",
    phone: "Número de teléfono",
    email: "Correo electrónico",
    selectRole: "Seleccionar rol",
    assignVenue: "Asignar lugar",
    assign: "Asignar",
    venue: "Venue",
    instructions:
      "Solo si tu rol te otorga el permiso adecuado, puedes cambiar el rol de un usuario",
    cancel: "Cancelar",
  },
  de: {},
};

export default translation;
