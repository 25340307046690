import React from "react";

import { useHistory, useLocation } from "react-router-dom";

import { FulfillmentDefinition } from "@innomius/ravent-typescript-types";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import DetailForm from "../FulfillmentForm/DetailForm";
import CustomError from "../../utils/CustomError";
import { SideMenu } from "../SideMenu/SideMenu";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { EditableTitle } from "../EditableTitle";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { InputError } from "../Inputs/InputError/InputError";

interface Props {
  fulfillment: FulfillmentDefinition;
  onChangeFulfillment: <P extends keyof FulfillmentDefinition>(
    prop: P,
    value: FulfillmentDefinition[P]
  ) => void;
  path: string;
  onCreate: () => void;
  onClose: () => void;
  loadingF: boolean;
  validateError: CustomError | undefined;
}

const CreateFulfillment: React.FC<Props> = ({
  fulfillment,
  onChangeFulfillment,
  path,
  onCreate,
  onClose,
  loadingF,
  validateError,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <SideMenu
      onClose={() => {
        history.push(`${path}`);
        onClose();
      }}
      open={location.pathname === `/fulfillments/fulfillmentStates/new`}
    >
      <FlexContainer
        padding="1em 0 2em 0"
        justifyContent="space-between"
        alignItems="center"
      >
        <EditableTitle
          value={fulfillment.name}
          placeholder={t(
            "container.createFulfillment.fulfillmentNamePlaceholder"
          )}
          onChange={(value) => onChangeFulfillment("name", value)}
          error={
            !fulfillment.name &&
            get(validateError, "errors.fulfillment-name", "")
          }
        />

        <ButtonsContainer>
          <Button
            theme="white"
            label={t("container.createFulfillment.cancel")}
            onClick={() => {
              onClose();
              history.push(`${path}`);
            }}
          />
          <Button
            label={loadingF ? "..." : t("container.createFulfillment.save")}
            onClick={() => onCreate()}
          />
        </ButtonsContainer>
      </FlexContainer>
      {}

      <InputError
        error={
          !fulfillment.name && get(validateError, "errors.fulfillment-name", "")
        }
      />

      <DetailForm data={fulfillment} onChange={onChangeFulfillment} />
    </SideMenu>
  );
};

export default CreateFulfillment;
