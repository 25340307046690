import React, { useState } from "react";

import styles from "./styles";
import { useTranslation } from "react-i18next";
import { FulfillerData } from "../../services/entities";
import { useLocation } from "react-router-dom";
import { SideMenu } from "../SideMenu/SideMenu";
import Text from "../Text";
import chatIcon from "../../assets/chat.svg";
import { ContainerTitle } from "../ContainerTitle";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { Button } from "../Button";
import { GridContainer } from "../GridContainer/GridContainer";
import { Checkbox, DatePicker, Radio } from "antd";
import eventIcon from "../../assets/sidebar/cal.svg";
// import mapIcon from "../../assets/mapBlack.svg";
// import listIcon from "../../assets/view-list.svg";
import { Checkpoint } from "../../utils/types";
import { DayHeatMapResponse } from "../../services/events";
import dayjs from "dayjs";
import DailyHeatMap from "./DailyHeatmap.tsx";
import MapWithRoutes, {
  MarkerData,
  MarkerStateData,
} from "../MapWithMultipleMarkers/MapWithRoutes";
import { Card } from "../Card/Card";
import { FulfillmentEvent } from "@innomius/ravent-typescript-types";
import PlannedList from "./PlannedList";

interface Props {
  data: FulfillerData[];
  dataFulfillerRange: FulfillerData[];
  name: string;
  fulfillerId: string;
  fulfillmentTeamId: string;
  onClose: () => void;
  heatmap?: DayHeatMapResponse;
  from: string;
  to: string;
  timezone: string;
  checkpoints: Checkpoint[];
  markers: MarkerData[];
  events: FulfillmentEvent[];
  loading: boolean;
  error: string;
  changeDay: (date: string) => void;
  setFrom: (date: string) => void;
  setTo: (date: string) => void;
  onChangeSortOrder?: (sort: string) => void;
  setSelectedEvents: (events: FulfillmentEvent[]) => void;
  selectedEvents: FulfillmentEvent[];
  stateMarkers: MarkerStateData[];
}

const FulfillerItem: React.FC<Props> = ({
  fulfillerId,
  fulfillmentTeamId,
  onClose,
  name,
  data,
  heatmap,
  from,
  to,
  timezone,
  checkpoints,
  dataFulfillerRange,
  markers,
  events,
  error,
  loading,
  setFrom,
  setTo,
  changeDay,
  onChangeSortOrder,
  setSelectedEvents,
  selectedEvents,
  stateMarkers,
}) => {
  const weekdays = ["S", "M", "T", "W", "T", "F", "S"];

  const { t } = useTranslation();
  const [dateFilter, setDateFilter] = useState<string>(
    dayjs(new Date()).format("YYYY-MM-DD")
  );
  const [view, setView] = useState<string>("list");

  const [checkboxes, setCheckboxes] = useState<string>("planned,real");

  const location = useLocation();

  return (
    <SideMenu
      open={
        location.pathname ===
        `/overview/teams/${fulfillmentTeamId}/fulfillers/${fulfillerId}`
      }
      width={view === "list" ? "95%" : "70%"}
      onClose={() => {
        onClose();
        setView("list");
        setSelectedEvents([]);
        setCheckboxes("");
      }}
    >
      <GridContainer gap="1em" columns={view === "list" ? "1.5fr 1fr" : "1fr"}>
        <div className={styles.container}>
          <FlexContainer justifyContent="space-between">
            <ContainerTitle size="medium" label={name} />
            <Button
              icon={chatIcon}
              theme="white"
              label={t("component.fulfillerOverviewList.sendMessage")}
            />
          </FlexContainer>
          <div className={styles.background}>
            <GridContainer gap="1em" columns="1.5fr 1fr">
              <div>
                <FlexContainer justifyContent="flex-start">
                  <DatePicker.RangePicker
                    style={{ width: "60%" }}
                    format="YYYY-MM-DD"
                    value={[from ? dayjs(from) : null, to ? dayjs(to) : null]}
                    onChange={(_date, dateString) => {
                      setFrom(dateString[0]);
                      setTo(dateString[1]);
                    }}
                  />
                </FlexContainer>

                {heatmap && (
                  <DailyHeatMap
                    onClick={(date) => {
                      setDateFilter(date);
                      changeDay(date);
                    }}
                    counts="events"
                    heatmap={heatmap?.heatmap}
                    dateRange={{ start: from, end: to }}
                    dayLabels={weekdays}
                    range={heatmap?.ranges}
                  />
                )}
              </div>
              <div>
                <FlexContainer
                  padding="0 0 0.5em 0"
                  justifyContent="flex-start"
                >
                  <img src={eventIcon} alt="" className={styles.icon} />
                  <Text
                    padding="0 0 0em 0.5em"
                    fontSize="1.3em"
                    fontWeight={700}
                    text={t("component.fulfillerOverviewList.events")}
                  />
                </FlexContainer>
                <Card containerClassname={styles.noPadding}>
                  <div className={styles.lineDown}>
                    <Text text={t("component.fulfillerOverviewList.pending")} />
                    <Text
                      padding="0 0 0 0.5em"
                      color="#000"
                      fontSize="1.3em"
                      fontWeight={600}
                      text={dataFulfillerRange
                        ?.filter((item) => item.state === "pending")
                        .length?.toString()}
                    />{" "}
                  </div>
                  <div className={styles.lineDown}>
                    <Text text={t("component.fulfillerOverviewList.running")} />
                    <Text
                      padding="0 0 0 0.5em"
                      color="#000"
                      fontSize="1.3em"
                      fontWeight={600}
                      text={dataFulfillerRange
                        ?.filter((item) => item.state === "running")
                        .length?.toString()}
                    />{" "}
                  </div>
                  <div className={styles.lineDown}>
                    <Text text={t("component.fulfillerOverviewList.failed")} />
                    <Text
                      padding="0 0 0 0.5em"
                      color="#000"
                      fontSize="1.3em"
                      fontWeight={600}
                      text={dataFulfillerRange
                        ?.filter((item) => item.state === "failed")
                        .length?.toString()}
                    />{" "}
                  </div>

                  <div className={styles.lineDown}>
                    <Text
                      text={t("component.fulfillerOverviewList.fulfilled")}
                    />
                    <Text
                      padding="0 0 0 0.5em"
                      color="#000"
                      fontSize="1.3em"
                      fontWeight={600}
                      text={dataFulfillerRange
                        ?.filter((item) => item.state === "completed")
                        .length?.toString()}
                    />{" "}
                  </div>
                  <div className={styles.noLine}>
                    <Text text={t("component.fulfillerOverviewList.total")} />
                    <Text
                      padding="0 0 0 0.5em"
                      color="#000"
                      fontSize="1.3em"
                      fontWeight={600}
                      text={dataFulfillerRange.length?.toString()}
                    />{" "}
                  </div>
                </Card>
              </div>
            </GridContainer>
          </div>
          <FlexContainer padding="1em 0 0 0" justifyContent="space-between">
            <ContainerTitle
              size="medium"
              label={`${dayjs(dateFilter)
                .tz(timezone)
                .format("dddd DD MMM YYYY")}`}
            />
            <Radio.Group
              value={dateFilter}
              onChange={(value) => {
                setDateFilter(value.target.value);
                changeDay(value.target.value);
              }}
            >
              <Radio.Button
                value={dayjs(new Date()).format("YYYY-MM-DD")}
                onClick={() => {
                  setDateFilter(dayjs(new Date()).format("YYYY-MM-DD"));
                  changeDay(dayjs(new Date()).format("YYYY-MM-DD"));
                }}
              >
                {t("component.fulfillerOverviewList.today")}
              </Radio.Button>
              <Radio.Button
                value={dayjs(new Date()).add(1, "day").format("YYYY-MM-DD")}
                onClick={() => {
                  setDateFilter(
                    dayjs(new Date()).add(1, "day").format("YYYY-MM-DD")
                  );
                  changeDay(
                    dayjs(new Date()).add(1, "day").format("YYYY-MM-DD")
                  );
                }}
              >
                {t("component.fulfillerOverviewList.tomorrow")}
              </Radio.Button>
            </Radio.Group>
          </FlexContainer>
          <FlexContainer justifyContent="space-between">
            <div style={{ width: "60%" }}>
              <GridContainer
                padding="2em 0"
                gap="1em"
                columns="0.5fr 0.5fr 0.5fr 0.5fr 0.5fr"
              >
                <div className={styles.separation}>
                  <Text
                    color="#000"
                    text={t("component.fulfillerOverviewList.pending")}
                  />
                  <Text
                    padding="0 0 0 0.5em"
                    color="#000"
                    fontSize="1.3em"
                    fontWeight={600}
                    text={data
                      ?.filter((item) => item.state === "pending")
                      .length?.toString()}
                  />
                </div>
                <div className={styles.separation}>
                  <Text
                    color="#000"
                    text={t("component.fulfillerOverviewList.running")}
                  />
                  <Text
                    padding="0 0 0 0.5em"
                    color="#000"
                    fontSize="1.3em"
                    fontWeight={600}
                    text={data
                      ?.filter((item) => item.state === "running")
                      .length?.toString()}
                  />
                </div>
                <div className={styles.separation}>
                  <Text
                    color="#000"
                    text={t("component.fulfillerOverviewList.failed")}
                  />
                  <Text
                    padding="0 0 0 0.5em"
                    color="#000"
                    fontSize="1.3em"
                    fontWeight={600}
                    text={data
                      ?.filter((item) => item.state === "failed")
                      .length?.toString()}
                  />
                </div>
                <div className={styles.separation}>
                  <Text
                    color="#000"
                    text={t("component.fulfillerOverviewList.fulfilled")}
                  />
                  <Text
                    padding="0 0 0 0.5em"
                    color="#000"
                    fontSize="1.3em"
                    fontWeight={600}
                    text={data
                      ?.filter((item) => item.state === "completed")
                      .length?.toString()}
                  />
                </div>
                <div
                  style={{ borderRight: "none" }}
                  className={styles.separation}
                >
                  <Text
                    color="#000"
                    text={t("component.fulfillerOverviewList.total")}
                  />
                  <Text
                    padding="0 0 0 0.5em"
                    color="#000"
                    fontSize="1.3em"
                    fontWeight={600}
                    text={data.length?.toString()}
                  />
                </div>
              </GridContainer>
            </div>
            {view === "list" && (
              <Checkbox.Group
                value={checkboxes.split(",")}
                onChange={(checkedValues) =>
                  setCheckboxes(checkedValues.join(","))
                }
              >
                <Checkbox value="planned">
                  <Text
                    text={t("component.fulfillerOverviewList.plannedRoute")}
                  />
                </Checkbox>
                {/* <Checkbox value="states">
                  <Text text={t("component.fulfillerOverviewList.states")} />
                </Checkbox> */}
                <Checkbox value="real">
                  <Text
                    text={`${t("component.fulfillerOverviewList.realRoute")} (${
                      checkpoints?.length
                    })`}
                  />
                </Checkbox>
              </Checkbox.Group>
            )}
          </FlexContainer>
          {/* <FlexContainer justifyContent="space-between">
            <Button
              icon={view === "map" ? mapIcon : listIcon}
              theme="white"
              onClick={() => setView(view === "map" ? "list" : "map")}
              label={
                view === "map"
                  ? t("component.fulfillerOverviewList.map")
                  : t("component.fulfillerOverviewList.list")
              }
            />
          </FlexContainer> */}

          <PlannedList
            fulfillmentStates={[]}
            setSelected={setSelectedEvents}
            selected={selectedEvents}
            error={error}
            onChangeSortOrder={onChangeSortOrder}
            data={events}
            loading={loading}
          />
        </div>
        <div
          style={{
            position: "sticky",
            height: "90vh",
            top: 0,
            display: view === "list" ? "block" : "none",
          }}
        >
          <MapWithRoutes
            stateMarkers={stateMarkers || []}
            startCenter={
              checkboxes.includes("planned") && markers[0]
                ? {
                    lat: markers[0].coordinates.latitude,
                    lng: markers[0].coordinates.longitude,
                  }
                : checkboxes.includes("real") && checkpoints[0]
                ? {
                    lat: parseFloat(checkpoints[0].geolocation.latitude),
                    lng: parseFloat(checkpoints[0].geolocation.longitude),
                  }
                : undefined
            }
            polylineColor="red"
            markers={checkboxes.includes("planned") ? markers : []}
            checkpoints={
              checkboxes.includes("real")
                ? checkpoints.map((item) => ({
                    lat: parseFloat(item.geolocation.latitude),
                    lng: parseFloat(item.geolocation.longitude),
                    timestamp: item.checkpointDate,
                  }))
                : []
            }
          />
        </div>
      </GridContainer>
    </SideMenu>
  );
};

export default FulfillerItem;
