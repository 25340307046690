
import React, { useState } from "react";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { Modal } from "../Modal/Modal";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { InputError } from "../Inputs/InputError/InputError";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { InputText } from "../Inputs/InputText";

interface Props {
  onClose: () => void;
  open: boolean;
  onSave: (value: string) => void;
  loading?: boolean;
  error?: string;
}

const SaveFilterModal: React.FC<Props> = ({
  onClose,
  onSave,
  open,
  error,
  loading,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");

  return (
    <div className={styles.container}>
      <Modal
        width="600px"
        buttons={
          <div>
            <ButtonsContainer justifyContent="flex-end">
              <Button
                onClick={onClose}
                theme="white"
                label={t("component.saveFilterModal.close")}
              />
              <Button
                onClick={() => {
                  onSave(value);
                  onClose();
                  setValue("");
                }}
                label={loading ? "..." : t("component.saveFilterModal.save")}
              />
            </ButtonsContainer>
            {error ? (
              <FlexContainer justifyContent="flex-end" padding="0.5em 1em 0 0">
                <InputError error={error} />
              </FlexContainer>
            ) : null}
          </div>
        }
        open={open}
        onClose={onClose}
      >
        <ContainerTitle
          size="medium"
          padding="0 0 1em 0"
          label={t("component.saveFilterModal.saveFilter")}
        />
        <InputText
          value={value}
          onChange={(value) => setValue(value)}
          label={t("component.saveFilterModal.name")}
        />
      </Modal>
    </div>
  );
};

export default SaveFilterModal;
