import * as React from "react";
import { classes } from "typestyle";
import { InputLabel } from "../InputLabel/InputLabel";
import { InputError } from "../InputError/InputError";
import styles from "./styles.module.css";

export interface InputTextareaProps {
  id?: string;
  value: string | undefined;
  label?: string;
  placeholder?: string;
  onChange?: (value: string, id?: string) => void;
  disabled?: boolean;
  readOnly?: boolean;
  onClick?: (event: React.MouseEvent<HTMLTextAreaElement, MouseEvent>) => void;
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  containerClassname?: string;
  inputClassname?: string;
  error?: false | "" | string[] | string;
  showError?: boolean;
  showLabel?: boolean;
  actions?: React.ReactChild;
}

export const InputTextarea: React.FC<InputTextareaProps> = (props) => {
  const {
    id,
    value = "",
    onChange,
    placeholder,
    disabled,
    readOnly,
    containerClassname,
    inputClassname,
    onFocus,
    onBlur,
    onClick,
    error,
    showError,
    showLabel,
    label,
    actions,
  } = props;

  const change = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(e.target.value, id);
    }
  };

  return (
    <div className={classes(styles.container, containerClassname)}>
      {label && <InputLabel showLabel={showLabel} label={label} id={id} />}
      <div className={actions ? styles.actionContainer : styles.inputContainer}>
        {readOnly || disabled ? (
          <div
            className={classes(styles.readOnlyContainer, inputClassname)}
            dangerouslySetInnerHTML={{ __html: value.replaceAll("\n", "<br>") }}
          />
        ) : (
          <textarea
            id={id}
            className={classes(
              actions ? styles.inputAction : styles.input,
              inputClassname
            )}
            value={value}
            onChange={change}
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readOnly}
            onFocus={onFocus}
            onBlur={onBlur}
            onClick={onClick}
            autoComplete="off"
            style={{
              borderColor: error ? "red" : "",
              height: "8em",
            }}
          />
        )}
        <div>{actions || null}</div>
      </div>
      <InputError error={error} showError={showError} />
    </div>
  );
};

InputTextarea.defaultProps = {
  showError: true,
  showLabel: true,
};
