import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import markerSVG from "../../assets/marker.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { linkBlue } from "../../utils/colors";
import styles from "./styles";
import Text from "../Text";
import { FlexContainer } from "../FlexContainer/FlexContainer";

interface MarkerData {
  lat: number;
  lng: number;
  name: string;
  clicked: () => void;
  group: string;
  groupCode: string;
  line1: string;
  reference: string;
  externalId: string;
  // Add a 'title' property to MarkerData
}

interface MapMarkersProps {
  markers: MarkerData[];
}

const MapMarkers: React.FC<MapMarkersProps> = ({ markers }) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [selectedMarker, setSelectedMarker] = useState<MarkerData | null>(null);

  const containerStyle = {
    width: "100%",
    height: "350px",
  };

  const defaultCenter = { lat: 21.0417033, lng: -86.8740062 };

  const onLoad = (mapInstance: google.maps.Map) => {
    setMap(mapInstance);
  };

  useEffect(() => {
    if (map && markers.length > 0) {
      const bounds = new google.maps.LatLngBounds();

      markers.forEach((marker) => {
        bounds.extend(new google.maps.LatLng(marker.lat, marker.lng));
      });

      map.fitBounds(bounds);
    }
  }, [map, markers]);

  const handleMarkerClick = (marker: MarkerData) => {
    setSelectedMarker(marker);

    if (map) {
      map.panTo({ lat: marker.lat, lng: marker.lng });
      map.setZoom(15);
    }
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBnUmMfyn4yxmKUBh-xP8U9k7GYmMp-oyo",
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={defaultCenter}
      zoom={10} // Initial zoom level
      onLoad={onLoad}
    >
      {markers.map((marker, index) => (
        <Marker
          key={index}
          position={{ lat: marker.lat, lng: marker.lng }}
          icon={{
            url: markerSVG,
            scaledSize: new google.maps.Size(32, 32),
          }}
          onClick={() => handleMarkerClick(marker)}
        >
          {selectedMarker && selectedMarker === marker && (
            <InfoWindow>
              <div style={{ width: "20em" }}>
                <Text
                  fontSize="1.5em"
                  text={marker.name}
                  padding="0 0 0.5em 0"
                  fontWeight={700}
                />
                <Text
                  fontSize="1.1em"
                  text={marker.externalId}
                  fontWeight={600}
                />

                <Text
                  fontSize="1.1em"
                  text={`${marker.groupCode ? `${marker.groupCode}::` : ""}${
                    marker.group
                  }`}
                  fontWeight={600}
                />
                <Text fontSize="1em" text={marker.line1} fontWeight={400} />
                <Text fontSize="1em" text={marker.reference} fontWeight={400} />

                <div
                  className={styles.closeButton}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    marker.clicked();
                  }}
                >
                  <FlexContainer
                    padding="1em 0 0 0"
                    justifyContent="flex-start"
                  >
                    <Text
                      fontSize="1em"
                      text="View More"
                      color={linkBlue}
                      fontWeight={700}
                      padding="0 1em 0 0"
                    />
                    <FontAwesomeIcon
                      icon={faChevronRight as IconProp}
                      className={styles.chevron}
                      color={linkBlue}
                    />
                  </FlexContainer>
                </div>
              </div>
            </InfoWindow>
          )}
        </Marker>
      ))}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default MapMarkers;
