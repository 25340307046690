import React from "react";
import { classes, style } from "typestyle";
import chevronDown from "../../../assets/chevron-down.svg";
import { InputColors } from "../../../constants/colors";
import { black } from "../../../constants/colors";
import styles from "./styles.module.css";
import { BaseInput } from "../styles";
import deleteIcon from "../../../assets/deleteBlack.svg";
import { InputLabel } from "../InputLabel/InputLabel";
import { InputError } from "../InputError/InputError";

interface InputSelectOption {
  label: string;
  value: any;
  id?: string;
}

export interface InputSelectProps {
  id?: string;
  value?: string | number;
  placeholder?: string;
  options?: InputSelectOption[];
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: (value: any) => void;
  onDropdownClick?: () => void;
  containerClassname?: string;
  label?: string;
  error?: false | "" | string[] | string;
  showError?: boolean;
  showLabel?: boolean;
  maxHeightOptions?: string;
  height?: string;
  onClick?: () => void;
  onDelete?: (value: any) => void;
  erase?: boolean;
}

const selectButton = style({
  ...BaseInput.input,
  zIndex: 2,
  borderRadius: "2px",
  position: "relative",
  display: "block",

  boxSizing: "border-box",
  color: black,
  background: "#FFF",
  $nest: {
    "&::-webkit-input-placeholder": {
      color: "#BFBFBF",
    },
    "&:hover": {
      border: `1px solid ${InputColors.inputFocusBorderColor}`,
    },
    "&:focus": {
      border: `1px solid ${InputColors.inputFocusBorderColor}`,
      boxShadow: "0 0 0 2px rgb(24 144 255 / 20%)",
      $nest: {
        "& > div": {
          borderColor: InputColors.inputFocusBorderColor,
        },
      },
    },
  },
});

const buttonLabelContainer = style({
  width: "100%",
  display: "block",
  textAlign: "left",
});

const arrowIconContainer = style({
  position: "absolute",
  zIndex: 1,
  top: 0,
  right: 0,
  height: "100%",
  width: "2.7em",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const InputSelect: React.FC<InputSelectProps> = (props) => {
  const [focus, setFocus] = React.useState(false);
  const {
    id,
    label,
    value,
    disabled,
    readOnly,
    onChange,
    options,
    placeholder,
    containerClassname,
    error,
    showLabel = true,
    showError = true,
    onDropdownClick,
    height,
    onClick,
    maxHeightOptions,
    erase,
    onDelete,
  } = props;

  const valueLabel = options?.find((e) => e.value === value)?.label;
  const ref = React.useRef<HTMLButtonElement>(null);

  return (
    <div
      onClick={onClick}
      className={classes(styles.container, containerClassname)}
    >
      <InputLabel showLabel={showLabel} label={label} id={id} />
      <div className={styles.selectWrapper}>
        <button
          ref={ref}
          className={selectButton}
          onClick={() => {
            ref.current?.focus();
          }}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => setTimeout(() => setFocus(false), 150)}
          style={{ borderColor: error ? "red" : "", height: height }}
          onMouseUp={(e) => e.preventDefault()}
          disabled={disabled}
        >
          <div className={buttonLabelContainer}>
            {valueLabel ? (
              <span style={{ marginRight: "2em !important" }}>
                {valueLabel}
              </span>
            ) : (
              <div style={{ color: "#bfbfbf", marginRight: "2em !important" }}>
                {placeholder}
              </div>
            )}
          </div>
          <div
            className={arrowIconContainer}
            style={{ borderColor: error ? "red" : "" }}
          >
            <img src={chevronDown} alt="" className={styles.icon} />
          </div>
        </button>

        <div
          className={styles.options}
          style={{
            maxHeight: maxHeightOptions,
            display: focus && !disabled && !readOnly ? "block" : "none",
          }}
        >
          {options?.map((option, index) => (
            <div
              onClick={() => {
                onChange?.(option.value);
                onDropdownClick?.();
              }}
              key={option.label + index}
              className={
                options.find((e) => e.value === value)?.label === option.label
                  ? styles.optionSelected
                  : styles.option
              }
            >
              <div style={{ cursor: "pointer" }} className={styles.flex}>
                <div>{option.label}</div>
                {erase && (
                  <img
                    alt=""
                    className={styles.deleteIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete?.(option.value);
                    }}
                    src={deleteIcon}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <InputError error={error as string} showError={showError} />
    </div>
  );
};
