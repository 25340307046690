import React, { useState } from "react";

import { Offer, Modifier } from "@innomius/ravent-typescript-types";
import ModifierList from "./ModifierList";
import OfferModifierForm from "../OfferForm/OfferModifierForm";
import { defaultModifier } from "../../services/variantModifiers";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { EmptyList } from "../List/EmptyList";
import { ContainerTitle } from "../ContainerTitle";
import { Button } from "../Button";

interface Props {
  data: Offer;
}

const ModifiersDetail: React.FC<Props> = ({ data }) => {
  const [addNewModifier, setForm] = useState(false);
  const [edit, setEdit] = useState(false);
  const [modifier, setModifier] = useState<Modifier>(defaultModifier);

  const { t } = useTranslation();

  const onChange = <P extends keyof Modifier>(prop: P, value: Modifier[P]) => {
    setModifier({ ...modifier, [prop]: value });
  };

  return (
    <div>
      {!data.modifiers ? (
        <div className={styles.emptyCard}>
          <EmptyList
            instructions={t("component.offerVariantDetail.emptyList")}
            text={t("component.offerVariantDetail.emptyListInstructions")}
            buttonText={t(
              "component.offerVariantDetail.emptyButtonTextModifier"
            )}
            onNewClick={() => {}}
          />
        </div>
      ) : !addNewModifier ? (
        <>
          <FlexContainer
            padding="0 0 1em"
            alignItems="center"
            justifyContent="space-between"
          >
            <ContainerTitle
              padding="1em 0 0 0"
              label={t("component.offerVariantDetail.modifieres")}
              size="xsmall"
              count={data.modifiers.length.toString()}
            />
            <Button
              onClick={() => setForm(true)}
              label={t("component.offerVariantDetail.addNewModifier")}
            />
          </FlexContainer>
          <ModifierList
            data={data}
            onEdit={async (id: string) => {
              const modifier = await data.modifiers.find(
                (item) => item.name === id
              );
              if (modifier) {
                setModifier(modifier);
              }
              setEdit(true);
              setForm(true);
            }}
          />
        </>
      ) : (
        <OfferModifierForm
          edit={edit}
          onChange={onChange}
          data={modifier}
          onSave={() => setModifier(defaultModifier)}
          onCancel={() => {
            setModifier(defaultModifier);
            setForm(false);
          }}
        />
      )}
    </div>
  );
};
export default ModifiersDetail;
