import React, { useState } from "react";
import {
  Message,
  OfficialMedia,
  Visitor,
} from "@innomius/ravent-typescript-types";

import { ChatMessage } from "../ChatMessage";
import { formatDate } from "../../utils/times";
import styles from "./styles";
import { SideMenu } from "../SideMenu/SideMenu";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";
import { SectionProperty } from "../Section/SectionProperty";
import { InputSelect } from "../Inputs/InputSelect";
import { Button } from "../Button";
import { InputTextarea } from "../Inputs/InputTextArea";
import { InputError } from "../Inputs/InputError/InputError";

interface Props {
  data: Visitor;
  messages: Message[];
  path?: string;
  onClose: () => void;
  open: boolean;
  sendMessage: (content: string) => void;
  status: () => void;
  officialMedia: OfficialMedia[];
}

const VisitorChat: React.FC<Props> = ({
  open,
  onClose,
  data,
  sendMessage,
  messages,
  officialMedia,
}) => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [selectedOfficialMedia, setOfficialMedia] = useState("");
  const [answered, setAnswer] = useState<Date | null>(new Date());
  const officialMediaList = officialMedia
    .filter((item) => item.venueId === data.venueId)
    .map((item) => {
      return { label: `${item.name} / ${item.locationName}`, value: item.id };
    });

  return (
    <SideMenu width="50%" onClose={onClose} open={open}>
      <div className={styles.itemsWrapper}>
        <FlexContainer justifyContent="space-between">
          <ContainerTitle
            padding="0 0 1em 0"
            size="small"
            label="Visitor Chat"
          />
          <FlexContainer>
            <span className={styles.bluePoint} />
            <p className={styles.lastAnswer}>
              Read by Astrid at {formatDate(answered, "HOUR")}
            </p>
          </FlexContainer>
        </FlexContainer>
        <GridContainer padding="1em 0" gap="1em" columns="1fr 1fr 1fr">
          <SectionProperty label="Location Name" value={data.locationName} />
          <SectionProperty label="Order ID" value={data.orderId || "-"} />
          <SectionProperty
            label="Name"
            value={
              data.firstName || data.lastName
                ? `${data.firstName} ${data.lastName}`
                : "-"
            }
          />
          <SectionProperty label="Reference" value={data.reference || "-"} />
          <SectionProperty
            label="Bracelete ID"
            value={data.braceleteId || "-"}
          />
          <SectionProperty
            label="Phone Number"
            value={data.phoneNumber || "-"}
          />
        </GridContainer>
        {!messages.length ? (
          <div className={styles.noMessages}>No messages</div>
        ) : (
          <div className={styles.content}>
            {messages.map((item) => (
              <ChatMessage
                key={item.id}
                timestamp={item.sentTimestamp}
                visitor={item.visitor}
                content={item.content}
              />
            ))}
          </div>
        )}
        <GridContainer
          justifyContent="center"
          alignItems="center"
          gap="1em"
          columns="2fr 1fr"
        >
          <InputSelect
            label="Venue Official Media"
            placeholder={
              officialMediaList.length
                ? "File Name / Location Name"
                : "Non-existent Official Media"
            }
            options={officialMediaList}
            value={selectedOfficialMedia}
            disabled={!officialMediaList.length}
            onChange={(value) => setOfficialMedia(value)}
          />
          <Button
            label="Send Official Media"
            disable={!officialMediaList.length}
          />
        </GridContainer>
        <InputTextarea
          value={message}
          onChange={(value) => setMessage(value)}
        />

        <Button
          label="Send Message"
          onClick={() => {
            if (!data.phoneNumber) {
              setError(
                "This visitant can't receive messages. The visitant didn't register a phone number."
              );
            }
            setAnswer(new Date());
            sendMessage(message);
          }}
        />
        <InputError error={error} />
      </div>
    </SideMenu>
  );
};

export default VisitorChat;
