import {
  CardPayment,
  CardRefund,
  CashPayment,
  CashRefund,
  DeliveryEvent,
  InvoiceEvent,
  OrderEvent,
  Payment,
  PayNetPayment,
  PayNetRefund,
  Refund,
  WirePayment,
  MessageEvent,
  PaymentEvent,
  RefundEvent,
  PaymentRequestEvent,
  PhysicalDelivery,
  DeliveryTypes,
  DigitalDelivery,
  W3wAddress,
  AddressType,
  PostalAddress,
  WellKnownAddress,
  FirstDataPayment,
  StorePayment,
} from "@innomius/ravent-typescript-types";

export function isCardRefund(payment: Refund | any): payment is CardRefund {
  return (payment as CardRefund).type === "card";
}

export function isCashRefund(payment: Refund): payment is CashRefund {
  return (payment as CashRefund).type === "cash";
}

export function isPayNetRefund(payment: Refund): payment is PayNetRefund {
  return (payment as PayNetRefund).type === "payment-link";
}

export function isFirstData(payment: Payment): payment is FirstDataPayment {
  return (payment as FirstDataPayment).paymentType === "payment-link";
}

export function isCard(payment: Payment): payment is CardPayment {
  return (
    (payment as CardPayment).paymentType === "card" ||
    (payment as CardPayment).paymentType === "credit-card" ||
    (payment as CardPayment).paymentType === "card-reader"
  );
}
export function isWire(payment: Payment): payment is WirePayment {
  return (payment as WirePayment).paymentType === "wire-transfer";
}
export function isCash(payment: Payment): payment is CashPayment {
  return (payment as CashPayment).paymentType === "cash";
}

export function isStorePayment(payment: Payment): payment is StorePayment {
  return (payment as StorePayment).paymentType === "in-store";
}

export function isPayNet(payment: Payment): payment is PayNetPayment {
  return (payment as PayNetPayment).paymentType === "netd";
}

export function isDeliveryEvent(item: OrderEvent): item is DeliveryEvent {
  return (item as DeliveryEvent).type === "delivery";
}
export function isInvoiceEvent(item: OrderEvent): item is InvoiceEvent {
  return (item as InvoiceEvent).type === "invoice";
}
export function isMessageEvent(item: OrderEvent): item is MessageEvent {
  return (item as MessageEvent).type === "message";
}
export function isPaymentEvent(item: OrderEvent): item is PaymentEvent {
  return (item as PaymentEvent).type === "payment";
}
export function isRefundEvent(item: OrderEvent): item is RefundEvent {
  return (item as RefundEvent).type === "refund";
}
export function isPaymentRequest(
  item: OrderEvent
): item is PaymentRequestEvent {
  return (item as PaymentRequestEvent).type === "payment-request";
}

export function isPhysicalDelivery(
  item: DeliveryTypes
): item is PhysicalDelivery {
  return (item as PhysicalDelivery).type === "physical";
}

export function isDigitalDelivery(
  item: DeliveryTypes
): item is DigitalDelivery {
  return (item as DigitalDelivery).type === "digital";
}

export function isW3WAddress(item: AddressType): item is W3wAddress {
  return (item as W3wAddress).type === "w3w";
}

export function isPostalAddress(item: AddressType): item is PostalAddress {
  return (item as PostalAddress).type === "postal-address";
}

export function isWellKnownAddress(
  item: AddressType
): item is WellKnownAddress {
  return (item as WellKnownAddress).type === "well-known";
}
