import dayjs from "dayjs";
import APIError from "../utils/APIError";
import { getAccessToken } from "./auth";

interface ExportParams {
  all: boolean;
  projection: string[];
  venue_id?: string;
  venueName?: string;
  ids?: string[];
}

export async function exportMultipleLocationsCVS(data: ExportParams) {
  const token = await getAccessToken();
  const stringWithHyphens: string = data.projection
    .map((str) => `-${str}`)
    .join(" ");

  const body = data.all
    ? { venue_id: data.venue_id, projection: stringWithHyphens }
    : {
        ids: data.ids,
        venue_id: data.venue_id,
        projection: stringWithHyphens,
      };

  const res = await fetch(`/api/v1/locations/exportCsv`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  if (res.ok) {
    const csvData = await res.blob();
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const link = document.createElement("a");

    link.href = URL.createObjectURL(blob);
    link.setAttribute(
      "download",
      `locations_${data.venueName}-${dayjs(new Date()).format(
        "YYYY.MM.DD"
      )}.csv`
    );
    link.click();
  } else {
    const json = await res.json();
    throw new APIError(json.error, json.message, res.status.toString());
  }
}

export async function exportMultipleAssetsCVS(data: ExportParams) {
  const token = await getAccessToken();
  const stringWithHyphens: string = data.projection
    .map((str) => `-${str}`)
    .join(" ");

  const body = data.all
    ? {
        projection: stringWithHyphens,
      }
    : {
        ids: data.ids,
        projection: stringWithHyphens,
      };

  const res = await fetch(`/api/v1/assets/exportCsv`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  if (res.ok) {
    const csvData = await res.blob();
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const link = document.createElement("a");

    link.href = URL.createObjectURL(blob);
    link.setAttribute(
      "download",
      `assets-${dayjs(new Date()).format("YYYY.MM.DD")}.csv`
    );
    link.click();
  } else {
    const json = await res.json();
    throw new APIError(json.error, json.message, res.status.toString());
  }
}
