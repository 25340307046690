import { getAccessToken } from "./auth";

export const imageConverter = (source: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = async () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const context = canvas.getContext("2d");
      context?.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL();
      return resolve(dataURL);
    };
    img.onerror = (error) => reject(error);
    img.src = source;
  });
};

export const downloadOrderPDF = async (
  orderId: string,
  language: string,
  messageType: string,
  open: boolean
) => {
  const token = await getAccessToken();

  const body = {
    orderId: orderId,
    language: language,
    transport: "pdf",
    targets: [],
    data: {},
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "cache-control": "no-cache",
      Authorization: token ? `Bearer ${token}` : "",
    },
    body: body ? JSON.stringify(body) : undefined,
  };

  fetch(`/api/v1/messaging/${messageType}`, requestOptions)
    .then((res) => {
      return res.blob();
    })
    .then((data) => {
      if (open) {
        const file = new Blob([data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        const pdfWindow = window.open();
        if (pdfWindow) pdfWindow.location.href = fileURL;
        return;
      }

      var a = document.createElement("a");
      a.href = window.URL.createObjectURL(data);
      a.download = `order${orderId}`;
      a.click();
    });
  return;
};

export const downloadRequestReceipe = async (
  orderId: string,
  language: string,
  messageType: string,
  requestId: string,
  open: boolean
) => {
  const token = await getAccessToken();

  const data = {
    paymentRequestId: requestId,
  };

  const body = {
    orderId: orderId,
    language: language,
    transport: "pdf",
    targets: [],
    data,
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "cache-control": "no-cache",
      Authorization: token ? `Bearer ${token}` : "",
    },
    body: body ? JSON.stringify(body) : undefined,
  };

  fetch(`/api/v1/messaging/${messageType}`, requestOptions)
    .then((res) => {
      return res.blob();
    })
    .then((data) => {
      if (open) {
        const file = new Blob([data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        const pdfWindow = window.open();
        if (pdfWindow) pdfWindow.location.href = fileURL;
        return;
      }

      var a = document.createElement("a");
      a.href = window.URL.createObjectURL(data);
      a.download = `request_${requestId}`;
      a.click();
    });
};

export const downloadPaymentReceipe = async (
  orderId: string,
  language: string,
  messageType: string,
  paymentId: string,
  open: boolean
) => {
  const token = await getAccessToken();

  const data = {
    paymentId: paymentId,
  };

  const body = {
    orderId: orderId,
    language: language,
    transport: "pdf",
    targets: [],
    data,
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "cache-control": "no-cache",
      Authorization: token ? `Bearer ${token}` : "",
    },
    body: body ? JSON.stringify(body) : undefined,
  };

  fetch(`/api/v1/messaging/${messageType}`, requestOptions)
    .then((res) => {
      return res.blob();
    })
    .then((data) => {
      if (open) {
        const file = new Blob([data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        const pdfWindow = window.open();
        if (pdfWindow) pdfWindow.location.href = fileURL;
        return;
      }

      var a = document.createElement("a");
      a.href = window.URL.createObjectURL(data);
      a.download = `receipe${paymentId}`;
      a.click();
    });
};

export const downloadInvoicePDF = async (
  orderId: string,
  language: string,
  messageType: string,
  invoiceId: string,
  open: boolean
) => {
  const token = await getAccessToken();

  const data = {
    invoiceId: invoiceId,
  };

  const body = {
    orderId: orderId,
    language: language,
    transport: "pdf",
    targets: [],
    data,
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "cache-control": "no-cache",
      Authorization: token ? `Bearer ${token}` : "",
    },
    body: body ? JSON.stringify(body) : undefined,
  };

  fetch(`/api/v1/messaging/${messageType}`, requestOptions)
    .then((res) => {
      return res.blob();
    })
    .then((data) => {
      if (open) {
        const file = new Blob([data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        const pdfWindow = window.open();
        if (pdfWindow) pdfWindow.location.href = fileURL;
        return;
      }

      var a = document.createElement("a");
      a.href = window.URL.createObjectURL(data);
      a.download = `${invoiceId}`;
      a.click();
    });
};

export const downloadInvoice = async (
  url: string,
  invoiceId: string,
  fileType: string,
  open: boolean
) => {
  const token = await getAccessToken();

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  };

  fetch(url, requestOptions)
    .then((res) => {
      return res.blob();
    })
    .then((data) => {
      const file = new Blob([data], { type: `application/${fileType}` });

      if (open) {
        const fileURL = URL.createObjectURL(file);
        const pdfWindow = window.open();
        if (pdfWindow) pdfWindow.location.href = fileURL;
        return;
      }

      var a = document.createElement("a");
      a.href = window.URL.createObjectURL(file);
      a.download = `${invoiceId}`;
      a.click();
    });
};

export const downloadMediaFile = async (
  mediaId: string,
  fileType: string,
  open: boolean,
  name: string
) => {
  const token = await getAccessToken();

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  };

  fetch(`/api/v1/location_media/${mediaId}/file`, requestOptions)
    .then((res) => {
      return res.blob();
    })
    .then((data) => {
      const file = new Blob([data], { type: `${fileType}` });

      if (open) {
        const fileURL = URL.createObjectURL(file);
        const pdfWindow = window.open();
        if (pdfWindow) pdfWindow.location.href = fileURL;
        return;
      }

      var a = document.createElement("a");
      a.href = window.URL.createObjectURL(file);
      a.download = `${name}`;
      a.click();
    });
};
