import React, { useState } from "react";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import deleteIcon from "../../assets/officialMedia/default-delete.svg";
import { Popover } from "antd";
import DeletePopUp from "../DeletePopUp";
import { useTranslation } from "react-i18next";
import AddOrderlineState from "../FulfillmentStates/AddOrderlineState";
import styles from "./styles";
import { Action, FulfillmentStateDef } from "@innomius/ravent-typescript-types";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";

interface OrderStateListProps {
  data: FulfillmentStateDef;
  onDelete: (id: number) => void;
  loading: string;
  onAdd: (data: FulfillmentStateDef) => void;
  onEdit: (data: FulfillmentStateDef, index: number) => void;
  index: number;
  actions: Action[]
}

const OrderlineStateItem: React.FC<OrderStateListProps> = ({
  data,
  onDelete,
  loading,
  onEdit,
  onAdd,
  index,
  actions
}) => {
  const [deleteOrderState, setDelete] = useState("");
  const [edit, setEdit] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      <AddOrderlineState
        index={index}
        edit
        actions={actions}
        open={edit}
        onAdd={(data) => onAdd(data)}
        onUpdate={(data) => onEdit(data, index)}
        onCancel={() => setEdit(false)}
        data={data}
        loading={loading === "edit"}
      />
      <ListRow template="1.5fr 3fr 1fr" hover={true} key={data.name}>
        <ListLabel fontFormat="bold" value={data.name} textAlign="left" />
        <ListLabel value={data.actionName || data.actionId} textAlign="left" />

        <div className={styles.iconContainer}>
          <EditIcon className={styles.icon} onClick={() => setEdit(true)} />
          <Popover
            placement="left"
            content={
              <div onClick={() => setDelete("")}>
                <DeletePopUp
                  loading={loading === "delete"}
                  value={`${t("component.orderlineStateList.deleteMessage")} ${
                    data.name
                  } ?`}
                  onClose={(e) => {
                    e.stopPropagation();
                    setDelete("");
                  }}
                  onDelete={(e) => {
                    e.stopPropagation();
                    onDelete(index);
                  }}
                />
              </div>
            }
            trigger="click"
            open={deleteOrderState === "delete"}
            onOpenChange={() => setDelete(deleteOrderState)}
          />
          <img
            src={deleteIcon}
            alt="userDelete"
            onClick={(e) => {
              e.stopPropagation();
              setDelete("delete");
            }}
          />
        </div>
      </ListRow>
    </div>
  );
};

export default OrderlineStateItem;
