import React from "react";
import styles from "./styles";
import { MissingLocation } from "../../utils/types";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";
import { Button } from "../Button";
interface LocationsListProps {
  data: MissingLocation;
  onClickItem: (externalId: string, name: string) => void;
}

const LocationItemList: React.FC<LocationsListProps> = ({
  data,
  onClickItem,
}) => {
  return (
    <div>
      <ListRow
        boxShadow
        borderRadius="6px"
        template="0.5fr 1fr 2fr 0.5fr"
        hover={true}
        key={data.id}
      >
        <ListLabel value={data.count.toString() || "-"} textAlign="left" />
        <ListLabel value={data.externalId || "-"} textAlign="left" />
        <ListLabel value={data.name} textAlign="left" />
        <div className={styles.iconContainer}>
          <Button
            label={"+ Create"}
            onClick={() => onClickItem(data.externalId, data.name)}
          />
        </div>
      </ListRow>
    </div>
  );
};

export default LocationItemList;
