const translation = {
  en: {
    refundPayment: "Refund Payment",
    cancel: "Cancel",
    issue: "Issue",
    instructions: "The amount to be reunded excluiding penalties is",
    expiredDate: "Expired Date",
    penalty: "Penalty %",
    penaltyAmount: "Penalty Amount",
    refundMethod: "Refund Information Method",
    finalRefundAmount: "Final Refund Amount",
    wrongAmount: "Wrong Amount",
    cancelled: "Order Cancelled",
    other: "Other Reason",
  },
  es: {
    refundPayment: "Pago de reembolso",
    cancel: "Cancelar",
    issue: "Emitir",
    instructions: "El importe a reembolsar sin incluir las sanciones es",
    expiredDate: "Fecha de vencimiento",
    penalty: "Penalización %",
    penaltyAmount: "Cantidad de la penalización",
    refundMethod: "Método de información de reembolso",
    finalRefundAmount: "Importe del reembolso final",
    wrongAmount: "Cantidad incorrecta",
    cancelled: "Orden cancelado",
    other: "Otro motivo",
  },
  de: {},
};

export default translation;
