import React from "react";

import { useTranslation } from "react-i18next";
import styles from "./styles";
import { StatusBadgePoint } from "../StatusBadgePoint/StatusBagePoint";
import { VenueInvoiceEvent } from "../../utils/types";
import { ListHeader } from "../List/ListHeader";
import { ListRow } from "../List/ListRow";
import { List } from "../List";
import { ListLabel } from "../List/ListLabel";
import { StatusCatalog } from "../StatusBadge";
import { ListHeaderLabel } from "../List/ListHeaderLabel";

interface StateProps {
  data: VenueInvoiceEvent;
}

const InvoiceStateList: React.FC<StateProps> = ({ data }) => {
  const { t } = useTranslation();

  const events = [
    {
      name: "invoice issuance",
      actionId: data.invoiceIssuance.actionId,
      automatic: data.invoiceIssuance.automatic,
      actionName: data.invoiceIssuance.actionName,
    },
    {
      name: "invoice cancellation",
      actionId: data.invoiceCancellation.actionId,
      automatic: data.invoiceCancellation.automatic,
      actionName: data.invoiceCancellation.actionName,
    },
    {
      name: "invoice refund",
      actionId: data.invoiceRefund.actionId,
      automatic: data.invoiceRefund.automatic,
      actionName: data.invoiceRefund.actionName,
    },
  ];

  const header = (
    <div>
      <ListHeader template="1fr 3fr 1fr">
        <ListHeaderLabel
          justifyContent="flex-start"
          value={t("component.invoiceStateList.invoiceState")}
        />
        <ListHeaderLabel
          value={t("component.invoiceStateList.actionName")}
          textAlign="center"
          justifyContent="flex-start"
        />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <List
        data={events}
        keyExtractor={(item) => item.name}
        renderHeader={() => header}
        renderItem={(item) => (
          <ListRow template="1fr 3fr 1fr" hover={false} key={item.name}>
            <div className={styles.center}>
              <StatusBadgePoint status={item.name as StatusCatalog} />
            </div>
            <ListLabel
              value={item.actionName || item.actionId || ""}
              textAlign="left"
            />
          </ListRow>
        )}
      />
    </div>
  );
};

export default InvoiceStateList;
