import React, { useState } from "react";
import {
  Location,
  Message,
  Placement,
  Visitor,
} from "@innomius/ravent-typescript-types";
import { OrderListSkeleton } from "../ListSkeletons";
import LocationItemList from "./LocationItemList";
import styles from "./styles";
import { PaginationRavent } from "../Pagination/Pagination";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { List } from "../List";

interface LocationsListProps {
  error: string;
  loading: boolean;
  data: Location[];
  onChangeSortOrder?: (value: string) => void;
  path?: string;
  messages: Message[];
  visitors: Visitor[];
  page: number;
  total: number;
  hitsPerPage: number;
  onPageChange?: (page: number) => void;
  onHitsPerPageChange?: (hitsPerPage: number) => void;
  sortable: boolean;
  pagination?: boolean;
  onDelete: (id: string) => void;
  onEditLocation: (data: Location, placement: Placement) => void;
  load: () => void;
  checkbox?: boolean;
  selectedLocations: { id: string; name: string }[];
  setSelectedLocations: (value: any) => void;
  venueId: string;
  onClickItem: (id: string) => void;
}

const LocationsList: React.FC<LocationsListProps> = ({
  error,
  loading,
  data,
  path,
  messages,
  visitors,
  pagination,
  page,
  total,
  hitsPerPage,
  onHitsPerPageChange,
  onPageChange,
  onDelete,
  onEditLocation,
  load,
  selectedLocations,
  setSelectedLocations,
  venueId,
  onClickItem,
  onChangeSortOrder,
}) => {
  const [sort, setSort] = useState<string | null>(null);
  const [order, setOrder] = useState<string>("asc");

  const [selectAll, setSelectAll] = useState(false);
  const handleCheckboxChange = (locationId: string, locationName: string) => {
    const isSelected = selectedLocations.some((loc) => loc.id === locationId);

    if (selectAll) {
      setSelectedLocations((prevSelected: any[]) => {
        if (isSelected) {
          return prevSelected.filter((loc) => loc.id !== locationId);
        } else {
          return [...prevSelected, { id: locationId, name: locationName }];
        }
      });
    } else {
      setSelectedLocations((prevSelected: any[]) => {
        if (isSelected) {
          return prevSelected.filter((loc) => loc.id !== locationId);
        } else {
          return [...prevSelected, { id: locationId, name: locationName }];
        }
      });
    }
  };

  const handleSelectAllChange = (e: CheckboxChangeEvent) => {
    setSelectAll(e.target.checked);
    setSelectedLocations(
      e.target.checked
        ? data.map((item) => ({ id: item.id, name: item.name }))
        : []
    );
  };
  const header = (
    <div>
      <ListHeader template="2em 0.4fr 0.7fr 0.4fr 0.5fr 0.7fr 0.5fr">
        <div style={{ padding: "0.4em" }}>
          <Checkbox checked={selectAll} onChange={handleSelectAllChange} />
        </div>
        <ListHeaderLabel
          value="ID"
          sortable
          sort={
            sort === "externalId" ? (order === "desc" ? "asc" : "desc") : null
          }
          onClick={() => {
            setSort("externalId");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder?.(`externalId:${order}`);
            }
          }}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value="Location Name"
          sortable
          sort={sort === "name" ? (order === "desc" ? "asc" : "desc") : null}
          onClick={() => {
            setSort("name");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder?.(`name:${order}`);
            }
          }}
          justifyContent={"flex-start"}
        />
        <ListHeaderLabel
          value="Created"
          sortable
          sort={
            sort === "createdAt" ? (order === "desc" ? "asc" : "desc") : null
          }
          onClick={() => {
            setSort("createdAt");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder?.(`createdAt:${order}`);
            }
          }}
          justifyContent="flex-start"
        />

        <ListHeaderLabel
          value="Group"
          sortable
          sort={sort === "group" ? (order === "desc" ? "asc" : "desc") : null}
          onClick={() => {
            setSort("group");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`group:${order}`);
            }
          }}
          justifyContent="flex-start"
        />
        <ListHeaderLabel value="Address" justifyContent="flex-start" />

        {/* <ListHeaderLabel value="Self Checkin Url" justifyContent="center" /> */}
        <ListHeaderLabel value="" justifyContent="center" />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <List
        loading={loading}
        loadingElement={() => <OrderListSkeleton />}
        error={error}
        data={data}
        keyExtractor={(item) => item.id}
        renderHeader={() => header}
        renderItem={(item) => (
          <LocationItemList
            load={load}
            onClickItem={onClickItem}
            venueId={venueId}
            onEditLocation={(data, placement) =>
              onEditLocation(data, placement)
            }
            onCheckboxChange={() => handleCheckboxChange(item.id, item.name)}
            isSelected={selectedLocations.some((loc) => loc.id === item.id)}
            visitors={visitors}
            messages={messages}
            onDelete={(id) => onDelete(id)}
            path={`${path}`}
            data={item}
            key={item.id}
          />
        )}
      />
      {pagination ? (
        <div className={styles.paginationContainer}>
          <PaginationRavent
            current={page + 1}
            defaultCurrent={page + 1}
            total={total}
            showSizeChanger
            onShowSizeChange={(page, size) => {
              if (onHitsPerPageChange) {
                onHitsPerPageChange(size);
              }
            }}
            onChange={(page) => {
              if (onPageChange) {
                onPageChange(page - 1);
              }
            }}
            pageSize={hitsPerPage}
          />
        </div>
      ) : null}
    </div>
  );
};

export default LocationsList;
