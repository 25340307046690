const translation = {
  en: {
    cancel: "Cancel",
    delete: "Delete",
    confirm: "Confirm",
    question: "Are you sure you want to reset this user's password?",
    instructions:
      "We will send a reset link to their registered email or phone number with instructions to set up a new password.",
  },
  es: {
    confirm: "Confirmar",
    question: "¿Estás seguro de que deseas restablecer la contraseña de este usuario?",
    instructions: "Enviaremos un enlace de restablecimiento a su correo electrónico o número de teléfono registrado con instrucciones para configurar una nueva contraseña.",
    cancel: "Cancelar",
    delete: "Eliminar",
  },
  de: {},
};

export default translation;
