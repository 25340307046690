import React, { useState } from "react";
import styles from "./styles";
import { ListRow } from "../List/ListRow";
import { CountsResponse } from "../../services/events";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  numberFormatMoreDecimals,
  numberFormatNoDecimals,
} from "../../utils/format";
import BillingDetail from "../BillingDetail";
import dayjs from "dayjs";
import { ListLabel } from "../List/ListLabel";

interface Props {
  data: CountsResponse;
}

const Item: React.FC<Props> = ({ data }) => {
  const [open, setOpen] = useState("");

  return (
    <div key={data.month + Math.random()}>
      <BillingDetail
        data={data}
        open={open === data.month}
        onClose={() => setOpen("")}
      />
      <ListRow
        template="1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr"
        hover={true}
        key={data.month + Math.random()}
        boxShadow
        onClick={() => setOpen(data.month)}
        borderRadius="6px"
      >
        <ListLabel
          value={dayjs.utc(data.month).format("MMM, YYYY")}
          textAlign="left"
        />

        <ListLabel
          value={numberFormatNoDecimals(data.orders)}
          textAlign="left"
        />
        <ListLabel
          value={numberFormatNoDecimals(data.ordersDeleted)}
          textAlign="left"
        />
        <ListLabel
          value={numberFormatMoreDecimals(data.costPerOrder)}
          textAlign="left"
        />
        <ListLabel
          value={numberFormatMoreDecimals(data.ordersCost)}
          textAlign="left"
        />
        <ListLabel value={numberFormatNoDecimals(data.pax)} textAlign="left" />
        <ListLabel
          value={numberFormatMoreDecimals(data.ordersCost / data.pax)}
          textAlign="left"
        />

        <div className={styles.center} onClick={() => setOpen(data.month)}>
          <FontAwesomeIcon
            icon={faChevronRight as IconProp}
            className={styles.icon}
            color={"#333333"}
            size="2x"
          />
        </div>
      </ListRow>
    </div>
  );
};

export default Item;
