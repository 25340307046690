import React, { useCallback, useEffect, useState } from "react";
import statesIcon from "../../../assets/states.svg";
import statesBlackIcon from "../../../assets/sidebar/states.svg";

import { Button } from "../../Button";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FulfillmentDefinition } from "@innomius/ravent-typescript-types";
import FulfillmentModal from "../../OrderFulfillment/FulfilmentStatus/FulfillmentModal";
import NameModal from "../../OrderFulfillment/NameModal";
import StatesModal from "../../OrderFulfillment/StatesModal";
import styles from "./styles";
import {
  defaultFulfillment,
  readFulfillment,
} from "../../../services/fulfilments";
import { Badge, Tooltip } from "antd";
import {
  orderReviewIconMap,
  orderReviewIconNormalMap,
} from "../../../utils/order";
import Text from "../../Text";
import { ButtonsContainer } from "../../ButtonsContainer";
import { FlexContainer } from "../../FlexContainer/FlexContainer";

interface ActionRowOrderProps {
  team: string;
  path: string;
  commentsLength: number;
  updateFulfillmentDefinition: (id: string, definitionId: string) => void;
  fulfillmentDefintions: { label: string; value: string }[];
  errorFulfillment: string;
  fulfillmentState: string;
  setState: (name: string, w3w: string, images: string[]) => void;
  fulfillmentLevel: string;
  locationLevel: string;
  fulfillmentDefinitionId: string;
  orderState: string;
  reviewArray: [string, boolean][];
  orderId: string;
  onComment: () => void;
}

const ActionRowOrder: React.FC<ActionRowOrderProps> = ({
  orderId,
  updateFulfillmentDefinition,
  fulfillmentDefintions,
  errorFulfillment,
  fulfillmentState,
  setState,
  fulfillmentLevel,
  fulfillmentDefinitionId,
  orderState,
  reviewArray,
  onComment,
  commentsLength,
}) => {
  const [open, setOpen] = useState("");
  const [fulfillmentDefinition, setFulfilment] =
    useState<FulfillmentDefinition>(defaultFulfillment);

  const history = useHistory();

  const { t } = useTranslation();

  const load = useCallback(async () => {
    try {
      const res = await readFulfillment(fulfillmentDefinitionId);
      setFulfilment(res);
    } catch (err) {}
  }, [fulfillmentDefinitionId]);

  useEffect(() => {
    if (fulfillmentDefinitionId) {
      load();
    }
  }, [load, fulfillmentDefinitionId, open]);

  const setOpenReview = () => {};

  const orderReviewTooltip: Record<string, string> = {
    collectPayment: t("component.orderDetail.collectPayment"),
    replyMessage: t("component.orderDetail.replyMessage"),
    refundOrder: t("component.orderDetail.refundOrder"),
  };

  return (
    <div className={styles.row}>
      <NameModal
        error={errorFulfillment}
        fulfillmentNameId={fulfillmentDefinitionId}
        orderlineId={""}
        fulfillmentNames={fulfillmentDefintions}
        updateFulfillmentName={async (
          id: string,
          fulfillmentDefinitionId: string
        ) => {
          try {
            await updateFulfillmentDefinition(orderId, fulfillmentDefinitionId);
            setOpen("");
          } catch (err) {
            if (err instanceof Error) {
              console.error("Error Fulfillment", err.message);
            }
          }
        }}
        open={open === "name"}
        onClose={() => setOpen("")}
      />
      <StatesModal
        error={errorFulfillment}
        fulfillmentDefinitionId={fulfillmentDefinitionId}
        orderlineId={""}
        fulfillmentNames={fulfillmentDefintions}
        updateFulfillmentName={async (
          id: string,
          fulfillmentDefinitionId: string
        ) => {
          try {
            await updateFulfillmentDefinition(orderId, fulfillmentDefinitionId);
            setOpen("");
          } catch (err) {
            if (err instanceof Error) {
              console.error("Error Fulfillment", err.message);
            }
          }
        }}
        state={fulfillmentState}
        setState={(name, w3w, images) => {
          try {
            setState(name, w3w, images);
            setOpen("");
          } catch (err) {}
        }}
        open={open === "states"}
        onClose={() => setOpen("")}
      />

      <ButtonsContainer
        padding="0 0 0 0"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        {fulfillmentLevel === "orderline" ||
        fulfillmentLevel === "none" ? null : fulfillmentDefinition.id ? (
          <div>
            {fulfillmentState ? (
              <div>
                <FulfillmentModal
                  error={errorFulfillment}
                  fulfillmentNameId={fulfillmentDefinitionId}
                  orderlineId={""}
                  fulfillmentNames={fulfillmentDefintions}
                  updateFulfillmentName={async (
                    id: string,
                    fulfillmentDefinitionId: string
                  ) => {
                    try {
                      await updateFulfillmentDefinition(
                        orderId,
                        fulfillmentDefinitionId
                      );
                      setOpen("");
                    } catch (err) {
                      if (err instanceof Error) {
                        console.error("Error Fulfillment", err.message);
                      }
                    }
                  }}
                  open={fulfillmentState === open}
                  onClose={() => setOpen("")}
                  status={open}
                  onSetStatus={(name, w3w) => {
                    try {
                      setState(name, w3w, []);
                      setOpen("");
                    } catch (err) {}
                  }}
                />
                <FlexContainer width="100%" flexDirection="column">
                  {fulfillmentDefinitionId ? (
                    <Text
                      text={
                        fulfillmentDefintions.find(
                          (item) => item.value === fulfillmentDefinitionId
                        )?.label || ""
                      }
                      fontWeight={700}
                      padding="0.5em 0 0 0"
                    />
                  ) : null}
                  <Button
                    minWidth="12em"
                    icon={statesIcon}
                    label={fulfillmentState}
                    theme={fulfillmentState ? "blue" : "white"}
                    buttonClassname={styles.insideButton}
                    containerClassname={styles.button}
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpen("states");
                    }}
                  />
                </FlexContainer>
              </div>
            ) : (
              <Button
                icon={statesBlackIcon}
                disable={orderState === "cancelled" || orderState === "expired"}
                theme="white"
                containerClassname={styles.buttonSize}
                label={t("component.orderFulfillment.setState")}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen("states");
                }}
              />
            )}
          </div>
        ) : (
          <Button
            theme="white"
            containerClassname={styles.buttonSize}
            label={t("component.orderFulfillment.setState")}
            onClick={(e) => {
              e.stopPropagation();
              setOpen("states");
            }}
          />
        )}
        {reviewArray
          .filter(
            (item) => item[0] === "collectPayment" || item[0] === "replyMessage"
          )
          .map((item, index) => {
            const IconComponent = item[1]
              ? orderReviewIconMap[item[0]]
              : orderReviewIconNormalMap[item[0]];

            return (
              <Tooltip key={index} title={orderReviewTooltip[item[0]]}>
                <div
                  className={styles.iconReview}
                  onClick={() => {
                    item[0] === "collectPayment"
                      ? history.push(`/orders/${orderId}/payments`)
                      : item[0] === "replyMessage"
                      ? onComment()
                      : item[0] === "refundOrder"
                      ? history.push(`/orders/${orderId}/payments`)
                      : setOpenReview();
                  }}
                >
                  {item[0] === "replyMessage" ? (
                    <Badge count={commentsLength}>
                      <IconComponent />
                    </Badge>
                  ) : (
                    <IconComponent />
                  )}
                </div>
              </Tooltip>
            );
          })}
      </ButtonsContainer>
    </div>
  );
};

export default ActionRowOrder;
