import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import { RootState } from "../../store";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";

import AssetList from "../../components/AssetList";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import AssetForm from "../../components/AssetForm";
import { Asset } from "../../utils/types";
import {
  AssetMapResponse,
  createAsset,
  defaultAsset,
  deleteAssetImage,
  deleteAssets,
  deleteMultipleAssets,
  eventAssetMap,
  parseAsset,
  readAsset,
  updateAsset,
  uploadAssetFile,
} from "../../services/assets";
import { message } from "antd";
import APIError from "../../utils/APIError";
import { Placement } from "@innomius/ravent-typescript-types";
import AssetDetail from "../../components/AssetDetail";
import { SideMenu } from "../../components/SideMenu/SideMenu";
import ExportCVSFile from "../../components/ExportCVSFile";
import DeleteAssetsModal from "../../components/DeleteAssetsModal";
import { DoubleButton } from "../../components/DoubleButton";
import qs from "qs";
import { assetTextSearchList } from "../../store/assetsTextSearch";
import secureLocalStorage from "react-secure-storage";
import CVSFUploader from "../../components/CVSFUploader";
import { exportMultipleAssetsCVS } from "../../services/export";
import {
  parseQueryParameter,
  removeEmptyStringFromObject,
} from "../../utils/order";
import mapIcon from "../../assets/map.svg";
import listIcon from "../../assets/view-list.svg";
import AssetMapView from "../../components/AssetMapView";
import TabNavBar from "../../components/TabNavBar";
import ErrorBox from "../../components/ErrorBox";
import { Filter } from "../../services/orders";
import SideFiltersAssets from "../../components/SideFilters/SideFiltersAssets";
import CrossIcon from "../../components/Icons/CrossIcon";
import Search from "antd/es/input/Search";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { ContainerTitle } from "../../components/ContainerTitle";
import { GridContainer } from "../../components/GridContainer/GridContainer";
import { Button } from "../../components/Button";
import { ButtonsContainer } from "../../components/ButtonsContainer";
import { EditableTitle } from "../../components/EditableTitle";
import { Card } from "../../components/Card/Card";
import { EmptyList } from "../../components/List/EmptyList";

interface Props {}

interface RouteParams {
  id: string;
}

const inputString =
  "createdBy;deleted;createdAt;updatedAt;__v;_id;organization_id;name;enabled;externalId;type;subType;notes;hasImage;tags;description;data;vehicleLicensePlate;vehicleSerial;vehicleBrand;vehicleModel;vehicleCapacity.quantity;vehicleCapacity.units;vehicleWeight.max;vehicleWeight.current;vehicleWeight.units;vehicleLastKnownLocation.timestamp;vehicleLastKnownLocation.coordinates.latitude;vehicleLastKnownLocation.coordinates.longitude;";

const Assets: React.FC<Props> = () => {
  const { t } = useTranslation();

  function useQuery() {
    const { search } = useLocation();
    return search;
  }
  const query = useQuery();

  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const [resize, setSize] = useState(false);

  const assets = useSelector((state: RootState) => state.assetTextSearch);
  const [hitsPerPage, setHitsPerPage] = useState(10);
  const [selectedLocations, setSelectedLocations] = useState<
    { id: string; name: string }[]
  >([]);

  const [page, setPage] = useState(
    (qs.parse(query.substring(1)).page as string) || assets.list.page
  );

  const [view, setView] = useState<string>(
    (qs.parse(query.substring(1)).view as string) || "list"
  );

  const [projection, setProjection] = useState<string[]>([]);

  const [d, setD] = useState<boolean>(false);

  const [tags, setTags] = useState<Filter>(parseQueryParameter(query, "tags"));
  const [enabled, setEnabled] = useState<Filter>(
    parseQueryParameter(query, "enabled")
  );
  const [name, setName] = useState<Filter>(parseQueryParameter(query, "name"));
  const [type, setType] = useState<Filter>(parseQueryParameter(query, "type"));

  const { id } = useParams<RouteParams>();
  const [asset, setAsset] = useState(defaultAsset);
  const [loading, setLoading] = useState<string>("");
  const [edit, setEdit] = useState(false);
  const location = useLocation();
  const [open, setModal] = useState<string>("");
  const [sort, setSortBy] = useState("name:desc");
  const [search, setSearch] = useState("");
  const [current, setCurrent] = useState(0);
  const [errorDetails, setErrorDetails] = useState<string>("");

  const [assetsMap, setAssetMap] = useState<AssetMapResponse>({
    assetsDuplicated: [],
    running: [],
    notRunning: [],
    runningEventsWithNoneAssets: [],
  });
  const [error, setError] = useState("");

  type StateMap = {
    [key: string]: [Filter, React.Dispatch<React.SetStateAction<Filter>>];
  };

  const stateMap: StateMap = {
    tags: [tags, setTags],
    name: [name, setName],
    type: [type, setType],
    enabled: [enabled, setEnabled],
  };

  const history = useHistory();
  const load = useCallback(async () => {
    dispatch(
      assetTextSearchList({
        page,
        records_per_page: hitsPerPage,
        sort,
        search,
        count: assets.list.count,
        tags,
        type,
        name,
        view,
        enabled,
      })
    );

    const states = {
      page: page as string,
      view: view as string,

      search: search,

      name: name.include + "|" + name.items.map((item) => item.key).join(","),
      type: type.include + "|" + type.items.map((item) => item.key).join(","),
      tags: tags.include + "|" + tags.items.map((item) => item.key).join(","),
      enabled:
        enabled.include + "|" + enabled.items.map((item) => item.key).join(","),
    };

    const assetsPathRegex = /^\/assets\/\w+$/;

    if (assetsPathRegex.test(location.pathname)) {
      return;
    } else {
      const params = new URLSearchParams(removeEmptyStringFromObject(states));
      history.replace(`/assets?${params}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    page,
    hitsPerPage,
    sort,
    search,
    view,
    history,
    name,
    type,
    tags,
    enabled,
  ]);

  useEffect(() => {
    load();
  }, [load]);

  const loadAssetMap = useCallback(async () => {
    try {
      const res = await eventAssetMap({
        venue_id: (secureLocalStorage.getItem("venueId") as string) || "",
        dtReference: "2024-05-23T00:00:00.000",
      });
      setAssetMap(res);
    } catch (err) {
      if (err instanceof APIError) {
        setError(err.message);
      }
    }
    const states = {
      page: page as string,
      view: view as string,
    };
    const assetsPathRegex = /^\/assets\/\w+$/;

    if (assetsPathRegex.test(location.pathname)) {
      return;
    } else {
      const params = new URLSearchParams(states);
      history.push(`/assets?${params.toString()}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    loadAssetMap();
  }, [loadAssetMap]);

  useEffect(() => {
    document.title = "RAVENT APP :: Assets";
  });

  const onChange = <P extends keyof Asset>(prop: P, value: Asset[P]) =>
    setAsset({ ...asset, [prop]: value });

  const onCreate = async () => {
    try {
      setLoading("create");
      const res = await createAsset(asset);
      setLoading("");

      message.success({
        content: t("container.assets.successCreation"),
        style: {
          marginTop: "5%",
        },
      });
      setAsset(defaultAsset);
      setCurrent(0);
      history.push(`/assets/${parseAsset(res).id}`);
      load();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const selected: Filter[] = [];
  Object.values(stateMap).forEach(([filter]) => {
    selected.push(filter);
  });

  const onEdit = async (data: Asset, placement: Placement) => {
    try {
      setLoading("edit");
      await updateAsset(data.id, data, placement);
      setLoading("");
      message.success({
        content: t("container.assets.successUpdated"),
        style: {
          marginTop: "5%",
        },
      });
      if (location.pathname.includes("placement")) {
        history.push(`/assets/${data.id}/placement`);
      } else history.push(`/assets/${data.id}`);

      load();
      setEdit(false);
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onDelete = async (id: string, replace: string) => {
    try {
      setLoading("delete");
      await deleteAssets(id, replace);
      setLoading("");
      message.success(t("container.assets.successDelete"));
      history.push("/assets");
      load();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onGetDetails = async (id: string) => {
    try {
      setLoading("details");
      const res = await readAsset(id);
      setAsset(res);
      history.push(`/assets/${id}`);
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        setErrorDetails(err.message);
      }
    }
  };

  const hasNonEmptyState = Object.values(stateMap).some(
    ([filter]) => filter.items.length > 0
  );

  const handleTagClose = (facetGroup: string, option: string) => {
    const [currentState, setState] = stateMap[facetGroup];
    const itemToRemoveIndex = currentState.items.findIndex(
      (item) => item.key === option
    );
    if (itemToRemoveIndex !== -1) {
      setState({
        ...currentState,
        items: currentState.items.filter(
          (_item, index) => index !== itemToRemoveIndex
        ),
      });
    }
  };

  function generateTag(label: Filter, _onClose: (label: string) => void) {
    if (!label || !label.items || label.items.length === 0) {
      return null;
    }
    return label.items.map((tagItem: { key: string }, index: number) => (
      <div
        className={styles.tag}
        key={index}
        style={{ margin: "0 0.3em 0.3em 0" }}
      >
        <div style={{ color: "#1890ff" }}>{tagItem.key.trim()}</div>
        <button
          className={styles.button}
          name="remove"
          onClick={() => handleTagClose(label.name, tagItem.key)}
        >
          <CrossIcon color={"#1890ff"} />
        </button>
      </div>
    ));
  }

  const closeAllTags = () => {
    Object.values(stateMap).forEach(([_filter, setFilter]) =>
      setFilter((prevFilter) => ({ ...prevFilter, items: [] }))
    );
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  if (!auth.loading && !auth.user) {
    return <Redirect from="*" to="/" />;
  }

  return (
    <LayouWithSideBar>
      <CVSFUploader
        loading={loading === "multiple"}
        handleSubmit={async (file) => {
          try {
            setLoading("multiple");
            await uploadAssetFile(
              file,
              secureLocalStorage.getItem("venueId") as string
            );
            setLoading("");
            setModal("");
            load();
          } catch (err) {
            setLoading("");
            if (err instanceof APIError) {
              message.error(err.message);
            }
          }
        }}
        open={open === "multiple"}
        onClose={() => setModal("")}
      />

      <DeleteAssetsModal
        loading={loading === "delete"}
        data={selectedLocations}
        open={open === "delete"}
        onClose={() => setModal("")}
        assets={assets.list.hits}
        onDelete={async (replace) => {
          try {
            setLoading("delete");
            await deleteMultipleAssets(
              selectedLocations.map((item) => item.id),
              replace
            );
            setLoading("");
            setModal("");
            load();
            setSelectedLocations([]);
          } catch (err) {
            setLoading("");
          }
        }}
      />

      <ExportCVSFile
        inputString={inputString}
        setExcludedIds={(values) => setProjection(values)}
        loading={loading === "export"}
        all={!selectedLocations.length}
        count={
          selectedLocations.length >= 1
            ? selectedLocations.length
            : assets.list.count
        }
        data={
          selectedLocations.length >= 1 ? selectedLocations : assets.list.hits
        }
        open={open === "export"}
        onClose={() => setModal("")}
        onExport={async () => {
          try {
            setLoading("export");
            selectedLocations.length
              ? await exportMultipleAssetsCVS({
                  ids: selectedLocations.map((item) => item.id),
                  all: false,
                  projection: projection,
                })
              : await exportMultipleAssetsCVS({
                  all: true,
                  projection,
                });
            setLoading("");
            setModal("");
            load();
          } catch (err) {
            setLoading("");
            if (err instanceof APIError) {
              message.error(err.message);
            }
          }
        }}
      />

      <GridContainer columns={resize ? "0.24fr 1fr" : "3em 1fr"}>
        <div
          style={{
            backgroundColor: "#fff",
            height: "100%",
            borderLeft: "1px solid #dedede",
          }}
        >
          <SideFiltersAssets
            setSize={setSize}
            selected={selected}
            facets={assets.list.facets}
            shrink={resize}
            onChange={(facetGroup, option) => {
              setPage(0);
              const map = stateMap;
              if (map[facetGroup]) {
                const [currentState, setState] = map[facetGroup];
                const existingIndex = currentState.items.findIndex(
                  (item) => item.key === option
                );
                let updatedItems;
                if (existingIndex !== -1) {
                  updatedItems = currentState.items.filter(
                    (item) => item.key !== option
                  );
                } else {
                  updatedItems = [
                    ...currentState.items,
                    {
                      key: option,
                      include: currentState?.items.every(
                        (item) => item.include === true
                      ),
                    },
                  ];
                }
                setState({
                  ...currentState,
                  items: updatedItems,
                });
              }
            }}
            onChangeExlusion={(facetGroup, value) => {
              const map = stateMap;
              const [currentState, setState] = map[facetGroup];
              if (currentState && currentState.items) {
                setState((prevState) => ({
                  ...prevState,
                  items: prevState.items.map((item) => ({
                    ...item,
                    include: value, // Set all items to the same value
                  })),
                }));
              }
            }}
            onChangeSelect={(facetGroup, value) => {
              const map = stateMap;
              const [currentState, setState] = map[facetGroup];
              if (currentState) {
                setState({
                  ...currentState,
                  include: value as "Any" | "All",
                });
              }
            }}
          />
        </div>

        <div className={styles.container}>
          <FlexContainer justifyContent="space-between" alignItems="center">
            <ContainerTitle
              size="medium"
              label={t("container.assets.label")}
              count={assets.list.count.toString()}
            />
          </FlexContainer>

          <FlexContainer alignItems="center" justifyContent="space-between">
            <div className={styles.searchContainer}>
              <Search
                style={{ margin: "1em 0" }}
                placeholder={t("container.assets.search")}
                value={search}
                onSearch={load}
                onChange={(text) => {
                  setSearch(text.target.value);
                }}
              />
            </div>
          </FlexContainer>

          <FlexContainer
            flexWrap="wrap"
            justifyContent="flex-start"
            padding="1em 0 0em 0"
          >
            {tags.items.filter((item) => item.key.trim()).length > 0 &&
              generateTag(tags, (label) =>
                setTags((prevFilter) => ({
                  ...prevFilter,
                  items: prevFilter.items.filter((item) => item.key !== label),
                }))
              )}

            {enabled.items.filter((item) => item.key.trim()).length > 0 &&
              generateTag(enabled, (label) =>
                setEnabled((prevFilter) => ({
                  ...prevFilter,
                  items: prevFilter.items.filter((item) => item.key !== label),
                }))
              )}

            {name.items.filter((item) => item.key.trim()).length > 0 &&
              generateTag(name, (label) =>
                setName((prevFilter) => ({
                  ...prevFilter,
                  items: prevFilter.items.filter((item) => item.key !== label),
                }))
              )}
            {type.items.filter((item) => item.key.trim()).length > 0 &&
              generateTag(type, (label) =>
                setType((prevFilter) => ({
                  ...prevFilter,
                  items: prevFilter.items.filter((item) => item.key !== label),
                }))
              )}
            {hasNonEmptyState && (
              <button
                style={{ cursor: "pointer", textDecorationLine: "underline" }}
                onClick={closeAllTags}
              >
                Clear All
              </button>
            )}
          </FlexContainer>

          <AssetDetail
            edit={edit}
            load={load}
            errorDetails={errorDetails}
            d={d}
            setD={setD}
            onDeleteImage={async (id) => {
              try {
                await deleteAssetImage(id);
                message.success(t("container.assets.successDeleteImage"));
                setD(false);
                load();
              } catch (err) {
                if (err instanceof APIError) message.error(err.message);
              }
            }}
            setEdit={setEdit}
            onDelete={(id) => onDelete(id, "")}
            onSave={(data, placement) => onEdit(data, placement)}
            open={
              location.pathname === `/assets/${id}` ||
              location.pathname === `/assets/${id}/placement` ||
              location.pathname === `/assets/${id}/vehicleDetails`
            }
            path={`/assets/${id}`}
            onClose={() => {
              history.replace("/assets");
              setAsset(defaultAsset);
              load();
            }}
            loading={loading}
          />
          <SideMenu
            width="60%"
            open={
              location.pathname === `/assets/new` ||
              location.pathname === `/assets/new/placement`
            }
            onClose={() => {
              setAsset(defaultAsset);

              history.push("/assets");
            }}
          >
            <FlexContainer
              padding="1.5em 0 1em 0"
              alignItems="center"
              justifyContent="space-between"
            >
              <EditableTitle
                placeholder="Name"
                value={asset.name}
                titleClassname={styles.titleClass}
                onChange={(value) => onChange("name", value)}
              />

              <ButtonsContainer>
                {current === 0 ? (
                  <Button
                    label={t("component.assetForm.cancel")}
                    theme="white"
                    onClick={() => {
                      setAsset(defaultAsset);
                      history.push("/assets");
                    }}
                  />
                ) : (
                  <Button
                    label={t("component.assetForm.cancel")}
                    theme="white"
                    onClick={() => {
                      setAsset(defaultAsset);
                      history.push("/assets");
                    }}
                  />
                )}
                {current === 1 && asset.type === "generic" ? (
                  <Button
                    label={
                      loading === "create"
                        ? "..."
                        : t("component.assetForm.save")
                    }
                    theme="blue"
                    onClick={() => {
                      if (loading) {
                        return;
                      }
                      onCreate();
                    }}
                  />
                ) : current === 2 && asset.type === "vehicle" ? (
                  <Button
                    label={
                      loading === "create"
                        ? "..."
                        : t("component.assetForm.save")
                    }
                    theme="blue"
                    onClick={() => {
                      if (loading) {
                        return;
                      }
                      onCreate();
                    }}
                  />
                ) : (
                  <Button
                    label={
                      loading === "create"
                        ? "..."
                        : t("component.assetForm.next")
                    }
                    theme="blue"
                    onClick={() => {
                      next();
                    }}
                  />
                )}
              </ButtonsContainer>
            </FlexContainer>

            <AssetForm
              current={current}
              setCurrent={setCurrent}
              next={next}
              prev={prev}
              onChange={onChange}
              data={asset}
            />
          </SideMenu>

          <TabNavBar
            options={[
              {
                id: "assets",
                label: `${t("container.assets.label")}`,
                path: `/assets`,
                onClick: () => (document.title = "RAVENT APP :: Assets"),
              },
            ]}
          >
            <FlexContainer padding="0 0 1em 0" justifyContent="space-between">
              <div>
                {view === "map" ? (
                  <Button
                    icon={listIcon}
                    theme="white"
                    onClick={() => {
                      setView("list");
                      const params = new URLSearchParams(
                        removeEmptyStringFromObject({
                          view: "list",
                        })
                      );

                      history.replace(`/assets?${params.toString()}`);
                    }}
                    label={t("component.venueDetailHeader.listView")}
                  />
                ) : (
                  <Button
                    icon={mapIcon}
                    theme="white"
                    onClick={() => {
                      setView("map");
                      setPage(0);
                      const params = new URLSearchParams(
                        removeEmptyStringFromObject({
                          view: "map",
                        })
                      );
                      history.replace(`/assets?${params.toString()}`);
                    }}
                    label={t("component.venueDetailHeader.mapView")}
                  />
                )}
              </div>
              <FlexContainer justifyContent="flex-end">
                <div style={{ margin: "0 1em" }}>
                  <DoubleButton
                    theme="white"
                    options={[
                      {
                        label: `${t("container.assets.importMultiple")}`,
                        click: () => setModal("multiple"),
                      },
                      {
                        label: `${t("container.assets.export")}`,
                        click: () => setModal("export"),
                      },

                      {
                        label: `${t("container.assets.delete")}`,
                        click: () => setModal("delete"),
                      },
                    ]}
                    buttonClassname={styles.buttonsContainer}
                    label={t("container.assets.actions")}
                  />
                </div>
                <div style={{ marginLeft: "1em" }}>
                  <Button
                    label={t("container.assets.createAsset")}
                    onClick={() => history.push("/assets/new")}
                  />
                </div>
              </FlexContainer>
            </FlexContainer>
            {error && view === "map" ? (
              <Card>
                <ErrorBox error={error} />
              </Card>
            ) : !assets.list.hits.length && !assets.error && !assets.loading ? (
              <Card>
                <EmptyList
                  instructions={""}
                  text={t("container.assets.emptyList")}
                />
              </Card>
            ) : view === "map" ? (
              <>
                <AssetMapView data={assetsMap} />
              </>
            ) : (
              <AssetList
                selectedLocations={selectedLocations}
                setSelectedLocations={setSelectedLocations}
                error={assets.error}
                loading={assets.loading}
                data={assets.list.hits}
                page={parseInt(page as string)}
                total={assets.list.count}
                hitsPerPage={hitsPerPage}
                onPageChange={setPage}
                onHitsPerPageChange={setHitsPerPage}
                pagination
                sortable
                onChangeSortOrder={setSortBy}
                path={"/assets"}
                onDetails={(id) => onGetDetails(id)}
                onDelete={(id, r) => onDelete(id, r)}
              />
            )}
          </TabNavBar>
        </div>
      </GridContainer>
    </LayouWithSideBar>
  );
};

export default Assets;
