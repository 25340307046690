import React, { useState } from "react";
import {
  Message,
  OfficialMedia,
  Visitor,
} from "@innomius/ravent-typescript-types";
import { formatDate } from "../../utils/times";
import convIcon from "./conversation.svg";
import VisitorChat from "../VisitorChat";
import styles from "./styles";
import dayjs from "dayjs";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";

interface VisitorListProp {
  data: Visitor;
  locationColumn?: boolean;
  messages: Message[];
  path: string;
  officialMedia: OfficialMedia[];
}

const VisitorItemList: React.FC<VisitorListProp> = ({
  data,
  locationColumn,
  messages,
  officialMedia,
}) => {
  const [open, setOpen] = useState(false);
  const visitorMessages = messages.filter(
    (item) => item.conversationId === data.conversationId
  );
  const lastMessage = visitorMessages?.[visitorMessages?.length - 1];

  const statusMessage = () => {
    if (visitorMessages.length && lastMessage.visitor) {
      const minutes = dayjs(new Date()).diff(
        dayjs(lastMessage.readTimestamp),
        "minutes"
      );
      if (minutes >= 30) {
        return styles.redPoint;
      }
      if (minutes < 30 && minutes > 1) {
        return styles.yellowPoint;
      }
      if (minutes > 1 || !lastMessage.readTimestamp) {
        return styles.bluePoint;
      }
    }
  };

  return (
    <ListRow
      template={
        locationColumn ? "1fr 1fr 1fr 1fr 1fr 1fr" : "1fr 1fr 1fr 1fr 1fr"
      }
      hover={true}
      key={data.id}
    >
      <ListLabel value={`${data.firstName} ${data.lastName}` || "-"} />
      <ListLabel value={data.reference || "-"} />

      <ListLabel value={data.phoneNumber || "-"} textAlign="left" />
      <ListLabel
        value={formatDate(data.checkoutDate, "MIDDLE")}
        textAlign="left"
      />
      {locationColumn ? (
        <ListLabel value={data.locationName} textAlign="center" />
      ) : null}

      <div className={styles.center} onClick={() => setOpen(true)}>
        <img src={convIcon} alt="conversationVisitor" className={styles.icon} />
        {visitorMessages?.length.toString()}
        <span
          className={
            !visitorMessages.length || !lastMessage.visitor
              ? styles.bluePoint
              : statusMessage()
          }
        />
      </div>
      <VisitorChat
        officialMedia={officialMedia}
        messages={visitorMessages}
        onClose={() => setOpen(false)}
        data={data}
        open={open}
        status={() => statusMessage()}
        sendMessage={() => {}}
      />
    </ListRow>
  );
};

export default VisitorItemList;
