const translation = {
  en: {
    cancel: "Cancel",
    save: "Save",
    name: "Name",
    description: "Description",
    details: "Details",
    properties: "Properties",
    loadType: "Load Type",
    editLoad: "Edit Load",
    person: "Person",
    package: "Package",
    suitcase: "Suitcase",
    box: "Box",
    tags: "Tags",
  },
  es: {
    tags: "Etiquetas",
    suitcase: "Maleta",
    box: "Caja",
    cancel: "Cancelar",
    save: "Guardar",
    name: "Nombre",
    description: "Descripción",
    details: "Detalles",
    properties: "Propiedades",
    loadType: "Tipo de Carga",
    editLoad: "Editar Carga",
    person: "Persona",
    package: "Paquete",
  },
  de: {},
};

export default translation;
