import React, { useCallback, useEffect, useState } from "react";
import {
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import { Entity } from "@innomius/ravent-typescript-types";
import TabNavBar from "../../components/TabNavBar";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { ContainerTitle } from "../../components/ContainerTitle";

import styles from "./styles.module.css";
import {
  OrgSettingsResponse,
  getOrgSettings,
  readOrg,
  updateOrg,
  updateOrgSettings,
} from "../../services/entities";
import { Loading } from "../../components/Loading";
import { useDispatch } from "react-redux";
import { integrationList } from "../../store/integrations";
import APIError from "../../utils/APIError";
import { useTranslation } from "react-i18next";
import secureLocalStorage from "react-secure-storage";
import OrgDetailComponent from "../../components/OrgDetailComponent";
import { message } from "antd";
import OrgForm from "../../components/OrgForm";
import { defaultW3W, w3wToCoordinates } from "../../services/maps";
import { CountsResponse, countsReport } from "../../services/events";
import BillingList from "../../components/BillingList";
import OrgTags from "../../components/OrgTags";
import { Category } from "../../utils/types";
import qs from "qs";
import { deleteRol, Role, roleList } from "../../services/roles";
import RoleList from "../../components/RoleList";
import { EmptyList } from "../../components/List/EmptyList";
import { EditableTitle } from "../../components/EditableTitle";

interface RouteParams {
  id: string;
}

interface Props extends RouteComponentProps<RouteParams> {}

const MyCompany: React.FC<Props> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [entity, setEntity] = useState<Entity | null>(null);
  const [loading, setLoading] = useState<string>("");
  const [errorDetail, setErrorDetail] = useState("");
  const [loadingBilling, setLoadingBilling] = useState<string>("");
  const [sort, setSortBy] = useState("month:desc");
  const [categories, setCategories] = useState<OrgSettingsResponse>();
  const [roles, setRoles] = useState<Role[]>([]);
  const [page, setPage] = useState(0);
  const [records_per_page, setRecordsPerPage] = useState(10);
  const [error, setError] = useState("");

  function useQuery() {
    const { search } = useLocation();
    return search;
  }
  const query = useQuery();
  const [content, setContent] = useState(
    (qs.parse(query.substring(1)).content as string) || "orders"
  );

  const [edit, setEdit] = useState<boolean>(false);
  const [counts, setCounts] = useState<CountsResponse[]>([]);

  const [locationW3W, setLocationW3W] = useState(defaultW3W);

  const load = useCallback(async () => {
    dispatch(integrationList({}));
    try {
      setLoading("diveshop");
      const res = await readOrg(secureLocalStorage.getItem("orgId") as string);
      setEntity(res);

      setLoading("");

      if (res?.address.w3w) {
        const resW = await w3wToCoordinates(res?.address.w3w);
        setLocationW3W(resW);
        return;
      }
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        setErrorDetail(err.message);
      }
    }
  }, [dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  const loadSettings = useCallback(async () => {
    try {
      setLoading("details");

      const res = await getOrgSettings(
        secureLocalStorage.getItem("orgId") as string
      );
      setCategories(res);
      setLoading("");
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        console.log(err.message);
      }
    }
  }, []);

  useEffect(() => {
    loadSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadRoles = useCallback(async () => {
    try {
      setLoading("roles");

      const res = await roleList({ page, records_per_page });
      setRoles(res.data);

      setLoading("");
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        setError(err.message);
      }
    }
  }, [page, records_per_page]);

  useEffect(() => {
    loadRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadCount = useCallback(async () => {
    try {
      setLoadingBilling("billing");
      const res = await countsReport({
        dateField: "orderChannelCreatedAt",
        ordersCost: 0.4,
        sort,
        eventsCost: 0.035,
      });
      setCounts(res);
      setLoadingBilling("");
    } catch (err) {
      setLoadingBilling("");
      if (err instanceof APIError) {
        console.log(err.message);
      }
    }
  }, [sort]);

  useEffect(() => {
    loadCount();
  }, [loadCount]);

  if (loading) {
    return <Loading />;
  }

  if (!entity) {
    return (
      <EmptyList
        instructions={""}
        text={t("container.entityDetail.notFound")}
        label={t("container.entityDetail.back")}
        onClick={() => history.push("/teams")}
        error={errorDetail}
      />
    );
  }

  const optionsDiveshops = [
    {
      id: "entitiesDivers",
      label:
        entity.type === "diveshop"
          ? `${t("container.entityDetail.divers")}`
          : `${t("container.entityDetail.users")}`,
      path: `/teams/${secureLocalStorage.getItem("orgId")}/users`,
      onClick: () => (document.title = "RAVENT APP :: Org :: Divers"),
    },
    {
      id: "entitiesDetail",
      label: t("container.entityDetail.detailsLabel"),
      path: `/teams/${secureLocalStorage.getItem("orgId")}`,
      onClick: () => (document.title = "RAVENT APP :: Org :: Details"),
    },
  ];

  const optionsOperators = [
    {
      id: "entitiesDetail",
      label: t("container.entityDetail.detailsLabel"),
      path: `/organization`,
      onClick: () => (document.title = "RAVENT APP :: Org :: Details"),
    },
    {
      id: "billing",
      label: t("container.entityDetail.billing"),
      path: `/organization/billing`,
      onClick: () => (document.title = "RAVENT APP :: Org :: Billing"),
    },
    {
      id: "tags",
      label: t("container.entityDetail.tags"),
      path: `/organization/tags`,
      onClick: () => (document.title = "RAVENT APP :: Org :: Tags"),
    },
    {
      id: "roles",
      label: t("container.myCompany.roles"),
      path: `/organization/roles`,
      onClick: () => (document.title = "RAVENT APP :: Org :: Roles"),
    },
  ];

  const onChange = <P extends keyof Entity>(prop: P, value: Entity[P]) =>
    setEntity({ ...entity, [prop]: value });

  const onEditOrg = async () => {
    try {
      setLoading("edit");
      await updateOrg(entity.id, {
        name: entity.legalName,
        phone: entity.phone
          ? entity.phone.includes("+")
            ? `${entity.phone}`
            : `+${entity.phone}`
          : null,
        email: entity.email,
        address: entity.address,
      });
      message.success(t("container.profileDetail.successUpdatedOrg"));
      setLoading("");
      setEdit(false);
      load();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(err.messages);
      }
    }
  };

  const onEditSettings = async (cat: OrgSettingsResponse) => {
    try {
      setLoading("save");
      await updateOrgSettings(
        secureLocalStorage.getItem("orgId") as string,
        cat
      );
      message.success(t("container.profileDetail.successUpdateSettings"));
      setLoading("");
      loadSettings();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(err.messages);
      }
    }
  };

  const onDeleteRol = async (id: string) => {
    try {
      await deleteRol(id);
      message.success(t("container.myCompany.successDeletedRol"));
      setLoading("");
      loadRoles();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(err.messages);
      }
    }
  };

  return (
    <LayouWithSideBar>
      <div className={styles.container}>
        {edit ? (
          <FlexContainer justifyContent="space-between">
            <EditableTitle
              titleClassname={styles.size}
              value={entity.legalName}
              onChange={(value) => onChange("legalName", value)}
            />
          </FlexContainer>
        ) : (
          <FlexContainer padding="0em 0 0 0" justifyContent="space-between">
            <ContainerTitle size="medium" label={entity.legalName} />
          </FlexContainer>
        )}

        <TabNavBar
          options={
            entity.type === "diveshop" ? optionsDiveshops : optionsOperators
          }
        >
          <Switch>
            <Route path={`/organization`} exact>
              {edit ? (
                <OrgForm
                  setLocationW3W={setLocationW3W}
                  locW3W={locationW3W}
                  onChange={onChange}
                  loading={loading}
                  entity={entity}
                  load={load}
                  setEdit={setEdit}
                  onEditOrg={onEditOrg}
                />
              ) : (
                <OrgDetailComponent
                  setEdit={setEdit}
                  loading={loading}
                  data={entity}
                />
              )}
            </Route>
            <Route path={`/organization/billing`} exact>
              <BillingList
                onChangeSortOrder={setSortBy}
                loading={loadingBilling === "billing"}
                error={""}
                data={counts}
              />
            </Route>

            <Route path={`/organization/roles`} exact>
              <RoleList
                page={page}
                total={roles.length}
                deleteRol={onDeleteRol}
                loading={loading === "roles"}
                error={error}
                hitsPerPage={records_per_page}
                data={roles}
                onPageChange={setPage}
                onHitsPerPageChange={(number) => setRecordsPerPage(number)}
                pagination={true}
              />
            </Route>
            <Route path={`/organization/tags`}>
              {categories?.facetSettings && (
                <OrgTags
                  content={content}
                  setContent={setContent}
                  load={loadSettings}
                  loading={loading}
                  locationsCategories={categories.facetSettings.locations}
                  assetCategories={categories.facetSettings.assets}
                  userCategories={categories.facetSettings.users}
                  eventCategories={categories.facetSettings.fulfillmentEvents}
                  orderCategories={categories.facetSettings.orders}
                  setCategories={(
                    content: string,
                    newCategories: Category[]
                  ) => {
                    const updatedOrgSettings: OrgSettingsResponse = {
                      ...categories,
                      facetSettings: {
                        ...categories.facetSettings,
                        [content]: newCategories,
                      },
                    };

                    setCategories(updatedOrgSettings);
                    onEditSettings(updatedOrgSettings);
                  }}
                />
              )}
            </Route>
          </Switch>
        </TabNavBar>
      </div>
    </LayouWithSideBar>
  );
};

export default MyCompany;
