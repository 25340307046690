import React, { useEffect, useState } from "react";

import { OrderListSkeleton } from "../../ListSkeletons";
import { useTranslation } from "react-i18next";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { ListHeaderLabel } from "../../List/ListHeaderLabel";
import { ListHeader } from "../../List/ListHeader";
import { List } from "../../List";
import styles from "./styles";
import Item from "./Item";
import {
  FulfillmentEvent,
  FulfillmentState,
} from "@innomius/ravent-typescript-types";

interface PlannedListProps {
  error: string;
  loading: boolean;
  fulfillmentStates: FulfillmentState[];
  data: FulfillmentEvent[];
  onChangeSortOrder?: (sort: string) => void;
  selected: FulfillmentEvent[];
  setSelected: (value: FulfillmentEvent[]) => void;
}

const PlannedList: React.FC<PlannedListProps> = ({
  error,
  loading,
  data,
  onChangeSortOrder,
  //   page,
  //   total,
  //   hitsPerPage,
  //   onHitsPerPageChange,
  //   onPageChange,
  selected,
  setSelected,
}) => {
  const { t } = useTranslation();

  const [selectAll, setSelectAll] = useState(false);
  const [order, setOrder] = useState("asc");
  const [sort, setSort] = useState<string | null>(null);

  const handleCheckboxChange = (item: FulfillmentEvent) => {
    const isSelected = selected.some(
      (selectedItem) => selectedItem.id === item.id
    );
    setSelected(
      isSelected
        ? selected.filter((selectedItem) => selectedItem.id !== item.id)
        : [...selected, item]
    );
  };

  const handleSelectAllChange = (e: CheckboxChangeEvent) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    setSelected(isChecked ? data : []); // Directly use `data` array
  };
  useEffect(() => {
    setSelectAll(data.length > 0 && selected.length === data.length);
  }, [selected, data]);

  const header = (
    <div>
      <ListHeader template="2em 0.5fr 2fr 0.5fr 0.5fr 0.5fr 1.5fr">
        <div style={{ padding: "0.4em" }}>
          <Checkbox
            checked={selectAll}
            indeterminate={selected.length > 0 && selected.length < data.length}
            onChange={handleSelectAllChange}
          />
        </div>
        <ListHeaderLabel
          value={t("component.fulfillerOverviewList.shortId")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.fulfillerOverviewList.name")}
          justifyContent="flex-start"
          sortable={true}
          sort={sort === "name" ? (order === "asc" ? "asc" : "desc") : null}
          onClick={() => {
            setSort("name");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`name:${order}`);
            }
          }}
        />
        <ListHeaderLabel
          value={t("component.fulfillerOverviewList.time")}
          justifyContent="flex-start"
          sortable={true}
          sort={sort === "datetime" ? (order === "asc" ? "asc" : "desc") : null}
          onClick={() => {
            setSort("datetime");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`datetime:${order}`);
            }
          }}
        />
        <ListHeaderLabel
          value={t("component.fulfillerOverviewList.status")}
          justifyContent="flex-start"
          sortable={true}
          sort={sort === "state" ? (order === "asc" ? "asc" : "desc") : null}
          onClick={() => {
            setSort("state");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`state:${order}`);
            }
          }}
        />
        <ListHeaderLabel
          value={t("component.fulfillerOverviewList.load")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.fulfillerOverviewList.asset")}
          justifyContent="flex-start"
          sortable={true}
          sort={
            sort === "assetName" ? (order === "asc" ? "asc" : "desc") : null
          }
          onClick={() => {
            setSort("assetName");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`assetName:${order}`);
            }
          }}
        />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <div className={styles.listContainer}>
        <List
          loading={loading}
          loadingElement={() => <OrderListSkeleton />}
          error={error}
          data={data}
          empty={!data.length}
          keyExtractor={(item) => item.id}
          renderHeader={() => header}
          renderItem={(item) => (
            <Item
              index={data.indexOf(item)}
              data={item}
              key={item.id}
              onCheckboxChange={() => {
                handleCheckboxChange(item);
              }}
              isSelected={selected.some(
                (selectedItem) => selectedItem.id === item.id
              )}
            />
          )}
        />
      </div>
    </div>
  );
};

export default PlannedList;
