import React from "react";
import Item from "./Item";

import { OrderListSkeleton } from "../ListSkeletons";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { PaginationRavent } from "../Pagination/Pagination";
import { Role } from "../../services/roles";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { List } from "../List";

interface RoleListProps {
  error: string;
  loading: boolean;
  data: Role[];
  page: number;
  total: number;
  hitsPerPage: number;
  onPageChange?: (page: number) => void;
  onHitsPerPageChange?: (hitsPerPage: number) => void;
  pagination?: boolean;
  deleteRol: (id: string) => void;
}

const RoleList: React.FC<RoleListProps> = ({
  error,
  loading,
  data,
  pagination,
  page,
  total,
  hitsPerPage,
  onHitsPerPageChange,
  onPageChange,
  deleteRol,
}) => {
  const { t } = useTranslation();

  const header = (
    <div>
      <ListHeader template="1.5fr 1fr 1.5fr 1fr">
        <ListHeaderLabel
          value={t("component.roleList.rol")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.roleList.scope")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.roleList.description")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel value={""} justifyContent="flex-start" />
      </ListHeader>
    </div>
  );

  return (
    <div>
      {pagination ? (
        <div className={styles.paginationContainerUp}>
          <PaginationRavent
            current={page + 1}
            showSizeChanger={true}
            defaultCurrent={page + 1}
            total={total}
            onShowSizeChange={(page, size) => {
              if (onHitsPerPageChange) {
                onHitsPerPageChange(size);
              }
            }}
            onChange={(page) => {
              if (onPageChange) {
                onPageChange(page - 1);
              }
            }}
            pageSize={hitsPerPage}
          />
        </div>
      ) : null}
      <div className={styles.listContainer}>
        <List
          loading={loading}
          loadingElement={() => <OrderListSkeleton />}
          error={error}
          data={data}
          empty={!data.length}
          keyExtractor={(item) => item.id}
          renderHeader={() => header}
          renderItem={(item) => (
            <Item deleteRol={deleteRol} data={item} key={item.id} />
          )}
        />
      </div>
    </div>
  );
};

export default RoleList;
