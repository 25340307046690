import { stylesheet } from "typestyle";

const style = stylesheet({
  leftBar: {
    flexShrink: 0,
    flexGrow: 0,
    height: "100%",
    fontSize: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    background: "#fff",
    zIndex: 1,
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    background: "#fff",
    height: "3.6em",
    marginTop: "0.6em",
  },
  logo: {
    width: "100%",
    display: "block",
    objectFit: "contain",
    height: "100%",
    padding: "0.06em",
  },
  options: {
    height: "100%",
    width: "100%",
    padding: "1em 0 0 0",
  },
  icon: {
    width: "1.5em",
    cursor: "pointer",
  },
  iconTransform: {
    width: "1.5em",
    cursor: "pointer",
    transform: "rotate(180deg)"
  },
});

export default style;
