const translation = {
  en: {
    paymentRequested: "Payments Requested",
    empty: "You don't have any Payment Request",
    requestPayment: "Request Payment",
    instructions: "Empty List",
  },
  es: {
    paymentRequested: "Pagos solicitados",
    empty: "No tiene ninguna solicitud de pago",
    requestPayment: "Solicitar pago",
    instructions: "Lista vacía",
  },
  de: {},
};

export default translation;
