import React from 'react';
import { classes } from 'typestyle';
import styles from './styles.module.css';
import alertInfo from '../../../assets/alert-info.svg';

export interface InputLabelProps {
  id?: string;
  label?: string;
  showLabel?: boolean;
  theme?: 'black' | 'gray';
  onHelp?: (() => void) | null;
}

export const InputLabel: React.FC<InputLabelProps> = ({ id, label, showLabel = true, theme, onHelp }) => {
  if (showLabel === false) {
    return null;
  }

  return (
    <div className={styles.labelContainer}>
      {label ? (
        <label
          className={classes(styles.label, {
            [styles.labelBlack]: theme === 'black',
            [styles.labelGray]: theme === 'gray'
          })}
          htmlFor={id}
        >
          {label}
        </label>
      ) : null}
      {onHelp ? (
        <button onClick={onHelp} className={styles.helpButton} type="button">
          <img src={alertInfo} alt="" className={styles.helpIcon} />
        </button>
      ) : null}
    </div>
  );
};
