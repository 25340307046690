const translation = {
  en: {
    venueInfo: "Information",
    type: "Type",
    maxCapacity: "Max Capacity",
    currentVisitors: "Current Visitors",
    fopSet: "FOP Set",
    description: "Description",
    venueContact: "Contact",
    email: "Company Email",
    phone: "Company Phone Number",
    invoice: "Invoice Issuance Type",
    config: "Configuration",
    flowUser: "Automated Flow User",
    timezone: "Timezone",
    dateFormatting: "Date Formatting",
    numberFormatting: "Number Formatting",
    defaultLanguage: "Default Language",
    orders: "Orders",
    events: "Events",
    users: "Users",
    fulfillments: "Fulfillment Definitions",
    locations: "Locations",
  },
  es: {
    defaultLanguage: "Idioma Predeterminado",
    venueInfo: "Información",
    type: "Tipo",
    maxCapacity: "Capacidad máxima",
    currentVisitors: "Visitantes actuales",
    fopSet: "Conjunto FOP",
    description: "Descripción",
    venueContact: "Contact0",
    email: "Correo electrónico de la empresa",
    phone: "Número de teléfono de la empresa",
    invoice: "Tipo de emisión de factura",
    config: "Configuración",
    flowUser: "Usario de flujos automatizado",
    timezone: "Zona de Horario",
    dateFormatting: "Formato de Fechas",
    numberFormatting: "Formato de Números",
    orders: "Órdenes",
    events: "Eventos",
    users: "Usuarios",
    fulfillments: "Definiciones de Cumplimiento",
  },
  de: {},
};

export default translation;
