import React from 'react';
import plus from '../../../assets/plus.svg';
import { ReactComponent as Delete } from '../../../assets/icons-delete.svg';
import { ReactComponent as Edit } from '../../../assets/icons-edit.svg';
import { FlexContainer } from '../../FlexContainer/FlexContainer';
import styles from './styles.module.css';

export interface Props {
  text?: string;
  padding?: string;
  display?: 'flex' | 'block' | 'none';
  onClick?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onDragOver?: () => void;
  onDragEnter?: () => void;
  onDragLeave?: () => void;
  onDrop?: (e: React.DragEvent<HTMLDivElement>) => void;
  edit?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  image?: string;
}

export const Thumbnail: React.FC<Props> = (props) => {
  const {
    text = 'Upload Image',
    padding,
    display,
    onClick,
    onFocus,
    onBlur,
    onDragOver,
    onDragEnter,
    onDragLeave,
    onDrop,
    edit,
    image,
    onDelete,
    onEdit
  } = props;

  return (
    <div
      draggable
      className={edit ? styles.uploadCardEdit : styles.uploadCard}
      style={{
        padding,
        display,
        backgroundImage: `url(${image})`
      }}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      onDragOver={(e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        onDragOver?.();
      }}
      onDragEnter={(e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        onDragEnter?.();
      }}
      onDragLeave={onDragLeave}
      onDrop={(e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        onDrop?.(e);
      }}
    >
      {edit ? (
        <FlexContainer padding="1em 1em 0 0" justifyContent="flex-end">
          <Delete className={styles.iconDelete} onClick={onDelete} />
          <Edit onClick={onEdit} />
        </FlexContainer>
      ) : (
        <div className={styles.uploadIcon}>
          <img src={plus} alt="" />
        </div>
      )}
      {!edit ? <div className={styles.text}>{text}</div> : null}
    </div>
  );
};

export default Thumbnail;
