import React from "react";

import styles from "./styles";
import { VenueOrderState } from "../../utils/types";
import { useTranslation } from "react-i18next";
import OrderStateList from "../OrderStateList";
import Text from "../Text";
import { black } from "../../utils/colors";
import { GridContainer } from "../GridContainer/GridContainer";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { Card } from "../Card/Card";
import { ContainerTitle } from "../ContainerTitle";
import { Button } from "../Button";

interface Props {
  orderStates: VenueOrderState;
  secretLastSet?: Date;
  secretLastSetBy?: string;
  secret?: string;
  onConfigureHash: (hash: string) => void;
  loading: string;
  edit: string;
  setEdit: (value: string) => void;
  onEdit: () => void;
}

// const defaultSecret: SecretKeyHash = {
//   secretKey: "",
//   digestAlgorithm: "",
// };

const VenueStates: React.FC<Props> = ({
  orderStates,

  setEdit,
}) => {
  const { t } = useTranslation();
  // const [secretHash, setHash] = useState<SecretKeyHash>(defaultSecret);

  // const onChange = <P extends keyof SecretKeyHash>(
  //   prop: P,
  //   value: SecretKeyHash[P]
  // ) => {
  //   setHash({ ...secretHash, [prop]: value });
  // };

  const states = [
    {
      name: "fulfilled",
      actionName: orderStates.fulfilled.actionName,
      automatic: orderStates.fulfilled.automatic,
      actionId: orderStates.fulfilled.actionId,
    },
    {
      name: "cancelled",
      actionName: orderStates.cancelled.actionName,
      automatic: orderStates.cancelled.automatic,
      actionId: orderStates.cancelled.actionId,
    },
  ];

  return (
    <div>
      <GridContainer gap="1em" columns="2fr">
        <Card containerClassname={styles.wrapperCard}>
          <FlexContainer padding="0 0 1em 0" justifyContent="space-between">
            <ContainerTitle
              size="small"
              label={t("component.fulfillmentStates.orderStates")}
            />
            <Button
              label={t("container.rIntegration.edit")}
              onClick={() => setEdit("states")}
            />
          </FlexContainer>

          <Text
            textAlign="justify"
            padding="0 0 2em 0"
            color={black}
            text={t("component.venueStates.url")}
          />
          <OrderStateList data={states} />
        </Card>
        {/* <Card containerClassname={styles.wrapperCard}>
          <ContainerTitle
            padding="0 0 0.5em 0"
            size="small"
            label={t("component.venueStates.hash")}
          />

          <Text
            textAlign="justify"
            padding={secret ? "0 0 1em 0" : "0 0 1em 0"}
            color={black}
            text={t("component.venueStates.notConfigured")}
          />
          {secret ? (
            <div>
              <p style={{ color: black, padding: "0 0 2em 0" }}>
                {`${t("component.venueStates.configured")} `}
                <span style={{ color: linkBlue }}>{`${formatDate(
                  secretLastSet
                )} `}</span>
                {` ${t("component.venueStates.by")} `}
                <span style={{ color: linkBlue }}>{`${secretLastSetBy} `}</span>
              </p>
            </div>
          ) : null}
          <InputPassword
            showIcon
            value={secretHash.secretKey}
            label={t("component.venueStates.secretKey")}
            onChange={(value) => onChange("secretKey", value)}
          />
          <InputText
            disabled
            label={t("component.venueStates.digest")}
            value={"SHA-512"}
            onChange={(value) => onChange("digestAlgorithm", value)}
          />
          <Button
            width="100%"
            label={
              loading === "key"
                ? "..."
                : t("component.venueStates.configureButton")
            }
            onClick={() => onConfigureHash(secretHash.secretKey)}
          />
        </Card> */}
      </GridContainer>
    </div>
  );
};

export default VenueStates;
