import { stylesheet } from "typestyle";
import { black, darkBlack, lightGrey, normalGray } from "../../utils/colors";

const styles = stylesheet({
  itemsWrapper: {
    padding: "1em 0",
  },
  containerDetails: {
    padding: "1em 0",
  },
  wrapperCard: {
    padding: "2em",
    marginBottom: "2em",
  },
  textUpload: {
    color: darkBlack,
    fontSize: "1em",
    fontWeight: 700,
  },
  center: {
    color: black,
    fontStyle: "normal",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    display: "flex",
    alignItems: "flex-start",
    marginRight: "0.5em",
    paddingBottom: '1em'
  },
  width: { minWidth: "20em" },
  image: {
    height: 250,
    width: "100%",
  },
  box: {
    background: "#F6F8FA",
    height: "15em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  imageBox: {
    padding: "0.2em",
    width: "3.5em",
    height: "3.5em",
    background: "#DCE1E6",
    borderRadius: "2px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    marginRight: "2em",
  },
  container: {
    borderRadius: "5px",
    padding: "1em",
  },
  contain: {
    width: "100%",
  },
  title: {
    color: darkBlack,
    fontWeight: "bold",
    fontSize: "1.1em",
  },
  label: {
    fontSize: "1em",
    color: normalGray,
    fontWeight: 700,
    paddingBottom: "0.4em",
  },
  text: {
    fontSize: "1.1em",
    color: darkBlack,
    fontWeight: 400,
  },
  number: {
    fontSize: "1.1em",
    color: black,
    fontWeight: 400,
  },
  mapContainer: {
    height: "10em",
  },
  imageContainer: {
    borderRadius: "7px",
    height: "11em",
    width: "100%",
  },
  userContainer: {
    width: "2em",
    marginRight: "1em",
  },
  infoContainer: {
    padding: "1em 0",
  },
  infoContainer2: {
    padding: "0 3em",
  },
  imageWrapper: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    backgroundSize: "cover",
  },
  line: {
    width: "100%",
    height: "1px",
    margin: "1em 0 1em 0",
    background: lightGrey,
  },
  day: {
    color: black,
    fontSize: "1.1em",
    fontWeight: 600,
    textTransform: "capitalize",
  },
  hour: {
    color: black,
    fontSize: "1.1em",
    fontWeight: 400,
  },
  icon: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "0.9em",
    color: "#1fa0ff",
  },
  inputContainer: {
    marginLeft: "2em",
  },
  instructions: {
    fontSize: "1.1em",
  },
  numberOfVisitors: {
    fontWeight: 700,
    fontSize: "2.2em",
  },
  visitorsIcon: {
    width: "36px",
    marginRight: "0.5em",
  },
  inputSearchContainer: {
    width: "70%",
  },
  uploadingContainer: {
    padding: "1em",
  },
});

export default styles;
