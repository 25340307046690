const translation = {
  en: {
    cancel: "Cancel",
    noPasses: "You don't have passes yet.",
    definition: "Definition",
    passes: "Passes",
    passId: "Pass ID",
    load: "Load",
    state: "State",
    level: "Level",
    name: "Name",
    action: "Action",
    by: "By",
    date: "Date/Time",
    data: "Data",
    pass: "Pass",
    sharePass: "Pass"
  },
  es: {
    sharePass: "Pase",
    pass: "Pase",
    action: "Acción",
    by: "Usuario",
    date: "Fecha/Tiempo",
    data: "Data",
    passId: "ID de Pase",
    load: "Carga",
    state: "Estado",
    cancel: "Cancelar",
    noPasses: "Aún no tiene constancias.",
    definition: "Definición",
    passes: "Passes",
    level: "Nivel",
    name: "Nombre",
  },
  de: {},
};

export default translation;
