import { stylesheet } from "typestyle";
import { black } from "../../utils/colors";

const styles = stylesheet({
  wrapperCard: {
    padding: "2em",
    marginBottom: "1em",
  },
  container: {
    minHeight: "100vh",
  },
  image: { width: "60px" },
  width: {
    width: "100%",
    margin: "1em 0",
  },
  instructions: {
    color: black,
    marginTop: "0.5em",
    paddingBottom: "2em",
  },
  padding: {
    margin: "2em",
  },
  inputContainer: {
    margin: "0.em 0",
  },
  center: {
    color: black,
    fontStyle: "normal",
    justifyContent: "center",
    alignContent: "center",
    padding: "1em",
    display: "flex",
    alignItems: "center",
  },
  radioWrapper: {
    padding: "0.7em 0",
  },
  margin: {
    margin: "1em 0 0 0",
  },
  checkbox: {
    marginTop: "0.4em",
    marginRight: "1em",
  },
  modalContainer: {
    padding: "1em",
  },
  selectContainer: {
    width: "50%",
  },
  icon: {
    width: "20px",
    marginLeft: "0.5em",
    cursor: "pointer",
  },
});

export default styles;
