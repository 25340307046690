import * as React from "react";
import { stylesheet, classes } from "typestyle";
import {
  black,
  lightGrey,
  pointBlue,
  pointBrown,
  pointFusia,
  pointGray,
  pointGreen,
  pointLightBlue,
  pointOrange,
  pointPink,
  pointPurple,
  pointRed,
  pointTurquoise,
  pointYellow,
  linkBlue,
} from "../../utils/colors";
import { lowerCase } from "../../utils/format";
import { StatusCatalog } from "../StatusBadge";

export interface StatusBadgePointProps {
  status: StatusCatalog;
  label?: string;
  size?: string;
  point?: boolean;
}

const styles = stylesheet({
  property: {
    padding: "0",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  text: {
    fontSize: "1em",
    color: black,
    fontWeight: 500,
    textTransform: "capitalize",
    padding: "0 0 0 .5em",
  },
  big: {
    fontSize: "1.1em",
  },
  label: {
    fontSize: "0.9em",
    color: "#a1a0c2",
  },
  dot: {
    width: "10px",
    height: "10px",
    borderRadius: "10px",
    background: lightGrey,
  },
  greenDot: {
    background: pointGreen,
  },
  greyDot: {
    background: pointGray,
  },
  redDot: {
    background: pointRed,
  },
  blueDot: {
    background: pointBlue,
  },
  yellowDot: {
    background: pointYellow,
  },
  orangeDot: {
    background: pointOrange,
  },
  brownDot: {
    background: pointBrown,
  },
  purpleDot: {
    background: pointPurple,
  },
  lightBlueDot: {
    background: pointLightBlue,
  },
  turqouiseDot: {
    background: pointTurquoise,
  },
  fusiaDot: {
    background: pointFusia,
  },
  pinkDot: {
    background: pointPink,
  },
  stateDot: {
    background: linkBlue,
  },
});

export const StatusBadgePoint: React.FC<StatusBadgePointProps> = ({
  status,
  label,
  size,
  point,
}) => (
  <div className={styles.property}>
    {label ? <div className={styles.label}>{label}</div> : null}
    <div className={styles.wrapper}>
      <div
        className={classes(styles.dot, {
          [styles.greenDot]:
            status === "running" ||
            status === "active" ||
            status === "issued" ||
            status === "closed" ||
            status === "fulfillment" ||
            status === "approved" ||
            status === "invoice issuance" ||
            status.includes("2"),

          [styles.blueDot]:
            status === "ENABLED" ||
            status === "paid" ||
            status === "payment" ||
            status === "fulfilled" ||
            status === "completed",
          [styles.yellowDot]:
            status === "review" ||
            status === "un-paid" ||
            status === "unpaid" ||
            status.includes("4"),
          [styles.greyDot]:
            status === "DISABLED" ||
            status === "created" ||
            status === "creation" ||
            status === "closure" ||
            status === "pending",
          [styles.redDot]:
            status === "failed" ||
            status === "cancelled" ||
            status === "declined" ||
            status === "cancellation" ||
            status === "invoice cancellation" ||
            status.includes("5"),
          [styles.purpleDot]: status === "expired" || status === "expiration",
          [styles.orangeDot]:
            status === "partial" || status === "partially paid",
          [styles.lightBlueDot]: status === "payment request",
          [styles.turqouiseDot]:
            status === "payment extension" || status.includes("3"),
          [styles.fusiaDot]:
            status === "integrations" || status === "exception",
          [styles.pinkDot]: status === "other integrations",
          [styles.brownDot]:
            status === "refunded" ||
            status === "refund" ||
            status === "invoice refund",
          [styles.stateDot]: status === "states",
        })}
      />
      {!point ? (
        <div className={classes(styles.text, { [styles.big]: size === "big" })}>
          <span>{lowerCase(status.replace(/_/g, " ")).replace("-", "")}</span>
        </div>
      ) : null}
    </div>
  </div>
);
