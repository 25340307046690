import {
  Order,
  OrderInvoice,
  Payment,
  RefundTypes,
} from "@innomius/ravent-typescript-types";

import React, { useState } from "react";
import { Card } from "../Card/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OrderTitle from "../OrderDetail/OrderTitle";
import OrderPaymentList from "../OrderPaymentList";
import OrderInvoiceList from "../OrderInvoiceList";
import RegisterPaymentForm from "../RegisterPaymentForm";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { normalGray } from "../../utils/colors";
import RequestPaymentForm from "../RequestPaymentForm";
import {
  FOP,
  RegisterPaymentRequest,
  RequestPaymentRequest,
} from "../../utils/types";
import OrderRefunds from "../OrderRefunds";
import styles from "./styles";
import OrderRequestPayment from "../OrderRequestPayment";
import { useHistory } from "react-router";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import OrderInvoiceF from "../OrderInvoiceF";
import { DoubleButton } from "../DoubleButton";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { Button } from "../Button";
import { GridContainer } from "../GridContainer/GridContainer";
import { EmptyList } from "../List/EmptyList";

interface Props {
  data: Order;
  errorPayment: string;
  payments: Payment[];
  refunds: RefundTypes[];
  loading: boolean;
  requests: Payment[];
  path: string;
  loadingRegister: boolean;
  loadingRequest: boolean;
  onRegisterPayment: (
    data: RegisterPaymentRequest,
    amount: string,
    requestId: string
  ) => void;
  onRequestPayment: (
    data: RequestPaymentRequest,
    amount: string,
    fopId: string,
    send: boolean,
    email: string,
    lang: string
  ) => void;
  load: () => void;
  requestError: string;
  onDeleteRquest: (id: string) => void;
  fops: FOP[];
  customer: string;
  invoices: OrderInvoice[];
  invoiceIssuanceMethod: string;
  refundActionId: string;
  cancelInvoiceActionId: string;
  invoiceIssuanceActionId: string;
}

const OrderPayments: React.FC<Props> = ({
  data,
  errorPayment,
  payments,
  loading,
  refunds,
  requests,
  path,
  onRegisterPayment,
  onRequestPayment,
  loadingRegister,
  loadingRequest,
  load,
  onDeleteRquest,
  requestError,
  fops,
  customer,
  invoiceIssuanceActionId,
  invoices,
  invoiceIssuanceMethod,
  refundActionId,
  cancelInvoiceActionId,
}) => {
  const [open, setSideMenu] = useState("");
  const [chevronPayment, setChevronPayment] = useState(
    payments.length ? true : false
  );
  const [chevronInvoice, setChevronInvoice] = useState(
    invoices.length ? true : false
  );
  const history = useHistory();
  const { t } = useTranslation();

  const sumAmountRequest = requests
    .filter((request) => !request.cancelled)
    .map((item) => item.amount.value)
    .reduce((partialSum, a) => partialSum + a, 0);

  return (
    <div className={styles.container}>
      <FlexContainer
        padding="1em 0 1em 0"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          containerClassname={styles.buttonsContainer}
          label={t("component.orderPayments.requestPayment")}
          disable={data.state === "cancelled" || data.dueAmount < 1}
          onClick={() => {
            if (sumAmountRequest >= data.totalAmount) {
              message.warning(t("component.orderPayments.warning"));
              return;
            }
            setSideMenu("requests");
          }}
        />
        <Button
          containerClassname={styles.buttonsContainer}
          label={t("component.orderPayments.registerPayment")}
          onClick={() => setSideMenu("register")}
          disable={data.dueAmount < 0}
        />
        <DoubleButton
          options={[
            {
              label: t("component.orderPayments.addInvoice"),
              click: () =>
                history.push({
                  pathname: `${path}/invoices/addInvoice`,
                  state: { amount: data.totalAmount },
                }),
            },
            {
              label: t("component.orderPayments.issueInvoice"),
              click: () =>
                history.push({
                  pathname: `${path}/invoices/new`,
                }),
            },
          ]}
          containerClassname={styles.buttonsContainer}
          label={t("component.orderPayments.invoice")}
        />
        <Button
          theme="white"
          label={t("component.orderPayments.issueRefund")}
          disable={true}
          onClick={() =>
            refunds.length
              ? history.push(`${path}/refunds`)
              : history.push(`${path}/refunds/new`)
          }
        />
      </FlexContainer>
      <GridContainer gap="1em" columns="1fr">
        {open === "register" ? (
          <Card>
            <RegisterPaymentForm
              currency={data.currency}
              dueAmount={data.dueAmount.toString()}
              fops={fops}
              shortId={data.shortId}
              error={requestError}
              overPaid={data.dueAmount <= 0}
              requestsPayments={requests.filter((item) => !item.cancelled)}
              load={load}
              loading={loadingRegister}
              onCancel={() => setSideMenu("")}
              onRegister={(data, amount, requestId) =>
                onRegisterPayment(data, amount, requestId)
              }
            />
          </Card>
        ) : null}
        {open === "requests" ? (
          <Card>
            <RequestPaymentForm
              currency={data.currency}
              customerEmail={customer}
              paymentDueDate={data.paymentDueDate as Date}
              fops={fops}
              dueAmount={data.dueAmount}
              shortId={data.shortId}
              loading={loadingRequest}
              open={open === "requests"}
              onCancel={() => {
                setSideMenu("");
                load();
              }}
              onRequest={(data, amount, fopId, send, email, lang) =>
                onRequestPayment(data, amount, fopId, send, email, lang)
              }
            />
          </Card>
        ) : null}

        <Card>
          <div
            className={styles.itemsWrapper}
            onClick={() => setChevronPayment(!chevronPayment)}
          >
            <>
              <FlexContainer alignItems="flex-start">
                <OrderTitle
                  label={`${t("component.orderPayments.paymentsReceived")} (${
                    payments.length
                  })`}
                />
                <FontAwesomeIcon
                  icon={
                    chevronPayment
                      ? (faChevronUp as IconProp)
                      : (faChevronDown as IconProp)
                  }
                  className={styles.chevron}
                  color={normalGray}
                />
              </FlexContainer>
            </>
          </div>
          {chevronPayment && payments.length ? (
            <div className={styles.itemsWrapperDetail}>
              <OrderPaymentList
                orderDescription={data.description}
                orderShortId={data.shortId}
                contactEmail={data.customer.email || ""}
                onIssueInvoice={(id: string) => {
                  history.push({
                    pathname: `${path}/invoices/new`,
                    state: { paymentId: id },
                  });
                }}
                error={errorPayment}
                loading={loading}
                data={payments}
              />
            </div>
          ) : chevronPayment ? (
            <div className={styles.itemsWrapperDetail}>
              <EmptyList
                instructions={""}
                text={t("component.orderPayments.noPayments")}
              />
            </div>
          ) : null}
        </Card>

        <OrderRefunds
          error={errorPayment}
          loading={loading}
          refunds={refunds}
          order={data}
          path={path}
        />

        <OrderRequestPayment
          onDelete={(id) => onDeleteRquest(id)}
          orderDescription={data.description}
          orderShortId={data.shortId}
          contactPhone={data.customer.phone || ""}
          contactEmail={data.customer.email || ""}
          loading={loading}
          path={path}
          requests={
            requests.length
              ? requests
                  .sort(
                    (x, y) =>
                      new Date(x.created).getTime() -
                      new Date(y.created).getTime()
                  )
                  .sort((x) => (!x.cancelled ? -1 : 1))
              : []
          }
          errorPayment={errorPayment}
        />

        <Card>
          <div
            className={styles.itemsWrapper}
            onClick={() => setChevronInvoice(!chevronInvoice)}
          >
            <>
              <FlexContainer alignItems="flex-start">
                <OrderTitle
                  label={`${t("component.orderPayments.invoices")} (${
                    invoices.length
                  })`}
                />
                <FontAwesomeIcon
                  icon={
                    chevronInvoice
                      ? (faChevronUp as IconProp)
                      : (faChevronDown as IconProp)
                  }
                  className={styles.chevron}
                  color={normalGray}
                />
              </FlexContainer>
            </>
          </div>
          {chevronInvoice && invoices.length ? (
            <div className={styles.itemsWrapperDetail}>
              <OrderInvoiceList
                refundActionId={refundActionId}
                orderId={data.id}
                load={load}
                cancelInvoiceActionId={cancelInvoiceActionId}
                contactEmail={data.customer.email || ""}
                error={errorPayment}
                loading={loading}
                data={invoices}
                payments={payments.map((item) => ({
                  label: item.shortId,
                  value: item.id,
                }))}
                requestIds={requests
                  .filter((request) => !request.cancelled)
                  .map((item) => ({
                    label: item.shortId,
                    value: item.id,
                  }))}
              />
            </div>
          ) : chevronInvoice ? (
            <div className={styles.itemsWrapperDetail}>
              <EmptyList
                instructions={t("component.orderPayments.empty")}
                text={t("component.orderPayments.noInvoices")}
              />
            </div>
          ) : null}
        </Card>
      </GridContainer>
      <OrderInvoiceF
        currency={data.currency}
        invoiceIssuance={invoiceIssuanceMethod}
        paymentState={data.paymentState}
        invoiceActionId={invoiceIssuanceActionId}
        requestsPayments={requests}
        payments={payments}
        orderAmount={data.totalAmount.toString()}
        defaultErpId={data.erp?.clientId || ""}
        path={path}
        orderId={data.id}
        load={load}
      />
    </div>
  );
};

export default OrderPayments;
