const translation = {
  en: {
    allowedTransitions: "Allowed Transitions",
    addTransition: "Add Transition",
    from: "From",
    to: "To",
    locations: "Locations",
    type: "Type",
    orderStates: "Order States",
    stateName: "State Name",
    url: "URL",
    ticketing: "Ticketing",
    customerNameMandatory: "Customer Name for tickets is mandatory",
    activationType: "Activation",
    atIssuance: "At Issuance",
    atDateTime: "At Date Time",
    onFirstUse: "On First Use(Check-in)",
    maxEvents: "Max Activations",
    maxEventsDescription: "Event's amount that a ticket can be activated.",
    lifeTime: "Life Time",
    lifeTimeDescription: "Time after activation.",
    amount: "Amount",
    duration: "Duration",
    endOf: "End Of",
    unit: "Unit",
    min: "min",
    hour: "hour",
    day: "day",
    month: "month",
    year: "year",
    ticketingEnabled: "Enable Ticketing",
    locationsEnabled: "Locations Enabled",
    validFrom: "Valid From",
    validUntil: "Valid Until",
    actionId: "Action",
  },
  es: {
    actionId: "Acción",
    allowedTransitions: "Transiciones Permitidas",
    addTransition: "Agregar transición",
    from: "De",
    to: "A",
    locations: "Ubicaciones",
    type: "Tipo",
    orderStates: "Estados de la Orden",
    stateName: "Nombre del estado",
    url: "URL",
    ticketing: "Boletos",
    customerNameMandatory:
      "El nombre del cliente para los boletos es obligatorio",
    activationType: "Activación",
    atIssuance: "En Emisión",
    atDateTime: "En la fecha y hora",
    onFirstUse: "En el primer uso (registro)",
    maxEvents: "Eventos máximos",
    maxEventsDescription: "Cantidad del evento que se puede activar un ticket.",
    lifeTime: "Tiempo de Vida",
    lifeTimeDescription: "Tiempo después de la activación.",
    amount: "Cantidad",
    duration: "Duración",
    endOf: "Fin de",
    unit: "Unidad",
    min: "min",
    hour: "hora",
    day: "día",
    month: "mes",
    year: "año",
    ticketingEnabled: "Habilitar emisión de boletos",
    locationsEnabled: "Ubicaciones habilitadas",
    validFrom: "Válido desde",
    validUntil: "Válido hasta",
  },
  de: {},
};

export default translation;
