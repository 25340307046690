import { stylesheet } from "typestyle";
import { linkBlue } from "../../utils/colors";

const styles = stylesheet({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexDirection: "row",
  },
  buttonContainer: {
    marginRight: "1em",
  },
  flex: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: 'row'
  },

  shareIcon: {
    cursor: "pointer",
  },
  flow: {
    cursor: "pointer",
  },
  chevron: {
    width: "18px",
  },
  chevronContainer: {
    cursor: "pointer",
    marginRight: "1.5em",
  },
  bookMark: {
    color: linkBlue,
    fill: linkBlue,
    cursor: "pointer",
  },
});

export default styles;
