const translation = {
  en: {
    nameLabel: "Venue Name",
    type: "Type",
    currentVisitors: "Current Visitors",
    actions: "",
    fopSet: "FOP Set",
    currency: "Currency",
    orders: "Orders",
    events: "Events",
    users: "Users",
    fulfillments: "Fulfillments",
    locations: "Locations",

  },
  es: {
    nameLabel: "Nombre del lugar",
    type: "Tipo",
    currentVisitors: "Visitantes actuales",
    locations: "Ubicaciones",
    actions: "",
    fopSet: "FOP Set",
    currency: "Moneda",
    orders: "Órdenes",
    events: "Eventos",
    users: "Usuarios",
    fulfillments: "Cumplimientos",
  },
  de: {},
};

export default translation;
