import React, { useState } from "react";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { User } from "@innomius/ravent-typescript-types";
import { bluishGrey, hoverList } from "../../utils/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faArrowDown } from "@fortawesome/pro-light-svg-icons";
import { Modal } from "../Modal/Modal";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { ContainerTitle } from "../ContainerTitle";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { ListRow } from "../List/ListRow";
import { Tag } from "../Tag/Tag";
import { ListLabel } from "../List/ListLabel";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { Checkbox, Input } from "antd";
import phoneIcon from "../../assets/phone-gray.svg";
import emailIcon from "../../assets/user-email.svg";

import { trimString } from "../../utils/format";

const { Search } = Input;

interface Props {
  onClose: () => void;
  open: boolean;
  loading?: boolean;
  users: User[];
  addUser: (ids: string[]) => void;
}

const AddExistingUser: React.FC<Props> = ({
  onClose,
  open,
  loading,
  users,
  addUser,
}) => {
  const { t } = useTranslation();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [search, setSearch] = useState("");

  const filteredUsers = users?.filter(
    (item) =>
      item.type !== "user" &&
      `${item.firstName || ""} ${item.lastName || ""}`
        .toLowerCase()
        .includes(search.toLowerCase())
  );

  const handleSelectUser = (id: string, checked: boolean) => {
    setSelectedUsers((prev) =>
      checked ? [...prev, id] : prev.filter((userId) => userId !== id)
    );
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      const allUserIds = filteredUsers.map((user) => user.id);
      setSelectedUsers(allUserIds);
    } else {
      setSelectedUsers([]);
    }
  };

  return (
    <div className={styles.container}>
      <Modal
        buttons={
          <div>
            <ButtonsContainer justifyContent="flex-end">
              <Button
                onClick={() => {
                  onClose();
                  setSelectedUsers([]);
                }}
                theme="white"
                label={t("component.entityDivers.cancel")}
              />
              <Button
                onClick={() => addUser(selectedUsers)}
                label={loading ? "..." : t("component.entityDivers.add")}
              />
            </ButtonsContainer>
          </div>
        }
        width="900px"
        open={open}
        onClose={onClose}
      >
        <div className={styles.modalContainer}>
          <ContainerTitle
            padding="1em 0 1em 0"
            size="medium"
            label={t("component.entityDivers.exisitingUsers")}
          />
          <Search
            placeholder={t("component.entityDivers.search")}
            onChange={(e) => setSearch(e.target.value)}
            style={{ marginBottom: "1em" }}
          />

          <div>
            <ListHeader template="2em 1fr 1fr 1.1fr">
              <div className={styles.center}>
                <Checkbox
                  onChange={(e) => handleSelectAll(e.target.checked)}
                  checked={
                    filteredUsers.length > 0 &&
                    selectedUsers.length === filteredUsers.length
                  }
                />
              </div>

              <ListHeaderLabel
                value={t("component.entityDivers.name")}
                justifyContent="flex-start"
              />
              <ListHeaderLabel
                value={t("component.entityDivers.email")}
                justifyContent="flex-start"
              />
              <ListHeaderLabel
                value={t("component.entityDivers.role")}
                justifyContent="flex-start"
              />
            </ListHeader>
            <div className={styles.list}>
              {filteredUsers?.map((item) => (
                <div
                  key={item.id}
                  className={
                    selectedUsers.includes(item.id)
                      ? styles.background
                      : styles.nothing
                  }
                >
                  <ListRow
                    background={
                      selectedUsers.includes(item.id) ? hoverList : "#fff"
                    }
                    template="2em 1fr 1fr 1.1fr"
                  >
                    <div className={styles.center}>
                      <Checkbox
                        onChange={(e) =>
                          handleSelectUser(item.id, e.target.checked)
                        }
                        checked={selectedUsers.includes(item.id)}
                      />
                    </div>

                    {!item.firstName ? (
                      <div className={styles.center}>
                        <Tag
                          theme="yellow"
                          padding="0 0.5em"
                          label="waiting-for-enrollment"
                        />
                      </div>
                    ) : (
                      <ListLabel
                        fontFormat="bold"
                        value={`${item.firstName} ${item.lastName}`}
                        textAlign="left"
                      />
                    )}
                    <div className={styles.normal}>
                      <FlexContainer
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        flexDirection="column"
                      >
                        {item.phone?.length && item.email?.length ? (
                          <FlexContainer justifyContent="flex-start">
                            <img
                              src={
                                item.phone?.length && item.loginWithPhone
                                  ? phoneIcon
                                  : emailIcon
                              }
                              className={styles.icon}
                              alt=""
                            />
                            {trimString(
                              item.phone?.length && item.loginWithPhone
                                ? item.phone
                                : item.email,
                              25
                            )}{" "}
                          </FlexContainer>
                        ) : null}
                      </FlexContainer>
                    </div>

                    <div className={styles.center}>
                      {item.roles.map((role, index) => (
                        <div key={index} style={{ margin: "0 0.5em 0.5em 0" }}>
                          <Tag size="small" theme="blue" label={role} />
                        </div>
                      ))}
                    </div>
                  </ListRow>
                </div>
              ))}
            </div>
            <FlexContainer justifyContent="flex-end">
              <FontAwesomeIcon
                color={bluishGrey}
                icon={faArrowDown as IconProp}
                width={"2em"}
                size="2x"
              />
            </FlexContainer>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddExistingUser;
