import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import secureLocalStorage from "react-secure-storage";
import { getProfile } from "../services/profile";
import { listActions } from "../services/actions";
import { Profile } from "../utils/types";

type ProfileSliceState = {
  loading: boolean;
  error: string;
  profile: Profile | null;
  orgName: string;
};

const initialState: ProfileSliceState = {
  loading: true,
  error: "",
  profile: null,
  orgName: "",
};

export const loadProfile = createAsyncThunk("profile/load", async () => {
  const profile = await getProfile();
  secureLocalStorage.setItem("orgId", profile.organizationId);
  secureLocalStorage.setItem("venueName", profile.venueName);
  secureLocalStorage.setItem("venueId", profile.venueId);
  localStorage.setItem("venueId", profile.venueId);

  secureLocalStorage.setItem(
    "invoiceIssuanceType",
    profile.invoiceIssuanceType
  );

  secureLocalStorage.setItem("orgName", profile.orgName);
  secureLocalStorage.setItem(
    "userName",
    `${profile.firstName} ${profile.lastName}`
  );

  const actions = await listActions({});
  secureLocalStorage.setItem("actions", actions.data);

  return { profile: profile, orgName: profile.orgName };
});

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadProfile.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(loadProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.profile = action.payload.profile;
      state.orgName = action.payload.orgName;
    });
    builder.addCase(loadProfile.rejected, (state, action) => {
      console.log("error ocurred ", action);
      state.loading = false;
      state.error = action.error.message || "An unexpected error ocurred";
    });
  },
});

// export const {} = profileSlice.actions;

export default profileSlice.reducer;
