const translation = {
  en: {
    successCreated: "FOP Set succesfully created.",
    successDeleted: "FOP Set succesfully deleted.",
    back: "Back to Payments",
    add: "+ Add FOP Set",
    form: "Form of Payments Sets",
    search: "Search",
    instructions: "Start creating FOP Sets",
    createNew: "Create New FOP Set",
  },
  es: {
    successCreated: "Conjunto FDP creado con éxito.",
    successDeleted: "Conjunto de FDP eliminado con éxito.",
    back: "Regresar a Pagos",
    add: "+ Agregar conjunto de FOP",
    form: "Forma de Conjuntos de Pagos",
    search: "Buscar",
    instructions: "Empezar a crear conjuntos FDP",
    createNew: "Crear nuevo conjunto de FDP",
  },
  de: {
    successCreated: "FOP Set succesfully created.",
    successDeleted: "FOP Set succesfully deleted.",
    back: "Back to Payments",
    add: "+ Add FOP Set",
    form: "Form of Payments Sets",
    search: "Search",
    instructions: "Start creating FOP Sets",
    createNew: "Create New FOP Set",
  },
};

export default translation;
