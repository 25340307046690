const translation = {
  en: {
    description: "Description",
    requestId: "Request Id",
    paymentId: "Payment Id",
    issuer: "Issuer",
    issuedDate: "Issued Date",
    amount: "Amount",
    status: "Status",
    actions: "Actions",
    invoiceDetails: "Invoice Details",
    invoiceId: "Invoice ID",
    invoiceData: "Invoice Data",
    issuerId: "Issuer ID",
    authority: "Authority",
    authorityId: "Authority ID",
    issueTimestamp: "Issued timestamp",
    files: "Files",
    uploadFiles: "Upload Files",
    cancel: "Cancel",
    close: "Close",
    addInvoice: "Add Invoice",
    save: "Save",
    skip: "Skip",
    instructions: "Pleas upload related files or add them later.",
    successCreation: "Invoice created successfully.",
    successCreationFile: "File added successfully",
    fileSize: "File Size",
    uploadFile: "Upload File"
  },
  es: {
    description: "Descripción",
    requestId: "Solicitud de identificación",
    paymentId: "Id de pago",
    issuer: "Emisor",
    issuedDate: "Fecha de emisión",
    amount: "Cantidad",
    status: "Estado",
    actions: "Acciones",
    invoiceDetails: "Detalles de la factura",
    invoiceId: "ID de la factura",
    invoiceData: "Datos de la factura",
    issuerId: "ID del emisor",
    authority: "Autoridad",
    authorityId: "ID de la autoridad",
    issueTimestamp: "Marca de tiempo emitida",
    files: "Archivos",
    uploadFiles: "Subir archivos",
    cancel: "Cancelar",
    close: "Cerrar",
    addInvoice: "Añadir Factura",
    save: "Guardar",
    skip: "Saltar",
    instructions: "Cargue los archivos relacionados o agréguelos más tarde.",
    successCreation: "Factura creada éxitosamente",
    successCreationFile: "Archivo se agregó éxistosamente",
    fileSize: "Tamaño del Archivo",
    uploadFile: "Subir Archivo"

  },
  de: {},
};

export default translation;
