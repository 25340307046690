import { stylesheet } from "typestyle";

const styles = stylesheet({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  buttonContainer: {
    marginRight: "1em",
  },
  titleContainer: {
    width: "50%",
    paddingTop: "1em",
  },
});

export default styles;
