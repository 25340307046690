const translation = {
  en: {
    periodicity: "Peiodicity",
    checkedInPeriod: "Checked-in period",
    description: "Description",
    diveShop: "Dive Shop",
    details: "Details",
    uploadImage: "Upload Image",
    cancel: "Cancel",
    tagsForThisOffer: "Tags for this offer",
    addLabelEnter: "Add label and press enter",
  },
  es: {},
  de: {},
};

export default translation;
