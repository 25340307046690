import React, { useCallback, useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import {
  Location,
  Message,
  Placement,
  Visitor,
} from "@innomius/ravent-typescript-types";

import Details from "./Details";
import LocationPlacement from "./LocationPlacement";
import LocationOfficialMedia from "./LocationOfficialMedia";
import LocationCheckin from "./LocationCheckin";
import LocationVisitors from "./LocationVisitors";
import styles from "./styles";
import { SideMenu } from "../SideMenu/SideMenu";
import APIError from "../../utils/APIError";
import { locationRead } from "../../services/venues";
import DeleteModal from "../DeleteModal";
import { useTranslation } from "react-i18next";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { EditableTitle } from "../EditableTitle";
import { ContainerTitle } from "../ContainerTitle";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import TabNavBar from "../TabNavBar";

interface Props {
  data: Location;
  messages: Message[];
  visitors: Visitor[];
  path?: string;
  onClose: () => void;
  onEditLocation: (data: Location, placement: Placement) => void;
  load: () => void;
  onDelete: (id: string) => void;
  loading?: string;
  venueId: string;
  open: boolean;
  groups: { value: string; label: string }[];
}

const LocationDetail: React.FC<Props> = ({
  data,
  onClose,
  messages,
  visitors,
  onEditLocation,
  load,
  loading,
  onDelete,
  venueId,
  groups,
}) => {
  const [edit, setEdit] = useState(false);
  const [venueLocation, setLocation] = useState<Location>(data);
  const [erase, setErase] = useState(false);
  const location = useLocation();

  const locationId = location.pathname.split("/")[2];

  const onChange = <P extends keyof Location>(prop: P, value: Location[P]) => {
    setLocation({ ...venueLocation, [prop]: value });
  };

  const { t } = useTranslation();

  const getLocationData = useCallback(async () => {
    try {
      if (locationId) {
        const res = await locationRead(locationId);
        setLocation(res);
      }
    } catch (err) {
      if (err instanceof APIError) {
        console.error(err.messages);
      }
    }
  }, [locationId]);

  useEffect(() => {
    const isLocationPath = location.pathname.includes(`/venueLocations/`);
    if (isLocationPath && locationId) {
      getLocationData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venueId, locationId, getLocationData, edit]);

  return (
    <>
      <SideMenu
        onClose={onClose}
        open={
          location.pathname === `/venueLocations/${locationId}/officialMedia` ||
          location.pathname === `/venueLocations/${locationId}/visitors` ||
          location.pathname === `/venueLocations/${locationId}` ||
          location.pathname === `/venueLocations/${locationId}/checkin` ||
          location.pathname === `/venueLocations/${locationId}/placement`
        }
      >
        <DeleteModal
          value={t("component.locationDetail.warning")}
          onCancel={() => setErase(false)}
          open={erase}
          loading={loading === "delete"}
          onDelete={() => onDelete(data.id)}
        />
        <div className={styles.itemsWrapper}>
          <FlexContainer justifyContent="space-between">
            {edit ? (
              <EditableTitle
                titleClassname={styles.width}
                padding="0 0 1em 0"
                value={venueLocation.name}
                onChange={(value) => onChange("name", value)}
              />
            ) : (
              <ContainerTitle
                padding="0 0 1em 0"
                size="medium"
                label={venueLocation.name}
              />
            )}

            {edit ? (
              <ButtonsContainer justifyContent="flex-start">
                <Button
                  label="Cancel"
                  theme="white"
                  onClick={() => {
                    setEdit(false);
                    setLocation(data);
                  }}
                />
                <Button
                  label="Save"
                  onClick={() => {
                    onEditLocation(venueLocation, venueLocation.placement);
                    setEdit(false);
                  }}
                />
              </ButtonsContainer>
            ) : (
              <ButtonsContainer>
                <Button
                  label="Delete"
                  theme="red"
                  onClick={() => setErase(true)}
                />

                <Button label="Edit" onClick={() => setEdit(true)} />
              </ButtonsContainer>
            )}
          </FlexContainer>
          <TabNavBar
            options={[
              {
                id: "locationPlacement",
                label: "Placement",
                path: `/venueLocations/${locationId}/placement`,
              },
              {
                id: "locationDetails",
                label: "Details",
                path: `/venueLocations/${locationId}`,
              },
              {
                id: "locationCheckin",
                label: "Check-In",
                path: `/venueLocations/${locationId}/checkin`,
              },
              {
                id: "locationOfficialMedia",
                label: "Official Media",
                path: `/venueLocations/${locationId}/officialMedia`,
              },

              // {
              //   id: "locationVisitors",
              //   label: "Visitors",
              //   path: `/venueLocations/${locationId}/visitors`,
              // },
            ]}
          >
            <Switch>
              <Route exact path={`/venueLocations/${locationId}/visitors`}>
                <LocationVisitors
                  visitors={visitors}
                  data={data}
                  messages={messages}
                  path={`/venueLocations`}
                />
              </Route>
              <Route exact path={`/venueLocations/${locationId}`}>
                <Details edit={edit} onChange={onChange} data={venueLocation} />
              </Route>
              <Route exact path={`/venueLocations/${locationId}/placement`}>
                <LocationPlacement
                  edit={edit}
                  groups={groups}
                  onChangeLocation={onChange}
                  data={venueLocation}
                  onChange={(value) => onChange("placement", value)}
                />
              </Route>
              <Route exact path={`/venueLocations/${locationId}/checkin`}>
                <LocationCheckin
                  edit={edit}
                  onChange={onChange}
                  data={venueLocation}
                />
              </Route>
              <Route exact path={`/venueLocations/${locationId}/officialMedia`}>
                <LocationOfficialMedia
                  path={`/venueLocations/${locationId}/officialMedia`}
                  venueId={venueLocation.venueId}
                  locationId={locationId}
                  load={load}
                />
              </Route>
            </Switch>
          </TabNavBar>
        </div>
      </SideMenu>
    </>
  );
};

export default LocationDetail;
