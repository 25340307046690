import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles";
import MapWithMarkers from "../../MapWithMultipleMarkers";
import { SideMenu } from "../../SideMenu/SideMenu";
import { ContainerTitle } from "../../ContainerTitle";
// import MapLoader from "../../MapInput/MapLoader";

interface Props {
  onClose: () => void;
  open: boolean;
  markers: { lat: number; lng: number; title: string }[];
}

const PlacementSideMenu: React.FC<Props> = ({ onClose, open, markers }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <SideMenu onClose={onClose} open={open}>
        <ContainerTitle
          size="medium"
          label={t("component.orderLocation.directions")}
        />
        {/* {markers.length === 1 ? (
          <MapLoader
            onChange={() => {}}
            type={"w3w"}
            value={{ lat: markers[0].lat, lng: markers[0].lng }}
          />
        ) : markers.length >= 1 ? (
          <div style={{ margin: "1em" }}>
            <MapWithMarkers markers={markers} />
          </div>
        ) : null} */}
        {markers.length >= 1 ? (
          <div style={{ margin: "1em" }}>
            <MapWithMarkers markers={markers} />
          </div>
        ) : null}
      </SideMenu>
    </div>
  );
};

export default PlacementSideMenu;
