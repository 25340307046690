import React, { useState } from "react";
import OrderListItem from "./OrderListItem";

import { OrderListSkeleton } from "../ListSkeletons";
import { Order } from "@innomius/ravent-typescript-types";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { PaginationRavent } from "../Pagination/Pagination";
import Checkbox, { CheckboxChangeEvent } from "antd/es/checkbox/Checkbox";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { List } from "../List";

interface OrdersListProps {
  error: string;
  loading: boolean;
  data: Order[];
  page: number;
  total: number;
  hitsPerPage: number;
  onPageChange?: (page: number) => void;
  onHitsPerPageChange?: (hitsPerPage: number) => void;
  onChangeSortOrder?: (sort: string) => void;
  sortable: boolean;
  pagination?: boolean;
  onTagClick: (tag: string) => void;
  onCustomerClick: (value: string) => void;
  selected: { id: string }[];
  setSelected: (value: any) => void;
}

const OrdersList: React.FC<OrdersListProps> = ({
  error,
  loading,
  data,
  onChangeSortOrder,
  sortable,
  pagination,
  page,
  total,
  hitsPerPage,
  onHitsPerPageChange,
  onPageChange,
  onTagClick,
  onCustomerClick,
  selected,
  setSelected,
}) => {
  const [order, setOrder] = useState("asc");
  const [sort, setSort] = useState<string | null>(null);

  const { t } = useTranslation();

  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (locationId: string) => {
    const isSelected = selected.some((loc) => loc.id === locationId);

    if (selectAll) {
      setSelected((prevSelected: any[]) => {
        if (isSelected) {
          return prevSelected.filter((loc) => loc.id !== locationId);
        } else {
          return [...prevSelected, { id: locationId }];
        }
      });
    } else {
      setSelected((prevSelected: any[]) => {
        if (isSelected) {
          return prevSelected.filter((loc) => loc.id !== locationId);
        } else {
          return [...prevSelected, { id: locationId }];
        }
      });
    }
  };

  const handleSelectAllChange = (e: CheckboxChangeEvent) => {
    setSelectAll(e.target.checked);
    setSelected(e.target.checked ? data.map((item) => ({ id: item.id })) : []);
  };

  const header = (
    <div>
      <ListHeader template="2em 0.7fr 0.8fr 1fr 1fr 1.2fr 0.3fr 1fr">
        <div style={{ padding: "0.4em" }}>
          <Checkbox checked={selectAll} onChange={handleSelectAllChange} />
        </div>
        <ListHeaderLabel value="ID" justifyContent="flex-start" />
        <ListHeaderLabel
          value={t("component.orderList.created")}
          sortable={sortable}
          sort={
            sort === "createdAt" ? (order === "asc" ? "asc" : "desc") : null
          }
          onClick={() => {
            setSort("createdAt");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`createdAt:${order}`);
            }
          }}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderList.customer")}
          justifyContent="flex-start"
          sortable={sortable}
          sort={
            sort === "customer.fullName"
              ? order === "asc"
                ? "asc"
                : "desc"
              : null
          }
          onClick={() => {
            setSort("customer.fullName");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`customer.fullName:${order}`);
            }
          }}
        />
        <ListHeaderLabel
          value={t("component.orderList.description")}
          justifyContent="flex-start"
          sortable={sortable}
          sort={
            sort === "description" ? (order === "asc" ? "asc" : "desc") : null
          }
          onClick={() => {
            setSort("description");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`description:${order}`);
            }
          }}
        />
        <ListHeaderLabel
          value={t("component.orderList.tags")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderList.status")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={`${t("component.orderList.total")}`}
          justifyContent="flex-end"
          sortable={sortable}
          sort={sort === "total" ? (order === "asc" ? "asc" : "desc") : null}
          onClick={() => {
            setSort("total");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`total:${order}`);
            }
          }}
        />
      </ListHeader>
    </div>
  );

  return (
    <div>
      {pagination && data.length >= 3 ? (
        <div className={styles.paginationContainerUp}>
          <PaginationRavent
            current={page + 1}
            showSizeChanger={true}
            defaultCurrent={page + 1}
            total={total}
            onShowSizeChange={(page, size) => {
              if (onHitsPerPageChange) {
                onHitsPerPageChange(size);
              }
            }}
            onChange={(page) => {
              if (onPageChange) {
                onPageChange(page - 1);
              }
            }}
            pageSize={hitsPerPage}
          />
        </div>
      ) : null}
      <div className={styles.listContainer}>
        <List
          loading={loading}
          loadingElement={() => <OrderListSkeleton />}
          error={error}
          data={data}
          empty={!data.length}
          keyExtractor={(item) => item.id}
          renderHeader={() => header}
          renderItem={(item) => (
            <OrderListItem
              onCheckboxChange={() => handleCheckboxChange(item.id)}
              isSelected={selected.some((loc) => loc.id === item.id)}
              markFavourite={(id: string) => {}}
              data={item}
              key={item.id}
              onTagClick={(item) => onTagClick(item)}
              onCustomerClick={(item) => onCustomerClick(item)}
            />
          )}
        />
      </div>
    </div>
  );
};

export default OrdersList;
