import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  locationList as locationListService,
  LocParamList,
} from "../services/venues";
import { LocationsSearchResponse } from "../services/venues";

type LocationsSliceState = {
  loading: boolean;
  error: string;
  list: LocationsSearchResponse;
};

const initialState: LocationsSliceState = {
  loading: true,
  error: "",
  list: {
    page: 0,
    total: 0,
    data: [],
  },
};

export const locationList = createAsyncThunk(
  "locations/list",
  async (params: LocParamList) => {
    const response = await locationListService(params);
    return response;
  }
);

const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(locationList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(locationList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.list = action.payload;
    });
    builder.addCase(locationList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An unexpected error ocurred";
    });
  },
});

export default locationsSlice.reducer;
