
import React, { useEffect, useRef } from "react";
import Text from "../Text";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import QRCode from "qrcode";
import { downloadPDF } from "../../utils/pdfs";
import cross from "../../assets/close.svg";
import media from "../../assets/media-white.svg";
import pdf from "../../assets/pdf-white.svg";
import QR from "./QR";
import { Modal } from "../Modal/Modal";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";

interface Props {
  onClose: () => void;
  open: boolean;
  id: string;
  shortId: string;
  createdAt: string;
}

const OrderQR: React.FC<Props> = ({
  onClose,
  open,
  id,
  shortId,
  createdAt,
}) => {
  const { t } = useTranslation();
  const url = window.location.host.includes("dev.ravent.com")
    ? `https://app.dev.ravent.com/orders/${id}`
    : `https://app.ravent.com/orders/${id}`;

  const qrData = `https://app.dev.ravent.com/orders/${id}`; // Replace with your data
  const qrCodeRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const currentRef = qrCodeRef.current;
    if (currentRef) {
      QRCode.toDataURL(qrData)
        .then((url) => {
          currentRef.src = url;
        })
        .catch((error) => {
          console.error("Error generating QR code:", error);
        });
    }
  }, [qrData]);

  const downloadQrCode = async () => {
    const checkinFlowUrl = await QRCode.toCanvas(url);
    let tagA = document.createElement("a");
    document.body.appendChild(tagA);
    tagA.href = checkinFlowUrl.toDataURL();
    tagA.download = `${id}.png`;
    tagA.click();
    document.body.removeChild(tagA);
  };

  return (
    <div className={styles.container}>
      <Modal width="350px" open={open} onClose={onClose}>
        <FlexContainer
          padding="1em 1em"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text
            text={t("component.orderHeader.orderQR")}
            color="#000000"
            fontSize="1.4em"
            fontWeight={700}
          />
          <img onClick={onClose} src={cross} alt="" style={{ width: 32 }} />
        </FlexContainer>
        <FlexContainer padding="0 1em 1em 1em">
          <QR url={url} />
        </FlexContainer>
        <ButtonsContainer padding="0 0 1em 0">
          <Button
            icon={pdf}
            onClick={() => downloadPDF(id, shortId, createdAt)}
            label={t("component.orderHeader.downloadPDF")}
          />
          <Button
            icon={media}
            onClick={downloadQrCode}
            label={t("component.orderHeader.downloadPNG")}
          />
        </ButtonsContainer>
      </Modal>
    </div>
  );
};

export default OrderQR;
