import React, { useCallback, useEffect, useState } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import TabNavBar from "../../components/TabNavBar";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { GridContainer } from "../../components/GridContainer/GridContainer";

import { Loading } from "../../components/Loading";
import message from "antd/lib/message";
import DeleteModal from "../../components/DeleteModal";
import styles from "./styles.module.css";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import APIError from "../../utils/APIError";
import { useTranslation } from "react-i18next";
import {
  defaultRole,
  deleteRol,
  getRole,
  Permission,
  permissionGroupList,
  permissionList,
  Role,
  updateRole,
} from "../../services/roles";
import PermissionDetail from "../../components/RoleDetailComponent/PermissionDetail";
import RoleDetailComponent from "../../components/RoleDetailComponent";
import PermissionForm from "../../components/RoleForm/PermissionForm";
import RoleForm from "../../components/RoleForm";
import { EmptyList } from "../../components/List/EmptyList";
import { ButtonsContainer } from "../../components/ButtonsContainer";
import { BackLink } from "../../components/BackLink";
import { Button } from "../../components/Button";
import { EditableTitle } from "../../components/EditableTitle";
import { ContainerTitle } from "../../components/ContainerTitle";

interface RouteParams {
  id: string;
}

interface Props extends RouteComponentProps<RouteParams> {}

const RoleDetail: React.FC<Props> = ({ match, history }) => {
  const [role, setRole] = useState<Role>(defaultRole);
  const auth = useSelector((state: RootState) => state.auth);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [group, setGroup] = useState("assets");

  const [loadError, setError] = useState("");
  const [edit, setEdit] = useState(false);
  const { t } = useTranslation();

  const [loading, setLoading] = useState("");
  const [open, setOpen] = useState(false);
  const [permissionGroups, setPermissionsGroups] = useState<string[]>(
    []
  );

  const loadGroups = useCallback(async () => {
    try {
      setLoading("roles");
      const res = await permissionGroupList();
      setPermissionsGroups(res);
      setLoading("");
    } catch (err) {
      if (err instanceof APIError) {
        setError(err.message);
      }
    }
  }, []);

  useEffect(() => {
    loadGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadRoles = useCallback(async () => {
    try {
      setLoading("roles");
      const res = await permissionList({
        page: 0,
        records_per_page: 200,
        group,
      });
      setPermissions(res.hits);
      setLoading("");
    } catch (err) {
      if (err instanceof APIError) {
        setError(err.message);
      }
    }
  }, [group]);

  useEffect(() => {
    loadRoles();
  }, [loadRoles]);

  const load = useCallback(async () => {
    try {
      const res = await getRole(match.params.id);
      setRole(res);
    } catch (err) {
      if (err instanceof APIError) {
        setError(err.message);
      }
    }
  }, [match.params.id]);

  useEffect(() => {
    load();
  }, [match.params.id, load]);

  useEffect(() => {
    document.title = "RAVENT APP :: Roles :: Details";
  });

  if (loadError) {
    return (
      <EmptyList
        instructions={""}
        buttonText={`${t("container.roleDetail.goBack")}`}
        onClick={() => history.push("/users")}
        text={`${t("container.roleDetail.error")}`}
        error={loadError}
      />
    );
  }

  if (!auth.loading && !auth.user) {
    return <Redirect from="*" to="/" />;
  }

  if (!role) {
    return <Loading />;
  }

  const onChange = <P extends keyof Role>(prop: P, value: Role[P]) =>
    setRole({ ...role, [prop]: value });

  const onUserUpdate = async () => {
    try {
      setLoading("update");
      const res = await updateRole(role);
      message.success(
        `${t("container.userDetail.label")} ${res.name} ${t(
          "container.userDetail.successCreation"
        )}`
      );
      setEdit(false);
      setLoading("");
      load();
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`Error: ${err.messages}`);
      }
      setLoading("");
    }
  };

  const onDelete = async () => {
    try {
      setLoading("delete");
      await deleteRol(role.id);
      history.push("/organization/roles");
      setOpen(false);
      setLoading("");
    } catch (err) {
      if (err instanceof APIError) {
        message.error(err.messages);
      }
      setLoading("");
    }
  };

  const tabs = [
    {
      id: "roledetails",
      label: `${t("container.roleDetail.details")}`,
      path: `/organization/roles/details/${match.params.id}`,
      onClick: () => (document.title = "RAVENT APP :: Org :: Roles"),
    },
    {
      id: "permissionsDetails",
      label: t("container.roleDetail.permissions"),
      path: `/organization/roles/permissions/${match.params.id}`,
      onClick: () => (document.title = "RAVENT APP :: Org :: Roles"),
    },
  ];

  return (
    <LayouWithSideBar>
      <div className={styles.container}>
        <FlexContainer
          padding="1em 0 0em 0"
          justifyContent="space-between"
          alignItems="center"
        >
          <BackLink
            label={t("container.roleDetail.backToUsers")}
            onClick={() => history.push("/organization/roles")}
          />

          {edit ? (
            <ButtonsContainer>
              <Button
                theme="white"
                label={t("container.roleDetail.cancel")}
                onClick={() => {
                  setEdit(false);
                  load();
                }}
              />
              <Button
                label={loading ? "..." : `${t("container.roleDetail.save")}`}
                onClick={() => {
                  if (!loading) {
                    onUserUpdate();
                  }
                }}
              />
            </ButtonsContainer>
          ) : (
            <ButtonsContainer>
              <Button
                theme="red"
                label={t("container.roleDetail.delete")}
                onClick={() => setOpen(true)}
              />
              <Button
                label={t("container.roleDetail.edit")}
                onClick={() => setEdit(true)}
              />
            </ButtonsContainer>
          )}
        </FlexContainer>
        <DeleteModal
          loading={loading === "delete"}
          open={open}
          onDelete={() => onDelete()}
          onCancel={() => setOpen(false)}
          value={`${t("container.roleDetail.deleteMessage")} ${
            role.name
          }  permanently?`}
        />
        {edit && role.name ? (
          <GridContainer padding="1em 0 0 0" gap="1em" columns="1fr 1fr">
            <EditableTitle
              value={role.name}
              onChange={(value) => onChange("name", value)}
            />
          </GridContainer>
        ) : (
          <ContainerTitle
            padding="1em 0 0 0"
            size="medium"
            label={`${role.name}`}
          />
        )}
        <TabNavBar options={tabs}>
          <Switch>
            <Route path={`/organization/roles/details/${match.params.id}`}>
              {!edit ? (
                <RoleDetailComponent data={role} />
              ) : (
                <RoleForm data={role} onChange={onChange} />
              )}
            </Route>
            <Route path={`/organization/roles/permissions/${match.params.id}`}>
              {!edit ? (
                <>
                  <PermissionDetail
                    permissionsGroups={
                      permissionGroups.map((item) => item) || [
                        "actions",
                        "messaging",
                        "orders",
                        "organizations",
                        "fops",
                        "integrations",
                        "assets",
                        "ott",
                        "payments",
                        "users",
                        "webhooks",
                      ]
                    }
                    group={group}
                    setGroup={setGroup}
                    loading={loading === "roles"}
                    permissions={permissions}
                    error={loadError}
                    data={role}
                  />
                </>
              ) : (
                <PermissionForm
                  creation={false}
                  data={role}
                  group={group}
                  setGroup={setGroup}
                  permissionsGroups={
                    permissionGroups.map((item) => item) || [
                      "actions",
                      "messaging",
                      "orders",
                      "organizations",
                      "fops",
                      "integrations",
                      "assets",
                      "ott",
                      "payments",
                      "users",
                      "webhooks",
                    ]
                  }
                  permissions={permissions}
                  loading={loading === "roles"}
                  error={loadError}
                  onChange={onChange}
                />
              )}
            </Route>
          </Switch>
        </TabNavBar>
      </div>
    </LayouWithSideBar>
  );
};

export default RoleDetail;
