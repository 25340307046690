const translation = {
  en: {
    validationError: "Passwords are not the same.",
    welcome: "You have been invited to join the",
    workspace: "workspace",
    instructions:
      "Please setup your new password and optionally provide us with your name and last name.",
    name: "First Name",
    lastName: "Last Name",
    password: "Password",
    confirmPassword: "ConfirmPassword",
  },
  es: {
    validationError: "Las contraseñas no son las mismas",
    welcome: "Ha sido invitado a unirse al",
    workspace: "espacio de trabajo",
    instructions:
      "Configure su nueva contraseña y, opcionalmente, proporciónenos su nombre y apellido",
    name: "Nombre",
    lastName: "Apellido",
    password: "Contraseña",
    confirmPassword: "Contraseña Confirmada",
  },
  de: {
    validationError: "Passwords are not the same.",
    welcome: "You have been invited to join the",
    workspace: "workspace",
    instructions:
      "Please setup your new password and optionally provide us with your name and last name.",
    name: "Name",
    lastName: "Last Name",
    password: "Password",
    confirmPassword: "ConfirmPassword",
  },
};

export default translation;
