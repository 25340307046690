import React from "react";

import styles from "./styles";
import { useTranslation } from "react-i18next";
import OrderStateList from "../OrderStateList";
import Text from "../Text";
import { black } from "../../utils/colors";
import { VenueEventTriggers } from "../../utils/types";
import { Card } from "../Card/Card";
import { ContainerTitle } from "../ContainerTitle";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { Button } from "../Button";

interface Props {
  orderEvents: VenueEventTriggers;
  edit: string;
  setEdit: (value: string) => void;
  onEdit: () => void;
  loading: string;
}

const EventTriggers: React.FC<Props> = ({ orderEvents, setEdit }) => {
  const { t } = useTranslation();

  const events = [
    {
      name: "creation",
      actionName: orderEvents.creation.actionName,
      automatic: orderEvents.creation.automatic,
      actionId: orderEvents.creation.actionId,
    },
    {
      name: "update",
      actionName: orderEvents.update.actionName,
      automatic: orderEvents.update.automatic,
      actionId: orderEvents.update.actionId,
    },
    {
      name: "state transition",
      actionName: orderEvents.stateTransition.actionName,
      automatic: orderEvents.stateTransition.automatic,
      actionId: orderEvents.stateTransition.actionId,
    },
  ];

  return (
    <div>
      <Card containerClassname={styles.wrapperCard}>
        <FlexContainer padding="0 0 1em 0" justifyContent="space-between">
          <ContainerTitle
            size="small"
            label={t("component.venueEvents.eventTriggers")}
          />
          <Button
            label={t("container.rIntegration.edit")}
            onClick={() => setEdit("event-triggers")}
          />
        </FlexContainer>

        <Text
          textAlign="justify"
          padding="0 0 2em 0"
          color={black}
          text={t("component.venueEvents.url")}
        />
        <OrderStateList data={events} event />
      </Card>
    </div>
  );
};

export default EventTriggers;
