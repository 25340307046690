import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { ContainerTitle } from "../ContainerTitle";

import React, { useState } from "react";
import Text from "../Text";
import styles from "../FulfillerList/styles";
import { useTranslation } from "react-i18next";
import { Modal } from "../Modal/Modal";
import { InputError } from "../Inputs/InputError/InputError";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { InputTextarea } from "../Inputs/InputTextArea";

interface Props {
  onClose: () => void;
  open: boolean;
  onSend: () => void;
  loading?: boolean;
  error?: string;
}

const SendMessage: React.FC<Props> = ({
  onClose,
  onSend,
  open,
  error,
  loading,
}) => {
  const [message, setMessage] = useState("");

  const { t } = useTranslation();

  return (
    <div className={styles.mcontainer}>
      <Modal
        width="600px"
        buttons={
          <div>
            <ButtonsContainer justifyContent="flex-end">
              <Button
                onClick={onClose}
                theme="white"
                label={t("component.entityList.close")}
              />
              <Button
                onClick={() => onSend()}
                theme="blue"
                label={loading ? "..." : t("component.entityList.send")}
              />
            </ButtonsContainer>
            {error ? (
              <FlexContainer justifyContent="flex-end" padding="0.5em 1em 0 0">
                <InputError error={error} />
              </FlexContainer>
            ) : null}
          </div>
        }
        open={open}
        onClose={onClose}
      >
        <div className={styles.wrapper}>
          <ContainerTitle
            size="medium"
            label={t("component.entityList.titleSend")}
          />
          <Text
            padding="1em 0 1em 0"
            text={t("component.entityList.how")}
            color="#000000"
            fontSize="1.1em"
            fontWeight={500}
          />
          <InputTextarea
            value={message}
            onChange={(value) => setMessage(value)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default SendMessage;
