import React from "react";
import { Card } from "../Card/Card";
import styles from "./styles";

import { useTranslation } from "react-i18next";
import { Role } from "../../services/roles";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";
import { SectionProperty } from "../Section/SectionProperty";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import copy from "../../assets/copy.svg";
interface Props {
  data: Role;
}

const RoleDetailComponent: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  function copyToClipboard(id: string): void {
    navigator.clipboard.writeText(id).then(
      () => {
        console.log("Copied to clipboard successfully!");
      },
      (err) => {
        console.error("Failed to copy to clipboard: ", err);
      }
    );
  }

  return (
    <>
      <div className={styles.container}>
        <Card>
          <div className={styles.wrapperCard}>
            <ContainerTitle
              size="small"
              padding="0 0 1em 0"
              label={t("component.roleDetailComponent.details")}
            />
            <GridContainer gap="1em" columns="1fr 1fr">
              <SectionProperty
                label={t("component.roleDetailComponent.description")}
                value={data.description}
              />
              <div>
                <InputLabel label={t("component.roleDetailComponent.roleId")} />
                <FlexContainer justifyContent="flex-start">
                  <div>{data.id}</div>
                  <img
                    style={{ marginLeft: "1em", width: "1em", cursor: "pointer" }}
                    src={copy}
                    
                    alt="copy"
                    onClick={() => copyToClipboard(data.id)}
                  />
                </FlexContainer>
              </div>
            </GridContainer>
            <ContainerTitle
              size="small"
              padding="0 0 1em 0"
              label={t("component.roleDetailComponent.scope")}
            />
            <SectionProperty
              label={t("component.roleDetailComponent.type")}
              value={data.scope}
            />
          </div>
        </Card>
      </div>
    </>
  );
};

export default RoleDetailComponent;
