import React from "react";
import { PaymentListSkeleton } from "../ListSkeletons";
import { useTranslation } from "react-i18next";
import { ScheduleLog } from "@innomius/ravent-typescript-types";
import ScheduleLogItem from "./ScheduleLogItem";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { List } from "../List";

interface StateProps {
  error: string;
  loading: boolean;
  data: ScheduleLog[];
  action: boolean;
}

const ScheduleLogList: React.FC<StateProps> = ({
  error,
  data,
  loading,
  action,
}) => {
  const { t } = useTranslation();

  const header = (
    <div>
      <ListHeader template={!action ? "1.5fr 3fr 1fr 1fr" : "1.5fr 3fr 1fr"}>
        <ListHeaderLabel
          justifyContent="flex-start"
          value={t("component.actionListLogs.timestamp")}
        />
        <ListHeaderLabel
          value={t("component.actionListLogs.url")}
          textAlign="center"
          justifyContent="flex-start"
        />
        {!action ? (
          <ListHeaderLabel
            value={t("component.actionListLogs.scheduleId")}
            textAlign="center"
            justifyContent="flex-start"
          />
        ) : null}

        <ListHeaderLabel
          value={t("component.actionListLogs.statusCode")}
          textAlign="center"
          justifyContent="flex-start"
        />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <List
        loading={loading}
        loadingElement={() => <PaymentListSkeleton />}
        error={error}
        data={data}
        keyExtractor={(item) => item.id}
        renderHeader={() => header}
        renderItem={(item) => (
          <ScheduleLogItem action={action} key={item.id} data={item} />
        )}
      />
    </div>
  );
};

export default ScheduleLogList;
