import React, { useState } from "react";
import Item from "./Item";
import { FulfillerOverview } from "../../utils/types";

import { OrderListSkeleton } from "../ListSkeletons";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { List } from "../List";

interface Props {
  error: string;
  loading: boolean;
  data: FulfillerOverview[];
  fulfillmentTeamId: string;
  from: string;
  to: string;
  timezone: string;
  close: () => void;
  onChangeSortOrder?: (sort: string) => void;
}

const FulfillerOverviewList: React.FC<Props> = ({
  error,
  loading,
  data,
  fulfillmentTeamId,
  from,
  to,
  timezone,
  close,
  onChangeSortOrder,
}) => {
  const { t } = useTranslation();
  const [order, setOrder] = useState("asc");
  const [sort, setSort] = useState<string | null>(null);

  const header = (
    <div>
      <ListHeader template="2fr 1fr 1fr 1fr 1fr 1fr 1fr">
        <ListHeaderLabel
          value={t("component.fulfillerOverviewList.name")}
          justifyContent="flex-start"
          sortable={true}
          sort={
            sort === "fulfillerName" ? (order === "asc" ? "asc" : "desc") : null
          }
          onClick={() => {
            setSort("fulfillerName");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`fulfillerName:${order}`);
            }
          }}
        />
        <ListHeaderLabel
          value={t("component.fulfillerOverviewList.pending")}
          justifyContent="flex-start"
          sortable={true}
          sort={sort === "Pending" ? (order === "asc" ? "asc" : "desc") : null}
          onClick={() => {
            setSort("Pending");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`Pending:${order}`);
            }
          }}
        />
        <ListHeaderLabel
          value={t("component.fulfillerOverviewList.running")}
          justifyContent="flex-start"
          sortable={true}
          sort={sort === "Running" ? (order === "asc" ? "asc" : "desc") : null}
          onClick={() => {
            setSort("Running");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`Running:${order}`);
            }
          }}
        />
        <ListHeaderLabel
          value={t("component.fulfillerOverviewList.failed")}
          justifyContent="flex-start"
          sortable={true}
          sort={sort === "Failed" ? (order === "asc" ? "asc" : "desc") : null}
          onClick={() => {
            setSort("Failed");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`Failed:${order}`);
            }
          }}
        />
        <ListHeaderLabel
          value={t("component.fulfillerOverviewList.fulfilled")}
          justifyContent="flex-start"
          sortable={true}
          sort={
            sort === "Completed" ? (order === "asc" ? "asc" : "desc") : null
          }
          onClick={() => {
            setSort("Completed");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`Completed:${order}`);
            }
          }}
        />
        <ListHeaderLabel
          value={t("component.fulfillerOverviewList.total")}
          justifyContent="flex-start"
          sortable={true}
          sort={sort === "Total" ? (order === "asc" ? "asc" : "desc") : null}
          onClick={() => {
            setSort("Total");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`Total:${order}`);
            }
          }}
        />
        <ListHeaderLabel value={""} justifyContent="flex-start" />
      </ListHeader>
    </div>
  );

  return (
    <div className={styles.listContainer}>
      <List
        loading={loading}
        loadingElement={() => <OrderListSkeleton />}
        error={error}
        data={data}
        empty={!data.length}
        keyExtractor={(item) => item.fulfillerId}
        renderHeader={() => header}
        renderItem={(item) => (
          <Item
            timezone={timezone}
            fromPassed={from}
            toPassed={to}
            close={close}
            fulfillmentTeamId={fulfillmentTeamId}
            data={item}
            key={item.fulfillerId}
          />
        )}
      />
    </div>
  );
};

export default FulfillerOverviewList;
