import React from "react";
import Text from "../../components/Text";
import styles from "./styles";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { Card } from "../../components/Card/Card";
import { Button } from "../../components/Button";

import successIcon from "../../assets/check.svg";
import appStoreIcon from "../../assets/store-apple.svg";
import googleIcon from "../../assets/store-android.svg";
import { useHistory } from "react-router";
import { ButtonsContainer } from "../../components/ButtonsContainer";
import { SectionSpacer } from "../../components/Section/SectionSpacer";

const SuccessScreenEnroll: React.FC = () => {
  const history = useHistory();
  return (
    <Card containerClassname={styles.cardContainer}>
      <img alt="" src={successIcon} />
      <p className={styles.textThank}>Thank you</p>
      <Text
        text="You can now login to our Back of the House Web interface or you can download the Concierge app from the stores."
        fontSize="1.1em"
        color="#000"
        padding="2em 0"
      />
      <FlexContainer padding="0 0 1em 0">
        <div className={styles.loginButton}>
          <Button
            label="Login"
            width="11em"
            onClick={() => history.push("/")}
          />
        </div>
      </FlexContainer>
      <SectionSpacer />
      <Text
        text="Don't have the Concierge App yet ? "
        fontSize="1.1em"
        color="#000"
        padding="1em 0 0 0"
      />
      <Text
        text="Download it from Apple Store or  Google Play"
        fontSize="1.1em"
        color="#000"
        padding="0em 0 2em 0"
      />
      <ButtonsContainer>
        <img
          onClick={() =>
            (window.location.href =
              "https://apps.apple.com/de/app/ravent-concierge/id1575005586?l=en")
          }
          src={appStoreIcon}
          alt=""
          className={styles.button}
        />
        <img src={googleIcon} alt="" className={styles.button} />
      </ButtonsContainer>
    </Card>
  );
};

export default SuccessScreenEnroll;
