const translation = {
  en: {
    requestPayment: "Request Payment",
    warning:
      "For this order the sum of the payments requests excceed the order amount. Please erase unnecesary requests to create more.",
    registerPayment: "Register Payment",
    issueRefund: "Issue Refund",
    paymentsReceived: "Payments Received",
    empty: "Empty List",
    noPayments: "No Payments",
    invoices: "Invoices",
    noInvoices: "No Invoice uploaded",
    uploadInvoice: "Upload Invoice",
    addIssueInvoice: "Add/Issue Invoice",
    invoice: "Invoice",
    addInvoice: "+ Add Invoice",
    issueInvoice: "Issue Invoice",
  },
  es: {
    requestPayment: "Solicitar pago",
    warning:
      "Para esta orden, la suma de las solicitudes de pago supera el monto del pedido. Borre las solicitudes innecesarias para crear más",
    registerPayment: "Registrar Pago",
    issueRefund: "Emitir reembolso",
    paymentsReceived: "Pagos Recibidos",
    empty: "Lista vacía",
    noPayments: "No tienes ningún pago",
    invoices: "Facturas",
    noInvoices: "No tienes ningúna factura registrada",
    uploadInvoice: "Subir Factura",
    addIssueInvoice: "Agregar/Emitir Factura",
    invoice: "Factura",
    addInvoice: "+ Agregar Factura",
    issueInvoice: "Emitir Factura",
  },
  de: {},
};

export default translation;
