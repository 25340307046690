import crossFetch from "cross-fetch";
import CustomError from "./CustomError";
import APIError from "./APIError";
import { dataURLtoBlob } from "./images";

export function createFetch(baseApiUrl: string) {
  return async function customFetch(
    url: string,
    token?: string | null,
    files?: any,
    method?: string
  ) {
    const formData = new FormData();

    files.forEach((imageData: any, index: number) => {
      const blob = dataURLtoBlob(imageData);
      formData.append(`file${index + 1}`, blob);
    });

    try {
      const res = await crossFetch(`${baseApiUrl}${url}`, {
        method: method ? method : "POST",
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
        body: formData,
      });

      if (res.ok && res.status !== 204) {
        const json = await res.json();
        return { status: res.status, body: json };
      }

      if (res.status >= 400) {
        const json = await res.json();
        throw new APIError(json.error, json.message, res.status.toString());
      }

      return { status: res.status, body: undefined };
    } catch (error) {
      if (
        error instanceof Error &&
        error.message === "Network request failed"
      ) {
        throw new CustomError("network", "newtwork");
      }
      throw error;
    }
  };
}

const multiPartMultipleImages = createFetch("/api/v1");

export default multiPartMultipleImages;
