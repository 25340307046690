const translation = {
  en: {
    deleteWarning: "Are you sure you want to delete this Team?",
    back: "Back to Teams",
    cancel: "Cancel",
    save: "Save",
    delete: "Delete",
    edit: "Edit Team",
    sendMessage: "Send Message",
  },
  es: {
    deleteWarning: "¿Está seguro de que desea eliminar este equipo?",
    back: "Volver a Equipos",
    cancel: "Cancelar",
    save: "Guardar",
    delete: "Eliminar",
    edit: "Editar Equipo",
    sendMessage: "Mandar mensaje",
  },
  de: {},
};

export default translation;
