import React from "react";
import { Media } from "@innomius/ravent-typescript-types";
import { OfficialMediaListSkeleton } from "../ListSkeletons";
import OfficialMediaItemList from "./OfficialMediaItemList";
import { useTranslation } from "react-i18next";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { List } from "../List";

interface OfficialMediasListProps {
  error: string;
  loading: boolean;
  data: Media[];
  onChangeSortOfficialMedia?: (order: number, sortBy: number) => void;
  sortable: boolean;
  editOfficialMedia: (id: string, officialMediaPublic: boolean) => void;
  deleteMedia: (id: string) => void;
}

const OfficialMediasList: React.FC<OfficialMediasListProps> = ({
  error,
  loading,
  data,
  editOfficialMedia,
  deleteMedia,
}) => {
  const { t } = useTranslation();
  const header = (
    <div>
      <ListHeader template="1fr 1fr 0.5fr 0.5fr 0.7fr">
        <ListHeaderLabel
          value={t("component.officialMediaList.id")}
          justifyContent={"flex-start"}
        />
        <ListHeaderLabel
          value={t("component.officialMediaList.documentName")}
          justifyContent={"flex-start"}
        />
        <ListHeaderLabel
          value={t("component.officialMediaList.type")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.officialMediaList.qrCode")}
          justifyContent="center"
        />
        <ListHeaderLabel value="" justifyContent="center" />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <List
        loading={loading}
        loadingElement={() => <OfficialMediaListSkeleton />}
        error={error}
        data={data}
        keyExtractor={(item) => item.id}
        renderHeader={() => header}
        renderItem={(item) => (
          <OfficialMediaItemList
            editOfficialMedia={(value) => editOfficialMedia(item.id, value)}
            data={item}
            key={item.id}
            deleteMedia={deleteMedia}
          />
        )}
      />
    </div>
  );
};

export default OfficialMediasList;
