import React from "react";
import { black } from "../../utils/colors";
import styles from "../SendModal/styles";
import Text from "../Text";

interface Props {
  onClick: () => void;
  image: string;
  active: boolean;
  label?: string;
  disable?: boolean;
  horizontal?: boolean;
}

const SendButton: React.FC<Props> = ({
  onClick,
  image,
  label,
  disable,
  active,
}) => {
  return (
    <div
      onClick={!disable ? () => onClick() : () => {}}
      className={!active ? styles.iconContainer : styles.iconContainerActive}
    >
      <img className={styles.icon} src={image} alt="" />
      {label ? (
        <Text padding="0 0 0 0.5em" text={label} fontSize="1em" color={black} />
      ) : null}
    </div>
  );
};

export default SendButton;
