import { OrderInvoice } from "@innomius/ravent-typescript-types";

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { linkBlue } from "../../utils/colors";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { formatDate } from "../../utils/times";
import pdfIcon from "../../assets/pdf.svg";
import upload from "../../assets/upload.svg";
import download from "../../assets/download.svg";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { message } from "antd";
import APIError from "../../utils/APIError";
import { downloadInvoice } from "../../services/pdfs";
import EditInvoice from "./EditInvoice";
import { FormComponent } from "../../utils/types";
import { refundInvoiceMock } from "../../mocks/refunds";
import IssueRefundDyn from "../IssueRefundDyn";
import CancelInvoiceDyn from "../CancelInvoiceDyn";
import { cancelInvoiceMock } from "../../mocks/invoices";
import { notifyWebHook } from "../../services/orders";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { GridContainer } from "../GridContainer/GridContainer";
import { SectionProperty } from "../Section/SectionProperty";
import { SectionSpacer } from "../Section/SectionSpacer";

interface Props {
  data: OrderInvoice;
  onClose: () => void;
  onUpload: () => void;
  orderId: string;
  payments: { label: string; value: string }[];
  requestIds: { label: string; value: string }[];
  onChange: <P extends keyof OrderInvoice>(
    prop: P,
    value: OrderInvoice[P]
  ) => void;
  onUpdate: () => void;
  refundActionId: string;
  load: () => void;
  cancelInvoiceActionId: string;
}

const InvoiceDetail: React.FC<Props> = ({
  data,
  onClose,
  onUpload,
  payments,
  requestIds,
  onChange,
  onUpdate,
  refundActionId,
  load,
  orderId,
  cancelInvoiceActionId,
}) => {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openRefund, setOpenR] = useState(false);
  const [openCancellation, setOpenC] = useState(false);

  const { t } = useTranslation();

  const onDownload = async (url: string, name: string, fileType: string) => {
    try {
      await downloadInvoice(url, name, fileType, open);
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const [schemeElements, setElements] = useState<FormComponent[]>(
    refundInvoiceMock.elements
  );

  const [schemeElementsCancellation, setElementsCancellation] = useState<
    FormComponent[]
  >(cancelInvoiceMock.elements);

  const onIssueRefund = async (invoiceId: string, refund: FormComponent[]) => {
    try {
      setLoading(true);
      const map = refund.map((item) => [item.name, item.value]);

      const body = {
        orderId: orderId,
        invoiceId,
        refundData: {
          ...Object.fromEntries(map),
        },
      };

      await notifyWebHook({ actionId: refundActionId, notificationBody: body });
      setLoading(false);
      message.success(t("component.orderInvoiceF.success"));
      load();
    } catch (err) {
      setLoading(false);
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onCancelInvoice = async (
    invoiceId: string,
    cancellation: FormComponent[]
  ) => {
    try {
      setLoading(true);
      const map = cancellation.map((item) => [item.name, item.value]);

      const body = {
        orderId: orderId,
        invoiceId,
        refundData: {
          ...Object.fromEntries(map),
        },
      };

      const res = await notifyWebHook({
        actionId: cancelInvoiceActionId,
        notificationBody: body,
      });
      if (res.successful === false) {
        message.error({
          content: `${res.statusCode} ${res.message}`,
        });
      }
      if (res.successful === true) {
        message.success(t("component.invoiceDetail.successC"));
      }
      setLoading(false);
      load();
    } catch (err) {
      setLoading(false);
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  return (
    <>
      <IssueRefundDyn
        onIssueRefund={() => onIssueRefund(data.id, schemeElements)}
        webhook={refundActionId}
        loading={loading}
        onChangeSchema={(elements) => setElements(elements)}
        refundSchemaElements={schemeElements}
        invoiceId={data.id}
        open={openRefund}
        onCancel={() => setOpenR(false)}
      />
      <CancelInvoiceDyn
        onCancelInvoice={() =>
          onCancelInvoice(data.id, schemeElementsCancellation)
        }
        webhook={cancelInvoiceActionId}
        loading={loading}
        onChangeSchema={(elements) => setElementsCancellation(elements)}
        cancelSchemaComponents={schemeElementsCancellation}
        invoiceId={data.id}
        open={openCancellation}
        onClose={() => setOpenC(false)}
      />
      <div>
        <div className={styles.containerDetail}>
          <FlexContainer
            padding="0 0 1em 0"
            alignItems="center"
            justifyContent="space-between"
          >
            <ContainerTitle
              size="xsmall"
              label={t("component.invoiceDetail.invoiceDetails")}
            />
            <div>
              {edit ? (
                <ButtonsContainer>
                  <Button
                    label={t("component.invoiceDetail.editCancel")}
                    theme="white"
                    onClick={() => setEdit(false)}
                  />
                  <Button
                    label={t("component.invoiceDetail.save")}
                    onClick={() => {
                      try {
                        onUpdate();
                        setEdit(false);
                      } catch (err) {}
                    }}
                  />
                </ButtonsContainer>
              ) : (
                <FlexContainer>
                  <div style={{ marginRight: "1em" }}>
                    <Button
                      label={t("component.invoiceDetail.refund")}
                      theme="white"
                      onClick={() => setOpenR(true)}
                    />
                  </div>

                  <Button
                    label={t("component.invoiceDetail.cancel")}
                    theme="white"
                    onClick={() => setOpenC(true)}
                    disable={data.status === ("canceled" as "cancelled")}
                  />

                  <div
                    className={styles.editContainer}
                    onClick={() => setEdit(true)}
                  >
                    <EditIcon className={styles.iconEdit} />
                  </div>
                </FlexContainer>
              )}
            </div>
          </FlexContainer>

          {edit ? (
            <EditInvoice
              payments={payments}
              requestIds={requestIds}
              data={data}
              onChange={onChange}
            />
          ) : (
            <>
              <GridContainer
                gap="1em"
                columns="1fr 1fr"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <SectionProperty
                  label={t("component.invoiceDetail.invoiceId")}
                  value={data.id}
                />
                <SectionProperty
                  label={t("component.invoiceDetail.description")}
                  value={data.description || ""}
                />
              </GridContainer>
              <SectionSpacer />
              <ContainerTitle
                size="xsmall"
                padding="0 0 1em 0"
                label={t("component.invoiceDetail.invoiceData")}
              />
              <GridContainer
                gap="1em"
                columns="0.4fr 1fr"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <div className={styles.bold}>
                  {t("component.invoiceDetail.requestId")}
                </div>
                <div>{data.paymentRequestID || "-"}</div>
                <div className={styles.bold}>
                  {t("component.invoiceDetail.paymentId")}
                </div>
                <div>{data.paymentID || "-"}</div>
                <div className={styles.bold}>
                  {t("component.invoiceDetail.issuer")}
                </div>
                <div>{data.issuer}</div>
                <div className={styles.bold}>
                  {t("component.invoiceDetail.issuerId")}
                </div>
                <div>{data.issuerId}</div>
                <div className={styles.bold}>
                  {t("component.invoiceDetail.authority")}
                </div>
                <div>{data.authority}</div>
                <div className={styles.bold}>
                  {t("component.invoiceDetail.authorityId")}
                </div>
                <div>{data.authorityId}</div>
                <div className={styles.bold}>
                  {t("component.invoiceDetail.issueTimestamp")}
                </div>
                <div>{formatDate(data.issuedDate)}</div>
              </GridContainer>
            </>
          )}

          <SectionSpacer />
          <FlexContainer
            padding="0 0 1em 0"
            alignItems="center"
            justifyContent="space-between"
          >
            <ContainerTitle
              size="xsmall"
              label={t("component.invoiceDetail.files")}
            />
            <Button
              label={t("component.invoiceDetail.uploadFiles")}
              theme="white"
              icon={upload}
              onClick={() => onUpload()}
            />
          </FlexContainer>
          <GridContainer gap="2em" columns="1fr 1fr">
            {data.files.map((item) => (
              <div key={item.id} className={styles.box}>
                <GridContainer
                  gap="1em"
                  key={item.name}
                  columns="24px 1fr 0.1fr"
                >
                  <img src={pdfIcon} className={styles.icon} alt="" />
                  <div>{item.name}</div>
                  <FlexContainer alignItems="flex-end">
                    <div className={styles.gray}>{item.fileSize}</div>
                    <img
                      src={download}
                      className={styles.icon}
                      alt=""
                      onClick={() =>
                        onDownload(
                          item.issuerUrl,
                          item.name,
                          item.name.split(".")[1]
                        )
                      }
                    />
                  </FlexContainer>
                </GridContainer>
              </div>
            ))}
          </GridContainer>
        </div>
        <div
          className={styles.closeButton}
          onClick={() => {
            setOpen(!open);
            onClose();
          }}
        >
          {t("component.invoiceDetail.close")}
          <FontAwesomeIcon
            icon={faChevronUp as IconProp}
            className={styles.chevron}
            color={linkBlue}
          />
        </div>
      </div>
    </>
  );
};

export default InvoiceDetail;
