const translation = {
  en: {
    requestPayment: "Request Payment",
    cancel: "Cancel",
    request: "Request",
    paymentType: "Payment Type",
    amount: "Amount",
    supplierName: "Supplier Name / Service",
    bankAccount: "Bank Account",
    expDate: "Expiration Date",
    overPaid: "The amount exceeds the due amount",
    sendViaEmail: "Send Request via email",
    downWarning:
      "This form of payment accepts only round down amount , please erase all decimals.",
    upWarning:
      "This form of payment accepts only round up values, please erase all decimals.",
  },
  es: {
    requestPayment: "Solicitar Pago",
    cancel: "Cancelar",
    request: "Solicitud",
    paymentType: "Tipo de pago",
    amount: "Cantidad",
    supplierName: "Nombre del proveedor / servicio",
    bankAccount: "Cuenta Bancaria",
    expDate: "Fecha de vencimiento",
    sendViaEmail: "Enviar por correo electrónico",
    overPaid: "La cantidad excede la cantidad adeudada",
    upWarning:
      "Esta forma de pago solo acepta valores redondeados hacia arriba, borre los decimales.",
    downWarning:
      "Esta forma de pago solo acepta valores de montos redondeados hacia abajo, por favor borre las decimales.",
  },
  de: {},
};

export default translation;
