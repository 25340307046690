import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FlexContainer } from "../FlexContainer/FlexContainer";

import DeleteModal from "../DeleteModal";
import styles from "../../components/VenueDetail/styles";
import { Venue } from "@innomius/ravent-typescript-types";
import { useTranslation } from "react-i18next";
import { BackLink } from "../BackLink";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { EditableTitle } from "../EditableTitle";
import { ContainerTitle } from "../ContainerTitle";

interface Props {
  path: string;
  onEdit: () => void;
  onSave: () => void;
  loading: string;
  onCancelEdit: () => void;
  onChange: <P extends keyof Venue>(prop: P, value: Venue[P]) => void;
  onDelete: () => void;
  edit: boolean;
  name: string;
}

const VenueDetailHeader: React.FC<Props> = ({
  onEdit,
  onCancelEdit,
  edit,
  onSave,
  onDelete,
  name,
  loading,
  onChange,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);

  return (
    <div>
      <DeleteModal
        value={t("component.venueDetailHeader.deleteWarning")}
        open={open}
        onCancel={() => setOpen(false)}
        onDelete={() => onDelete()}
        loading={loading === "delete"}
      />
      <FlexContainer justifyContent="space-between" alignItems="center">
        <BackLink
          label={t("component.venueDetailHeader.back")}
          onClick={() => history.push("/venues")}
        />
        {edit ? (
          <ButtonsContainer>
            <Button
              label={t("component.venueDetailHeader.cancel")}
              onClick={() => onCancelEdit()}
              theme="white"
            />
            <Button
              label={
                loading === "edit"
                  ? "..."
                  : `${t("component.venueDetailHeader.save")}`
              }
              onClick={() => onSave()}
            />
          </ButtonsContainer>
        ) : (
          <ButtonsContainer>
            <Button
              label={t("component.venueDetailHeader.delete")}
              theme="red"
              onClick={() => {
                setOpen(true);
              }}
            />
            <Button
              label={t("component.venueDetailHeader.edit")}
              onClick={() => onEdit()}
            />
          </ButtonsContainer>
        )}
      </FlexContainer>

      <FlexContainer justifyContent="space-between">
        {edit ? (
          <div className={styles.editableContainer}>
            <EditableTitle
              padding="1em 0 0 0"
              value={name}
              onChange={(value) => onChange("name", value)}
            />
          </div>
        ) : (
          <ContainerTitle padding="1em 0 0 0" size="medium" label={name} />
        )}
      </FlexContainer>
    </div>
  );
};

export default VenueDetailHeader;
