import { stylesheet } from "typestyle";
import { black } from "../../utils/colors";

const styles = stylesheet({
  container: {
    padding: "2em",
  },
  title: {
    fontSize: "1.5em",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "left",
    color: "#141322",
    paddingTop: "1em",
  },
  labelContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    marginRight: "0.5em",
    padding: "1em",
  },
  label: {
    margin: "0.2em 0.5em 0.2em 0",
    width: "auto",
  },

  listContainer: {
    padding: "0em",
  },

  center: {
    color: black,
    fontStyle: "normal",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    marginRight: "0.5em",
  },
  icon: {
    marginRight: "1em",
    cursor: 'pointer'
  },
  link: {
    textDecoration: "none",
    cursor: 'pointer'
  },
  paginationContainer: {
    padding: "2em 0",
    display: "flex",
    width: '100%',
    justifyContent: "center",
  },
  listWrapper: {
    marginTop: "1em",
  },
});

export default styles;
