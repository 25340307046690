const translation = {
  en: {
    cancelInvoice: "Invoice Cancellation",
    close: "Close",
    cancel: "Cancel",
    invoiceId: "Invoice ID",
  },
  es: {
    cancelInvoice: "Cancelación de la Factura",
    close: "Cerrar",
    cancel: "Cancelar",
    invoiceId: "Factura ID",
  },

  de: {},
};

export default translation;
