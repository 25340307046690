import {
  OfferFrequency,
  Offer,
  WeeklySchedule,
} from "@innomius/ravent-typescript-types";
import get from "lodash/get";

export function parseFrequency(data: unknown): OfferFrequency {
  return {
    name: get(data, "name", ""),
    startDate: get(data, "startDate", ""),
    endDate: get(data, "endDate", ""),
    enabled: get(data, "enabled", false),
    weeklySchedule: get(data, "weeklySchedule", []),
  };
}

export function parseSchedule(data: unknown): WeeklySchedule {
  return {
    weekday: get(
      data,
      "weekday",
      "" as
        | "monday"
        | "tuesday"
        | "wednesday"
        | "thursday"
        | "friday"
        | "saturday"
        | "sunday"
    ),
    hour: get(data, "hour", ""),
  };
}

export const defaulVariant: Offer = {
  name: "",
  id: "",
  image: "",
  description: "",
  price: {
    currency: "",
    value: 0,
  },
  modifiers: [],
  locationId: "",
  ticketingEnabled: true,
  frequencies: [],
  availability: "free-sell",

  languages: [],
  tags: [],
  ticketingFulfillment: {
    type: "access",
    status: [],
    orderLineStates: [],
    activationType: "at-date-time",
    maxEvents: 1,
    locations: [],
    lifeTime: {
      amount: 0,
      type: "duration",
      unit: "hour",
    },
    description: "",
    modifiers: "",
    customerNameRequired: false,
    properties: [{ value: "", valueType: "string", name: "" }],
  },
};

export const defaultFrequencyList = {
  name: "",
  startDate: new Date(),
  endDate: new Date(),
  enabled: true,
  weeklySchedule: [],
};
