import React, { useState } from "react";
import deleteIcon from "../../assets/officialMedia/default-delete.svg";
import editIcon from "../../assets/officialMedia/default-edit.svg";
import { Media } from "@innomius/ravent-typescript-types";
import styles from "./styles";
import Popover from "antd/lib/popover";
import DeleteModal from "../DeletePopUp";
import { ReactComponent as Share } from "../../assets/share.svg";
import OfficialMediaItemEdit from "./OffcialMediaItemEdit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowToBottom, faQrcode } from "@fortawesome/pro-light-svg-icons";
import { normalGray } from "../../utils/colors";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import QRCode from "qrcode";
import SendMail from "../SendModal/SendMail";
import { sendLocationMedia } from "../../services/media";
import { message } from "antd";
import APIError from "../../utils/APIError";
import { downloadMediaFile } from "../../services/pdfs";
import { useTranslation } from "react-i18next";
import copy from "../../assets/copy.svg";
import { ListLabel } from "../List/ListLabel";
import { ListRow } from "../List/ListRow";

interface OfficialMediasListProps {
  data: Media;
  editOfficialMedia: (officialMediaType: boolean) => void;
  deleteMedia: (id: string) => void;
}

const OfficialMediaItemList: React.FC<OfficialMediasListProps> = ({
  data,
  editOfficialMedia,
  deleteMedia,
}) => {
  const [open, setOpen] = useState("");
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState("");
  const [qrCode, setQRCode] = useState("");

  const { t } = useTranslation();

  const downloadQrCode = async () => {
    const checkinFlowUrl = await QRCode.toCanvas(data.public_url);
    let tagA = document.createElement("a");
    document.body.appendChild(tagA);
    tagA.href = checkinFlowUrl.toDataURL();
    tagA.download = `${data.id}/public.png`;
    tagA.click();
    document.body.removeChild(tagA);
  };

  const generateQRCODE = async () => {
    const qr = await QRCode.toCanvas(data.public_url);
    let tag = document.createElement("a");
    document.body.appendChild(tag);
    tag.href = qr.toDataURL();
    setQRCode(tag.toString());
  };

  return (
    <>
      {edit ? (
        <OfficialMediaItemEdit
          editOfficialMedia={(value) => {
            try {
              editOfficialMedia(value);
              setEdit(false);
            } catch (err) {}
          }}
          data={data}
          finishEditing={() => setEdit(false)}
        />
      ) : (
        <>
          <SendMail
            title={t("component.officialMediaList.media")}
            onDownload={async (lang) => {
              setLoading("download");
              await downloadMediaFile(
                data.id,
                data.contentType,
                false,
                data.fileName
              );
              setLoading("");
            }}
            onOpenPdf={async (lang) => {
              setLoading("open");
              await downloadMediaFile(
                data.id,
                data.contentType,
                true,
                data.fileName
              );
              setLoading("");
            }}
            loading={loading === "send"}
            loadingDownload={loading === "download" || loading === "open"}
            contactEmail={""}
            id={data.id}
            onSend={async (
              medium: string,
              contact: string,
              language: string
            ) => {
              try {
                setLoading("send");
                await sendLocationMedia(data.id, language, contact);
                message.success(`Media successfully shared.`);
                setOpen("");
                setLoading("");
              } catch (err) {
                setLoading("");
                if (err instanceof APIError) {
                  message.error(`${err.message}: ${err.messages}`);
                }
              }
            }}
            onCancel={() => setOpen("")}
            open={open === "share"}
          />
          <ListRow
            template="1fr 1fr 0.5fr 0.5fr 0.7fr"
            hover={true}
            key={data.id}
          >
            <div
              onClick={() => {
                navigator.clipboard.writeText(data.id);
                message.success(
                  `${data.fileName}  ${t("component.orderDetail.copy")}`
                );
              }}
              className={styles.normalWrap}
            >
              {data.id}
              <img src={copy} alt="copy" className={styles.iconSize} />
            </div>

            <div
              style={{ cursor: "pointer" }}
              onClick={async () =>
                await downloadMediaFile(
                  data.id,
                  data.contentType,
                  false,
                  data.fileName
                )
              }
            >
              <ListLabel value={data.fileName} textAlign="left" />
            </div>
            <ListLabel
              value={data.public ? "public" : "private"}
              textAlign="left"
            />

            <div className={styles.iconContainerQR}>
              <Popover
                content={
                  <img
                    onClick={() => setOpen("")}
                    className={styles.imageQRCODE}
                    src={qrCode}
                    alt="qr"
                  />
                }
                trigger="click"
                open={open === "qrcode"}
                onOpenChange={() => setOpen("")}
              />
              {data.public_url ? (
                <>
                  <FontAwesomeIcon
                    style={{ width: 23 }}
                    icon={faQrcode as IconProp}
                    className={styles.qrIcon}
                    color={normalGray}
                    onClick={async () => {
                      await generateQRCODE();
                      setOpen("qrcode");
                    }}
                  />
                  <FontAwesomeIcon
                    style={{ width: 23 }}
                    icon={faArrowToBottom as IconProp}
                    className={styles.qrIcon}
                    color={normalGray}
                    onClick={() => downloadQrCode()}
                  />
                </>
              ) : (
                <ListLabel textAlign="left" value="-" />
              )}
            </div>

            <div className={styles.iconContainer}>
              {data.public ? (
                <Share
                  className={styles.icon}
                  onClick={() => setOpen("share")}
                />
              ) : (
                <div className={styles.icon} />
              )}

              <img
                src={editIcon}
                alt="editIcon"
                className={styles.iconEdit}
                onClick={() => setEdit(true)}
              />
              <div>
                <Popover
                  content={
                    <div onClick={() => setOpen("")}>
                      <DeleteModal
                        onClose={() => setOpen("")}
                        onDelete={() => deleteMedia(data.id)}
                      />
                    </div>
                  }
                  trigger="click"
                  open={open === "delete"}
                  onOpenChange={() => setOpen(open)}
                />
                <img
                  src={deleteIcon}
                  alt="delete"
                  className={styles.iconEdit}
                  onClick={() => setOpen("delete")}
                />
              </div>
            </div>
          </ListRow>
        </>
      )}
    </>
  );
};

export default OfficialMediaItemList;
