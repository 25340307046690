import {
  EventFulfillmentDefinition,
  FulfillmentEvent,
  Load,
  Person,
  Placement,
} from "@innomius/ravent-typescript-types";
import get from "lodash/get";
import { getAccessToken } from "./auth";
import fetch from "../utils/fetch";
import { isNil, omitBy } from "lodash";
import { Facet, FacetEvent, Filter } from "./orders";
import {
  EventOverview,
  EventPeriod,
  MissingPlacement,
  TeamOverview,
} from "../utils/types";
import { dateFilters } from "../utils/times";
import dayjs from "dayjs";
import { uploadFiles } from "./fulfilments";

export function parsePlacement(data?: unknown): Placement {
  return {
    id: get(data, "_id", ""),
    name: get(data, "name", ""),
    chronologicalIndex: get(data, "chronologicalIndex", 0),

    externalId: get(data, "externalId", ""),
    type: get(data, "type", ""),
    coordinates: get(data, "coordinates.latitude", "")
      ? {
          latitude: get(data, "coordinates.latitude", ""),
          longitude: get(data, "coordinates.longitude", ""),
        }
      : null,
    line1: get(data, "line1", ""),
    line2: get(data, "line2", ""),
    w3w: get(data, "w3w", ""),
    reference: get(data, "reference", ""),
    country: get(data, "country", ""),
    state: get(data, "state", ""),
    city: get(data, "city", ""),
    zipCode: get(data, "zipCode", ""),
  };
}

export function parsePerson(data?: unknown): Person {
  return {
    name: get(data, "name", ""),
    lastName: get(data, "lastName", ""),
    email: get(data, "email", ""),
    phone: get(data, "phone", ""),

    dob: get(data, "dob", null),
  };
}

export function parseLoad(data?: unknown): Load {
  return {
    name: get(data, "name", ""),
    type: get(data, "type", ""),
    details: get(data, "details", ""),

    properties: get(data, "properties", {}),
  };
}

export function parseDefinition(data?: unknown): EventFulfillmentDefinition {
  return {
    name: get(data, "name", ""),
    _id: get(data, "_id", ""),
    deleted: get(data, "deleted", false),
    fulfillmentStatesDefinitions: get(data, "fulfillmentStatesDefinitions", []),
    organization_id: get(data, "organization_id", ""),
    venue_id: get(data, "venue_id", ""),
    type: get(data, "type", ""),
    _actions: get(data, "_actions", []),
    createdAt: get(data, "createdAt", ""),
    createdBy: get(data, "createdBy", ""),
    updatedAt: get(data, "updatedAt", ""),
    __v: get(data, "__v", 0),
  };
}

export function parseFEvent(data?: unknown): FulfillmentEvent {
  return {
    id: get(data, "_id", ""),
    externalId: get(data, "externalId", ""),

    fulfillmentDefinition: parseDefinition(
      get(data, "fulfillmentDefinition", {})
    ),
    fulfillmentState: {
      eventId: get(data, "fulfillmentState.eventId", ""),
      eventName: get(data, "fulfillmentState.eventName", ""),
      eventShortId: get(data, "fulfillmentState.eventShortId", ""),
      assetId: get(data, "fulfillmentState.assetId", ""),
      assetName: get(data, "fulfillmentState.assetName", ""),
      name: get(data, "fulfillmentState.name", ""),
      id: get(data, "fulfillmentState.id", ""),
      numberOfFiles: get(data, "fulfillmentState.numberOfFiles", 0),
      location: {
        type: get(data, "fulfillmentState.location.type", ""),
        w3w: get(data, "fulfillmentState.location.w3w", ""),
        coordinates: {
          latitude: get(data, "fulfillmentState.location.coordinates.latitude", ""),
          longitude: get(data, "fulfillmentState.location.coordinates.longitude", ""),
        },
      },
      timestamp: get(data, "fulfillmentState.timestamp", ""),
      userId: get(data, "fulfillmentState.userId", ""),
    },
    assetExternalId: get(data, "assetExternalId", ""),
    organizationId: get(data, "organization_id", ""),
    venueId: get(data, "venue_id", ""),
    shortId: get(data, "shortId", ""),
    orderShortId: get(data, "orderShortId", ""),
    orderlineShortId: get(data, "orderlineShortId", ""),
    fulfillmentDefinitionId: get(data, "fulfillmentDefinitionId", ""),
    currentState: get(data, "currentState", ""),
    considerTime: get(data, "considerTime", false),
    createdAt: get(data, "createdAt", ""),
    orderExternalId: get(data, "orderExternalId", ""),
    data: get(data, "data", {}),
    relatedEvents: get(data, "relatedEvents", []),

    fulfillmentTeamId: get(data, "fulfillmentTeamId", ""),
    fulfillmentTeamName: get(data, "fulfillmentTeamName", ""),
    fulfillmentLevel: get(data, "fulfillmentLevel", ""),
    fulfillerId: get(data, "fulfillerId", ""),
    fulfillerName: `${get(data, "fulfillerFirstName", "")} ${get(
      data,
      "fulfillerLastName",
      ""
    )}`,
    assetId: get(data, "assetId", ""),
    assetName: get(data, "assetName", ""),
    loads: get(data, "loads", []).map(parseLoad),
    order: get(data, "orderId", ""),
    orderlineId: get(data, "orderlineId", ""),
    timezone: get(data, "timezone", ""),
    notes: get(data, "notes", ""),
    name: get(data, "name", ""),
    state: get(data, "state", "" as "pending"),
    webhook: get(data, "webhook", ""),
    datetime: get(data, "datetime", ""),
    endDatetime: get(data, "endDatetime", ""),
    tags: get(data, "tags", []),
    colorizedTags: get(data, "colorizedTags", []),
    createdBy: get(data, "createdBy", ""),
    placements: get(data, "placements", []).map(parsePlacement),
  };
}

export interface ListParams {
  page?: number;
  records_per_page?: number;
  orderId?: string;
  orderlineId?: string;
}

export interface ListSearchResponse {
  data: FulfillmentEvent[];
  page: number;
  total: number;
}

function mapSorts(data: ListParams) {
  return omitBy(
    {
      page: data.page || null,
      records_per_page: data.records_per_page || null,
      orderlineId: data.orderlineId || null,
      orderId: data.orderId || null,
      sort: "datetime:asc",
    },
    isNil
  );
}

export async function listEvents(
  params: ListParams
): Promise<ListSearchResponse> {
  const token = await getAccessToken();
  const res = await fetch("/events", token, "GET", null, mapSorts(params));
  return {
    data: res.body ? res.body.hits.map(parseFEvent) : [],
    page: get(res.body, "page", 0),
    total: get(res.body, "count", 0),
  };
}

export const getEvent: (id: string) => Promise<FulfillmentEvent> = async (
  id
) => {
  const token = await getAccessToken();

  const res = await fetch(`/events/id/${id}`, token, "GET");
  return parseFEvent(res.body as FulfillmentEvent);
};

export async function createOrderEvent(
  data: FulfillmentEvent,
  orderId: string
): Promise<FulfillmentEvent> {
  const body = {
    name: data.name,
    datetime: data.datetime,
    webhook: data.webhook,
    tags: data.tags,
    endDatetime: data.endDatetime ? data.endDatetime : null,
    notes: data.notes,
    considerTime: data.considerTime,
    timezone: data.timezone,
  };
  const token = await getAccessToken();

  const res = await fetch(`/events/order/${orderId}`, token, "POST", body);
  return parseFEvent(res.body);
}

export async function createOrderlineEvent(
  data: FulfillmentEvent,
  orderlineId: string
): Promise<FulfillmentEvent> {
  const body = {
    name: data.name,
    datetime: data.datetime,
    webhook: data.webhook,
    endDatetime: data.endDatetime ? data.endDatetime : null,
    notes: data.notes,
    tags: data.tags,
    considerTime: data.considerTime,
    timezone: data.timezone,
  };
  const token = await getAccessToken();

  const res = await fetch(
    `/events/orderline/${orderlineId}`,
    token,
    "POST",
    body
  );
  return parseFEvent(res.body);
}

export async function deleteEvent(id: string): Promise<void> {
  const token = await getAccessToken();
  await fetch(`/events/id/${id}`, token, "DELETE");
  return;
}

export async function updateEvent(e: FulfillmentEvent) {
  const token = await getAccessToken();
  const body = {
    name: e.name,
    notes: e.notes,
    datetime: e.datetime,
    endDatetime: e.endDatetime ? e.endDatetime : null,
    webhook: e.webhook,
    tags: e.tags,
    timezone: e.timezone,
    placements: e.placements,
    considerTime: e.considerTime,
  };
  const res = await fetch(`/events/id/${e.id}`, token, "PATCH", body);
  const data = parseFEvent(res.body);
  return data;
}

export async function updateElementEvent(
  id: string,
  update: Record<string, any>
) {
  const token = await getAccessToken();
  const res = await fetch(`/events/id/${id}`, token, "PATCH", update);
  const data = parseFEvent(res.body);
  return data;
}

export interface TextSearchList {
  count: number;
  page: number;
  hits: TeamOverview[];
  facets: {
    [key: string]: Facet[];
  };
}

export interface FulfilmentEventListParamsFT {
  state?: string;
  fulfillmentLevel?: string;
  tags?: string;
  fulfillmentTeamName?: string;
  fulfillerUsername?: string;
  placementGroup?: string;

  from?: string;
  to?: string;
  dateType?: string; //
  page?: number | string; //
  records_per_page?: number; //
  search?: string; //
  sort?: string; //
  timezone?: string; //
  venueId?: string; //
}

function mapSortsEventFT(data: FulfilmentEventListParamsFT) {
  return omitBy(
    {
      page: data.page,
      tags: data.tags || null,
      placementGroup: data.placementGroup || null,
      sort: data.sort,
      fulfillerUsername: data.fulfillerUsername || null,
      fulfillmentTeamName: data.fulfillmentTeamName || null,
      records_per_page: data.records_per_page,
      search: data.search || null,
      "createdAt[from]":
        data.from && data.dateType === "createdAt"
          ? dateFilters(data.from, "00:00:00.000")
          : null,
      "createdAt[to]":
        data.to && data.dateType === "createdAt"
          ? dateFilters(data.to, "23:59:00.000")
          : null,
      "datetime[from]":
        data.from && data.dateType === "datetime"
          ? dateFilters(data.from, "00:00:00.000")
          : null,
      "datetime[to]":
        data.to && data.dateType === "datetime"
          ? dateFilters(data.to, "23:59:00.000")
          : null,
      timezone: data.timezone || null,
      state: data.state || null,
      fulfillmentLevel: data.fulfillmentLevel || null,
      venue_id: data.venueId || null,
    },
    isNil
  );
}

export async function fulfillmentTeamOverview(
  params: FulfilmentEventListParamsFT
): Promise<TextSearchList> {
  const token = await getAccessToken();
  const res = await fetch("/events/textSearch", token, "GET", null, {
    filters: mapSortsEventFT(params),
  });
  return {
    hits: res.body ? res.body.hits.map(parseFEvent) : [],
    page: get(res.body, "page", 0),
    count: get(res.body, "count", 0),
    facets: res.body.facets ? res.body.facets : {},
  };
}

interface HeatMapProps {
  year?: string;
  from?: string;
  to?: string;
  timezone?: string;
  range?: string;
}

function mapOverview(data: HeatMapProps) {
  return omitBy(
    {
      year: data.year || null,
      // from: data.from || null,
      // to: data.to || null,
      timezone: data.timezone || null,
      range: data.range || null,
    },

    isNil
  );
}

interface HeatMap {
  date: string;
  count: number;
}
interface Range {
  from: number;
  to: number;
}

interface HeatMapPeriod {
  year: number | string;
  count: number;
  weekOfYear: number;
  month: string;
  date: string;
}

export function parseHeatMap(data: unknown): HeatMap {
  return {
    date: get(data, "date", ""),
    count: get(data, "count", 0),
  };
}

export function parseRanges(data: unknown): Range {
  return {
    from: get(data, "from", 0),
    to: get(data, "to", 0),
  };
}

export function parseEventOverview(data: unknown): EventOverview {
  return {
    heatmap: get(data, "heatmap", []).map(parseHeatMap),
    ranges: get(data, "ranges", []).map(parseRanges),
  };
}

export function parsePeriodHeatMap(data: unknown): HeatMapPeriod {
  return {
    year: get(data, "year", 0),
    weekOfYear: get(data, "weekOfYear", 0),
    count: get(data, "count", 0),
    month: get(data, "month", ""),
    date: get(data, "date", ""),
  };
}

export function parsePeriod(data: unknown): EventPeriod {
  return {
    heatmap: get(data, "heatmap", []).map(parsePeriodHeatMap),
    ranges: get(data, "ranges", []).map(parseRanges),
  };
}

export async function eventHeatMap(
  params: HeatMapProps
): Promise<EventOverview> {
  const token = await getAccessToken();
  const res = await fetch(
    "/events/yearEvents",
    token,
    "GET",
    null,
    mapOverview(params)
  );
  return parseEventOverview(res.body);
}

interface PeriodProps {
  timePeriod: string;
  dateField: string;
  year?: string;
  from?: string;
  to?: string;
  timezone?: string;
  countField?: string;
}

function mapProps(data: PeriodProps) {
  const startDate = dayjs(`${data.year}-01-01`).format("YYYY-MM-DD");
  const endDate = dayjs(`${data.year}-12-31`).format("YYYY-MM-DD");
  return omitBy(
    {
      timePeriod: data.timePeriod || null,
      dateField: data.dateField || null,
      year: data.year || null,
      countField: data.countField || null,
      from: dateFilters(startDate, "00:00:00.000"),
      to: dateFilters(endDate, "23:59:00.000"),
      timezone: data.timezone || null,
    },
    isNil
  );
}

export async function eventsByPeriod(params: PeriodProps) {
  const token = await getAccessToken();
  const res = await fetch(
    "/events/eventsByPeriod",
    token,
    "GET",
    null,
    mapProps(params)
  );
  return parsePeriod(res.body);
}

export interface DuplicateParams {
  venueId: string;
  search?: string;
  tags?: Filter;
  page?: number | string;
  records_per_page?: number | string;
}

export function parseMissing(data: unknown): MissingPlacement {
  return {
    id: get(data, "_id", ""),
    name: get(data, "name", ""),
    externalId: get(data, "placement.externalId", ""),
    placement: get(data, "placement.name", ""),
    eventName: get(data, "name", ""),
    orderId: get(data, "orderId", ""),
    orderShortId: get(data, "orderShortId", ""),
  };
}

interface Filters {
  tags: Filter | null;
}

function mapSortMissing(data: DuplicateParams) {
  const filters = {
    tags: data.tags?.items.length ? data.tags : null,
    allPlacementsHasCoordinates: {
      include: "Any",
      items: [
        {
          key: false,
          include: true,
        },
      ],
    },
  };

  const filteredFilters: Partial<Filters> = {};
  for (const key in filters) {
    if (filters[key as keyof Filters] !== null) {
      filteredFilters[key as keyof Filters] = filters[key as keyof Filters]!;
    }
  }

  const jsonString = JSON.stringify(filters);

  return omitBy(
    {
      search: data.search || null,
      venue_id: data.venueId,
      records_per_page: data.records_per_page,
      page: data.page,
      filters: jsonString,
    },
    isNil
  );
}

export interface SearchList {
  count: number;
  page: number;
  hits: FulfillmentEvent[];
}

export async function missingListEvents(
  params: DuplicateParams
): Promise<SearchList> {
  const token = await getAccessToken();
  const res = await fetch(
    "/events/textSearch",
    token,
    "GET",
    null,
    mapSortMissing(params)
  );

  return {
    hits: res.body ? res.body.hits.map(parseFEvent) : [],
    page: get(res.body, "page", 0),
    count: get(res.body, "count", 0),
  };
}

export async function setEventFulfillmentDefState(
  eventId: string,
  state: string,
  w3w: string,
  images: string[]
): Promise<FulfillmentEvent | void> {
  const token = await getAccessToken();
  const body = {
    name: state,
    location: {
      type: "w3w",
      w3w: w3w,
    },
  };
  const res = await fetch(
    `/events/${eventId}/fulfillmentState`,
    token,
    "PATCH",
    body
  );
  const data = parseFEvent(res.body);
  if (images.length) {
    await uploadFiles(data.id, images);
    return;
  }
  return data;
}

export async function setEventState(id: string, state: string) {
  const token = await getAccessToken();
  const body = {
    state: state,
  };
  await fetch(`/events/id/${id}/state`, token, "PATCH", body);
  return;
}

export async function assignFulfillment(
  ids: string[],
  fulfillmentTeamId?: string,
  fulfillerId?: string
): Promise<FulfillmentEvent> {
  const body = {
    ids,
    fulfillmentTeamId,
    fulfillerId,
  };
  const token = await getAccessToken();
  const res = await fetch(`/events/assignFulfillment`, token, "PATCH", body);
  return res.body;
}

export async function assignAsset(
  assetId: string,
  ids: string[]
): Promise<FulfillmentEvent> {
  const body = {
    assetId,
    ids,
  };
  const token = await getAccessToken();
  const res = await fetch("/events/assignAsset", token, "PATCH", body);
  return res.body;
}

interface CountsParams {
  dateField: string;
  ordersCost: number;
  eventsCost: number;
  sort: string;
}

export interface CountsResponse {
  month: string;
  dtReference: Date | string;
  orders: number;
  ordersDeleted: number;
  ordersCost: number;
  events: number;
  eventsDeleted: number;
  eventsCost: number;
  pax: number;
  paxDeleted: number;
  paxCost: number;
  paxDetail: number[];
  costPerOrder: number;
  costPerPax: number;
  costPerEvent: number;
}

export function parseCountReport(data: unknown): CountsResponse {
  return {
    month: get(data, "month", ""),
    dtReference: get(data, "dtReference", ""),
    orders: get(data, "Orders", 0),
    ordersDeleted: get(data, "Orders Deleted", 0),
    ordersCost: get(data, "Cost Orders", 0),
    costPerEvent: get(data, "Cost per Event", 0),
    costPerOrder: get(data, "Cost per Order", 0),
    costPerPax: get(data, "Cost per Pax", 0),
    events: get(data, "Events", 0),
    eventsDeleted: get(data, "Events Deleted", 0),
    eventsCost: get(data, "Cost Events", 0),
    pax: get(data, "Pax", 0),
    paxDeleted: get(data, "Pax Deleted", 0),
    paxCost: get(data, "Pax Cost", 0),
    paxDetail: get(data, "PaxDetail", []),
  };
}

export async function countsReport(
  params: CountsParams
): Promise<CountsResponse[]> {
  const token = await getAccessToken();
  const res = await fetch("/events/countsReport", token, "GET", null, params);

  return res.body.map((item: CountsResponse) => parseCountReport(item));
}

export interface RelatedSearch {
  count: number;
  page: number;
  hits: any[];
  tagColors: any[];
  totalRangesParams?: string;
  facets: FacetEvent[];
}

export async function listRelatedEvents(
  params: RelatedParams
): Promise<RelatedSearch> {
  const token = await getAccessToken();
  const res = await fetch(
    "/events/relatedEventsById",
    token,
    "GET",
    null,
    mapSortRelated(params)
  );

  return {
    hits: res.body ? res.body.hits.map(parseFEvent) : [],
    page: get(res.body, "page", 0),
    count: get(res.body, "count", 0),
    tagColors: get(res.body, "tagColors", []),
    facets: res.body.facets ? res.body.facets : [],
  };
}

export interface RelatedParams {
  id: string;
  search?: string;
  sort?: string;
  page?: number | string;
  records_per_page?: number | string;
}

function mapSortRelated(data: RelatedParams) {
  // const filters = {
  //   tags: data.tags?.items.length ? data.tags : null,
  //   allPlacementsHasCoordinates: {
  //     include: "Any",
  //     items: [
  //       {
  //         key: false,
  //         include: true,
  //       },
  //     ],
  //   },
  // };

  // const filteredFilters: Partial<Filters> = {};
  // for (const key in filters) {
  //   if (filters[key as keyof Filters] !== null) {
  //     filteredFilters[key as keyof Filters] = filters[key as keyof Filters]!;
  //   }
  // }

  // const jsonString = JSON.stringify(filters);

  return omitBy(
    {
      search: data.search || null,
      sort: data.sort || null,
      id: data.id,
      records_per_page: data.records_per_page,
      page: data.page,
    },
    isNil
  );
}

export interface DayHeatMapParams {
  timezone: string;
  search: string;
  fulfillerId: string;
  from: string;
  to: string;
  numberOfRanges: number;
}

function mapSortDayHeat(data: DayHeatMapParams) {
  const filters = {
    datetime: {
      include: "All",
      items: [
        {
          from: dateFilters(data.from, "00:00:00.000"),
          to: dateFilters(data.to, "23:59:00.000"),
          include: true,
        },
      ],
    },
    fulfillerId: {
      include: "All",
      items: [
        {
          key: data.fulfillerId,
          include: true,
        },
      ],
    },
  };

  const jsonString = JSON.stringify(filters);

  return omitBy(
    {
      // venue_id: data.venueId || null,
      timezone: data.timezone || null,
      search: data.search || null,
      from: dateFilters(data.from, "00:00:00.000"),
      to: dateFilters(data.to, "23:59:00.000"),
      numberOfRanges: 10,
      filters: jsonString,
    },
    isNil
  );
}

export interface DayHeatMapResponse {
  heatmap: HeatMap[];
  ranges: Range[];
}

export function parseDayHeatMap(data: unknown): DayHeatMapResponse {
  return {
    heatmap: get(data, "heatmap", []).map(parsePeriodHeatMap),
    ranges: get(data, "ranges", []).map(parseRanges),
  };
}

export async function getEventDayHeatMap(
  params: DayHeatMapParams
): Promise<DayHeatMapResponse> {
  const token = await getAccessToken();
  const res = await fetch(
    "/events/dayHeatmap",
    token,
    "GET",
    null,
    mapSortDayHeat(params)
  );

  return {
    heatmap: res.body ? res.body.heatmap.map(parsePeriodHeatMap) : [],
    ranges: res.body ? res.body.ranges.map(parseRanges) : [],
  };
}
