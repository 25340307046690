import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import {
  FulfillmentEvent,
  FulfillmentState,
} from "@innomius/ravent-typescript-types";
import { formatDate } from "../../utils/times";

export interface MarkerData {
  title: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  eventId: string; // Event ID
  placementIndex: number; // Placement index
  event: FulfillmentEvent;
}

interface CheckpointData {
  lat: number;
  lng: number;
  timestamp: string;
}

export interface MarkerStateData {
  title: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  state: FulfillmentState; // Represents the state for this marker
}

interface MapWithRoutesProps {
  markers: MarkerData[]; // Individual marker points
  stateMarkers: MarkerStateData[]; // State markers
  checkpoints?: CheckpointData[]; // Route using polyline
  startCenter?: { lat: number; lng: number }; // Center for the map
  polylineColor?: string; // Optional polyline color
  polylineZoom?: number; // Zoom level for the polyline
}

const MapWithRoutes: React.FC<MapWithRoutesProps> = ({
  markers,
  checkpoints = [],
  startCenter,
  polylineColor = "#FF0000",
  polylineZoom = 10,
  stateMarkers = [],
}) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const defaultCenter = { lat: 21.0417033, lng: -86.8740062 }; // Default Cancun location

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBnUmMfyn4yxmKUBh-xP8U9k7GYmMp-oyo",
  });

  const getColorForTitle = (title: string) => {
    const prefix = title.split(".")[0];
    const colors = [
      "#007BFF",
      "#FF5733",
      "#28A745",
      "#FFC107",
      "#8E44AD",
      "#34495E",
      "#E74C3C",
      "#1ABC9C",
      "#2ECC71",
      "#F39C12",
      "#C0392B",
      "#7F8C8D",
      "#3498DB",
      "#9B59B6",
      "#D35400",
      "#16A085",
      "#E67E22",
      "#2C3E50",
      "#ECF0F1",
      "#D5DBDB",
      "#1F618D",
      "#AF7AC5",
      "#F1948A",
      "#7DCEA0",
      "#F7DC6F",
      "#85929E",
      "#E59866",
      "#DC7633",
      "#48C9B0",
      "#B03A2E",
    ];
    const index = parseInt(prefix, 10) - 1;
    return colors[index % colors.length];
  };

  const adjustMarkerPositions = (markers: MarkerData[]) => {
    const positionMap = new Map<string, number>();

    return markers.map((marker) => {
      const key = `${marker.coordinates.latitude},${marker.coordinates.longitude}`;
      const count = positionMap.get(key) || 0;

      // Adjust position slightly for overlapping markers
      const adjustedLatitude = marker.coordinates.latitude + count * 0.00005;
      const adjustedLongitude = marker.coordinates.longitude + count * 0.00005;

      positionMap.set(key, count + 1);

      return {
        ...marker,
        coordinates: {
          latitude: adjustedLatitude,
          longitude: adjustedLongitude,
        },
      };
    });
  };

  // Generate a dynamic color based on the string
  const generateColorFromTitle = (title: string): string => {
    // Hash function to generate a number from the title string
    let hash = 0;
    for (let i = 0; i < title.length; i++) {
      hash = title.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Convert the hash to a color by taking parts of the hash
    const r = (hash >> 16) & 0xff;
    const g = (hash >> 8) & 0xff;
    const b = hash & 0xff;

    // Return the color as a hexadecimal string
    return `#${r.toString(16).padStart(2, "0")}${g
      .toString(16)
      .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;
  };

  useEffect(() => {
    if (map) {
      // Adjust marker positions to avoid overlap
      const adjustedMarkers = adjustMarkerPositions(markers);

      // Clear previous markers and polylines
      map.data.forEach((feature) => {
        map.data.remove(feature);
      });

      // Add markers for `markers`
      adjustedMarkers.forEach((marker) => {
        const color = generateColorFromTitle(marker.title); // Use the dynamic color generation
        const markerInstance = new google.maps.Marker({
          position: {
            lat: marker.coordinates.latitude,
            lng: marker.coordinates.longitude,
          },
          map,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: color,
            fillOpacity: 1,
            strokeColor: "#FFFFFF",
            strokeWeight: 2,
            scale: 20, // Increased marker size
          },
          label: {
            text: marker.event.shortId,
            fontSize: "9px",
            fontWeight: "bold",
            color: "#FFFFFF",
          },
        });

        markerInstance.addListener("click", () => {
          const infoWindow = new google.maps.InfoWindow({
            content: `<div><strong>${marker.title}</strong><br/>Event: ${marker.event.name}</div>`,
          });
          infoWindow.open(map, markerInstance);
        });
      });

      // Add markers for `stateMarkers`
      stateMarkers.forEach((stateMarker) => {
        const color = generateColorFromTitle(stateMarker.state.name); // Use dynamic color here as well

        const markerInstance = new google.maps.Marker({
          position: {
            lat: stateMarker.coordinates.latitude,
            lng: stateMarker.coordinates.longitude,
          },
          map,
          icon: {
            path: "M -50 -15 L 50 -15 L 50 15 L -50 15 Z", // Increase the width of the rectangle
            fillColor: color,
            fillOpacity: 0.8,
            strokeColor: "#FFFFFF",
            strokeWeight: 2,
            scale: 1, // Scale is set to 1 for rectangles
          },
          label: {
            text: stateMarker.state.name,
            fontSize: "9px",
            fontWeight: "bold",
            color: "#FFFFFF",
          },
        });

        markerInstance.addListener("click", () => {
          const infoWindow = new google.maps.InfoWindow({
            content: `<div><strong>${
              stateMarker.state.name
            }</strong><br/>Datetime: ${formatDate(
              stateMarker.state.timestamp,
              "HOUR"
            )}</div>`,
          });
          infoWindow.open(map, markerInstance);
        });
      });

      // Add polyline and checkpoints
      if (checkpoints.length > 1) {
        const polyline = new google.maps.Polyline({
          path: checkpoints.map((checkpoint) => ({
            lat: checkpoint.lat,
            lng: checkpoint.lng,
          })),
          geodesic: true,
          strokeColor: polylineColor,
          strokeOpacity: 0.8,
          strokeWeight: 4,
        });

        polyline.setMap(map);

        // Add checkpoint markers
        checkpoints.forEach((checkpoint) => {
          const checkpointMarker = new google.maps.Marker({
            position: { lat: checkpoint.lat, lng: checkpoint.lng },
            map,
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              fillColor: "#FF0000",
              fillOpacity: 0.6,
              strokeColor: "#FFFFFF",
              strokeWeight: 1,
              scale: 3,
            },
          });

          checkpointMarker.addListener("click", () => {
            const infoWindow = new google.maps.InfoWindow({
              content: `<div><strong>Timestamp:</strong> ${formatDate(
                checkpoint.timestamp,
                "HOUR"
              )}</div>`,
            });
            infoWindow.open(map, checkpointMarker);
          });
        });

        if (startCenter) {
          map.setCenter(
            new google.maps.LatLng(startCenter.lat, startCenter.lng)
          );
          if (polylineZoom) {
            map.setZoom(polylineZoom);
          }
        } else {
          const bounds = new google.maps.LatLngBounds();
          checkpoints.forEach((checkpoint) =>
            bounds.extend(
              new google.maps.LatLng(checkpoint.lat, checkpoint.lng)
            )
          );
          map.fitBounds(bounds);
        }
      }
    }
  }, [
    map,
    markers,
    stateMarkers,
    checkpoints,
    startCenter,
    polylineColor,
    polylineZoom,
  ]);

  const onLoad = (mapInstance: google.maps.Map) => {
    setMap(mapInstance);
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={defaultCenter}
      zoom={10}
      key={JSON.stringify({ markers, checkpoints, startCenter })}
      onLoad={onLoad}
    />
  ) : (
    <div>Loading...</div>
  );
};

export default MapWithRoutes;
