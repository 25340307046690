import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoginResponse } from '../services/auth';

export type AuthSliceState = {
  loading: boolean;
  error: string;
  user: LoginResponse | null;
  expired: boolean;
};

const initialState: AuthSliceState = {
  loading: true,
  error: '',
  user: null,
  expired: false
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authUser(state, action: PayloadAction<LoginResponse>) {
      state.loading = false;
      state.user = action.payload;
    },
    signOutUser(state) {
      state.loading = false;
      state.user = null;
    },
    cleanExpired(state) {
      state.loading = false;
      state.user = null;
      state.expired = true;
    }
  }
});

export const { authUser, signOutUser, cleanExpired } = authSlice.actions;

export default authSlice.reducer;
