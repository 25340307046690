import React from "react";
import styles from "./styles";
import { VenueState } from "../../utils/types";
import { StatusBadgePoint } from "../StatusBadgePoint/StatusBagePoint";
import { ListRow } from "../List/ListRow";
import { StatusCatalog } from "../StatusBadge";
import { ListLabel } from "../List/ListLabel";
import { InputSwitch } from "../Inputs/InputSwitch";

interface OrderStateListProps {
  data: VenueState;
}

const OrderStateItem: React.FC<OrderStateListProps> = ({ data }) => {
  return (
    <ListRow template="1fr 3fr 1fr" hover={false} key={data.name}>
      <div className={styles.center}>
        <StatusBadgePoint status={data.name as StatusCatalog} />
      </div>
      <ListLabel
        value={data.actionName || data.actionId || ""}
        textAlign="left"
      />
      <div className={styles.normal}>
        <InputSwitch value={data.automatic} />
      </div>
    </ListRow>
  );
};

export default OrderStateItem;
