const translation = {
  en: {
    label: "Assets",
    createAsset: "+ Create Asset",
    search: "Search",
    instructions: "Start creating Assets",
    addAsset: "+ Add Asset",
    successCreation: "Asset successfully created",
    successDelete: "Asset successfully deleted",
    successUpdated: "Asset was successfully updated",
    multiple: "Import Multiple Assets",
    importMultiple: "Import from CSV",
    export: "Export to CSV",
    delete: "Delete Assets",
    actions: "Actions",
    successDeleteImage: "Asset Image successfully deleted",
    emptyList: "Empty List",
  },
  es: {
    emptyList: "List Vacía",
    successDeleteImage: "Imagen fue borrada con éxito",
    multiple: "Importar multiples Activos",
    label: "Activos",
    createAsset: "+ Crear Activo",
    search: "Buscar",
    instructions: "Empiece a crear Activos",
    addAsset: "+ Agregar Activo",
    successCreation: "Activo creado con éxito",
    successDelete: "Activo eliminado con éxito",
    successUpdated: "Activo editado con éxito",
    actions: "Acciones",
    delete: "Eliminar Activos",
    export: "Exportar a CSV",
    importMultiple: "Importar de CSV",
  },
  de: {},
};

export default translation;
