import React from "react";
import { classes, style } from "typestyle";
import { darkBlack } from "../../utils/colors";

import { useTranslation } from "react-i18next";
import { Card } from "../Card/Card";
import { Customer } from "../../utils/types";
import acceptIcon from "../../assets/officialMedia/default-checkmark.svg";
import quitIcon from "../../assets/quit.svg";
import PhoneInput from "react-phone-input-2";
import { ButtonsContainer } from "../ButtonsContainer";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputText } from "../Inputs/InputText";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";

interface Props {
  data: Customer;
  onChange: <P extends keyof Customer>(prop: P, value: Customer[P]) => void;
  onSave: () => void;
  onCancel: () => void;
}

const labelStyle = style({
  color: darkBlack,
  fontWeight: "bold",
  fontSize: "1.4em",
});

const sectionWrapper = style({
  padding: "2em",
  marginBottom: "1em",
});

const EditCustomer: React.FC<Props> = ({
  data,
  onChange,
  onCancel,
  onSave,
}) => {
  const { t } = useTranslation();

  return (
    <Card>
      <div className={sectionWrapper}>
        <GridContainer alignItems="center" columns={"3fr"}>
          <FlexContainer padding="0 0 1em 0" justifyContent="space-between">
            <p className={classes(labelStyle, {})}>
              {t("component.orderDetail.customer")}
            </p>
            <ButtonsContainer justifyContent="flex-end">
              <img
                src={quitIcon}
                alt="quit"
                style={{ width: "2em", cursor: "pointer" }}
                onClick={() => {
                  onCancel();
                }}
              />
              <img
                src={acceptIcon}
                style={{ cursor: "pointer" }}
                alt="accept"
                onClick={onSave}
              />
            </ButtonsContainer>
          </FlexContainer>
          <InputText
            value={data.fullName}
            onChange={(value) => onChange("fullName", value)}
            label={t("component.orderDetail.name")}
          />
          <InputText
            value={data.email}
            onChange={(value) => onChange("email", value)}
            label={t("component.orderDetail.email")}
          />

          <FlexContainer
            padding="0 0 1em 0"
            flexDirection="column"
            alignItems="flex-start"
          >
            <InputLabel label={t("component.orderDetail.phone")} />

            <PhoneInput
              enableLongNumbers
              country={"mx"}
              disableDropdown={true}
              value={data.phone}
              onChange={(value: string) => onChange("phone", `+${value}`)}
            />
          </FlexContainer>
        </GridContainer>
      </div>
    </Card>
  );
};

export default EditCustomer;
