
const translation = {
  en: {
    dashboard: "Overview",
    orders: "Orders",
    payments: "Orders Payment States",
    currency: "Currency",
    teams: "Teams",
    back: "Back to Teams",
    pending: "Pending",
    fulfilled: "Fulfilled",
    events: "Events",
    team: "Team",
    count: "Count",
    date: "Date",
    weekly: "weekly",
    monthly: "monthly",
    daily: "daily",
    completed: "Completed",
    failed: "Failed",
    running: "Running",
    total: "Total Events",

    week: "Week",
    month: "Month",
    seeMore: "See more",
    loads: "Loads",
  },
  es: {
    total: "Eventos Totales",
    failed: "Fallidos",
    running: "Running",
    loads: "Cargas",
    completed: "Completados",
    seeMore: "Ver más",
    month: "Mes",
    week: "Semana",
    date: "Fecha",
    weekly: "semanal",
    monthly: "mensual",
    daily: "diario",
    count: "Cantidad",
    team: "Equipo",
    teams: "Equipos",
    dashboard: "Resumen",
    orders: "Órdenes",
    currency: "Moneda",
    payments: "Estados de Pago de Órdenes",
    back: "Regresar a Equipos",
    pending: "Pendientes",
    fulfilled: "Completados",
    events: "Eventos",
  },
  de: {
    dashboard: "Overview",
  },
};

export default translation;
