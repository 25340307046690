const translation = {
  en: {
    bankName: "Bank Name",
    bankAccount: "Bank Account",
    legalName: "Legal Name",
    alias: "Alias",
    qrCodeType: "QR Code Type",
    existingForm: "Select Existing Form of Payment",
    cancel: "Cancel",
    save: "Save",
    selectFOP: "Select Form of Payment",
    accountConfig: "Bank Account Configuration",
    skip: "You can skip this section and fill up later",
    next: "Next",
    step: "Step",
    of: "of",
    type: "Payment Type",
    supplier: "Supplier",
    round: "Round",
    notExistingTemplate: "There is no Payment Template",
    chooseAnother: "Please go back and select another type of payment",
    roundInstructions: "Please select a round value for the amount payments",
  },
  es: {
    bankName: "Nombre del banco",
    bankAccount: "Cuenta Bancaria",
    legalName: "Nombre legal",
    alias: "Alias",
    qrCodeType: "Tipo de código QR",
    existingForm: "Seleccione la forma de pago existente",
    cancel: "Cancelar",
    save: "Guardar",
    selectFOP: "Seleccionar forma de pago",
    accountConfig: "Configuración de cuenta bancaria",
    skip: "Puedes saltarte esta sección y llenarla más tarde",
    next: "Siguiente",
    step: "Paso",
    of: "de",
    type: "Tipo de pago",
    supplier: "Proveedor",
    notExistingTemplate: "No existe el template del pago que quiere",
    chooseAnother: "Porfavor regrese y seleccione otro tipo de pago",
    round: "Redondear",
    roundInstructions:
      "Por favor, seleccione un valor de redondeo para los pagos",
  },
  de: {},
};

export default translation;
