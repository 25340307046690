import React, { useState } from "react";

import styles from "./styles";
import { InputTags } from "../../Inputs/InputTags";
import { useTranslation } from "react-i18next";
import { InputTime } from "../../Inputs/InputTime/InputTime";
import InputDate from "../../Inputs/Date";
import dayjs from "dayjs";
import { FulfillmentEvent } from "@innomius/ravent-typescript-types";
import InputTextDropdown from "../../Inputs/InputTextDropdown";
import { timezones } from "../../../constants/timezones";
import Text from "../../Text";
import { Checkbox } from "antd";
import { InputText } from "../../Inputs/InputText";
import { FlexContainer } from "../../FlexContainer/FlexContainer";
import { InputTextarea } from "../../Inputs/InputTextArea";

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

interface Props {
  event: FulfillmentEvent;
  onChangeEvent: <P extends keyof FulfillmentEvent>(
    prop: P,
    value: FulfillmentEvent[P]
  ) => void;
  error: string;
  setError: (value: string) => void;
}

const NewEvent: React.FC<Props> = ({
  event,
  onChangeEvent,
  error,
  setError,
}) => {
  const { t } = useTranslation();
  const [end, setEnd] = useState(false);

  return (
    <div className={styles.containerDetails}>
      <InputText
        value={event.name}
        onFocus={() => setError("")}
        label={t("component.orderLocation.name")}
        onChange={(value) => onChangeEvent("name", value)}
        error={error && !event.name ? error : ""}
      />
      <InputTags
        height={event.tags?.length <= 2 ? "2.4em" : ""}
        label={t("component.orderLocation.tags")}
        value={event.tags}
        onChange={(value) => {
          onChangeEvent("tags", value);
        }}
      />

      <Checkbox
        style={{ padding: "1em 0 0 0" }}
        checked={event.considerTime}
        onChange={(value) =>
          onChangeEvent("considerTime", value.target.checked)
        }
      >
        <Text fontSize="1em" text={t("component.orderLocation.considerTime")} />
      </Checkbox>

      <div className={styles.containerDateSmall}>
        <FlexContainer
          alignItems="flex-start"
          padding="1em 0 0.5em 0"
          justifyContent="flex-start"
        >
          <InputDate
            label={t("component.orderLocation.date")}
            dateClassname={styles.date}
            id="dateTime"
            value={(event.datetime as Date) || new Date()}
            onChange={(value) => {
              onChangeEvent(
                "datetime",
                dayjs(value).format(
                  event.considerTime
                    ? "YYYY-MM-DDT00:00:00"
                    : "YYYY-MM-DDTHH:mm:ss"
                )
              );
            }}
          />
          {event.considerTime ? (
            <InputTime
              id=""
              value={(event.datetime as Date) || new Date()}
              onChange={(value) => {
                onChangeEvent(
                  "datetime",
                  dayjs(value.value).format("YYYY-MM-DDTHH:mm:ss")
                );
              }}
            />
          ) : null}

          <div style={{ marginLeft: "1em" }}>
            <InputTextDropdown
              label={t("component.orderFilters.timezone")}
              placeholder={t("component.orderFilters.timezone")}
              value={event.timezone}
              showError={false}
              top={"calc(100% + 1px)"}
              options={timezones.map((item) => ({
                value: item,
                label: item,
              }))}
              onChange={(value) => onChangeEvent("timezone", value)}
            />
          </div>
        </FlexContainer>

        <div
          onClick={() => {
            if (end) {
              setEnd(false);
              onChangeEvent("endDatetime", "");
            } else setEnd(true);
          }}
          style={{ width: "100%", cursor: "pointer" }}
        >
          <Text
            padding="1em 0 1em 0"
            text={
              end
                ? t("component.orderLocation.deleteEnd")
                : t("component.orderLocation.addEnd")
            }
            color={"#1FA0FF"}
          />
        </div>

        {end ? (
          <FlexContainer justifyContent="flex-start" padding="0 0 1em 0">
            <InputDate
              label={t("component.orderLocation.end")}
              dateClassname={styles.date}
              id="dateTime"
              value={event.endDatetime as Date}
              onChange={(value) => {
                onChangeEvent(
                  "endDatetime",
                  dayjs(value).format(
                    event.considerTime
                      ? "YYYY-MM-DDT00:00:00"
                      : "YYYY-MM-DDTHH:mm:ss"
                  )
                );
              }}
              disableDate={(current) => {
                return current < dayjs(event.datetime);
              }}
            />
            {event.considerTime ? (
              <InputTime
                id=""
                value={event.endDatetime as Date}
                onChange={(value) => {
                  onChangeEvent(
                    "endDatetime",
                    dayjs(value.value).format("YYYY-MM-DDTHH:mm:ss")
                  );
                }}
              />
            ) : null}
          </FlexContainer>
        ) : null}

        <InputTextarea
          showError={false}
          value={event.notes}
          label="Notes"
          onChange={(value) => onChangeEvent("notes", value)}
        />
      </div>
    </div>
  );
};

export default NewEvent;
