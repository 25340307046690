import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DiveShopModal from "../DiveShopModal";

import { ReactComponent as FulfillerIcon } from "../../../assets/fulfiller.svg";

import Text from "../../Text";
import styles from "./styles";

interface Props {
  team: string;
  teams: { label: string; value: string }[];
  updateTeam: (id: string, teamId: string) => void;
  updateFulfiller: (id: string, fId: string) => void;
  orderlineId: string;
  fulfillerId: string;
  fulfillerName: string;
}

const FulfillerContent: React.FC<Props> = ({
  team,
  teams,
  orderlineId,
  fulfillerId,
  updateTeam,
  updateFulfiller,
  fulfillerName,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState("");

  return (
    <>
      <DiveShopModal
        fulfillerId={fulfillerId}
        teamId={team}
        orderlineId={orderlineId}
        teams={teams}
        updateFulfiller={(id: string, fId: string) => {
          try {
            updateFulfiller(id, fId);
            setOpen("");
          } catch (err) {}
        }}
        updateTeam={(id: string, teamId: string) => {
          try {
            updateTeam(id, teamId);
            setOpen("");
          } catch (err) {}
        }}
        open={open === "diveshop"}
        onClose={() => setOpen("")}
      />

      <div
        style={{
          marginRight: "2em",
          borderRight: "1px solid #dedede",
          paddingRight: "1em",
        }}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
            setOpen("diveshop");
          }}
          className={styles.link}
        >
          <FulfillerIcon
            style={{ marginRight: "0.5em" }}
            className={styles.smallIcon}
          />
          <Text
            text={
              team
                ? `${teams?.find((item) => item.value === team)?.label}: ${
                    fulfillerId
                      ? fulfillerName
                      : `${t(
                          "component.orderFulfillment.fulfillerNotAssigned"
                        )}`
                  }`
                : t("component.orderFulfillment.setFulfiller")
            }
          />
        </div>
      </div>
      {/* {!team ? null : fulfillerId ? (
        <>
          <Popover
            placement="left"
            content={
              <FlexContainer
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <FlexContainer
                  padding="0 1em 0 0"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  flexDirection="column"
                >
                  <FlexContainer
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    padding="0 2em 0 0"
                  >
                    <Text
                      padding="0 0.5em 0 0"
                      fontWeight={700}
                      text={
                        fulfillers.find((item) => item.id === fulfillerId)
                          ?.name || "-"
                      }
                    />
                    <Text
                      fontWeight={700}
                      text={
                        fulfillers.find((item) => item.id === fulfillerId)
                          ?.lastName || "-"
                      }
                    />
                  </FlexContainer>
                  <FlexContainer
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Text
                      text={
                        fulfillers.find((item) => item.id === fulfillerId)
                          ?.email || "-"
                      }
                      padding="0 0.5em 0 0"
                    />
                    <img
                      onClick={() => {
                        if (
                          fulfillers.find((item) => item.id === fulfillerId)
                            ?.email
                        ) {
                          navigator.clipboard.writeText(
                            fulfillers.find((item) => item.id === fulfillerId)
                              ?.email || ""
                          );
                          message.success(
                            `${
                              fulfillers.find((item) => item.id === fulfillerId)
                                ?.email
                            }  ${t("component.orderDetail.copy")}`
                          );
                        }
                      }}
                      alt=""
                      src={copyIcon}
                      className={styles.copy}
                    />
                  </FlexContainer>
                  <Text
                    text={
                      fulfillers.find((item) => item.id === fulfillerId)
                        ?.phoneNumber || "-"
                    }
                  />
                </FlexContainer>
                <Popover
                  content={
                    <DeletePopUp
                      value={`Are you sure you want to unassgined  ${
                        fulfillers.find((item) => item.id === fulfillerId)
                          ?.email
                      }?`}
                      onClose={(e) => {
                        e.stopPropagation();
                        setOpen("");
                      }}
                      onDelete={(e) => {
                        e.stopPropagation();
                        updateFulfiller(orderlineId, "");
                        setOpen("");
                      }}
                    />
                  }
                  placement="left"
                  open={open === "delete"}
                >
                  <div
                    className={styles.iconContainerNoBorder}
                    style={{ marginRight: "0.3em" }}
                  >
                    <DeleteIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => setOpen("delete")}
                    />
                  </div>
                </Popover>

                <div className={styles.iconContainerNoBorder}>
                  <AssignIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => setOpen("fulfiller")}
                  />
                </div>
              </FlexContainer>
            }
          >
            <div className={styles.normalButton}>
              {fulfillers.find((item) => item.id === fulfillerId)?.name ||
                fulfillers.find((item) => item.id === fulfillerId)?.email ||
                "-"}
            </div>
          </Popover>
        </>
      ) : !fulfillers.filter((item) => item.emailVerified).length ? (
        <Button
          theme="white"
          disable
          buttonClassname={styles.button}
          label={t("component.orderFulfillment.setDiveshop")}
        />
      ) : (
        <div>
          <InputSelect
            containerClassname={styles.inputSelect}
            placeholder={t("component.orderTeam.selectF")}
            options={fulfillers
              .filter((item) => item.emailVerified)
              .map((item) => ({
                label: item.name
                  ? `${item.name} ${item.lastName} - ${item.email}`
                  : `${item.phoneNumber}` || `${item.email}`,
                value: item.id,
              }))}
            onChange={(value) => {
              try {
                updateFulfiller(orderlineId, value);
                setOpen("");
              } catch (err) {}
            }}
          />
        </div>
      )} */}
    </>
  );
};

export default FulfillerContent;
