import React from "react";
import { useTranslation } from "react-i18next";
import { EmptyList } from "../List/EmptyList";

interface Props {}

const NoCatalog: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <div>
      <EmptyList
        instructions={""}
        text={t("component.fopForm.chooseAnother")}
      />
    </div>
  );
};

export default NoCatalog;
