import * as React from 'react';
import { classes } from 'typestyle';
import styles from './styles.module.css';

export interface ModalProps {
  open: boolean;
  onClose?: () => void;
  width?: string | number;
  maxWidth?: string | number;
  closeOnBackdropClick?: boolean;
  modalAlignment?: 'center' | 'top' | 'bottom';
  justifyContent?: 'center' | 'flex-start' | 'flex-end';
  buttons?: React.ReactChild;
  children?: React.ReactNode[] | React.ReactNode;
  padding?: string;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  onClose,
  open,
  width,
  maxWidth,
  closeOnBackdropClick = true,
  justifyContent,
  buttons,
  modalAlignment,
  padding
}) => {
  if (!open) {
    return null;
  }
  return (
    <div
      style={{ padding: padding }}
      className={classes(styles.container, {
        [styles.containerCenter]: modalAlignment === 'center',
        [styles.containerTop]: modalAlignment === 'top',
        [styles.containerBottom]: modalAlignment === 'bottom'
      })}
      onClick={
        closeOnBackdropClick
          ? onClose
          : () => {
              // do nothing
            }
      }
    >
      <div onClick={(e) => e.stopPropagation()} style={{ width, maxWidth }} className={styles.modal}>
        <div
          className={classes(styles.modalWrapper, {
            [styles.modalWrapperCenter]: justifyContent === 'center',
            [styles.modalWrapperEnd]: justifyContent === 'flex-end'
          })}
        >
          {children}
        </div>
        {buttons ? <div className={styles.buttonWrapper}>{buttons}</div> : null}
      </div>
    </div>
  );
};
