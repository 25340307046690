import React from "react";

import { Offer, Venue } from "@innomius/ravent-typescript-types";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { ContainerTitle } from "../ContainerTitle";
import Search from "antd/es/input/Search";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";

interface Props {
  data: Offer;
  onChange: <P extends keyof Offer>(prop: P, value: Offer[P]) => void;
  edit?: boolean;
  onEdit?: () => void;
  onCancel?: () => void;
  venues: Venue[];
}

const OfferSelectVenue: React.FC<Props> = ({
  edit,
  onEdit,
  onCancel,
  onChange,
  venues,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.containerDetails}>
        <>
          <ContainerTitle
            size="xsmall"
            label={t("component.offerVariantForm.selectVenue")}
          />

          <Search
            style={{ marginBottom: "1em" }}
            placeholder={t("component.offerVariantForm.search")}
            onSearch={() => {}}
          />
        </>
        {/* {venue?.locations.length ? (
          <>
            <ContainerTitle
              size="xsmall"
              padding="2em 0 1em 0"
              label={t("component.offerVariantForm.selectLocation")}
            />
            <GridContainer columns="1fr 1fr 1fr" gap="1em">
              {venue.locations.map((location) => (
                <RadioButtonOption
                  name="location"
                  width="17em"
                  icon={location.image}
                  key={location.id}
                  value={location.id}
                  text={location.name}
                  onChange={() => onChange("locationId", location.id)}
                />
              ))}
            </GridContainer>
          </>
        ) : venue?.locations ? (
          <div className={styles.warning}>
            This venue doesn't have locations.
          </div>
        ) : null} */}

        {edit ? (
          <ButtonsContainer padding="2em 0 0 0" justifyContent="flex-start">
            <Button
              label={t("component.offerVariantForm.cancel")}
              theme="white"
              onClick={() => onCancel?.()}
            />
            <Button
              label={t("component.offerVariantForm.save")}
              onClick={() => onEdit?.()}
            />
          </ButtonsContainer>
        ) : null}
      </div>
    </div>
  );
};

export default OfferSelectVenue;
