import { stylesheet } from "typestyle";

const styles = stylesheet({
  container: {
    marginBottom: "1em",
  },
  containerDetails: {
    padding: "0em 0em 1em 0em",
  },
  containerDetailsCheckin: {
    padding: "0em",
  },
  officeHourRow: {
    width: "70%",
  },
  min: { minWidth: "20em" },
  formContainer: {
    padding: "0 0 1em 0",
  },
  text: {
    paddingBottom: "1em",
    paddingTop: "1em",
  },
  textInstructions: {
    padding: "0.5em 0",
  },
  bold: {
    fontWeight: 600,
    textTransform: "capitalize",
  },
  radioContainer: {
    marginRight: "3em",
    padding: "1em 0",
  },
  instructions: {
    fontSize: "1.1em",
  },
  inputContainer: {
    marginLeft: "2em",
  },
});

export default styles;
