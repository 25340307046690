import fetch from "../utils/fetch";
import { getAccessToken } from "./auth";

export async function sendMessagePayment(
  orderId: string,
  language: string,
  medium: string,
  target: string,
  messageType: string,
  paymentId: string
) {
  const token = await getAccessToken();

  const data = {
    paymentId: paymentId,
  };

  const body = {
    orderId: orderId,
    language: language,
    transport: medium,
    targets: target.split(" "),
    messageType: messageType,
    data,
  };

  await fetch(`/messaging/${messageType}`, token, "POST", body);
  return;
}

export async function sendMessage(
  orderId: string,
  language: string,
  medium: string,
  target: string,
  messageType: string,
  requestId?: string,
  invoiceId?: string
) {
  const token = await getAccessToken();

  const dataRequest = {
    paymentRequestId: requestId,
  };
  const invoiceRequest = {
    invoiceId: invoiceId,
  };

  const body = {
    orderId: orderId,
    language: language,
    transport: medium,
    targets: target.split(" "),
    data: messageType === "paymentRequest" ? dataRequest : invoiceRequest,
  };

  await fetch(`/messaging/${messageType}`, token, "POST", body);
  return;
}
