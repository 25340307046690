import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coordinatesToW3w } from "../../../services/maps";
import MapLoader from "../../MapInput/MapLoader";
import Text from "../../Text";

import styles from "./styles";
import { Modal } from "../../Modal/Modal";
import { FlexContainer } from "../../FlexContainer/FlexContainer";
import { Button } from "../../Button";
import { InputSelect } from "../../Inputs/InputSelect";
import { ContainerTitle } from "../../ContainerTitle";
import { EmptyList } from "../../List/EmptyList";

interface Props {
  open: boolean;
  status: string;
  onSetStatus: (name: string, w3w: string) => void;
  onClose: () => void;
  updateFulfillmentName: (id: string, diveshopId: string) => void;
  fulfillmentNames: { label: string; value: string }[];
  fulfillmentNameId?: string;
  loading?: boolean;
  error: string;
  orderlineId: string;
}

interface Coordinates {
  lat: number;
  lng: number;
}

const FulfillmentModal: React.FC<Props> = ({
  open,
  status,
  onClose,
  onSetStatus,
  fulfillmentNames,
  fulfillmentNameId,
  updateFulfillmentName,
  error,
  orderlineId,
}) => {
  const [coordinates, setCoordinates] = useState<Coordinates>();
  const [geoStatus, setStatus] = useState("");
  const [w3w, setW3w] = useState("");
  const [loading, setLoading] = useState(false);
  const [fulfillmentName, setFulfillmentName] = useState(
    fulfillmentNameId !== "-" && fulfillmentNameId ? fulfillmentNameId : ""
  );

  const { t } = useTranslation();

  const getLocation = useCallback(() => {
    if (!navigator.geolocation) {
      setStatus("Geolocation is not supported by your browser");
    } else {
      setStatus("Locating...");
      setLoading(true);

      navigator.geolocation.getCurrentPosition(
        async (position) => {
          setStatus("");
          const coord = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          const w3w = await coordinatesToW3w(
            position.coords.latitude,
            position.coords.longitude
          );
          setW3w(w3w.words);
          setCoordinates(coord);
          setLoading(false);
        },
        () => {
          setStatus("Unable to retrieve your location");
          setLoading(false);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (open) {
      getLocation();
    }
  }, [getLocation, open]);

  return (
    <Modal
      width="70%"
      open={open}
      buttons={
        <FlexContainer justifyContent="flex-end">
          <Button
            containerClassname={styles.buttonsContainer}
            onClick={onClose}
            theme="white"
            label={t("component.orderFulfillment.cancel")}
          />
          <Button
            onClick={() => {
              updateFulfillmentName(orderlineId, fulfillmentName);
              onSetStatus(status, w3w);
            }}
            theme="blue"
            disable={loading}
            label={t("component.orderFulfillment.setStatus")}
          />
        </FlexContainer>
      }
    >
      <Text
        padding="1em 0 1em 0"
        text={t("component.orderFulfillment.fulfillmentStates")}
        color="#000000"
        fontSize="1.4em"
        fontWeight={700}
      />
      {!fulfillmentNames.length ? (
        <div className={styles.notEnrollContainer}>
          No States Definitions configured. Please add them to your Organization
        </div>
      ) : (
        <div className={styles.selectContainer}>
          <InputSelect
            maxHeightOptions="8em"
            label={t("component.orderFulfillment.fulfillmentDefinition")}
            options={fulfillmentNames}
            value={fulfillmentName}
            onChange={(value) => {
              setFulfillmentName(value);
            }}
            error={error}
          />
        </div>
      )}

      {fulfillmentName ? (
        <>
          <ContainerTitle
            label={t("component.orderFulfillment.fulfillmentStatus")}
            size="xsmall"
          />
          <Text
            padding="1em 0 1em 0"
            text={t("component.orderFulfillment.selectState")}
            color="#000000"
            fontSize="1em"
          />
          {!loading ? (
            <FlexContainer flexWrap="wrap" justifyContent="flex-end">
              <Text fontSize="1.1em" fontWeight={700} text={`///${w3w}`} />
            </FlexContainer>
          ) : null}

          {loading ? (
            <div className={styles.iconContainer}>
              <FontAwesomeIcon
                color="#1677ff"
                icon={faSpinner as IconProp}
                size={"2x"}
                spin
              />
            </div>
          ) : !coordinates?.lat && !coordinates?.lng && !coordinates ? null : (
            <div className={styles.addressContainer}>
              <div>{geoStatus}</div>
              <MapLoader
                type="w3w"
                setZoom={false}
                value={coordinates}
                onChange={() => {}}
              />
            </div>
          )}
        </>
      ) : (
        <div style={{ marginTop: "1em" }}>
          <EmptyList
            instructions=""
            text={t("component.orderFulfillment.noFulfillment")}
          />
        </div>
      )}
    </Modal>
  );
};

export default FulfillmentModal;
