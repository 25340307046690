const translation = {
  en: {
    paymentId: "Payment ID",
    paymentType: "Payment Type",
    paymentDetails: "Payment Details",
    refunded: "Refunded",
    amount: "Amount",
    status: "Status",
    actions: "",
    refund: "Refund",
  },
  es: {
    paymentId: "ID de pago",
    paymentType: "Tipo de pago",
    paymentDetails: "Detalles de pago",
    refunded: "Reembolsado",
    amount: "Cantidad",
    status: "Estado",
    actions: "",
    refund: "Reembolso",
  },
  de: {},
};

export default translation;
