const translation = {
  en: {
    cancel: "Cancel",
    edit: "Edit",
    name: "First Name",
    lastName: "Last Name",
    email: "Email",
    externalId: "External ID",
    phone: "Phone Number",
    invitation: "Invitation Magic Link",
    details: "Details",
    sendMagic: "Send Magic Link",
    active: "Fulfiller Active",
    instructions:
      "Invite this user to install the application and start to work on it.",
    how: "Magic Link it's going to be send to the registerd Fulfiller's phone.",
    viaEmail: "Via Email",
    viaPhone: "Via Phone Number",
    send: "Send",
    role: "Roles",
    close: "Close",
    tags: "Tags",
  },
  es: {
    externalId: "ID Externo",
    tags: "Etiquetas",
    cancel: "Cancelar",
    edit: "Editar",
    name: "Nombre",
    lastName: "Apellido",
    email: "Correo electrónico",
    phone: "Número de teléfono",
    invitation: "Invitación Link Mágico",
    detalles: "Detalles",
    sendMagic: "Enviar Link Mágico",
    active: "Fulfiller Activo",
    send: "Enviar",
    viaEmail: "Via Email",
    role: "Roles",
    close: "Cerrar",
    viaPhone: "Via Número de Teléfono",
    how: "Magic Link se enviará al teléfono del fulfiller registrado.",
    instructions:
      "Invite a este usuario a instalar la aplicación y comience a trabajar en ella.",
  },
  de: {},
};

export default translation;
