import React, { useState } from "react";

import Text from "../Text";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { Action, ScheduleLog } from "@innomius/ravent-typescript-types";
import ActionDetail from "./ActionDetail";
import DeleteModal from "../DeleteModal";
import Editor from "@monaco-editor/react";
import { useHistory, useLocation } from "react-router-dom";
import TabNavBar from "../TabNavBar";
import ScheduleLogList from "../ScheduleLogList";
import { SideMenu } from "../SideMenu/SideMenu";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { ContainerTitle } from "../ContainerTitle";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { EditableTitle } from "../EditableTitle";
import { InputText } from "../Inputs/InputText";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
import { EmptyList } from "../List/EmptyList";
import { Spin, Switch } from "antd";

interface Props {
  data: Action;
  onDeleteAction: (id: string) => void;
  onSaveAction: (data: Action) => void;
  logs: ScheduleLog[];
  newLoading: boolean;
}

const ActionForm: React.FC<Props> = ({
  data,
  onSaveAction,
  onDeleteAction,
  logs,
  newLoading,
}) => {
  const [action, setAction] = useState<Action>(data);
  const [edit, setEdit] = useState<boolean>(false);
  const [modal, setModal] = useState<string>("");

  const { t } = useTranslation();

  const onChange = <P extends keyof Action>(prop: P, value: Action[P]) => {
    setAction({ ...action, [prop]: value });
  };

  const location = useLocation();
  const history = useHistory();

  const stringBody = JSON.stringify(action.notificationBody);

  return (
    <SideMenu
      width="60%"
      onClose={() => {
        history.push("/integrations/actions");
        setAction(data);
      }}
      open={
        location.pathname === `/integrations/actions/${data.id}` ||
        location.pathname === `/integrations/actions/${data.id}/logs`
      }
    >
      <DeleteModal
        open={modal === "remove" || modal === "delete"}
        onCancel={() => setModal("")}
        onDelete={() => onDeleteAction(data.id)}
        value={
          modal === "remove"
            ? "Are you sure you want to remove the scheduling of this action?"
            : "Are you sure you want to delete this action?"
        }
      />
      {edit ? (
        <FlexContainer padding="1em 0 0 0" justifyContent="space-between">
          <EditableTitle
            padding="0 0 1em 0"
            value={action.name}
            placeholder={"Name"}
            onChange={(value) => onChange("name", value)}
          />
          <ButtonsContainer justifyContent="flex-start">
            <Button
              label="Cancel"
              theme="white"
              onClick={() => {
                setEdit(false);
              }}
            />
            <Button
              label="Save"
              onClick={async () => {
                try {
                  await onSaveAction(action);
                  setAction(data);
                } catch (err) {}
              }}
            />
          </ButtonsContainer>
        </FlexContainer>
      ) : (
        <>
          <FlexContainer justifyContent="space-between">
            <ContainerTitle size="medium" label={data.name} />
            <ButtonsContainer justifyContent="flex-start">
              <Button
                label={t("component.orderStateList.delete")}
                theme="red"
                onClick={() => setModal("delete")}
              />
              <Button
                theme="white"
                label={t("component.orderStateList.edit")}
                onClick={async () => {
                  setEdit(true);
                }}
              />
            </ButtonsContainer>
          </FlexContainer>
        </>
      )}

      <TabNavBar
        options={[
          {
            id: "details",
            path: `/integrations/actions/${data.id}`,
            label: t("component.orderActionList.details"),
            onClick: () => {
              history.push(`/integrations/actions/${data.id}`);
            },
          },
          {
            label: t("component.orderActionList.historyLogs"),
            id: "logs",
            path: `/integrations/actions/${data.id}/logs`,
            onClick: () => {
              history.push(`/integrations/actions/${data.id}/logs`);
            },
          },
        ]}
      >
        {location.pathname === `/integrations/actions/${data.id}` ? (
          edit ? (
            <div className={styles.itemsWrapper}>
              <div>
                <GridContainer
                  padding="0em 0 1em 0"
                  gap="1em"
                  columns="3fr 1fr"
                >
                  <InputText
                    value={action.url}
                    showError={false}
                    onChange={(value) => onChange("url", value)}
                    label={t("component.addIntegration.flowUrl")}
                  />
                  <div />
                  <InputText
                    showError={false}
                    value={action.description}
                    onChange={(value) => onChange("description", value)}
                    label={t("component.addIntegration.description")}
                  />
                  <div />
                </GridContainer>
                <InputText
                  showError={false}
                  value={action.dataCollectionUrl}
                  onChange={(value) => onChange("dataCollectionUrl", value)}
                  label={t("component.orderActionList.dataCollectionUrl")}
                />
                <FlexContainer padding="2em 0 0 0" justifyContent="flex-start">
                  <Switch
                    checked={action.orderOnly}
                    onChange={(checked) => onChange("orderOnly", checked)}
                  />
                  <Text
                    padding="0 0 0 1em"
                    text={t("component.orderActionList.orderOnly")}
                  />
                </FlexContainer>
                <FlexContainer
                  padding="2em 0 1em 0"
                  justifyContent="flex-start"
                >
                  <Switch
                    checked={action.manualTrigger}
                    onChange={(checked) => onChange("manualTrigger", checked)}
                  />
                  <Text
                    padding="0 0 0 1em"
                    text={t("component.orderActionList.manualTrigger")}
                  />
                </FlexContainer>
                <InputLabel
                  label={t("component.orderActionList.notificationBody")}
                />
                <Editor
                  onChange={(value) =>
                    onChange("notificationBody", value as string)
                  }
                  className={styles.editorBox}
                  height="100%"
                  defaultValue={stringBody}
                  defaultLanguage="json"
                  options={{
                    selectOnLineNumbers: true,
                    // minimap: { enabled: false },
                    scrollbar: {
                      vertical: "hidden",
                    },
                    overviewRulerLanes: 0,
                    hideCursorInOverviewRuler: true,
                    overviewRulerBorder: false,
                    foldingHighlight: false,
                  }}
                />
              </div>
            </div>
          ) : (
            <ActionDetail onDeleteAction={onDeleteAction} data={data} />
          )
        ) : (
          <div className={styles.itemsWrapper}>
            {newLoading ? (
              <div
                style={{
                  height: "20em",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spin />
              </div>
            ) : logs.length ? (
              <ScheduleLogList
                action={true}
                data={logs}
                loading={false}
                error=""
              />
            ) : !newLoading ? (
              <EmptyList
                instructions={""}
                text={t("component.orderActionList.empty")}
              />
            ) : null}
          </div>
        )}
      </TabNavBar>
    </SideMenu>
  );
};

export default ActionForm;
