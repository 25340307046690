import { stylesheet } from "typestyle";
import { black, linkBlue } from "../../utils/colors";

const styles = stylesheet({
  container: {
    padding: "2em",
  },
  title: {
    fontSize: "1.5em",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "left",
    color: "#141322",
    paddingTop: "1em",
  },
  listContainer: {
    padding: "0em",
  },
  loading: {
    color: black,
    fontWeight: 600,
    padding: "6em 1em",
  },
  item: {
    fontSize: "1em",
    width: "100%",
    boxSizing: "border-box",
    breakInside: "avoid",
    textDecoration: "none",
    color: black,
    display: "flex",
    paddingLeft: "0.5em",
    flexWrap: "wrap",
    alignItems: "center",
    minHeight: "4em",
  },

  center: {
    color: black,
    fontStyle: "normal",
    justifyContent: "center",
    padding: "1em",
    display: "flex",
    alignItems: "center",
    marginRight: "0.5em",
    fontSize: "0.9em",
  },
  normal: {
    fontSize: "1em",

    fontWeight: 600,
    alignItems: "center",
    display: "flex",
    padding: "0 0.5em",
  },
  iconSize: {
    width: "1em",
    marginLeft: "0.2em",
  },
  normalWrap: {
    fontSize: "1em",
    justifyContent: "flex-start",

    fontWeight: 600,
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    padding: "0 0.5em",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center !important",
    cursor: "pointer",
  },
  iconContainerQR: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center !important",
    cursor: "pointer",
  },
  imageIcon: {
    width: 60,
    borderRadius: "2px",
    marginRight: "1em",
  },

  imageQRCODE: {
    width: "10em",
    margin: "1em",
  },

  spaceBetween: {
    color: black,
    fontStyle: "normal",
    justifyContent: "flex-start",
    padding: "1em",
    display: "flex",
    alignItems: "center",
    marginRight: "0.5em",
    fontSize: "0.9em",
    flexWrap: "wrap",
  },
  tags: {
    fontSize: "0.9em",
    color: linkBlue + " !important",
    width: "auto",
    borderRadius: "5px",
  },
  icon: {
    width: "2em",
  },
  iconShare: {},
  iconEdit: {},
  qrIcon: {
    width: 60,
  },
  link: {
    textDecoration: "none",
  },
  button: {
    width: "68px !important",
    marginRight: "1em",
  },
  buttonRight: {
    width: "68px !important",
  },
  warning: {
    width: "20px",
  },
  padding: {
    padding: "0.5em",
  },
  inputSelect: {
    width: "90%",
  },
});

export default styles;
