
import React, { useEffect, useState } from "react";
import Text from "../Text";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import InputTextDropdown from "../Inputs/InputTextDropdown";
import AssignAsset from "./AssignAsset";
import { Asset } from "../../utils/types";
import { Modal } from "../Modal/Modal";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { InputError } from "../Inputs/InputError/InputError";

interface Props {
  onClose: () => void;
  open: boolean;
  loading?: boolean;
  error?: string;
  updateAsset: (assetId: string) => void;
  assets: Asset[];
  assetId: string;
}

const AssetModal: React.FC<Props> = ({
  onClose,
  updateAsset,
  open,
  error,
  loading,
  assets,
  assetId,
}) => {
  const [asset, setAsset] = useState(assetId !== "-" && assetId ? assetId : "");
  const [openSelect, setOpen] = useState(false);

  useEffect(() => {
    setAsset(assetId);
  }, [assetId]);

  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Modal
        width="600px"
        buttons={
          <div>
            <ButtonsContainer justifyContent="flex-end">
              <Button
                onClick={onClose}
                theme="white"
                label={t("component.assetModal.cancel")}
              />
              <Button
                onClick={() => updateAsset(asset)}
                theme="blue"
                label={loading ? "..." : t("component.assetModal.save")}
              />
            </ButtonsContainer>
            {error ? (
              <FlexContainer justifyContent="flex-end" padding="0.5em 1em 0 0">
                <InputError error={error} />
              </FlexContainer>
            ) : null}
          </div>
        }
        open={open}
        onClose={onClose}
      >
        <div className={styles.modalContainer}>
          <Text
            padding="0.5em 0 1em 0"
            text={t("component.assetModal.notification")}
            color="#000000"
            fontSize="1.4em"
            fontWeight={700}
          />
          {!assets.length ? (
            <div className={styles.notEnrollContainer}>
              No Assets are configured. Please add them to your Organization
            </div>
          ) : (
            <div>
              {!asset || openSelect ? (
                <InputTextDropdown
                  top={"calc(70% + 1px)"}
                  label={t("component.assetModal.assets")}
                  options={[
                    ...assets.map((item) => ({
                      label: `${item.externalID}-${item.name}`,
                      value: item.id,
                    })),
                    { label: "unset asset", value: "unset" },
                  ]}
                  value={asset}
                  onChange={(value) => {
                    setAsset(value);
                  }}
                />
              ) : (
                <div style={{ marginBottom: "1em" }}>
                  <AssignAsset
                    border
                    onDelete={() => updateAsset("unset")}
                    onClick={() => setOpen(true)}
                    subtype={assets.find((item) => item.id === asset)?.subtype || ""}
                    name={
                      `${
                        assets.find((item) => item.id === asset)?.externalID
                      } - ${assets.find((item) => item.id === asset)?.name}` ||
                      ""
                    }
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AssetModal;
