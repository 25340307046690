import React from "react";

import styles from "./styles";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import Search from "antd/es/input/Search";
import { InputSelect } from "../Inputs/InputSelect";

interface Props {
  onChangeSupplierType?: (text: string) => void;
  onChangeType?: (status: string) => void;
  onChangeSupplierName?: (text: string) => void;
  load: () => void;
  text: string;
  onChangeText: (text: string) => void;
  type: string;
  supplierType: string;
  supplierName: string;
}

const IntegrationFilters: React.FC<Props> = ({
  onChangeType,
  onChangeSupplierType,
  onChangeSupplierName,
  load,
  onChangeText,
  text,
  type,
  supplierType,
  supplierName,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.searchContainerText}>
        <Search
          style={{ marginBottom: "1em" }}
          placeholder={t("component.integrationFilters.searchByName")}
          onSearch={load}
          value={text}
          onChange={(text) => {
            onChangeText(text.target.value);
            history.replace(`integrations?text=${text}`);
          }}
        />
      </div>

      <FlexContainer justifyContent="space-between" alignItems="flex-start">
        <FlexContainer
          padding="0 0 1em 0"
          width="100%"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <InputSelect
            showLabel={false}
            placeholder={t("component.integrationFilters.integrationType")}
            value={type}
            containerClassname={styles.dropdownWidth}
            options={[
              { label: "all", value: "all" },
              { label: "connection", value: "connection" },
              { label: "template", value: "template" },
            ]}
            onChange={(value) => onChangeType?.(value === "all" ? "" : value)}
          />

          <InputSelect
            showLabel={false}
            placeholder={t("component.integrationFilters.supplierType")}
            value={supplierType}
            containerClassname={styles.dropdownWidth}
            options={[
              { label: "all", value: "all" },
              { label: "erp", value: "erp" },
              { label: "payment", value: "payment" },
              { label: "config", value: "config" },
            ]}
            onChange={(value) =>
              onChangeSupplierType?.(value === "all" ? "" : value)
            }
          />
          <div className={styles.searchContainer}>
            <Search
              style={{ marginBottom: "1em" }}
              placeholder={t(
                "component.integrationFilters.searchBySupplierName"
              )}
              onSearch={load}
              value={supplierName}
              onChange={(supplierName) =>
                onChangeSupplierName?.(supplierName.target.value)
              }
            />
          </div>
        </FlexContainer>
        <button
          onClick={() => {
            onChangeType?.("");
            onChangeText("");
            onChangeSupplierType?.("");
            history.replace("integrations");
          }}
          className={styles.buttonText}
        >
          {t("component.integrationFilters.clearAll")}
        </button>
      </FlexContainer>
    </div>
  );
};

export default IntegrationFilters;
