const translation = {
  en: {
    issueInvoice: "Issue Invoice",
    cancel: "Cancel",
    issue: "Issue",
    useERP: "Customer ID",
    regularForm: "Regular Form",
    erpClientId: "ERP Client ID",
    paymentID: "Payment ID",
    taxIdentifier: "Tax Identifier*",
    legalName: "Legal Name*",
    cfdi: "CFDI",
    address: "Address Line 1",
    country: "Country",
    city: "City",
    state: "State",
    zipCode: "Zip Code*",
    customer: "Customer",
    invoice: "Invoice",
    amount: "Amount",
    addressLine2: "Address Line 2",

  },
  es: {
    addressLine2: "Línea 2",
    amount: "Cantidad",
    invoice: "Factura",
    customer: "Cliente",
    issueInvoice: "Emitir Factura",
    cancel: "Cancelar",
    issue: "Emitir",
    useERP: "ID del Cliente * ",
    regularForm: "Forma Normal",
    erpClientId: "ID de cliente",
    paymentID: "ID de pago",
    taxIdentifier: "Identificador fiscal * ",
    legalName: "Nombre legal",
    cfdi: "CFDI",
    address: "Dirección Fiscal",
    country: "País",
    city: "Ciudad",
    state: "Estado",
    zipCode: "Código Postal * ",
    erpId: "ERP ID",
    addressLine: "Address Line 1",
  },
  de: {},
};

export default translation;
