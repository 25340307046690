import React, { useCallback, useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import qs from "qs";
import { getAccessToken } from "../../services/auth";
import loginStyles from "./styles";
import logo from "../../assets/logo.svg";
import downloadIcon from "../../assets/image/downloadImage.svg";
import { useTranslation } from "react-i18next";
import { Alert } from "antd";
import { Button } from "../../components/Button";

interface Props {}

const DownloadFile: React.FC<Props> = () => {
  const useQuery = () => {
    const { search } = useLocation();
    return search;
  };

  const { t } = useTranslation();

  const [fileName, setFileName] = useState<string | null>(null);
  const [fileSize, setFileSize] = useState<number | null>(null); // State for file size
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const query = useQuery();
  const auth = useSelector((state: RootState) => state.auth);
  const fileId = (qs.parse(query.substring(1)).fileId as string) || "";

  useEffect(() => {
    document.title = "RAVENT APP :: Download File";
  }, []);

  const loadFileDetails = useCallback(async () => {
    if (fileId) {
      try {
        const token = await getAccessToken();
        const url = `/api/v1/file_storage/${fileId}`;
        const headers = new Headers({
          Authorization: `Bearer ${token}`,
        });

        const response = await fetch(url, { headers });
        if (!response.ok) {
          throw new Error("File not found");
        }

        const contentDisposition = response.headers.get("Content-Disposition");
        const contentLength = response.headers.get("Content-Length");

        let filename = "downloaded_file";
        if (contentDisposition) {
          const match = contentDisposition.match(/filename="(.+)"/);
          if (match) {
            filename = match[1];
          }
        }

        setFileName(filename);
        setFileSize(contentLength ? parseInt(contentLength, 10) : null);
        setError(""); // Clear any previous error
      } catch (error) {
        setFileName(null);
        setFileSize(null);
        if (error instanceof Error) {
          setError(error.message);
        }
      }
    }
  }, [fileId]);

  const downloadFile = useCallback(async () => {
    if (fileId) {
      setLoading(true);
      try {
        const token = await getAccessToken();
        const url = `/api/v1/file_storage/${fileId}`;
        const headers = new Headers({
          Authorization: `Bearer ${token}`,
        });

        const response = await fetch(url, { headers });
        if (!response.ok) {
          throw new Error("File download failed");
        }

        const blob = await response.blob();
        const contentDisposition = response.headers.get("Content-Disposition");
        let filename = "downloaded_file";
        if (contentDisposition) {
          const match = contentDisposition.match(/filename="(.+)"/);
          if (match) {
            filename = match[1];
          }
        }

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link); // Append the link to the body
        link.click(); // Trigger the download
        document.body.removeChild(link); // Remove the link from the document
        URL.revokeObjectURL(link.href); // Clean up the URL object
        setLoading(false);
      } catch (error) {
        console.error("Download failed:", error);
        setLoading(false);
      }
    }
  }, [fileId]);

  useEffect(() => {
    loadFileDetails();
  }, [loadFileDetails]);

  if (!auth.loading && !auth.user) {
    return <Redirect to="/" />;
  }

  return (
    <div className={loginStyles.grid}>
      <div className={loginStyles.inputsContainer}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={logo} alt="logo" className={loginStyles.logo} />
          <img src={downloadIcon} alt="" className={loginStyles.image} />
          <div className={loginStyles.title}>
            {`${t("container.login.download")} 1 ${t("container.login.file")}`}
          </div>
          {error ? (
            <Alert style={{ margin: "1em 0" }} message={error} type="error" />
          ) : (
            <>
              <div className={loginStyles.text}>
                {fileName ? fileName : "Loading..."}
              </div>
              {fileSize !== null && (
                <div className={loginStyles.subtitle}>
                  {`File Size: ${(fileSize / 1024).toFixed(2)} KB`}{" "}
                  {/* Display file size in KB */}
                </div>
              )}
            </>
          )}
          <Button
            width="100%"
            onClick={downloadFile}
            disable={loading || error !== ""}
            label={t("container.login.downloadFile")}
          />
        </div>
      </div>
    </div>
  );
};

export default DownloadFile;
