import React from "react";
import styles from "./styles";

import { useTranslation } from "react-i18next";

interface Props {}

const NotEnrollBox: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.notEnrollContainer}>
      {t("component.userDetailComponent.notEnroll")}
    </div>
  );
};

export default NotEnrollBox;
