import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as EventIcon } from "../../../assets/fevents.svg";
import { ReactComponent as PassIcon } from "../../../assets/star.svg";

import statesIcon from "../../../assets/states.svg";
import { Button } from "../../Button";
import statesBlackIcon from "../../../assets/sidebar/states.svg";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  FulfillmentEvent,
  FulfillmentDefinition,
  Placement,
  Load,
} from "@innomius/ravent-typescript-types";
import OrderlineDetails from "../../OrderlineDetail";
import FulfillmentModal from "../../OrderFulfillment/FulfilmentStatus/FulfillmentModal";
import StatesModal from "../../OrderFulfillment/StatesModal";
import styles from "./styles";
import {
  defaultFulfillment,
  readFulfillment,
} from "../../../services/fulfilments";
import { Asset } from "../../../utils/types";
import { FlexContainer } from "../../FlexContainer/FlexContainer";
import { ButtonsContainer } from "../../ButtonsContainer";

interface FulfilmentListProps {
  id: string;
  orderlineDescription: string;
  orderlineDetails: string;
  path: string;
  teams: { label: string; value: string }[];
  updateTeam: (id: string, teamId: string) => void;
  load: () => void;
  updateFulfillmentDefinition: (
    id: string,
    definitionId: string,
    list: boolean
  ) => void;
  fulfillmentDefintions: { label: string; value: string }[];
  errorFulfillment: string;
  fulfillmentState: string;
  setState: (id: string, name: string, w3w: string, images: string[]) => void;
  onCreateEvent: (event: FulfillmentEvent) => void;
  onSaveEvent: (event: FulfillmentEvent) => void;
  onDeleteEvent: (id: string) => void;
  updateFulfiller: (
    eventId: string,
    fulfillerId: string,
    orderlineId: string
  ) => void;
  fulfillmentLevel: string;
  locationLevel: string;
  fulfillmentDefinitionId: string;
  orderState: string;
  fulfillmentDate: string;
  state: string;
  onEditPlacement: (event: FulfillmentEvent, placement: Placement) => void;
  onDeletePlacement: (event: FulfillmentEvent, id: string) => void;
  assets: Asset[];
  updateAsset: (event: FulfillmentEvent, id: string) => void;
  onSaveLoad: (eventId: string, data: Load[]) => void;
  saveLoc: (p: Placement, type: string, group: string) => void;
}

const ActionRow: React.FC<FulfilmentListProps> = ({
  id,
  path,
  teams,
  updateTeam,
  updateFulfillmentDefinition,
  fulfillmentDefintions,
  errorFulfillment,
  fulfillmentState,
  setState,
  orderlineDescription,
  onCreateEvent,
  onDeleteEvent,
  updateFulfiller,
  fulfillmentLevel,
  locationLevel,
  fulfillmentDefinitionId,
  orderState,
  fulfillmentDate,
  state,
  onSaveEvent,
  onDeletePlacement,
  onEditPlacement,
  assets,
  updateAsset,
  onSaveLoad,
  orderlineDetails,
  saveLoc,
}) => {
  const [open, setOpen] = useState("");
  const [openDetails, setDetails] = useState(false);
  const [fulfillmentDefinition, setFulfilment] =
    useState<FulfillmentDefinition>(defaultFulfillment);

  const history = useHistory();

  const { t } = useTranslation();

  const load = useCallback(async () => {
    try {
      const res = await readFulfillment(fulfillmentDefinitionId);
      setFulfilment(res);
    } catch (err) {}
  }, [fulfillmentDefinitionId]);

  useEffect(() => {
    if (fulfillmentDefinitionId) {
      load();
    }
  }, [load, fulfillmentDefinitionId, open]);

  return (
    <div className={styles.row}>
      <StatesModal
        fulfillmentNames={fulfillmentDefintions}
        orderlineId={id}
        fulfillmentDefinitionId={fulfillmentDefinitionId}
        error={errorFulfillment}
        updateFulfillmentName={async (
          id: string,
          fulfillmentDefinitionId: string
        ) => {
          try {
            await updateFulfillmentDefinition(
              id,
              fulfillmentDefinitionId,
              false
            );
            setOpen("");
          } catch (err) {
            if (err instanceof Error) {
              console.error("Error Fulfillment", err.message);
            }
          }
        }}
        state={fulfillmentState}
        setState={(name, w3w, images) => {
          try {
            setState(id, name, w3w, images);
            setOpen("");
          } catch (err) {}
        }}
        open={open === "states"}
        onClose={() => setOpen("")}
      />

      <OrderlineDetails
        assets={assets}
        saveLoc={saveLoc}
        orderlines={[]}
        orderlineDetails={orderlineDetails}
        onSaveLoad={onSaveLoad}
        updateAsset={updateAsset}
        onDeletePlacement={onDeletePlacement}
        onEditPlacement={onEditPlacement}
        onEditEvent={onSaveEvent}
        orderlineState={state}
        fulfillmentDate={fulfillmentDate}
        orderState={orderState}
        fulfillmentDefinitionId={fulfillmentDefinitionId}
        locationLevel={locationLevel}
        fulfillmentLevel={fulfillmentLevel}
        ticketDefinitions={[]}
        certificates={[]}
        path={`${path}/orderlines`}
        onDeleteEvent={(id) => onDeleteEvent(id)}
        onCreateEvent={(location) => onCreateEvent(location)}
        fulfillmentState={fulfillmentState}
        setState={(id: string, name: string, w3w: string, images: string[]) =>
          setState(id, name, w3w, images)
        }
        errorFulfillment={errorFulfillment}
        fulfillmentDefintions={fulfillmentDefintions}
        id={id}
        teams={teams}
        updateFulfillmentDefinition={(id: string, fulfillmentId: string) =>
          updateFulfillmentDefinition(id, fulfillmentId, true)
        }
        updateTeam={(id: string, teamId: string) => updateTeam(id, teamId)}
        updateFulfiller={updateFulfiller}
        orderlineDescription={orderlineDescription}
        open={openDetails}
        onClose={() => {
          setDetails(false);
          history.replace(`${path}`);
        }}
      />
      <FlexContainer justifyContent="space-between">
        <ButtonsContainer justifyContent="flex-start" alignItems="flex-start">
          {fulfillmentLevel === "order" ||
          fulfillmentLevel === "none" ? null : fulfillmentDefinition.id ? (
            <div>
              {fulfillmentState ? (
                <div>
                  <FulfillmentModal
                    fulfillmentNames={fulfillmentDefintions}
                    orderlineId={id}
                    fulfillmentNameId={fulfillmentDefinitionId}
                    error={errorFulfillment}
                    updateFulfillmentName={async (
                      id: string,
                      fulfillmentDefinitionId: string
                    ) => {
                      try {
                        await updateFulfillmentDefinition(
                          id,
                          fulfillmentDefinitionId,
                          false
                        );
                        setOpen("");
                      } catch (err) {
                        if (err instanceof Error) {
                          console.error("Error Fulfillment", err.message);
                        }
                      }
                    }}
                    open={fulfillmentState === open}
                    onClose={() => setOpen("")}
                    status={open}
                    onSetStatus={(name, w3w) => {
                      try {
                        setState(id, name, w3w, []);
                        setOpen("");
                      } catch (err) {}
                    }}
                  />
                  <Button
                    icon={statesIcon}
                    label={fulfillmentState}
                    theme={fulfillmentState ? "blue" : "white"}
                    buttonClassname={styles.insideButton}
                    containerClassname={styles.buttonSize}
                    minWidth="10em"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpen("states");
                    }}
                  />
                </div>
              ) : (
                <Button
                  disable={
                    orderState === "cancelled" || orderState === "expired"
                  }
                  containerClassname={styles.buttonSize}
                  icon={statesBlackIcon}
                  theme="white"
                  minWidth="9em"
                  label={t("component.orderFulfillment.select")}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen("states");
                  }}
                />
              )}
            </div>
          ) : (
            <Button
              minWidth="9em"
              theme="white"
              label={t("component.orderFulfillment.select")}
              onClick={(e) => {
                e.stopPropagation();
                setOpen("states");
              }}
            />
          )}

          {fulfillmentLevel === "order" ||
          fulfillmentLevel === "none" ? null : (
            <div
              onClick={(e) => {
                e.stopPropagation();
                history.replace(`${path}/orderlines/${id}/events`);
              }}
              className={styles.normalSmall}
            >
              <EventIcon className={styles.icon} />
            </div>
          )}

          {fulfillmentLevel === "order" ||
          fulfillmentLevel === "none" ? null : (
            <div
              onClick={(e) => {
                e.stopPropagation();
                history.replace(`${path}/orderlines/${id}/tickets`);
              }}
              className={styles.normalSmall}
            >
              <PassIcon className={styles.icon} />
            </div>
          )}
        </ButtonsContainer>
      </FlexContainer>
    </div>
  );
};

export default ActionRow;
