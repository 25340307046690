const translation = {
  en: {
    add: "Add Orderline State",
    stateName: "State Name",
    flowUrl: "Flow URL",
    cancel: "Cancel",
    addModal: "Add",
    deleteMessage: "Are you sure you want yo delete ",
    name: "Name",
    schedule: "Schedule",
    url: "URL",
    timestamp: "Timestamp",
    statusCode: "Status Code",
    scheduleId: "Schedule ID",
  },
  es: {
    schedule: "Calendarización",
    name: "Nombre",
    add: "Agregar estado de línea de orden",
    stateName: "Nombre del estado",
    flowUrl: "Flow URL",
    cancel: "Cancelar",
    addModal: "Agregar",
    deleteMessage: "¿Está seguro de que desea eliminarlo?",
    url: "URL",
    timestamp: "Marca de tiempo",
    statusCode: "Código de Estado",
    scheduleId: "ID de Schedule",
  },
  de: {},
};

export default translation;
