import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles";

import { useTranslation } from "react-i18next";
import FulfilmentStatus from "../OrderFulfillment/FulfilmentStatus";
import FulfillmentHistoryList from "../OrderFulfillment/FulfillmentHistoryList";

import {
  FulfillmentDefinition,
  FulfillmentState,
} from "@innomius/ravent-typescript-types";
import dayjs from "dayjs";
import ChangeModal from "../OrderFulfillment/ChangeModal";
import APIError from "../../utils/APIError";
import {
  defaultFulfillment,
  readFulfillment,
} from "../../services/fulfilments";
import ErrorBox from "../ErrorBox";
import AssignFulfiller from "./AssignFulfillment";
import { GridContainer } from "../GridContainer/GridContainer";
import { Card } from "../Card/Card";
import { ContainerTitle } from "../ContainerTitle";
import { EmptyList } from "../List/EmptyList";
import { InputSelect } from "../Inputs/InputSelect";

interface Props {
  path: string;
  fulfillmentDefinitionId: string;
  fulfillmentDefintions: { label: string; value: string }[];
  configureDefinition: (definitionId: string) => void;
  fulfillmentHistoric: FulfillmentState[];
  setState: (id: string, name: string, w3w: string, images: string[]) => void;
  orderId: string;
  error: string;
  state: string;
  fulfillmentDate: string;
}

const OrderFulfillmentComponent: React.FC<Props> = ({
  fulfillmentDefinitionId,
  fulfillmentDefintions,
  configureDefinition,
  fulfillmentHistoric,
  setState,
  orderId,
  error,
  state,
  fulfillmentDate,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [defError, setDefError] = useState("");
  const [fulfillmentDefinition, setFulfilment] =
    useState<FulfillmentDefinition>(defaultFulfillment);

  const sortedDescData = fulfillmentHistoric?.sort(
    (objA, objB) =>
      Number(dayjs(objB.timestamp, "SSS")) -
      Number(dayjs(objA.timestamp, "SSS"))
  );

  const load = useCallback(async () => {
    try {
      const res = await readFulfillment(fulfillmentDefinitionId);
      setFulfilment(res);
    } catch (err) {
      if (err instanceof APIError) {
        setDefError(err.message);
      }
    }
  }, [fulfillmentDefinitionId]);

  useEffect(() => {
    if (fulfillmentDefinitionId) {
      load();
    }
  }, [load, fulfillmentDefinitionId]);

  return (
    <div>
      <ChangeModal
        error={error}
        fulfillmentNames={fulfillmentDefintions}
        updateFulfillmentName={(name) => configureDefinition(name)}
        onClose={() => setOpen(false)}
        open={open}
      />
      <GridContainer gap="1em" columns="3fr 1.2fr">
        <Card>
          <div className={styles.wrapperCard}>
            <div className={styles.contentContainer}>
              {defError ? (
                <>
                  <ErrorBox error={defError} />
                </>
              ) : fulfillmentDefinition.id ? (
                <>
                  <div>
                    <FulfilmentStatus
                      error={error}
                      updateFulfillmentName={configureDefinition}
                      fulfillmentNames={fulfillmentDefintions}
                      orderlineId={orderId}
                      fulfillmentNameId={fulfillmentDefinitionId}
                      fulfillmentDate={fulfillmentDate}
                      passed={fulfillmentHistoric.map((item) => item.name)}
                      id={fulfillmentDefinitionId}
                      orderStates={
                        fulfillmentDefinition.fulfillmentStatesDefinitions
                      }
                      lastState={sortedDescData[0]?.name}
                      setState={(name, w3w, images) =>
                        setState(orderId, name, w3w, images)
                      }
                      status={fulfillmentDefinition.fulfillmentStatesDefinitions.map(
                        (item) => item.name
                      )}
                      onFulfilled={() => {}}
                    />
                    <div className={styles.contentContainer}>
                      <ContainerTitle
                        size="small"
                        label={t("component.orderlineDetail.history")}
                        padding="0 0 1em 0"
                      />
                      {fulfillmentHistoric.length ? (
                        <div>
                          <FulfillmentHistoryList
                            error=""
                            current={state}
                            loading={false}
                            data={fulfillmentHistoric}
                          />
                        </div>
                      ) : (
                        <EmptyList
                          text={t("component.orderlineDetail.noStateActivated")}
                          instructions=" "
                        />
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <ContainerTitle
                    label={t("component.orderFulfilmentComponent.states")}
                    size="small"
                    padding="0 0 1em 0"
                  />
                  <EmptyList
                    instructions=""
                    text={t("component.orderFulfilmentComponent.noDefinition")}
                  />
                </>
              )}
            </div>
          </div>
        </Card>
        <Card
          containerClassname={
            fulfillmentDefinition.id ? styles.card : styles.bigCard
          }
        >
          {fulfillmentDefinitionId ? (
            <div style={{ paddingTop: "1em" }}>
              <AssignFulfiller
                onClick={() => setOpen(true)}
                type={fulfillmentDefinition.type}
                name={fulfillmentDefinition.name}
              />
            </div>
          ) : (
            <div style={{ padding: "0 0.5em" }}>
              <ContainerTitle
                size="small"
                label={t("component.orderFulfilmentComponent.fulfillmentState")}
                padding="1em 0 1em 0em"
              />
              <div className={styles.instructions}>
                {t("component.orderFulfilmentComponent.instructions")}
              </div>
              {!fulfillmentDefintions.length ? (
                <div className={styles.notEnrollContainer}>
                  {t("component.orderFulfilmentComponent.noDefinitions")}
                </div>
              ) : (
                <InputSelect
                  placeholder={t("component.orderFulfilmentComponent.select")}
                  options={fulfillmentDefintions}
                  onChange={(value) => configureDefinition(value)}
                />
              )}
            </div>
          )}
        </Card>
      </GridContainer>
    </div>
  );
};

export default OrderFulfillmentComponent;
