import { Payment } from "@innomius/ravent-typescript-types";

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { linkBlue } from "../../utils/colors";
import styles from "./styles";
import DeleteModal from "./DeleteModal";
import { useTranslation } from "react-i18next";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { Button } from "../Button";
import { GridContainer } from "../GridContainer/GridContainer";
import { SectionProperty } from "../Section/SectionProperty";
import { SectionSpacer } from "../Section/SectionSpacer";
import { ButtonsContainer } from "../ButtonsContainer";

interface Props {
  data: Payment;
  onClose: () => void;
  onDelete: (id: string) => void;
}

const PaymentDetail: React.FC<Props> = ({ data, onClose, onDelete }) => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <div>
      <DeleteModal
        value={t("component.paymentDetail.deleteMessage")}
        onCancel={() => setOpen(false)}
        open={open}
        onDelete={() => {
          onDelete(data.id);
          setOpen(false);
        }}
      />
      <div className={styles.containerDetail}>
        <FlexContainer
          padding="0 0 1em 0"
          alignItems="center"
          justifyContent="space-between"
        >
          <ContainerTitle
            size="xsmall"
            label={t("component.paymentDetail.paymentDetails")}
          />
          <Button
            label={t("component.paymentDetail.delete")}
            theme="red"
            onClick={() => setOpen(true)}
            disable
          />
        </FlexContainer>

        <GridContainer
          columns="1fr 1fr"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <SectionProperty
            label={t("component.paymentDetail.paymentId")}
            value={data.id || "-"}
          />
          <SectionProperty
            label={t("component.paymentDetail.requestId")}
            value={data.paymentRequestId || "-"}
          />
          <SectionProperty
            label={t("component.paymentDetail.supplier")}
            value={data.supplierName || "-"}
          />
          {data.authorizedByExternalName && (
            <SectionProperty
              label={t("component.paymentDetail.employee")}
              value={data.authorizedByExternalName || "-"}
            />
          )}
          {data.authorizedByName && (
            <SectionProperty
              label={t("component.paymentDetail.employee")}
              value={data.authorizedByName || "-"}
            />
          )}
        </GridContainer>
        {Object.entries(data.paymentData).length ? (
          <div>
            <SectionSpacer />
            <ContainerTitle
              padding="0 0 1em 0"
              size="xsmall"
              label={t("component.paymentDetail.paymentData")}
            />
            <div>
              {Object.entries(data.paymentData).map((item, index) => (
                <GridContainer
                  key={index}
                  gap="1em"
                  columns="1fr 2fr"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <div className={styles.bold}>{item[0]}</div>
                  <div className={styles.pointer}>{item[1]}</div>
                </GridContainer>
              ))}
            </div>
          </div>
        ) : null}
      </div>
      <ButtonsContainer padding="1em 0 0 0" justifyContent="flex-end">
        <div className={styles.closeButton} onClick={() => onClose()}>
          {t("component.paymentDetail.close")}
          <FontAwesomeIcon
            icon={faChevronUp as IconProp}
            className={styles.chevron}
            color={linkBlue}
          />
        </div>
      </ButtonsContainer>
    </div>
  );
};

export default PaymentDetail;
