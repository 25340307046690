import { Action, FulfillmentStateDef } from "@innomius/ravent-typescript-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles";
import { ButtonsContainer } from "../ButtonsContainer";
import { Modal } from "../Modal/Modal";
import { Button } from "../Button";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputText } from "../Inputs/InputText";
import InputTextDropdown from "../Inputs/InputTextAutoDropdown";

interface Props {
  onCancel: () => void;
  loading: boolean;
  onAdd: (data: FulfillmentStateDef) => void;
  open: boolean;
  edit?: boolean;
  onUpdate: (data: FulfillmentStateDef, index?: number) => void;
  index?: number;
  data: FulfillmentStateDef;
  actions: Action[];
}

const AddOrderlineState: React.FC<Props> = ({
  data,
  onCancel,
  loading,
  onAdd,
  open,
  edit,
  onUpdate,
  index,
  actions,
}) => {
  const { t } = useTranslation();
  const [orderLineState, setOrderLineState] =
    useState<FulfillmentStateDef>(data);

  const onChangeState = <P extends keyof FulfillmentStateDef>(
    prop: P,
    value: FulfillmentStateDef[P]
  ) => setOrderLineState({ ...orderLineState, [prop]: value });

  return (
    <div>
      <Modal
        width="700px"
        buttons={
          <div>
            <ButtonsContainer justifyContent="flex-end">
              <Button
                theme="white"
                label={t("component.fulfillmentStates.cancel")}
                onClick={() => onCancel()}
              />
              <Button
                label={
                  loading
                    ? "..."
                    : edit
                    ? `${t("component.fulfillmentStates.update")}`
                    : `${t("component.fulfillmentStates.addModal")}`
                }
                onClick={
                  edit
                    ? () => onUpdate(orderLineState, index)
                    : () => onAdd(orderLineState)
                }
              />
            </ButtonsContainer>
          </div>
        }
        open={open}
        onClose={onCancel}
      >
        <div className={styles.modal}>
          <ContainerTitle
            size="medium"
            label={
              edit
                ? t("component.fulfillmentStates.edit")
                : t("component.fulfillmentStates.add")
            }
          />
            <GridContainer padding="1.5em 0 0 0" gap="1em" columns="1.2fr 3fr">
            <InputText
              label={t("component.fulfillmentStates.stateName")}
              value={orderLineState.name}
              onChange={(value) => onChangeState("name", value)}
            />
            <InputTextDropdown
              top={"calc(100% - 1.5em)"}
              options={
              edit
                ? [
                  ...actions
                  .map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label)),
                  { label: "No Action Selected", value: "" },
                ]
                : actions
                  .map((item) => ({
                  label: item.name,
                  value: item.id,
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label))
              }
              value={orderLineState.actionId}
              onChange={(value) => onChangeState("actionId", value)}
              label={t("component.fulfillmentStates.flowUrl")}
            />
            </GridContainer>
        </div>
      </Modal>
    </div>
  );
};

export default AddOrderlineState;
