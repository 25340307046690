import React from "react";

import styles from "./styles.module.css";
import { Integration } from "../../utils/types";
import Editor from "@monaco-editor/react";
import {
  integrationsTypesOptions,
  intTypesOptions,
} from "../../constants/integrations";
import { InputTextSelect } from "../Inputs/InputTextSelect";
import { useTranslation } from "react-i18next";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { EditableTitle } from "../EditableTitle";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputSelect } from "../Inputs/InputSelect";
import { InputSwitch } from "../Inputs/InputSwitch";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
import { ContainerTitle } from "../ContainerTitle";
import { InputText } from "../Inputs/InputText";

interface IntegrationFormProps {
  data: Integration;
  onSave: () => void;
  onUpdate: (data: Integration) => void;
  edit?: boolean;
  loading: boolean;
  onCancel: () => void;
  integrationsOptions: { label: string; value: string }[];
  onChange: <P extends keyof Integration>(
    prop: P,
    value: Integration[P]
  ) => void;
}

const IntegrationForm: React.FC<IntegrationFormProps> = ({
  onCancel,
  onSave,
  integrationsOptions,
  data,
  onChange,
  loading,
  onUpdate,
  edit,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <FlexContainer justifyContent="space-between" padding="0 0 1em 0">
        <div className={styles.title}>
          <EditableTitle
            placeholder="Integration Name"
            value={data.name}
            onChange={(value) => onChange("name", value)}
          />
        </div>

        <ButtonsContainer padding="1em 0" justifyContent="flex-start">
          <Button
            label={t("component.integrationForm.cancel")}
            theme="white"
            onClick={onCancel}
          />
          <Button
            label={loading ? "..." : t("component.integrationForm.save")}
            onClick={() => {
              if (loading) {
                return;
              }
              edit ? onUpdate(data) : onSave();
            }}
          />
        </ButtonsContainer>
      </FlexContainer>
      <GridContainer
        padding="1em 0 1.5em 0"
        gap="1em"
        columns={edit ? "0.5fr 1fr" : "1fr 1fr"}
      >
        <InputSelect
          label={t("component.integrationForm.integrationType")}
          placeholder={t("component.integrationForm.selectOption")}
          options={intTypesOptions}
          value={data.type}
          showError={false}
          onChange={(value) => onChange("type", value)}
        />
        <FlexContainer
          alignItems="flex-start"
          justifyContent="flex-start"
          flexDirection="column"
        >
          <InputLabel label={t("component.integrationForm.status")} />
          <InputSwitch
            value={data.active}
            onChange={(value) => onChange("active", value)}
            label={t("component.integrationForm.status")}
          />
        </FlexContainer>
        <div />
      </GridContainer>
      <ContainerTitle
        padding={edit ? "1em 0 1em 0" : "0em 0 1em 0"}
        size="xsmall"
        label={t("component.integrationForm.supplierInfo")}
      />
      <GridContainer gap="1em" columns="1fr 1fr">
        <InputSelect
          placeholder={t("component.integrationForm.selectOption")}
          label={t("component.integrationForm.supplierType")}
          options={integrationsTypesOptions}
          value={data.supplierType}
          onChange={(value) => onChange("supplierType", value)}
        />
        <div />
        <InputTextSelect
          label={t("component.integrationForm.supplierName")}
          placeholder={t("component.integrationForm.selectOption")}
          options={integrationsOptions}
          value={data.supplierName}
          onChange={(value) => onChange("supplierName", value)}
        />

        <InputText
          label={t("component.integrationForm.supplierService")}
          value={data.supplierService}
          onChange={(value) => onChange("supplierService", value)}
        />
      </GridContainer>
      <FlexContainer padding="1em 0 2em 0" justifyContent="space-between">
        <ContainerTitle
          size="xsmall"
          label={t("component.integrationForm.setUpJson")}
        />
        {!edit ? (
          <Button
            theme="white"
            label={t("component.integrationForm.seeExample")}
          />
        ) : null}
      </FlexContainer>

      <div className={styles.editorDetails}>
        <Editor
          height="100%"
          className={styles.editorBox}
          defaultLanguage="json"
          defaultValue="{}"
          options={{
            // minimap: { enabled: false },
            scrollbar: {
              vertical: "hidden",
            },
            overviewRulerLanes: 0,
            hideCursorInOverviewRuler: true,
            overviewRulerBorder: false,
            roundedSelection: false,
          }}
          value={data.data}
          onChange={(value) => {
            if (value) {
              onChange("data", value);
            }
          }}
        />
      </div>
    </div>
  );
};

export default IntegrationForm;
