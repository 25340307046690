const translation = {
  en: {
    paymentReqId: "Payment Request ID",
    requestType: "Request Type",
    supplier: "Supplier",
    issued: "Issued",
    expires: "Expires",
    amount: "Amount",
    delete: "Cancel Request",
    close: "Close",
    barCodeDataUri: "BarCode Data URI",
    customerReference: "Customer Reference",
    org: "Organization",
    reference: "Reference",
    bankName: "Bank Name",
    bankAccount: "Bank Account",
    requestDetails: "Request Details",
    requestData: "Payment Request Data",
    link: "Link",
  },
  es: {
    link: "Enlace",
    paymentReqId: "ID de solicitud de pago",
    requestType: "Tipo de solicitud",
    supplier: "Proveedor",
    issued: "Emitido",
    expires: "Vence",
    amount: "Cantidad",
    delete: "Cancelar Solicitud",
    close: "Cerrar",
    barCodeDataUri: "URI de datos de código de barras",
    customerReference: "Referencia del cliente",
    org: "Organización",
    reference: "Referencia",
    bankName: "Nombre del banco",
    bankAccount: "Cuenta Bancaria",
    requestDetails: "Detalles",
    requestData: "Datos de Solicitud de Pago",
  },
  de: {},
};

export default translation;
