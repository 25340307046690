import * as React from 'react';
import { classes } from 'typestyle';
import { InputLabel } from '../InputLabel/InputLabel';
import { InputError } from '../InputError/InputError';
import styles from './styles.module.css';

export interface InputTextProps {
  id?: string;
  value?: string | number | undefined;
  label?: string;
  placeholder?: string;
  password?: boolean;
  onChange?: (value: string, id?: string) => void;
  onHelp?: () => void;
  disabled?: boolean;
  readOnly?: boolean;
  onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  containerClassname?: string;
  padding?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  wrapperFocusClassname?: string | any;
  wrapperClassname?: string;
  inputClassname?: string;
  buttonLabel?: string;
  buttonIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  buttonPositionLeft?: boolean;
  onButtonClick?: () => void;
  error?: false | '' | string[] | string;
  showError?: boolean;
  showLabel?: boolean;
  number?: boolean;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  noEtiquets?: boolean;
  textInside?: string;
  textInsideWidth?: string;
  ref?: React.LegacyRef<HTMLInputElement>;
}

export const InputText: React.FC<InputTextProps> = (props) => {
  const {
    id,
    value,
    label,
    onChange,
    placeholder,
    showLabel = true,
    showError = true,
    password,
    disabled,
    readOnly,
    containerClassname,
    padding,
    wrapperClassname,
    wrapperFocusClassname,
    inputClassname,
    onFocus,
    onBlur,
    onClick,
    buttonLabel,
    buttonIcon: Icon,
    buttonPositionLeft,
    onButtonClick,
    error,

    number,
    onKeyUp,
    noEtiquets,
    textInside,
    textInsideWidth,
    ref
  } = props;
  const [isFocus, setFocus] = React.useState<boolean>(false);
  const change = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value, id);
    }
  };

  return (
    <div className={classes(styles.container, containerClassname)} style={{ padding }}>
      {!noEtiquets ? <InputLabel showLabel={showLabel} label={label} id={id} /> : null}
      <div
        className={classes(styles.wrapper, wrapperClassname, {
          [styles.wrapperFocused]: isFocus,
          [wrapperFocusClassname]: isFocus
        })}
      >
        {textInside ? (
          <div
            style={{ width: textInsideWidth ? textInsideWidth : '28%' }}
            className={error ? styles.textInsideError : isFocus ? styles.textInsideClicked : styles.textInside}
          >
            {textInside}
          </div>
        ) : null}
        <input
          id={id}
          ref={ref}
          className={classes(styles.input, inputClassname)}
          value={value}
          type={password ? 'password' : number ? 'number' : 'text'}
          onChange={change}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          onFocus={(e) => {
            setFocus(true);
            onFocus?.(e);
          }}
          onBlur={(e) => {
            setFocus(false);
            onBlur?.(e);
          }}
          onClick={onClick}
          autoComplete="off"
          style={{
            borderRightWidth: onButtonClick ? 0 : 1,
            borderColor: error ? 'red' : '',
            borderLeftWidth: textInside ? 0 : 1
          }}
          onKeyUp={onKeyUp}
        />
        {onButtonClick ? (
          <div className={isFocus ? styles.buttonContainerClicked : styles.buttonContainer}>
            <button
              style={{ order: buttonPositionLeft ? -1 : 0 }}
              className={styles.button}
              onClick={() => onButtonClick?.()}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
            >
              {buttonLabel}
              {Icon ? <Icon className={styles.icon} /> : null}
            </button>
          </div>
        ) : null}
      </div>
      {!noEtiquets ? <InputError error={error} showError={showError} /> : null}
    </div>
  );
};
