const translation = {
  en: {
    cancel: "Cancel",
    save: "Save",
    details: "Details",
    placement: "Placement",
    externalId: "External ID",
    status: "Enabled",
    type: "Type",
    tags: "Tags",
    notes: "Notes",
    data: "Data (Notification Body)",
    assetType: "AssetType",
    description: "Description",
    vehicle: "vehicle",
    van: "van",
    truck: "truck",
    moped: "moped",
    motorcycle: "motorcycle",
    scooter: "scooter",
    bike: "bike",
    ferry: "ferry",
    ship: "ship",
    IOT: "IOT",
    locker: "locker",
    tools: "tools",
    other: "other",
    bus: "bus",
    limo: "limo",
    suv: "suv",
    subType: "Sub Type",
    vehicleLicensePlate: "License Plate",
    vehicleSerial: "Vehicle Serial",
    vehicleBrand: "Vehicle Brand",
    vehicleModel: "Vehicle Model",
    vehicleCapacity: "Vehicle Capacity",
    vehicleWeight: "Vehicle Weight",
    vehicleLastKnownLocation: "Vehicle Last Known Location",
    locationCoordinatesLatitude: "Latitude",
    locationCoordinatesLongitude: "Longitude",
    quantity: "Quantity",
    units: "Units",
    max: "Max",
    current: "Current",
    timestamp: "Timestamp",
    coordinates: "Coordinates",
    generic: "generic",
    next: "Next",
    vehicleDetails: "Vehicle Details",
    prev:  "Previous",
  
  },
  es: {
    vehicleDetais: 'Vehicle Details',
    prev: "Anterior",
    next: "Siguiente",
    generic: "genérico",
    subType: "Sub Tipo",
    vehicleLicensePlate: "Placa",
    vehicleSerial: "Serial",
    vehicleBrand: "Marca",
    vehicleModel: "Modelo",
    vehicleCapacity: "Capacidad",
    vehicleWeight: "Peso",
    vehicleLastKnownLocation: "Última Ubicación Conocida",
    locationCoordinatesLatitude: "Latitud",
    locationCoordinatesLongitude: "Longitud",
    quantity: "Cantidad",
    units: "Unidades",
    max: "Máximo",
    current: "Actual",
    timestamp: "Marca de Tiempo",
    coordinates: "Coordenadas",
    suv: "suv",
    bus: "bus",
    limo: "limo",
    vehicle: "vehículo",
    van: "camioneta",
    truck: "camión",
    moped: "ciclomotor",
    motorcycle: "motocicleta",
    scooter: "scooter",
    bike: "bicicleta",
    ferry: "ferry",
    ship: "barco",
    IOT: "IOT",
    locker: "locker",
    tools: "herramientas",
    other: "otros",
    description: "Descripción",
    cancel: "Cancelar",
    save: "Guardar",
    details: "Detalles",
    placement: "Lugar",
    externalId: "ID Externo",
    status: "Activado",
    type: "Tipo",
    tags: "Etiquetas",
    notes: "Notas",
    data: "Datos (Cuerpo de Notificación)",
    assetType: "Tipo de Activo",
  },
  de: {},
};

export default translation;
