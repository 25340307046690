import { stylesheet } from "typestyle";

const styles = stylesheet({
  card: {
    background: "rgba(250, 173, 21, 0.1)",
    border: "1px solid rgba(250, 173, 21, 0.3)",
    borderRadius: "6px",
    padding: "1em 1em 1em 2em",
  },
  button: {
    height: "100%",
    minWidth: "10em",
    marginLeft: "1em",
  },
});

export default styles;
