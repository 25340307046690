import React, { useCallback, useEffect, useState } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import OfferSetDetailComponent from "../../components/OfferSetDetail";
import { OfferSet, Offer } from "@innomius/ravent-typescript-types";
import TabNavBar from "../../components/TabNavBar";
import OfferSetDetailHeader from "../../components/OfferSetDetailHeader";

import { offerSetRead, OfferSetParams } from "../../services/offersets";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import OfferForm from "../../components/OfferForm";
import { defaulVariant } from "../../services/offers";
import OfferSetForm from "../../components/OfferSetForm";
import { venueList } from "../../store/venues";
import styles from "./styles.module.css";
import OfferListComponent from "../../components/OfferListComponent";
import APIError from "../../utils/APIError";
import { useTranslation } from "react-i18next";
import { EmptyList } from "../../components/List/EmptyList";
import { Card } from "../../components/Card/Card";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { Button } from "../../components/Button";
import { ContainerTitle } from "../../components/ContainerTitle";

interface Params {
  id: string;
}

interface Props extends RouteComponentProps<Params> {}

const OfferSetDetail: React.FC<Props> = ({ match, history }) => {
  const [offerSet, setOfferSet] = useState<OfferSet | null>();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [error, setError] = useState("");
  const [open, setModal] = useState("");
  const [edit, setEdit] = useState<boolean>(false);
  const offers = useSelector((state: RootState) => []);
  const [variant, setVariant] = useState<Offer>(defaulVariant);
  const venues = useSelector((state: RootState) => state.venues);

  const load = useCallback(async () => {
    dispatch(venueList({}));

    try {
      const offer = await offerSetRead(match.params.id);
      setOfferSet(offer);
    } catch (err) {
      if (err instanceof APIError) {
        setError(err.message);
      }
    }
  }, [match.params.id, dispatch]);

  useEffect(() => {
    load();
  }, [match.params.id, load]);

  if (!offerSet) {
    return <EmptyList instructions="Error" error={error} />;
  }

  const onChange = <P extends keyof OfferSetParams>(
    prop: P,
    value: OfferSetParams[P]
  ) => {
    setOfferSet({ ...offerSet, [prop]: value });
  };

  const onChangeVariant = <P extends keyof Offer>(prop: P, value: Offer[P]) => {
    setVariant({ ...variant, [prop]: value });
  };

  return (
    <LayouWithSideBar>
      <div className={styles.container}>
        <OfferSetDetailHeader
          edit={edit}
          onCancel={() => setEdit(false)}
          onEdit={() => setEdit(true)}
          onChange={onChange}
          data={offerSet}
        />
        <TabNavBar
          options={[
            {
              id: "offeerVariants",
              label: t("container.offerDetail.offers"),
              path: `/offersets/${match.params.id}/offers`,
            },
            {
              id: "offerSetDetails",
              label: t("container.offerDetail.offerDetails"),
              path: `/offersets/${match.params.id}`,
            },
          ]}
        >
          <Switch>
            <Route path={`/offersets/${match.params.id}`} exact>
              {!edit ? (
                <OfferSetDetailComponent data={offerSet} />
              ) : (
                <OfferSetForm edit={edit} onChange={onChange} data={offerSet} />
              )}
            </Route>
            <Route path={`/offersets/${match.params.id}/offers`}>
              {!offers.length ? (
                <Card containerClassname={styles.routeContainerEmpty}>
                  <EmptyList
                    instructions="Start creating your Offers"
                    text="Multiple offers can be added to the offer set."
                    buttonText="Create New Variant"
                    onNewClick={() =>
                      history.push(`/offersets/${match.params.id}/offers/new`)
                    }
                  />
                </Card>
              ) : (
                <Card containerClassname={styles.routeContainer}>
                  <FlexContainer
                    padding="0 0 2em 0"
                    justifyContent="space-between"
                  >
                    <ContainerTitle
                      label="Offers"
                      size="xsmall"
                      count={offers.length.toString()}
                    />
                    <Button
                      label="+ Create New Offer"
                      onClick={() => {
                        history.push(
                          `/offersets/${match.params.id}/offers/new`
                        );
                        setModal("create");
                      }}
                    />
                  </FlexContainer>
                  <OfferListComponent
                    onChange={onChangeVariant}
                    path={`/offersets/${match.params.id}/offers`}
                    loading={false}
                    error={error}
                    data={[]}
                  />
                </Card>
              )}
              <OfferForm
                onClose={() => setModal("")}
                open={open === "create"}
                venues={venues.list.data}
                path={`/offersets/${match.params.id}/offers`}
                data={variant}
                onChange={onChangeVariant}
                onCreate={() => {}}
              />
            </Route>
          </Switch>
        </TabNavBar>
      </div>
    </LayouWithSideBar>
  );
};

export default OfferSetDetail;
