import { stylesheet } from "typestyle";
import { lightGrey } from "../../utils/colors";

const styles = stylesheet({
  container: {
    padding: "1em 0 0 0",
  },
  button: {
    marginRight: "1em",
  },
  line: {
    width: "100%",
    height: "1px",
    margin: "1em 0 0 0",
    background: lightGrey,
  },
  lineDown: {
    width: "100%",
    height: "1px",
    margin: "0 0 2em 0",
    background: lightGrey,
  },
  radioContainer: {
    marginRight: "3em",
    padding: "1em 0",
  },
});

export default styles;
