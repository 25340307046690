import React, { useCallback, useEffect, useState } from "react";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import styles from "./styles";
import { Placement } from "@innomius/ravent-typescript-types";
import { useTranslation } from "react-i18next";
import MapForm from "../MapForm";
import { Location } from "@innomius/ravent-typescript-types";
import {
  coordinatesToW3w,
  defaultW3W,
  w3wToCoordinates,
} from "../../services/maps";
import InputTextAutoDropdown from "../Inputs/InputTextAutoDropdown";
import InputTextDropdown from "../Inputs/InputTextDropdown";
import { venueTypes } from "../../constants/venues";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputText } from "../Inputs/InputText";

interface Props {
  placement: Placement;
  groups: { value: string; label: string }[];
  onChange: (value: Placement) => void;
  edit?: boolean;
  onEdit?: () => void;
  data: Location;
  onChangeLocation: <P extends keyof Location>(
    prop: P,
    value: Location[P]
  ) => void;
}

const PlacementForm: React.FC<Props> = ({
  placement,
  onChange,
  edit,
  onChangeLocation,
  data,
  groups,
}) => {
  const { t } = useTranslation();

  const [locationW3W, setLocationW3W] = useState(defaultW3W);

  const load = useCallback(async () => {
    if (placement.coordinates?.latitude) {
      const res = await coordinatesToW3w(
        parseFloat(placement.coordinates.latitude || ""),
        parseFloat(placement.coordinates.longitude || "")
      );
      setLocationW3W(res);
      onChange({
        w3w: res.words,
        type: "w3w",
        id: "",
        chronologicalIndex: 0,
        reference: placement.reference,
        line1: placement.line1,
        name: placement.name,
        coordinates: {
          latitude: placement.coordinates?.latitude.toString() || "0",
          longitude: placement.coordinates?.longitude.toString() || "0",
        },
      });
      return;
    }
    if (placement.w3w) {
      const res = await w3wToCoordinates(placement.w3w);
      setLocationW3W(res);
      onChange({
        w3w: placement.w3w,
        chronologicalIndex: 0,
        type: "w3w",
        id: "",
        reference: placement.reference,
        line1: placement.line1,
        name: placement.name,
        coordinates: {
          latitude: res.coordinates.lat.toString(),
          longitude: res.coordinates.lng.toString(),
        },
      });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <div>
      <div className={styles.containerDetails}>
        <FlexContainer justifyContent="space-between">
          <ContainerTitle size="xsmall" label="Placement" />
        </FlexContainer>
        <p className={styles.textInstructions}>
          {edit
            ? "Search a placement location."
            : "Search a placement location."}
        </p>

        <GridContainer padding="1em 0 0 0" gap="1em" columns="1fr 1fr">
          <InputText
            value={data.externalId}
            label={t("component.orderLocation.externalId")}
            onChange={(value) => {
              onChangeLocation("externalId", value);
            }}
          />
          <InputTextDropdown
            label={"Type"}
            showError={false}
            value={data.type}
            top={"calc(100% + 1px)"}
            options={venueTypes}
            onChange={(value) => onChangeLocation("type", value)}
          />
        </GridContainer>
        <GridContainer padding="0em 0 0 0" gap="1em" columns="1fr 1fr">
          <InputText
            value={data.groupCode}
            label={t("component.locationDetail.groupCode")}
            onChange={(value) => {
              onChangeLocation("groupCode", value);
            }}
          />
          <InputTextAutoDropdown
            top={"calc(100% - 1.5em)"}
            options={groups}
            value={data.group}
            label="Group"
            onChange={(value) => onChangeLocation("group", value)}
          />
        </GridContainer>

        <GridContainer gap="1em" columns="1fr">
          <InputText
            value={placement.reference}
            label={t("component.orderLocation.reference")}
            onChange={(value) => {
              onChange({
                w3w: placement.w3w,
                type: "w3w",
                chronologicalIndex: 0,

                id: "",
                reference: value,
                line1: placement.line1,
                name: placement.name,
                coordinates: {
                  latitude: placement.coordinates?.latitude.toString() || "0",
                  longitude: placement.coordinates?.longitude.toString() || "0",
                },
              });
            }}
          />
        </GridContainer>

        <MapForm
          setW3W={(value) => {
            onChange({
              w3w: value,
              type: "w3w",
              id: "",
              chronologicalIndex: 0,
              reference: placement.reference,
              line1: placement.line1,
              name: placement.name,
              externalId: placement.externalId,
              coordinates: {
                latitude: placement.coordinates?.latitude.toString() || "0",
                longitude: placement.coordinates?.longitude.toString() || "0",
              },
            });
          }}
          line1={placement.line1}
          w3w={locationW3W}
          setData={(data, name) => {
            onChange({
              w3w: data.words,
              type: "w3w",
              chronologicalIndex: 0,

              id: "",
              reference: placement.reference,
              line1: name,
              name: data.name,
              coordinates: {
                latitude: data.coordinates.lat.toString(),
                longitude: data.coordinates.lng.toString(),
              },
            });
            setLocationW3W(data);
          }}
        />
      </div>
    </div>
  );
};

export default PlacementForm;
