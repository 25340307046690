const translation = {
  en: {
    add: "Add Orderline State",
    stateName: "State Name",
    flowUrl: "Flow URL",
    cancel: "Cancel",
    addModal: "Add",
    deleteMessage: "Are you sure you want yo delete ",
    eventName: "Event Name",
    automatic: "Off/On",
    description: "Description",
    scheduling: "Scheduled",
    schedulingDetails: "Scheduling Details",
    warning:
      "Are you sure you want to erase the scheduling flow configuration? ",
    crontabExpression: "Crontab Expression",
    frequency: "Frequency",
    scheduleFlow: "Schedule Flow",
    single: "Single",
    multiple: "Multiple Times",
    when: "Schedule Date",
    cronTabExpression: "Cron Tab Expression",
    des: "(minute, hour, day of month, month,  day of week)",
    scheduleTimes: "Schedule Times",
    schedule: "Schedule",
    delete: "Delete",
    edit: "Edit",
    actionName: "Action Name",
  },
  es: {
    actionName: "Nombre de la Acción",

    delete: "Eliminar",
    edit: "Editar",
    add: "Agregar estado de línea de orden",
    stateName: "Nombre del estado",
    flowUrl: "Flow URL",
    cancel: "Cancelar",
    addModal: "Agregar",
    deleteMessage: "¿Está seguro de que desea eliminarlo ",
    eventName: "Evento",
    automatic: "Apagado/Prendido",
    description: "Descripción",
    scheduling: "Programar",
    schedulingDetails: "Detalles de Programación",
    warning: " ¿Está seguro de que desea eliminar la programción del flow?",
    crontabExpression: "Expresión CronTab",
    frequency: "Frequencia",
    scheduleFlow: "Programar Flow",
    single: "Una Vez",
    multiple: "Multiples Veces",
    when: "Fecha de Programación",
    cronTabExpression: "Expresión de CronTab",
    des: "(minuto, hora, día del mes, mes, día de la semana)",
    scheduleTimes: "Ejecuciones",
    schedule: "Calendarización",
  },
  de: {},
};

export default translation;
