const translation = {
  en: {
    name: "Full Name",
    email: "Email",
    userName: "User Name",
    phoneNumber: "Phone Number",
    actions: "",
    team: "Team",
    status: "Status",
    role: "Role",
    magicLink: "Send Magic Link",
    edit: "Edit",
    unassigned: "Assign to new Team",
    delete: "Delete User",
    tags: "Tags",
  },
  es: {
    team: "Equipo",
    tags: "Etiquetas",
    name: "Nombre completo",
    email: "Correo electrónico",
    userName: "Usuario",
    phoneNumber: "Número de teléfono",
    actions: "",
    status: "Status",
    role: "Rol",
    magicLink: "Mandar Magic Link",
    edit: "Editar",
    unassigned: "Asignar a nuevo Equipo",
    delete: "Eliminar Usuario",
  },
  de: {},
};

export default translation;
