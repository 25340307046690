import React from "react";

import styles from "./styles";
import { useTranslation } from "react-i18next";
import OrderlineStateList from "../OrderlineStateList";
import FulfilmentType from "../FulfillmentDetailComponent/FulfillmentType";
import { Action, FulfillmentStateDef } from "@innomius/ravent-typescript-types";
import { ContainerTitle } from "../ContainerTitle";
import { SectionSpacer } from "../Section/SectionSpacer";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { Button } from "../Button";
import { EmptyList } from "../List/EmptyList";

interface Props {
  orderLinesStates: FulfillmentStateDef[];
  addState: (data: FulfillmentStateDef) => void;
  openModal: () => void;
  loading: boolean;
  loadingActions: string;
  error: string;
  onDeleteState: (id: number) => void;
  onEditState: (data: FulfillmentStateDef, index: number) => void;
  type: string;
  actions: Action[];
}

const FulfillmentStates: React.FC<Props> = ({
  orderLinesStates,
  addState,
  loading,
  loadingActions,
  error,
  onDeleteState,
  onEditState,
  openModal,
  type,
  actions,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <ContainerTitle
        padding="1em 0 1em 0"
        size="small"
        label={t("component.fulfillmentDetail.type")}
      />
      <FulfilmentType type={type} />
      <div className={styles.padding} />
      <SectionSpacer />
      {orderLinesStates.length ? (
        <div>
          <FlexContainer padding="1em 0 2em 0" justifyContent="space-between">
            <ContainerTitle
              size="small"
              label={t("component.fulfillmentStates.orderlinesStates")}
            />
            <Button
              label={t("component.fulfillmentStates.add")}
              onClick={() => openModal()}
            />
          </FlexContainer>
          <OrderlineStateList
            error={error}
            actions={actions}
            onDelete={(id) => onDeleteState(id)}
            update={(item, index) => onEditState(item, index)}
            onAddState={(data) => addState(data)}
            data={orderLinesStates}
            loading={loading}
            loadingActions={loadingActions}
          />
        </div>
      ) : (
        <EmptyList
          instructions={""}
          text={t("component.fulfillmentStates.emptyInstructions")}
          label={t("component.fulfillmentStates.add")}
          onClick={() => openModal()}
        />
      )}
    </div>
  );
};

export default FulfillmentStates;
