import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Text from "../../components/Text";
import styles from "./styles";

import Layout from "../../components/Layout";
import SuccessScreenEnroll from "./SuccessScreenEnroll";
import qs from "qs";
import { message } from "antd";
import { enrollUser } from "../../services/users";
import APIError from "../../utils/APIError";
import logo from "../../assets/logo.svg";
import { InputPassword } from "../../components/Inputs/InputPassword";
import { useTranslation } from "react-i18next";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { Card } from "../../components/Card/Card";
import { GridContainer } from "../../components/GridContainer/GridContainer";
import { InputText } from "../../components/Inputs/InputText";
import { Button } from "../../components/Button";

interface Props {}

const EnrollUser: React.FC<Props> = () => {
  const { t } = useTranslation();

  function useQuery() {
    const { search } = useLocation();
    return search;
  }

  const query = useQuery();
  const [name, setName] = useState<string>(
    (qs.parse(query.substring(1)).firstName as string) || ""
  );

  const [lastName, setLastName] = useState<string>(
    (qs.parse(query.substring(1)).lastName as string) || ""
  );
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirm] = useState<string>("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const token = qs.parse(query.substring(1)).token as string;

  const orgName = qs.parse(query.substring(1)).organization as string;

  useEffect(() => {
    document.title = "RAVENT APP :: Enroll User";
  });

  async function onEnroll() {
    if (loading) {
      return;
    }
    if (confirmPassword !== password) {
      message.error(t("container.enrollUser.validationError"));
      return;
    }
    try {
      setLoading(true);
      await enrollUser(name, lastName, password, token);
      setSuccess(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err instanceof APIError) {
        message.error(err.messages || "An unexpected error ocurred");
      }
    }
  }

  return (
    <div>
      <div className={styles.mobileContainer}>
        {success ? (
          <SuccessScreenEnroll />
        ) : (
          <Card containerClassname={styles.cardMobile}>
            <FlexContainer padding="0 0 0.5em 0">
              <img src={logo} alt="" className={styles.logo} />
            </FlexContainer>
            <p className={styles.textMobile}>
              {t("container.enrollUser.welcome")}
              <span className={styles.blueText}>
                {" "}
                {orgName ? orgName.replaceAll("_", " ") : orgName}{" "}
              </span>
              {t("container.enrollUser.workspace")}
            </p>
            <Text
              text={t("container.enrollUser.instructions")}
              fontSize="1.1em"
              color="#000"
              textAlign="left !important"
              padding="0.5em 0"
            />

            <InputText
              label={t("container.enrollUser.name")}
              value={name}
              onChange={(value) => setName(value)}
            />
            <InputText
              label={t("container.enrollUser.lastName")}
              value={lastName}
              onChange={(value) => setLastName(value)}
            />
            <InputPassword
              showIcon
              label={t("container.enrollUser.password")}
              value={password}
              onChange={(value) => setPassword(value)}
            />
            <InputPassword
              showIcon
              label={t("container.enrollUser.confirmPassword")}
              onChange={(value) => setConfirm(value)}
              error={
                confirmPassword !== password && confirmPassword
                  ? `${t("container.enrollUser.validationError")}`
                  : ""
              }
            />
            <Button
              width="100%"
              label={loading ? "..." : "Enroll"}
              onClick={() => onEnroll()}
            />
          </Card>
        )}
      </div>
      <div className={styles.normal}>
        <Layout>
          <div className={styles.container}>
            {success ? (
              <SuccessScreenEnroll />
            ) : (
              <Card containerClassname={styles.webContainer}>
                <p className={styles.text}>
                  {t("container.enrollUser.welcome")}
                  <span className={styles.blueText}>
                    {" "}
                    {orgName ? orgName.replaceAll("_", " ") : orgName}{" "}
                  </span>
                  {t("container.enrollUser.workspace")}
                </p>
                <Text
                  text={t("container.enrollUser.instructions")}
                  fontSize="1.1em"
                  color="#000"
                  textAlign="left !important"
                  padding="2em 0"
                />

                <GridContainer gap="1em" columns="1fr 1fr">
                  <InputText
                    label={t("container.enrollUser.name")}
                    value={name}
                    onChange={(value) => setName(value)}
                  />
                  <InputText
                    label={t("container.enrollUser.lastName")}
                    value={lastName}
                    onChange={(value) => setLastName(value)}
                  />
                </GridContainer>
                <InputPassword
                  showIcon
                  label={t("container.enrollUser.password")}
                  value={password}
                  onChange={(value) => setPassword(value)}
                />
                <InputPassword
                  showIcon
                  label={t("container.enrollUser.confirmPassword")}
                  onChange={(value) => setConfirm(value)}
                  error={
                    confirmPassword !== password && confirmPassword
                      ? `${t("container.enrollUser.validationError")}`
                      : ""
                  }
                />
                <Button
                  width="100%"
                  label={loading ? "..." : "Enroll"}
                  onClick={() => onEnroll()}
                />
              </Card>
            )}
          </div>
        </Layout>
      </div>
    </div>
  );
};

export default EnrollUser;
