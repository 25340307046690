const translation = {
  en: {
    successCreation: "Venue was successfully created",
    successUpdated: "Venue was successfully updated",

    successDelete: "Venue was successfully deleted",
    locations: "Locations",
    venueDetails: "Details",
    instructions: "No Locations Found",
    addMultiple: "Add multiple locations to your venue.",
    createNew: "+ Create New Venue",
    createNewLocations: "+ Create New Location",
    states: "Order States",
    events: "Order Events",
    successCreationLocation: "Location was successfully created",
    locationDeleted: "Location was successfully deleted",
    successUpdatedLocation: "Location was succcessfully updated",
    paymentStates: "Payment States",
    statesDef: "State Definitions",
    ticketingDef: "Ticketing Definitions",
    importMultiple: "Import from CSV",
    export: "Export to CSV",
    delete: "Delete Locations",
    actions: "Actions",
    duplicate: "Duplicate Locations",
    instructionsD: "You don't have any duplicates",
    missing: "Missing Locations",
  },
  es: {
    missing: "Lugares Faltantes",
    instructionsD: "No tiene lugares duplicados",
    actions: "Acciones",
    duplicate: "Lugares Duplicados",
    delete: "Eliminar Lugares",
    export: "Exportar a CSV",
    importMultiple: "Importar de CSV",
    successCreation: "El Recinto se creó con éxito",
    successDelete: "El Recinto se eliminó con éxito",
    successUpdated: "El Recinto se actualizó con éxito ",
    locations: "Ubicaciones",
    venueDetails: "Detalles",
    instructions: "Empieza a crear tus Ubicaciones",
    addMultiple: "Agrega múltiples ubicaciones a tu Recinto.",
    createNew: "+ Crear Nuevo Recinto",
    createNewLocations: "+ Crear una nueva Ubicación",
    states: "Estados de la Orden",
    events: "Eventos de la Orden",
    successCreationLocation: "Localización se creó con éxito",
    locationDeleted: "Lugar fue borrado con éxito",
    successUpdatedLocation: "Localización fue actualizada con éxito",
    paymentStates: "Estados del Pago",
    statesDef: "Definiciones de Estado",
    ticketingDef: "Definiciones de Certificado",
  },

  de: {},
};

export default translation;
