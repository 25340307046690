import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Order, Schedule } from "@innomius/ravent-typescript-types";
import styles from "./styles";
import { numberFormat } from "../../utils/format";
import CancelOrderModal from "../CancelOrderModal";
import CloseOrderModal from "../CloseOrderModal";
import DeleteModal from "../DeleteModal";
import { useTranslation } from "react-i18next";
import { ReactComponent as ShareIcon } from "../../assets/share.svg";

import { ReactComponent as SendIcon } from "../../assets/send-order.svg";
import SendMail from "../SendModal/SendMail";
import { downloadOrderPDF } from "../../services/pdfs";
import APIError from "../../utils/APIError";
import message from "antd/lib/message";
// import { ReactComponent as BookMarkActiveIcon } from "../../assets/btn-bookmark-active.svg";
import { ReactComponent as QRCodeIcon } from "../../assets/download-qr.svg";

import { ReactComponent as BookMarIcon } from "../../assets/bookmark.svg";
import { sendMessage } from "../../services/messaging";

import { Tooltip } from "antd";
import { StatusCatalog } from "../StatusBadge";
import { StatusBadgePoint } from "../StatusBadgePoint/StatusBagePoint";
import { formatDate } from "../../utils/times";
import { Button } from "../Button";
import ScheduleFlow from "../SendModal/ScheduleFlow";
import OrderQR from "../OrderQR";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { BackLink } from "../BackLink";
import { ButtonsContainer } from "../ButtonsContainer";
import { ContainerTitle } from "../ContainerTitle";

interface OrderHeaderProps {
  path: string;
  data: Order;
  onCancel: () => void;
  shortId: string;
  totalAmount: number;
  currency: string;
  deleteError?: string;
  loading: string;
  onDelete: () => void;
  markAsFavourite: () => void;
  flows: { name: string; actionId: string; url: string }[];
  setOpen: (value: string) => void;
  open: string;
  notifyWebHook: (actionId: string) => void;
  errorFlows: string;
  onCloseFlows: () => void;
  closeOrder: () => void;
  schedule: (actionId: string, schedule: Schedule) => void;
}

const OrderHeader: React.FC<OrderHeaderProps> = ({
  shortId,
  onCancel,
  totalAmount,
  deleteError,
  loading,
  onDelete,
  data,
  markAsFavourite,
  flows,
  setOpen,
  open,
  notifyWebHook,
  errorFlows,
  onCloseFlows,
  closeOrder,
  currency,
  schedule,
}) => {
  const history = useHistory();
  const [mailLoading, setLoading] = useState("");
  const { t } = useTranslation();
  const [sched, setSched] = useState(false);

  return (
    <div>
      <OrderQR
        shortId={data.shortId}
        createdAt={data.created as string}
        id={data.id}
        open={open === "qrcode"}
        onClose={() => setOpen("")}
      />
      <SendMail
        onDownload={async (lang: string) => {
          try {
            setLoading("download");
            await downloadOrderPDF(data.id, lang, "orderDetails", false);
            setLoading("");
          } catch (err) {
            setLoading("");
            if (err instanceof APIError) {
              message.error(`${err.message}: ${err.messages}`);
            }
          }
        }}
        onOpenPdf={async (lang: string) => {
          try {
            setLoading("open");
            await downloadOrderPDF(data.id, lang, "orderDetails", true);
            setLoading("");
          } catch (err) {
            setLoading("");
            if (err instanceof APIError) {
              message.error(`${err.message}: ${err.messages}`);
            }
          }
        }}
        id={data.id}
        title={t("component.orderDetail.order")}
        onSend={async (medium: string, contact: string, lang: string) => {
          try {
            setLoading("send");
            await sendMessage(data.id, lang, medium, contact, "orderDetails");
            setLoading("");
            setOpen("");
            message.success(
              `${t("component.orderDetail.order")} ${data.shortId} ${t(
                "component.orderDetail.shared"
              )}`
            );
          } catch (err) {
            setLoading("");
            if (err instanceof APIError) {
              message.error(`${err.message}: ${err.messages}`);
            }
          }
        }}
        loading={mailLoading === "send"}
        loadingDownload={loading === "download" || loading === "open"}
        onCancel={() => setOpen("")}
        open={open === "share"}
        contactEmail={data.customer.email}
      />
      <ScheduleFlow
        setSchedule={setSched}
        schedule={sched}
        flows={flows || []}
        id={data.id}
        onSend={async (actionId, s) => {
          if (sched) {
            await schedule(actionId, s);
          } else {
            await notifyWebHook(actionId);
          }
        }}
        error={errorFlows}
        loading={loading === "schedule" || loading === "flows"}
        onCancel={() => {
          setOpen("");
          onCloseFlows();
        }}
        open={open === "flows"}
        contactEmail={data.customer.email}
      />

      <CancelOrderModal
        error={deleteError}
        open={open === "cancel"}
        loading={loading === "cancel"}
        onCancel={() => onCancel()}
        onClose={() => setOpen("")}
      />
      <CloseOrderModal
        error={deleteError}
        open={open === "close"}
        loading={loading === "close"}
        closeOrder={() => closeOrder()}
        onClose={() => setOpen("")}
      />
      <DeleteModal
        value={t("component.orderHeader.warningDelete")}
        open={open === "delete"}
        onCancel={() => setOpen("")}
        onDelete={() => onDelete()}
      />

      <FlexContainer
        padding="0 0 1em 0"
        justifyContent="space-between"
        alignItems="center"
      >
        <FlexContainer>
          <BackLink
            label={t("component.orderHeader.back")}
            // onClick={() => history.push("/orders")}
            onClick={() => history.goBack()}
          />
        </FlexContainer>
        <ButtonsContainer
          padding="0 0 0 0 !important"
          justifyContent="flex-end"
        >
          <Button
            label={t("component.orderHeader.deleteOrder")}
            onClick={() => setOpen("delete")}
            theme="red"
          />
          <Button
            label={t("component.orderHeader.cancelOrder")}
            onClick={() =>
              data.state !== "cancelled" ? setOpen("cancel") : setOpen("")
            }
            theme={"orange"}
            disable={data.state === "cancelled"}
          />
          <Button
            label={t("component.orderHeader.closeOrder")}
            onClick={() => setOpen("close")}
            disable={data.state === "fulfilled"}
            theme="white"
          />
          <Tooltip title={t("component.orderHeader.share")}>
            <ShareIcon
              className={styles.shareIcon}
              onClick={() => setOpen("share")}
            />
          </Tooltip>
          <Tooltip title={t("component.orderHeader.sendToFlow")}>
            <SendIcon
              className={styles.flow}
              onClick={() => setOpen("flows")}
            />
          </Tooltip>

          <Tooltip title={t("component.orderHeader.bookmark")}>
            <BookMarIcon
              className={styles.shareIcon}
              onClick={() => markAsFavourite()}
            />
          </Tooltip>
          <Tooltip title={t("component.orderHeader.qrcode")}>
            <QRCodeIcon
              className={styles.shareIcon}
              onClick={() => setOpen("qrcode")}
            />
          </Tooltip>
        </ButtonsContainer>
      </FlexContainer>
      <div className={styles.headerContainer}>
        <FlexContainer
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Tooltip title={data.state}>
            <div className={styles.flex}>
              <StatusBadgePoint point status={data.state as StatusCatalog} />
              <ContainerTitle
                size="medium"
                padding="0 0 0 0.5em"
                label={`${t("component.orderHeader.order")} #${shortId}`}
              />
            </div>
          </Tooltip>
        </FlexContainer>

        <FlexContainer
          alignItems="flex-end"
          flexDirection="column"
          justifyContent="flex-end"
        >
          <ContainerTitle
            size="medium"
            label={`${t(
              "component.orderHeader.total"
            )} ${currency} ${numberFormat(totalAmount)}`}
          />

          <FlexContainer padding="0 0 0.5em 0">
            <div style={{ paddingRight: "0.5em" }}>
              <StatusBadgePoint
                status={
                  data.state === "cancelled" && data.paymentState !== "refunded"
                    ? data.state
                    : (data.paymentState as StatusCatalog)
                }
              />
            </div>
            {data.paymentDate ? (
              <> {formatDate(new Date(data.paymentDate), "MIDDLEWHOUR")}</>
            ) : null}
          </FlexContainer>
        </FlexContainer>
      </div>
    </div>
  );
};

export default OrderHeader;
