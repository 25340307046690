import React from "react";
import SideBarButton, { SideBarButtonProps } from "./SideBarButton";
import styles from "./styles";
import { ReactComponent as Shrink } from "../../assets/chevron-left.svg";
import { FlexContainer } from "../FlexContainer/FlexContainer";

export interface SideBarProps {
  options: (SideBarButtonProps | null)[];
  brand?: string;
  path?: string;
  resize?: boolean;
  setSize: (value: boolean) => void;
}

export function isButton(input: unknown): input is SideBarButtonProps {
  return (input as SideBarButtonProps)?.path !== undefined;
}

export const SideBar: React.FC<SideBarProps> = ({
  options,
  setSize,
  resize,
}) => {
  return (
    <div className={styles.leftBar}>
      <FlexContainer
        padding="1em 0 0 1em"
        width="100%"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Shrink
          className={resize ? styles.iconTransform : styles.icon}
          onClick={() => setSize(!resize)}
        />
      </FlexContainer>
      <div className={styles.options}>
        {options.map((option) => {
          if (isButton(option)) {
            return (
              <SideBarButton shrink={resize} key={option.id} data={option} />
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};
