import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Text from "../Text";
import styles from "./styles";
import { InputText } from "../Inputs/InputText";
import { InputError } from "../Inputs/InputError/InputError";
import { Modal } from "../Modal/Modal";

interface Props {
  onCancel: () => void;
  open: boolean;
  onSave: (name: string) => void;
  loading?: boolean;
  error?: string;
}

const FOPSetForm: React.FC<Props> = ({
  onCancel,
  onSave,
  open,
  error,
  loading,
}) => {
  const [name, setName] = useState("");
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Modal
        width="600px"
        buttons={
          <div>
            <ButtonsContainer justifyContent="flex-end">
              <Button
                onClick={onCancel}
                theme="white"
                label={t("component.fopSetForm.cancel")}
              />
              <Button
                onClick={() => onSave(name)}
                theme="blue"
                label={loading ? "..." : t("component.fopSetForm.save")}
              />
            </ButtonsContainer>
            {error ? (
              <FlexContainer justifyContent="flex-end" padding="0.5em 1em 0 0">
                <InputError error={error} />
              </FlexContainer>
            ) : null}
          </div>
        }
        open={open}
        onClose={onCancel}
      >
        <Text
          padding="1em 0 2em 0"
          text={t("component.fopSetForm.createNewFOP")}
          color="#000000"
          fontSize="1.4em"
          fontWeight={700}
        />
        <InputText
          label={t("component.fopSetForm.name")}
          value={name}
          onChange={(value) => setName(value)}
        />
      </Modal>
    </div>
  );
};

export default FOPSetForm;
