import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";

import styles from "./styles.module.css";
import {
  checkOrg,
  createTeam,
  CreateTeamParams,
  defaultEntity,
  defaultMaskingTemaplte,
} from "../../services/entities";
import EntityForm from "../../components/EntityForm";
import message from "antd/lib/message";
import CustomError from "../../utils/CustomError";
import APIError from "../../utils/APIError";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { MaskingTemplates } from "../../utils/types";
import { BackLink } from "../../components/BackLink";
import { ButtonsContainer } from "../../components/ButtonsContainer";
import { Button } from "../../components/Button";
import { EditableTitle } from "../../components/EditableTitle";
import { InputError } from "../../components/Inputs/InputError/InputError";

interface RouteParams {
  id: string;
}

interface Props extends RouteComponentProps<RouteParams> {}

const CreateEntity: React.FC<Props> = ({ history }) => {
  const [entity, setEntity] = useState(defaultEntity);
  const [maskingTemplate, setMasking] = useState<MaskingTemplates>(
    defaultMaskingTemaplte
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<CustomError>();

  const { t } = useTranslation();

  useEffect(() => {
    document.title = "RAVENT APP :: Create Org";
  });

  const onChange = <P extends keyof CreateTeamParams>(
    prop: P,
    value: CreateTeamParams[P]
  ) => setEntity({ ...entity, [prop]: value });

  const onCreate = async () => {
    setError(undefined);
    try {
      checkOrg(entity);
    } catch (err) {
      if (err instanceof CustomError) {
        setError(err);
      }
      return;
    }
    try {
      setLoading(true);
      await createTeam(entity, maskingTemplate);
      setLoading(false);
      message.success(t("container.createEntity.successCreation"));
      history.push("/teams");
      setError(undefined);
    } catch (err) {
      setLoading(false);
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };
  return (
    <LayouWithSideBar>
      <div className={styles.container}>
        <FlexContainer justifyContent="space-between" alignItems="center">
          <BackLink
            label={t("container.createEntity.back")}
            onClick={() => history.push("/teams")}
          />
          <ButtonsContainer>
            <Button
              theme="white"
              label={t("container.createEntity.cancel")}
              onClick={() => {
                setError(undefined);
                history.push("/teams");
              }}
            />
            <Button
              label={loading ? "..." : `${t("container.createEntity.save")}`}
              onClick={() => {
                if (loading) {
                  return;
                }
                onCreate();
              }}
            />
          </ButtonsContainer>
        </FlexContainer>

        <EditableTitle
          padding="1em 0 0em 0"
          placeholder={t("container.createEntity.name")}
          value={entity.name}
          onChange={(value) => onChange("name", value)}
          error={!entity.name && get(error, "errors.org-name", "")}
        />
        <InputError error={!entity.name && get(error, "errors.org-name", "")} />

        <EntityForm
          maskingTemplates={maskingTemplate}
          onChangeMaskingTemplate={(data) => setMasking(data)}
          error={error}
          onChange={onChange}
          entity={entity}
          editing={false}
        />
      </div>
    </LayouWithSideBar>
  );
};

export default CreateEntity;
