import React from "react";
import bike from "../../assets/assets/bike.svg";
import ferry from "../../assets/assets/ferry.svg";
import IOT from "../../assets/assets/IOT.svg";
import locker from "../../assets/assets/locker.svg";
import moped from "../../assets/assets/moped.svg";
import motorcycle from "../../assets/assets/motorcycle.svg";
import other from "../../assets/assets/other.svg";
import scooter from "../../assets/assets/scooter.svg";
import van from "../../assets/assets/VAN.svg";
import ship from "../../assets/assets/ship.svg";
import tools from "../../assets/assets/tools.svg";
import truck from "../../assets/assets/truck.svg";
import vehicle from "../../assets/assets/vehicle.svg";
import bus from "../../assets/assets/bus.svg";
import limo from "../../assets/assets/limo.svg";
import suv from "../../assets/assets/SUV.svg";

import Text from "../Text";
import { capitalize } from "lodash";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { Card } from "../Card/Card";
import { GridContainer } from "../GridContainer/GridContainer";
import InputRadio from "../Inputs/InputRadio";
import styles from "./styles";

interface Props {
  type: string;
  onClick: (value: string) => void;
  selectedType: string;
  label: string;
}

const TypeButton: React.FC<Props> = ({
  type,
  onClick,
  selectedType,
  label,
}) => {
  
  const typeIconMap: Record<string, string> = {
    vehicle: vehicle,
    van: van,
    truck: truck,
    moped: moped,
    motorcycle: motorcycle,
    scooter: scooter,
    bike: bike,
    ferry: ferry,
    ship: ship,
    IOT: IOT,
    locker: locker,
    tools: tools,
    other: other,
    bus: bus,
    limo: limo,
    suv: suv,
  };

  return (
    <Card containerClassname={styles.card}>
      <FlexContainer justifyContent="space-between" alignItems="center">
        <GridContainer alignItems="center" gap="1em" columns="52px 0.9fr">
          <img className={styles.iconBUtton} src={typeIconMap[type]} alt="" />
          <FlexContainer
            justifyContent="center"
            alignItems="flex-start"
            flexDirection="column"
          >
            <Text text={capitalize(label)} fontSize="1.1em" fontWeight={700} />
          </FlexContainer>
        </GridContainer>
        <InputRadio
          name="type"
          value={type}
          checked={selectedType === type}
          onChange={(value) => onClick(value)}
        />
      </FlexContainer>
    </Card>
  );
};

export default TypeButton;
