import React, { useState } from "react";

import { fopTitleMap } from "../../utils/fop";
import { useHistory } from "react-router";
import { BankAccount } from "../../utils/types";
import AccountForm from "../AccountList/AccountForm";
import { useTranslation } from "react-i18next";
import NotAssignedBox from "../OrderFulfillment/NotAssignedBox";
import { SideMenu } from "../SideMenu/SideMenu";
import { SectionProperty } from "../Section/SectionProperty";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";
import { FlexContainer } from "../FlexContainer/FlexContainer";

interface Props {
  open: boolean;
  id: string;
  onClose: () => void;
  integrations: number;
  integrationName?: string;
  integrationRequired: boolean;
  type: string;
  supplierName: string;
  supplierService: string;
  round: string;
  account: BankAccount;
  addAccount: (id: string) => void;
  loading: boolean;
  onChange: <P extends keyof BankAccount>(
    prop: P,
    value: BankAccount[P]
  ) => void;
  setOpen: () => void;
}

const FOPDetail: React.FC<Props> = ({
  onClose,
  open,
  integrationRequired,
  integrations,
  type,
  supplierService,
  supplierName,
  account,
  addAccount,
  integrationName,
  loading,
  onChange,
  id,
  round,
  setOpen,
}) => {
  const history = useHistory();
  const [form, setForm] = useState("");
  const { t } = useTranslation();

  return (
    <div>
      <AccountForm
        editing={false}
        loading={loading}
        onSave={async () => {
          try {
            await addAccount(id);
            setOpen();
            setForm("");
          } catch (err) {
            setForm("create");
          }
        }}
        onClose={() => {
          setForm("");
          setOpen();
        }}
        onChange={onChange}
        open={form === "create"}
        data={account}
      />
      <SideMenu width="55%" open={open} onClose={() => onClose()}>
        <FlexContainer
          alignItems="flex-start"
          justifyContent="space-between"
          padding="2em 0 2em 0"
        >
          <ContainerTitle size="medium" label={fopTitleMap[type]} />
        </FlexContainer>
        <ContainerTitle
          padding="0 0 1em 0"
          size="small"
          label={t("component.fopDetail.supplierInfo")}
        />
        <GridContainer gap="1em" columns="1fr 1fr">
          <SectionProperty
            label={t("component.fopDetail.supplierName")}
            value={supplierName}
          />
          <SectionProperty
            label={t("component.fopDetail.supplierService")}
            value={supplierService}
          />
          <SectionProperty
            label={t("component.fopDetail.round")}
            value={round || "-"}
          />
        </GridContainer>
        <ContainerTitle
          size="small"
          label={t("component.fopDetail.integrations")}
          padding="1em 0 1em 0"
        />
        <GridContainer gap="1em" columns="1fr 1fr">
          <SectionProperty
            label={t("component.fopDetail.intRequired")}
            value={integrationRequired ? "Yes" : "No"}
          />
          {integrationName ? (
            <SectionProperty
              label={t("component.fopDetail.intName")}
              value={integrationName}
            />
          ) : null}
        </GridContainer>

        {integrationRequired && integrations < 1 ? (
          <NotAssignedBox
            text={t("component.fopDetail.noInt")}
            onClick={() => {
              onClose();
              history.push("/integrations/orgs/new");
            }}
            button={t("component.fopDetail.createNewInt")}
          />
        ) : null}
        <ContainerTitle
          padding="1em 0"
          size="small"
          label={t("component.fopDetail.bankAccount")}
        />
        {account.bank ? (
          <GridContainer gap="1em" columns="1fr 1fr">
            <SectionProperty
              label={t("component.fopDetail.bankName")}
              value={account.bank}
            />
            <SectionProperty
              label={t("component.fopDetail.bankAccount")}
              value={account.bankAccount}
            />
          </GridContainer>
        ) : (
          <NotAssignedBox
            text={t("component.fopDetail.enterAccount")}
            onClick={() => {
              onClose();
              setForm("create");
            }}
            button={t("component.fopDetail.addAccount")}
          />
        )}
      </SideMenu>
    </div>
  );
};

export default FOPDetail;
