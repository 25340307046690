import { stylesheet } from "typestyle";
import { gray } from "../../constants/colors";

const style = stylesheet({
  container: {
    minHeight: "100vh",
  },
  box: {
    border: "1px solid #E6E9ED",
    padding: "1em",
    width: "80%",
    borderRadius: "8px",
    marginBottom: "2em",
    $nest: {
      "@media screen and (max-width: 1000px)": {
        width: "100%",
      },
    },
  },
  totalContainer: {
    display: "flex",
    justifyContent: "space-between",
  },

  boxButton: {
    border: "1px solid #E6E9ED",
    padding: "1em",
    width: "80%",
    borderRadius: "8px",
    textDecoration: "none",
    marginBottom: "2em",
    $nest: {
      "@media screen and (max-width: 1000px)": {
        width: "100%",
      },
    },
  },
  titleWrapper: {
    width: "80%",
    padding: "0 0 2em 0",
    $nest: {
      "@media screen and (max-width: 1000px)": {
        width: "100%",
      },
    },
  },
  button: {
    background: "#246DF1",
    color: "#fff",
    textTransform: "uppercase",
    padding: "1em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "48px",
    textDecoration: "none",
  },
  buttonContainer: {
    width: "100%",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    $nest: {
      "@media screen and (max-width: 1000px)": {
        display: "none",
      },
    },
  },
  orderDetails: {
    padding: "4em 2em",
    background: "#FAFBFC",
    height: "100vh",
    $nest: {
      "@media screen and (max-width: 1000px)": {
        height: "auto",
        padding: "1em",
      },
    },
  },
  orgDetails: {
    padding: "4em 2em",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    background: "#fff",
    height: "100vh",
    $nest: {
      "@media screen and (max-width: 1000px)": {
        height: "auto",
        padding: "2em 1em",
      },
    },
  },
  grid: {
    display: "grid",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gridTemplateColumns: "1fr 1.2fr",
    $nest: {
      "@media screen and (max-width: 1000px)": {
        gridTemplateColumns: "1fr",
        paddingBottom: "4em",
      },
    },
  },
  numberBox: {
    color: "#000",
    border: "1px solid #868686",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0.2em",
  },
  textItem: {
    color: "#000",
    fontSize: "16px",
    lineHeight: "22px",
  },
  textGray: {
    color: "#858D8F",
    fontSize: "15px",
  },
  itemContainer: {
    width: "100%",
    padding: "1em 0",
  },
  line: {
    borderBottom: "1px solid #DCE1E6",
    padding: "0.2em 0 0.5em 0",
    marginBottom: "1em",
  },
  total: {
    fontSize: "16px",
    fontWeight: 700,
    color: gray,
  },
  totalBig: {
    fontSize: "20px",
    fontWeight: 700,
  },
  totalBigM: {
    fontSize: "20px",
    fontWeight: 500,
  },
  textXLarge: {
    fontSize: "28px",
  },
  time: {
    width: "55px",
    marginRight: "1em",
  },
  chevron: {
    width: "24px",
  },
  payMobile: {
    borderTop: "1px solid #DCE1E6",
    padding: "1em 1em",
    position: "fixed",
    bottom: 0,
    background: "white",
    width: "100%",
    $nest: {
      "@media screen and (min-width: 1000px)": {
        display: "none",
      },
    },
  },
});

export default style;
