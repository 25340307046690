import React from "react";

import styles from "./styles";
import { VenuePaymentState } from "../../utils/types";
import { useTranslation } from "react-i18next";
import OrderStateList from "../OrderStateList";
import Text from "../Text";
import { black } from "../../utils/colors";
import { Card } from "../Card/Card";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { Button } from "../Button";

interface Props {
  paymentStates: VenuePaymentState;
  secretLastSet?: Date;
  secretLastSetBy?: string;
  secret?: string;
  onConfigureHash: (hash: string) => void;
  loading: string;
  edit: string;
  setEdit: (value: string) => void;
  onEdit: () => void;
}

const PaymentStates: React.FC<Props> = ({
  paymentStates,

  setEdit,
}) => {
  const { t } = useTranslation();

  const states = [
    {
      name: "unpaid",
      actionName: paymentStates.unpaid.actionName,
      automatic: paymentStates.unpaid.automatic,
      actionId: paymentStates.unpaid.actionId,
    },
    {
      name: "expired",
      actionName: paymentStates.expired.actionName,
      automatic: paymentStates.expired.automatic,
      actionId: paymentStates.expired.actionId,
    },
    {
      name: "partially paid",
      actionName: paymentStates.partial.actionName,
      automatic: paymentStates.partial.automatic,
      actionId: paymentStates.partial.actionId,
    },

    {
      name: "paid",
      actionName: paymentStates.paid.actionName,
      automatic: paymentStates.paid.automatic,
      actionId: paymentStates.paid.actionId,
    },
    {
      name: "refunded",
      actionName: paymentStates.refunded.actionName,
      automatic: paymentStates.refunded.automatic,
      actionId: paymentStates.refunded.actionId,
    },
  ];

  return (
    <div>
      <Card containerClassname={styles.wrapperCard}>
        <FlexContainer padding="0 0 1em 0" justifyContent="space-between">
          <ContainerTitle
            padding="0 0 0.5em 0"
            size="small"
            label={t("component.venueStates.paymentStates")}
          />
          <Button
            label={t("container.rIntegration.edit")}
            onClick={() => setEdit("payment-states")}
          />
        </FlexContainer>
        <Text
          textAlign="justify"
          padding="0 0 2em 0"
          color={black}
          text={t("component.venueStates.paymentURL")}
        />
        <OrderStateList data={states} />
      </Card>
    </div>
  );
};

export default PaymentStates;
