import { stylesheet } from "typestyle";
import { black, darkBlack, linkBlue } from "../../utils/colors";

const styles = stylesheet({
  text: {
    fontWeight: 600,
  },
  container: {
    width: "100%",
    borderRadius: "4px",
    margin: "1em 0",
  },
  locationsContainer: {
    padding: "0 0 2em 0",
  },
  contentContainer: {
    padding: "2em 0",
  },
  lock: {
    width: "18px",
    marginRight: '1em'
  },
  borderRight: {
    borderRight: "1px solid #DCE1E6",
    padding: "0 1em 0 0",
    marginRight: "1em",
  },
  notEnrollContainer: {
    background: "rgba(250, 173, 21, 0.1)",
    border: "1px solid #FAAD15",
    padding: "1.1em",
    borderRadius: "6px",
    margin: "1em 0 0 0",
  },
  instructions: {
    color: black,
    padding: "0.5em 0 0em 0",
    fontWeight: 400,
    fontSize: "1.1em",
  },
  listContainer: {
    padding: "1em",
    border: "1px solid #DCE1E6",
    borderRadius: 4,
  },
  definitionContainer: {
    padding: "1em",
    border: "1px solid #DCE1E6",
    borderRadius: 4,
    maxWidth: "30%",
  },
  options: {
    position: "relative",
    top: "calc(0em + 3px)",
    width: "100%",
    boxShadow: "0px 0px 2px 1px rgba(235, 235, 235, 1)",
    borderRadius: " 2",
    overflow: "hidden",
    scrollPaddingTop: "2em",
    overflowY: "scroll",
    maxHeight: "12em",
    display: "none",
    zIndex: 3,
  },
  option: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    padding: "0.5em",
    height: "2.5em",
    background: "#fff",
    color: "#333",
    $nest: {
      "&:hover": {
        backgroundColor: "#dedede",
      },
    },
  },
  select: {
    cursor: "pointer",
    position: "relative",
  },
  chevron: {
    width: "1em",
    color: linkBlue,
    marginLeft: "1em",
  },
  chevronGray: {
    width: "1em",
    color: "#858d8f",
    marginLeft: "1em",
  },
  inputSelect: {
    minWidth: "13em",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  margin: {
    marginRight: "1em",
  },

  infoGray: {
    fontSize: "1em",
    fontWeight: 400,
    color: "#858d8f",
    paddingBottom: "0.2em",
  },
  propertyInstructions: {
    padding: "0.5em 0 1.5em 0",
    textAlign: "justify",
  },
  infoBlue: {
    fontSize: "1em",
    fontWeight: 400,
    color: linkBlue,
    paddingBottom: "0.2em",
  },
  valueText: {
    fontSize: "1.1em",
    fontWeight: 500,
    color: darkBlack,
    marginLeft: "0.2em",
  },
  icon: {
    width: "2em",
    marginLeft: "0.5em",
    cursor: "pointer",
  },
  iconEmpty: {
    width: "90px",
  },
});

export default styles;
