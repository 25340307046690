import { OfferSet } from "@innomius/ravent-typescript-types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { offerSetList as offerSetListService } from "../services/offersets";

type OfferSetsSliceState = {
  loading: boolean;
  error: string;
  list: OfferSet[];
};

const initialState: OfferSetsSliceState = {
  loading: true,
  error: "",
  list: [],
};

export const offerSetsList = createAsyncThunk("offerSets/list", async () => {
  const response = await offerSetListService();
  return response;
});

const offerSetsSlice = createSlice({
  name: "offerSets",
  initialState,
  reducers: {
    addOfferSet(state, action: PayloadAction<OfferSet>) {
      state.list.unshift(action.payload);
    },
    updateOfferSet(state, action: PayloadAction<OfferSet>) {
      state.list = state.list.map((current) =>
        current.id === action.payload.id ? action.payload : current
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(offerSetsList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(offerSetsList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.list = action.payload;
    });
    builder.addCase(offerSetsList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An unexpected error ocurred";
    });
  },
});

export const { addOfferSet, updateOfferSet } = offerSetsSlice.actions;

export default offerSetsSlice.reducer;
