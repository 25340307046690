import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { ContainerTitle } from "../ContainerTitle";

import DeleteModal from "../DeleteModal";
import { useTranslation } from "react-i18next";
import { CreateTeamParams } from "../../services/entities";
import messageIcon from "../../assets/message.svg";
import styles from "./styles";
import SendMessage from "../EntityList/SendMessage";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { BackLink } from "../BackLink";
import { EditableTitle } from "../EditableTitle";

interface EntityHeaderProps {
  path: string;
  onCancel: () => void;
  loading: string;
  onDelete: () => void;
  edit: boolean;
  onEdit: () => void;
  setEdit: (value: boolean) => void;
  name: string;
  onChange: <P extends keyof CreateTeamParams>(
    prop: P,
    value: CreateTeamParams[P]
  ) => void;
}

const EntityHeader: React.FC<EntityHeaderProps> = ({
  loading,
  edit,
  onDelete,
  onEdit,
  setEdit,
  name,
  path,
  onChange,
}) => {
  const history = useHistory();
  const [open, setOpen] = useState("");
  const { t } = useTranslation();

  return (
    <div>
      <SendMessage
        onSend={() => {}}
        open={open === "chat"}
        onClose={() => setOpen("")}
      />
      <DeleteModal
        open={open === "delete"}
        loading={loading === "delete"}
        value={t("component.entityHeader.deleteWarning")}
        onCancel={() => setOpen("")}
        onDelete={async () => {
          try {
            await onDelete();
            setOpen("");
            history.push("/teams");
          } catch (err) {}
        }}
      />

      <FlexContainer
        padding="0em 0 0 0"
        justifyContent="space-between"
        alignItems="center"
      >
        <BackLink
          label={t("component.entityHeader.back")}
          onClick={() => history.push("/teams")}
        />
        {edit ? (
          <ButtonsContainer>
            <Button
              theme="white"
              label={t("component.entityHeader.cancel")}
              onClick={() => setEdit(false)}
            />
            <Button
              label={t("component.entityHeader.save")}
              onClick={() => onEdit()}
            />
          </ButtonsContainer>
        ) : (
          <ButtonsContainer>
            <Button
              theme="white"
              label={t("component.entityHeader.sendMessage")}
              icon={messageIcon}
              onClick={() => setOpen("chat")}
            />
            <Button
              theme="red"
              label={
                loading === "delete"
                  ? "..."
                  : t("component.entityHeader.delete")
              }
              onClick={() => setOpen("delete")}
            />
            <Button
              label={
                loading === "edit" ? "..." : t("component.entityHeader.edit")
              }
              onClick={() => {
                if (loading === "edit") {
                  return;
                }
                setEdit(true);
                history.push(path);
              }}
            />
          </ButtonsContainer>
        )}
      </FlexContainer>
      {edit ? (
        <div className={styles.titleContainer}>
          <EditableTitle
            value={name}
            onChange={(value) => onChange("name", value)}
          />
        </div>
      ) : (
        <ContainerTitle padding="1em 0 0 0" size="medium" label={name} />
      )}
    </div>
  );
};

export default EntityHeader;
