import { InputLabel } from "../InputLabel/InputLabel";
import { InputError } from "../InputError/InputError";
import { Popover } from "antd";
import React from "react";
import { classes } from "typestyle";
import DeletePopUp from "../../DeletePopUp";
import styles from "./styles.module.css";
import { ButtonsContainer } from "../../ButtonsContainer";
import { Tag } from "../../Tag/Tag";

export interface InputTagsProps {
  label?: string;
  value?: string[];
  error?: false | "" | string[] | string;
  onChange?: (tags: string[]) => void;
  showLabel?: boolean;
  showError?: boolean;
  placeholder?: string;
  height?: string;
}

interface InputTagsState {
  text: string;
  open: string;
  focus: boolean;
  color: string;
  openColor: string;
  isTagValid: boolean; // New state property for tag validation feedback
}

export class InputTags extends React.Component<InputTagsProps, InputTagsState> {
  input = React.createRef<HTMLInputElement>();

  constructor(props: InputTagsProps) {
    super(props);
    this.state = {
      text: "",
      open: "",
      focus: false,
      color: "",
      openColor: "",
      isTagValid: true, // New state property for tag validation feedback
    };
  }

  componentDidMount() {
    this.input.current?.addEventListener("paste", this.handlePaste);
  }

  isValidTag = (text: string) => {
    const regex = new RegExp(
      // eslint-disable-next-line no-useless-escape
      /^([a-zA-Z0-9áéíóúÁÉÍÓÚñÑ][a-zA-Z0-9áéíóúÁÉÍÓÚñÑ._&@%$+\-]*$)|(^[a-zA-Z0-9áéíóúÁÉÍÓÚñÑ_&@%$+\-]+::[a-zA-Z0-9áéíóúÁÉÍÓÚñÑ][a-zA-Z0-9áéíóúÁÉÍÓÚñÑ._&@%$+\-]*$)/
    );
    return regex.test(text);
  };

  componentWillUnmount() {
    this.input.current?.removeEventListener("paste", this.handlePaste);
  }

  handlePaste = (event: ClipboardEvent) => {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData?.getData("text");

    if (pastedText) {
      event.preventDefault();
      const currentValue = this.props.value || [];
      this.props.onChange?.([...currentValue, pastedText.trim()]);
    }
  };
  onChange = (value: string) => {
    this.setState({
      text: value,
      isTagValid: this.isValidTag(value), // Validate as the user types
    });
  };
  onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { text } = this.state;

    // Check for space, enter, or comma key presses
    if (e.key === " " || e.key === "Enter" || e.key === ",") {
      if (text && this.isValidTag(text)) {
        const currentValue = this.props.value || [];
        this.props.onChange?.([...currentValue, text]); // Add the tag if valid
      } else {
        console.log("Invalid tag format"); // Handle invalid tag scenario
      }

      // Clear the text input after adding a tag or handling invalid input
      this.setState({ text: "" });
      e.preventDefault(); // Prevent the default behavior of the key press (e.g., adding a space)
    }
  };

  onRemoveItem = (index: number) => {
    if (this.props.value) {
      this.props.onChange?.(this.props.value.filter((_a, i) => i !== index));
    }
  };

  onOpen = (index: string) => {
    this.setState({ open: index.toString() });
    return;
  };

  onOpenColor = (index: string) => {
    this.setState({ openColor: index.toString() });
    return;
  };

  focusInput = () => {
    this.input.current?.focus();
  };
  render() {
    const { value, label, showLabel, showError, error, placeholder, height } =
      this.props;
    return (
      <div className={styles.container} onClick={this.focusInput}>
        {label ? <InputLabel label={label} showLabel={showLabel} /> : null}
        <div
          onFocus={(e) => {
            this.setState({ focus: true });
          }}
          onBlur={(e) => {
            this.setState({ focus: false });
          }}
          style={{ height: height }}
          className={classes(styles.wrapper, {
            [styles.wrapperFocused]: this.state.focus,
          })}
        >
          <ButtonsContainer
            justifyContent="flex-start"
            flexWrap="wrap"
            itemsPadding="0 .5em .5em 0"
          >
            {value?.map((item, i) => (
              <div key={item + i}>
                <Popover
                  placement="topLeft"
                  content={
                    <div onClick={() => this.setState({ open: i.toString() })}>
                      <DeletePopUp
                        value={`Are you sure you want to delete ${item} tag?`}
                        onClose={(e) => {
                          e.stopPropagation();
                          this.setState({ open: "delete" });
                        }}
                        onDelete={(e) => {
                          e.stopPropagation();
                          this.onRemoveItem(i);
                          this.setState({ open: "" });
                        }}
                      />
                    </div>
                  }
                  trigger="click"
                  open={this.state.open === i.toString()}
                  onOpenChange={() => this.setState({ open: this.state.open })}
                />

                <Tag
                  size="medium"
                  key={i}
                  label={item}
                  onClose={() => this.onOpen(i.toString())}
                  theme="blue"
                  onChangeColor={() => this.onOpenColor(i.toString())}
                />
              </div>
            ))}
            <input
              className={classes(styles.inputText, {
                [styles.invalid]: !this.state.isTagValid, // Optional invalid class for styling
              })}
              ref={this.input}
              value={this.state.text}
              onKeyDown={this.onKeyDown} // Use onKeyDown instead of onKeyPress
              onChange={(e) => this.onChange(e.target.value)} // Handle text change
              placeholder={value ? "" : placeholder}
            />
          </ButtonsContainer>
          {error ? <InputError error={error} showError={showError} /> : null}
        </div>
      </div>
    );
  }
}
