import React, { useState } from "react";
import { onCopy, paymentTypeIconMap } from "../../utils/payment";
import { formatDate } from "../../utils/times";
import { ReactComponent as Share } from "../../assets/share.svg";
import copy from "../../assets/copy.svg";
import SendModal from "../SendModal";
import { Payment } from "@innomius/ravent-typescript-types";
import RequestPaymentDetail from "../RequestPaymentDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { normalGray } from "../../utils/colors";
import { numberFormat } from "../../utils/format";
import { message, Tooltip } from "antd";
import { downloadRequestReceipe } from "../../services/pdfs";
import { sendMessage } from "../../services/messaging";
import styles from "./styles";
import APIError from "../../utils/APIError";
import { useTranslation } from "react-i18next";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { StatusBadgePoint } from "../StatusBadgePoint/StatusBagePoint";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";
import { FlexContainer } from "../FlexContainer/FlexContainer";

interface RequestProps {
  data: Payment;
  onIssueInvoice: (id: string) => void;
  contactEmail: string;
  contactPhone: string;
  shortId: string;
  description: string;
  onDelete: (id: string) => void;
  cancelled?: boolean;
}

const RequestItem: React.FC<RequestProps> = ({
  data,
  onIssueInvoice,
  contactEmail,
  contactPhone,
  shortId,
  onDelete,
  cancelled,
}) => {
  const [open, setOpen] = useState("");
  const [chevron, setChevron] = useState(false);
  const [loading, setLoading] = useState("");
  const { t } = useTranslation();

  return (
    <ListRow
      greyedOut={cancelled}
      onClick={() => {
        setChevron(!chevron);
        setOpen(chevron ? "" : "details");
      }}
      showDetail={open === "details"}
      detail={
        <RequestPaymentDetail
          onDelete={() => onDelete(data.id)}
          data={data}
          onClose={() => {
            setOpen("");
            setChevron(!chevron);
          }}
        />
      }
      template="0.8fr 1.2fr 1.5fr 1.5fr 0.7fr 1fr"
      hover={true}
      key={data.id}
    >
      {cancelled ? (
        <ListLabel fontFormat="bold" value={data.shortId} textAlign="left" />
      ) : (
        <div className={styles.normal}>
          <FontAwesomeIcon
            icon={
              chevron ? (faChevronUp as IconProp) : (faChevronDown as IconProp)
            }
            className={styles.icon}
            color={normalGray}
          />
          {data.shortId}
        </div>
      )}

      <div className={styles.iconContainer}>
        <img
          className={styles.icon}
          src={paymentTypeIconMap[data.paymentType]}
          alt=""
        />
        {data.paymentType}
      </div>
      <ListLabel value={formatDate(data.created, "MIDDLE")} textAlign="left" />
      <ListLabel
        value={formatDate(data.expiration, "MIDDLE") || "-"}
        textAlign="left"
      />
      <ListLabel
        value={`${data.amount.currency} ${numberFormat(data.amount.value)}`}
        textAlign="center"
      />
      <FlexContainer justifyContent="space-evenly">
        {cancelled ? (
          <StatusBadgePoint status="cancelled" />
        ) : (
          <>
            <Tooltip title={t("component.requestList.share")}>
              <Share
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen("share");
                }}
                className={styles.shareContainer}
              />
            </Tooltip>
            <Tooltip title={t("component.requestList.copy")}>
              <img
                src={copy}
                alt=""
                onClick={(e) => {
                  e.stopPropagation();
                  onCopy(data, shortId, "Leon Felipe");
                  message.success(
                    `${
                      data.paymentType === "in-store"
                        ? "Reference copied to clipboard"
                        : "Link copied to clipboard"
                    }`
                  );
                  setOpen("");
                }}
                className={styles.copyContainer}
              />
            </Tooltip>
            <SendModal
              title={`Request ${
                data.paymentType === "in-store"
                  ? "Reference"
                  : data.paymentType === "payment-link"
                  ? "Link"
                  : ""
              }`}
              onDownload={async (lang) => {
                setLoading("download");
                await downloadRequestReceipe(
                  data.orderId,
                  lang,
                  "paymentRequest",
                  data.id,
                  false
                );
                setLoading("");
              }}
              onOpenPdf={async (lang) => {
                setLoading("open");
                await downloadRequestReceipe(
                  data.orderId,
                  lang,
                  "paymentRequest",
                  data.id,
                  true
                );
                setLoading("");
              }}
              loading={loading === "send"}
              loadingDownload={loading === "download" || loading === "open"}
              contactPhone={contactPhone}
              contactEmail={contactEmail}
              id={data.id}
              onSend={async (
                medium: string,
                contact: string,
                language: string
              ) => {
                try {
                  setLoading("send");
                  await sendMessage(
                    data.orderId,
                    language,
                    medium,
                    contact,
                    "paymentRequest",
                    data.id
                  );
                  message.success(
                    `Payment ${
                      data.paymentType === "in-store" ? "Reference" : "Link"
                    } successfully via ${medium} shared.`
                  );
                  setOpen("");
                  setLoading("");
                } catch (err) {
                  setLoading("");
                  if (err instanceof APIError) {
                    message.error(`${err.message}: ${err.messages}`);
                  }
                }
              }}
              onCancel={() => setOpen("")}
              open={open === "share"}
            />
          </>
        )}
      </FlexContainer>
    </ListRow>
  );
};

export default RequestItem;
