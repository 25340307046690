import React, { useState } from "react";
import brand from "../../assets/logo.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import Header from "../Header";
import { ReactComponent as Overview } from "../../assets/sidebar/overview.svg";
import { ReactComponent as Orders } from "../../assets/sidebar/orders.svg";
import { ReactComponent as Events } from "../../assets/sidebar/cal.svg";
import { ReactComponent as User } from "../../assets/sidebar/user.svg";
import { ReactComponent as Locations } from "../../assets/sidebar/location.svg";

import { ReactComponent as Fulfillment } from "../../assets/sidebar/states.svg";
import { ReactComponent as Tickets } from "../../assets/sidebar/certificates.svg";
import { ReactComponent as Integrations } from "../../assets/sidebar/integration.svg";
import { ReactComponent as Payments } from "../../assets/sidebar/payment.svg";
import { ReactComponent as Assets } from "../../assets/sidebar/assets.svg";
import { ReactComponent as Venues } from "../../assets/sidebar/venue-1.svg";
import { ReactComponent as Organizations } from "../../assets/sidebar/fulfiller.svg";

import { SideBar } from "../SideBar";
import { logout } from "../../services/auth";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

interface Props {
  children: React.ReactNode | React.ReactNode[];
  shrink?: boolean;
  overflowY?: string;
}

const SectionWithSideBar: React.FC<Props> = ({
  children,
  shrink,
  overflowY,
}) => {
  const auth = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();
  const [open, setUserMenuOpen] = useState(false);
  const [resize, setSize] = useState(shrink);
  const from = dayjs().startOf("month").format("YYYY-MM-DD");
  const to = dayjs().endOf("month").format("YYYY-MM-DD");
  const path = `/orders?from=${from}&to=${to}`;

  const options = [
    {
      id: "overview",
      label: "Overview",
      path: "/overview",
      icon: <Overview className={styles.icon} />,
    },
    {
      id: "orders",
      label: t("component.layoutWithSideBar.orders"),
      path: path,
      icon: <Orders className={styles.icon} />,
    },
    {
      id: "events",
      label: t("component.layoutWithSideBar.events"),
      // path: `/events?from=${dayjs(new Date()).format("YYYY-MM-DD")}&to=${dayjs(
      //   new Date()
      // ).format("YYYY-MM-DD")}`,
      path: `/events`,
      icon: <Events className={styles.icon} />,
    },
    {
      id: "certificates",
      label: t("component.layoutWithSideBar.certificates"),
      path: `/passes`,
      icon: <Tickets className={styles.icon} />,
    },
    {
      id: "fulfillment",
      label: t("component.layoutWithSideBar.fulfillment"),
      path: `/fulfillments`,
      icon: <Fulfillment className={styles.icon} />,
    },

    {
      id: "organizations",
      label: t("component.layoutWithSideBar.orgs"),
      path: "/teams",
      icon: <Organizations className={styles.icon} />,
    },

    {
      id: "users",
      label: t("component.layoutWithSideBar.users"),
      path: "/users",
      icon: <User className={styles.icon} />,
    },

    {
      id: "assers",
      label: t("component.layoutWithSideBar.assets"),
      path: "/assets",
      icon: <Assets className={styles.icon} />,
    },
    {
      id: "locations",
      label: t("component.layoutWithSideBar.locations"),
      path: "/venueLocations",
      icon: <Locations className={styles.icon} />,
    },

    {
      id: "venue",
      label: t("component.layoutWithSideBar.venues"),
      path: "/venues",
      icon: <Venues className={styles.icon} />,
    },

    {
      id: "payments",
      label: t("component.layoutWithSideBar.payments"),
      path: "/payments",
      icon: <Payments className={styles.icon} />,
    },
    {
      id: "integrations",
      label: t("component.layoutWithSideBar.integrations"),
      path: `/integrations/actions`,
      icon: <Integrations className={styles.icon} />,
    },
  ];

  return (
    <div>
      <Header
        open={open}
        onClickOutside={() => setUserMenuOpen(false)}
        setUserMenuOpen={() => setUserMenuOpen(!open)}
        refresh={auth.user?.refreshTokenParsed || null}
        access={auth.user?.accessTokenParsed || null}
        onLogout={() => logout(auth.user?.refreshToken || "")}
      />
      <div
        onClick={() => setUserMenuOpen(false)}
        className={resize ? styles.shrinkContainer : styles.container}
      >
        <SideBar
          setSize={setSize}
          resize={resize}
          brand={brand}
          options={options}
        />
        <div className={styles.containerLayout}>
          <div
            style={{
              overflowY:
                (overflowY as React.CSSProperties["overflowY"]) || "auto",
            }}
            className={styles.content}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionWithSideBar;
