import Offers from "./OfferSets/i18n";
import OfferSetDetail from "./OfferSetDetail/i18n";
import CreateOfferSet from "./CreateOfferSet/i18n";
import Venues from "./Venues/i18n";
import VenueDetail from "./VenueDetail/i18n";
import Users from "./Users/i18n";
import UserDetail from "./UserDetail/i18n";
import Settings from "./Settings/i18n";
import ResetPassword from "./ResetPassword/i18n";
import Orders from "./Orders/i18n";
import Login from "./Login/i18n";
import Integrations from "./Integrations/i18n";
import OrderDetail from "./OrderDetail/i18n";
import GlobalPreferences from "./GlobalPreferences/i18n";
import FulfillmentDetail from "./FulfillmentDetail/i18n";
import FOPSets from "./FOPSets/i18n";
import FOPSetDetail from "./FOPSetDetail/i18n";
import FOPs from "./FOPs/i18n";
import EntityDetail from "./EntityDetail/i18n";
import Entities from "./Entities/i18n";
import EnrollUser from "./EnrollUser/i18n";
import CreateVenue from "./CreateVenue/i18n";
import CreateUser from "./CreateUser/i18n";
import CreateFulfillment from "./CreateFulfillment/i18n";
import CreateEntity from "./CreateEntity/i18n";
import SetPassword from "./SetPassword/i18n";
import ProfileDetail from "./ProfileDetail/i18n";
import Dashboard from "./Dashboard/i18n";
import TicketingDefDetail from "./PassDefDetail/i18n";
import CreateTicketingDef from "./CreatePassDef/i18n";
import Fulfillment from "./Fulfillment/i18n";
import RIntegration from "./RIntegration/i18n";
import CreateOrder from "./CreateOrderC/i18n";
import Assets from "./Assets/i18n";
import Certificates from "./Certificates/i18n";
import Events from "./Events/i18n";
import MyCompany from "./MyCompany/i18n";
import CreateRol from "./CreateRol/i18n";
import RoleDetail from "./RoleDetail/i18n";


const translation = {
  en: {
    roleDetail: RoleDetail.en,
    createRol: CreateRol.en,
    myCompany: MyCompany.en,
    events: Events.en,
    assets: Assets.en,
    createOrder: CreateOrder.en,
    fulfillment: Fulfillment.en,
    certificates: Certificates.en,
    rIntegration: RIntegration.en,
    createTicketingDef: CreateTicketingDef.en,
    ticketingDefDetail: TicketingDefDetail.en,
    dashboard: Dashboard.en,
    offers: Offers.en,
    offerDetail: OfferSetDetail.en,
    createOfferSet: CreateOfferSet.en,
    venues: Venues.en,
    venueDetail: VenueDetail.en,
    users: Users.en,
    userDetail: UserDetail.en,
    settings: Settings.en,
    resetPassword: ResetPassword.en,
    orders: Orders.en,
    login: Login.en,
    integrations: Integrations.en,
    orderDetail: OrderDetail.en,
    global: GlobalPreferences.en,
    fulfillmentDetail: FulfillmentDetail.en,
    fopsets: FOPSets.en,
    fopsetsDetail: FOPSetDetail.en,
    fops: FOPs.en,
    entityDetail: EntityDetail.en,
    entities: Entities.en,
    enrollUser: EnrollUser.en,
    createVenue: CreateVenue.en,
    createUser: CreateUser.en,
    createFulfillment: CreateFulfillment.en,
    createEntity: CreateEntity.en,
    setPassword: SetPassword.en,
    profileDetail: ProfileDetail.en,
  },
  es: {
    roleDetail: RoleDetail.es,
    createRol: CreateRol.es,
    myCompany: MyCompany.es,
    events: Events.es,
    assets: Assets.es,

    createOrder: CreateOrder.es,

    fulfillment: Fulfillment.es,
    certificates: Certificates.es,
    rIntegration: RIntegration.es,
    createTicketingDef: CreateTicketingDef.es,
    ticketingDefDetail: TicketingDefDetail.es,
    offers: Offers.es,
    dashboard: Dashboard.es,
    offerDetail: OfferSetDetail.es,
    createOfferSet: CreateOfferSet.es,
    venues: Venues.es,
    venueDetail: VenueDetail.es,
    users: Users.es,
    userDetail: UserDetail.es,
    settings: Settings.es,
    resetPassword: ResetPassword.es,
    orders: Orders.es,
    login: Login.es,
    integrations: Integrations.es,
    orderDetail: OrderDetail.es,
    global: GlobalPreferences.es,
    fulfillmentDetail: FulfillmentDetail.es,
    fopsets: FOPSets.es,
    fopsetsDetail: FOPSetDetail.es,
    fops: FOPs.es,
    entityDetail: EntityDetail.es,
    entities: Entities.es,
    enrollUser: EnrollUser.es,
    createVenue: CreateVenue.es,
    createUser: CreateUser.es,
    createFulfillment: CreateFulfillment.es,
    setPassword: SetPassword.es,
    profileDetail: ProfileDetail.es,
  },
  de: {
    createRol: CreateRol.de,
    myCompany: MyCompany.de,
    events: Events.de,
    assets: Assets.de,
    createOrder: CreateOrder.de,
    fulfillment: Fulfillment.de,
    certifictes: Certificates.de,
    rIntegration: RIntegration.de,
    createTicketingDef: CreateTicketingDef.de,
    ticketingDefDetail: TicketingDefDetail.de,
    offers: Offers.de,
    dashboard: Dashboard.de,
    OfferSetDetail: OfferSetDetail.de,
    createOfferSet: CreateOfferSet.de,
    venues: Venues.de,
    venueDetail: VenueDetail.de,
    users: Users.de,
    userDetail: UserDetail.de,
    settings: Settings.de,
    resetPassword: ResetPassword.de,
    orders: Orders.de,
    login: Login.de,
    integrations: Integrations.de,
    orderDetail: OrderDetail.de,
    global: GlobalPreferences.de,
    fulfillmentDetail: FulfillmentDetail.de,
    fopsets: FOPSets.de,
    fopsetsDetail: FOPSetDetail.de,
    fops: FOPs.de,
    entityDetail: EntityDetail.de,
    entities: Entities.de,
    enrollUser: EnrollUser.de,
    createVenue: CreateVenue.de,
    createUser: CreateUser.de,
    createFulfillment: CreateFulfillment.de,
    setPassword: SetPassword.de,
    profileDetail: ProfileDetail.de,
  },
};

export default translation;
