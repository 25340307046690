import React, { useState } from "react";
import { OrderListSkeleton } from "../ListSkeletons";
import { useTranslation } from "react-i18next";
import { ReactComponent as ViewIcon } from "../../assets/View.svg";
import { ReactComponent as ShareIcon } from "../../assets/shareGray.svg";

import styles from "./styles";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";
import { StatusBadgePoint } from "../StatusBadgePoint/StatusBagePoint";
import { StatusCatalog } from "../StatusBadge";
import SendPass from "../SendModal/SendPass";
import OrderPassModal from "./OrderPassModal";
import { Pass } from "../../utils/types";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { List } from "../List";

interface Props {
  data: Pass[];
  onViewDetails: (pass: Pass) => void;
}

const PassList: React.FC<Props> = ({ data, onViewDetails }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState("");
  const [details, setDetails] = useState("");

  const header = (
    <div>
      <ListHeader template={"1fr 1fr 1fr 0.5fr 0.5fr"}>
        <ListHeaderLabel
          value={t("component.orderPasses.passId")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderPasses.level")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderPasses.name")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderPasses.state")}
          justifyContent="flex-start"
        />
        <div />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <List
        loadingElement={() => <OrderListSkeleton />}
        data={data}
        keyExtractor={(item) => item._id}
        renderHeader={() => header}
        renderItem={(item) => (
          <>
            {details === item._id && (
              <OrderPassModal
                data={item}
                open={details === item._id}
                onClose={() => setDetails("")}
              />
            )}
            {open === item._id && (
              <SendPass
                onSend={() => {}}
                open={open === item._id}
                id="pass"
                title={t("component.orderPasses.sharePass")}
                onCancel={() => setOpen("")}
              />
            )}

            <ListRow template={"1fr 1fr 1fr 0.5fr 0.5fr"} background="#fff">
              <ListLabel
                value={item.shortId || item._id || "-"}
                textAlign="left"
              />
              <ListLabel
                value={item.ownership_level.toString()}
                textAlign="left"
              />
              <ListLabel value={item.displayName.toString()} textAlign="left" />
              <div className={styles.center}>
                <StatusBadgePoint status={item.state as StatusCatalog} />
              </div>

              <div className={styles.spaceBetween}>
                <ViewIcon
                  className={styles.icon}
                  onClick={(e) => {
                    e.stopPropagation();
                    onViewDetails(item);
                    setDetails(item._id);
                  }}
                />
                <ShareIcon
                  className={styles.iconView}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen(item._id);
                  }}
                />
              </div>
            </ListRow>
          </>
        )}
      />
    </div>
  );
};

export default PassList;
