import React, { useState } from "react";
import { AddInvoiceRequest, FormComponent } from "../../utils/types";

import styles from "./styles";
import { useTranslation } from "react-i18next";
import SchemaForm from "./SchemaForm";
import { message } from "antd";
import { SideMenu } from "../SideMenu/SideMenu";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { Button } from "../Button";
import InputRadio from "../Inputs/InputRadio";
import { InputText } from "../Inputs/InputText";
import { GridContainer } from "../GridContainer/GridContainer";

interface Props {
  onCancel: () => void;
  open: boolean;
  defaultErpId: string;
  invoiceSchemaComponents: FormComponent[];
  onChangeSchema: (data: FormComponent[]) => void;
  loading: boolean;
  onIssueInvoice: (customer: AddInvoiceRequest) => void;
  webhook: string;
  invoiceIssuance: string;
  paymentState: string;
}

const InvoiceForm: React.FC<Props> = ({
  open,
  onCancel,
  defaultErpId,
  invoiceSchemaComponents,
  onChangeSchema,
  onIssueInvoice,
  loading,
  invoiceIssuance,
  paymentState,
  webhook,
}) => {
  const { t } = useTranslation();

  const defaultInvoiceRequest: AddInvoiceRequest = {
    taxId: "",
    legalName: "",
    zipCode: "",
    erpId: defaultErpId,
  };
  const [invoice, setInvoice] = useState(defaultInvoiceRequest);
  const [erp, setErpForm] = useState("erp");

  const onChange = <P extends keyof AddInvoiceRequest>(
    prop: P,
    value: AddInvoiceRequest[P]
  ) => {
    setInvoice({ ...invoice, [prop]: value });
  };

  return (
    <SideMenu width="50%" open={open} onClose={() => onCancel()}>
      <div className={styles.container}>
        <FlexContainer padding="1em 0 2em 0" justifyContent="space-between">
          <ContainerTitle
            label={t("component.invoiceForm.issueInvoice")}
            size="medium"
          />

          <FlexContainer justifyContent="flex-start">
            <Button
              onClick={() => {
                onCancel();
              }}
              containerClassname={styles.button}
              theme="white"
              label={t("component.invoiceForm.cancel")}
            />
            <Button
              onClick={() => {
                if (!webhook) {
                  message.error(
                    "The venue to which you are associated has not been properly configured to issue a manual invoice. Please go to the Integrations Side Menu, open the Invoices tab and insert a valid URL for the Invoice Issuance entry."
                  );
                  return;
                }
                onIssueInvoice(invoice);
              }}
              theme="blue"
              disable={
                paymentState === "unpaid" && invoiceIssuance !== "payment"
              }
              label={loading ? "..." : t("component.invoiceForm.issue")}
            />
          </FlexContainer>
        </FlexContainer>

        <ContainerTitle
          label={t("component.invoiceForm.customer")}
          size="small"
        />

        <FlexContainer padding="0 0 1.5em 0" justifyContent="flex-start">
          <div className={styles.radioContainer}>
            <InputRadio
              name="formType"
              checked={erp === "erp"}
              value={erp}
              text={t("component.invoiceForm.useERP")}
              onChange={() => setErpForm("erp")}
            />
          </div>
          <div className={styles.radioContainer}>
            <InputRadio
              name="formType"
              checked={erp === "customer"}
              value={erp}
              text={t("component.invoiceForm.customer")}
              onChange={() => setErpForm("customer")}
            />
          </div>
        </FlexContainer>

        {erp === "erp" ? (
          <div>
            <InputText
              label={t("component.invoiceForm.erpClientId")}
              value={invoice.erpId}
              onChange={(value) => onChange("erpId", value)}
            />
          </div>
        ) : (
          <div>
            <GridContainer gap="1em" columns="1fr 1fr">
              <InputText
                label={t("component.invoiceForm.legalName")}
                value={invoice.legalName}
                onChange={(value) => onChange("legalName", value)}
              />
              <InputText
                label={t("component.invoiceForm.taxIdentifier")}
                value={invoice.taxId}
                onChange={(value) => onChange("taxId", value)}
              />
              <InputText
                label={t("component.invoiceForm.zipCode")}
                value={invoice.zipCode}
                onChange={(value) => onChange("zipCode", value)}
              />
            </GridContainer>
          </div>
        )}

        <FlexContainer
          alignItems="flex-start"
          justifyContent="space-between"
          padding="1em 0 1em 0"
        >
          <ContainerTitle
            label={t("component.invoiceForm.invoice")}
            size="small"
          />
        </FlexContainer>
        <SchemaForm
          elements={invoiceSchemaComponents}
          onChange={(elements) => onChangeSchema(elements)}
        />
      </div>
    </SideMenu>
  );
};

export default InvoiceForm;
