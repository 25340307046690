import React from "react";

import { useTranslation } from "react-i18next";
import OrderActionList from "../OrderActionList";
import styles from "../VenueStates/styles";
import { Action } from "@innomius/ravent-typescript-types";
import CreateAction from "../OrderActionList/CreateAction";
import { useHistory } from "react-router-dom";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { Card } from "../Card/Card";
import { ContainerTitle } from "../ContainerTitle";
import { Button } from "../Button";
import { EmptyList } from "../List/EmptyList";
import { Spin } from "antd";

interface Props {
  states: Action[];
  onAddIntegration: (data: Action) => void;
  loading: string;
  loadingActions: string;
  error: string;
  onDeleteState: (id: string) => void;
  onSaveAction: (data: Action) => void;
}

const IntAction: React.FC<Props> = ({
  states,
  onAddIntegration,
  onDeleteState,
  onSaveAction,
  loading,
  loadingActions,
}) => {
  const { t } = useTranslation();

  const defaultAction: Action = {
    id: "",
    name: "",
    dataCollectionUrl: "",
    url: "",
    description: "",
    orderOnly: false,
    notificationBody: "",
    manualTrigger: false,
  };

  const history = useHistory();

  return (
    <>
      <CreateAction data={defaultAction} onSaveAction={onAddIntegration} />

      <Card containerClassname={styles.wrapperCard}>
        <FlexContainer padding="0 0 1em 0" justifyContent="space-between">
          <ContainerTitle
            size="small"
            label={t("component.venueStates.actions")}
          />
          <Button
            label={t("component.venueStates.addAction")}
            onClick={() => history.push("/integrations/actions/new")}
          />
        </FlexContainer>

        {loading ? (
          <div
            style={{
              height: "20em",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : states.length ? (
          <OrderActionList
            loading={loading}
            onDeleteAction={onDeleteState}
            update={onSaveAction}
            loadingActions={loadingActions}
            onDelete={onDeleteState}
            onAddState={onAddIntegration}
            data={states}
          />
        ) : !loading ? (
          <EmptyList
            instructions=""
            text={t("container.integrations.noIntegrations")}
          />
        ) : null}
      </Card>
    </>
  );
};

export default IntAction;
