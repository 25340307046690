import React from "react";
import { formatDate } from "../../utils/times";
import { WebHookNotifications } from "../../utils/types";
import styles from "./styles";
import { Tag } from "../Tag/Tag";

interface Props {
  data: WebHookNotifications;
  clickUser: (userId: string) => void;
}


const WebHookEvent: React.FC<Props> = ({ data, clickUser }) => {
  return (
    <div key={data.id}>
      <>
        <div>
          <p className={styles.bold}>{`${data.event.replace("_", " ")}`}</p>
          <p>{formatDate(data.eventTimestamp)}</p>
          <div>
            <p>Type: {data.type}</p>
            <Tag
              padding="0 2em"
              size="medium"
              label={data.responseStatusCode.toString()}
              theme={
                data.responseStatusCode >= 200 && data.responseStatusCode < 400
                  ? "green"
                  : "red"
              }
            />
          </div>
          <p
            className={styles.cursor}
            onClick={() => clickUser(data.createdBy)}
          >
            By: {data.createdBy}
          </p>
          <p>URL: {data.url}</p>
        </div>
      </>
    </div>
  );
};

export default WebHookEvent;
