import React, { useState } from "react";

import { Fulfiller } from "@innomius/ravent-typescript-types";
import { useTranslation } from "react-i18next";
import DiverStateModal from "../FulfillerStateModal";
import { Checkbox, Switch } from "antd";
import Text from "../Text";
import { Role } from "../../services/roles";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputText } from "../Inputs/InputText";
import { InputTags } from "../Inputs/InputTags";
import { CheckboxValueType } from "antd/es/checkbox/Group";

interface Props {
  onSave: (data: Fulfiller) => Promise<void>;
  onCancel: () => void;
  edit?: boolean;
  error?: string;
  fulfiller: Fulfiller;
  loading: boolean;
  roles: Role[];
  onChange: <P extends keyof Fulfiller>(prop: P, value: Fulfiller[P]) => void;
}

const FulfillersForm: React.FC<Props> = ({
  onSave,
  onCancel,
  edit,
  loading,
  roles,
  fulfiller,
  onChange,
}) => {
  const [open, setOpen] = useState("");
  const [error, setError] = useState("");

  const options = roles.map((role) => ({ label: role.name, value: role.id }));

  const { t } = useTranslation();

  return (
    <div>
      <DiverStateModal
        disable={fulfiller.active ? true : false}
        onClose={() => setOpen("")}
        open={open === "disable"}
        username={fulfiller.username}
      />
      <FlexContainer justifyContent="space-between" padding="1em 0 2em 0">
        {edit ? (
          <ContainerTitle
            size="medium"
            label={t("component.diversForm.editDiver")}
          />
        ) : (
          <ContainerTitle
            size="medium"
            label={t("component.diversForm.createDiver")}
          />
        )}

        <ButtonsContainer alignItems="center" justifyContent="flex-end">
          <Button
            label={t("component.diversForm.cancel")}
            onClick={() => {
              onCancel();
            }}
            theme="white"
          />
          <Button
            label={loading ? "..." : t("component.diversForm.save")}
            onClick={async () => {
              if (loading) {
                return;
              }

              setError("");
              try {
                await onSave(fulfiller);
                onCancel();
              } catch (e) {}
            }}
          />
        </ButtonsContainer>
      </FlexContainer>

      <FlexContainer justifyContent="space-between">
        {edit ? (
          <ContainerTitle
            padding="0 0 1em 0"
            size="small"
            label={t("component.diversForm.info")}
          />
        ) : (
          <ContainerTitle
            padding="0 0 1em 0"
            size="small"
            label={t("component.diversForm.info")}
          />
        )}
        <FlexContainer alignItems="center" justifyContent="flex-start">
          <div style={{ marginRight: "1em" }}>
            <InputLabel label={t("component.diversForm.status")} />
          </div>
          <Switch
            checked={fulfiller.disabled}
            onChange={(checked) => onChange("disabled", checked)}
          />
        </FlexContainer>
      </FlexContainer>

      <GridContainer gap="1em" columns="0.5fr 1fr 1fr">
        <InputText
          id={`externalId`}
          placeholder={t("component.diversForm.externalId")}
          value={fulfiller.externalId}
          onChange={(value) => onChange("externalId", value)}
          label={t("component.diversForm.externalId")}
        />

        <InputText
          id={`name`}
          placeholder={t("component.diversForm.firstName")}
          value={fulfiller.firstName}
          onChange={(value) => onChange("firstName", value)}
          label={t("component.diversForm.firstName")}
        />
        <InputText
          id={`lastName`}
          placeholder="Last Name"
          value={fulfiller.lastName}
          onChange={(value) => onChange("lastName", value)}
          label={t("component.diversForm.lastName")}
        />
      </GridContainer>

      {edit ? null : (
        <Checkbox
          checked={fulfiller.loginWithPhone}
          onChange={(checked) =>
            onChange("loginWithPhone", checked.target.checked)
          }
        >
          <Text text={t("component.userForm.loginWithPhone")} />
        </Checkbox>
      )}

      <GridContainer padding="1em 0 0 0" gap="1em" columns="1fr 1fr">
        <InputText
          id={`email`}
          placeholder="email@org.com"
          value={fulfiller.email}
          onChange={(value) => onChange("email", value)}
          label={t("component.diversForm.email")}
          error={error && !fulfiller.email ? error : ""}
        />

        <InputText
          id={`phoneNumber`}
          placeholder="+49xxxx"
          value={fulfiller.phone}
          onChange={(value) => onChange("phone", value)}
          label={t("component.diversForm.phone")}
          error={error && !fulfiller.phone ? error : ""}
        />
      </GridContainer>

      <div style={{ paddingBottom: "1em" }}>
        <InputTags
          label={t("component.assetForm.tags")}
          value={fulfiller.tags}
          onChange={(value) => onChange("tags", value)}
        />
      </div>

      <ContainerTitle
        padding="1em 0 1em 0"
        size="small"
        label={t("component.diverDetail.role")}
      />

      <Checkbox.Group
        options={options}
        value={fulfiller.roles}
        onChange={(checkedValues: CheckboxValueType[]) =>
          onChange("roles", checkedValues as string[])
        }
      />
      <div style={{ paddingBottom: "3em" }} />
    </div>
  );
};

export default FulfillersForm;
