import React from 'react';
import { classes } from 'typestyle';
import styles from './styles.module.css';
import { InputText } from '../Inputs/InputText';

export interface Props {
  value?: string;
  placeholder?: string;
  onChange?: (value: string, id?: string) => void;
  titleClassname?: string;
  padding?: string;
  error?: false | '' | string[] | string;
}

export const EditableTitle: React.FC<Props> = ({ value, placeholder, onChange, titleClassname, padding, error }) => {
  return (
    <InputText
      padding={padding}
      value={value}
      placeholder={placeholder}
      wrapperClassname={error ? styles.wrapperError : styles.wrapperFocused}
      wrapperFocusClassname={styles.wrapperFocused}
      inputClassname={classes(styles.inputText, titleClassname)}
      onChange={onChange}
      noEtiquets
    />
  );
};
