const translation = {
  en: {
    successPayment: "Payment succesfully registered.",
    successComment: "Comment succesfully send.",
    passLabel: "Passes",
    successRemoveComment: "Comment succesfully removed.",
    successRequest: "Payment succesfully requested.",
    successCancelled: "Order successfully cancelled.",
    successDeleted: "Order succesfully deleted.",
    successRequestDeleted: "Payment Request succesfully deleted.",
    detailsLabel: "Details",
    paymentsLabel: "Payments",
    fulfillmentLabel: "States",
    historyLabel: "Actions",
    successTag: "Tags successfully updated.",
    successDiveshop: "Fulfiller Team successfully updated.",
    successFulfillment: "Fulfillment successfully updated.",
    stateSuccess: "State was sucessfully activated",
    successAddLocation: "Locatios was added successfully",
    successDeleteLocation: "Location was deleted successfully",
    successDiver: "Fulfiller was successfully set",
    locationLabel: "Events",
    certificateLabel: "Passes",
    successOrderUpdate: "Order was successfully updated",
    teamsLabel: "Teams",
    eventsLabel: "Events",
    successClosed: "Order was successfully fulfilled",
    successAddOrderline: "Orderline was successfully added",
    successClosedOrderline: "Orderline was successfully marked as completed",
    successUpdated: "Customer was successfully updated",
    successEventUpdate: "Event was successfully updated",
    successEvent: "Event was successfully created",
    successAsset: "Asset was successfully assigned",
    scheduled: "scheduled",
    sendEventToFlow: "Send event to flow",
    event: "Event",
    sendAll: "Send all events to flow",
    relatedEvents: "Related Events",
  },
  es: {
    relatedEvents: "Eventos Relacionados",
    sendAll: "Enviar todos los eventos al flujo",
    event: "Evento",
    passLabel: "Passes",
    sendEventToFlow: "Enviar evento a flujo",
    scheduled: "calendarizada",
    successEvent: "Evento se creo con éxito",
    successAsset: "Activo fue asignado con éxito",

    successEventUpdate: "Evento fue actualizado con éxito",
    successUpdated: "La información del cliente fue actualizada con éxito",
    eventsLabel: "Eventos",
    successOrderUpdate: "Orden fue actualizada con éxito",
    teamsLabel: "Equipos de Entrega",
    successPayment: "Pago registrado con éxito",
    successDiver: "Cumplidor se agrego éxitosamente",
    successAddLocation: "Se agrego éxitosamente la localidad",
    successComment: "Comentario enviado con éxito.",
    successRemoveComment: "Comenatario eliminado con éxito.",
    successRequest: "Pago solicitado con éxito",
    successCancelled: "Orden cancelado con éxito.",
    successDeleted: "Orden eliminado con éxito.",
    successRequestDeleted: "Solicitud de pago eliminada con éxito.",
    detailsLabel: "Detalles",
    paymentsLabel: "Pagos",
    fulfillmentLabel: "Estados",
    historyLabel: "Acciones",
    successTag: "Etiquetas actualizadas con éxito.",
    successDiveshop: "Fulfiller Team fue actualizado con éxito.",
    successFulfillment: "Entrega actualizado con éxito",
    stateSuccess: "Estado fue activado con éxito",
    successDeleteLocation: "La ubicación se eliminó con éxito",
    locationLabel: "Eventos",
    certificateLabel: "Pases",
    successClosed: "Orden fue cerrada éxitosamente",
    successAddOrderline: "Elemento se agrego éxitosamente",
    successClosedOrderline: "Elemento completado con éxito",
  },
  de: {},
};

export default translation;
