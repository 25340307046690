import React, { useState } from "react";

import { Offer } from "@innomius/ravent-typescript-types";
import styles from "./styles";
import OfferDetailForm from "../OfferForm/OfferDetailForm";
import { useTranslation } from "react-i18next";
import { SectionProperty } from "../Section/SectionProperty";
import { GridContainer } from "../GridContainer/GridContainer";
import { Button } from "../Button";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import InputRadio from "../Inputs/InputRadio";
import { Tag } from "../Tag/Tag";

interface Props {
  data: Offer;
  onChange: <P extends keyof Offer>(prop: P, value: Offer[P]) => void;
}

const Detail: React.FC<Props> = ({ data, onChange }) => {
  const [edit, setEdit] = useState(false);

  const { t } = useTranslation();

  return (
    <div>
      {edit ? (
        <OfferDetailForm
          edit={edit}
          data={data}
          onEdit={() => setEdit(false)}
          onChange={onChange}
          onCancel={() => setEdit(false)}
        />
      ) : (
        <>
          <FlexContainer justifyContent="space-between">
            <ContainerTitle
              padding="1em 0 0 0"
              label={t("component.offerVariantDetail.details")}
              size="xsmall"
            />
            <Button
              theme="white"
              label={t("component.offerVariantDetail.editButtonLabel")}
              onClick={() => setEdit(true)}
            />
          </FlexContainer>
          <GridContainer padding="2em 0 0 0" gap="1em" columns="3fr 1fr">
            <SectionProperty
              label={t("component.offerVariantDetail.labelDescription")}
              value={data.description}
            />
            <img
              src={data.image}
              className={styles.imageContainer}
              alt="offerImage"
            />
            <SectionProperty
              label={t("component.offerVariantDetail.location")}
              value={data.locationId}
            />
          </GridContainer>
          <div className={styles.line} />
          <ContainerTitle
            padding="0 0 0 0"
            label={t("component.offerVariantDetail.availability")}
            size="xsmall"
          />
          <FlexContainer padding="1.5em 0 1em 0" justifyContent="flex-start">
            <InputRadio
              name="availability"
              value={data.availability}
              checked={data.availability === "free-sell"}
              text={t("component.offerVariantDetail.freeSell")}
              containerClassname={styles.radioContainer}
            />
            <InputRadio
              name="availability"
              value={data.availability}
              checked={data.availability === "confirmation-required"}
              text={t("component.offerVariantDetail.confirmationDiveShop")}
            />
          </FlexContainer>

          <div className={styles.line} />
          <ContainerTitle padding="0 0 1em 0" size="xsmall" label="Tags" />
          <FlexContainer
            alignItems="center"
            justifyContent="flex-start"
            flexWrap="wrap"
          >
            {data.tags?.map((item) => (
              <div className={styles.label}>
                <Tag theme="blue" label={item} />
              </div>
            ))}
          </FlexContainer>
        </>
      )}
    </div>
  );
};
export default Detail;
