import { stylesheet } from "typestyle";

const styles = stylesheet({
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
  modalContainer: {
    padding: "0 1em",
  },
  selectContainer: {
    width: "50%",
  },
  icon: {
    width: "30px",
    cursor: "pointer",
  },
  container: {
    $nest: {
      "div > div": {
        alignItems: "center !important",
      },
    },
  },
});

export default styles;
