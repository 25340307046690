import React from "react";
import styles from "./styles.module.css";

export interface Props {
  text: string;
  padding?: string;
  fontSize?: string;
  color?: string;
  fontWeight?: number;
  textAlign?: string;
  onClick?: () => void;
  hover?: boolean;
}

const Text: React.FC<Props> = ({
  text,
  padding,
  fontSize,
  color,
  fontWeight,
  onClick,
  hover,
}) => {
  return (
    <div onClick={onClick}>
      <p
        style={{
          padding,
          fontSize,
          color,
          fontWeight,
        }}
        className={
          hover ? styles.textWHover : onClick ? styles.textClicked : styles.text
        }
      >
        {text}
      </p>
    </div>
  );
};

export default Text;
