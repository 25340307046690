import React, { useState } from "react";
import Text from "../Text";
import { Modal } from "../Modal/Modal";

import styles from "./styles";

import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import Projection from "./Projection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { linkBlue } from "../../utils/colors";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";

interface Props {
  onClose: () => void;
  open: boolean;
  data: any[];
  loading: boolean;
  onExport: () => void;
  count: number;
  all: boolean;
  inputString: string;
  setExcludedIds: (values: string[]) => void;
}

const ExportCVSFile: React.FC<Props> = ({
  onClose,
  open,
  loading,
  data,
  onExport,
  count,
  all,
  inputString,
  setExcludedIds,
}) => {
  const { t } = useTranslation();
  const [seeMore, setSee] = useState<boolean>(false);

  return (
    <div className={styles.container}>
      <Modal
        width={"70%"}
        buttons={
          <div>
            <ButtonsContainer justifyContent="flex-end">
              <Button
                onClick={() => {
                  onClose();
                }}
                theme="white"
                label={t("component.exportCVSFile.close")}
              />
              {loading ? (
                <FlexContainer>
                  <Spin />
                </FlexContainer>
              ) : (
                <Button
                  onClick={() => {
                    if (loading) {
                      return;
                    }
                    onExport();
                  }}
                  label={loading ? "..." : t("component.exportCVSFile.export")}
                />
              )}
            </ButtonsContainer>
          </div>
        }
        open={open}
        onClose={onClose}
      >
        <div className={styles.modalContainer}>
          <div>
            <Text
              padding="0em 0 1em 0"
              text={t("component.exportCVSFile.exportFile")}
              color="#000000"
              fontSize="1.4em"
              fontWeight={700}
            />
            <div onClick={() => setSee(!seeMore)}>
              <FlexContainer padding="0em 0 1em 0" justifyContent="flex-start">
                <Text
                  text={`${t("component.exportCVSFile.rows")}: ${
                    all ? count : data.length
                  }`}
                  color="#000000"
                  fontSize="1em"
                  fontWeight={700}
                />
                {all ? null : (
                  <div className={styles.iconContainer}>
                    <FontAwesomeIcon
                      color={linkBlue}
                      icon={
                        !seeMore
                          ? (faChevronDown as IconProp)
                          : (faChevronUp as IconProp)
                      }
                    />
                  </div>
                )}
              </FlexContainer>
            </div>

            {all ? null : seeMore ? (
              <div
                style={{ height: data.length < 2 ? 100 : 200 }}
                className={styles.scroll}
              >
                {data.map((val: any, i: number) => {
                  return (
                    <ListRow key={i} template="0.2fr 1fr">
                      <ListLabel textAlign="left" value={(i + 1).toString()} />
                      <ListLabel textAlign="left" value={val.name} />
                    </ListRow>
                  );
                })}
              </div>
            ) : null}
            <div
              style={{
                height: seeMore ? 200 : 400,
                marginTop: seeMore ? "1em" : 0,
              }}
              className={styles.wrapper}
            >
              <Projection
                setExcludedIds={setExcludedIds}
                inputString={inputString}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ExportCVSFile;
