import { stylesheet } from "typestyle";
import { linkBlue, yellowUI } from "../../utils/colors";

const styles = stylesheet({
  itemsWrapper: {
    padding: "1em 0",
  },
  content: {
    background: "#F8F8F8",
    height: "30vh",
    margin: "1em 0",
    padding: "1em",
    overflowY: "scroll",
    width: "100%",
  },
  noMessages: {
    background: "#F8F8F8",
    height: "35vh",
    margin: "1em 0",
    padding: "1em",
    width: "100%",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bluePoint: {
    marginLeft: "0.5em",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    background: linkBlue,
  },
  yellowPoint: {
    marginLeft: "0.5em",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    background: yellowUI,
  },
  lastAnswer: {
    marginLeft: "0.5em",
  },
});

export default styles;
