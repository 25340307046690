import React, { useCallback, useEffect, useState } from "react";
import { Asset, Certificate } from "../../utils/types";
import { useTranslation } from "react-i18next";
import FulfillmentHistoryList from "../OrderFulfillment/FulfillmentHistoryList";
import {
  FulfillmentDefinition,
  FulfillmentState,
  FulfillmentEvent,
  Placement,
  Load,
  OrderLine,
} from "@innomius/ravent-typescript-types";
import lock from "../../assets/lock.svg";

import FulfilmentStatus from "../OrderFulfillment/FulfilmentStatus";
import styles from "./styles";
import OrderlineEvent from "../OrderEvent/OrderlineEvent";
import { Route, useLocation } from "react-router-dom";
import ChangeModal from "../OrderFulfillment/ChangeModal";
import AssignFulfiller from "../OrderFulfillmentComponent/AssignFulfillment";
import {
  defaultFulfillment,
  listFulfillmentStates,
  readFulfillment,
} from "../../services/fulfilments";
import APIError from "../../utils/APIError";
import ErrorBox from "../ErrorBox";
import TabNavBar from "../TabNavBar";
import { StatusBadgePoint } from "../StatusBadgePoint/StatusBagePoint";
import { StatusCatalog } from "../StatusBadge";
import dayjs from "dayjs";
import { listEvents } from "../../services/events";
import { message } from "antd";
import { SideMenu } from "../SideMenu/SideMenu";
import Text from "../Text";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { EmptyList } from "../List/EmptyList";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputSelect } from "../Inputs/InputSelect";

interface OrderlineDetailProps {
  open: boolean;
  onClose: () => void;
  orderlineDescription: string;
  orderlineDetails: string;

  id: string;
  updateTeam: (id: string, teamId: string) => void;
  teams: { label: string; value: string }[];
  fulfillmentDefinitionId: string;
  onCreateEvent: (location: FulfillmentEvent) => void;
  onEditEvent: (location: FulfillmentEvent) => void;
  onDeleteEvent: (id: string, orderlineId: string) => void;
  updateFulfillmentDefinition: (id: string, definitionId: string) => void;
  fulfillmentDefintions: { label: string; value: string }[];
  errorFulfillment: string;
  fulfillmentState: string;
  orderState: string;
  setState: (id: string, name: string, w3w: string, images: string[]) => void;
  ticketDefinitions: { label: string; value: string }[];
  certificates: Certificate[];
  path: string;
  locationLevel: string;
  fulfillmentLevel: string;
  fulfillmentDate: string;
  orderlineState: string;
  onEditPlacement: (event: FulfillmentEvent, placement: Placement) => void;
  onDeletePlacement: (event: FulfillmentEvent, id: string) => void;
  assets: Asset[];
  updateAsset: (event: FulfillmentEvent, id: string) => void;
  onSaveLoad: (eventId: string, data: Load[]) => void;
  updateFulfiller: (
    eventId: string,
    fulfillerId: string,
    orderlineId: string
  ) => void;
  orderlines: OrderLine[];
  saveLoc: (p: Placement, type: string, group: string) => void;
}

const OrderlineDetail: React.FC<OrderlineDetailProps> = ({
  orderlineDescription,
  onClose,
  updateTeam,
  teams,
  id,
  updateFulfillmentDefinition,
  fulfillmentDefintions,
  fulfillmentDefinitionId,
  fulfillmentState,
  setState,
  onCreateEvent,
  onDeleteEvent,
  updateFulfiller,
  path,
  ticketDefinitions,
  certificates,
  fulfillmentLevel,
  fulfillmentDate,
  orderlineState,
  onEditEvent,
  onDeletePlacement,
  onEditPlacement,
  assets,
  onSaveLoad,
  updateAsset,
  orderlineDetails,
  orderlines,
  saveLoc,
}) => {
  const [open, setOpen] = useState(false);
  const [defError, setDefError] = useState("");
  const [fEvents, setEvents] = useState<FulfillmentEvent[]>([]);
  const [fulfillmentHistoric, setFulfillmentHistoric] = useState<
    FulfillmentState[]
  >([]);

  const [fulfillmentDefinition, setFulfilment] =
    useState<FulfillmentDefinition>(defaultFulfillment);

  const location = useLocation();

  const loadStates = useCallback(async () => {
    try {
      const res = await listFulfillmentStates({
        orderlineId: id,
      });
      setFulfillmentHistoric(res.data);
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`States Fetch Error: ${err.messages}`);
      }
    }
  }, [id]);

  useEffect(() => {
    if (location.pathname === `${path}/${id}/states`) {
      loadStates();
    }
  }, [loadStates, id, location.pathname, path]);

  const loadEvents = useCallback(async () => {
    try {
      const res = await listEvents({
        orderlineId: id,
      });
      setEvents(res.data);
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`Events Fetch Error: ${err.messages}`);
      }
    }
  }, [id]);

  useEffect(() => {
    if (location.pathname === `${path}/${id}/events`) {
      loadEvents();
    }
  }, [loadEvents, id, location.pathname, path]);

  const load = useCallback(async () => {
    try {
      const res = await readFulfillment(fulfillmentDefinitionId);
      setFulfilment(res);
    } catch (err) {
      if (err instanceof APIError) {
        setDefError(err.message);
      }
    }
    try {
      const res = await listEvents({
        orderlineId: id,
      });
      setEvents(res.data);
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`Events Fetch Error: ${err.messages}`);
      }
    }
  }, [fulfillmentDefinitionId, id]);

  useEffect(() => {
    if (
      fulfillmentDefinitionId &&
      location.pathname === `${path}/${id}/states`
    ) {
      load();
    }
  }, [load, fulfillmentDefinitionId, path, id, location.pathname]);

  const { t } = useTranslation();

  const statesTab = {
    id: "lineStates",
    label: t("component.orderlineDetail.states"),
    path: `${path}/${id}/states`,
  };

  const eventTabs = {
    id: "lineEvents",
    label: t("component.orderlineDetail.events"),
    path: `${path}/${id}/events`,
  };

  const sortedDescData = fulfillmentHistoric?.sort(
    (objA, objB) =>
      Number(dayjs(objB.timestamp, "SSS")) -
      Number(dayjs(objA.timestamp, "SSS"))
  );

  //CAMBIAR UPDATE TEAM FUNCTION

  return (
    <>
      <SideMenu
        width="90%"
        onClose={() => {
          onClose();
        }}
        open={
          location.pathname === `${path}/${id}/states` ||
          location.pathname === `${path}/${id}/events` ||
          location.pathname.includes(`${path}/${id}/events`) ||
          location.pathname === `${path}/${id}/teams` ||
          location.pathname === `${path}/${id}/tickets`
        }
      >
        <ChangeModal
          error=""
          fulfillmentNames={fulfillmentDefintions}
          updateFulfillmentName={(name) =>
            updateFulfillmentDefinition(id, name)
          }
          onClose={() => setOpen(false)}
          open={open}
        />

        <FlexContainer
          padding="1em 0 1em 0"
          flexDirection="column"
          alignItems="flex-start"
        >
          <FlexContainer
            padding="0 0 0.5em 0"
            justifyContent="flex-start"
            alignItems="center"
          >
            {fulfillmentLevel === "orderline" ? (
              <StatusBadgePoint
                point
                status={orderlineState as StatusCatalog}
              />
            ) : null}
            <ContainerTitle
              padding={
                fulfillmentLevel === "orderline" ? "0 0.5em 0 0.5em" : "0 0 0 0"
              }
              size="medium"
              label={orderlineDescription}
            />
          </FlexContainer>
          <FlexContainer justifyContent="flex-start" alignItems="center">
            <img src={lock} className={styles.lock} alt="" />

            <Text text={orderlineDetails} />
          </FlexContainer>
        </FlexContainer>

        <div>
          <TabNavBar
            margin="1em 0 0 0"
            options={
              fulfillmentLevel === "orderline" ? [eventTabs, statesTab] : []
            }
          >
            <Route path={`${path}/${id}/states`}>
              <GridContainer gap="1em" columns="3fr 1.2fr">
                <div className={styles.borderRight}>
                  <div className={styles.contentContainer}>
                    {defError ? (
                      <>
                        <ErrorBox error={defError} />
                      </>
                    ) : fulfillmentDefinition.id ? (
                      <>
                        <div>
                          <FulfilmentStatus
                            error=""
                            fulfillmentNames={fulfillmentDefintions}
                            updateFulfillmentName={(name) =>
                              updateFulfillmentDefinition(id, name)
                            }
                            orderlineId={id}
                            fulfillmentDate={fulfillmentDate}
                            passed={fulfillmentHistoric.map(
                              (item: any) => item.name
                            )}
                            id={fulfillmentDefinitionId}
                            orderStates={
                              fulfillmentDefinition.fulfillmentStatesDefinitions
                            }
                            lastState={sortedDescData[0]?.name}
                            setState={(name, w3w, images) =>
                              setState(id, name, w3w, images)
                            }
                            status={fulfillmentDefinition.fulfillmentStatesDefinitions.map(
                              (item) => item.name
                            )}
                            onFulfilled={() => {}}
                          />
                          <div className={styles.contentContainer}>
                            <ContainerTitle
                              size="small"
                              label={t("component.orderlineDetail.history")}
                              padding="0 0 1em 0"
                            />
                            {fulfillmentHistoric.length ? (
                              <div>
                                <FulfillmentHistoryList
                                  error=""
                                  current={fulfillmentState}
                                  loading={false}
                                  data={fulfillmentHistoric}
                                />
                              </div>
                            ) : (
                              <EmptyList
                                text={t(
                                  "component.orderlineDetail.noStateActivated"
                                )}
                                instructions=" "
                              />
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <ContainerTitle
                          label={t("component.orderFulfillment.states")}
                          size="small"
                          padding="0 0 1em 0"
                        />
                        <EmptyList
                          instructions=""
                          text={t(
                            "component.orderFulfilmentComponent.noDefinition"
                          )}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div>
                  {fulfillmentDefinitionId ? (
                    <div style={{ paddingTop: "1em" }}>
                      <AssignFulfiller
                        onClick={() => setOpen(true)}
                        type={fulfillmentDefinition.type}
                        name={fulfillmentDefinition.name}
                      />
                    </div>
                  ) : (
                    <div style={{ padding: "1em 0em 0 1em" }}>
                      <ContainerTitle
                        size="small"
                        label={t(
                          "component.orderFulfilmentComponent.fulfillmentState"
                        )}
                        padding="1em 0 1em 0em"
                      />
                      <div className={styles.instructions}>
                        {t("component.orderFulfilmentComponent.instructions")}
                      </div>
                      {!fulfillmentDefintions.length ? (
                        <div className={styles.notEnrollContainer}>
                          {t(
                            "component.orderFulfilmentComponent.noDefinitions"
                          )}
                        </div>
                      ) : (
                        <InputSelect
                          placeholder={t(
                            "component.orderFulfilmentComponent.select"
                          )}
                          options={fulfillmentDefintions}
                          onChange={(value) =>
                            updateFulfillmentDefinition(id, value)
                          }
                        />
                      )}
                    </div>
                  )}
                </div>
              </GridContainer>
            </Route>
            <Route path={`${path}/${id}/events`}>
              <OrderlineEvent
                path={`${path}/${id}/events`}
                id={id}
                flows={[]}
                orderlines={orderlines}
                fulfillmentLevel={fulfillmentLevel}
                updateFulfiller={updateFulfiller}
                updateTeam={updateTeam}
                teams={teams}
                saveLoc={saveLoc}
                onSaveLoad={onSaveLoad}
                updateAsset={updateAsset}
                assets={assets}
                onDeletePlacement={onDeletePlacement}
                onEditPlacement={onEditPlacement}
                onEditEvent={onEditEvent}
                events={fEvents}
                onCreateEvent={onCreateEvent}
                orderlineDescription={orderlineDescription}
                onDeleteEvent={(i) => onDeleteEvent(i, id)}
              />
            </Route>

            {/* <Route path={`${path}/${id}/tickets`}>
              <OrderlineTicket
                issueCertificate={() => {}}
                certificates={certificates}
                orderlineId={id}
                errorModal=""
                definitions={ticketDefinitions}
                definitionId={""}
                uppdateOrderlineDefinition={() => {}}
              />
            </Route> */}
          </TabNavBar>
        </div>
      </SideMenu>
    </>
  );
};

export default OrderlineDetail;
