import { stylesheet } from "typestyle";

export interface ButtonTheme {
  backgroundColor: string;
  backgroundColorHover: string;
  textColor: string;
  textColorHover: string;
  borderColor: string;
  borderColorHover: string;
  disabledBackgroundColor: string;
  disabledBorderColor: string;
  disabledTextColor: string;
  textDecoration?: string;
}

export const blueColors: ButtonTheme = {
  backgroundColor: "#1fa0ff",
  backgroundColorHover: "#0091fa",
  textColor: "#fff",
  textColorHover: "#fff",
  borderColor: "#1fa0ff",
  borderColorHover: "#0091fa",
  disabledBackgroundColor: "#dce1e6",
  disabledBorderColor: "#dce1e6",
  disabledTextColor: "#999999",
};

export const whiteColors: ButtonTheme = {
  backgroundColor: "#fff",
  backgroundColorHover: "#dce1e6",
  textColor: "#333",
  textColorHover: "#333",
  borderColor: "#dce1e6",
  borderColorHover: "#dce1e6",
  disabledBackgroundColor: "#dce1e6",
  disabledBorderColor: "#dce1e6",
  disabledTextColor: "#999999",
};

export const orangeColors: ButtonTheme = {
  backgroundColor: "#fff",
  backgroundColorHover: "#FF7832",
  textColor: "#FF7832",
  textColorHover: "#fff",
  borderColor: "#FF7832",
  borderColorHover: "#FF7832",
  disabledBackgroundColor: "#dce1e6",
  disabledBorderColor: "#dce1e6",
  disabledTextColor: "#999999",
};

export const linkColors: ButtonTheme = {
  backgroundColor: "#fff",
  backgroundColorHover: "#fff",
  textColor: "#0ca4ff",
  textColorHover: "#0ca4ff",
  borderColor: "#fff",
  borderColorHover: "#fff",
  disabledBackgroundColor: "#fff",
  disabledBorderColor: "#fff",
  disabledTextColor: "#999999",
  textDecoration: "underline",
};

export function applyButtonTheme(theme: ButtonTheme) {
  return {
    color: theme.textColor,
    background: theme.backgroundColor,
    borderColor: theme.borderColor,
    borderWidth: 1,
    borderStyle: "solid",
    online: "none",
    fontSize: "1em",
    fontWeight: 700,
    minWidth: "9em",
    padding: "0.5em 0.6em",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: 3,
    $nest: {
      "&:hover": {
        background: theme.backgroundColorHover,
        borderColor: theme.borderColorHover,
        color: theme.textColorHover,
        textDecoration: theme.textDecoration,
      },
      "&:disabled": {
        background: theme.disabledBackgroundColor,
        borderColor: theme.disabledBorderColor,
        color: theme.disabledTextColor,
      },
    },
  };
}

const styles = stylesheet({
  iconContainer: {
    width: "1em",
    height: "1em",
    marginLeft: ".5em",
  },
  wrapper: {
    position: "relative",
  },
  iconImage: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  options: {
    borderRadius: "6px",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.05)",
    background: "#FFFFFF",
    position: "absolute",
    top: "calc(2.3em + 6px)",
    overflow: "hidden",
    scrollPaddingTop: "2em",
    overflowY: "scroll",
    maxHeight: "12em",
    width: "95%",
    zIndex: 3,
  },

  option: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    padding: " 0.5em",
    height: "2.5em",
    background: "#fff",
    color: "#333",
    cursor: "pointer",
    $nest: {
      "&:hover": {
        background: '#FAFBFC',
      },
    },
  },

  smallSize: {
    height: "2em",
  },
  mediumSize: {
    height: "2.5em",
  },
  disable: {
    color: "#999999 !important",
  },
  buttonBlue: applyButtonTheme(blueColors),
  buttonWhite: applyButtonTheme(whiteColors),
  buttonOrange: applyButtonTheme(orangeColors),
  buttonLink: applyButtonTheme(linkColors),
});

export default styles;
