import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import MissingLocationList from "../MissingLocationList";
import { MissingLocation } from "../../utils/types";
import LocationForm from "../LocationForm";
import { Placement, Location } from "@innomius/ravent-typescript-types";
import { EmptyList } from "../List/EmptyList";

interface Props {
  venueId: string;
  locations: MissingLocation[];
  locationsLoading: boolean;
  count: string;
  error: string;
  load: () => void;
  sort: string;
  groups: string[];
  onCreateLocation: (
    venueLocation: Location,
    venuePlacement: Placement
  ) => void;
  onChangeLocation: <P extends keyof Location>(
    prop: P,
    value: Location[P]
  ) => void;
  venueLocation: Location;
  venuePlacement: Placement;
  page: number;
  total: number;
  hitsPerPage: number;
  setPage?: (page: number) => void;
  setHitsPerPage?: (hitsPerPage: number) => void;
}

const Missing: React.FC<Props> = ({
  locations,

  venueId,
  locationsLoading,
  error,
  load,
  groups,
  onCreateLocation,
  venueLocation,
  venuePlacement,
  onChangeLocation,
  page,
  total,
  hitsPerPage,
  setPage,
  setHitsPerPage,
}) => {
  const { t } = useTranslation();

  const alphaSorted =
    groups
      ?.map((item) => ({ label: item, value: item }))
      ?.sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  const [open, setOpen] = useState(false);
  const [name, setname] = useState("");

  return (
    <div>
      {!locations.length && !locationsLoading ? (
        <div className={styles.routeContainerEmpty}>
          <EmptyList
            instructions={""}
            text={`${t("component.venueLocations.instructions")}`}
          />
        </div>
      ) : (
        <div className={styles.routeContainer}>
          <div>
            <MissingLocationList
              venueId={venueId}
              page={page}
              onPageChange={setPage}
              hitsPerPage={hitsPerPage}
              onHitsPerPageChange={setHitsPerPage}
              pagination
              total={total}
              onClickItem={(id, name) => {
                const updatedLocation = {
                  externalId: id,
                  name: name,
                };
                setname(updatedLocation.name);
                setOpen(true); // Set open to true
                onChangeLocation("externalId", updatedLocation.externalId);
              }}
              error={error}
              loading={locationsLoading}
              data={locations}
            />
          </div>
        </div>
      )}
      <LocationForm
        groups={alphaSorted}
        open={open}
        name={name}
        onChangeName={(value) => setname(value)}
        path={`/missing`}
        data={venueLocation}
        onChange={onChangeLocation}
        onCreate={() => {
          onChangeLocation("name", name);
          onCreateLocation(venueLocation, venuePlacement);
        }}
        onClose={() => {
          setOpen(false);
          load();
        }}
      />
    </div>
  );
};

export default Missing;
