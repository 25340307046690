import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles";
import PassList from "./PassList";
import { Pass } from "../../utils/types";
import { Card } from "../Card/Card";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { EmptyList } from "../List/EmptyList";

interface Props {
  passes: Pass[];
  onSharePasses: (eventId: string) => void;
}

const OrderPasses: React.FC<Props> = ({ passes, onSharePasses }) => {
  const { t } = useTranslation();

  return (
    <Card containerClassname={styles.card}>
      <div>
        <>
          <FlexContainer
            padding="0 0 1em 0"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <ContainerTitle
              size="small"
              label={`${t("component.orderPasses.passes")}`}
            />
          </FlexContainer>
        </>

        <div>
          {passes.length ? (
            <PassList data={passes} onViewDetails={() => {}} />
          ) : (
            <EmptyList
              instructions=""
              text={t("component.orderPasses.noPasses")}
            />
          )}
        </div>
      </div>
    </Card>
  );
};

export default OrderPasses;
