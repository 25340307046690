import React, { useState } from "react";

import { UserCircle } from "../UserCircle";
import { OrderComment } from "@innomius/ravent-typescript-types";

import styles from "./styles";
import OrderCommentList from "../OrderCommentList";
import { useTranslation } from "react-i18next";
import { SideMenu } from "../SideMenu/SideMenu";
import { ContainerTitle } from "../ContainerTitle";
import { EmptyList } from "../List/EmptyList";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputTextarea } from "../Inputs/InputTextArea";
import { Button } from "../Button";

interface Props {
  comments: OrderComment[];
  userName: string;
  sendMessage: (message: string, file?: string) => void;
  removeMessage: (id: string) => void;
  error?: string;
  open: boolean;
  onClose: () => void;
}

const OrderComments: React.FC<Props> = ({
  userName,
  sendMessage,
  comments,
  removeMessage,
  error,
  open,
  onClose,
}) => {
  const [message, setMessage] = useState("");
  const [errorImage, setErrorImage] = useState("");
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const inputFile = React.useRef<HTMLInputElement>(null);

  const handleFile = (file: File) => {
    const reader = new FileReader();
    reader.onloadend = async () => {
      setFileName(file.name);
      console.log(fileName);
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpg" &&
        file.type !== "image/jpeg"
      ) {
        setErrorImage("This type it's not allowed");
        console.log(errorImage);
        return null;
      }
      try {
        setFile((reader.result as string).replace(/^.+?;base64,/, "") || "");
      } catch (error) {}
    };
    reader.readAsDataURL(file);
  };

  const handleInputFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.target.files;
    const file = files ? files[0] : null;
    if (file) {
      handleFile(file);
    }
  };

  // const openInputFile = () => inputFile.current?.click();

  return (
    <SideMenu width="45%" open={open} onClose={() => onClose()}>
      <ContainerTitle
        padding="1em 0 1em 0"
        size="medium"
        label={t("component.orderComments.comments")}
      />

      <div className={styles.commentList}>
        {error ? (
          <EmptyList
            instructions=""
            text={t("component.orderComments.error")}
            error={error}
          />
        ) : null}

        {comments.length ? (
          <div className={styles.containerList}>
            <OrderCommentList
              data={comments}
              removeMessage={(id) => removeMessage(id)}
            />
          </div>
        ) : (
          <EmptyList
            instructions={t("component.orderComments.empty")}
            text={t("component.orderComments.noComments")}
          />
        )}
      </div>

      <div className={styles.footer}>
        <GridContainer
          padding="1em 0 0 0"
          columns="50px 1fr"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <div className={styles.userContainer}>
            <UserCircle name={userName} />
          </div>
          <div>
            <GridContainer gap="1em" columns="1fr 0.35fr">
              <InputTextarea
              
                value={message}
                onChange={(value) => setMessage(value)}
              />
            </GridContainer>
          </div>
          <input
            ref={inputFile}
            type="file"
            onChange={(e) => handleInputFile(e)}
            style={{ display: "none" }}
          />
          <div />
          <Button
            label={loading ? "..." : t("component.orderComments.send")}
            onClick={async () => {
              if (loading) {
                return;
              }
              try {
                setLoading(true);
                await sendMessage(message, file);
                setLoading(false);

                setMessage("");
              } catch (err) {
                setLoading(false);
              }
            }}
          />
        </GridContainer>
      </div>
    </SideMenu>
  );
};

export default OrderComments;
