const translation = {
  en: {
    actions: "Actions",
    addIntegration: "+Add new integration",
    addAction: "+ Add Action",
    schedules: "Schedules",
    addSchedule: "+ Add Schedule",
    lastStatus: "Last Status",
    timezone: "Timezone",
    noSchedules: "No actions have been scheduled",
  },
  es: {
    noSchedules: "No se han programado acciones",
    addAction: "+ Agregar Acción",
    addIntegration: "+Agregar Integración",
    others: "Acciones",
    schedules: "Calendarización",
    addSchedule: "+ Agregar Calendarización",
    lastStatus: "Último Estado",
    timezone: "Timezone",
  },
  de: {},
};

export default translation;
