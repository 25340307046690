import * as React from "react";
import { NavLink } from "react-router-dom";
import styles from "./styles";

export interface SideBarButtonProps {
  id: string;
  icon?: string | React.ReactElement; // Add iconSrc property to store icon source
  label: string;
  path: string;
}

interface Props {
  data: SideBarButtonProps;
  shrink?: boolean;
}

const SideNabBar: React.FC<Props> = ({ data, shrink }) => (
  <NavLink
    key={data.id}
    className={styles.button}
    activeClassName={styles.buttonActive}
    to={data.path}
    exact={false}
  >
    {shrink ? (
      <div className={styles.iconContainer}>
        {data.icon &&
          // Render icon source if it exists
          (typeof data.icon === "string" ? (
            <img src={data.icon} className={styles.icon} alt="" />
          ) : (
            data.icon
          ))}
      </div>
    ) : (
      <>
        <div className={styles.iconContainer}>
          {data.icon &&
            // Render icon source if it exists
            (typeof data.icon === "string" ? (
              <img src={data.icon} className={styles.icon} alt="" />
            ) : (
              data.icon
            ))}
        </div>
        <p className={styles.buttonLabel}>{data.label}</p>
      </>
    )}
  </NavLink>
);

export default SideNabBar;
