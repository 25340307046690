import React from "react";
import styles from "./styles.module.css";
import { Popover } from "antd";
import Text from "../../Text";
import {
  format,
  parseISO,
  startOfYear,
  endOfYear,
  addDays,
  getDay,
  getDayOfYear,
  differenceInDays,
} from "date-fns";
import { utcToZonedTime, format as formatWithTimezone } from "date-fns-tz";
import { useHistory } from "react-router-dom";

interface Props {
  range: {
    from: number;
    to: number;
  }[];
  heatmap: {
    year: number | string;
    count: number;
    weekOfYear: number;
    month: string;
    date: string;
  }[];
  year: string;
  monthLabels: string[];
  dayLabels: string[];
  counts: string;
  timezone: string;
}

const Daily: React.FC<Props> = ({
  range,
  heatmap,
  year,
  monthLabels,
  dayLabels,
  counts,
  timezone,
}) => {
  const history = useHistory();

  // Define the start and end dates of the specified year
  const startDate = startOfYear(new Date(Number(year), 0, 1));
  console.log("year", year);
  console.log("startDate", startDate);

  const endDate = endOfYear(startDate);
  const daysInYear = differenceInDays(endDate, startDate) + 1;

  // Prepare day of the year data
  const dayOfYearData: (number | null)[] = Array.from(
    { length: daysInYear },
    () => null
  );

  heatmap.forEach((data) => {
    const dateObj = utcToZonedTime(parseISO(data.date), timezone);
    if (format(dateObj, "yyyy") === year) {
      const dayOfYear = getDayOfYear(dateObj);
      dayOfYearData[dayOfYear - 1] = data.count;
    }
  });

  const getDayColor = (count: number | null) => {
    if (count === null || count === 0) return styles.colorEmpty; // Treat 0 as empty
    for (let i = 0; i < range.length; i++) {
      if (count >= range[i].from && count <= range[i].to) {
        return styles[`colorScale${i + 1}`];
      }
    }
    return styles.colorEmpty;
  };

  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const getDateTooltip = (
    date: string,
    count: number | null,
    year: number,
    timezone: string
  ) => {
    const dateObj = utcToZonedTime(parseISO(date), timezone);

    const dayOfWeek = getDay(dateObj);
    const dayName = dayNames[dayOfWeek];

    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          e.stopPropagation();
          history.push(
            `/events?from=${format(dateObj, "yyyy-MM-dd")}&to=${format(
              dateObj,
              "yyyy-MM-dd"
            )}`
          );
        }}
      >
        <div>{dayName}</div>
        <div>{`${formatWithTimezone(dateObj, "dd MMM yyyy", {
          timeZone: timezone,
        })}`}</div>
        <div>{`${count !== null ? count : 0} ${
          counts === "events" ? "events" : "loads"
        }`}</div>
      </div>
    );
  };

  const startDayOfWeek = getDay(startDate);

  const monthLabelElements = monthLabels.map((label, index) => (
    <div
      key={index}
      className={styles.monthLabel}
      style={{
        gridColumnStart: index * 7 + 2,
        gridColumnEnd: (index + 1) * 7 + 2,
      }}
    >
      {label}
    </div>
  ));

  const dayLabelElements = dayLabels.map((label, index) => (
    <div key={index} className={styles.dayLabel}>
      <Text color="#aaa" padding="0 0.5em" text={label} />
    </div>
  ));

  const calendarGrid = (
    <div className={styles.calendarGrid}>
      <div className={styles.dayLabels}>{dayLabelElements}</div>
      <div className={styles.monthLabels}>{monthLabelElements}</div>
      <div className={styles.gridContainer}>
        {dayOfYearData.map((count, dayIndex) => {
          const date = addDays(startDate, dayIndex);

          const weekDay = getDay(date);
          const weekIndex = Math.floor((dayIndex + startDayOfWeek) / 7);

          return (
            <Popover
              key={dayIndex}
              content={getDateTooltip(
                format(date, "yyyy-MM-dd"),
                count,
                parseInt(year, 10),
                timezone
              )}
              trigger="hover"
              overlayStyle={{ zIndex: 999 }}
            >
              <div
                className={`${styles.calendarCell} ${getDayColor(count)}`}
                style={{ gridColumn: weekIndex + 2, gridRow: weekDay + 2 }}
              />
            </Popover>
          );
        })}
      </div>
    </div>
  );

  return <div className={styles.container}>{calendarGrid}</div>;
};

export default Daily;
