import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import { RootState } from "../../store";
import { Redirect, RouteComponentProps } from "react-router-dom";

import { defaultAddOfferSet, OfferSetParams } from "../../services/offersets";
import styles from "./styles.module.css";
import { teamList } from "../../store/teams";
import { useTranslation } from "react-i18next";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { BackLink } from "../../components/BackLink";
import { ButtonsContainer } from "../../components/ButtonsContainer";
import { Button } from "../../components/Button";
import { EditableTitle } from "../../components/EditableTitle";

interface Props extends RouteComponentProps {}

const CreateVenue: React.FC<Props> = ({ history }) => {
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const [offerSet, setOfferSet] = useState<OfferSetParams>(defaultAddOfferSet);
  const entities = useSelector((state: RootState) => state.teams);

  const { t } = useTranslation();

  const onChange = <P extends keyof OfferSetParams>(
    prop: P,
    value: OfferSetParams[P]
  ) => {
    setOfferSet({ ...offerSet, [prop]: value });
  };

  const load = useCallback(async () => {
    dispatch(teamList({}));
  }, [dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  if (!auth.loading && !auth.user && !entities) {
    return <Redirect from="*" to="/" />;
  }
  return (
    <LayouWithSideBar>
      <div className={styles.container}>
        <BackLink
          label={t("container.createOfferSet.backButton")}
          onClick={() => history.push("/offersets")}
        />
        <FlexContainer padding="1em 0 2em 0" justifyContent="space-between">
          <EditableTitle
            placeholder={t("container.createOfferSet.placeHolder")}
            value={offerSet.name}
            onChange={(value) => onChange("name", value)}
          />
          <ButtonsContainer itemsPadding="0 0 0 1em">
            <Button
              label={t("container.createOfferSet.cancel")}
              theme="white"
              onClick={() => history.push("/offersets")}
            />
            <Button label={t("container.createOfferSet.save")} />
          </ButtonsContainer>
        </FlexContainer>
        {/* <OfferSetForm data={offerSet} onChange={onChange} /> */}
      </div>
    </LayouWithSideBar>
  );
};

export default CreateVenue;
