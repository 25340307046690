import React, { useState } from "react";

import { OfferSet } from "@innomius/ravent-typescript-types";
import deleteIcon from "../../assets/officialMedia/default-delete.svg";
import editIcon from "../../assets/officialMedia/default-edit.svg";
import styles from "./styles";
import { useHistory } from "react-router-dom";
import { Popover } from "antd";
import DeletePopUp from "../DeletePopUp";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";
import { InputSwitch } from "../Inputs/InputSwitch";
import { FlexContainer } from "../FlexContainer/FlexContainer";

interface OfferSetsListProps {
  data: OfferSet;
}

const OfferSetListItem: React.FC<OfferSetsListProps> = ({ data }) => {
  const [deleteOfferSet, setDelete] = useState("");
  let history = useHistory();

  return (
    <ListRow
      onClick={() => history.push(`offersets/${data.id}/offers`)}
      template="2fr 1fr 1fr 1.5fr"
      hover={true}
      key={data.id}
    >
      <ListLabel fontFormat="bold" value={data.name} textAlign="left" />


      <div className={styles.center}>{data.offers.length.toString()}</div>
      <div className={styles.center}>
        <InputSwitch value={data.enabled} />
      </div>
      <FlexContainer justifyContent="space-evenly" alignItems="center">
        <Popover
          placement="right"
          content={
            <div onClick={() => setDelete("")}>
              <DeletePopUp
                value={`Are you sure you want to delete ${data.name} Offer Set?`}
                onClose={(e) => {
                  e.stopPropagation();
                  setDelete("");
                }}
                onDelete={(e) => {
                  e.stopPropagation();
                  setDelete("delete");
                }}
              />
            </div>
          }
          trigger="click"
          open={deleteOfferSet === data.id}
          onOpenChange={() => setDelete(deleteOfferSet)}
        />
        <img src={editIcon} className={styles.icon} alt="editOffer" />
        <img
          src={deleteIcon}
          className={styles.icon}
          alt="deleteOffer"
          onClick={(e) => {
            e.stopPropagation();
            setDelete(data.id);
          }}
        />
      </FlexContainer>
    </ListRow>
  );
};

export default OfferSetListItem;
