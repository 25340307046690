const translation = {
  en: {
    comments: "Messages",
    error: "Error Message List",
    noComments: "No Messages",
    empty: "Empty List",
    send: "Send",
    eventMessages: "Event Messages",
  },
  es: {
    comments: "Mensajes",
    error: "Error de Lista de Mensajes",
    noComments: "No exiten Mensajes",
    empty: "Lista vacía",
    send: "Enviar",
    eventMessages: "Mensajes de Evento",
  },
  de: {},
};

export default translation;
