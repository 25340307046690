import React from "react";
import styles from "./styles.module.css";
import { Popover } from "antd";
import { useTranslation } from "react-i18next";
import Text from "../Text";
import { GridContainer } from "../GridContainer/GridContainer";
import { FlexContainer } from "../FlexContainer/FlexContainer";

interface Props {
  range: {
    from: number;
    to: number;
  }[];
  year: number | string;
  heatmap: {
    count: number;
    year: string | number;
    month: string;
    weekOfYear: number;
  }[];
}

const Monthly: React.FC<Props> = ({ range, year, heatmap }) => {
  const colors = [
    "#E2F3FF",
    "#C7E7FF",
    "#9AD4FF",
    "#79C6FF",
    "#4CB3FF",
    "#1FA0FF",
    "#1A88D9",
    "#136099",
    "#0C4066",
    "#09304D",
  ];

  const { t } = useTranslation();

  // Create a map for heatmap data
  const heatMapData = new Map<string, number>();
  heatmap.forEach(({ count, month }) => {
    heatMapData.set(month, count);
  });

  // Function to get week color based on count
  const getWeekColor = (count: number) => {
    for (let i = 0; i < range.length; i++) {
      if (count >= range[i].from && count <= range[i].to) {
        return colors[i];
      }
    }
    return "#eee";
  };

  const months = [
    { label: "January", value: "01" },
    { label: "February", value: "02" },
    { label: "March", value: "03" },
    { label: "April", value: "04" },
    { label: "May", value: "05" },
    { label: "June", value: "06" },
    { label: "July", value: "07" },
    { label: "August", value: "08" },
    { label: "September", value: "09" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
  ];

  // Function to get number of days in a month
  const getDaysInMonth = (year: number, month: number) => {
    return new Date(year, month, 0).getDate();
  };

  return (
    <div>
      <GridContainer gap="0.2em" columns={`repeat(12, 1fr)`}>
        {Array.from({ length: 12 }, (_, index) => index + 1).map((month) => {
          const monthString = month < 10 ? `0${month}` : `${month}`;
          const count = heatMapData.get(`${year}-${monthString}`) || 0;
          const monthLabel = months.find((m) => m.value === monthString)?.label;
          const daysInMonth = getDaysInMonth(Number(year), Number(month));
          const popoverTitle = `${monthLabel} 01-${daysInMonth}`;
          return (
            <FlexContainer key={`${year}-${month}`} flexDirection="column">
              <Text
                color="#aaa"
                padding="0 0 0.5em 0"
                text={monthLabel || ""}
              />
              <Popover
                title={`${t("container.dashboard.month")}: ${popoverTitle}`}
                content={`${t("container.dashboard.count")}: ${count}`}
              >
                <div
                  className={styles.rectangle}
                  style={{
                    background: getWeekColor(count),
                  }}
                />
              </Popover>
            </FlexContainer>
          );
        })}
      </GridContainer>
    </div>
  );
};

export default Monthly;
