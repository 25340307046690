const translation = {
  en: {
    successCreation: "Fulfillment Definition succesfully created.",
    back: "Back to Fulfillment Definitions",
    cancel: "Cancel",
    save: "Save",
    fulfillmentNamePlaceholder: "Fulfillment Name",
    detailsLabel: "Details",
    ticketingLabel: "Ticketing",
  },
  es: {
    successCreation: "Definición de entrega creada con éxito.",
    back: "Volver a Definiciones de Entrega",
    cancel: "Cancelar",
    save: "Guardar",
    fulfillmentNamePlaceholder: "Nombre de Entrega",
    detailsLabel: "Detalles",
    ticketingLabel: "Emisión de boletos",
  },
  de: {},
};

export default translation;
