import React, { useState } from "react";

import { OrderListSkeleton } from "../ListSkeletons";
import styles from "./styles";
import Item from "./Item";
import { CountsResponse } from "../../services/events";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { List } from "../List";

interface BillingListProps {
  error: string;
  loading: boolean;
  data: CountsResponse[];
  onChangeSortOrder?: (sort: string) => void;
}

const BillingList: React.FC<BillingListProps> = ({
  error,
  loading,
  data,
  onChangeSortOrder,
}) => {
  const [order, setOrder] = useState("asc");
  const [sort, setSort] = useState<string | null>(null);

  const header = (
    <div>
      <ListHeader template="1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr">
        <ListHeaderLabel
          value={"Month"}
          sortable
          sort={sort === "month" ? (order === "asc" ? "asc" : "desc") : null}
          onClick={() => {
            setSort("month");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`month:${order}`);
            }
          }}
          justifyContent="flex-start"
        />
        <ListHeaderLabel value={"Orders"} justifyContent="flex-start" />
        <ListHeaderLabel value={"Delted Orders"} justifyContent="flex-start" />
        <ListHeaderLabel value={"Cost per Order"} justifyContent="flex-start" />
        <ListHeaderLabel value={"Orders Cost"} justifyContent="flex-start" />
        <ListHeaderLabel value={"Paxes"} justifyContent="flex-start" />
        <ListHeaderLabel value={"Cost Per Pax"} justifyContent="flex-start" />
        <ListHeaderLabel value={""} justifyContent="flex-start" />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <div className={styles.listContainer}>
        <List
          loading={loading}
          loadingElement={() => <OrderListSkeleton />}
          error={error}
          data={data}
          empty={!data.length}
          keyExtractor={(item) => item.month + Math.random()}
          renderHeader={() => header}
          renderItem={(item) => (
            <Item key={item.month + Math.random()} data={item} />
          )}
        />
      </div>
    </div>
  );
};

export default BillingList;
