import React from "react";
import styles from "./styles";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { DuplicateLocation } from "../../utils/types";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";

interface LocationsListProps {
  data: DuplicateLocation;
  onClickItem: (id: string) => void;
}

const LocationItemList: React.FC<LocationsListProps> = ({
  data,
  onClickItem,
}) => {
  return (
    <div>
      <ListRow
        boxShadow
        borderRadius="6px"
        template="0.5fr 1fr 0.5fr 1fr 0.5fr 0.7fr"
        hover={true}
        key={data.location.id}
      >
        <ListLabel value={data.location.externalId || "-"} textAlign="left" />
        <ListLabel value={data.location.name} textAlign="left" />
        <ListLabel value={data.location.group} textAlign="left" />
        <ListLabel value={data.location.line1 || "-"} textAlign="left" />
        <ListLabel value={data.duplicates.toString() || "-"} textAlign="left" />

        <div
          className={styles.iconContainer}
          onClick={() =>
            onClickItem(
              data.location.externalId
                ? data.location.externalId
                : data.location.name
            )
          }
        >
          <FontAwesomeIcon
            icon={faChevronRight as IconProp}
            className={styles.icon}
            color={"#333333"}
            size="2x"
          />
        </div>
      </ListRow>
    </div>
  );
};

export default LocationItemList;
