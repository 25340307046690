import * as React from "react";
import { stylesheet } from "typestyle";
import { darkBlack } from "../../utils/colors";

export interface SectionPropertyProps {
  value: string;
  label: string;
  padding?: string;
  labelFontSize?: string;
  labelColor?: string;
  labelPadding?: string | number;
  valueFontSize?: string;
  valueColor?: string;
}

const styles = stylesheet({
  property: {
    padding: "0 4em 0 0",
  },
  text: {
    padding: ".4em 0 0 0",
    fontSize: "1.1em",
    color: darkBlack,
    paddingBottom: ".4em",
    fontWeight: 400,
    minHeight: "3em",
    whiteSpace: "pre-wrap", // Preserve newlines and spaces
  },
  label: {
    fontSize: "1em",
    color: darkBlack,
    fontWeight: 700,
  },
});

export const SectionProperty: React.FC<SectionPropertyProps> = ({
  label,
  value,
  padding,
  labelColor,
  labelFontSize,
  labelPadding,
  valueColor,
  valueFontSize,
}) => (
  <div className={styles.property} style={{ padding: padding }}>
    <div
      className={styles.label}
      style={{
        fontSize: labelFontSize,
        color: labelColor,
        padding: labelPadding,
      }}
    >
      {label}
    </div>
    <div
      className={styles.text}
      style={{
        fontSize: valueFontSize,
        color: valueColor,
      }}
    >
      {value}
    </div>
  </div>
);
