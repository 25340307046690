import { stylesheet } from "typestyle";
import { black, darkBlack, linkBlue, orangeyRed } from "../../utils/colors";

const styles = stylesheet({
  itemsWrapper: {
    padding: "1em 0",
  },
  containerDetails: {
    padding: "1em 0",
  },
  wrapperCard: {
    padding: "2em",
    marginBottom: "2em",
  },
  title: {
    color: darkBlack,
    fontWeight: "bold",
    fontSize: "1.1em",
  },
  text: {
    fontSize: "1.1em",
    color: darkBlack,
    fontWeight: 400,
    textTransform: "capitalize",
  },
  radioContainer: {
    marginRight: "1em",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center !important",
    cursor: "pointer",
  },
  icon: {},
  iconShare: {
    marginRight: "1em",
  },
  blue: {
    fontSize: "0.9em",
    color: linkBlue,
    margin: "1em 0",
    width: "20%",
    cursor: "pointer",
  },
  selectContainer: {
    width: "50%",
  },
  iconDelete: {
    width: "20px",
  },
  defaultContainer: {
    paddingBottom: "1em",
  },
  warning: {
    padding: "2em 0 0 0",
    color: orangeyRed,
  },
  amountContainer: {
    width: "20%",
  },
  startDateContainer: {
    width: "25%",
  },
  checkboxContainer: {
    padding: "2em 1em 0 1em",
  },
  label: {
    color: black,
    fontWeight: 500,
  },
  maxEventsContainer:{
    width: '20%'
  },
  descriptionContainer:{
    width: '57%'
  },
  propertyInstructions: {
    padding: '0 0 1.5em 0',
    textAlign: 'justify'
  }
});

export default styles;
