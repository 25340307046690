import * as React from "react";
import { classes, style } from "typestyle";
import CrossIcon from "../Icons/CrossIcon";

interface TagTheme {
  background: string;
  border: string;
  borderRadius: string;
  textColor: string;
  iconColor: string;
  padding?: string;
  borderStyle?: string;
}

type DefaultThemes =
  | "green"
  | "purple"
  | "blue"
  | "red"
  | "yellow"
  | "purpleState"
  | "bluePaid"
  | "blueDashed";

const green: TagTheme = {
  background: "#e6fffb'",
  border: "1px solid #87e8de",
  borderRadius: "2px",
  textColor: "#13c2c2",
  borderStyle: "solid",
  iconColor: "#13c2c2",
};

const purple: TagTheme = {
  background: "#f9f0ff",
  border: "1px solid #d3adf7",
  borderRadius: "2px",
  textColor: "#722ed1",
  iconColor: "#722ed1",
  borderStyle: "solid",
};

const yellow: TagTheme = {
  background: "rgba(250, 173, 21, 0.1)",
  border: "1px solid rgba(250, 173, 21, 0.3)",
  borderRadius: "2px",
  padding: "0 0.5em",
  textColor: "#000000",
  iconColor: "#000000",
  borderStyle: "solid",
};

const red: TagTheme = {
  background: "#FFF2F2",
  border: "1px solid #ff2929",
  borderRadius: "2px",
  textColor: "#ff2929",
  iconColor: "#ff2929",
};

const blue: TagTheme = {
  background: "#e6f7ff",
  border: "1px solid #91d5ff",
  borderRadius: "2px",
  textColor: "#1890ff",
  iconColor: "#1890ff",
  borderStyle: "solid",
};

const blueDashed: TagTheme = {
  background: "#e6f7ff",
  border: "1px dashed #91d5ff",
  borderRadius: "2px",
  textColor: "#1890ff",
  iconColor: "#1890ff",
  borderStyle: "dashed",
};

const purpleState: TagTheme = {
  background: "#dcd0ea",
  border: "1px solid rgba(119,34,213,255)",
  borderRadius: "2px",
  textColor: "rgba(119,34,213,255)",
  iconColor: "rgba(119,34,213,255)",
  borderStyle: "solid",
};

const bluePaid: TagTheme = {
  background: "#cedaef",
  border: "1px solid rgb(36, 109, 241)",
  borderRadius: "2px",
  textColor: "rgb(36, 109, 241)",
  iconColor: "rgb(36, 109, 241)",
  borderStyle: "solid",
};

const container = style({
  fontSize: "1em",
  minHeight: "1.4em",
  background: "#FEFEFE",
  border: "1px solid #e4e8f0",
  borderRadius: "3px !important",
  padding: "0.1em 0.2em",
  outline: "0",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  width: "auto",
  flexWrap: "wrap",
});

const text = style({
  color: "#666",
});

const button = style({
  display: "block",
  color: "#666",
  background: 0,
  border: 0,
  outline: 0,
  cursor: "pointer",
});

const smallHeight = style({
  minHeight: "1.4em !important",
  padding: "0em 0.4em !important",
  fontSize: "1em !important",
});
const mediumHeight = style({
  height: "1.7em !important",
  padding: "0.7em 0.4em !important",
  fontSize: "1em !important",
  flexWrap: "nowrap",
});

const bigHeight = style({
  height: "2em !important",
  padding: "1em 0.5em !important",
  fontSize: "1.1em !important",
  flexWrap: "nowrap",
});

export interface ChangeTag {
  id?: string;
  label?: string;
  color?: string;
}

export interface TagProps {
  id?: string;
  label: string;
  onClose?: (data?: ChangeTag) => void;
  theme?: DefaultThemes;
  customTheme?: TagTheme;
  size?: "small" | "medium" | "big";
  padding?: string;
  onChangeColor?: (data?: ChangeTag) => void;
  borderStyle?: string;
  flexWrap?: "nowrap" | "wrap" | "wrap-reverse";
  width?: string;
}

const themeMap: Record<DefaultThemes, TagTheme> = {
  blueDashed: blueDashed,
  green: green,
  purple: purple,
  blue: blue,
  red: red,
  yellow: yellow,
  purpleState: purpleState,
  bluePaid: bluePaid,
};

export const Tag: React.FC<TagProps> = ({
  id,
  label,
  onClose,
  theme,
  customTheme,
  size,
  padding,
  onChangeColor,
  borderStyle,
  flexWrap,
  width
}) => {
  const { background, border, borderRadius, iconColor, textColor } = customTheme
    ? customTheme
    : theme
    ? themeMap[theme]
    : green;
  return (
    <div
      className={classes(container, {
        [smallHeight]: size === "small",
        [mediumHeight]: size === "medium",
        [bigHeight]: size === "big",
      })}
      style={{
        width,
        flexWrap,
        background,
        border,
        borderRadius,
        padding,
        borderStyle: `${borderStyle} !important`,
      }}
    >
      <div
        onClick={() => onChangeColor?.({ id, label })}
        className={text}
        style={{ color: textColor }}
      >
        {label}
      </div>
      {onClose ? (
        <button
          className={button}
          name="remove"
          onClick={() => onClose({ id, label })}
        >
          <CrossIcon color={iconColor} />
        </button>
      ) : null}
    </div>
  );
};
