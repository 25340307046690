import React, { useCallback, useEffect, useState } from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import OrderDetailComponent from "../../components/OrderDetail";
import {
  Action,
  FulfillmentEvent,
  FulfillmentState,
  Order,
  OrderComment,
  OrderInvoice,
  OrderLine,
  Payment,
  Placement,
  Schedule,
  Venue,
} from "@innomius/ravent-typescript-types";
import TabNavBar from "../../components/TabNavBar";
import OrderPayments from "../../components/OrderPayments";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import OrderComments from "../../components/OrderComments";
import OrderHistory from "../../components/OrderHistory";
import {
  cancelOrder,
  closeOrder,
  closeOrderline,
  deleteOrder,
  getOrderWebHookNotifications,
  listOrderlines,
  notifyWebHook,
  orderRead,
  replicateOrderERP,
  scheduleWebHook,
  setOrderlineState,
  setOrderState,
  updateCustomerOrder,
  updateOrder,
  updateOrderLine,
  updatePriceOrderline,
} from "../../services/orders";
import { teamList } from "../../store/teams";
import OrderDetailHeader from "../../components/OrderDetail/OrderDetailHeader";
import OrderHeader from "../../components/OrderHeader";
import { Loading } from "../../components/Loading";
import {
  Customer,
  FOP,
  Pass,
  RegisterPaymentRequest,
  RequestPaymentRequest,
  WebHookNotifications,
} from "../../utils/types";
import message from "antd/lib/message";
import {
  deletePayementRequest,
  listPayments,
  registerOrderPayment,
  requestOrderPayment,
} from "../../services/payments";
import styles from "./styles.module.css";
import {
  createComment,
  listOrderComments,
  removeComment,
} from "../../services/comments";
import { fopList } from "../../services/fop";
import APIError from "../../utils/APIError";
import { fulfillmentList } from "../../store/fulfillments";
import { sendMessage } from "../../services/messaging";
import { useTranslation } from "react-i18next";
import { invoiceList } from "../../services/invoices";
import OrderEvent from "../../components/OrderEvent";
import OrderFulfillmentComponent from "../../components/OrderFulfillmentComponent";
import { loadProfile } from "../../store/profile";
import {
  createOrderEvent,
  createOrderlineEvent,
  deleteEvent,
  listEvents,
  setEventState,
  updateElementEvent,
  updateEvent,
} from "../../services/events";
import { listActions } from "../../services/actions";
import { assetList } from "../../store/assets";
import { listFulfillmentStates } from "../../services/fulfilments";
import { createMiniLocation, getVenue } from "../../services/venues";
import secureLocalStorage from "react-secure-storage";
import { venueList } from "../../store/venues";
import OrderlineFulfillment from "../../components/OrderlineFulfillment";
import OrderPasses from "../../components/OrderPasses";
import { passList } from "../../services/passes";
import { ErrorNotFound } from "../../components/Error404/Error404";
import { StatusCatalog } from "../../components/StatusBadge";
import { EmptyList } from "../../components/List/EmptyList";

interface RouteParams {
  id: string;
}

interface Props extends RouteComponentProps<RouteParams> {}

const OrderDetail: React.FC<Props> = ({ match, history }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);
  const { profile } = useSelector((state: RootState) => state.profile);
  // const locations = useSelector((state: RootState) => state.locationTextSearch);

  const fulfillments = useSelector((state: RootState) => state.fulfillments);
  const [order, setOrder] = useState<Order | null>(null);
  const [orderlines, setOrderlines] = useState<OrderLine[]>([]);
  const [payments, setPayments] = useState<Payment[]>([]);
  const [paymentRequests, setPaymentRequests] = useState<Payment[]>([]);
  const [fEvents, setEvents] = useState<FulfillmentEvent[]>([]);
  const [comments, setComments] = useState<OrderComment[]>([]);
  const [invoices, setInvoices] = useState<OrderInvoice[]>([]);
  const [webhookNot, setWebHookNot] = useState<WebHookNotifications[]>([]);
  const teams = useSelector((state: RootState) => state.teams);
  const [loading, setLoading] = useState<string>("");
  const [fops, setFOPs] = useState<FOP[]>([]);
  const [passes, setPasses] = useState<Pass[]>([]);

  const [error, setError] = useState("");
  const [errorFOPs, setErrorFOPs] = useState("");
  const assets = useSelector((state: RootState) => state.assets);

  const [open, setOpen] = useState<string>("");
  const [commentError, setCommentsError] = useState("");
  const [requestError, setRequestError] = useState("");
  const [weebHookError, setErrorWebHook] = useState("");
  const [openHeader, setOpenHeader] = useState("");
  const [errorFlows, setErrorFlows] = useState<string>("");
  const [updateOrderError, setOrderUpdateError] = useState("");
  const [edit, setEdit] = useState<boolean>(false);
  const [selectedVenue, setSelectedVenue] = useState<Venue>();

  const venues = useSelector((state: RootState) => state.venues);
  const venueArray = [
    ...venues.list.data.map((item) => ({ label: item.name, value: item.id })),
    { label: "all", value: "" },
  ];

  const loadVenues = useCallback(async () => {
    dispatch(venueList({ records_per_page: 20 }));
  }, [dispatch]);

  useEffect(() => {
    loadVenues();
  }, [loadVenues]);

  const loadVenue = useCallback(async () => {
    try {
      const venueId = secureLocalStorage.getItem("venueId") as string;
      if (venueId) {
        const res = await getVenue(venueId);
        setSelectedVenue(res);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    loadVenue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [actions, setActions] = useState<Action[]>([]);
  const [fulfillmentHistoric, setFulfillmentHistoric] = useState<
    FulfillmentState[]
  >([]);

  const loadAssets = useCallback(async () => {
    dispatch(assetList({ records_per_page: 500 }));
  }, [dispatch]);

  useEffect(() => {
    loadAssets();
  }, [loadAssets]);

  const loadActions = useCallback(async () => {
    try {
      const res = await listActions({
        page: 0,
        records_per_page: 100,
        manualTrigger: true,
      });
      setActions(res.data);
    } catch (err) {
      if (err instanceof APIError) {
        console.error(`${err.message}: ${err.messages}`);
      }
    }
  }, []);

  useEffect(() => {
    loadActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadFulfillmentHistoric = useCallback(async () => {
    try {
      const res = await listFulfillmentStates({ orderId: match.params.id });
      setFulfillmentHistoric(res.data);
    } catch (err) {
      if (err instanceof APIError) {
        console.error(`${err.message}: ${err.messages}`);
      }
    }
  }, [match.params.id]);

  useEffect(() => {
    loadFulfillmentHistoric();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadPasses = useCallback(async () => {
    try {
      const res = await passList(match.params.id);
      setPasses(res);
    } catch (err) {
      if (err instanceof APIError) {
        console.error(`${err.message}: ${err.messages}`);
      }
    }
  }, [match.params.id]);

  useEffect(() => {
    loadPasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadWebHookNotifications = useCallback(async () => {
    try {
      const res = await getOrderWebHookNotifications(match.params.id);
      setWebHookNot(res);
    } catch (err) {
      if (err instanceof APIError) {
        setErrorWebHook(err.message);
      }
    }
  }, [match.params.id]);

  const getInvoices = useCallback(async () => {
    try {
      const res = await invoiceList(match.params.id);
      setInvoices(res);
    } catch (err) {
      if (err instanceof APIError) {
        console.log(err.messages);
      }
    }
  }, [match.params.id]);

  const load = useCallback(async () => {
    dispatch(teamList({ records_per_page: 100 }));
    dispatch(fulfillmentList({ records_per_page: 50 }));
    try {
      setLoading("detail");
      const order = await orderRead(match.params.id);
      setOrder(order);
      setLoading("");
    } catch (err) {
      if (err instanceof APIError) {
        setError(err.message);
      }
      setLoading("");
    }
    try {
      const comments = await listOrderComments(match.params.id);
      setComments(comments);
    } catch (err) {
      if (err instanceof APIError) {
        setCommentsError(err.message);
      }
    }
    try {
      const res = await fopList();
      setFOPs(res.data);
    } catch (err) {
      if (err instanceof APIError) {
        setErrorFOPs(err.message);
      }
    }

    try {
      const res = await listOrderlines({ orderId: match.params.id });
      setOrderlines(res);
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`Orderline Fetch Error ${err.messages}`);
      }
    }
    try {
      const res = await listPayments({
        type: "payment",
        orderId: match.params.id,
      });
      setPayments(res);
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`Payments Fetch Error: ${err.messages}`);
      }
    }
    try {
      const res = await listPayments({
        type: "payment-request",
        orderId: match.params.id,
      });
      setPaymentRequests(res);
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`Payments Fetch Error: ${err.messages}`);
      }
    }

    loadWebHookNotifications();
  }, [match.params.id, dispatch, loadWebHookNotifications]);

  const loadEvents = useCallback(async () => {
    try {
      const res = await listEvents({
        orderId: match.params.id,
        records_per_page: 100,
      });
      setEvents(res.data);
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`Events Fetch Error: ${err.messages}`);
      }
    }
  }, [match.params.id]);

  const flows = actions
    .filter((item) => item.orderOnly)
    .map((item) => ({ name: item.name, url: item.url, actionId: item.id }));

  useEffect(() => {
    getInvoices();
  }, [match.params.id, getInvoices]);

  useEffect(() => {
    loadEvents();
  }, [loadEvents]);

  useEffect(() => {
    load();
  }, [match.params.id, load]);

  const loadP = useCallback(async () => {
    dispatch(loadProfile());
  }, [dispatch]);

  useEffect(() => {
    loadP();
  }, [loadP]);

  if ((loading === "detail" && !error) || !profile) {
    return <Loading />;
  }

  if (!order || !order.id) {
    return (
      <ErrorNotFound
        goBackButton
        containerClassname={styles.container}
        onClick={() => history.goBack()}
      />
    );
  }

  if (!order && error) {
    return <EmptyList error={error} instructions="Error" />;
  }

  const registerPayment = async (
    data: RegisterPaymentRequest,
    amount: string,
    requestId: string
  ) => {
    try {
      setLoading("register");
      await registerOrderPayment(
        data,
        amount,
        order.id,
        requestId,
        order.currency
      );
      setLoading("");
      message.success(t("container.orderDetail.successPayment"));
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        setRequestError(err.message);
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const sendComment = async (comment: string) => {
    try {
      await createComment(order.id, comment);
      message.success({
        content: t("container.orderDetail.successComment"),
        className: "custom-class",
        style: {
          marginTop: "10vh",
        },
      });
      try {
        const comments = await listOrderComments(match.params.id);
        setComments(comments);
      } catch (err) {
        if (err instanceof APIError) {
          setCommentsError(err.message);
        }
      }
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const removeMessage = async (id: string) => {
    try {
      await removeComment(id);
      message.success(t("container.orderDetail.successRemoveComment"));
      load();
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const requestPayment = async (
    data: RequestPaymentRequest,
    amount: string,
    fopId: string,
    send: boolean,
    email: string,
    lang: string
  ) => {
    try {
      setLoading("request");
      const request = await requestOrderPayment(
        data,
        order.id,
        amount,
        order.shortId,
        fopId,
        order.currency
      );

      try {
        if (send) {
          await sendMessage(
            order.id,
            lang,
            "email",
            email,
            "paymentRequest",
            request.id
          );
          message.success("Payment Request successfully via email shared.");
        }
      } catch (err) {
        message.error("Payment Request was not succesfully send.");
      }
      setLoading("");
      message.success(t("container.orderDetail.successRequest"));
      load();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onUpdateOrder = async (
    record: Record<string, any>,
    msg?: string,
    route?: () => void
  ) => {
    try {
      await updateOrder(match.params.id, record);
      message.success(
        msg ? msg : t("container.orderDetail.successOrderUpdate")
      );
      load();
      route?.();
    } catch (err) {
      if (err instanceof APIError) {
        message.error(err.message);
        setOrderUpdateError(err.message);
      }
    }
  };

  const onCancel = async () => {
    try {
      setLoading("cancel");
      await cancelOrder(order.id);
      message.success(t("container.orderDetail.successCancelled"));
      setLoading("");
      setOpenHeader("");
      load();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onUpdateCustomer = async (customer: Customer) => {
    try {
      setLoading("update");
      await updateCustomerOrder(order.id, customer);
      message.success(t("container.orderDetail.successUpdated"));
      setLoading("");
      setEdit(false);
      load();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onCloseOrder = async () => {
    try {
      setLoading("close");
      await closeOrder(order.id);
      message.success(t("container.orderDetail.successClosed"));
      setLoading("");
      setOpenHeader("");
      load();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onCloseOrderline = async (id: string) => {
    try {
      setLoading("close");
      await closeOrderline(id);
      message.success(t("container.orderDetail.successClosedOrderline"));
      setLoading("");
      setOpenHeader("");
      load();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onDelete = async () => {
    try {
      setLoading("delete");
      await deleteOrder(order.id);
      message.success(t("container.orderDetail.successDeleted"));
      history.push("/orders");
      setLoading("");
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onDeletePayementRequest = async (id: string) => {
    try {
      await deletePayementRequest(id);
      message.success(t("container.orderDetail.successRequestDeleted"));
      history.push(`/orders/${match.params.id}/payments`);
      load();
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onSendToFlow = async (actionId: string) => {
    try {
      setLoading("flows");
      const res = await notifyWebHook({
        actionId: actionId,
        ordersIds: [match.params.id],
      });
      if (res.successful === false) {
        message.error({
          content: `${res.statusCode} ${res.message}`,
        });
      }
      if (res.successful === true) {
        message.success(
          `${t("component.orderDetail.order")} ${order.shortId} ${t(
            "component.orderDetail.shared"
          )}`
        );
      }
      setLoading("");
      setOpenHeader("");

      load();
      setErrorFlows("");
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        setErrorFlows(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onScheduleFlow = async (actionId: string, schedule: Schedule) => {
    try {
      setLoading("flows");
      await scheduleWebHook({
        actionId: actionId,
        ordersIds: [match.params.id],
        date: schedule.date,
        timezone: schedule.timezone,
        prettyName: schedule.prettyName,
      });
      setLoading("");
      setOpenHeader("");
      message.success(
        `${t("component.orderDetail.order")} ${order.shortId} ${t(
          "container.orderDetail.scheduled"
        )}`
      );
      load();
      setErrorFlows("");
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        setErrorFlows(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onSetState = async (
    orderlineId: string,
    state: string,
    w3w: string,
    images: string[]
  ) => {
    try {
      setLoading("state-orderline");
      await setOrderlineState(orderlineId, state, w3w, images);
      message.success(t("container.orderDetail.stateSuccess"));
      setLoading("");
      load();
      loadFulfillmentHistoric();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onSetStateOrder = async (
    state: string,
    w3w: string,
    images: string[]
  ) => {
    try {
      setLoading("state-orderline");
      await setOrderState(match.params.id, state, w3w, images);
      message.success(t("container.orderDetail.stateSuccess"));
      setLoading("");
      load();
      loadFulfillmentHistoric();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onUpdateEvent = async (
    id: string,
    record: Record<string, any>,
    msg?: string,
    route?: () => void
  ) => {
    try {
      await updateElementEvent(id, record);
      message.success(
        msg ? msg : t("container.orderDetail.successEventUpdate")
      );
      setTimeout(() => {
        load();
        loadEvents();
        route?.();
      }, 500);
    } catch (err) {
      if (err instanceof APIError) {
        message.error(err.message);
        setOrderUpdateError(err.message);
      }
    }
  };

  const onCreateLocation = async (
    placement: Placement,
    type: string,
    group: string
  ) => {
    try {
      setLoading("create-location");
      await createMiniLocation(
        secureLocalStorage.getItem("venueId") as string,
        placement,
        type,
        group
      );
      setLoading("");
      message.success(t("container.venueDetail.successCreationLocation"));
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const passesTab = {
    id: "passRavent",
    label: t("container.orderDetail.passLabel"),
    path: `/orders/${match.params.id}/passes`,
    onClick: () => (document.title = "RAVENT APP :: Order :: Passes"),
  };

  const detailsTab = {
    id: "orderDetailRavent",
    label: t("container.orderDetail.detailsLabel"),
    path: `/orders/${match.params.id}`,
    onClick: () => (document.title = "RAVENT APP :: Order :: Details"),
  };

  const paymentsTab = {
    id: "orderPaymentRavent",
    label: t("container.orderDetail.paymentsLabel"),
    path: `/orders/${match.params.id}/payments`,
    onClick: () => (document.title = "RAVENT APP :: Order :: Payments"),
  };

  const fulfillmentTab = {
    id: "orderFulfilmentRavent",
    label: t("container.orderDetail.fulfillmentLabel"),
    path: `/orders/${match.params.id}/states`,
    onClick: () => (document.title = "RAVENT APP :: Order :: States"),
  };

  const eventsTab = {
    id: "orderEventssRavent",
    label: t("container.orderDetail.eventsLabel"),
    path: `/orders/${match.params.id}/events`,
    onClick: () => (document.title = "RAVENT APP :: Order :: Events"),
  };

  const historyTab = {
    id: "orderHistoryRavent",
    label: t("container.orderDetail.historyLabel"),
    path: `/orders/${match.params.id}/history`,
    onClick: () => (document.title = "RAVENT APP :: Order :: History"),
  };

  const fulfillmentDefinitionOptions = fulfillments.list.data.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const renderOrderDetail = () => (
    <OrderDetailComponent
      saveLoc={(p, t, g) => onCreateLocation(p, t, g)}
      onSaveLoad={(eventId, load) => onUpdateEvent(eventId, { loads: load })}
      assets={assets.list.data}
      updateAsset={async (e: FulfillmentEvent, id: string) => {
        onUpdateEvent(
          e.id,
          { assetId: id },
          "Asset was successfully added",
          () =>
            history.replace(
              `/orders/${e.order}/orderlines/${e.orderlineId}/events`
            )
        );
      }}
      onDeletePlacement={async (e: FulfillmentEvent, id: string) => {
        if (id) {
          const newLocationArray = e.placements.filter(
            (item) => item.id !== id
          );
          e.placements = newLocationArray;
          try {
            await updateEvent(e);
            message.success("Placement deleted successfully");
            load();
            history.replace(
              `/orders/${e.order}/orderlines/${e.orderlineId}/events`
            );
          } catch (err) {
            if (err instanceof APIError) {
              message.error(err.message);
            }
          }
        }
      }}
      onEditPlacement={async (event, placement) => {
        if (placement.id) {
          const index = event.placements.findIndex((object) => {
            return object.id === placement.id;
          });
          if (index !== -1) {
            event.placements[index] = placement;
            try {
              await updateEvent(event);
              message.success("Placement updated successfully");
              load();
              history.replace(
                `/orders/${event.order}/orderlines/${event.orderlineId}/events`
              );
            } catch (err) {
              if (err instanceof APIError) {
                message.error(err.messages);
              }
            }
          }
          return;
        }
        try {
          event.placements = [...event.placements, placement];
          await updateEvent(event);
          message.success("Placement added successfully");
          load();
          history.replace(
            `/orders/${event.order}/orderlines/${event.orderlineId}/events`
          );
        } catch (err) {
          if (err instanceof APIError) {
            message.error(err.messages);
          }
        }
      }}
      orderlines={orderlines || []}
      edit={edit}
      setEdit={setEdit}
      onSaveNotes={(data) => onUpdateOrder({ notes: data })}
      onSaveCustomer={(data) => onUpdateCustomer(data)}
      onCloseOrder={onCloseOrder}
      onCloseOrderline={onCloseOrderline}
      onEditOrderline={async (ol) => {
        try {
          await updatePriceOrderline(ol.id, ol);
          message.success("Orderline was successfully updated");
          load();
        } catch (err) {
          if (err instanceof APIError) message.error(err.message);
        }
      }}
      onDeleteEvent={async (id) => {
        try {
          await deleteEvent(id);
          message.success("Event was deleted successfully");
          load();
        } catch (err) {
          if (err instanceof APIError) {
            message.error(err.message);
          }
        }
      }}
      updateFulfiller={async (
        eventId: string,
        fId: string,
        orderlineId: string
      ) => {
        onUpdateEvent(
          eventId,
          { fulfillerId: fId },
          t("container.orderDetail.successDiver"),
          () =>
            history.replace(
              `/orders/${match.params.id}/orderlines/${orderlineId}/events`
            )
        );
      }}
      orderState={order.state}
      setState={(id, name, w3w, images) => {
        onSetState(id, name, w3w, images);
      }}
      onCreateEvent={async (event, id) => {
        try {
          await createOrderlineEvent(event, id);
          message.success("Event created successfully");
          history.replace(`/orders/${match.params.id}/orderlines/${id}/events`);
          load();
        } catch (err) {
          if (err instanceof APIError) {
            message.error(err.message);
          }
        }
      }}
      onSaveEvent={async (event) => {
        try {
          await updateEvent(event);
          message.success("Event updated successfully");
          load();
          history.replace(
            `/orders/${event.order}/orderlines/${event.orderlineId}/events`
          );
        } catch (err) {
          if (err instanceof APIError) {
            message.error(err.message);
          }
        }
      }}
      errorFulfillment={fulfillments.error}
      fulfillmentDefinitions={fulfillments.list.data}
      load={load}
      updateFulfillmentDefinition={async (id, fulfillmentId, list) => {
        try {
          await updateOrderLine(id, {
            fulfillmentDefinitionId: fulfillmentId,
          });
          message.success(t("container.orderDetail.successFulfillment"));
          load();
          if (list) {
            history.push(`/orders/${match.params.id}/orderlines/${id}/states`);
          }
        } catch (err) {
          if (err instanceof APIError) {
            message.error(`Error ${err.message}: ${err.messages}`);
          }
        }
      }}
      teams={teams.list.data}
      updateTeam={async (id, teamId) => {
        onUpdateEvent(
          id,
          { fulfillmentTeamId: teamId },
          t("container.orderDetail.successDiveshop")
        );
      }}
      path={`/orders/${match.params.id}`}
      onSendToErp={async () => {
        try {
          await replicateOrderERP(order.id);
          message.success("Order replicated in ERP.");
        } catch (err) {
          if (err instanceof APIError) {
            message.error(`Error: ${err.message}`);
          }
        }
      }}
      data={order}
      updateTags={async (tags: string[]) => {
        onUpdateOrder({ tags: tags }, t("container.orderDetail.successTag"));
      }}
    />
  );
  const onUpdateOrderline = async (id: string, fulfillmentId: string) => {
    try {
      await updateOrderLine(id, {
        fulfillmentDefinitionId: fulfillmentId,
      });
      message.success(t("container.orderDetail.successFulfillment"));
      load();
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`Error ${err.message}: ${err.messages}`);
      }
    }
  };

  return (
    <LayouWithSideBar>
      <OrderComments
        open={open === "comments"}
        onClose={() => setOpen("")}
        userName={auth.user?.accessTokenParsed.email || ""}
        comments={comments}
        sendMessage={(message) => sendComment(message)}
        removeMessage={(id) => removeMessage(id)}
        error={commentError}
      />
      <div className={styles.container}>
        <OrderHeader
          currency={order.currency}
          flows={flows}
          open={openHeader}
          setOpen={(value) => setOpenHeader(value)}
          markAsFavourite={() => {}}
          data={order}
          closeOrder={onCloseOrder}
          shortId={order.shortId}
          onCancel={() => onCancel()}
          onDelete={() => onDelete()}
          onCloseFlows={() => setErrorFlows("")}
          path="/orders"
          deleteError={""}
          loading={loading}
          errorFlows={errorFlows}
          totalAmount={order.totalAmount}
          schedule={(actionId: string, schedule: Schedule) =>
            onScheduleFlow(actionId, schedule)
          }
          notifyWebHook={(actionId: string) => onSendToFlow(actionId)}
        />

        <TabNavBar
          options={
            order.fulfillmentLevel === "none"
              ? [detailsTab, paymentsTab, eventsTab, passesTab, historyTab]
              : [
                  detailsTab,
                  paymentsTab,
                  fulfillmentTab,
                  eventsTab,
                  passesTab,
                  historyTab,
                ]
          }
        >
          <OrderDetailHeader
            id={order.id}
            currency={order.currency}
            locationLevel={order.locationLevel}
            fulfillmentLevel={order.fulfillmentLevel}
            path={`/orders/${match.params.id}`}
            errorFulfillment={""}
            orderState={order.state}
            team={order.fulfillmentTeamId}
            teams={teams.list.data.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            fulfillmentState={order.fulfillmentState}
            updateTeam={async (id, teamId) => {
              try {
                await onUpdateEvent(
                  id,
                  { fulfillmentTeamId: teamId },
                  t("container.orderDetail.successDiveshop")
                );
                history.push(`/orders/${match.params.id}/events`);
              } catch (err) {}
            }}
            setState={async (name, w3w, images) => {
              await onSetStateOrder(name, w3w, images);
            }}
            updateFulfillmentDefinition={async (id) =>
              await onUpdateOrder({ fulfillmentDefinitionId: id })
            }
            fulfillmentDefintions={[
              ...fulfillmentDefinitionOptions,
              { label: "unset defintion", value: "unset" },
            ]}
            commentsLength={comments.length}
            fulfillmentDefinitionId={order.fulfillmentDefinitionId}
            paymentStatus={order.paymentState}
            userId={auth.user ? auth.user?.accessTokenParsed.sub : ""}
            paymentDueDate={order.paymentDueDate as Date}
            onComment={() => setOpen("comments")}
            status={order.state as StatusCatalog}
            load={load}
            created={order.created as Date}
            dueAmount={order.dueAmount}
            paidAmount={order.paidAmount}
            updatePaymentDueDate={async (date: Date) => {
              onUpdateOrder(
                { paymentDueDate: date },
                `Payment Due Date successfully changed to: ${date}.`
              );
            }}
            review={order.review}
          />

          <Route path={`/orders/:id/orderlines/:orderlineid`}>
            {renderOrderDetail()}
          </Route>
          <Route path={`/orders/${match.params.id}`} exact>
            {renderOrderDetail()}
          </Route>
          <Route path={`/orders/${match.params.id}/payments`}>
            <OrderPayments
              refundActionId={
                selectedVenue?.invoiceEvents.invoiceRefund.actionId || ""
              }
              cancelInvoiceActionId={
                selectedVenue?.invoiceEvents.invoiceCancellation.actionId || ""
              }
              invoiceIssuanceMethod={
                selectedVenue?.invoiceIssuance || "payment"
              }
              customer={order.customer.email ? order.customer.email : ""}
              fops={fops}
              onDeleteRquest={(id) => onDeletePayementRequest(id)}
              load={() => load()}
              path={`/orders/${match.params.id}/payments`}
              loading={loading === "detail"}
              errorPayment={errorFOPs}
              data={order}
              invoiceIssuanceActionId={
                selectedVenue?.invoiceEvents.invoiceIssuance.actionId || ""
              }
              invoices={invoices}
              requestError={requestError}
              requests={paymentRequests || []}
              refunds={[]}
              payments={payments || []}
              onRegisterPayment={(data, amount, requestId) =>
                registerPayment(data, amount, requestId)
              }
              loadingRegister={loading === "register"}
              loadingRequest={loading === "request"}
              onRequestPayment={(data, amount, fopId, send, email, lang) =>
                requestPayment(data, amount, fopId, send, email, lang)
              }
            />
          </Route>
          <Route path={`/orders/${match.params.id}/states`}>
            {order.fulfillmentLevel === "orderline" ? (
              <OrderlineFulfillment
                updateFulfillmentName={(id, diveshopId, _fulfillmentLevel) => {
                  onUpdateOrderline(id, diveshopId);
                }}
                fulfillmentNames={fulfillments.list.data.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                setState={(id, _fulfillmentLevel, status, w3w, images) => {
                  onSetState(id, status, w3w, images);
                }}
                orderlines={orderlines}
              />
            ) : (
              <OrderFulfillmentComponent
                state={order.fulfillmentState}
                error={updateOrderError}
                fulfillmentDate={order.fulfillmentDate as string}
                fulfillmentHistoric={fulfillmentHistoric}
                orderId={order.id}
                setState={(id, name, w3w, images) => {
                  onSetStateOrder(name, w3w, images);
                }}
                configureDefinition={(id) =>
                  onUpdateOrder({ fulfillmentDefinitionId: id })
                }
                fulfillmentDefintions={[
                  ...fulfillmentDefinitionOptions,
                  { label: "unset defintion", value: "unset" },
                ]}
                fulfillmentDefinitionId={order.fulfillmentDefinitionId}
                path={`/orders/${match.params.id}/states`}
              />
            )}
          </Route>
          <Route path={`/orders/${match.params.id}/events`}>
            <OrderEvent
              flows={flows}
              updateFulfillmentName={(id, diveshopId, _fulfillmentLevel) => {
                onUpdateOrderline(id, diveshopId);
              }}
              setNew={async (eventId, state) => {
                try {
                  await setEventState(eventId, state);
                  message.success(`State was successfully changed to ${state}`);
                  loadEvents();
                } catch (err) {
                  if (err instanceof APIError) {
                    message.error(err.message);
                  }
                }
              }}
              loadEvent={loadEvents}
              venues={venueArray}
              fulfillmentNames={fulfillments.list.data.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              setState={(id, _fulfillmentLevel, status, w3w, images) => {
                onSetState(id, status, w3w, images);
              }}
              orderlines={orderlines}
              fulfillmentLevel={order.fulfillmentLevel}
              onSaveLoad={(eventId, load) =>
                onUpdateEvent(eventId, { loads: load })
              }
              onChangeEventState={async (eventId) => {
                try {
                  await setEventState(eventId, "completed");
                  message.success(
                    "State was successfully changed to fulfilled"
                  );
                  loadEvents();
                } catch (err) {
                  if (err instanceof APIError) {
                    message.error(err.message);
                  }
                }
              }}
              teams={teams.list.data.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              updateTeam={(eventId, teamId) =>
                onUpdateEvent(eventId, { fulfillmentTeamId: teamId })
              }
              updateAsset={async (e: FulfillmentEvent, id: string) => {
                onUpdateEvent(
                  e.id,
                  { assetId: id },
                  "Asset was successfully updated"
                );
              }}
              saveLoc={(p, t, g) => {
                onCreateLocation(p, t, g);
              }}
              updateFulfiller={(eventId, fulfillerId) =>
                onUpdateEvent(eventId, { fulfillerId: fulfillerId })
              }
              assets={assets.list.data}
              path={`/orders/${match.params.id}/events`}
              onDeleteEvent={async (id) => {
                try {
                  await deleteEvent(id);
                  message.success("Event deleted successfully");
                  loadEvents();
                  load();
                } catch (err) {
                  if (err instanceof APIError) {
                    message.error(err.message);
                  }
                }
              }}
              onDeletePlacement={async (e: FulfillmentEvent, id: string) => {
                if (id) {
                  const newLocationArray = e.placements.filter(
                    (item) => item.id !== id
                  );
                  e.placements = newLocationArray;
                  try {
                    await updateEvent(e);
                    message.success("Placement deleted successfully");
                    loadEvents();
                    load();
                  } catch (err) {
                    if (err instanceof APIError) {
                      message.error(err.message);
                    }
                  }
                }
              }}
              onEditPlacement={async (event, placement) => {
                if (placement.id) {
                  const index = event.placements.findIndex((object) => {
                    return object.id === placement.id;
                  });
                  if (index !== -1) {
                    event.placements[index] = placement;
                    try {
                      await updateEvent(event);
                      message.success(t("Placement updated successfully"));
                      load();
                      loadEvents();
                    } catch (err) {
                      if (err instanceof APIError) {
                        message.error(err.messages);
                      }
                    }
                  }
                  return;
                } else
                  try {
                    event.placements = [...event.placements, placement];
                    await updateEvent(event);
                    message.success("Placement was addedd successfully");
                    load();
                    loadEvents();
                  } catch (err) {
                    if (err instanceof APIError) {
                      message.error(err.messages);
                    }
                  }
              }}
              events={fEvents}
              id={order.id}
              onEditEvent={async (event) => {
                try {
                  await updateEvent(event);
                  message.success("Event updated successfully");
                  load();
                  loadEvents();
                } catch (err) {
                  if (err instanceof APIError) {
                    message.error(err.message);
                  }
                }
              }}
              onCreateEvent={async (event, orderlineId) => {
                try {
                  if (orderlineId) {
                    await createOrderlineEvent(event, orderlineId);
                  } else await createOrderEvent(event, match.params.id);

                  message.success("Event was created successfully");
                  load();
                  loadEvents();
                } catch (err) {
                  if (err instanceof APIError) {
                    message.error(err.message);
                  }
                }
              }}
              description={`Order ${order.shortId}`}
            />
          </Route>
          <Route path={`/orders/${match.params.id}/passes`}>
            <OrderPasses passes={passes} onSharePasses={() => {}} />
          </Route>
          <Route path={`/orders/${match.params.id}/history`}>
            <OrderHistory
              error={weebHookError}
              webHookNotifications={webhookNot}
              orderHistory={[]}
              clickUser={async (id) => {
                history.push(`/users/${id}`);
              }}
            />
          </Route>
        </TabNavBar>
      </div>
    </LayouWithSideBar>
  );
};

export default OrderDetail;
