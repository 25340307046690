
import React from "react";
import Text from "../Text";
import warningIcon from "../../assets/warning.svg";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { Modal } from "../Modal/Modal";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { InputError } from "../Inputs/InputError/InputError";
import { ContainerTitle } from "../ContainerTitle";

interface Props {
  onClose: () => void;
  open: boolean;
  loading?: boolean;
  error?: string;
  username: string;
  disable: boolean;
}

const FulfillerStateModal: React.FC<Props> = ({
  onClose,
  open,
  error,
  loading,
  username,
  disable,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Modal
        width="600px"
        buttons={
          <div>
            <ButtonsContainer justifyContent="flex-end">
              <Button
                onClick={onClose}
                theme="white"
                label={t("component.disableDiverModal.cancel")}
              />
              <Button
                theme="blue"
                label={
                  loading
                    ? "..."
                    : disable
                    ? t("component.disableDiverModal.disable")
                    : t("component.disableDiverModal.enable")
                }
              />
            </ButtonsContainer>
            {error ? (
              <FlexContainer justifyContent="flex-end" padding="0.5em 1em 0 0">
                <InputError error={error} />
              </FlexContainer>
            ) : null}
          </div>
        }
        open={open}
        onClose={onClose}
      >
        <div className={styles.modalContainer}>
          <FlexContainer flexDirection="column">
            <img src={warningIcon} alt="" className={styles.icon} />
            <ContainerTitle
              padding="1em 0 1em 0"
              size="small"
              label={
                disable
                  ? t("component.disableDiverModal.disableDiver")
                  : t("component.disableDiverModal.enableDiver")
              }
            />
            <Text
              padding="0em 0 0 0"
              text={
                disable
                  ? t("component.disableDiverModal.warning")
                  : t("component.disableDiverModal.positiveWarning")
              }
              color="#000000"
              fontSize="1.1em"
              fontWeight={400}
            />
            <Text
              padding="0em 0 2em 0"
              text={`${username} ?`}
              color="#000000"
              fontSize="1.1em"
              fontWeight={700}
            />
          </FlexContainer>
        </div>
      </Modal>
    </div>
  );
};

export default FulfillerStateModal;
