import React from "react";
import styles from "./styles.module.css";
import logo from "../../assets/logo.svg";

interface Props {
  children?: React.ReactNode | React.ReactNode[];
  logoIcon?: string;
}

const Layout: React.FC<Props> = ({ children, logoIcon }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.logoContainer}>
          {logoIcon ? (
            <img src={logoIcon} alt="" className={styles.logo} />
          ) : (
            <img src={logo} alt="" className={styles.logo} />
          )}
        </div>
      </div>
      <div className={styles.containerLayout}>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
