import React, { useState } from "react";
import FulfillerItem from "./FulfillerItem";
import { OrderListSkeleton } from "../ListSkeletons";
import { Fulfiller, Team } from "@innomius/ravent-typescript-types";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { PaginationRavent } from "../Pagination/Pagination";
import { Role } from "../../services/roles";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { ListHeader } from "../List/ListHeader";
import { List } from "../List";

interface EntityProps {
  error: string;
  loading: boolean;
  data: Fulfiller[];
  entityId: string;
  total: number;
  hitsPerPage: number;
  onPageChange: (page: number) => void;
  onHitsPerPageChange: (hitsPerPage: number) => void;
  onChangeSortOrder?: (value: string) => void;
  page: number;

  sortable: boolean;
  pagination?: boolean;
  onDetails: (id: string) => void;
  onSendMagicLink?: () => void;
  path: string;
  onChangeUserFromTeam: (id: string, teamId: string) => void;
  onDeleteUser: (id: string) => void;
  loadingFulfiller: string;
  onCancel: () => void;
  edit: string;
  setEdit: (edit: string) => void;
  roles: Role[];
  teams: Team[];
  onSave: (data: Fulfiller) => Promise<void>;
  selectedUsers: {
    id: string;
    name: string;
    loginWithPhone: boolean;
    phone?: string;
    email?: string;
  }[];
  setSelectedUsers: (value: any) => void;
  resetPassword: (username: string) => void;
}

const FulfillerList: React.FC<EntityProps> = ({
  error,
  loading,
  data,
  onCancel,
  loadingFulfiller,

  page,
  total,
  teams,
  hitsPerPage,
  onHitsPerPageChange,
  onPageChange,
  onDetails,
  onSendMagicLink,
  path,
  onChangeUserFromTeam,
  roles,
  onDeleteUser,
  onSave,
  edit,
  setEdit,
  selectedUsers,
  setSelectedUsers,

  onChangeSortOrder,
  resetPassword,
}) => {
  const { t } = useTranslation();
  const [order, setOrder] = useState<string>("asc");
  const [sort, setSort] = useState<string | null>(null);

  const [selectAll] = useState(false);
  const handleCheckboxChange = (
    userId: string,
    name: string,
    loginWithPhone: boolean,
    phone: string,
    email: string
  ) => {
    const isSelected = selectedUsers.some((loc) => loc.id === userId);

    if (selectAll) {
      setSelectedUsers((prevSelected: any[]) => {
        if (isSelected) {
          return prevSelected.filter((loc) => loc.id !== userId);
        } else {
          return [
            ...prevSelected,
            {
              id: userId,
              name: name,
              loginWthPhone: loginWithPhone,
              phone,
              email,
            },
          ];
        }
      });
    } else {
      setSelectedUsers((prevSelected: any[]) => {
        if (isSelected) {
          return prevSelected.filter((loc) => loc.id !== userId);
        } else {
          return [
            ...prevSelected,
            { id: userId, name: name, loginWithPhone, phone, email },
          ];
        }
      });
    }
  };

  const header = (
    <div>
      <ListHeader template="2em 0.3fr 0.5fr 1fr 0.7fr 1.5fr 0.5fr">
        <div />
        <ListHeaderLabel
          value={t("component.userList.externalId")}
          justifyContent="flex-start"
          sortable
          sort={
            sort === "externalId" ? (order === "desc" ? "asc" : "desc") : null
          }
          onClick={() => {
            setSort("externalId");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`externalId:${order}`);
            }
          }}
        />
        <ListHeaderLabel
          value={t("component.userList.createdAt")}
          justifyContent="flex-start"
          sortable
          sort={
            sort === "createdAt" ? (order === "desc" ? "asc" : "desc") : null
          }
          onClick={() => {
            setSort("createdAt");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`createdAt:${order}`);
            }
          }}
        />
        <ListHeaderLabel
          value={t("component.userList.user")}
          justifyContent="flex-start"
          sortable
          sort={sort === "name" ? (order === "desc" ? "asc" : "desc") : null}
          onClick={() => {
            setSort("name");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`name:${order}`);
            }
          }}
        />

        <ListHeaderLabel
          value={t("component.userList.role")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.userList.tags")}
          justifyContent="flex-start"
        />
        <div />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <List
        loading={loading}
        loadingElement={() => <OrderListSkeleton />}
        error={error}
        data={data}
        keyExtractor={(item) => item.id}
        renderHeader={() => header}
        renderItem={(item) => (
          <FulfillerItem
            resetPassword={resetPassword}
            path={path}
            data={item}
            onCheckboxChange={() =>
              handleCheckboxChange(
                item.id,
                item.lastName,
                item.loginWithPhone,
                item.phone,
                item.email
              )
            }
            isSelected={selectedUsers.some((loc) => loc.id === item.id)}
            teams={teams}
            edit={edit}
            setEdit={setEdit}
            onSave={onSave}
            onCancel={onCancel}
            loading={loadingFulfiller}
            onChangeUserFromTeam={onChangeUserFromTeam}
            roles={roles}
            onSendMagicLink={onSendMagicLink}
            onDetails={(id) => onDetails(id)}
            key={item.id}
            onDelete={onDeleteUser}
          />
        )}
      />

      <div className={styles.paginationContainer}>
        <PaginationRavent
          current={page + 1}
          defaultCurrent={page + 1}
          total={total}
          showSizeChanger
          onShowSizeChange={(page, size) => {
            if (onHitsPerPageChange) {
              onHitsPerPageChange(size);
            }
          }}
          onChange={(page) => {
            if (onPageChange) {
              onPageChange(page - 1);
            }
          }}
          pageSize={hitsPerPage}
        />
      </div>
    </div>
  );
};

export default FulfillerList;
