const translation = {
  en: {
    invoices: "Invoices",
    events: "Events",
    hash: "Order Secret Key",
    secretKey: "Secret Key",
    others: "Other Integrations",
  },
  es: {
    invoices: "Facturas",
    events: "Eventos",
    addIntegration: "+Agregar Integración",
    others: "Otras Integraciones",
  },
  de: {},
};

export default translation;
