
import React from "react";
import { useTranslation } from "react-i18next";
import { TeamOverview } from "../../utils/types";
import TeamList from "../TeamList";
import PieCharOverview from "./PieChartOverview";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { Card } from "../Card/Card";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";

interface Props {
  teams: TeamOverview[];
  error: string;
  loading: boolean;
  to: string;
  from: string;
  onChangeSortOrder?: (sort: string) => void;

}

const TeamOverviewC: React.FC<Props> = ({
  to,
  from,
  teams,
  error,
  loading,
  onChangeSortOrder
}) => {
  const { t } = useTranslation();
  return (
    <Card>
      <FlexContainer
        padding="0 0 1em 0"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <ContainerTitle
          size="big"
          label={t("container.dashboard.teams")}
          count={loading ? "" : teams.length.toString()}
        />
      </FlexContainer>
      <GridContainer gap="1em" columns="1fr 2fr">
        <PieCharOverview teams={teams} error={error} loading={loading} />

        <TeamList
          to={to}
          from={from}
          data={teams}
          onChangeSortOrder={onChangeSortOrder}
          error={error}
          loading={loading}
        />
      </GridContainer>
    </Card>
  );
};

export default TeamOverviewC;
