import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { TeamOverview } from "../utils/types";
import { OverviewParams, getTeamsOverviews } from "../services/entities";

type TextSearchSlice = {
  loading: boolean;
  error: string;
  teams: TeamOverview[];
};

const initialState: TextSearchSlice = {
  loading: true,
  error: "",
  teams: [],
};

export const eventTeamOverview = createAsyncThunk(
  "eventTeamOverview/list",
  async (params: OverviewParams) => {
    const response = await getTeamsOverviews(params);
    return response;
  }
);

const eventTeamOverviewSlice = createSlice({
  name: "eventTeamOverview",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(eventTeamOverview.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(eventTeamOverview.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.teams = action.payload;
    });
    builder.addCase(eventTeamOverview.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An unexpected error ocurred";
    });
  },
});

export default eventTeamOverviewSlice.reducer;
