import { stylesheet } from "typestyle";
import { lightGrey } from "../../utils/colors";

const styles = stylesheet({
  colorContainer: {
    borderRadius: 4,
    border: `1px solid ${lightGrey}`,
    padding: "0.2em",
  },
  colorBox: {
    borderRadius: 2,
    width: "16px",
    height: "16px",
  },
  scroll: {
    overflowY: "auto",
    height: "70%",
    marginTop: "1em",
    paddingBottom: "5em",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.5em",
  },
  icon: {
    width: "1.5em",
    marginRight: "0.5em",
  },
});

export default styles;
