// export const getOperatorsForType = (type: string) => {
//   switch (type) {
//     case "text":
//       return [
//         { name: "=", label: "is" },
//         { name: "notEquals", label: "is not" },
//         { name: "contains", label: "contains" },
//         { name: "notContains", label: "does not contain" },
//         { name: "beginsWith", label: "begins with" },
//         { name: "endsWith", label: "ends with" },
//       ];
//     case "boolean":
//       return [{ name: "=", label: "is" }];
//     case "date":
//       return [
//         { name: "=", label: "on" },
//         { name: "<", label: "before" },
//         { name: ">", label: "after" },
//         { name: "notEquals", label: "not on" },
//       ];
//     case "number":
//       return [
//         { name: "=", label: "equals" },
//         { name: "<", label: "less than" },
//         { name: ">", label: "greater than" },
//         { name: "notEquals", label: "not equals" },
//       ];

//     default:
//       return [{ name: "=", label: "equals" }]; // Fallback operator
//   }
// };
export const getOperatorsForType = (type: string) => {
  switch (type) {
    case "text":
      return [
        { name: "=", label: "is" }, // Maps to `match` or `term` in ES
        { name: "!=", label: "is not" }, // Maps to `must_not` + `term`
        { name: "contains", label: "contains" }, // Maps to `match`
        { name: "notContains", label: "does not contain" }, // Maps to `must_not` + `match`
        { name: "beginsWith", label: "begins with" }, // Maps to `prefix`
        { name: "endsWith", label: "ends with" }, // Maps to `wildcard`
      ];
    case "boolean":
      return [{ name: "=", label: "is" }]; // Maps to `term` in ES
    case "date":
      return [
        { name: "=", label: "on" }, // Maps to `term` for exact date
        { name: "<", label: "before" }, // Maps to `range` with `lt`
        { name: ">", label: "after" }, // Maps to `range` with `gt`
        { name: "!=", label: "not on" }, // Maps to `must_not` + `term`
      ];
    case "number":
      return [
        { name: "=", label: "equals" }, // Maps to `term` for exact number
        { name: "<", label: "less than" }, // Maps to `range` with `lt`
        { name: ">", label: "greater than" }, // Maps to `range` with `gt`
        { name: "!=", label: "not equals" }, // Maps to `must_not` + `term`
      ];

    default:
      return [{ name: "=", label: "equals" }]; // Fallback to `term` in ES
  }
};

export const determineFieldType = (
  type: string
): "text" | "number" | "date" | "boolean" | "keyword" => {
  switch (type) {
    case "date":
      return "date";
    case "number":
    case "integer":
    case "long":
      return "number";
    case "boolean":
      return "boolean";
    case "keyword":
    case "text":
      return "text";
    default:
      return "text"; // Default to string for keyword/text
  }
};
