import React from "react";
import styles from "./styles";
interface Props {
  color: string;
}

const ColorTag: React.FC<Props> = ({ color }) => {
  return (
    <div className={styles.colorContainer}>
      <div
        className={styles.colorBox}
        style={{ backgroundColor: color }}
      />
    </div>
  );
};

export default ColorTag;
