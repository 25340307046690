import { stylesheet } from "typestyle";
import { black, linkBlue } from "../../../utils/colors";

const styles = stylesheet({
  container: {
    padding: "2em",
  },
  title: {
    fontSize: "1.5em",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "left",
    color: "#141322",
    paddingTop: "1em",
  },

  filtersContainer: {
    paddingBottom: "2em",
  },
  filtersContainerW: {
    paddingBottom: "1.5em",
  },

  mapContainer: {
    height: "150px",
    width: "100%",
  },

  loading: {
    color: black,
    fontWeight: 600,
    padding: "6em 1em",
  },

  labelContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    marginRight: "0.5em",
    padding: "1em",
  },
  label: {
    margin: "0.2em 0.5em 0.2em 0",
    width: "auto",
    color: linkBlue + " !important",
  },

  center: {
    color: black,
    fontStyle: "normal",
    justifyContent: "flex-start",
    padding: "1em",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    marginRight: "0.5em",
  },

  spaceBetween: {
    color: black,
    fontStyle: "normal",
    justifyContent: "space-evenly",
    padding: "1em",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
  },
  normal: {
    padding: "1em",
    color: black,
    display: "flex",
    alignItems: "center",
    fontSize: "1em",
    fontWeight: 500,
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    width: 23,
  },
  iconDelete: {
    width: 32,
  },
  link: {
    textDecoration: "none",
  },
  paginationContainer: {
    padding: "2em 0",
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  listContainer: {
    marginTop: "0",
  },
  listLabel: {
    padding: "0.5em 0 0.5em 1.5em",
  },
});

export default styles;
