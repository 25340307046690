import { stylesheet } from "typestyle";
import { black } from "../../utils/colors";

const styles = stylesheet({
  container: {
    padding: "2em",
  },
  title: {
    fontSize: "1.5em",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "left",
    color: "#141322",
    paddingTop: "1em",
  },
  justy: {
    fontSize: "1em",
    fontWeight: 700,
    alignItems: "center",
    display: "flex",
    justifyContent: 'flex-start',
    color: "#333333",
    padding: "0 0.5em",
  },
  searchRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "1em",
  },
  center: {
    color: black,
    fontStyle: "normal",
    justifyContent: "space-evenly",
    padding: "1em",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    marginRight: "0.5em",
  },
  normal: {
    fontSize: "1em",
    fontWeight: 600,
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    padding: "0 0.5em",
  },
  flexStart: {
    fontSize: "1em",
    fontWeight: 600,
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    padding: "0 0.5em",
  },
  radioContainer: {
    marginRight: "3em",
    padding: "0.5em 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  wrapper: {
    padding: "0em 2em",
  },
  mcontainer: {
    $nest: {
      "div > div": {
        alignItems: "center !important",
      },
    },
  },
  start: {
    fontSize: "1em",
    fontWeight: 600,
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    display: "flex",
    padding: "0 0.5em",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    width: 23,
  },
  iconBig: {
    width: 30,
  },
  link: {
    textDecoration: "none",
  },
  paginationContainer: {
    padding: "2em 0",
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
  },
  listContainer: {
    marginTop: "1em",
    boxShadow: "0px 4px 7px rgba(0, 0, 0, 0.1)",
  },
});

export default styles;
