import React from "react";
import { Card } from "../Card/Card";
import { FlexContainer } from "../FlexContainer/FlexContainer";

import styles from "./styles";
import { useTranslation } from "react-i18next";
import { Team } from "@innomius/ravent-typescript-types";
import Text from "../Text";
import { Switch, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { GridContainer } from "../GridContainer/GridContainer";
import { ContainerTitle } from "../ContainerTitle";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
import { Tag } from "../Tag/Tag";
import Editor from "@monaco-editor/react";

// import { Checkbox } from "antd";

interface Props {
  data: Team;
}

const TeamDetailComponent: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const stringBody = JSON.stringify(data.data);

  return (
    <div className={styles.container}>
      <Card>
        <div className={styles.wrapperCard}>
          <ContainerTitle
            padding="0 0 1em 0"
            size="small"
            label={t("component.entityDetail.details")}
          />
          <div>
            <GridContainer gap="1em" columns="1fr 1fr 1fr">
              <FlexContainer alignItems="center" justifyContent="flex-start">
                <Switch checked={data.enabled} />
                <p className={styles.label}>
                  {t("component.entityDetail.enabled")}
                </p>
              </FlexContainer>
              <FlexContainer alignItems="center" justifyContent="flex-start">
                <Switch checked={data.internal} />
                <p className={styles.label}>
                  {t("component.entityDetail.internal")}
                </p>
              </FlexContainer>
            </GridContainer>
          </div>
          <ContainerTitle
            padding="2em 0 0.5em 0"
            size="small"
            label={t("component.entityForm.maskingTemplates")}
          />
          <Text
            padding="0 0 2em 0"
            text={t("component.entityForm.description")}
          />
          <GridContainer padding="0 0 1em 0" gap="2em" columns="2fr 1fr">
            <div>
              <FlexContainer justifyContent="flex-start">
                <InputLabel label={t("component.entityDetail.orders")} />
                <Tooltip
                  title={
                    data.maskingTemplates.orders.type === "excludes"
                      ? t("component.entityDetail.infoOrderE")
                      : t("component.entityDetail.infoOrderI")
                  }
                >
                  <InfoCircleOutlined style={{ marginLeft: "0.5em" }} />{" "}
                </Tooltip>
              </FlexContainer>
              {data.maskingTemplates.orders.fields ? (
                <FlexContainer
                  flexWrap="wrap"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {data.maskingTemplates.orders.fields.map((item) => (
                    <div style={{ marginRight: "0.5em", marginTop: "0.5em" }}>
                      <Tag padding="0.2em 0.7em" label={item} theme="blue" />
                    </div>
                  ))}
                </FlexContainer>
              ) : (
                <Text text="Not Configured" />
              )}
            </div>
            <div>
              <InputLabel label={t("component.entityDetail.type")} />
              <Text
                text={
                  data.maskingTemplates.orders.fields
                    ? data.maskingTemplates.orders.type
                    : "Not Configured"
                }
              />
            </div>
            <div>
              <FlexContainer justifyContent="flex-start">
                <InputLabel label={t("component.entityDetail.orderlines")} />
                <Tooltip
                  title={
                    data.maskingTemplates.orderlines.type === "excludes"
                      ? t("component.entityDetail.infoLinesE")
                      : t("component.entityDetail.infoLinesI")
                  }
                >
                  <InfoCircleOutlined style={{ marginLeft: "0.5em" }} />{" "}
                </Tooltip>
              </FlexContainer>
              {data.maskingTemplates.orderlines.fields ? (
                <FlexContainer
                  flexWrap="wrap"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {data.maskingTemplates.orderlines.fields.map((item) => (
                    <div style={{ marginRight: "0.5em", marginTop: "0.5em" }}>
                      <Tag padding="0.2em 0.7em" label={item} theme="blue" />
                    </div>
                  ))}
                </FlexContainer>
              ) : (
                <Text text="Not Configured" />
              )}
            </div>
            <div>
              <InputLabel label={t("component.entityDetail.type")} />
              <Text
                text={
                  data.maskingTemplates.orderlines.fields
                    ? data.maskingTemplates.orderlines.type
                    : "Not Configured"
                }
              />
            </div>
            <div>
              <FlexContainer justifyContent="flex-start">
                <InputLabel label={t("component.entityDetail.invoices")} />
                <Tooltip
                  title={
                    data.maskingTemplates.invoices.type === "excludes"
                      ? t("component.entityDetail.infoInvoiceE")
                      : t("component.entityDetail.infoInvoiceI")
                  }
                >
                  <InfoCircleOutlined style={{ marginLeft: "0.5em" }} />{" "}
                </Tooltip>{" "}
              </FlexContainer>
              {data.maskingTemplates.invoices.fields ? (
                <FlexContainer
                  flexWrap="wrap"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {data.maskingTemplates.invoices.fields.map((item) => (
                    <div style={{ marginRight: "0.5em", marginTop: "0.5em" }}>
                      <Tag padding="0.2em 0.7em" label={item} theme="blue" />
                    </div>
                  ))}
                </FlexContainer>
              ) : (
                <Text text="Not Configured" />
              )}
            </div>
            <div>
              <InputLabel label={t("component.entityDetail.type")} />
              <Text
                text={
                  data.maskingTemplates.invoices.fields
                    ? data.maskingTemplates.invoices.type
                    : "Not Configured"
                }
              />
            </div>
          </GridContainer>
          <ContainerTitle
            padding="1em 0 1em 0"
            size="small"
            label={t("component.entityDetail.data")}
          />

          <div className={styles.editorDetails}>
            <Editor
              className={styles.editorBox}
              height="100%"
              // theme="vs-dark"
              defaultLanguage="json"
              defaultValue={stringBody}
              options={{
                readOnly: true,
                selectOnLineNumbers: true,
                // minimap: { enabled: false },
                scrollbar: {
                  vertical: "hidden",
                },
                overviewRulerLanes: 0,
                hideCursorInOverviewRuler: true,
                overviewRulerBorder: false,
                foldingHighlight: false,
              }}
            />
          </div>

          {/* <ContainerTitle
            padding="1em 0 1em 0"
            size="small"
            label={t("component.entityForm.roles")}
          />
          <FlexContainer justifyContent="flex-start">
            {options?.map((item) => (
              <FlexContainer padding="0 2em 0 0" justifyContent="flex-start">
                <Checkbox
                  disabled
                  value={item.label}
                  checked={
                    data?.roles?.find((i) => item.value === i) ? true : false
                  }
                />
                <Text padding="0 0 0 1em" text={item.label} />
              </FlexContainer>
            ))}
          </FlexContainer> */}
        </div>
      </Card>
    </div>
  );
};

export default TeamDetailComponent;
