import React from "react";
import MapMarkersAsset from "../MapMarkersAsset";
import { AssetMapResponse } from "../../services/assets";

interface Props {
  data: AssetMapResponse;
}

const AssetMapView: React.FC<Props> = ({ data }) => {
  const runningMarkers = data.running
    .filter(
      (item) =>
        item.location &&
        item.location.coordinates &&
        item.location.coordinates.latitude !== undefined &&
        item.location.coordinates.longitude !== undefined &&
        item.location.coordinates.latitude !== null &&
        item.location.coordinates.longitude !== null &&
        item.location.coordinates.latitude !== "" &&
        item.location.coordinates.longitude !== ""
    )
    .map((item) => ({
      lat: parseFloat(item.location.coordinates.latitude || "0"),
      lng: parseFloat(item.location.coordinates.longitude || "0"),
      name: item.name || item.id,
      id: item.id,
      externalId: item.externalId,
      type: item.type,
      eventData: item.eventData,
      responseType: "running",
    }));

  const notRunningMarkers = data.notRunning
    .filter(
      (item) =>
        item.location &&
        item.location.coordinates &&
        item.location.coordinates.latitude !== undefined &&
        item.location.coordinates.longitude !== undefined &&
        item.location.coordinates.latitude !== null &&
        item.location.coordinates.longitude !== null &&
        item.location.coordinates.latitude !== "" &&
        item.location.coordinates.longitude !== ""
    )
    .map((item) => ({
      lat: parseFloat(item.location.coordinates.latitude || "0"),
      lng: parseFloat(item.location.coordinates.longitude || "0"),
      name: item.name || item.id,
      id: item.id,
      externalId: item.externalId,
      type: item.type,
      eventData: {},
      responseType: "not-running",
    }));

  const allMarkers = [...runningMarkers, ...notRunningMarkers];
  return <MapMarkersAsset markers={allMarkers} />;
};

export default AssetMapView;
