import React, { useState } from "react";
import { RefundRequest } from "../../utils/types";

import { Order } from "@innomius/ravent-typescript-types";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import InputDate from "../Inputs/Date";
import { SideMenu } from "../SideMenu/SideMenu";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { Button } from "../Button";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputText } from "../Inputs/InputText";
import InputRadio from "../Inputs/InputRadio";

interface Props {
  onCancel: () => void;
  open: boolean;
  order: Order;
}

const defaultRefundRequest: RefundRequest = {
  type: "total",
  requestDate: "",
  paymentId: "",
  penaltyPercentage: "0",
  refundAmount: "",
  deliveryMethod: "",
  phone: "",
  email: "",
  reason: "",
};

const RefundForm: React.FC<Props> = ({ open, onCancel, order }) => {
  const [refund, setRefund] = useState(defaultRefundRequest);
  const [otherReason, setOtherText] = useState(false);

  const onChange = <P extends keyof RefundRequest>(
    prop: P,
    value: RefundRequest[P]
  ) => {
    setRefund({ ...refund, [prop]: value });
  };

  const { t } = useTranslation();

  return (
    <SideMenu open={open} onClose={onCancel}>
      <div className={styles.container}>
        <FlexContainer justifyContent="space-between" padding="0 0 2em 0">
          <ContainerTitle
            label={t("component.refundForm.refundPayment")}
            size="medium"
          />
          <FlexContainer justifyContent="flex-end">
            <Button
              onClick={onCancel}
              containerClassname={styles.button}
              theme="white"
              label={t("component.refundForm.cancel")}
            />
            <Button
              onClick={() => {}}
              theme="blue"
              label={t("component.refundForm.issue")}
            />
          </FlexContainer>
        </FlexContainer>

        <div className={styles.text}>
          {t("component.refundForm.instructions")} {order.currency}{" "}
          {order.paidAmount}.
        </div>
        <GridContainer
          padding="0.5em 0 1em 0"
          gap="1em"
          columns="1.1fr 1fr 1fr 1fr"
        >
          <InputDate
            id="requestedDate"
            label={t("component.refundForm.expiredDate")}
          />
          <InputText
            label={t("component.refundForm.penalty")}
            value={refund.penaltyPercentage}
            onChange={(value) => onChange("penaltyPercentage", value)}
          />
          <InputText
            label={t("component.refundForm.penaltyAmount")}
            value={
              (order.paidAmount *
                parseInt(refund.penaltyPercentage, 10)) /
              100
            }
          />
          <InputText
            label={t("component.refundForm.finalRefundAmount")}
            value={
              order.paidAmount -
              (order.paidAmount *
                parseInt(refund.penaltyPercentage, 10)) /
                100
            }
          />
        </GridContainer>
        <p className={styles.section}>Refund Information Method</p>
        {/* {order.payments.map((item) => (
          <>
            <GridContainer
              gap="1em"
              justifyContent="flex-start"
              alignItems="center"
              columns="3em 1fr 1fr 2fr 6em 1fr"
            >
              <img src={paymentTypeIconMap[item.paymentType]} alt="" />
              <div>{item.type}</div>
              <div>{item.id}</div>
              <FlexContainer
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <p>{paymentDetails(item)}</p>
              </FlexContainer>
              <p>
                {item.amount.currency} {item.amount.value.toString()}
              </p>
              <InputText
                value={
                  order.paidAmount.value -
                  (order.paidAmount.value *
                    parseInt(refund.penaltyPercentage, 10)) /
                    100
                }
              />
            </GridContainer>
          </>
        ))} */}
        <GridContainer
          justifyContent="flex-start"
          padding="1em 0"
          gap="1em"
          columns="1fr 1fr"
        >
          <div>
            <p className={styles.title}>
              {t("component.refundForm.refundMethod")}
            </p>
            <InputRadio
              onChange={() => setOtherText(false)}
              containerClassname={styles.margin}
              value={t("component.refundForm.wrongAmount")}
              name="reasons"
              text={t("component.refundForm.wrongAmount")}
            />
            <InputRadio
              containerClassname={styles.margin}
              value={t("component.refundForm.cancelled")}
              name="reasons"
              text={t("component.refundForm.cancelled")}
              onChange={() => setOtherText(false)}
            />
            <InputRadio
              onChange={() => setOtherText(true)}
              containerClassname={styles.margin}
              value={t("component.refundForm.other")}
              name="reasons"
              text={t("component.refundForm.other")}
            />
            {otherReason ? (
              <InputText
                value={refund.reason}
                onChange={(value) => onChange("reason", value)}
              />
            ) : null}
          </div>
          <></>
        </GridContainer>
      </div>
    </SideMenu>
  );
};

export default RefundForm;
