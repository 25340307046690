import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TextSearchListEvent } from "../services/orders";
import {
  LocationsListParamsFTS,
  locationsFullTextSearch,
} from "../services/venues";

type LocationTextSearchSlice = {
  loading: boolean;
  error: string;
  locations: TextSearchListEvent;
};

const initialState: LocationTextSearchSlice = {
  loading: true,
  error: "",
  locations: {
    page: 0,
    count: 0,
    hits: [],
    facets: [],
  },
};

export const locationTextSearchList = createAsyncThunk(
  "locationTextSearch/list",
  async (params: LocationsListParamsFTS) => {
    const response = await locationsFullTextSearch(params);
    return response;
  }
);

const locationTextSearchSlice = createSlice({
  name: "locationTextSearch",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(locationTextSearchList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(locationTextSearchList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.locations = action.payload;
    });
    builder.addCase(locationTextSearchList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An unexpected error ocurred";
    });
  },
});

export default locationTextSearchSlice.reducer;
