import React from "react";
import styles from "./styles";
import { Discount, Surcharge, Tax } from "@innomius/ravent-typescript-types";
import { numberFormat } from "../../utils/format";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { GridContainer } from "../../components/GridContainer/GridContainer";

interface Props {
  number: number;
  description: string;
  taxes: Tax[];
  discounts: Discount[];
  surcharges: Surcharge[];
  amount: number;
  currency: string;
  totalAmount: number;
}

const Item: React.FC<Props> = ({
  number,
  description,
  taxes,
  discounts,
  surcharges,
  amount,
  currency,
  totalAmount,
}) => {
  return (
    <div className={styles.itemContainer}>
      <GridContainer gap="0.5em" columns="25px 1fr">
        <div className={styles.numberBox}>{number}</div>
        <div style={{ width: "100%" }}>
          <FlexContainer
            padding="0 0 0.5em 0"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <div className={styles.textItem}>{description}</div>
            <FlexContainer justifyContent="flex-end" alignItems="center">
              <div
                style={{ paddingRight: "0.2em" }}
                className={styles.textItem}
              >{`${numberFormat(amount)} `}</div>
              <div className={styles.textItem}>{` ${currency}`}</div>
            </FlexContainer>
          </FlexContainer>
          {discounts.map((item) => (
            <FlexContainer justifyContent="space-between" alignItems="center">
              <div className={styles.textGray}>{item.description}</div>
              <FlexContainer justifyContent="flex-end" alignItems="center">
                <div className={styles.textGray}>{`${numberFormat(
                  item.value
                )} ${currency}`}</div>
              </FlexContainer>
            </FlexContainer>
          ))}
          {taxes.map((item) => (
            <FlexContainer
              padding="0.3em 0 0 0"
              justifyContent="space-between"
              alignItems="center"
            >
              <div className={styles.textGray}>{item.name}</div>
              <div className={styles.textGray}>{`${numberFormat(
                item.value
              )} ${currency}`}</div>
            </FlexContainer>
          ))}
          {surcharges.map((item) => (
            <FlexContainer
              padding="0.3em 0 0 0"
              justifyContent="space-between"
              alignItems="center"
            >
              <div className={styles.textGray}>{item.name}</div>
              <div className={styles.textGray}>{`${numberFormat(
                item.value
              )} ${currency}`}</div>
            </FlexContainer>
          ))}
          <FlexContainer padding="0.5em 0 0 0" justifyContent="flex-end">
            <div className={styles.textGray}>{`${numberFormat(
              totalAmount
            )} ${currency}`}</div>
          </FlexContainer>
        </div>
      </GridContainer>
    </div>
  );
};

export default Item;
