import React, { useCallback, useEffect, useState } from "react";
import {
  Action,
  FulfillmentDefinition,
  FulfillmentStateDef,
} from "@innomius/ravent-typescript-types";

import DeleteModal from "../DeleteModal";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import { typeIconMap } from "../../utils/fulfillments";
import AddOrderlineState from "../FulfillmentStates/AddOrderlineState";
import FulfillmentStates from "../FulfillmentStates";
import {
  defaultFulfillment,
  defaultOrderState,
  deleteStateFulfillment,
  readFulfillment,
} from "../../services/fulfilments";
import DetailForm from "../FulfillmentForm/DetailForm";
import styles from "./styles.module.css";
import APIError from "../../utils/APIError";
import { useLocation } from "react-router-dom";
import { SideMenu } from "../SideMenu/SideMenu";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { EditableTitle } from "../EditableTitle";
import { ContainerTitle } from "../ContainerTitle";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";

interface Props {
  onClose: () => void;
  onEditFulfillmentDefinition: (data: FulfillmentDefinition) => void;
  onAddState: (
    fulfillment: FulfillmentDefinition,
    data: FulfillmentStateDef,
    edit: boolean,
    index?: number
  ) => void;
  onDeleteFulfillmentState: (id: string) => void;
  load: () => void;
  id: string;
  openModal: boolean;
  actions: Action[];
}

const FulfillmentStateDetail: React.FC<Props> = ({
  onClose,
  onAddState,
  onDeleteFulfillmentState,
  load,
  onEditFulfillmentDefinition,
  id,
  openModal,
  actions,
}) => {
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);

  const [add, setAdd] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");

  const location = useLocation();

  const [fulfillment, setFulfillment] =
    useState<FulfillmentDefinition>(defaultFulfillment);

  const getFulfillment = useCallback(async () => {
    try {
      const res = await readFulfillment(id);
      setFulfillment(res);
    } catch (err) {
      if (err instanceof APIError) {
      }
    }
  }, [id]);

  useEffect(() => {
    if (openModal) {
      getFulfillment();
    }
  }, [getFulfillment, openModal]);

  const onChange = <P extends keyof FulfillmentDefinition>(
    prop: P,
    value: FulfillmentDefinition[P]
  ) => {
    setFulfillment({ ...fulfillment, [prop]: value });
  };

  const { t } = useTranslation();

  const onDeleteState = async (index: number) => {
    try {
      setLoading("delete");
      await deleteStateFulfillment(
        fulfillment,
        index,
        fulfillment.fulfillmentStatesDefinitions
      );
      message.success(t("container.fulfillmentDetail.successDeleteLine"));
      setAdd(false);
      setLoading("");
      load();
    } catch (err) {
      if (err instanceof APIError) {
        setError(err.messages.toString());
        message.error(`Error: ${err.messages}`);
      }
      setLoading("");
    }
  };

  return (
    <SideMenu
      onClose={() => {
        onClose();
        setEdit(false);
      }}
      open={location.pathname === `/fulfillments/${fulfillment.id}`}
    >
      <div>
        <DeleteModal
          open={open}
          loading={loading === "delete"}
          value={t("container.fulfillmentDetail.deleteInstructions")}
          onCancel={() => setOpen(false)}
          onDelete={() => onDeleteFulfillmentState(fulfillment.id)}
        />
        <FlexContainer
          padding="1em 0 1em 0"
          justifyContent="space-between"
          alignItems="center"
        >
          {edit ? (
            <EditableTitle
              titleClassname={styles.title}
              value={fulfillment.name}
              onChange={(value) => onChange("name", value)}
            />
          ) : (
            <FlexContainer alignItems="center" justifyContent="flex-start">
              <img
                className={styles.icon}
                src={typeIconMap[fulfillment.type]}
                alt=""
              />
              <ContainerTitle size="medium" label={fulfillment.name} />
            </FlexContainer>
          )}
          {edit ? (
            <ButtonsContainer>
              <Button
                theme="white"
                label={t("container.fulfillmentDetail.cancel")}
                onClick={() => setEdit(false)}
              />
              <Button
                label={t("container.fulfillmentDetail.save")}
                onClick={() => onEditFulfillmentDefinition(fulfillment)}
              />
            </ButtonsContainer>
          ) : (
            <ButtonsContainer>
              <Button
                theme="red"
                label={t("container.fulfillmentDetail.delete")}
                onClick={() => setOpen(true)}
              />
              <Button
                label={t("container.fulfillmentDetail.edit")}
                onClick={() => setEdit(true)}
              />
            </ButtonsContainer>
          )}
        </FlexContainer>

        {!edit ? (
          <>
            <AddOrderlineState
              data={defaultOrderState}
              loading={loading === "state"}
              onCancel={() => {
                setAdd(false);
              }}
              actions={actions}
              open={add}
              onAdd={(data) => onAddState(fulfillment, data, false)}
              onUpdate={(data, index) =>
                onAddState(fulfillment, data, true, index)
              }
            />
            <FulfillmentStates
              actions={actions}
              type={fulfillment.type}
              onDeleteState={(index) => onDeleteState(index)}
              onEditState={(data, index) =>
                onAddState(fulfillment, data, true, index)
              }
              loadingActions={loading}
              loading={loading === "detail"}
              error={error}
              addState={(data) => onAddState(fulfillment, data, false)}
              openModal={() => setAdd(true)}
              orderLinesStates={fulfillment.fulfillmentStatesDefinitions}
            />
          </>
        ) : (
          <DetailForm data={fulfillment} onChange={onChange} />
        )}
      </div>
    </SideMenu>
  );
};

export default FulfillmentStateDetail;
