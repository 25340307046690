import React from "react";
import { fopIconTypeMap } from "../../utils/fop";
import Text from "../Text";

import styles from "./styles";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { Card } from "../Card/Card";

interface Props {
  type: string;
  onSelectType: (type: string) => void;
  active: boolean;
  supplierName: string;
  supplierService: string;
  id: string;
  account: string;
  alias: string;
}

const ExistingFOP: React.FC<Props> = ({
  type,
  onSelectType,
  active,
  supplierName,
  id,
  supplierService,
  account,
  alias,
}) => {
  return (
    <div className={styles.wrapperCard} onClick={() => onSelectType(id)}>
      <Card containerClassname={active ? styles.active : styles.card}>
        <FlexContainer alignItems="center" justifyContent="flex-start">
          <img src={fopIconTypeMap[type]} alt="" className={styles.icon} />
          <FlexContainer
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="column"
          >
            <Text text={`${supplierName}`} fontSize="1.4em" fontWeight={600} />
            <FlexContainer>
              <Text
                text={`${supplierService}`}
                fontSize="1.1em"
                fontWeight={400}
              />
            </FlexContainer>
            {account ? (
              <Text
                text={`${alias} - ${account}`}
                fontSize="1.1em"
                fontWeight={400}
              />
            ) : null}
          </FlexContainer>
        </FlexContainer>
      </Card>
    </div>
  );
};

export default ExistingFOP;
