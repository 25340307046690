import { stylesheet } from "typestyle";
import {
  black,
  darkBlack,
  kellyGreen,
  lightGrey,
  normalGray,
} from "../../utils/colors";

const styles = stylesheet({
  itemsWrapper: {
    padding: "1em 0",
  },
  containerDetails: {
    padding: "1em 0",
  },
  wrapperCard: {
    padding: "2em",
    marginBottom: "2em",
  },
  title: {
    color: darkBlack,
    fontWeight: "bold",
    fontSize: "1.1em",
  },
  text: {
    fontSize: "1.1em",
    color: darkBlack,
    fontWeight: 400,
    textTransform: "capitalize",
  },
  green: {
    color: kellyGreen,
    fontSize: "0.8em",
  },
  gray: {
    color: normalGray,
    fontSize: "0.8em",
  },
  number: {
    fontSize: "1.1em",
    color: black,
    fontWeight: 400,
  },
  emptyCard: {
    paddingTop: "10%",
  },
  mapContainer: {
    height: "10em",
  },
  imageContainer: {
    borderRadius: "7px",
    height: "11em",
    width: "100%",
  },
  userContainer: {
    width: "2em",
    marginRight: "1em",
  },
  infoContainer: {
    padding: "1em 0",
  },
  infoContainer2: {
    padding: "0 3em",
  },
  imageWrapper: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    backgroundSize: "cover",
  },
  line: {
    width: "100%",
    height: "1px",
    margin: "1em 0 1em 0",
    background: lightGrey,
  },
  day: {
    color: black,
    fontSize: "1.1em",
    fontWeight: 600,
    textTransform: "capitalize",
  },
  hour: {
    color: black,
    fontSize: "1.1em",
    fontWeight: 400,
  },
  iconList: {
    cursor: 'pointer'
  },
  iconShare: {
    marginRight: "1em",
    cursor: 'pointer'
  },
  icon: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "0.9em",
  },

  iconChevron: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "0.9em",
    margin: "1em 0",
  },

  inputContainer: {
    marginLeft: "2em",
  },
  instructions: {
    fontSize: "1.1em",
  },
  numberOfVisitors: {
    fontWeight: 700,
    fontSize: "2.2em",
  },
  visitorsIcon: {
    width: "36px",
    marginRight: "0.5em",
  },
  inputSearchContainer: {
    width: "70%",
  },
  radioContainer: {
    marginRight: "1em",
  },
  label: {
    margin: "0.2em 0.5em 0.2em 0",
    width: "auto",
  },
  titleContainer: {
    minWidth: "21em",
    fontFamily: "Lato",
    padding: "0em",
  },
  titlePrice: {
    textAlign: "right",
    width: "3em",
    fontFamily: "Lato",
    padding: "0em",
  },
  propertyInstructions: {
    padding: '0 0 1.5em 0',
    textAlign: 'justify'
  }
});

export default styles;
