const translation = {
  en: {
    label: "Fulfillment States",
    createNew: "+ Create New Fulfillment State",
    search: "Search",
    instructions: "Start creating States",
    empty: "Add new fulfillment states",
  },
  es: {
    label: "Definición de Estados de Entrega",
    createNew: "+ Crear nueva entrega",
    search: "Buscar",
    instructions: "Empezar a crear Estados de Entrega",
    empty: "Empieza a agregar nuevos estados de entrega",
  },
  de: {
    label: "Fulfillment Definition",
    createNew: "+ Create New Fulfillment",
    search: "Search",
    instructions: "Start creating Fulfillments",
  },
};

export default translation;
