import React, { useState } from "react";
import {
  FulfillmentEvent,
  Load,
  OrderLine,
  Placement,
} from "@innomius/ravent-typescript-types";
import { useTranslation } from "react-i18next";
import styles from "./styles";

import OrderEventForm from "./OrderEventForm";
import { defaultFulfillmentEvent } from "../../services/fulfilments";
import OrderEventList from "./OrderEventList";
import locationEmpty from "../../assets/empty-location.svg";
import { Asset } from "../../utils/types";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { Button } from "../Button";
import { EmptyList } from "../List/EmptyList";

interface Props {
  events: FulfillmentEvent[];
  onCreateEvent: (location: FulfillmentEvent) => void;
  orderlineDescription: string;
  id: string;
  onDeleteEvent: (id: string) => void;
  onEditEvent: (location: FulfillmentEvent) => void;
  onDeletePlacement: (event: FulfillmentEvent, id: string) => void;
  onEditPlacement: (event: FulfillmentEvent, placement: Placement) => void;
  path: string;
  assets: Asset[];
  updateAsset: (event: FulfillmentEvent, id: string) => void;
  onSaveLoad: (eventId: string, data: Load[]) => void;
  teams: { label: string; value: string }[];
  updateTeam: (eventId: string, teamId: string) => void;
  updateFulfiller: (
    eventId: string,
    fulfillerId: string,
    orderlineId: string
  ) => void;
  fulfillmentLevel: string;
  orderlines: OrderLine[];
  saveLoc: (p: Placement, type: string, group: string) => void;
  flows: { name: string; actionId: string; url: string }[];
}

const OrderlineEvent: React.FC<Props> = ({
  events,
  onCreateEvent,
  orderlineDescription,
  id,
  onDeleteEvent,
  onDeletePlacement,
  onEditPlacement,
  onEditEvent,
  path,
  assets,
  updateAsset,
  onSaveLoad,
  teams,
  updateTeam,
  updateFulfiller,
  fulfillmentLevel,
  orderlines,
  saveLoc,
  flows
}) => {
  const { t } = useTranslation();
  const [openForm, setForm] = useState("");

  return (
    <>
      <OrderEventForm
        assets={assets}
        data={defaultFulfillmentEvent}
        onCreateEvent={(location) => onCreateEvent(location)}
        id={id}
        open={openForm === "location"}
        onClose={() => setForm("")}
      />
      <div className={styles.wrapperCardEvent}>
        <FlexContainer
          padding="2em 0 2em 0"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <ContainerTitle
            size="small"
            label={t("component.orderLocation.locations")}
          />
          <Button
            label={t("component.orderLocation.setLocation")}
            onClick={() => setForm("location")}
          />
        </FlexContainer>

        {events?.length ? (
          <OrderEventList
            flows={flows}
            venues={[]}
            setNew={() => {}}
            loadEvent={() => {}}
            fulfillmentNames={[]}
            setState={() => {}}
            updateFulfillmentName={() => {}}
            onChangeEventState={() => {}}
            saveLoc={saveLoc}
            onCreateEvent={() => {}}
            orderlines={orderlines}
            fulfillmentLevel={fulfillmentLevel}
            teams={teams}
            path={path}
            updateFulfiller={(eventId, fulfillerId) =>
              updateFulfiller(eventId, fulfillerId, id)
            }
            updateTeam={updateTeam}
            onSaveLoad={onSaveLoad}
            assets={assets}
            updateAsset={updateAsset}
            onDeletePlacement={onDeletePlacement}
            onEditPlacement={onEditPlacement}
            onEditEvent={(location) => onEditEvent(location)}
            description={orderlineDescription}
            onDelete={(id) => onDeleteEvent(id)}
            sortable
            events={events}
          />
        ) : (
          <EmptyList
            image={locationEmpty}
            instructions=""
            onClick={() => setForm("location")}
            text={t("component.orderLocation.noLocations")}
          />
        )}
      </div>
    </>
  );
};

export default OrderlineEvent;
