import React, { useCallback, useEffect, useState } from "react";
import { Placement, Location } from "@innomius/ravent-typescript-types";

import styles from "./styles";
import PlacementForm from "../LocationForm/PlacementForm";
import MapLoader from "../MapInput/MapLoader";
import {
  addressToCoordinates,
  coordinatesToW3w,
  defaultW3W,
  w3wToCoordinates,
} from "../../services/maps";
import map from "../../assets/image/map.jpg";
import { capitalize } from "../../utils/format";
import { useTranslation } from "react-i18next";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";
import { SectionProperty } from "../Section/SectionProperty";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";

interface Props {
  data: Location;
  groups: { value: string; label: string }[];
  onChange: (value: Placement) => void;
  edit: boolean;
  onChangeLocation: <P extends keyof Location>(
    prop: P,
    value: Location[P]
  ) => void;
}

const LocationPlacement: React.FC<Props> = ({
  onChange,
  data,
  edit,
  onChangeLocation,
  groups,
}) => {
  const [grid, setGrid] = useState(false);

  const [locationW3W, setLocationW3W] = useState(defaultW3W);

  const getcoordinates = useCallback(async () => {
    if (data.placement.coordinates?.latitude) {
      const resPlacement = await coordinatesToW3w(
        parseFloat(data.placement.coordinates.latitude || ""),
        parseFloat(data.placement.coordinates.longitude || "")
      );
      setLocationW3W(resPlacement);
      return;
    }
    if (data.placement.w3w) {
      try {
        const res = await w3wToCoordinates(data.placement.w3w);
        setLocationW3W(res);
        return;
      } catch (err) {
        console.error(err);
      }
    }
    if (data.placement.line1) {
      const res = await addressToCoordinates(`${data.placement.line1}`);

      if (res.status === "OK") {
        const resPlacement = await coordinatesToW3w(
          res.results[0].geometry.location.lat,
          res.results[0].geometry.location.lng
        );
        onChange({
          w3w: data.placement.w3w,
          type: data.placement.type,
          id: "",
          chronologicalIndex: 0,
          reference: data.placement.reference,
          line1: data.placement.line1,
          name: data.name,
          coordinates: {
            latitude: res.results[0].geometry.location.lat.toString(),
            longitude: res.results[0].geometry.location.lng.toString(),
          },
        });
        setLocationW3W(resPlacement);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data.placement.w3w,
    data.placement.coordinates?.longitude,
    data.placement.coordinates?.latitude,
    data.placement.line1,
  ]);

  useEffect(() => {
    getcoordinates();
  }, [getcoordinates]);

  const { t } = useTranslation();

  return (
    <div>
      {edit ? (
        <PlacementForm
          groups={groups}
          onChange={onChange}
          onChangeLocation={onChangeLocation}
          data={data}
          placement={data.placement}
        />
      ) : (
        <>
          <FlexContainer padding="1em 0 2em 0" justifyContent="space-between">
            <ContainerTitle size="xsmall" label="Placement" />
          </FlexContainer>
          <GridContainer gap="1em" columns="1.5fr 3fr">
            <div>
              <SectionProperty value={capitalize(data.type)} label="Type" />

              <SectionProperty
                label="External ID"
                value={data.externalId || "-"}
              />
              <SectionProperty
                label="Reference"
                value={data.placement.reference || "-"}
              />
              <div style={{ margin: "0em 0 1em 0" }}>
                <SectionProperty
                  label="Address"
                  value={data.placement.line1 || "-"}
                />
              </div>
              <SectionProperty
                label="Three Words Placement"
                value={`${
                  data.placement.w3w ? `///${data.placement.w3w}` : "-"
                }`}
              />

              <div style={{ margin: "0em 0 1em 0" }}>
                <SectionProperty label="Group" value={data.group || "-"} />
              </div>
              <div style={{ margin: "0em 0 1em 0" }}>
                <SectionProperty
                  label={t("component.locationDetail.groupCode")}
                  value={data.groupCode || "-"}
                />
              </div>
              <ButtonsContainer padding="2em 0 0 0" justifyContent="flex-start">
                <Button
                  theme="white"
                  label="Show Grid"
                  onClick={() => setGrid(true)}
                />
              </ButtonsContainer>
            </div>
            <div className={styles.mapContainer}>
              <div style={{ padding: "0 0 1em 0" }}>
                <span style={{ fontWeight: 700 }}>Coordinates: </span>
                {data.placement.coordinates?.latitude
                  ? `${data.placement.coordinates?.latitude},${data.placement.coordinates?.longitude} `
                  : "-"}
              </div>

              {data.placement.coordinates?.latitude ? (
                <MapLoader
                  height="300px"
                  setZoom={grid}
                  onChange={() => {}}
                  type={data.placement.type}
                  value={locationW3W.coordinates}
                />
              ) : (
                <img className={styles.image} src={map} alt="map" />
              )}
            </div>
          </GridContainer>
        </>
      )}
    </div>
  );
};

export default LocationPlacement;
