const translation = {
  en: {
    details: "Details",
    name: "Name",
    organization: "Organization",
    fulfiller: "Fulfiller",
    venue: "Venue",
    fulfillmentTeam: "Fulfillment Team",
    type: "Type",
    info: "Enabling this button will automatically grant all permissions groups",
    scopeInfo:
      "These are related to the creation, access, and updating of resources at the following levels:",
    description: "Description",
    adminRole: "Admin Role",
    fulfillerDescription: "Most focused level, usually for roles concentrating on individual tasks, direct order processing, and specific customer interactions. Ideal for those responsible for completing day-to-day fulfillment tasks with precision.",
    venueDescription: "Focuses on specific locations or sites where operations take place. Best for roles that manage or oversee activities at one or more venues, especially in companies with multiple event or retail locations.",
    organizationDescription: "Suitable for roles that need a broad, strategic perspective and influence across the entire organization. Ideal for leadership roles or those driving company-wide policies, strategies, and initiatives.",
    fulfillmentTeamDescription: "Appropriate for roles working closely within a specific fulfillment team, responsible for managing and optimizing processes, logistics, and team performance on a smaller, team-based scale."
  },
  es: {
    fulfillerDescription: "Nivel más enfocado, generalmente para roles que se concentran en tareas individuales, procesamiento de pedidos directos e interacciones específicas con los clientes. Ideal para aquellos responsables de completar tareas de cumplimiento diarias con precisión.",
    venueDescription: "Se centra en ubicaciones o sitios específicos donde se llevan a cabo operaciones. Lo mejor para roles que gestionan o supervisan actividades en uno o más lugares, especialmente en empresas con múltiples ubicaciones de eventos o minoristas.",  
    organizationDescription: "Adecuado para roles que necesitan una perspectiva amplia y estratégica e influencia en toda la organización. Ideal para roles de liderazgo o aquellos que impulsan políticas, estrategias e iniciativas a nivel de toda la empresa.",
    fulfillmentTeamDescription: "Apropiado para roles que trabajan estrechamente dentro de un equipo de cumplimiento específico, responsables de administrar y optimizar procesos, logística y rendimiento del equipo en una escala más pequeña y basada en equipos.",
    adminRole: "Roles de Administrador",
    description: "Descripción",
    details: "Detalles",
    name: "Nombre",
    type: "Tipo",
    organization: "Organización",
    fulfiller: "Fulfiller",
    venue: "Lugar",
    fulfillmentTeam: "Equipo",
    info: "Al habilitar este botón se otorgarán automáticamente todos los grupos de permisos",
    scopeInfo:
      "Estos están relacionados con la creación, acceso y actualización de recursos en los siguientes niveles:",
  },
  de: {},
};

export default translation;
