import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles";
import { SideMenu } from "../../SideMenu/SideMenu";
import MapLoader from "../../MapInput/MapLoader";
import mapImage from "../../../assets/image/map.jpg";

import Text from "../../Text";
import { ContainerTitle } from "../../ContainerTitle";

interface Props {
  onClose: () => void;
  open: boolean;
  markers: { lat: number; lng: number; title: string };
  name: string;
  line1: string;
  w3w: string;
}

const MapSideMenu: React.FC<Props> = ({
  onClose,
  open,
  markers,
  name,
  line1,
  w3w,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <SideMenu width="50%" onClose={onClose} open={open}>
        <ContainerTitle
          size="medium"
          padding="0 0 1em 0"
          label={t("component.orderLocation.map")}
        />
        <Text
          padding="0 0 1em 0"
          color="#333333"
          text={name}
          fontSize="1.22em"
        />
        <Text
          padding="0 0 0.5em 0"
          color="#858D8F"
          text={line1}
          fontSize="1.1em"
        />

        {w3w === "restrict.firmly.move" ? (
          <img src={mapImage} className={styles.mapContainer} alt="map" />
        ) : (
          <MapLoader
            onChange={() => {}}
            type={"w3w"}
            value={{ lat: markers.lat, lng: markers.lng }}
          />
        )}
      </SideMenu>
    </div>
  );
};

export default MapSideMenu;
