import { Visitor } from "@innomius/ravent-typescript-types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { visitorList as visitorListService } from "../services/visitors";

type VisitorsSliceState = {
  loading: boolean;
  error: string;
  list: Visitor[];
};

const initialState: VisitorsSliceState = {
  loading: true,
  error: "",
  list: [],
};

export const visitorList = createAsyncThunk("visitors/list", async () => {
  const response = await visitorListService();
  return response;
});

const visitorsSlice = createSlice({
  name: "visitors",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(visitorList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(visitorList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.list = action.payload;
    });
    builder.addCase(visitorList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An unexpected error ocurred";
    });
  },
});

export default visitorsSlice.reducer;
