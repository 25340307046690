const translation = {
  en: {
    successFileUpload: "The file has been successfully uploaded",
    officialMedia: "Official Content",
    save: "Save",
    uploadFiles: "Upload Files",
    multiples: "Add multiple files to your location.",
    successUpdate: "The official content was successfully updated",
    successDelete: "The official content was successfully deleted",
    noFiles: "No Files have been added",
    warning: "Are you sure you want to delete this Location ?",
    noTags: "No tags assgined",
    groupCode: "Group Code",
  },
  es: {
    groupCode: "Código de Grupo",
    noTags: "No hay etiquetas asignadas",

    warning: "Está seguro que quiere eliminar este Lugar ?",

    successFileUpload: "El archivo se guardo exitosamente",
    officialMedia: "Contenido Oficial",
    save: "Guardar",
    uploadFiles: "Subir archivos",
    multiples: "Agrega múltiples archivos a tu ubicación.",
    successUpdate: "El contenido se actualizó con éxito",
    successDelete: "El contenido se eliminó correctamente",
    noFiles: "No se han agregado archivos",
  },
  de: {},
};

export default translation;
