
import crossFetch from "cross-fetch";
import CustomError from "./CustomError";
import APIError from "./APIError";

export function createFetch(baseApiUrl: string) {
  return async function customFetch(
    url: string,
    file: any,
    venue_id: string,
    location_id: string,
    token?: string | null,
    query?: any
  ) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("venue_id", venue_id);
    formData.append("location_id", location_id);

    try {
      const res = await crossFetch(
        query
          ? `${baseApiUrl}${url}?${new URLSearchParams(query).toString()}`
          : `${baseApiUrl}${url}`,
        {
          method: "POST",
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
          body: formData,
        }
      );

      if (res.ok && res.status !== 204) {
        const json = await res.json();
        return { status: res.status, body: json };
      }

      if (res.status >= 400) {
        const json = await res.json();
        throw new APIError(json.error, json.message, res.status.toString());
      }

      return { status: res.status, body: undefined };
    } catch (error) {
      if (
        error instanceof Error &&
        error.message === "Network request failed"
      ) {
        throw new CustomError("network", "newtwork");
      }
      throw error;
    }
  };
}

const multiPartFetchMedia = createFetch("/api/v1");

export default multiPartFetchMedia;
