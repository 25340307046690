import React from "react";
import { useTranslation } from "react-i18next";
import { BankAccount } from "../../utils/types";
import styles from "./styles";
import { Card } from "../Card/Card";
import { InputText } from "../Inputs/InputText";

interface Props {
  account: BankAccount;
  onChange: <P extends keyof BankAccount>(
    prop: P,
    value: BankAccount[P]
  ) => void;
}

const InputCard: React.FC<Props> = ({ onChange, account }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapperCard}>
      <Card containerClassname={styles.card}>
        <InputText
          label={t("component.fopForm.bankName")}
          value={account.bank}
          onChange={(value) => onChange("bank", value)}
        />
      </Card>
    </div>
  );
};

export default InputCard;
