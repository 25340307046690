import React from "react";

import styles from "./styles";
import { useTranslation } from "react-i18next";
import { VenueEvent } from "../../utils/types";
import Text from "../../components/Text";
import { black } from "../../utils/colors";
import { StatusBadgePoint } from "../StatusBadgePoint/StatusBagePoint";
import EditButtonsContainer from "../EditButtonsContainer";
import { Action } from "@innomius/ravent-typescript-types";
import { Card } from "../Card/Card";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { InputSwitch } from "../Inputs/InputSwitch";
import { InputSelect } from "../Inputs/InputSelect";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
import { StatusCatalog } from "../StatusBadge";

interface Props {
  data: VenueEvent;
  onChange: (data: VenueEvent) => void;
  setEdit: (value: string) => void;
  onEdit: () => void;
  loading: string;
  actions: Action[];
}

const VenueEventsForm: React.FC<Props> = ({
  data,
  onChange,
  onEdit,
  setEdit,
  loading,
  actions,
}) => {
  const { t } = useTranslation();

  return (
    <Card containerClassname={styles.wrapperCard}>
      <FlexContainer padding="0 0 1em 0" justifyContent="space-between">
        <ContainerTitle size="small" label={t("component.venueForm.events")} />
        <EditButtonsContainer
          loading={loading}
          setEdit={() => setEdit("")}
          onEdit={onEdit}
        />
      </FlexContainer>

      <Text
        textAlign="justify"
        padding="0 0 2em 0"
        color={black}
        text={t("component.venueEvents.url")}
      />
      <GridContainer padding="0 0 1em 0" gap="1em" columns="1fr 3fr 1fr">
        <InputLabel label={t("component.venueForm.eventName")} />
        <InputLabel label={t("component.fulfillmentForm.actionId")} />
        <InputLabel label={t("component.venueForm.automatic")} />
      </GridContainer>
      <GridContainer
        alignItems="center"
        justifyContent="center"
        gap="1em"
        padding="0 0 1em 0"
        columns="1fr 3fr 1fr"
      >
        <StatusBadgePoint status={"creation" as StatusCatalog} />
        <InputSelect
          showError={false}
          showLabel={false}
          value={data.creation.actionId}
          options={[
            ...actions.map((item) => ({
              value: item.id,
              label: item.name,
            })),
            { label: "Unassigned Action", value: "" },
          ]}
          onChange={(actionId) =>
            onChange({
              ...data,
              creation: {
                actionId,
                automatic: data.creation.automatic,
              },
            })
          }
        />
        <InputSwitch
          value={data.creation.automatic}
          onChange={(automatic) =>
            onChange({
              ...data,
              creation: {
                actionId: data.creation.actionId,
                automatic: automatic,
              },
            })
          }
        />
        <StatusBadgePoint status={"fulfillment" as StatusCatalog} />
        <InputSelect
          showError={false}
          showLabel={false}
          options={[
            ...actions.map((item) => ({
              value: item.id,
              label: item.name,
            })),
            { label: "Unassigned Action", value: "" },
          ]}
          value={data.fulfillment.actionId}
          onChange={(actionId) =>
            onChange({
              ...data,
              fulfillment: {
                actionId,
                automatic: data.fulfillment.automatic,
              },
            })
          }
        />
        <InputSwitch
          value={data.fulfillment.automatic}
          onChange={(automatic) =>
            onChange({
              ...data,
              fulfillment: {
                actionId: data.fulfillment.actionId,
                automatic: automatic,
              },
            })
          }
        />
        <StatusBadgePoint status={"cancellation" as StatusCatalog} />
        <InputSelect
          showError={false}
          showLabel={false}
          value={data.cancellation.actionId}
          options={[
            ...actions.map((item) => ({
              value: item.id,
              label: item.name,
            })),
            { label: "Unassigned Action", value: "" },
          ]}
          onChange={(actionId) =>
            onChange({
              ...data,
              cancellation: {
                actionId,
                automatic: data.cancellation.automatic,
              },
            })
          }
        />
        <InputSwitch
          value={data.cancellation.automatic}
          onChange={(automatic) =>
            onChange({
              ...data,
              cancellation: {
                actionId: data.cancellation.actionId,
                automatic: automatic,
              },
            })
          }
        />
        <StatusBadgePoint status={"exception" as StatusCatalog} />
        <InputSelect
          showError={false}
          showLabel={false}
          options={[
            ...actions.map((item) => ({
              value: item.id,
              label: item.name,
            })),
            { label: "Unassigned Action", value: "" },
          ]}
          value={data.exception.actionId}
          onChange={(actionId) =>
            onChange({
              ...data,
              exception: {
                actionId,
                automatic: data.exception.automatic,
              },
            })
          }
        />
        <InputSwitch
          value={data.exception.automatic}
          onChange={(automatic) =>
            onChange({
              ...data,
              exception: {
                actionId: data.exception.actionId,
                automatic: automatic,
              },
            })
          }
        />
      </GridContainer>
      <div />
    </Card>
  );
};

export default VenueEventsForm;
