import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { FulfillmentDefinition } from "@innomius/ravent-typescript-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { coordinatesToW3w } from "../../../services/maps";
import MapLoader from "../../MapInput/MapLoader";
import Text from "../../Text";
import styles from "./styles";
import AssignFulfiller from "../../OrderFulfillmentComponent/AssignFulfillment";
import { readFulfillment } from "../../../services/fulfilments";
import APIError from "../../../utils/APIError";
import { Modal } from "../../Modal/Modal";
import { FlexContainer } from "../../FlexContainer/FlexContainer";
import { Button } from "../../Button";
import { InputSelect } from "../../Inputs/InputSelect";
import { ContainerTitle } from "../../ContainerTitle";
import { ButtonsContainer } from "../../ButtonsContainer";
import { GridContainer } from "../../GridContainer/GridContainer";
import { EmptyList } from "../../List/EmptyList";
import { Spin } from "antd";

interface Props {
  onClose: () => void;
  open: boolean;
  loading?: boolean;
  setState: (state: string, w3w: string, images: string[]) => void;
  state: string;
  updateFulfillmentName: (id: string, diveshopId: string) => void;
  fulfillmentNames: { label: string; value: string }[];
  fulfillmentDefinitionId: string;
  error: string;
  orderlineId: string;
}

interface Coordinates {
  lat: number;
  lng: number;
}

const StatesModal: React.FC<Props> = ({
  onClose,
  open,
  setState,
  state,
  updateFulfillmentName,
  fulfillmentNames,
  orderlineId,
  fulfillmentDefinitionId,
  error,
}) => {
  const { t } = useTranslation();
  const [selectedState, setSelected] = useState(state || "");
  const [coordinates, setCoordinates] = useState<Coordinates>();
  const [geoStatus, setStatus] = useState("");
  const [w3w, setW3w] = useState("");
  const [loading, setLoading] = useState(false);
  const [openSelect, setOpen] = useState(false);

  const [fulfillmentDefinition, setFulfilment] =
    useState<FulfillmentDefinition>();

  const [capturedImages, setCapturedImages] = useState<Array<string>>([]);
  const [fDefinitionId, setId] = useState(fulfillmentDefinitionId);
  const [fulfillmentNameSet, setFulfillmentNameSet] = useState(false);

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const MAX_IMAGE_WIDTH = 800; // Maximum width for resized image
  const MAX_IMAGE_HEIGHT = 600; // Maximum height for resized image

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      const image = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        if (e.target && e.target.result) {
          const imageSrc = e.target.result as string;
          image.src = imageSrc;

          image.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            const maxWidth = MAX_IMAGE_WIDTH;
            const maxHeight = MAX_IMAGE_HEIGHT;

            if (image.width > maxWidth || image.height > maxHeight) {
              const widthRatio = maxWidth / image.width;
              const heightRatio = maxHeight / image.height;
              const scaleFactor = Math.min(widthRatio, heightRatio);

              canvas.width = image.width * scaleFactor;
              canvas.height = image.height * scaleFactor;

              ctx?.drawImage(image, 0, 0, canvas.width, canvas.height);
              const resizedImageString = canvas.toDataURL("image/jpeg"); // You can specify the format here

              setCapturedImages((prevImages) => [
                ...prevImages,
                resizedImageString,
              ]);
            } else {
              setCapturedImages((prevImages) => [...prevImages, imageSrc]);
            }
          };
        }
      };

      reader.readAsDataURL(file);

      event.target.value = ""; // Clear the input to allow selecting the same file again
    }
  };

  const handleAddImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const getLocation = useCallback(() => {
    if (!navigator.geolocation) {
      setStatus("Geolocation is not supported by your browser");
    } else {
      setStatus("Locating...");
      setLoading(true);

      navigator.geolocation.getCurrentPosition(
        async (position) => {
          setStatus("");
          const coord = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          const w3w = await coordinatesToW3w(
            position.coords.latitude,
            position.coords.longitude
          );
          setW3w(w3w.words);
          setCoordinates(coord);
          setLoading(false);
        },
        () => {
          setStatus("Unable to retrieve your location");
          setLoading(false);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (open) {
      getLocation();
    }
  }, [getLocation, open]);

  const load = useCallback(async () => {
    try {
      const res = await readFulfillment(
        fDefinitionId || fulfillmentDefinitionId
      );
      setFulfilment(res);
    } catch (err) {
      if (err instanceof APIError) {
        console.log(err.message);
      }
    }
  }, [fDefinitionId, fulfillmentDefinitionId]);

  useEffect(() => {
    if (fDefinitionId || fulfillmentDefinitionId) {
      load();
    }
  }, [load, fDefinitionId, fulfillmentDefinitionId]);

  return (
    <Modal
      width="700px"
      buttons={
        <FlexContainer justifyContent="flex-end">
          <Button
            containerClassname={styles.cancel}
            onClick={() => {
              onClose();
              setFulfillmentNameSet(false);
              setId(fulfillmentDefinitionId);
            }}
            theme="white"
            label={t("component.orderFulfillment.cancel")}
          />
          <Button
            label={t("component.orderFulfillment.setState")}
            disable={
              loading || !w3w ||
              (!fulfillmentDefinition?.fulfillmentStatesDefinitions.length &&
                (selectedState === "not-set" || !selectedState))
            }
            onClick={async () => {
              if (fulfillmentNameSet) {
                updateFulfillmentName(orderlineId, fDefinitionId);
              }
              if (selectedState && (fDefinitionId || fulfillmentDefinitionId)) {
                setTimeout(() => {
                  setState(selectedState, w3w, capturedImages);
                }, 1000);
              }
              setSelected("");
              setFulfillmentNameSet(false);
            }}
          />
        </FlexContainer>
      }
      open={open}
      onClose={onClose}
    >
      <Text
        padding="0em 0 1em 0"
        text={t("component.orderFulfillment.fulfillmentStates")}
        color="#000000"
        fontSize="1.4em"
        fontWeight={700}
      />
      {!fulfillmentNames.length ? (
        <div className={styles.notEnrollContainer}>
          No States Definitions configured. Please add them to your Organization
        </div>
      ) : !fulfillmentDefinition ? (
        <Spin />
      ) : (
        <div>
          {fDefinitionId || openSelect ? (
            <InputSelect
              maxHeightOptions="8em"
              label={t("component.orderFulfillment.fulfillmentDefinition")}
              options={fulfillmentNames}
              value={fDefinitionId}
              onChange={(value) => {
                setId(value);
                setFulfillmentNameSet(true);
              }}
              error={error}
            />
          ) : (
            <div style={{ marginBottom: "1em" }}>
              <AssignFulfiller
                border
                onClick={() => setOpen(true)}
                type={fulfillmentDefinition.type}
                name={fulfillmentDefinition.name}
              />
            </div>
          )}
        </div>
      )}

      <FlexContainer justifyContent="space-between">
        <ContainerTitle
          label={t("component.orderFulfillment.states")}
          size="xsmall"
        />
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileInputChange}
        />
        <Button
          label="Click to Upload Image"
          theme="white"
          onClick={handleAddImageClick}
        />
      </FlexContainer>

      {fDefinitionId || fulfillmentDefinitionId ? (
        <>
          <Text
            padding="1em 0 1em 0"
            text={t("component.orderFulfillment.selectState")}
            color="#000000"
            fontSize="1em"
          />
          {fulfillmentDefinition?.fulfillmentStatesDefinitions.length ? (
            <ButtonsContainer
              padding="0 0 1em 0"
              justifyContent="flex-start"
              flexWrap="wrap"
            >
              {fulfillmentDefinition?.fulfillmentStatesDefinitions.map(
                (item, index) => (
                  <Button
                    containerClassname={styles.buttonsContainer}
                    key={item.actionId + index}
                    theme={item.name === selectedState ? "blue" : "white"}
                    label={item.name}
                    onClick={() => setSelected(item.name)}
                  />
                )
              )}
            </ButtonsContainer>
          ) : (
            <div className={styles.notEnrollContainer}>
              No States are configured. Please add states to your definition.
            </div>
          )}

          <>
            {!loading ? (
              <FlexContainer flexWrap="wrap" justifyContent="flex-end">
                <Text fontSize="1.1em" fontWeight={700} text={`///${w3w}`} />
              </FlexContainer>
            ) : null}

            {loading ? (
              <div className={styles.iconContainer}>
                <FontAwesomeIcon
                  color="#1677ff"
                  icon={faSpinner as IconProp}
                  size={"2x"}
                  spin
                />{" "}
              </div>
            ) : !coordinates?.lat &&
              !coordinates?.lng &&
              !coordinates ? null : (
              <div className={styles.addressContainer}>
                <div>{geoStatus}</div>
                <MapLoader
                  type="w3w"
                  height="150px"
                  setZoom={false}
                  value={coordinates}
                  onChange={() => {}}
                />
              </div>
            )}
            {capturedImages.length ? (
              <>
                <ContainerTitle
                  label={t("component.orderFulfillment.files")}
                  size="small"
                  padding="0 0 1em 0"
                />
                <GridContainer gap="0.5em" columns="1fr 1fr 1fr 1fr">
                  {capturedImages.map((item) => (
                    <img src={item} className={styles.imageSmall} alt="" />
                  ))}
                </GridContainer>
              </>
            ) : null}
          </>
        </>
      ) : (
        <div style={{ marginTop: "1em" }}>
          <EmptyList
            instructions=""
            text={t("component.orderFulfillment.noFulfillment")}
          />
        </div>
      )}
    </Modal>
  );
};

export default StatesModal;
