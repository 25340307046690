const translation = {
  en: {
    warning: "Are you sure you want to delete this ?",
    cancel: "Cancel",
    ok: "OK",
  },
  es: {
    warning: "¿Está seguro de que desea eliminar esto?",
    cancel: "Cancelar",
    ok: "OK",
  },
  de: {},
};

export default translation;
