const translation = {
  en: {
    cancel: "Cancel",
    save: "Save",
    createNewFOP: "Create New FOP Set",
    name: "Name",
  },
  es: {
    save: "Guardar",
    createNewFOP: "Guardar Nuevo FDP Set",
    name: "Nombre",
    cancel: "Cancelar",

  },
  de: {},
};

export default translation;
