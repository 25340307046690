import { stylesheet } from "typestyle";

const styles = stylesheet({
  wrapperCard: {
    padding: "2em",
  },
  wrapperCard2: {
    padding: "2em",
    marginTop: "2em",
  },
  switch: {
    padding: "0 0 0 1em",
  },
  switchText: {
    padding: "0 1em 0 1em",
  },
  radioContainer: {
    marginRight: "3em",
    padding: "1em 0",
  },
  propertyInstructions: {
    padding: "0 0 1.5em 0",
    textAlign: "justify",
  },
  cardContainer: {
    border: "1px solid #DCE1E6",
    borderRadius: "4px",
    padding: "1em",
    margin: "0 0 1em 0",
  },
  transictionsCard: {
    margin: "0 0 1em 0",
  },
});

export default styles;
