import { Invoice, OrderInvoice } from "@innomius/ravent-typescript-types";
import { Schema } from "../utils/types";

export const invoicesMock: Invoice[] = [
  {
    id: "89ae2bb7-ca8c-4743-baf9-c451f7dcba0b",
    paymentId: "c6b93871-dcbe-4441-a955-0be4178971f7",
    taxId: "MOSS870910C89",
    status: "cancelled",
    reference: "reference",
    cancelReason: "Address is incorrect.",
    created: new Date(),
    updated: new Date(),
    deleted: false,
    definitions: [{ cfdi: "P01" }],
    address: {
      addressLine1: "Manteuffelstr. 1",
      addressLine2: "c/o Appellido",
      threeWords: "dose.twigs.youthful",
      zipCode: "10999",
      coordinates: {
        lat: 52.4671452,
        lng: 13.3783174,
      },
      country: "Germany",
      city: "Berlin",
      state: "Berlin",
    },
    legalName: "Mady Smith R.",
    amount: { value: 150, currency: "$" },
    taxes: [
      {
        name: "IVA",
        id: "c6b93871",
        value: 150,
        rate: "",
      },
    ],
  },
];

export const orderInvoiceMock: OrderInvoice[] = [
  {
    id: "89ae2bb7-ca8c-4743-baf9-c451f7dcba0b",
    description: "Factura 1",
    paymentRequestID: "uuid",
    paymentID: "uuid",
    issuer: "facturapi",
    issuerId: "folio-facturapi-uuid",
    authority: "SAT",
    authorityId: "folio-fiscal-uuid",
    issuedDate: new Date(),
    amount: { value: 12578.54, currency: "MXN" },
    status: "cancelled",
    files: [
      {
        name: "archivo.xml",
        file: "https://api.ravent.com/content/{fileID}",
        issuerUrl: "https://facturapi.com/{invoiceID}",
        fileSize: "25KB",
        id: "123455",
      },
    ],
  },
];

export const invoiceMexico: Schema = {
  componentName: "invoicing-mx",
  componentLabel: "Facturación Mexicana",
  elements: [
    {
      label: "Régimen Fiscal",
      name: "fiscal-regime",
      type: "list",
      value: "",
      elements: [
        {
          value: "601",
          label: "General de Ley Personas Morales",
        },
        {
          value: "603",
          label: "Personas Morales con Fines no Lucrativos",
        },
        {
          value: "605",
          label: "Sueldos y Salarios e Ingresos Asimilados a Salarios",
        },
        {
          value: "606",
          label: "Arrendamiento",
        },
        {
          value: "607",
          label: "Régimen de Enajenación o Adquisición de Bienes",
        },
        {
          value: "608",
          label: "Demás ingresos",
        },
        {
          value: "609",
          label: "Consolidación",
        },
        {
          value: "610",
          label:
            "Residentes en el Extranjero sin Establecimiento Permanente en México",
        },
        {
          value: "611",
          label: "Ingresos por Dividendos (socios y accionistas)",
        },
        {
          value: "612",
          label:
            "Personas Físicas con Actividades Empresariales y Profesionales",
        },
        {
          value: "614",
          label: "Ingresos por intereses",
        },
        {
          value: "615",
          label: "Régimen de los ingresos por obtención de premios",
        },
        {
          value: "616",
          label: "Sin obligaciones fiscales",
        },
        {
          value: "620",
          label:
            "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
        },
        {
          value: "621",
          label: "Incorporación Fiscal",
        },
        {
          value: "622",
          label: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
        },
        {
          value: "623",
          label: "Opcional para Grupos de Sociedades",
        },
        {
          value: "624",
          label: "Coordinados",
        },
        {
          value: "625",
          label:
            "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
        },
        {
          value: "626",
          label: "Régimen Simplificado de Confianza",
        },
        {
          value: "628",
          label: "Hidrocarburos",
        },
        {
          value: "629",
          label:
            "De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales",
        },
        {
          value: "630",
          label: "Enajenación de acciones en bolsa de valores",
        },
      ],
    },
    {
      label: "Uso de CFDI",
      name: "cfdi-use",
      value: "",
      type: "list",
      elements: [
        {
          value: "G01",
          label: "G01 - Adquisición de mercancías",
        },
        {
          value: "G02",
          label: "G02 - Devoluciones, descuentos o bonificaciones",
        },
        {
          value: "G03",
          label: "G03 - Gastos en general",
        },
        {
          value: "I01",
          label: "I01 - Construcciones",
        },
        {
          value: "I02",
          label: "I02 - Mobiliario y equipo de oficina por inversiones",
        },
        {
          value: "I03",
          label: "I03 - Equipo de transporte",
        },
        {
          value: "I04",
          label: "I04 - Equipo de cómputo y accesorios",
        },
        {
          value: "I05",
          label: "I05 - Dados, troqueles, moldes, matrices y herramental",
        },
        {
          value: "I06",
          label: "I06 - Comunicaciones telefónicas",
        },
        {
          value: "I07",
          label: "I07 - Comunicaciones satelitales",
        },
        {
          value: "I08",
          label: "I08 - Otra maquinaria y equipo",
        },

        {
          value: "D01",
          label: "D01 - Honorarios médicos, dentales y gastos hospitalarios",
        },
        {
          value: "D02",
          label: "D02 - Gastos médicos por incapacidad o discapacidad",
        },
        {
          value: "d03",
          label: "D03 - Gastos funerales.",
        },
        {
          value: "D04",
          label: "D04 - Donativos",
        },
        {
          value: "D05",
          label:
            "D05 - Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",
        },
        {
          value: "D06",
          label: "D06 - Aportaciones voluntarias al SAR.",
        },
        {
          value: "D07",
          label: "D07 - Primas por seguros de gastos médicos.",
        },
        {
          value: "D08",
          label: "D08 - Gastos de transportación escolar obligatoria.",
        },
        {
          value: "D09",
          label:
            "D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.",
        },
        {
          value: "CP01",
          label: "CP01 - Pagos",
        },
        {
          value: "CP02",
          label: "CP02 - Nómina",
        },
        {
          value: "S01",
          label: "S01 - Sin Efectos Fiscales",
        },
      ],
    },
  ],
};

export const cancelInvoiceMock: Schema = {
  componentName: "cancellation-mx",
  componentLabel: "Cancelación de Factura",
  elements: [
    {
      label: "Razón de Cancelación",
      name: "cancellationReason",
      value: "",
      type: "list",
      elements: [
        {
          value: "01",
          label: "Comprobantes emitidos con errores con relación",
        },
        {
          value: "02",
          label: "Comprobantes emitidos con errores sin relación.",
        },
        {
          value: "03",
          label: "No se llevó a cabo la operación",
        },
        {
          value: "04",
          label: "Operación nominativa relacionada en una factura global",
        },
      ],
    },
    {
      label: "Documentos Relacionados",
      name: "relatedDocuments",
      value: "",
      type: "text",
      elements: [],
    },
  ],
};
