import { EventComment, OrderComment } from "@innomius/ravent-typescript-types";
import get from "lodash/get";
import { getAccessToken } from "./auth";
import fetch from "../utils/fetch";


export function parseEventComment(data?: unknown): EventComment {
  return {
    id: get(data, "_id", ""),
    created: get(data, "createdAt", ""),
    updated: get(data, "updatedAt", ""),
    orderId: get(data, "order_id", ""),
    fulfillmentEventId: get(data, "fulfillmentEventId", ""),
    userFullName: `${get(data, "createdBy.firstName", "")} ${get(data, "createdBy.lastName", "")}`,
    organizationId: get(data, "organization_id", ""),
    message: get(data, "message", ""),
    deleted: get(data, "deleted", false),
  };
}

export function parseOrderComment(data?: unknown): OrderComment {
  return {
    id: get(data, "_id", ""),
    created: get(data, "createdAt", ""),
    updated: get(data, "updatedAt", ""),
    userId: get(data, "userId", ""),
    type: get(data, "type", ""),
    orderId: get(data, "order_id", ""),
    organizationId: get(data, "organization_id", ""),
    userFullName: get(data, "userFullName", ""),
    fulfillerId: get(data, "fulfillerId", ""),
    fulfillerName: get(data, "fulfillerName", ""),
    message: get(data, "message", ""),
    tags: get(data, "tags", []),
    image: get(data, "image", ""),
    deleted: get(data, "deleted", false),
  };
}

export async function removeComment(id: string): Promise<void> {
  const token = await getAccessToken();
  const res = await fetch(`/comments/${id}`, token, "DELETE");
  return res.body;
}

export async function createComment(
  id: string,
  message: string
): Promise<OrderComment> {
  const token = await getAccessToken();
  const body = {
    message: message,
    order_id: id,
  };
  const res = await fetch(`/comments`, token, "POST", body);
  return res.body;
}

export async function listOrderComments(
  orderId: string
): Promise<OrderComment[]> {
  const token = await getAccessToken();
  const res = await fetch(`/orders/${orderId}/comments`, token);

  return res.body ? res.body.hits.map(parseOrderComment) : [];
}

export async function createEventComment(
  id: string,
  message: string
): Promise<EventComment> {
  const token = await getAccessToken();

  const res = await fetch(`/events/${id}/comment`, token, "POST", { message });

  const data = parseEventComment(res.body);
  return data;
}

export interface EventCommentResponse {
  count: number;
  hits: EventComment[];
}

export async function getEventComments(
  id: string
): Promise<EventCommentResponse> {
  const token = await getAccessToken();
  const res = await fetch(`/events/${id}/comment`, token);
  const data = res.body;
  return { count: data.count, hits: data.hits.map(parseEventComment) };
}

export async function getEventComment(id: string): Promise<EventComment> {
  const token = await getAccessToken();
  const res = await fetch(`/events/comment/${id}`, token);
  const data = parseEventComment(res.body);
  return data;
}
