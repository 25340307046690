const translation = {
  en: {
    name: "Fulfillment Name",
    type: "Type",
    activationType: "Activation Type",
    actions: "",
    ticketing: "Ticketing",
    orderlineStates: "Orderline States",
    enabled: "Enabled",
    disabled: "Disabled",
    published: "Published",
  },
  es: {
    name: "Nombre",
    type: "Tipo",
    activationType: "Tipo de activación",
    actions: "",
    ticketing: "Boletos",
    orderlineStates: "Estados de línea de orden",
    enabled: "Habilitado",
    disabled: "Deshabilitado",
    published: "Published",
  },
  de: {},
};

export default translation;
