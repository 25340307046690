import React from 'react';
import { classes } from 'typestyle';
import { InputError } from '../InputError/InputError';
import { InputLabel } from '../InputLabel/InputLabel';
import styles from './styles.module.css';

export interface ChangeCheckbox {
  id?: string;
  value: string | undefined;
}

export interface InputCheckboxProps {
  id?: string;
  value?: boolean;
  label?: string;
  text?: string;
  showLabel?: boolean;
  showError?: boolean;
  error?: string;
  containerClassname?: string;
  onChange?: (value: boolean, id?: string) => void;
}

export const InputCheckbox: React.FC<InputCheckboxProps> = ({
  id,
  showLabel = true,
  showError = true,
  value,
  label,
  text,
  onChange,
  error,
  containerClassname
}) => {
  return (
    <div className={classes(styles.container, containerClassname)}>
      {label ? <InputLabel showLabel={showLabel} label={label} /> : null}
      <div className={styles.wrapper}>
        <input
          type="checkbox"
          id={id}
          checked={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (onChange) {
              onChange(e.target.checked, id);
            }
          }}
          className={styles.checkbox}
        />
        <label htmlFor={id} className={styles.text}>
          {text}
        </label>
      </div>
      {error ? <InputError error={error} showError={showError} /> : null}
    </div>
  );
};
