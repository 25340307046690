import { stylesheet } from "typestyle";
import { linkBlue } from "../../utils/colors";

const styles = stylesheet({
  container: {
    padding: "2em",
  },
  shareContainer: {
    cursor: "pointer",
    width: "23px",
    marginRight: '2em',
    $nest: {
      "&:hover > path": {
        fill: linkBlue,
      },
    },
  },
  normal: {
    color: "#333",
    fontSize: "1em",
    fontWeight: 600,
    alignItems: "center",
    display: "flex",
    padding: "0 0.5em",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
  modalContainer: {
    padding: "1em",
  },
  selectContainer: {
    width: "50%",
  },
  icon: {
    width: "30px",
    cursor: "pointer",
  },
  containerModal: {
    $nest: {
      "div > div": {
        alignItems: "center !important",
      },
    },
  },
});

export default styles;
