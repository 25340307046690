import { stylesheet } from "typestyle";
import { darkBlack, lightGrey } from "../../utils/colors";

const styles = stylesheet({
  container: {},
  wrapperCard: {
    padding: "2em",
    marginBottom: "1em",
  },
  title: {
    color: darkBlack,
    fontWeight: "bold",
    fontSize: "1.1em",
  },
  line: {
    height: "1px",
    margin: "1em 0",
    background: lightGrey,
  },
  mapContainer: {
    height: "5em",
  },
  wrapper: {},
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  logoContainer: {
    objectFit: "cover",
    height: "14em",
    borderRadius: "7px",
  },
  text: {
    fontSize: "1.1em",
    color: darkBlack,
    fontWeight: 400,
  },
  delivered: {
    padding: "0em 0 1em 0",
  },
  label: {
    fontSize: "1.1em",
    color: "#000000",
    padding: "0.4em 1em",
  },
  addressContainer: {
    padding: "0.6em 0",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  legalFulfillerContainer: {},
});

export default styles;
