const translation = {
  en: {
    warningDelete: "Are you sure you want to delete this order?",
    back: "Back",
    deleteOrder: "Delete Order",
    cancelOrder: "Cancel Order",
    total: "Total",
    order: "Order",
    share: "Share Order",
    sendToFlow: "Send Order to Flow",
    bookmark: "Bookmark Order",
    backToOrders: "Back to Orders",
    closeOrder: "Order Fulfilled",
    schedule: "Schedule",
    externalId: "External Id",
    qrcode: "Download QR",
    downloadPDF: "Download PDF",
    downloadPNG: "Download PNG",
    orderQR: "Order QR Code",
  },
  es: {
    orderQR: "Código QR de la Orden",
    downloadPDF: "Descargar PDF",
    downloadPNG: "Descargar PNG",
    qrcode: "Descargar QR",
    warningDelete: "¿Está seguro de que desea eliminar esta orden?",
    back: "Regresar",
    deleteOrder: "Eliminar Orden",
    cancelOrder: "Cancelar Orden",
    total: "Total",
    order: "Orden",
    share: "Compartir Orden",
    sendToFlow: "Mandar Orden a Flow",
    bookmark: "Marcar Orden como favorita",
    backToOrders: "Regresar a Ordenes",
    closeOrder: "Completar Orden",
    schedule: "Calendarización",
    externalId: "Id Externo",
  },
  de: {},
};

export default translation;
