const translation = {
  en: {
    ordersLabel: "Orders",
    createOrder: "Create Order",
    orderlines: "Orderlines",
    events: "Events",
    sendSelection: "Send Selection to Flow",
    orderToFlows: "Send to Flow",
    missing: "Missing",
    instructions: "No Events without missing location information",
    selected: "events selected",
    missingPlacements: "Missing Location",
    setTeam: "Set Team",
    setAsset: "Set Asset",
    warningDelete: " Are you sure you want to delete this",
    filter: "Filter ?",
  },
  es: {
    warningDelete: "¿Estás seguro de que quieres eliminar ",
    filter: "Filtro ?",
    setTeam: "Asignar Equipo",
    setAsset: "Asignar Activo",
    missing: "Faltantes",
    instructions: "No se encontró ningún evento sin información faltante",
    selected: "eventos seleccionados",
    orderToFlows: "Mandar Selección a Flujo",
    sendSelection: "Mandar Selecciones a Flujo",
    ordersLabel: "Órdenes",
    createOrder: "Crear Orden",
    orderlines: "Orderlines",
    events: "Eventos",
    missingPlacements: "Lugares no Encontrados",
  },
  de: {
    ordersLabel: "Bestellungen",
  },
};

export default translation;
