const translation = {
  en: {
    customer: "Customer",
    created: "Created",
    description: "Description",
    tags: "Tags",
    status: "State",
    due: "Due",
    paid: "Paid",
    review: "Review",
    copy: "Customer name copied to clipboard",
    date: "Date",
    total: "Total"
  },
  es: {
    total: "Total",
    date: "Fecha",
    customer: "Cliente",
    created: "Creado",
    description: "Descripción",
    tags: "Etiquetas",
    status: "Estados",
    due: "Pendiente",
    paid: "Pagado",
    review: "Revisión",
    copy: "Nombre del cliente copiado al portapapeles",
  },
  de: {},
};

export default translation;
