import { Schedule, ScheduleLog } from "@innomius/ravent-typescript-types";
import fetch from "../utils/fetch";
import { getAccessToken } from "./auth";
import { get, isNil, omitBy } from "lodash";

export function parseSchedule(data: unknown): Schedule {
  return {
    id: get(data, "_id", ""),
    actionId: get(data, "actionId", ""),
    description: get(data, "description", ""),
    prettyName: get(data, "prettyName", ""),
    lastStatus: get(data, "lastStatus", ""),
    type: get(data, "type", "at"),
    timezone: get(data, "timezone", ""),
    date: get(data, "expression", ""),
    cronTabExpression: get(data, "expression", ""),
    url: get(data, "url", ""),
    notificationBody: get(data, "notificationBody", ""),
  };
}

export function parseScheduleLogs(data: unknown): ScheduleLog {
  return {
    id: get(data, "_id", ""),
    timestamp: get(data, "createdAt", ""),
    url: get(data, "url", ""),
    scheduleNotificationId: get(data, "scheduleNotificationId", ""),
    actionId: get(data, "actionId", ""),

    statusCode: get(data, "responseStatusCode", 0),
    orderId: get(data, "orderId", []),
    notificationBody: get(data, "notificationBody", ""),
  };
}

export interface ScheduleParams {
  page?: number;
  records_per_page?: number;
  venue_id?: string;
  scheduleNotificationId?: string;
  actionId?: string;
}

function mapSortsFullText(data: ScheduleParams) {
  return omitBy(
    {
      page: data.page,
      records_per_page: data.records_per_page,
      venue_id: data.venue_id || null,
      scheduleNotificationId: data.scheduleNotificationId || null,
      actionId: data.actionId || null,
    },
    isNil
  );
}

export interface ScheduleSearchParams {
  data: Schedule[];
  page: number;
  total: number;
}

export async function listSchedules(
  params: ScheduleParams
): Promise<ScheduleSearchParams> {
  const token = await getAccessToken();

  const res = await fetch(
    "/scheduleNotifications",
    token,
    "GET",
    null,
    mapSortsFullText(params)
  );
  return {
    data: res.body ? res.body.hits.map(parseSchedule) : [],
    page: get(res.body, "page", 0),
    total: get(res.body, "count", 0),
  };
}

export async function deleteSchedule(id: string) {
  const token = await getAccessToken();

  await fetch(`/scheduleNotifications/${id}`, token, "DELETE");
  return;
}

export async function updateSchedule(
  id: string,
  update: Schedule
): Promise<Schedule> {
  const token = await getAccessToken();

  const body = {
    url: update.url,
    type: update.type,
    actionId: update.actionId,
    description: update.description,
    prettyName: update.prettyName,
    expression: update.type === "at" ? update.date : update.cronTabExpression,
    timezone: update.timezone,
  };

  const res = await fetch(`/scheduleNotifications/${id}`, token, "PATCH", body);
  const data = parseSchedule(res.body);
  return data;
}

export async function getSchedule(id: string): Promise<Schedule> {
  const token = await getAccessToken();
  const res = await fetch(`/scheduleNotifications/${id}`, token);
  const data = parseSchedule(res.body);
  return data;
}

export async function createSchedule(data: Schedule): Promise<Schedule> {
  const body = {
    url: data.url,
    type: data.type,
    expression: data.type === "at" ? data.date : data.cronTabExpression,
    timezone: data.timezone,
    actionId: data.actionId,
    description: data.description,
    prettyName: data.prettyName,
  };
  const token = await getAccessToken();
  const res = await fetch("/scheduleNotifications", token, "POST", body);
  return res.body;
}

export interface LogResponse {
  data: ScheduleLog[];
  page: number;
  total: number;
}

export async function listSchedulesLogs(
  params: ScheduleParams
): Promise<LogResponse> {
  const token = await getAccessToken();

  const res = await fetch(
    "/webhookNotifications",
    token,
    "GET",
    null,
    mapSortsFullText(params)
  );
  return {
    data: res.body ? res.body.hits.map(parseScheduleLogs) : [],
    page: get(res.body, "page", 0),
    total: get(res.body, "count", 0),
  };
}
