const translation = {
  en: {
    successCreation: "Venue was successfully created",
    successUpdated: "Venue was successfully updated",

    successDelete: "Venue was successfully deleted",
    locations: "Locations",
    venueDetails: "Details",
    instructions: "Start creating your Locations",
    addMultiple: "Add multiple locations to your venue.",
    createNew: "+ Create New Venue",
    createNewLocations: "+ Create New Location",
    states: "Order States",
    events: "Order Events",
    successCreationLocation: "Location was successfully created",
    locationDeleted: "Location was successfully deleted",
    successUpdatedLocation: "Location was succcessfully updated",
    paymentStates: "Payment States",
    statesDef: "Fulfillment States",
    ticketingDef: "Ticketing",
    successCreationTicketing: "Ticketing Definition succesfully created.",
    back: "Back to Ticketing Definitions",
    cancel: "Cancel",
    save: "Save",
    ticketingDefNamePlaceholder: "Definition Name",
    detailsLabel: "Details",
    ticketingLabel: "Ticketing",
    duplicates: "Duplicates",
    missing: "Missing",
    emptyInstructions: "No Venue found. Please go back.",
  },
  es: {
    emptyInstructions: "No se encontró Recinto. Por favor regrese.",
    missing: "Faltantes",
    duplicates: "Duplicados",
    successCreation: "El Recinto se creó con éxito",
    successDelete: "El Recinto se eliminó con éxito",
    successUpdated: "El Recinto se actualizó con éxito ",
    locations: "Ubicaciones",
    venueDetails: "Detalles",
    instructions: "Empieza a crear tus Ubicaciones",
    addMultiple: "Agrega múltiples ubicaciones a tu Recinto.",
    createNew: "+ Crear Nuevo Recinto",
    createNewLocations: "+ Crear una nueva Ubicación",
    states: "Estados de la Orden",
    events: "Eventos de la Orden",
    successCreationLocation: "Localización se creó con éxito",
    locationDeleted: "Lugar fue borrado con éxito",
    successUpdatedLocation: "Localización fue actualizada con éxito",
    paymentStates: "Estados del Pago",
    statesDef: "Estados de Entrega",
    ticketingDef: "Certificados",
    successCreationTicketing: "Definición de certificado creada con éxito.",
    back: "Volver a Definiciones de Certificado",
    cancel: "Cancelar",
    save: "Guardar",
    ticketingDefNamePlaceholder: "Nombre de Certificado",
    detailsLabel: "Detalles",
    ticketingLabel: "Emisión de boletos",
  },

  de: {},
};

export default translation;
