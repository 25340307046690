import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { capitalize } from "lodash";
import { DndContext } from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";


import { Button } from "../Button";
import Text from "../Text";
import { ListHeader } from "../List/ListHeader";
import { ReactComponent as PickerIcon } from "../../assets/colorPicker.svg";
import styles from "./styles";
import ColorTag from "./ColorTag";
import { Category } from "../../utils/types";
import EditTags from "./EditTags";
import produce from "immer";
import { Empty } from "antd";
import { ListRow } from "../List/ListRow";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ListLabel } from "../List/ListLabel";
import { Card } from "../Card/Card";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { ButtonsContainer } from "../ButtonsContainer";
import { ContainerTitle } from "../ContainerTitle";

interface Props {
  content: string;
  data: Category[];
  loading: boolean;
  onSave: (content: string, categories: Category[]) => void;
  load: () => void;
}

const Item: React.FC<Props> = ({ data, onSave, content, loading }) => {
  const [edit, setEdit] = useState(false);

  const [categories, setCategories] = useState(data.length ? data : []);

  const onChange = (index: number, newData: Partial<Category>) => {
    setCategories((prevPeople) => {
      return produce(prevPeople, (draft) => {
        draft[index] = { ...draft[index], ...newData };
      });
    });
  };

  const { t } = useTranslation();

  const onDragEnd = (event: any) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const activeIndex = categories.findIndex(
        (item) => item.chronologicalIndex === active.id
      );
      const overIndex = categories.findIndex(
        (item) => item.chronologicalIndex === over.id
      );
      let newCategories = arrayMove(categories, activeIndex, overIndex);

      newCategories = newCategories.map((item, index) => ({
        ...item,
        chronologicalIndex: index,
      }));

      setCategories(newCategories);
      onSave(content, newCategories); // Call onSave() after setting the new categories
    }
  };

  const SortableRow = ({ category }: { category: Category }) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id: category.chronologicalIndex });

    const style = {
      transform: CSS.Translate.toString(transform),
      transition,
    };

    return (
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <ListRow template="1fr 2fr 0.5fr">
          <FlexContainer justifyContent="flex-start">
            <PickerIcon className={styles.icon} />
            <ColorTag color={category.color} />
          </FlexContainer>
          <ListLabel value={category.name} textAlign="left" />
        </ListRow>
      </div>
    );
  };

  return (
    <>
      <Card>
        <FlexContainer alignItems="flex-start" justifyContent="space-between">
          <ContainerTitle
            size="small"
            label={capitalize(
              content === "fulfillmentEvents" ? "Events" : content
            )}
          />
          {edit ? (
            <ButtonsContainer justifyContent="flex-end">
              <Button
                label={t("component.orgTags.cancel")}
                onClick={() => setEdit(false)}
                theme="white"
              />
              <Button
                label={loading ? "..." : t("component.orgTags.save")}
                onClick={() => {
                  onSave(content, categories);
                }}
                theme="blue"
              />
            </ButtonsContainer>
          ) : (
            <ButtonsContainer justifyContent="flex-end">
              <Button
                label={t("component.orgTags.edit")}
                onClick={() => setEdit(true)}
                theme="white"
              />
            </ButtonsContainer>
          )}
        </FlexContainer>
        <Text
          fontSize="1em"
          padding="1em 0"
          text={t("component.orgTags.description")}
        />
        <ListHeader template="1fr 2fr 0.5fr">
          <ListHeaderLabel
            value={t("component.orgTags.color")}
            justifyContent="flex-start"
          />
          <ListHeaderLabel
            value={t("component.orgTags.categoryName")}
            justifyContent="flex-start"
          />
          <ListHeaderLabel value={""} justifyContent="flex-start" />
        </ListHeader>
        {edit ? (
          <EditTags data={categories} onChange={onChange} />
        ) : categories.length > 0 ? (
          <DndContext onDragEnd={onDragEnd}>
            <SortableContext
              items={categories.map((category) => category.chronologicalIndex)}
            >
              {categories.map((category) => (
                <SortableRow
                  key={category.chronologicalIndex}
                  category={category}
                />
              ))}
            </SortableContext>
          </DndContext>
        ) : (
          <Empty />
        )}
      </Card>
    </>
  );
};

export default Item;
