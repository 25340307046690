import React, { useState } from "react";
import styles from "../EntityForm/styles";
import { Button } from "../Button";
import Thumbnail from "../Inputs/Thumbnail";

interface Props {
  upload: () => void;
  image?: string;
  edit?: boolean;
  button?: boolean;
  buttonLabel?: string;
  theme?: "blue" | "white" | "link";
  uploadPercentage?: (percentage: number) => void;
  sendFileName?: (file: string) => void;
}

const Upload: React.FC<Props> = ({
  button,
  image,
  edit,
  buttonLabel,
  theme,
  uploadPercentage,
  sendFileName,
}) => {
  const [errorImage, setErrorImage] = useState("");
  const [file, setFile] = useState("");

  const [logoPreview, setPreview] = useState("");
  const [loadingLogo, setLoading] = useState(false);
  const [percentage, setPercentage] = useState<number>(0);

  const inputFile = React.useRef<HTMLInputElement>(null);

  const handleFile = (file: File) => {
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);

    reader.onload = async (e) => {
      sendFileName?.(file.name);

      if (e.target !== null) {
        const chunkSize = 1000;
        if (e.target.result instanceof ArrayBuffer) {
          const chunkCount = e.target.result.byteLength / chunkSize;
          for (let chunckId = 0; chunckId < chunkCount; chunckId++) {
            const chunck = e.target?.result?.slice(
              chunckId * chunkSize,
              chunckId * chunkSize + chunkSize
            );
            console.log(chunck);
            setPercentage(Math.round(chunckId * 100) / chunkCount);
            uploadPercentage?.(percentage);

            //await fetch call y en el body se manda el chunck, headers:
            //"content-type": "application/octet-stream"
            //"content-lenght": chunck.lenght
            //"file-name": fileName // const fileName = Math.random() * 1000 + file.name
          }
        }
      }
    };

    reader.onloadend = async () => {
      setLoading(true);
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpg" &&
        file.type !== "image/jpeg"
      ) {
        setErrorImage("This type it's not allowed");
        console.log(errorImage);
        console.log(loadingLogo);
        setLoading(false);
        return null;
      }
      try {
        setPreview(URL.createObjectURL(file));
        setFile((reader.result as string).replace(/^.+?;base64,/, "") || "");
        setLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          setErrorImage(error.message);
        }
        setLoading(false);
      }
    };

    // reader.readAsDataURL(file);
  };

  const handleInputFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.target.files;
    const file = files ? files[0] : null;
    if (file) {
      handleFile(file);
    }
  };

  const openInputFile = () => inputFile.current?.click();

  return (
    <div>
      {file && !button ? (
        <img
          className={styles.preview}
          src={logoPreview}
          alt="upload"
          onClick={() => openInputFile()}
        />
      ) : button ? (
        <Button
          theme={theme}
          label={buttonLabel}
          onClick={() => openInputFile()}
        />
      ) : (
        <Thumbnail
          image={image}
          edit={edit}
          text="Upload Logo"
          onClick={() => openInputFile()}
        />
      )}
      <input
        type="file"
        onChange={(e) => handleInputFile(e)}
        style={{ display: "none" }}
        ref={inputFile}

      />
    </div>
  );
};

export default Upload;
