import React, { useCallback, useEffect, useState } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import LayouWithSideBar from "../../components/LayoutWithSideBar";

import TabNavBar from "../../components/TabNavBar";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import styles from "./styles.module.css";
import message from "antd/lib/message";
import APIError from "../../utils/APIError";

import { useTranslation } from "react-i18next";
import { ticketingDefList } from "../../store/ticketingDefs";
import VenueTicketing from "../../components/VenueTicketing";

import {
  createPassDefinition,
  defaultPassDefinition,
  deleteTicketingDef,
  updatePassDefinition,
} from "../../services/passDef";
import { PassDefinition } from "../../utils/types";
import { venueList } from "../../store/venues";
import secureLocalStorage from "react-secure-storage";
import { InputSelect } from "../../components/Inputs/InputSelect";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { ContainerTitle } from "../../components/ContainerTitle";

interface RouteParams {
  id: string;
}

interface Props extends RouteComponentProps<RouteParams> {}

const Certificates: React.FC<Props> = ({ match }) => {
  const [loading, setLoading] = useState("");
  const [open, setModal] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch();

  const [venueId, setVenueId] = useState(secureLocalStorage.getItem("venueId"));

  const { t } = useTranslation();
  const ticketingDefs = useSelector((state: RootState) => state.ticketingDefs);
  const [records_per_page_tickets, setHitsPerPageTickets] = useState(10);
  const [pageT, setPageT] = useState(0);

  const venues = useSelector((state: RootState) => state.venues);

  const loadVenues = useCallback(async () => {
    dispatch(venueList({ page: 0, records_per_page: 100 }));
  }, [dispatch]);

  useEffect(() => {
    loadVenues();
  }, [loadVenues]);

  const [ticketingDef, setTicketing] =
    useState<PassDefinition>(defaultPassDefinition);
  const [loadingT, setLoadingT] = useState<boolean>(false);

  const onChangeTicketingDef = <P extends keyof PassDefinition>(
    prop: P,
    value: PassDefinition[P]
  ) => setTicketing({ ...ticketingDef, [prop]: value });

  const loadTicketing = useCallback(async () => {
    dispatch(
      ticketingDefList({
        page: pageT,
        records_per_page: records_per_page_tickets,
        venue_id: venueId as string,
      })
    );
  }, [dispatch, records_per_page_tickets, pageT, venueId]);

  useEffect(() => {
    loadTicketing();
  }, [loadTicketing]);

  useEffect(() => {
    document.title = "RAVENT APP :: Ticketing";
  });

  const onCreateTicketing = async () => {
    try {
      setLoadingT(true);
      await createPassDefinition(ticketingDef);
      setLoadingT(false);
      message.success(
        t("container.createTicketingDef.successCreationTicketing")
      );
      setModal("");
    } catch (err) {
      setLoadingT(false);
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onUpdateTicketing = async () => {
    try {
      setLoading("update-ticketing");
      await updatePassDefinition(ticketingDef);
      message.success(t("container.ticketingDefDetail.successUpdated"));
      setLoading("");
      loadTicketing();
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`Error: ${err.messages}`);
      }
      setLoading("");
    }
  };

  const onDeleteTicketing = async () => {
    try {
      setLoading("delete-ticketing");
      await deleteTicketingDef(ticketingDef.id);
      message.success(t("container.ticketingDefDetail.successDeleted"));
      setLoading("");
      loadTicketing();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const tabTicketsDefinitions = {
    id: "tabTicketsDef",
    label: `${t("container.certificates.ticketingLabel")}`,
    path: `/passes`,
    onClick: () => (document.title = "RAVENT APP :: Passes"),
  };

  return (
    <LayouWithSideBar>
      <div className={styles.container}>
        <FlexContainer justifyContent="space-between">
          <ContainerTitle
            padding="0em 0 0 0"
            size="medium"
            label={t("container.certificates.certificates")}
          />
          <InputSelect
            containerClassname={styles.inputSelect}
            options={venues.list.data.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            value={venueId as string}
            label={t("container.certificates.venue")}
            showError={false}
            showLabel={false}
            onChange={(value) => setVenueId(value)}
          />
        </FlexContainer>

        <TabNavBar options={[tabTicketsDefinitions]}>
          <Switch>
            <Route path={`/passes`}>
              <VenueTicketing
                load={loadTicketing}
                openModal={openModal}
                setOpen={setOpenModal}
                loadingEditT={loading}
                onDeleteTicketing={onDeleteTicketing}
                onEditTicketingDef={onUpdateTicketing}
                hitsPerPage={records_per_page_tickets}
                page={pageT}
                setHitsPerPage={setHitsPerPageTickets}
                setPage={setPageT}
                total={ticketingDefs.list.total}
                recordsPerPage={records_per_page_tickets}
                ticketingDefs={ticketingDefs.list}
                error={ticketingDefs.error}
                loading={ticketingDefs.loading}
                ticketingDef={ticketingDef}
                setModal={setModal}
                onCreateTicketingDef={onCreateTicketing}
                onChangeTicketingDef={onChangeTicketingDef}
                loadingT={loadingT}
                open={open}
                onClose={() => setModal("")}
                path={`/passes`}
              />
            </Route>
          </Switch>
        </TabNavBar>
      </div>
    </LayouWithSideBar>
  );
};

export default Certificates;
