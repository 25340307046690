import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./styles";
import { TeamOverview } from "../../utils/types";
import view from "../../assets/View.svg";
import { numberFormat, numberFormatNoDecimals } from "../../utils/format";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";

interface Props {
  data: TeamOverview;
  from: string;
  to: string;
  total: number;
  
}

const Item: React.FC<Props> = ({ data, from, to, total }) => {
  const history = useHistory();

  return (
    <ListRow
      onClick={() => {
        history.push(
          `/overview/teams/${data.fulfillmenTeamId}?from=${from}&to=${to}&teamName=${data.fulfillmentTeamName}&pending=${data.pending}&completed=${data.completed}&total=${data.total}&failed=${data.failed}&running=${data.running}`
        );
      }}
      template="2fr 1fr 1fr 1fr 1fr 1fr"
      hover={true}
      key={data.fulfillmenTeamId}
    >
      <ListLabel value={data.fulfillmentTeamName} textAlign="left" />
      <div className={styles.normal}>
        {numberFormatNoDecimals(data.pending)}
      </div>
      <div className={styles.normal}>
        {numberFormatNoDecimals(data.completed)}
      </div>
      <div className={styles.normal}>{numberFormatNoDecimals(data.total)}</div>
      <div className={styles.normal}>{`${numberFormat(
        (data.total / total) * 100
      )} %`}</div>

      <div className={styles.center}>
        <img className={styles.icon} src={view} alt="view" />
      </div>
    </ListRow>
  );
};

export default Item;
