import React from "react";

import { Card } from "../Card/Card";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { ContainerTitle } from "../ContainerTitle";
import { SectionProperty } from "../Section/SectionProperty";
import { GridContainer } from "../GridContainer/GridContainer";

export interface Props {
  language: string;
  timeZone: string;
  dateFormat: string;
  numberFormat: string;
}

const GlobalDetail: React.FC<Props> = ({
  language,
  timeZone,
  dateFormat,
  numberFormat,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Card containerClassname={styles.card}>
        <ContainerTitle
          padding="0 0 1em 0"
          size="small"
          label={t("component.globalDetail.userPreferences")}
        />
        <SectionProperty
          label={t("component.globalDetail.preferedLanguage")}
          value={language}
        />
        <GridContainer gap="1em" columns="1fr 1fr">
          <SectionProperty
            label={t("component.globalDetail.dateFormating")}
            value={dateFormat}
          />
          <SectionProperty
            label={t("component.globalDetail.numberFormating")}
            value={numberFormat}
          />
        </GridContainer>
        <SectionProperty
          label={t("component.globalDetail.timeZone")}
          value={timeZone}
        />
      </Card>
    </div>
  );
};

export default GlobalDetail;
