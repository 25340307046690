import React, { useState } from "react";
import Text from "../Text";
import Papa from "papaparse";
import { Modal } from "../Modal/Modal";

import { trimString } from "../../utils/format";

import { useTranslation } from "react-i18next";
import { Spin, message } from "antd";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";
import styles from "./styles";
import Instructions from "../EntityFulfillers/Instructions";

interface Props {
  onClose: () => void;
  open: boolean;
  loading: boolean;
  onImport: (data: any[]) => void;
}

const CSVFileUploader: React.FC<Props> = ({
  onClose,
  open,
  loading,
  onImport,
}) => {
  const [preview, setPreview] = useState(false);
  const inputFile = React.useRef<HTMLInputElement>(null);
  const [tableRows, setTableRows] = useState<string[]>([]);
  const [values, setValues] = useState<unknown[]>([]);
  const [fileName, setFileName] = useState("");

  const repeatTemplate = () => {
    const string = `${values.length}fr `;
    return string.repeat(tableRows.length);
  };

  const { t } = useTranslation();

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.target.files;
    const file = files ? files[0] : null;
    if (file) {
      if (!file.name.toLowerCase().endsWith(".csv")) {
        message.error("Please select a valid CSV file.");
        return;
      }
      setFileName(file.name);

      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const rowsArray: string[][] = [];
          const valuesArray: unknown[][] = [];

          if (rowsArray) {
            // eslint-disable-next-line array-callback-return
            results.data.map((d: any) => {
              rowsArray.push(Object.keys(d));
              valuesArray.push(Object.values(d));
            });
          }
          setPreview(true);
          setTableRows(rowsArray[0]);
          setValues(valuesArray);
        },
      });
    }
  };

  const importData = () => {
    const data: any[] = (values as unknown[][]).map((row) => {
      const rowData: any = {};
      tableRows.forEach((header, index) => {
        rowData[header] = row[index];
      });
      return rowData;
    });

    onImport(data);
  };

  const openInputFile = () => inputFile.current?.click();

  return (
    <div className={styles.container}>
      <Modal
        width={"60%"}
        buttons={
          <div>
            <ButtonsContainer justifyContent="flex-end">
              <Button
                disable={loading}
                onClick={() => {
                  if (loading) {
                    return;
                  }
                  onClose();
                  setValues([]);
                  setTableRows([]);
                  setPreview(false);
                }}
                theme="white"
                label={t("component.cvsFileUploader.close")}
              />
              {loading ? (
                <Spin />
              ) : preview ? (
                <Button
                  onClick={() => {
                    if (loading) {
                      return;
                    }
                    importData();
                    setValues([]);
                    setTableRows([]);
                    setPreview(false);
                  }}
                  label={
                    loading ? "..." : t("component.cvsFileUploader.create")
                  }
                />
              ) : null}
            </ButtonsContainer>
          </div>
        }
        open={open}
        onClose={() => {
          if (loading) {
            return;
          }
          onClose();
        }}
      >
        <div className={styles.modalContainer}>
          {preview ? (
            <div>
              <Text
                padding="0em 0 1em 0"
                text={t("component.cvsFileUploader.import")}
                color="#000000"
                fontSize="1.4em"
                fontWeight={700}
              />

              <Text
                color="#000000"
                fontSize="1em"
                padding="0em 0 0.2em 0"
                fontWeight={700}
                text={`${fileName}`}
              />
              <Text
                padding="0em 0 1em 0"
                text={`${t("component.cvsFileUploader.rows")}: ${
                  values.length
                }`}
                color="#000000"
                fontSize="1em"
                fontWeight={700}
              />
              <div className={styles.scroll}>
                <ListHeader template={repeatTemplate()}>
                  {tableRows.map((rows, index) => {
                    return (
                      <ListHeaderLabel
                        key={index}
                        value={rows}
                        justifyContent="flex-start"
                      />
                    );
                  })}
                </ListHeader>
                <ListRow template={repeatTemplate()}>
                  {values.slice(0, 3).map((value: any) => {
                    return value.map((val: any, i: number) => {
                      return (
                        <ListLabel
                          textAlign="left"
                          key={i}
                          value={trimString(val, 25)}
                        />
                      );
                    });
                  })}
                </ListRow>
              </div>
            </div>
          ) : (
            <>
              <Text
                padding="1em 0 1em 0"
                text={t("component.cvsFileUploader.import")}
                color="#000000"
                fontSize="1.4em"
                fontWeight={700}
              />
              <div
                id="dropArea"
                draggable
                onClick={() => openInputFile()}
                onDragOver={(e: React.DragEvent<HTMLDivElement>) => {
                  e.preventDefault();
                }}
                onDragEnter={(e: React.DragEvent<HTMLDivElement>) => {
                  e.preventDefault();
                }}
                onDrop={(e: React.DragEvent<HTMLDivElement>) => {
                  e.stopPropagation();
                  e.preventDefault();
                  openInputFile();
                }}
                className={styles.box}
              >
                <Text
                  padding="1em 0 0 0"
                  text={t("component.cvsFileUploader.drag")}
                  color="#000000"
                  fontSize="1em"
                  fontWeight={700}
                />

                <input
                  type="file"
                  name="file"
                  accept=".csv"
                  onChange={(e) => changeHandler(e)}
                  style={{ display: "none" }}
                  ref={inputFile}
                />
              </div>

              <Text
                padding="1em 0 2em 0"
                fontWeight={400}
                fontSize={"1em"}
                color="#858D8F"
                text={t("component.entityDivers.ensure")}
              />

              <Instructions />
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default CSVFileUploader;
