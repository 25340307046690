import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserMenu from "../UserMenu";
import logo from "../../assets/logo.svg";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { AccessTokenParsed, Profile } from "../../utils/types";
import { capitalize } from "lodash";
import Text from "../Text";
import { RefreshTokenParsed } from "@innomius/ravent-typescript-types";
import styles from "./styles.module.css";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { getProfile } from "../../services/profile";

interface Props {
  onLogout: () => void;
  access: AccessTokenParsed | null;
  refresh: RefreshTokenParsed | null;
  onClickOutside: () => void;
  open: boolean;
  setUserMenuOpen: () => void;
}

export default function Header(props: Props) {
  const { onLogout, setUserMenuOpen, open, onClickOutside } = props;
  const [profile, setProfile] = useState<Profile | null>(null);

  const loadProfile = useCallback(async () => {
    try {
      const profile = await getProfile();
      setProfile(profile);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    loadProfile()
  }, [loadProfile]);

  return (
    <div className={styles.header} onClick={() => onClickOutside()}>
      <div className={styles.logoContainer}>
        <img src={logo} alt="" className={styles.logo} />
      </div>
      <div className={styles.buttons}>
        <div
          className={styles.button}
          onClick={(e) => {
            e.stopPropagation();
            setUserMenuOpen();
            return 0;
          }}
        >
          <FlexContainer alignItems="flex-end" flexDirection="column">
            <>
              <Text fontSize="1.2em" text={`${profile?.firstName || ""}`} />
              <FlexContainer>
                <Text
                  padding="0 0.5em 0 0"
                  fontSize="1em"
                  text={`${profile?.orgName || ""} · `}
                />
                <Text
                  padding="0 0.5em 0 0"
                  fontSize="1em"
                  text={`${profile?.venueName || ""} · `}
                />
                <Text
                  fontSize="1em"
                  text={
                    `${
                      localStorage.getItem("timeZone")
                        ? localStorage.getItem("timeZone")
                        : Intl.DateTimeFormat().resolvedOptions().timeZone
                    } TZ` || ""
                  }
                />
              </FlexContainer>
            </>
          </FlexContainer>
          <div className={styles.usermenuContainer}>
            {capitalize(profile?.firstName?.charAt(0))}
            <UserMenu onLogout={onLogout} open={open} />
          </div>

          {open ? (
            <FontAwesomeIcon
              icon={faChevronUp as IconProp}
              className={styles.chevron}
            />
          ) : (
            <FontAwesomeIcon
              icon={faChevronDown as IconProp}
              className={styles.chevron}
            />
          )}
        </div>
      </div>
    </div>
  );
}
