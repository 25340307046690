import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  listFOPCatalog as fopsCatalogListService,
  FOPCatalogSearchResponse,
} from "../services/fop";

type FOPCatalogSliceState = {
  loading: boolean;
  error: string;
  list: FOPCatalogSearchResponse;
};

const initialState: FOPCatalogSliceState = {
  loading: true,
  error: "",
  list: {
    page: 0,
    total: 0,
    data: [],
  },
};

export const fopsCatalogList = createAsyncThunk(
  "fopsCatalog/list",
  async () => {
    const response = await fopsCatalogListService();
    return response;
  }
);

const fopsCatalogSlice = createSlice({
  name: "fopsCatalog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fopsCatalogList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(fopsCatalogList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.list = action.payload;
    });
    builder.addCase(fopsCatalogList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An unexpected error ocurred";
    });
  },
});

export default fopsCatalogSlice.reducer;
