const translation = {
  en: {
    erp: "ERP",
    erpCopied: "Order ERP ID copied to clipboard",
    orderID: "Order ID",
    customerCopied: "Customer ERP ID copied to clipboard",
    customerId: "Customer ID",
    name: "Name",
    paymentState: "Payment State",
    orderLines: "Order Lines",
    customerInfo: "Customer Information",
    idCopied: "Customer ID copied to clipboard",
    description: "Description",
    channel: "Channel",
    employeeId: "Employee ID",
    employeeName: "Employee Name",
    offeringSystem: "Offering System",
    tags: "Tags",
    walletId: "Wallet ID",
    order: "Order",
    shared: "successfully shared",
    status: "Status",
    created: "Created",
    overPaid: "Overpaid",
    paid: "Paid",
    due: "Due",
    customer: "Customer",
    sentToERP: "Send to ERP",
    expired: "Expired",
    expires: "Expires",
    payLimit: "Pay Limit",
    collectPayment: "Collect Payment",
    readyOrder: "Ready Order",
    issueTickets: "Issue Tickets",
    replyMessage: "Reply Message",
    refundOrder: "Refund Order",
    resetOrder: "Reset Order",
    fulfillmentDate: "Fulfillment Date",
    copy: "copied to clipboard",
    by: "Created By: ",
    id: "ID: ",
    notes: "Notes",
    createdAt: "Created At: ",
    phone: "Phone",
    fulfillment: "Fulfillment",
    email: "Email",
    externalId: "External Id",
    fulfillmentLevel: "Fulfillment Level",
    venueName: "Venue Name",
    
    resetDisabled:
      "You do not have the expired url configured at the Venue level and therefore it is not possible to refresh this order",
  },
  es: {
    venueName: "Nombre de Venue",
    employeeId: "ID de Empleado",
    employeeName: "Nombre de Empleado",
    externalId: "Id Externo",
    fulfillmentLevel: "Nivel de Entrega",
    paymentState: "Estado de Pago",
    erp: "ERP",
    id: "ID: ",
    createdAt: "Fecha de Creación: ",
    fulfillmentDate: "Fecha de Entrega",
    by: "Creado por:",
    erpCopied: "Orden ERP ID copiado al portapapeles",
    orderID: "ID de la Orden",
    customerCopied: "ID de ERP del cliente copiado en el portapapeles",
    customerId: "ID del cliente",
    name: "Nombre",
    orderLines: "Líneas de orden",
    customerInfo: "Información del cliente",
    idCopied: "ID de cliente copiado al portapapeles",
    description: "Descripción",
    channel: "Canal",
    offeringSystem: "Sistema de oferta",
    tags: "Etiquetas",
    walletId: "ID de Wallet",
    order: "Orden",
    customer: "Cliente",
    notes: "Notas",
    fulfillment: "Fulfillment",

    shared: "compartido con éxito",
    status: "Estado",
    created: "Creado",
    overPaid: "Pagado en exceso",
    paid: "Pagado",
    due: "Pendiente",
    sentToERP: "Enviar a ERP",
    expired: "Expirado",
    expires: "Expira",
    payLimit: "Límite de pago",
    collectPayment: "Recolectar Pago",
    readyOrder: "Prepar órden",
    issueTickets: "Generar Tickets",
    replyMessage: "Responder Mensaje",
    refundOrder: "Reembolosar",
    resetOrder: "Reiniciar Orden",
    copy: "copiado al portapapeles",
    resetDisabled:
      "No tienes configurada la url caducada a nivel de Venue y por lo tanto no es posible refrescar este pedido",
  },
  de: {},
};

export default translation;
