import React from "react";

import { useTranslation } from "react-i18next";
import { CreateUserParams } from "../../services/users";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputText } from "../Inputs/InputText";

interface Props {
  data: CreateUserParams;
  onChange: <P extends keyof CreateUserParams>(
    prop: P,
    value: CreateUserParams[P]
  ) => void;
  onEdit?: () => void;
  onCancel?: () => void;
}

const EditProfileForm: React.FC<Props> = ({ data, onChange }) => {
  const { t } = useTranslation();

  return (
    <div>
      <ContainerTitle
        padding="0 0 1em 0"
        size="small"
        label={t("component.editProfileForm.personalInfo")}
      />
      <GridContainer gap="0em" columns="2fr 1fr">
        <InputText
          label={t("component.editProfileForm.name")}
          value={data.firstName}
          onChange={(value) => onChange("firstName", value)}
        />
        <div />
        <InputText
          label={t("component.editProfileForm.lastName")}
          value={data.lastName}
          onChange={(value) => onChange("lastName", value)}
        />
        <div />
      </GridContainer>
      <GridContainer gap="2em" columns="1fr 1fr 1fr">
        <InputText
          label={t("component.editProfileForm.email")}
          value={data.email}
          onChange={(value) => onChange("email", value)}
        />
        <InputText
          label={t("component.editProfileForm.phone")}
          value={data.phone}
          onChange={(value) => onChange("phone", value)}
        />
      </GridContainer>
    </div>
  );
};

export default EditProfileForm;
