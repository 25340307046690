const translation = {
  en: {
    close: "Close",
    cancel: "Cancel",
    cancelInvoice: "Cancel Invoice",
    comments: "Comments",
  },

  es: {
    close: "Cerrar",
    cancel: "Cancelar",
    cancelInvoice: "Cancelar Factura",
    comments: "Comentarios",
  },
  de: {},
};

export default translation;
