import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import OrdersList from "../../components/OrderList";
import { RootState } from "../../store";
import { Redirect, useHistory, useLocation } from "react-router-dom";

import CrossIcon from "../../components/Icons/CrossIcon";
import flow from "../../assets/sendflows4.svg";
import { textSearchList } from "../../store/textSearch";
import styles from "./styles.module.css";
import qs from "qs";
import {
  parseQueryParameter,
  removeEmptyStringFromObject,
} from "../../utils/order";
import { useTranslation } from "react-i18next";
import { venueList } from "../../store/venues";
import { InputSelect } from "../../components/Inputs/InputSelect";
import SideFilters from "../../components/SideFilters";
import { Action, Schedule } from "@innomius/ravent-typescript-types";
import { message } from "antd";
import APIError from "../../utils/APIError";
import { listActions } from "../../services/actions";
import ScheduleFlow from "../../components/SendModal/ScheduleFlow";
import {
  Filter,
  createOrderBatch,
  notifyWebHook,
  scheduleWebHook,
} from "../../services/orders";
import dayjs from "dayjs";
import { numberFormatNoFractions } from "../../utils/format";
import CVSFUploader from "../../components/CVSFUploader";
import secureLocalStorage from "react-secure-storage";
import Text from "../../components/Text";
import SaveFilterModal from "../../components/SaveFilterModal";
import DeleteModal from "../../components/DeleteModal";
import Search from "antd/es/input/Search";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { Button } from "../../components/Button";
import { GridContainer } from "../../components/GridContainer/GridContainer";
import { ContainerTitle } from "../../components/ContainerTitle";

interface Props {}

interface SavedData {
  name: string;
  type: string;
  filters?: Array<{ name: string; include: string; items: Array<any> }>;
  search?: string;
}

const Orders: React.FC<Props> = () => {
  function useQuery() {
    const { search } = useLocation();
    return search;
  }
  const history = useHistory();
  const query = useQuery();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);
  const { profile } = useSelector((state: RootState) => state.profile);
  const textSearch = useSelector((state: RootState) => state.textSearch);
  const [open, setOpen] = useState<string>("");
  const [errorFlows, setErrorFlows] = useState<string>("");

  const [savedFilter, setSavedFilter] = useState<string>("");

  const [loading, setLoading] = useState<string>("");
  const [actions, setActions] = useState<Action[]>([]);

  const [page, setPage] = useState(
    (qs.parse(query.substring(1)).page as string) || textSearch.orders.page
  );
  const [resize, setSize] = useState(false);

  const [sort, setSortBy] = useState("createdAt:desc");
  const [schedule, setSchedule] = useState(false);

  const [records_per_page, setHitsPerPage] = useState(
    (qs.parse(query.substring(1)).records_per_page as string) || 100
  );
  const [deletedFilter, setDeletedFilter] = useState("");

  const [search, setSearch] = useState(
    (qs.parse(query.substring(1)).search as string) || ""
  );

  const [text, setText] = useState(
    (qs.parse(query.substring(1)).search as string) || ""
  );

  const [from, setFrom] = useState<string>(
    (qs.parse(query.substring(1)).from as string) || ""
  );

  const [dateType, setDateType] = useState<string>(
    (qs.parse(query.substring(1)).dateType as string) || "createdAt"
  );

  const totalRangesParams = textSearch.orders.totalRangesParams || "";

  const [to, setTo] = useState<string>(
    (qs.parse(query.substring(1)).to as string) || ""
  );
  const [timezone, setTimeZone] = useState<string>(
    localStorage.getItem("timeZone") ||
      Intl.DateTimeFormat().resolvedOptions().timeZone ||
      ""
  );

  const [totalRanges, setTotalRanges] = useState<Filter>(
    parseQueryParameter(query, "totalRanges")
  );
  const [state, setState] = useState<Filter>(
    parseQueryParameter(query, "state")
  );
  const [tags, setTags] = useState<Filter>(parseQueryParameter(query, "tags"));

  const [customerName, setCustomerName] = useState<Filter>(
    parseQueryParameter(query, "customerName")
  );

  const [currency, setCurrency] = useState<Filter>(
    parseQueryParameter(query, "currency")
  );
  const [channelName, setChannelName] = useState<Filter>(
    parseQueryParameter(query, "channelName")
  );
  const [paymentState, setPaymentState] = useState<Filter>(
    parseQueryParameter(query, "paymentState")
  );
  const [fulfillmentState, setFulfillmentState] = useState<Filter>(
    parseQueryParameter(query, "fulfillmentState")
  );
  const [fulfillmentLevel, setFulfillmentLevel] = useState<Filter>(
    parseQueryParameter(query, "fulfillmentLevel")
  );

  const [savedData, setSavedData] = useState<Record<string, SavedData>>({});

  const [openFlows, setOpenFlows] = useState("");

  const [selectedOrders, setSelected] = useState<{ id: string }[]>([]);

  const [venueId, setVenueId] = useState(
    (qs.parse(query.substring(1)).venueId as string) ||
      (localStorage.getItem("venueId") as string) ||
      ""
  );

  const loadActions = useCallback(async () => {
    try {
      const res = await listActions({
        page: 0,
        records_per_page: 100,
        manualTrigger: true,
        venue_id: venueId as string,
      });
      setActions(res.data);
    } catch (err) {
      if (err instanceof APIError) {
        console.error(`${err.message}: ${err.messages}`);
      }
    }
  }, [venueId]);

  useEffect(() => {
    try {
      const data = localStorage.getItem("orderSaveFilter");
      if (data) {
        setSavedData(JSON.parse(data) as Record<string, SavedData>);
      } else {
        setSavedData({});
      }
    } catch (error) {
      console.error("Error parsing orderSaveFilter from localStorage:", error);
      setSavedData({});
    }
  }, []);

  useEffect(() => {
    loadActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  type StateMap = {
    [key: string]: [Filter, React.Dispatch<React.SetStateAction<Filter>>];
  };

  const stateMap: StateMap = {
    state: [state, setState],
    tags: [tags, setTags],
    currency: [currency, setCurrency],
    channelName: [channelName, setChannelName],
    totalRanges: [totalRanges, setTotalRanges],
    fulfillmentLevel: [fulfillmentLevel, setFulfillmentLevel],
    paymentState: [paymentState, setPaymentState],
    fulfillmentState: [fulfillmentState, setFulfillmentState],
    customerName: [customerName, setCustomerName],
  };

  const venues = useSelector((state: RootState) => state.venues);
  const venueArray = [
    ...venues.list.data.map((item) => ({ label: item.name, value: item.id })),
    { label: "all", value: "" },
  ];

  const loadVenues = useCallback(async () => {
    dispatch(venueList({ records_per_page: 20 }));
  }, [dispatch]);

  useEffect(() => {
    loadVenues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const load = useCallback(async () => {
    dispatch(
      textSearchList({
        page,
        records_per_page,
        from,
        to,
        search,
        timezone,
        sort,
        state,
        fulfillmentLevel,
        fulfillmentState,
        paymentState,
        total: totalRanges,
        venueId,
        tags,
        channelName,
        dateType,
        currency,
        totalRangesParams,
        customerName,
      })
    );

    const states = {
      search: search,
      records_per_page: records_per_page as string,
      from: from,
      to: to,
      state:
        state.include + "|" + state.items.map((item) => item.key).join(","),
      paymentState:
        paymentState.include +
        "|" +
        paymentState.items.map((item) => item.key).join(","),
      customerName:
        customerName.include +
        "|" +
        customerName.items.map((item) => item.key).join(","),
      fulfillmentLevel:
        fulfillmentLevel.include +
        "|" +
        fulfillmentLevel.items.map((item) => item.key).join(","),
      fulfillmentState:
        fulfillmentState.include +
        "|" +
        fulfillmentState.items.map((item) => item.key).join(","),
      totalRanges:
        totalRanges.include +
        "|" +
        totalRanges.items.map((item) => item.key).join(","),
      page: page as string,
      venueId: venueId as string,
      tags: tags.include + "|" + tags.items.map((item) => item.key).join(","),
      channelName:
        channelName.include +
        "|" +
        channelName.items.map((item) => item.key).join(","),
      dateType: dateType,
      currency:
        currency.include +
        "|" +
        currency.items.map((item) => item.key).join(","),
    };

    const params = new URLSearchParams(removeEmptyStringFromObject(states));
    history.replace(`/orders?${params.toString()}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    page,
    customerName,
    records_per_page,
    from,
    to,
    tags,
    channelName,
    timezone,
    search,
    history,
    sort,
    state,
    totalRangesParams,
    fulfillmentLevel,
    fulfillmentState,
    paymentState,
    totalRanges,
    venueId,
    dateType,
    currency,
  ]);

  useEffect(() => {
    load();
  }, [load, auth.user]);

  const flows = actions.map((item) => ({
    name: `${item.name} - ${item.url}`,
    actionId: item.id,
    url: item.url,
  }));

  useEffect(() => {
    load();
  }, [load, auth.user]);

  const filters = Object.keys(savedData)
    .map((key) => {
      const name = savedData[key].name;
      return name ? { label: name, value: name } : undefined;
    })
    .filter(
      (item): item is { label: string; value: string } => item !== undefined
    );

  const hasNonEmptyState = Object.values(stateMap).some(
    ([filter]) => filter.items.length > 0
  );

  const closeAllTags = () => {
    Object.values(stateMap).forEach(([_filter, setFilter]) =>
      setFilter((prevFilter) => ({ ...prevFilter, items: [] }))
    );
  };

  useEffect(() => {
    document.title = "RAVENT APP :: Orders";
  });

  const onSendToFlow = async (actionId: string, schedule: Schedule) => {
    try {
      setLoading("schedule");
      if (schedule.timezone) {
        await scheduleWebHook({
          actionId: actionId,
          ordersIds: selectedOrders.map((item) => item.id),
          date: schedule.date,
          timezone: schedule.timezone,
          prettyName: schedule.prettyName,
        });
        message.success(`Orders were sent successfully`);
      } else {
        const res = await notifyWebHook({
          actionId: actionId,
          ordersIds: selectedOrders.map((item) => item.id),
        });
        if (res.successful === false) {
          message.error({
            content: `${res.statusCode} ${res.message}`,
          });
        }
        if (res.successful === true) {
          message.success(`Orders were sent successfully`);
        }
      }

      setLoading("");

      setErrorFlows("");
      setOpenFlows("");
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        setErrorFlows(`Err: ${err.messages}`);
      }
    }
  };

  if (!auth.loading && !auth.user) {
    return <Redirect from="*" to="/" />;
  }

  const handleTagClose = (facetGroup: string, option: string) => {
    const [currentState, setState] = stateMap[facetGroup];
    const itemToRemoveIndex = currentState.items.findIndex(
      (item) => item.key === option
    );
    if (itemToRemoveIndex !== -1) {
      setState({
        ...currentState,
        items: currentState.items.filter(
          (item, index) => index !== itemToRemoveIndex
        ),
      });
    }
  };

  function generateTag(label: Filter, _onClose: (label: string) => void) {
    if (!label || !label.items || label.items.length === 0) {
      return null;
    }
    return label.items.map(
      (tagItem: { key: string; include: boolean }, index: number) => (
        <div
          className={tagItem.include ? styles.tag : styles.tagRed}
          key={index}
          style={{ margin: "0 0.3em 0.3em 0" }}
        >
          <div style={{ color: !tagItem.include ? "red" : "#1890ff" }}>
            {tagItem.key.trim()}
          </div>
          <button
            className={styles.button}
            name="remove"
            onClick={() => handleTagClose(label.name, tagItem.key)}
          >
            <CrossIcon color={!tagItem.include ? "red" : "#1890ff"} />
          </button>
        </div>
      )
    );
  }

  async function uploadCSVFile(file: File) {
    try {
      setLoading("file");
      await createOrderBatch(
        file,
        secureLocalStorage.getItem("venueId") as string
      );
      setLoading("");
      message.success(t("container.orders.success"));
      setOpen("");
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(err.message);
      }
    }
  }

  const selected: Filter[] = [];
  Object.values(stateMap).forEach(([filter]) => {
    selected.push(filter);
  });

  return (
    <LayouWithSideBar shrink={true}>
      <DeleteModal
        value={`${t("container.events.warningDelete")} ${deletedFilter} ${t(
          "container.events.filter"
        )}`}
        open={open === "deleteFilter"}
        onCancel={() => {
          setOpen("");
          setDeletedFilter("");
        }}
        onDelete={() => {
          const saved = { ...savedData };
          delete saved[deletedFilter];
          setSavedData(saved);
          localStorage.setItem("orderSaveFilter", JSON.stringify(saved));
          setDeletedFilter("");
          setOpen("");
        }}
      />
      <SaveFilterModal
        open={open === "save"}
        onSave={(value) => {
          const dataToStore = {
            filters: search ? selected : selected,
            name: value,
            search: search,
            type: search ? "query" : "normal",
          };

          const saved = {
            ...savedData,
            [value]: dataToStore,
          };
          setSavedData(saved);
          localStorage.setItem("orderSaveFilter", JSON.stringify(saved));
        }}
        onClose={() => setOpen("")}
      />
      <GridContainer columns={resize ? "0.24fr 1fr" : "3em 1fr"}>
        <div
          style={{
            position: "sticky",

            backgroundColor: "#fff",
            height: "100%",
            borderLeft: "1px solid #dedede",
          }}
        >
          <div
            style={
              {
                // overflowY: "scroll",
                // height: "90vh",
                // scrollbarWidth: "none",
                // msOverflowStyle: "none",
                // WebkitOverflowScrolling: "touch",
              }
            }
          >
            <SideFilters
              tab={"orders"}
              onDeleteFilter={(value) => {
                setDeletedFilter(value);
                setOpen("deleteFilter");
              }}
              dateType={dateType}
              setDateType={(value) => {
                setDateType(value);
              }}
              filters={filters}
              savedFilter={savedFilter}
              onSaveFilter={(value) => {
                setSavedFilter(value);

                const matchingObject = savedData[value];

                if (matchingObject && savedData[value].type === "query") {
                  setText(savedData[value].search || "");

                  setSearch(savedData[value].search || "");
                  return;
                }

                if (matchingObject && matchingObject.filters) {
                  matchingObject.filters.forEach((filter) => {
                    const stateAndSetter = stateMap[filter.name];
                    if (stateAndSetter) {
                      const setFilter = stateAndSetter[1];
                      setFilter((prevFilter) => ({
                        ...prevFilter,
                        items: filter.items,
                      }));
                    }
                  });
                }
              }}
              facets={textSearch.orders.facets}
              selected={selected}
              from={from}
              to={to}
              setSize={setSize}
              shrink={resize}
              timezone={timezone}
              onChangeTimeZone={(value) => {
                setPage(0);
                setFrom(from);
                setTo(to);
                setTimeZone(value);
              }}
              onChange={(facetGroup, option) => {
                setPage(0);
                const map = stateMap;
                if (map[facetGroup]) {
                  const [currentState, setState] = map[facetGroup];
                  const existingIndex = currentState.items.findIndex(
                    (item) => item.key === option
                  );
                  let updatedItems;
                  if (existingIndex !== -1) {
                    updatedItems = currentState.items.filter(
                      (item) => item.key !== option
                    );
                  } else {
                    updatedItems = [
                      ...currentState.items,
                      {
                        key: option,
                        include: currentState?.items.every(
                          (item) => item.include === true
                        ),
                      },
                    ];
                  }
                  setState({
                    ...currentState,
                    items: updatedItems,
                  });
                }
              }}
              onChangeExlusion={(facetGroup, value) => {
                const map = stateMap;
                const [currentState, setState] = map[facetGroup];
                if (currentState && currentState.items) {
                  setState((prevState) => ({
                    ...prevState,
                    items: prevState.items.map((item) => ({
                      ...item,
                      include: value, // Set all items to the same value
                    })),
                  }));
                }
              }}
              onChangeSelect={(facetGroup, value) => {
                const map = stateMap;
                const [currentState, setState] = map[facetGroup];
                if (currentState) {
                  setState({
                    ...currentState,
                    include: value as "Any" | "All",
                  });
                }
              }}
              onChangeDate={(value) => {
                setPage(0);
                setFrom(value[0]);
                setTo(value[1]);
              }}
            />
          </div>
        </div>

        <div className={styles.container}>
          <CVSFUploader
            handleSubmit={async (file) => uploadCSVFile(file)}
            loading={loading === "file"}
            open={open === "file"}
            onClose={() => setOpen("")}
          />
          <ScheduleFlow
            schedule={schedule}
            setSchedule={setSchedule}
            flows={flows || []}
            onSend={(flow, schedule) => onSendToFlow(flow, schedule)}
            error={errorFlows}
            loading={loading === "flows"}
            onCancel={() => {
              setOpenFlows("");
              setErrorFlows("");
            }}
            title={`${t("container.orders.orderToFlows")}`}
            open={openFlows === "flows"}
            id=""
          />
          <FlexContainer padding="0 0 1em 0" justifyContent="space-between">
            <ContainerTitle
              size="medium"
              label={t("container.orders.ordersLabel")}
              count={numberFormatNoFractions(textSearch.orders.count)}
            />

            <FlexContainer alignItems="center" justifyContent="flex-end">
              {profile?.adminRole ? (
                <InputSelect
                  containerClassname={styles.inputSelect}
                  options={venueArray}
                  value={venueId}
                  height="2.5em"
                  label={t("container.certificates.venue")}
                  showError={false}
                  showLabel={false}
                  onChange={(value) => {
                    setPage(0);
                    setVenueId(value);
                  }}
                />
              ) : null}
              <div style={{ marginLeft: "1em" }}>
                <Button
                  theme="blue"
                  label={t("container.orders.uploadOrders")}
                  onClick={() => setOpen("file")}
                />
              </div>
            </FlexContainer>
          </FlexContainer>

          <FlexContainer
            padding="1em 0 0 0"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <FlexContainer
              width="50%"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <div className={styles.searchContainerText}>
                <Search
                  style={{ marginBottom: "1em" }}
                  placeholder={t("component.orderFilters.search")}
                  onSearch={() => {
                    setSearch(text);
                  }}
                  value={text}
                  onChange={(text) => {
                    setText(text.target.value);
                  }}
                />
              </div>
              {search ? (
                <div style={{ marginLeft: "1em" }}>
                  <Button
                    theme="white"
                    onClick={() => setOpen("save")}
                    label={t("container.orders.save")}
                  />
                </div>
              ) : null}
            </FlexContainer>

            {!selectedOrders.length ? null : (
              <FlexContainer justifyContent="flex-end">
                <Text
                  padding="0 1em 0 0"
                  text={`${selectedOrders.length} ${t(
                    "container.orders.selected"
                  )}`}
                />
                <Button
                  theme="white"
                  label={t("container.orders.sendSelection")}
                  icon={flow}
                  onClick={() => setOpenFlows("flows")}
                />
              </FlexContainer>
            )}
          </FlexContainer>

          <FlexContainer
            flexWrap="wrap"
            justifyContent="flex-start"
            padding="1em 0 0.5em 0"
          >
            {from && to ? (
              <div className={styles.tag} style={{ margin: "0 0.3em 0.3em 0" }}>
                <div style={{ color: "#1890ff" }}>{`${dayjs(from).format(
                  "YYYY-MM-DD"
                )} - ${dayjs(to).format("YYYY-MM-DD")}`}</div>
                <button
                  className={styles.button}
                  name="remove"
                  onClick={() => {
                    setFrom("");
                    setTo("");
                  }}
                >
                  <CrossIcon color={"#1890ff"} />
                </button>
              </div>
            ) : null}

            {currency.items.filter((item) => item.key.trim()).length > 0 &&
              generateTag(currency, (label) =>
                setCurrency((prevFilter) => ({
                  ...prevFilter,
                  items: prevFilter.items.filter((item) => item.key !== label),
                }))
              )}

            {customerName.items.filter((item) => item.key.trim()).length > 0 &&
              generateTag(customerName, (label) =>
                setCustomerName((prevFilter) => ({
                  ...prevFilter,
                  items: prevFilter.items.filter((item) => item.key !== label),
                }))
              )}

            {state.items.filter((item) => item.key.trim()).length > 0 &&
              generateTag(state, (label) =>
                setState((prevFilter) => ({
                  ...prevFilter,
                  items: prevFilter.items.filter((item) => item.key !== label),
                }))
              )}

            {tags.items.filter((item) => item.key.trim()).length > 0 &&
              generateTag(tags, (label) =>
                setState((prevFilter) => ({
                  ...prevFilter,
                  items: prevFilter.items.filter((item) => item.key !== label),
                }))
              )}

            {channelName.items.filter((item) => item.key.trim()).length > 0 &&
              generateTag(channelName, (label) =>
                setChannelName((prevFilter) => ({
                  ...prevFilter,
                  items: prevFilter.items.filter((item) => item.key !== label),
                }))
              )}

            {fulfillmentLevel.items.filter((item) => item.key.trim()).length >
              0 &&
              generateTag(fulfillmentLevel, (label) =>
                setFulfillmentLevel((prevFilter) => ({
                  ...prevFilter,
                  items: prevFilter.items.filter((item) => item.key !== label),
                }))
              )}

            {fulfillmentState.items.filter((item) => item.key.trim()).length >
              0 &&
              generateTag(fulfillmentState, (label) =>
                setFulfillmentState((prevFilter) => ({
                  ...prevFilter,
                  items: prevFilter.items.filter((item) => item.key !== label),
                }))
              )}

            {paymentState.items.filter((item) => item.key.trim()).length > 0 &&
              generateTag(paymentState, (label) =>
                setPaymentState((prevFilter) => ({
                  ...prevFilter,
                  items: prevFilter.items.filter((item) => item.key !== label),
                }))
              )}

            {totalRanges.items.filter((item) => item.key.trim()).length > 0 &&
              generateTag(totalRanges, (label) =>
                setTotalRanges((prevFilter) => ({
                  ...prevFilter,
                  items: prevFilter.items.filter((item) => item.key !== label),
                }))
              )}

            {hasNonEmptyState && (
              <>
                <button
                  style={{ cursor: "pointer", textDecorationLine: "underline" }}
                  onClick={closeAllTags}
                >
                  Clear All
                </button>
                <div style={{ marginLeft: "1em" }}>
                  <Button
                    theme="white"
                    onClick={() => setOpen("save")}
                    label={t("container.orders.save")}
                  />
                </div>
              </>
            )}
          </FlexContainer>

          <div className={styles.content}>
            <OrdersList
              onTagClick={(tag) => {
                setPage(0);
                const existingIndex = tags.items.findIndex(
                  (item) => item.key === tag
                );
                let updatedItems;
                if (existingIndex !== -1) {
                  updatedItems = tags.items.filter((item) => item.key !== tag);
                } else {
                  updatedItems = [
                    ...tags.items,
                    {
                      key: tag,
                      include: tags?.items.every(
                        (item) => item.include === true
                      ),
                    },
                  ];
                }
                setTags({
                  ...tags,
                  items: updatedItems,
                });
              }}
              onCustomerClick={(value) => {
                setPage(0);
                setText(value);
              }}
              sortable
              error={textSearch.error}
              loading={textSearch.loading}
              data={textSearch.orders.hits}
              onChangeSortOrder={(sort) => {
                setPage(0);
                setSortBy(sort);
              }}
              selected={selectedOrders}
              setSelected={setSelected}
              page={parseInt(page as string)}
              total={textSearch.orders.count}
              hitsPerPage={parseInt(records_per_page as string)}
              onPageChange={setPage}
              onHitsPerPageChange={(number) => setHitsPerPage(number)}
              pagination
            />
          </div>
        </div>
      </GridContainer>
    </LayouWithSideBar>
  );
};

export default Orders;
