import React from "react";

import { PassDefinition } from "../../utils/types";
import { useTranslation } from "react-i18next";
import Editor from "@monaco-editor/react";
import styles from "./styles";
import { ContainerTitle } from "../ContainerTitle";
import { InputText } from "../Inputs/InputText";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";

interface Props {
  data: PassDefinition;
  onChange: <P extends keyof PassDefinition>(
    prop: P,
    value: PassDefinition[P]
  ) => void;
}

const PassDefForm: React.FC<Props> = ({ data, onChange }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <ContainerTitle
          padding="1em 0 1em 0"
          size="xsmall"
          label={t("component.ticketingDefForm.details")}
        />
        <InputText
          label={t("component.ticketingDefDetails.description")}
          value={data.description}
          onChange={(value) => onChange("description", value)}
        />

        <InputLabel label={t("component.ticketingDefDetails.data")} />

        <div className={styles.editorDetails}>
          <Editor
            className={styles.editorBox}
            height="100%"
            // theme="vs-dark"
            defaultLanguage="json"
            value={data.name}
            options={{
              selectOnLineNumbers: true,
              // minimap: { enabled: false },
              scrollbar: {
                vertical: "hidden",
              },
              overviewRulerLanes: 0,
              hideCursorInOverviewRuler: true,
              overviewRulerBorder: false,
              foldingHighlight: false,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PassDefForm;
