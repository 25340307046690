import React, { useState } from "react";


import { useTranslation } from "react-i18next";

import ScheduleDetail from "./ScheduleDetail";
import DeleteModal from "../DeleteModal";
import ScheduleLogList from "../ScheduleLogList";
import {
  Action,
  Schedule,
  ScheduleLog,
} from "@innomius/ravent-typescript-types";
import styles from "./styles";
import { useHistory, useLocation } from "react-router-dom";
import TabNavBar from "../TabNavBar";
import { SideMenu } from "../SideMenu/SideMenu";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { EmptyList } from "../List/EmptyList";

interface Props {
  data: Schedule;
  actions: Action[];
  onDeleteSchedule: (id: string) => void;
  logs: ScheduleLog[];
}

const SchedulingForm: React.FC<Props> = ({
  data,
  onDeleteSchedule,
  logs,
  actions,
}) => {
  const [modal, setModal] = useState<string>("");

  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();

  return (
    <SideMenu
      width="60%"
      onClose={() => history.push(`/integrations/schedules`)}
      open={
        location.pathname === `/integrations/schedules/${data.id}` ||
        location.pathname === `/integrations/schedules/${data.id}/logs`
      }
    >
      <DeleteModal
        open={modal === "remove" || modal === "delete"}
        onCancel={() => history.push(`/integrations/schedules/`)}
        onDelete={() => onDeleteSchedule(data.id)}
        value={
          modal === "remove"
            ? "Are you sure you want to remove the scheduling?"
            : "Are you sure you want to delete this schedule?"
        }
      />

      <>
        <FlexContainer padding="1em 0 0 0" justifyContent="space-between">
          <ContainerTitle size="medium" label={data.prettyName || "Schedule"} />
          <ButtonsContainer justifyContent="flex-start">
            <Button
              label={t("component.orderStateList.delete")}
              theme="red"
              onClick={() => setModal("delete")}
            />
          </ButtonsContainer>
        </FlexContainer>
      </>

      <TabNavBar
        options={[
          {
            id: "details",
            path: `/integrations/schedules/${data.id}`,
            label: t("component.orderActionList.details"),
            onClick: () => {
              history.push(`/integrations/schedules/${data.id}`);
            },
          },
          {
            label: t("component.orderActionList.historyLogs"),
            id: "losgs",
            path: `/integrations/schedules/${data.id}/logs`,
            onClick: () => {
              history.push(`/integrations/schedules/${data.id}/logs`);
            },
          },
        ]}
      >
        {location.pathname === `/integrations/schedules/${data.id}` ? (
          <ScheduleDetail
            actions={actions}
            onDeleteSchedule={onDeleteSchedule}
            data={data}
          />
        ) : (
          <div className={styles.itemsWrapper}>
            {logs.length ? (
              <ScheduleLogList
                action={false}
                data={logs}
                loading={false}
                error=""
              />
            ) : (
              <EmptyList
                instructions={""}
                text={t("component.orderActionList.empty")}
              />
            )}
          </div>
        )}
      </TabNavBar>
    </SideMenu>
  );
};

export default SchedulingForm;
