import { Payment, Refund } from "@innomius/ravent-typescript-types";
import cash from "../assets/paymentsMethods/cash.svg";
import convenienceStore from "../assets/paymentsMethods/convenience-store.svg";
import creditCard from "../assets/paymentsMethods/credit-card.svg";
import link from "../assets/paymentsMethods/link.svg";
import codi from "../assets/paymentsMethods/qr-code.svg";
import wireTransfer from "../assets/paymentsMethods/wiretransfer.svg";

import { createRequestMessage } from "./messaging";
import {
  isCard,
  isCash,
  isWire,
  isPayNet,
  isCardRefund,
  isCashRefund,
  isPayNetRefund,
  isStorePayment,
  isFirstData,
} from "./typeGuards";
import { FOP } from "./types";

export const paymentTypeIconMap: Record<string, string> = {
  "credit-card": creditCard,
  "card-reader": creditCard,
  card: creditCard,
  codi: codi,
  cash: cash,
  "in-store": convenienceStore,
  "payment-link": link,
  "first-data-paymentLink": link,
  "first-data-urlLink": link,
  "wire-transfer": wireTransfer,
};

export function paymentDetails(payment: Payment) {
  if (isCard(payment)) {
    return `${payment.paymentData.cardBrand} ****${payment.paymentData.lastFourDigits} `;
  }
  if (isCash(payment)) {
    return `Employee: ${payment.createdBy}`;
  }
  if (isWire(payment)) {
    return `Account: ${payment.paymentData.bankAccount}`;
  }
  if (isPayNet(payment)) {
    return `${payment.paymentData.customerReference}`;
  }
  if (isStorePayment(payment)) {
    return `${payment.reference}`;
  }
  if (isFirstData(payment)) {
    return `${payment.link}`;
  }
}

export function refundDetails(refund: Refund) {
  if (isCardRefund(refund)) {
    return `${refund.cardBrand} ${refund.lastFourDigits}`;
  }
  if (isCashRefund(refund)) {
    return `${refund.employeeId}`;
  }

  if (isPayNetRefund(refund)) {
    return `${refund.paymentId}`;
  }
}

export function onCopy(payment: Payment, shortId: string, orgName: string) {
  navigator.clipboard.writeText(
    createRequestMessage(payment, shortId, orgName)
  );
}

export function requestQRContent(payment: Payment) {
  const inStoreMessage = `Reference: ${payment.paymentData.reference}`;
  const paymentLinkMessage = `${payment.paymentData.link}`;
  const EPC069QRCode = `BCD
1
001
SCT
${payment.paymentData.bankCode}
${payment.paymentData.customerName}
${payment.paymentData.bankAccount}
${payment.amount.currency}${Number(payment.amount.value).toFixed(1)}

${payment.paymentData.customerReference}
  `;
  if (payment.paymentType === "wire-transfer") {
    return EPC069QRCode;
  }
  if (payment.paymentType === "in-store") {
    return inStoreMessage;
  }
  if (payment.paymentType === "payment-link") {
    return paymentLinkMessage;
  }
  return payment.id;
}

export const supplierOptions = (type: string, fops: FOP[]) => {
  const suppliers = fops
    .filter((item) => item.type === type)
    .map((item) => ({
      label: `${item.supplierName} - ${item.supplierService}`,
      value: item.id,
    }));
  return suppliers || [];
};
