import React from "react";
import { classes } from "typestyle";
import { capitalize } from "../../utils/format";
import styles from "./styles.module.css";

export interface Props {
  name: string;
  color?: string;
}

export const UserCircle: React.FC<Props> = ({ name, color }) => {
  return (
    <div className={classes(styles.circle, { color: color })}>
      {capitalize(name.charAt(0))}
    </div>
  );
};
