import React from "react";
import { OrderEvent } from "@innomius/ravent-typescript-types";
import Timeline from "antd/lib/timeline/Timeline";
import { Card } from "../Card/Card";
import styles from "./styles";
import WebHookEvent from "./WebHookEvent";
import { useTranslation } from "react-i18next";
import { WebHookNotifications } from "../../utils/types";
import ErrorBox from "../ErrorBox";
import { ContainerTitle } from "../ContainerTitle";
import { EmptyList } from "../List/EmptyList";

interface Props {
  orderHistory: OrderEvent[];
  webHookNotifications: WebHookNotifications[];
  error: string;
  clickUser: (id: string) => void;
}

const OrderHistory: React.FC<Props> = ({
  webHookNotifications,
  clickUser,
  error,
}) => {
  const { t } = useTranslation();

  const items = webHookNotifications.map((item) => ({
    children: (
      <WebHookEvent
        clickUser={(id) => clickUser(id)}
        key={item.id}
        data={item}
      />
    ),
  }));

  return (
    <div className={styles.container}>
      <Card>
        <div className={styles.wrapperCard}>
          <ContainerTitle padding="0 0 1em 0" size="small" label={t("component.orderHistory.history")} />
          {error ? (
            <ErrorBox error={error} />
          ) : webHookNotifications.length ? (
            <Timeline mode="alternate" items={items} />
          ) : (
            <EmptyList
              text={t("component.orderHistory.notFound")}
              instructions={""}
            />
          )}
        </div>
      </Card>
    </div>
  );
};

export default OrderHistory;
