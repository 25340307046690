
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { numberFormat, numberFormatNoDecimals } from "../../utils/format";
import { OrderStateSummaryCount, Profile } from "../../utils/types";
import ErrorBox from "../ErrorBox";
import { OrderStateSummarySkeleton } from "../ListSkeletons";
import Text from "../Text";
import OrderStateItem from "./OrderStateItem";
import PaymentStateSummary from "./PaymentStatesSummary";
import styles from "./styles.module.css";
import StackedBars from "./StackedBars";
import { InputSelect } from "../Inputs/InputSelect";
import { Card } from "../Card/Card";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { ButtonsContainer } from "../ButtonsContainer";
import { GridContainer } from "../GridContainer/GridContainer";

interface StateProps {
  error: string;
  loading: string;
  orderStates: OrderStateSummaryCount[];
  from: string;
  to: string;
  loadingP: string;
  paymentStates: OrderStateSummaryCount[];
  onStateIn: (value: string) => void;
  stateIn: string;
  venueId: string;
  setVenueId: (id: string) => void;
  currency: string;
  setCurrency: (value: string) => void;
  venueArray: any;
  profile: Profile | null;
  currencies: { label: string; value: string }[];
}

const OrderStatesSummary: React.FC<StateProps> = ({
  loading,
  error,
  orderStates,
  from,
  loadingP,
  to,
  paymentStates,
  onStateIn,
  stateIn,
  currency,
  setCurrency,
  setVenueId,
  venueId,
  venueArray,
  profile,
  currencies,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const total = orderStates
    .map((item) => item.count)
    .reduce((partialSum, a) => partialSum + a, 0);

  const totalAmount = orderStates
    .map((item) => item.total)
    .reduce((partialSum, a) => partialSum + a, 0);

  const sortingArr = ["created", "fulfilled", "cancelled"];

  const statesSorted = orderStates.sort(
    (a, b) => sortingArr.indexOf(a.id) - sortingArr.indexOf(b.id)
  );

  const totalPayment = paymentStates
    .map((item) => item.count)
    .reduce((partialSum, a) => partialSum + a, 0);

  const totalPaymentAmount = paymentStates
    .map((item) => item.total)
    .reduce((partialSum, a) => partialSum + a, 0);

  const sortingArrPayment = [
    "un-paid",
    "partial",
    "paid",
    "refunded",
    "expired",
  ];

  const statesPay = paymentStates.sort(
    (a, b) => sortingArrPayment.indexOf(a.id) - sortingArrPayment.indexOf(b.id)
  );

  return (
    <Card>
      <FlexContainer alignItems="flex-start" justifyContent="space-between">
        <ContainerTitle
          size="big"
          label={t("container.dashboard.orders")}
          count={numberFormatNoDecimals(total)}
        />
        <FlexContainer>
          <InputSelect
            label={t("container.dashboard.currency")}
            value={currency}
            containerClassname={styles.inputSelectCurrency}
            options={currencies}
            showError={false}
            onChange={setCurrency}
          />
          {profile?.roles.find((item) => item.includes("owner")) ? (
            <InputSelect
              containerClassname={styles.inputSelect}
              options={venueArray}
              value={venueId}
              label={t("container.certificates.venue")}
              showError={false}
              onChange={(value) => setVenueId(value)}
            />
          ) : null}
        </FlexContainer>
      </FlexContainer>

      <FlexContainer padding="1em 0 0 0" justifyContent="space-between">
        <ButtonsContainer justifyContent="flex-start">
          {statesSorted.map((item, i) => (
            <div key={i}>
              <div
                onClick={() => {
                  if (stateIn?.includes(item.id)) {
                    onStateIn?.(stateIn.replace(`${item.id}`, ""));
                  } else {
                    onStateIn?.(`${stateIn},${item.id}`);
                  }
                }}
                className={
                  stateIn?.includes(item.id)
                    ? styles.buttonFilterActive
                    : styles.buttonFilter
                }
              >
                {item.id}
              </div>
            </div>
          ))}
        </ButtonsContainer>

        <FlexContainer justifyContent="flex-end">
          <Text
            fontSize="1.4em"
            text={`${numberFormat(totalAmount.toString())}`}
            color="#000"
            fontWeight={700}
          />
          <StackedBars
            orderStates={statesPay}
            count={totalPaymentAmount}
            total={totalPayment}
          />
        </FlexContainer>
      </FlexContainer>

      {error ? (
        <ErrorBox error={error} />
      ) : loading === "orderStates" ? (
        <OrderStateSummarySkeleton />
      ) : (
        <GridContainer
          padding="2em 0 1em 0"
          gap="1.5em"
          columns="1fr  1fr  1fr"
        >
          {statesSorted.map((item, i) => (
            <div key={i}>
              <OrderStateItem
                orderState
                key={item.id}
                stateIn={stateIn}
                setState={(value) => onStateIn(value)}
                lastItem={i !== statesSorted.length - 1}
                onClick={() =>
                  history.push(
                    `/orders?stateIn=${item.id}&from=${from}&to=${to}`
                  )
                }
                totalAmount={totalAmount}
                status={item.id}
                amount={item.total}
                count={item.count}
              />
            </div>
          ))}
        </GridContainer>
      )}
      <PaymentStateSummary
        paymentStates={paymentStates}
        from={from}
        to={to}
        error={error}
        loading={loadingP}
        stateIn={stateIn}
      />
    </Card>
  );
};

export default OrderStatesSummary;
