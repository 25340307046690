import {
  InvoiceFile,
  InvoiceStatus,
  OrderInvoice,
} from "@innomius/ravent-typescript-types";
import { get } from "lodash";
import { getAccessToken } from "./auth";
import fetch from "../utils/fetch";
import { Schema } from "../utils/types";
import { invoiceMexico } from "../mocks/invoices";
import multiPartFetch from "../utils/multiPartFetch";

export function parseInvoiceFile(data: unknown): InvoiceFile {
  return {
    id: get(data, "_id", ""),
    name: get(data, "name", ""),
    file: get(data, "file_id", ""),
    fileSize: get(data, "fileSize", ""),
    issuerUrl: get(data, "url", ""),
  };
}

export function parseInvoice(data: unknown): OrderInvoice {
  return {
    id: get(data, "_id", ""),
    amount: {
      value: get(data, "amount.value", 0),
      currency: get(data, "amount.currency", ""),
    },
    authority: get(data, "authority", ""),
    authorityId: get(data, "authorityId", ""),
    status: get(data, "status", "" as InvoiceStatus),
    paymentID: get(data, "paymentId", ""),
    paymentRequestID: get(data, "paymentRequestID", ""),
    description: get(data, "description", ""),
    issuer: get(data, "issuer", ""),
    issuerId: get(data, "issuerId", ""),
    issuedDate: get(data, "issuedDate", ""),
    files: get(data, "files", []).map(parseInvoiceFile),
  };
}

export async function invoiceList(orderId: string): Promise<OrderInvoice[]> {
  const token = await getAccessToken();
  const res = await fetch("/invoices", token, "GET", undefined, {
    orderId: orderId,
  });
  return res.body ? res.body.hits.map(parseInvoice) : [];
}

export async function addInvoiceFile(
  invoiceId: string,
  files: File[],
  orderId: string,
  issuerUrl?: string
): Promise<OrderInvoice> {
  const token = await getAccessToken();

  const res = await multiPartFetch(
    `/invoices/${invoiceId}/file`,
    token,
    files[0]
  );
  const data = parseInvoice(res.body);
  return data;
}

export async function updateInvoice(
  orderId: string,
  invoiceId: string,
  updates: OrderInvoice
): Promise<OrderInvoice> {
  const token = await getAccessToken();

  const body = {
    description: updates?.description,
    paymentRequestID: updates?.paymentRequestID,
    paymentId: updates?.paymentID,
    issuer: updates?.issuer,
    issuerId: updates?.issuerId,
    authority: updates?.authority,
    authorityId: updates?.authorityId,
    issuedDate: updates?.issuedDate,
  };

  const res = await fetch(`/invoices/${invoiceId}`, token, "PATCH", body);
  const data = parseInvoice(res.body);
  return data;
}

export async function updateInvoiceOrder(
  orderId: string,
  invoiceId: string,
  update: Record<string, any>
): Promise<OrderInvoice> {
  const token = await getAccessToken();
  const body = update;
  const res = await fetch(`/invoices/${invoiceId}`, token, "PATCH", body);
  const data = parseInvoice(res.body);
  return data;
}

export async function updateInvoiceStatus(
  invoiceId: string,
  orderId: string
): Promise<OrderInvoice> {
  const token = await getAccessToken();
  const body = {
    status: "canceled",
  };
  const res = await fetch(
    `/invoices/${invoiceId}/status`,
    token,
    "PATCH",
    body
  );
  const data = parseInvoice(res.body);
  return data;
}

export async function getInvoiceFile(
  invoiceId: string,
  fileId: string,
  orderId: string
): Promise<OrderInvoice> {
  const token = await getAccessToken();

  const res = await fetch(`/invoices/${invoiceId}/file/${fileId}`, token);
  const data = parseInvoice(res.body);
  return data;
}

export async function createInvoice(
  data: OrderInvoice,
  orderId: string,
  amount: string,
  currency: string
): Promise<OrderInvoice> {
  const body = {
    orderId: orderId,
    description: data.description,
    paymentRequestID: data.paymentRequestID,
    paymentId: data.paymentID,
    issuer: data.issuer,
    issuerId: data.issuerId,
    authority: data.authority,
    authorityId: data.authorityId,
    issuedDate: data.issuedDate,
    amount: parseInt(amount),
    currency: currency,
  };

  const token = await getAccessToken();
  const res = await fetch(`/invoices`, token, "POST", body);
  return parseInvoice(res.body);
}

export async function getInvoiceSchema(): Promise<Schema> {
  const res = await invoiceMexico;
  return res as Schema;
}

export const defaultInvoice: OrderInvoice = {
  id: "",
  amount: {
    value: 0,
    currency: "",
  },
  authority: "",
  authorityId: "",
  status: "issued",
  paymentID: "",
  paymentRequestID: "",
  description: "",
  issuer: "",
  issuerId: "",
  issuedDate: new Date(),
  files: [],
};
