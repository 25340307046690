import React from "react";

import { Modifier } from "@innomius/ravent-typescript-types";
import styles from "./styles";
import { modifiersOptionsType } from "../../constants/offers";
import ModifierValueForm from "./ModifierValueForm";
import { useTranslation } from "react-i18next";
import { ContainerTitle } from "../ContainerTitle";
import { InputText } from "../Inputs/InputText";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputSelect } from "../Inputs/InputSelect";
import { InputCheckbox } from "../Inputs/InputCheckbox";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";

interface Props {
  edit?: boolean;
  onCancel: () => void;
  onSave: () => void;
  data: Modifier;
  onChange: <P extends keyof Modifier>(prop: P, value: Modifier[P]) => void;
}

const OfferModifierForm: React.FC<Props> = ({
  edit,

  data,
  onCancel,
  onChange,
  onSave,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.containerDetails}>
        <ContainerTitle
          padding="0 0 1em 0"
          size="xsmall"
          label={
            !edit
              ? t("component.offerVariantForm.addNewModifier")
              : t("component.offerVariantForm.editModifier")
          }
        />
        <InputText
          value={data.name}
          label={t("component.offerVariantForm.modifierName")}
          onChange={(value) => onChange("name", value)}
        />
        <GridContainer columns="2fr 1fr 1fr" gap="1em">
          <InputSelect
            label={t("component.offerVariantForm.selectionTypeLabel")}
            value={data.selectionType}
            options={modifiersOptionsType}
            onChange={(value) =>
              onChange("selectionType", value as "single" | "multiple")
            }
          />
          {data.selectionType === "multiple" ? (
            <>
              <InputText
                value={data.minSelection}
                label={t("component.offerVariantForm.minSelection")}
                onChange={(value) => onChange("minSelection", parseInt(value))}
              />
              <InputText
                value={data.maxSelection}
                label={t("component.offerVariantForm.maxSelection")}
                onChange={(value) => onChange("maxSelection", parseInt(value))}
              />
            </>
          ) : null}
        </GridContainer>

        <InputCheckbox
          containerClassname={styles.defaultContainer}
          value={data.default}
          text={t("component.offerVariantForm.defaultValue")}
          onChange={(value) => onChange("default", value)}
        />
        <ModifierValueForm
          data={data.options}
          onChange={(value) => onChange("options", value)}
        />
        <ButtonsContainer padding="2em 0 0 0" justifyContent="flex-start">
          <Button
            theme="white"
            label={t("component.offerVariantForm.cancel")}
            onClick={onCancel}
          />
          <Button
            onClick={onSave}
            theme="blue"
            label={t("component.offerVariantForm.save")}
          />
        </ButtonsContainer>
      </div>
    </div>
  );
};

export default OfferModifierForm;
