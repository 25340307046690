import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import { RootState } from "../../store";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { Card } from "../../components/Card/Card";
import { Button } from "../../components/Button";
import { ContainerTitle } from "../../components/ContainerTitle";

import EntityList from "../../components/EntityList";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { teamTextSearchList } from "../../store/teamsTextSearch";
import qs from "qs";
import Search from "antd/es/input/Search";
import { EmptyList } from "../../components/List/EmptyList";

interface Props {}

const Entities: React.FC<Props> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const teams = useSelector((state: RootState) => state.teamsTextSearch);
  const [hitsPerPage, setHitsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [sort, setSortBy] = useState("name:desc");

  function useQuery() {
    const { search } = useLocation();
    return search;
  }
  const query = useQuery();

  const [search, setSearch] = useState(
    (qs.parse(query.substring(1)).search as string) || ""
  );

  const load = useCallback(async () => {
    dispatch(
      teamTextSearchList({ page, records_per_page: hitsPerPage, search, sort })
    );
  }, [dispatch, page, hitsPerPage, search, sort]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    document.title = "RAVENT APP :: Orgs";
  });

  if (!auth.loading && !auth.user) {
    return <Redirect from="*" to="/" />;
  }
  return (
    <LayouWithSideBar>
      <div className={styles.container}>
        <FlexContainer justifyContent="space-between" alignItems="center">
          <ContainerTitle
            size="medium"
            label={t("container.entities.orgsLabel")}
            count={teams.list.count.toString()}
          />
          <Button
            label={t("container.entities.createOrg")}
            onClick={() => history.push("/teams/new")}
          />
        </FlexContainer>

        <div className={styles.searchContainer}>
          <Search
            style={{ margin: "1em 0" }}
            value={search}
            onChange={(text) => {
              setSearch(text.target.value);
            }}
            onSearch={() => load()}
            placeholder={`${t("container.entities.search")}`}
          />
        </div>

        {!teams.list.hits.length && !teams.error && !teams.loading ? (
          <Card>
            <EmptyList
              instructions={""}
              text={t("container.entities.instructions")}
            />
          </Card>
        ) : (
          <EntityList
            error={teams.error}
            loading={teams.loading}
            history={history}
            data={teams.list.hits}
            page={page}
            sortable
            onChangeSortOrder={setSortBy}
            total={teams.list.count}
            hitsPerPage={hitsPerPage}
            onPageChange={setPage}
            onHitsPerPageChange={setHitsPerPage}
            pagination
          />
        )}
      </div>
    </LayouWithSideBar>
  );
};

export default Entities;
