import React from "react";

import { useTranslation } from "react-i18next";
import Editor from "@monaco-editor/react";
import styles from "./styles";
import { ContainerTitle } from "../ContainerTitle";
import { SectionProperty } from "../Section/SectionProperty";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";

interface Props {
  description: string;
  jsonData: string;
}

const PassDefDetails: React.FC<Props> = ({ description, jsonData }) => {
  const { t } = useTranslation();

  return (
    <div>
      <ContainerTitle
        padding="1em 0 1em 0"
        size="xsmall"
        label={t("component.ticketingDefDetails.ticketing")}
      />

      <SectionProperty
        label={t("component.ticketingDefDetails.description")}
        value={description || "-"}
      />
      <InputLabel label={t("component.ticketingDefDetails.data")} />
      <div className={styles.editorDetails}>
        <Editor
          className={styles.editorBox}
          height="100%"
          // theme="vs-dark"
          defaultLanguage="json"
          value={jsonData}
          options={{
            readOnly: true,
            selectOnLineNumbers: true,
            // minimap: { enabled: false },
            scrollbar: {
              vertical: "hidden",
            },
            overviewRulerLanes: 0,
            hideCursorInOverviewRuler: true,
            overviewRulerBorder: false,
            foldingHighlight: false,
          }}
        />
      </div>
    </div>
  );
};

export default PassDefDetails;
