import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  orderList as orderListService,
  OrderListParams,
  OrderSearchResponse,
} from "../services/orders";

type OrdersSliceState = {
  loading: boolean;
  error: string;
  list: OrderSearchResponse;
};

const initialState: OrdersSliceState = {
  loading: true,
  error: "",
  list: {
    page: 0,
    total: 0,
    data: [],
  },
};

export const orderList = createAsyncThunk(
  "orders/list",
  async (params: OrderListParams) => {
    const response = await orderListService(params);
    return response;
  }
);

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(orderList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(orderList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.list = action.payload;
    });
    builder.addCase(orderList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An unexpected error ocurred";
    });
  },
});

export default ordersSlice.reducer;
