const translation = {
  en: {
    integrationName: "Integration Name",
    status: "Status",
    integrationType: "Integration Type",
    supplierInfo: "Supplier Information",
    supplierType: "Supplier Type",
    supplierName: "Supplier Name",
    supplierService: "Supplier Service",
    configuredJson: "Configurated JSON",
    type: "Type",
    edit: "Edit",
    delete: "Delete",
    deleteWarning: "Are you sure you want to delete this integration ?",
  },

  es: {
    integrationName: "Nombre de la Integración",
    status: "Estados",
    integrationType: "Tipo de integración",
    supplierInfo: "Información del proveedor",
    supplierType: "Tipo de proveedor",
    supplierName: "Nombre del proveedor ",
    supplierService: "Servicio de proveedor",
    configuredJson: "JSON configurado",
    type: "Tipo",
    edit: "Editar",
    delete: "Eliminar",
    deleteWarning: "¿Está seguro de que desea eliminar esta integración?",
  },
  de: {},
};

export default translation;
