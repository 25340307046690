const translation = {
  en: {
    resetPasswordLabel: "Reset Password",
    invalidPhone: "Invalid phone number",
    invalidEmail: "Invalid email",
    instructions: "We will send you instructions to reset your password.",
    email: "Email",
    emailPhone: "Email / Phone Number",
    backToLogin: "Back to Login",
    rightsReserved: "2022 RAVENT • version 0.1.120 • All rights reserved ",
    success: "Successfully Sent",
    instructionsSuccess: "In case we find an account with the email address",
    changeInstructions:
      "we will send an email with the password reset instructions",
    proceed: "Proceed to Login",
  },
  es: {
    invalidPhone: "Número de teléfono inválido",
    invalidEmail: "Correo electrónico inválido",
    emailPhone: "Correo electrónico/ Número de teléfono",
    resetPasswordLabel: "Restablecer contraseña",
    instructions:
      "Le enviaremos instrucciones para restablecer su contraseña.",
    email: "Correo electrónico",
    backToLogin: "Volver a Iniciar Sesión",
    rightsReserved: "2022 RAVENT • version 0.1.120 • Derechos Reservados ",
    success: "Enviado con éxito",
    instructionsSuccess:
      "En caso de que encontremos una cuenta con la dirección de correo electrónico",
    changeInstructions:
      "le enviaremos un correo electrónico con las instrucciones para restablecer la contraseña",
    proceed: "Proceder al inicio de sesión",
  },
  de: {},
};

export default translation;
