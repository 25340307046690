import React, { useState } from "react";

import { ReactComponent as ViewIcon } from "../../assets/View.svg";
import deleteIcon from "../../assets/officialMedia/default-delete.svg";
import { message, Popover } from "antd";
import DeletePopUp from "../DeletePopUp";
import styles from "./styles";
import { useHistory } from "react-router";
import { Integration } from "../../utils/types";
import APIError from "../../utils/APIError";
import { useTranslation } from "react-i18next";
import { StatusBadgePoint } from "../StatusBadgePoint/StatusBagePoint";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";

interface IntegrationItemProps {
  supplierName: string;
  supplierType: string;
  supplierReference: string;
  active: boolean;
  id: string;
  type: string;
  loading: boolean;
  name: string;
  onUpdate: (data: Integration) => void;
  onRemove: (id: string) => void;
  onOpen: (id: string) => void;
}

const IntegrationItem: React.FC<IntegrationItemProps> = ({
  supplierType,
  supplierName,

  id,
  name,
  type,
  onRemove,
  active,
  onOpen,
}) => {
  const [open, setOpen] = useState("");
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div>
      <ListRow
        template="1fr 1fr 1fr 1.5fr 0.8fr 0.5fr"
        hover={true}
        onClick={() => {
          onOpen(id);
          history.push(`/integrations/orgs/${id}`);
        }}
      >
        <ListLabel value={supplierType} textAlign="left" />
        <ListLabel fontFormat="bold" value={supplierName} textAlign="left" />

        <ListLabel value={type} textAlign="left" />

        <ListLabel value={name} textAlign="left" />
        <div className={styles.center}>
          <StatusBadgePoint status={active ? "active" : "DISABLED"} />
        </div>

        <div className={styles.center}>
          <ViewIcon
            className={styles.icon}
            onClick={() => {
              setOpen(id);
              history.push(`/integrations/orgs/${id}`);
            }}
          />
          <Popover
            content={
              <div>
                <DeletePopUp
                  value={`${t(
                    "component.integrationList.deleteWarning"
                  )} ${name}?`}
                  onClose={(e) => {
                    e.stopPropagation();
                    setOpen("");
                  }}
                  onDelete={async (e) => {
                    e.stopPropagation();
                    try {
                      await onRemove(id);
                    } catch (err) {
                      if (err instanceof APIError) {
                        message.error(`${err.message}: ${err.messages}`);
                      }
                    }
                    setOpen("delete");
                  }}
                />
              </div>
            }
            trigger="click"
            open={open === "delete"}
            onOpenChange={() => setOpen(open)}
          />
          <img
            src={deleteIcon}
            className={styles.iconDelete}
            alt="deleteOffer"
            onClick={(e) => {
              e.stopPropagation();
              setOpen("delete");
            }}
          />
        </div>
      </ListRow>
    </div>
  );
};

export default IntegrationItem;
