const translation = {
  en: {
    createIntegration: "Create Integration",
    cancel: "Cancel",
    save: "Save",
    integrationName: "Integration Name",
    status: "Status",
    integrationType: "Integration Type",
    supplierInfo: "Supplier Information",
    supplierType: "Supplier Type",
    supplierName: "Supplier Name",
    supplierService: "Supplier Service",
    setUpJson: "Setup JSON",
    type: "Type",
    selectOption: "Select Option",
    seeExample: "See Example",
  },
  es: {
    createIntegration: "Crear Integración",
    cancel: "Cancelar",
    save: "Guardar",
    integrationName: "Nombre de la Integración",
    status: "Estados",
    integrationType: "Tipo de integración",
    supplierInfo: "Información del proveedor",
    supplierType: "Tipo de proveedor",
    supplierName: "Nombre del proveedor",
    supplierService: "Servicio de proveedor",
    setUpJson: "Configurar JSON",
    type: "Tipo",
    selectOption: "Seleccionar opción",
    seeExample: "Ver Ejemplo",
  },
  de: {},
};

export default translation;
