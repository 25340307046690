const translation = {
  en: {
    setInfo: "Offer Set Information",
    description: "Description",
    diveShop: "Dive Shop",
    tags: "Tags",
    startingPrice: "Starting Price",
    currency: "Currency",
    amount: "Amount"
  },
  es: {},
  de: {},
};

export default translation;
