import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./styles";
import { Checkpoint, FulfillerOverview } from "../../utils/types";
import view from "../../assets/View.svg";
import FulfillerItem from "./FulfillerItem";
import { FulfillerData, fulfillerSchedule } from "../../services/entities";
import APIError from "../../utils/APIError";
import dayjs from "dayjs";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";
import { DayHeatMapResponse, getEventDayHeatMap } from "../../services/events";
import { listCheckpoints } from "../../services/checkpoints";
import { fulfillmentEventListFullText } from "../../services/orders";
import {
  FulfillmentEvent,
  FulfillmentState,
} from "@innomius/ravent-typescript-types";
import {
  MarkerData,
  MarkerStateData,
} from "../MapWithMultipleMarkers/MapWithRoutes";
import { convertEventsToMarkers } from "../../utils/events";
import { numberFormatNoDecimals } from "../../utils/format";
import qs from "qs";
import { listFulfillmentStates } from "../../services/fulfilments";

interface Props {
  data: FulfillerOverview;
  fulfillmentTeamId: string;
  fromPassed: string;
  toPassed: string;
  timezone: string;
  close: () => void;
}

const Item: React.FC<Props> = ({
  data,
  fulfillmentTeamId,
  fromPassed,
  toPassed,
  timezone,
  close,
}) => {
  function useQuery() {
    const { search } = useLocation();
    return search;
  }
  const query = useQuery();
  const history = useHistory();
  const [fulfiller, setFulfiller] = useState<FulfillerData[]>([]);
  const location = useLocation();
  const [fulfillerId, setFulfillerId] = useState<string>(() => {
    const match = location.pathname.match(/\/fulfillers\/([^/?]+)/);
    return match ? match[1] : "";
  });
  const [heatmap, setHeatmap] = useState<DayHeatMapResponse>();
  const [checkpoints, setCheckpoints] = useState<Checkpoint[]>([]);
  const [fulfillerRange, setFulfillerRange] = useState<FulfillerData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [events, setEvents] = useState<FulfillmentEvent[]>([]);
  const [markers, setMarkers] = useState<MarkerData[]>([]);
  const [error, setError] = useState<string>("");
  const [fulfillmentStates, setFulfillmetnStates] = useState<
    FulfillmentState[]
  >([]);
  const [stateMarkers, setStateMarkers] = useState<MarkerStateData[]>([]);

  const [selectedEvents, setSelectedEvents] = useState<FulfillmentEvent[]>([]);

  const [from, setFrom] = useState(
    dayjs(new Date(fromPassed)).format(`YYYY-MM-DD`) ||
      (qs.parse(query.substring(1)).from as string)
  );
  const [to, setTo] = useState(
    dayjs(new Date(toPassed)).format(`YYYY-MM-DD`) ||
      (qs.parse(query.substring(1)).to as string)
  );

  const [day, setDay] = useState<string>(
    dayjs(new Date()).format("YYYY-MM-DD")
  );
  const [sort, setSort] = useState<string | null>(null);

  const getFulfillerData = useCallback(async () => {
    try {
      if (data.fulfillerId === fulfillerId) {
        const res = await fulfillerSchedule({
          fulfillerId: fulfillerId,
          from: day,
          to: day,
          columns: "datetime,state",
        });
        setFulfiller(res.hits);
      }
    } catch (err) {
      if (err instanceof APIError) {
        console.error(err.messages);
      }
    }
  }, [data.fulfillerId, day, fulfillerId]);

  const getStates = useCallback(async () => {
    try {
      if (data.fulfillerId === fulfillerId) {
        const res = await listFulfillmentStates({
          userId: data.fulfillerId,
          from: dayjs(new Date(day)).format("YYYY-MM-DD"),
          to: dayjs(new Date(day)).format("YYYY-MM-DD"),
          page: 0,
          timezone: timezone,
          records_per_page: 100,
        });
        setFulfillmetnStates(res.data);
        setStateMarkers(
          res.data.map((state) => ({
            title: state.name,
            coordinates: {
              latitude: state.location?.coordinates.latitude
                ? parseFloat(state.location?.coordinates.latitude)
                : 0,
              longitude: state.location?.coordinates.longitude
                ? parseFloat(state.location?.coordinates.longitude)
                : 0,
            },
            state,
          }))
        );
      }
    } catch (err) {
      if (err instanceof APIError) {
        console.error(err.messages);
      }
    }
  }, [data.fulfillerId, day, fulfillerId, timezone]);

  const getFulfillerDataRange = useCallback(async () => {
    try {
      if (data.fulfillerId === fulfillerId) {
        const res = await fulfillerSchedule({
          fulfillerId: data.fulfillerId,
          from: dayjs(new Date(from)).format("YYYY-MM-DD"),
          to: dayjs(new Date(to)).format("YYYY-MM-DD"),
          columns: "datetime,state",
        });
        setFulfillerRange(res.hits);
      }
    } catch (err) {
      if (err instanceof APIError) {
        console.error(err.messages);
      }
    }
  }, [data.fulfillerId, from, to, fulfillerId]);

  const loadHeatmap = useCallback(async () => {
    try {
      if (data.fulfillerId === fulfillerId) {
        const res = await getEventDayHeatMap({
          fulfillerId: data.fulfillerId,
          from,
          to,
          timezone,
          search: "",
          numberOfRanges: 10,
        });
        setHeatmap(res);
      }
    } catch (err) {
      if (err instanceof APIError) {
        console.error(err.messages);
      }
    }
  }, [data.fulfillerId, fulfillerId, from, to, timezone]);

  const loadCheckpoints = useCallback(async () => {
    try {
      if (data.fulfillerId === fulfillerId) {
        const res = await listCheckpoints({
          page: 0,
          records_per_page: 700,
          fulfillerId: data.fulfillerId,
          from: dayjs(new Date(day)).format("YYYY-MM-DD"),
          to: dayjs(new Date(day)).format("YYYY-MM-DD"),
          timezone,
        });
        setCheckpoints(res.hits);
      }
    } catch (err) {
      if (err instanceof APIError) {
        console.error(err.messages);
      }
    }
  }, [data.fulfillerId, fulfillerId, day, timezone]);

  useEffect(() => {
    getFulfillerData();
  }, [getFulfillerData, day]);

  useEffect(() => {
    getStates();
  }, [getStates, day]);

  useEffect(() => {
    getFulfillerDataRange();
  }, [getFulfillerDataRange]);

  useEffect(() => {
    loadHeatmap();
  }, [loadHeatmap]);

  useEffect(() => {
    if (fulfillerId === data.fulfillerId) {
      loadCheckpoints();
    }
  }, [data.fulfillerId, loadCheckpoints, fulfillerId]);

  const loadEvents = useCallback(async () => {
    if (data.fulfillerId === fulfillerId) {
      try {
        setLoading(true);
        const res = await fulfillmentEventListFullText({
          page: 0,
          from: day,
          to: day,
          timezone,
          fulfillerId: data.fulfillerId,
          records_per_page: 100,
          dateType: "datetime",
          sort: sort || "datetime:asc",
        });

        setEvents(res.hits);
        setSelectedEvents(res.hits);
        setMarkers(convertEventsToMarkers(res.hits));

        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof APIError) {
          setError(err.message);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day, timezone, fulfillerId, data.fulfillerId, sort]);

  useEffect(() => {
    loadEvents();
  }, [loadEvents, day]);

  return (
    <>
      <FulfillerItem
        from={from}
        stateMarkers={stateMarkers}
        selectedEvents={selectedEvents}
        setSelectedEvents={(events) => {
          setSelectedEvents(events);
          setMarkers(convertEventsToMarkers(events));
        }}
        setFrom={(date) => setFrom(date)}
        setTo={(date) => setTo(date)}
        changeDay={(date) => setDay(date)}
        loading={loading}
        error={error}
        events={events}
        markers={markers || []}
        to={to}
        dataFulfillerRange={fulfillerRange}
        timezone={timezone}
        onClose={() => {
          setFulfillerId("");
          close();
        }}
        onChangeSortOrder={setSort}
        checkpoints={checkpoints}
        data={fulfiller}
        fulfillmentTeamId={fulfillmentTeamId}
        fulfillerId={data.fulfillerId}
        name={data.fulfillerUserName}
        heatmap={heatmap}
      />
      <ListRow
      
        onClick={() => {
          setFulfillerId(data.fulfillerId);
          history.push(
            `/overview/teams/${fulfillmentTeamId}/fulfillers/${data.fulfillerId}?from=${from}&to=${to}`
          );
        }}
        template="2fr 1fr 1fr 1fr 1fr 1fr 1fr"
        hover={true}
        key={data.fulfillerId}
      >
        <ListLabel value={data.fulfillerUserName} textAlign="left" />
        <div className={styles.normal}>
          {numberFormatNoDecimals(data.pending)}
        </div>
        <div className={styles.normal}>
          {numberFormatNoDecimals(data.running)}
        </div>
        <div className={styles.normal}>
          {numberFormatNoDecimals(data.failed)}
        </div>

        <div className={styles.normal}>
          {numberFormatNoDecimals(data.completed)}
        </div>
        <div className={styles.normal}>
          {numberFormatNoDecimals(data.total)}
        </div>
        <div className={styles.center}>
          <img className={styles.icon} src={view} alt="view" />
        </div>
      </ListRow>
    </>
  );
};

export default Item;
