const translation = {
  en: {
    successCreation: "Venue was successfully created",
    successUpdated: "Venue was successfully updated",
    successDelete: "Venue was successfully deleted",

    ticketingDef: "Definitions",
    successCreationTicketing: "Pass Definition succesfully created.",
    back: "Back to Pass Definitions",
    cancel: "Cancel",
    save: "Save",
    ticketingDefNamePlaceholder: "Definition Name",
    detailsLabel: "Details",
    ticketingLabel: "Definitions",
    ticketing: "Ticketing",
    certificates: "Passes",
    venue: "Venue"
  },
  es: {
    venue: "Recinto",
    successCreation: "El Recinto se creó con éxito",
    successDelete: "El Recinto se eliminó con éxito",
    successUpdated: "El Recinto se actualizó con éxito ",
    certificates: "Pases",
    ticketingDef: "Definiciones",
    successCreationTicketing: "Definición de pases creada con éxito.",
    back: "Volver a Definiciones de Pases",
    cancel: "Cancelar",
    save: "Guardar",
    ticketingDefNamePlaceholder: "Nombre de Pase",
    detailsLabel: "Detalles",
    ticketingLabel: "Definiciones",
    ticketing: "Pases",
  },

  de: {},
};

export default translation;
