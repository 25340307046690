import card from "../assets/card_reader.svg";
import cash from "../assets/cash.svg";
import credit from "../assets/credit.svg";
import store from "../assets/convenience_store.svg";
import paymentLink from "../assets/payment_link.svg";
import wiretransfer from "../assets/paymentsMethods/wiretransferazul.svg";

export const fopTitleMap: Record<string, string> = {
  "card-reader": "Card Reader",
  netd: "Credit(NetD Payments)",
  cash: "Cash",
  "in-store": "In Store",
  "payment-link": "Payment Link",
  "wire-transfer": "Wiretransfer",
};

export const fopIconTypeMap: Record<string, string> = {
  "card-reader": card,
  netd: credit,
  cash: cash,
  "in-store": store,
  "payment-link": paymentLink,
  "wire-transfer": wiretransfer,
};
