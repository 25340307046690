
import React, { useEffect, useState } from "react";
import Text from "../Text";
import { useTranslation } from "react-i18next";
import { Load } from "@innomius/ravent-typescript-types";
import produce from "immer";
import styles from "./styles";
import LoadComponent from "./LoadComponent";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { Modal } from "../Modal/Modal";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { InputError } from "../Inputs/InputError/InputError";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputSelect } from "../Inputs/InputSelect";
import { InputText } from "../Inputs/InputText";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";

interface Props {
  onClose: () => void;
  open: boolean;
  loading?: boolean;
  data: Load[];
  error?: string;
  onSave: (data: Load[]) => void;
}

const LoadModal: React.FC<Props> = ({
  onClose,
  open,
  error,
  loading,
  onSave,
  data,
}) => {
  const [load, setLoad] = useState(
    data.length
      ? data
      : [{ name: "", type: "person", details: "", properties: {} }]
  );

  useEffect(() => {
    setLoad(data);
  }, [data]);

  const { t } = useTranslation();

  const onChange = (index: number, newData: Partial<Load>) => {
    setLoad((prevLoad) => {
      return produce(prevLoad, (draft) => {
        draft[index] = { ...draft[index], ...newData };
      });
    });
  };

  return (
    <Modal
      width="70%"
      buttons={
        <div>
          <ButtonsContainer justifyContent="flex-end">
            <Button
              onClick={onClose}
              theme="white"
              label={t("component.loadModal.cancel")}
            />
            <Button
              onClick={() => {
                onSave(load);
              }}
              theme="blue"
              label={loading ? "..." : t("component.loadModal.save")}
            />
          </ButtonsContainer>
          {error ? (
            <FlexContainer justifyContent="flex-end" padding="0.5em 1em 0 0">
              <InputError error={error} />
            </FlexContainer>
          ) : null}
        </div>
      }
      open={open}
      onClose={onClose}
    >
      <FlexContainer justifyContent="space-between">
        <Text
          padding="1em 0 2em 0"
          text={t("component.loadModal.editLoad")}
          color="#000000"
          fontSize="1.4em"
          fontWeight={700}
        />
      </FlexContainer>
      <div className={styles.scroll}>
        {load.map((property, index) => (
          <div style={{ marginBottom: "1em" }} key={`property-${index}`}>
            <GridContainer
              justifyContent="center"
              alignItems="flex-start"
              gap="1em"
              columns="0.5fr 1fr 1fr 2fr 2em"
            >
              <InputSelect
                label={t("component.loadModal.loadType")}
                options={[
                  { label: t("component.loadModal.person"), value: "person" },
                  { label: t("component.loadModal.box"), value: "box" },
                  {
                    label: t("component.loadModal.suitcase"),
                    value: "suitcase",
                  },
                ]}
                value={property.type}
                showError={false}
                onChange={(value) => onChange(index, { type: value })}
              />
              <InputText
                label={t("component.loadModal.name")}
                id={`optionName${index}`}
                value={property.name}
                showError={false}
                onChange={(value) => onChange(index, { name: value })}
              />

              <InputText
                label={t("component.loadModal.details")}
                id={`rate${index}`}
                value={property.details}
                showError={false}
                onChange={(value) => onChange(index, { details: value })}
              />
              <div>
                <InputLabel label={t("component.loadModal.tags")} />
                <div className={styles.box}>
                  <LoadComponent
                    onSave={(value) => onChange(index, { properties: value })}
                    initialLoad={property}
                  />
                </div>
              </div>
            </GridContainer>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default LoadModal;
