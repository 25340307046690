import React from "react";
import VisitorItemList from "./VisitorListItem";
import {
  Message,
  OfficialMedia,
  Visitor,
} from "@innomius/ravent-typescript-types";
import { OrderListSkeleton } from "../ListSkeletons";
import styles from "./styles";
import { PaginationRavent } from "../Pagination/Pagination";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { List } from "../List";

interface VisitorListProps {
  error: string;
  loading: boolean;
  data: Visitor[];
  locationColumn?: boolean;
  path: string;
  messages: Message[];
  officialMedia: OfficialMedia[];
  onChangeSort?: (order: number, sortBy: number) => void;
  page: number;
  total: number;
  hitsPerPage: number;
  onPageChange?: (page: number) => void;
  onHitsPerPageChange?: (hitsPerPage: number) => void;
  sortable: boolean;
  pagination?: boolean;
}

const VisitorList: React.FC<VisitorListProps> = ({
  error,
  loading,
  data,
  locationColumn,
  path,
  messages,
  officialMedia,
  pagination,
  page,
  total,
  hitsPerPage,
  onHitsPerPageChange,
  onPageChange,
}) => {
  const header = (
    <div>
      <ListHeader
        template={
          locationColumn ? "1fr 1fr 1fr 1fr 1fr 1fr" : "1fr 1fr 1fr 1fr 1fr"
        }
      >
        <ListHeaderLabel value="Visitor" justifyContent="flex-start" />
        <ListHeaderLabel value="Reference" justifyContent="flex-start" />
        <ListHeaderLabel value="Phone Number" justifyContent="flex-start" />
        <ListHeaderLabel
          value="Checkout Date Time"
          justifyContent="flex-start"
        />
        {locationColumn ? (
          <ListHeaderLabel value="Location" justifyContent="flex-start" />
        ) : null}
        <ListHeaderLabel value="Conversations" justifyContent="center" />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <div className={locationColumn ? styles.listWrapper : ""}>
        <List
          loading={loading}
          loadingElement={() => <OrderListSkeleton />}
          error={error}
          data={data}
          keyExtractor={(item) => item.id}
          renderHeader={() => header}
          renderItem={(item) => (
            <VisitorItemList
              officialMedia={officialMedia}
              messages={messages}
              path={path}
              data={item}
              key={item.id}
              locationColumn={locationColumn}
            />
          )}
        />
      </div>

      {pagination ? (
        <div className={styles.paginationContainer}>
          <PaginationRavent
            current={page + 1}
            defaultCurrent={page + 1}
            total={total}
            showSizeChanger
            onShowSizeChange={(page, size) => {
              if (onHitsPerPageChange) {
                onHitsPerPageChange(size);
              }
            }}
            onChange={(page) => {
              if (onPageChange) {
                onPageChange(page - 1);
              }
            }}
            pageSize={hitsPerPage}
          />
        </div>
      ) : null}
    </div>
  );
};

export default VisitorList;
