import React from 'react';
import styles from './styles.module.css';

export interface InputErrorProps {
  error?: false | '' | string[] | string;
  showError?: boolean;
}

export const InputError: React.FC<InputErrorProps> = ({ error, showError = true }) => {
  if (showError === false) {
    return null;
  }
  return (
    <div className={styles.labelContainer}>
      {error ? (
        <div className={styles.errorContaienr}>
          <span className={styles.error}>{error}</span>
        </div>
      ) : null}
    </div>
  );
};
