const translation = {
  en: {
    cancel: "Close",
    invalidPhone: "Invalid Phone Number",
    send: "Send",
    shareVia: "Send Via",
    contact: "Contact",
    download: "Download",
    email: "Email",
    es: "Spanish",
    en: "English",
    language: "Content Language",
    flows: "Flows",
    sendManually: "Send Manually",
    sendToFlow: "Send Order to Flow",
    other: "Other",
    instructions: "Select an existing action",
    sendToOther: "Send to Other",
    open: "Preview PDF",
    scheduleDate: "Schedule Date",
    schedule: "Schedule",
    noActions:
      "No actions are configured. Please create a actions in the integrations section.",
    name: "Name",
    later: "Schedule this for later",
  },

  es: {
    later: "Agendar Ejecución",

    noActions:
      "No hay acciones configuradas. Cree una acción en la sección de integraciones.",
    schedule: "Calendarizar",
    name: "Nombre",

    scheduleDate: "Calendarización",
    cancel: "Cerrar",
    invalidPhone: "Número de teléfono no válido",
    send: "Enviar",
    shareVia: "Compartir a través de:",
    contact: "Contacto",
    download: "Descargar",
    email: "Email",
    es: "Español",
    en: "Inglés",
    language: "Lenguaje del Contenido",
    flows: "Flujos",
    sendManually: "Enviar",
    sendToFlow: "Mandar órden a flujo",
    other: "Otro",
    sendToOther: "Mandar a otro",
    instructions: "Selecciona una acción",
    open: "Abrir PDF",
  },
  de: {},
};

export default translation;
