import {
  Discount,
  Payment,
  PaymentGateway,
  Surcharge,
  Tax,
} from "@innomius/ravent-typescript-types";
import {
  PriceRequest,
  PublicOrderline,
  PublicPaymentRequestResponse,
  RegisterPaymentRequest,
  RequestPaymentRequest,
} from "../utils/types";
import get from "lodash/get";
import { getAccessToken } from "./auth";
import fetch from "../utils/fetch";
import CustomError from "../utils/CustomError";
import { parseFOP } from "./fop";
import { isNil, omitBy } from "lodash";

export function parseTax(data?: unknown): Tax {
  return {
    name: get(data, "description", ""),
    id: get(data, "id", ""),
    value: get(data, "value", 0),
    rate: get(data, "rate", ""),
  };
}

export function parseDiscounts(item: unknown): Discount {
  return {
    name: get(item, "name", ""),
    description: get(item, "description", ""),
    value: get(item, "value", 0),
  };
}

export function parseSurcharges(item: unknown): Surcharge {
  return {
    name: get(item, "name", ""),
    description: get(item, "description", ""),
    value: get(item, "value", 0),
  };
}

export function parsePayment(data?: unknown): Payment {
  return {
    authorizedByName: get(data, "authorizedByName", ""),
    authorizedById: get(data, "authorizedById", ""),
    authorizedByExternalId: get(data, "authorizedByExternalId", ""),
    authorizedByExternalName: get(data, "authorizedByExternalName", ""),
    id: get(data, "_id", ""),
    type: get(data, "type", "" as "payment" | "payment-request"),
    status: get(data, "state", ""),
    link: get(data, "paymentData.link", ""),
    reference: get(data, "reference", ""),
    payDayLimit: get(data, "paymentData.payDayLimit", ""),
    supplierService: get(data, "supplierService", ""),
    supplierName: get(data, "supplierName", ""),
    publicPaymentLink: get(data, "publicPaymentLink", ""),
    shortId: get(data, "shortId", ""),
    orgId: get(data, "organization_id", ""),
    paymentType: get(data, "paymentType", ""),
    expiration: get(data, "expiration", ""),
    paymentDueDate: get(data, "expired", ""),
    bankAccountNumber: get(data, "paymentData.bankAccountNumber", ""),
    bankCode: get(data, "paymentData.bankCode", ""),
    barcodeDatauri: get(data, "paymentData.barcodeDatauri", ""),
    bankName: get(data, "paymentData.bankName", ""),
    customerName: get(data, "paymentData.customerName", ""),
    customerReference: get(data, "paymentData.customerReference", ""),
    lastFourDigits: get(data, "paymentData.lastFourDigits", ""),
    gateway: get(data, "paymentData.gateway", "" as PaymentGateway),
    gatewayPanToken: get(data, "paymentData.gatewayPanToken", ""),
    gatewayReference: get(data, "paymentData.gatewayReference", ""),
    cardBrand: get(data, "paymentData.cardBrand", ""),
    paymentData: get(data, "paymentData", {}),
    paymentTimestamp: get(data, "paymentDate", ""),
    created: get(data, "createdAt", ""),
    createdName: get(data, "createdName", ""),
    createdBy: get(data, "createdBy", ""),
    updated: get(data, "updatedAt", ""),
    paymentRequestId: get(data, "paymentRequest_id", ""),
    paymentRequestShortId: get(data, "paymentRequestShortId", ""),
    deleted: get(data, "deleted", false),
    cancelled: get(data, "cancelled", false),
    amount: {
      currency: get(data, "amount.currency", ""),
      value: get(data, "amount", 0),
    },
    orderId: get(data, "order_id", ""),
    employeeId: get(data, "createdBy", ""),
    venueId: get(data, "venueId", ""),
    posId: get(data, "posId", ""),
  };
}

export const defaultPriceRequest: PriceRequest = {
  currency: "",
  amount: "",
  country: "",
};

export async function registerOrderPayment(
  data: RegisterPaymentRequest,
  amount: string,
  orderId: string,
  requestId: string,
  currency: string
): Promise<Payment> {
  const token = await getAccessToken();

  const resFOP = await fetch(`/fops/org/${data.fopId}`, token);
  const fopData = parseFOP(resFOP.body);

  const resRequests = await fetch("/payments", token, "GET", null, {
    type: "payment-request",
    order_id: orderId,
  });
  const paymentRequests = resRequests.body.hits.map(parsePayment);

  const payDate = (data.timestamp as Date).toISOString().split("T")[0];

  let paymentData;

  if (data.type === "wire-transfer") {
    paymentData = {
      bankName: data.bankName,
      bankAccount: data.bankAccount,
      comments: data.comments,
      reference: data.reference,
    };
  }

  if (data.type === "cash") {
    paymentData = {
      comments: data.comments,
    };
  }

  if (data.type === "card-reader") {
    paymentData = {
      cardBrand: data.cardBrand,
      lastFourDigits: data.lastFourDigits,
      bankName: data.bankName,
      customerName: data.customerName,
      paymentTimestamp: payDate,
      authorization: data.cardAuthorization,
    };
  }

  const paymentsWithRequestId =
    data.type === "wire-transfer" ||
    data.type === "payment-link" ||
    data.type === "in-store";

  const body = {
    amount: parseFloat(amount),

    state: "approved",
    paymentDate: payDate,
    order_id: orderId,
    supplierService:
      data.type === "cash"
        ? "cash"
        : paymentsWithRequestId
        ? paymentRequests.find((item: Payment) => item.id === requestId)
            ?.supplierService
        : fopData.supplierService,
    supplierName:
      data.type === "cash"
        ? "cash"
        : paymentsWithRequestId
        ? paymentRequests.find((item: Payment) => item.id === requestId)
            ?.supplierName
        : fopData.supplierName,
    paymentData: paymentData || {},
    paymentType: data.type,
    paymentRequest_id: paymentsWithRequestId ? requestId : null,
  };

  const res = await fetch("/payments", token, "POST", body);
  return res.body;
}

export async function requestOrderPayment(
  data: RequestPaymentRequest,
  orderId: string,
  amount: string,
  shortId: string,
  fopId: string,
  currency: string
): Promise<Payment> {
  const token = await getAccessToken();

  const expiration = (data.expiredDate as Date).toISOString();
  let paymentData;

  const resFOP = await fetch(`/fops/org/${fopId}`, token);
  const fopData = parseFOP(resFOP.body);

  if (fopData.supplierService === "2529") {
    const formatAmount = (amount: string) => {
      if (amount.includes(".")) {
        return amount;
      }
      return `${amount}.00`;
    };
    paymentData = {
      amount: parseFloat(formatAmount(amount)),
      reference: shortId,
      payDayLimit: expiration,
      constant: "A",
      clientReference: shortId,
    };
  }

  if (data.type === "wire-transfer") {
    paymentData = {
      bank: fopData.supplierName,
      bankAccount: fopData.data.account.bankAccount,
      payDayLimit: expiration,
      customerReference: orderId,
    };
  }

  if (fopData.supplierName === "first-data") {
    paymentData = {
      transactionType: "SALE",
    };
  }

  const body = {
    amount: Number(amount),

    paymentType: data.type,
    supplierName: fopData.supplierName,
    order_id: orderId,
    paymentData: paymentData,
    expiration: expiration,
    supplierService: fopData.supplierService,
  };

  const res = await fetch("/payments/request", token, "POST", body);
  return parsePayment(res.body);
}

export async function deletePayementRequest(id: string): Promise<void> {
  const token = await getAccessToken();
  await fetch(`/payments/request/${id}`, token, "DELETE");
  return;
}

export function defaultRequestPayment(date: Date): RequestPaymentRequest {
  return {
    type: "payment-link",
    reference: "",
    supplierService: "",
    fopId: "",
    amount: {
      currency: "",
      amount: "",
      country: "",
    },
    supplierName: "",
    expiredDate: date,
    expiredTime: date,
    link: "",
    constant: "",
    datauri: "",
    bankAccount: "",
    bankName: "",
    concept: "",
    clabe: "",
    phone: "",
    email: "",
    deliveryMethod: "",
    customerReference: "",
    bankCode: "",
  };
}

export function checkRequest(
  input: RequestPaymentRequest,
  amount: string,
  paidAmount: number,
  supplier: string
) {
  const error = new CustomError(
    "Error saving Payment Request Form",
    "formError"
  );

  if (amount.length < 1) {
    error.setError("request-amount", "Request Amount is required");
  }

  if (parseInt(amount) > paidAmount) {
    error.setError("paid-request-amount", "The amount exceeds the due amount.");
  }

  if (
    (input.type === "payment-link" || input.type === "in-store") &&
    !supplier
  ) {
    error.setError("supplierName", "Supplier Name required.");
  }

  if (!error.length) {
    return true;
  }

  throw error;
}

export function checkRegister(input: RegisterPaymentRequest, amount: string) {
  const error = new CustomError(
    "Error saving Register Payment Form",
    "formError"
  );

  if (amount.length < 1) {
    error.setError("register-amount", "Amount is required");
  }
  if (input.type === "wire-transfer" && !input.bankAccount) {
    error.setError("bank-account", "Account is required.");
  }
  if (input.type === "card-reader" && !input.cardAuthorization) {
    error.setError("card-authorization", "Authorization is required.");
  }

  if (input.type === "card-reader" && !input.cardAuthorization) {
    error.setError("card-authorization", "Authorization is required.");
  }
  if (input.type === "card-reader" && !input.cardBrand) {
    error.setError("card-brand", "Brand is required.");
  }
  if (input.type === "card-reader" && !input.lastFourDigits) {
    error.setError("last-four-digits", "Last Four Digitis is required.");
  }

  if (!error.length) {
    return true;
  }

  throw error;
}

export const defaultRegisterPayment: RegisterPaymentRequest = {
  type: "cash",
  reference: "",
  amount: {
    currency: "",
    amount: "",
    country: "",
  },
  fopId: "",
  cardAuthorization: "",
  timestamp: new Date(),
  customerName: "",
  lastFourDigits: "",
  bankName: "",
  bankAccount: "",
  comments: "",
  timeOfPayment: new Date(),
  cardBrand: "",
};

export function parsePublicOrderline(data?: unknown): PublicOrderline {
  return {
    discounts: get(data, "discounts", []).map(parseDiscounts),
    total: get(data, "total", 0),

    description: get(data, "description", ""),
    shippingFee: get(data, "shippingFee", 0),

    surcharges: get(data, "surcharges", []).map(parseSurcharges),
    taxes: get(data, "taxes", []).map(parseTax),
    subTotal: get(data, "subTotal", 0),
  };
}

export function parsePublicPaymentReq(
  data?: unknown
): PublicPaymentRequestResponse {
  return {
    order: {
      description: get(data, "order.description", ""),
      totalDiscount: get(data, "order.totalDiscount", 0),
      currency: get(data, "order.currency", ""),

      total: get(data, "order.total", 0),

      totalTaxes: get(data, "order.totalTaxes", 0),

      subTotal: get(data, "order.subTotal", 0),

      totalSurcharge: get(data, "order.totalSurcharge", 0),

      totalShippingFees: get(data, "order.totalShippingFees", 0),

      due: get(data, "order.due", 0),

      paid: get(data, "order.paid", 0),

      requested: get(data, "order.requested", 0),

      shortId: get(data, "order.shortId", ""),
      paymentState: get(data, "order.paymentState", ""),
      paymentDueDate: get(data, "order.paymentDueDate", ""),
      orderlines: get(data, "order.orderlines", []).map(parsePublicOrderline),
    },
    paymentRequest: {
      expiration: get(data, "paymentRequest.expiration", ""),
      amount: get(data, "paymentRequest.amount", 0),

      link: get(data, "paymentRequest.link", ""),
    },
    organization: {
      name: get(data, "organization.name", ""),
      email: get(data, "organization.email", ""),
      phone: get(data, "organization.phone", ""),
      address: {
        chronologicalIndex: get(
          data,
          "organization.address.chronologicalIndex",
          0
        ),
        id: get(data, "organization.address._id", ""),
        name: get(data, "organization.address.name", ""),
        type: get(data, "organization.address.type", ""),
        line1: get(data, "organization.address.line1", ""),
        line2: get(data, "organization.address.line2", ""),
        w3w: get(data, "organization.address.w3w", ""),
        reference: get(data, "organization.address.reference", ""),
        country: get(data, "organization.address.country", ""),
        state: get(data, "organization.address.state", ""),
        city: get(data, "organization.address.city", ""),
        zipCode: get(data, "organization.address.zipCode", ""),
      },
    },
  };
}

export async function getPublicReq(
  paymentRequestId: string,
  tokenId: string
): Promise<PublicPaymentRequestResponse> {
  const res = await fetch(
    `/pub/payments/request/${paymentRequestId}/info?publicToken=${tokenId}`
  );

  const data = parsePublicPaymentReq(res.body);

  return data;
}

export interface ListParams {
  type: string;
  page?: number;
  records_per_page?: number;
  orderId?: string;
}

function mapSorts(data: ListParams) {
  return omitBy(
    {
      page: data.page || null,
      records_per_page: data.records_per_page || null,
      order_id: data.orderId || null,
      type: data.type || "payment",
    },
    isNil
  );
}

export async function listPayments(params: ListParams): Promise<Payment[]> {
  const token = await getAccessToken();
  const res = await fetch("/payments", token, "GET", null, mapSorts(params));
  return res.body ? res.body.hits.map(parsePayment) : [];
}
