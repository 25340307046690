import React, { useState } from "react";
import Text from "../Text";
import Papa from "papaparse";
import { Modal } from "../Modal/Modal";

import styles from "./styles";

import { useTranslation } from "react-i18next";
import { Spin, message } from "antd";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { InputError } from "../Inputs/InputError/InputError";

interface Props {
  onClose: () => void;
  open: boolean;
  loading: boolean;
  handleSubmit: (file: File) => Promise<void>;
}

const CVSFUploader: React.FC<Props> = ({
  onClose,
  open,
  loading,
  handleSubmit,
}) => {
  const [preview, setPreview] = useState(false);
  const inputFile = React.useRef<HTMLInputElement>(null);
  const [values, setValues] = useState<unknown[]>([]);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);

  const { t } = useTranslation();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      if (!selectedFile.name.toLowerCase().endsWith(".csv")) {
        message.error("Please select a valid CSV file.");
        return;
      }
      setFileName(selectedFile.name);
    }
  };

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.target.files;
    const file = files ? files[0] : null;
    if (file) {
      if (!file.name.toLowerCase().endsWith(".csv")) {
        message.error("Please select a valid CSV file.");
        return;
      }
      setFileName(file.name);

      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const rowsArray: string[][] = [];
          const valuesArray: unknown[][] = [];

          if (rowsArray) {
            // eslint-disable-next-line array-callback-return
            results.data.map((d: any) => {
              rowsArray.push(Object.keys(d));
              valuesArray.push(Object.values(d));
            });
          }
          setPreview(true);
          setValues(valuesArray);
        },
      });
    }
  };

  const handleInternalSubmit = async () => {
    try {
      if (file) await handleSubmit(file);
    } catch (error) {
      if (error instanceof Error) setError(error.message);
    } finally {
    }
  };

  const openInputFile = () => inputFile.current?.click();

  return (
    <div className={styles.container}>
      <Modal
        width={"80%"}
        buttons={
          <div>
            <ButtonsContainer justifyContent="flex-end">
              <Button
                disable={loading}
                onClick={() => {
                  if (loading) {
                    return;
                  }
                  onClose();
                  setValues([]);
                  setPreview(false);
                }}
                theme="white"
                label={t("component.cvsFileUploader.close")}
              />
              {loading ? (
                <Spin />
              ) : preview ? (
                <Button
                  onClick={() => {
                    if (loading) {
                      return;
                    }
                    handleInternalSubmit();
                    setValues([]);
                    setPreview(false);
                  }}
                  label={
                    loading ? "..." : t("component.cvsFileUploader.create")
                  }
                />
              ) : null}
            </ButtonsContainer>
            {error ? <InputError error={error || ""} /> : null}
          </div>
        }
        open={open}
        onClose={() => {
          if (loading) {
            return;
          }
          onClose();
        }}
      >
        <div className={styles.modalContainer}>
          {preview ? (
            <div>
              <Text
                padding="0em 0 1em 0"
                text={t("component.cvsFileUploader.import")}
                color="#000000"
                fontSize="1.4em"
                fontWeight={700}
              />

              <Text
                color="#000000"
                fontSize="1em"
                padding="0em 0 0.2em 0"
                fontWeight={700}
                text={`${fileName}`}
              />
              <Text
                padding="0em 0 1em 0"
                text={`${t("component.cvsFileUploader.rows")}: ${
                  values.length
                }`}
                color="#000000"
                fontSize="1em"
                fontWeight={700}
              />
            </div>
          ) : (
            <>
              <Text
                padding="0em 0 1em 0"
                text={t("component.cvsFileUploader.upload")}
                color="#000000"
                fontSize="1.4em"
                fontWeight={700}
              />
              <div
                id="dropArea"
                draggable
                onClick={() => openInputFile()}
                onDragOver={(e: React.DragEvent<HTMLDivElement>) => {
                  e.preventDefault();
                }}
                onDragEnter={(e: React.DragEvent<HTMLDivElement>) => {
                  e.preventDefault();
                }}
                onDrop={(e: React.DragEvent<HTMLDivElement>) => {
                  e.stopPropagation();
                  e.preventDefault();
                  openInputFile();
                }}
                className={styles.box}
              >
                <Text
                  padding="0em 0 0 0"
                  text={"Arrastra y suelta un archivo CSV aquí"}
                  color="#000000"
                  fontSize="1em"
                  fontWeight={700}
                />

                <input
                  type="file"
                  name="file"
                  accept=".csv"
                  onChange={(e) => {
                    changeHandler(e);
                    handleFileChange(e);
                  }}
                  style={{ display: "none" }}
                  ref={inputFile}
                />
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default CVSFUploader;
