import { stylesheet } from "typestyle";

const styles = stylesheet({
  container: {
  },
  wrapperCard: {
    padding: "2em",
    marginBottom: "1em",
  },
  bold: {
    fontSize: "1em",
    fontWeight: 700,
    fontStyle: "normal",
    letterSpacing: 0,
    margin: "0",
  },
  timeLineContainer: {
    padding: "1em",
    margin: "auto",
    width: "60%",
    marginTop: "2em",
    display: "flex",
    justifyContent: "center",
    height: "90vh",
  },
  image: {
    width: "14px",
    height: "14px",
    backgroundColor: "none",
  },
  time: {
    marginBottom: "1em",
    color: "#5e619a",
    $nest: {
      "ant-timeline-item-head ant-timeline-item-head-custom ant-timeline-item-head-#5e619a":
        { marginBottom: "40px" },
    },
  },
  padding: {
    padding: "0em 1em",
  },
  cursor: {
    cursor: "pointer",
  },
});

export default styles;
