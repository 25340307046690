import React, { useCallback, useEffect, useState } from "react";

import DeleteModal from "../DeleteModal";
import { useTranslation } from "react-i18next";
import { PassDefinition } from "../../utils/types";
import TicketingDefDetails from "../PassDefDetails";
import TicketingDefForm from "../PassDefForm";
import styles from "./styles.module.css";
import {
  defaultPassDefinition,
  readPassDefinition,
} from "../../services/passDef";
import APIError from "../../utils/APIError";
import { useLocation } from "react-router-dom";
import { SideMenu } from "../SideMenu/SideMenu";
import { EditableTitle } from "../EditableTitle";
import { ContainerTitle } from "../ContainerTitle";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { FlexContainer } from "../FlexContainer/FlexContainer";

interface Props {
  onClose: () => void;
  openModal: boolean;
  onEditTicketingDef: (data: PassDefinition) => void;
  id: string;
  ticketingDef: PassDefinition;
  onDeleteTicketing: (id: string) => void;
  loading: string;
}

const PassDefSide: React.FC<Props> = ({
  id,
  openModal,
  onClose,
  onDeleteTicketing,
  onEditTicketingDef,
  loading,
  ticketingDef,
}) => {
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);

  const location = useLocation();

  const [venueTicketingDef, setTicketingDef] = useState<PassDefinition>(
    defaultPassDefinition
  );

  const getFulfillment = useCallback(async () => {
    try {
      const res = await readPassDefinition(id);
      setTicketingDef(res);
    } catch (err) {
      if (err instanceof APIError) {
      }
    }
  }, [id]);

  useEffect(() => {
    if (openModal) {
      getFulfillment();
    }
  }, [getFulfillment, openModal]);

  const onChange = <P extends keyof PassDefinition>(
    prop: P,
    value: PassDefinition[P]
  ) => {
    setTicketingDef({ ...venueTicketingDef, [prop]: value });
  };

  const { t } = useTranslation();

  return (
    <SideMenu onClose={onClose} open={location.pathname === `/passes/${id}`}>
      <div>
        <DeleteModal
          open={open}
          loading={loading === "delete-ticketing"}
          value={t("container.ticketingDefDetail.deleteInstructions")}
          onCancel={() => setOpen(false)}
          onDelete={() => onDeleteTicketing(venueTicketingDef.id)}
        />
        <FlexContainer
          padding="1em 0 1em 0"
          justifyContent="space-between"
          alignItems="center"
        >
          {edit ? (
            <EditableTitle
              titleClassname={styles.title}
              value={venueTicketingDef.name}
              onChange={(value) => onChange("name", value)}
            />
          ) : (
            <ContainerTitle size="medium" label={venueTicketingDef.name} />
          )}
          {edit ? (
            <ButtonsContainer>
              <Button
                theme="white"
                label={t("container.ticketingDefDetail.cancel")}
                onClick={() => {
                  setTicketingDef(ticketingDef);
                  setEdit(false);
                }}
              />
              <Button
                label={t("container.ticketingDefDetail.save")}
                onClick={() => onEditTicketingDef(venueTicketingDef)}
              />
            </ButtonsContainer>
          ) : (
            <ButtonsContainer>
              <Button
                theme="red"
                label={t("container.ticketingDefDetail.delete")}
                onClick={() => setOpen(true)}
              />
              <Button
                label={t("container.ticketingDefDetail.edit")}
                onClick={() => setEdit(true)}
              />
            </ButtonsContainer>
          )}
        </FlexContainer>

        {!edit ? (
          <>
            <TicketingDefDetails
              description={venueTicketingDef.description}
              jsonData={venueTicketingDef.name}
            />
          </>
        ) : (
          <TicketingDefForm data={venueTicketingDef} onChange={onChange} />
        )}
      </div>
    </SideMenu>
  );
};

export default PassDefSide;
