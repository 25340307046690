import React from "react";

import { useTranslation } from "react-i18next";
import noTicketsIcon from "../../assets/errorAPI.svg";
import restrictedIcon from "../../assets/restricted3.png";
import styles from "./styles";
import Text from "../Text";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { Button } from "../Button";

interface Props {
  error: string;
  goBackButton?: boolean;
  onClick?: () => void;
}

const ErrorBox: React.FC<Props> = ({ error, goBackButton, onClick }) => {
  const { t } = useTranslation();

  return (
    <div>
      {error.includes("Forbidden") ? (
        <FlexContainer padding="10% 0" flexDirection="column">
          <img src={restrictedIcon} alt="" className={styles.icon} />
          <ContainerTitle
            padding="1em 0 1em 0"
            size="small"
            label={t("component.errorBox.hidden")}
          />
          <Text
            padding="0 0 1em 0"
            color="#000000"
            text={t("component.errorBox.warningHidden")}
          />
        </FlexContainer>
      ) : (
        <FlexContainer padding="10% 0" flexDirection="column">
          <img src={noTicketsIcon} alt="" className={styles.icon} />
          <ContainerTitle
            padding="1em 0 1em 0"
            size="small"
            label={t("component.errorBox.error")}
          />
          <Text padding="0 0 1em 0" color="#000000" text={error} />
          {goBackButton ? (
            <Button label="Go Back" onClick={() => onClick?.()} />
          ) : null}
        </FlexContainer>
      )}
    </div>
  );
};

export default ErrorBox;
