import { EventComment, OrderComment } from "@innomius/ravent-typescript-types";

import React, { useState } from "react";
import { formatDate } from "../../utils/times";
import { ReactComponent as Delete } from "../../assets/delete.svg";
import { ReactComponent as Warning } from "../../assets/warning.svg";
import styles from "./styles";
import Popover from "antd/lib/popover";
import { message } from "antd";
import APIError from "../../utils/APIError";
import { useTranslation } from "react-i18next";
import { GridContainer } from "../GridContainer/GridContainer";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { Button } from "../Button";

interface Props {
  data: OrderComment | EventComment;
  color: string;
  removeMessage: (id: string) => void;
}

const UpdateItem: React.FC<Props> = ({ data, color, removeMessage }) => {
  const [pop, setPop] = useState("");

  const { t } = useTranslation();

  const hashTagFormatter = (str: string) => {
    return str
      .split(/((?:^|\s)(?:#[a-z\d-]+))/gi)
      .filter(Boolean)
      .map((v, i) => {
        if (v.includes("#")) {
          return (
            <p key={i} style={{ color: "#009AFF", margin: "0 0.3em" }}>
              {v}
            </p>
          );
        } else {
          return <p key={i}>{v}</p>;
        }
      });
  };

  return (
    <div className={styles.margin}>
      <div className={styles.textContainer}>
        {formatDate(data.created, "MIDDLE")}
      </div>
      <p className={styles.name}>{`${data.userFullName || '-'}`} </p>
      <div className={styles.text}>{hashTagFormatter(data.message)}</div>

      <FlexContainer>
        <div>
          <Popover
            placement="left"
            content={
              <GridContainer
                justifyContent="flex-start"
                alignItems="center"
                gap="0.2em"
                columns="1fr 1fr"
              >
                <Warning className={styles.iconAlert} />
                {t("component.commentList.deleteComment")}
                <div />
                <FlexContainer>
                  <Button
                    buttonClassname={styles.buttonContainer}
                    label={t("component.commentList.cancel")}
                    onClick={() => setPop("")}
                    theme="white"
                    width="68px"
                    size="small"
                  />
                  <Button
                    onClick={async () => {
                      try {
                        await removeMessage(data.id);
                        setPop("");
                      } catch (err) {
                        if (err instanceof APIError) {
                          message.error(`${err.message}: ${err.messages}`);
                        }
                      }
                    }}
                    label="OK"
                    width="50px"
                    size="small"
                  />
                </FlexContainer>
              </GridContainer>
            }
            trigger="click"
            open={pop === "delete"}
          >
            <Delete
              onClick={() => setPop("delete")}
              className={styles.deleteIcon}
            />
          </Popover>
        </div>
      </FlexContainer>
      <div />
    </div>
  );
};

export default UpdateItem;
