const translation = {
  en: {
    error: "Error",
    back: "Go Back",
    notFound: "Not Found",
    successUpdated: "Team was successfully updated",
    successDeleted: "Team was successfully deleted",
    successDiverUpdated: "Fulfiller was successfully updated",
    successDeletedUser: "Fulfiller was successfully deleted",

    divers: "Fulfillers",
    users: "Users",
    detailsLabel: "Details",
    successDiverCreation: "Fulfiller successfully created",
    successUserAdded: "User was sucessfully added to Team",
    successUserRemoved: "User was sucessfully removed from Team",
    billing: "Billing",
    tags: "Tags",
  },
  es: {
    tags: "Etiquetas",
    billing: "Facturación",
    error: "Error",
    back: "Regresar",
    successDeletedUser: "Fulfiller fue borrado exitosamente",
    notFound: "No se encontró lo que buscaba",
    successUpdated: "El equipo de entrega se actualizó con éxito",
    successDeleted: "El equipo de entrega se eliminó con éxito",
    successDiverUpdated: "Fulfiller se actualizó con éxito",
    divers: "Fulfillers",
    users: "Usuarios",
    detailsLabel: "Detalles",
    successDiverCreation: "Fulfiller creado con éxito",
    successUserAdded: "Usuario se agrego éxitosamente al equipo",
    successUserRemoved: "Usuario se eliminio éxitosamente del equipo",
  },
  de: {},
};

export default translation;
