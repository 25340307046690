const translation = {
  en: {
    error: "Error",
    back: "Go Back",
    notFound: "Not Found",
    backToDetails: "Back to Fulfillment Definitions",
    cancel: "Cancel",
    save: "Save",
    delete: "Delete",
    edit: "Edit Fulfillment",
    detailsLabel: "Details",
    ticketing: "Ticketing",
    states: "States",
    successDeleted: "Fulfillment Definition was successfully deleted",
    deleteInstructions:
      "Are you sure you want to delete this fulfillment definition?",
    successUpdated: "Fulfillment was sucessfully updated",
    successLine: "State was sucessfully added",
    successEditLine: " State was sucessfully updated",
    successDeleteLine: " State was sucessfully deleted",
    publish: "Published",
    draft: "Draft",
    publishInstr: "Are you sure you want to publish this definition ?",
  },

  es: {
    publishInstr: "Esta seguro que quiere publicar la definición? ",
    publish: "Publicar",
    draft: "Borrador",
    error: "Error",
    back: "Regresar",
    notFound: "No encontrado",
    backToDetails: "Volver a Definiciones de Entrega",
    cancel: "Cancelar",
    save: "Guardar",
    delete: "Borrar",
    edit: "Editar",
    detailsLabel: "Detalles",
    ticketing: "Entradas",
    states: "Estados",
    successDeleted: "La definición de entrega se eliminó con éxito",
    deleteInstructions:
      "¿Está seguro de que desea eliminar esta definición de entrega?",
    successUpdated: "El entrega se actualizó con éxito",
    successEditLine: "El estado fue actualizado con éxito",
    successDeleteLine: "El estado fue borrada exitosamente",
  },
  de: {},
};

export default translation;
