import React, { useCallback, useEffect, useState } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import { User } from "@innomius/ravent-typescript-types";
import TabNavBar from "../../components/TabNavBar";
import { EmptyList } from "../../components/List/EmptyList";
import { ButtonsContainer } from "../../components/ButtonsContainer";
import { BackLink } from "../../components/BackLink";
import { Button } from "../../components/Button";
import { EditableTitle } from "../../components/EditableTitle";
import { ContainerTitle } from "../../components/ContainerTitle";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { GridContainer } from "../../components/GridContainer/GridContainer";

import UserDetailComponent from "../../components/UserDetailComponent";
import UserForm from "../../components/UserForm";
import { Loading } from "../../components/Loading";
import {
  CreateUserParams,
  deleteUser,
  getUser,
  onUpdateElmentUser,
  updateUser,
  updateUserAuthz,
} from "../../services/users";
import message from "antd/lib/message";
import DeleteModal from "../../components/DeleteModal";
import styles from "./styles.module.css";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { venueList } from "../../store/venues";
import APIError from "../../utils/APIError";
import NotEnrollBox from "../../components/UserDetailComponent/NotEnrollBox";
import { getVenue } from "../../services/venues";
import { useTranslation } from "react-i18next";
import { teamList } from "../../store/teams";
import { Role, roleList } from "../../services/roles";

interface RouteParams {
  id: string;
}

interface Props extends RouteComponentProps<RouteParams> {}

const UserDetail: React.FC<Props> = ({ match, history }) => {
  const [user, setUser] = useState<User | null>(null);
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const venues = useSelector((state: RootState) => state.venues);
  const teams = useSelector((state: RootState) => state.teams);
  // const { profile } = useSelector((state: RootState) => state.profile);
  const [roles, setRoles] = useState<Role[]>([]);

  const [loadError, setError] = useState("");
  const [edit, setEdit] = useState(false);
  const [venueName, setVenueName] = useState("");
  const { t } = useTranslation();

  const [loading, setLoading] = useState("");
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);

  const loadRoles = useCallback(async () => {
    try {
      const res = await roleList({ records_per_page: 100, page: 0 });
      setRoles(res.data);
    } catch (err) {
      if (err instanceof APIError) {
        setError(err.message);
      }
    }
  }, []);

  useEffect(() => {
    loadRoles();
  }, [loadRoles]);

  const load = useCallback(async () => {
    try {
      const res = await getUser(match.params.id);
      setUser(res);
      const resVenue = await getVenue(res.venueId);
      setVenueName(resVenue.name);
    } catch (err) {
      if (err instanceof APIError) {
        setError(err.message);
      }
    }
  }, [match.params.id]);

  useEffect(() => {
    load();
  }, [match.params.id, load]);

  const loadVenues = useCallback(async () => {
    dispatch(venueList({}));
  }, [dispatch]);

  useEffect(() => {
    loadVenues();
  }, [loadVenues]);

  const loadTeams = useCallback(async () => {
    dispatch(teamList({ page: 0, records_per_page: 200 }));
  }, [dispatch]);

  useEffect(() => {
    loadTeams();
  }, [loadTeams]);

  useEffect(() => {
    document.title = "RAVENT APP :: User :: Details";
  });

  if (!user && loadError) {
    return (
      <EmptyList
        instructions={""}
        buttonText={`${t("container.userDetail.goBack")}`}
        onClick={() => history.push("/users")}
        text={`${t("container.userDetail.error")}`}
        error={loadError}
      />
    );
  }

  if (!auth.loading && !auth.user) {
    return <Redirect from="*" to="/" />;
  }

  if (!user) {
    return <Loading />;
  }

  const onChange = <P extends keyof CreateUserParams>(
    prop: P,
    value: CreateUserParams[P]
  ) => setUser({ ...user, [prop]: value });

  const onUserUpdate = async () => {
    try {
      setLoading("update");
      const res = await updateUser(user);
      message.success(
        `${t("container.userDetail.userLabel")} ${res.firstName} ${
          res.lastName
        } ${t("container.userDetail.successCreation")}`
      );
      setEdit(false);
      setLoading("");
      load();
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`Error: ${err.messages}`);
      }
      setLoading("");
    }
  };

  const onDelete = async () => {
    try {
      setLoading("delete");
      await deleteUser(user.id);
      history.push("/users");
      setOpen(false);
      setLoading("");
    } catch (err) {
      if (err instanceof APIError) {
        message.error(err.messages);
      }
      setLoading("");
    }
  };

  const onAssign = async (venueId: string) => {
    try {
      setLoading("assign");
      await updateUserAuthz(user.id, venueId, user.roles);
      setOpen(false);
      message.success("Venue assignment successfull");
      setLoading("");
      load();
    } catch (err) {
      if (err instanceof APIError) {
        message.error(err.messages);
      }
      setLoading("");
    }
  };

  const onAssignRole = async (roles: string[]) => {
    try {
      setLoading("roles");
      await updateUserAuthz(user.id, user.venueId, roles);
      setOpen(false);
      message.success("Roles assignment successfull");
      setLoading("");
      setModal(false);
      load();
    } catch (err) {
      if (err instanceof APIError) {
        message.error(err.messages);
      }
      setLoading("");
    }
  };

  return (
    <LayouWithSideBar>
      <div className={styles.container}>
        <FlexContainer
          padding="1em 0 0em 0"
          justifyContent="space-between"
          alignItems="center"
        >
          <BackLink
            label={t("container.userDetail.backToUsers")}
            onClick={() => history.goBack()}
          />

          {edit ? (
            <ButtonsContainer>
              <Button
                theme="white"
                label={t("container.userDetail.cancel")}
                onClick={() => {
                  setEdit(false);
                }}
              />
              <Button
                label={loading ? "..." : `${t("container.userDetail.save")}`}
                onClick={() => {
                  if (!loading) {
                    onUserUpdate();
                  }
                }}
              />
            </ButtonsContainer>
          ) : (
            <ButtonsContainer>
              <Button
                theme="red"
                label={t("container.userDetail.delete")}
                onClick={() => setOpen(true)}
              />
              <Button
                label={t("container.userDetail.edit")}
                onClick={() => setEdit(true)}
              />
            </ButtonsContainer>
          )}
        </FlexContainer>
        <DeleteModal
          loading={loading === "delete"}
          open={open}
          onDelete={() => onDelete()}
          onCancel={() => setOpen(false)}
          value={`${t("container.userDetail.deleteMessage")} ${
            user.firstName
          } ${user.lastName} permanently?`}
        />
        {edit ? (
          <GridContainer padding="1em 0 0 0" gap="1em" columns="1fr 1fr 2fr">
            <EditableTitle
              placeholder="Name"
              value={user.firstName}
              onChange={(value) => onChange("firstName", value)}
            />
            <EditableTitle
              placeholder="Last Name"
              value={user.lastName}
              onChange={(value) => onChange("lastName", value)}
            />
          </GridContainer>
        ) : user.lastName ? (
          <ContainerTitle
            padding="1em 0 0 0"
            size="medium"
            label={`${user.firstName} ${user.lastName}`}
          />
        ) : (
          <NotEnrollBox />
        )}

        <TabNavBar
          options={[
            {
              id: "usersDetail",
              label: t("container.userDetail.userDetailsLabel"),
              path: `/users/${match.params.id}`,
              onClick: () => (document.title = "RAVENT APP :: User :: Details"),
            },
          ]}
        >
          <Switch>
            <Route path={`/users/${match.params.id}`} exact>
              {!edit ? (
                <UserDetailComponent
                  permission={
                    // (profile &&
                    //   profile.roles.some((role) =>
                    //     role.includes("role:organization:owner")
                    //   )) ??
                    false
                  }
                  loading={loading}
                  team={
                    teams.list.data.find(
                      (item) => item.id === user.fulfillerTeamId
                    )?.name || "-"
                  }
                  teams={teams.list.data}
                  load={load}
                  onChange={onChange}
                  onChangeRoles={onAssignRole}
                  roles={roles}
                  venues={venues.list.data}
                  onAssign={(id) => onAssign(id)}
                  onAssignTeam={async (teamId) => {
                    try {
                      await onUpdateElmentUser(match.params.id, {
                        fulfillmentTeamId: teamId,
                      });
                      message.success("Team assignment successfull");
                      load();
                    } catch (err) {
                      if (err instanceof APIError) message.error(err.message);
                    }
                  }}
                  data={user}
                  venue={venueName}
                  setModal={setModal}
                  openModal={modal}
                  teamId={user.fulfillerTeamId}
                />
              ) : (
                <UserForm
                  roles={roles}
                  permission={
                    // (profile &&
                    //   profile.roles.some((role) =>
                    //     role.includes("role:organization:owner")
                    //   )) ??
                    false
                  }
                  editing
                  data={user}
                  onChange={onChange}
                />
              )}
            </Route>
          </Switch>
        </TabNavBar>
      </div>
    </LayouWithSideBar>
  );
};

export default UserDetail;
