import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { EventPeriod } from "../../utils/types";
import "react-calendar-heatmap/dist/styles.css";
import styles from "./styles.module.css";
import ErrorBox from "../ErrorBox";
import Range from "./Range";
import Weekly from "./Weekly";
import { Radio, Spin } from "antd";
import { InputSelect } from "../Inputs/InputSelect";
import Monthly from "./Monthly";
import { capitalize } from "../../utils/format";
import { useHistory } from "react-router-dom";
import Text from "../Text";
import { linkBlue } from "../../utils/colors";
import Daily from "./Daily";
import loadIcon from "../../assets/loadButton.svg";
import placementIcon from "../../assets/events-placement.svg";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { Card } from "../Card/Card";
import { ContainerTitle } from "../ContainerTitle";

interface Props {
  period: EventPeriod;
  errorPeriod: string;
  loadingPeriod: boolean;
  counts: string;
  setCount: (value: string) => void;
  year: string;
  setTime: (value: string) => void;
  setYear: (value: string) => void;
  timezone: string;
  time: string;
}

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const weekdays = ["S", "M", "T", "W", "T", "F", "S"];

const EventOverviewC: React.FC<Props> = ({
  period,
  errorPeriod,
  loadingPeriod,
  setTime,
  year,
  counts,
  setCount,
  setYear,
  timezone,
  time
}) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<any>();
  const calendarRef = useRef<HTMLDivElement>(null);

  const history = useHistory();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const clickedElement = event.target as HTMLElement;
      if (
        calendarRef.current &&
        !calendarRef.current.contains(clickedElement) &&
        !clickedElement.closest(`.${styles.cardNumber}`)
      ) {
        setSelected(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const load = useCallback(async () => {
    const states = {
      year: year,
      view: time,
    };

    const params = new URLSearchParams(states);
    history.replace(`/overview/events?${params.toString()}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, time]);

  useEffect(() => {
    load();
  }, [load]);

  // const getColors = (valueCount: number, ranges: any[]) => {
  //   for (let i = 0; i < ranges.length; i++) {
  //     if (valueCount >= ranges[i].from && valueCount < ranges[i].to) {
  //       return styles[`colorScale${i + 1}`];
  //     }
  //   }
  //   return "#eee";
  // };

  // const startDate = dayjs(`${year}-01-01`).format("YYYY-MM-DD");
  // const endDate = dayjs(`${year}-12-31`).format("YYYY-MM-DD");

  return (
    <>
      <Card>
        <FlexContainer
          padding="0 0 1em 0"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <ContainerTitle
            size="big"
            label={
              counts === "events"
                ? t("container.dashboard.events")
                : t("container.dashboard.loads")
            }
          />
          <FlexContainer alignItems="flex-start" justifyContent="flex-end">
            <div style={{ marginRight: "1em" }}>
              <Radio.Group
                style={{ margin: "0em 1em", display: "flex" }}
                value={counts}
                onChange={(value) => setCount(value.target.value)}
              >
                <Radio.Button value="events">
                  <div className={styles.imageContainer}>
                    <img alt="" className={styles.icon} src={placementIcon} />
                  </div>
                </Radio.Button>
                <Radio.Button value="loads">
                  <div className={styles.imageContainer}>
                    <img alt="" className={styles.icon} src={loadIcon} />
                  </div>
                </Radio.Button>
              </Radio.Group>
            </div>
            <InputSelect
              value={year}
              onChange={(value) => setYear(value)}
              showLabel={false}
              showError={false}
              containerClassname={styles.inputSelect}
              options={[
                { label: "2022", value: "2022" },
                { label: "2023", value: "2023" },
                { label: "2024", value: "2024" },
                { label: "2025", value: "2025" },
                { label: "2026", value: "2026" },
              ]}
            />
            <Radio.Group
              style={{ marginLeft: "1em" }}
              value={time}
              onChange={(value) => setTime(value.target.value)}
            >
              <Radio.Button
                value="day"
                onClick={() => {
                  setTime("day");
                  // history.push(`/overview/events?view=weekly&year=${year}`);
                }}
              >
                {t("container.dashboard.daily")}
              </Radio.Button>
              <Radio.Button
                value="week"
                onClick={() => {
                  setTime("week");
                  // history.push(`/overview/events?view=weekly&year=${year}`);
                }}
              >
                {t("container.dashboard.weekly")}
              </Radio.Button>
              <Radio.Button
                value="month"
                onClick={() => {
                  setTime("month");
                  // history.push(`/overview/events?view=monthly&year=${year}`);
                }}
              >
                {t("container.dashboard.monthly")}
              </Radio.Button>
            </Radio.Group>
          </FlexContainer>
        </FlexContainer>
        <div className={styles.calendarContainer} ref={calendarRef}>
          {errorPeriod ? (
            <ErrorBox error={errorPeriod} />
          ) : (
            time === "day" && (
              <>
                <Daily
                  counts={counts}
                  dayLabels={weekdays}
                  monthLabels={months}
                  heatmap={period.heatmap}
                  year={year}
                  timezone={timezone}
                  range={period.ranges}
                />
              </>
            )
          )}
        </div>
        <div style={{ margin: "2em 0 1em 0" }}>
          {errorPeriod ? (
            <ErrorBox error={errorPeriod} />
          ) : period?.heatmap && time === "week" ? (
            loadingPeriod ? (
              <Spin />
            ) : (
              <Weekly
                year={year}
                range={period.ranges}
                heatmap={period.heatmap}
              />
            )
          ) : null}

          {errorPeriod ? (
            <ErrorBox error={errorPeriod} />
          ) : period?.heatmap && time === "month" ? (
            loadingPeriod ? (
              <Spin />
            ) : (
              <>
                <Monthly
                  range={period.ranges}
                  heatmap={period.heatmap}
                  year={year}
                />
              </>
            )
          ) : null}
        </div>

        {selected ? (
          <div
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/events?from=${selected.date}&to=${selected.date}`);
            }}
            style={{ position: "absolute", right: "5%", cursor: "pointer" }}
          >
            <Card containerClassname={styles.cardNumber}>
              <FlexContainer
                alignItems="flex-start"
                flexDirection="column"
                justifyContent="flex-start"
              >
                <div>{`${t("container.dashboard.date")}: ${
                  selected.date
                }`}</div>
                {`${t("container.dashboard.count")}: ${selected.count}`}
              </FlexContainer>
              <Text
                onClick={() =>
                  history.push(
                    `/events?from=${selected.date}&to=${selected.date}`
                  )
                }
                padding="0.5em 0 0 0"
                fontSize="0.9em"
                color={linkBlue}
                text={`${t("container.dashboard.seeMore")}`}
              />
            </Card>
          </div>
        ) : null}

        <div
          style={{
            marginTop: "2em",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          {period.ranges.length && time === "day" ? (
            <div style={{ width: "50%" }}>
              <Range content={capitalize(time)} range={period.ranges} />
            </div>
          ) : null}
          {(period && period.ranges.length && time === "week") ||
          time === "month" ? (
            <div style={{ width: "50%" }}>
              {period ? (
                <Range content={capitalize(time)} range={period.ranges} />
              ) : null}
            </div>
          ) : null}
        </div>
      </Card>
    </>
  );
};

export default EventOverviewC;
