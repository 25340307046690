import React, { FC } from "react";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryStack,
} from "victory";
import { getOrderStatusColor } from "../../utils/order";
import { OrderStateSummaryCount } from "../../utils/types";
import styles from "./styles.module.css";

interface Props {
  orderStates: OrderStateSummaryCount[];
  total: number;
  count: number;
}

const StackedBars: FC<Props> = ({ orderStates = [], total }) => {
  const dataA = [{ x: "", y: 0 }];

  const width = 500;
  const height = 30;

  const seeSumAfterIndex = (index: number) => {
    const sliced = orderStates.slice(index + 1).map((item) => item.count);
    if (sliced.length) {
      return sliced.reduce((total, current) => {
        return total + current;
      }, 0);
    }

    return sliced;
  };

  const seeSummBeforeIndex = (index: number) => {
    const reversed = [...orderStates].reverse();
    const reverseSlice = reversed.slice(index + 1).map((item) => item.count);
    if (reverseSlice.length) {
      return reverseSlice.reduce((total, current) => {
        return total + current;
      }, 0);
    }

    return reverseSlice;
  };

  return (
    <div className={styles.graphContainer}>
      <VictoryChart horizontal height={height} width={width}>
        <VictoryStack
          style={{
            data: { width: 20, borderRadius: 2 },
            labels: { fontSize: 15 },
          }}
        >
          {total === 0 ? (
            <VictoryBar
              cornerRadius={{ top: 5, bottom: 5 }}
              style={{
                data: { fill: "#ededed" },
              }}
              y={() => 100}
            />
          ) : (
            orderStates.map((item, index) => (
              <VictoryBar
                key={index}
                cornerRadius={
                  item.count === total
                    ? { top: 5, bottom: 5 }
                    : index === 0 || seeSummBeforeIndex(index) === 0
                    ? { top: 0, bottom: 5 }
                    : orderStates.length - 1 === index ||
                      seeSumAfterIndex(index) === 0
                    ? { top: 5, bottom: 0 }
                    : { top: 0, bottom: 0 }
                }
                style={{
                  data: {
                    fill: getOrderStatusColor(item.id),
                  },
                }}
                y={() => item.total}
              />
            ))
          )}
        </VictoryStack>
        <VictoryAxis
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            tickLabels: { fontSize: 15, fill: "black" },
          }}
          tickLabelComponent={<VictoryLabel x={100 / 2} textAnchor="middle" />}
          tickValues={dataA.map((point) => point.x).reverse()}
        />
      </VictoryChart>
    </div>
  );
};

export default StackedBars;
