import React from "react";
import { useTranslation } from "react-i18next";
import { VictoryPie, VictoryTooltip } from "victory";
import { TeamOverview } from "../../utils/types";
import { Spin } from "antd";
import { FlexContainer } from "../FlexContainer/FlexContainer";

interface Props {
  teams: TeamOverview[];
  error: string;
  loading: boolean;
}

const PieCharOverview: React.FC<Props> = ({ teams, error, loading }) => {
  const { t } = useTranslation();

  if (loading) {
    return (
      <div style={{ height: "200px" }}>
        <FlexContainer justifyContent="center" alignItems="center">
          <Spin />
        </FlexContainer>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        {t("Error")}: {error}
      </div>
    );
  }

  // Calculate the total sum of all 'total' fields
  const grandTotal = teams.reduce((sum, team) => sum + team.total, 0);

  // Prepare data for the pie chart
  const data = teams.map((team) => ({
    x: team.fulfillmentTeamName,
    y: (team.total / grandTotal) * 100,
  }));

  return (
    <div style={{position: 'relative', zIndex: 9999}}>
      <VictoryPie
        data={data}
        colorScale="qualitative"
        labelComponent={<VictoryTooltip />}
        labels={({ datum }) => `${datum.x}: ${datum.y.toFixed(2)}%`}
        style={{
          labels: { fontSize: 14, fill: "black", fontFamily: "Lato" },
        }}
      />
    </div>
  );
};

export default PieCharOverview;
