const translation = {
  en: {
    remove: "Remove",
    delete: "Delete",
  },
  es: {
    remove: "Retirar",
    delete: "Eliminar",
  },
  de: {},
};

export default translation;
