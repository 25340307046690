import React from "react";
import OrderTitle from "./OrderTitle";
import { Card } from "../Card/Card";

import styles from "./styles";
import message from "antd/lib/message";
import copyIcon from "../../assets/copy.svg";
import { useTranslation } from "react-i18next";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { FlexContainer } from "../FlexContainer/FlexContainer";

interface Props {
  name: string;
  clientId: string;
  orderUrl: string;
  customerUrl: string;
  referenceId: string;
  onSendToErp: () => void;
}

const ERPCard: React.FC<Props> = ({
  name,
  clientId,
  orderUrl,
  customerUrl,
  referenceId,
  onSendToErp,
}) => {
  const { t } = useTranslation();

  return (
    <Card>
      <div className={styles.walletWrapper}>
        <OrderTitle
          label={t("component.orderDetail.erp")}
          buttonComponent={
            <ButtonsContainer
              alignItems="flex-start"
              justifyContent="flex-start"
              padding="0em 0 0em 0"
            >
              <Button
                width="8em"
                label={t("component.orderDetail.sentToERP")}
                theme="white"
                onClick={onSendToErp}
              />
            </ButtonsContainer>
          }
          component={
            <div>
              <FlexContainer
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <div
                  onClick={() => window.open(orderUrl)}
                  className={styles.valueSmall}
                >
                  {`${t("component.orderDetail.orderID")}: ${
                    referenceId || "-"
                  } `}
                </div>
              
                <div
                  onClick={() => {
                    if (referenceId) {
                      navigator.clipboard.writeText(referenceId);
                      message.success(t("component.orderDetail.erpCopied"));
                    }
                  }}
                >
                  <img alt="" src={copyIcon} className={styles.copy} />
                </div>
              </FlexContainer>
              <FlexContainer
                alignItems="flex-start"
                justifyContent="space-between"
                padding="0 0 1em 0"
              >
                <div
                  onClick={() => window.open(customerUrl)}
                  className={styles.valueSmall}
                >
                  {`${t("component.orderDetail.customerId")}: ${
                    clientId || "-"
                  }`}
                </div>
                <div
                  onClick={() => {
                    if (clientId) {
                      navigator.clipboard.writeText(clientId);
                      message.success(
                        t("component.orderDetail.customerCopied")
                      );
                    }
                  }}
                >
                  <img alt="" src={copyIcon} className={styles.copy} />
                </div>
              </FlexContainer>
            </div>
          }
          value={`${t("component.orderDetail.name")}: ${name}`}
        />
      </div>
    </Card>
  );
};

export default ERPCard;
