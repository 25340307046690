import { stylesheet } from "typestyle";
import { darkBlack } from "../../utils/colors";

const styles = stylesheet({
  container: {},
  cardContainer: {},
  title: {
    color: darkBlack,
    fontWeight: "bold",
    fontSize: "1.1em",
  },
  widht: {
    width: "100%",
    position: "relative",
    zIndex: 10000,
  },
  noBorder: {
    border: "none",
  },
  positionButton: {
    position: "relative",
    top: "1em",
  },
  background: {
    background: "#F3F9FE",
    borderRadius: "4px",
    padding: "1em",
    height: "79vh",
  },
  calendar: {
    width: "1.1em",
  },
  tagsWrapper: {
    padding: "2em",
  },

  itemsWrapper: {
    padding: "0em 1em 0em 1em",
  },
  containerSelect: {
    width: "15em !important",
  },
  userContainer: {
    marginTop: "0em",
  },
  notifications: {
    fontWeight: 600,
  },
  textContainer: {
    width: "100%",
    border: "0 !important",
    resize: "none",
    minHeight: "60px !important",
    overflowY: "auto",
  },
  containerList: {
    margin: "0.3em",
  },
  attachIcon: {
    width: "28px",
    cursor: "pointer",
    marginTop: "1em",
  },
  fileContainer: {
    width: "24%",
  },
  spinner: {
    fontSize: "1em",
    color: "#999",
  },
  input: {
    color: "#333",
    fontWeight: "18px",
  },
  checkboxContainer: {
    marginRight: "1em",
  },
  inputContainer: {
    position: "relative",
    zIndex: 1000,
  },
  footer: {
    width: "38%",
    position: "fixed",
    bottom: "2em",
    background: "white",
    borderRadius: "6px",
    padding: "1em",
    zIndex: 999,
  },
  commentList: {
    height: "50%",
    overflowY: "auto",
  },
});

export default styles;
