const translation = {
  en: {
    paymentId: "Payment ID",
    paymentType: "Payment Type",
    paymentDetails: "Payment Details",
    paid: "Paid",
    requestId: "Request ID",
    state: "State",

    amount: "Amount",
    actions: "",
    receipt: "Receipt",
    payment: "Payment",
    shared: "successfully shared.",
    share: "Share Receipt",
    invoice: "Issue Invoice",
    uploadInvoice: "Upload Invoice",
    addIssueInvoice: "Add/Issue Invoice"
  },
  es: {
    paymentId: "ID de pago",
    paymentType: "Tipo de pago",
    paymentDetails: "Detalles de Pago",
    paid: "Pagado",
    requestId: "ID de solicitud",
    state: "Estado",

    amount: "Cantidad",
    actions: "",
    receipt: "Recibo",
    payment: "Pago",
    shared: "compartido con éxito.",
    share: "Compartir Recibo",
    invoice: "Emitir Factura",
    uploadInvoice: "Subir Factura",
    addIssueInvoice: "Agregar/Emitir Factura"

  },
  de: {},
};

export default translation;
