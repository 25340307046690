import React from "react";

import { OrderListSkeleton } from "../ListSkeletons";
import styles from "./styles";
import Item from "./Item";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { List } from "../List";

interface BillingIntListProps {
  error: string;
  loading: boolean;
}

const BillingIntList: React.FC<BillingIntListProps> = ({ error, loading }) => {
  const data = [
    {
      name: "HERE",
      usingStartDate: new Date("01.01.2024"),
      requests: 1,
      costPerRequest: 0.035,
    },
    {
      name: "OAG",
      usingStartDate: new Date("05.01.2024"),
      requests: 1,
      costPerRequest: 0.9,
    },
    {
      name: "TWILIO",
      usingStartDate: new Date("10.01.2024"),
      requests: 1,
      costPerRequest: 0.023,
    },
  ];

  const header = (
    <div>
      <ListHeader template="1fr 1fr 1fr 1fr 1fr">
        <ListHeaderLabel value={"Name"} justifyContent="flex-start" />
        <ListHeaderLabel
          value={"Usage Start Date"}
          justifyContent="flex-start"
        />
        <ListHeaderLabel value={"Requests"} justifyContent="flex-start" />
        <ListHeaderLabel value={"Cost per Request"} justifyContent="flex-end" />
        <ListHeaderLabel value={"Total Cost"} justifyContent="flex-end" />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <div className={styles.listContainer}>
        <List
          loading={loading}
          loadingElement={() => <OrderListSkeleton />}
          error={error}
          data={data}
          empty={!data.length}
          keyExtractor={(item) => item.name}
          renderHeader={() => header}
          renderItem={(item) => <Item data={item} />}
        />
      </div>
    </div>
  );
};

export default BillingIntList;
