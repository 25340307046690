import { stylesheet } from "typestyle";
import { azure, black, clearBlue, lightGrey } from "../../utils/colors";

const loginStyles = stylesheet({
  container: {
    width: "100%",
    minHeight: "100vh",
  },
  imageContainer: {
    width: "100%",
  },
  emailContainer: {
    marginRight: "2em",
  },
  image: {
    display: "block",
    backgroundSize: "cover",
    height: "100vh",
    width: "50%",
    objectFit: "cover",
    top: 0,
    willChange: "opacity",
    position: "absolute",
    $nest: {
      "@media screen and (max-width: 600px)": {
        display: "none",
      },
    },
  },
  paddingTop: {
    paddingTop: "3em",
  },
  link: {
    cursor: "pointer",
    fontSize: "1em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    color: clearBlue,
    padding: "0em 0 0 0",
    $nest: {
      "& > a": {
        color: "currentColor",
      },
    },
  },
  footer: {
    fontSize: "0.8em",
    display: "flex",
    textAlign: 'justify',
    justifyContent: "center",
    alignItems: "center",
    gap: "1.4em",
    color: clearBlue,
    padding: "3em 0 0 0",
    $nest: {
      "& > a": {
        color: "currentColor",
      },
    },
  },
  title: {
    fontSize: "2.5em",
    fontWeight: "bold",
    paddingBottom: "1.5em",
    marginRight: "2em",
  },
  subtitle: {
    fontSize: "2.2em",
    fontWeight: "bold",
    padding: "1em 0 1em 0",
    textAlign: "center",
    color: "#000000",
    marginRight: "1em",
  },
  text: {
    textAlign: "left",
    padding: "0 0 1.5em 0",
    color: "#000000",
    fontWeight: "bold",
  },
  inputsContainer: {
    padding: "2em 0em 2em 2em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "#FFF",
    $nest: {
      "& > div": {
        maxWidth: "27em",
        width: "100%",
        margin: "0 auto",
      },
    },
  },
  grid: {
    display: "grid",
    gap: "3em",
    gridTemplateColumns: "1fr 1fr",
    justifyContent: "center",
    alignItems: "center",
    $nest: {
      "@media screen and (max-width: 600px)": {
        gridTemplateColumns: "1fr",
      },
    },
  },

  desktop: {
    display: "block",

    $nest: {
      "@media screen and (max-width: 750px)": {
        display: "none",
      },
    },
  },
  mobile: {
    display: "block",

    $nest: {
      "@media screen and (min-width: 750px)": {
        display: "none",
      },
    },
  },
  error: {
    minHeight: "2em",
    color: "red",
    textAlign: "center",
    marginTop: "1em",
  },
  button: {
    background: lightGrey,
    color: "#fff",
    padding: "10px 21px 10px 22px",
    cursor: "pointer",
    marginTop: "2em",
    width: "100%",
    $nest: {
      "&:hover": {
        backgroundColor: azure,
      },
    },
  },
  logo: {
    height: "29px",
    margin: "0 auto",
    display: "block",
  },
  footerText: {
    color: black,
  },
  footerTextUnderline: {
    color: black,
    textDecoration: "underline",
    margin: '0 0.5em',
    cursor: 'pointer',
  },
});

export default loginStyles;
