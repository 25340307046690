import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  VenueListParams,
  VenueStatsResponse,
  listVenuesStats,
} from "../services/venues";

type VenueStatesSliceState = {
  loading: boolean;
  error: string;
  list: VenueStatsResponse;
};

const initialState: VenueStatesSliceState = {
  loading: true,
  error: "",
  list: {
    page: 0,
    count: 0,
    hits: [],
  },
};

export const venueList = createAsyncThunk(
  "venuesStates/list",
  async (params: VenueListParams) => {
    const response = await listVenuesStats(params);
    return response;
  }
);

const venuesStatesSlice = createSlice({
  name: "venuesStates",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(venueList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(venueList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.list = action.payload;
    });
    builder.addCase(venueList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An unexpected error ocurred";
    });
  },
});

export default venuesStatesSlice.reducer;