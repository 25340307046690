import * as React from "react";
import { style } from "typestyle";
import { hoverList, black } from "../../utils/colors";

export interface ListRowProps {
  textAlign?: "center" | "left" | "right";
  template?: string;
  onClick?: () => void;
  hover?: boolean;
  showDetail?: boolean;
  detail?: React.ReactChild;
  background?: string;
  paddingDetail?: string;
  border?: boolean;
  greyedOut?: boolean;
  children?: React.ReactNode[] | React.ReactNode;
  borderTop?: string;
  borderBottom?: string;
  borderLeft?: string;
  borderRight?: string;
  borderDetail?: string;

  margin?: string;
  noBorder?: boolean;
  borderRadius?: string;
  boxShadow?: boolean;
}

const row = style({
  display: "grid",
  backgroundColor: "#fff",
  borderRadius: "8px",
  margin: "0.5em 0",
  $nest: {
    "&:hover": {
      background: hoverList + "! important",
      color: black,
      cursor: "pointer",
      borderRadius: "8px",
    },
  },
});

const rowGreyedOut = style({
  display: "grid",
  backgroundColor: "rgba(220, 225, 230, 0.3)",
  borderRadius: "8px",
  margin: "0.5em 0",
});

const rowBorder = style({
  display: "grid",
  backgroundColor: "#fff",
  borderRadius: "8px",
  border: "1px solid #E6E6E6",
  margin: "0.5em 0",
  $nest: {
    "&:hover": {
      background: hoverList + "! important",
      color: black,
      cursor: "pointer",
      borderRadius: "8px",
      border: "none",
    },
  },
});

const noBorder = style({
  display: "grid",
  backgroundColor: "#fff",
  margin: "0.5em 0",
  $nest: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});

const detailContainer = style({
  padding: "2em 3em",
  background: hoverList,
  width: "100%",
});

export const ListRow: React.FC<ListRowProps> = ({
  children,
  template,
  onClick,
  showDetail,
  detail,
  background,
  paddingDetail,
  border,
  greyedOut,
  borderBottom,
  borderTop,
  margin,
  borderLeft,
  borderRight,
  borderRadius,
  borderDetail,
  boxShadow,
}) => {
  const childs = React.Children.toArray(children);
  const columns = template || `repeat(${childs.length}, 1fr)`;
  return (
    <div>
      <div
        className={
          noBorder
            ? noBorder
            : greyedOut
            ? rowGreyedOut
            : border
            ? rowBorder
            : row
        }
        style={{
          gridTemplateColumns: columns,
          background: background,
          margin,
          borderLeft,
          borderRight,
          borderRadius,
          borderTop: borderTop ? borderTop : "",
          borderBottom: borderBottom ? borderBottom : "",
          boxShadow: boxShadow
            ? "0px 4px 10px 0px rgba(20, 19, 34, 0.10)"
            : "none",
        }}
        onClick={onClick}
      >
        {children}
      </div>
      {showDetail && !greyedOut ? (
        <div
          className={detailContainer}
          style={{ padding: paddingDetail, border: borderDetail }}
        >
          {detail}
        </div>
      ) : null}
    </div>
  );
};
