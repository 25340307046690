const translation = {
  en: {
    fulfillment: "Fulfillment",
    states: "States",
    fulfiller: "Fulfiller",
    team: "Team",
    select: "Set Team",
    selectF: "Select Fulfiller",
    instructionF: "Select a Fulfillment Team in order to assign a Fulfiller",
    noFulfiler: "You don't have any fulfiller assigned to this event",

    setState: "Set State",
    noTeams: "You don't have any team assigned to this event",
    noDefinition: "The list is empty until you configure a definition",
    definitions: "State Definitions",
    instructions: "Please select a definition to be able to set states.",
    noDefinitions:
      "No definitions are configured for this venue. Please create state definitions.",
  },
  es: {
    instructionsF: "Seleccione un equipo de entrega para asignar un encargado",
    fulfillment: "Entrega",
    team: "Equipo",
    noFulfiler: "No tiene ningún fulfiller asignado al avento",
    selectF: "Seleccionar Persona de Entrega",
    states: "Estados",
    select: "Seleccione un equipo",
    fulfiller: "Fulfiller",
    noTeams: "No tienes ningún equipo asignado al evento",
    setState: "Establecer estado",
    noDefinition: "La lista está vacía hasta que configure una definición",
    definitions: "Definiciones de Estado",
    instructions: "Seleccione una definición para poder establecer estados.",
    noDefinitions:
      "No hay definiciones configuradas para este lugar. Cree definiciones de entradas.",
  },
  de: {},
};

export default translation;
