import { getAccessToken } from "./auth";
import fetch from "../utils/fetch";
import get from "lodash/get";
import { PassDefinition } from "../utils/types";

export interface TicketingDefParams {
  page?: number;
  records_per_page?: number;
  venue_id?: string;
}

export interface TicketingDefSearchResponse {
  data: PassDefinition[];
  page: number;
  total: number;
}

export function parsePassDefinition(data?: unknown): PassDefinition {
  return {
    id: get(data, "_id", ""),
    name: get(data, "name", ""),
    created: get(data, "created", ""),
    description: get(data, "description", ""),
  };
}

export async function passDefListServices(
  params: TicketingDefParams
): Promise<TicketingDefSearchResponse> {
  const token = await getAccessToken();
  const res = await fetch("/fdefs", token, "GET", null, params);
  return {
    data: res.body ? res.body.hits.map(parsePassDefinition) : [],
    page: get(res.body, "page", 0),
    total: get(res.body, "count", 0),
  };
}

export async function createPassDefinition(
  data: PassDefinition
): Promise<PassDefinition> {
  const body = {
    name: data.name,
    description: data.description,
  };

  const token = await getAccessToken();

  const res = await fetch("/fdefs", token, "POST", body);
  return res.body;
}

export async function updatePassDefinition(
  data: PassDefinition
): Promise<PassDefinition> {
  const body = {
    name: data.name,
    description: data.description,
  };

  const token = await getAccessToken();

  const res = await fetch(`/fdefs/${data.id}`, token, "PATCH", body);
  return res.body;
}

export async function deleteTicketingDef(id: string): Promise<void> {
  const token = await getAccessToken();

  await fetch(`/fdefs/${id}`, token, "DELETE");
}

export async function readPassDefinition(id: string): Promise<PassDefinition> {
  const token = await getAccessToken();
  const res = await fetch(`/fdefs/${id}`, token);
  const data = parsePassDefinition(res.body);
  return data;
}

export const defaultPassDefinition: PassDefinition = {
  name: "",
  id: "",
  created: new Date(),

  description: "",
};
