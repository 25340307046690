const translation = {
  en: {
    pageLoading: "Page Loading",
    wait: "Loading content, please wait...",
  },
  es: {
    pageLoading: "Página cargando",
    wait: "Cargando contenido, por favor espere...",
  },
  de: {},
};

export default translation;
