import React, { useState } from "react";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";
import { Asset } from "../../utils/types";
import { useTranslation } from "react-i18next";

import TypeButton from "./TypeButton";
import { Radio, Steps } from "antd";
import AssetDetailsForm from "./AssetDetailsForm";
import VehicleDetailsForm from "./VehicleDetailsForm";

interface Props {
  data: Asset;
  onChange: <P extends keyof Asset>(prop: P, value: Asset[P]) => void;
  next: () => void;
  prev: () => void;
  current: number;
  setCurrent: (value: number) => void;
}

const AssetForm: React.FC<Props> = ({
  data,
  onChange,
  current,
  setCurrent,
}) => {
  const { t } = useTranslation();
  const [type, setType] = useState<string>(data.subtype || "vehicle");

  const vehicleType = [
    { label: t("component.assetForm.van"), value: "van" },
    { label: t("component.assetForm.bus"), value: "bus" },
    { label: t("component.assetForm.limo"), value: "limo" },
    { label: t("component.assetForm.suv"), value: "suv" },
    { label: t("component.assetForm.truck"), value: "truck" },
    { label: t("component.assetForm.moped"), value: "moped" },
    { label: t("component.assetForm.motorcycle"), value: "motorcycle" },
    { label: t("component.assetForm.scooter"), value: "scooter" },
    { label: t("component.assetForm.bike"), value: "bike" },
    { label: t("component.assetForm.ferry"), value: "ferry" },
    { label: t("component.assetForm.ship"), value: "ship" },
    { label: t("component.assetForm.other"), value: "other" },
  ];

  const genericType = [
    { label: t("component.assetForm.IOT"), value: "IOT" },
    { label: t("component.assetForm.locker"), value: "locker" },
    { label: t("component.assetForm.tools"), value: "tools" },
    { label: t("component.assetForm.other"), value: "other" },
  ];

  const types = data.type === "vehicle" ? vehicleType : genericType;

  return (
    <div>
      <div
        style={data.type === "vehicle" ? { width: "100%" } : { width: "50%" }}
      >
        <div style={{ padding: "1em 0 2em 0" }}>
          <div>
            <Steps
              current={current}
              onChange={(current) => setCurrent(current)}
            >
              <Steps.Step title={t("component.assetForm.type")} />
              <Steps.Step title={t("component.assetForm.details")} />
              {data.type === "vehicle" && (
                <Steps.Step title={t("component.assetForm.vehicleDetails")} />
              )}
            </Steps>
          </div>
        </div>
      </div>

      {current === 0 && (
        <div>
          <ContainerTitle
            padding="0em 0 1em 0"
            size="xsmall"
            label={t("component.assetForm.type")}
          />

          <Radio.Group
            onChange={(value) => {
              onChange("type", value.target.value);
            }}
            defaultValue={data.type}
          >
            <Radio value={"vehicle"}>{t("component.assetForm.vehicle")}</Radio>
            <Radio value={"generic"}>{t("component.assetForm.generic")}</Radio>
          </Radio.Group>

          <>
            <ContainerTitle
              padding="1.5em 0 1em 0"
              size="xsmall"
              label={t("component.assetForm.subType")}
            />

            <GridContainer
              alignItems="center"
              justifyContent="center"
              gap="1em"
              columns="1fr 1fr"
            >
              {types.map((item) => (
                <TypeButton
                  label={item.label}
                  key={item.value}
                  selectedType={type}
                  type={item.value}
                  onClick={(value) => {
                    setType(value);
                    onChange("subtype", value);
                  }}
                />
              ))}
            </GridContainer>
          </>
        </div>
      )}
      {current === 1 && <AssetDetailsForm data={data} onChange={onChange} />}
      {current === 2 && (
        <div style={{ padding: "0em 0 3em 0" }}>
          {data.type === "vehicle" && (
            <VehicleDetailsForm data={data} onChange={onChange} />
          )}
        </div>
      )}
    </div>
  );
};

export default AssetForm;
