import React from "react";

import Text from "../Text";
import { CreateTeamParams } from "../../services/entities";
import CustomError from "../../utils/CustomError";
import { useTranslation } from "react-i18next";
import { Switch, Tooltip, TreeSelect } from "antd";
import styles from "./styles";
// import { InputCheckboxGroup } from "../Inputs/InputCheckboxGroup";
import {
  maskingInvoicesTeamTreeData,
  maskingOrdelinesTeamTreeData,
  maskingOrdersTeamTreeData,
} from "../../constants/teams";
import { InputSelect } from "../Inputs/InputSelect";
import { MaskingTemplates } from "../../utils/types";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Card } from "../Card/Card";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
import Editor from "@monaco-editor/react";

interface Props {
  editing: boolean;
  entity: CreateTeamParams;
  onChange: <P extends keyof CreateTeamParams>(
    prop: P,
    value: CreateTeamParams[P]
  ) => void;
  error?: CustomError;
  maskingTemplates: MaskingTemplates;
  onChangeMaskingTemplate: (data: MaskingTemplates) => void;
}

const EntityForm: React.FC<Props> = ({
  entity,
  onChange,
  onChangeMaskingTemplate,
  maskingTemplates,
}) => {
  const { t } = useTranslation();
  const stringBody = JSON.stringify(entity.data);

  // const options = [
  //   {
  //     label: "Fulfiller Admin",
  //     value: "fulfiller:admin",
  //   },
  //   { label: "Fulfiller User", value: "fulfiller:user" },
  // ];

  const types = [
    {
      label: "excludes",
      value: "excludes",
    },
    { label: "includes", value: "includes" },
  ];

  return (
    <div className={styles.container}>
      <Card>
        <div className={styles.wrapperCard}>
          <ContainerTitle
            padding="0 0 1em 0"
            size="small"
            label={t("component.entityForm.orgDetails")}
          />

          <div>
            <GridContainer gap="1em" padding="0 0 0 0" columns="1fr 1fr 1fr">
              <FlexContainer alignItems="center" justifyContent="flex-start">
                <Switch
                  onChange={(value) => {
                    onChange("enabled", value);
                  }}
                  checked={entity.enabled}
                />
                <p className={styles.label}>Enabled</p>
              </FlexContainer>
              <FlexContainer alignItems="center" justifyContent="flex-start">
                <Switch
                  onChange={(value) => {
                    onChange("internal", value);
                  }}
                  checked={entity.internal}
                />
                <p className={styles.label}>
                  {t("component.entityForm.internal")}
                </p>
              </FlexContainer>
            </GridContainer>
            <ContainerTitle
              padding="2em 0 0.5em 0"
              size="small"
              label={t("component.entityForm.maskingTemplates")}
            />
            <Text
              padding="0 0 2em 0"
              text={t("component.entityForm.description")}
            />
            <GridContainer gap="1em" columns="2fr 1fr 1fr">
              <div>
                <FlexContainer justifyContent="flex-start">
                  <InputLabel label={t("component.entityForm.orders")} />
                  <Tooltip
                    title={
                      maskingTemplates.orders.type === "excludes"
                        ? t("component.entityDetail.infoOrderE")
                        : t("component.entityDetail.infoOrderI")
                    }
                  >
                    <InfoCircleOutlined style={{ marginLeft: "0.5em" }} />{" "}
                  </Tooltip>
                </FlexContainer>
                <TreeSelect
                  showSearch
                  value={maskingTemplates.orders.fields}
                  style={{ width: "100%" }}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder="Please select"
                  allowClear
                  multiple
                  treeDefaultExpandAll
                  onChange={(fields) => {
                    onChangeMaskingTemplate({
                      ...maskingTemplates,
                      orders: {
                        type: maskingTemplates.orders.type,
                        fields: fields,
                      },
                    });
                  }}
                  treeData={maskingOrdersTeamTreeData}
                />
              </div>
              <InputSelect
                label={t("component.entityForm.type")}
                options={types}
                onChange={(value) =>
                  onChangeMaskingTemplate({
                    ...maskingTemplates,
                    orders: {
                      type: value,
                      fields: maskingTemplates.orders.fields,
                    },
                  })
                }
                value={maskingTemplates.orders.type}
                placeholder={t("component.entityForm.selectAnOption")}
              />
            </GridContainer>
            <GridContainer padding="1em 0 0 0" gap="1em" columns="2fr 1fr 1fr">
              <div>
                <FlexContainer justifyContent="flex-start">
                  <InputLabel label={t("component.entityForm.orderlines")} />
                  <Tooltip
                    title={
                      maskingTemplates.orderlines.type === "excludes"
                        ? t("component.entityDetail.infoLinesE")
                        : t("component.entityDetail.infoLinesI")
                    }
                  >
                    <InfoCircleOutlined style={{ marginLeft: "0.5em" }} />{" "}
                  </Tooltip>
                </FlexContainer>
                <TreeSelect
                  showSearch
                  value={maskingTemplates.orderlines.fields}
                  style={{ width: "100%" }}
                  onChange={(fields) => {
                    onChangeMaskingTemplate({
                      ...maskingTemplates,
                      orderlines: {
                        type: maskingTemplates.orderlines.type,
                        fields: fields,
                      },
                    });
                  }}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder="Please select"
                  allowClear
                  multiple
                  treeDefaultExpandAll
                  treeData={maskingOrdelinesTeamTreeData}
                />
              </div>
              <InputSelect
                value={maskingTemplates.orderlines.type}
                onChange={(value) =>
                  onChangeMaskingTemplate({
                    ...maskingTemplates,
                    orderlines: {
                      type: value,
                      fields: maskingTemplates.orderlines.fields,
                    },
                  })
                }
                label={t("component.entityForm.type")}
                options={types}
                placeholder={t("component.entityForm.selectAnOption")}
              />
            </GridContainer>
            <GridContainer padding="1em 0 0 0" gap="1em" columns="2fr 1fr 1fr">
              <div>
                <FlexContainer justifyContent="flex-start">
                  <InputLabel label={t("component.entityForm.invoices")} />
                  <Tooltip
                    title={
                      maskingTemplates.invoices.type === "excludes"
                        ? t("component.entityDetail.infoInvoiceE")
                        : t("component.entityDetail.infoInvoiceI")
                    }
                  >
                    <InfoCircleOutlined style={{ marginLeft: "0.5em" }} />{" "}
                  </Tooltip>
                </FlexContainer>

                <TreeSelect
                  showSearch
                  value={maskingTemplates.invoices.fields}
                  style={{ width: "100%" }}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder="Please select"
                  allowClear
                  multiple
                  treeDefaultExpandAll
                  onChange={(fields) => {
                    onChangeMaskingTemplate({
                      ...maskingTemplates,
                      invoices: {
                        type: maskingTemplates.invoices.type,
                        fields: fields,
                      },
                    });
                  }}
                  treeData={maskingInvoicesTeamTreeData}
                />
              </div>
              <InputSelect
                label={t("component.entityForm.type")}
                options={types}
                value={maskingTemplates.invoices.type}
                onChange={(value) =>
                  onChangeMaskingTemplate({
                    ...maskingTemplates,
                    invoices: {
                      type: value,
                      fields: maskingTemplates.invoices.fields,
                    },
                  })
                }
                placeholder={t("component.entityForm.selectAnOption")}
              />
            </GridContainer>
            <ContainerTitle
              size="small"
              padding="0 0 1em 0"
              label={t("component.entityForm.data")}
            />

            <Editor
              onChange={(value) =>
                onChange("data", JSON.parse(value as string))
              }
              className={styles.editorBox}
              height="100%"
              // theme="vs-dark"
              defaultLanguage="json"
              defaultValue={stringBody}
              options={{
                selectOnLineNumbers: true,
                // minimap: { enabled: false },
                scrollbar: {
                  vertical: "hidden",
                },
                overviewRulerLanes: 0,
                hideCursorInOverviewRuler: true,
                overviewRulerBorder: false,
                foldingHighlight: false,
              }}
            />
            {/* <ContainerTitle
              padding="2em 0 1em 0"
              size="small"
              label={t("component.entityForm.roles")}
            />
            <InputCheckboxGroup
              onChange={(checkedValues) => {
                const selected = checkedValues.map((item) => item.toString());
                onChange("roles", selected);
              }}
              defaultValue=""
              options={options}
            /> */}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default EntityForm;
