import React, { useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { loadProfile } from "../../store/profile";

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

const PrivateContainer: React.FC<Props> = ({ children }) => {
  const { loading, user, error } = useSelector(
    (state: RootState) => state.auth
  );
  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    dispatch(loadProfile());
  }, [dispatch]);

  if (loading) {
    return null;
  }

  if (error) {
    return (
      <Redirect from="*" to={{ pathname: "/", search: "?expired=true" }} />
    );
  }

  if (!user) {
    const targetURL = location.pathname + location.search;

    return (
      <Redirect
        to={{
          pathname: "/",
          search: `?targetURL=${encodeURIComponent(targetURL)}`,
        }}
      />
    );
  }

  return <>{children}</>;
};

export default PrivateContainer;
