import { stylesheet } from "typestyle";
import { black, lightGrey, linkBlue } from "../../../utils/colors";

const styles = stylesheet({
  containerDetail: {
    color: black,
    padding: "1em 1em",
    marginBottom: "1em",
  },
  containerCancel: {
    color: "#A5A5A5 !important",
    padding: "1em 0",
    marginBottom: "1em",
  },
  white: {
    backgroundColor: "#fff",
    height: "2.3em",
    borderRadius: 2,
    border: "1px solid #DCE1E6",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.5em",
    cursor: "pointer",
  },
  link: {
    display: "flex",
    flexDirection: "row",
    padding:"0 0 0.5em 0",
    justifyContent: "flex-start",
  },
  width: {
    maxWidth: "10%",
  },
  editorBox: {
    height: "5em",
    border: "1px solid #dce1e6",
  },

  smallIcon: {
    width: "1.2em",
    cursor: "pointer",
    margin: "0 0.5em 0 0.1em",
  },

  green: {
    backgroundColor: "#10A841",
    height: "2.3em",
    borderRadius: 2,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.5em",
    cursor: "pointer",
  },

  yellow: {
    backgroundColor: "#FAAD15",
    height: "2.3em",
    borderRadius: 2,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.5em",
    cursor: "pointer",
  },
  blue: {
    padding: "0.5em",
    cursor: "pointer",
    backgroundColor: "#1FA0FF",
    height: "2.3em",
    borderRadius: 2,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  date: {
    minWidth: "12em",
    marginRight: "2em",
  },
  editContainer: {
    borderRadius: 3,
    border: "1px solid #DCE1E6",
    display: "flex",
    justifyContent: "center",
    padding: "0em 0.6em",
    marginLeft: "1em",
    background: "white",
    cursor: "pointer",
    height: "2.5em",
  },
  iconEdit: {
    width: "1.3em",
  },
  time: {
    minWidth: "11em",
    marginRight: "1em",
  },
  pointer: {
    cursor: "pointer",
    display: "flex",
    flexWrap: "wrap",
  },
  placements: {
    background: "#fff",
    padding: "0 1em 1em 1em",
    border: "1px solid #DCE1E6",
    borderRadius: "8px",
  },
  line: {
    width: "100%",
    height: "1px",
    margin: "1em 0",
    background: lightGrey,
  },
  lineSmall: {
    width: "90%",
    height: "1px",
    margin: "1em 0",
    background: lightGrey,
  },
  commentsContainer: {
    borderBottom: `1px solid ${lightGrey}`,
    paddingBottom: "1em",
    marginBottom: "1em",
  },
  label: {
    color: "#000",
    textAlign: "center",
    fontSize: "1em",
    fontWeight: 700,
    padding: "0em 0 0.5em 0",
  },
  center: {
    color: black,
    fontStyle: "normal",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    display: "flex",
    alignItems: "flex-start",
    marginRight: "0.5em",
  },
  select: { minWidth: "14em" },
  closeButton: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    color: linkBlue,
    cursor: "pointer",
  },
  buttonContainer: {
    marginRight: "1em",
  },
  chevron: {
    width: "36px",
    color: linkBlue,
    marginLeft: "0.5em",
  },
  title: {
    padding: "0 0 1em 0",
    fontWeight: 700,
  },
  bold: {
    fontWeight: 700,
    marginRight: "1em",
  },
  medium: {
    fontWeight: 500,
  },
  big: {
    textTransform: "uppercase",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },

  icon: {
    width: "30px",
    cursor: "pointer",
  },
  normalButton: {
    cursor: "pointer",
    fontSize: "1em",
    fontWeight: 600,
    padding: "0 0.5em",
    border: "1px solid #dce1e6",
    borderRadius: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "2.9em",
    minWidth: "9em",
  },
  container: {
    $nest: {
      "div > div": {
        alignItems: "center !important",
      },
    },
  },
  notEnrollContainer: {
    background: "rgba(250, 173, 21, 0.1)",
    border: "1px solid #FAAD15",
    padding: "1.1em",
    borderRadius: "6px",
    margin: "0em 0 1.5em 0",
  },
  marker: {
    position: "absolute",
    zIndex: 97,
    right: "0em",
    top: "35%",
    cursor: "pointer",
    background: "#fff",
    padding: "0.3em",
    borderRadius: "20px",
  },
});

export default styles;
