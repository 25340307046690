import React, { useState } from "react";
import styles from "./styles.module.css";
import { ReactComponent as Chevron } from "./chevron-down.svg";
import { InputText } from "../InputText";

interface InputTextDropdownProps {
  value?: string;
  label?: string;
  error?: false | "" | string[] | string;
  showError?: boolean;
  onChange: (value: string) => void;
  options: { label: string; value: string }[];
  placeholder?: string;
  showLabel?: boolean;
  top?: string;
  containerClassName?: string;
}

const InputTextDropdown: React.FC<InputTextDropdownProps> = ({
  value,
  label,
  error,
  showError,
  onChange,
  placeholder,
  options,
  showLabel,
  top,
  containerClassName,
}) => {
  const [text, setText] = useState("");
  const [isFocus, setIsFocus] = useState(false);
  const [filteredItems, setFilteredItems] = useState(options); // State for filtered options

  const handleTextChange = (newValue: string) => {
    const lowerCaseNewValue = newValue.toLowerCase(); // Convert input text to lowercase
    setText(newValue);
    if (!newValue && value) {
      onChange(""); // Clear value if text is empty
    } else {
      onChange(newValue); // Apply onChange to the text being written
    }
    const filteredItems = options.filter(
      (e) => e.label.toLowerCase().includes(lowerCaseNewValue) // Compare lowercase option labels
    );
    setFilteredItems(filteredItems);
  };

  const handleOptionClick = (item: { label: string; value: string }) => {
    setText(item.label);
    onChange(item.value);
  };

  return (
    <div className={styles.container}>
      <InputText
        containerClassname={(styles.containerWrapper, containerClassName)}
        label={label}
        showLabel={showLabel}
        value={isFocus ? text : value}
        onChange={handleTextChange}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setTimeout(() => setIsFocus(false), 200)}
        error={error}
        showError={showError}
        placeholder={placeholder}
        buttonIcon={Chevron}
      />
      {isFocus && filteredItems.length ? (
        <div style={{ top: top }} className={styles.list}>
          {filteredItems.map((item) => (
            <div
              className={
                options.find((e) => e.value === value)?.label === item.label
                  ? styles.itemSelected
                  : styles.item
              }
              key={item.value}
              onClick={() => handleOptionClick(item)}
            >
              {item.label}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default InputTextDropdown;
