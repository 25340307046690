interface NestedObject {
  [key: string]: NestedObject | string;
}

export function convertKeys(
  arr: Array<{ [key: string]: string }>
): NestedObject[] {
  const result: NestedObject[] = [];

  arr.forEach((obj) => {
    const newObj: NestedObject = {};

    for (const key in obj) {
      const keys = key.split(".");
      let currentObj: NestedObject = newObj;

      keys.forEach((nestedKey, index) => {
        if (index === keys.length - 1) {
          currentObj[nestedKey] = obj[key];
        } else {
          currentObj[nestedKey] = currentObj[nestedKey] || {};
          currentObj = currentObj[nestedKey] as NestedObject;
        }
      });
    }

    result.push(newObj);
  });

  return result;
}
