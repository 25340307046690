import React from "react";

import CustomError from "../../utils/CustomError";
import { BankAccount } from "../../utils/types";
import { useTranslation } from "react-i18next";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputText } from "../Inputs/InputText";
import { InputSelect } from "../Inputs/InputSelect";

interface Props {
  data: BankAccount;
  onChange: <P extends keyof BankAccount>(
    prop: P,
    value: BankAccount[P]
  ) => void;
  error?: CustomError;
  edit: boolean;
}

const AccountForm: React.FC<Props> = ({ data, onChange, edit }) => {
  const { t } = useTranslation();

  return (
    <div>
      <GridContainer gap="1em" columns="1fr">
        <InputText
          disabled={edit}
          label={t("component.fopForm.bankName")}
          value={data.bank}
          onChange={(value) => onChange("bank", value)}
        />
        <InputText
          label={t("component.fopForm.bankAccount")}
          value={data.bankAccount}
          onChange={(value) => onChange("bankAccount", value)}
        />
      </GridContainer>
      <GridContainer gap="1em" columns="1fr 1fr">
        <InputText
          label={t("component.fopForm.legalName")}
          value={data.legalName}
          onChange={(value) => onChange("legalName", value)}
        />
        <InputText
          label={t("component.fopForm.alias")}
          value={data.alias}
          onChange={(value) => onChange("alias", value)}
        />
        <InputSelect
          label={t("component.fopForm.qrCodeType")}
          options={[
            { label: "None", value: "none" },
            { label: "EPC QR Code", value: "epc-qrCode" },
            { label: "Codi", value: "codi" },
          ]}
          value={data.qrCodeType}
          onChange={(value) => onChange("qrCodeType", value)}
        />
      </GridContainer>
    </div>
  );
};

export default AccountForm;
