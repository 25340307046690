import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./styles.module.css";
import { ThreeWordAddress, w3wCoordinatesTo3wa } from "../../../services/maps";
import { InputText } from "../InputText/index";
interface Prediction {
  id: string;
  description: string;
  place_id: string;
  lat: number;
  lng: number;
}

interface GooglePlacesAutocompleteProps {
  onChange: (w3a: ThreeWordAddress) => void;
  line1?: string;
}

const GooglePlacesAutocomplete: React.FC<GooglePlacesAutocompleteProps> = ({
  onChange,
  line1,
}) => {
  const [inputValue, setInputValue] = useState<string>(line1 || "");
  const [predictions, setPredictions] = useState<Prediction[]>([]);
  const [error, setError] = useState<string>("");
  const [isFocus, setIsFocus] = useState(false);
  const [errorPredictions, setErrorPredictions] = useState<string>("");

  useEffect(() => {
    setInputValue(line1 || "");
  }, [line1]);

  const API_KEY = "AIzaSyBnUmMfyn4yxmKUBh-xP8U9k7GYmMp-oyo";

  useEffect(() => {
    if (inputValue.length > 3) {
      axios
        .get(
          `/maps/api/place/autocomplete/json?input=${inputValue}&key=${API_KEY}`
        )
        .then((response) => {
          setPredictions(response.data.predictions);
        })
        .catch((error) => {
          setPredictions([]);
          console.error(error);
          setErrorPredictions(error.message);
        });
    } else {
      setPredictions([]);
    }
  }, [inputValue]);

  const handleSelect = (selectedPrediction: Prediction) => {
    setInputValue(selectedPrediction.description);

    axios
      .get(
        `/maps/api/place/details/json?place_id=${selectedPrediction.place_id}&key=${API_KEY}`
      )
      .then(async (response) => {
        const { lat, lng } = response.data.result.geometry.location;
        selectedPrediction.lat = lat;
        selectedPrediction.lng = lng;

        const location = {
          lat: lat,
          lng: lng,
        };
        const three = await w3wCoordinatesTo3wa(location);

        onChange({
          ...three,
          name: selectedPrediction.description,
        });
      })
      .catch((error) => {
        console.error(error);
        setError(error.message);
      });
  };

  return (
    <div className={styles.containerGEO}>
      <InputText
        containerClassname={styles.containerWrapper}
        label="Address"
        value={inputValue}
        onChange={(value) => setInputValue(value)}
        error={error || errorPredictions}
        onFocus={() => {
          setIsFocus(true);
          setError("");
          setErrorPredictions("");
        }}
        onBlur={() => setTimeout(() => setIsFocus(false), 200)}
      />

      {isFocus && predictions?.length ? (
        <div className={styles.list}>
          {predictions.map((prediction) => (
            <div
              style={{ cursor: "pointer" }}
              key={prediction.id}
              className={styles.item}
              onClick={() => {
                handleSelect(prediction);
              }}
            >
              {prediction.description}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default GooglePlacesAutocomplete;
