export const maskingOrdersTeamTreeData = [
  {
    value: "order",
    title: "order",
    children: [
      {
        value: "payments",
        title: "payments",
      },
      {
        value: "details",
        title: "details",
      },
      {
        value: "description",
        title: "description",
      },
      {
        value: "paymentDueDate",
        title: "payment due date",
      },
      {
        value: "paid",
        title: "paid amount",
      },
      {
        value: "due",
        title: "due amount",
      },
      {
        value: "paymentRequests",
        title: "payments requests",
      },
      {
        value: "total",
        title: "total",
      },
      {
        value: "totalTaxes",
        title: "total taxes",
      },
      {
        value: "subTotal",
        title: "subtTotal",
      },
      {
        value: "totalDiscount",
        title: "total discount",
      },
      {
        value: "taxAmount",
        title: "tax amount",
      },
      {
        value: "totalSurcharge",
        title: "total surcharge",
      },
      {
        value: "comments",
        title: "comments",
      },
      {
        value: "customer",
        title: "customer",
        children: [
          {
            value: "customer.fullName",
            title: "customer name",
          },
          {
            value: "customer.email",
            title: "customer email",
          },
          {
            value: "customer.phone",
            title: "customer phone",
          },
          {
            value: "customer.externalId",
            title: "customer externalId",
          },
        ],
      },
      {
        value: "channel",
        title: "channel",
        children: [
          {
            value: "channel.name",
            title: "channel name",
          },
        ],
      },
      {
        value: "orderlines",
        title: "orderlines",
      },
    ],
  },
];

export const maskingOrdelinesTeamTreeData = [
  {
    value: "orderlines",
    title: "orderlines",
    children: [
      {
        value: "details",
        title: "details",
      },
      {
        value: "description",
        title: "description",
      },
      {
        value: "sku",
        title: "sku",
      },
      {
        value: "unitPrice",
        title: "unit price",
      },
      {
        value: "surcharges",
        title: "surcharges",
      },
      {
        value: "taxes",
        title: "taxes",
      },
      {
        value: "paymentRequests",
        title: "payments requests",
      },
      {
        value: "total",
        title: "total",
      },
      {
        value: "totalTaxes",
        title: "total taxes",
      },
      {
        value: "subTotal",
        title: "subtTotal",
      },
      {
        value: "totalDiscount",
        title: "total discount",
      },
      {
        value: "taxAmount",
        title: "tax amount",
      },
      {
        value: "totalSurcharge",
        title: "total surcharge",
      },
      {
        value: "comments",
        title: "comments",
      },
    ],
  },
];

export const maskingInvoicesTeamTreeData = [
  {
    value: "invoices",
    title: "invoices",
    children: [
      {
        value: "amount",
        title: "amount",
      },
      {
        value: "authority",
        title: "authority",
      },
      {
        value: "description",
        title: "description",
      },
      {
        value: "issuer",
        title: "issuer",
      },
      {
        value: "files",
        title: "files",
      },
    ],
  },
];

export const maskingTeamTreeData = [
  {
    value: "order",
    title: "order",
    children: [
      {
        value: "order.details",
        title: "details",
      },
      {
        value: "order.description",
        title: "description",
      },
      {
        value: "order.payments",
        title: "payments",
      },
      {
        value: "order.paymentDueDate",
        title: "payment due date",
      },
      {
        value: "order.paid",
        title: "paid amount",
      },
      {
        value: "order.due",
        title: "due amount",
      },
      {
        value: "order.paymentRequests",
        title: "payments requests",
      },
      {
        value: "order.total",
        title: "total",
      },
      {
        value: "order.totalTaxes",
        title: "total taxes",
      },
      {
        value: "order.subTotal",
        title: "subtTotal",
      },
      {
        value: "order.totalDiscount",
        title: "total discount",
      },
      {
        value: "order.taxAmount",
        title: "tax amount",
      },
      {
        value: "order.totalSurcharge",
        title: "total surcharge",
      },
      {
        value: "order.comments",
        title: "comments",
      },

      {
        value: "order.customer",
        title: "customer",
        children: [
          {
            value: "order.customer.fullName",
            title: "customer name",
          },
          {
            value: "order.customer.email",
            title: "customer email",
          },
          {
            value: "order.customer.phone",
            title: "customer phone",
          },
          {
            value: "order.customer.externalId",
            title: "customer externalId",
          },
        ],
      },
      {
        value: "order.channel",
        title: "channel",
        children: [
          {
            value: "order.channel.name",
            title: "channel name",
          },
        ],
      },
      {
        value: "order.orderlines",
        title: "orderlines",
      },
    ],
  },
  {
    value: "orderlines",
    title: "orderlines",
    children: [
      {
        value: "orderlines.details",
        title: "details",
      },
      {
        value: "orderlines.description",
        title: "description",
      },
      {
        value: "orderlines.sku",
        title: "sku",
      },
      {
        value: "orderlines.unitPrice",
        title: "unit price",
      },
      {
        value: "orderlines.surcharges",
        title: "surcharges",
      },
      {
        value: "orderlines.taxes",
        title: "taxes",
      },
      {
        value: "orderlines.paymentRequests",
        title: "payments requests",
      },
      {
        value: "orderlines.total",
        title: "total",
      },
      {
        value: "orderlines.totalTaxes",
        title: "total taxes",
      },
      {
        value: "orderlines.subTotal",
        title: "subtTotal",
      },
      {
        value: "orderlines.totalDiscount",
        title: "total discount",
      },
      {
        value: "orderlines.taxAmount",
        title: "tax amount",
      },
      {
        value: "orderlines.totalSurcharge",
        title: "total surcharge",
      },
      {
        value: "orderlines.comments",
        title: "comments",
      },
    ],
  },
  {
    value: "invoices",
    title: "invoices",
    children: [
      {
        value: "invoices.amount",
        title: "amount",
      },
      {
        value: "invoices.authority",
        title: "authority",
      },
      {
        value: "invoices.description",
        title: "description",
      },
      {
        value: "invoices.issuer",
        title: "issuer",
      },
      {
        value: "invoices.files",
        title: "files",
      },
    ],
  },
];
