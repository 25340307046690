import React from "react";
import { FulfillmentListSkeleton } from "../ListSkeletons";
import FulfillmentItem from "./FulfillmentItem";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { PaginationRavent } from "../Pagination/Pagination";
import {
  Action,
  FulfillmentDefinition,
  FulfillmentStateDef,
} from "@innomius/ravent-typescript-types";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { List } from "../List";

interface EntityProps {
  error: string;
  loading: boolean;
  data: FulfillmentDefinition[];
  page: number;
  total: number;
  hitsPerPage: number;
  onPageChange?: (page: number) => void;
  onHitsPerPageChange?: (hitsPerPage: number) => void;
  sortable: boolean;
  pagination?: boolean;
  path: string;
  onEditFulfillmentDefinition: (data: FulfillmentDefinition) => void;
  onAddState: (
    fulfillment: FulfillmentDefinition,
    data: FulfillmentStateDef,
    edit: boolean,
    index?: number
  ) => void;
  onDeleteFulfillmentState: (id: string) => void;
  load: () => void;
  open: boolean;
  setOpen: (value: boolean) => void;
  actions: Action[];
  onDelete: (id: string) => void;
}

const FulfillmentList: React.FC<EntityProps> = ({
  error,
  loading,
  data,
  pagination,
  page,
  total,
  hitsPerPage,
  onHitsPerPageChange,
  onPageChange,
  path,
  onAddState,
  onDeleteFulfillmentState,
  onEditFulfillmentDefinition,
  load,
  open,
  setOpen,
  actions,
  onDelete,
}) => {
  const { t } = useTranslation();

  const header = (
    <div>
      <ListHeader template="1fr 1fr 1fr 0.5fr">
        <ListHeaderLabel
          value={t("component.fulfillmentList.name")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.fulfillmentList.type")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.fulfillmentList.orderlineStates")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.fulfillmentList.actions")}
          justifyContent="center"
        />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <div className={styles.listContainer}>
        <List
          loading={loading}
          loadingElement={() => <FulfillmentListSkeleton />}
          error={error}
          data={data}
          keyExtractor={(item) => item.id}
          renderHeader={() => header}
          renderItem={(item) => (
            <FulfillmentItem
              actions={actions}
              key={item.id}
              open={open}
              onDelete={onDelete}
              setOpen={setOpen}
              fulfillmentList={item}
              path={path}
              onDeleteFulfillmentState={onDeleteFulfillmentState}
              fulfillmentId={item.id}
              onAddState={onAddState}
              load={load}
              onEditFulfillmentDefinition={onEditFulfillmentDefinition}
            />
          )}
        />
      </div>

      {pagination && data.length >= 5 ? (
        <div className={styles.paginationContainer}>
          <PaginationRavent
            current={page + 1}
            defaultCurrent={page + 1}
            total={total}
            showSizeChanger
            onShowSizeChange={(page, size) => {
              if (onHitsPerPageChange) {
                onHitsPerPageChange(size);
              }
            }}
            onChange={(page) => {
              if (onPageChange) {
                onPageChange(page - 1);
              }
            }}
            pageSize={hitsPerPage}
          />
        </div>
      ) : null}
    </div>
  );
};

export default FulfillmentList;
