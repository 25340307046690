const translation = {
  en: {
    cancel: "Cancel",
    save: "Save",
    name: "Name",
    lastName: "Last Name",
    dob: "Date of Birth",
    notification: "Edit People",
    addPerson: "+ Add Person",
    phone: "Phone",
    email: "Email",
  },
  es: {
    phone: "Teléfono",
    email: "Correo Electrónico",
    cancel: "Cancelar",
    save: "Guardar",
    name: "Nombre",
    addPerson: "+ Agregar Persona",
    lastName: "Apellido",
    dob: "Fecha de Nac.",
    notification: "Editar a Personas relacionadas al Evento",
  },
  de: {},
};

export default translation;
