import { stylesheet } from "typestyle";

const styles = stylesheet({
  container: {
    minHeight: "100vh",
  },
  sectionWrapper: {
    padding: "2em",
    marginBottom: "1em",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  logoContainer: {
    objectFit: "cover",
    height: "14em",
    borderRadius: "7px",
  },
  label: {
    margin: "0.2em 0.5em 0.2em 0",
    width: "auto",
  },
});

export default styles;
