const translation = {
  en: {
    error: "Oops! An Error occured.",
    hidden: "Hidden Spot",
    warningHidden:
      "You’re attempting to access a restricted feature. Please contact your administrator for assistance.",
  },
  es: {
    hidden: "Lugar Oculto",
    warningHidden:
      "Estás intentando acceder a una función restringida. Por favor, contacta al administrador para obtener ayuda.",
    error: "Lo sentimos, un error acaba de ocurrir",
  },
  de: {},
};

export default translation;
