import { stylesheet } from "typestyle";
import { black, darkBlack, lightGrey } from "../../utils/colors";

const styles = stylesheet({
  container: {
    padding: '1em 0 0 0'
  },
  containerBox: {
    borderRadius: "2px",
    border: `1px solid ${lightGrey}`,
    marginBottom: "1em",
  },
  blueBox: {
    background: "#F3F9FE",
    height: "20%",
    overflowY: "scroll",
    borderBottom: `1px solid ${lightGrey}`,
  },
  button: {
    marginRight: "1em",
  },
  row: {
    padding: "1em",
  },
  amountRow: {
    padding: "2em 2em 1em 2em",
    display: "flex",
    justifyContent: "flex-end",
  },
  info: {
    fontSize: "1em",
    fontWeight: 400,
    color: black,
    paddingBottom: "0.2em",
  },
  infoBold: {
    fontSize: "1em",
    fontWeight: 700,
    color: black,
    paddingBottom: "0.2em",
  },
  title: {
    color: darkBlack,
    fontWeight: "bold",
    fontSize: "1.1em",
    paddingBottom: "1em",
  },
  quantity: {
    fontSize: "1.1em",
    color: darkBlack,
    fontWeight: 400,
    textAlign: "center",
  },
  numbers: {
    fontSize: "1em",
    textAlign: "right",
    color: darkBlack,
    fontWeight: 400,
    marginBottom: "0.5em",
  },
  sectionRight: {
    fontSize: "1.1em",
    textAlign: "right",
    color: darkBlack,
    fontWeight: 400,
  },
  total: {
    marginTop: "1em",
    fontSize: "1.2em !important",
    fontWeight: "bold",
    color: darkBlack + "!!important",
    textAlign: "right",
  },
  description: {
    paddingBottom: "1em",
    color: black,
  },
  line: {
    width: "100%",
    height: "1px",
    margin: "1em 0",
    background: lightGrey,
  },
  section: {
    color: darkBlack,
    fontSize: "1.1em",
    fontWeight: 700,
  },
  text: {
    fontSize: "1.1em",
    padding: "1em 0",
  },
  margin:{
    paddingBottom: '1em !important',
    justifyContent: 'flex-start !important'
  }
});

export default styles;
