const translation = {
  en: {
    requestId: "Request ID",
    paymentType: "Payment Type",
    issued: "Issued",
    expires: "Expires",
    amount: "Amount",
    actions: "",
    share: "Share Request",
    copy: "Copy to Clipboard",
    invoice: "Issues Invoice",
  },
  es: {
    requestId: "ID de solicitud",
    paymentType: "Tipo de pago",
    issued: "Emitido",
    expires: "Caduca",
    amount: "Cantidad",
    actions: "",
    share: "Compartir solicitud de pago",
    copy: "copiar al portapapeles",
    invoice: "emitir factura",
  },
  de: {},
};

export default translation;
