import React from "react";
import VenueItemList from "./VenueListItem";
import { OrderListSkeleton } from "../ListSkeletons";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { PaginationRavent } from "../Pagination/Pagination";
import { VenueStats } from "../../utils/types";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { List } from "../List";

interface VenuesListProps {
  error: string;
  loading: boolean;
  data: VenueStats[];
  onChangeSort?: (order: number, sortBy: number) => void;
  page: number;
  total: number;
  hitsPerPage: number;
  onPageChange?: (page: number) => void;
  onHitsPerPageChange?: (hitsPerPage: number) => void;
  sortable: boolean;
  pagination?: boolean;
}

const VenuesList: React.FC<VenuesListProps> = ({
  error,
  loading,
  data,
  pagination,
  page,
  total,
  hitsPerPage,
  onHitsPerPageChange,
  onPageChange,
}) => {
  const { t } = useTranslation();

  const header = (
    <div>
      <ListHeader template="1.3fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.5fr">
        <ListHeaderLabel
          value={t("component.venueList.nameLabel")}
          justifyContent={"flex-start"}
        />
        {/* <ListHeaderLabel
          value={t("component.venueList.currentVisitors")}
          justifyContent="center"
        /> */}
        {/* <ListHeaderLabel
          value={t("component.venueList.locations")}
          justifyContent="center"
        /> */}
        <ListHeaderLabel
          value={t("component.venueList.orders")}
          justifyContent={"flex-start"}
        />
        <ListHeaderLabel
          value={t("component.venueList.events")}
          justifyContent={"flex-start"}
        />
        <ListHeaderLabel
          value={t("component.venueList.locations")}
          justifyContent={"flex-start"}
        />
        <ListHeaderLabel
          value={t("component.venueList.users")}
          justifyContent={"flex-start"}
        />
        <ListHeaderLabel
          value={t("component.venueList.fulfillments")}
          justifyContent={"flex-start"}
        />

        <ListHeaderLabel value={""} justifyContent="center" />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <div className={styles.listWrapper}>
        <List
          loading={loading}
          loadingElement={() => <OrderListSkeleton />}
          error={error}
          data={data}
          empty={!data.length && !error && !loading}
          keyExtractor={(item) => item.id}
          renderHeader={() => header}
          renderItem={(item) => <VenueItemList data={item} key={item.id} />}
        />
      </div>

      {pagination ? (
        <div className={styles.paginationContainerFixed}>
          <PaginationRavent
            current={page + 1}
            defaultCurrent={page + 1}
            total={total}
            showSizeChanger
            onShowSizeChange={(page, size) => {
              if (onHitsPerPageChange) {
                onHitsPerPageChange(size);
              }
            }}
            onChange={(page) => {
              if (onPageChange) {
                onPageChange(page - 1);
              }
            }}
            pageSize={hitsPerPage}
          />
        </div>
      ) : null}
    </div>
  );
};

export default VenuesList;
