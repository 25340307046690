import React, { useCallback, useEffect, useState } from "react";
import {
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import { Fulfiller, Team, User } from "@innomius/ravent-typescript-types";
import TabNavBar from "../../components/TabNavBar";
import EntityDetailComponent from "../../components/TeamDetailComponent";
import EntityForm from "../../components/EntityForm";
import EntityFulfillers from "../../components/EntityFulfillers";
import styles from "./styles.module.css";
import {
  createFulfiller,
  CreateTeamParams,
  defaultFulfillers,
  deleteTeam,
  editDiver,
  getFulfiller,
  readTeam,
  updateTeam,
  defaultMaskingTemaplte,
  teamList,
} from "../../services/entities";
import { Loading } from "../../components/Loading";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { integrationList } from "../../store/integrations";
import EntityHeader from "../../components/EntityHeader";
import APIError from "../../utils/APIError";
import { useTranslation } from "react-i18next";
import AddExistingUser from "../../components/EntityFulfillers/AddExisitingUser";
import {
  deleteUser,
  registerUsers,
  sendResetUsername,
  updateTeamUser,
  userFullTextSearch,
  userList,
} from "../../services/users";
import { MaskingTemplates, SavedData } from "../../utils/types";
import { Role, roleList } from "../../services/roles";
import { EmptyList } from "../../components/List/EmptyList";
import { GridContainer } from "../../components/GridContainer/GridContainer";
import SideFiltersUsers from "../../components/SideFilters/SideFiltersUsers";
import { Filter, TextSearchListEvent } from "../../services/orders";
import { parseQueryParameter } from "../../utils/order";
import CrossIcon from "../../components/Icons/CrossIcon";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import Search from "antd/es/input/Search";
import { Button } from "../../components/Button";
import SaveFilterModal from "../../components/SaveFilterModal";
import DeleteModal from "../../components/DeleteModal";
interface RouteParams {
  id: string;
}

interface Props extends RouteComponentProps<RouteParams> {}

const EntityDetail: React.FC<Props> = ({ match }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [entity, setEntity] = useState<Team | null>(null);
  const [savedData, setSavedData] = useState<Record<string, SavedData>>({});
  const [savedFilter, setSavedFilter] = useState<string>("");
  const [diver, setFulfiller] = useState<Fulfiller>(defaultFulfillers);
  const [loading, setLoading] = useState<string>("");
  const [error, setError] = useState("");
  const [errorDetail, setErrorDetail] = useState("");
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState("");
  const [errorCreation, setCreationError] = useState("");
  const [modal, setModal] = useState(false);
  const [roles, setRoles] = useState<Role[]>([]);
  const [users, setUsersL] = useState<User[]>([]);
  const [fulfillers, setFulfillers] = useState<TextSearchListEvent>({
    hits: [],
    count: 0,
    page: 0,
    facets: [],
  });

  const [deletedFilter, setDeletedFilter] = useState("");

  const [teams, setTeams] = useState<Team[]>([]);
  const [sort, setSortBy] = useState("");
  const [resize, setSize] = useState(false);

  const [page, setPage] = useState(0);

  const [records_per_page, setHitsPerPage] = useState(10);

  const [maskingTemplate, setMasking] = useState<MaskingTemplates>(
    entity ? entity.maskingTemplates : defaultMaskingTemaplte
  );

  function useQuery() {
    const { search } = useLocation();
    return search;
  }

  const query = useQuery();

  const [venueName, setVenueName] = useState<Filter>(
    parseQueryParameter(query, "venueName")
  );

  const [tags, setTags] = useState<Filter>(parseQueryParameter(query, "tags"));
  const [search, setSearch] = useState("");
  const [fulfillmentTeamName, setFulfillmentTeamName] = useState<Filter>(
    parseQueryParameter(query, "fulfillmentTeamName")
  );

  const [text, setText] = useState("");

  const [type, setType] = useState<Filter>(parseQueryParameter(query, "type"));

  type StateMap = {
    [key: string]: [Filter, React.Dispatch<React.SetStateAction<Filter>>];
  };

  const stateMap: StateMap = {
    venueName: [venueName, setVenueName],
    tags: [tags, setTags],
    type: [type, setType],
    fulfillmentTeamName: [fulfillmentTeamName, setFulfillmentTeamName],
  };

  const loadRoles = useCallback(async () => {
    try {
      const res = await roleList({ records_per_page: 100, page: 0 });
      setRoles(res.data);
    } catch (err) {
      if (err instanceof APIError) {
        setError(err.message);
      }
    }
  }, []);

  useEffect(() => {
    loadRoles();
  }, [loadRoles]);

  const loadTeams = useCallback(async () => {
    try {
      const res = await teamList({ records_per_page: 100, page: 0 });
      setTeams(res.data);
    } catch (err) {
      if (err instanceof APIError) {
        setError(err.message);
      }
    }
  }, []);

  useEffect(() => {
    loadTeams();
  }, [loadTeams]);

  const loadUsers = useCallback(async () => {
    try {
      const res = await userList({ records_per_page: 10000 });
      setUsersL(res.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const loadFulfillers = useCallback(async () => {
    try {
      setLoading("fulfillers");
      const res = await userFullTextSearch({
        fulfillmentTeamId: match.params.id,
        page,
        records_per_page,
        sort,
        tags,
        search,
        type,
        venueName,
        fulfillmentTeamName,
      });
      setFulfillers(res);
      setLoading("");
    } catch (err) {
      if (err instanceof APIError) {
        setError(err.message);
      }
      setLoading("");
    }
  }, [
    match.params.id,
    page,
    records_per_page,
    sort,
    tags,
    search,
    type,
    venueName,
    fulfillmentTeamName,
  ]);

  useEffect(() => {
    loadFulfillers();
  }, [loadFulfillers]);

  useEffect(() => {
    try {
      const data = localStorage.getItem("userSaveFilter");
      if (data) {
        setSavedData(JSON.parse(data) as Record<string, SavedData>);
      } else {
        setSavedData({});
      }
    } catch (error) {
      console.error("Error parsing userSaveFilter from localStorage:", error);
      setSavedData({});
    }
  }, []);

  const load = useCallback(async () => {
    dispatch(integrationList({}));
    try {
      setLoading("diveshop");
      const res = await readTeam(match.params.id);
      setEntity(res);
      setLoading("");
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        setErrorDetail(err.message);
      }
    }
  }, [match.params.id, dispatch]);

  const filters = Object.keys(savedData)
    .map((key) => {
      const name = savedData[key].name;
      return name ? { label: name, value: name } : undefined;
    })
    .filter(
      (item): item is { label: string; value: string } => item !== undefined
    );

  useEffect(() => {
    load();
  }, [match.params.id, load]);

  const onInviteUsers = async (selectedUsers: any[]) => {
    try {
      await registerUsers(selectedUsers);
      message.success(`${t("container.users.inviteSuccess")}`);
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  if (loading === "diveshop") {
    return <Loading />;
  }

  if (errorDetail) {
    return (
      <EmptyList
        instructions={""}
        text={t("container.entityDetail.error")}
        error={error}
        label={t("container.entityDetail.back")}
        onClick={() => history.push("/teams")}
      />
    );
  }

  if (!entity && loading !== "diveshop") {
    return (
      <EmptyList
        instructions={""}
        text={t("container.entityDetail.notFound")}
        label={t("container.entityDetail.back")}
        onClick={() => history.push("/teams")}
        error={error}
      />
    );
  }

  const onChange = <P extends keyof CreateTeamParams>(
    prop: P,
    value: CreateTeamParams[P]
  ) =>
    setEntity((prevEntity) =>
      prevEntity ? { ...prevEntity, [prop]: value } : prevEntity
    );

  const onEdit = async () => {
    try {
      setLoading("edit");
      if (!entity) return;
      await updateTeam(entity, maskingTemplate);
      message.success(t("container.entityDetail.successUpdated"));
      setLoading("");
      setEdit(false);
      load();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        setCreationError(`${err.message}`);
      }
    }
  };

  const hasNonEmptyState = Object.values(stateMap).some(
    ([filter]) => filter.items.length > 0
  );

  const handleTagClose = (facetGroup: string, option: string) => {
    const [currentState, setState] = stateMap[facetGroup];
    const itemToRemoveIndex = currentState.items.findIndex(
      (item) => item.key === option
    );
    if (itemToRemoveIndex !== -1) {
      setState({
        ...currentState,
        items: currentState.items.filter(
          (_item, index) => index !== itemToRemoveIndex
        ),
      });
    }
  };

  function generateTag(label: Filter, _onClose: (label: string) => void) {
    if (!label || !label.items || label.items.length === 0) {
      return null;
    }
    return label.items.map((tagItem: { key: string }, index: number) => (
      <div
        className={styles.tag}
        key={index}
        style={{ margin: "0 0.3em 0.3em 0" }}
      >
        <div style={{ color: "#1890ff" }}>{tagItem.key.trim()}</div>
        <button
          name="remove"
          onClick={() => handleTagClose(label.name, tagItem.key)}
        >
          <CrossIcon color={"#1890ff"} />
        </button>
      </div>
    ));
  }

  const onDelete = async () => {
    try {
      setLoading("delete");
      if (!entity) return;
      await deleteTeam(entity.id);
      message.success(t("container.entityDetail.successDeleted"));
      setLoading("");
      setEdit(false);
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const getDetails = async (id: string) => {
    try {
      const res = await getFulfiller(id);
      setFulfiller(res);
      setOpen("details");
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`${err.messages}`);
      }
    }
  };

  const onEditFulfiller = async (data: Fulfiller) => {
    try {
      setLoading("edit-diver");
      if (!entity) return;

      await editDiver(data, entity.id);
      setLoading("");
      message.success({
        content: t("container.entityDetail.successDiverUpdated"),
        className: "custom-class",
        style: {
          marginTop: "3em",
        },
      });
    } catch (err) {
      setLoading("");

      if (err instanceof APIError) {
        message.error(`${err.messages}`);
      }
    }
  };

  const onResetPasswrod = async (userName: string) => {
    try {
      await sendResetUsername(userName);
      message.success(`${t("container.users.resetSuccess")}`);
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onAddExistingUserToTeam = async (userIds: string[]) => {
    try {
      setLoading("edit-diver");

      // Use Promise.all to handle multiple asynchronous calls
      await Promise.all(
        userIds.map(async (userId) => {
          await updateTeamUser(match.params.id, userId);
        })
      );

      setLoading("");
      message.success(t("container.entityDetail.successUserAdded"));
      setModal(false);
      load(); // Reload data after successful updates
    } catch (err) {
      setLoading("");

      if (err instanceof APIError) {
        message.error(`${err.messages}`);
      }
    }
  };

  const closeAllTags = () => {
    Object.values(stateMap).forEach(([_filter, setFilter]) =>
      setFilter((prevFilter) => ({ ...prevFilter, items: [] }))
    );
  };

  const onChangeUserFromTeam = async (userId: string, team: string) => {
    try {
      await updateTeamUser(team, userId);
      message.success(t("container.entityDetail.successUserRemoved"));
      load();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.messages}`);
      }
    }
  };
  const selected: Filter[] = [];
  Object.values(stateMap).forEach(([filter]) => {
    selected.push(filter);
  });

  const optionsDiveshops = [
    {
      id: "entitiesDivers",
      label: `${t("container.entityDetail.users")}`,
      path: `/teams/${match.params.id}/users`,
      onClick: () => (document.title = "RAVENT APP :: Org :: Divers"),
    },
    {
      id: "entitiesDetail",
      label: t("container.entityDetail.detailsLabel"),
      path: `/teams/${match.params.id}`,
      onClick: () => (document.title = "RAVENT APP :: Org :: Details"),
    },
  ];

  return (
    <LayouWithSideBar>
      <DeleteModal
        value={`${t("container.events.warningDelete")} ${deletedFilter} ${t(
          "container.events.filter"
        )} `}
        open={open === "deleteFilter"}
        onCancel={() => setOpen("")}
        onDelete={() => {
          const saved = { ...savedData };
          delete saved[deletedFilter];
          setSavedData(saved);
          localStorage.setItem("userSaveFilter", JSON.stringify(saved));
          setDeletedFilter("");
          setOpen("");
        }}
      />
      <AddExistingUser
        addUser={(ids) => onAddExistingUserToTeam(ids)}
        users={users}
        open={modal}
        onClose={() => setModal(false)}
      />
      <SaveFilterModal
        open={open === "save"}
        onSave={(value) => {
          const dataToStore = {
            filters: selected,
            name: value,
            search: search,
            type: search ? "query" : "normal",
          };
          const saved = {
            ...savedData,
            [value]: dataToStore,
          };
          setSavedData(saved);
          localStorage.setItem("userSaveFilter", JSON.stringify(saved));
        }}
        onClose={() => setOpen("")}
      />

      <GridContainer columns={resize ? "0.24fr 1fr" : "3em 1fr"}>
        <div
          style={{
            backgroundColor: "#fff",
            height: "100%",
            borderLeft: "1px solid #dedede",
          }}
        >
          <SideFiltersUsers
            onDeleteFilter={(value) => {
              setOpen("deleteFilter");
              setDeletedFilter(value);
            }}
            filters={filters}
            setSize={setSize}
            savedFilter={savedFilter}
            onSaveFilter={(value) => {
              setSavedFilter(value);

              const matchingObject = savedData[value];

              if (matchingObject && savedData[value].type === "query") {
                setText(savedData[value].search || "");
                setSearch(savedData[value].search || "");
                return;
              }

              if (matchingObject && matchingObject.filters) {
                matchingObject.filters.forEach((filter) => {
                  const stateAndSetter = stateMap[filter.name];
                  if (stateAndSetter) {
                    const setFilter = stateAndSetter[1];
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      items: filter.items,
                    }));
                  }
                });
              }
            }}
            selected={selected}
            facets={fulfillers.facets}
            shrink={resize}
            onChange={(facetGroup, option) => {
              setPage(0);
              const map = stateMap;
              if (map[facetGroup]) {
                const [currentState, setState] = map[facetGroup];
                const existingIndex = currentState.items.findIndex(
                  (item) => item.key === option
                );
                let updatedItems;
                if (existingIndex !== -1) {
                  updatedItems = currentState.items.filter(
                    (item) => item.key !== option
                  );
                } else {
                  updatedItems = [
                    ...currentState.items,
                    {
                      key: option,
                      include: currentState?.items.every(
                        (item) => item.include === true
                      ),
                    },
                  ];
                }
                setState({
                  ...currentState,
                  items: updatedItems,
                });
              }
            }}
            onChangeExlusion={(facetGroup, value) => {
              const map = stateMap;
              const [currentState, setState] = map[facetGroup];
              if (currentState && currentState.items) {
                setState((prevState) => ({
                  ...prevState,
                  items: prevState.items.map((item) => ({
                    ...item,
                    include: value, // Set all items to the same value
                  })),
                }));
              }
            }}
            onChangeSelect={(facetGroup, value) => {
              const map = stateMap;
              const [currentState, setState] = map[facetGroup];
              if (currentState) {
                setState({
                  ...currentState,
                  include: value as "Any" | "All",
                });
              }
            }}
          />
        </div>
        <div className={styles.container}>
          <EntityHeader
            onChange={onChange}
            name={entity?.name || ""}
            edit={edit}
            setEdit={(value) => {
              if (entity) setMasking(entity?.maskingTemplates);
              setEdit(value);
            }}
            onEdit={() => onEdit()}
            loading={loading}
            onDelete={() => onDelete()}
            onCancel={() => setEdit(false)}
            path={`/teams/${match.params.id}/users`}
          />

          <div style={{ paddingBottom: "1em" }}>
            <FlexContainer
              padding="1em 0 0 0"
              justifyContent="flex-start"
              alignItems="center"
            >
              <div style={{ width: "80%" }}>
                <Search
                  size="large"
                  style={{ margin: "0em 0 0 0" }}
                  placeholder={t("component.orderFilters.search")}
                  onSearch={() => setSearch(text)}
                  value={text}
                  onChange={(text) => {
                    setText(text.target.value);
                  }}
                />
              </div>

              {search ? (
                <div style={{ marginLeft: "1em" }}>
                  <Button
                    theme="white"
                    onClick={() => setOpen("save")}
                    label={t("container.orders.save")}
                  />
                </div>
              ) : null}
              {text && text.length > 0 ? (
                <div
                  onClick={() => {
                    setSearch("");
                    setText("");
                  }}
                  style={{
                    marginLeft: "1em",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Clear all
                </div>
              ) : null}
            </FlexContainer>
          </div>

          <FlexContainer
            flexWrap="wrap"
            justifyContent="flex-start"
            padding="0 0 1em 0"
          >
            {venueName.items.filter((item) => item.key.trim()).length > 0 &&
              generateTag(venueName, (label) =>
                setVenueName(
                  (prevFilter: Filter): Filter => ({
                    ...prevFilter,
                    items: prevFilter.items.filter(
                      (item) => item.key !== label
                    ),
                  })
                )
              )}

            {tags.items.filter((item) => item.key.trim()).length > 0 &&
              generateTag(tags, (label) =>
                setTags((prevFilter: Filter) => ({
                  ...prevFilter,
                  items: prevFilter.items.filter((item) => item.key !== label),
                }))
              )}

            {type.items.filter((item) => item.key.trim()).length > 0 &&
              generateTag(type, (label) =>
                setType((prevFilter) => ({
                  ...prevFilter,
                  items: prevFilter.items.filter((item) => item.key !== label),
                }))
              )}

            {fulfillmentTeamName.items.filter((item) => item.key.trim())
              .length > 0 &&
              generateTag(fulfillmentTeamName, (label) =>
                setFulfillmentTeamName((prevFilter) => ({
                  ...prevFilter,
                  items: prevFilter.items.filter((item) => item.key !== label),
                }))
              )}
            {hasNonEmptyState && (
              <button
                style={{
                  cursor: "pointer",
                  textDecorationLine: "underline",
                }}
                onClick={closeAllTags}
              >
                Clear All
              </button>
            )}
          </FlexContainer>

          {!edit ? (
            <TabNavBar options={optionsDiveshops}>
              <Switch>
                <Route path={`/teams/${match.params.id}/users`}>
                  <EntityFulfillers
                    resetPassword={onResetPasswrod}
                    page={page}
                    setSearch={setSearch}
                    search={search}
                    onInviteUsers={onInviteUsers}
                    stateMap={stateMap}
                    totalFulfillers={fulfillers.count || 0}
                    total={fulfillers.count || 0}
                    hitsPerPage={records_per_page}
                    onPageChange={setPage}
                    onHitsPerPageChange={setHitsPerPage}
                    onChangeSortOrder={setSortBy}
                    teams={teams}
                    roles={roles}
                    load={load}
                    onDeleteUser={async (id) => {
                      try {
                        await deleteUser(id);
                        message.success(
                          t("container.entityDetail.successDeletedUser")
                        );
                        loadFulfillers();
                      } catch (err) {
                        if (err instanceof APIError) {
                          message.error(`${err.messages}`);
                        }
                      }
                    }}
                    onChangeUserFromTeam={onChangeUserFromTeam}
                    openAddUser={modal}
                    onClickAddAexistingUser={() => setModal(true)}
                    onDetails={getDetails}
                    diver={diver}
                    path={`/teams/${match.params.id}/users`}
                    open={open}
                    setOpen={(name) => setOpen(name)}
                    error={error}
                    divers={fulfillers.hits}
                    loading={loading}
                    entityId={entity?.id || ""}
                    onSave={async (data: Fulfiller) =>
                      await onEditFulfiller(data)
                    }
                    creationError={errorCreation}
                    onCancel={() => {
                      setEdit(false);
                      setOpen("");
                      setCreationError("");
                      history.push(`/teams/${match.params.id}/users`);
                    }}
                    onCreate={async (data) => {
                      try {
                        setLoading("create-fulfiller");

                        if (entity) await createFulfiller(data, entity.id);
                        message.success(
                          t("container.entityDetail.successDiverCreation")
                        );
                        setOpen("");
                        setLoading("");
                        history.push(`/teams/${match.params.id}/users`);
                        load();
                      } catch (err) {
                        setLoading("");

                        if (err instanceof APIError) {
                          message.error(
                            `Creation Error: ${err.messages} ${err.message}`
                          );
                        }
                      }
                    }}
                  />
                </Route>
                <Route path={`/teams/${match.params.id}`} exact>
                  {entity && <EntityDetailComponent data={entity} />}
                </Route>
              </Switch>
            </TabNavBar>
          ) : (
            <div className={styles.formContainer}>
              {entity && (
                <EntityForm
                  maskingTemplates={maskingTemplate}
                  onChangeMaskingTemplate={(data) => setMasking(data)}
                  editing
                  onChange={onChange}
                  entity={entity}
                />
              )}
            </div>
          )}
        </div>
      </GridContainer>
    </LayouWithSideBar>
  );
};

export default EntityDetail;
