const translation = {
  en: {
    import: "Import CSV File",
    locations: "Locations",
    upload: "Upload",
    drag: "Drag and drop a CSV file here",
    rows: "Rows",
    close: "Close",
    create: "Create",
    export: "Export",
    exportFile: "Export to CSV File",
    total: "Total",
    deleteLocations: "Delete Multiple Locations",
    delete: "Delete",
    noLocations:
      "No Locations are selected. Please choose the locations you wish to delete by clicking on the checkboxes. ",
  },
  es: {
    noLocations:
      "No hay ubicaciones seleccionadas. Elija las ubicaciones que desea eliminar haciendo clic en las casillas de verificación.",
    delete: "Eliminar",
    deleteLocations: "Eliminar Múltiples Lugares",
    total: "Total",
    export: "Exportar",
    locations: "Lugares",
    exportFile: "Exportar a Archivo CSV",
    close: "Cerrar",
    create: "Crear",
    rows: "Filas",
    import: "Importar Archivo CSV",
    upload: "Subir",
    drag: "Arrastra y suelta un archivo CSV aquí",
  },
  de: {},
};

export default translation;
