import get from "lodash/get";
import { Integration, KeyValueIntegration } from "../utils/types";
import fetch from "../utils/fetch";
import { getAccessToken } from "./auth";
import { isNil, omitBy } from "lodash";

export function parseKeyValueIntegration(data: unknown): KeyValueIntegration {
  return {
    key: get(data, "key", ""),
    keytype: get(data, "keytype", ""),
    value: get(data, "value", ""),
    label: get(data, "label", ""),
  };
}

export function parseIntegration(data: unknown): Integration {
  return {
    id: get(data, "_id", ""),
    created: get(data, "createdAt", ""),
    orgId: get(data, "orgId", ""),
    supplierName: get(data, "supplierName", ""),
    type: get(data, "type", ""),
    supplierReference: get(data, "supplierReference", ""),
    supplierType: get(data, "supplierType", ""),
    name: get(data, "name", ""),
    data: JSON.stringify(get(data, "data", {}), null, " "),
    active: get(data, "active", false),
    deleted: get(data, "deleted", false),
    supplierService: get(data, "supplierService", ""),
  };
}

export interface IntegrationsSearchResponse {
  data: Integration[];
  page: number;
  total: number;
}

export interface IntegrationListParams {
  page?: number;
  records_per_page?: number;
  type?: string;
  supplierType?: string;
  text?: string;
  supplierName?: string;
}

function mapSorts(data: IntegrationListParams) {
  return omitBy(
    {
      page: data.page,
      recordsPerPage: data.records_per_page,
      supplierType: data.supplierType || null,
      supplierName: data.supplierName || null,
      type: data.type || null,
      name: data.text || null,
    },
    isNil
  );
}

export async function integrationList(
  params: IntegrationListParams
): Promise<IntegrationsSearchResponse> {
  const token = await getAccessToken();
  const res = await fetch(
    "/integrations",
    token,
    "GET",
    null,
    mapSorts(params)
  );

  return {
    data: res.body ? res.body.hits.map(parseIntegration) : [],
    page: get(res.body, "page", 0),
    total: get(res.body, "count", 0),
  };
}

export async function getIntegration(id: string): Promise<Integration> {
  const token = await getAccessToken();
  const res = await fetch(`/integrations/${id}`, token);
  const data = parseIntegration(res.body);
  return data;
}

export interface CreateIntegration {
  name: string;
  supplierName: string;
  supplierType: string;
  data: Record<string, string>;
}

export async function createIntegration(
  data: Integration
): Promise<Integration> {
  const token = await getAccessToken();
  const body = {
    name: data.name,
    type: data.type,
    supplierType: data.supplierType,
    supplierName: data.supplierName,
    supplierService: data.supplierService,
    data: JSON.parse(data.data as string),
  };
  const res = await fetch(`/integrations`, token, "POST", body);
  return parseIntegration(res.body);
}

export async function updateIntegration(
  data: Integration
): Promise<Integration> {
  const token = await getAccessToken();
  const body = {
    name: data.name,
    type: data.type,
    active: data.active,
    supplierType: data.supplierType,
    supplierName: data.supplierName,
    supplierService: data.supplierService,
    data: JSON.parse(data.data as string),
  };
  const res = await fetch(`/integrations/${data.id}`, token, "PATCH", body);
  return parseIntegration(res.body);
}

export async function removeIntegration(id: string): Promise<void> {
  const token = await getAccessToken();
  await fetch(`/integrations/${id}`, token, "DELETE");
}

export const defaultIntegration: Integration = {
  created: null,
  supplierName: "",
  supplierReference: "",
  supplierType: "",
  type: "",
  id: "",
  orgId: "",
  name: "",
  data: "{}",
  active: false,
  deleted: false,
  supplierService: "",
};
