import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import { RootState } from "../../store";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { BackLink } from "../../components/BackLink";
import { ButtonsContainer } from "../../components/ButtonsContainer";
import { Button } from "../../components/Button";
import { checkVenue, createVenue } from "../../services/venues";
import VenueForm from "../../components/VenueForm";
import { defaultVenue } from "../../services/venues";
import styles from "./styles.module.css";
import message from "antd/lib/message";
import { Venue } from "@innomius/ravent-typescript-types";
import { fopSetsList } from "../../store/fopSets";
import APIError from "../../utils/APIError";
import CustomError from "../../utils/CustomError";
import get from "lodash/get";
// import VenueSecret from "../../components/VenueStates/VenueSecret";
import { useTranslation } from "react-i18next";
import { InputError } from "../../components/Inputs/InputError/InputError";
import { EditableTitle } from "../../components/EditableTitle";
import { GridContainer } from "../../components/GridContainer/GridContainer";

interface Props extends RouteComponentProps {}

const CreateVenue: React.FC<Props> = ({ history }) => {
  const dispatch = useDispatch();

  const auth = useSelector((state: RootState) => state.auth);
  const [venue, setVenue] = useState<Venue>(defaultVenue);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<CustomError>();
  const { t } = useTranslation();

  const fopSets = useSelector((state: RootState) => state.fopSets);

  const onChange = <P extends keyof Venue>(prop: P, value: Venue[P]) => {
    setVenue({ ...venue, [prop]: value });
  };

  const load = useCallback(async () => {
    dispatch(fopSetsList({}));
  }, [dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    document.title = "RAVENT APP :: Create Venue";
  });

  const onCreate = async () => {
    setError(undefined);
    try {
      checkVenue(venue);
    } catch (err) {
      if (err instanceof CustomError) {
        setError(err);
      }
      return;
    }

    try {
      setLoading(true);
      await createVenue(venue);
      setLoading(false);
      message.success(t("container.createVenue.successCreation"));
      setVenue(defaultVenue);
      setError(undefined);
      history.push("/venues");
    } catch (err) {
      setLoading(false);
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };
  if (!auth.loading && !auth.user) {
    return <Redirect from="*" to="/" />;
  }
  return (
    <LayouWithSideBar>
      <div className={styles.container}>
        <FlexContainer justifyContent="space-between">
          <BackLink
            label={t("container.createVenue.back")}
            onClick={() => history.push("/venues")}
          />
          <ButtonsContainer>
            <Button
              label={t("container.createVenue.cancel")}
              theme="white"
              onClick={() => history.push("/venues")}
            />
            <Button
              label={loading ? "..." : `${t("container.createVenue.save")}`}
              onClick={() => onCreate()}
            />
          </ButtonsContainer>
        </FlexContainer>
        <EditableTitle
          placeholder={t("container.createVenue.name")}
          padding="1em 0 0em 0"
          value={venue.name}
          onChange={(value) => onChange("name", value)}
          error={!venue.name && get(error, "errors.venue-name", "")}
        />
        <InputError
          error={!venue.name && get(error, "errors.venue-name", "")}
        />
        <GridContainer gap="2em" columns="2fr">
          <VenueForm
            edit={false}
            fopSets={fopSets.list.data}
            data={venue}
            onChange={onChange}
            error={error}
          />
          {/* <VenueSecret onChange={onChange} secret={venue.notificationsSecret} /> */}
        </GridContainer>
      </div>
    </LayouWithSideBar>
  );
};

export default CreateVenue;
