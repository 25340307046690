import React from "react";

import produce from "immer";
import { ModifierOption } from "@innomius/ravent-typescript-types";
import deleteIcon from "../../assets/delete.svg";
import styles from "./styles";
import {
  addSubstractOptions,
  amountPercentageOptions,
} from "../../constants/offers";
import { useTranslation } from "react-i18next";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputText } from "../Inputs/InputText";
import { InputSelect } from "../Inputs/InputSelect";
import InputRadio from "../Inputs/InputRadio";

interface Props {
  data: ModifierOption[];
  onChange: (data: ModifierOption[]) => void;
}

const ModifierValueForm: React.FC<Props> = ({ data, onChange }) => {
  const { t } = useTranslation();

  return (
    <div>
      {data.map((property, index) => (
        <div key={`property-${index}`}>
          <GridContainer
            justifyContent="flex-start"
            alignItems="center"
            gap="1em"
            columns="2fr 1fr 0.5fr 0.7fr 2em 1.5em"
          >
            <InputText
              id={`optionName${index}`}
              value={property.name}
              onChange={(value) =>
                onChange(
                  produce(data, (draft) => {
                    draft[index].name = value;
                  })
                )
              }
              placeholder={t("component.offerVariantForm.valueNamePlaceHolder")}
              label={t("component.offerVariantForm.valueName")}
            />
            <InputSelect
              id={`addSubstract${index}`}
              value={property.operation}
              onChange={(value) =>
                onChange(
                  produce(data, (draft) => {
                    draft[index].operation = value;
                  })
                )
              }
              placeholder={t("component.offerVariantForm.placeHolderOperation")}
              options={addSubstractOptions}
            />
            <InputSelect
              id={`percentage${index}`}
              value={property.percentage ? "%" : "$"}
              onChange={(value) =>
                onChange(
                  produce(data, (draft) => {
                    draft[index].percentage = value === "%" ? true : false;
                  })
                )
              }
              options={amountPercentageOptions}
            />
            <InputText
              id={`amount${index}`}
              value={property.amount}
              onChange={(value) =>
                onChange(
                  produce(data, (draft) => {
                    draft[index].amount = parseInt(value);
                  })
                )
              }
              label={t("component.offerVariantForm.amount")}
            />
            <InputRadio
              name="default"
              id={`default${index}`}
              value={`default${index}`}
              checked={property.default}
              onChange={(value) =>
                onChange(
                  produce(data, (draft) => {
                    draft[index].default =
                      value === `default${index}` ? true : false;
                  })
                )
              }
            />
            <div className={styles.iconContainer}>
              <img
                src={deleteIcon}
                alt="quit"
                className={styles.iconDelete}
                onClick={() =>
                  onChange(
                    produce(data, (draft) => {
                      draft.splice(index, 1);
                    })
                  )
                }
              />
            </div>
          </GridContainer>
        </div>
      ))}
      <div
        onClick={() =>
          onChange(
            produce(data, (draft) => {
              draft.push({
                name: "",
                finalPrice: {
                  value: 0,
                  currency: "$",
                },
                amount: 0,
                operation: "",
                percentage: false,
                default: false,
                enabled: false,
              });
            })
          )
        }
        className={styles.blue}
      >
        {t("component.offerVariantForm.addValue")}
      </div>
    </div>
  );
};

export default ModifierValueForm;
