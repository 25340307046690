import React, { useState } from "react";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { Action } from "@innomius/ravent-typescript-types";
import DeleteModal from "../DeleteModal";
import Editor from "@monaco-editor/react";
import { GridContainer } from "../GridContainer/GridContainer";
import { SectionProperty } from "../Section/SectionProperty";

interface Props {
  data: Action;
  onDeleteAction: (id: string) => void;
}

const ActionDetail: React.FC<Props> = ({ data, onDeleteAction }) => {
  const [modal, setModal] = useState<string>("");

  const { t } = useTranslation();

  return (
    <div>
      <DeleteModal
        open={modal === "remove" || modal === "delete"}
        onCancel={() => setModal("")}
        onDelete={() => onDeleteAction(data.id)}
        value={
          modal === "remove"
            ? "Are you sure you want to remove the scheduling of this action?"
            : "Are you sure you want to delete this action?"
        }
      />
      <div className={styles.itemsWrapper}>
        <div>
          <GridContainer padding="0em 0 0em 0" gap="1em" columns="1fr">
            <SectionProperty
              value={data.url}
              label={t("component.addIntegration.flowUrl")}
            />
          </GridContainer>
          <SectionProperty
            value={data.description || "-"}
            label={t("component.addIntegration.description") || "-"}
          />
          <div style={{ marginTop: "1em" }}>
            <SectionProperty
              value={data.dataCollectionUrl || "-"}
              label={t("component.orderActionList.dataCollectionUrl")}
            />
          </div>

          <div style={{ margin: "1em 0 0 0" }}>
            <SectionProperty
              value={data.orderOnly ? "Enabled" : "Disabled"}
              label={t("component.orderActionList.orderOnly") || "-"}
            />
          </div>
          <SectionProperty
            value={data.manualTrigger ? "Enabled" : "Disabled"}
            label={t("component.orderActionList.manualTrigger") || "-"}
          />

          {data.notificationBody ? (
            <Editor
              className={styles.editorBox}
              height="100%"
              // theme="vs-dark"
              defaultLanguage="json"
              value={`${JSON.stringify(data.notificationBody)}`}
              defaultValue="{}"
              options={{
                readOnly: true,
                selectOnLineNumbers: true,
                // minimap: { enabled: false },
                scrollbar: {
                  vertical: "hidden",
                },
                overviewRulerLanes: 0,
                hideCursorInOverviewRuler: true,
                overviewRulerBorder: false,
                foldingHighlight: false,
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ActionDetail;
