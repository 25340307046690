import React from "react";

import { ReactComponent as Warning } from "../../assets/warning.svg";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { GridContainer } from "../GridContainer/GridContainer";
import { Button } from "../Button";

interface Props {
  onDelete: (e: any | null) => void;
  onClose: (e: any | null) => void;
  value?: string;
  loading?: boolean;
  children?: React.ReactNode;
}

const DeletePopUp: React.FC<Props> = ({
  onDelete,
  onClose,
  value,
  loading,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <GridContainer alignItems="center" columns="2em 1fr">
      <Warning className={styles.warning} />
      {!value ? <p>{t("component.deletePopUp.warning")}</p> : <p>{value}</p>}

      <div />
      {children}

      {children ? (
        <>
          <div />

          <FlexContainer padding="1em 0 0 0" justifyContent="flex-end">
            <Button
              buttonClassname={styles.button}
              size="small"
              label={t("component.deletePopUp.cancel")}
              theme="white"
              onClick={onClose}
            />
            <Button
              buttonClassname={styles.buttonRight}
              size="small"
              label={loading ? "..." : t("component.deletePopUp.ok")}
              onClick={(e) => {
                if (loading) {
                  return;
                }
                onDelete(e);
              }}
            />
          </FlexContainer>
        </>
      ) : (
        <FlexContainer padding="1em 0 0 0" justifyContent="flex-end">
          <Button
            buttonClassname={styles.button}
            size="small"
            label={t("component.deletePopUp.cancel")}
            theme="white"
            onClick={onClose}
          />
          <Button
            buttonClassname={styles.buttonRight}
            size="small"
            label={loading ? "..." : t("component.deletePopUp.ok")}
            onClick={(e) => {
              if (loading) {
                return;
              }
              onDelete(e);
            }}
          />
        </FlexContainer>
      )}
    </GridContainer>
  );
};

export default DeletePopUp;
