import React from "react";
import Text from "../../Text";
import styles from "./styles";
import { FlexContainer } from "../../FlexContainer/FlexContainer";
import { Button } from "../../Button";

interface Props {
  button: string;
  text: string;
  onClick: () => void;
  state?: string;
  diveshop?: boolean;
}

const NotAssignedBox: React.FC<Props> = ({
  button,
  text,
  onClick,
  state,
  diveshop,
}) => {
  return (
    <div className={styles.card}>
      <FlexContainer justifyContent="space-between">
        <Text text={text} />
        <Button
          containerClassname={styles.button}
          onClick={onClick}
          label={button}
          disable={
            diveshop
              ? state === "cancelled" || state === 'expired'
              : state
              ? state !== "un-paid"
              : false
          }
        />
      </FlexContainer>
    </div>
  );
};

export default NotAssignedBox;
