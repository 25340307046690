import APIError from "../utils/APIError";

const key = "GNO58M1P";
const w3wURL = "https://api.what3words.com/v3";

export interface Location {
  lat: number;
  lng: number;
}

interface Suggestion {
  country: string;
  language: string;
  nearestPlace: string;
  rank: number;
  words: string;
}

interface Square {
  southwest: Location;
  northeast: Location;
}

export interface ThreeWordAddress {
  coordinates: Location;
  country: string;
  language: string;
  map: string;
  nearestPlace: string;
  square: Square;
  words: string;
  error?: object;
  name: string;
}

export interface CoordinatesToW3WResponse {
  country: string;
  square: {
    southwest: {
      lng: number;
      lat: 51.520833;
    };
    northeast: {
      lng: number;
      lat: number;
    };
  };
  nearestPlace: string;
  coordinates: {
    lng: number;
    lat: number;
  };
  words: string;
  language: string;
  map: string;
}

export async function w3wCoordinatesTo3wa(
  location: Location
): Promise<ThreeWordAddress> {
  const coordinates = `${location.lat},${location.lng}`;
  const res = await fetch(
    `${w3wURL}/convert-to-3wa?key=${key}&coordinates=${coordinates}`
  );
  const json = await res.json();
  return json;
}

export async function w3wToCoordinates(w3w: string) {
  try {
    const res = await fetch(
      `${w3wURL}/convert-to-coordinates?words=${w3w}&key=${key}`
    );
    if (res.ok && res.status !== 204) {
      const json = await res.json();
      return json;
    }

    if (res.status >= 400) {
      const json = await res.json();
      throw new APIError(
        json.error.message,
        json.error.code,
        res.status.toString()
      );
    }
  } catch (err) {
    throw err;
  }
}

export async function addressToCoordinates(address: string) {
  try {
    const res = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyBnUmMfyn4yxmKUBh-xP8U9k7GYmMp-oyo`
    );
    if (res.ok && res.status !== 204) {
      const json = await res.json();
      return json;
    }

    if (res.status >= 400) {
      const json = await res.json();
      throw new APIError(
        json.error.message,
        json.error.code,
        res.status.toString()
      );
    }
  } catch (err) {
    alert(err);
    throw err;
  }
}

export async function coordinatesToW3w(
  lat: number,
  lng: number
): Promise<ThreeWordAddress> {
  const res = await fetch(
    `${w3wURL}/convert-to-3wa?coordinates=${lat},${lng}&key=${key}`
  );
  const json = await res.json();
  return json;
}

export async function w3wAutosuggest(input: string): Promise<Suggestion[]> {
  const res = await fetch(`${w3wURL}/autosuggest?key=${key}&input=${input}`);
  const json = await res.json();
  return json;
}

export async function w3wGetGridSection(
  x0: string,
  x1: string,
  y0: string,
  y1: string,
  format: "json" | "geojson"
): Promise<Suggestion[]> {
  const res = await fetch(
    `${w3wURL}/grid-section?key=${key}&bounding-box=${x0},${x1},${y0},${y1}&format=${format}`
  );
  const json = await res.json();
  return json;
}

interface AutoSuggestResult {}

export async function mapAutosugges(
  address: string
): Promise<AutoSuggestResult> {
  const res = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?
  address=${address}
  &language=en
  &key=AIzaSyBnUmMfyn4yxmKUBh-xP8U9k7GYmMp-oyo`);
  const json = await res.json();
  return json;
}

export async function placeAutoComplete(
  address: string
): Promise<AutoSuggestResult> {
  const res = await fetch(`
  https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${address}&key=AIzaSyBnUmMfyn4yxmKUBh-xP8U9k7GYmMp-oyo`);
  const json = await res.json();
  return json;
}

// w3wCoordinatesTo3wa({ lat: "19.4343056", lng: "-99.1894293" });
// w3wAutosuggest("film.crunchy.spirit");

export const defaultW3W = {
  name: "",
  country: "MX",
  square: {
    southwest: {
      lng: -99.167668,
      lat: 19.427016,
    },
    northeast: {
      lng: -99.167639,
      lat: 19.427043,
    },
  },
  nearestPlace: "Cuauhtémoc, Mexico City",
  coordinates: {
    lng: -99.167653,
    lat: 19.427029,
  },
  words: "restrict.firmly.move",
  language: "en",
  map: "https://w3w.co/restrict.firmly.move",
};
