import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles";
import timerIcon from "../../assets/unpaidOrder.svg";
import chevron from "../../assets/chevron-down-blue.svg";
import Item from "./Item";
import { numberFormat } from "../../utils/format";
import { RouteComponentProps, useLocation } from "react-router-dom";
import qs from "qs";
import { getPublicReq } from "../../services/payments";
import APIError from "../../utils/APIError";
import { PublicPaymentRequestResponse } from "../../utils/types";
import ErrorBox from "../../components/ErrorBox";
import { formatDate } from "../../utils/times";
import { Loading } from "../../components/Loading";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";

interface RouteParams {
  id: string;
}

interface Props extends RouteComponentProps<RouteParams> {}

const PaymentDetail: React.FC<Props> = ({ match }) => {
  const [error, setError] = useState("");
  const [data, setData] = useState<PublicPaymentRequestResponse | null>(null);
  const [loading, setLoading] = useState("");

  function useQuery() {
    const { search } = useLocation();
    return search;
  }

  const query = useQuery();
  const token = qs.parse(query.substring(1)).publicToken as string;

  const load = useCallback(async () => {
    try {
      setLoading("detail");
      const preq = await getPublicReq(match.params.id, token);
      setData(preq);
      setLoading("");
    } catch (err) {
      if (err instanceof APIError) {
        setError(err.message);
      }
    }
  }, [match.params.id, token]);

  useEffect(() => {
    load();
  }, [match.params.id, token, load]);

  if (loading === "detail" && !error) {
    return <Loading />;
  }

  if (!data) {
    return <ErrorBox error={error} />;
  }

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.grid}>
          <div className={styles.orgDetails}>
            <div className={styles.titleWrapper}>
              <FlexContainer
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <img src={timerIcon} className={styles.time} alt="" />
                <FlexContainer
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <div
                    className={styles.textXLarge}
                  >{`Order #${data.order.shortId}`}</div>
                  <div className={styles.textItem}>
                    {`${data.order.paymentState} · Expires on: ${
                      data.paymentRequest.expiration
                        ? formatDate(data.paymentRequest.expiration as Date)
                        : "Not Provided"
                    }`}
                  </div>
                </FlexContainer>
              </FlexContainer>
            </div>
            <div className={styles.box}>
              <FlexContainer justifyContent="space-between" alignItems="center">
                <div
                  style={{ paddingBottom: "0.5em" }}
                  className={styles.totalBig}
                >
                  Organization Details
                </div>
                <img src={chevron} className={styles.chevron} alt="" />
              </FlexContainer>
              <div className={styles.totalBigM}>{data.organization.name}</div>
              <div
                style={{ padding: "2em 0 0.5em 0" }}
                className={styles.totalBig}
              >
                Contact Information
              </div>
              {data.organization.address?.type === "w3w" ? (
                <>
                  <div className={styles.textItem}>
                    {data.organization.address?.w3w}
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.textItem}>
                    {data.organization.address?.line1}
                  </div>
                  <div className={styles.textItem}>
                    {`${data.organization.address?.zipCode} ${data.organization.address?.city} ${data.organization.address?.country}`}
                  </div>
                </>
              )}
              <div className={styles.textItem}> {data.organization.email}</div>
              <div className={styles.textItem}> {data.organization.phone}</div>
            </div>
            <div className={styles.buttonContainer}>
              <a
                href={`${data.paymentRequest.link}`}
                target="_blank"
                rel="noreferrer"
                className={styles.boxButton}
              >
                <div className={styles.button}>
                  {`PAY  ${data.order.currency}${numberFormat(
                    data.paymentRequest.amount
                  )}`}
                </div>
              </a>
            </div>
          </div>
          <div className={styles.orderDetails}>
            <div
              style={{
                marginBottom: "1em",
                paddingBottom: "1.5em",
                borderBottom: "1px solid #dedede",
              }}
            >
              <div
                style={{ paddingBottom: "0.5em" }}
                className={styles.totalBig}
              >
                Order Details
              </div>
              <div className={styles.totalBigM}>{data.order.description}</div>
            </div>
            <div
              style={{
                borderBottom: "1px solid #dedede",
                paddingBottom: "1em",
              }}
              className={styles.totalContainer}
            >
              <div className={styles.total}>{"Total"}</div>
              <div className={styles.totalBig}>{`${numberFormat(
                data.order.total
              )} ${data.order.currency}`}</div>
            </div>

            <div
              style={{
                borderBottom: "1px solid #dedede",
                paddingBottom: "1em",
                marginTop: "1em",
              }}
              className={styles.totalContainer}
            >
              <div
                style={{
                  width: "50%",
                  borderRight: "1px solid #dedede",
                  paddingRight: "1em",
                }}
              >
                <FlexContainer
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  flexDirection="column"
                >
                  <div className={styles.total}>{"Paid"}</div>
                  <div className={styles.totalBig}>{`${numberFormat(
                    data.order.paid
                  )} ${data.order.currency}`}</div>
                </FlexContainer>
              </div>
              <div style={{ width: "50%", paddingLeft: "1em" }}>
                <FlexContainer
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  flexDirection="column"
                >
                  <div className={styles.total}>{"Due"}</div>
                  <div className={styles.totalBig}>{`${numberFormat(
                    data.order.due
                  )} ${data.order.currency}`}</div>
                </FlexContainer>
              </div>
            </div>

            <div
              style={{ marginTop: "1em", paddingBottom: "0.2em" }}
              className={styles.totalBig}
            >
              {`Order Lines (${data.order.orderlines.length})`}
            </div>
            {data.order.orderlines.map((item, index) => (
              <Item
                discounts={item.discounts}
                surcharges={item.surcharges}
                currency={data.order.currency}
                totalAmount={item.total}
                taxes={item.taxes}
                description={item.description}
                amount={item.subTotal}
                number={index + 1}
              />
            ))}
            <div className={styles.line} />
            <FlexContainer
              padding={"0 0 1em 0"}
              justifyContent="space-between"
              alignItems="center"
            >
              <div className={styles.textGray}>{"Subtotal"}</div>
              <div className={styles.textItem}>{`${numberFormat(
                data.order.subTotal
              )} ${data.order.currency}`}</div>
            </FlexContainer>
            <FlexContainer
              padding={"0 0 1em 0"}
              justifyContent="space-between"
              alignItems="center"
            >
              <div className={styles.textGray}>{"Discounts"}</div>
              <div className={styles.textItem}>{`${numberFormat(
                data.order.totalDiscount
              )} ${data.order.currency}`}</div>
            </FlexContainer>
            <FlexContainer
              padding={"0 0 1em 0"}
              justifyContent="space-between"
              alignItems="center"
            >
              <div className={styles.textGray}>{"Surcharges"}</div>
              <div className={styles.textItem}>{`${numberFormat(
                data.order.totalSurcharge
              )}`}</div>
            </FlexContainer>
            <FlexContainer
              padding={"0 0 1em 0"}
              justifyContent="space-between"
              alignItems="center"
            >
              <div className={styles.textGray}>{"Taxes"}</div>
              <div className={styles.textItem}>{`${numberFormat(
                data.order.totalDiscount
              )} ${data.order.currency}`}</div>
            </FlexContainer>
          </div>
        </div>
        <a
          href={`${data.paymentRequest.link}`}
          target="_blank"
          rel="noreferrer"
        >
          <div className={styles.payMobile}>
            <div className={styles.button}>{`PAY ${numberFormat(
              data.paymentRequest.amount
            )} ${data.order.currency}`}</div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default PaymentDetail;
