
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import IntegrationList from "../IntegrationList";
import styles from "./styles.module.css";
import IntegrationForm from "../IntegrationForm";

import { Integration } from "../../utils/types";
import { defaultIntegration } from "../../services/integrations";
import { integrationsOptions } from "../../constants/integrations";
import IntegrationFilters from "../IntegrationFilters";
import IntegrationDetail from "../IntegrationDetail";
import { useTranslation } from "react-i18next";
import { Card } from "../Card/Card";
import { SideMenu } from "../SideMenu/SideMenu";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { Button } from "../Button";
import { EmptyList } from "../List/EmptyList";

interface Props {
  onGetIntegration: (id: string) => void;
  loading: string;
  onChange: <P extends keyof Integration>(
    prop: P,
    value: Integration[P]
  ) => void;
  onCreate: () => void;
  load: () => void;
  onRemove: (id: string) => void;
  onUpdate: (data: Integration) => void;
  setOpen: (value: string) => void;
  open: string;
  integration: Integration;
  integrations: Integration[];
  total: number;
  supplierName: string;
  supplierType: string;
  type: string;
  text: string;
  error: string;
  listLoading: boolean;
  setIntegration: (data: Integration) => void;
  page: number;
  records_per_page: number;
  setPage: (number: number) => void;
  setHitsPerPage: (value: number) => void;
  setSupplierName: (value: string) => void;
  setSupplierType: (value: string) => void;
  setText: (value: string) => void;
  setType: (value: string) => void;
}

const OrgIntegrations: React.FC<Props> = ({
  onGetIntegration,
  loading,
  onChange,
  onCreate,
  onRemove,
  load,
  onUpdate,
  setOpen,
  open,
  integration,
  integrations,
  total,
  setIntegration,
  page,
  records_per_page,
  setHitsPerPage,
  setPage,
  listLoading,
  error,
  setSupplierName,
  setSupplierType,
  supplierName,
  supplierType,
  text,
  setText,
  type,
  setType,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  return (
    <div>
      <SideMenu
        width="55%"
        open={open === "add" || location.pathname === "/integrations/orgs/new"}
        onClose={() => {
          history.push("/integrations/orgs");
          setOpen("");
        }}
      >
        <div className={styles.containerSideMenu}>
          <IntegrationForm
            onUpdate={() => {}}
            loading={loading === "create" || loading === "edit"}
            integrationsOptions={integrationsOptions}
            onChange={onChange}
            data={integration}
            onSave={() => onCreate()}
            onCancel={() => {
              history.push("/integrations/orgs");
              setOpen("");
            }}
          />
        </div>
      </SideMenu>
      <SideMenu
        width="55%"
        open={open === "details" || open === "edit"}
        onClose={() => {
          setOpen("");
          history.push("/integrations/orgs");
        }}
      >
        <div className={styles.containerSideMenu}>
          {open === "edit" ? (
            <IntegrationForm
              data={integration}
              loading={loading === "edit"}
              integrationsOptions={integrationsOptions}
              onChange={onChange}
              edit
              onSave={() => {}}
              onUpdate={(data) => {
                onUpdate(data);
                setOpen("details");
              }}
              onCancel={() => {
                setOpen("details");
              }}
            />
          ) : (
            <IntegrationDetail
              onDelete={(id) => {
                onRemove(id);
                setOpen("");
              }}
              loading={loading}
              name={integration.name}
              onEdit={() => setOpen("edit")}
              supplierService={integration.supplierService}
              type={integration.type}
              id={integration.id}
              active={integration.active}
              supplierReference={integration.supplierReference}
              supplierType={integration.supplierType}
              supplierName={integration.supplierName}
              jsonData={integration.data as string}
            />
          )}
        </div>
      </SideMenu>
      <Card>
        <div className={styles.container}>
          <FlexContainer justifyContent="space-between">
            <ContainerTitle
              padding="1em 0 1em 0"
              size="medium"
              label={t("container.integrations.integrationsLabel")}
              count={total.toString()}
            />
            <Button
              label={t("container.integrations.addIntegration")}
              onClick={() => {
                setIntegration(defaultIntegration);
                setOpen("add");
                history.push("/integrations/orgs/new");
              }}
            />
          </FlexContainer>

          {integrations.length ? (
            <>
              <IntegrationFilters
                onChangeText={(value) => setText(value)}
                onChangeSupplierType={(value) => setSupplierType(value)}
                onChangeType={(value) => setType(value)}
                onChangeSupplierName={(value) => setSupplierName(value)}
                supplierName={supplierName}
                supplierType={supplierType}
                type={type}
                text={text}
                load={load}
              />
              <IntegrationList
                onOpen={(id) => onGetIntegration(id)}
                onRemove={(id) => onRemove(id)}
                onUpdate={(data) => onUpdate(data)}
                error={error}
                loading={listLoading}
                sortable
                loadingForm={loading === "details"}
                data={integrations}
                page={page}
                total={total}
                hitsPerPage={records_per_page}
                onPageChange={setPage}
                onHitsPerPageChange={setHitsPerPage}
                pagination
              />
            </>
          ) : (
            <EmptyList
              instructions=""
              text={t("container.integrations.noIntegrations")}
            />
          )}
        </div>
      </Card>
    </div>
  );
};

export default OrgIntegrations;
