import React, { useCallback, useEffect, useState } from "react";
import LayouWithSideBar from "../../components/LayoutWithSideBar";

import styles from "./styles.module.css";
import {
  OverviewCurrency,
  getCurrencies,
  getOrderStateSummary,
} from "../../services/orders";
import APIError from "../../utils/APIError";
import DatePicker from "antd/lib/date-picker";
import { EventPeriod, OrderStateSummaryCount } from "../../utils/types";
import InputTextDropdown from "../../components/Inputs/InputTextDropdown";
import { timezones } from "../../constants/timezones";
import OrderStatesSummary from "../../components/OrderStatesSummary";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import secureLocalStorage from "react-secure-storage";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { venueList } from "../../store/venues";
import TabNavBar from "../../components/TabNavBar";
import { Route, useHistory, useLocation } from "react-router-dom";
import { eventTeamOverview } from "../../store/teamsSummary";
import TeamOverviewC from "../../components/TeamOverview";
import EventOverviewC from "../../components/EventOverview";
import { eventsByPeriod } from "../../services/events";
import { Spin } from "antd";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { ContainerTitle } from "../../components/ContainerTitle";
import { Card } from "../../components/Card/Card";
import { EmptyList } from "../../components/List/EmptyList";

const orderStatesData = [
  { id: "created", count: 0, total: 0, countPercent: 0 },
  { id: "cancelled", count: 0, total: 0, countPercent: 0 },
  { id: "fulfilled", count: 0, total: 0, countPercent: 0 },
];

const paymentStatesData = [
  { id: "paid", count: 0, total: 0, countPercent: 0 },
  { id: "unpaid", count: 0, total: 0, countPercent: 0 },
  { id: "partial", count: 0, total: 0, countPercent: 0 },
  { id: "expired", count: 0, total: 0, countPercent: 0 },
  { id: "refunded", count: 0, total: 0, countPercent: 0 },
];

const Dashboard: React.FC = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState("orderStates");
  const dispatch = useDispatch();

  const [currencies, setCurrencies] = useState<OverviewCurrency[]>([]);

  const teams = useSelector((state: RootState) => state.teamsSummary);
  const [sort, setSort] = useState("");

  const [from, setFrom] = useState<string>(
    localStorage.getItem("orderStatesFrom") ||
      dayjs(new Date()).endOf("months").format(`YYYY-MM-01`)
  );
  const [to, setTo] = useState<string>(
    localStorage.getItem("orderStatesTo") ||
      dayjs(new Date()).endOf("month").format(`YYYY-MM-DD`)
  );
  const [orderStates, setOrderStates] =
    useState<OrderStateSummaryCount[]>(orderStatesData);
  const [timezone, setTimeZone] = useState<string>(
    localStorage.getItem("timeZone") ||
      Intl.DateTimeFormat().resolvedOptions().timeZone ||
      ""
  );
  const [count, setCount] = useState<string>("events");

  const [paymentStates, setOrderPaymentStates] =
    useState<OrderStateSummaryCount[]>(paymentStatesData);

  const [stateIn, setStateIn] = useState<string>("created,fulfilled");
  const [currency, setCurrency] = useState<string>("MXN");

  const [venueId, setVenueId] = useState(
    (secureLocalStorage.getItem("venueId") as string) || ""
  );

  const { profile } = useSelector((state: RootState) => state.profile);

  const venues = useSelector((state: RootState) => state.venues);

  const [period, setPeriod] = useState<EventPeriod>();
  const [periodLoading, setLoadingP] = useState(false);
  const [errorPeriod, setPeriodError] = useState("");
  const [time, setTime] = useState("day");
  const [year, setYear] = useState("2025");

  const loadVenues = useCallback(async () => {
    dispatch(venueList({}));
  }, [dispatch]);

  useEffect(() => {
    loadVenues();
  }, [loadVenues]);

  const loadTeams = useCallback(async () => {
    dispatch(
      eventTeamOverview({
        venue_id: venueId,
        rowsField: "fulfillmentTeamName",
        columnsField: "state",
        rowsFieldId: "fulfillmentTeamId",
        timezone: timezone,
        from: from,
        to: to,
        sort: sort || "Total:desc",
      })
    );
  }, [dispatch, venueId, timezone, from, to, sort]);

  useEffect(() => {
    loadTeams();
  }, [loadTeams]);

  const loadCurrencies = useCallback(async () => {
    try {
      const res = await getCurrencies();
      setCurrencies(res);
      const first = res[0];
      setCurrency(first.currency);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    loadCurrencies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadPeriod = useCallback(async () => {
    try {
      setLoadingP(true);
      const res = await eventsByPeriod({
        timePeriod: time,
        dateField: "datetime",
        year: year,
        countField: count,
      });

      setPeriod(res);
      setLoadingP(false);
    } catch (err) {
      setLoadingP(false);
      if (err instanceof APIError) {
        setPeriodError(`${err.messages} ${err.message}`);
      }
    }
  }, [time, year, count]);

  useEffect(() => {
    loadPeriod();
  }, [loadPeriod]);

  const load = useCallback(async () => {
    setError("");

    if (profile && profile.adminRole) {
      try {
        setLoading("orderStates");
        const res = await getOrderStateSummary({
          groupingField: "state",
          from,
          to,
          stateIn,
          timezone,
          currency,
          venueId,
        });
        const results = orderStatesData.filter(
          ({ id: id1 }) => !res.state.some(({ id: id2 }) => id2 === id1)
        );
        const merge = [...res.state, ...results];
        setOrderStates(merge);
        const resultsP = paymentStatesData.filter(
          ({ id: id1 }) => !res.paymentState.some(({ id: id2 }) => id2 === id1)
        );
        const mergeP = [...res.paymentState, ...resultsP];
        setOrderPaymentStates(mergeP);
        setLoading("");
      } catch (err) {
        setLoading("");
        if (err instanceof APIError) {
          setError(err.message);
        }
      }
    }
  }, [from, to, timezone, stateIn, venueId, currency, profile]);

  useEffect(() => {
    document.title = "RAVENT APP :: Overview";
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  const venueArray = [
    ...venues.list.data.map((item) => ({ label: item.name, value: item.id })),
    { label: "all", value: "" },
  ];

  const finanzOption = {
    id: "ordersDashboard",
    label: `${t("container.dashboard.orders")}`,
    path: `/overview`,
    onClick: () => (document.title = "RAVENT APP :: Overview :: Orders"),
  };

  const teamOption = {
    id: "teamsDashboard",
    label: `${t("container.dashboard.teams")}`,
    path: `/overview/teams`,
    onClick: () => (document.title = "RAVENT APP :: Overview :: Teams"),
  };

  const eventOption = {
    id: "eventDashboard",
    label: `${t("container.dashboard.events")}`,
    path: `/overview/events`,
    onClick: () => {
      document.title = "RAVENT APP :: Overview :: Events";
      history.push("/overview/events?view=daily&year=2004");
    },
  };

  return (
    <LayouWithSideBar>
      <div className={styles.container}>
        <FlexContainer
          padding="0 0 0em 0"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <ContainerTitle
            size="medium"
            label={t("container.dashboard.dashboard")}
          />
          <FlexContainer
            flexDirection="column"
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <FlexContainer justifyContent="flex-start" alignItems="flex-end">
              {!location.pathname.includes("/events") ? (
                <DatePicker.RangePicker
                  style={{ fontFamily: "Lato", fontSize: "0.1em" }}
                  className={styles.calendar}
                  format="YYYY-MM-DD"
                  value={[from ? dayjs(from) : null, to ? dayjs(to) : null]}
                  onCalendarChange={async (date, dateString) => {
                    setFrom(dateString[0]);
                    setTo(dateString[0]);
                    localStorage.setItem("orderStatesFrom", dateString[0]);
                    setTo(dateString[1]);
                    localStorage.setItem("orderStatesTo", dateString[1]);
                  }}
                />
              ) : null}

              <div className={styles.timezone}>
                <InputTextDropdown
                  placeholder={t("component.orderFilters.timezone")}
                  showError={false}
                  showLabel={false}
                  value={timezone}
                  top={"calc(100% + 3px)"}
                  options={timezones.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  onChange={(value) => setTimeZone(value)}
                />
              </div>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <TabNavBar
          options={
            profile && profile.adminRole
              ? [finanzOption, teamOption, eventOption]
              : [teamOption, eventOption]
          }
        >
          <Route path={`/overview`} exact>
            <OrderStatesSummary
              error={error}
              currencies={currencies.map((item) => ({
                label: item.currency,
                value: item.currency,
              }))}
              orderStates={orderStates}
              from={from}
              to={to}
              profile={profile}
              currency={currency}
              setCurrency={setCurrency}
              venueArray={venueArray}
              venueId={venueId}
              setVenueId={setVenueId}
              stateIn={stateIn}
              onStateIn={setStateIn}
              paymentStates={paymentStates}
              loadingP={loading}
              loading={loading}
            />
          </Route>
          <Route path={`/overview/teams`} exact>
            <TeamOverviewC
              teams={teams.teams}
              error={teams.error}
              to={to}
              onChangeSortOrder={setSort}
              from={from}
              loading={teams.loading}
            />
          </Route>
          <Route path={`/overview/events`}>
            {periodLoading ? (
              <Card>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: 200,
                    alignItems: "center",
                  }}
                >
                  <Spin />
                </div>
              </Card>
            ) : errorPeriod ? (
              <Card>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: 200,
                    alignItems: "center",
                  }}
                >
                  <EmptyList instructions="" error={errorPeriod} />
                </div>
              </Card>
            ) : period ? (
              <EventOverviewC
                year={year}
                time={time}
                loadingPeriod={periodLoading}
                period={period}
                counts={count}
                setCount={setCount}
                setTime={setTime}
                errorPeriod={errorPeriod}
                setYear={setYear}
                timezone={timezone}
              />
            ) : (
              <Card>
                <EmptyList instructions="" error={errorPeriod} />
              </Card>
            )}
          </Route>
        </TabNavBar>
      </div>
    </LayouWithSideBar>
  );
};

export default Dashboard;
