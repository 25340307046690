import React, { useState } from "react";
import { Location, Placement } from "@innomius/ravent-typescript-types";
import MapMarkers from "../MapMarkers";
import { useHistory } from "react-router-dom";
import LocationDetail from "../LocationDetail";

interface Props {
  data: Location[];
  path: string;
  onEditLocation: (data: Location, placement: Placement) => void;
  load: () => void;
  venueId: string;
  onDelete: (id: string) => void;
  groups: { value: string; label: string }[];
}

const LocationsMapView: React.FC<Props> = ({
  data,
  path,
  onEditLocation,
  load,
  onDelete,
  venueId,
  groups,
}) => {
  const history = useHistory();
  const [open, setOpen] = useState("");

  const [venueLocation, setLocation] = useState<Location | null>();

  const markers = data
    .filter(
      (item) =>
        item.placement.coordinates?.latitude !== undefined &&
        item.placement.coordinates?.longitude !== undefined &&
        item.placement.coordinates.latitude !== null &&
        item.placement.coordinates.longitude !== null &&
        item.placement.coordinates.latitude !== "" &&
        item.placement.coordinates.longitude !== ""
    )
    .map((item) => ({
      lat: parseFloat(item.placement.coordinates?.latitude || "0"),
      lng: parseFloat(item.placement.coordinates?.longitude || "0"),
      name: item.name || item.id,
      clicked: () => {
        history.replace(`/venueLocations/${item.id}/placement`);
        setLocation(item);
      },
      group: item.group,
      line1: item.placement.line1 || "",
      reference: item.placement.reference || "",
      externalId: item.externalId || "",
      groupCode: item.groupCode || "",
    }));

  return (
    <>
      {venueLocation ? (
        <LocationDetail
          load={load}
          groups={groups}
          open={open === venueLocation.id}
          venueId={venueId}
          onDelete={onDelete}
          onEditLocation={(data, placement) => onEditLocation(data, placement)}
          messages={[]}
          visitors={[]}
          data={venueLocation}
          onClose={() => {
            history.push(`${path}`);
            setOpen(venueLocation.id);
          }}
        />
      ) : null}

      <MapMarkers markers={markers} />
    </>
  );
};

export default LocationsMapView;
