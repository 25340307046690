
const translation = {
  en: {
    error: "Error",
    back: "Go Back",
    notFound: "Not Found",
    successUpdated: "Organization was successfully updated",
    successDeleted: "Organization was successfully deleted",
    successDiverUpdated: "Fulfiller was successfully updated",
    divers: "Fulfillers",
    users: "Users",
    detailsLabel: "Details",
    successDiverCreation: "Fulfiller successfully created",
    billing: "Billing",
    tags: "Tags",
    roles: "Roles",
    successDeletedRol: "Role was successfully deleted",
  },
  es: {
    successDeletedRol: "El rol se eliminó con éxito",
    roles: "Roles",
    tags: "Etiquetas",
    error: "Error",
    back: "Regresar",
    notFound: "No se encontró lo que buscaba",
    successUpdated: "La organización se actualizó con éxito",
    successDeleted: "La organización se eliminó con éxito",
    successDiverUpdated: "Fulfiller se actualizó con éxito",
    divers: "Fulfillers",
    users: "Usuarios",
    detailsLabel: "Detalles",
    successDiverCreation: "Fulfiller creado con éxito",
    billing: "Facturación",
  },
  de: {},
};

export default translation;
