import React from "react";
import { useHistory } from "react-router-dom";
import { OrderStateSummaryCount } from "../../utils/types";
import ErrorBox from "../ErrorBox";
import { OrderStateSummarySkeleton } from "../ListSkeletons";
import ItemPayment from "./ItemPayment";
import { GridContainer } from "../GridContainer/GridContainer";

interface StateProps {
  error: string;
  loading: string;
  paymentStates: OrderStateSummaryCount[];
  from: string;
  to: string;
  stateIn: string;
}

const PaymentStateSummary: React.FC<StateProps> = ({
  loading,
  error,
  paymentStates,
  from,
  to,
  stateIn,
}) => {
  const history = useHistory();

  const totalPaymentAmount = paymentStates
    .map((item) => item.total)
    .reduce((partialSum, a) => partialSum + a, 0);

  const sortingArrPayment = [
    "un-paid",
    "partial",
    "paid",
    "refunded",
    "expired",
  ];

  const statesPay = paymentStates.sort(
    (a, b) => sortingArrPayment.indexOf(a.id) - sortingArrPayment.indexOf(b.id)
  );

  return (
    <div>
      {error ? (
        <ErrorBox error={error} />
      ) : loading === "orderPaymentStates" ? (
        <OrderStateSummarySkeleton />
      ) : (
        <GridContainer padding="2em 0 1em 0" columns="1fr  1fr  1fr  1fr  1fr">
          {statesPay.map((item, i) => (
            <div key={i}>
              <ItemPayment
                key={item.id}
                lastItem={i !== statesPay.length - 1}
                onClick={() => {
                  history.push(
                    `/orders?stateIn=${stateIn}&paymentStateIn=${item.id}&from=${from}&to=${to}`
                  );
                }}
                totalAmount={totalPaymentAmount}
                status={item.id}
                amount={item.total}
                count={item.count}
              />
            </div>
          ))}
        </GridContainer>
      )}
    </div>
  );
};

export default PaymentStateSummary;
