import React, { useCallback, useEffect, useState } from "react";
import Text from "../../Text";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import AssignFulfiller from "../../OrderFulfillmentComponent/AssignFulfillment";
import { User } from "@innomius/ravent-typescript-types";
import APIError from "../../../utils/APIError";
import { Modal } from "../../Modal/Modal";
import { ButtonsContainer } from "../../ButtonsContainer";
import { Button } from "../../Button";
import { FlexContainer } from "../../FlexContainer/FlexContainer";
import { InputError } from "../../Inputs/InputError/InputError";
import { ContainerTitle } from "../../ContainerTitle";
import { InputSelect } from "../../Inputs/InputSelect";
import InputTextDropdown from "../../Inputs/InputTextDropdown";
import { userFullTextSearch } from "../../../services/users";
import Search from "antd/es/input/Search";

interface Props {
  onClose: () => void;
  open: boolean;
  loading?: boolean;
  error?: string;
  updateTeam: (id: string, teamId: string) => void;
  teams: { label: string; value: string }[];
  teamId?: string;
  updateFulfiller: (id: string, fulfillerId: string) => void;
  orderlineId: string;
  fulfillerId?: string;
  updateBoth?: (teamId: string, fulfillerId: string) => void;
}

const DiveShopModal: React.FC<Props> = ({
  onClose,
  updateTeam,
  open,
  error,
  loading,
  teams,
  orderlineId,
  teamId,
  fulfillerId,
  updateFulfiller,
  updateBoth,
}) => {
  const [diveShop, setDiveShop] = useState(
    teamId !== "-" && teamId ? teamId : ""
  );
  const [fulfiller, setFulfiller] = useState(
    fulfillerId !== "-" && fulfillerId ? fulfillerId : ""
  );
  const [openSelect, setOpen] = useState(false);

  const [search, setSearch] = useState("");
  const [text, setText] = useState("");

  const [diveShopSet, setFulfillmentNameSet] = useState(false);
  const [fulfillers, setFulfillers] = useState<User[] | []>([]);

  const { t } = useTranslation();

  const getFulfillers = useCallback(async () => {
    try {
      const res = await userFullTextSearch({
        fulfillmentTeamId: diveShop,
        records_per_page: 1000,
        page: 0,
        search: search,
      });
      setFulfillers(res.hits);
    } catch (err) {
      if (err instanceof APIError) {
        console.log(err);
      }
    }
  }, [diveShop, search]);

  useEffect(() => {
    if (diveShop && open) {
      getFulfillers();
    }
  }, [getFulfillers, diveShop, open, search]);

  return (
    <div className={styles.container}>
      <Modal
        width="600px"
        buttons={
          <div>
            <ButtonsContainer justifyContent="flex-end">
              <Button
                onClick={() => {
                  onClose();
                  setFulfiller(fulfillerId || "");
                }}
                theme="white"
                label={t("component.orderFulfillment.goBack")}
              />
              <Button
                onClick={() => {
                  if (diveShopSet) {
                    updateTeam(orderlineId, diveShop);
                  }
                  if (fulfiller) {
                    setTimeout(() => {
                      updateFulfiller(orderlineId, fulfiller);
                    }, 1000);
                  }
                  updateBoth?.(diveShop, fulfiller);
                  setFulfillmentNameSet(false);
                }}
                theme="blue"
                label={
                  loading
                    ? "..."
                    : t("component.orderFulfillment.updateDiveShop")
                }
              />
            </ButtonsContainer>
            {error ? (
              <FlexContainer justifyContent="flex-end" padding="0.5em 1em 0 0">
                <InputError error={error} />
              </FlexContainer>
            ) : null}
          </div>
        }
        open={open}
        onClose={onClose}
      >
        <div className={styles.modalContainer}>
          <ContainerTitle size="medium" label="Team and Fulfiller" />
          <Text
            padding="1em 0 1em 0"
            text={t("component.orderFulfillment.notificationDiveShop")}
            color="#000000"
            fontSize="1em"
            fontWeight={500}
          />

          {!teams.length ? (
            <div className={styles.notEnrollContainer}>
              No Teams configured. Please add them to your Organization
            </div>
          ) : (
            <div>
              {!diveShop || openSelect ? (
                <InputSelect
                  maxHeightOptions="8em"
                  label={t("component.orderFulfillment.diveShops")}
                  options={[...teams, { label: "unset team", value: "unset" }]}
                  value={diveShop}
                  onChange={(value) => {
                    setDiveShop(value);
                    setFulfillmentNameSet(true);
                  }}
                />
              ) : (
                <div style={{ marginBottom: "1em" }}>
                  <AssignFulfiller
                    border
                    onClick={() => setOpen(true)}
                    type="fulfiller"
                    name={
                      teams.find((item) => item.value === diveShop)?.label || ""
                    }
                  />
                </div>
              )}
            </div>
          )}

          <div>
            <Text
              padding="1em 0 0.5em 0"
              text={t("component.orderFulfillment.notificationfulfiller")}
              color="#000000"
              fontSize="1em"
              fontWeight={500}
            />
            <Search
              style={{ marginBottom: "1em" }}
              placeholder={"Buscar"}
              onSearch={() => {
                setSearch(text);
              }}
              value={text}
              onChange={(text) => {
                setText(text.target.value);
              }}
            />{" "}
            {!fulfillers.length ? (
              <div className={styles.notEnrollContainer}>
                No Fulfillers registerd to team please add them in the
                organization section.
              </div>
            ) : (
              <div className={styles.selectContainer}>
                <InputTextDropdown
                  top={"calc(70% + 1px)"}
                  label={t("component.orderFulfillment.fulfillers")}
                  options={fulfillers.map((item) => ({
                    label: `${item.externalId ? `${item.externalId} -` : ""} ${
                      item.lastName
                    }, ${item.firstName} - ${
                      item.loginWithPhone
                        ? item.phone || "not registerred"
                        : item.email || "not registerred"
                    }`,
                    value: item.id,
                  }))}
                  value={fulfiller}
                  onChange={(value) => {
                    setFulfiller(value);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DiveShopModal;
