import React, { useState } from "react";
import UserItem from "./UserItem";
import { UserListSkeleton } from "../ListSkeletons";
import { User } from "@innomius/ravent-typescript-types";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { PaginationRavent } from "../Pagination/Pagination";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { List } from "../List";
import { Role } from "../../services/roles";

interface UserProps {
  error: string;
  loading: boolean;
  data: User[];
  roles: Role[];
  page: number;
  total: number;
  hitsPerPage: number;
  onPageChange?: (page: number) => void;
  onHitsPerPageChange?: (hitsPerPage: number) => void;
  onChangeSortOrder?: (value: string) => void;
  sortable: boolean;
  pagination?: boolean;
  onDelete: (id: string) => void;
  loadingDelete: boolean;
  permission: boolean;
  resetPassword: (id: string) => void;

  selectedUsers: {
    id: string;
    name: string;
    loginWithPhone: boolean;
    phone?: string;
    email?: string;
  }[];
  setSelectedUsers: (value: any) => void;
}

const UserList: React.FC<UserProps> = ({
  error,
  loading,
  data,
  pagination,
  page,
  total,
  hitsPerPage,
  onHitsPerPageChange,
  onPageChange,
  onDelete,
  loadingDelete,
  onChangeSortOrder,
  permission,
  selectedUsers,
  setSelectedUsers,
  resetPassword,
  roles,
}) => {
  const { t } = useTranslation();
  const [order, setOrder] = useState<string>("asc");
  const [sort, setSort] = useState<string | null>(null);

  const [selectAll] = useState(false);
  const handleCheckboxChange = (
    userId: string,
    name: string,
    loginWithPhone: boolean,
    phone: string,
    email: string
  ) => {
    const isSelected = selectedUsers.some((loc) => loc.id === userId);

    if (selectAll) {
      setSelectedUsers((prevSelected: any[]) => {
        if (isSelected) {
          return prevSelected.filter((loc) => loc.id !== userId);
        } else {
          return [
            ...prevSelected,
            {
              id: userId,
              name: name,
              loginWthPhone: loginWithPhone,
              phone,
              email,
            },
          ];
        }
      });
    } else {
      setSelectedUsers((prevSelected: any[]) => {
        if (isSelected) {
          return prevSelected.filter((loc) => loc.id !== userId);
        } else {
          return [
            ...prevSelected,
            { id: userId, name: name, loginWithPhone, phone, email },
          ];
        }
      });
    }
  };

  // const handleSelectAllChange = (e: CheckboxChangeEvent) => {
  //   setSelectAll(e.target.checked);
  //   setSelectedUsers(
  //     e.target.checked
  //       ? data.map((item) => ({ id: item.id, name: item.firstName }))
  //       : []
  //   );
  // };

  const header = (
    <div>
      <ListHeader template="2em 0.3fr 0.5fr 1fr 0.6fr 0.8fr 1.5fr 0.2fr">
        <div style={{ padding: "0.4em" }}>
          {/* <Checkbox checked={selectAll} onChange={handleSelectAllChange} /> */}
        </div>
        <ListHeaderLabel
          value={t("component.userList.externalId")}
          justifyContent="flex-start"
          sortable
          sort={
            sort === "externalId" ? (order === "desc" ? "asc" : "desc") : null
          }
          onClick={() => {
            setSort("externalId");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`externalId:${order}`);
            }
          }}
        />
        <ListHeaderLabel
          value={t("component.userList.createdAt")}
          justifyContent="flex-start"
          sortable
          sort={
            sort === "createdAt" ? (order === "desc" ? "asc" : "desc") : null
          }
          onClick={() => {
            setSort("createdAt");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`createdAt:${order}`);
            }
          }}
        />
        <ListHeaderLabel
          value={t("component.userList.user")}
          justifyContent="flex-start"
          sortable
          sort={sort === "name" ? (order === "desc" ? "asc" : "desc") : null}
          onClick={() => {
            setSort("name");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`name:${order}`);
            }
          }}
        />

        <ListHeaderLabel
          value={t("component.userList.team")}
          justifyContent="flex-start"
          sortable
          sort={
            sort === "fulfillmentTeamName"
              ? order === "desc"
                ? "asc"
                : "desc"
              : null
          }
          onClick={() => {
            setSort("fulfillmentTeamName");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`fulfillmentTeamName:${order}`);
            }
          }}
        />
        <ListHeaderLabel
          value={t("component.userList.role")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.userList.tags")}
          justifyContent="flex-start"
        />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <div className={styles.listContainer}>
        <List
          loading={loading}
          loadingElement={() => <UserListSkeleton />}
          error={error}
          data={data}
          empty={!data && !loading && !error}
          keyExtractor={(item) => item.id}
          renderHeader={() => header}
          renderItem={(item) => (
            <UserItem
              resetPassword={resetPassword}
              roles={roles}
              onCheckboxChange={() =>
                handleCheckboxChange(
                  item.id,
                  item.firstName,
                  item.loginWithPhone,
                  item.phone,
                  item.email
                )
              }
              isSelected={selectedUsers.some((loc) => loc.id === item.id)}
              permission={permission}
              loadingDelete={loadingDelete}
              onDelete={(id) => onDelete(id)}
              data={item}
              key={item.id}
            />
          )}
        />
      </div>

      {pagination ? (
        <div className={styles.paginationContainerFixed}>
          <PaginationRavent
            current={page + 1}
            defaultCurrent={page + 1}
            total={total}
            showSizeChanger
            onShowSizeChange={(page, size) => {
              if (onHitsPerPageChange) {
                onHitsPerPageChange(size);
              }
            }}
            onChange={(page) => {
              if (onPageChange) {
                onPageChange(page - 1);
              }
            }}
            pageSize={hitsPerPage}
          />
        </div>
      ) : null}
    </div>
  );
};

export default UserList;
