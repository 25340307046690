import React from "react";
import styles from "./styles.module.css";
import { Popover } from "antd";
import Text from "../../Text";
import {
  format,
  parseISO,
  addDays,
  getDay,
  startOfMonth,
  endOfMonth,
  differenceInCalendarMonths,
  addMonths,
  startOfWeek,
  endOfWeek,
  differenceInDays,
  isToday,
} from "date-fns";

interface Props {
  range: {
    from: number;
    to: number;
  }[];
  heatmap: {
    count: number;
    date: string;
  }[];
  dateRange: {
    start: string;
    end: string;
  };
  dayLabels: string[];
  counts: string;
  onClick: (date: string) => void;
}

const DailyHeatMap: React.FC<Props> = ({
  range,
  heatmap,
  dateRange,
  dayLabels,
  counts,
  onClick,
}) => {
  const rangeStartDate = parseISO(dateRange.start);
  const rangeEndDate = parseISO(dateRange.end);

  const startDate = startOfMonth(addMonths(rangeStartDate, -1));
  const endDate = endOfMonth(addMonths(rangeEndDate, 1));
  const monthsInRange = Math.min(
    differenceInCalendarMonths(endDate, startDate) + 1,
    5
  );

  const visibleStartDate = startOfWeek(startDate);
  const visibleEndDate = endOfWeek(endDate);

  const totalDays = differenceInDays(visibleEndDate, visibleStartDate) + 1;
  const dayOfYearData: (number | null)[] = Array.from(
    { length: totalDays },
    () => null
  );

  heatmap.forEach((data) => {
    const dateObj = parseISO(data.date);
    if (dateObj >= visibleStartDate && dateObj <= visibleEndDate) {
      const dayIndex = differenceInDays(dateObj, visibleStartDate);
      dayOfYearData[dayIndex] = data.count;
    }
  });

  const getDayColor = (count: number | null) => {
    if (count === null || count === 0) return styles.colorEmpty;
    for (let i = 0; i < range.length; i++) {
      if (count >= range[i].from && count <= range[i].to) {
        return styles[`colorScale${i + 1}`];
      }
    }
    return styles.colorEmpty;
  };

  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const getDateTooltip = (date: string, count: number | null) => {
    const dateObj = parseISO(date);
    const dayOfWeek = getDay(dateObj);
    const dayName = dayNames[dayOfWeek];

    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          e.stopPropagation();
          onClick(format(dateObj, "yyyy-MM-dd"));
        }}
      >
        <div>{dayName}</div>
        <div>{format(dateObj, "dd MMM yyyy")}</div>
        <div>{`${count !== null ? count : 0} ${
          counts === "events" ? "events" : "loads"
        }`}</div>
      </div>
    );
  };

  const monthLabels = Array.from({ length: monthsInRange }, (_, index) => {
    const monthStart = startOfMonth(addMonths(startDate, index));
    return {
      label: format(monthStart, "MMM"),
    };
  });

  const calendarGrid = (
    <>
      <div className={styles.calendarGrid}>
        <div className={styles.monthLabels}>
          {monthLabels.map((month, index) => (
            <div key={index} className={styles.monthLabel}>
              {month.label}
            </div>
          ))}
        </div>
        <div className={styles.dayLabels}>
          {dayLabels.map((label, index) => (
            <div key={index} className={styles.dayLabel}>
              <Text color="#aaa" padding="0 0.5em" text={label} />
            </div>
          ))}
        </div>
        <div className={styles.gridContainer}>
          {Array.from({ length: totalDays }).map((_, dayIndex) => {
            const date = addDays(visibleStartDate, dayIndex);
            const count = dayOfYearData[dayIndex];

            const weekDay = getDay(date);
            const weekIndex = Math.floor(dayIndex / 7);

            const isCurrentDate = isToday(date); // No timezone handling needed

            const cellClass = `${styles.calendarCell} ${getDayColor(count)} ${
              isCurrentDate ? styles.todayCell : ""
            }`;

            return (
              <Popover
                key={dayIndex}
                content={getDateTooltip(format(date, "yyyy-MM-dd"), count)}
                trigger="hover"
              >
                <div
                  className={cellClass}
                  style={{
                    gridColumn: weekIndex + 2,
                    gridRow: weekDay + 2,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick(format(date, "yyyy-MM-dd"));
                  }}
                />
              </Popover>
            );
          })}
        </div>
      </div>
    </>
  );

  return <div className={styles.container}>{calendarGrid}</div>;
};

export default DailyHeatMap;
