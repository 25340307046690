
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { TicketingDefSearchResponse } from "../../services/passDef";
import { PassDefinition } from "../../utils/types";
import CreateTicketingDef from "../CreatePassDef";
import TicketingDefList from "../PassDefList";
import styles from "./styles.module.css";
import { Card } from "../Card/Card";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { Button } from "../Button";
import { EmptyList } from "../List/EmptyList";

interface Props {
  ticketingDefs: TicketingDefSearchResponse;
  error: string;
  loading: boolean;
  page: number;
  total: number;
  hitsPerPage: number;
  setPage: (page: number) => void;
  setHitsPerPage: (hitsPerPage: number) => void;
  recordsPerPage: number;
  ticketingDef: PassDefinition;
  onChangeTicketingDef: <P extends keyof PassDefinition>(
    prop: P,
    value: PassDefinition[P]
  ) => void;
  onCreateTicketingDef: () => void;
  open: string;
  onClose: () => void;
  loadingT: boolean;
  setModal: (value: string) => void;
  loadingEditT: string;
  onEditTicketingDef: (data: PassDefinition) => void;
  onDeleteTicketing: (id: string) => void;
  load: () => void;
  path: string;
  openModal: boolean;
  setOpen: (value: boolean) => void;
}

const VenueTicketing: React.FC<Props> = ({
  ticketingDefs,
  ticketingDef,
  error,
  loading,
  page,
  setHitsPerPage,
  setPage,
  recordsPerPage,
  total,
  setModal,
  open,
  onChangeTicketingDef,
  onCreateTicketingDef,
  loadingT,
  onDeleteTicketing,
  onEditTicketingDef,
  loadingEditT,
  path,
  openModal,
  setOpen,
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  return (
    <div>
      <Card>
        <div className={styles.routeContainer}>
          <FlexContainer
            padding="0 0 2em 0"
            justifyContent="space-between"
            alignItems="center"
          >
            <ContainerTitle
              size="small"
              label={t("component.venueTicketing.ticketingLabel")}
              count={total.toString()}
            />
            <Button
              label={t("component.venueTicketing.createNew")}
              onClick={() => {
                setModal("create-ticketing");
                history.push(`/passes/new`);
              }}
            />
          </FlexContainer>

          <div>
            {ticketingDefs.data.length < 1 && !loading && !error ? (
              <EmptyList
                instructions={""}
                text={t("component.venueTicketing.instructions")}
              />
            ) : (
              <TicketingDefList
                sortable
                onEditTicketingDef={onEditTicketingDef}
                onDeleteTicketing={onDeleteTicketing}
                loadingEditT={loadingEditT}
                error={error}
                loading={loading}
                data={ticketingDefs.data}
                page={page}
                total={ticketingDefs.total}
                hitsPerPage={recordsPerPage}
                onPageChange={setPage}
                onHitsPerPageChange={setHitsPerPage}
                pagination
                path={path}
                open={openModal}
                setOpen={setOpen}
              />
            )}
          </div>
        </div>
      </Card>
      <CreateTicketingDef
        loadingT={loadingT}
        onCreateTicketingDef={onCreateTicketingDef}
        onChangeTicketingDef={onChangeTicketingDef}
        path={path}
        open={open === "create-ticketing"}
        onClose={() => setModal("")}
        ticketingDef={ticketingDef}
      />
    </div>
  );
};

export default VenueTicketing;
