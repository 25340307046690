import React from "react";
import UpdateItem from "./UpdateItem";
import styles from "./styles";
import { EventComment, OrderComment } from "@innomius/ravent-typescript-types";
import Timeline from "antd/lib/timeline/Timeline";

interface Props {
  data: OrderComment[] | EventComment[];
  removeMessage: (id: string) => void;
}

const OrderCommentList: React.FC<Props> = ({ data, removeMessage }) => {
  const colors = ["00bfbf"];

  const items = data.map((item, index) => ({
    children: (
      <UpdateItem
        removeMessage={(id) => removeMessage(id)}
        key={index + index}
        color={colors[index]}
        data={item}
      />
    ),
  }));

  return (
    <div className={styles.container}>
      <Timeline items={items} />
    </div>
  );
};

export default OrderCommentList;
