const translation = {
  en: {
    refundsIssued: "Refunds Issued",
    issueRefund: "Issue Refund",
    emptyList: "Empty List",
    noRefunds: "No Refunds Issued",
  },
  es: {
    refundsIssued: "Reembolsos emitidos",
    issueRefund: "Emitir reembolso",
    emptyList: "Lista vacía",
    noRefunds: "No se emitieron reembolsos",
  },
  de: {},
};

export default translation;
