import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { bluishGrey } from "../../utils/colors";
import Text from "../Text";
import styles from "./styles.module.css";
import { FlexContainer } from "../FlexContainer/FlexContainer";

interface Props {
  children?: React.ReactNode;
}

export const Loading: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <FlexContainer padding="5em" flexDirection="column">
      <Text
        text={t("component.loading.pageLoading")}
        padding="0 0 0.5em 0"
        fontSize="1.6em"
        fontWeight={600}
      />
      <Text
        text={t("component.loading.wait")}
        padding="0 0 1em 0"
        fontSize="1em"
        fontWeight={600}
        color={bluishGrey}
      />
      <FontAwesomeIcon
        icon={faSpinner as IconProp}
        width={"5em"}
        className={styles.icon}
        spin
      />
      {children}
    </FlexContainer>
  );
};
