const translation = {
  en: {
    successCreation: "Order succesfully created.",
    back: "Back to Orders",
    cancel: "Cancel",
    save: "Save",
    createOrderLabel: "Create Order",
  },
  es: {
    successCreation: "Orden creada con éxito",
    back: "Regresar a Órdenes",
    cancel: "Cancelar",
    save: "Guardar",
    createOrderLabel: "Crear Orden",
  },
  de: {},
};

export default translation;
