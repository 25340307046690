import { stylesheet } from "typestyle";

const styles = stylesheet({
  wrapperCard: {
    padding: "2em",
  },
  switch: {
    padding: "0 0 0 1em",
  },
  switchText: {
    padding: "0 1em 0 1em",
  },
  radioContainer: {
    marginRight: "3em",
    padding: "1em 0",
  },
  propertyInstructions: {
    padding: "0 50% 1.5em 0",
    textAlign: "justify",
  },
  icon: {
    width: "90px",
  },

  noCard: {
    border: "none",
    boxShadow: "none !important",
    padding: 0,
  },
  editorDetails: {
    width: "100%",
    height: "20em",
    marginBottom: "1em",
  },

  editorBox: {
    width: "100%",
    height: "20em",
    border: "1px solid #dce1e6 !important",
  },
});

export default styles;
