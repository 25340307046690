import { combineReducers } from "redux";
import auth from "./auth";
import profile from "./profile";
import orders from "./orders";
import offerSets from "./offersets";
import venues from "./venues";
import teams from "./teams";
import visitors from "./visitors";
import messages from "./messages";
import users from "./users";
import fops from "./fops";
import fopSets from "./fopSets";
import fulfillments from "./fulfillments";
import fopsCatalog from "./fopsCatalog";
import locations from "./locations";
import ticketingDefs from "./ticketingDefs";
import integrations from "./integrations";
import media from "./media";
import assets from "./assets";
import textSearch from "./textSearch";
import eventTextSearch from "./eventTextSearch";
import locationTextSearch from "./locationsTextSearch";
import fulfillersSummary from "./fulfillersSummary";
import teamsSummary from "./teamsSummary";
import assetTextSearch from "./assetsTextSearch";
import venueStats from "./venueStats";
import userTextSearch from "./userTextSearch";
import teamsTextSearch from "./teamsTextSearch";

export const rootReducer = combineReducers({
  auth,
  profile,
  orders,
  fopSets,
  offerSets,
  venues,
  teams,
  visitors,
  messages,
  users,
  fulfillments,
  integrations,
  fops,
  fopsCatalog,
  locations,
  ticketingDefs,
  assets,
  venueStats,
  assetTextSearch,
  media,
  textSearch,
  eventTextSearch,
  locationTextSearch,
  fulfillersSummary,
  teamsSummary,
  userTextSearch,
  teamsTextSearch,
});

export type RootState = ReturnType<typeof rootReducer>;
