
import React from "react";
import { useTranslation } from "react-i18next";
import Cron from "react-js-cron";
import "react-js-cron/dist/styles.css";
import Text from "../Text";
import { Schedule } from "@innomius/ravent-typescript-types";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { InputText } from "../Inputs/InputText";

interface Props {
  data: Schedule;
  onChange: <P extends keyof Schedule>(prop: P, value: Schedule[P]) => void;
  error?: string;
  setError: (value: string) => void;
}

const CronForm: React.FC<Props> = ({ data, onChange, error, setError }) => {
  const { t } = useTranslation();

  return (
    <div>
      <FlexContainer justifyContent="flex-start">
        <InputLabel label={t("component.orderStateList.cronTabExpression")} />
        <Text
          padding="0 0 0 0.5em"
          fontSize="1em"
          text={t("component.orderStateList.des")}
        />
      </FlexContainer>
      
      <InputText
        onFocus={() => setError("")}
        error={
          error && !data.cronTabExpression ? "This field is mandatory" : ""
        }
        showLabel={false}
        value={data.cronTabExpression as string}
        onChange={(value) => {
          onChange("cronTabExpression", value);
        }}
      />
      
      <Cron
        value={data.cronTabExpression as string}
        setValue={(value: any) => onChange("cronTabExpression", value)}
      />
    </div>
  );
};

export default CronForm;
