import { OrderInvoice } from "@innomius/ravent-typescript-types";

import React from "react";
import { useTranslation } from "react-i18next";
import InputDate from "../Inputs/Date";
import { InputTime } from "../Inputs/InputTime/InputTime";
import styles from "./styles";
import { GridContainer } from "../GridContainer/GridContainer";
import { SectionProperty } from "../Section/SectionProperty";
import { InputText } from "../Inputs/InputText";
import { SectionSpacer } from "../Section/SectionSpacer";
import { ContainerTitle } from "../ContainerTitle";
import { InputSelect } from "../Inputs/InputSelect";

interface Props {
  data: OrderInvoice;
  payments: { label: string; value: string }[];
  requestIds: { label: string; value: string }[];
  onChange: <P extends keyof OrderInvoice>(
    prop: P,
    value: OrderInvoice[P]
  ) => void;
}

const EditInvoice: React.FC<Props> = ({
  data,
  payments,
  requestIds,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <GridContainer
        gap="1em"
        columns="1fr 1fr"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <SectionProperty
          label={t("component.invoiceDetail.invoiceId")}
          value={data.id}
        />
        <InputText
          label={t("component.invoiceDetail.description")}
          value={data.description}
          showError={false}
          onChange={(value) => onChange("description", value)}
        />
      </GridContainer>
      <SectionSpacer />
      <ContainerTitle
        size="xsmall"
        padding="0 0 1em 0"
        label={t("component.invoiceDetail.invoiceData")}
      />
      <GridContainer
        gap="1em"
        columns="0.4fr 1fr"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <div className={styles.bold}>
          {t("component.invoiceDetail.requestId")}
        </div>
        <InputSelect
          showLabel={false}
          showError={false}
          placeholder={t("component.orderInvoiceList.selectOption")}
          value={data.paymentRequestID}
          options={[...requestIds, { value: "", label: "Not Assigned" }]}
          onChange={(value) => onChange("paymentRequestID", value)}
        />
        <div className={styles.bold}>
          {t("component.invoiceDetail.paymentId")}
        </div>
        <InputSelect
          placeholder={t("component.orderInvoiceList.selectOption")}
          value={data.paymentID}
          options={[...payments, { value: "", label: "Not Assigned" }]}
          onChange={(value) => onChange("paymentID", value)}
          showLabel={false}
          showError={false}
        />
        <div className={styles.bold}>{t("component.invoiceDetail.issuer")}</div>
        <InputText
          showLabel={false}
          value={data.issuer}
          showError={false}
          onChange={(value) => onChange("issuer", value)}
        />
        <div className={styles.bold}>
          {t("component.invoiceDetail.issuerId")}
        </div>
        <InputText
          showLabel={false}
          showError={false}
          value={data.issuerId}
          onChange={(value) => onChange("issuerId", value)}
        />
        <div className={styles.bold}>
          {t("component.invoiceDetail.authority")}
        </div>
        <InputText
          showLabel={false}
          showError={false}
          value={data.authority}
          onChange={(value) => onChange("authority", value)}
        />
        <div className={styles.bold}>
          {t("component.invoiceDetail.authorityId")}
        </div>
        <InputText
          showLabel={false}
          showError={false}
          value={data.authorityId}
          onChange={(value) => onChange("authorityId", value)}
        />
        <div className={styles.bold}>
          {t("component.invoiceDetail.issueTimestamp")}
        </div>

        <GridContainer padding="0 0 2em 0" gap="1em" columns="1fr 1fr 2fr">
          <InputDate
            showLabel={false}
            id="issued"
            value={data.issuedDate as Date}
            onChange={(value) => onChange("issuedDate", value)}
          />
          <InputTime
            showLabel={false}
            id="issuedDate"
            value={data.issuedDate as Date}
            onChange={(value) => onChange("issuedDate", value.value)}
          />
        </GridContainer>
      </GridContainer>
    </div>
  );
};

export default EditInvoice;
