import React from "react";
import produce from "immer";
import { FormComponent } from "../../utils/types";
import SchemaComponent from "./SchemaComponents";
import { GridContainer } from "../GridContainer/GridContainer";

interface SchemaFormProps {
  elements: FormComponent[];
  onChange: (change: FormComponent[]) => void;
}

export function handleElements(elements: FormComponent[]): FormComponent[] {
  return elements.map((c) => {
    if (c.type === "text" || c.type === "list") {
      return {
        ...c,
        value: "",
      } as FormComponent;
    }
    return c as FormComponent;
  });
}

const SchemaForm: React.FC<SchemaFormProps> = ({ elements, onChange }) => {
  return (
    <div>
      <GridContainer gap="1em" columns="1fr 1fr">
        {elements.map((item, index) => (
          <SchemaComponent
            key={item.name}
            data={item}
            onChange={(value) => {
              onChange(
                produce(elements, (draft) => {
                  draft[index].value = value as string;
                })
              );
            }}
          />
        ))}
      </GridContainer>
    </div>
  );
};

export default SchemaForm;
