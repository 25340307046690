import React from "react";
import { OrderListSkeleton } from "../ListSkeletons";
import LocationItemList from "./LocationItemList";
import { MissingLocation } from "../../utils/types";
import { PaginationRavent } from "../Pagination/Pagination";
import styles from "./styles";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { ListHeader } from "../List/ListHeader";
import { List } from "../List";

interface MissingLocationListProps {
  error: string;
  loading: boolean;
  data: MissingLocation[];
  venueId: string;
  onClickItem: (externalId: string, name: string) => void;
  pagination: boolean;
  page: number;
  total: number;
  hitsPerPage: number;
  onPageChange?: (page: number) => void;
  onHitsPerPageChange?: (hitsPerPage: number) => void;
}

const MissingLocationList: React.FC<MissingLocationListProps> = ({
  error,
  loading,
  data,
  onClickItem,
  pagination,
  page,
  total,
  onHitsPerPageChange,
  onPageChange,
  hitsPerPage,
}) => {
  const header = (
    <div>
      <ListHeader template="0.5fr 1fr 2fr 0.5fr">
        <ListHeaderLabel value="Count" justifyContent="flex-start" />

        <ListHeaderLabel value="ID" justifyContent="flex-start" />
        <ListHeaderLabel value="Location Name" justifyContent={"flex-start"} />
        <ListHeaderLabel value="" justifyContent="center" />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <List
        loading={loading}
        loadingElement={() => <OrderListSkeleton />}
        error={error}
        data={data}
        keyExtractor={(item) => item.id}
        renderHeader={() => header}
        renderItem={(item) => (
          <LocationItemList
            onClickItem={onClickItem}
            data={item}
            key={item.id}
          />
        )}
      />
      {pagination ? (
        <div className={styles.paginationContainer}>
          <PaginationRavent
            current={page + 1}
            defaultCurrent={page + 1}
            total={total}
            showSizeChanger
            onShowSizeChange={(page, size) => {
              if (onHitsPerPageChange) {
                onHitsPerPageChange(size);
              }
            }}
            onChange={(page) => {
              if (onPageChange) {
                onPageChange(page - 1);
              }
            }}
            pageSize={hitsPerPage}
          />
        </div>
      ) : null}
    </div>
  );
};

export default MissingLocationList;
