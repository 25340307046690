import React, { useCallback, useEffect, useState } from "react";
import { formatDate } from "../../utils/times";
import { useHistory } from "react-router-dom";
import styles from "./styles";
import { FulfillmentEvent, Load } from "@innomius/ravent-typescript-types";
import { Tooltip, Tag, Popover, Checkbox } from "antd";
import { Button } from "../Button";
import { ReactComponent as FulfillerYellowIcon } from "../../assets/fulfillerYellow.svg";

import { ReactComponent as FulfillerBlueIcon } from "../../assets/fulfiller-blue.svg";
import { ReactComponent as AssetBlue } from "../../assets/asset-blue.svg";
import { ReactComponent as AssetIcon } from "../../assets/sidebar/assets.svg";
import { ReactComponent as FulfillerIcon } from "../../assets/fulfiller.svg";
import { ReactComponent as StatesIcon } from "../../assets/statesBlack.svg";
import { ReactComponent as StatesIconBlue } from "../../assets/statesB.svg";
import Text from "../Text";
import { useTranslation } from "react-i18next";
import { Asset } from "../../utils/types";
import DiveShopModal from "../OrderFulfillment/DiveShopModal";
import AssetModal from "../AssetModal";
import StatesModal from "../OrderFulfillment/StatesModal";
import { ListRow } from "../List/ListRow";
import { StatusBadgePoint } from "../StatusBadgePoint/StatusBagePoint";
import MarkEvent from "./MarkEvent";
import fontColorContrast from "font-color-contrast";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ListLabel } from "../List/ListLabel";
import { getEvent } from "../../services/events";
import APIError from "../../utils/APIError";

interface Props {
  data: FulfillmentEvent;
  onTagClick: (tag: string) => void;
  assets: Asset[];
  updateFulfiller: (eventId: string, fulfillerId: string) => void;
  updateTeam: (eventId: string, teamId: string) => void;
  updateAsset: (event: FulfillmentEvent, id: string) => void;
  onSaveLoad: (eventId: string, data: Load[]) => void;
  teams: { label: string; value: string }[];

  onCheckboxChange?: () => void;
  isSelected?: boolean;
  setState: (
    id: string,
    fulfillmentLevel: string,
    status: string,
    w3w: string,
    images: string[]
  ) => void;
  updateFulfillmentName: (
    id: string,
    diveshopId: string,
    fulfillmentLevel: string
  ) => void;
  fulfillmentNames: { label: string; value: string }[];
  errorState: string;
  setNew: (id: string, state: string) => void;
  loadingState: { eventId: string; state: string };
}

const EventItemNormal: React.FC<Props> = ({
  data,
  onTagClick,
  updateAsset,
  updateFulfiller,
  updateTeam,
  teams,
  assets,
  onCheckboxChange,
  isSelected,
  setState,
  updateFulfillmentName,
  fulfillmentNames,
  errorState,
  setNew,
  loadingState,
}) => {
  const history = useHistory();
  const [open, setOpen] = useState("");
  const [assetModal, setAsset] = useState(false);
  const [fEvent, setEvent] = useState<FulfillmentEvent>(data);
  const [detail, setDetails] = useState("");

  const { t } = useTranslation();

  const getDetails = useCallback(async () => {
    try {
      const res = await getEvent(data.id);
      setEvent(res);
    } catch (err) {
      if (err instanceof APIError) {
        console.log(err);
      }
    }
  }, [data.id]);

  useEffect(() => {
    if (data.id === detail) {
      getDetails();
    }
  }, [getDetails, data, detail]);

  const formatOptionKey = (key: string, color: string) => {
    if (key.includes("::")) {
      const [firstPart, secondPart] = key.split("::");
      return (
        <Tooltip title={firstPart}>
          <Tag
            style={{
              color: color
                ? color === "blue"
                  ? "blue"
                  : fontColorContrast(color, 0.5)
                : "blue",
            }}
            color={color || "blue"}
          >
            {secondPart}
          </Tag>
        </Tooltip>
      );
    } else {
      return <Tag color="blue">{key}</Tag>;
    }
  };

  return (
    <>
      <DiveShopModal
        fulfillerId={data.fulfillerId}
        orderlineId={data.id}
        updateFulfiller={(id: string, fId: string) => {
          try {
            updateFulfiller(id, fId);
            setOpen("");
          } catch (err) {}
        }}
        teamId={data.fulfillmentTeamId}
        teams={teams || []}
        updateTeam={(id: string, teamId: string) => {
          try {
            updateTeam(id, teamId);
            setOpen("");
          } catch (err) {}
        }}
        open={open === "diveshop"}
        onClose={() => setOpen("")}
      />
      <AssetModal
        open={assetModal}
        assets={assets}
        onClose={() => setAsset(false)}
        assetId={data.assetId}
        updateAsset={(assetId) => updateAsset(data, assetId)}
      />

      <StatesModal
        updateFulfillmentName={(id, diveshopId) =>
          updateFulfillmentName(id, diveshopId, data.fulfillmentLevel)
        }
        fulfillmentNames={fulfillmentNames}
        fulfillmentDefinitionId={fEvent.fulfillmentDefinitionId}
        error={errorState}
        orderlineId={
          data.fulfillmentLevel === "order"
            ? data.order
            : data.orderlineId || ""
        }
        setState={(name, w3w, images) => {
          try {
            setState(data.id, data.fulfillmentLevel, name, w3w, images);
            setOpen("");
            setDetails("");
          } catch (err) {}
        }}
        open={open === "states"}
        onClose={() => {
          setOpen("");
          setDetails("");
        }}
        state={fEvent.currentState}
      />

      <ListRow
        onClick={() => {
          history.push(`/events/${data.id}`);

          document.title = "RAVENT APP :: Events :: Details";
        }}
        template="2em 0.3fr 0.7fr 0.4fr 1fr 0.7fr"
        hover={true}
        key={data.id}
        boxShadow
        borderRadius="6px"
      >
        <div className={styles.normal} onClick={(e) => e.stopPropagation()}>
          <Checkbox checked={isSelected} onChange={onCheckboxChange} />
        </div>
        <div className={styles.normalBold}>
          <FlexContainer
            justifyContent="flex-start"
            alignItems="flex-start"
            flexDirection="column"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: data.orderExternalId ? "0 0 0.2em 0" : "",
              }}
              className={styles.bold}
            >
              <div style={{ marginRight: "0.3em" }}>
                <StatusBadgePoint
                  point
                  status={
                    data.state === "pending"
                      ? "DISABLED"
                      : data.state === "running"
                      ? "active"
                      : data.state === "failed"
                      ? "unpaid"
                      : "paid"
                  }
                />
              </div>

              {`#${data.orderShortId}`}
            </div>
            <div className={styles.gray}>{data.orderExternalId}</div>
          </FlexContainer>
        </div>
        <div className={styles.normal}>{`${data.name}`}</div>
        <ListLabel
          value={formatDate(new Date(data.datetime), "MIDDLEWHOURYEAR")}
          textAlign="left"
        />
        <div className={styles.center}>
          {data.colorizedTags?.map(
            (
              item: {
                key: string;
                settings: { tagClassification: string; color: string };
              },
              index
            ) => (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  onTagClick(item.key);
                }}
                style={{ margin: "0 0.2em 0.2em 0" }}
                key={index}
              >
                {formatOptionKey(
                  item.key,
                  item.settings && item.settings.color
                    ? item.settings.color
                    : "blue"
                )}
              </div>
            )
          )}
        </div>
        <div>
          <div className={styles.spacebetweenNormal}>
            <div style={{ cursor: "pointer" }}>
              {fEvent.currentState && fEvent.currentState === "not-set" ? (
                <StatesIconBlue
                  onClick={(e) => {
                    e.stopPropagation();
                    setDetails(data.id);
                    setOpen("states");
                  }}
                  className={styles.iconDelete}
                />
              ) : (
                <StatesIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setDetails(data.id);
                    setOpen("states");
                  }}
                  className={styles.iconDelete}
                />
              )}
            </div>
            <Popover
              placement="left"
              content={
                <>
                  {data.fulfillmentTeamId ? (
                    <div style={{ minWidth: "15em" }}>
                      <FlexContainer
                        padding="0 0 0.5em 0"
                        justifyContent="space-between"
                      >
                        <Text
                          text={t("component.orderLocation.team")}
                          fontWeight={700}
                        />
                        <Button
                          size="small"
                          theme="white"
                          onClick={() => updateTeam(data.id, "unset")}
                          label={t("component.orderLocation.remove")}
                        />
                      </FlexContainer>
                      {data.fulfillmentTeamName}
                      <div className={styles.borderTop} />
                      <FlexContainer
                        padding="0 0 0.5em 0"
                        justifyContent="space-between"
                      >
                        <Text
                          text={t("component.orderLocation.fulfiller")}
                          fontWeight={700}
                        />
                        {data.fulfillerId ? (
                          <Button
                            size="small"
                            theme="white"
                            label={t("component.orderLocation.remove")}
                            onClick={(e) => {
                              e.stopPropagation();
                              updateFulfiller(data.id, "unset");
                            }}
                          />
                        ) : (
                          <Button
                            size="small"
                            theme="white"
                            label={t("component.orderLocation.set")}
                            onClick={(e) => {
                              e.stopPropagation();
                              setOpen("diveshop");
                            }}
                          />
                        )}
                      </FlexContainer>

                      {data.fulfillerId
                        ? data.fulfillerName
                        : t("component.orderLocation.noFulfillerAssigned")}
                    </div>
                  ) : (
                    <div style={{ maxWidth: "12em" }}>
                      <FlexContainer justifyContent="space-between">
                        <Text
                          text={t("component.orderLocation.team")}
                          fontWeight={700}
                        />
                      </FlexContainer>
                      <div>{t("component.orderLocation.noTeamAssigned")}</div>
                    </div>
                  )}
                </>
              }
            >
              {data.fulfillmentTeamId && data.fulfillerId ? (
                <FulfillerBlueIcon
                  style={{ cursor: "pointer" }}
                  className={styles.icon}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen("diveshop");
                  }}
                />
              ) : data.fulfillmentTeamId ? (
                <FulfillerYellowIcon
                  style={{ cursor: "pointer" }}
                  className={styles.icon}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen("diveshop");
                  }}
                />
              ) : (
                <FulfillerIcon
                  style={{ cursor: "pointer" }}
                  className={styles.icon}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen("diveshop");
                  }}
                />
              )}
            </Popover>

            <Popover
              placement="left"
              content={
                <div>
                  {data.assetName ? (
                    <div style={{ minWidth: "15em" }}>
                      <FlexContainer
                        padding="0 0 0.5em 0"
                        justifyContent="space-between"
                      >
                        <Text
                          text={t("component.orderLocation.asset")}
                          fontWeight={700}
                        />
                        <Button
                          size="small"
                          theme="white"
                          label={t("component.orderLocation.remove")}
                          onClick={() => updateAsset(data, "unset")}
                        />
                      </FlexContainer>

                      {data.assetName}
                    </div>
                  ) : (
                    <div style={{ maxWidth: "12em" }}>
                      <FlexContainer justifyContent="space-between">
                        <Text
                          text={t("component.orderLocation.asset")}
                          fontWeight={700}
                        />
                      </FlexContainer>
                      <div>{t("component.orderLocation.noAssetAssigned")}</div>
                    </div>
                  )}
                </div>
              }
            >
              <div style={{ cursor: "pointer" }}>
                {data.assetName ? (
                  <AssetBlue
                    onClick={(e) => {
                      e.stopPropagation();
                      setAsset(true);
                    }}
                    className={styles.iconDelete}
                  />
                ) : (
                  <AssetIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      setAsset(true);
                    }}
                    className={styles.iconDelete}
                  />
                )}
              </div>
            </Popover>
            <MarkEvent
              state={data.state}
              loading={{
                loading: data.id === loadingState.eventId,
                state: loadingState.state,
              }}
              id={data.id}
              setNew={setNew}
            />
          </div>
        </div>
      </ListRow>
    </>
  );
};

export default EventItemNormal;
