import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { Card } from "../../components/Card/Card";
import { Button } from "../../components/Button";
import { ContainerTitle } from "../../components/ContainerTitle";

import { Loading } from "../../components/Loading";
import DeleteModal from "../../components/DeleteModal";
import styles from "./styles.module.css";
import { BankAccount, FOPSet } from "../../utils/types";
import {
  addFOP,
  addFOPtoFOPSet,
  defaultAccount,
  defaultFOPSet,
  deleteFOPfromFOPSet,
  getFOPSet,
  updateFOP,
  updateFOPSetName,
} from "../../services/fop";
import FOPCard from "../../components/FOPCard";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { integrationList } from "../../store/integrations";
import message from "antd/lib/message";
import FOPForm from "../../components/FOPForm";
import { fopsCatalogList } from "../../store/fopsCatalog";
import ExistingForm from "../../components/FOPForm/ExistingForm";
import { fopsList } from "../../store/fops";
import APIError from "../../utils/APIError";
import { useTranslation } from "react-i18next";
import { EmptyList } from "../../components/List/EmptyList";
import { ButtonsContainer } from "../../components/ButtonsContainer";
import { BackLink } from "../../components/BackLink";
import { EditableTitle } from "../../components/EditableTitle";

interface RouteParams {
  id: string;
}

interface Props extends RouteComponentProps<RouteParams> {}

const FOPSetDetail: React.FC<Props> = ({ match }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const integrations = useSelector((state: RootState) => state.integrations);
  const catalogs = useSelector((state: RootState) => state.fopsCatalog);
  const fops = useSelector((state: RootState) => state.fops);

  const [fopSet, setFopSet] = useState<FOPSet>(defaultFOPSet);
  const [loadError, setError] = useState("");
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState("");

  const [loading, setLoading] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [account, setAccount] = useState<BankAccount>(defaultAccount);

  const load = useCallback(async () => {
    dispatch(
      integrationList({
        page: 0,
        records_per_page: 100,
        supplierType: "payment",
        supplierName: supplierName,
      })
    );
    dispatch(fopsCatalogList());
    dispatch(fopsList());

    try {
      setLoading("detail");
      const res = await getFOPSet(match.params.id);
      setFopSet(res);
      setLoading("");
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        setError(err.message);
      }
    }
  }, [match.params.id, dispatch, supplierName]);

  useEffect(() => {
    load();
  }, [match.params.id, load]);

  useEffect(() => {
    document.title = "RAVENT APP :: FOP Set :: Details";
  });

  const onChange = <P extends keyof BankAccount>(
    prop: P,
    value: BankAccount[P]
  ) => {
    setAccount({ ...account, [prop]: value });
  };

  const onChangeFOPSET = <P extends keyof FOPSet>(
    prop: P,
    value: FOPSet[P]
  ) => {
    setFopSet({ ...fopSet, [prop]: value });
  };

  const onActiveUpdate = async (id: string, value: boolean) => {
    try {
      await updateFOP(id, { active: value });
      message.success({
        content: t("container.fopsetsDetail.successStatusUpdated"),
        className: "custom-class",
        style: {
          marginTop: "3em",
        },
      });

      load();
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onChangeBankAccount = async (id: string, bankData: BankAccount) => {
    try {
      setLoading("account");
      await updateFOP(id, {
        data: {
          account: bankData,
        },
      });
      message.success({
        content: t("container.fopsetsDetail.successBankUpdated"),
      });
      setLoading("");
      load();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onCreate = async (catalogId: string, round: string) => {
    try {
      setLoading("create");
      await addFOP(catalogId, round, account);
      message.success({
        content: t("container.fopsetsDetail.successFOPCreated"),
        className: "custom-class",
        style: {
          marginTop: "3em",
        },
      });
      setLoading("");

      load();
    } catch (err) {
      setLoading("");

      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onAddFOP = async (fopId: string) => {
    try {
      setLoading("add");
      await addFOPtoFOPSet(match.params.id, fopId);
      message.success({
        content: t("container.fopsetsDetail.successFOPadded"),
        className: "custom-class",
        style: {
          marginTop: "3em",
        },
      });
      setLoading("");

      load();
    } catch (err) {
      setLoading("");

      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onRemove = async (id: string) => {
    try {
      setLoading(id);
      await deleteFOPfromFOPSet(match.params.id, id);
      message.success(t("container.fopsetsDetail.sucessDeleted"));
      setLoading("");
      load();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onChangeName = async () => {
    try {
      setLoading("name");
      await updateFOPSetName(match.params.id, fopSet.name);
      message.success(t("container.fopsetsDetail.nameUpdated"));
      setLoading("");
      setEdit(false);
      load();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  if (!fopSet && loadError) {
    return (
      <EmptyList
        buttonText={t("container.fopsetsDetail.back")}
        onNewClick={() => history.push("/payments/fopSets")}
        instructions={""}
        text={t("container.fopsetsDetail.error")}
        error={loadError}
      />
    );
  }

  if (!fopSet) {
    return <Loading />;
  }

  return (
    <LayouWithSideBar>
      <div className={styles.container}>
        <FlexContainer
          padding="1em 0 0em 0"
          justifyContent="space-between"
          alignItems="center"
        >
          <BackLink
            label={t("container.fopsetsDetail.backFOP")}
            onClick={() => history.push("/payments")}
          />

          {edit ? (
            <ButtonsContainer>
              <Button
                theme="white"
                label={t("container.fopsetsDetail.cancel")}
                onClick={() => setEdit(false)}
              />
              <Button
                label={
                  loading === "name"
                    ? "..."
                    : `${t("container.fopsetsDetail.save")}`
                }
                onClick={() => onChangeName()}
              />
            </ButtonsContainer>
          ) : (
            <ButtonsContainer>
              <Button
                theme="red"
                label={t("container.fopsetsDetail.delete")}
                onClick={() => setOpen("delete")}
              />
              <Button
                label={t("container.fopsetsDetail.edit")}
                onClick={() => setEdit(true)}
              />
            </ButtonsContainer>
          )}
        </FlexContainer>
        <FOPForm
          onCreate={(id: string, round: string) => onCreate(id, round)}
          loading={loading === "create"}
          account={account}
          onChangeAccount={onChange}
          fopCatalog={catalogs.list.data}
          open={open === "create"}
          onClose={() => {
            setAccount(defaultAccount);
            setOpen("");
          }}
        />

        <ExistingForm
          onAddToSet={(id: string) => onAddFOP(id)}
          loading={loading === "add"}
          fops={fops.list.data}
          open={open === "add"}
          onClose={() => setOpen("")}
        />

        <DeleteModal
          open={open === "delete"}
          onDelete={() => {}}
          onCancel={() => setOpen("")}
          value={t("container.fopsetsDetail.deleteWarning")}
        />
        {edit ? (
          <div className={styles.title}>
            <EditableTitle
              value={fopSet.name}
              onChange={(value) => onChangeFOPSET("name", value)}
            />
          </div>
        ) : (
          <ContainerTitle
            padding="1em 0 1.5em 0"
            size="medium"
            label={fopSet.name}
          />
        )}
        {fopSet.fops.length < 1 && loading !== "detail" ? (
          <Card>
            <ContainerTitle
              padding="0 0 2em 0"
              size="small"
              label={t("container.fopsetsDetail.fopLabel")}
              count={fopSet.fops.length.toString()}
            />
            <EmptyList
              instructions={t("container.fopsetsDetail.instructions")}
              label={t("container.fopsetsDetail.add")}
              onClick={() => setOpen("add")}
            />
          </Card>
        ) : (
          <Card containerClassname={styles.cardWrapper}>
            <FlexContainer padding="0 0 2em 0" justifyContent="space-between">
              <ContainerTitle
                size="small"
                label={t("container.fopsetsDetail.fopLabel")}
                count={fopSet.fops.length.toString()}
              />
              <ButtonsContainer>
                <Button
                  label={t("container.fopsetsDetail.add")}
                  onClick={() => setOpen("add")}
                />
              </ButtonsContainer>
            </FlexContainer>

            {fopSet.fops.map((item) => (
              <FOPCard
                round={item.round}
                noBorder={false}
                list={true}
                loading={loading === item.id ? item.id : loading}
                setSupplierName={() => setSupplierName(item.supplierName)}
                key={item.id}
                onEditBank={(id, bankAccount) =>
                  onChangeBankAccount(id, bankAccount)
                }
                onRemove={(id: string) => onRemove(item.id)}
                bankAccountData={item.data.account}
                integrationRequired={item.integrationRequired}
                onEnabled={(id: string, value: boolean) =>
                  onActiveUpdate(id, value)
                }
                integrationName={
                  integrations.list.data.find(
                    (integration) =>
                      integration.supplierName === item.supplierName
                  )?.supplierName
                }
                supplierName={item.supplierName}
                supplierService={item.supplierService}
                type={item.type}
                active={item.active}
                id={item.id}
              />
            ))}
          </Card>
        )}
      </div>
    </LayouWithSideBar>
  );
};

export default FOPSetDetail;
