import React from "react";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { ReactComponent as AssignIcon } from "../../assets/assign.svg";
import { ReactComponent as DeleteIcon } from "../../assets/On.svg";

import bike from "../../assets/assets/bike.svg";
import ferry from "../../assets/assets/ferry.svg";
import IOT from "../../assets/assets/IOT.svg";
import locker from "../../assets/assets/locker.svg";
import moped from "../../assets/assets/moped.svg";
import motorcycle from "../../assets/assets/motorcycle.svg";
import other from "../../assets/assets/other.svg";
import scooter from "../../assets/assets/scooter.svg";
import van from "../../assets/assets/VAN.svg";
import ship from "../../assets/assets/ship.svg";
import tools from "../../assets/assets/tools.svg";
import truck from "../../assets/assets/truck.svg";
import vehicle from "../../assets/assets/vehicle.svg";
import bus from "../../assets/assets/bus.svg";
import limo from "../../assets/assets/limo.svg";
import suv from "../../assets/assets/SUV.svg";

import Text from "../Text";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";

interface Props {
  name: string;
  subtype: string;
  onClick: () => void;
  onDelete: () => void;
  border?: boolean;
}

const AssignAsset: React.FC<Props> = ({
  name,
  subtype,
  onClick,
  border,
  onDelete,
}) => {
  const { t } = useTranslation();

  const typeIconMap: Record<string, string> = {
    vehicle: vehicle,
    van: van,
    truck: truck,
    moped: moped,
    motorcycle: motorcycle,
    scooter: scooter,
    bike: bike,
    ferry: ferry,
    ship: ship,
    IOT: IOT,
    locker: locker,
    tools: tools,
    other: other,
    bus: bus,
    limo: limo,
    suv: suv,
  };

  return (
    <div>
      <InputLabel label={t("component.assetModal.asset")} />
      <div
        className={
          border ? styles.definitionContainerBorder : styles.definitionContainer
        }
      >
        <FlexContainer justifyContent="space-between">
          <FlexContainer justifyContent="flex-start" alignItems="flex-start">
            <div
              style={{
                padding: "0.5em",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#F3F9FE",
                marginRight: "0.5em",
                borderRadius: 8,
                width: 40,
                height: 40,
              }}
            >
              <img className={styles.iconType} src={typeIconMap[subtype]} alt="" />
            </div>
            <FlexContainer
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Text color="#000" fontWeight={700} text={name} />
            </FlexContainer>
          </FlexContainer>
          <FlexContainer justifyContent="flex-end">
            <div className={styles.iconContainer}>
              <AssignIcon style={{ cursor: "pointer" }} onClick={onClick} />
            </div>
            <div style={{ marginLeft: "1em" }} className={styles.iconContainer}>
              <DeleteIcon style={{ cursor: "pointer" }} onClick={onDelete} />
            </div>
          </FlexContainer>
        </FlexContainer>
      </div>
    </div>
  );
};

export default AssignAsset;
