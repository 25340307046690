const translation = {
  en: {
    createNew: "Create New Integration",
    instructions:
      "There is not an integration for this Form of Payment. Please Configure the integration.",
  },
  es: {
    createNew: "Crear nueva integración",
    instructions:
      "No hay una integración para esta forma de pago. Configure la integración.",
  },
  de: {},
};

export default translation;
