import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./styles";
import logo from "../../assets/logo.svg";
import mountains from "../../assets/login/mountains.jpg";
import { GridContainer } from "../../components/GridContainer/GridContainer";

import {
  isValidEmail,
  isValidPhone,
  sendResetUsername,
} from "../../services/users";
import APIError from "../../utils/APIError";
import { useTranslation } from "react-i18next";
import { InputText } from "../../components/Inputs/InputText";
import { Button } from "../../components/Button";
import { message } from "antd";

const ResetPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [msg, setMessage] = useState("");
  const [username, setUsername] = useState("");
  const [success, setSuccess] = useState(false);
  const [valid, setValid] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    document.title = "RAVENT APP :: Reset Password";
  });

  async function sendMail(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (loading) {
      return;
    }
    try {
      e.preventDefault();
      setLoading(true);

      const active = /^\d+$/.test(username) ? "phone" : "email";
      if (username && active === "phone") {
        setValid(isValidPhone(username));
      } else if (username) {
        setValid(isValidEmail(username));
      }
      if (active === "email") {
        if (!valid) {
          setMessage(t("container.resetPassword.invalidEmail"));
          setLoading(false);
          return;
        }
        await sendResetUsername(username);

        setUsername("");
        setLoading(false);
        setSuccess(true);
      } else {
        if (!valid) {
          setMessage(t("container.resetPassword.invalidPhone"));
          setLoading(false);
          return;
        }
        const fullPhoneNumber = `${username}@ravent.com`;

        const response = await sendResetUsername(fullPhoneNumber);
        setUsername("");
        setLoading(false);
        setSuccess(true);
        return response;
      }
    } catch (err) {
      setLoading(false);
      if (err instanceof APIError) {
        message.error(
          `${err.message} ${err.messages}` || "An unexpected error ocurred"
        );
      }
    }
  }

  return (
    <div className={styles.container}>
      <GridContainer
        columns="1fr 1fr"
        justifyContent="center"
        alignItems="center"
      >
        <div className={styles.imageContainer}>
          <img src={mountains} alt="" className={styles.image} />
        </div>
        <div>
          {success ? (
            <div className={styles.inputsContainer}>
              <div className={styles.logoContainer}>
                <img src={logo} alt="logo" className={styles.logo} />
              </div>
              <div className={styles.subtitle}>
                {t("container.resetPassword.success")}
              </div>
              <div className={styles.text}>
                {t("container.resetPassword.instructionsSuccess")} {` `}
                {username} {t("container.resetPassword.changeInstructions")}
              </div>
              <Link className={styles.link} to="/">
                {t("container.resetPassword.backToLogin")}
              </Link>
            </div>
          ) : (
            <div className={styles.inputsContainer}>
              <div className={styles.logoContainer}>
                <img src={logo} alt="logo" className={styles.logo} />
              </div>
              <div className={styles.subtitle}>
                {t("container.resetPassword.resetPasswordLabel")}
              </div>
              <div className={styles.text}>
                {t("container.resetPassword.instructions")}
              </div>
              <div>
                <InputText
                  onFocus={() => setMessage("")}
                  label={t("container.resetPassword.emailPhone")}
                  onChange={(value) => setUsername(value)}
                  value={username}
                  error={msg}
                />
                <div>
                  <Button
                    width="100%"
                    label={
                      loading
                        ? "..."
                        : `${t("container.resetPassword.resetPasswordLabel")}`
                    }
                    onClick={(e) => {
                      if (loading) {
                        return;
                      }
                      sendMail(e);
                    }}
                  />
                </div>
                <Link className={styles.link} to="/">
                  {t("container.resetPassword.backToLogin")}
                </Link>
              </div>
            </div>
          )}

          <div className={styles.footer}>
            <span className={styles.footerText}>
              {t("container.resetPassword.rightsReserved")}
            </span>{" "}
          </div>
        </div>
      </GridContainer>
    </div>
  );
};

export default ResetPassword;
