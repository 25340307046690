import React, { useState } from "react";
import { paymentTypeIconMap, refundDetails } from "../../utils/payment";

import { formatDate } from "../../utils/times";
import { ReactComponent as Receipt } from "../../assets/receipt.svg";
import SendModal from "../SendModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { RefundTypes } from "@innomius/ravent-typescript-types";
import { normalGray } from "../../utils/colors";
import RefundDetail from "../RefundDetail";
import styles from "./styles";
import { numberFormat } from "../../utils/format";
import { useTranslation } from "react-i18next";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { StatusBadgePoint } from "../StatusBadgePoint/StatusBagePoint";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";
import { FlexContainer } from "../FlexContainer/FlexContainer";

interface RefundListProps {
  data: RefundTypes;
}

const RefundItem: React.FC<RefundListProps> = ({ data }) => {
  const [open, setOpen] = useState("");
  const [chevron, setChevron] = useState(false);

  const { t } = useTranslation();

  return (
    <ListRow
      showDetail={open === "details"}
      detail={
        <RefundDetail
          onClose={() => {
            setOpen("");
            setChevron(!chevron);
          }}
          data={data}
        />
      }
      template="1fr 1fr 1fr 1.5fr 1fr 1fr 1fr"
      hover={true}
      key={data.id}
    >
      <div
        className={styles.normal}
        onClick={() => {
          setChevron(!chevron);
          setOpen(chevron ? "" : "details");
        }}
      >
        <FontAwesomeIcon
          icon={chevron ? faChevronUp as IconProp : faChevronDown as IconProp}
          className={styles.icon}
          color={normalGray}
        />
        {data.shortPaymentId}
      </div>
      <div className={styles.iconContainer}>
        <img
          className={styles.icon}
          src={paymentTypeIconMap[data.type]}
          alt=""
        />
        {data.type}
      </div>
      {data ? (
        <ListLabel value={`${refundDetails(data)}`} textAlign="left" />
      ) : (
        <div className={styles.center}>"-"</div>
      )}
      <ListLabel
        value={formatDate(data.timestamp, "MIDDLE")}
        textAlign="left"
      />
      <div className={styles.center}>{`${data.amount.currency} ${numberFormat(
        data.amount.value
      )}`}</div>
      <div className={styles.center}>
        <StatusBadgePoint status="pending" />
      </div>
      <FlexContainer justifyContent="space-evenly">
        <SendModal
          title={t("component.orderRefundList.refund")}
          id={data.id}
          onSend={() => {}}
          onCancel={() => setOpen("")}
          open={open === "send"}
        />
        <Receipt
          onClick={() => setOpen("send")}
          className={styles.receiptContainer}
        />
      </FlexContainer>
    </ListRow>
  );
};

export default RefundItem;
