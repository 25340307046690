import { stylesheet } from "typestyle";
import { black, darkBlack, lightGrey, normalGray } from "../../utils/colors";

const styles = stylesheet({
  container: {
    minHeight: "100vh",
  },
  wrapperCard: {
    padding: "2em",
  },
  editorDetails: {
    height: "20em",
    marginBottom: "1em",
    width: "100%",
  },

  editorBox: {
    width: "100%",
    height: "15em",
    border: "1px solid #dce1e6",
  },
  card: {
    padding: "1em",
    cursor: "pointer",
  },
  title: {
    color: darkBlack,
    fontWeight: "bold",
    fontSize: "1.1em",
  },
  label: {
    fontSize: "1em",
    color: normalGray,
    fontWeight: 700,
    padding: "0.8em 0 0.4em 0",
  },
  text: {
    fontSize: "1.1em",
    color: darkBlack,
    fontWeight: 400,
  },
  number: {
    fontSize: "1.1em",
    color: black,
    fontWeight: 400,
  },
  select: {
    minWidth: "11em",
  },
  mapContainer: {},
  imageContainer: {
    borderRadius: "7px",
    height: "11em",
    width: "100%",
  },
  userContainer: {
    width: "2em",
    marginRight: "1em",
  },
  infoContainer: {
    padding: "3em 0 2em 0",
  },
  infoContainer2: {
    padding: "0em",
  },
  imageWrapper: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  line: {
    width: "100%",
    height: "1px",
    margin: "1em 0 1em 0",
    background: lightGrey,
  },
  day: {
    color: black,
    fontSize: "1.1em",
    fontWeight: 600,
    textTransform: "capitalize",
  },
  hour: {
    color: black,
    fontSize: "1.1em",
    fontWeight: 400,
  },
  icon: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "0.9em",
    color: "#1fa0ff",
  },
  editableContainer: {
    width: "50%",
  },
  cardMiddle: {
    margin: "1em 0em",
    padding: "2em",
  },
});

export default styles;
