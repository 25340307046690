import React from "react";

import { Card } from "../Card/Card";
import {
  languagesOptions,
  numberFormatingOptions,
  dateFormatingOptions,
} from "../../constants/settings";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { timezones } from "../../constants/timezones";
import InputTextDropdown from "../Inputs/InputTextDropdown";
import { InputSelect } from "../Inputs/InputSelect";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";

export interface Props {
  language: string;
  timeZone: string;
  numberFormat: string;
  dateFormat: string;
  onChangeNumberFormat: (number: string) => void;
  onChangeDateFormat: (date: string) => void;
  onChangeTimeZone: (timezone: string) => void;
  onChangeLanguage: (lang: string) => void;
}

const EditGlobal: React.FC<Props> = ({
  language,
  timeZone,
  numberFormat,
  dateFormat,
  onChangeDateFormat,
  onChangeNumberFormat,
  onChangeTimeZone,
  onChangeLanguage,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Card containerClassname={styles.card}>
        <ContainerTitle
          padding="0 0 1em 0"
          size="xsmall"
          label={t("component.editGlobal.languages")}
        />
        <InputSelect
          label={t("component.editGlobal.preferredLanguage")}
          value={language}
          options={languagesOptions}
          onChange={(value) => onChangeLanguage(value)}
        />
        <GridContainer padding="1em 0 1em 0" gap="1em" columns="1fr 1fr">
          <InputSelect
            label={t("component.editGlobal.dateFormating")}
            value={dateFormat}
            options={dateFormatingOptions}
            onChange={(value) => onChangeDateFormat(value)}
          />
          <InputSelect
            label={t("component.editGlobal.numberFormating")}
            value={numberFormat}
            options={numberFormatingOptions}
            onChange={(value) => onChangeNumberFormat(value)}
          />
        </GridContainer>
        <InputTextDropdown
          label={t("component.editGlobal.timeZone")}
          showError={false}
          value={timeZone}
          top={"calc(100% + 1em)"}
          options={timezones.map((item) => ({
            value: item,
            label: item,
          }))}
          onChange={(value) => onChangeTimeZone(value)}
        />
      </Card>
    </div>
  );
};

export default EditGlobal;
