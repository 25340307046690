import { stylesheet } from "typestyle";
import { lightGrey } from "../../utils/colors";

const styles = stylesheet({
  filterContainer: {
    padding: "1em",
    background: "#fff",
    minHeight: "100vh",
  },

  filterContainerShrink: {
    padding: "1em",
    background: "#fff",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  dot: {
    width: "10px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center', 
    fontSize: "7px",
    height: "10px",
    borderRadius: "10px",
    background: lightGrey,
  },

  icon: { width: "18px" },
  link: {
    color: "#1FA0FF",
    marginLeft: "1em",
    cursor: "pointer",
  },

  iconShrink: {
    width: "1.5em",
    cursor: "pointer",
  },
  textHorizonatl: {
    fontSize: "20px",
    marginTop: "1em",
    fontWeight: 700,
    transform: "rotate(270deg)",
  },
  iconShrinkTransform: {
    width: "1.5em",
    cursor: "pointer",
    transform: "rotate(180deg)",
  },
  inputSelect: {
    minWidth: "20em",
  },
  buttonText: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  searchContainer: {
    width: "60%",
  },
  dateContainer: {
    paddingRight: "1em",
  },
  searchContainerText: {
    width: "60%",
    marginRight: "2em",
  },
  timezone: {
    minWidth: "20em",
  },
  calendar: {
    fontFamily: "Lato",
  },
  radioContainer: {
    marginLeft: "1em",
  },
  option: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    padding: " 0.5em",
    height: "2.5em",
    color: " #333",
  },
});

export default styles;
