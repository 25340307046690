import ContentLoader from "react-content-loader";
import styles from "./styles";

const backgroundColor = "#f3f3f3";
const foregroundColor = "#ecebeb";

export const OrderListSkeleton = () => (
  <div className={styles.svgContainer}>
    <ContentLoader
      width={"100%"}
      height={400}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="0%" y="5%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="10%" y="5%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="23%" y="5%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="40%" y="5%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="54%" y="5%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="75%" y="5%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="89%" y="5%" rx="3" ry="3" width="5vw" height="1.2vh" />

      <rect x="0%" y="15%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="10%" y="15%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="23%" y="15%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="40%" y="15%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="54%" y="15%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="75%" y="15%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="89%" y="15%" rx="3" ry="3" width="5vw" height="1.2vh" />

      <rect x="0%" y="25%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="10%" y="25%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="23%" y="25%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="40%" y="25%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="54%" y="25%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="75%" y="25%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="89%" y="25%" rx="3" ry="3" width="5vw" height="1.2vh" />

      <rect x="0%" y="35%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="10%" y="35%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="23%" y="35%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="40%" y="35%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="54%" y="35%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="75%" y="35%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="89%" y="35%" rx="3" ry="3" width="5vw" height="1.2vh" />

      <rect x="0%" y="45%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="10%" y="45%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="23%" y="45%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="40%" y="45%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="54%" y="45%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="75%" y="45%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="89%" y="45%" rx="3" ry="3" width="5vw" height="1.2vh" />

      <rect x="0%" y="55%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="10%" y="55%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="23%" y="55%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="40%" y="55%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="54%" y="55%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="75%" y="55%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="89%" y="55%" rx="3" ry="3" width="5vw" height="1.2vh" />

      <rect x="0%" y="65%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="10%" y="65%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="23%" y="65%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="40%" y="65%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="54%" y="65%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="75%" y="65%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="89%" y="65%" rx="3" ry="3" width="5vw" height="1.2vh" />

      <rect x="0%" y="75%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="10%" y="75%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="23%" y="75%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="40%" y="75%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="54%" y="75%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="75%" y="75%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="89%" y="75%" rx="3" ry="3" width="5vw" height="1.2vh" />

      <rect x="0%" y="85%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="10%" y="85%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="23%" y="85%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="40%" y="85%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="54%" y="85%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="75%" y="85%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="89%" y="85%" rx="3" ry="3" width="5vw" height="1.2vh" />

      <rect x="0%" y="95%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="10%" y="95%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="23%" y="95%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="40%" y="95%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="54%" y="95%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="75%" y="95%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="89%" y="95%" rx="3" ry="3" width="5vw" height="1.2vh" />
    </ContentLoader>
  </div>
);

export const DeliveryListSkeleton = () => (
  <div className={styles.svgContainer}>
    <ContentLoader
      width={"100%"}
      height={200}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="248" y="76" rx="3" ry="3" width="88" height="6" />
      <rect x="418" y="79" rx="3" ry="3" width="52" height="6" />
      <rect x="24" y="78" rx="3" ry="3" width="178" height="6" />
      <rect x="23" y="127" rx="3" ry="3" width="178" height="6" />
      <rect x="245" y="128" rx="3" ry="3" width="88" height="6" />
      <rect x="515" y="76" rx="3" ry="3" width="52" height="6" />
      <rect x="422" y="135" rx="3" ry="3" width="52" height="6" />
      <rect x="513" y="131" rx="3" ry="3" width="52" height="6" />
    </ContentLoader>
  </div>
);

export const OrderStateSummarySkeleton = () => (
  <div className={styles.svgContainer}>
    <ContentLoader
      width={"100%"}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      {" "}
      <rect x="0" y="35" rx="3" ry="3" width="140" height="15" />
      <rect x="0" y="60" rx="3" ry="3" width="35" height="40" />
      <rect x="4%" y="60" rx="3" ry="3" width="100" height="15" />
      <rect x="20%" y="35" rx="3" ry="3" width="140" height="15" />
      <rect x="20%" y="60" rx="3" ry="3" width="35" height="40" />
      <rect x="24%" y="60" rx="3" ry="3" width="100" height="15" />
      <rect x="40%" y="35" rx="3" ry="3" width="140" height="15" />
      <rect x="40%" y="60" rx="3" ry="3" width="35" height="40" />
      <rect x="44%" y="60" rx="3" ry="3" width="100" height="15" />
      <rect x="60%" y="35" rx="3" ry="3" width="140" height="15" />
      <rect x="60%" y="60" rx="3" ry="3" width="35" height="40" />
      <rect x="64%" y="60" rx="3" ry="3" width="100" height="15" />
      <rect x="80%" y="35" rx="3" ry="3" width="140" height="15" />
      <rect x="80%" y="60" rx="3" ry="3" width="35" height="40" />
      <rect x="84%" y="60" rx="3" ry="3" width="100" height="15" />
    </ContentLoader>
  </div>
);

export const PaymentListSkeleton = () => (
  <div className={styles.svgContainer}>
    <ContentLoader
      width={"100%"}
      height={400}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="0%" y="5%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="5%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="5%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="5%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="5%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="15%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="15%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="15%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="15%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="15%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="25%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="25%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="25%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="25%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="25%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="35%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="35%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="35%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="35%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="35%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="45%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="45%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="45%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="45%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="45%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="55%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="55%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="55%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="55%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="55%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
    </ContentLoader>
  </div>
);

export const OfficialMediaListSkeleton = () => (
  <div className={styles.svgContainer}>
    <ContentLoader
      width={"100%"}
      height={400}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="0%" y="5%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="5%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="5%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="5%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="5%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="15%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="15%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="15%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="15%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="15%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="25%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="25%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="25%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="25%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="25%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="35%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="35%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="35%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="35%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="35%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="45%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="45%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="45%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="45%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="45%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="55%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="55%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="55%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="55%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="55%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
    </ContentLoader>
  </div>
);

export const OfferSetListSkeleton = () => (
  <div className={styles.svgContainer}>
    <ContentLoader
      width={"100%"}
      height={400}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="0%" y="5%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="5%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="5%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="5%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="5%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="15%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="15%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="15%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="15%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="15%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="25%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="25%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="25%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="25%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="25%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="35%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="35%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="35%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="35%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="35%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="45%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="45%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="45%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="45%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="45%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="55%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="55%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="55%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="55%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="55%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="65%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="65%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="65%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="65%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="65%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="75%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="75%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="75%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="75%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="75%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="85%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="85%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="85%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="85%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="85%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="95%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="95%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="95%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="95%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="95%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
    </ContentLoader>
  </div>
);

export const OfferListSkeleton = () => (
  <div className={styles.svgContainer}>
    <ContentLoader
      width={"100%"}
      height={400}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="0%" y="5%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="5%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="5%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="5%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="5%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="15%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="15%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="15%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="15%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="15%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="25%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="25%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="25%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="25%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="25%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="35%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="35%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="35%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="35%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="35%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="45%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="45%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="45%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="45%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="45%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="55%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="55%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="55%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="55%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="55%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="65%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="65%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="65%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="65%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="65%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="75%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="75%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="75%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="75%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="75%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="85%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="85%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="85%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="85%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="85%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="0%" y="95%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="23%" y="95%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="46%" y="95%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
      <rect x="64%" y="95%" rx="3" ry="3" width="7vw" height="1.2vh" />
      <rect x="89%" y="95%" rx="3" ry="3" width="3.7vw" height="1.2vh" />
    </ContentLoader>
  </div>
);

export const UserListSkeleton = () => (
  <div className={styles.svgContainer}>
    <ContentLoader
      width={"100%"}
      height={400}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="0%" y="5%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="30%" y="5%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="60%" y="5%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="90%" y="5%" rx="3" ry="3" width="10vw" height="1.2vh" />

      <rect x="0%" y="15%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="30%" y="15%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="60%" y="15%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="90%" y="15%" rx="3" ry="3" width="10vw" height="1.2vh" />

      <rect x="0%" y="25%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="30%" y="25%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="60%" y="25%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="90%" y="25%" rx="3" ry="3" width="10vw" height="1.2vh" />

      <rect x="0%" y="35%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="30%" y="35%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="60%" y="35%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="90%" y="35%" rx="3" ry="3" width="10vw" height="1.2vh" />

      <rect x="0%" y="45%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="30%" y="45%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="60%" y="45%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="90%" y="45%" rx="3" ry="3" width="10vw" height="1.2vh" />

      <rect x="0%" y="55%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="30%" y="55%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="60%" y="55%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="90%" y="55%" rx="3" ry="3" width="10vw" height="1.2vh" />

      <rect x="0%" y="65%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="30%" y="65%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="60%" y="65%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="90%" y="65%" rx="3" ry="3" width="10vw" height="1.2vh" />

      <rect x="0%" y="75%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="30%" y="75%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="60%" y="75%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="90%" y="75%" rx="3" ry="3" width="10vw" height="1.2vh" />

      <rect x="0%" y="85%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="30%" y="85%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="60%" y="85%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="90%" y="85%" rx="3" ry="3" width="10vw" height="1.2vh" />

      <rect x="0%" y="95%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="30%" y="95%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="60%" y="95%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="90%" y="95%" rx="3" ry="3" width="10vw" height="1.2vh" />
    </ContentLoader>
  </div>
);

export const FulfillmentListSkeleton = () => (
  <div className={styles.svgContainer}>
    <ContentLoader
      width={"100%"}
      height={400}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="0%" y="5%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="20%" y="5%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="50%" y="5%" rx="3" ry="3" width="15vw" height="1.2vh" />
      <rect x="90%" y="5%" rx="3" ry="3" width="5vw" height="1.2vh" />

      <rect x="0%" y="15%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="20%" y="15%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="50%" y="15%" rx="3" ry="3" width="15vw" height="1.2vh" />
      <rect x="90%" y="15%" rx="3" ry="3" width="5vw" height="1.2vh" />

      <rect x="0%" y="25%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="20%" y="25%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="50%" y="25%" rx="3" ry="3" width="15vw" height="1.2vh" />
      <rect x="90%" y="25%" rx="3" ry="3" width="5vw" height="1.2vh" />

      <rect x="0%" y="35%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="20%" y="35%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="50%" y="35%" rx="3" ry="3" width="15vw" height="1.2vh" />
      <rect x="90%" y="35%" rx="3" ry="3" width="5vw" height="1.2vh" />

      <rect x="0%" y="45%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="20%" y="45%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="50%" y="45%" rx="3" ry="3" width="15vw" height="1.2vh" />
      <rect x="90%" y="45%" rx="3" ry="3" width="5vw" height="1.2vh" />

      <rect x="0%" y="55%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="20%" y="55%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="50%" y="55%" rx="3" ry="3" width="15vw" height="1.2vh" />
      <rect x="90%" y="55%" rx="3" ry="3" width="5vw" height="1.2vh" />

      <rect x="0%" y="65%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="20%" y="65%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="50%" y="65%" rx="3" ry="3" width="15vw" height="1.2vh" />
      <rect x="90%" y="65%" rx="3" ry="3" width="5vw" height="1.2vh" />

      <rect x="0%" y="75%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="20%" y="75%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="50%" y="75%" rx="3" ry="3" width="15vw" height="1.2vh" />
      <rect x="90%" y="75%" rx="3" ry="3" width="5vw" height="1.2vh" />

      <rect x="0%" y="85%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="20%" y="85%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="50%" y="85%" rx="3" ry="3" width="15vw" height="1.2vh" />
      <rect x="90%" y="85%" rx="3" ry="3" width="5vw" height="1.2vh" />

      <rect x="0%" y="95%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="20%" y="95%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="50%" y="95%" rx="3" ry="3" width="15vw" height="1.2vh" />
      <rect x="90%" y="95%" rx="3" ry="3" width="5vw" height="1.2vh" />
    </ContentLoader>
  </div>
);

export const DiveShopsListSkeleton = () => (
  <div className={styles.svgContainer}>
    <ContentLoader
      width={"100%"}
      height={400}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="0%" y="5%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="20%" y="5%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="45%" y="5%" rx="3" ry="3" width="15vw" height="1.2vh" />
      <rect x="75%" y="5%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="90%" y="5%" rx="3" ry="3" width="5vw" height="1.2vh" />

      <rect x="0%" y="15%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="20%" y="15%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="45%" y="15%" rx="3" ry="3" width="15vw" height="1.2vh" />
      <rect x="75%" y="15%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="90%" y="15%" rx="3" ry="3" width="5vw" height="1.2vh" />

      <rect x="0%" y="25%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="20%" y="25%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="45%" y="25%" rx="3" ry="3" width="15vw" height="1.2vh" />
      <rect x="75%" y="25%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="90%" y="25%" rx="3" ry="3" width="5vw" height="1.2vh" />

      <rect x="0%" y="35%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="20%" y="35%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="45%" y="35%" rx="3" ry="3" width="15vw" height="1.2vh" />
      <rect x="75%" y="35%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="90%" y="35%" rx="3" ry="3" width="5vw" height="1.2vh" />

      <rect x="0%" y="45%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="20%" y="45%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="45%" y="45%" rx="3" ry="3" width="15vw" height="1.2vh" />
      <rect x="75%" y="45%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="90%" y="45%" rx="3" ry="3" width="5vw" height="1.2vh" />

      <rect x="0%" y="55%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="20%" y="55%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="45%" y="55%" rx="3" ry="3" width="15vw" height="1.2vh" />
      <rect x="75%" y="55%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="90%" y="55%" rx="3" ry="3" width="5vw" height="1.2vh" />

      <rect x="0%" y="65%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="20%" y="65%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="45%" y="65%" rx="3" ry="3" width="15vw" height="1.2vh" />
      <rect x="75%" y="65%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="90%" y="65%" rx="3" ry="3" width="5vw" height="1.2vh" />

      <rect x="0%" y="75%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="20%" y="75%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="45%" y="75%" rx="3" ry="3" width="15vw" height="1.2vh" />
      <rect x="75%" y="75%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="90%" y="75%" rx="3" ry="3" width="5vw" height="1.2vh" />

      <rect x="0%" y="85%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="20%" y="85%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="45%" y="85%" rx="3" ry="3" width="15vw" height="1.2vh" />
      <rect x="75%" y="85%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="90%" y="85%" rx="3" ry="3" width="5vw" height="1.2vh" />

      <rect x="0%" y="95%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="20%" y="95%" rx="3" ry="3" width="10vw" height="1.2vh" />
      <rect x="45%" y="95%" rx="3" ry="3" width="15vw" height="1.2vh" />
      <rect x="75%" y="95%" rx="3" ry="3" width="5vw" height="1.2vh" />
      <rect x="90%" y="95%" rx="3" ry="3" width="5vw" height="1.2vh" />
    </ContentLoader>
  </div>
);
