import React, { useState } from "react";

interface LangCtxInterface {
  translations: Record<string, string>;
}

const LangCtx = React.createContext<LangCtxInterface>({
  translations: { mensaje: "message" },
});

interface Props {
  children?: React.ReactNode | React.ReactNode[];
}

export const LangProvider: React.FC<Props> = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = useState<LangCtxInterface>({
    translations: { mensaje: "message" },
  });
  return <LangCtx.Provider value={value}>{children}</LangCtx.Provider>;
};

export function useLang() {
  const lang = React.useContext(LangCtx);
  function getKey(key: string) {
    return lang.translations[key] || key;
  }
  return {
    lang,
    getKey,
  };
}
