const translation = {
  en: {
    userInfo: "User Information",
    name: "Name",
    lastName: "Last Name",
    email: "Email",
    phone: "Phone",
    roles: "Roles",
    assignedVenue: "Assigned Venue",
    associatedTeam: "Associated Team",
    venue: "Venue",
    fopSet: "FOP Set",
    noTags: "You don't have any tags for this user",
    buttonEdit: "Edit Venue",
    externalId: "External ID",
    tags: "Tags",
    enabled: "Enabled",
    disabled: "Disabled",
    active: "Active",
    notEnrolled: "Not Enrolled",
    loginWithPhone: "Login with Phone",
    fulfillmentTeam: "Fulfillment Team",
    notEnroll:
      "Invitation sent to this user and has not yet completed. User information (name/ lastname) will be received when user completes the enrollment process.",
  },
  es: {
    notEnrolled: "No inscrito",
    enabled: "Habilitado",
    disabled: "Deshabilitado",
    active: "Activo",
    loginWithPhone: "Sesión con teléfono",
    fulfillmentTeam: "Equipo de Fulfillment",
    externalId: "ID Externo",
    tags: "Etiquetas",
    associatedTeam: "Equipo Asociado",
    userInfo: "Información del usuario",
    noTags: "No tiene etiquetas para este usuario",

    name: "Nombre",
    lastName: "Apellidos",
    email: "Correo electrónico",
    phone: "Teléfono",
    roles: "Roles",
    assignedVenue: "Recinto asignado",
    venue: "Recinto",
    fopSet: "Conjunto de Formas de Pago",
    buttonEdit: "Editar Recinto",
    notEnroll:
      "Invitación enviada a este usuario y aún no completada. La información del usuario (nombre/apellido) se recibirá cuando el usuario complete el proceso de inscripción.",
  },
  de: {},
};

export default translation;
