const translation = {
  en: {
    ticketing: "Details",
    customerNameMandatory: "Customer Name for tickets is mandatory",
    activation: "Activation",
    type: "Type",
    maxEvents: "Max Activations",
    maxEventsDescription: "Event's amount that a ticket can be activated.",
    lifeTime: "Life Time",
    lifeTimeDescription: "Time after activation",
    amount: "Amount",
    unit: "Unit",
    enabledTicketing: "Tickets Enabled",
    enabledTicketingTrue: "Tickets are enabled",
    enabledTicketingFalse:
      "Tickets are disabled for this Fulfillment Defintion",
    noTicketing: "Ticketing is Disable",
    instructionsEnabled: "To enable ticketing please edit the fulfillment",
    validFrom: "Valid From",
    validUntil: "Valid Until",
    enabled: "Enabled",
    disabled: "Disabled",
    data: "Data",
    description: "Description",
  },
  es: {
    data: "Data",
    description: "Descripción",
    ticketing: "Detalle",
    customerNameMandatory:
      "El nombre del cliente para los boletos es obligatorio",
    activation: "Activación",
    type: "Tipo",
    maxEvents: "Eventos máximos",
    maxEventsDescription: "Cantidad del evento que se puede activar un ticket.",
    lifeTime: "Tiempo de Vida",
    lifeTimeDescription: "Tiempo después de la activación",
    amount: "Cantidad",
    unit: "Unidad",
    enabledTicketing: "Boleto habilitadas",
    enabledTicketingTrue: "Boletos habilitadas",
    enabledTicketingFalse:
      "Los boletos están deshabilitados para esta definición de entrega",
    noTicketing: "La emisión de boletos está deshabilitada",
    instructionsEnabled:
      "Para habilitar la emisión de boletos, edite el entrega",
    validFrom: "Válido desde",
    validUntil: "Válido hasta",
    enabled: "Habilitado",
    disabled: "Deshabilitado",
  },
  de: {},
};

export default translation;
