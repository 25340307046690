import { FulfillmentStateDef } from "@innomius/ravent-typescript-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../Button";
import StatesModal from "../StatesModal";
import styles from "./styles";
import { ContainerTitle } from "../../ContainerTitle";
import { FlexContainer } from "../../FlexContainer/FlexContainer";

interface Props {
  status: string[];
  onFulfilled: () => void;
  lastState?: string;
  id: string;
  passed: string[];
  fulfillmentDate: string;
  orderStates: FulfillmentStateDef[];
  setState: (status: string, w3w: string, images: string[]) => void;
  updateFulfillmentName: (id: string, diveshopId: string) => void;
  fulfillmentNames: { label: string; value: string }[];
  fulfillmentNameId?: string;
  error: string;
  orderlineId: string;
}

const FulfilmentStatus: React.FC<Props> = ({
  status,
  setState,
  lastState,
  orderStates,
  passed,
  updateFulfillmentName,
  fulfillmentNames,
  fulfillmentNameId,
  error,
  orderlineId,
}) => {
  const [open, setOpen] = useState("");

  const { t } = useTranslation();

  const arraysHaveSameElements = (passed: string[], status: string[]) => {
    if (passed.length !== status.length) {
      return false;
    }

    const sortedpassed = [...passed].sort();
    const sortedArr2 = [...status].sort();

    return sortedpassed.every(
      (element, index) => element === sortedArr2[index]
    );
  };

  return (
    <div>
      <ContainerTitle
        label={t("component.orderFulfillment.states")}
        size="xsmall"
      />
      {orderStates.length ? (
        <div className={styles.propertyInstructions}>
          {t("component.orderFulfillment.clickOnActivate")}
        </div>
      ) : (
        <div className={styles.notEnrollContainer}>
          No States are configured. Please add states to your definition.
        </div>
      )}

      <FlexContainer
        flexWrap="wrap"
        padding="1em 0 0 0"
        flexDirection="row"
        justifyContent={"space-start"}
      >
        {status.map((item, index) => (
          <div key={`${item} + ${index}`}>
            {open === item ? (
              <StatesModal
                updateFulfillmentName={updateFulfillmentName}
                fulfillmentNames={fulfillmentNames}
                fulfillmentDefinitionId={fulfillmentNameId || ''}
                error={error}
                orderlineId={orderlineId}
                setState={(name, w3w, images) => {
                  try {
                    setState(name, w3w, images);
                    setOpen("");
                  } catch (err) {}
                }}
                open={open === item}
                onClose={() => setOpen("")}
                state={item}
              />
            ) : null}

            <Button
              label={item}
              theme={
                arraysHaveSameElements(passed, status)
                  ? "light-blue"
                  : lastState === item
                  ? "blue"
                  : "white"
              }
              buttonClassname={styles.insideButton}
              containerClassname={styles.button}
              onClick={() => setOpen(item)}
            />
          </div>
        ))}
      </FlexContainer>
    </div>
  );
};

export default FulfilmentStatus;
