import React from "react";
import { User, Venue } from "@innomius/ravent-typescript-types";

import { useTranslation } from "react-i18next";
import VenueModal from "../UserForm/VenueModal";
import { CreateUserParams } from "../../services/users";
import { ContainerTitle } from "../ContainerTitle";
import { SectionProperty } from "../Section/SectionProperty";
import { GridContainer } from "../GridContainer/GridContainer";
import { Button } from "../Button";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
import { Profile } from "../../utils/types";

interface Props {
  profile: Profile;
  venues: Venue[];
  open: string;
  setOpen: (value: string) => void;
  loading: string;
  onAssign: (id: string) => void;
  onChange: <P extends keyof CreateUserParams>(
    prop: P,
    value: CreateUserParams[P]
  ) => void;
  orgName: string;
}

const ProfileComponent: React.FC<Props> = ({
  profile,
  setOpen,
  open,
  onAssign,
  loading,
  onChange,
  venues,
  orgName,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <VenueModal
          open={open === "edit"}
          onAssign={async (id) => onAssign(id)}
          venues={venues}
          loading={loading === "assign"}
          data={profile as unknown as User}
          onChange={onChange}
          onCancel={() => setOpen("")}
        />
        <ContainerTitle
          padding="0 0 1em 0"
          size="small"
          label={t("container.profileDetail.personalInfo")}
        />
        <SectionProperty
          label={t("container.profileDetail.name")}
          value={profile.firstName}
        />
        <SectionProperty
          label={t("container.profileDetail.lastName")}
          value={profile.lastName}
        />
        <GridContainer gap="1em" columns="1fr 1fr">
          <SectionProperty
            label={t("container.profileDetail.email")}
            value={profile.email}
          />
          <SectionProperty
            label={t("container.profileDetail.phone")}
            value={profile.phone}
          />
        </GridContainer>

        <SectionProperty
          label={t("container.profileDetail.org")}
          value={orgName}
        />

        <div>
          <InputLabel label={t("container.profileDetail.venue")} />

          <Button
            theme="white"
            onClick={() => setOpen("edit")}
            label={
              venues.find((item) => item.id === profile.venueId)?.name || "-"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileComponent;
