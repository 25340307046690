import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  integrationList as integrationListService,
  IntegrationListParams,
  IntegrationsSearchResponse,
} from "../services/integrations";

type IntegrationsSliceState = {
  loading: boolean;
  error: string;
  list: IntegrationsSearchResponse;
};

const initialState: IntegrationsSliceState = {
  loading: true,
  error: "",
  list: {
    page: 0,
    total: 0,
    data: [],
  },
};

export const integrationList = createAsyncThunk(
  "integrations/list",
  async (params: IntegrationListParams) => {
    const response = await integrationListService(params);
    return response;
  }
);

const integrationsSlice = createSlice({
  name: "integrations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(integrationList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(integrationList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.list = action.payload;
    });
    builder.addCase(integrationList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An unexpected error ocurred";
    });
  },
});

export default integrationsSlice.reducer;
