import React from "react";
import { FormComponent } from "../../utils/types";

import styles from "./styles";
import { useTranslation } from "react-i18next";
import SchemaForm from "./SchemaForm";
import { message } from "antd";
import { SideMenu } from "../SideMenu/SideMenu";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { Button } from "../Button";
import { InputText } from "../Inputs/InputText";

interface Props {
  onCancel: () => void;
  open: boolean;
  invoiceId: string;
  refundSchemaElements: FormComponent[];
  onChangeSchema: (data: FormComponent[]) => void;
  loading: boolean;
  webhook: string;
  onIssueRefund: () => void;
}

const IssueRefundDyn: React.FC<Props> = ({
  open,
  onCancel,
  refundSchemaElements,
  onChangeSchema,
  loading,
  webhook,
  invoiceId,
  onIssueRefund,
}) => {
  const { t } = useTranslation();

  return (
    <SideMenu width="50%" open={open} onClose={() => onCancel()}>
      <div className={styles.container}>
        <FlexContainer padding="1em 0 2em 0" justifyContent="space-between">
          <ContainerTitle
            label={t("component.issueRefundDyn.issueRefund")}
            size="medium"
          />
          <FlexContainer justifyContent="flex-start">
            <Button
              onClick={() => {
                onCancel();
              }}
              containerClassname={styles.button}
              theme="white"
              label={t("component.issueRefundDyn.cancel")}
            />
            <Button
              onClick={() => {
                if (!webhook) {
                  message.error(
                    "The venue to which you are associated has not been properly configured to issue a manual refund. Please go to the Integrations Side Menu, open the Invoices tab and insert a valid URL for the Invoice Refund entry."
                  );
                  return;
                }
                onIssueRefund();
              }}
              theme="blue"
              label={loading ? "..." : t("component.issueRefundDyn.issue")}
            />
          </FlexContainer>
        </FlexContainer>
        <InputText
          disabled
          label={t("component.issueRefundDyn.invoiceId")}
          value={invoiceId}
        />
        <SchemaForm
          elements={refundSchemaElements}
          onChange={(elements) => onChangeSchema(elements)}
        />
      </div>
    </SideMenu>
  );
};

export default IssueRefundDyn;
