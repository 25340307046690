const translation = {
  en: {
    successCreation: "Integration was successfully created",
    successUpdated: "Integration was successfully updated",
    successDelete: "Integration was successfully deleted",

    states: "Order States",
    events: "Order Events",
    paymentsEvents: "Payment Events",
    paymentsState: "Payment States",

    integrations: "Integrations",

    cancel: "Cancel",
    save: "Save",
    edit: "Edit",
    orgs: "Organization",
    invoice: "Order Invoices",
    actions: "Actions",
    checkin: "Check-In",
    scheduling: "Scheduling",
    schedules: "Schedules",
    manual: "Actions",
    scheduled: "Schedulded Actions",
    stateMachine: "State Machine Actions",
    others: "Other",
  },
  es: {
    manual: "Acciones",
    scheduled: "Acciones Calendarizadas",
    stateMachine: "Acciones de la Máquina de Estados",
    others: "Otras",
    scheduling: "Planificación",
    successCreation: "La Integración se creó con éxito",
    successDelete: "La Integración se eliminó con éxito",
    successUpdated: "La Integración se actualizó con éxito ",
    integrations: "Integraciones",
    paymentsEvents: "Eventos de Pago",
    paymentsState: "Estados de Pago",
    states: "Estados de la Orden",
    events: "Eventos de la Orden",
    actions: "Acciones",
    cancel: "Cancelar",
    save: "Guardar",
    edit: "Editar",
    orgs: "Organización",
    invoice: "Facturaciones de la Orden",
    checkin: "Check-In",
    schedules: "Programaciones",
  },

  de: {},
};

export default translation;
