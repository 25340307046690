import React from "react";
import { Pagination } from "antd";

export interface PaginationRaventProps {
  current?: number;
  defaultCurrent?: number;
  total?: number;
  page?: number;
  pageSize?: number;
  showSizeChanger?: boolean;
  onShowSizeChange?: (current: number, size: number) => void;
  onChange?: (page: number, pageSize?: number) => void;
  showTotal?: (total: number, range?: number) => void;
}

export const PaginationRavent: React.FC<PaginationRaventProps> = (props) => {
  return (
    <Pagination
      current={props.current}
      defaultCurrent={props.defaultCurrent}
      total={props.total}
      size="default"
      onShowSizeChange={props.onShowSizeChange}
      onChange={props.onChange}
      pageSize={props.pageSize}
      showSizeChanger={props.showSizeChanger}
      pageSizeOptions={[
        "10",
        "50",
        "100",
        "200",
        "300",
        "400",
        "500",
        "1000",
        "4000",
      ]}
    />
  );
};
