import { red } from '../../../constants/colors';
import { stylesheet } from 'typestyle';

export interface ButtonTheme {
  backgroundColor: string;
  backgroundColorHover: string;
  textColor: string;
  textColorHover: string;
  borderColor: string;
  borderColorHover: string;
  disabledBackgroundColor: string;
  disabledBorderColor: string;
  disabledTextColor: string;
  textDecoration?: string;
}

export const lightBlueColors: ButtonTheme = {
  backgroundColor: '#ECFAFF',
  backgroundColorHover: '#A5CDF8',
  textColor: '#0163E7',
  textColorHover: '#ECFAFF',
  borderColor: '#A5CDF8',
  borderColorHover: '#A5CDF8',
  disabledBackgroundColor: '#dce1e6',
  disabledBorderColor: '#dce1e6',
  disabledTextColor: '#999999'
};

export const blueColors: ButtonTheme = {
  backgroundColor: '#1fa0ff',
  backgroundColorHover: '#0091fa',
  textColor: '#fff',
  textColorHover: '#fff',
  borderColor: '#1fa0ff',
  borderColorHover: '#0091fa',
  disabledBackgroundColor: '#dce1e6',
  disabledBorderColor: '#dce1e6',
  disabledTextColor: '#999999'
};

export const whiteColors: ButtonTheme = {
  backgroundColor: '#fff',
  backgroundColorHover: '#dce1e6',
  textColor: '#333',
  textColorHover: '#333',
  borderColor: '#dce1e6',
  borderColorHover: '#dce1e6',
  disabledBackgroundColor: '#dce1e6',
  disabledBorderColor: '#dce1e6',
  disabledTextColor: '#999999'
};

export const redColors: ButtonTheme = {
  backgroundColor: '#fff',
  backgroundColorHover: red,
  textColor: red,
  textColorHover: '#fff',
  borderColor: red,
  borderColorHover: red,
  disabledBackgroundColor: '#dce1e6',
  disabledBorderColor: '#dce1e6',
  disabledTextColor: '#999999'
};

export const orangeColors: ButtonTheme = {
  backgroundColor: '#fff',
  backgroundColorHover: '#FF7832',
  textColor: '#FF7832',
  textColorHover: '#fff',
  borderColor: '#FF7832',
  borderColorHover: '#FF7832',
  disabledBackgroundColor: '#dce1e6',
  disabledBorderColor: '#dce1e6',
  disabledTextColor: '#999999'
};

export const linkColors: ButtonTheme = {
  backgroundColor: '#fff',
  backgroundColorHover: '#fff',
  textColor: '#0ca4ff',
  textColorHover: '#0ca4ff',
  borderColor: '#fff',
  borderColorHover: '#fff',
  disabledBackgroundColor: '#fff',
  disabledBorderColor: '#fff',
  disabledTextColor: '#999999',
  textDecoration: 'underline'
};

export function applyButtonTheme(theme: ButtonTheme) {
  return {
    color: theme.textColor,
    background: theme.backgroundColor,
    borderColor: theme.borderColor,
    borderWidth: 1,
    borderStyle: 'solid',
    online: 'none',
    fontSize: '1em',
    fontWeight: 700,
    minWidth: '7.1em',
    padding: '0.5em 0.6em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 3,
    $nest: {
      '&:hover': {
        background: theme.backgroundColorHover,
        borderColor: theme.borderColorHover,
        color: theme.textColorHover,
        textDecoration: theme.textDecoration
      },
      '&:disabled': {
        background: theme.disabledBackgroundColor,
        borderColor: theme.disabledBorderColor,
        color: theme.disabledTextColor
      }
    }
  };
}

const styles = stylesheet({
  iconContainer: {
    width: '1em',
    height: '1em',
    marginRight: '.3em'
  },
  iconImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },

  smallSize: {
    height: '2em'
  },
  mediumSize: {
    height: '2.5em'
  },
  disable: {
    color: '#999999 !important'
  },
  buttonBlue: applyButtonTheme(blueColors),
  buttonWhite: applyButtonTheme(whiteColors),
  buttonRed: applyButtonTheme(redColors),
  buttonOrange: applyButtonTheme(orangeColors),
  buttonLink: applyButtonTheme(linkColors),
  buttonLightBlue: applyButtonTheme(lightBlueColors)
});

export default styles;
