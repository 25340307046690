const translation = {
  en: {
    successCreation: "User succesfully created.",
    back: "Back to Users",
    cancel: "Cancel",
    save: "Save",
    createUserLabel: "Create User",
  },
  es: {
    successCreation: "Usuario creado con éxito",
    back: "Regresar a Usuarios",
    cancel: "Cancelar",
    save: "Guardar",
    createUserLabel: "Crear Usuario",
  },
  de: {},
};

export default translation;
