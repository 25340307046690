import {
  AddressType,
  FulfillmentDefinition,
  FulfillmentEvent,
  FulfillmentState,
  FulfillmentStateDef,
  PostalAddress,
} from "@innomius/ravent-typescript-types";
import get from "lodash/get";
import { Transition } from "../utils/types";
import { getAccessToken } from "./auth";
import fetch from "../utils/fetch";
import CustomError from "../utils/CustomError";
import multiPartMultipleImages from "../utils/multiPartMultipleImages";
import { isNil, omitBy } from "lodash";
import { dateFilters } from "../utils/times";

export function parseFulfillmentStateDef(data?: unknown): FulfillmentStateDef {
  return {
    actionId: get(data, "actionId", ""),
    name: get(data, "name", ""),
    actionName: get(data, "actionName", ""),
  };
}

export function parseTransition(data?: unknown): Transition {
  return {
    from: get(data, "from", ""),
    to: get(data, "to", ""),
  };
}

export function parseFulfillmentState(data?: unknown): FulfillmentState {
  return {
    fulfillmentDefinitionId: get(data, "fulfillmentDefinitionId", ""),
    id: get(data, "_id", ""),
    name: get(data, "name", ""),
    location: {
      w3w: get(data, "location.w3w", ""),
      type: get(data, "location.type", ""),
      coordinates: {
        latitude: get(data, "location.coordinates.latitude", ""),
        longitude: get(data, "location.coordinates.longitude", ""),
      }
    },
    eventId: get(data, "eventId", ""),
    eventName: get(data, "eventName", ""),
    eventShortId: get(data, "eventShortId", ""),
    assetId: get(data, "assetId", ""),
    assetName: get(data, "assetName", ""),
    numberOfFiles: get(data, "numberOfFiles", 0),
    timestamp: get(data, "createdAt", ""),
    userId: get(data, "createdBy", ""),
    createdByFullName: get(data, "createdByFullName", ""),
  };
}

export function parseFulfillmentDef(data?: unknown): FulfillmentDefinition {
  return {
    id: get(data, "_id", ""),
    name: get(data, "name", ""),
    type: get(data, "type", "locker"),
    fulfillmentStatesDefinitions: get(
      data,
      "fulfillmentStatesDefinitions",
      []
    ).map(parseFulfillmentStateDef),
  };
}

export interface FulfillmentSearchResponse {
  data: FulfillmentDefinition[];
  page: number;
  total: number;
}

export interface FulfillmentListParams {
  page?: number;
  records_per_page?: number;
  venue_id?: string;
}

export async function fulfillmentList(
  params: FulfillmentListParams
): Promise<FulfillmentSearchResponse> {
  const token = await getAccessToken();
  const res = await fetch("/fdefs", token, "GET", null, params);
  return {
    data: res.body ? res.body.hits.map(parseFulfillmentDef) : [],
    page: get(res.body, "page", 0),
    total: get(res.body, "count", 0),
  };
}

export async function readFulfillment(
  id: string
): Promise<FulfillmentDefinition> {
  const token = await getAccessToken();
  const res = await fetch(`/fdefs/${id}`, token);
  const data = parseFulfillmentDef(res.body);
  return data;
}

export async function createFulfillment(
  data: FulfillmentDefinition,
  venueId?: string
): Promise<FulfillmentDefinition> {
  const body = {
    name: data.name,
    type: data.type,
    fulfillmentStatesDefinitions: [],
    venue_id: venueId,
  };
  const token = await getAccessToken();

  const res = await fetch("/fdefs", token, "POST", body);

  const fulfillment = parseFulfillmentDef(res.body);

  return fulfillment;
}

export async function updateFulfillment(
  data: FulfillmentDefinition
): Promise<FulfillmentDefinition> {
  const body = {
    name: data.name,
    type: data.type,
    fulfillmentStatesDefinitions: data.fulfillmentStatesDefinitions,
  };
  const token = await getAccessToken();

  const res = await fetch(`/fdefs/${data.id}`, token, "PATCH", body);
  return res.body;
}

export async function addStateFulfillment(
  fulfillment: FulfillmentDefinition,
  fulfillmentStateDefinition: FulfillmentStateDef,
  fulfillmentStateDefinitions: FulfillmentStateDef[],
  edit: boolean,
  index?: number
): Promise<FulfillmentDefinition> {
  const states = fulfillmentStateDefinitions || [];

  if (edit) {
    const indexArray = states.findIndex((obj, i) => {
      return i === index;
    });

    if (indexArray !== -1) {
      states[indexArray] = fulfillmentStateDefinition;
    }
  }

  const body = {
    name: fulfillment.name,
    fulfillmentStatesDefinitions: edit
      ? states
      : [...states, fulfillmentStateDefinition],
    type: fulfillment.type,
  };
  const token = await getAccessToken();
  const res = await fetch(`/fdefs/${fulfillment.id}`, token, "PATCH", body);
  return res.body;
}

export async function deleteStateFulfillment(
  fulfillment: FulfillmentDefinition,
  index: number,
  fulfillmentStateDefinitions: FulfillmentStateDef[]
): Promise<FulfillmentDefinition> {
  const states = fulfillmentStateDefinitions || [];

  const objWithIdIndex = states.findIndex((obj, i) => i === index);
  states.splice(objWithIdIndex, 1);

  const body = {
    name: fulfillment.name,
    fulfillmentStatesDefinitions: [...states],
    type: fulfillment.type,
  };
  const token = await getAccessToken();
  const res = await fetch(`/fdefs/${fulfillment.id}`, token, "PATCH", body);
  return res.body;
}

export async function deleteFulfillment(id: string): Promise<void> {
  const token = await getAccessToken();

  await fetch(`/fdefs/${id}`, token, "DELETE");
}

export interface CreateFulfillmentParams {
  name: string;
  status: string[];
  type: "locker" | "pickup" | "delivery" | "access" | "digital" | "service";
}

export const defaultAddress: AddressType = {
  type: "w3w",
  reference: "",
  coordinates: {
    lng: -99.167668,
    lat: 19.427016,
  },
};

export const defaultPostalAddress: PostalAddress = {
  type: "postal-address",
  line1: "",
  line2: "",
  zipCode: "",
  city: "",
  state: "",
  country: "",
};

export const defaultFulfillmentEvent: FulfillmentEvent = {
  name: "",
  id: "",
  externalId: "",
  fulfillmentState: {
    eventId:'',
    eventName: "",
    eventShortId: "",
    assetId: '',
    assetName: "",
    name: "",
    id: "",
    numberOfFiles: 0,
    timestamp: "",
    userId: "",
    createdByFullName: "",
  },
  fulfillmentDefinition: {
    name: "",
    createdAt: "",
    createdBy: "",
    updatedAt: "",
    deleted: false,
    __v: 0,
    organization_id: "",
    venue_id: "",
    _actions: [],
    _id: "",
    type: "",
    fulfillmentStatesDefinitions: [],
  },

  orderExternalId: "",
  assetExternalId: "",
  venueId: "",
  orderlineShortId: "",
  considerTime: true,
  orderShortId: "",
  colorizedTags: [],
  relatedEvents: [],
  createdAt: "",
  currentState: "",
  fulfillmentDefinitionId: "",
  shortId: "",
  notes: "",
  order: "",
  organizationId: "",
  fulfillmentLevel: "",
  fulfillmentTeamId: "",
  fulfillmentTeamName: "",
  state: "pending",
  createdBy: "",
  timezone: "",
  datetime: new Date(),
  assetId: "",
  assetName: "",
  loads: [],
  tags: [],
  placements: [
    {
      type: "postal-address",
      line1: "",
      line2: "",
      chronologicalIndex: 0,
      zipCode: "",
      city: "",
      state: "",
      name: "",
      id: "",
      country: "",
    },
  ],
};

export const defaultFulfillment: FulfillmentDefinition = {
  name: "",
  id: "",
  type: "access",
  fulfillmentStatesDefinitions: [{ name: "", actionId: "" }],
};

export const defaultOrderState: FulfillmentStateDef = {
  name: "",
  actionId: "",
};

export function checkFulfillment(input: FulfillmentDefinition) {
  const error = new CustomError("Error saving Fulfillment Form", "formError");

  if (!input.name) {
    error.setError("fulfillment-name", "Name is required");
  }

  if (!error.length) {
    return true;
  }
  throw error;
}

export async function publishFulfillment(
  id: string,
  publish: boolean
): Promise<FulfillmentDefinition> {
  const token = await getAccessToken();
  const res = await fetch(`/fdefs/${id}`, token, "PATCH", {
    published: publish,
  });
  return res.body;
}

interface FulfillmentStatesParams {
  orderId?: string;
  orderlineId?: string;
  userId?: string;
  from?: string;
  to?: string;
  page?: number;
  records_per_page?: number;
  timezone?: string;
}

export interface FulfillmentStatesResponse {
  data: FulfillmentState[];
  page: number;
  total: number;
}

function mapSort(data: FulfillmentStatesParams) {
  return omitBy(
    {
      userId: data.userId || null,
      from: data.from ? dateFilters(data.from, "00:00:00.000") : null,
      to: data.to ? dateFilters(data.to, "23:59:00.000") : null,
      records_per_page: data.records_per_page || null,
      page: data.page,
      orderId: data.orderId || null,
      orderlineId: data.orderlineId || null,
      timezone: data.timezone || null,
    },
    isNil
  );
}

export async function listFulfillmentStates(
  params: FulfillmentStatesParams
): Promise<FulfillmentStatesResponse> {
  const token = await getAccessToken();
  const res = await fetch(
    "/fulfillmentStates",
    token,
    "GET",
    null,
    mapSort(params)
  );
  return {
    data: res.body ? res.body.hits.map(parseFulfillmentState) : [],
    page: get(res.body, "page", 0),
    total: get(res.body, "count", 0),
  };
}

export async function readFulfillmentState(
  id: string
): Promise<FulfillmentState> {
  const token = await getAccessToken();
  const res = await fetch(`/fulfillmentStates/id/${id}`, token);
  const data = parseFulfillmentState(res.body);
  return data;
}

export async function uploadFiles(id: string, files: any) {
  const token = await getAccessToken();
  const res = await multiPartMultipleImages(
    `/fulfillmentStates/id/${id}/files`,
    token,
    files,
    "POST"
  );
  parseFulfillmentState(res.body);
  return;
}

export interface StateFile {
  id: string;
}

export function parseFile(data?: unknown): StateFile {
  return {
    id: get(data, "_id", ""),
  };
}

export async function listFiles(
  fulfillmentStateId: string
): Promise<StateFile[]> {
  const token = await getAccessToken();
  const res = await fetch(
    `/fulfillmentStates/id/${fulfillmentStateId}/files`,
    token,
    "GET"
  );
  return res.body ? res.body.hits.map(parseFile) : [];
}
