import React, { useState } from "react";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import deleteIcon from "../../assets/officialMedia/default-delete.svg";
import styles from "./styles";
import { useHistory } from "react-router";
import { FOPSet } from "../../utils/types";
import { Popover } from "antd";
import DeletePopUp from "../DeletePopUp";
import { useTranslation } from "react-i18next";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";

interface UserListProps {
  data: FOPSet;
  onDelete: (id: string) => void;
  loading: boolean;
}

const SetItem: React.FC<UserListProps> = ({ data, onDelete, loading }) => {
  const history = useHistory();
  const [deleteSet, setDelete] = useState("");

  const { t } = useTranslation();

  return (
    <ListRow
      template="1fr 1fr 1fr 1fr"
      hover={true}
      onClick={() => history.push(`/payments/fopSets/${data.id}`)}
    >
      <ListLabel fontFormat="bold" value={data.name} textAlign="left" />
      <ListLabel value={data.fops.length.toString()} textAlign="left" />
      <div />
      <div className={styles.iconContainer}>
        <EditIcon className={styles.icon} />
        <Popover
          placement="left"
          content={
            <div onClick={() => setDelete("")}>
              <DeletePopUp
                loading={loading}
                value={`${t("component.fopSetList.deleteWarning")} ${
                  data.name
                } ${t("component.fopSetList.set")}?`}
                onClose={(e) => {
                  e.stopPropagation();
                  setDelete("");
                }}
                onDelete={(e) => {
                  e.stopPropagation();
                  onDelete(data.id);
                }}
              />
            </div>
          }
          trigger="click"
          open={deleteSet === "delete"}
          onOpenChange={() => setDelete(deleteSet)}
        />
        <img
          src={deleteIcon}
          alt="userDelete"
          onClick={(e) => {
            e.stopPropagation();
            setDelete("delete");
          }}
        />
      </div>
    </ListRow>
  );
};

export default SetItem;
