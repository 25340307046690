import { stylesheet } from "typestyle";
import { black, linkBlue } from "../../utils/colors";

const styles = stylesheet({
  container: {
    padding: "2em",
  },
  title: {
    fontSize: "1.5em",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "left",
    color: "#141322",
    paddingTop: "1em",
  },
  searchRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "1em",
  },
  center: {
    color: black,
    fontStyle: "normal",
    justifyContent: "space-between",
    padding: "1em",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    marginRight: "0.5em",
  },
  normal: {
    fontSize: "1em",
    fontWeight: 600,
    alignItems: "center",
    justifyContent: "flex-start",
    display: "flex",
    padding: "0 0.5em",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: "1em",
  },
  icon: {
    width: 23,
    cursor: "pointer",
    marginRight: "1em",
  },
  iconDelete: {
    width: 23,
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
  },
  paginationContainer: {
    padding: "2em 0",
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  listContainer: {
    marginTop: "1em",
    boxShadow: "0px 4px 7px rgba(0, 0, 0, 0.1)",
  },
  imageQRCODE: {
    width: "10em",
    margin: "1em",
  },

  spaceBetween: {
    color: black,
    fontStyle: "normal",
    justifyContent: "flex-start",
    padding: "1em",
    display: "flex",
    alignItems: "center",
    marginRight: "0.5em",
    fontSize: "0.9em",
    flexWrap: "wrap",
  },
  itemsWrapper: {
    padding: "1em 0",
  },

  icon2: {
    width: "2em",
  },
  icon3: {
    width: "2em",
    marginRight: "1em",
  },
  iconShare: {},
  iconEdit: {},
  qrIcon: {
    width: 60,
  },

  button: {
    width: "68px !important",
    marginRight: "1em",
  },
  buttonRight: {
    width: "68px !important",
  },
  warning: {
    width: "20px",
  },
  padding: {
    padding: "0.5em",
  },
  inputSelect: {
    width: "90%",
  },
  containerDetail: {
    color: black,
    padding: "1em 0",
    marginBottom: "1em",
  },
  closeButton: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    color: linkBlue,
    cursor: "pointer",
  },
  chevron: {
    width: "36px",
    color: linkBlue,
    marginLeft: "0.5em",
  },
  editContainer: {
    borderRadius: 3,
    border: "1px solid #DCE1E6",
    display: "flex",
    justifyContent: "center",
    padding: "0.1em 0.3em 0em 0.3em",
    marginLeft: "1em",
    background: "white",
    cursor: "pointer",
    height: "2.5em",
    width: "2.5em",
    marginRight: "0.5em",
  },
  editorDetails: {
    height: "20em",
    marginBottom: "1em",
    width: "100%",
  },
  
  editorBox: {
    width: "100%",
    height: "10em",
    border: "1px solid #dce1e6",
  },
});

export default styles;
