import React from "react";
import Text from "../Text";
import { Modal } from "../Modal/Modal";

import styles from "./styles";
import { ButtonsContainer } from "../ButtonsContainer";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";

interface Props {
  onClose: () => void;
  open: boolean;
  data: any[];
  loading: boolean;
  onDelete: () => void;
}

const DeleteLocationsModal: React.FC<Props> = ({
  onClose,
  open,
  loading,
  data,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Modal
        width={"50%"}
        buttons={
          <div>
            <ButtonsContainer justifyContent="flex-end">
              <Button
                onClick={() => {
                  onClose();
                }}
                theme="white"
                label={t("component.deleteLocationsModal.close")}
              />
              <Button
                theme="red"
                disable={!data.length}
                onClick={() => {
                  if (loading) {
                    return;
                  }
                  onDelete();
                }}
                label={
                  loading ? "..." : t("component.deleteLocationsModal.delete")
                }
              />
            </ButtonsContainer>
          </div>
        }
        open={open}
        onClose={onClose}
      >
        <div className={styles.modalContainer}>
          <div>
            <Text
              padding="0em 0 1em 0"
              text={t("component.deleteLocationsModal.deleteLocations")}
              color="#000000"
              fontSize="1.4em"
              fontWeight={700}
            />
            <Text
              padding="0em 0 1em 0"
              text={` ${data.length} ${t(
                "component.deleteLocationsModal.locations"
              )} `}
              color="#000000"
              fontSize="1em"
              fontWeight={700}
            />
            <div className={styles.scroll}>
              {data.length ? (
                <ListRow template="0.2fr 1fr">
                  {data.map((val: any, i: number) => {
                    return (
                      <>
                        <ListLabel
                          textAlign="left"
                          value={(i + 1).toString()}
                        />
                        <ListLabel textAlign="left" value={val.name} />
                      </>
                    );
                  })}
                </ListRow>
              ) : (
                <div className={styles.notEnrollContainer}>
                  {t("component.deleteLocationsModal.noLocations")}
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteLocationsModal;
