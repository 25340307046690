const translation = {
  en: {
    settingsLabel: "Payments",
    profileDescription: "Change information and update your password.",
    profileLabel: "Profile",
    globalDescription: "Configure language, currency and time zone.",
    globalLabel: "User Preferences",
    integrationDescription: "Manage your integrations.",
    integrationLabel: "Integrations",
    fopSetsDescription: "Configure your sets.",
    fopSetsLabel: "FOPs Sets",
    fopDescription: "Create FOPs",
    fopLabel: "Form of Payments",
  },
  es: {
    settingsLabel: "Pagos",
    profileDescription: "Cambiar información y actualizar su contraseña.",
    profileLabel: "Perfil",
    globalDescription: "Configurar idioma, moneda y zona horaria..",
    globalLabel: "Preferencias de Usuario",
    integrationDescription: "Gestiona tus integraciones.",
    integrationLabel: "Integraciones",
    fopSetsDescription: "Configura tus conjuntos.",
    fopSetsLabel: "Conjuntos de FDP",
    fopDescription: "Crear FDP",
    fopLabel: "Formas de Pago",
  },
  de: {},
};

export default translation;
