import React, { useState } from "react";
import styles from "./styles.module.css";
import { ReactComponent as Chevron } from "./chevron-down.svg";
import { InputText } from "../InputText";

interface InputTextDropdownProps {
  value?: string;
  label?: string;
  error?: false | "" | string[] | string;
  showError?: boolean;
  onChange: (value: string) => void;
  options: { label: string; value: string }[];
  placeholder?: string;
  showLabel?: boolean;
  top?: string;
  containerClassName?: string;
}

const InputTextDropdown: React.FC<InputTextDropdownProps> = ({
  value,
  label,
  error,
  showError,
  onChange,
  placeholder,
  options,
  showLabel,
  top,
  containerClassName,
}) => {
  const [text, setText] = useState("");
  const [isFocus, setIsFocus] = useState(false);
  const items = options.filter((e) => e.label.toLowerCase().includes(text));

  return (
    <div className={styles.container}>
      <InputText
        containerClassname={(styles.containerWrapper, containerClassName)}
        label={label}
        showLabel={showLabel}
        value={
          isFocus
            ? text.toLowerCase()
            : options.find((e) => e.value === value)?.label || ""
        }
        onChange={setText}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setTimeout(() => setIsFocus(false), 200)}
        error={error}
        showError={showError}
        placeholder={placeholder}
        buttonIcon={Chevron}
      />
      {isFocus && items.length ? (
        <div style={{ top: top }} className={styles.list}>
          {options
            .filter((e) => e.label.toLowerCase().includes(text))
            .map((item) => (
              <div
                className={
                  options.find((e) => e.value === value)?.label === item.label
                    ? styles.itemSelected
                    : styles.item
                }
                key={item.value}
                onClick={() => onChange(item.value)}
              >
                {item.label}
              </div>
            ))}
        </div>
      ) : null}
    </div>
  );
};

export default InputTextDropdown;
