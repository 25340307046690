import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import VenueDetailComponent from "../../components/VenueDetail";
import { Venue } from "@innomius/ravent-typescript-types";
import VenueForm from "../../components/VenueForm";
import {
  defaultVenueStats,
  deleteVenue,
  getVenue,
  updateVenue,
} from "../../services/venues";
import { messageList } from "../../services/visitors";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { visitorList } from "../../store/visitors";
import VenueDetailHeader from "../../components/VenueDetailHeader";
import styles from "./styles.module.css";
import { Loading } from "../../components/Loading";
import message from "antd/lib/message";
import { fopSetsList } from "../../store/fopSets";
import APIError from "../../utils/APIError";

import { useTranslation } from "react-i18next";
import { venueList } from "../../store/venueStats";
import { EmptyList } from "../../components/List/EmptyList";

interface RouteParams {
  id: string;
}

interface Props extends RouteComponentProps<RouteParams> {}

const VenueDetail: React.FC<Props> = ({ match, history, location }) => {
  const [venue, setVenue] = useState<Venue | null>();
  const venues = useSelector((state: RootState) => state.venueStats);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const dispatch = useDispatch();
  const [edit, setEdit] = useState<boolean>(false);

  const { t } = useTranslation();

  const fopSets = useSelector((state: RootState) => state.fopSets);

  const loadSettings = useCallback(async () => {
    dispatch(visitorList());
    dispatch(fopSetsList({}));
    dispatch(venueList({}));

    try {
      setLoading("detail");
      const venue = await getVenue(match.params.id);
      setVenue(venue);
      setLoading("");
    } catch (err) {
      setLoading("detail");
      if (err instanceof APIError) {
        setError(err.message);
      }
    }
    try {
      setLoading("messages");

      await messageList();
      setLoading("");
    } catch (err) {
      setLoading("");

      if (err instanceof APIError) {
        setError(err.message);
      }
    }
  }, [dispatch, match.params.id]);

  useEffect(() => {
    loadSettings();
  }, [match.params.id, loadSettings]);

  useEffect(() => {
    document.title = "RAVENT APP :: Venues :: Locations";
  });

  if (loading === "detail" && !error) {
    return <Loading />;
  }

  if (!venue) {
    return (
      <EmptyList
        instructions={t("container.venueDetail.emptyInstructions")}
        error={error}
        label="Go Back"
        onClick={() => history.goBack()}
      />
    );
  }

  const onChange = <P extends keyof Venue>(prop: P, value: Venue[P]) => {
    setVenue({ ...venue, [prop]: value });
  };

  const onEdit = async () => {
    try {
      setLoading("edit");
      await updateVenue(venue);
      message.success(`${t("container.venueDetail.successUpdated")}`);
      setLoading("");
      setEdit(false);
      loadSettings();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`Error: ${err.messages}`);
      }
    }
  };

  const onDelete = async () => {
    try {
      setLoading("delete");
      await deleteVenue(venue.id);
      history.push("/venues");
      message.success(`${t("container.venueDetail.successDelete")}`);
      setLoading("");
      setEdit(false);
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`Error: ${err.messages}`);
      }
      setLoading("");
    }
  };

  return (
    <LayouWithSideBar>
      <div className={styles.container}>
        <VenueDetailHeader
          loading={loading}
          onChange={onChange}
          name={venue.name}
          path={match.params.id}
          edit={edit}
          onSave={() => onEdit()}
          onCancelEdit={() => setEdit(false)}
          onEdit={() => {
            if (location.pathname === `/venues/${match.params.id}/locations`) {
              history.push(`/venues/${match.params.id}`);
            }
            setEdit(true);
          }}
          onDelete={() => onDelete()}
        />
        <div style={{ margin: "1em 0" }}>
          {!edit ? (
            <VenueDetailComponent
              stats={
                venues.list.hits.find((item) => item.id === match.params.id) ||
                defaultVenueStats
              }
              fopSets={fopSets.list.data}
              data={venue}
            />
          ) : (
            <VenueForm
              fopSets={fopSets.list.data}
              onChange={onChange}
              data={venue}
              edit
            />
          )}
        </div>
      </div>
    </LayouWithSideBar>
  );
};

export default VenueDetail;
