import React from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./styles.module.css";

export interface Option {
  id: string;
  label: string;
  path: string;
  disable?: boolean;
  onClick?: (value: string) => void;
}

interface Props {
  options: Option[];
  margin?: string;
}

const Header: React.FC<Props> = ({ options, margin }) => {
  const location = useLocation();
  return (
    <div style={{ margin: margin }} className={styles.header}>
      <div className={styles.headerTabs}>
        {options.map(({ label, path, id, disable, onClick }) => (
          <div key={id} className={styles.tabWrapper}>
            {disable ? (
              <div className={styles.headerTabsButtonDisable}>
                <span className={styles.labelTab}>{label}</span>
              </div>
            ) : location.pathname === path ? (
              <Link
                key={id}
                onClick={() => {
                  onClick?.(label);
                }}
                replace
                className={styles.headerTabsButtonActive}
                to={path}
              >
                <span className={styles.labelTab}>{label}</span>
              </Link>
            ) : (
              <Link
                replace
                key={id}
                onClick={() => {
                  onClick?.(label);
                }}
                className={styles.headerTabsButton}
                to={path}
              >
                <span className={styles.labelTab}>{label}</span>
              </Link>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Header;
