
import React from "react";
import transportIcon from "../../assets/fulfilment/service.svg";
import serviceIcon from "../../assets/fulfilment/service2.svg";
import accessIcon from "../../assets/fulfilment/access.svg";
import deliveryIcon from "../../assets/fulfilment/delivery.svg";
import digitalIcon from "../../assets/fulfilment/digital.svg";
import lockerIcon from "../../assets/fulfilment/locker.svg";
import pickUpIcon from "../../assets/fulfilment/pickup.svg";
import hospitalityIcon from "../../assets/fulfilment/hospitality.svg";

import Text from "../Text";
import { capitalize } from "lodash";
import styles from "./styles";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { GridContainer } from "../GridContainer/GridContainer";
import InputRadio from "../Inputs/InputRadio";
import { Card } from "../Card/Card";

interface Props {
  type: string;
  onClick: (value: string) => void;
  selectedType: string;
}

const TypeButton: React.FC<Props> = ({ type, onClick, selectedType }) => {
  const typeIconMap: Record<string, string> = {
    service: serviceIcon,
    access: accessIcon,
    delivery: deliveryIcon,
    locker: lockerIcon,
    digital: digitalIcon,
    "pick-up": pickUpIcon,
    transportation: transportIcon,
    hospitality: hospitalityIcon,
  };

  return (
    <Card>
      <FlexContainer justifyContent="space-between" alignItems="center">
        <GridContainer alignItems="center" gap="1em" columns="52px 0.9fr">
          <img className={styles.icon} src={typeIconMap[type]} alt="" />
          <FlexContainer
            justifyContent="center"
            alignItems="flex-start"
            flexDirection="column"
          >
            <Text text={capitalize(type)} fontSize="1.1em" fontWeight={700} />
          </FlexContainer>
        </GridContainer>
        <InputRadio
          name="type"
          value={type}
          checked={selectedType === type}
          onChange={(value) => onClick(value)}
        />
      </FlexContainer>
    </Card>
  );
};

export default TypeButton;
