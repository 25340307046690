import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { FulfillerOverview } from "../utils/types";
import { OverviewParams, getFulfillersOverview } from "../services/entities";

type TextSearchSlice = {
  loading: boolean;
  error: string;
  fulfillers: FulfillerOverview[];
};

const initialState: TextSearchSlice = {
  loading: true,
  error: "",
  fulfillers: [],
};

export const eventFulfillerOverview = createAsyncThunk(
  "eventFulfillerOverview/list",
  async (params: OverviewParams) => {
    const response = await getFulfillersOverview(params);
    return response;
  }
);

const eventFulfillerOverviewSlice = createSlice({
  name: "eventFulfillerOverview",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(eventFulfillerOverview.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(eventFulfillerOverview.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.fulfillers = action.payload;
    });
    builder.addCase(eventFulfillerOverview.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An unexpected error ocurred";
    });
  },
});

export default eventFulfillerOverviewSlice.reducer;
