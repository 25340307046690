import React from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as Edit } from "../../assets/edit.svg";
import styles from "./styles";
import { VenueStats } from "../../utils/types";
import { numberFormatNoDecimals } from "../../utils/format";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";
// import visitorIcon from "../../assets/icon-set-visitors.svg";
// import mapIcon from "../../assets/icon-set-map.svg";

interface VenuesListProps {
  data: VenueStats;
}

const VenueItemList: React.FC<VenuesListProps> = ({ data }) => {
  const history = useHistory();
  return (
    <ListRow
      boxShadow
      borderRadius="6px"
      onClick={() => {
        document.title = "RAVENT APP :: Venue";
        history.push(`venues/${data.id}`);
      }}
      template="1.3fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.5fr"
      hover={true}
      key={data.id}
    >
      <ListLabel fontFormat="bold" value={data.name} textAlign="left" />
      <ListLabel
        fontFormat="bold"
        value={numberFormatNoDecimals(data.counts.orders)}
        textAlign="left"
      />
      <ListLabel
        fontFormat="bold"
        value={numberFormatNoDecimals(data.counts.fulfillmentEvents)}
        textAlign="left"
      />
      <ListLabel
        fontFormat="bold"
        value={numberFormatNoDecimals(data.counts.locations)}
        textAlign="left"
      />
      <ListLabel
        fontFormat="bold"
        value={numberFormatNoDecimals(data.counts.users)}
        textAlign="left"
      />
      <ListLabel
        fontFormat="bold"
        value={numberFormatNoDecimals(data.counts.fulfillmentDefinitions)}
        textAlign="left"
      />

      <div />
      {/* <div className={styles.center}>
        <img className={styles.visitorIcon} src={visitorIcon} alt="" />
        {data.visitors.toString()}
      </div> */}
      {/* <div className={styles.center}>
        <img className={styles.visitorIcon} src={mapIcon} alt="" />
        {data.locations.length.toString()}
      </div> */}

      <div className={styles.iconContainer}>
        <Edit className={styles.icon} />
      </div>
    </ListRow>
  );
};

export default VenueItemList;
