export const modifiersOptionsType = [
  { label: "Single Selection", value: "single" },
  { label: "Multiple Selection", value: "multiple" },
];

export const addSubstractOptions = [
  { label: "+ (add)", value: "+" },
  { label: "- (substract)", value: "-" },
];

export const amountPercentageOptions = [
  { label: "%", value: "%" },
  { label: "$", value: "$" },
];

