const translation = {
  en: {
    address: "Address",
    w3w: "/// What Three Words",
    postal: "Postal Address",
    wellKnown: "Well Known Address",
    reference: "Reference",
    line1: "Address Line 1",
    line2: "Address Line 2",
    city: "City",
    state: "State",
    zipCode: "Zip Code",
    country: "Country",
    orgDetails: "Details",
    type: "Type",
    data: "Data",
    legalName: "Name",
    email: "Email",
    phone: "Phone",
    appConfig: "Fulfiller App Configuration",
    seeOrder: "Allows Fulfiller to See Order Details",
    allow: "Allows Fulfiller to Chat with Customers",
    upload: "Upload Logo",
    venue: "Venue",
    maskingTemplates: "Team Data Policy",
    orders: "Orders Fields",
    roles: "Roles",
    selectAnOption: "Select an Option",
    internal: "Internal",
    invoices: "Invoices Fields",
    orderlines: "Orderlines Fields",
    exludedFields: "Excluded Fields",
    description:
      "Enhance the privacy protection of your organization's orders by specifying the level of access that users in this group will have to sensitive data. You will be able to indicate whether the selected fields should be included or excluded in the web and mobile interface data.",
  },

  es: {
    data: "Datos",
    internal: "Interno",
    description:
      "Mejore la protección de la privacidad de los pedidos de su organización especificando el nivel de acceso que los usuarios de este grupo tendrán a los datos confidenciales. Podrá indicar si los campos seleccionados deben incluirse o excluirse en los datos de la interfaz web y móvil",
    exludedFields: "Campos excluídos",
    selectAnOption: "Selecciona una opción",

    invoices: "Campos de Factura",
    orderlines: "Campos de Orderlines",

    roles: "Roles",

    orders: "Campos de Órdenes",

    maskingTemplates: "Política de datos del equipo",
    venue: "Recinto",
    address: "Dirección",
    w3w: "/// What Three Words",
    postal: "Dirección postal",
    wellKnown: "Dirección conocida",
    reference: "Referencia",
    line1: "Línea de dirección 1",
    line2: "Línea de dirección 2",
    city: "Ciudad",
    state: "Estado",
    zipCode: "Código Postal",
    country: "País",
    orgDetails: "Detalles",
    type: "Tipo",
    legalName: "Nombre",
    email: "Correo electrónico",
    phone: "Teléfono",
    appConfig: "Configuración de la aplicación Fulfiller",
    seeOrder: "Permite al Fulfiller ver los detalles del pedido",
    allow: "Permite a Fulfiller chatear con los clientes",
    upload: "Subir logotipo",
  },
  de: {},
};

export default translation;
