import React from "react";
import { Card } from "../Card/Card";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
import { StatusCatalog } from "../StatusBadge";
import { InputSelect } from "../Inputs/InputSelect";
import { InputSwitch } from "../Inputs/InputSwitch";
import { FlexContainer } from "../FlexContainer/FlexContainer";

import styles from "./styles";
import { useTranslation } from "react-i18next";
import { VenuePaymentState } from "../../utils/types";
import Text from "../../components/Text";
import { black } from "../../utils/colors";
import { StatusBadgePoint } from "../StatusBadgePoint/StatusBagePoint";
import EditButtonsContainer from "../EditButtonsContainer";
import { Action } from "@innomius/ravent-typescript-types";

interface Props {
  data: VenuePaymentState;
  onChange: (data: VenuePaymentState) => void;
  setEdit: (value: string) => void;
  onEdit: () => void;
  loading: string;
  actions: Action[];
}

const VenuePaymentStatesForm: React.FC<Props> = ({
  data,
  onChange,
  onEdit,
  setEdit,
  loading,
  actions,
}) => {
  const { t } = useTranslation();

  return (
    <Card containerClassname={styles.wrapperCard}>
      <FlexContainer padding="0 0 1em 0" justifyContent="space-between">
        <ContainerTitle
          size="small"
          label={t("component.fulfillmentForm.orderStates")}
        />

        <EditButtonsContainer
          loading={loading}
          setEdit={() => setEdit("")}
          onEdit={onEdit}
        />
      </FlexContainer>

      <Text
        textAlign="justify"
        padding="0 0 2em 0"
        color={black}
        text={t("component.venueStates.url")}
      />
      <GridContainer padding="0 0 1em 0" gap="1em" columns="1fr 3fr 1fr">
        <InputLabel label={t("component.fulfillmentForm.stateName")} />
        <InputLabel label={t("component.fulfillmentForm.actionId")} />
        <InputLabel label={t("component.venueForm.automatic")} />
      </GridContainer>
      <GridContainer
        alignItems="center"
        justifyContent="center"
        gap="1em"
        padding="0 0 1em 0"
        columns="1fr 3fr 1fr"
      >
        <StatusBadgePoint status={"unpaid" as StatusCatalog} />
        <InputSelect
          showError={false}
          showLabel={false}
          options={[
            ...actions.map((item) => ({
              value: item.id,
              label: item.name,
            })),
            { label: "Unassigned Action", value: "" },
          ]}
          value={data.unpaid.actionId}
          onChange={(actionId) =>
            onChange({
              ...data,
              unpaid: {
                actionId,
                automatic: data.unpaid.automatic,
              },
            })
          }
        />
        <InputSwitch
          value={data.unpaid.automatic}
          onChange={(automatic) =>
            onChange({
              ...data,
              unpaid: {
                actionId: data.unpaid.actionId,
                automatic: automatic,
              },
            })
          }
        />
        <StatusBadgePoint status={"expired" as StatusCatalog} />
        <InputSelect
          showError={false}
          showLabel={false}
          options={[
            ...actions.map((item) => ({
              value: item.id,
              label: item.name,
            })),
            { label: "Unassigned Action", value: "" },
          ]}
          value={data.expired.actionId}
          onChange={(actionId) =>
            onChange({
              ...data,
              expired: {
                actionId,
                automatic: data.expired.automatic,
              },
            })
          }
        />
        <InputSwitch
          value={data.expired.automatic}
          onChange={(automatic) =>
            onChange({
              ...data,
              expired: {
                actionId: data.expired.actionId,
                automatic: automatic,
              },
            })
          }
        />{" "}
        <StatusBadgePoint status={"partially paid" as StatusCatalog} />
        <InputSelect
          showError={false}
          showLabel={false}
          options={[
            ...actions.map((item) => ({
              value: item.id,
              label: item.name,
            })),
            { label: "Unassigned Action", value: "" },
          ]}
          value={data.partial.actionId}
          onChange={(actionId) =>
            onChange({
              ...data,
              partial: {
                actionId,
                automatic: data.partial.automatic,
              },
            })
          }
        />
        <InputSwitch
          value={data.partial.automatic}
          onChange={(automatic) =>
            onChange({
              ...data,
              partial: {
                actionId: data.partial.actionId,
                automatic: automatic,
              },
            })
          }
        />
        <StatusBadgePoint status={"paid" as StatusCatalog} />
        <InputSelect
          showError={false}
          showLabel={false}
          options={[
            ...actions.map((item) => ({
              value: item.id,
              label: item.name,
            })),
            { label: "Unassigned Action", value: "" },
          ]}
          value={data.paid.actionId}
          onChange={(actionId) =>
            onChange({
              ...data,
              paid: {
                actionId,
                automatic: data.paid.automatic,
              },
            })
          }
        />
        <InputSwitch
          value={data.paid.automatic}
          onChange={(automatic) =>
            onChange({
              ...data,
              paid: {
                actionId: data.paid.actionId,
                automatic: automatic,
              },
            })
          }
        />{" "}
        <StatusBadgePoint status={"refunded" as StatusCatalog} />
        <InputSelect
          showError={false}
          showLabel={false}
          value={data.refunded.actionId}
          options={[
            ...actions.map((item) => ({
              value: item.id,
              label: item.name,
            })),
            { label: "Unassigned Action", value: "" },
          ]}
          onChange={(actionId) =>
            onChange({
              ...data,
              refunded: {
                actionId,
                automatic: data.refunded.automatic,
              },
            })
          }
        />
        <InputSwitch
          value={data.refunded.automatic}
          onChange={(automatic) =>
            onChange({
              ...data,
              refunded: {
                actionId: data.refunded.actionId,
                automatic: automatic,
              },
            })
          }
        />{" "}
      </GridContainer>
      <div />
    </Card>
  );
};

export default VenuePaymentStatesForm;
