import get from "lodash/get";
import fetch from "../utils/fetch";
import { getAccessToken } from "./auth";
import { isNil, omitBy } from "lodash";
import { Asset, AssetListInterface, MaskingTemplates } from "../utils/types";
import { defaultPlacement } from "./venues";
import { Placement } from "@innomius/ravent-typescript-types";
import Papa from "papaparse";
import { convertStringToObject, extractValues } from "../utils/assets";
import multiPartFetch from "../utils/multiPartFetch";
import { Filter, parseFacetEvent, TextSearchListEvent } from "./orders";

export function parseAsset(data: unknown): Asset {
  return {
    locationTimestamp: get(data, "locationTimestamp", ""),
    id: get(data, "_id", ""),
    created: get(data, "createdAt", ""),
    subtype: get(data, "subtype", ""),
    vehicleLicensePlate: get(data, "vehicleLicensePlate", ""),
    vehicleBrand: get(data, "vehicleBrand", ""),
    vehicleCapacity: {
      quantity: get(data, "vehicleCapacity.quantity", 0),
      units: get(data, "vehicleCapacity.units", ""),
    },
    vehicleModel: get(data, "vehicleModel", ""),
    vehicleSerial: get(data, "vehicleSerial", ""),
    vehicleWeight: {
      max: get(data, "vehicleWeight.max", 0),
      current: get(data, "vehicleWeight.current", 0),
      units: get(data, "vehicleWeight.units", ""),
    },
    vehicleLastKnownLocation: {
      timestamp: get(data, "vehicleLastKnownLocation.timestamp", ""),
      coordinates: {
        latitude: get(
          data,
          "vehicleLastKnownLocation.coordinates.latitude",
          ""
        ),
        longitude: get(
          data,
          "vehicleLastKnownLocation.coordinates.longitude",
          ""
        ),
      },
    },

    imageKey: get(data, "imageKey", ""),
    colorizedTags: get(data, "colorizedTags", []),
    status: get(data, "enabled", false),
    hasImage: get(data, "hasImage", false),
    onTime: get(data, "onTime", true),
    datetime: get(data, "datetime", ""),
    notes: get(data, "notes", ""),
    organization_id: get(data, "organization_id", ""),
    tags: get(data, "tags", []),
    type: get(data, "type", ""),
    updated: get(data, "updatedAt", ""),
    deleted: get(data, "deleted", false),
    externalID: get(data, "externalId", ""),
    name: get(data, "name", ""),
    description: get(data, "description", ""),
    data: get(data, "data", {}),
    placement: {
      id: get(data, "location.id", ""),
      name: get(data, "location.name", ""),
      chronologicalIndex: get(data, "location.chronologicalIndex", 0),

      type: get(data, "location.type", ""),
      line1: get(data, "location.line1", ""),
      w3w: get(data, "location.w3w", ""),
      reference: get(data, "location.reference", ""),
      line2: get(data, "location.line2", ""),
      zipCode: get(data, "location.zipCode", ""),
      city: get(data, "location.city", ""),
      state: get(data, "location.state", ""),
      country: get(data, "location.country", ""),
      coordinates: {
        latitude: get(data, "location.coordinates.latitude", ""),
        longitude: get(data, "location.coordinates.longitude", ""),
      },
    },
  };
}

export function parseAssetList(data: unknown): AssetListInterface {
  return {
    colorizedTags: get(data, "colorizedTags", []),
    id: get(data, "_id", ""),
    subtype: get(data, "subtype", ""),
    vehicleLicensePlate: get(data, "vehicleLicensePlate", ""),
    vehicleBrand: get(data, "vehicleBrand", ""),
    vehicleCapacity: {
      quantity: get(data, "vehicleCapacity.quantity", 0),
      units: get(data, "vehicleCapacity.units", ""),
    },
    vehicleModel: get(data, "vehicleModel", ""),
    vehicleSerial: get(data, "vehicleSerial", ""),
    vehicleWeight: {
      max: get(data, "vehicleWeight.max", 0),
      current: get(data, "vehicleWeight.current", 0),
      units: get(data, "vehicleWeight.units", ""),
    },
    vehicleLastKnownLocation: {
      timestamp: get(data, "vehicleLastKnownLocation.timestamp", ""),
      coordinates: {
        latitude: get(
          data,
          "vehicleLastKnownLocation.coordinates.latitude",
          ""
        ),
        longitude: get(
          data,
          "vehicleLastKnownLocation.coordinates.longitude",
          ""
        ),
      },
    },
    created: get(data, "createdAt", ""),
    imageKey: get(data, "imageKey", ""),
    status: get(data, "enabled", false),
    hasImage: get(data, "hasImage", false),
    onTime: get(data, "onTime", true),
    datetime: get(data, "datetime", ""),
    organization_id: get(data, "organization_id", ""),
    tags: get(data, "tags", []),
    type: get(data, "type", ""),
    updated: get(data, "updatedAt", ""),
    deleted: get(data, "deleted", false),
    externalID: get(data, "externalId", ""),
    name: get(data, "name", ""),
    description: get(data, "description", ""),
    locationCoordinatesLatitude: get(data, "locationCoordinatesLatitude", ""),
    locationCoordinatesLongitude: get(data, "locationCoordinatesLongitude", ""),
  };
}

export interface AssetsParams {
  page?: number | string; //
  records_per_page?: number;
  venue_id?: string;
  sort?: string;
  search?: string;
  view?: string;
  count?: number;
}

function mapSortsFullText(data: AssetsParams) {
  return omitBy(
    {
      page: data.page,
      records_per_page:
        data.view === "map" ? data.count : data.records_per_page,
      venue_id: data.venue_id || null,
      sort: data.sort || "externalId:asc",
      search: data.search || null,
    },
    isNil
  );
}

export interface AssetsSearchResponse {
  data: Asset[];
  page: number;
  total: number;
}

export async function assetList(
  params: AssetsParams
): Promise<AssetsSearchResponse> {
  const token = await getAccessToken();

  const res = await fetch(
    "/assets",
    token,
    "GET",
    null,
    mapSortsFullText(params)
  );
  return {
    data: res.body ? res.body.hits.map(parseAsset) : [],
    page: get(res.body, "page", 0),
    total: get(res.body, "count", 0),
  };
}

export interface CreateAssetsParams {
  venue_id?: string;
  name: string;
  enabled: boolean;
  roles?: string[];
  maskingTemplates?: {
    orders?: {
      type: string;
      fields: string[];
    };
    orderlines?: {
      type: string;
      fields: string[];
    };
    invoices?: {
      type: string;
      fields: string[];
    };
  };
}

export const defaultMaskingTemaplte: MaskingTemplates = {
  orders: {
    type: "",
    fields: [],
  },
  orderlines: {
    type: "",
    fields: [],
  },
  invoices: {
    type: "",
    fields: [],
  },
};

export async function createAsset(data: Asset): Promise<Asset> {
  const body = {
    name: data.name,
    enabled: data.status,
    description: data.description,
    externalId: data.externalID,
    type: data.type,
    tags: data.tags,
    notes: data.notes,
    vehicleBrand: data.vehicleBrand,
    vehicleCapacity: {
      quantity: data.vehicleCapacity.quantity,
      units: data.vehicleCapacity.units,
    },
    vehicleLicensePlate: data.vehicleLicensePlate,
    vehicleModel: data.vehicleModel,
    vehicleSerial: data.vehicleSerial,
    vehicleWeight: {
      max: data.vehicleWeight.max,
      current: data.vehicleWeight.current,
      units: data.vehicleWeight.units,
    },
    subtype: data.subtype,
  };
  const bodyGeneric = {
    name: data.name,
    enabled: data.status,
    description: data.description,
    externalId: data.externalID,
    type: data.type,
    tags: data.tags,
    notes: data.notes,

    subtype: data.subtype,
  };
  const token = await getAccessToken();
  const res = await fetch(
    "/assets",
    token,
    "POST",
    data.type === "generic" ? bodyGeneric : body
  );
  return res.body;
}

export async function readAsset(id: string): Promise<Asset> {
  const token = await getAccessToken();
  const res = await fetch(`/assets/${id}`, token);
  const data = parseAsset(res.body);
  return data;
}

export async function updateAsset(
  id: string,
  data: Asset,
  placement: Placement
): Promise<Asset> {
  const bodyLocation = {
    type: "w3w",
    line1: placement.line1,
    coordinates: {
      latitude: placement.coordinates?.latitude,
      longitude: placement.coordinates?.longitude,
    },
    w3w: placement.w3w,
  };

  const body = {
    name: data.name,
    enabled: data.status,
    description: data.description,
    externalId: data.externalID,
    type: data.type,
    tags: data.tags,
    notes: data.notes,
    data: data.data ? JSON.parse(JSON.stringify(data.data)) : {},
    vehicleBrand: data.vehicleBrand,
    vehicleCapacity: {
      quantity: data.vehicleCapacity.quantity,
      units: data.vehicleCapacity.units,
    },
    vehicleLicensePlate: data.vehicleLicensePlate,
    vehicleModel: data.vehicleModel,
    vehicleSerial: data.vehicleSerial,
    vehicleWeight: {
      max: data.vehicleWeight.max,
      current: data.vehicleWeight.current,
      units: data.vehicleWeight.units,
    },
    subtype: data.subtype,
    location: placement.coordinates?.latitude ? bodyLocation : { type: "w3w" },
  };

  const token = await getAccessToken();
  const res = await fetch(`/assets/${id}`, token, "PATCH", body);
  return parseAsset(res.body);
}

export async function deleteAssets(id: string, replace: string): Promise<void> {
  const token = await getAccessToken();
  const body = {
    ids: [id],
    replacement: replace,
  };
  await fetch(`/assets`, token, "DELETE", body);
  return;
}

export async function deleteAssetImage(id: string): Promise<void> {
  const token = await getAccessToken();

  await fetch(`/assets/${id}/image`, token, "DELETE");
  return;
}

export const defaultAsset: Asset = {
  organization_id: "",
  name: "",
  colorizedTags: [],
  id: "",
  locationTimestamp: "",
  notes: "",
  hasImage: false,
  updated: "",
  deleted: false,
  created: "",
  createdAt: "",
  description: "",
  externalID: "",
  type: "vehicle",
  tags: [],
  placement: defaultPlacement(0),
  status: false,
  data: {},
  imageKey: "",
  onTime: true,
  datetime: "",
  subtype: "",
  vehicleBrand: "",
  vehicleCapacity: {
    quantity: 0,
    units: "",
  },
  vehicleLicensePlate: "",
  vehicleLastKnownLocation: {
    timestamp: "",
    coordinates: {
      latitude: "",
      longitude: "",
    },
  },
  vehicleModel: "",
  vehicleSerial: "",
  vehicleWeight: {
    max: 0,
    current: 0,
    units: "",
  },
};

export async function createMultipleAsset(data: any[]) {
  for (const assetData of data) {
    const body = {
      name: assetData.name || "",
      enabled: assetData.status,
      description: assetData.description,
      externalId: assetData.externalId,
      type: assetData.type,
      tags: extractValues(assetData.tags),
      notes: assetData.notes,
      data: convertStringToObject(assetData.data),
      vehicleBrand: assetData.vehicleBrand,
      vehicleCapacity: {
        quantity: assetData.vehicleCapacity.quantity,
        units: assetData.vehicleCapacity.units,
      },
      vehicleLicensePlate: assetData.vehicleLicensePlate,
      vehicleModel: assetData.vehicleModel,
      vehicleSerial: assetData.vehicleSerial,
      vehicleWeight: {
        max: assetData.vehicleWeight.max,
        current: assetData.vehicleWeight.current,
        units: assetData.vehicleWeight.units,
      },
      subtype: assetData.subtype,
    };

    const token = await getAccessToken();
    await fetch("/assets", token, "POST", body)
      .then((res) => parseAsset(res.body))
      .catch((error) => {
        console.error(`Error creating location: ${error}`);
        return null;
      });
  }

  return;
}

// export async function deleteMultipleAssets(data: string[]) {
//   for (const assetData of data) {
//     const token = await getAccessToken();
//     await fetch(`/assets/${assetData}`, token, "DELETE")
//       .then((res) => parseAsset(res.body))
//       .catch((error) => {
//         console.error(`Error deleting assets: ${error}`);
//         return null;
//       });
//   }
//   return;
// }

export async function deleteMultipleAssets(
  data: string[],
  replace: string
): Promise<void> {
  const token = await getAccessToken();
  const body = {
    ids: data,
    replacement: replace,
  };
  await fetch(`/assets`, token, "DELETE", body);
  return;
}

export async function exportMultipleAssets(assetData: Asset[]) {
  const csvRows = assetData.map((assetData) => {
    const body = {
      name: assetData.name,
      enabled: assetData.status,
      description: assetData.description,
      externalId: assetData.externalID,
      type: assetData.type,
      tags: assetData.tags,
      vehicleBrand: assetData.vehicleBrand,
      "vehicleCapacity.quantity": assetData.vehicleCapacity.quantity,
      "vehicleCapacity.units": assetData.vehicleCapacity.units,

      vehicleLicensePlate: assetData.vehicleLicensePlate,
      vehicleModel: assetData.vehicleModel,
      vehicleSerial: assetData.vehicleSerial,
      "vehicleWeight.max": assetData.vehicleWeight.max,
      "vehicleWeight.current": assetData.vehicleWeight.current,
      "vehicleWeight.units": assetData.vehicleWeight.units,

      subtype: assetData.subtype,
      notes: assetData.notes,
      data: assetData.data ? JSON.parse(JSON.stringify(assetData.data)) : {},
      "placement.type": assetData.placement.w3w ? "w3w" : "postal-address",
      "placement.reference": assetData.placement.reference || "",
      "placement.line2": assetData.placement.line2 || "",
      "placement.line1": assetData.placement.line1 || "",
      "placement.state": assetData.placement.state || "",
      "placement.city": assetData.placement.city || "",
      "placement.w3w": assetData.placement.w3w || "",
      "placement.coordinates.latitude":
        assetData.placement.coordinates?.latitude || "",
      "placement.coordinates.longitude":
        assetData.placement.coordinates?.longitude || "",
    };

    return body;
  });

  const csv = Papa.unparse(csvRows);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.setAttribute("download", "assets_data.csv");
  link.click();

  return;
}

export async function uploadAssetFile(file: File, venue_id: string) {
  const token = await getAccessToken();
  await multiPartFetch(`/assets/file`, token, file, null, "POST", venue_id);
  return;
}

interface AssetMapParams {
  venue_id: string;
  dtReference?: string;
  timezone?: string;
}

export interface AssetMapResponse {
  assetsDuplicated: DuplicatedAsset[];
  notRunning: NotRunningAsset[];
  running: RunningAsset[];
  runningEventsWithNoneAssets: RunningEventsWithNoneAssets[];
}

export interface AssetItemMap {
  id: string;
  name: string;
  externalId: string;
  type: string;
  location: {
    type: string;
    coordinates: {
      longitude: string;
      latitude: string;
    };
  };
}

export interface EventItemMap {
  orderShortId: string;
  id: string;
  orderId: string;
  name: string;
  state: string;
  orderExternalId: string;
  datetime: string;
  load: number;
  fulfillmentTeamName: string;
  fulfillerName: string;
  onTime: boolean;
}

interface DuplicatedAsset {
  id: string;
  name: string;
  externalId: string;
  type: string;
  location: {
    type: string;
    coordinates: {
      longitude: string;
      latitude: string;
    };
  };
  eventData: EventItemMap;
}

interface NotRunningAsset {
  id: string;
  name: string;
  externalId: string;
  type: string;
  location: {
    type: string;
    coordinates: {
      longitude: string;
      latitude: string;
    };
  };
}

interface RunningAsset {
  id: string;
  name: string;
  externalId: string;
  type: string;
  location: {
    type: string;
    coordinates: {
      longitude: string;
      latitude: string;
    };
  };
  eventData: EventItemMap;
}

interface RunningEventsWithNoneAssets {
  eventData: EventItemMap;
}

export function parseDuplicatedAsset(data: unknown): DuplicatedAsset {
  return {
    id: get(data, "_id", ""),
    name: get(data, "name", ""),
    type: get(data, "type", ""),
    externalId: get(data, "externalId", ""),
    location: {
      type: get(data, "location.type", ""),
      coordinates: {
        latitude: get(data, "location.coordinates.latitude", ""),
        longitude: get(data, "location.coordinates.longitude", ""),
      },
    },

    eventData: {
      orderExternalId: get(data, "eventData.orderExternalId", ""),
      orderId: get(data, "eventData.orderId", ""),
      orderShortId: get(data, "eventData.orderShortId", ""),
      id: get(data, "eventData._id", ""),
      name: get(data, "eventData.name", ""),
      state: get(data, "eventData.state", ""),
      fulfillerName: get(data, "eventData.fulfillerName", ""),
      fulfillmentTeamName: get(data, "eventData.fulfillmentTeamName", ""),
      onTime: get(data, "eventData.onTime", false),
      datetime: get(data, "eventData.datetime", ""),
      load: get(data, "eventData.load", 0),
    },
  };
}

export function parseNotRunningAsset(data: unknown): NotRunningAsset {
  return {
    id: get(data, "_id", ""),
    name: get(data, "name", ""),
    type: get(data, "type", ""),
    externalId: get(data, "externalId", ""),
    location: {
      type: get(data, "location.type", ""),
      coordinates: {
        latitude: get(data, "location.coordinates.latitude", ""),
        longitude: get(data, "location.coordinates.longitude", ""),
      },
    },
  };
}

export function parseRunningAsset(data: unknown): RunningAsset {
  return {
    id: get(data, "_id", ""),
    name: get(data, "name", ""),
    type: get(data, "type", ""),
    externalId: get(data, "externalId", ""),
    location: {
      type: get(data, "location.type", ""),
      coordinates: {
        latitude: get(data, "location.coordinates.latitude", ""),
        longitude: get(data, "location.coordinates.longitude", ""),
      },
    },
    eventData: {
      orderExternalId: get(data, "eventData.orderExternalId", ""),
      orderId: get(data, "eventData.orderId", ""),
      orderShortId: get(data, "eventData.orderShortId", ""),
      id: get(data, "eventData._id", ""),
      name: get(data, "eventData.name", ""),
      state: get(data, "eventData.state", ""),
      fulfillerName: get(data, "eventData.fulfillerName", ""),
      fulfillmentTeamName: get(data, "eventData.fulfillmentTeamName", ""),
      onTime: get(data, "eventData.onTime", false),
      datetime: get(data, "eventData.datetime", ""),
      load: get(data, "eventData.load", 0),
    },
  };
}

export function parseRunnningEventsWithNoneAsset(
  data: unknown
): RunningEventsWithNoneAssets {
  return {
    eventData: {
      orderExternalId: get(data, "eventData.orderExternalId", ""),
      orderId: get(data, "eventData.orderId", ""),
      orderShortId: get(data, "eventData.orderShortId", ""),
      id: get(data, "eventData._id", ""),
      name: get(data, "eventData.name", ""),
      state: get(data, "eventData.state", ""),
      fulfillerName: get(data, "eventData.fulfillerName", ""),
      fulfillmentTeamName: get(data, "eventData.fulfillmentTeamName", ""),
      onTime: get(data, "eventData.onTime", false),
      datetime: get(data, "eventData.datetime", ""),
      load: get(data, "eventData.load", 0),
    },
  };
}

export function parseAssetMap(data: unknown): AssetMapResponse {
  return {
    assetsDuplicated: get(data, "assetsDuplicated", []).map(
      parseDuplicatedAsset
    ),
    runningEventsWithNoneAssets: get(
      data,
      "runningEventsWithNoneAssets",
      []
    ).map(parseRunnningEventsWithNoneAsset),
    running: get(data, "running", []).map(parseRunningAsset),
    notRunning: get(data, "notRunning", []).map(parseNotRunningAsset),
  };
}

export async function eventAssetMap(
  params: AssetMapParams
): Promise<AssetMapResponse> {
  const token = await getAccessToken();
  const res = await fetch("/events/assetMap", token, "GET", null, params);
  return res.body;
}

export interface AssetListParamsFT {
  type?: Filter;
  tags?: Filter;
  name?: Filter;
  page?: number | string; //
  records_per_page?: number | string; //
  search?: string; //
  sort?: string; //
  venueId?: string; //
  view?: string;
  count?: number;
  lastHours?: number;
  enabled?: Filter;
}

export interface FiltersAsset {
  type: Filter | null;
  tags: Filter | null;
  name: Filter | null;
  enabled: Filter | null;
}

export function mapSortAssets(data: AssetListParamsFT) {
  const filters: FiltersAsset = {
    type: data.type?.items.length ? data.type : null,
    tags: data.tags?.items.length ? data.tags : null,
    name: data.name?.items.length ? data.name : null,
    enabled: data.enabled?.items.length ? data.enabled : null,
  };

  const filteredFilters: Partial<FiltersAsset> = {};
  for (const key in filters) {
    if (filters[key as keyof FiltersAsset] !== null) {
      filteredFilters[key as keyof FiltersAsset] =
        filters[key as keyof FiltersAsset]!;
    }
  }

  const jsonString = JSON.stringify(filteredFilters);
  return omitBy(
    {
      page: data.page,
      records_per_page:
        data.view === "map" ? data.count : data.records_per_page,
      sort: data.sort || "externalId:asc",
      search: data.search || null,
      lastHours: data.view === "map" ? 72 : data.lastHours,
      filters: jsonString === "{}" ? "{}" : jsonString,
    },
    isNil
  );
}

export async function assetFullTextSearch(
  params: AssetListParamsFT
): Promise<TextSearchListEvent> {
  const token = await getAccessToken();
  const res = await fetch(
    "/events/textSearchAssets",
    token,
    "GET",
    null,
    mapSortAssets(params)
  );
  return {
    hits: res.body ? res.body.hits.map(parseAssetList) : [],
    page: get(res.body, "page", 0),
    count: get(res.body, "count", 0),
    facets: res.body.facets ? res.body.facets.map(parseFacetEvent) : [],
  };
}
