import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TextSearchListEvent } from "../services/orders";
import { UserListParamsFT, userFullTextSearch } from "../services/users";

type TextSearchSlice = {
  loading: boolean;
  error: string;
  list: TextSearchListEvent;
};

const initialState: TextSearchSlice = {
  loading: true,
  error: "",
  list: {
    page: 0,
    count: 0,
    hits: [],
    facets: [],
  },
};

export const userTextSearchList = createAsyncThunk(
  "userTextSearch/list",
  async (params: UserListParamsFT) => {
    const response = await userFullTextSearch(params);
    return response;
  }
);

const userTextSearchSlice = createSlice({
  name: "userTextSearch",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userTextSearchList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(userTextSearchList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.list = action.payload;
    });
    builder.addCase(userTextSearchList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An unexpected error ocurred";
    });
  },
});

export default userTextSearchSlice.reducer;
