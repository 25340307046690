import React from "react";
import { useTranslation } from "react-i18next";
import ScheduleItem from "./ScheduleItem";
import { Action, Schedule } from "@innomius/ravent-typescript-types";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { ListHeader } from "../List/ListHeader";
import { List } from "../List";

interface StateProps {
  data: Schedule[];
  onAddState: (data: Schedule) => void;
  loadingSchedules: string;
  onDelete: (id: string) => void;
  onEditSchedule: (item: Schedule) => void;
  onRemoveSchedule: (id: string) => void;
  actions: Action[];
  onDeleteSchedule: (id: string) => void;
}

const ScheduleList: React.FC<StateProps> = ({
  data,
  loadingSchedules,
  onEditSchedule,
  onDelete,
  onAddState,
  onDeleteSchedule,
  actions,
}) => {
  const { t } = useTranslation();

  const header = (
    <div>
      <ListHeader template="2fr 1fr 1fr 1fr 1fr">
        <ListHeaderLabel
          value={t("component.orderlineStateList.name")}
          textAlign="center"
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderStateList.scheduling")}
          textAlign="center"
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.schedules.timezone")}
          textAlign="center"
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.schedules.lastStatus")}
          textAlign="center"
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={""}
          textAlign="center"
          justifyContent="flex-start"
        />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <List
        data={data}
        keyExtractor={(item) => item.id}
        renderHeader={() => header}
        renderItem={(item) => (
          <ScheduleItem
            actions={actions}
            key={item.id}
            onDeleteSchedule={onDeleteSchedule}
            onAdd={onAddState}
            loading={loadingSchedules}
            index={data.indexOf(item)}
            onEditSchedule={(item) => onEditSchedule(item)}
            onDelete={() => onDelete(item.id)}
            data={item}
          />
        )}
      />
    </div>
  );
};

export default ScheduleList;
