import { stylesheet } from "typestyle";
import { black } from "../../utils/colors";

const styles = stylesheet({
  container: {
    padding: "2em",
  },
  title: {
    fontSize: "1.5em",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "left",
    color: "#141322",
    paddingTop: "1em",
  },
  searchRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "1em",
  },
  labelContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    marginRight: "0.5em",
    padding: "1em",
  },
  center: {
    color: black,
    fontStyle: "normal",
    justifyContent: "space-between",
    padding: "1em",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    marginRight: "0.5em",
  },
  normal: {
    fontSize: "1em",
    fontWeight: 500,
    alignItems: "center",
    display: "flex",
    color: "#333333",
    padding: "0.5em 0.5em",
  },
  normalBold: {
    fontSize: "1em",
    fontWeight: 700,
    alignItems: "center",
    display: "flex",
    color: "#333333",
    padding: "0.5em 0.5em",
  },
  justy: {
    fontSize: "1em",
    fontWeight: 700,
    alignItems: "center",
    display: "flex",
    justifyContent: 'flex-start',
    color: "#333333",
    padding: "0 0.5em",
  },
  wrap: {
    fontSize: "1em",
    fontWeight: 600,
    alignItems: "center",
    display: "flex",
    justifyContent: 'flex-start',
    color: "#333333",
    padding: "0 0.5em",
    flexWrap: "wrap",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1em",
  },
  icon: {
    width: 23,
    cursor: "pointer",
    marginRight: "1em",
  },
  paginationContainerFixed: {
    padding: "2em 0 1em 0",
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    position: "fixed",
    bottom: 0,
    background: "#f2f4f8",
  },
  iconDelete: {
    width: 23,
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
  },
  paginationContainer: {
    padding: "2em 0",
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  listContainer: {
    paddingBottom: "3em",
  },
});

export default styles;
