import React, { useState } from "react";
import { ColumnsType } from "antd/es/table";
import { TransferItem } from "antd/es/transfer";
import ProjectionTransfer, { RecordType } from "./ProjectionTransfer";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";

interface Props {
  inputString: string;
  setExcludedIds: (values: string[]) => void;
}

const leftTableColumns: ColumnsType<TransferItem> = [
  {
    dataIndex: "title",
    title: "Value",
  },
];

const rightTableColumns: ColumnsType<Pick<TransferItem, "title">> = [
  {
    dataIndex: "title",
    title: "Value",
  },
];

function getIdsByIndexStrings(indexStringArray: string[], idArray: string[]) {
  let result = [];

  for (let i = 0; i < indexStringArray.length; i++) {
    let indexString = indexStringArray[i];
    let index = parseInt(indexString, 10);

    if (!isNaN(index) && index >= 0 && index < idArray.length) {
      result.push(idArray[index]);
    } else {
      console.warn(`Invalid index: ${indexString}`);
    }
  }

  return result;
}

const Projection: React.FC<Props> = ({ inputString, setExcludedIds }) => {
  const arrayOfStrings = inputString.split(";");

  const data: RecordType[] = arrayOfStrings.map((item, index) => ({
    key: index.toString(),
    title: item,
    description: item,
    externalId: item,
    actionId: item,
    disabled: false,
    id: item,
    type: "",
  }));

  const originTargetKeys = data.map((item: any) => item.title);
  const [targetKeys, setTargetKeys] = useState<string[]>(originTargetKeys);

  const onChange = (nextTargetKeys: string[]) => {
    setTargetKeys(nextTargetKeys);
    const assetIdss = getIdsByIndexStrings(nextTargetKeys, originTargetKeys);
    setExcludedIds(assetIdss);
  };

  return (
    <div>
      <GridContainer padding="0 0 0.2em 0" gap="3em" columns="1fr 1fr">
        <InputLabel label="Column Values" />
        <InputLabel label="Excluded Columns" />
      </GridContainer>

      <ProjectionTransfer
        dataSource={data}
        showSearch={true}
        disabled={false}
        targetKeys={targetKeys}
        onChange={onChange}
        filterOption={(inputValue, item) =>
          item.title!.indexOf(inputValue) !== -1
        }
        leftColumns={leftTableColumns}
        rightColumns={rightTableColumns}
      />
    </div>
  );
};

export default Projection;
