import { get, isNil, omitBy } from "lodash";
import { Checkpoint } from "../utils/types";
import { getAccessToken } from "./auth";
import fetch from "../utils/fetch";
import { dateFilters } from "../utils/times";

export function parseCheckpoints(data: unknown): Checkpoint {
  return {
    id: get(data, "_id", ""),
    type: get(data, "type", ""),
    subtype: get(data, "subtype", ""),
    fulfillerId: get(data, "fulfillerId", ""),
    fulfillmentTeamId: get(data, "fulfillmentTeamId", ""),
    userId: get(data, "userId", ""),
    assetExternalId: get(data, "assetExternalId", ""),
    assetId: get(data, "assetId", ""),
    numberOfFiles: get(data, "numberOfFiles", 0),
    checkpointDate: get(data, "checkpointDate", ""),
    geolocation: {
      latitude: get(data, "geolocation.latitude", ""),
      longitude: get(data, "geolocation.longitude", ""),
    },
  };
}

export interface ListCheckpointsParams {
  fulfillerId: string;
  from: string;
  to: string;
  timezone: string;
  page: number;
  records_per_page: number;
}

export interface CheckpointsResponse {
  hits: Checkpoint[];
  count: number;
  page: number;
}

function mapSort(data: ListCheckpointsParams) {
  return omitBy(
    {
      fulfillerId: data.fulfillerId || null,
      timezone: data.timezone || null,
      from: dateFilters(data.from, "00:00:00.000"),
      to: dateFilters(data.to, "23:59:00.000"),
      page: data.page || 0,
      records_per_page: data.records_per_page || 10,
    },
    isNil
  );
}

export async function listCheckpoints(
  params: ListCheckpointsParams
): Promise<CheckpointsResponse> {
  const token = await getAccessToken();

  const body = mapSort(params);

  const res = await fetch("/checkpoints/paginate", token, "POST", body);

  return {
    hits: res.body ? res.body.hits.map(parseCheckpoints) : [],
    count: get(res.body, "count", 0),
    page: get(res.body, "page", 0),
  };
}
