import React, { useCallback, useEffect, useState } from "react";
import { formatDate } from "../../utils/times";
import InfoDetail from "./InfoDetail";
import { Card } from "../Card/Card";
import { PaymentState } from "@innomius/ravent-typescript-types";
import styles from "./styles";
import { numberFormat } from "../../utils/format";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { message, Popover } from "antd";
import APIError from "../../utils/APIError";
import { expireOrder } from "../../services/orders";
import DeletePopUp from "../DeletePopUp";
import InputDate from "../Inputs/Date";
import ActionRowOrder from "./ActionRowOrder";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { GridContainer } from "../GridContainer/GridContainer";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { StatusCatalog } from "../StatusBadge";

interface OrderHeaderProps {
  id: string;
  userId: string;
  status: StatusCatalog;
  paymentStatus: PaymentState;
  load: () => void;
  created: Date;
  paymentDueDate: Date;
  dueAmount: number;
  paidAmount: number;
  currency: string;
  onComment: () => void;
  commentsLength: number;
  updatePaymentDueDate: (date: Date) => void;
  review: {
    collectPayment: boolean;
    replyMessage: boolean;
    issueTickets: boolean;
    refundOrder: boolean;
    readyOrder: boolean;
    resetOrder: boolean;
  };
  team: string;
  path: string;
  teams: { label: string; value: string }[];
  updateTeam: (id: string, teamId: string) => void;
  updateFulfillmentDefinition: (definitionId: string) => void;
  fulfillmentDefintions: { label: string; value: string }[];
  errorFulfillment: string;
  fulfillmentState: string;
  setState: (name: string, w3w: string, images: string[]) => void;
  fulfillmentLevel: string;
  locationLevel: string;
  fulfillmentDefinitionId: string;
  orderState: string;
}

const OrderHeader: React.FC<OrderHeaderProps> = ({
  created,
  dueAmount,
  paidAmount,
  onComment,
  status,
  id,
  review,
  paymentDueDate,
  userId,
  updatePaymentDueDate,
  load,
  team,
  path,
  updateFulfillmentDefinition,
  fulfillmentDefintions,
  errorFulfillment,
  fulfillmentState,
  setState,
  fulfillmentLevel,
  locationLevel,
  fulfillmentDefinitionId,
  orderState,
  currency,
  commentsLength,
}) => {
  const reviewArray = Object.entries(review);
  const [editDueDate, setEdit] = useState(false);
  const [dueDate, onChangeDueDate] = useState<Date>(paymentDueDate);
  const [pop, setPop] = useState(false);

  const setExpired = useCallback(async () => {
    try {
      const expired = dayjs(paymentDueDate) < dayjs(new Date());
      if (status === "unpaid" && expired) {
        await expireOrder(id, userId);
        load();
      }
    } catch (err) {
      if (err instanceof APIError) {
        message.error(err.message);
      }
    }
  }, [paymentDueDate, status, id, userId, load]);

  useEffect(() => {
    setExpired();
  }, [setExpired]);

  const { t } = useTranslation();

  const expired = dayjs(paymentDueDate) < dayjs(new Date());

  return (
    <Card containerClassname={styles.card}>
      <div>
        <GridContainer
          alignItems="center"
          justifyContent="center"
          gap="1em"
          columns="3fr 2px 2fr 1.5fr"
        >
          <FlexContainer
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            {/* <InfoDetail label={t("component.orderDetail.status")}> */}
            <div style={{ paddingBottom: "0.5em" }}></div>
            <GridContainer
              gap="1em"
              columns="0.7fr 1fr 0.7fr"
              justifyContent="flex-start"
            >
              <FlexContainer
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <InfoDetail
                  label={t("component.orderDetail.created")}
                  value={formatDate(new Date(created), "MIDDLEWHOUR")}
                />
              </FlexContainer>

              {!editDueDate ? (
                <FlexContainer
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <InfoDetail
                    label={
                      status === "paid"
                        ? t("component.orderDetail.payLimit")
                        : expired
                        ? t("component.orderDetail.expired")
                        : t("component.orderDetail.expires")
                    }
                    color={expired ? "red" : undefined}
                    value={
                      paymentDueDate
                        ? formatDate(new Date(paymentDueDate), "MIDDLEWHOUR")
                        : "-"
                    }
                  />
                  <EditIcon
                    onClick={() => setEdit(true)}
                    className={styles.icon}
                  />
                </FlexContainer>
              ) : (
                <FlexContainer
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <Popover
                    content={
                      <div onClick={() => setPop(false)}>
                        <DeletePopUp
                          value={`Are you sure you want to update the payment due date?`}
                          onClose={(e) => {
                            e.stopPropagation();
                            setPop(false);
                            setEdit(false);

                            onChangeDueDate(paymentDueDate);
                          }}
                          onDelete={(e) => {
                            e.stopPropagation();
                            updatePaymentDueDate(dueDate);
                            setPop(false);
                            setEdit(false);
                          }}
                        />
                      </div>
                    }
                    trigger="click"
                    open={pop}
                  >
                    <InputDate
                      id="paymentDate"
                      defaultValue={paymentDueDate}
                      showTime={true}
                      label={t("component.orderDetail.payLimit")}
                      value={dueDate || new Date()}
                      onChange={(value) => {
                        onChangeDueDate(value);
                        setPop(true);
                      }}
                    />
                  </Popover>
                </FlexContainer>
              )}
              <FlexContainer
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <InfoDetail
                  label={t("component.orderDetail.fulfillmentLevel")}
                  value={fulfillmentLevel}
                />
              </FlexContainer>
            </GridContainer>
          </FlexContainer>

          <div className={styles.verticalLine}></div>

          <FlexContainer
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            {/* <InfoDetail label={t("component.orderDetail.status")}> */}

            {/* </InfoDetail> */}
            <div className={styles.paymentWrapper}>
              <FlexContainer
                padding="0 2em 0 0"
                alignItems="flex-start"
                flexDirection="column"
              >
                <p className={styles.title}>
                  {dueAmount < 0
                    ? t("component.orderDetail.overPaid")
                    : t("component.orderDetail.due")}
                </p>
                <p className={dueAmount < 0 ? styles.amountRed : styles.amount}>
                  {currency} {` `}
                  {numberFormat(dueAmount) || numberFormat(0)}
                </p>
              </FlexContainer>

              <FlexContainer
                padding="0 2em 0 0"
                alignItems="flex-start"
                flexDirection="column"
              >
                <p className={styles.title}>
                  {t("component.orderDetail.paid")}
                </p>
                <p className={styles.amount}>
                  {currency} {numberFormat(paidAmount)}
                </p>
              </FlexContainer>
            </div>
          </FlexContainer>

          <ActionRowOrder
            commentsLength={commentsLength}
            onComment={onComment}
            orderId={id}
            reviewArray={reviewArray}
            orderState={orderState}
            fulfillmentLevel={fulfillmentLevel}
            locationLevel={locationLevel}
            setState={setState}
            fulfillmentDefintions={fulfillmentDefintions}
            fulfillmentState={fulfillmentState}
            fulfillmentDefinitionId={fulfillmentDefinitionId}
            path={path}
            errorFulfillment={errorFulfillment}
            team={team}
            updateFulfillmentDefinition={(orderId, defId) =>
              updateFulfillmentDefinition(defId)
            }
          />
        </GridContainer>
      </div>
    </Card>
  );
};

export default OrderHeader;
