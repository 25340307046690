import React, { useState } from "react";

import styles from "./styles";
import { useTranslation } from "react-i18next";
import { Action, Schedule } from "@innomius/ravent-typescript-types";
import DeleteModal from "../DeleteModal";
import dayjs from "dayjs";
import { SectionProperty } from "../Section/SectionProperty";
import { GridContainer } from "../GridContainer/GridContainer";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
// import { formatDate } from "../../utils/times";

interface Props {
  data: Schedule;
  onDeleteSchedule: (id: string) => void;
  actions: Action[];
}

const ScheduleDetail: React.FC<Props> = ({
  data,
  onDeleteSchedule,
  actions,
}) => {
  const [modal, setModal] = useState<string>("");

  const { t } = useTranslation();

  return (
    <div>
      <DeleteModal
        open={modal === "remove" || modal === "delete"}
        onCancel={() => setModal("")}
        onDelete={() => onDeleteSchedule(data.id)}
        value={
          modal === "remove"
            ? "Are you sure you want to remove the scheduling of this Schedule?"
            : "Are you sure you want to delete this Schedule?"
        }
      />
      <div className={styles.itemsWrapper}>
        <div>
          <GridContainer padding="1.5em 0 0em 0" gap="1em" columns="1fr">
            <SectionProperty
              value={
                actions.find((item) => item.id === data.actionId)?.name || ""
              }
              label={t("component.addIntegration.action")}
            />
            <SectionProperty
              value={data.description}
              label={t("component.addIntegration.description")}
            />
            <SectionProperty
              value={data.lastStatus}
              label={t("component.schedules.lastStatus")}
            />
          </GridContainer>

          <>
            <FlexContainer padding="0 0 1em 0" justifyContent="space-between">
              <ContainerTitle
                size="xsmall"
                label={t("component.orderStateList.frequency")}
              />
            </FlexContainer>

            <FlexContainer padding="0 0 2em 0" justifyContent="flex-start">
              <SectionProperty
                label=""
                value={
                  data.type === "at"
                    ? t("component.orderStateList.single")
                    : t("component.orderStateList.multiple")
                }
              />
            </FlexContainer>
            {data.type === "at" ? (
              <FlexContainer
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <GridContainer
                  padding="0 0 2em 0"
                  gap="1em"
                  columns="1.5fr 1fr 1fr"
                >
                  <SectionProperty
                    label={t("component.orderStateList.when")}
                    value={`${dayjs(data.date)
                      .tz(data.timezone)
                      .format("DD MMMM, YYYY HH:mm")}`}
                  />
                  <SectionProperty
                    label={t("component.orderFilters.timezone")}
                    value={data.timezone}
                  />
                </GridContainer>
              </FlexContainer>
            ) : (
              <div>
                <FlexContainer justifyContent="space-between">
                  <ContainerTitle
                    padding="0 0 1em 0"
                    size="xsmall"
                    label={t("component.orderStateList.scheduleTimes")}
                  />
                </FlexContainer>

                <SectionProperty
                  label={t("component.orderStateList.cronTabExpression")}
                  value={data.cronTabExpression as string}
                />
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default ScheduleDetail;
