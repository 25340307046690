import { stylesheet } from "typestyle";

const styles = stylesheet({
  button: {
    margin: "0 0.5em",
  },
  insideButton: {
    padding: "1em",
  },

  left: {
    color: "#333333",
    fontStyle: "normal",
    justifyContent: "flex-start",
    display: "flex",
    alignItems: "center",
  },
  row: { paddingTop: "1em" },
  normal: {
    cursor: "pointer",
    fontSize: "1em",
    fontWeight: 600,
    padding: "0 0.5em",
    border: "1px solid #dce1e6",
    borderRadius: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "2.9em",
    minWidth: "9em",
  },

  normalSmall: {
    cursor: "pointer",
    fontSize: "1em",
    fontWeight: 600,
    padding: "0 0.5em",
    border: "1px solid #dce1e6",
    borderRadius: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "2.9em",
    minWidth: "4em",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    height: "2.6em",
    cursor: "pointer",
  },
  smallIcon: {
    width: "1.2em",
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
    justifyContent: "",
  },
  paginationContainer: {
    padding: "2em 0",
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  listContainer: {
    marginTop: "0",
  },
  buttonSize: {},
});

export default styles;
