import React, { useState } from "react";
import {
  FulfillmentEvent,
  Load,
  OrderLine,
  Placement,
} from "@innomius/ravent-typescript-types";
import { useTranslation } from "react-i18next";
import styles from "./styles";

import OrderEventForm from "./OrderEventForm";
import { defaultFulfillmentEvent } from "../../services/fulfilments";
import OrderEventList from "./OrderEventList";
import locationEmpty from "../../assets/empty-location.svg";
import { Asset } from "../../utils/types";
import { Card } from "../Card/Card";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { Button } from "../Button";
import { EmptyList } from "../List/EmptyList";

interface Props {
  events: FulfillmentEvent[];
  onCreateEvent: (location: FulfillmentEvent, orderlineId?: string) => void;
  onEditEvent: (event: FulfillmentEvent) => void;
  description: string;
  id: string;
  onDeletePlacement: (event: FulfillmentEvent, id: string) => void;
  onEditPlacement: (event: FulfillmentEvent, placement: Placement) => void;
  path: string;
  onDeleteEvent: (id: string, orderlineId: string) => void;
  assets: Asset[];
  updateAsset: (event: FulfillmentEvent, id: string) => void;
  onSaveLoad: (eventId: string, data: Load[]) => void;
  teams: { label: string; value: string }[];
  updateTeam: (eventId: string, teamId: string) => void;
  updateFulfiller: (eventId: string, fulfillerId: string) => void;
  loading?: boolean;
  fulfillmentLevel: string;
  orderlines: OrderLine[];
  saveLoc: (p: Placement, type: string, group: string) => void;
  onChangeEventState: (eventId: string) => void;
  setState: (
    id: string,
    fulfillmentLevel: string,
    status: string,
    w3w: string,
    images: string[]
  ) => void;
  updateFulfillmentName: (
    id: string,
    diveshopId: string,
    fulfillmentLevel: string
  ) => void;
  fulfillmentNames: { label: string; value: string }[];
  venues: { value: string; label: string }[];
  loadEvent: () => void;
  setNew: (eventId: string, state: string) => void;
  flows: { name: string; actionId: string; url: string }[];
}

const OrderEvent: React.FC<Props> = ({
  events,
  loading,
  onCreateEvent,
  description,
  id,
  onDeleteEvent,
  onDeletePlacement,
  onEditPlacement,
  onEditEvent,
  path,
  assets,
  updateAsset,
  onSaveLoad,
  teams,
  updateTeam,
  updateFulfiller,
  fulfillmentLevel,
  orderlines,
  saveLoc,
  onChangeEventState,
  fulfillmentNames,
  updateFulfillmentName,
  setState,
  venues,
  loadEvent,
  setNew,
  flows
}) => {
  const { t } = useTranslation();
  const [openForm, setForm] = useState("");

  return (
    <div className={styles.container}>
      <Card>
        <div className={styles.wrapperCard}>
          <FlexContainer
            padding="0em 0 2em 0"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <ContainerTitle
              size="small"
              label={t("component.orderLocation.locations")}
            />
            {fulfillmentLevel !== "orderline" ? (
              <Button
                label={t("component.orderLocation.setLocation")}
                onClick={() => setForm("event")}
              />
            ) : null}
          </FlexContainer>
          <OrderEventForm
            data={defaultFulfillmentEvent}
            onCreateEvent={(event) => onCreateEvent(event)}
            id={id}
            assets={assets}
            open={openForm === "event"}
            onClose={() => setForm("")}
          />
          {events?.length ? (
            <OrderEventList
              flows={flows}
              path={path}
              onCreateEvent={(event, orderlineId) =>
                onCreateEvent(event, orderlineId)
              }
              setNew={setNew}
              loadEvent={loadEvent}
              venues={venues}
              fulfillmentNames={fulfillmentNames}
              setState={setState}
              updateFulfillmentName={updateFulfillmentName}
              onChangeEventState={onChangeEventState}
              saveLoc={saveLoc}
              orderlines={orderlines}
              fulfillmentLevel={fulfillmentLevel}
              orderEvent
              loading={loading}
              teams={teams}
              updateFulfiller={updateFulfiller}
              updateTeam={updateTeam}
              onSaveLoad={onSaveLoad}
              assets={assets}
              updateAsset={updateAsset}
              onDeletePlacement={onDeletePlacement}
              onEditPlacement={onEditPlacement}
              onEditEvent={(event) => onEditEvent(event)}
              description={description}
              onDelete={(i) => onDeleteEvent(i, id)}
              sortable
              events={events}
            />
          ) : (
            <EmptyList
              image={locationEmpty}
              instructions=""
              onClick={() => setForm("location")}
              text={t("component.orderLocation.noLocations")}
            />
          )}
        </div>
      </Card>
    </div>
  );
};

export default OrderEvent;
