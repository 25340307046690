import React from "react";

import { Card } from "../Card/Card";
import styles from "./styles";
import { CreateUserParams } from "../../services/users";
import CustomError from "../../utils/CustomError";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { Checkbox, Switch, Tooltip } from "antd";
import Text from "../Text";
import { InputTags } from "../Inputs/InputTags";
import { GroupWithValues } from "../Inputs/InputCheckboxGroup/GroupWithValue";
import { Role } from "../../services/roles";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
import { InputText } from "../Inputs/InputText";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ButtonsContainer } from "../ButtonsContainer";
import info from "../../assets/info.svg";

interface Props {
  editing: boolean;
  data: CreateUserParams;
  onChange: <P extends keyof CreateUserParams>(
    prop: P,
    value: CreateUserParams[P]
  ) => void;
  error?: CustomError;
  permission: boolean;
  roles: Role[];
}

const UserForm: React.FC<Props> = ({
  data,
  editing,
  onChange,
  error,
  permission,
  roles,
}) => {
  const { t } = useTranslation();

  const options = roles.map((role) => ({
    label: role.name,
    value: role.id,
  }));

  const rolesV = data.roles.map((value) => value.replace("role:", ""));

  return (
    <div>
      <Card>
        <div className={styles.wrapperCard}>
          <FlexContainer justifyContent="space-between">
            <ContainerTitle
              size="small"
              padding="0 0 1em 0"
              label={t("component.userForm.userInfo")}
            />
            <ButtonsContainer>
              <FlexContainer alignItems="center" justifyContent="flex-start">
                <div style={{ marginRight: "1em" }}>
                  <FlexContainer justifyContent="flex-start">
                    <InputLabel
                      label={`${t("component.userForm.adminRole")} (${t(
                        "component.userForm.superUser"
                      )})`}
                    />
                    <Tooltip title={t("component.userForm.superUserInfo")}>
                      <img style={{ marginLeft: "0.2em" }} src={info} alt="" />
                    </Tooltip>
                  </FlexContainer>
                </div>
                <Switch
                  checked={data.adminRole}
                  onChange={(checked) => onChange("adminRole", checked)}
                />
              </FlexContainer>
              <FlexContainer alignItems="center" justifyContent="flex-start">
                <div style={{ marginRight: "1em" }}>
                  <InputLabel label={t("component.diversForm.status")} />
                </div>
                <Switch
                  checked={data.disabled}
                  onChange={(checked) => onChange("disabled", checked)}
                />
              </FlexContainer>
            </ButtonsContainer>
          </FlexContainer>

          <GridContainer gap="2em" columns="0.5fr 1fr 1fr">
            <InputText
              label={t("component.userForm.externalId")}
              value={data.externalId}
              onChange={(value) => onChange("externalId", value)}
            />
            {editing ? null : (
              <>
                <InputText
                  label={t("component.userForm.name")}
                  value={data.firstName}
                  onChange={(value) => onChange("firstName", value)}
                />
                <InputText
                  label={t("component.userForm.lastName")}
                  value={data.lastName}
                  onChange={(value) => onChange("lastName", value)}
                />
              </>
            )}
          </GridContainer>
          {editing ? null : (
            <Checkbox
              checked={data.loginWithPhone}
              onChange={(checked) =>
                onChange("loginWithPhone", checked.target.checked)
              }
            >
              <Text text={t("component.userForm.loginWithPhone")} />
            </Checkbox>
          )}

          <GridContainer padding="0em 0 0 0" gap="2em" columns="1fr 1fr 1fr">
            <div>
              <InputLabel label={t("component.userForm.phone")} />
              <PhoneInput
                disabled={data.loginWithPhone && data.isEnrolled}
                country={"mx"}
                value={data.phone}
                onChange={(value) => onChange("phone", value)}
              />
            </div>

            <InputText
              label={t("component.userForm.email")}
              value={data.email}
              disabled={!data.loginWithPhone && data.isEnrolled}
              onChange={(value) => onChange("email", value)}
              error={
                !data.email &&
                !data.loginWithPhone &&
                get(error, "errors.user-email", "")
              }
            />
          </GridContainer>

          <div style={{ marginBottom: "1em" }}>
            <InputTags
              label={t("component.assetForm.tags")}
              value={data.tags}
              onChange={(value) => onChange("tags", value)}
            />
          </div>
          {/* {!editing && (
            <>
              <InputLabel label={t("component.userForm.userType")} />
              <FlexContainer justifyContent="flex-start">
                <Radio.Group
                  onChange={(value) => onChange("type", value.target.value)}
                >
                  <Radio value={"user"}>User</Radio>
                  <Radio value="fulfiller">Fulfiller</Radio>
                </Radio.Group>
              </FlexContainer>
            </>
          )} */}

          {!editing ? (
            <>
              <ContainerTitle
                padding="1em 0 1em 0"
                size="small"
                label={t("component.userForm.roles")}
              />
              <GroupWithValues
                onChange={(checkedValues) => {
                  const selected = checkedValues.map((item) => item.toString());
                  onChange("roles", selected);
                }}
                value={rolesV}
                options={options}
              />
            </>
          ) : null}
        </div>
      </Card>
    </div>
  );
};

export default UserForm;
