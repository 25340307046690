import { stylesheet } from "typestyle";

const styles = stylesheet({
  container: {
    padding: '1em 0 0 0'
  },
  button: {
    marginRight: '1em'
  }
});

export default styles;
