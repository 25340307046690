const translation = {
  en: {
    description: "Description",
    requestId: "Request Id",
    paymentId: "Payment Id",
    issuer: "Issuer",
    issuedDate: "Issued Date",
    amount: "Amount",
    status: "Status",
    actions: "Actions",
    invoiceDetails: "Invoice Details",
    invoiceId: "Invoice ID",
    invoiceData: "Invoice Data",
    issuerId: "Issuer ID",
    authority: "Authority",
    authorityId: "Authority ID",
    issueTimestamp: "Issued timestamp",
    files: "Files",
    uploadFiles: "Upload Files",
    cancel: "Cancel Invoice",
    close: "Close",
    warning: "Are you sure you want cancel this invoice ?",
    editCancel: "Cancel",
    save: "Save",
    refund: "Issue Refund",
    successC: "Invoice was successfuly cancelled",
  },
  es: {
    successC: "La factura se canceló éxitosamente",
    description: "Description",
    editCancel: "Cancelar",
    save: "Guardar",
    requestId: "Request Id",
    paymentId: "Payment Id",
    issuer: "Issuer",
    issuedDate: "Issued Date",
    amount: "Amount",
    status: "Status",
    actions: "Actions",
    invoiceDetails: "Invoice Details",
    invoiceId: "Invoice ID",
    invoiceData: "Invoice Data",
    issuerId: "Issuer ID",
    authority: "Authority",
    authorityId: "Authority ID",
    issueTimestamp: "Issued timestamp",
    files: "Files",
    uploadFiles: "Upload Files",
    cancel: "Cancel Invoice",
    close: "Close",
    warning: "¿Está seguro de que desea cancelar esta factura?",
    refund: "Emitir Reembolso",
  },
  de: {},
};

export default translation;
