import React, { useState, useEffect } from "react";
import {
  DirectionsRenderer,
  GoogleMap,
  useJsApiLoader,
} from "@react-google-maps/api";
import markerSVG from "../../assets/marker.svg";

interface MarkerData {
  lat: number;
  lng: number;
}

interface MapWithMarkersProps {
  markers: MarkerData[];
  startCenter?: { lat: number; lng: number };
}

const MapWithMarkers: React.FC<MapWithMarkersProps> = ({
  markers,
  startCenter,
}) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [directions, setDirections] =
    useState<google.maps.DirectionsResult | null>(null);

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const center = startCenter || { lat: 21.0417033, lng: -86.8740062 };

  const onLoad = (mapInstance: google.maps.Map) => {
    setMap(mapInstance);
  };

  useEffect(() => {
    setDirections(null);

    if (map) {
      if (markers.length >= 2) {
        // If there are 2 or more markers, show directions
        const directionsService = new google.maps.DirectionsService();

        const waypoints = markers
          .slice(1, markers.length - 1)
          .map((marker) => ({
            location: { lat: marker.lat, lng: marker.lng },
          }));

        directionsService.route(
          {
            origin: { lat: markers[0].lat, lng: markers[0].lng },
            destination: {
              lat: markers[markers.length - 1].lat,
              lng: markers[markers.length - 1].lng,
            },
            waypoints,
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              setDirections(result);
            } else {
              console.error("Directions request failed:", status);
            }
          }
        );
      } else if (markers.length === 1) {
        // If there's only 1 marker, show marker point and zoom in
        const marker = new google.maps.Marker({
          position: { lat: markers[0].lat, lng: markers[0].lng },
          map: map,
          icon: {
            url: markerSVG,
            scaledSize: new google.maps.Size(32, 32),
          },
        });

        if (marker.getPosition()) {
          map.setCenter(marker.getPosition() as google.maps.LatLng);
          map.setZoom(15);
        }

        return () => {
          marker.setMap(null);
        };
      }
    }
  }, [map, markers]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBnUmMfyn4yxmKUBh-xP8U9k7GYmMp-oyo",
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      onLoad={onLoad}
    >
      {directions && (
        <DirectionsRenderer
          directions={directions}
          options={{
            markerOptions: {
              icon: markerSVG,
              clickable: true,
            },
          }}
        />
      )}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default MapWithMarkers;
