import React, { useState } from "react";

import { Offer } from "@innomius/ravent-typescript-types";
import OfferTicketingForm from "../OfferForm/OfferTicketingForm";
import { capitalize } from "../../utils/format";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { Button } from "../Button";
import { InputSwitch } from "../Inputs/InputSwitch";
import { SectionProperty } from "../Section/SectionProperty";
import { GridContainer } from "../GridContainer/GridContainer";
import { EmptyList } from "../List/EmptyList";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";

interface Props {
  data: Offer;
  onChange: <P extends keyof Offer>(prop: P, value: Offer[P]) => void;
}

const Ticketing: React.FC<Props> = ({ data, onChange }) => {
  const [edit, setEdit] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      {edit ? (
        <OfferTicketingForm
          onCancel={() => setEdit(false)}
          onEdit={() => setEdit(false)}
          edit={edit}
          data={data}
          onChange={onChange}
        />
      ) : (
        <>
          <FlexContainer padding="1em 0" justifyContent="space-between">
            <ContainerTitle
              padding="0 0 0 0"
              label={t("component.offerVariantDetail.ticketing")}
              size="xsmall"
            />
            <Button
              theme="white"
              label={t("component.offerVariantDetail.editButtonLabel")}
              onClick={() => setEdit(true)}
            />
          </FlexContainer>
          {data.ticketingEnabled ? (
            <>
              <FlexContainer justifyContent="flex-start">
                <InputSwitch
                  value={data.ticketingFulfillment.customerNameRequired}
                />
                <p>{t("component.offerVariantDetail.customerName")}</p>
              </FlexContainer>
              <ContainerTitle
                padding="1em 0 1em 0"
                label={t("component.offerVariantDetail.activation")}
                size="xsmall"
              />
              <GridContainer gap="1em" columns="1fr 1fr 1fr">
                <SectionProperty
                  label={t("component.offerVariantDetail.activationType")}
                  value={
                    data.ticketingFulfillment.activationType === "at-date-time"
                      ? "At Date Time"
                      : data.ticketingFulfillment.activationType ===
                        "at-first-use"
                      ? "At Issuance"
                      : "At Check-in"
                  }
                />

                {data.ticketingFulfillment.activationType === "at-first-use" ? (
                  <SectionProperty
                    label={t("component.offerVariantDetail.maxEvents")}
                    value={data.ticketingFulfillment.maxEvents.toString()}
                  />
                ) : null}
              </GridContainer>
              {data.ticketingFulfillment.lifeTime ? (
                <div>
                  <ContainerTitle
                    padding="1em 0 1em 0"
                    label={t("component.offerVariantDetail.lifeTime")}
                    size="xsmall"
                  />
                  <GridContainer gap="1em" columns="1fr 0.5fr 0.5fr 1fr">
                    <SectionProperty
                      label={t("component.offerVariantDetail.lifeTimeType")}
                      value={capitalize(
                        data.ticketingFulfillment.lifeTime.type
                      )}
                    />
                    <SectionProperty
                      label={t("component.offerVariantDetail.amount")}
                      value={data.ticketingFulfillment.lifeTime.amount.toString()}
                    />
                    <SectionProperty
                      label={t("component.offerVariantDetail.unit")}
                      value={data.ticketingFulfillment.lifeTime.unit}
                    />
                  </GridContainer>
                </div>
              ) : null}

              <ContainerTitle
                padding="1em 0 1em 0"
                label={t("component.offerVariantDetail.properties")}
                size="xsmall"
              />
              <p className={styles.propertyInstructions}>
                {t("component.offerVariantDetail.propertyDescription")}
              </p>

              {!data.ticketingFulfillment.properties.length ? (
                <EmptyList text="No properties found" instructions="" />
              ) : (
                <div>
                  <ListHeader template="1fr 1fr 1fr">
                    <ListHeaderLabel
                      value={t("component.offerVariantDetail.propertyName")}
                      justifyContent="flex-start"
                    />
                    <ListHeaderLabel
                      value={t("component.offerVariantDetail.propertyValue")}
                      justifyContent="flex-start"
                    />
                    <ListHeaderLabel
                      value={t("component.offerVariantDetail.valueType")}
                      justifyContent="flex-start"
                    />
                  </ListHeader>

                  {data.ticketingFulfillment.properties.map((item: any) => (
                    <ListRow template="1fr 1fr 1fr">
                      <ListLabel value={item.name} />
                      <ListLabel value={item.value as string} />

                      <ListLabel value={item.valueType} />
                    </ListRow>
                  ))}
                </div>
              )}
            </>
          ) : (
            <p>{t("component.offerVariantDetail.ticketingNotEnabled")}</p>
          )}
        </>
      )}
    </div>
  );
};
export default Ticketing;
