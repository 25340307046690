import React, { useCallback, useEffect, useState } from "react";
import { Popover, Tag, Tooltip } from "antd";
import DeletePopUp from "../../DeletePopUp";
import { useTranslation } from "react-i18next";
import {
  FulfillmentEvent,
  Load,
  Placement,
  Fulfiller,
  EventComment,
} from "@innomius/ravent-typescript-types";
import OrderEventDetail from "../OrderEventDetail";
import { ReactComponent as EditIcon } from "../../../assets/editBlack.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/deletBlack.svg";
import { ReactComponent as SendIcon } from "../../../assets/sendflows4.svg";
import { ReactComponent as StatesIcon } from "../../../assets/statesBlack.svg";
import { ReactComponent as StatesIconBlue } from "../../../assets/statesB.svg";
import { ReactComponent as TagIcon } from "../../../assets/tags.svg";
import { ReactComponent as TagIconBlue } from "../../../assets/tagsBlue.svg";
import { ReactComponent as FulfillerYellowIcon } from "../../../assets/fulfillerYellow.svg";

import CreatePlacement from "../OrderEventForm/CreatePlacement";
import { defaultPlacement } from "../../../services/venues";
import OrderEditForm from "../OrderEventForm/OrderEditForm";
import AssetModal from "../../AssetModal";
import { Asset } from "../../../utils/types";
import { ReactComponent as FulfillerIcon } from "../../../assets/fulfiller.svg";
import DiveShopModal from "../../OrderFulfillment/DiveShopModal";
import { ReactComponent as LoadIcon } from "../../../assets/load.svg";
import { ReactComponent as LoadBlueIcon } from "../../../assets/load-blue.svg";
import { ReactComponent as FulfillerBlueIcon } from "../../../assets/fulfiller-blue.svg";
import { ReactComponent as AssetBlue } from "../../../assets/asset-blue.svg";
import { ReactComponent as AssetIcon } from "../../../assets/sidebar/assets.svg";
import messageIcon from "../../../assets/message-black.svg";
import styles from "./styles";

import Text from "../../Text";

import { formatDate } from "../../../utils/times";
import { ListRow } from "../../List/ListRow";
import { fulfillerList } from "../../../services/entities";
import APIError from "../../../utils/APIError";
import MarkEvent from "../../EventList/MarkEvent";
import LoadModal from "../../LoadModal";
import fontColorContrast from "font-color-contrast";
import { FlexContainer } from "../../FlexContainer/FlexContainer";
import { Button } from "../../Button";
import StatesModal from "../../OrderFulfillment/StatesModal";
import EventComments from "../../EventComments";

interface EventItemProps {
  data: FulfillmentEvent;
  onDelete: (id: string) => void;
  onEditEvent: (event: FulfillmentEvent) => void;
  path: string;
  description: string;
  onDeletePlacement: (event: FulfillmentEvent, id: string) => void;
  onEditPlacement: (event: FulfillmentEvent, placement: Placement) => void;
  assets: Asset[];
  updateAsset: (event: FulfillmentEvent, id: string) => void;
  onSaveLoad: (eventId: string, data: Load[]) => void;
  teams: { label: string; value: string }[];
  updateFulfiller: (eventId: string, fulfillerId: string) => void;
  updateTeam: (eventId: string, teamId: string) => void;
  fulfillmentLevel: string;
  orderEvent?: boolean;
  saveLoc: (p: Placement, type: string, group: string) => void;
  index: number;
  updateFulfillmentName: (
    id: string,
    diveshopId: string,
    fulfillmentLevel: string
  ) => void;
  fulfillmentNames: { label: string; value: string }[];
  errorState: string;
  onChangeEventState: (eventId: string) => void;
  venues: { value: string; label: string }[];
  setNew: (eventId: string, state: string) => void;
  loadEvent: () => void;
  sendToFlows: () => void;
  setState: (
    id: string,
    fulfillmentLevel: string,
    status: string,
    w3w: string,
    images: string[]
  ) => void;
  sendMessage: (eventId: string, message: string) => void;
  messages: EventComment[];
}

const EventDetailComponent: React.FC<EventItemProps> = ({
  onDelete,
  onEditEvent,
  onDeletePlacement,
  onEditPlacement,
  path,
  data,
  assets,
  updateAsset,
  onSaveLoad,
  teams,
  updateTeam,
  updateFulfiller,
  fulfillmentLevel,
  orderEvent,
  saveLoc,
  index,
  onChangeEventState,
  venues,
  setNew,
  loadEvent,
  sendToFlows,
  setState,
  updateFulfillmentName,
  errorState,
  fulfillmentNames,
  sendMessage,
  messages,
}) => {
  const [open, setOpen] = useState("");
  const [event, setEvent] = useState<FulfillmentEvent>(data);
  const [openPlacement, setOpenPlacement] = useState<string>("");
  const [edit, setEdit] = useState(false);
  const [assetModal, setAsset] = useState(false);

  const [fulfillers, setFulfillers] = useState<Fulfiller[] | []>([]);

  const getFulfillers = useCallback(async () => {
    try {
      const res = await fulfillerList({
        fulfillmentTeamId: event.fulfillmentTeamId,
        records_per_page: 1000,
        page: 0,
      });
      setFulfillers(res.data);
    } catch (err) {
      if (err instanceof APIError) {
        console.log(err);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event.fulfillmentTeamId, event.fulfillerId]);

  useEffect(() => {
    if (event.fulfillmentTeamId) {
      getFulfillers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event.fulfillmentTeamId]);

  const onChangeEvent = <P extends keyof FulfillmentEvent>(
    prop: P,
    value: FulfillmentEvent[P]
  ) => {
    setEvent({ ...event, [prop]: value });
  };

  const { t } = useTranslation();

  const formatOptionKey = (key: string, color: string) => {
    if (key.includes("::")) {
      const [firstPart, secondPart] = key.split("::");
      return (
        <Tooltip title={firstPart}>
          <Tag
            style={{
              color: color
                ? color === "blue"
                  ? "blue"
                  : fontColorContrast(color, 0.5)
                : "blue",
            }}
            color={color || "blue"}
          >
            {secondPart}
          </Tag>
        </Tooltip>
      );
    } else {
      return <Tag color="blue">{key}</Tag>;
    }
  };

  return (
    <div>
      <EventComments
        shortId={event.shortId}
        removeMessage={(id) => id}
        sendMessage={(message) => sendMessage(data.id, message)}
        comments={messages}
        open={open === "messages"}
        onClose={() => setOpen("")}
        eventId={data.id}
        eventName={data.name}
      />
      <StatesModal
        updateFulfillmentName={(id, diveshopId) =>
          updateFulfillmentName(id, diveshopId, data.fulfillmentLevel)
        }
        fulfillmentNames={fulfillmentNames}
        fulfillmentDefinitionId={data.fulfillmentDefinitionId}
        error={errorState}
        orderlineId={
          data.fulfillmentLevel === "order"
            ? data.order
            : data.orderlineId || ""
        }
        setState={(name, w3w, images) => {
          try {
            setState(
              data.fulfillmentLevel === "order"
                ? data.order
                : data.orderlineId || "",
              data.fulfillmentLevel,
              name,
              w3w,
              images
            );
            setOpen("");
          } catch (err) {}
        }}
        open={open === "states"}
        onClose={() => setOpen("")}
        state={data.currentState}
      />
      <DiveShopModal
        teamId={data.fulfillmentTeamId}
        orderlineId={data.id}
        teams={teams || []}
        updateTeam={(id: string, teamId: string) => {
          try {
            updateTeam(id, teamId);
            setOpen("");
          } catch (err) {}
        }}
        open={open === "diveshop"}
        onClose={() => setOpen("")}
        fulfillerId={data.fulfillerId}
        updateFulfiller={(id: string, fId: string) => {
          try {
            updateFulfiller(id, fId);
            setOpen("");
          } catch (err) {}
        }}
      />

      <OrderEditForm
        open={edit}
        onDelete={() => onDelete(data.id)}
        onSave={() => onEditEvent(event)}
        onClose={() => {
          setEdit(false);
          setEvent(data);
        }}
        event={event}
        onChangeEvent={onChangeEvent}
      />
      <LoadModal
        data={data.loads}
        onSave={(loads) => onSaveLoad(data.id, loads)}
        open={open === "load"}
        onClose={() => setOpen("")}
      />

      <AssetModal
        open={assetModal}
        assets={assets}
        onClose={() => setAsset(false)}
        assetId={data.assetId}
        updateAsset={(assetId) => updateAsset(data, assetId)}
      />

      <CreatePlacement
        saveLoc={saveLoc}
        venues={venues}
        index={data.placements.length}
        data={defaultPlacement(data.placements.length)}
        setOpen={(value) => {
          if (!value) {
            setOpenPlacement("");
          }
        }}
        onCreatePlacement={(placement) => {
          onEditPlacement(event, placement);
          setOpenPlacement("");
        }}
        open={openPlacement === "placement"}
      />

      <ListRow
        noBorder
        borderDetail="1px solid #DCE1E6"
        borderRadius={
          (fulfillmentLevel === "orderline" && orderEvent) ||
          index === 0 ||
          fulfillmentLevel === "order"
            ? "6px 6px 0px 0px"
            : "0px 0px 0px 0px"
        }
        borderLeft="1px solid #DCE1E6"
        borderRight="1px solid #DCE1E6"
        borderBottom={"none"}
        borderTop={
          index === 0 || fulfillmentLevel === "order"
            ? "1px solid #DCE1E6"
            : "none"
        }
        margin={
          fulfillmentLevel === "orderline" && orderEvent
            ? "0em 0em 0em 0em"
            : "1em 0em 0em 0em"
        }
        onClick={() => {
          if (data.id === open) {
            setOpen("");
          } else setOpen(data.id);
        }}
        showDetail={true}
        template={
          data.endDatetime &&
          formatDate(data.datetime, "DAYMONTHYEAR") !==
            formatDate(data.endDatetime, "DAYMONTHYEAR")
            ? "0.35fr 0.35fr 1.5fr 0.1fr 1fr"
            : formatDate(data.datetime, "DAYMONTHYEAR") ===
              formatDate(data.endDatetime, "DAYMONTHYEAR")
            ? "0.6fr 1.5fr 0.1fr 1fr"
            : "0.35fr 1.5fr 0.1fr 1fr"
        }
        hover={true}
        border={true}
        paddingDetail="1em"
        detail={
          <OrderEventDetail
            teams={teams}
            notShow
            setNew={setNew}
            loadEvent={loadEvent}
            fulfillerName={
              data.fulfillerId
                ? `${
                    fulfillers.find((item) => item.id === data.fulfillerId)
                      ?.firstName
                  } ${
                    fulfillers.find((item) => item.id === data.fulfillerId)
                      ?.lastName
                  }`
                : ""
            }
            venues={venues}
            onChangeEventState={onChangeEventState}
            saveLoc={saveLoc}
            updateFulfiller={updateFulfiller}
            updateTeam={updateTeam}
            onSaveLoad={(load) => onSaveLoad(event.id, load)}
            onChangeEvent={onChangeEvent}
            onEditPlacement={(p) => onEditPlacement(event, p)}
            path={path}
            onDeletePlacement={(id) => {
              onDeletePlacement(event, id);
            }}
            assets={assets}
            updateAsset={(_id, assetId) => updateAsset(event, assetId)}
            id={event.id}
            loading={""}
            event={event}
            onClose={() => {
              setOpen("");
            }}
          />
        }
      >
        <div
          style={{
            borderRadius:
              index === 0 || fulfillmentLevel === "order"
                ? "6px 0 0 0"
                : "none",
          }}
          className={styles.border}
        >
          <Text
            fontSize="1em"
            textAlign="right"
            color="#fff"
            fontWeight={700}
            text={formatDate(data.datetime, "DAYMONTHYEAR")}
          />
          <Text
            fontWeight={700}
            fontSize="1.5em"
            color="#fff"
            text={`${formatDate(data.datetime, "HOUR")} ${
              event.endDatetime &&
              formatDate(data.datetime, "DAYMONTHYEAR") ===
                formatDate(data.endDatetime, "DAYMONTHYEAR")
                ? `-${formatDate(data.endDatetime, "HOUR")}`
                : ""
            }`}
          />
        </div>
        {event.endDatetime &&
        formatDate(data.datetime, "DAYMONTHYEAR") !==
          formatDate(data.endDatetime, "DAYMONTHYEAR") ? (
          <div className={styles.border}>
            <Text
              fontSize="1em"
              color="#fff"
              textAlign="right"
              fontWeight={700}
              text={formatDate(data.endDatetime, "DAYMONTHYEAR")}
            />
            <Text
              fontWeight={700}
              fontSize="1.5em"
              color="#fff"
              text={formatDate(data.endDatetime, "HOUR")}
            />
          </div>
        ) : null}

        <div className={styles.big}> </div>

        <div />

        <div className={styles.spaceBetween}>
          <img
            src={messageIcon}
            onClick={(e) => {
              e.stopPropagation();

              setOpen("messages");
            }}
            alt="message"
            className={styles.icon}
          />
          <SendIcon
            onClick={() => sendToFlows()}
            className={styles.iconDelete}
          />
          <div style={{ cursor: "pointer" }}>
            {data.currentState ? (
              <StatesIconBlue
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen("states");
                }}
                className={styles.iconDelete}
              />
            ) : (
              <StatesIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen("states");
                }}
                className={styles.iconDelete}
              />
            )}
          </div>
          <Popover
            placement="bottom"
            content={
              <div style={{ minWidth: "15em" }}>
                <FlexContainer
                  padding="0 0 0.5em 0"
                  justifyContent="space-between"
                >
                  <Text
                    text={t("component.orderLocation.load")}
                    fontWeight={700}
                  />
                  <Button
                    size="small"
                    theme="white"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpen("load");
                    }}
                    label={t("component.orderLocation.edit")}
                  />
                </FlexContainer>
                {data.loads.map((item, index) => (
                  <FlexContainer key={index} justifyContent="flex-start">
                    <LoadIcon className={styles.smallIcon} />
                    {item.name}
                  </FlexContainer>
                ))}
              </div>
            }
          >
            {data.loads.length ? (
              <LoadBlueIcon
                style={{ cursor: "pointer" }}
                className={styles.iconDelete}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen("load");
                }}
              />
            ) : (
              <LoadIcon
                style={{ cursor: "pointer" }}
                className={styles.iconDelete}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen("load");
                }}
              />
            )}
          </Popover>
          <Popover
            placement="bottom"
            content={
              <>
                {data.fulfillmentTeamId ? (
                  <div style={{ minWidth: "15em" }}>
                    <FlexContainer
                      padding="0 0 0.5em 0"
                      justifyContent="space-between"
                    >
                      <Text
                        text={t("component.orderLocation.team")}
                        fontWeight={700}
                      />
                      <Button
                        size="small"
                        theme="white"
                        onClick={() => updateTeam(event.id, "unset")}
                        label={t("component.orderLocation.remove")}
                      />
                    </FlexContainer>
                    {
                      teams?.find(
                        (item) => item.value === data.fulfillmentTeamId
                      )?.label
                    }
                    <div className={styles.borderTop} />
                    <FlexContainer
                      padding="0 0 0.5em 0"
                      justifyContent="space-between"
                    >
                      <Text
                        text={t("component.orderLocation.fulfiller")}
                        fontWeight={700}
                      />
                      {data.fulfillerId ? (
                        <Button
                          size="small"
                          theme="white"
                          label={t("component.orderLocation.remove")}
                          onClick={(e) => {
                            e.stopPropagation();
                            updateFulfiller(event.id, "unset");
                          }}
                        />
                      ) : (
                        <Button
                          size="small"
                          theme="white"
                          label={t("component.orderLocation.set")}
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpen("diveshop");
                          }}
                        />
                      )}
                    </FlexContainer>

                    {data.fulfillerId
                      ? `${
                          fulfillers.find(
                            (item) => item.id === data.fulfillerId
                          )?.firstName
                        } ${
                          fulfillers.find(
                            (item) => item.id === data.fulfillerId
                          )?.lastName
                        }`
                      : t("component.orderLocation.noFulfillerAssigned")}
                  </div>
                ) : (
                  <div style={{ maxWidth: "12em" }}>
                    <FlexContainer justifyContent="space-between">
                      <Text
                        text={t("component.orderLocation.team")}
                        fontWeight={700}
                      />
                    </FlexContainer>
                    <div>{t("component.orderLocation.noTeamAssigned")}</div>
                  </div>
                )}
              </>
            }
          >
            {data.fulfillmentTeamId && data.fulfillerId ? (
              <FulfillerBlueIcon
                style={{ cursor: "pointer" }}
                className={styles.icon}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen("diveshop");
                }}
              />
            ) : data.fulfillmentTeamId ? (
              <FulfillerYellowIcon
                style={{ cursor: "pointer" }}
                className={styles.icon}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen("diveshop");
                }}
              />
            ) : (
              <FulfillerIcon
                style={{ cursor: "pointer" }}
                className={styles.icon}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen("diveshop");
                }}
              />
            )}
          </Popover>

          <Popover
            placement="bottom"
            content={
              <div>
                {data.assetName ? (
                  <div style={{ minWidth: "15em" }}>
                    <FlexContainer
                      padding="0 0 0.5em 0"
                      justifyContent="space-between"
                    >
                      <Text
                        text={t("component.orderLocation.asset")}
                        fontWeight={700}
                      />
                      <Button
                        size="small"
                        theme="white"
                        label={t("component.orderLocation.remove")}
                        onClick={() => updateAsset(event, "unset")}
                      />
                    </FlexContainer>

                    {data.assetName}
                  </div>
                ) : (
                  <div style={{ maxWidth: "12em" }}>
                    <FlexContainer justifyContent="space-between">
                      <Text
                        text={t("component.orderLocation.asset")}
                        fontWeight={700}
                      />
                    </FlexContainer>
                    <div>{t("component.orderLocation.noAssetAssigned")}</div>
                  </div>
                )}
              </div>
            }
          >
            <div style={{ cursor: "pointer" }}>
              {data.assetName ? (
                <AssetBlue
                  onClick={(e) => {
                    e.stopPropagation();
                    setAsset(true);
                  }}
                  className={styles.iconDelete}
                />
              ) : (
                <AssetIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setAsset(true);
                  }}
                  className={styles.iconDelete}
                />
              )}
            </div>
          </Popover>

          <Popover
            placement="bottom"
            content={
              <div>
                {data.tags.length >= 1 ? (
                  <div style={{ maxWidth: "15em" }}>
                    <FlexContainer
                      padding="0 0 0.2em 0"
                      justifyContent="space-between"
                    >
                      <Text
                        text={t("component.orderLocation.tags")}
                        fontWeight={700}
                      />
                    </FlexContainer>

                    <div className={styles.center}>
                      {event.colorizedTags.length >= 1 ? (
                        event.colorizedTags?.map(
                          (
                            item: {
                              key: string;
                              settings: {
                                tagClassification: string;
                                color: string;
                              };
                            },
                            index
                          ) => (
                            <div
                              style={{ margin: "0 0.2em 0.2em 0" }}
                              key={index}
                            >
                              {formatOptionKey(
                                item.key,
                                item.settings && item.settings.color
                                  ? item.settings.color
                                  : "blue"
                              )}
                            </div>
                          )
                        )
                      ) : (
                        <div>{t("component.orderLocation.notTags")}</div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div style={{ maxWidth: "12em" }}>
                    <FlexContainer justifyContent="space-between">
                      <Text
                        text={t("component.orderLocation.tags")}
                        fontWeight={700}
                      />
                    </FlexContainer>
                    <div>{t("component.orderLocation.noTagsAssigned")}</div>
                  </div>
                )}
              </div>
            }
          >
            <div style={{ cursor: "pointer" }}>
              {data.tags.length > 0 ? (
                <TagIconBlue
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className={styles.iconDelete}
                />
              ) : (
                <TagIcon
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className={styles.iconDelete}
                />
              )}
            </div>
          </Popover>

          <MarkEvent
            state={data.state}
            loading={{ loading: false, state: "" }}
            setNew={setNew}
            id={event.id}
          />

          <div style={{}}>
            <EditIcon
              className={styles.icon}
              onClick={(e) => {
                e.stopPropagation();
                setEdit(true);
              }}
            />
          </div>
          <Popover
            content={
              <div onClick={() => setOpen("")}>
                <DeletePopUp
                  value={`${t("component.orderLocation.deleteMessage")} ${
                    data.name
                  }?`}
                  onClose={(e) => {
                    e.stopPropagation();
                    setOpen("");
                  }}
                  onDelete={(e) => {
                    e.stopPropagation();
                    onDelete(data.id);
                  }}
                />
              </div>
            }
            trigger="click"
            placement="left"
            onOpenChange={() => setOpen(open)}
          >
            <DeleteIcon
              className={styles.icon}
              onClick={(e) => {
                e.stopPropagation();
                setOpen("delete");
              }}
            />
          </Popover>
        </div>

        {/* `${formatDate(event.endDatetime)} (${
                      localStorage.getItem("timeZone")
                        ? localStorage.getItem("timeZone")
                        : Intl.DateTimeFormat().resolvedOptions().timeZone
                    })` */}
      </ListRow>
      {/* 
      <div
        onClick={() => {
          if (data.id === open) {
            setOpen("");
          } else setOpen(data.id);
        }}
      >
        {open === data.id ? null : !data.placements.length ? (
          <div className={styles.placement}>
            <Text
              fontWeight={600}
              color="#858D8F"
              text={t("component.orderLocation.noPlacements")}
            />
          </div>
        ) : (
          <div className={styles.placement}>
            <div style={{ padding: "0 1em" }}>
              {data.placements.map((item, index) => (
                <PlacementCard
                  lastIndex={index === data.placements.length - 1}
                  key={item.id}
                  data={item}
                  onClickEdit={() => {}}
                />
              ))}
            </div>
          </div>
        )}
      </div> */}
    </div>
  );
};

export default EventDetailComponent;
