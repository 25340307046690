import React from "react";
import { Location } from "@innomius/ravent-typescript-types";

import iconUrl from "../../assets/icon-set-url.svg";
import iconLocation from "../../assets/icon-set-location-blue.svg";

import OrderTitle from "../OrderDetail/OrderTitle";
import LocationCheckinForm from "../LocationForm/LocationCheckinForm";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputCheckbox } from "../Inputs/InputCheckbox";

interface Props {
  data: Location;
  onChange: <P extends keyof Location>(prop: P, value: Location[P]) => void;
  edit: boolean;
}

const LocationCheckin: React.FC<Props> = ({ data, onChange, edit }) => {
  return (
    <div>
      {edit ? (
        <LocationCheckinForm error="" edit data={data} onChange={onChange} />
      ) : (
        <>
          <FlexContainer justifyContent="space-between">
            <FlexContainer padding="1em 0 1.5em 0" justifyContent="flex-start">
              <ContainerTitle
                size="xsmall"
                label="Check-In"
                padding="0 1em 0 0"
              />
            </FlexContainer>
          </FlexContainer>
          <GridContainer padding="3em 0" gap="1em" columns="1.5fr 1fr 1fr">
            <OrderTitle
              image={iconUrl}
              size="xsmall"
              value={data.checkinFlowUrl}
              label="Checkin Flow Url"
              noCaps
            />
            <OrderTitle
              image={iconLocation}
              value={`${data.checkinPeriodinMinutes} min`}
              label="Check-in period"
              noCaps
              size="xsmall"
            />
          </GridContainer>
          <InputCheckbox value={data.publicCheckin} text="Public Check-in" />
        </>
      )}
    </div>
  );
};

export default LocationCheckin;
