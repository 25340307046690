import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { updateActionImage } from "../../services/media";
import { message } from "antd";
import APIError from "../../utils/APIError";
import FileItem from "../AddInvoice/FileItem";
import styles from "./styles";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";

interface Props {
  onClose: () => void;
  id: string;
  load: () => void;
}

const UploadImage: React.FC<Props> = ({ onClose, id, load }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const inputFile = React.useRef<HTMLInputElement>(null);

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const handleUploadFiles = (files: File[]) => {
    const uploaded = [...uploadedFiles];

    if (files) {
      // eslint-disable-next-line array-callback-return
      files.some((file: File) => {
        if (uploaded.findIndex((f: File) => f.name === file.name) === -1) {
          uploaded.push(file);
        }
      });
    }
    setUploadedFiles(uploaded);
  };

  const handleFileEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const chosenFiles = Array.prototype.slice.call(event.target.files);
    if (chosenFiles) {
      handleUploadFiles(chosenFiles);
    }
  };

  const openInputFile = () => inputFile.current?.click();

  const onSave = async () => {
    try {
      setLoading(true);
      await updateActionImage(id, uploadedFiles);
      setLoading(false);
      message.success(t("component.locationDetail.successFileUpload"));
      setUploadedFiles([]);
      onClose();
      load();
    } catch (err) {
      setLoading(false);
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  return (
    <div>
      <input
        id="fileUpload"
        type="file"
        ref={inputFile}
        multiple
        style={{ display: "none" }}
        onChange={handleFileEvent}
      />

      {uploadedFiles.length ? null : (
        <div onClick={async () => openInputFile()} className={styles.box}>
          + upload image
        </div>
      )}

      {uploadedFiles.length ? (
        <div>
          {uploadedFiles.map((file: File) => (
            <FileItem type={file.type} name={file.name} size={file.size} />
          ))}
        </div>
      ) : null}
      {uploadedFiles.length ? (
        <ButtonsContainer justifyContent="flex-end">
          <Button
            label={t("component.assetDetail.cancel")}
            theme="white"
            onClick={() => {
              onClose();
              setUploadedFiles([]);
            }}
          />
          <Button
            label={loading ? "..." : t("component.assetDetail.save")}
            theme="blue"
            onClick={() => {
              onSave();
            }}
          />
        </ButtonsContainer>
      ) : null}
    </div>
  );
};

export default UploadImage;
