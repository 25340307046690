import { stylesheet } from "typestyle";
import { black, linkBlue, orangeyRed, yellowUI } from "../../utils/colors";

const styles = stylesheet({
  center: {
    color: black,
    fontStyle: "normal",
    justifyContent: "center",
    padding: "1em",
    display: "flex",
    alignItems: "center",
    fontSize: "0.9em",
    textDecoration: "underline",
    cursor: "pointer",
  },
  icon: {
    width: "25px",
    marginRight: "0.5em",
  },
  bluePoint: {
    marginLeft: "0.5em",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    background: linkBlue,
  },
  yellowPoint: {
    marginLeft: "0.5em",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    background: yellowUI,
  },
  redPoint: {
    marginLeft: "0.5em",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    background: orangeyRed,
  },
  paginationContainer: {
    padding: "2em 0",
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  listWrapper: {
    marginTop: "1em",
  },
});

export default styles;
