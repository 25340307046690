import React, { useCallback, useEffect, useState } from "react";


import { useTranslation } from "react-i18next";
import InputDate from "../Inputs/Date";
import { InputTime } from "../Inputs/InputTime/InputTime";
import { Action, Schedule } from "@innomius/ravent-typescript-types";
import InputTextDropdown from "../Inputs/InputTextDropdown";
import { timezones } from "../../constants/timezones";
import dayjs from "dayjs";
import CronForm from "./CronForm";
import { InputSelect } from "../Inputs/InputSelect";
import Editor from "@monaco-editor/react";
import { useHistory, useLocation } from "react-router-dom";
import { SideMenu } from "../SideMenu/SideMenu";
import styles from "./styles";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputText } from "../Inputs/InputText";
import InputRadio from "../Inputs/InputRadio";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";


interface Props {
  data: Schedule;
  onClose: () => void;
  onSaveScheduling: (data: Schedule) => void;
  actions: Action[];
}

const CreateSchedule: React.FC<Props> = ({
  data,
  onClose,
  onSaveScheduling,
  actions,
}) => {
  const [frequency, setFrequency] = useState("single");

  const [schedule, setSchedule] = useState<Schedule>(data);
  const [error, setError] = useState<string>("");

  const { t } = useTranslation();

  const onChange = <P extends keyof Schedule>(prop: P, value: Schedule[P]) => {
    setSchedule({ ...schedule, [prop]: value });
  };

  const history = useHistory();

  const location = useLocation() as {
    state?: { actionId: string; name: string };
    pathname: string;
  };

  const load = useCallback(async () => {
    if (location.state?.actionId) {
      onChange("prettyName", location.state?.name);
      onChange("actionId", location.state?.actionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.actionId]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <SideMenu
      width="60%"
      onClose={() => history.goBack()}
      open={location.pathname === "/integrations/schedules/new"}
    >
      <div className={styles.itemsWrapper}>
        <FlexContainer justifyContent="space-between">
          <ContainerTitle size="small" padding="0 0 1em 0" label={"Schedule"} />
          <ButtonsContainer justifyContent="flex-start">
            <Button
              label="Cancel"
              theme="white"
              onClick={() => {
                onClose();
                setSchedule(data);
              }}
            />
            <Button
              label="Save"
              onClick={async () => {
                if (
                  !schedule.timezone ||
                  (!schedule.cronTabExpression && schedule.type === "cron")
                ) {
                  setError("Mandatory fields missing");
                  return;
                }
                try {
                  await onSaveScheduling(schedule);
                  onClose();
                } catch (err) {}
              }}
            />
          </ButtonsContainer>
        </FlexContainer>
        <div>
          <GridContainer padding="1.5em 0 0em 0" gap="1em" columns="1fr">
            <InputSelect
              label={t("component.addIntegration.flowUrl")}
              onChange={(value) => onChange("actionId", value)}
              value={schedule.actionId}
              options={actions.map((item) => ({
                label: `${item.name} - ${item.url}`,
                value: item.id,
              }))}
              showError={false}
            />
            <InputText
              label={t("component.addIntegration.description")}
              value={schedule.description}
              onChange={(value) => onChange("description", value)}
            />
          </GridContainer>

          <>
            <FlexContainer padding="0 0 1em 0" justifyContent="space-between">
              <ContainerTitle
                size="xsmall"
                label={t("component.orderStateList.frequency")}
              />
            </FlexContainer>
        

            <FlexContainer padding="0 0 2em 0" justifyContent="flex-start">
              <div style={{ paddingRight: "1em" }}>
                <InputRadio
                  name="frequency"
                  onChange={() => {
                    setFrequency("single");
                    onChange("type", "at");
                  }}
                  text={t("component.orderStateList.single")}
                  value={frequency}
                  checked={frequency === "single"}
                />
              </div>
              <div style={{ paddingRight: "1em" }}>
                <InputRadio
                  name="frequency"
                  onChange={() => {
                    setFrequency("multiple");
                    onChange("type", "cron");
                  }}
                  text={t("component.orderStateList.multiple")}
                  value={frequency}
                  checked={frequency === "multiple"}
                />
              </div>
            </FlexContainer>
            {frequency === "single" ? (
              <FlexContainer
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <GridContainer
                  padding="0 0 2em 0"
                  gap="1em"
                  columns="1fr 1fr 1fr"
                >
                  <InputDate
                    label={t("component.orderStateList.when")}
                    id="issued"
                    value={(schedule.date as Date) || new Date()}
                    onChange={(value) => {
                      onChange(
                        "date",
                        dayjs(value).format("YYYY-MM-DDTHH:mm:ss")
                      );
                    }}
                  />
                  <InputTime
                    id="issuedDate"
                    value={(schedule.date as Date) || new Date()}
                    onChange={(value) => {
                      onChange(
                        "date",
                        dayjs(value.value).format("YYYY-MM-DDTHH:mm:ss")
                      );
                    }}
                  />
                  <InputTextDropdown
                    label={t("component.orderFilters.timezone")}
                    value={schedule.timezone}
                    error={
                      !schedule.timezone && error
                        ? "This field is mandatory"
                        : ""
                    }
                    top={"calc(100% + 2em)"}
                    options={timezones.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                    onChange={(value) => {
                      onChange("timezone", value);
                    }}
                  />
                </GridContainer>
              </FlexContainer>
            ) : (
              <>
                <GridContainer padding="0 0 3em 0" columns="1fr 2fr">
                  <InputTextDropdown
                    label={t("component.orderFilters.timezone")}
                    value={schedule.timezone}
                    error={
                      !schedule.timezone && error
                        ? "This field is mandatory"
                        : ""
                    }
                    top={"calc(100% + 2em)"}
                    options={timezones.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                    onChange={(value) => {
                      onChange("timezone", value);
                    }}
                  />
                </GridContainer>

                <div>
                  <ContainerTitle
                    padding="0 0 1em 0"
                    size="xsmall"
                    label={t("component.orderStateList.scheduleTimes")}
                  />
                  <CronForm
                    setError={(value) => setError(value)}
                    onChange={onChange}
                    data={schedule}
                  />
                </div>
              </>
            )}

            <InputLabel
              label={t("component.orderActionList.notificationBody")}
            />

            <Editor
              onChange={(value) =>
                onChange("notificationBody", value as string)
              }
              className={styles.editorBox}
              height="100%"
              // theme="vs-dark"
              defaultLanguage="json"
              defaultValue="{}"
              value={schedule.notificationBody as string}
              options={{
                selectOnLineNumbers: true,
                // minimap: { enabled: false },
                scrollbar: {
                  vertical: "hidden",
                },
                overviewRulerLanes: 0,
                hideCursorInOverviewRuler: true,
                overviewRulerBorder: false,
                foldingHighlight: false,
              }}
            />
          </>
        </div>
      </div>
    </SideMenu>
  );
};

export default CreateSchedule;
