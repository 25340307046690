import React, { useState } from "react";

import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { ReactComponent as MapIcon } from "../../assets/icon-set-map.svg";

import { Checkbox, Popover, Tag, Tooltip } from "antd";
import deleteIcon from "../../assets/officialMedia/default-delete.svg";
import { StatusBadgePoint } from "../StatusBadgePoint/StatusBagePoint";
import { useTranslation } from "react-i18next";
import { Asset } from "../../utils/types";
import styles from "./styles";
import IconAsset from "./IconAsset";
import { useHistory } from "react-router-dom";
import { formatDate } from "../../utils/times";
import { ListRow } from "../List/ListRow";
import { ReactComponent as Warning } from "../../assets/warning.svg";
import fontColorContrast from "font-color-contrast";
import { ListLabel } from "../List/ListLabel";
import { InputSelect } from "../Inputs/InputSelect";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { Button } from "../Inputs/Button";

interface AssetListProps {
  data: Asset;
  path: string;
  onCheckboxChange?: () => void;
  isSelected?: boolean;
  onDetails: (id: string) => void;
  assets: Asset[];
  onDelete: (id: string, replace: string) => void;
}

const AssetItem: React.FC<AssetListProps> = ({
  data,
  onDetails,
  onDelete,
  onCheckboxChange,
  isSelected,
  assets,
}) => {
  const [deleteUser, setDelete] = useState("");
  const [replace, setReplace] = useState("");

  const history = useHistory();
  const { t } = useTranslation();

  const formatOptionKey = (key: string, color: string) => {
    if (key && key.includes("::")) {
      const [firstPart, secondPart] = key.split("::");
      return (
        <Tooltip title={firstPart}>
          <Tag
            style={{
              color: color
                ? color === "blue"
                  ? "blue"
                  : fontColorContrast(color, 0.5)
                : "blue",
            }}
            color={color || "blue"}
          >
            {secondPart}
          </Tag>
        </Tooltip>
      );
    } else {
      return <Tag color={color || "blue"}>{key}</Tag>;
    }
  };

  return (
    <ListRow
      boxShadow
      borderRadius="6px"
      onClick={() => {
        onDetails(data.id);
      }}
      template="2em 0.7fr 0.6fr 1fr 0.5fr 1.2fr 0.2fr 0.6fr"
      hover={true}
    >
      <div className={styles.center} onClick={(e) => e.stopPropagation()}>
        <Checkbox checked={isSelected} onChange={onCheckboxChange} />
      </div>
      <div className={styles.gridNormal}>
        <IconAsset type={data.subtype} />
        {data.externalID || "-"}
      </div>
      <div className={styles.normal}>
        {formatDate(data.created, "MIDDLEWHOUR")}
      </div>
      <ListLabel value={data.name} textAlign="left" />

      <div className={styles.normal}>{data.subtype}</div>


      <div className={styles.normal}>
        {data.colorizedTags?.map(
          (
            item: {
              key: string;
              settings: { tagClassification: string; color: string };
            },
            index
          ) => (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{ margin: "0 0.2em 0.2em 0" }}
              key={index}
            >
              {formatOptionKey(
                item.key,
                item.settings && item.settings.color
                  ? item.settings.color
                  : "blue"
              )}
            </div>
          )
        )}
      </div>
      <Tooltip title={data.status ? "enabled" : "disabled"}>
        <div className={styles.normal}>
          <StatusBadgePoint
            point
            status={data.status ? "active" : "DISABLED"}
          />
        </div>
      </Tooltip>

      <div className={styles.center}>
        <div
          onClick={(e) => {
            e.stopPropagation();
            history.push(`/assets/${data.id}/placement`);
          }}
        >
          <MapIcon className={styles.iconBig} />
        </div>
        <EditIcon className={styles.icon} />
        <Popover
          placement="left"
          content={
            <div
              style={{ height: "15em" }}
              onClick={(e) => e.stopPropagation()}
            >
              <FlexContainer justifyContent="flex-start" flexWrap="wrap">
                <Warning style={{ width: "1em" }} />
                <div> Are you sure you want to delete ${data.name}? </div>
              </FlexContainer>

              <InputSelect
                label="Replace with"
                maxHeightOptions="6em"
                containerClassname={styles.size}
                options={assets
                  .filter((asset) => asset.name !== data.name)
                  .map((item) => ({
                    label: `${item.name}`,
                    value: item.id,
                  }))}
                value={replace}
                onChange={(value) => setReplace(value)}
              />

              <FlexContainer padding="3em 0 0 0" justifyContent="flex-end">
                <Button
                  buttonClassname={styles.button}
                  size="small"
                  label={t("component.deletePopUp.cancel")}
                  theme="white"
                  onClick={(e) => {
                    e.stopPropagation();
                    setDelete("");
                  }}
                />
                <Button
                  buttonClassname={styles.buttonRight}
                  size="small"
                  label={t("component.deletePopUp.ok")}
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(data.id, replace);
                  }}
                />
              </FlexContainer>
            </div>
          }
          trigger="click"
          open={deleteUser === "delete"}
          onOpenChange={() => setDelete(deleteUser)}
        />
        <Tooltip title={t("component.assetList.delete")}>
          <img
            src={deleteIcon}
            alt="userDelete"
            onClick={(e) => {
              e.stopPropagation();
              setDelete("delete");
            }}
          />
        </Tooltip>
      </div>
    </ListRow>
  );
};

export default AssetItem;
