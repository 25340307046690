import React from "react";
import { useTranslation } from "react-i18next";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";

interface Props {
  setEdit: (value: string) => void;
  loading: string;
  onEdit: () => void;
}

const EditButtonsContainer: React.FC<Props> = ({
  setEdit,
  loading,
  onEdit,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <ButtonsContainer>
        <Button
          label={t("container.rIntegration.cancel")}
          onClick={() => setEdit("")}
          theme="white"
        />
        <Button
          label={
            loading === "edit" ? "..." : `${t("container.rIntegration.save")}`
          }
          onClick={() => onEdit()}
        />
      </ButtonsContainer>
    </div>
  );
};

export default EditButtonsContainer;
