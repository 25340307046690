import { stylesheet } from "typestyle";

const styles = stylesheet({
  container: {
    marginBottom: "1em",
  },
  containerDetails: {
    padding: "1em",
  },
  officeHourRow: {
    width: "70%",
  },
  text: {
    paddingBottom: "1em",
    paddingTop: "1em",
  },
  editorDetails: {
    height: "20em",
    marginBottom: "1em",
    width: "100%",
  },

  editorBox: {
    width: "100%",
    height: "15em",
    border: "1px solid #dce1e6",
  },
  bold: {
    fontWeight: 600,
    textTransform: "capitalize",
  },
  wrapperCard: {
    padding: "2em",
  },
});

export default styles;
