const translation = {
  en: {
    orders: "Orders",
    fulfillment: "Fulfillment",
    venues: "Venues",
    orgs: "Teams",
    users: "Users",
    settings: "Settings",
    ticketing: "Ticketing",
    integrations: "Integrations",
    payments: "Payments",
    certificates: "Passes",
    assets: "Assets",
    events: "Events",
    locations: "Locations",
  },
  es: {
    locations: "Lugares",
    events: "Eventos",
    orders: "Órdenes",
    fulfillment: "Entrega",
    certificate: "Certificados",
    venues: "Recintos",
    orgs: "Equipos",
    users: "Usuarios",
    settings: "Configuración",
    ticketing: "Pases",
    integrations: "Integraciones",
    payments: "Pagos",
    assets: "Activos",
  },
  de: {},
};

export default translation;
