import React from "react";

import styles from "./styles";
import { useTranslation } from "react-i18next";
import OrderStateList from "../OrderStateList";
import Text from "../Text";
import { black } from "../../utils/colors";
import { VenuePaymentEvent } from "../../utils/types";
import { Card } from "../Card/Card";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { Button } from "../Button";

interface Props {
  orderEvents: VenuePaymentEvent;
  edit: string;
  setEdit: (value: string) => void;
  onEdit: () => void;
  loading: string;
}

const PaymentEvents: React.FC<Props> = ({ orderEvents, setEdit }) => {
  const { t } = useTranslation();

  const events = [
    {
      name: "payment",
      actionName: orderEvents.payment.actionName,
      automatic: orderEvents.payment.automatic,
      actionId: orderEvents.payment.actionId,
    },
    {
      name: "payment request",
      actionName: orderEvents.paymentRequest.actionName,
      automatic: orderEvents.paymentRequest.automatic,
      actionId: orderEvents.paymentRequest.actionId,
    },
    {
      name: "payment extension",
      actionName: orderEvents.paymentExtension.actionName,
      automatic: orderEvents.paymentExtension.automatic,
      actionId: orderEvents.paymentExtension.actionId,
    },

    {
      name: "refund",
      actionName: orderEvents.refund.actionName,
      automatic: orderEvents.refund.automatic,
      actionId: orderEvents.refund.actionId,
    },
    {
      name: "expiration",
      actionName: orderEvents.expiration.actionName,
      automatic: orderEvents.expiration.automatic,
      actionId: orderEvents.expiration.actionId,
    },
  ];

  return (
    <div>
      <Card containerClassname={styles.wrapperCard}>
        <FlexContainer padding="0 0 1em 0" justifyContent="space-between">
          <ContainerTitle
            size="small"
            label={t("component.venueEvents.paymentEvents")}
          />
          <Button
            label={t("container.rIntegration.edit")}
            onClick={() => setEdit("payment-events")}
          />
        </FlexContainer>

        <Text
          textAlign="justify"
          padding="0 0 2em 0"
          color={black}
          text={t("component.venueEvents.url")}
        />
        <OrderStateList data={events} event />
      </Card>
    </div>
  );
};

export default PaymentEvents;
