const translation = {
  en: {
    addOrderline: "Add Orderline",
    details: "Details",
    description: "Description",
    sku: "SKU",
    quantity: "Quantity",
    unitPrice: "Unit Price",
    discounts: "Discounts",
    surcharges: "Surcharges",
    taxes: "Taxes",
    cancel: "Cancel",
    save: "Save",
    addDiscount: "+ Add Discount",
    amount: "Amount",
    addSurcharge: "+ Add Surcharge",
    addTax: "+ Add Tax",
    next: "Next",
    back: "Back",
    rate: "Rate",
    edit: "Edit",
    taxesVariant: "Variants",
  },
  es: {
    taxesVariant: "Variantes",
    edit: "Editar",

    addOrderline: "Agregar Elemento",
    details: "Detalles",
    description: "Descripción",
    sku: "SKU",
    quantity: "Cantidad",
    unitPrice: "Precio Unitario",
    discounts: "Descuentos",
    surcharges: "Recargos",
    taxes: "Impuestos",
    cancel: "Cancelar",
    save: "Guardar",
    addDiscount: "+ Agregar Descuento",
    amount: "Cantidad",
    addSurcharge: "+ Agregar Recargo",
    addTax: "+ Agregar Impuesto",
    next: "Continuar",
    back: "Regresar",
    rate: "Tasa",
  },
  de: {},
};

export default translation;
