import { Payment, StorePayment } from "@innomius/ravent-typescript-types";
import React from "react";

import styles from "./styles";
import { useTranslation } from "react-i18next";
import { GridContainer } from "../GridContainer/GridContainer";

interface Props {
  data: Payment;
}

const Scotiabank: React.FC<Props> = ({ data }) => {
  const payment = data as StorePayment;

  const { t } = useTranslation();

  return (
    <div>
      <GridContainer
        gap="1em"
        columns="0.4fr 1fr"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <div className={styles.bold}>
          {t("component.requestPaymentDetail.reference")}
        </div>
        <div>{payment.reference}</div>
      </GridContainer>
    </div>
  );
};

export default Scotiabank;
