import React from "react";
import { OfferSet } from "@innomius/ravent-typescript-types";

import styles from "./styles";
import { useTranslation } from "react-i18next";
import { Card } from "../Card/Card";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";
import { SectionProperty } from "../Section/SectionProperty";

interface Props  {
  data: OfferSet;
}

const OfferSetDetail: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Card containerClassname={styles.sectionWrapper}>
        <ContainerTitle
          padding="0 0 1em 0"
          size="xsmall"
          label={t("component.offerSetDetail.details")}
        />
        <GridContainer gap="1em" columns="3fr 1fr">
          <div>
            <SectionProperty
              label={t("component.offerSetDetail.description")}
              value={data.description}
            />
          </div>
          <div className={styles.imageContainer}>
            <img
              src={data.image}
              className={styles.logoContainer}
              alt="offerImage"
            />
          </div>
        </GridContainer>
      </Card>
    </div>
  );
};

export default (OfferSetDetail);
