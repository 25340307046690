import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./store";
import Login from "./containers/Login";
import Offers from "./containers/OfferSets";
import OfferSetDetail from "./containers/OfferSetDetail";
import Orders from "./containers/Orders";
import Venues from "./containers/Venues";
import ResetPassword from "./containers/ResetPassword";
import OrderDetail from "./containers/OrderDetail";
import Entities from "./containers/Entities";
import CreateEntity from "./containers/CreateEntity";
import EntityDetail from "./containers/EntityDetail";
import Users from "./containers/Users";
import CreateUser from "./containers/CreateUser";
import UserDetail from "./containers/UserDetail";
import VenueDetail from "./containers/VenueDetail";
import CreateVenue from "./containers/CreateVenue";
import Visitors from "./containers/Visitors";
import CreateOfferSet from "./containers/CreateOfferSet";
import ProfileDetail from "./containers/ProfileDetail";
import PrivateContainer from "./containers/PrivatContainer";
import Workspace from "./containers/Workspace";
import FOPSetDetail from "./containers/FOPSetDetail";
import EnrollUser from "./containers/EnrollUser";
import SetPassword from "./containers/SetPassword";
import { LangProvider } from "./components/LangProvider";
import ReactGA from "react-ga4";
import Dashboard from "./containers/Dashboard";
import "./utils/i18n";
import RIntegration from "./containers/RIntegration";
import Certificates from "./containers/Certificates";
import Fulfillment from "./containers/Fulfillment";
import MyCompany from "./containers/MyCompany";
import Payments from "./containers/Payments";
import PaymentDetail from "./containers/PaymentDetail";
import Assets from "./containers/Assets";
import Events from "./containers/Events";
import DashboardTeam from "./containers/DashboardTeam";
import Locations from "./containers/Locations";
import DownloadFile from "./containers/DownloadFile";
import CreateRol from "./containers/CreateRol";
import RoleDetail from "./containers/RoleDetail";
import EventDetail from "./containers/EventDetail";

const TRACKING_ID = "G-XVQE3BBPSJ";
ReactGA.initialize(TRACKING_ID);

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default function App() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);

  return (
    <LangProvider>
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route path="/pub/enrollUser" component={EnrollUser} exact />
            <Route path="/pub/workspace" component={Workspace} exact />
            <Route path="/" component={Login} exact />
            <Route path="/pub/resetPassword" component={ResetPassword} exact />
            <Route path="/pub/setPassword" component={SetPassword} exact />
            <Route path="/pub/pmntreqs/:id" component={PaymentDetail} />
            <Route path="/">
              <PrivateContainer>
                <Switch>
                  <Route path="/files/download" component={DownloadFile} />
                  <Route path="/overview" component={Dashboard} exact />
                  <Route path="/overview/teams" component={Dashboard} exact />
                  <Route path="/overview/teams/:id" component={DashboardTeam} />
                  <Route path="/overview/events" component={Dashboard} />
                  <Route
                    path="/payments/fopSets/:id"
                    component={FOPSetDetail}
                  />

                  <Route path="/visitors" component={Visitors} />
                  <Route
                    path="/offersets/new"
                    component={CreateOfferSet}
                    exact
                  />
                  <Route path="/offersets/:id" component={OfferSetDetail} />
                  <Route path="/offersets" component={Offers} />

                  <Route path="/fulfillments" component={Fulfillment} />
                  <Route path="/integrations" component={RIntegration} />
                  <Route path="/passes" component={Certificates} />

                  <Route path="/users/new" component={CreateUser} exact />
                  <Route path="/users/:id" component={UserDetail} exact />
                  <Route path="/users" component={Users} />
                  <Route path="/offers/:id" component={OfferSetDetail} />
                  <Route path="/offers" component={Offers} />
                  <Route path="/events/missing" component={Events} exact />

                  <Route path="/events/:id" component={EventDetail} />

                  <Route path="/events" component={Events}/>
                  <Route path="/orders" component={Orders} exact />
                  <Route path="/orders/:id" component={OrderDetail} />
                  <Route path="/venues/new" component={CreateVenue} />
                  <Route path="/venues/:id" component={VenueDetail} />
                  <Route path="/venueLocations" component={Locations} />
                  <Route path="/venueLocations/:id" component={Locations} />
                  <Route path="/missing" component={Locations} />
                  <Route path="/duplicates" component={Locations} />

                  <Route path="/venues" component={Venues} />
                  <Route path="/assets/:id" component={Assets} />
                  <Route path="/assets" component={Assets} />
                  <Route path="/teams/new" component={CreateEntity} exact />
                  <Route path="/teams/:id" component={EntityDetail} />
                  <Route path="/teams" component={Entities} />
                  <Route
                    path="/payments/profile/globalPreferences"
                    component={ProfileDetail}
                    exact
                  />
                  <Route path="/profile" component={ProfileDetail} />
                  <Route
                    path="/organization/roles/new/details"
                    component={CreateRol}
                  />
                  <Route
                    path="/organization/roles/new/permissions"
                    component={CreateRol}
                  />
                  <Route
                    path="/organization/roles/permissions/:id"
                    component={RoleDetail}
                  />

                  <Route
                    path="/organization/roles/details/:id"
                    component={RoleDetail}
                  />
                  <Route path="/organization/roles" component={MyCompany} />
                  <Route path="/organization/tags" component={MyCompany} />
                  <Route path="/organization/billing" component={MyCompany} />
                  <Route path="/organization" component={MyCompany} />
                  <Route path="/payments" component={Payments} />
                  <Redirect from="*" to="/" />
                </Switch>
              </PrivateContainer>
            </Route>
            <Route path="/login" component={Login} exact />
            <Redirect from="*" to="/" />
          </Switch>
        </BrowserRouter>
      </Provider>
    </LangProvider>
  );
}
