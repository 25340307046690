import { stylesheet } from "typestyle";
import { black } from "../../utils/colors";

const styles = stylesheet({
  container: {
    padding: "2em",
  },
  button: {
    width: "68px !important",
    marginRight: "1em",
  },
  buttonRight: {
    width: "68px !important",
  },
  title: {
    fontSize: "1.5em",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "left",
    color: "#141322",
    paddingTop: "1em",
  },
  size: {
    minWidth: "20em",
  },
  searchRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "1em",
  },
  center: {
    color: black,
    fontStyle: "normal",
    justifyContent: "space-evenly",
    padding: "1em",
    flexWrap: "wrap",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    marginRight: "0.5em",
  },
  gridNormal: {
    display: "grid",
    gridTemplateColumns: "50px 1fr",
    padding: "0 0.5em",
    fontSize: "1em",
    fontWeight: 600,
    justifyContent: "flex-start",
    alignItems: "center",
  },
  normal: {
    fontSize: "1em",
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    padding: "0 0.5em",
  },
  radioContainer: {
    marginRight: "3em",
    padding: "0.5em 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  wrapper: {
    padding: "0em 2em",
  },
  mcontainer: {
    $nest: {
      "div > div": {
        alignItems: "center !important",
      },
    },
  },
  start: {
    fontSize: "1em",
    fontWeight: 600,
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    padding: "0 0.5em",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    width: 23,
  },
  iconBig: {
    width: 30,
  },
  link: {
    textDecoration: "none",
  },
  paginationContainer: {
    padding: "2em 0",
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
  },
  listContainer: {
    marginTop: "1em",
    boxShadow: "0px 4px 7px rgba(0, 0, 0, 0.1)",
  },
  iconBUtton: {
    width: "40px",
    marginRight: "1em",
  },
  paginationContainerFixed: {
    padding: "2em 0 1em 0",
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    position: "fixed",
    bottom: 0,
    background: "#f2f4f8",
  },
  listContainerB: {
    paddingBottom: "3em",
  },
});

export default styles;
