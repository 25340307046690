import React, { useState } from "react";

import styles from "./styles.module.css";
import { capitalize } from "../../utils/format";
import Editor from "@monaco-editor/react";
import { useTranslation } from "react-i18next";
import DeleteModal from "../DeleteModal";
import { StatusBadgePoint } from "../StatusBadgePoint/StatusBagePoint";
import { ButtonsContainer } from "../ButtonsContainer";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { Button } from "../Button";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
import { SectionProperty } from "../Section/SectionProperty";

export interface Props {
  supplierReference: string;
  supplierType: string;
  supplierName: string;
  supplierService: string;
  jsonData: string;
  type: string;
  active: boolean;
  id: string;
  onEdit: () => void;
  name: string;
  onDelete: (id: string) => void;
  loading: string;
}

const IntegrationDetail: React.FC<Props> = ({
  supplierType,
  supplierName,
  jsonData,
  type,
  active,
  supplierService,
  onEdit,
  name,
  loading,
  onDelete,
  id,
}) => {
  const [open, setOpen] = useState("");

  const { t } = useTranslation();

  return (
    <div>
      <DeleteModal
        open={open === "delete"}
        loading={loading === "delete"}
        value={t("component.integrationDetail.deleteWarning")}
        onCancel={() => setOpen("")}
        onDelete={async () => {
          try {
            await onDelete(id);
            setOpen("");
          } catch (err) {}
        }}
      />
      <FlexContainer justifyContent="space-between" padding="0 0 1em 0">
        <ContainerTitle size="medium" label={name} />
        <ButtonsContainer justifyContent="flex-end">
          <Button
            label={t("component.integrationDetail.delete")}
            theme="red"
            onClick={() => setOpen("delete")}
          />
          <Button
            label={t("component.integrationDetail.edit")}
            theme="white"
            onClick={() => onEdit()}
          />
        </ButtonsContainer>
      </FlexContainer>
      <div className={styles.container}>
        <GridContainer padding="0 0 1em 0" columns="1fr 1fr">
          <FlexContainer
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="column"
          >
            <InputLabel label={t("component.integrationDetail.status")} />
            <StatusBadgePoint
              size="big"
              status={active ? "active" : "DISABLED"}
            />
          </FlexContainer>
          <SectionProperty
            label={t("component.integrationDetail.integrationType")}
            value={capitalize(type)}
          />
        </GridContainer>
        <ContainerTitle
          padding="1em 0 1em 0"
          size="xsmall"
          label={t("component.integrationDetail.supplierInfo")}
        />
        <GridContainer gap="1em" columns="1fr 1fr">
          <SectionProperty
            label={t("component.integrationDetail.supplierType")}
            value={capitalize(supplierType)}
          />
        </GridContainer>
        <GridContainer gap="1em" columns="1fr 1fr">
          <SectionProperty
            label={t("component.integrationDetail.supplierName")}
            value={capitalize(supplierName)}
          />
          <SectionProperty
            label={t("component.integrationDetail.supplierService")}
            value={capitalize(supplierService) || "-"}
          />
        </GridContainer>
        <ContainerTitle
          padding="1em 0 1em 0"
          size="xsmall"
          label={t("component.integrationDetail.configuredJson")}
        />
        <div className={styles.editorDetails}>
          <Editor
            className={styles.editorBox}
            height="100%"
            // theme="vs-dark"
            defaultLanguage="json"
            value={jsonData}
            options={{
              readOnly: true,
              selectOnLineNumbers: true,
              // minimap: { enabled: false },
              scrollbar: {
                vertical: "hidden",
              },
              overviewRulerLanes: 0,
              hideCursorInOverviewRuler: true,
              overviewRulerBorder: false,
              foldingHighlight: false,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default IntegrationDetail;
