import { OfficialMedia } from "@innomius/ravent-typescript-types";

import pdf from "../assets/locationActions/IconSet_PDF.svg";

export const officialMediaMock: OfficialMedia[] = [
  {
    id: "9959bf29-491a-438e-a24d-9c8d6a49d0b0",
    name: "OpeningHours.jpg",
    type: "Public",
    tags: ["opening", "hours"],
    fileType: "jpg",
    image: "",
    qrCode: "",
    locationId: "a8a4nna-7912-44f5-b18d-920b5nnnna58",
    locationName: "Chachoben Archeological Ruins",

    venueId: "4ed6758c-5792-461a-b84f-4dd9d91a0cad",
  },
  {
    id: "uuu59bf29-491a-438e-a24d-9c8d6a498u8u8",
    name: "Activities.pdf",
    type: "Public",
    tags: ["activities", "jungle", "ecological", "mayas"],
    fileType: "pdf",
    image: pdf,
    qrCode: "",
    locationId: "a8a4nna-7912-44f5-b18d-920b5nnnna58",
    locationName: "Chachoben Archeological Ruins",
    venueId: "4ed6758c-5792-461a-b84f-4dd9d91a0cad",
  },
];
