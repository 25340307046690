const translation = {
  en: {
    error: "Error",
    back: "Go Back",
    notFound: "Not Found",
    backToDetails: "Back to Definitions",
    cancel: "Cancel",
    save: "Save",
    delete: "Delete",
    edit: "Edit Definition",
    detailsLabel: "Details",
    ticketing: "Passes",
    states: "States",
    successDeleted: " Definition was successfully deleted",
    deleteInstructions: "Are you sure you want to delete this pass definition?",
    successUpdated: "Pass Definition was sucessfully updated",
  },

  es: {
    publishInstr: "Esta seguro que quiere publicar la definición? ",
    publish: "Publicar",
    draft: "Borrador",
    error: "Error",
    back: "Regresar",
    notFound: "No encontrado",
    backToDetails: "Volver a Definiciones de Pases",
    cancel: "Cancelar",
    save: "Guardar",
    delete: "Borrar",
    edit: "Editar",
    detailsLabel: "Detalles",
    ticketing: "Pases",
    states: "Estados",
    successDeleted: "La definición del pases se eliminó con éxito",
    deleteInstructions:
      "¿Está seguro de que desea eliminar esta definición del pase?",
    successUpdated: "El pase se actualizó con éxito",
  },
  de: {},
};

export default translation;
