const translation = {
  en: {
    customerInfo: "Customer Information",
    name: "Name",
    lastName: "Last Name",
    phone: "Phone Number",
    email: "Email",
    deliveryLocation: "Delivery Location",
    w3w: "What Three Words",
    postalAddress: "Postal Address",
    wellKnownAddress: "Well Known Address",
    notes: "Notes",
  },
  es: {
    customerInfo: "Información del Cliente",
    name: "Nombre",
    lastName: "Apellido",
    phone: "Número de teléfono",
    email: "Correo electrónico",
    deliveryLocation: "Ubicación de entrega",
    w3w: "What Three Words",
    postalAddress: "Dirección postal",
    wellKnownAddress: "Dirección conocida",
    notes: "Notas",
  },
  de: {},
};

export default translation;
