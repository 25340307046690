import React from "react";
import styles from "./styles.module.css";
import { ReactComponent as CrossIcon } from "../../assets/close.svg";

import { classes } from "typestyle";

export interface SideMenuProps {
  onClose: () => void;
  width?: string;
  open?: boolean;
  displayLeft?: boolean;
  children?: React.ReactNode[] | React.ReactNode;
}

export const SideMenu: React.FC<SideMenuProps> = ({
  onClose,
  open,
  children,
  width,
  displayLeft,
}) => {
  if (!open) {
    return null;
  }
  return (
    <div className={styles.container}>
      <div
        className={classes(styles.menu, { [styles.displayLeft]: displayLeft })}
        style={{ width }}
      >
        <div className={styles.closeButtonContainer} onClick={onClose}>
          <CrossIcon className={styles.closeButton} />
        </div>
        {children}
      </div>
    </div>
  );
};
