const translation = {
  en: {
    personalInfo: "Personal Information",
    name: "Name",
    lastName: "Last Name",
    email: "Email",
    phone: "Phone Number",
    company: "Company Name",
  },
  es: {
    personalInfo: "Información Personal",
    name: "Nombre",
    lastName: "Apellido",
    email: "Correo electrónico",
    phone: "Número de teléfono",
    company: "Nombre de la empresa",
  },
  de: {},
};

export default translation;
