import { stylesheet } from "typestyle";
import { black, darkBlack, lightGrey } from "../../utils/colors";

const styles = stylesheet({
  container: {
    minHeight: "100vh",
  },
  wrapperCard: {
    padding: "2em",
    marginBottom: "1em",
  },
  title: {
    color: darkBlack,
    fontWeight: "bold",
    fontSize: "1.1em",
    padding: "1em 0 0.5em 0",
  },
  center: {
    color: black,
    fontStyle: "normal",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    display: "flex",
    alignItems: "flex-start",
    marginRight: "0.5em",
  },
  line: {
    height: "1px",
    margin: "1em 0",
    background: lightGrey,
  },
  iconContainer: {
    width: "1em",
    marginLeft: "1em",
  },
  text: {
    fontSize: "1.1em",
    color: darkBlack,
    fontWeight: 400,
  },
  label: {
    fontSize: "1em",
    color: darkBlack,
    fontWeight: 600,
    paddingBottom: "0.4em",
  },
  pointer: {
    cursor: "pointer",
  },
  notEnrollContainer: {
    background: "rgba(250, 173, 21, 0.1)",
    border: "1px solid #FAAD15",
    padding: "1.1em",
    borderRadius: "6px",
    margin: "2em 0 0 0",
  },
  role: {
    cursor: "pointer",
  },
});

export default styles;
