const translation = {
  en: {
    createDiver: "Create Fulfiller",
    cancel: "Cancel",
    save: "Save",
    userName: "User Name",
    info: "Information",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    phone: "Phone Number",
    status: "Disabled",
    editDiver: "Edit Fulfiller",
    externalId: "External ID",

  },
  es: {
    externalId: "Id Externo",
    editDiver: "Editar Fulfiller",
    createDiver: "Crear Fulfiller",
    cancel: "Cancelar",
    save: "Guardar",
    userName: "Usuario",
    info: "Información",
    firstName: "Nombre",
    lastName: "Apellido",
    email: "Email",
    phone: "Número de teléfono",
    status: "Desactivado",
  },
  de: {},
};

export default translation;
