const translation = {
  en: {
    welcome: "Welcome to RAVENT",
    loginAccount: "Login to your account",
    email: "Email",
    password: "Password",
    login: "Login",
    forgotPassword: "Forgot Password?",
    rightsReserved: "2022 RAVENT • version 0.1.120 • All rights reserved ",
    validEmail: "Please enter a valid email",
    empty: "Password cannot be empty",
    downloadFile: "Download File",
    download: "Download",
    file: "File",
    emailPhone: "Email/ Phone Number",
    policy: "Privacy Policy and Terms and Conditions",
    private: "By clicking 'Login', I confirm that I have read and accept the",
    understand:
      "of the platform. I understand and consent that my personal data will be treated in accordance with the Privacy Policy, in accordance with the applicable data protection legislation.",
  },
  es: {
    policy: "Política de privacidad y Términos y Condiciones",
    private:
      "Al hacer clic en 'Iniciar sesión', confirmo que he leído y acepto la",
    understand: "de la plataforma. Entiendo y consiento que mis datos personales serán tratados de conformidad con la Política de Privacidad, de conformidad con la legislación aplicable de protección de datos.",
    
  
    emailPhone: "Correo electrónico/ Número de teléfono",
    download: "Descargar",
    file: "Archivo",
    downloadFile: "Descargar archivo",
    welcome: "Bienvenido a RAVENT",
    loginAccount: "Inicia sesión en tu cuenta",
    email: "Correo electrónico",
    password: "Contraseña",
    login: "Iniciar sesión",
    forgotPassword: "¿Olvidó su contraseña?",
    rightsReserved: " 2022 RAVENT • version 0.1.120 • Derechos reservados",
    validEmail: "Favor de poner un email válido",
    empty: "La contraseña no puede estar vacía",
  },
  de: {
    downloadFile: "Datei herunterladen",
    welcome: "Welcome to RAVENT",
    loginAccount: "Login to your account",
    email: "Email",
    password: "Password",
    login: "Login",
    forgotPassword: "Forgot Password?",
    rightsReserved: "2022 RAVENT • version 0.1.120 • All rights reserved ",
    empty: "Passwort kann nicht leer sein",
  },
};

export default translation;
