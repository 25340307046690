import React, { useState } from "react";

import { Fulfiller } from "@innomius/ravent-typescript-types";
import { useTranslation } from "react-i18next";
// import Text from "../Text";
import MagicLinkModal from "./MagicLinkModal";
import { StatusBadgePoint } from "../StatusBadgePoint/StatusBagePoint";
import { Role } from "../../services/roles";
import { Tag, Tooltip } from "antd";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
import { GridContainer } from "../GridContainer/GridContainer";
import { SectionProperty } from "../Section/SectionProperty";
// import { SectionSpacer } from "../Section/SectionSpacer";

interface Props {
  data: Fulfiller;
  onEdit: () => void;
  onSendMagicLink?: () => void;
  roles: Role[];
}

const FulfillerDetail: React.FC<Props> = ({
  data,
  onEdit,
  onSendMagicLink,
  roles,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState("");
  const formatOptionKey = (key: string, color: string) => {
    if (key && key.includes("::")) {
      const [firstPart, secondPart] = key.split("::");
      return (
        <Tooltip title={firstPart}>
          <Tag style={{ margin: "0 0.2em 0.2em 0" }} color={color || "blue"}>
            {secondPart}
          </Tag>
        </Tooltip>
      );
    } else {
      return <Tag color={color || "blue"}>{key}</Tag>;
    }
  };

  return (
    <div>
      <MagicLinkModal
        onSend={() => onSendMagicLink?.()}
        open={open === "modal"}
        onClose={() => setOpen("")}
        email={data.email}
        phone={data.phone}
      />

      <FlexContainer justifyContent="space-between" padding="1em 0 1em 0">
        <ContainerTitle size="medium" label={`${data.firstName || '-'} ${data.lastName}`} />
        <ButtonsContainer alignItems="center" justifyContent="flex-end">
          <Button
            label={t("component.diverDetail.edit")}
            onClick={() => onEdit()}
          />
        </ButtonsContainer>
      </FlexContainer>

      <ContainerTitle
        padding="0 0 1em 0"
        size="xsmall"
        label={t("component.diverDetail.details")}
      />

      <GridContainer padding="1em 0 0 0" gap="1em" columns="1fr 1fr">
        <SectionProperty
          value={data.externalId || "-"}
          label={t("component.diverDetail.externalId")}
        />
        <FlexContainer
          padding="0 0 2em 0"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <InputLabel label={t("component.diversForm.status")} />
          <StatusBadgePoint status={data.disabled ? "DISABLED" : "active"} />
        </FlexContainer>
      </GridContainer>

      <GridContainer padding="1em 0 0 0" gap="1em" columns="1fr 1fr">
        <SectionProperty
          value={data.email || "-"}
          label={t("component.diverDetail.email")}
        />
        <SectionProperty
          value={data.phone || "-"}
          label={t("component.diverDetail.phone")}
        />
      </GridContainer>
      <InputLabel label={t("component.diverDetail.tags")} />

      <FlexContainer
        padding="0em 0 1em 0"
        justifyContent="flex-start"
        flexWrap="wrap"
      >
        {data.tags?.map((item) => formatOptionKey(item, "blue"))}
      </FlexContainer>

      <ContainerTitle
        padding="0 0 1em 0"
        size="xsmall"
        label={t("component.diverDetail.role")}
      />
      {data.roles.map((item, index) => {
        const role = roles.find((role) => role.id === item);
        return role ? <Tag key={index}>{role.name}</Tag> : null;
      })}

      {/* <SectionSpacer padding={"2em 0 1em 0"} /> */}

      {/* <FlexContainer padding="1em 0 0.5em 0" justifyContent="space-between">
        <ContainerTitle
          size="xsmall"
          label={t("component.diverDetail.invitation")}
        />
        <Button
          theme="white"
          label={t("component.diverDetail.sendMagic")}
          onClick={() => setOpen("modal")}
        />
      </FlexContainer>

      <Text
        padding="0 0 1em 0"
        text={t("component.diverDetail.instructions")}
      /> */}
    </div>
  );
};

export default FulfillerDetail;
