const translation = {
  en: {
    name: "Name",
    email: "email",
    role: "Roles",
    actions: "",
    deleteMessage: "Are you sure you want to delete",
    venue: "Venue",
    team: "Team",
    tags: "Tags",
    user: "User",
    externalId: "ID",
    createdAt: "Created",
    venueTeam: "Venue/Team",
    resetPassword: "Reset Password",
    cancel: "Cancel",
    reset: "Reset",
    adminRole: "Admin Role",
  },
  es: {
    adminRole: "Rol Administrador",
    cancel: "Cancelar",
    reset: "Restablecer",
    resetPassword: "Restablecer contraseña",
    user: "Usuario",
    venueTeam: "Recinto/Equipo",
    externalId: "ID",
    createdAt: "Creado",
    tags: "Etiquetas",
    name: "Nombre",
    email: "Email",
    role: "Roles",
    actions: "",
    deleteMessage: "¿Está seguro de que quiere eliminar esto?",
    venue: "Recinto",
    team: "Equipo",
  },
  de: {},
};

export default translation;
