import React, { useEffect } from "react";

import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { FOPSetSearchResponse } from "../../services/fop";
import FOPSetForm from "../FOPSetForm";
import FOPSetList from "../FOPSetList";
import { Card } from "../Card/Card";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { Button } from "../Button";
import Search from "antd/es/input/Search";
import { EmptyList } from "../List/EmptyList";

interface Props {
  onDeletFOPSet: (id: string) => void;
  onCreateFOPSet: (name: string) => void;
  fopSets: { loading: boolean; error: string; list: FOPSetSearchResponse };
  records_per_page_set: number;
  pageSet: number;
  setPageSet: (number: number) => void;
  loadingSet: string;
  setHitsPerPageSet: (value: number) => void;
  add: boolean;
  setAdd: (value: boolean) => void;
}

const FOPSets: React.FC<Props> = ({
  onCreateFOPSet,
  onDeletFOPSet,
  loadingSet,
  setHitsPerPageSet,
  pageSet,
  records_per_page_set,
  fopSets,
  setPageSet,
  add,
  setAdd,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = "RAVENT APP :: FOP Sets";
  });

  return (
    <Card>
      <div className={styles.container}>
        <FOPSetForm
          onCancel={() => setAdd(false)}
          onSave={(name) => onCreateFOPSet(name)}
          open={add}
          loading={loadingSet === "add"}
        />
        <FlexContainer
          padding="0 0 0.5em 0"
          alignItems="center"
          justifyContent="space-between"
        >
          <ContainerTitle
            size="small"
            label={t("container.fopsets.form")}
            count={fopSets.list.total.toString()}
          />
          <Button
            label={t("container.fopsets.add")}
            onClick={() => setAdd(true)}
          />
        </FlexContainer>

        <div className={styles.searchContainer}>
          <Search
            style={{ marginBottom: "1em" }}
            onSearch={() => {}}
            placeholder={t("container.fopsets.search")}
          />
        </div>
        {!fopSets.list.data.length && !fopSets.error && !fopSets.loading ? (
          <div className={styles.wrapperCard}>
            <EmptyList
              instructions={""}
              text={t("container.fopsets.instructions")}
              label={t("container.fopsets.add")}
              onClick={() => setAdd(true)}
            />
          </div>
        ) : (
          <FOPSetList
            loadingDelete={loadingSet === "delete"}
            onDelete={(id) => onDeletFOPSet(id)}
            error={fopSets.error}
            loading={fopSets.loading}
            data={fopSets.list.data}
            total={fopSets.list.total}
            pagination
            sortable
            page={pageSet}
            hitsPerPage={records_per_page_set}
            onPageChange={setPageSet}
            onHitsPerPageChange={setHitsPerPageSet}
          />
        )}
      </div>
    </Card>
  );
};

export default FOPSets;
