import { formatDate } from "./times";

export const createDocumentationOrder = (
  id: string,
  shortId: string,
  creation: string
) => {
  const documentDefinition = {
    pageSize: "A4",
    content: [
      {
        style: "tableHeader",
        table: {
          widths: ["auto"],
          body: [
            [
              {
                border: [false, false, false, false],
                fillColor: "#fff",
                stack: [
                  {
                    style: "mediumSpace",
                    text: " ",
                  },
                  {
                    style: "boldText",
                    text: `Order #${shortId}`,
                  },
                  {
                    style: "normalText",
                    text: `Creation date: ${formatDate(
                      creation,
                      "MIDDLEWHOUR"
                    )}`,
                  },
                  {
                    border: [false, false, false, false],
                    width: "145",
                    style: "qrCode",
                    fit: "145",
                    qr: `https://ravent.com/orders/${id}`,
                  },
                  {
                    style: "smallText",
                    text: `Powered by RAVENT`,
                  },
                  {
                    style: "mediumSpace",
                    text: " ",
                  },
                  {
                    style: "mediumSpace",
                    text: " ",
                  },
                  {
                    border: [false, false, false, false],
                    fillColor: "#fff",
                    stack: [
                      {
                        style: "mediumSpace",
                        text: "---------------------------------------------------------------------------------------------------------------------------",
                      },
                    ],
                  },
                ],
              },
            ],
          ],
        },
      },
    ],
    styles: {
      header: {
        fontSize: 14,
        fontWeight: 600,
        fontFamily: "Lato",
        color: "#095BB7",
        margin: [0, 0, 0, 6],
      },
      normalText: {
        fontSize: 9,
        fontWeight: 400,
        fontFamily: "Lato-Regular",
        color: "#000",
        margin: [0, 5, 0, 5],
      },
      smallText: {
        fontSize: 6.5,
        fontWeight: 400,
        fontFamily: "Lato-Regular",
        color: "#000",
        margin: [37, 0, 0, 5],
      },
      semiBoldText: {
        fontSize: 10,
        fontWeight: "bold",
        fontFamily: "Lato-Regular",
        color: "#444A60",
      },
      mediumText: {
        fontSize: 12,
        fontWeight: 400,
        fontFamily: "Lato-Regular",
        color: "#444A60",
      },
      borderBottom: {
        background: "#E5E6F0",
        height: "1px",
        width: "100%",
        margin: [0, 2, 0, 2],
      },
      blueText: {
        fontSize: 18,
        fontWeight: 400,
        fontFamily: "Lato-Regular",
        color: "#49B8F6",
      },
      mediumBoldText: {
        fontSize: 14,
        fontWeight: 600,
        fontFamily: "Lato-Regular",
        color: "#444A60",
        margin: [0, 0, 0, 5],
      },
      mediumBoldTextBig: {
        fontSize: 14,
        fontWeight: 600,
        fontFamily: "Lato-Regular",
        color: "#444A60",
      },
      boldText: {
        fontSize: 20,
        fontWeight: 700,
        bold: true,
        fontFamily: "Lato",
        color: "#000",
      },
      subscriptionsHeaderText: {
        fontSize: 9,
        fontWeight: 600,
        bold: true,
        fontFamily: "Lato",
        color: "#444A60",
        margin: [0, 0, 0, 5],
      },
      grayContainer: {
        margin: [0, 20, 0, 20],
        minHeight: 120,
        backgroundColor: "#000000",
      },

      tableHeader: {
        margin: [0, 5, 0, 30],
      },

      margin: {
        margin: [0, 20, 0, 0],
      },

      tableSubscriptions: {
        margin: [0, 5, 0, 5],
      },
      tableBank: {
        margin: [0, 0, 0, 20],
      },
      insideTable: {
        margin: [0, 20, 0, 20],
        minHeight: 120,
        backgroundColor: "#F2FBFF",
      },
      space: {
        margin: [0, 10, 0, 10],
      },
      smallSpace: {
        margin: [0, 0, 0, 0],
      },
      mediumSpace: {
        margin: [0, 0, 0, 5],
      },
      spaceRight: {
        margin: [10, 0, 10, 0],
      },
      table: {
        margin: [0, 0, 10, 10],
      },
      noteText: {
        color: "#444A60",
        fontSize: 8,
        fontFamily: "Lato-Regular",
        fontWeight: 400,
      },
      bankInsideTable: {
        fontSize: 10,
        fontWeight: 400,
        fontFamily: "Lato-Regular",
        color: "#444A60",
        margin: [0, 20, 0, 20],
      },
      bankInsideTableBold: {
        fontSize: 10,
        fontWeight: 700,
        color: "#444A60",
        margin: [0, 20, 0, 20],
      },
      qrCode: {
        margin: [0, 10, 0, 10],
        display: "flex",
        justifyContent: "center",
      },
    },
  };
  return documentDefinition;
};
