import React from "react";
import {
  capitalize,
  numberFormat,
  numberFormatNoDecimals,
} from "../../utils/format";
import { StatusBadgePoint } from "../StatusBadgePoint/StatusBagePoint";
import Text from "../Text";
import styles from "./styles.module.css";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { Card } from "../Card/Card";
import { StatusCatalog } from "../StatusBadge";

interface StateProps {
  status: string;
  count: number;
  onClick: () => void;
  amount: number;
  totalAmount: number;
  lastItem: boolean;
  setState?: (value: string) => void;
  stateIn?: string;
  orderState?: boolean;
}

const OrderStateItem: React.FC<StateProps> = ({
  status,
  count,
  amount,
  onClick,
  totalAmount,
}) => {
  const percentage = (amount: number) => {
    return totalAmount <= 0
      ? `0%`
      : `${((amount / totalAmount) * 100).toFixed(2)} %`;
  };
  return (
    <Card>
      <div style={{ cursor: "pointer" }}>
        <FlexContainer
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection="column"
          padding="0 1em 0 1em"
        >
          <FlexContainer width="100%" justifyContent="space-between">
            <Text
              fontSize="1.2em"
              color="#000000"
              fontWeight={700}
              text={capitalize(status.replace("-", ""))}
            />

            <div className={styles.badge}>
              <Text
                fontSize="1.2em"
                fontWeight={700}
                color="#858D8F"
                text={`${percentage(amount)}`}
              />
            </div>
          </FlexContainer>
          <Text
            color="#000"
            padding="0.3em 0"
            fontWeight={700}
            fontSize="1.5em"
            text={`${numberFormat(amount)}`}
          />

          <div style={{ width: "100%" }} onClick={onClick}>
            <FlexContainer
              justifyContent="space-between"
              alignItems="flex-start"
              width="100%"
            >
              <FlexContainer>
                <StatusBadgePoint point status={status as StatusCatalog} />
                <Text
                  fontSize="1em"
                  padding="0 0.5em 0 0.2em"
                  color="#858D8F"
                  fontWeight={400}
                  text={`${numberFormatNoDecimals(count)} orders`}
                />
              </FlexContainer>
            </FlexContainer>
          </div>
        </FlexContainer>
      </div>
    </Card>
  );
};

export default OrderStateItem;
