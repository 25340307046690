import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import VenueList from "../../components/VenueList";
import { RootState } from "../../store";
import { Redirect, useHistory } from "react-router-dom";

import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { venueList } from "../../store/venueStats";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { ContainerTitle } from "../../components/ContainerTitle";
import { Button } from "../../components/Button";
import Search from "antd/es/input/Search";

interface Props {}

const Venues: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);
  const venues = useSelector((state: RootState) => state.venueStats);

  const [records_per_page, setHitsPerPage] = useState(10);
  const [page, setPage] = useState(venues.list.page);

  const { t } = useTranslation();

  const load = useCallback(async () => {
    dispatch(venueList({ page, records_per_page }));
  }, [dispatch, page, records_per_page]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    document.title = "RAVENT APP :: Venues";
  });

  const history = useHistory();

  if (!auth.loading && !auth.user) {
    return <Redirect from="*" to="/" />;
  }

  return (
    <LayouWithSideBar>
      <div className={styles.container}>
        <FlexContainer justifyContent="space-between" alignItems="center">
          <ContainerTitle
            size="medium"
            label={t("container.venues.venuesLabel")}
            count={venues.list.hits.length.toString()}
          />
          <Button
            label={t("container.venues.createNew")}
            onClick={() => history.push("/venues/new")}
          />
        </FlexContainer>
        <div className={styles.searchContainer}>
          <Search
            style={{ margin: "1em 0" }}
            placeholder={t("container.venues.search")}
            onSearch={() => {}}
          />
        </div>

        <VenueList
          sortable
          error={venues.error}
          loading={venues.loading}
          data={venues.list.hits}
          page={page}
          total={venues.list.count}
          hitsPerPage={records_per_page}
          onPageChange={setPage}
          onHitsPerPageChange={setHitsPerPage}
          pagination
        />
      </div>
    </LayouWithSideBar>
  );
};

export default Venues;
