import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import { visitorList } from "../../store/visitors";
import { RootState } from "../../store";
import { Redirect } from "react-router-dom";

import VisitorList from "../../components/VisitorsList";
import noVisitorsIcon from "../../assets/graphics-no-visitors.svg";
import { messageList } from "../../store/messages";
import styles from "./styles.module.css";
import { officialMediaMock } from "../../mocks/locations";
import { ContainerTitle } from "../../components/ContainerTitle";
import { Card } from "../../components/Card/Card";
import { EmptyList } from "../../components/List/EmptyList";
import Search from "antd/es/input/Search";

const Visitors: React.FC = () => {
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const visitors = useSelector((state: RootState) => state.visitors);
  const messages = useSelector((state: RootState) => state.messages);
  const [hitsPerPage, setHitsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const load = useCallback(async () => {
    dispatch(visitorList());
    dispatch(messageList());
  }, [dispatch]);

  useEffect(() => {
    load();
  }, [load, auth.user]);

  useEffect(() => {
    document.title = "RAVENT APP :: Visitors";
  });

  if (!auth.loading && !auth.user) {
    return <Redirect from="*" to="/" />;
  }
  return (
    <LayouWithSideBar>
      <div className={styles.container}>
        <ContainerTitle
          size="medium"
          label="Visitors"
          count={visitors.list.length.toString()}
        />
        {visitors.list.length < 1 ? (
          <Card containerClassname={styles.emptyContainer}>
            <EmptyList
              instructions={""}
              image={noVisitorsIcon}
              text="No visitors checked in."
            />
          </Card>
        ) : (
          <>
            <div className={styles.searchContainer}>
              <Search
                style={{ margin: "1em 0" }}
                placeholder="Search"
                onSearch={() => {}}
              />
            </div>
            <VisitorList
              officialMedia={officialMediaMock}
              messages={messages.list}
              locationColumn
              sortable
              path="/visitors"
              error={visitors.error}
              loading={false}
              data={visitors.list}
              page={page}
              total={visitors.list.length}
              hitsPerPage={hitsPerPage}
              onPageChange={setPage}
              onHitsPerPageChange={setHitsPerPage}
              pagination
            />
          </>
        )}
      </div>
    </LayouWithSideBar>
  );
};

export default Visitors;
