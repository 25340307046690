/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import { FormComponent } from "../../utils/types";
import { InputText } from "../Inputs/InputText";
import { InputSelect } from "../Inputs/InputSelect";

interface Props {
  data: FormComponent;
  error?: string;
  onChange?: (value: unknown) => void;
}

const SchemaComponent: React.FC<Props> = ({ data, error, onChange }) => {
  const label = data.label;

  if (data.type === "text") {
    return (
      <InputText
        label={label}
        key={data.type}
        value={data.value}
        onChange={onChange}
        error={error}
      />
    );
  }

  if (data.type === "list") {
    return (
      <InputSelect
        label={label}
        value={data.value}
        key={data.type}
        onChange={(value) => onChange?.(value)}
        options={data.elements}
        error={error}
      />
    );
  }

  return null;
};

export default SchemaComponent;
