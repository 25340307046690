import { Payment } from "@innomius/ravent-typescript-types";
import { numberFormat } from "./format";
import { formatDate } from "./times";

export function createRequestMessage(
  payment: Payment,
  shortId: string,
  orgName: string
) {
  const inStoreMessage = `You have received a payment request from ${orgName} with regards to your order number: ${shortId}
  Amount to be paid:  ${payment.amount.currency}${numberFormat(
    payment.amount.value
  )}
  Last date/time to pay: ${formatDate(payment.expiration)} 
  Please pay at: ${payment.supplierName}
  Using the following Reference: ${payment.paymentData.generatedReference}`;

  const wireTransferMessage = `You have received a payment request from ${orgName} with regards to your order number: ${shortId}
  Amount to be paid:  ${payment.amount.currency}${numberFormat(
    payment.amount.value
  )}
  Last date/time to pay: ${formatDate(payment.expiration)} 
  Please Send a Wire Transfer Payment to the following account
  ${payment.paymentData.bankAccount}
  In order for us to identify your payment, we need you to add a reference to your wire transfer.
  Reference: ${shortId}`;

  const paymentLinkMessage = `You have received a payment request from ${orgName} with regards to your order number: ${shortId}
  Amount to be paid:  ${payment.amount.currency}${numberFormat(
    payment.amount.value
  )}
  Last date/time to pay: ${formatDate(payment.expiration)} 
  Please pay using the following link: ${payment.paymentData.link}`;

  return payment.paymentType === "payment-link"
    ? paymentLinkMessage
    : payment.paymentType === "in-store"
    ? inStoreMessage
    : payment.paymentType === "wire-transfer"
    ? wireTransferMessage
    : "";
}
