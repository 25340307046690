import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fopList as fopsListService, FOPSearchResponse } from "../services/fop";

type FOPsSliceState = {
  loading: boolean;
  error: string;
  list: FOPSearchResponse;
};

const initialState: FOPsSliceState = {
  loading: true,
  error: "",
  list: {
    page: 0,
    total: 0,
    data: [],
  },
};

export const fopsList = createAsyncThunk("fops/list", async () => {
  const response = await fopsListService();
  return response;
});

const fopsSlice = createSlice({
  name: "fops",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fopsList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(fopsList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.list = action.payload;
    });
    builder.addCase(fopsList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An unexpected error ocurred";
    });
  },
});

export default fopsSlice.reducer;
