export const integrationsOptions = [
  { label: "bind-erp", value: "bind-erp" },
  { label: "codi", value: "codi" },
  { label: "conekta", value: "conekta" },
  { label: "first-data", value: "first-data" },
  { label: "stripe", value: "stripe" },
  { label: "zettle", value: "zettle" },
  { label: "scotiabank", value: "scotiabank" },
  { label: "payment-methods", value: "payment-methods" },
];

export const integrationsTypesOptions = [
  { label: "erp", value: "erp" },
  { label: "payment", value: "payment" },
  { label: "config", value: "config" },
];

export const intTypesOptions = [
  { label: "connection", value: "connection" },
  { label: "template", value: "template" },
];

export const roundOptions = [
  { label: "down", value: "down" },
  { label: "none", value: "none" },
];
