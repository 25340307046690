import { message } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { addInvoiceFile } from "../../services/invoices";
import APIError from "../../utils/APIError";
import Text from "../Text";
import FileItem from "./FileItem";
import styles from "./styles";
import { SideMenu } from "../SideMenu/SideMenu";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { GridContainer } from "../GridContainer/GridContainer";

interface Props {
  onSkip: () => void;
  description: string;
  id: string;
  orderId: string;
  open: boolean;
  load: () => void;
  list?: boolean;
}

const AddFile: React.FC<Props> = ({
  description,
  id,
  orderId,
  onSkip,
  open,
  load,
  list,
}) => {
  const { t } = useTranslation();

  const inputFile = React.useRef<HTMLInputElement>(null);

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);

  const handleUploadFiles = (files: File[]) => {
    const uploaded = [...uploadedFiles];

    if (files) {
      // eslint-disable-next-line array-callback-return
      files.some((file: File) => {
        if (uploaded.findIndex((f: File) => f.name === file.name) === -1) {
          uploaded.push(file);
        }
      });
    }
    setUploadedFiles(uploaded);
  };

  const handleFileEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const chosenFiles = Array.prototype.slice.call(event.target.files);
    if (chosenFiles) {
      handleUploadFiles(chosenFiles);
    }
  };

  const openInputFile = () => inputFile.current?.click();

  const onSave = async () => {
    try {
      setLoading(true);
      await addInvoiceFile(id, uploadedFiles, orderId);
      setLoading(false);
      message.success(t("component.addInvoice.successCreation"));
      onSkip();
      load();
    } catch (err) {
      setLoading(false);
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  return (
    <SideMenu width="50%" open={open} onClose={() => onSkip()}>
      <div className={styles.container}>
        <FlexContainer padding="1em 0 2em 0" justifyContent="space-between">
          <ContainerTitle label={description} size="medium" />
          <ButtonsContainer justifyContent="flex-end">
            <Button
              theme="white"
              label={
                list
                  ? t("component.addInvoice.cancel")
                  : t("component.addInvoice.skip")
              }
              onClick={onSkip}
            />
            <Button
              onClick={async () => onSave()}
              theme="blue"
              label={loading ? "..." : `${t("component.addInvoice.save")}`}
            />
          </ButtonsContainer>
        </FlexContainer>
        <Text
          padding="0 0 1em 0"
          text={t("component.addInvoice.instructions")}
        />
        <div
          id="dropArea"
          draggable
          onDragOver={(e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();
          }}
          onDragEnter={(e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();
          }}
          onDrop={(e: React.DragEvent<HTMLDivElement>) => {
            e.stopPropagation();
            e.preventDefault();
            openInputFile();
          }}
          className={styles.box}
        >
          <Text
            padding="0em 0 0 0"
            text={t("component.entityDivers.drag")}
            color="#000000"
            fontSize="1.4em"
            fontWeight={700}
          />

          <Text
            padding="0em 0 0.5em 0"
            text={t("component.entityDivers.or")}
            color="##666666"
            fontSize="1.1em"
            fontWeight={400}
          />
          <input
            id="fileUpload"
            type="file"
            ref={inputFile}
            multiple
            style={{ display: "none" }}
            onChange={handleFileEvent}
          />
          <Button
            theme="white"
            label={t("component.entityDivers.selectFile")}
            onClick={() => openInputFile()}
          />
        </div>
      </div>
      {uploadedFiles.length ? (
        <div className={styles.container}>
          <GridContainer
            padding="1em 0 1em 0"
            gap="0.5em"
            columns="1fr 0.1fr 0.3fr"
          >
            <Text
              color="#000000"
              text={t("component.addInvoice.description")}
              fontWeight={700}
            />
            <div />
            <Text
              color="#000000"
              text={t("component.addInvoice.fileSize")}
              fontWeight={700}
            />
          </GridContainer>

          <div>
            {uploadedFiles.map((file: File) => (
              <FileItem type={file.type} name={file.name} size={file.size} />
            ))}
          </div>
        </div>
      ) : null}
    </SideMenu>
  );
};

export default AddFile;
