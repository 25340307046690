import { stylesheet } from "typestyle";

const styles = stylesheet({
  button: {
    margin: "0 1em 1em 0",
  },
  insideButton: {
    padding: "1em 2em",
    minWidth: "17em",
  },
  fulfilledButton: {
    padding: "1em 2em",
    width: "17em",
  },
  buttonsContainer: {
    marginRight: "1em",
    marginBottom: "1em",
  },

  imageSmall: {
    width: "100%",
    height: "7em",
    borderRadius: 10,
    objectFit: "cover",
  },

  cancel: {
    marginRight: "1em",
  },
  notEnrollContainer: {
    background: "rgba(250, 173, 21, 0.1)",
    border: "1px solid #FAAD15",
    padding: "1.1em",
    borderRadius: "6px",
    marginBottom: "1em",
  },

  bold: {
    fontWeight: 700,
  },
  addressContainer: {
    padding: "1em 0 1em 0",
  },
  propertyInstructions: {
    padding: "0.5em 0 1.5em 0",
    textAlign: "justify",
  },
  iconContainer: {
    margin: "4em 0 3em 0",
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },

  modalContainer: {
    padding: "1em",
  },
  selectContainer: {
    width: "50%",
  },

  icon: {
    width: "40px",
    cursor: "pointer",
  },
  container: {
    $nest: {
      "div > div": {
        alignItems: "center !important",
      },
    },
  },
});

export default styles;
