const translation = {
  en: {
    successCreation: "Venue succesfully created.",
    back: "Back to Venues",
    cancel: "Cancel",
    save: "Save",
    name: "Name",
  },
  es: {
    successCreation: "Recinto creado con éxito.",
    back: "Regresar a Recintos",
    cancel: "Cancelar",
    save: "Guardar",
    name: "Nombre",
  },
  de: {},
};

export default translation;
