const translation = {
  en: {
    venuesLabel: "Venues",
    createNew: "+ Create New Venue",
    search: "Search",
  },
  es: {
    venues: "Recintos",
    createNew: "+ Crear Nuevo Recinto",
    search: "Buscar",
  },
  de: {},
};

export default translation;
