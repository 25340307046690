const translation = {
  en: {
    supplierInfo: "Supplier Information",
    supplierService: "Supplier Service",
    supplierName: "Supplier Name",
    integrations: "Integrations",
    intRequired: "Integration Required",
    intName: "Integration Name",
    noInt:
      "There is not an integration for this FOP. Please configure the integration.",
    createNewInt: "Create Integration",
    bank: "Bank",
    bankName: "Bank Name",
    bankAccount: "Bank Account",
    addAccount: "Add Account",
    enterAccount: "Please enter you account information.",
    round: "Round",
  },
  es: {
    supplierInfo: "Información del proveedor",
    supplierService: "Servicio de proveedor",
    supplierName: "Nombre del proveedor",
    integrations: "Integraciones",
    intRequired: "Integración requerida",
    intName: "Nombre de la integración",
    noInt: "No hay una integración para este FOP. Configure la integración",
    createNewInt: "Crear Integración",
    bank: "Banco",
    bankName: "Nombre del Banco",
    bankAccount: "Cuenta Bancaria",
    addAccount: "Agregar Cuenta",
    round: "Round",

    enterAccount: "Por favor ingrese la información de su cuenta.",
  },
  de: {},
};

export default translation;
