/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { BackLink } from "../../components/BackLink";
import { ContainerTitle } from "../../components/ContainerTitle";
import { Card } from "../../components/Card/Card";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { eventFulfillerOverview } from "../../store/fulfillersSummary";
import { DatePicker } from "antd";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import Text from "../../components/Text";
import FulfillerOverviewList from "../../components/FulfillerOverviewList";
import dayjs from "dayjs";
import { GridContainer } from "../../components/GridContainer/GridContainer";
import InputTextDropdown from "../../components/Inputs/InputTextDropdown";
import { timezones } from "../../constants/timezones";
import qs from "qs";
import { numberFormatNoDecimals } from "../../utils/format";

interface RouteParams {
  id: string;
}

interface Props extends RouteComponentProps<RouteParams> {}
const DashboardTeam: React.FC<Props> = ({ match, location }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function useQuery() {
    const { search } = useLocation();
    return search;
  }
  const query = useQuery();

  const [teamName, _setTeamName] = useState(
    (qs.parse(query.substring(1)).teamName as string) ||
      t("container.dashboard.team")
  );
  const [pending, _setPending] = useState(
    (qs.parse(query.substring(1)).pending as string) || "0"
  );
  const [total, _setTotal] = useState(
    (qs.parse(query.substring(1)).total as string) || "0"
  );
  const [sort, setSort] = useState(
    (qs.parse(query.substring(1)).sort as string) || "fulfillerName:asc"
  );
  const [fulfilled, _setFulfilled] = useState(
    (qs.parse(query.substring(1)).fulfilled as string) || "0"
  );
  const [running, _setRunning] = useState(
    (qs.parse(query.substring(1)).running as string) || "0"
  );
  const [failed, _setFailed] = useState(
    (qs.parse(query.substring(1)).failed as string) || "0"
  );
  const [timezone, setTimeZone] = useState<string>(
    localStorage.getItem("timeZone") ||
      Intl.DateTimeFormat().resolvedOptions().timeZone ||
      ""
  );
  const [from, setFrom] = useState(
    (qs.parse(query.substring(1)).from as string) ||
      dayjs(new Date()).startOf("month").format(`YYYY-MM-DD`)
  );
  
  const [to, setTo] = useState(
    (qs.parse(query.substring(1)).to as string) ||
      dayjs(new Date()).endOf("month").format(`YYYY-MM-DD`)
  );

  const [teamId, setTeamId] = useState(match.params.id);
  const fulfillers = useSelector((state: RootState) => state.fulfillersSummary);

  const loadFulfillers = useCallback(async () => {
    setTeamId(match.params.id);
    dispatch(
      eventFulfillerOverview({
        rowsField: "fulfillerName",
        columnsField: "state",
        rowsFieldId: "fulfillerId",
        fulfillmentTeamId: match.params.id,
        from,
        to,
        sort
      })
    );
  }, [dispatch, match.params.id, from, to, sort]);

  useEffect(() => {
    loadFulfillers();
  }, [loadFulfillers]);

  useEffect(() => {
    document.title = "RAVENT APP :: Overview :: Teams";
  });

  const history = useHistory();
  return (
    <LayouWithSideBar>
      <div className={styles.container}>
        <BackLink
          label={t("container.dashboard.back")}
          onClick={() => history.push("/overview/teams")}
        />
        <FlexContainer
          padding="1em 0 0em 0"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <ContainerTitle size="medium" label={teamName} />
          <FlexContainer justifyContent="flex-end">
            <DatePicker.RangePicker
              style={{ fontFamily: "Lato", fontSize: "0.1em" }}
              className={styles.calendar}
              format="YYYY-MM-DD"
              value={[from ? dayjs(from) : null, to ? dayjs(to) : null]}
              onChange={(_date, dateString) => {
                setFrom(dateString[0]);
                setTo(dateString[1]);
              }}
            />{" "}
            <div className={styles.timezone}>
              <InputTextDropdown
                placeholder={t("component.orderFilters.timezone")}
                showError={false}
                showLabel={false}
                value={timezone}
                top={"calc(100% + 3px)"}
                options={timezones.map((item) => ({
                  value: item,
                  label: item,
                }))}
                onChange={(value) => setTimeZone(value)}
              />
            </div>
          </FlexContainer>
        </FlexContainer>
        <GridContainer
          padding="2em 0 3em 0"
          gap="1em"
          columns="1fr 1fr 1fr 1fr 1fr"
        >
          <Card containerClassname={styles.padding}>
            <Text color="#000" text={t("container.dashboard.pending")} />
            <Text color="#000" fontSize="2em" fontWeight={600} text={numberFormatNoDecimals(pending)} />
          </Card>
          <Card containerClassname={styles.padding}>
            <Text color="#000" text={t("container.dashboard.running")} />
            <Text color="#000" fontSize="2em" fontWeight={600} text={numberFormatNoDecimals(running)} />
          </Card>
          <Card containerClassname={styles.padding}>
            <Text color="#000" text={t("container.dashboard.failed")} />
            <Text color="#000" fontSize="2em" fontWeight={600} text={numberFormatNoDecimals(failed)} />
          </Card>
          <Card containerClassname={styles.padding}>
            <Text color="#000" text={t("container.dashboard.completed")} />
            <Text
              color="#000"
              fontSize="2em"
              fontWeight={600}
              text={numberFormatNoDecimals(fulfilled)}
            />
          </Card>
          <Card containerClassname={styles.padding}>
            <Text color="#000" text={t("container.dashboard.total")} />
            <Text color="#000" fontSize="2em" fontWeight={600} text={`${numberFormatNoDecimals(total)}`} />
          </Card>
   
        </GridContainer>
        <FulfillerOverviewList
          from={from}
          to={to}
          onChangeSortOrder={(value) => {setSort(value)}}
          close={()=> history.push(`/overview/teams/${teamId}?from=${from}&to=${to}&teamName=${teamName}&pending=${pending}&completed=${fulfilled}&total=${total}&failed=${failed}&running=${running}`)}
          timezone={timezone}
          fulfillmentTeamId={teamId}
          data={fulfillers.fulfillers}
          error={fulfillers.error}
          loading={fulfillers.loading}
        />
      </div>
    </LayouWithSideBar>
  );
};

export default DashboardTeam;
