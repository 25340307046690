import React from "react";
import { Asset } from "../../utils/types";
import { useTranslation } from "react-i18next";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";
import { Input, InputNumber } from "antd"; // Import antd components
import { InputLabel } from "../Inputs/InputLabel/InputLabel";

interface Props {
  data: Asset;
  onChange: <P extends keyof Asset>(prop: P, value: Asset[P]) => void;
}

const VehicleDetailsForm: React.FC<Props> = ({ data, onChange }) => {
  const { t } = useTranslation();

  return (
    <div>
      <FlexContainer justifyContent="space-between">
        <ContainerTitle
          size="xsmall"
          label="Vehicle Details"
          padding="0 0 1em 0"
        />
      </FlexContainer>
      <GridContainer gap="1em" columns="1fr 1fr">
        {/* Vehicle License Plate */}
        <div>
          <InputLabel label={t("component.assetForm.vehicleLicensePlate")} />
          <Input
            value={data.vehicleLicensePlate}
            onChange={(e) => onChange("vehicleLicensePlate", e.target.value)}
          />
        </div>

        {/* Vehicle Serial */}
        <div>
          <InputLabel label={t("component.assetForm.vehicleSerial")} />
          <Input
            value={data.vehicleSerial}
            onChange={(e) => onChange("vehicleSerial", e.target.value)}
          />
        </div>

        {/* Vehicle Brand */}
        <div>
          <InputLabel label={t("component.assetForm.vehicleBrand")} />
          <Input
            value={data.vehicleBrand}
            onChange={(e) => onChange("vehicleBrand", e.target.value)}
          />
        </div>

        {/* Vehicle Model */}
        <div>
          <InputLabel label={t("component.assetForm.vehicleModel")} />
          <Input
            value={data.vehicleModel}
            onChange={(e) => onChange("vehicleModel", e.target.value)}
          />
        </div>
      </GridContainer>
      <ContainerTitle
        size="xsmall"
        label={t("component.assetForm.vehicleCapacity")}
        padding="1em 0 1em 0"
      />
      <GridContainer gap="1em" columns="1fr 1fr">
        {/* Vehicle Capacity (Quantity and Units) */}
        <div>
          <InputLabel label={t("component.assetForm.quantity")} />
          <InputNumber
            min={0}
            value={data.vehicleCapacity.quantity}
            onChange={(value) =>
              onChange("vehicleCapacity", {
                ...data.vehicleCapacity,
                quantity: value || 0,
              })
            }
          />
        </div>

        <div>
          <InputLabel label={t("component.assetForm.units")} />
          <Input
            value={data.vehicleCapacity.units}
            onChange={(e) =>
              onChange("vehicleCapacity", {
                ...data.vehicleCapacity,
                units: e.target.value,
              })
            }
          />
        </div>
      </GridContainer>
      <ContainerTitle
        size="xsmall"
        label={t("component.assetForm.vehicleWeight")}
        padding="1em 0 1em 0"
      />
      <GridContainer gap="1em" columns="1fr 1fr 1fr">
        {/* Vehicle Weight (Max, Current, Units) */}
        <div>
          <InputLabel label={t("component.assetForm.max")} />
          <InputNumber
            min={0}
            value={data.vehicleWeight.max}
            onChange={(value) =>
              onChange("vehicleWeight", {
                ...data.vehicleWeight,
                max: value || 0,
              })
            }
          />
        </div>

        <div>
          <InputLabel label={t("component.assetForm.current")} />
          <InputNumber
            min={0}
            value={data.vehicleWeight.current}
            onChange={(value) =>
              onChange("vehicleWeight", {
                ...data.vehicleWeight,
                current: value || 0,
              })
            }
          />
        </div>

        <div>
          <InputLabel label={t("component.assetForm.units")} />
          <Input
            value={data.vehicleWeight.units}
            onChange={(e) =>
              onChange("vehicleWeight", {
                ...data.vehicleWeight,
                units: e.target.value,
              })
            }
          />
        </div>
      </GridContainer>
    </div>
  );
};

export default VehicleDetailsForm;
