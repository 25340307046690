
import React from "react";
import Text from "../Text";
import deleteIcon from "../../assets/erase.svg";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { InputError } from "../Inputs/InputError/InputError";
import { Modal } from "../Modal/Modal";

interface Props {
  onCancel: () => void;
  open: boolean;
  onDelete: () => void;
  value: string;
  loading?: boolean;
  error?: string;
}

const DeleteModal: React.FC<Props> = ({
  onCancel,
  onDelete,
  open,
  value,
  error,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Modal
        width="600px"
        buttons={
          <div>
            <ButtonsContainer justifyContent="flex-end">
              <Button
                onClick={onCancel}
                theme="white"
                label={t("component.paymentDetail.cancel")}
              />
              <Button
                onClick={() => onDelete()}
                theme="red"
                label={
                  loading ? "..." : `${t("component.paymentDetail.delete")}`
                }
              />
            </ButtonsContainer>
            {error ? (
              <FlexContainer justifyContent="flex-end" padding="0.5em 1em 0 0">
                <InputError error={error} />
              </FlexContainer>
            ) : null}
          </div>
        }
        open={open}
        onClose={onCancel}
      >
        <FlexContainer flexDirection="column">
          <img src={deleteIcon} alt="" className={styles.icon} />
          <Text
            padding="1em 0 2em 0"
            text={value}
            color="#000000"
            fontSize="1.4em"
            fontWeight={700}
          />
        </FlexContainer>
      </Modal>
    </div>
  );
};

export default DeleteModal;
