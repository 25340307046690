import React from "react";
import { RegisterPaymentRequest } from "../../utils/types";
import { get } from "lodash";
import CustomError from "../../utils/CustomError";
import { useTranslation } from "react-i18next";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputText } from "../Inputs/InputText";

interface Props {
  registerPayment: RegisterPaymentRequest;
  onChange: <P extends keyof RegisterPaymentRequest>(
    prop: P,
    value: RegisterPaymentRequest[P]
  ) => void;
  error?: CustomError;
}

const WiretransferForm: React.FC<Props> = ({
  registerPayment,
  onChange,
  error,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <GridContainer gap="1em" columns="1fr 1fr">
        <InputText
          label={t("component.registerPaymentForm.bank")}
          value={registerPayment.bankName}
          onChange={(value) => onChange("bankName", value)}
        />
        <InputText
          value={registerPayment.bankAccount}
          label={t("component.registerPaymentForm.accountNumber")}
          onChange={(value) => onChange("bankAccount", value)}
          error={
            !registerPayment.bankAccount &&
            get(error, "errors.bank-account", "")
          }
        />
      </GridContainer>
    </div>
  );
};

export default WiretransferForm;
