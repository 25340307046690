import { getAccessToken } from "./auth";
import fetch from "../utils/fetch";
import { Media } from "@innomius/ravent-typescript-types";
import get from "lodash/get";
import multiPartFetchMedia from "../utils/multiPartFetchMedita";
import multiPartFetch from "../utils/multiPartFetch";

export function parseMedia(data: unknown): Media {
  return {
    id: get(data, "_id", ""),
    fileName: get(data, "fileName", ""),
    organizationId: get(data, "organization_id", ""),
    bucketKey: get(data, "bucketKey", ""),
    public: get(data, "public", false),
    created: get(data, "created", ""),
    updated: get(data, "updated", ""),
    updatedAt: get(data, "updatedAt", ""),
    contentType: get(data, "contentType", ""),
    deleted: get(data, "deleted", false),
    locationId: get(data, "location_id", ""),
    venueId: get(data, "venue_id", ""),
    public_url: get(data, "public_url", ""),
    description: get(data, "description", ""),
  };
}

export async function createLocationMedia(
  venueId: string,
  files: File[],
  locationId: string
) {
  const token = await getAccessToken();

  const res = await multiPartFetchMedia(
    "/location_media",
    files[0],
    venueId,
    locationId,
    token,
    "POST"
  );
  return parseMedia(res.body);
}

export async function sendLocationMedia(
  mediaId: string,
  language: string,
  target: string
) {
  const token = await getAccessToken();
  const body = {
    language: language,
    transport: "email",
    targets: target.split(" "),
    data: { mediaId: mediaId },
  };
  await fetch(`/messaging/locationMedia`, token, "POST", body);
}

export interface MediaParamsList {
  venue_id: string;
  location_id: string;
}

export interface MediaSearchResponse {
  data: Media[];
  page: number;
  total: number;
}

export async function mediaList(
  params: MediaParamsList
): Promise<MediaSearchResponse> {
  const token = await getAccessToken();
  const res = await fetch("/location_media", token, "GET", null, params);

  return {
    data: res.body ? res.body.hits.map(parseMedia) : [],
    page: get(res.body, "page", 0),
    total: get(res.body, "count", 0),
  };
}

export async function updateLocationMedia(
  id: string,
  update: Record<string, any>
): Promise<Media> {
  const token = await getAccessToken();
  const body = update;
  const res = await fetch(`/location_media/${id}`, token, "PATCH", body);
  const data = parseMedia(res.body);
  return data;
}

export async function deleteMedia(id: string): Promise<void> {
  const token = await getAccessToken();
  await fetch(`/location_media/${id}`, token, "DELETE");
  return;
}

export async function updateActionImage(id: string, files: File[]) {
  const token = await getAccessToken();

  const res = await multiPartFetch(
    `/assets/${id}`,
    token,
    files[0],
    null,
    "PATCH"
  );
  return parseMedia(res.body);
}
