import React from "react";
import RefunItem from "./RefundItem";
import { PaymentListSkeleton } from "../ListSkeletons";
import { RefundTypes } from "@innomius/ravent-typescript-types";
import { useTranslation } from "react-i18next";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { List } from "../List";

interface RefundListProps {
  error: string;
  loading: boolean;
  data: RefundTypes[];
}

const OrderRefundList: React.FC<RefundListProps> = ({
  error,
  data,
  loading,
}) => {
  const { t } = useTranslation();

  const header = (
    <div>
      <ListHeader template="1fr 1fr 1fr 1.5fr 1fr 1fr 1fr">
        <ListHeaderLabel
          value={t("component.orderRefundList.paymentId")}
          justifyContent={"flex-start"}
        />
        <ListHeaderLabel
          value={t("component.orderRefundList.paymentType")}
          textAlign={"center"}
          justifyContent={"flex-start"}
        />
        <ListHeaderLabel
          value={t("component.orderRefundList.paymentDetails")}
          justifyContent={"flex-start"}
        />
        <ListHeaderLabel
          value={t("component.orderRefundList.refunded")}
          justifyContent={"flex-start"}
        />
        <ListHeaderLabel
          value={t("component.orderRefundList.amount")}
          justifyContent={"center"}
        />
        <ListHeaderLabel
          value={t("component.orderRefundList.status")}
          justifyContent={"center"}
        />
        <ListHeaderLabel
          value={t("component.orderRefundList.actions")}
          justifyContent={"center"}
        />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <List
        loading={loading}
        loadingElement={() => <PaymentListSkeleton />}
        error={error}
        data={data}
        keyExtractor={(item) => item.id}
        renderHeader={() => header}
        renderItem={(item) => (
          <>
            <RefunItem data={item} key={item.id} />
          </>
        )}
      />
    </div>
  );
};

export default OrderRefundList;
