const translation = {
  en: {
    issueRefund: "Issue Refund",
    cancel: "Cancel",
    issue: "Issue",
    invoiceId: "Invoice ID",
  },
  es: {
    issueRefund: "Emitir Reembolso",
    cancel: "Cancelar",
    issue: "Emitir",
    invoiceId: "Factura ID",
  },

  de: {},
};

export default translation;
