
import { stylesheet } from "typestyle";
import { black } from "../../utils/colors";

const styles = stylesheet({
  container: {
    padding: "2em",
  },
  title: {
    fontSize: "1.5em",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "left",
    color: "#141322",
    paddingTop: "1em",
  },
  searchRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "1em",
  },
  center: {
    color: black,
    fontStyle: "normal",
    justifyContent: "center",
    padding: "0.5em",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    marginRight: "0.5em",
  },
  radioContainer: {
    marginRight: "3em",
    padding: "0.5em 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  wrapper: {
    padding: "0em 2em",
  },
  mcontainer: {
    $nest: {
      "div > div": {
        alignItems: "center !important",
      },
    },
  },
  even: {
    color: black,
    fontStyle: "normal",
    justifyContent: "space-evenly",
    padding: "0.5em",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    marginRight: "0.5em",
  },
  normal: {
    fontSize: "1em",
    fontWeight: 500,
    color: "#333",
    alignItems: "center",
    display: "flex",
    padding: "1em",
    textAlign: "left",
  },
  normalBold: {
    fontSize: "1em",
    fontWeight: 600,
    alignItems: "center",
    display: "flex",
    padding: "1em",
    textAlign: "left",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    width: 23,
  },
  iconType: {
    width: 23,
    margin: "0 0.5em 0 0",
  },
  link: {
    textDecoration: "none",
  },
  paginationContainer: {
    padding: "2em 0",
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  paginationContainerFixed: {
    padding: "2em 0 1em 0",
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    position: "fixed",
    bottom: 0,
    background: "#f2f4f8",
  },
  pointer: {
    cursor: "pointer",
  },
  listContainer: {
    paddingBottom: "3em",
  },
  paginationContainerUp: {
    padding: "2em 0 1em 0",
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    position: "fixed",
    bottom: 0,
    background: "#f2f4f8",
  },
});

export default styles;
