const translation = {
  en: {
    close: "Close",
    missing: "Missing Infromation",
    user: "User",
    status: "Status",
    affectedUsers: "Affected Users",
    of: "of",
    selected: "selected",
    missingEmail: "missing email",
    missingPhone: "missing phone",
    instructions:
      "The following users have incomplete information (email or phone) and cannot be registered. Please complete the required details to proceed.",
  },
  es: {
    missingEmail: "correo faltante",
    missingPhone: "teléfono faltante",
    user: "Usuario",
    status: "Estado",
    affectedUsers: "Usuarios Afectados",
    of: "de",
    selected: "seleccionados",
    missing: "Información Faltante",
    close: "Cerrar",
    instructions:
      "Los siguientes usuarios tienen información incompleta (correo electrónico o teléfono) y no pueden ser registrados. Por favor complete los detalles requeridos para continuar.",
  },
  de: {},
};

export default translation;
