import React from "react";


import { useTranslation } from "react-i18next";
import reciptIcon from "../../assets/receipt.svg";
import fileIcon from "../../assets/sidebar/orders.svg";
import eventIcon from "../../assets/sidebar/events.svg";

import { SideMenu } from "../SideMenu/SideMenu";
import Text from "../Text";
import {
  numberFormatMoreDecimals,
  numberFormatNoDecimals,
} from "../../utils/format";
import { Card } from "../Card/Card";
import styles from "./styles";
import { CountsResponse } from "../../services/events";
import BillingIntList from "../BillingIntList";
import dayjs from "dayjs";
import { ContainerTitle } from "../ContainerTitle";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { Button } from "../Button";
import { GridContainer } from "../GridContainer/GridContainer";

interface Props {
  open: boolean;
  onClose: () => void;
  data: CountsResponse;
}

const BillingDetail: React.FC<Props> = ({ onClose, open, data }) => {
  const { t } = useTranslation();

  return (
    <SideMenu width="60%" open={open} onClose={() => onClose()}>
      <FlexContainer
        padding="1.5em 0 1em 0"
        alignItems="center"
        justifyContent="space-between"
      >
        <ContainerTitle
          label={dayjs.utc(data.month).format("MMM, YYYY")}
          size="medium"
        />
        <Button
          theme="white"
          icon={reciptIcon}
          label={t("component.billingDetail.downloadReceipt")}
        />
      </FlexContainer>
      <FlexContainer padding="1em 0 1.5em 0" justifyContent="space-between">
        <Text
          text={`Total cost (Orders + Events)`}
          fontWeight={700}
          color="#000"
          fontSize="1.3em"
        />
        <Text
          text={numberFormatMoreDecimals(data.ordersCost + data.eventsCost)}
          fontWeight={700}
          color="#000"
          fontSize="1.5em"
        />
      </FlexContainer>
      <GridContainer padding="0 0 2em 0" gap="1em" columns="1fr 1fr">
        <Card containerClassname={styles.card}>
          <div className={styles.headerCard}>
            <FlexContainer padding="0 0 0.5em 0" justifyContent="space-between">
              <Text
                text={"Orders"}
                fontWeight={700}
                color="#000"
                fontSize="0.9em"
              />
              <Text
                text={"Total Orders Cost"}
                fontWeight={700}
                color="#000"
                fontSize="0.9em"
              />
            </FlexContainer>
            <FlexContainer justifyContent="space-between">
              <FlexContainer alignItems="flex-end" justifyContent="flex-start">
                <img src={fileIcon} alt="" className={styles.icon} />
                <Text
                  text={numberFormatNoDecimals(data.orders)}
                  fontWeight={700}
                  color="#000"
                  fontSize="1.4em"
                />
              </FlexContainer>
              <Text
                text={numberFormatMoreDecimals(data.ordersCost)}
                fontWeight={700}
                color="#000"
                fontSize="1.4em"
              />
            </FlexContainer>
          </div>
          <div className={styles.cardButtom}>
            <FlexContainer padding="0 0 0.5em 0" justifyContent="space-between">
              <Text
                text={"Cost per Order"}
                fontWeight={400}
                color="#333"
                fontSize="1.1em"
              />
              <Text
                text={numberFormatMoreDecimals(data.costPerOrder)}
                fontWeight={400}
                color="#333"
                fontSize="1.3em"
              />
            </FlexContainer>
            <FlexContainer justifyContent="space-between">
              <Text
                text={"Deleted Orders"}
                fontWeight={400}
                color="#333"
                fontSize="1.1em"
              />
              <Text
                text={numberFormatNoDecimals(data.ordersDeleted)}
                fontWeight={400}
                color="#333"
                fontSize="1.3em"
              />
            </FlexContainer>
          </div>
        </Card>
        <Card containerClassname={styles.card}>
          <div className={styles.headerCard}>
            <FlexContainer padding="0 0 0.5em 0" justifyContent="space-between">
              <Text
                text={"Events"}
                fontWeight={700}
                color="#000"
                fontSize="0.9em"
              />
              <Text
                text={"Total Events Cost"}
                fontWeight={700}
                color="#000"
                fontSize="0.9em"
              />
            </FlexContainer>
            <FlexContainer justifyContent="space-between">
              <FlexContainer justifyContent="flex-start">
                <img src={eventIcon} alt="" className={styles.icon} />
                <Text
                  text={numberFormatNoDecimals(data.events)}
                  fontWeight={700}
                  color="#000"
                  fontSize="1.4em"
                />
              </FlexContainer>
              <Text
                text={numberFormatMoreDecimals(data.eventsCost)}
                fontWeight={700}
                color="#000"
                fontSize="1.4em"
              />
            </FlexContainer>
          </div>
          <div className={styles.cardButtom}>
            <FlexContainer padding="0 0 0.5em 0" justifyContent="space-between">
              <Text
                text={"Cost per Event"}
                fontWeight={400}
                color="#333"
                fontSize="1.1em"
              />
              <Text
                text={numberFormatMoreDecimals(data.costPerEvent)}
                fontWeight={400}
                color="#333"
                fontSize="1.3em"
              />
            </FlexContainer>
            <FlexContainer padding="0 0 0.5em 0" justifyContent="space-between">
              <Text
                text={"Paxes"}
                fontWeight={400}
                color="#333"
                fontSize="1.1em"
              />
              <Text
                text={numberFormatNoDecimals(data.pax)}
                fontWeight={400}
                color="#333"
                fontSize="1.3em"
              />
            </FlexContainer>
            <FlexContainer justifyContent="space-between">
              <Text
                text={"Total Paxes Cost"}
                fontWeight={400}
                color="#333"
                fontSize="1.1em"
              />

              <Text
                text={numberFormatMoreDecimals(data.costPerPax * data.pax)}
                fontWeight={400}
                color="#333"
                fontSize="1.3em"
              />
            </FlexContainer>
          </div>
        </Card>
      </GridContainer>
      <Text
        text={"Integrations Costs"}
        fontWeight={700}
        color="#000"
        fontSize="1.3em"
        padding="0 0 1em 0"
      />
      <BillingIntList loading={false} error="" />
    </SideMenu>
  );
};

export default BillingDetail;
