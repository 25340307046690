import { User } from "@innomius/ravent-typescript-types";
import { isNil, omitBy } from "lodash";
import get from "lodash/get";
import CustomError from "../utils/CustomError";
import fetch from "../utils/fetch";
import { AccessTokenParsed, RefreshTokenParsed } from "../utils/types";
import { getAccessToken } from "./auth";
import { Filter, TextSearchListEvent, parseFacetEvent } from "./orders";

export function parseUser(data: unknown): User {
  return {
    username: get(data, "username", ""),
    id: get(data, "_id", ""),
    colorizedTags: get(data, "colorizedTags", []),
    created: get(data, "createdAt", ""),
    updated: get(data, "updated", ""),
    disabled: get(data, "disabled", false),
    externalId: get(data, "externalId", ""),
    venueId: get(data, "venue_id", ""),
    deleted: get(data, "deleted", false),
    adminRole: get(data, "admin", false),
    firstName: get(data, "firstName", ""),
    lastName: get(data, "lastName", ""),
    fulfillmentTeamName: get(data, "fulfillmentTeam.name", ""),
    phone: get(data, "phone", ""),
    email: get(data, "email", ""),
    venueName: get(data, "venueName", ""),
    fulfillerTeamId: get(data, "fulfillmentTeamId", ""),
    organizationName: get(data, "organizationName", ""),
    organizationId: get(data, "organization_id", ""),
    roles: get(data, "roles", []),
    tags: get(data, "tags", []),
    type: get(data, "type", ""),
    emailVerified: get(data, "emailVerified", false),
    loginWithPhone: get(data, "loginWithPhone", false),
  };
}

export interface UserListParams {
  page?: number;
  records_per_page?: number;
  type?: string;
  venue_id?: string;
  sort?: string;
  fulfillmentTeamId?: string;
}

export interface UserListResponse {
  data: User[];
  page: number;
  total: number;
}

function mapSorts(data: UserListParams) {
  return omitBy(
    {
      page: data.page,
      records_per_page: data.records_per_page,
      type: data.type || null,
      fulfillmentTeamId: data.fulfillmentTeamId || null,
      sort: data.sort || "createdAt:desc",
      venue_id: data.venue_id || null,
    },
    isNil
  );
}

export async function userList(
  params: UserListParams
): Promise<UserListResponse> {
  const token = await getAccessToken();
  const res = await fetch("/users", token, "GET", null, mapSorts(params));

  return {
    data: res.body ? res.body.hits.map(parseUser) : [],
    page: get(res.body, "page", 0),
    total: get(res.body, "count", 0),
  };
}

export async function getUser(id: string): Promise<User> {
  const token = await getAccessToken();

  const res = await fetch(`/users/${id}`, token);
  const data = parseUser(res.body);
  return data;
}

export async function deleteUser(id: string): Promise<void> {
  const token = await getAccessToken();

  await fetch(`/users/${id}`, token, "DELETE");
}

export const emailRegExp = /\S+@\S+\.\S+/;
export const phoneRegExp =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

export const isValidEmail = (email: string) => emailRegExp.test(email.trim());
export const isValidPhone = (phone: string) => phoneRegExp.test(phone.trim());

export async function createUser(data: CreateUserParams): Promise<User> {
  let body;
  let phone = data.phone;
  if (phone && !phone.startsWith("00") && !phone.startsWith("+")) {
    phone = `+${phone}`;
  }

  if (data.loginWithPhone) {
    body = {
      firstName: data.firstName,
      lastName: data.lastName,
      phone: phone || null,
      admin: data.adminRole,
      disabled: data.disabled,
      externalId: data.externalId,
      tags: data.tags,
      roles: data.roles,
      type: data.type || "user",
      loginWithPhone: data.loginWithPhone,
    };
  }
  if (!data.loginWithPhone)
    body = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email || null,
      disabled: data.disabled,
      externalId: data.externalId,
      admin: data.adminRole,
      tags: data.tags,
      phone: phone || null,
      roles: data.roles,
      type: data.type || "user",
      loginWithPhone: data.loginWithPhone,
    };
  const token = await getAccessToken();

  const res = await fetch("/users", token, "POST", body);
  return res.body;
}

export async function updateUser(data: User): Promise<User> {
  let phone = data.phone;
  if (phone && !phone.startsWith("00") && !phone.startsWith("+")) {
    phone = `+${phone}`;
  }
  const body = {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email || null,
    phone: phone || null,
    roles: data.roles,
    admin: data.adminRole,
    tags: data.tags,
    disabled: data.disabled,
    externalId: data.externalId,
  };

  const token = await getAccessToken();

  await fetch(`/users/${data.id}/authz`, token, "PATCH", {
    admin: data.adminRole,
  });

  const res = await fetch(`/users/${data.id}`, token, "PATCH", body);

  return res.body;
}

export async function onUpdateElmentUser(
  id: string,
  update: Record<string, any>
): Promise<User> {
  const token = await getAccessToken();

  const res = await fetch(`/users/${id}`, token, "PATCH", update);
  return res.body;
}

export async function updateTeamUser(
  teamId: string,
  userId: string
): Promise<User> {
  const body = {
    fulfillmentTeamId: teamId,
  };
  const token = await getAccessToken();

  const res = await fetch(`/users/${userId}/authz `, token, "PATCH", body);
  return res.body;
}

export async function updateUserAuthz(
  userId: string,
  venueId: string,
  roles?: string[]
): Promise<User> {
  const body = {
    venue_id: venueId,
    roles: roles,
  };
  const token = await getAccessToken();
  const res = await fetch(`/users/${userId}/authz `, token, "PATCH", body);
  return res.body;
}

export async function registerUsers(users: any[]): Promise<User> {
  const body = {
    ids: users.map((user) => user.id),
  };
  const token = await getAccessToken();
  const res = await fetch("/users/register", token, "POST", body);
  return res.body;
}

export interface CreateUserParams {
  firstName: string;
  lastName: string;
  isEnrolled?: boolean;
  username: string;
  emailVerified?: boolean;
  externalId: string;
  email: string;
  phone: string;
  tags: any[];
  adminRole: boolean;
  roles: string[];
  venueId: string;
  type: string;
  fulfillerTeamId: string;
  id: string;
  disabled: boolean;
  loginWithPhone: boolean;
  colorizedTags?: any[];  
}

export const defaultUser: CreateUserParams = {
  firstName: "",
  disabled: false,
  lastName: "",
  username: "",
  externalId: "",

  phone: "",
  tags: [],
  adminRole: false,
  email: "",
  loginWithPhone: false,
  roles: [],
  venueId: "",
  type: "",
  fulfillerTeamId: "",
  id: "",
};

export function checkUser(input: CreateUserParams) {
  const error = new CustomError("Error saving Org Form", "formError");

  if (!input.email && !input.phone) {
    error.setError("user-email", "Email is required");
  }

  if (!error.length) {
    return true;
  }
  throw error;
}

export interface ResetResponse {
  accessToken: string;
  expiresIn: number;
  refreshExpiresIn: number;
  refreshToken: string;
  tokenType: string;
  notBeforePolicy: number;
  sessionState: string;
  scope: string;
  accessTokenParsed: AccessTokenParsed;
  refreshTokenParsed: RefreshTokenParsed;
}

export async function enrollUser(
  firstName: string,
  lastName: string,
  password: string,
  token: string
): Promise<void> {
  await fetch("/enroll", null, "POST", {
    firstName,
    lastName,
    password,
    token,
  });
  return;
}

export async function changePassword(
  id: string,
  oldPassword: string,
  newPassword: string
): Promise<void> {
  const token = await getAccessToken();
  await fetch(`/users/${id}/changepassword`, token, "PATCH", {
    oldPassword,
    newPassword,
  });
  return;
}

export async function sendResetUsername(username: string): Promise<void> {
  await fetch("/users/forgot/password", null, "PATCH", {
    username,
  });
  return;
}

export async function setPasswordUser(
  password: string,
  token: string
): Promise<void> {
  await fetch("/users/set/password", null, "PATCH", {
    token,
    password,
  });
  return;
}

export interface UserListParamsFT {
  venueName?: Filter;
  type?: Filter;
  fulfillmentTeamName?: Filter;
  page?: number | string;
  records_per_page?: number | string;
  search?: string;
  fulfillmentTeamId?: string;
  tags?: Filter;
  venue_id?: string;
  sort?: string;
}

interface Filters {
  venueName: Filter | null;
  fulfillmentTeamName: Filter | null;
  tags: Filter | null;
  type: Filter | null;
}

function mapSortsFullText(data: UserListParamsFT) {
  const filters: Filters = {
    type: data.type?.items.length ? data.type : null,
    venueName: data.venueName?.items.length ? data.venueName : null,
    tags: data.tags?.items.length ? data.tags : null,
    fulfillmentTeamName: data.fulfillmentTeamName?.items.length
      ? data.fulfillmentTeamName
      : null,
  };

  const filteredFilters: Partial<Filters> = {};
  for (const key in filters) {
    if (filters[key as keyof Filters] !== null) {
      filteredFilters[key as keyof Filters] = filters[key as keyof Filters]!;
    }
  }

  const jsonString = JSON.stringify(filteredFilters);

  return omitBy(
    {
      records_per_page: data.records_per_page,
      page: data.page,
      fulfillmentTeamId: data.fulfillmentTeamId || null,
      venue_id: data.venue_id || null,
      search: data.search || null,
      sort: data.sort || "_score:desc,createdAt:desc",
      filters: jsonString === "{}" ? "{}" : jsonString,
    },
    isNil
  );
}

export async function userFullTextSearch(
  params: UserListParamsFT
): Promise<TextSearchListEvent> {
  const token = await getAccessToken();
  const res = await fetch(
    "/events/textSearchUsers",
    token,
    "GET",
    null,
    mapSortsFullText(params)
  );
  return {
    hits: res.body ? res.body.hits.map(parseUser) : [],
    page: get(res.body, "page", 0),
    count: get(res.body, "count", 0),
    facets: res.body.facets ? res.body.facets.map(parseFacetEvent) : [],
  };
}
