const translation = {
  en: {
    deleteUserMessage: "User succesfully deleted.",
    usersLabel: "Users",
    search: "Search",
    createUser: "+ Create User",
    instructions: "Start creating Users",
    no: "No Users",
    users: "Users",
    roles: "Roles",
    invite: "Invite to enroll",
    reset: "Reset Password",
    delete: "Delete",
    inviteSuccess: "Invitation sent successfully.",
    inviteUsers: "You are going to invite ",
    usersToEnroll: " users to enroll. Are you sure?",
    resetSuccess: "Password reset successfully.",
  },
  es: {
    resetSuccess: "Contraseña restablecida con éxito.",
    inviteSuccess: "Invitación enviada con éxito.",
    usersToEnroll: " usuarios a inscribirse. ¿Estás seguro?",
    inviteUsers: "Estás a punto de invitar a ",
    delete: "Eliminar",
    reset: "Restablecer Contraseña",
    invite: "Invitar a inscribirse",
    users: "Usuarios",
    roles: "Roles",
    deleteUserMessage: "Usuario eliminado con éxito.",
    usersLabel: "Usuarios",
    createUser: "+ Crear Usuario",
    search: "Buscar",
    instructions: "Empieza a crear Usuarios",
    no: "No hay Usuarios",
  },
  de: {},
};

export default translation;
