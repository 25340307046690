const translation = {
  en: {
    searchByName: "Search by name",
    integrationType: "Integration Type",
    supplierType: "Supplier Type",
    searchBySupplierName: "Search by supplier name",
    clearAll: "Clear all",
  },
  es: {
    searchByName: "Buscar por nombre",
    integrationType: "Tipo de integración",
    supplierType: "Tipo de proveedor",
    searchBySupplierName: "Buscar por nombre de proveedor",
    clearAll: "Borrar todo",
  },
  de: {},
};

export default translation;
