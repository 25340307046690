const translation = {
  en: {
    locationName: "Location",
    checkinFlowUrl: "Check-in Flow Url",
    checkinPeriod: "Check-in Period",
    public: "Public Check-in",
    checkin: "Check-in",
  },
  es: {
    locationName: "Ubicación",
    checkinFlowUrl: "URL de flujo de registro",
    checkinPeriod: "Período de registro",
    public: "Registro público",
    checkin: "Registro",
  },

  de: {},
};

export default translation;
