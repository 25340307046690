import { stylesheet } from "typestyle";

const styles = stylesheet({
  button: {
    width: "68px !important",
    marginRight: "1em",
  },
  buttonRight: {
    width: "68px !important",
  },
  warning: {
    width: "20px",
  },
  padding: {
    padding: "0.5em",
  },
  inputSelect: {
    width: "90%",
  },
});

export default styles;
