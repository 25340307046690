import { stylesheet } from "typestyle";
import { black } from "../../utils/colors";

const styles = stylesheet({
  wrapperCard: {
    padding: "2em",
    marginBottom: "1em",
  },
  instructions: {
    color: black,
    marginTop: "0.5em",
    paddingBottom: "2em",
  },
  inputContainer: {
    margin: "0.em 0",
  },
  radioWrapper: {
    padding: "0.7em 0",
  },
  margin: {
    margin: "1em 0 0 0",
  },
  checkbox: {
    marginTop: "0.4em",
    marginRight: "1em",
  },
  notEnrollContainer: {
    background: "rgba(250, 173, 21, 0.1)",
    border: "1px solid #FAAD15",
    padding: "1.1em",
    borderRadius: "6px",
  },
  modalContainer: {
    padding: "1em",
  },
  selectContainer: {
    width: "50%",
    padding: "0em 0 4.5em 0",
  },
  icon: {
    width: "30px",
    cursor: "pointer",
  },
});

export default styles;
