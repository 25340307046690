import React, { useState } from "react";

import { OrderListSkeleton } from "../ListSkeletons";
import { FulfillmentEvent, Load } from "@innomius/ravent-typescript-types";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { PaginationRavent } from "../Pagination/Pagination";
import EventItem from "./EventItem";
import { Asset } from "../../utils/types";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import EventItemNormal from "./EventItemNormal";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import secureLocalStorage from "react-secure-storage";
import { ListHeader } from "../List/ListHeader";
import { List } from "../List";
import EventMini from "./EventMini";

interface EventListProps {
  error: string;
  type: string;
  loading: boolean;
  data: FulfillmentEvent[];
  page: number;
  total: number;
  onChangeSortOrder?: (sort: string) => void;
  hitsPerPage: number;
  onPageChange?: (page: number) => void;
  onHitsPerPageChange?: (hitsPerPage: number) => void;
  pagination?: boolean;
  onTagClick: (tag: string) => void;
  updateAsset: (event: FulfillmentEvent, id: string) => void;
  onSaveLoad: (eventId: string, data: Load[]) => void;
  teams: { label: string; value: string }[];
  updateFulfiller: (eventId: string, fulfillerId: string) => void;
  updateTeam: (eventId: string, teamId: string) => void;
  assets: Asset[];
  selected: { id: string }[];
  setSelected: (value: any) => void;
  setState: (
    id: string,
    fulfillmentLevel: string,
    status: string,
    w3w: string,
    images: string[]
  ) => void;
  updateFulfillmentName: (
    id: string,
    diveshopId: string,
    fulfillmentLevel: string
  ) => void;
  fulfillmentNames: { label: string; value: string }[];
  errorState: string;
  setNew: (id: string, state: string) => void;
  loadingState: { eventId: string; state: string };
}

const EventList: React.FC<EventListProps> = ({
  error,
  loading,
  data,
  pagination,
  page,
  total,
  hitsPerPage,
  onHitsPerPageChange,
  onPageChange,
  onTagClick,
  updateAsset,
  updateFulfiller,
  updateTeam,
  teams,
  onChangeSortOrder,
  onSaveLoad,
  assets,
  selected,
  setSelected,
  errorState,
  updateFulfillmentName,
  setState,
  fulfillmentNames,
  setNew,
  type,
  loadingState,
}) => {
  const { t } = useTranslation();

  const [selectAll, setSelectAll] = useState(false);

  const [headerSelection, setHeader] = useState(
    (secureLocalStorage.getItem("header") as keyof FulfillmentEvent) || "notes"
  );

  const [order, setOrder] = useState("asc");
  const [sort, setSort] = useState<string | null>(null);

  const handleCheckboxChange = (locationId: string) => {
    const isSelected = selected.some((loc) => loc.id === locationId);

    if (selectAll) {
      setSelected((prevSelected: any[]) => {
        if (isSelected) {
          return prevSelected.filter((loc) => loc.id !== locationId);
        } else {
          return [...prevSelected, { id: locationId }];
        }
      });
    } else {
      setSelected((prevSelected: any[]) => {
        if (isSelected) {
          return prevSelected.filter((loc) => loc.id !== locationId);
        } else {
          return [...prevSelected, { id: locationId }];
        }
      });
    }
  };

  const handleSelectAllChange = (e: CheckboxChangeEvent) => {
    setSelectAll(e.target.checked);
    setSelected(e.target.checked ? data.map((item) => ({ id: item.id })) : []);
  };

  const items = [
    {
      label: t("component.orderLocation.notes"),
      key: "notes",
      onClick: () => {
        secureLocalStorage.setItem("header", "notes");

        setHeader("notes");
      },
    },
    {
      label: t("component.orderLocation.asset"),
      key: "assetName",
      onClick: () => {
        secureLocalStorage.setItem("header", "assetName");

        setHeader("assetName");
      },
    },
    {
      label: t("component.orderLocation.team"),
      key: "fulfillmentTeamName",
      onClick: () => {
        secureLocalStorage.setItem("header", "fulfillmentTeamName");
        setHeader("fulfillmentTeamName");
      },
    },
  ];

  const header = (
    <div>
      <ListHeader template="2em 0.3fr 0.7fr 0.4fr 1fr 0.7fr">
        <div style={{ padding: "0.4em" }}>
          <Checkbox
            checked={selectAll && selected.length >= 1}
            onChange={handleSelectAllChange}
          />
        </div>
        <ListHeaderLabel
          value={t("component.orderLocation.id")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderLocation.name")}
          justifyContent="flex-start"
          sortable={true}
          sort={sort === "name" ? (order === "asc" ? "asc" : "desc") : null}
          onClick={() => {
            setSort("name");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`name:${order}`);
            }
          }}
        />
        <ListHeaderLabel
          value={t("component.orderLocation.date")}
          justifyContent="flex-start"
          sortable={true}
          sort={sort === "datetime" ? (order === "asc" ? "asc" : "desc") : null}
          onClick={() => {
            setSort("datetime");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`datetime:${order}`);
            }
          }}
        />
        <ListHeaderLabel
          value={
            type === "card"
              ? t("component.orderLocation.tags")
              : headerSelection
              ? items.find((item) => item.key === headerSelection)?.label ||
                t("component.orderLocation.notes")
              : t("component.orderLocation.notes")
          }
          items={items}
          changeHeader={type === "list" ? true : false}
          justifyContent="flex-start"
        />
        {type === "card" ? (
          <ListHeaderLabel value={""} justifyContent="flex-start" />
        ) : (
          <ListHeaderLabel
            sort={
              sort === "loadsCount" ? (order === "asc" ? "asc" : "desc") : null
            }
            onClick={() => {
              setSort("loadsCount");
              setOrder(order === "asc" ? "desc" : "asc");
              if (onChangeSortOrder) {
                onChangeSortOrder(`loadsCount:${order}`);
              }
            }}
            sortable={true}
            value={""}
            justifyContent="flex-start"
          />
        )}
      </ListHeader>
    </div>
  );

  return (
    <div>
      {pagination && data.length >= 3 ? (
        <div className={styles.paginationContainerUp}>
          <PaginationRavent
            current={page + 1}
            showSizeChanger={true}
            defaultCurrent={page + 1}
            total={total}
            onShowSizeChange={(page, size) => {
              if (onHitsPerPageChange) {
                onHitsPerPageChange(size);
              }
            }}
            onChange={(page) => {
              if (onPageChange) {
                onPageChange(page - 1);
              }
            }}
            pageSize={hitsPerPage}
          />
        </div>
      ) : null}
      <div className={styles.listContainer}>
        <List
          loading={loading}
          loadingElement={() => <OrderListSkeleton />}
          error={error}
          data={data}
          empty={!data.length}
          keyExtractor={(item) => item.id}
          renderHeader={() => header}
          renderItem={(item) =>
            type === "card" ? (
              <EventItemNormal
                setNew={setNew}
                loadingState={loadingState}
                updateFulfillmentName={updateFulfillmentName}
                updateAsset={updateAsset}
                teams={teams}
                onSaveLoad={onSaveLoad}
                updateFulfiller={updateFulfiller}
                onTagClick={onTagClick}
                data={item}
                errorState={errorState}
                fulfillmentNames={fulfillmentNames}
                setState={setState}
                assets={assets}
                updateTeam={updateTeam}
                key={item.id}
                onCheckboxChange={() => handleCheckboxChange(item.id)}
                isSelected={selected.some((loc) => loc.id === item.id)}
              />
            ) : type === "mini" ? (
              <EventMini
                loadingState={loadingState}
                headerSelection={headerSelection}
                setNew={setNew}
                updateFulfillmentName={updateFulfillmentName}
                updateAsset={updateAsset}
                teams={teams}
                onSaveLoad={onSaveLoad}
                updateFulfiller={updateFulfiller}
                onTagClick={onTagClick}
                data={item}
                errorState={errorState}
                fulfillmentNames={fulfillmentNames}
                setState={setState}
                assets={assets}
                updateTeam={updateTeam}
                key={item.id}
                onCheckboxChange={() => handleCheckboxChange(item.id)}
                isSelected={selected.some((loc) => loc.id === item.id)}
              />
            ) : (
              <EventItem
                loadingState={loadingState}
                headerSelection={headerSelection}
                setNew={setNew}
                updateFulfillmentName={updateFulfillmentName}
                updateAsset={updateAsset}
                teams={teams}
                onSaveLoad={onSaveLoad}
                updateFulfiller={updateFulfiller}
                onTagClick={onTagClick}
                data={item}
                errorState={errorState}
                fulfillmentNames={fulfillmentNames}
                setState={setState}
                assets={assets}
                updateTeam={updateTeam}
                key={item.id}
                onCheckboxChange={() => handleCheckboxChange(item.id)}
                isSelected={selected.some((loc) => loc.id === item.id)}
              />
            )
          }
        />
      </div>
    </div>
  );
};

export default EventList;
