import { stylesheet } from "typestyle";
import { black, ceruleanBlue } from "../../../utils/colors";

const style = stylesheet({
  button: {
    height: "2.6em",
    display: "flex",
    color: black,
    padding: "0 1em",
    marginBottom: ".5em",
    justifyContent: "center",
    alignItems: "center",
    transition: "background .3s cubic-bezier(.645,.045,.355,1)",
    border: 0,
    outline: 0,
    textDecoration: "none",
    $nest: {
      "&:hover": {
        fontWeight: 700,
      },
    },
  },
  buttonActive: {
    color: black,
    fontWeight: 700,
    borderLeft: `3px solid ${ceruleanBlue} !important`,
  },
  buttonLabel: {
    fontSize: 14,
    width: "100%",
  },
  iconContainer: {
    fontSize: "1rem",
    width: "1.85em",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    objectFit: "contain",
    marginRight: "0.1em",
  },
  icon: {
    display: "block",
    width: "100%",
  },
  angleContainer: {
    fontSize: "1rem",
    width: "1em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: ".2em",
  },
  angleIcon: {
    display: "block",
  },
});

export default style;
