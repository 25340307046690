export const InputColors = {
  labelColor: "#333",
  inputBorderColor: "#dce1e6",
  inputBorderColorError: "red",
  inputBackgroundColor: "#fafbfc",
  inputFocusBorderColor: "#40a9ff",
  inputBackgroundColorDisabled: "#fafbfc",
  inputFontColorDisabled: "#dce1e6",
};

export const black = "#333333";


export const redCancelled = 'rgba(255,0,10,255)';
export const red = 'rgb(255, 41, 41)';
export const orangeUnfulfilled = 'rgba(255,106,0,255)';
export const yellowUnpaid = 'rgba(255,180,45,255)';
export const backgroundError = 'rgba(255, 197, 197, 1)';
export const backgroundInfo = 'rgba(225, 248, 255, 1)';
export const backgroundSucc = 'rgba(231, 255, 207, 1)';
export const white = 'rgb(255,255,255)';
export const whiteishGray = 'rgb(240,240,240)';
export const veryLightBlue = 'rgb(233,240,253)';
export const blueBG = 'rgb(228, 244, 255)';
export const lightGray = 'rgb(220,225,230)';
export const placeholderGray = 'rgb(133, 141, 143)';
export const alplhaGray = 'rgba(133,141,143,0.5)';
export const gray = 'rgb(102, 102, 102)';
export const backgroundPurple = 'rgba(126,93,226,1)';
export const purpleExpired = 'rgba(119,34,213,255)';
export const purple = 'rgb(94,53,219)';
export const lightGreen = 'rgb(76, 217, 100)';
export const greenFulfilled = 'rgba(84,191,0,255)';
export const green = 'rgb(0, 163, 52)';
export const statusGreen = 'rgb(0, 167, 23)';
export const blue = 'rgb(36, 109, 241)';
export const lightBlue = 'rgb(31,160, 255)';
export const paleBlue = 'rgb(112, 167, 253)';
export const modalBackdropGrey = 'rgba(62,62,62,0.6)';
export const modalBackdropTransparent = 'rgba(62,62,62,0.1)';
export const modalBackdropTransparentLite = 'rgba(62,62,62,0.05)';
export declare const semiBlack = 'rgb(1, 15, 7)';
export const modalBackdropDark = 'rgba(0,0,0,0.2)';
export const modalBackdropDarker = 'rgba(0,0,0,0.8)';
export const lightBlack = 'rgb(51,51,51)';
export const blueBackground = 'rgba(233, 245, 255, 1)';
export const yellowBackground = 'rgba(250, 173, 21, 0.3)';

export const blueGradient1 = ['rgb(0, 76, 155)', 'rgb(60, 137, 251)'];
export const blueGradient2 = ['rgb(60, 137, 251)', 'rgb(160, 196, 254)'];
export const blueGradient3 = [
  'rgba(36, 109, 241, 1)',
  'rgba(29, 87, 193, 1)',
  'rgba(29, 87, 193, 1)',
];
export const greenGradient = ['rgba(0, 186, 25, 1)', 'rgba(0, 163, 52, 1)'];
