import React from "react";
import { Card } from "../Card/Card";
import styles from "./styles";

import { useTranslation } from "react-i18next";
import { Permission, Role } from "../../services/roles";
import { Radio, Switch } from "antd";
import { useHistory } from "react-router-dom";
import { ListRow } from "../List/ListRow";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { OrderListSkeleton } from "../ListSkeletons";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { List } from "../List";
import { ListLabel } from "../List/ListLabel";

interface Props {
  data: Role;
  group: string;
  setGroup: (group: string) => void;
  permissionsGroups: string[];
  permissions: Permission[];
  loading: boolean;
  error: string;
  creation: boolean;
  onChange: <P extends keyof Role>(prop: P, value: Role[P]) => void;
}

const PermissionForm: React.FC<Props> = ({
  data,
  group,
  setGroup,
  permissionsGroups,
  permissions,
  loading,
  error,
  onChange,
  creation,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <div className={styles.container}>
        <FlexContainer justifyContent="flex-start">
          <Radio.Group
            value={group}
            onChange={(value) => setGroup(value.target.value)}
            style={{ marginBottom: 16 }}
          >
            {permissionsGroups.map((group) => (
              <Radio.Button
                value={group}
                onClick={() =>
                  history.push(
                    creation
                      ? `/organization/roles/new/permissions?group=${group}`
                      : `/organization/roles/permissions/${data.id}?group=${group}`
                  )
                }
              >
                {group}
              </Radio.Button>
            ))}
          </Radio.Group>
        </FlexContainer>
        <Card>
          <div className={styles.wrapperCard}>
            <ContainerTitle size="small" padding="1em 0 1em 0" label={group} />

            <List
              loading={loading}
              loadingElement={() => <OrderListSkeleton />}
              error={error}
              data={permissions}
              empty={!permissions.length}
              keyExtractor={(item) => item.id}
              renderHeader={() => (
                <ListHeader template="1.5fr 1fr 1.8fr 1fr">
                  <ListHeaderLabel
                    value={t("component.roleDetailComponent.name")}
                    justifyContent="flex-start"
                  />
                  <ListHeaderLabel
                    value={t("component.roleDetailComponent.group")}
                    justifyContent="flex-start"
                  />
                  <ListHeaderLabel
                    value={t("component.roleDetailComponent.description")}
                    justifyContent="flex-start"
                  />
                  <ListHeaderLabel value={""} justifyContent="flex-start" />
                </ListHeader>
              )}
              renderItem={(item) => (
                <ListRow template="1.5fr 1fr 1.8fr 1fr">
                  <ListLabel value={item.name} textAlign="left" />
                  <ListLabel value={item.group} textAlign="left" />
                  <ListLabel value={item.description} textAlign="left" />
                  <div className={styles.center}>
                    <Switch
                      checked={data.permissions.includes(item.id)}
                      onChange={(checked) => {
                        if (checked) {
                          onChange("permissions", [
                            ...data.permissions,
                            item.id,
                          ]);
                        } else {
                          onChange(
                            "permissions",
                            data.permissions.filter((id) => id !== item.id)
                          );
                        }
                      }}
                    />
                  </div>
                </ListRow>
              )}
            />
          </div>
        </Card>
      </div>
    </>
  );
};

export default PermissionForm;
