import React, { useState } from "react";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { ContainerTitle } from "../ContainerTitle";

import styles from "./styles";
import FulfillerList from "../FulfillerList";
import { Fulfiller, Team } from "@innomius/ravent-typescript-types";
import DiversForm from "../FulfillersForm";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { DoubleButton } from "../DoubleButton";
import { SideMenu } from "../SideMenu/SideMenu";
import CSVFileUploader from "../CVSFileUploader";
import { convertKeys } from "../../utils/locations";
import APIError from "../../utils/APIError";
import { message } from "antd";
import inviteIcon from "../../assets/invite.svg";
import {
  createMultipleFulfillers,
  defaultFulfillers,
} from "../../services/entities";
import { Role } from "../../services/roles";
import { Card } from "../Card/Card";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { EmptyList } from "../List/EmptyList";
import { Filter } from "../../services/orders";
import InviteModal from "../InviteModal";
import MissingModal from "../MissingModal";

interface Props {
  onCreate: (data: Fulfiller) => void;
  onCancel: () => void;
  error: string;
  loading: string;
  page: number;
  hitsPerPage: number;
  total: number;
  entityId: string;
  divers: Fulfiller[];
  open: string;
  setOpen: (name: string) => void;
  creationError: string;
  diver: Fulfiller;
  search: string;
  setSearch: (value: string) => void;
  stateMap: Record<string, [Filter, (filter: Filter) => void]>;
  path: string;
  onDetails: (id: string) => void;
  onClickAddAexistingUser: () => void;
  onChangeUserFromTeam: (id: string, teamId: string) => void;
  onDeleteUser: (id: string) => void;
  openAddUser: boolean;
  load: () => void;
  roles: Role[];
  teams: Team[];
  onPageChange: (page: number) => void;
  onHitsPerPageChange: (hitsPerPage: number) => void;
  onChangeSortOrder?: (value: string) => void;
  totalFulfillers: number;
  onInviteUsers: (users: any[]) => void;
  resetPassword: (username: string) => void;
  onSave: (data: Fulfiller) => Promise<void>;
}

const EntityFulfillers: React.FC<Props> = ({
  error,
  loading,
  page,
  teams,
  hitsPerPage,
  total,
  divers,
  entityId,
  onHitsPerPageChange,
  onPageChange,
  onChangeSortOrder,
  onCreate,
  setOpen,
  onCancel,
  onSave,
  path,
  onDetails,
  onClickAddAexistingUser,
  onChangeUserFromTeam,
  load,
  totalFulfillers,
  onDeleteUser,
  roles,
  onInviteUsers,
  resetPassword,
}) => {
  const [modal, setModal] = useState("");

  const [affected, setAffected] = useState<
    {
      id: string;
      name: string;
      loginWithPhone: boolean;
      phone?: string;
      email?: string;
    }[]
  >([]);

  const [loadingImport, setLoadingImport] = useState("");
  const [fulfiller, setFulfiller] = useState(defaultFulfillers);
  const onChange = <P extends keyof Fulfiller>(prop: P, value: Fulfiller[P]) =>
    setFulfiller({ ...fulfiller, [prop]: value });
  const [edit, setEdit] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<
    {
      id: string;
      name: string;
      loginWithPhone: boolean;
      phone?: string;
      email?: string;
    }[]
  >([]);

  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();

  return (
    <div className={styles.container}>
      <Card>
        <MissingModal
          affected={affected}
          total={selectedUsers?.length}
          open={modal === "missing"}
          onCancel={() => setModal("")}
        />
        <InviteModal
          amount={selectedUsers?.length.toString()}
          onCancel={() => setModal("")}
          onInvite={() => {
            const invalidUsers = selectedUsers.filter((user) =>
              user.loginWithPhone ? !user.phone : !user.email
            );
            if (invalidUsers.length > 0) {
              setAffected(invalidUsers);
              setModal("missing");
            } else {
              onInviteUsers(selectedUsers);
            }
          }}
          open={modal === "invite"}
        />
        <CSVFileUploader
          loading={loadingImport === "multiple"}
          onImport={async (data) => {
            const imported = convertKeys(data);
            try {
              setLoadingImport("multiple");
              await createMultipleFulfillers(imported, entityId);
              setLoadingImport("");
              setModal("");
              load();
            } catch (err) {
              setLoadingImport("");
              if (err instanceof APIError) {
                message.error(err.message);
              }
            }
          }}
          open={modal === "multiple"}
          onClose={() => setModal("")}
        />
        <div className={styles.wrapperCard}>
          <div>
            <SideMenu
              width="60%"
              open={location.pathname === `${path}/new`}
              onClose={() => {
                onCancel();
                setFulfiller(defaultFulfillers);
              }}
            >
              <DiversForm
                onChange={onChange}
                fulfiller={fulfiller}
                roles={roles}
                onSave={async (data: Fulfiller) => {
                  await onCreate(data);
                  setFulfiller(defaultFulfillers);
                }}
                onCancel={() => {
                  onCancel();
                  setFulfiller(defaultFulfillers);
                }}
                loading={loading === "create-diver"}
              />
            </SideMenu>

            <FlexContainer
              padding="0 0 1em 0"
              alignItems="center"
              justifyContent="space-between"
            >
              <ContainerTitle
                size="small"
                label={t("component.entityDivers.users")}
                count={totalFulfillers.toString()}
              />

              <ButtonsContainer>
                <DoubleButton
                  theme="white"
                  options={[
                    {
                      label: t("component.entityDivers.addMultipleDivers"),
                      click: () => setModal("multiple"),
                    },
                    {
                      label: t("component.entityDivers.addExistingUser"),
                      click: () => onClickAddAexistingUser(),
                    },
                  ]}
                  containerClassname={styles.buttonsContainer}
                  label={t("component.entityDivers.addUser")}
                />
                <Button
                  label={t("component.entityDivers.addFulfiller")}
                  onClick={() => {
                    setOpen("add");
                    history.push(`${path}/new`);
                  }}
                />
              </ButtonsContainer>
            </FlexContainer>

            {selectedUsers.length > 0 ? (
              <ButtonsContainer padding="0 0 1em 0" justifyContent="flex-start">
                <Button
                  theme="white"
                  size="medium"
                  onClick={() => {
                    setModal("invite");
                  }}
                  label={t("container.users.invite")}
                  icon={inviteIcon}
                />
                {/* <Button
                  theme="white"
                  size="medium"
                  label={t("container.users.reset")}
                  icon={resetIcon}
                /> */}
              </ButtonsContainer>
            ) : null}
            {divers.length < 1 && !error && loading !== "fulfillers" ? (
              <EmptyList
                instructions={""}
                text={t("component.entityDivers.noRegister")}
              />
            ) : (
              <FulfillerList
                edit={edit}
                resetPassword={resetPassword}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                onHitsPerPageChange={onHitsPerPageChange}
                onChangeSortOrder={onChangeSortOrder}
                onPageChange={onPageChange}
                setEdit={setEdit}
                onCancel={() => setEdit("")}
                sortable
                pagination
                onSave={onSave}
                loadingFulfiller={loading}
                roles={roles}
                teams={teams}
                onChangeUserFromTeam={onChangeUserFromTeam}
                path={path}
                onDetails={(id) => {
                  onDetails(id);
                }}
                onDeleteUser={onDeleteUser}
                page={page}
                total={total}
                hitsPerPage={hitsPerPage}
                entityId={entityId}
                data={divers}
                error={error}
                loading={loading === "fulfillers"}
              />
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default EntityFulfillers;
