import {
  AddressType,
  Entity,
  OrganizationStatus,
  OrganizationType,
  Fulfiller,
  Team,
} from "@innomius/ravent-typescript-types";
import get from "lodash/get";
import CustomError from "../utils/CustomError";
import fetch from "../utils/fetch";
import { getAccessToken } from "./auth";
import { isNil, omitBy } from "lodash";
import {
  Category,
  FulfillerOverview,
  MaskingTemplates,
  TeamOverview,
} from "../utils/types";
import { Filter, TextSearchList } from "./orders";
import { dateFilters } from "../utils/times";

export const defaultAddress: AddressType = {
  line1: "",
  type: "w3w",
  line2: "",
  country: "",
  city: "",
  state: "",
  zipCode: "",
  w3w: "",
  reference: "",
};

export const defaultFulfillers: Fulfiller = {
  id: "",
  firstName: "",
  adminRole: false,
  disabled: false,
  externalId: "",
  lastName: "",
  email: "",
  phone: "",
  created: "",
  username: "",
  active: true,
  roles: [],
  colorizedTags: [],
  tags: [],
  emailVerified: false,
  loginWithPhone: false,
};

export const defaultEntity: CreateTeamParams = {
  name: "",
  data: {},
  enabled: true,
  internal: false,
};

export interface EntityRequest {
  id: string;
  legalName: string;
  status: OrganizationStatus;
  address: AddressType;
  email: string;
  phone: string;
  Fulfillers: Fulfiller[];
  type: OrganizationType;
  logo?: string;
  deliveredButton?: boolean;
  mayChatWithCustomers: boolean;
  mayCloseOrdersManually: boolean;
  maySeeOrderDetails: boolean;
}

export function parseTeam(data: unknown): Team {
  return {
    id: get(data, "_id", ""),
    created: get(data, "createdAt", ""),
    data: get(data, "data", {}),
    internal: get(data, "internal", false),
    userCounts: {
      doc_count: get(data, "userCounts.doc_count", 0),
    },

    updated: get(data, "updatedAt", ""),
    deleted: get(data, "deleted", false),
    enabled: get(data, "enabled", false),
    name: get(data, "name", ""),
    organization_id: get(data, "organization_id", ""),
    venue_id: get(data, "venue_id", ""),
    maskingTemplates: {
      orders: {
        type: get(data, "maskingTemplates.orders.type", ""),
        fields: get(data, "maskingTemplates.orders.fields", []),
      },
      invoices: {
        type: get(data, "maskingTemplates.invoices.type", ""),
        fields: get(data, "maskingTemplates.invoices.fields", []),
      },
      orderlines: {
        type: get(data, "maskingTemplates.orderlines.type", ""),
        fields: get(data, "maskingTemplates.orderlines.fields", []),
      },
    },
    roles: get(data, "roles", []),
  };
}

export function parseEntity(data: unknown): Entity {
  return {
    id: get(data, "_id", ""),
    created: get(data, "createdAt", ""),
    updated: get(data, "updatedAt", ""),
    deleted: get(data, "deleted", false),
    type: get(data, "type", "" as OrganizationType),
    enabled: get(data, "enabled", false),
    phone: get(data, "phone", ""),
    mayChatWithCustomers: get(data, "mayChatWithCustomers", false),
    mayCloseOrdersManually: get(data, "mayCloseOrdersManually", false),
    maySeeOrderDetails: get(data, "maySeeOrderDetails", false),
    legalName: get(data, "name", ""),
    representatives: get(data, "Fulfillers", []),
    email: get(data, "email", ""),
    venueId: get(data, "venue_id", ""),
    locationId: get(data, "location_id", ""),
    fulfillersQuantity: get(data, "fulfillersQuantity", ""),
    address: {
      id: get(data, "address.id", ""),
      coordinates: {
        latitude: get(data, "address.coordinates.latitude", ""),
        longitude: get(data, "address.coordinates.longitude", ""),
      },
      chronologicalIndex: get(data, "address.chronologicalIndex", 0),

      externalId: get(data, "address.externalId", ""),
      name: get(data, "address.name", ""),
      type: get(data, "address.type", ""),
      line1: get(data, "address.line1", ""),
      w3w: get(data, "address.w3w", ""),
      reference: get(data, "address.reference", ""),
      line2: get(data, "address.line2", ""),
      zipCode: get(data, "address.zipCode", ""),
      city: get(data, "address.city", ""),
      state: get(data, "address.state", ""),
      country: get(data, "address.country", ""),
    },
  };
}

export function parseUser(data: unknown): Fulfiller {
  return {
    adminRole: get(data, "admin", false),
    created: get(data, "createdAt", ""),
    id: get(data, "_id", ""),
    firstName: get(data, "firstName", ""),
    lastName: get(data, "lastName", ""),
    email: get(data, "email", ""),
    phone: get(data, "phone", ""),
    roles: get(data, "roles", []),
    tags: get(data, "tags", []),
    colorizedTags: get(data, "colorizedTags", []),
    externalId: get(data, "externalId", ""),
    disabled: get(data, "disabled", false),
    username: get(data, "username", ""),
    active: get(data, "active", false),
    emailVerified: get(data, "emailVerified", false),
    loginWithPhone: get(data, "loginWithPhone", false),
  };
}

export interface TeamParams {
  page?: number;
  records_per_page?: number;
  venue_id?: string;
}

function mapSortsFullText(data: TeamParams) {
  return omitBy(
    {
      page: data.page,
      records_per_page: data.records_per_page,
      venue_id: data.venue_id || null,
    },
    isNil
  );
}

export interface TeamSearchResponse {
  data: Team[];
  page: number;
  total: number;
}

export async function teamList(
  params: TeamParams
): Promise<TeamSearchResponse> {
  const token = await getAccessToken();

  const res = await fetch(
    "/teams",
    token,
    "GET",
    null,
    mapSortsFullText(params)
  );
  return {
    data: res.body ? res.body.hits.map(parseTeam) : [],
    page: get(res.body, "page", 0),
    total: get(res.body, "count", 0),
  };
}

export interface CreateTeamParams {
  venue_id?: string;
  internal?: boolean;
  data: Record<string, any>;
  name: string;
  enabled: boolean;
  roles?: string[];
  maskingTemplates?: {
    orders?: {
      type: string;
      fields: string[];
    };
    orderlines?: {
      type: string;
      fields: string[];
    };
    invoices?: {
      type: string;
      fields: string[];
    };
  };
}

export const defaultMaskingTemaplte: MaskingTemplates = {
  orders: {
    type: "",
    fields: [],
  },
  orderlines: {
    type: "",
    fields: [],
  },
  invoices: {
    type: "",
    fields: [],
  },
};

export async function createTeam(
  data: CreateTeamParams,
  maskingTemplate: MaskingTemplates
): Promise<Team> {
  const body = {
    name: data.name,
    data: data.data ? JSON.parse(JSON.stringify(data.data)) : {},
    enabled: data.enabled,
    internal: data.internal || false,
    roles: data.roles || [],
    maskingTemplates: {
      orders: {
        type: maskingTemplate.orders.type || "excludes",
        fields: maskingTemplate.orders.fields,
      },
      invoices: {
        type: maskingTemplate.invoices.type || "excludes",
        fields: maskingTemplate.invoices.fields,
      },
      orderlines: {
        type: maskingTemplate.orderlines.type || "excludes",
        fields: maskingTemplate.orderlines.fields,
      },
    },
  };
  const token = await getAccessToken();

  const res = await fetch("/teams", token, "POST", body);
  return res.body;
}

export interface FulfillerListResponse {
  data: Fulfiller[];
  page: number;
  total: number;
}

export interface UserListParams {
  page?: number;
  records_per_page?: number;
  type?: string;
  venue_id?: string;
  sort?: string;
  fulfillmentTeamId?: string;
}

function mapSorts(data: UserListParams) {
  return omitBy(
    {
      page: data.page,
      records_per_page: data.records_per_page,
      type: data.type || null,
      fulfillmentTeamId: data.fulfillmentTeamId || null,
      sort: data.sort || "createdAt:desc",
      venue_id: data.venue_id || null,
    },
    isNil
  );
}

export async function fulfillerList(
  params: UserListParams
): Promise<FulfillerListResponse> {
  const token = await getAccessToken();

  const res = await fetch("/users", token, "GET", null, mapSorts(params));
  return {
    data: res.body ? res.body.hits.map(parseUser) : [],
    page: get(res.body, "page", 0),
    total: get(res.body, "count", 0),
  };
}

export async function createFulfiller(
  data: Fulfiller,
  orgId: string
): Promise<Fulfiller> {
  let body;

  let phone = data.phone;
  if (phone && !phone.startsWith("00") && !phone.startsWith("+")) {
    phone = `+${phone}`;
  }
  if (data.loginWithPhone)
    body = {
      firstName: data.firstName,
      lastName: data.lastName,
      phone: phone || null,
      roles: data.roles,
      admin: false,
      fulfillmentTeamId: orgId,
      type: "fulfiller",
      loginWithPhone: data.loginWithPhone,
      disabled: data.disabled,
      externalId: data.externalId,
      tags: data.tags,
    };
  if (!data.loginWithPhone) {
    body = {
      externalId: data.externalId,
      disabled: data.disabled,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: phone || null,
      admin: false,
      email: data.email ? data.email : null,
      roles: data.roles,
      fulfillmentTeamId: orgId,
      type: "fulfiller",
      loginWithPhone: data.loginWithPhone,
      tags: data.tags,
    };
  }

  const token = await getAccessToken();

  const res = await fetch("/users", token, "POST", body);
  return res.body;
}

export async function editDiver(
  data: Fulfiller,
  orgId: string
): Promise<Fulfiller> {
  const body = {
    firstName: data.firstName,
    lastName: data.lastName,
    disabled: data.disabled,
    phone: data.phone,
    email: data.email ? data.email : null,
    roles: data.roles,
    type: "fulfiller",
    tags: data.tags,
    externalId: data.externalId,
    fulfillmentTeamId: orgId,
  };
  const token = await getAccessToken();

  const res = await fetch(`/users/${data.id}`, token, "PATCH", body);
  return res.body;
}

export async function readOrg(id: string): Promise<Entity> {
  const token = await getAccessToken();
  const res = await fetch(`/organizations/${id}`, token);
  const data = parseEntity(res.body);
  return data;
}

export async function readTeam(id: string): Promise<Team> {
  const token = await getAccessToken();
  const res = await fetch(`/teams/${id}`, token);
  const data = parseTeam(res.body);
  return data;
}

export async function updateOrg(
  id: string,
  update: Record<string, any>
): Promise<Entity> {
  const body = update;

  const token = await getAccessToken();

  const res = await fetch(`/organizations/${id}`, token, "PATCH", body);
  return res.body;
}

export async function getFulfiller(id: string): Promise<Fulfiller> {
  const token = await getAccessToken();
  const res = await fetch(`/users/${id}`, token);
  const data = parseUser(res.body);
  return data;
}

export async function deleteTeam(id: string): Promise<void> {
  const token = await getAccessToken();
  await fetch(`/teams/${id}`, token, "DELETE");
  return;
}

export async function updateTeam(
  data: Team,
  maskingTemplate: MaskingTemplates
): Promise<Team> {
  const body = {
    name: data.name,
    enabled: data.enabled,
    internal: data.internal,
    data: data.data ? JSON.parse(JSON.stringify(data.data)) : {},
    roles: data.roles || [],
    maskingTemplates: {
      orders: {
        type: maskingTemplate.orders.type || "excludes",
        fields: maskingTemplate.orders.fields,
      },
      invoices: {
        type: maskingTemplate.invoices.type || "excludes",
        fields: maskingTemplate.invoices.fields,
      },
      orderlines: {
        type: maskingTemplate.orderlines.type || "excludes",
        fields: maskingTemplate.orderlines.fields,
      },
    },
  };
  const token = await getAccessToken();

  const res = await fetch(`/teams/${data.id}`, token, "PATCH", body);
  return res.body;
}

export function checkOrg(input: CreateTeamParams) {
  const error = new CustomError("Error saving Org Form", "formError");
  if (!input.name) {
    error.setError("org-name", "Name is required");
  }

  if (!error.length) {
    return true;
  }
  throw error;
}

export interface FulfillerData {
  datetime: string;
  state: string;
  id: string;
}

export function parseFulfillerData(data: unknown): FulfillerData {
  return {
    id: get(data, "_id", ""),
    datetime: get(data, "datetime", ""),
    state: get(data, "state", ""),
  };
}

export interface ScheduleResponse {
  count: number;
  page: number;
  hits: FulfillerData[];
}

export interface ScheduleParamsFulfiller {
  timezone?: string;
  venue_id?: string;
  from: string;
  to: string;
  columns: string;
  page?: number;
  records_per_page?: number;
  fulfillerId: string;
}

interface FiltersEvents {
  fulfillerId: Filter | null;
}

function mapParams(data: ScheduleParamsFulfiller) {
  const filters: any = {
    fulfillerId: data.fulfillerId
      ? {
          include: "All",
          items: [
            {
              key: data.fulfillerId,
              include: true,
            },
          ],
        }
      : null,
    datetime:
      data.from && data.to
        ? {
            include: "All",
            name: "datetime",
            items: [
              {
                key: "datetime",
                from: dateFilters(data.from, "00:00:00.000"),
                to: dateFilters(data.to, "23:59:00.000"),
                include: true,
              },
            ],
          }
        : null,
  };

  const filteredFilters: Partial<FiltersEvents> = {};
  for (const key in filters) {
    if (filters[key as keyof FiltersEvents] !== null) {
      filteredFilters[key as keyof FiltersEvents] =
        filters[key as keyof FiltersEvents]!;
    }
  }

  const jsonString = JSON.stringify(filteredFilters);
  return omitBy(
    {
      page: 0,
      // venue_id: data.venue_id || null,
      records_per_page: data.records_per_page || 100,
      filters: jsonString === "{}" ? "{}" : jsonString,
      // sort: "_score:desc,datetime:desc",
      columns: data.columns,
    },
    isNil
  );
}

export async function fulfillerSchedule(
  params: ScheduleParamsFulfiller
): Promise<ScheduleResponse> {
  const token = await getAccessToken();
  const res = await fetch(
    "/events/fulfillerschedule",
    token,
    "GET",
    null,
    mapParams(params)
  );
  return {
    hits: res.body ? res.body.hits.map(parseFulfillerData) : [],
    page: get(res.body, "page", 0),
    count: get(res.body, "count", 0),
  };
}

export interface OverviewParams {
  rowsField: string;
  rowsFieldId: string;
  columnsField: string;
  venue_id?: string;
  timezone?: string;
  page?: number;
  from?: string;
  to?: string;
  records_per_page?: number;
  fulfillmentTeamId?: string;
  sort?: string;
}

function mapOverview(data: OverviewParams) {
  const filters: any = {
    fulfillmentTeamId: data.fulfillmentTeamId
      ? {
          include: "All",
          items: [
            {
              key: data.fulfillmentTeamId,
              include: true,
            },
          ],
        }
      : null,
    datetime:
      data.from && data.to
        ? {
            include: "All",
            name: "datetime",
            items: [
              {
                key: "datetime",
                from: dateFilters(data.from, "00:00:00.000"),
                to: dateFilters(data.to, "23:59:00.000"),
                include: true,
              },
            ],
          }
        : null,
  };

  const filteredFilters: Partial<FiltersEvents> = {};
  for (const key in filters) {
    if (filters[key as keyof FiltersEvents] !== null) {
      filteredFilters[key as keyof FiltersEvents] =
        filters[key as keyof FiltersEvents]!;
    }
  }

  const jsonString = JSON.stringify(filteredFilters);
  return omitBy(
    {
      filters: jsonString === "{}" ? "{}" : jsonString,
      timezone: data.timezone || null,
      rowsField: data.rowsField,
      rowsFieldID: data.rowsFieldId,
      columnsField: data.columnsField,
      sort: data.sort || "fulfillerName:asc",
      expectedColumns: JSON.stringify({
        Running: {
          columns: ["running"],
        },
        Failed: {
          columns: ["failed"],
        },
        Pending: {
          columns: ["pending"],
        },
        Completed: {
          columns: ["completed"],
        },
        Total: {
          columns: ["pending", "completed", "running", "failed"],
          operator: "+",
        },
      }),
    },

    isNil
  );
}

export async function getTeamsOverviews(
  params: OverviewParams
): Promise<TeamOverview[]> {
  const token = await getAccessToken();
  const res = await fetch(
    "/events/table2d",
    token,
    "GET",
    null,
    mapOverview(params)
  );
  return res.body.map((i: TeamOverview) => parseTeamOverview(i));
}

export function parseTeamOverview(data: unknown): TeamOverview {
  return {
    fulfillmenTeamId: get(data, "fulfillmentTeamId", ""),
    completed: get(data, "Completed", 0),
    pending: get(data, "Pending", 0),
    total: get(data, "Total", 0),
    running: get(data, "Running", 0),
    failed: get(data, "Failed", 0),
    fulfillmentTeamName: get(data, "fulfillmentTeamName", ""),
  };
}

export function parseFulfillerOverview(data: unknown): FulfillerOverview {
  return {
    completed: get(data, "Completed", 0),
    pending: get(data, "Pending", 0),
    total: get(data, "Total", 0),
    failed: get(data, "Failed", 0),
    running: get(data, "Running", 0),
    fulfillerId: get(data, "fulfillerId", ""),
    fulfillerUserName: get(data, "fulfillerName", ""),
  };
}

export async function getFulfillersOverview(
  params: OverviewParams
): Promise<FulfillerOverview[]> {
  const token = await getAccessToken();
  const res = await fetch(
    "/events/table2d",
    token,
    "GET",
    null,
    mapOverview(params)
  );
  return res.body.map((i: FulfillerOverview) => parseFulfillerOverview(i));
}

export async function createMultipleFulfillers(data: any[], teamId: string) {
  for (const fulfillerData of data) {
    const body: {
      firstName: any;
      tags: any;
      lastName: any;
      roles: any[];
      enabled: boolean;
      externalId: any;
      admin: boolean;
      fulfillmentTeamId: string;
      loginWithPhone: boolean;
      type: string;
      email?: string;
      phone?: string;
    } = {
      firstName: fulfillerData.firstName,
      tags: fulfillerData.tags?.split(",").map((item: string) => item.trim()),
      lastName: fulfillerData.lastName,
      roles: [fulfillerData.roles],
      enabled: fulfillerData.enabled === "1" ? true : false,
      externalId: fulfillerData.externalId,
      admin: fulfillerData.admin === "1" ? true : false,
      fulfillmentTeamId: teamId,
      loginWithPhone: fulfillerData.loginWithPhone === "1" ? true : false,
      type: "fulfiller",
    };

    if (fulfillerData.email) {
      body.email = fulfillerData.email;
    }

    if (fulfillerData.phone) {
      body.phone = fulfillerData.phone;
    }

    const token = await getAccessToken();
    await fetch("/users", token, "POST", body)
      .then((res) => parseUser(res.body))
      .catch((error) => {
        console.error(`Error creating location: ${error}`);
        return null;
      });
  }

  return;
}

export interface TeamListParamsFT {
  page?: number | string;
  records_per_page?: number;
  search?: string;
  sort?: string;
}

interface Filters {}

function mapSortsFullTextSearch(data: TeamListParamsFT) {
  const filters: Filters = {};

  const filteredFilters: Partial<Filters> = {};
  for (const key in filters) {
    if (filters[key as keyof Filters] !== null) {
      filteredFilters[key as keyof Filters] = filters[key as keyof Filters]!;
    }
  }

  const jsonString = JSON.stringify(filteredFilters);

  return omitBy(
    {
      records_per_page: data.records_per_page,
      page: data.page,
      search: data.search || null,
      sort: data.sort || "_score:desc,createdAt:desc",
      filters: jsonString === "{}" ? "{}" : jsonString,
    },
    isNil
  );
}

export async function teamsFullTextSearch(
  params: TeamListParamsFT
): Promise<TextSearchList> {
  const token = await getAccessToken();
  const res = await fetch(
    "/events/textSearchFulfillmentTeams",
    token,
    "GET",
    null,
    mapSortsFullTextSearch(params)
  );
  return {
    hits: res.body ? res.body.hits.map(parseTeam) : [],
    page: get(res.body, "page", 0),
    count: get(res.body, "count", 0),
    facets: res.body.facets ? res.body.facets : {},
  };
}

export function parseCategory(data: unknown): Category {
  return {
    field: get(data, "field", ""),
    name: get(data, "name", ""),
    color: get(data, "color", ""),
    chronologicalIndex: get(data, "chronologicalIndex", 0),
  };
}

export interface OrgSettingsResponse {
  facetSettings: {
    [key: string]: Category[];
    orders: Category[];
    fulfillmentEvents: Category[];
    assets: Category[];
    users: Category[];
    locations: Category[];
  };
  organization_id: string;
  id: string;
}

export async function getOrgSettings(
  orgId: string
): Promise<OrgSettingsResponse> {
  const token = await getAccessToken();
  const res = await fetch(`/organizations/${orgId}/settings`, token, "GET");
  return {
    facetSettings: {
      orders: res.body.facetSettings.orders
        ? res.body.facetSettings.orders.map(parseCategory)
        : [],
      fulfillmentEvents: res.body.facetSettings.fulfillmentEvents
        ? res.body.facetSettings.fulfillmentEvents.map(parseCategory)
        : [],
      assets: res.body.facetSettings.assets
        ? res.body.facetSettings.assets.map(parseCategory)
        : [],
      users: res.body.facetSettings.users
        ? res.body.facetSettings.users.map(parseCategory)
        : [],
      locations: res.body.facetSettings.locations
        ? res.body.facetSettings.locations.map(parseCategory)
        : [],
    },
    organization_id: get(res.body, "organization_id", ""),
    id: get(res.body, "_id", ""),
  };
}

export async function updateOrgSettings(
  orgId: string,
  settings: OrgSettingsResponse
): Promise<OrgSettingsResponse> {
  const body = {
    orders: settings.facetSettings.orders,
    fulfillmentEvents: settings.facetSettings.fulfillmentEvents,
    assets: settings.facetSettings.assets,
    users: settings.facetSettings.users,
    locations: settings.facetSettings.locations,
  };
  const token = await getAccessToken();

  const res = await fetch(
    `/organizations/${orgId}/settings`,
    token,
    "PATCH",
    body
  );
  return res.body;
}
