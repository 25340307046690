const translation = {
  en: {
    downloadReceipt: "Download Receipt",
  },
  es: {
    downloadReceipt: "Descargar Recibo",
  },
  de: {},
};

export default translation;
