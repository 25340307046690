import React from "react";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/deleteBlack.svg";

import styles from "./styles";
// import { useTranslation } from "react-i18next";
import TicketingDefSide from "../PassDefSide";
import { PassDefinition } from "../../utils/types";
import { useHistory } from "react-router-dom";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";

interface Props {
  ticketingDef: PassDefinition;
  loadingEditT: string;
  id: string;
  onEditTicketingDef: (data: PassDefinition) => void;
  path: string;
  onDeleteTicketing: (id: string) => void;
  open: boolean;
  setOpen: (value: boolean) => void;
}

const Item: React.FC<Props> = ({
  ticketingDef,
  loadingEditT,
  onDeleteTicketing,
  onEditTicketingDef,
  path,
  open,
  setOpen,
}) => {
  // const { t } = useTranslation();

  const history = useHistory();

  return (
    <>
      <TicketingDefSide
        id={ticketingDef.id}
        loading={loadingEditT}
        openModal={open}
        ticketingDef={ticketingDef}
        onDeleteTicketing={onDeleteTicketing}
        onEditTicketingDef={onEditTicketingDef}
        onClose={() => {
          history.push(`${path}`);
          setOpen(false);
        }}
      />
      <ListRow
        onClick={() => {
          setOpen(true);
          history.push(`${path}/${ticketingDef.id}`);
        }}
        template="1fr 1.5fr 0.2fr"
        hover={true}
      >
        <ListLabel
          fontFormat="bold"
          value={ticketingDef.name}
          textAlign="left"
        />
        <ListLabel value={ticketingDef.description || '-'} textAlign="left" />

        <div className={styles.space}>
          <EditIcon
            className={styles.icon}
            onClick={() => {
              setOpen(true);
              history.push(`/passes/${ticketingDef.id}`);
            }}
          />
          <DeleteIcon
            className={styles.icon}
            onClick={() => {
              history.push(`/passes/${ticketingDef.id}`);
            }}
          />
        </div>
      </ListRow>
    </>
  );
};

export default Item;
