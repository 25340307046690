import * as React from "react";
import { black } from "../../constants/colors";
import { classes, style } from "typestyle";

export interface ListLabelProps {
  value: string;
  textAlign?: "center" | "left" | "right";
  justifyContent?: "center" | "flex-start" | "flex-end";
  fontFormat?: "bold" | "normal" | "medium" | "semiBold";
  onClick?: (e?: Event) => void;
}

const rowColumn = style({
  padding: "1em",
  color: black,
  display: "flex",
  alignItems: "center",
});

const rowColumnLabel = style({
  fontSize: "1em",
  fontWeight: 500,
});

const bold = style({ fontWeight: 700 });
const semiBold = style({ fontWeight: 600 });
const medium = style({ fontWeight: 500 });

export const ListLabel: React.FC<ListLabelProps> = ({
  textAlign,
  value,
  fontFormat,
  justifyContent,
  onClick,
}) => (
  <div
    onClick={() => {
      onClick?.();
    }}
    className={rowColumn}
    style={{ textAlign, justifyContent }}
  >
    <span
      className={classes(rowColumnLabel, {
        [bold]: fontFormat === "bold",
        [semiBold]: fontFormat === "semiBold",
        [medium]: fontFormat === "medium",
      })}
    >
      {value}
    </span>
  </div>
);
