import React, { useState } from "react";

import { Offer } from "@innomius/ravent-typescript-types";
import deleteIcon from "../../assets/officialMedia/default-delete.svg";
import editIcon from "../../assets/officialMedia/default-edit.svg";
import styles from "./styles";
import { formatDate } from "../../utils/times";
import { Popover } from "antd";
import DeletePopUp from "../DeletePopUp";
import { useTranslation } from "react-i18next";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { InputSwitch } from "../Inputs/InputSwitch";

interface Props {
  data: Offer;
  onEdit: (id: string) => void;
}

const FrequencyList: React.FC<Props> = ({ data, onEdit }) => {
  const [deleteFrequency, setDelete] = useState("");

  const { t } = useTranslation();

  return (
    <div>
      <ListHeader template="1fr 2fr 1fr 1fr">
        <ListHeaderLabel
          value={t("component.offerVariantDetail.freqName")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.offerVariantDetail.freqDateRange")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.offerVariantDetail.status")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.offerVariantDetail.actions")}
          justifyContent="center"
        />
      </ListHeader>
      {data.frequencies.map((item, index) => (
        <ListRow key={`${item.name}${index}`} hover template="1fr 2fr 1fr 1fr">
          <ListLabel value={item.name} />
          <ListLabel
            value={`${formatDate(item.startDate, "SHORT")} to ${formatDate(
              item.endDate,
              "SHORT"
            )}`}
            justifyContent="flex-start"
          />
          <FlexContainer justifyContent="flex-start">
            <InputSwitch value={item.enabled} />
            {item.enabled ? (
              <p className={styles.green}>
                {t("component.offerVariantDetail.active")}
              </p>
            ) : (
              <p className={styles.gray}>
                {t("component.offerVariantDetail.inactive")}
              </p>
            )}
          </FlexContainer>

          <FlexContainer>
            <img
              src={editIcon}
              alt="editIcon"
              onClick={(e) => {
                e.stopPropagation();
                onEdit(item.name);
              }}
              className={styles.iconShare}
            />
            <Popover
              placement="right"
              content={
                <div onClick={() => setDelete("")}>
                  <DeletePopUp
                    value={`Are you sure you want to delete ${item.name} Frequency List?`}
                    onClose={(e) => {
                      e.stopPropagation();
                      setDelete("");
                    }}
                    onDelete={(e) => {
                      e.stopPropagation();
                      setDelete("delete");
                    }}
                  />
                </div>
              }
              trigger="click"
              open={deleteFrequency === item.name}
              onOpenChange={() => setDelete(deleteFrequency)}
            />
            <img
              src={deleteIcon}
              alt="deleteFrequency"
              className={styles.iconList}
              onClick={(e) => {
                e.stopPropagation();
                setDelete(item.name);
              }}
            />
          </FlexContainer>
        </ListRow>
      ))}
    </div>
  );
};
export default FrequencyList;
