import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./styles";
import logo from "../../assets/logo.svg";
import loginImage from "../../assets/loginImg.jpg";
import APIError from "../../utils/APIError";
import { setPasswordUser } from "../../services/users";
import qs from "qs";
import message from "antd/lib/message";
import { SetPasswordMobile } from "../../components/SetPasswordMobile";
import { InputPassword } from "../../components/Inputs/InputPassword";
import { useTranslation } from "react-i18next";
import { GridContainer } from "../../components/GridContainer/GridContainer";
import { Button } from "../../components/Button";

interface Props {}

const SetPassword: React.FC<Props> = () => {
  const [loading, setLoading] = useState(false);
  const [password, setPass] = useState("");
  const [confirm, setConfirm] = useState("");
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const history = useHistory();

  function useQuery() {
    const { search } = useLocation();
    return search;
  }

  const query = useQuery();
  const token = qs.parse(query.substring(1)).token as string;

  useEffect(() => {
    document.title = "RAVENT APP :: Set Password";
  });

  async function sendMail() {
    if (loading) {
      return;
    }
    if (confirm !== password) {
      setError("Passwords don't match");
      return;
    }
    try {
      setLoading(true);
      await setPasswordUser(password, token);
      setLoading(false);
      history.push("/");
    } catch (err) {
      setLoading(false);
      if (err instanceof APIError) {
        message.error(err.messages);
      }
    }
  }

  return (
    <div>
      <div className={styles.container}>
        <GridContainer
          columns="1fr 1fr"
          justifyContent="center"
          alignItems="center"
          gap="1em"
        >
          <div className={styles.imageContainer}>
            <img src={loginImage} alt="" className={styles.image} />
          </div>
          <div>
            <div className={styles.inputsContainer}>
              <div className={styles.logoContainer}>
                <img src={logo} alt="logo" className={styles.logo} />
              </div>
              <div className={styles.subtitle}>
                {t("container.setPassword.setPasswordLabel")}
              </div>
              <div className={styles.text}>
                {t("container.setPassword.instructions")}
              </div>
              <div>
                <InputPassword
                  label={t("container.setPassword.password")}
                  onChange={(value) => setPass(value)}
                  value={password}
                  showIcon
                />
                <InputPassword
                  onFocus={() => setError("")}
                  label={t("container.setPassword.confirm")}
                  onChange={(value) => setConfirm(value)}
                  value={confirm}
                  error={error}
                  showIcon
                />
                <div>
                  <Button
                    width="100%"
                    label={
                      loading
                        ? "..."
                        : `${t("container.setPassword.setPasswordLabel")}`
                    }
                    onClick={(e) => {
                      if (loading) {
                        return;
                      }
                      sendMail();
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={styles.footer}>
              <span className={styles.footerText}>
                {t("container.setPassword.rightsReserved")}
              </span>{" "}
            </div>
          </div>
        </GridContainer>
      </div>
      <div className={styles.mobileContainer}>
        <SetPasswordMobile
          confirm={confirm}
          sendMail={() => sendMail()}
          password={password}
          loading={loading}
          error={error}
          setError={() => setError("")}
          onChangeConfirm={(value) => setConfirm(value)}
          onChangePassword={(value) => setPass(value)}
        />
      </div>
    </div>
  );
};

export default SetPassword;
