import React, { useState } from "react";
import { Popconfirm, Tag, Input, Form, Typography } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import type { FormInstance } from "antd";
import { blue } from "../../constants/colors";

export interface Load {
  name: string;
  type: string;
  details: string;
  properties: Record<string, string>;
}

const LoadComponent: React.FC<{
  initialLoad: Load;
  onSave: (properties: Record<string, string>) => void;
}> = ({ initialLoad, onSave }) => {
  const [load, setLoad] = useState(initialLoad);
  const [currentProperty, setCurrentProperty] = useState<{
    key: string;
    value: string;
  } | null>(null);
  const [visible, setVisible] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [newTag, setNewTag] = useState("");
  const [deleteKey, setDeleteKey] = useState<string | null>(null);
  const formRef = React.createRef<FormInstance>();

  const handleTagClick = (key: string, value: string) => {
    setCurrentProperty({ key, value });
    setVisible(true);
  };

  const handleConfirmEdit = (key: string, value: string) => {
    const newProperties = { ...load.properties };
    if (currentProperty && key !== currentProperty.key) {
      delete newProperties[currentProperty.key];
    }
    newProperties[key] = value;
    setLoad({ ...load, properties: newProperties });
    onSave(newProperties);
    setCurrentProperty(null);
    setVisible(false);
  };

  const handleCancelEdit = () => {
    setCurrentProperty(null);
    setVisible(false);
  };

  const handleAddNewTag = () => {
    const [key, value] = newTag.includes("::")
      ? newTag.split("::")
      : ["", newTag];
    if (value) {
      handleConfirmEdit(key || value, value);
      setNewTag("");
      setIsAdding(false);
    }
  };

  const handleDeleteTag = (key: string) => {
    const newProperties = { ...load.properties };
    delete newProperties[key];
    setLoad({ ...load, properties: newProperties });
    onSave(newProperties);
    setDeleteKey(null);
  };

  return (
    <div>
      {/* Display existing tags with delete option */}
      {Object.entries(load.properties).map(([key, value]) => (
        <Tag
          key={key}
          style={{ cursor: "pointer", margin: "0 0.2em 0.2em 0" }}
          color="blue"
          onClick={() => handleTagClick(key, value)}
        >
          {`${key}::${value}`}
          <Popconfirm
            title="Are you sure you want to delete this tag?"
            onConfirm={(e) => {
              e?.stopPropagation();
              handleDeleteTag(key);
            }}
            onCancel={(e) => {
              e?.stopPropagation()
              setDeleteKey(null);
            }}
            okText="Yes"
            cancelText="No"
            open={deleteKey === key}
          >
            <CloseOutlined
              style={{
                color: blue,
                marginLeft: 8,
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setDeleteKey(key);
              }}
            />
          </Popconfirm>
        </Tag>
      ))}

      {/* Add new tag button and input */}
      <div style={{ marginTop: "0.5em" }}>
        {isAdding ? (
          <Input
            placeholder="Add new tag (e.g., key::value or just value)"
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
            onPressEnter={handleAddNewTag}
            onBlur={() => setIsAdding(false)}
            style={{ width: "200px" }}
            autoFocus
          />
        ) : (
          <Typography.Text
            underline
            style={{ color: blue, cursor: "pointer" }}
            onClick={() => setIsAdding(true)}
          >
            + add tag
          </Typography.Text>
        )}
      </div>

      {/* Popconfirm for editing a tag */}
      {currentProperty && (
        <Popconfirm
          title={
            <Form
              ref={formRef}
              onFinish={({ key, value }) => handleConfirmEdit(key, value)}
            >
              <Form.Item name="key" initialValue={currentProperty.key}>
                <Input />
              </Form.Item>
              <Form.Item name="value" initialValue={currentProperty.value}>
                <Input />
              </Form.Item>
            </Form>
          }
          open={visible}
          onConfirm={() => {
            if (formRef.current) {
              formRef.current?.submit();
            }
          }}
          onCancel={handleCancelEdit}
        >
          <div></div>
        </Popconfirm>
      )}
    </div>
  );
};

export default LoadComponent;
