import React, { useState } from "react";

import { OfferFrequency, Offer } from "@innomius/ravent-typescript-types";
import styles from "./styles";
import FrequencyList from "./FrequencyList";
import { defaultFrequencyList } from "../../services/offers";
import OfferFrequencyListForm from "../OfferForm/OfferFrequencyListForm";
import { useTranslation } from "react-i18next";
import { EmptyList } from "../List/EmptyList";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { Button } from "../Button";

interface Props {
  data: Offer;
}

const FrequencyDetail: React.FC<Props> = ({ data }) => {
  const [frequencyForm, setForm] = useState(false);
  const [frequencyList, setFrequencyList] =
    useState<OfferFrequency>(defaultFrequencyList);
  const [edit, setEdit] = useState(false);

  const { t } = useTranslation();

  const onChange = <P extends keyof OfferFrequency>(
    prop: P,
    value: OfferFrequency[P]
  ) => {
    setFrequencyList({ ...frequencyList, [prop]: value });
  };
  return (
    <div>
      {!data.frequencies.length ? (
        <div className={styles.emptyCard}>
          <EmptyList
            instructions={t("component.offerVariantDetail.emptyList")}
            text={t("component.offerVariantDetail.emptyFrequencyInstructions")}
            buttonText={t("component.offerVariantDetail.addButtonFrequency")}
            onNewClick={() => {}}
          />
        </div>
      ) : !frequencyForm ? (
        <>
          <FlexContainer
            padding="1em 0 1em 0"
            alignItems="center"
            justifyContent="space-between"
          >
            <ContainerTitle
              label={t("component.offerVariantDetail.freqList")}
              size="xsmall"
              count={data.frequencies.length.toString()}
            />
            <Button
              label={t("component.offerVariantDetail.addButtonFrequency")}
              onClick={() => setForm(true)}
            />
          </FlexContainer>
          <FrequencyList
            data={data}
            onEdit={async (id: string) => {
              const frequencyList = await data.frequencies.find(
                (item) => item.name === id
              );
              if (frequencyList) {
                setFrequencyList(frequencyList);
              }
              setEdit(true);
              setForm(true);
            }}
          />
        </>
      ) : (
        <OfferFrequencyListForm
          onSave={() => {
            setFrequencyList(defaultFrequencyList);
            setForm(false);
          }}
          onCancel={() => {
            setFrequencyList(defaultFrequencyList);
            setForm(false);
          }}
          data={frequencyList}
          onChange={onChange}
          edit={edit}
        />
      )}
    </div>
  );
};
export default FrequencyDetail;
