import React, { useCallback, useEffect, useState } from "react";
import { Entity } from "@innomius/ravent-typescript-types";
import { Card } from "../Card/Card";

import styles from "./styles";
import { useTranslation } from "react-i18next";
import {
  addressToCoordinates,
  coordinatesToW3w,
  defaultW3W,
  w3wToCoordinates,
} from "../../services/maps";
import MapLoader from "../MapInput/MapLoader";
import { GridContainer } from "../GridContainer/GridContainer";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { SectionProperty } from "../Section/SectionProperty";
import { ContainerTitle } from "../ContainerTitle";
import { InputSwitch } from "../Inputs/InputSwitch";
import { Button } from "../Button";

interface Props {
  data: Entity;
  setEdit: (edit: boolean) => void;
  loading: string;
}

const OrgDetailComponent: React.FC<Props> = ({ data, setEdit, loading }) => {
  const { t } = useTranslation();

  const [locationW3W, setLocationW3W] = useState(defaultW3W);

  const getcoordinates = useCallback(async () => {
    if (data.address.coordinates?.latitude) {
      const resPlacement = await coordinatesToW3w(
        parseFloat(data.address.coordinates.latitude || ""),
        parseFloat(data.address.coordinates.longitude || "")
      );
      setLocationW3W(resPlacement);
      return;
    }
    if (data.address.w3w) {
      try {
        const res = await w3wToCoordinates(data.address.w3w);
        setLocationW3W(res);
        return;
      } catch (err) {
        console.error(err);
      }
    }
    if (data.address.line1) {
      const res = await addressToCoordinates(`${data.address.line1}`);

      if (res.status === "OK") {
        const resaddress = await coordinatesToW3w(
          res.results[0].geometry.location.lat,
          res.results[0].geometry.location.lng
        );

        setLocationW3W(resaddress);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data.address.w3w,
    data.address.coordinates?.longitude,
    data.address.coordinates?.latitude,
    data.address.line1,
  ]);

  useEffect(() => {
    getcoordinates();
  }, [getcoordinates]);

  return (
    <div className={styles.container}>
      <Card>
        <div className={styles.wrapperCard}>
          <FlexContainer justifyContent="space-between" padding="0 0 1.5em 0">
            <ContainerTitle
              size="small"
              label={t("component.entityDetail.details")}
            />

            <Button
              label={
                loading === "edit" ? "..." : t("container.profileDetail.edit")
              }
              onClick={() => setEdit(true)}
              theme="blue"
            />
          </FlexContainer>

          <GridContainer gap="1em" columns={data.logo ? "4fr 1fr" : "1fr"}>
            <div>
              <GridContainer
                gap="1em"
                columns="1fr 1fr 1fr"
                justifyContent="flex-start"
              >
                <SectionProperty
                  label={t("component.entityDetail.email")}
                  value={data.email || "-"}
                />
                <SectionProperty
                  label={t("component.entityDetail.phone")}
                  value={data.phone || "-"}
                />
              </GridContainer>

              {data.type === "diveshop" ? (
                <>
                  <ContainerTitle
                    padding="1.5em 0 1em 0"
                    size="small"
                    label={t("component.entityDetail.appConfig")}
                  />
                  <FlexContainer
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <InputSwitch value={data.mayChatWithCustomers} />

                    <p className={styles.label}>
                      {t("component.entityDetail.allowChat")}{" "}
                    </p>
                  </FlexContainer>
                  <FlexContainer
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <InputSwitch value={data.maySeeOrderDetails} />
                    <p className={styles.label}>
                      {t("component.entityDetail.allowDetails")}
                    </p>
                  </FlexContainer>
                </>
              ) : null}
            </div>
          </GridContainer>
          <div className={styles.addressContainer}>
            <ContainerTitle
              padding="0em 0 1em 0"
              size="small"
              label={t("component.entityDetail.address")}
            />
            <GridContainer gap="1em" columns="1.5fr 3fr">
              <div>
                <SectionProperty
                  label="Reference"
                  value={data.address.reference || "-"}
                />
                <div style={{ margin: "0em 0 1em 0" }}>
                  <SectionProperty
                    label="Address"
                    value={data.address.line1 || "-"}
                  />
                </div>
                <SectionProperty
                  label="Three Words Placement"
                  value={`/// ${data.address.w3w}`}
                />
              </div>
              <div className={styles.mapContainer}>
                <div style={{ padding: "0 0 1em 0" }}>
                  <span style={{ fontWeight: 700 }}>Coordinates: </span>
                  {`${data.address.coordinates?.latitude || ""},${
                    data.address.coordinates?.latitude || ""
                  } `}
                </div>

                <MapLoader
                  height="180px"
                  onChange={() => {}}
                  type={data.address.type}
                  value={locationW3W.coordinates}
                />
              </div>
            </GridContainer>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default OrgDetailComponent;
