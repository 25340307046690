import React from "react";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { ReactComponent as AssignIcon } from "../../assets/assign.svg";

import Text from "../Text";
import { typeIconMap } from "../../utils/fulfillments";
import { ContainerTitle } from "../ContainerTitle";
import { FlexContainer } from "../FlexContainer/FlexContainer";

interface Props {
  name: string;
  type: string;
  onClick: () => void;
  border?: boolean;
}

const AssignFulfiller: React.FC<Props> = ({ name, type, onClick, border }) => {
  const { t } = useTranslation();

  return (
    <div>
      <ContainerTitle
        size="xsmall"
        label={t("component.orderFulfilmentComponent.fulfillmentState")}
        padding="0 0 1em 0.5em"
      />
      <div
        className={
          border ? styles.definitionContainerBorder : styles.definitionContainer
        }
      >
        <FlexContainer justifyContent="space-between">
          <FlexContainer justifyContent="flex-start" alignItems="flex-start">
            <div
              style={{
                padding: "0.5em",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#F3F9FE",
                marginRight: "0.5em",
                borderRadius: 8,
                width: 40,
                height: 40,
              }}
            >
              <img className={styles.iconType} src={typeIconMap[type]} alt="" />
            </div>
            <FlexContainer
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Text color="#000" fontWeight={700} text={name} />
              {type === "fulfiller" ? (
                <div />
              ) : (
                <Text text={type} color="#333" />
              )}
            </FlexContainer>
          </FlexContainer>

          <div className={styles.iconContainer}>
            <AssignIcon style={{ cursor: "pointer" }} onClick={onClick} />
          </div>
        </FlexContainer>
      </div>
    </div>
  );
};

export default AssignFulfiller;
