import {
  OfferSet,
  Offer,
  WeeklySchedule,
} from "@innomius/ravent-typescript-types";

export const weeklySchedules: WeeklySchedule[] = [
  {
    weekday: "monday",
    hour: "9:00",
  },
  {
    weekday: "monday",
    hour: "9:00",
  },
  {
    weekday: "tuesday",
    hour: "10:00",
  },
  {
    weekday: "wednesday",
    hour: "20:80",
  },
];

export const offers: Offer[] = [
  {
    name: "Chacchoben Mayan Ruins - Basic",
    id: "6fe33d99-53fb-4fba-a1a0-83787e3dd3m2",
    image: "",
    locationId: "Chacchoben",
    ticketingEnabled: true,
    description:
      "Mostly unexcavated, the Chacchoben ruins (Zona Arqueológica de Chacchoben) make up the largest and most visited Maya archaeological site in Costa Maya. Here moss-covered temples sprout from a lush jungle, attracting visitors who want to learn about Maya history, including the collapse of the ancient civilization.",
    ticketingFulfillment: {
      type: "access",
      status: [],
      locations: [],
      orderLineStates: [],

      validFrom: new Date(),
      validUntil: new Date(),
      activationType: "at-date-time",
      maxEvents: 1,
      lifeTime: {
        value: 1,
        type: "duration",
        unit: "hour",
      },
      description: "Chacchoben Mayan Ruins - Basic Ticket.",
      modifiers: "",
      customerNameRequired: true,
      properties: [
        { value: "3NrLZobvA9", valueType: "string", name: "externalId" },
      ],
    },
    price: {
      currency: "$",
      value: 125,
    },
    availability: "confirmation-required",

    languages: ["english"],

    tags: ["basic", "ruins", "ticketing"],
    modifiers: [
      {
        name: "Ticket Type",
        selectionType: "single",
        minSelection: 0,
        enabled: true,
        default: true,
        maxSelection: 0,
        options: [],
      },
      {
        name: "Discounts",
        selectionType: "multiple",
        minSelection: 0,
        maxSelection: 2,
        enabled: true,
        default: false,
        options: [],
      },
    ],
    frequencies: [
      {
        name: "Summer",
        startDate: new Date("21 June"),
        endDate: new Date("22 September"),
        enabled: true,
        weeklySchedule: weeklySchedules,
      },
      {
        name: "Winter",
        startDate: new Date("21 December"),
        endDate: new Date("20 March"),
        enabled: false,
        weeklySchedule: weeklySchedules,
      },
    ],
  },
];

export const offersets: OfferSet[] = [
  {
    id: "992f00be-e748-43cc-8fc6-1af7b2ec6681",
    created: new Date("21 January 2021"),
    updated: new Date(),
    deleted: false,
    organizationId: "a3c5342f-0630-4868-b490-1b5d06ca866d",
    enabled: true,
    name: "Chacchoben Mayan Ruins",
    startingPrice: {
      currency: "$",
      value: 125.0,
    },
    image: "",
    description:
      "A fantastic way to know more about our Mayan culture. The Chacchoben Mayan Ruins are a great option for small groups and families. The tour will be led by a professional guide at all times. He/She will give you all the information you need to learn about our culture. Being on-site will give you the opportunity to witness the beautiful nature that surrounds Chacchoben. The Gran Basamento, Temples and Pyramids are among the archaeological wonders you will see.",

    offers: offers,
  },
];
