import {
  Visitor,
  Message,
  Conversation,
} from "@innomius/ravent-typescript-types";
import get from "lodash/get";
import { conversation, messages, visitorsMock } from "../mocks/visitors";

export function parseVisitor(data: unknown): Visitor {
  return {
    id: get(data, "id", ""),
    created: get(data, "created", ""),
    updated: get(data, "updated", ""),
    deleted: get(data, "deleted", false),
    orderId: get(data, "orderId", ""),
    braceleteId: get(data, "braceleteId", ""),
    locationId: get(data, "locationId", ""),
    firstName: get(data, "firstName", ""),
    lastName: get(data, "lastName", ""),
    reference: get(data, "reference", ""),
    phoneNumber: get(data, "phoneNumber", ""),
    locationName: get(data, "locationName", ""),
    checkinPeriod: get(data, "checkinPeriod", ""),
    checkoutDate: get(data, "checkoutDate", ""),
    conversationId: get(data, "conversationId", ""),
    venueId: get(data, "venueId", ""),
  };
}

export function parseMessage(data: unknown): Message {
  return {
    id: get(data, "id", ""),
    conversationId: get(data, "conversationId", ""),
    sender: get(data, "sender", ""),
    visitor: get(data, "visitor", false),
    sentTimestamp: get(data, "sentTimestamp", ""),
    readTimestamp: get(data, "readTimestamp", ""),
    content: get(data, "content", ""),
  };
}

export function parseConversation(data: unknown): Conversation {
  return {
    id: get(data, "id", ""),
    customerPhoneNumber: get(data, "customerPhoneNumber", ""),
  };
}

export function parseMessages(data: unknown): Message[] {
  const array = get(data, "conversation", []).map((item: Message) => ({
    id: get(data, "id", ""),
    conversationId: get(data, "conversationId", ""),
    sender: get(data, "sender", ""),
    visitor: get(data, "visitor", false),
    sentTimestamp: get(data, "sentTimestamp", ""),
    readTimestamp: get(data, "readTimestamp", ""),
    content: get(data, "content", ""),
  }));

  return array.length
    ? array
    : [
        {
          id: "",
          sender: "",
          conversationId: "",
          visitor: false,
          sentTimestamp: "",
          readTimestamp: "",
          content: "",
        },
      ];
}

export async function visitorList(): Promise<Visitor[]> {
  const res = visitorsMock;
  const data = res.map(parseVisitor);
  return data;
}

export async function conversationRead(): Promise<Conversation> {
  const res = conversation;
  const data = res;
  return data;
}

export async function messageList(): Promise<Message[]> {
  const res = messages;
  const data = res;
  return data;
}
