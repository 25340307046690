const translation = {
  en: {
    cancel: "Cancel",
    register: "Register",
    information:
      "This order is overpaid. Are you sure you want to register this payment?",
  },
  es: {
    cancel: "Cancelar",
    register: "Registrar",
    information:
      "Este pedido está pagado en exceso. ¿Está seguro de que desea registrar este pago?",
  },
  de: {},
};

export default translation;
