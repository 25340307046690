const translation = {
  en: {
    orderStates: "Order States",
    orderlinesStates: "States",
    allowedTransitions: "Allowed Transitions",
    emptyInstructions: "States are not configured yet",
    addInstructions: "+ Add Action",
    add: "Add Action",
    edit: "Edit",
    stateName: "Integration Name",
    flowUrl: "Flow URL",
    cancel: "Cancel",
    addModal: "Add Action",
    update: "Update",
    type: "Type",
    description: "Description",
    action: "Action",
  },
  es: {
    action: "Acción",
    orderStates: "Estados de Orden",
    orderlinesStates: "Estados",
    allowedTransitions: "Transiciones Permitidas",
    emptyInstructions: "Estados no se han configurado",
    addInstructions: "Agregue su lista de estados aquí",
    add: "+ Agregar Acción",
    edit: "Editar",
    stateName: "Nombre de la Acción",
    flowUrl: "Flow URL",
    cancel: "Cancelar",
    addModal: "+ Agregar Acción",
    update: "Actualizar",
    type: "Tipo",
    description: "Descripción",
  },
  de: {},
};

export default translation;
