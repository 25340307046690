const translation = {
  en: {
    shareMedia: "Share Media",
    documentName: "Document Name",
    type: "Type",
    qrCode: "QR Code",
    media: "Official Media",
    public: "public",
    private: "private",
    id: "ID",
  },
  es: {
    shareMedia: "Compartir contendio oficial",
    documentName: "Nombre",
    type: "Tipo",
    qrCode: "Código QR",
    media: "Contenido Oficial",
    public: "public",
    private: "private",
    id: "ID",
  },
  de: {},
};

export default translation;
