import React from "react";
import Text from "../Text";

import styles from "./styles";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { Card } from "../Card/Card";

interface Props {
  name: string;
  onSelectCatalog: (name: string) => void;
  active: boolean;
  logo: string;
}

const CatalogCard: React.FC<Props> = ({
  name,
  onSelectCatalog,
  active,
  logo,
}) => {
  return (
    <div className={styles.wrapperCard} onClick={() => onSelectCatalog(name)}>
      <Card containerClassname={active ? styles.active : styles.card}>
        <FlexContainer flexDirection="column">
          <img src={logo} alt="" className={styles.icon} />
          <Text text={name} fontSize="1.4em" fontWeight={600} />
        </FlexContainer>
      </Card>
    </div>
  );
};

export default CatalogCard;
