import React, { useCallback, useEffect, useState } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { BackLink } from "../../components/BackLink";
import { ButtonsContainer } from "../../components/ButtonsContainer";
import { Button } from "../../components/Button";
import { EditableTitle } from "../../components/EditableTitle";

import styles from "./styles.module.css";
import message from "antd/lib/message";
import { useDispatch } from "react-redux";
import { venueList } from "../../store/venues";
import APIError from "../../utils/APIError";
import { useTranslation } from "react-i18next";
import {
  createRol,
  defaultRole,
  Permission,
  permissionGroupList,
  permissionList,
  Role,
} from "../../services/roles";
import TabNavBar from "../../components/TabNavBar";
import RoleForm from "../../components/RoleForm";
import PermissionForm from "../../components/RoleForm/PermissionForm";

interface RouteParams {
  id: string;
}

interface Props extends RouteComponentProps<RouteParams> {}

const CreateRol: React.FC<Props> = ({ history }) => {
  const [role, setRole] = useState<Role>(defaultRole);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [loading, setLoading] = useState<string>("");
  const dispatch = useDispatch();
  const [group, setGroup] = useState("assets");

  const [permissionGroups, setPermissionsGroups] = useState<string[]>([]);

  const loadGroups = useCallback(async () => {
    try {
      setLoading("roles");
      const res = await permissionGroupList();
      setPermissionsGroups(res);
      setLoading("");
    } catch (err) {
      if (err instanceof APIError) {
        console.log(err.message);
      }
    }
  }, []);

  useEffect(() => {
    loadGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation();

  const tabs = [
    {
      id: "rolesdetailsnew",
      label: `${t("container.createRol.details")}`,
      path: `/organization/roles/new/details`,
      onClick: () => (document.title = "RAVENT APP :: Org :: Roles"),
    },
    {
      id: "permissionsnew",
      label: t("container.createRol.permissions"),
      path: `/organization/roles/new/permissions`,
      onClick: () => (document.title = "RAVENT APP :: Org :: Roles"),
    },
  ];

  const load = useCallback(async () => {
    dispatch(venueList({}));

    try {
      const res = await permissionList({ page: 0, records_per_page: 100 });
      setPermissions(res.hits);
    } catch (err) {
      if (err instanceof APIError) {
        console.log(err.message);
      }
    }
  }, [dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    document.title = "RAVENT APP :: Create User";
  });

  const onChange = <P extends keyof Role>(prop: P, value: Role[P]) =>
    setRole({ ...role, [prop]: value });

  const onCreate = async () => {
    try {
      setLoading("create");
      await createRol(role);
      setLoading("");
      message.success(t("container.createRol.successCreation"));
      history.push("/organization/roles");
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  return (
    <LayouWithSideBar>
      <div className={styles.container}>
        <FlexContainer
          padding="1em 0 0em 0"
          justifyContent="space-between"
          alignItems="center"
        >
          <BackLink
            label={t("container.createRol.back")}
            onClick={() => history.push("/organization/roles")}
          />
          <ButtonsContainer>
            <Button
              theme="white"
              label={t("container.createRol.cancel")}
              onClick={() => {
                if (loading) {
                  return;
                }
                history.push("/organization/roles");
              }}
            />
            <Button
              label={loading ? "..." : `${t("container.createRol.save")}`}
              onClick={() => {
                if (loading) {
                  return;
                }
                onCreate();
              }}
            />
          </ButtonsContainer>
        </FlexContainer>

        <EditableTitle
          padding={"1em 0 1em 0"}
          value={role.name}
          placeholder={t("container.createRol.roleName")}
          onChange={(value) => onChange("name", value)}
        />

        <TabNavBar options={tabs}>
          <Switch>
            <Route path={`/organization/roles/new/details`}>
              <RoleForm data={role} onChange={onChange} />
            </Route>
            <Route path={`/organization/roles/new/permissions`}>
              {
                <>
                  <PermissionForm
                    data={role}
                    creation
                    group={group}
                    setGroup={setGroup}
                    permissionsGroups={
                      permissionGroups.map((item) => item) || [
                        "actions",
                        "messaging",
                        "orders",
                        "organizations",
                        "fops",
                        "integrations",
                        "assets",
                        "ott",
                        "payments",
                        "users",
                        "webhooks",
                      ]
                    }
                    permissions={permissions}
                    loading={loading === "create"}
                    error={""}
                    onChange={onChange}
                  />
                </>
              }
            </Route>
          </Switch>
        </TabNavBar>
      </div>
    </LayouWithSideBar>
  );
};

export default CreateRol;
