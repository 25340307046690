import {
  Conversation,
  Message,
  Visitor,
} from "@innomius/ravent-typescript-types";

export const messages: Message[] = [
  {
    id: "69e9b71e-60ce-4c3c-9c7d-b89e1af0ed09",
    sender: "+49176705767",
    content: "Could you bring me an extra coca cola?",
    sentTimestamp: new Date("20 July 2021"),
    readTimestamp: null,
    visitor: true,
    conversationId: "f44f0481-a8bf-4685-a4ab-79c795cde152",
  },
  {
    id: "45e9b71e-60ce-4c3c-9c7d-b89e1af5555",
    sender: "stafId1235",
    content: "Yes anything extra?",
    sentTimestamp: new Date("21 July 2021"),
    readTimestamp: null,
    visitor: false,
    conversationId: "f44f0481-a8bf-4685-a4ab-79c795cde152",
  },
  {
    id: "89e9b71e-60ce-777c-9c7d-b89e1af0ed07",
    sender: "stafId1235",
    content: "Need extra towels?",
    sentTimestamp: new Date("21 Sep 2021 11:00"),
    readTimestamp: new Date("21 Sep 2021 14:17"),
    visitor: true,
    conversationId: "b5a3e40d-c782-4f5f-a3ab-edcde76c8163",
  },
];

export const conversation: Conversation = {
  id: "f44f0481-a8bf-4685-a4ab-79c795cde152",
  customerPhoneNumber: "+4917670571790",
};

export const visitorsMock: Visitor[] = [
  {
    id: "dj4fe688-9bc2-4358-8351-71321cc6na16",
    orderId: "",
    braceleteId: "",
    locationId: "a8a4664a-7912-44f5-b18d-920b534b5a58",
    locationName: "Kii Kitchen Experience",
    firstName: "Ana",
    lastName: "Lopez",
    reference: "Table 52",
    phoneNumber: "+491767890567",
    checkoutDate: new Date("17 July 2021"),
    checkinPeriod: "00:02:00",
    created: new Date(),
    deleted: false,
    updated: new Date(),
    conversationId: "f44f0481-a8bf-4685-a4ab-79c795cde152",
    venueId: "4ed6758c-5792-461a-b84f-4dd9d91a0cad",
  },
  {
    id: "5ae61bb4-2537-46ed-961c-943be9c54a12",
    orderId: "",
    braceleteId: "",
    locationId: "a8a4664a-7912-44f5-b18d-920b534b5a58",
    locationName: "Kii Kitchen Experience",
    firstName: "",
    lastName: "",
    reference: "Palapa 2",
    phoneNumber: "+491167890569",
    checkoutDate: new Date("17 July 2021"),
    checkinPeriod: "00:02:00",
    conversationId: "b5a3e40d-c782-4f5f-a3ab-edcde76c8163",
    venueId: "4ed6758c-5792-461a-b84f-4dd9d91a0cad",
    created: new Date(),
    deleted: false,
    updated: new Date(),
  },
  {
    id: "4d45d047-13b0-46bc-b3a6-fb14f357e036",
    orderId: "VJFBNA",
    braceleteId: "X1234B",
    locationId: "a8a4664a-7912-44f5-b18d-920b534b5a58",
    locationName: "Kii Kitchen Experience",
    firstName: "",
    lastName: "",
    reference: "",
    phoneNumber: "",
    checkoutDate: new Date("17 July 2021"),
    checkinPeriod: "00:02:00",
    conversationId: "a6b25efb-b6a6-45d4-9d39-36f63d3fc24d",
    created: new Date(),
    deleted: false,
    updated: new Date(),
    venueId: "4ed6758c-5792-461a-b84f-4dd9d91a0cad",
  },
  {
    id: "d34fe688-9bc2-4358-8351-71321cc6aa16",
    orderId: "",
    braceleteId: "",
    locationId: "a8a4nna-7912-44f5-b18d-920b5nnnna58",
    locationName: "Chachoben Archeological Ruins",
    firstName: "Dirsta",
    lastName: "Schob",
    reference: "",
    phoneNumber: "+491767890567",
    checkoutDate: new Date("17 July 2021"),
    checkinPeriod: "00:02:00",
    created: new Date(),
    deleted: false,
    updated: new Date(),
    conversationId: "1b8db9f7-b04c-4c02-bec0-8c64a6c1d103",
    venueId: "4ed6758c-5792-461a-b84f-4dd9d91a0cad",
  },
];
