const translation = {
  en: {
    successCreationTicketing: "Ticketing Definition succesfully created.",
    cancel: "Cancel",
    save: "Save",
    ticketingDefNamePlaceholder: "Definition Name",
    detailsLabel: "Details",
    ticketingLabel: "Ticketing",
  },
  es: {
    successCreationTicketing: "Definición de certificado creada con éxito.",
    cancel: "Cancelar",
    save: "Guardar",
    ticketingDefNamePlaceholder: "Nombre de Certificado",
    detailsLabel: "Detalles",
    ticketingLabel: "Emisión de boletos",
  },
  de: {},
};

export default translation;
