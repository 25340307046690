const translation = {
  en: {
    disableDiver: "Disable Diver",
    enableDiver: "Enable Diver",
    cancel: "Cancel",
    warning: "Are you sure you want to remove all access for ",
    positiveWarning: "Are you sure you want to enable the access for ",
    disable: "Disable",
    enable: "Enable",
  },
  es: {},
  de: {},
};

export default translation;
