const translation = {
  en: {
    profile: "My Profile",
    logout: "Logout",
    companyProfile: "Company Profile",
    billing: "Billing"
  },
  es: {
    profile: "Mi perfil",
    billing: "Facturación",
    logout: "Cerrar sesión",
    companyProfile: "Perfil de la Empresa",
  },
  de: {},
};

export default translation;
