const translation = {
  en: {
    import: "Import CSV File",
    locations: "Assets",
    upload: "Upload",
    drag: "Drag and drop a CSV file here",
    rows: "Rows",
    close: "Close",
    create: "Create",
    export: "Export",
    exportFile: "Export to CSV File",
    total: "Total",
    deleteLocations: "Delete Multiple Assets",
    delete: "Delete",
    noLocations:
      "No Assets are selected. Please choose the assets you wish to delete by clicking on the checkboxes. ",
  },
  es: {
    noLocations:
      "No hay activos seleccionadas. Elija los activos que desea eliminar haciendo clic en las casillas de verificación.",
    delete: "Eliminar",
    deleteLocations: "Eliminar Múltiples Activos",
    total: "Total",
    export: "Exportar",
    locations: "Activos",
    exportFile: "Exportar a Archivo CSV",
    close: "Cerrar",
    create: "Crear",
    rows: "Filas",
    import: "Importar Archivo CSV",
    upload: "Subir",
    drag: "Arrastra y suelta un archivo CSV aquí",
  },
  de: {},
};

export default translation;
