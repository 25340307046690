import React, { useState } from "react";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { useTranslation } from "react-i18next";

import Text from "../Text";
import { Checkbox } from "antd";
// import chevron from "../../assets/chevron-down.svg";
import { ReactComponent as Shrink } from "../../assets/chevron-left.svg";
import styles from "./styles";
import { FacetEvent, Filter } from "../../services/orders";
import { numberFormatNoDecimals } from "../../utils/format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";
import fontColorContrast from "font-color-contrast";

interface Props {
  facets: FacetEvent[];

  onChange: (facetGroup: string, option: string) => void;
  selected: Filter[];
  shrink: boolean;
  setSize: (value: boolean) => void;
  onChangeExlusion: (facetGroup: string, value: boolean) => void;
  onChangeSelect: (facetGroup: string, value: string) => void;
}

const SideFiltersLocations: React.FC<Props> = ({
  onChange,
  facets,
  shrink,
  selected,
  setSize,
  onChangeExlusion,
  onChangeSelect,
}) => {
  const { t } = useTranslation();
  const [visibleFacets, setVisibleFacets] = useState<Record<string, boolean>>(
    {}
  );
  const [open, setOpen] = useState<Record<string, boolean>>({});

  function camelCaseToWords(input: string) {
    const separatedWords = input.replace(/([A-Z])/g, " $1").trim();
    return separatedWords.charAt(0).toUpperCase() + separatedWords.slice(1);
  }

  return (
    <div
      className={
        !shrink ? styles.filterContainerShrink : styles.filterContainer
      }
    >
      <FlexContainer padding="0 0 0em 0" justifyContent="space-between">
        {shrink ? (
          <Text
            fontSize="20px"
            fontWeight={700}
            text={t("component.orderFilters.filters")}
          />
        ) : null}
        <Shrink
          className={shrink ? styles.iconShrink : styles.iconShrinkTransform}
          onClick={() => setSize(!shrink)}
        />
      </FlexContainer>

      {!shrink ? (
        <div className={styles.textHorizonatl}>
          {t("component.orderFilters.filters")}
        </div>
      ) : (
        <>
          <div style={{ paddingTop: "1em" }} />
          {facets
            ?.slice()
            ?.sort((a, b) => a.chronologicalIndex - b.chronologicalIndex)
            ?.map(
              (item, index) =>
                item.data.length > 0 && (
                  <div key={item.name + index}>
                    <FlexContainer
                      padding={
                        !open[item.name] ? "1.5em 0 1em 0" : "2em 0 1em 0"
                      }
                      justifyContent="flex-start"
                    >
                      <FontAwesomeIcon
                        color={"#333"}
                        style={{ cursor: "pointer", marginRight: "1em" }}
                        onClick={() =>
                          setOpen((prev) => ({
                            ...prev,
                            [item.name]: !prev[item.name],
                          }))
                        }
                        icon={
                          !open[item.name]
                            ? (faChevronRight as IconProp)
                            : (faChevronDown as IconProp)
                        }
                      />
                   <div
                        className={styles.dot}
                        style={{
                          background: item.color,
                          marginRight: "1em",
                          color: fontColorContrast(item.color),
                        }}
                      >
                        {item.field === "tags" ? "T" : "P"}
                      </div>
                      <Text
                        fontWeight={700}
                        text={camelCaseToWords(item.name)}
                      />
                      {/* {open[item.name] ? (
                        <div
                          className={styles.link}
                          onClick={(e) => {
                            e.preventDefault();
                            const currentInclude = selected.find(
                              (filter) => filter.name === item.field
                            )?.include;
                            const newValue =
                              currentInclude === "Any" ? "All" : "Any";
                            onChangeSelect(item.field, newValue);
                          }}
                        >
                          <Space>
                            {
                              selected.find(
                                (filter) => filter.name === item.field
                              )?.include
                            }
                          </Space>
                        </div>
                      ) : null} */}
                    </FlexContainer>
                    <>
                      {open[item.name] ? (
                        <>
                          {/* <div style={{ padding: "0 0 1em 0" }}>
                            <Radio.Group
                              onChange={(e) => {
                                const value = e.target.value;
                                onChangeExlusion(item.field, value);
                              }}
                              value={selected
                                .find((filter) => filter.name === item.field)
                                ?.items.every((item) => item.include === true)}
                            >
                              <Radio value={true}>Include</Radio>
                              <Radio value={false}>Exclude</Radio>
                            </Radio.Group>
                          </div> */}

                          {item.field.includes("datetimeHour") ? (
                            <></>
                          ) : item.data.length > 4 ? (
                            <div
                              style={{ marginBottom: "1em", marginTop: "1em" }}
                              className={styles.buttonText}
                              onClick={() =>
                                setVisibleFacets((prev) => ({
                                  ...prev,
                                  [item.name]: !prev[item.name],
                                }))
                              }
                            >
                              {visibleFacets[item.name]
                                ? "See Less"
                                : "See More"}
                            </div>
                          ) : null}

                          {item.data
                            .slice(
                              0,
                              visibleFacets[
                                item.name as keyof typeof visibleFacets
                              ]
                                ? item.data.length
                                : 4
                            )
                            .map((option, index) =>
                              item.field.includes("datetimeHour") ? (
                                <></>
                              ) : (
                                <div
                                  style={{ paddingTop: "0.5em" }}
                                  key={option.key + index}
                                >
                                  <Checkbox
                                    checked={selected
                                      .find(
                                        (filter) => filter.name === item.field
                                      )
                                      ?.items.some(
                                        (item) => item.key === option.key
                                      )}
                                    style={{ marginRight: "0.5em" }}
                                    onChange={() => {
                                      onChange(item.field, option.key);
                                    }}
                                  />
                                  {option.text}
                                  {option.doc_count === 0
                                    ? ""
                                    : ` (${numberFormatNoDecimals(
                                        option.doc_count
                                      )})`}
                                </div>
                              )
                            )}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  </div>
                )
            )}
        </>
      )}
    </div>
  );
};

export default SideFiltersLocations;
