import React, { useState } from "react";
import OrderTitle from "./OrderTitle";
import OrderDetailItemList from "../OrderDetailItemList";
import { Card } from "../Card/Card";
import {
  FulfillmentEvent,
  FulfillmentDefinition,
  Order,
  OrderLine,
  Team,
  Placement,
  Load,
} from "@innomius/ravent-typescript-types";
import message from "antd/lib/message";
import copyIcon from "../../assets/copy.svg";
import ERPCard from "./ERPCard";
import { useTranslation } from "react-i18next";
import Text from "../Text";
import { InputTags } from "../Inputs/InputTags";
import { Asset, CreateOrderline, Customer } from "../../utils/types";
import { ReactComponent as FulfillerIcon } from "../../assets/checkDisable.svg";

import fulfillerGreen from "../../assets/checkGreen.svg";
import styles from "./styles";
import { formatDate } from "../../utils/times";
import { Tooltip } from "antd";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import EditCustomer from "./EditCustomer";
import NoteCard from "./NoteCard";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { GridContainer } from "../GridContainer/GridContainer";

interface Props {
  data: Order;
  orderlines: OrderLine[];
  updateTags: (tags: string[]) => void;
  onSendToErp: () => void;
  path: string;
  teams: Team[];
  fulfillmentDefinitions: FulfillmentDefinition[];
  load: () => void;
  updateTeam: (id: string, teamId: string) => void;
  updateFulfillmentDefinition: (
    id: string,
    defintionId: string,
    list: boolean
  ) => void;
  errorFulfillment: string;
  orderState: string;
  setState: (id: string, name: string, w3w: string, images: string[]) => void;
  onDeleteEvent: (id: string) => void;
  onCreateEvent: (event: FulfillmentEvent, orderlineId: string) => void;
  onSaveEvent: (event: FulfillmentEvent) => void;
  updateFulfiller: (
    eventId: string,
    fulfillerId: string,
    orderlineId: string
  ) => void;
  onEditOrderline: (o: CreateOrderline) => void;
  onCloseOrderline: (id: string) => void;
  onCloseOrder: () => void;
  onSaveCustomer: (data: Customer) => void;
  edit: boolean;
  setEdit: (value: boolean) => void;
  onEditPlacement: (event: FulfillmentEvent, placement: Placement) => void;
  onDeletePlacement: (event: FulfillmentEvent, id: string) => void;
  onSaveNotes: (notes: string) => void;
  assets: Asset[];
  updateAsset: (event: FulfillmentEvent, id: string) => void;
  onSaveLoad: (eventId: string, data: Load[]) => void;
  saveLoc: (p: Placement, type: string, group: string) => void;
}

const OrderDetail: React.FC<Props> = ({
  data,
  updateTags,
  onSendToErp,
  teams,
  fulfillmentDefinitions,
  onDeleteEvent,
  onCreateEvent,
  updateTeam,
  updateFulfiller,
  updateFulfillmentDefinition,
  errorFulfillment,
  orderState,
  setState,
  load,
  path,
  onCloseOrderline,
  onEditOrderline,
  onCloseOrder,
  edit,
  setEdit,
  onSaveCustomer,
  orderlines,
  onDeletePlacement,
  onEditPlacement,
  onSaveEvent,
  onSaveNotes,
  assets,
  updateAsset,
  saveLoc,
  onSaveLoad,
}) => {
  const [tags, setTags] = useState(data.tags);

  const [customer, setCustomer] = useState(data.customer);

  const onChangeCustomer = <P extends keyof Customer>(
    prop: P,
    value: Customer[P]
  ) => {
    setCustomer({ ...customer, [prop]: value });
  };

  const { t } = useTranslation();
  const values = [
    data.customer.fullName,
    data.customer.email,
    data.customer.phone,
    data.customer.externalId,
  ];

  const renderCustomerInfo = () => {
    const map = values
      .filter((item) => item !== "" && item !== " ")
      .map((item, index) => (
        <div
          key={index + 100}
          className={styles.valueSmall}
          onClick={() => {
            if (item) {
              navigator.clipboard.writeText(item);
              message.success(`${item}  ${t("component.orderDetail.copy")}`);
            }
          }}
        >
          <FlexContainer alignItems="flex-start" justifyContent="space-between">
            {item}
            {!item ? null : (
              <img alt="" src={copyIcon} className={styles.copy} />
            )}
          </FlexContainer>
        </div>
      ));
    return map;
  };

  const entityOptions = teams.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const fulfillmentOptions = fulfillmentDefinitions.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  return (
    <>
      <div className={styles.container}>
        <GridContainer gap="1em" columns="1.5fr 3fr">
          <div>
            <Card containerClassname={styles.sectionWrapper}>
              <OrderTitle
                label={t("component.orderDetail.channel")}
                value={`${t("component.orderDetail.externalId")}: ${
                  data.externalId
                }`}
              />
              {data.channel.name ? (
                <Text
                  padding="0.5em 0 0 0"
                  color="#333"
                  text={`${t("component.orderDetail.name")}: ${
                    data.channel.name
                  }`}
                />
              ) : null}
              {data.channel.id ? (
                <Text
                  padding="0.5em 0 0.5em 0"
                  color="#333"
                  text={`${t("component.orderDetail.id")} ${data.channel.id}`}
                />
              ) : null}

              {data.channel.orderCreationDate ? (
                <Text
                  color="#333"
                  padding="0 0 0.5em 0"
                  text={`${t("component.orderDetail.createdAt")} ${formatDate(
                    data.channel.orderCreationDate,
                    "MIDDLEWHOUR"
                  )}`}
                />
              ) : null}

              {data.channel.createdAt ? (
                <Text
                  color="#333"
                  padding="0 0 0.5em 0"
                  text={`${t("component.orderDetail.createdAt")} ${formatDate(
                    data.channel.createdAt
                  )}`}
                />
              ) : null}
              {data.channel.createdBy ? (
                <Text
                  color="#333"
                  text={`${t("component.orderDetail.by")} ${
                    data.channel.createdBy
                  }`}
                />
              ) : null}
              {data.channel.employeeId ? (
                <Text
                  padding="0.5em 0 0 0"
                  color="#333"
                  text={`${t("component.orderDetail.employeeId")}: ${
                    data.channel.employeeId
                  }`}
                />
              ) : null}
              {data.channel.employeeName ? (
                <Text
                  padding="0.5em 0 0 0"
                  color="#333"
                  text={`${t("component.orderDetail.employeeName")}: ${
                    data.channel.employeeName
                  }`}
                />
              ) : null}
            </Card>
            {edit ? (
              <EditCustomer
                onSave={() => onSaveCustomer(customer)}
                onCancel={() => setEdit(false)}
                data={customer}
                onChange={onChangeCustomer}
              />
            ) : (
              <Card containerClassname={styles.sectionWrapper}>
                <div>
                  <OrderTitle
                    label={t("component.orderDetail.customerInfo")}
                    buttonComponent={
                      <EditIcon
                        onClick={() => setEdit(true)}
                        style={{ width: "1.3em", cursor: "pointer" }}
                      />
                    }
                    component={
                      <div>
                        {values.filter((item) => item !== "").length < 1 ? (
                          <div>anonymous</div>
                        ) : (
                          renderCustomerInfo()
                        )}
                      </div>
                    }
                  />
                </div>
              </Card>
            )}

            <Card containerClassname={styles.tagsContainer}>
              <OrderTitle label={t("component.orderDetail.tags")} />
              <InputTags
                value={tags}
                onChange={(value) => {
                  setTags(value);
                  updateTags(value);
                }}
              />
            </Card>

            <NoteCard
              notes={data.notes}
              onSave={(value) => onSaveNotes(value)}
            />

            {data.walletId ? (
              <Card>
                <div className={styles.walletWrapper}>
                  <OrderTitle
                    label={t("component.orderDetail.walletId")}
                    value={`ID: ${data.walletId}`}
                  />
                </div>
              </Card>
            ) : null}
            {data.erp && data.erp.name ? (
              <ERPCard
                orderUrl={data.erp.orderUrl}
                customerUrl={data.erp.customerUrl}
                name={data.erp.name}
                clientId={data.erp.data.clientId}
                referenceId={data.erp.referenceId}
                onSendToErp={onSendToErp}
              />
            ) : null}
            {data.venueName ? (
              <Card containerClassname={styles.tagsContainer}>
                <OrderTitle
                  label={t("component.orderDetail.venueName")}
                  value={data.venueName}
                />
              </Card>
            ) : null}
          </div>
          <Card>
            <div className={styles.itemsWrapper}>
              <FlexContainer justifyContent="space-between">
                <div style={{ width: "100%" }}>
                  <FlexContainer
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    {data.fulfillmentLevel === "none" ||
                    data.fulfillmentLevel === "orderline" ? null : (
                      <div style={{ marginRight: "0.5em" }}>
                        {data.state === "fulfilled" ? (
                          <img
                            style={{ marginRight: "0.1em" }}
                            src={fulfillerGreen}
                            alt=""
                          />
                        ) : (
                          <Tooltip
                            title={t(
                              "component.orderDetailItemList.markAsDone"
                            )}
                          >
                            <div
                              style={{
                                width: "1.5em",
                                marginRight: "0.5em",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                onCloseOrder();
                              }}
                            >
                              <FulfillerIcon />
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    )}

                    <OrderTitle label={`${data.description}`} />
                  </FlexContainer>

                  <div>
                    <Text
                      color="#333"
                      text={`${t("component.orderDetail.orderLines")} (${
                        orderlines.length
                      })`}
                    />
                  </div>
                </div>
              </FlexContainer>
            </div>

            <OrderDetailItemList
              data={data}
              saveLoc={saveLoc}
              onEditOrderline={onEditOrderline}
              onSaveLoad={onSaveLoad}
              onDeletePlacement={onDeletePlacement}
              onEditPlacement={onEditPlacement}
              onSaveEvent={onSaveEvent}
              orderlines={orderlines}
              fulfillmentLevel={data.fulfillmentLevel}
              onDeleteEvent={(i) => onDeleteEvent(i)}
              onCloseOrderline={onCloseOrderline}
              onCreateEvent={(location, orderlineId) =>
                onCreateEvent(location, orderlineId)
              }
              assets={assets}
              updateAsset={updateAsset}
              setState={(id, name, w3w, images) =>
                setState(id, name, w3w, images)
              }
              orderState={orderState}
              errorFulfillment={errorFulfillment}
              fulfillmentDefinitions={fulfillmentOptions}
              load={load}
              sortable
              teams={[...entityOptions]}
              updateTeam={(id: string, teamId: string) =>
                updateTeam(id, teamId)
              }
              updateFulfillmentDefinition={(
                id: string,
                fulfillmentId: string,
                list
              ) => updateFulfillmentDefinition(id, fulfillmentId, list)}
              path={path}
              updateFulfiller={updateFulfiller}
            />
          </Card>
        </GridContainer>
      </div>
    </>
  );
};

export default OrderDetail;
