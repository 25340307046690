import React from 'react';
import { classes } from 'typestyle';
import styles from './styles.module.css';

export interface CircleButtonProps {
  day: string;
  selected?: boolean;
  onClick?: () => void;
}

export const CircleButton: React.FC<CircleButtonProps> = ({ day, selected, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={classes(styles.circleButton, {
        [styles.selectedCircle]: selected
      })}
    >
      {day}
    </div>
  );
};
