import { stylesheet } from "typestyle";
import { black, lightGrey, linkBlue } from "../../utils/colors";

const styles = stylesheet({
  containerDetail: {
    color: black,
    padding: "1em 0",
    marginBottom: "1em",
  },
  containerCancel: {
    color: "#A5A5A5 !important",
    padding: "1em 0",
    marginBottom: "1em",
  },
  pointer: {
    cursor: "pointer",
    display: "flex",
    flexWrap: "wrap",
  },
  line: {
    width: "100%",
    height: "1px",
    margin: "1em 0",
    background: lightGrey,
  },
  lineSmall: {
    width: "90%",
    height: "1px",
    margin: "1em 0",
    background: lightGrey,
  },
  commentsContainer: {
    borderBottom: `1px solid ${lightGrey}`,
    paddingBottom: "1em",
    marginBottom: "1em",
  },
  closeButton: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    color: linkBlue,
    cursor: "pointer",
  },
  buttonContainer: {
    marginRight: "1em",
  },
  chevron: {
    width: "36px",
    color: linkBlue,
    marginLeft: "0.5em",
  },
  icon: {
    width: "1.5em",
    cursor: "pointer",
  },

  iconEdit: {
    width: "1.3em",
  },
  title: {
    padding: "0 0 1em 0",
    fontWeight: 700,
  },
  bold: {
    fontWeight: 700,
  },
  medium: {
    fontWeight: 500,
  },
  big: {
    textTransform: "uppercase",
  },
  gray: {
    color: "#858D8F",
  },
  box: {
    border: "1px solid #DCE1E6",
    borderRadius: "2px",
    padding: "1em",
    marginRight: "3em",
  },
  editContainer: {
    borderRadius: 3,
    border: "1px solid #DCE1E6",
    display: "flex",
    justifyContent: "center",
    padding: "0em 0.6em",
    marginLeft: "1em",
    background: "white",
    cursor: "pointer",
    height: "2.5em",
  },
});

export default styles;
