const translation = {
  en: {
    invoices: "Invoices",
    events: "Events",
    hash: "Order Secret Key",
    secretKey: "Secret Key",
    actions: "Actions",
    actionName: "Action Name",

    digest: "Digest Algorithm",
    configureButton: "Configure",
    paymentStates: "Payment States",
    addIntegration: "+Add new integration",
    configured: "Secret was last set on",
    by: "by",
    addAction: "+ Add Action",
    notConfigured:
      "Please enter the preshared secret key that you would like Ravent to use to compute the MAC (Message Authentication Code)",
    explanation:
      "Order states are pre-defined by Ravent and may not be changed by customers",
    url: "Order states are pre-defined by Ravent and may not be changed by customers, the URL allows organizations to configure the URL of a web-hook that Ravent will send notifications to. Every time the order reaches that state, a call to the web-hook will be generated and your platform will be notified.",
    paymentURL:
      "Payment states are pre-defined by Ravent and may not be changed by customers, the URL allows organizations to configure the URL of a web-hook that Ravent will send notifications to. Every time the order reaches that state, a call to the web-hook will be generated and your platform will be notified.",
  },
  es: {
    actionName: "Nombre de la Acción",
    invoices: "Facturas",
    events: "Eventos",
    addAction: "+ Agregar Acción",
    addIntegration: "+Agregar Integración",
    others: "Acciones",
    paymentStates: "Estados de Pago",
    hash: "Clave secreta del pedido",
    secretKey: "Clave secreta",
    digest: "Algoritmo de resumen",
    configureButton: "Configurar",
    configured: "El secreto se configuró por última vez",
    bye: "por",
    notConfigured:
      "Ingrese la clave secreta precompartida que le gustaría que Ravent use para calcular el MAC (Código de autenticación de mensajes)",
    explanation:
      "Los estados de líneas están predefinidos por Ravent y los clientes no pueden cambiarlos",
    url: "Los estados de orden están predefinidos por Ravent y los clientes no pueden cambiarlos. La URL permite a las organizaciones configurar la URL de un web-hook al que Ravent enviará notificaciones. Cada vez que el pedido alcance ese estado, una llamada a se generará el web-hook y se notificará a tu plataforma.",
    paymentURL:
      "Los estados de pago están predefinidos por Ravent y los clientes no pueden cambiarlos. La URL permite a las organizaciones configurar la URL de un web-hook al que Ravent enviará notificaciones. Cada vez que el pedido alcance ese estado, una llamada a se generará el web-hook y se notificará a tu plataforma.",
  },
  de: {},
};

export default translation;
