import React, { useState } from "react";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { GridContainer } from "../GridContainer/GridContainer";
import { BankAccount, FOPCatalog } from "../../utils/types";
import Text from "../Text";
import PaymentCard from "./PaymentCard";
import { fopTitleMap } from "../../utils/fop";
import CatalogCard from "./CatalogCard";
import AccountForm from "./AccountForm";
import InputCard from "./InputCard";
import { useTranslation } from "react-i18next";
import styles from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons";
import NoCatalog from "./NoCatalog";
import { roundOptions } from "../../constants/integrations";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { SideMenu } from "../SideMenu/SideMenu";
import { InputSelect } from "../Inputs/InputSelect";
import Search from "antd/es/input/Search";

interface Props {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  fopCatalog: FOPCatalog[];
  account: BankAccount;
  onChangeAccount: <P extends keyof BankAccount>(
    prop: P,
    value: BankAccount[P]
  ) => void;
  onCreate: (catalogId: string, round: string) => void;
  load?: () => void;
}

const FOPForm: React.FC<Props> = ({
  onClose,
  open,
  fopCatalog,
  account,
  loading,
  onChangeAccount,
  onCreate,
}) => {
  const [step, setStep] = useState(1);
  const [type, setType] = useState("card-reader");
  const [catalogId, setCatalog] = useState("");
  const [round, setRound] = useState("none");

  const { t } = useTranslation();

  const paymentMethods = [
    "card-reader",
    "payment-link",
    "in-store",
    "cash",
    "wire-transfer",
  ];

  const catalogFilter = fopCatalog.filter((item) => type === item.type);

  return (
    <SideMenu
      width="55%"
      open={open}
      onClose={() => {
        onClose();
        setStep(1);
        setRound("");
        setCatalog("");
        setType("card-reader");
      }}
    >
      <FlexContainer
        alignItems="flex-start"
        justifyContent="space-between"
        padding="2em 0 2em 0"
      >
        <FlexContainer alignItems="flex-start" flexDirection="column">
          {step === 1 ? (
            <ContainerTitle
              size="medium"
              label={t("component.fopForm.selectFOP")}
            />
          ) : null}
          {step === 3 ? (
            <FlexContainer justifyContent="flex-start" alignItems="center">
              <FontAwesomeIcon
                onClick={() => setStep(2)}
                size="2x"
                color="#333333"
                icon={faChevronLeft as IconProp}
                className={styles.icon}
              />
              <ContainerTitle
                padding="0 0 0em 0"
                size="medium"
                label={t("component.fopForm.accountConfig")}
              />
            </FlexContainer>
          ) : null}

          {step === 4 ? (
            <FlexContainer justifyContent="flex-start" alignItems="center">
              <FontAwesomeIcon
                onClick={() => setStep(3)}
                size="2x"
                color="#333333"
                icon={faChevronLeft as IconProp}
                className={styles.icon}
              />
              <ContainerTitle
                padding="0 0 0em 0"
                size="medium"
                label={t("component.fopForm.round")}
              />
            </FlexContainer>
          ) : null}
          {step === 2 ? (
            <FlexContainer justifyContent="flex-start" alignItems="center">
              <FontAwesomeIcon
                onClick={() => setStep(1)}
                size="2x"
                color="#333333"
                icon={faChevronLeft as IconProp}
                className={styles.icon}
              />
              <ContainerTitle size="medium" label={fopTitleMap[type]} />
            </FlexContainer>
          ) : null}
          <Text
            text={`${t("component.fopForm.step")} ${step} ${t(
              "component.fopForm.of"
            )} 4`}
            fontSize="1.1em"
            fontWeight={400}
          />
          {step === 3 ? (
            <Text
              padding="1em 0 0 0"
              text={t("component.fopForm.skip")}
              fontSize="1em"
              fontWeight={400}
            />
          ) : null}
          {step === 4 ? (
            <Text
              padding="1em 0 0 0"
              text={t("component.fopForm.roundInstructions")}
              fontSize="1em"
              fontWeight={400}
            />
          ) : null}
        </FlexContainer>

        <ButtonsContainer>
          <Button
            label={t("component.fopForm.cancel")}
            onClick={() => {
              onClose();
              setStep(1);
              setRound("");
              setCatalog("");
              setType("card-reader");
            }}
            theme="white"
          />
          <Button
            label={
              loading
                ? "..."
                : step === 4
                ? t("component.fopForm.save")
                : t("component.fopForm.next")
            }
            disable={
              (step === 1 && !type) ||
              (step === 2 &&
                !catalogFilter.length &&
                type !== "wire-transfer") ||
              (step === 2 && !catalogId && type !== "wire-transfer")
            }
            onClick={async () => {
              if (step === 1) {
                setStep(2);
              }
              if (step === 2) {
                setStep(3);
              }
              if (step === 3) {
                setStep(4);
              }
              if (step === 4) {
                try {
                  await onCreate(catalogId, round);
                  setType("");
                  setCatalog("");
                  setStep(1);
                } catch (err) {}
              }
            }}
          />
        </ButtonsContainer>
      </FlexContainer>
      {step === 1 ? (
        <div>
          <ContainerTitle size="small" label={t("component.fopForm.type")} />
          <GridContainer padding="1em 0" gap="2em" columns="1fr 1fr">
            {paymentMethods.map((item, index) => (
              <PaymentCard
                key={index}
                active={type === item}
                onSelectType={(type) => setType(type)}
                type={item}
              />
            ))}
          </GridContainer>
        </div>
      ) : null}
      {step === 2 && fopCatalog.length ? (
        <div>
          <ContainerTitle
            padding="0 0 1em 0"
            size="small"
            label={t("component.fopForm.supplier")}
          />
          {catalogFilter.length >= 1 ? <Search onSearch={() => {}} /> : null}
          <GridContainer padding="1em 0 0 0" gap="2em" columns="1fr 1fr">
            {catalogFilter.length < 1 ? (
              <InputCard account={account} onChange={onChangeAccount} />
            ) : (
              catalogFilter.map((item) => (
                <CatalogCard
                  key={item.id}
                  active={item.id === catalogId}
                  onSelectCatalog={() => setCatalog(item.id)}
                  name={item.supplierName}
                  logo={item.supplierLogo}
                />
              ))
            )}
          </GridContainer>
        </div>
      ) : null}
      {step === 2 && !fopCatalog.length ? <NoCatalog /> : null}

      {step === 3 ? (
        <AccountForm edit={false} data={account} onChange={onChangeAccount} />
      ) : null}
      {step === 4 ? (
        <GridContainer gap="1em" columns="1fr 1fr">
          <InputSelect
            value={round}
            onChange={(value) => setRound(value)}
            label={t("component.fopForm.round")}
            options={roundOptions}
          />
        </GridContainer>
      ) : null}
    </SideMenu>
  );
};

export default FOPForm;
