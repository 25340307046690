import React from "react";
import { FormComponent } from "../../utils/types";

import styles from "./styles";
import { useTranslation } from "react-i18next";
import SchemaForm from "./SchemaForm";
import { message } from "antd";
import { SideMenu } from "../SideMenu/SideMenu";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { Button } from "../Button";
import { InputText } from "../Inputs/InputText";

interface Props {
  onClose: () => void;
  open: boolean;
  invoiceId: string;
  cancelSchemaComponents: FormComponent[];
  onChangeSchema: (data: FormComponent[]) => void;
  loading: boolean;
  webhook: string;
  onCancelInvoice: () => void;
}

const CancelInvoiceDyn: React.FC<Props> = ({
  open,
  onClose,
  cancelSchemaComponents,
  onChangeSchema,
  loading,
  webhook,
  invoiceId,
  onCancelInvoice,
}) => {
  const { t } = useTranslation();

  return (
    <SideMenu width="50%" open={open} onClose={() => onClose()}>
      <div className={styles.container}>
        <FlexContainer padding="1em 0 2em 0" justifyContent="space-between">
          <ContainerTitle
            label={t("component.cancelInvoiceDyn.cancelInvoice")}
            size="medium"
          />
          <FlexContainer justifyContent="flex-start">
            <Button
              onClick={() => {
                onClose();
              }}
              containerClassname={styles.button}
              theme="white"
              label={t("component.cancelInvoiceDyn.close")}
            />
            <Button
              onClick={() => {
                if (!webhook) {
                  message.error(
                    "The venue to which you are associated has not been properly configured to issue a manual cancellation. Please go to the Integrations Side Menu, open the Invoices tab and insert a valid URL for the Invoice Cancellation entry."
                  );
                  return;
                }
                onCancelInvoice();
              }}
              theme="red"
              label={loading ? "..." : t("component.cancelInvoiceDyn.cancel")}
            />
          </FlexContainer>
        </FlexContainer>
        <InputText
          disabled
          label={t("component.cancelInvoiceDyn.invoiceId")}
          value={invoiceId}
        />
        <SchemaForm
          elements={cancelSchemaComponents}
          onChange={(elements) => onChangeSchema(elements)}
        />
      </div>
    </SideMenu>
  );
};

export default CancelInvoiceDyn;
