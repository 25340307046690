const translation = {
  en: {
    cancel: "Cancel",
    delete: "Delete",
  },
  es: {
    cancel: "Cancelar",
    delete: "Eliminar",
  },
  de: {},
};

export default translation;
