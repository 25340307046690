import React from "react";
import styles from "./styles";
import { Popover } from "antd";
import { ReactComponent as CheckIcon } from "../../assets/checked+circle.svg";
import { ReactComponent as PlayIcon } from "../../assets/play.svg";
import { ReactComponent as StopIcon } from "../../assets/reset.svg";

import { ReactComponent as WarningIcon } from "../../assets/white-warning.svg";
import { ReactComponent as WhiteCheckIcon } from "../../assets/white-check.svg";
import { ReactComponent as WhitePlayIcon } from "../../assets/white-play.svg";

import Text from "../Text";
import { FlexContainer } from "../FlexContainer/FlexContainer";

interface Props {
  id: string;
  setNew: (id: string, state: string) => void;
  loading: { loading: boolean; state: string };
  state: string;
}

const MarkEvent: React.FC<Props> = ({ id, setNew, loading, state }) => {
  return (
    <>
      <Popover
        placement="left"
        content={
          <div>
            <Text
              padding="0 0 0.5em 0"
              color="#000"
              fontWeight={600}
              text={"Mark event as:"}
            />
            <FlexContainer justifyContent="space-evenly">
              <div
                className={styles.yellow}
                style={{ marginRight: "0.5em" }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (loading.state === "failed" && loading.loading) return;
                  setNew(id, "failed");
                }}
              >
                <WarningIcon
                  style={{ marginRight: "0.2em" }}
                  className={styles.smallIcon}
                />

                <Text
                  color="#fff"
                  fontWeight={600}
                  text={
                    loading.state === "failed" && loading.loading
                      ? "..."
                      : "Failed"
                  }
                />
              </div>
              <div
                className={styles.blue}
                onClick={(e) => {
                  e.stopPropagation();
                  if (loading.state === "completed" && loading.loading) return;

                  setNew(id, "completed");
                }}
              >
                <WhiteCheckIcon
                  style={{ marginRight: "0.2em" }}
                  className={styles.smallIcon}
                />

                <Text
                  color="#fff"
                  fontWeight={600}
                  text={
                    loading.state === "completed" && loading.loading
                      ? "..."
                      : "Fulfilled"
                  }
                />
              </div>
            </FlexContainer>
          </div>
        }
      >
        <CheckIcon className={styles.icon} />
      </Popover>
      {state === "running" ? (
        <Popover
          placement="left"
          content={
            <div>
              <Text
                padding="0 0 0.5em 0"
                color="#000"
                fontWeight={600}
                text={"Stop running this event:"}
              />

              <div
                className={styles.white}
                onClick={(e) => {
                  e.stopPropagation();
                  if (loading.state === "pending" && loading.loading) return;
                  setNew(id, "pending");
                }}
              >
                <StopIcon
                  style={{ marginRight: "0.2em" }}
                  className={styles.smallIcon}
                />
                <Text
                  color="#333"
                  fontWeight={600}
                  text={
                    loading.state === "pending" && loading.loading
                      ? "..."
                      : "Reset"
                  }
                />
              </div>
            </div>
          }
        >
          <PlayIcon className={styles.iconDelete} />
        </Popover>
      ) : (
        <Popover
          placement="left"
          content={
            <div>
              <Text
                padding="0 0 0.5em 0"
                color="#000"
                fontWeight={600}
                text={"Start running this event:"}
              />

              <div
                className={styles.green}
                onClick={(e) => {
                  e.stopPropagation();
                  if (loading.state === "running" && loading.loading) return;
                  setNew(id, "running");
                }}
              >
                <WhitePlayIcon
                  style={{ marginRight: "0.2em" }}
                  className={styles.smallIcon}
                />
                <Text
                  color="#fff"
                  fontWeight={600}
                  text={
                    loading.state === "running" && loading.loading
                      ? "..."
                      : "Run Now"
                  }
                />
              </div>
            </div>
          }
        >
          <PlayIcon className={styles.iconDelete} />
        </Popover>
      )}
    </>
  );
};

export default MarkEvent;
