import { Payment } from "@innomius/ravent-typescript-types";
import { message } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, useHistory, useLocation } from "react-router-dom";
import { invoiceMexico } from "../../mocks/invoices";
import APIError from "../../utils/APIError";
import { AddInvoiceRequest, FormComponent } from "../../utils/types";
import AddInvoice from "../AddInvoice";
import InvoiceForm from "../InvoiceForm";
import { notifyWebHook } from "../../services/orders";

interface Props {
  path: string;
  defaultErpId: string;
  requestsPayments: Payment[];
  payments: Payment[];
  orderAmount: string;
  orderId: string;
  load: () => void;
  invoiceActionId: string;
  invoiceIssuance: string;
  paymentState: string;
  currency: string;
}

const OrderInvoiceF: React.FC<Props> = ({
  defaultErpId,
  path,
  requestsPayments,
  payments,
  orderAmount,
  orderId,
  load,
  currency,
  invoiceIssuance,
  paymentState,
  invoiceActionId,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [schemeElements, setElements] = useState<FormComponent[]>(
    invoiceMexico.elements
  );

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const onIssueInvoice = async (
    orderId: string,
    customer: AddInvoiceRequest,
    invoice: FormComponent[]
  ) => {
    try {
      setLoading(true);
      const map = invoice.map((item) => [item.name, item.value]);

      const body = !customer.zipCode
        ? {
            orderId: orderId,
            invoiceData: {
              ...{ erpId: customer.erpId },
              ...Object.fromEntries(map),
            },
          }
        : {
            orderId: orderId,
            invoiceData: {
              ...customer,
              ...Object.fromEntries(map),
            },
          };

      const res = await notifyWebHook({
        actionId: invoiceActionId,
        notificationBody: body,
      });
      if (res.successful === false) {
        message.error({
          content: `${res.statusCode} ${res.message}`,
        });
      }
      if (res.successful === true) {
        message.success(t("component.orderInvoiceF.success"));
      }

      setLoading(false);
      load();
    } catch (err) {
      setLoading(false);
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  return (
    <div>
      <Route exact path={`${path}/invoices/new`}>
        <InvoiceForm
          invoiceIssuance={invoiceIssuance}
          paymentState={paymentState}
          webhook={invoiceActionId}
          invoiceSchemaComponents={schemeElements}
          onChangeSchema={(elements) => setElements(elements)}
          defaultErpId={defaultErpId}
          loading={loading}
          open={location.pathname === `${path}/invoices/new`}
          onCancel={() => {
            history.push(`${path}`);
            load();
          }}
          onIssueInvoice={(customer) =>
            onIssueInvoice(orderId, customer, schemeElements)
          }
        />
      </Route>
      <Route exact path={`${path}/invoices/addInvoice`}>
        <AddInvoice
          load={load}
          currency={currency}
          orderId={orderId}
          onCancel={() => history.push(`${path}`)}
          orderAmount={orderAmount}
          requestsPayments={requestsPayments}
          payments={payments}
          open={location.pathname === `${path}/invoices/addInvoice`}
        />
      </Route>
    </div>
  );
};

export default OrderInvoiceF;
