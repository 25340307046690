const translation = {
  en: {
    tickets: "Tickets",
    issueTickets: "Tickets",
    shareTickets: "Share Tickets",
    instructions: "Fill in the tickets with customer name",
    alreadyIssued: "Already Issued Tickets",
    noCustomerName: "No Customer Name",
    activation: "Activation",
    lifeTime: "Lifetime",
    customerFullName: "Customer Full Name",
    on: "On",
  },
  es: {
    tickets: "Boletos",
    issueTickets: "Boletos",
    shareTickets: "Compartir Boletos",
    instructions: "Rellenar los boletos con el nombre del cliente",
    alreadyIssued: "Boletos emitidos",
    noCustomerName: "Sin nombre de cliente",
    activation: "Activación",
    lifeTime: "Tiempo de vida",
    customerFullName: "Nombre completo del cliente",
    on: "Encendido",
  },
  de: {},
};

export default translation;
