const translation = {
  en: {
    issueRefund: "Issue Refund",
    instructions: "The amount to be refunded excluiding penalities is",
    refundType: "Refund Type",
    selectItem: "Select the item(s) you would like to be refunded.",
    penalty: "Penalty %",
    penaltyAmount: "Penalty Amount",
    finalRefundAmount: "Final Refund Amount",
    refundInformationMethod: "Refund Information Method",
    cancel: "Cancel",
    issue: "Issue",
    orderAmount: "Order Amount",
    tax: "Tax",
    totalRefund: "Total Refund",
    expiredDate: "Expired Date",
  },
  es: {
    issueRefund: "Emitir reembolso",
    instructions: "El importe a devolver sin penalizaciones es",
    refundType: "Tipo de reembolso",
    selectItem:
      "Seleccione los artículos que le gustaría recibir el reembolso.",
    penalty: "Penalización %",
    penaltyAmount: "Cantidad de Penalización",
    finalRefundAmount: "Importe del reembolso final",
    refundInformationMethod: "Método de información de reembolso",
    cancel: "Cancelar",
    issue: "Emitir",
    orderAmount: "Importe del pedido",
    tax: "Impuestos",
    totalRefund: "Reembolso total",
    expiredDate: "Fecha de vencimiento",
  },
  de: {},
};

export default translation;
