import React, { useState } from "react";
import OrderTitle from "./OrderTitle";
import { Card } from "../Card/Card";

import acceptIcon from "../../assets/officialMedia/default-checkmark.svg";
import quitIcon from "../../assets/quit.svg";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import Text from "../Text";

import { useTranslation } from "react-i18next";
import styles from "./styles";
import { classes, style } from "typestyle";
import { darkBlack } from "../../utils/colors";
import { GridContainer } from "../GridContainer/GridContainer";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ButtonsContainer } from "../ButtonsContainer";
import { InputTextarea } from "../Inputs/InputTextArea";

interface Props {
  notes: string;
  onSave: (notes: string) => void;
}

const labelStyle = style({
  color: darkBlack,
  fontWeight: "bold",
  fontSize: "1.4em",
});

const NoteCard: React.FC<Props> = ({ notes, onSave }) => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [text, setText] = useState(notes || "");

  return (
    <Card containerClassname={styles.sectionWrapper}>
      {edit ? (
        <div>
          <GridContainer alignItems="center" columns={"3fr"}>
            <FlexContainer padding="0 0 1em 0" justifyContent="space-between">
              <p className={classes(labelStyle, {})}>
                {t("component.orderDetail.notes")}
              </p>
              <ButtonsContainer justifyContent="flex-end">
                <img
                  src={quitIcon}
                  alt="quit"
                  style={{ width: "2em", cursor: "pointer" }}
                  onClick={() => {
                    setEdit(false);
                  }}
                />
                <img
                  src={acceptIcon}
                  style={{ cursor: "pointer" }}
                  alt="accept"
                  onClick={() => onSave(text)}
                />
              </ButtonsContainer>
            </FlexContainer>

            <InputTextarea value={text} onChange={(value) => setText(value)} />
          </GridContainer>
        </div>
      ) : (
        <div>
          <OrderTitle
            label={t("component.orderDetail.notes")}
            buttonComponent={
              <EditIcon
                onClick={() => setEdit(true)}
                style={{ width: "1.3em", cursor: "pointer" }}
              />
            }
          />
          <Text padding="0.5em 0 0 0" color="#333" text={notes || "-"} />
        </div>
      )}
    </Card>
  );
};

export default NoteCard;
