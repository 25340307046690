import React, { useState } from "react";

import styles from "./styles";
import { useTranslation } from "react-i18next";
import { Action } from "@innomius/ravent-typescript-types";
import Editor from "@monaco-editor/react";
import Text from "../Text";
import { useHistory, useLocation } from "react-router-dom";
import { SideMenu } from "../SideMenu/SideMenu";
import { ButtonsContainer } from "../ButtonsContainer";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { Button } from "../Button";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputText } from "../Inputs/InputText";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
import { EditableTitle } from "../EditableTitle";
import { Switch } from "antd";

interface Props {
  data: Action;
  onSaveAction: (data: Action) => void;
}

const CreateAction: React.FC<Props> = ({ data, onSaveAction }) => {
  const [action, setAction] = useState<Action>(data);

  const { t } = useTranslation();

  const onChange = <P extends keyof Action>(prop: P, value: Action[P]) => {
    setAction({ ...action, [prop]: value });
  };

  const location = useLocation();
  const history = useHistory();

  return (
    <SideMenu
      width="60%"
      onClose={() => {
        history.goBack();
        setAction(data);
      }}
      open={location.pathname === "/integrations/actions/new"}
    >
      <div className={styles.itemsWrapper}>
        <FlexContainer justifyContent="space-between">
          <EditableTitle
            padding="0 0 1em 0"
            value={action.name}
            placeholder={"Name"}
            onChange={(value) => onChange("name", value)}
          />
          <ButtonsContainer justifyContent="flex-start">
            <Button
              label="Cancel"
              theme="white"
              onClick={() => {
                history.goBack();
                setAction(data);
              }}
            />
            <Button
              label="Save"
              onClick={async () => {
                onSaveAction(action);
              }}
            />
          </ButtonsContainer>
        </FlexContainer>
        <div>
          <GridContainer padding="0em 0 1em 0" gap="1em" columns="3fr 1fr">
            <InputText
              value={action.url}
              showError={false}
              onChange={(value) => onChange("url", value)}
              label={t("component.addIntegration.flowUrl")}
            />
            <div />
            <InputText
              showError={false}
              value={action.description}
              onChange={(value) => onChange("description", value)}
              label={t("component.addIntegration.description")}
            />
            <div />
          </GridContainer>
          <InputText
            showError={false}
            value={action.dataCollectionUrl}
            onChange={(value) => onChange("dataCollectionUrl", value)}
            label={t("component.orderActionList.dataCollectionUrl")}
          />
          <FlexContainer padding="2em 0 0 0" justifyContent="flex-start">
            <Switch
              checked={action.orderOnly}
              onChange={(checked) => onChange("orderOnly", checked)}
            />
            <Text
              padding="0 0 0 1em"
              text={t("component.orderActionList.orderOnly")}
            />
          </FlexContainer>
          <FlexContainer padding="2em 0 1em 0" justifyContent="flex-start">
            <Switch
              checked={action.manualTrigger}
              onChange={(checked) => onChange("manualTrigger", checked)}
            />
            <Text
              padding="0 0 0 1em"
              text={t("component.orderActionList.manualTrigger")}
            />
          </FlexContainer>
          <InputLabel label={t("component.orderActionList.notificationBody")} />
          <Editor
            onChange={(value) => onChange("notificationBody", value as string)}
            className={styles.editorBox}
            height="100%"
            // theme="vs-dark"
            defaultLanguage="json"
            value={action.notificationBody as string}
            options={{
              selectOnLineNumbers: true,
              // minimap: { enabled: false },
              scrollbar: {
                vertical: "hidden",
              },
              overviewRulerLanes: 0,
              hideCursorInOverviewRuler: true,
              overviewRulerBorder: false,
              foldingHighlight: false,
            }}
          />
        </div>
      </div>
    </SideMenu>
  );
};

export default CreateAction;
