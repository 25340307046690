import React, { useState } from "react";
import Text from "../Text";
import { Modal } from "../Modal/Modal";

import styles from "./styles";

import { useTranslation } from "react-i18next";
import { Asset } from "../../utils/types";
import { Button } from "../Button";
import { ButtonsContainer } from "../ButtonsContainer";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { InputSelect } from "../Inputs/InputSelect";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";

interface Props {
  onClose: () => void;
  open: boolean;
  data: any[];
  loading: boolean;
  onDelete: (replace: string) => void;
  assets: Asset[];
}

const DeleteAssetsModal: React.FC<Props> = ({
  onClose,
  open,
  loading,
  data,
  onDelete,
  assets,
}) => {
  const { t } = useTranslation();
  const [replace, setReplace] = useState("");

  return (
    <div className={styles.container}>
      <Modal
        width={"50%"}
        buttons={
          <div>
            <ButtonsContainer justifyContent="flex-end">
              <Button
                onClick={() => {
                  onClose();
                }}
                theme="white"
                label={t("component.deleteAssetsModal.close")}
              />
              <Button
                theme="red"
                disable={!data.length}
                onClick={() => {
                  if (loading) {
                    return;
                  }
                  onDelete(replace);
                }}
                label={
                  loading ? "..." : t("component.deleteAssetsModal.delete")
                }
              />
            </ButtonsContainer>
          </div>
        }
        open={open}
        onClose={onClose}
      >
        <div className={styles.modalContainer}>
          <div>
            <FlexContainer
              padding="0em 0 1em 0"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text
                text={t("component.deleteAssetsModal.deleteLocations")}
                color="#000000"
                fontSize="1.4em"
                fontWeight={700}
              />
              {data.length ? (
                <InputSelect
                  showError={false}
                  showLabel={false}
                  containerClassname={styles.size}
                  options={assets.filter((asset) => data.some((val) => val.name !== asset.name)).map((item) => ({
                    label: `${item.name}`,
                    value: item.id,
                  }))}
                  value={replace}
                  onChange={(value) => setReplace(value)}
                />
              ) : null}
            </FlexContainer>

            <Text
              padding="0em 0 1em 0"
              text={` ${data.length} ${t(
                "component.deleteAssetsModal.locations"
              )} `}
              color="#000000"
              fontSize="1em"
              fontWeight={700}
            />
            <div className={styles.scroll}>
              {data.length ? (
                <ListRow template="0.2fr 1fr">
                  {data.map((val: any, i: number) => {
                    return (
                      <>
                        <ListLabel
                          textAlign="left"
                          value={(i + 1).toString()}
                        />
                        <ListLabel textAlign="left" value={val.name} />
                      </>
                    );
                  })}
                </ListRow>
              ) : (
                <div className={styles.notEnrollContainer}>
                  {t("component.deleteAssetsModal.noLocations")}
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteAssetsModal;
