const translation = {
  en: {
    successCreation: "Team was succesfully created.",
    back: "Back to Teams",
    cancel: "Cancel",
    save: "Save",
    createOrg: "Create Team",
    name: "Name",
  },
  es: {
    successCreation: "Team creado con éxito.",
    back: "Volver a Teams",
    cancel: "Cancelar",
    save: "Guardar",
    createOrg: "Crear Team",
    name: "Nombre del Team",
  },
  de: {},
};

export default translation;
