import React, { useState } from "react";
import {
  Field,
  formatQuery,
  QueryBuilder,
  RuleGroupType,
} from "react-querybuilder";
import "react-querybuilder/dist/query-builder.css";
import { AutoComplete, InputNumber, DatePicker, Switch } from "antd";
import {
  antdControlElements,
  QueryBuilderAntD,
} from "@react-querybuilder/antd";
import trash from "../../assets/deleteBlack.svg";
import styles from "./styles.module.css";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { AdvancedFilter } from "../../utils/types";
import dayjs from "dayjs";
import { Button } from "../Button";

interface FilterQueryProps {
  fields: Field[];
  filters: AdvancedFilter[];
  onSendQuery: (query: string) => void;
}

const MyAntDActionElement = (props: any) => (
  <div {...props} onClick={() => props.handleOnClick()}>
    <img className={styles.icon} src={trash} alt="erase" />
  </div>
);

const FilterQuery: React.FC<FilterQueryProps> = ({
  fields,
  filters,
  onSendQuery,
}) => {
  const [query, setQuery] = useState<RuleGroupType>({
    combinator: "and",
    rules: [],
  });

  const handleQueryChange = (q: RuleGroupType) => {
    setQuery(q);
  };

  const getOptionsForField = (field: string) => {
    const selectedFilter = filters.find(
      (filter) => filter.fieldToSearchOn === field
    );
    if (selectedFilter && selectedFilter.options) {
      return selectedFilter.options.map((option) => ({
        label: option,
        value: option,
      }));
    }
    return [];
  };

  const ValueEditor = (props: any) => {
    const selectedFilter = filters.find(
      (filter) => filter.fieldToSearchOn === props.field
    );

    if (!selectedFilter) return null;

    switch (selectedFilter.type) {
      case "keyword":
      case "text":
        return (
          <AutoCompleteInput
            {...props}
            fieldOptions={getOptionsForField(props.field)}
          />
        );
      case "number":
      case "integer":
      case "long":
        // Implement InputNumber similar to AutoComplete with local state
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [localValue, setLocalValue] = useState<string | number | null>(
          props.value !== undefined ? props.value : null
        );

        const handleNumberChange = (value: string | number | null) => {
          setLocalValue(value); // Update local state as user types
        };

        const handleBlur = () => {
          // Only call handleOnChange when the user leaves the input or presses Enter
          if (localValue !== null && localValue !== undefined) {
            props.handleOnChange(localValue);
          }
        };

        return (
          <InputNumber
            style={{ width: "100%" }}
            value={localValue}
            onChange={handleNumberChange}
            onBlur={handleBlur} // Trigger parent update when the input loses focus
            onPressEnter={handleBlur} // Trigger update when pressing Enter
            placeholder="Enter a number"
          />
        );

      case "date":
        return (
          <DatePicker
            style={{ width: "100%" }}
            value={props.value ? dayjs(props.value) : null}
            onChange={(_date, dateString) => props.handleOnChange(dateString)}
            placeholder="Select a date"
          />
        );
      case "boolean":
        return (
          <Switch
            checked={props.value === true || props.value === "true"}
            onChange={(checked) => props.handleOnChange(checked)}
            checkedChildren="True"
            unCheckedChildren="False"
          />
        );
      default:
        return null;
    }
  };

  const AutoCompleteInput = (props: any) => {
    const [inputValue, setInputValue] = useState<string>(props.value || "");

    const handleSearch = (value: string) => {
      setInputValue(value);
    };

    const handleSelect = (value: string) => {
      setInputValue(value);
      props.handleOnChange(value);
    };

    return (
      <AutoComplete
        onBlur={() => props.handleOnChange(inputValue)}
        style={{ width: "100%" }}
        value={inputValue}
        options={props.fieldOptions}
        onSelect={handleSelect}
        onChange={handleSearch}
        placeholder="Enter or select a value"
        allowClear
        filterOption={(input, option) => {
          if (!option || !option.label) return false;
          return typeof option.label === "string"
            ? option.label.toLowerCase().includes(input.toLowerCase())
            : false;
        }}
      />
    );
  };

  return (
    <div className={styles.container}>
      <QueryBuilderAntD>
        <QueryBuilder
          fields={fields}
          query={query}
          onQueryChange={handleQueryChange}
          controlElements={{
            ...antdControlElements,
            removeRuleAction: MyAntDActionElement,
            removeGroupAction: MyAntDActionElement,
            valueEditor: (props) => <ValueEditor {...props} />,
          }}
          controlClassnames={{
            combinators: styles.combinators,
            addRule: styles.addRule,
            addGroup: styles.addGroup,
            rule: styles.rule,
            removeGroup: styles.removeGroup,
            ruleGroup: styles.ruleGroup,
            removeRule: styles.removeRule,
          }}
        />
      </QueryBuilderAntD>
      <FlexContainer justifyContent="flex-end" padding="0">
        <Button
          theme="white"
          label="Aplicar"
          buttonClassname={styles.exportButton}
          onClick={() => {
            const formatedQuery = formatQuery(query, "elasticsearch");

            onSendQuery(JSON.stringify(formatedQuery));
          }}
        />
      </FlexContainer>
    </div>
  );
};

export default FilterQuery;
