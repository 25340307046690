import React from "react";
import { Location } from "@innomius/ravent-typescript-types";

import OrderTitle from "../OrderDetail/OrderTitle";
import capacityIcon from "../../assets/sidebar/vendor.svg";
import styles from "./styles";
import LocationDetailsForm from "../LocationForm/LocationDetailsForm";
import OperationalHoursForm from "../LocationForm/OperationalHoursForm";
import { useTranslation } from "react-i18next";
import { Tag, Tooltip } from "antd";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";
import { SectionProperty } from "../Section/SectionProperty";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
import { Button } from "../Button";

interface Props {
  data: Location;
  onChange: <P extends keyof Location>(prop: P, value: Location[P]) => void;
  edit: boolean;
}

const Details: React.FC<Props> = ({ data, onChange, edit }) => {
  const { t } = useTranslation();
  const formatOptionKey = (key: string) => {
    if (key && key.includes("::")) {
      const [firstPart, secondPart] = key.split("::");
      return (
        <Tooltip title={firstPart}>
          <Tag color="blue">{secondPart}</Tag>
        </Tooltip>
      );
    } else {
      return <Tag color="blue">{key}</Tag>;
    }
  };

  return (
    <div className={styles.containerDetails}>
      {edit ? (
        <LocationDetailsForm
          error=""
          data={data}
          onChange={onChange}
          edit={edit}
        />
      ) : (
        <>
          <FlexContainer padding="0 0 1em 0" justifyContent="space-between">
            <ContainerTitle size="xsmall" label="Details" />
          </FlexContainer>
          <GridContainer
            justifyContent="flex-end"
            alignItems="flex-end"
            padding="0 0 0em 0"
            gap="2em"
            columns="3fr 1fr"
          >
            <div>
              <GridContainer gap="1em" columns="1fr 1fr">
                <OrderTitle
                  size="xsmall"
                  label="Max Capacity"
                  image={capacityIcon}
                  value={data.capacity?.toString() || "Not available"}
                />
                <SectionProperty
                  value={data.pointOfContact || "-"}
                  label="Point of Contact"
                />
                <SectionProperty value={data.email || "-"} label="Email" />
                <SectionProperty value={data.phone || "-"} label="Phone" />
              </GridContainer>
              <SectionProperty
                value={data.description || "-"}
                label="Description"
              />
            </div>
          </GridContainer>
        </>
      )}

      <InputLabel label={t("component.userDetailComponent.tags")} />

      <div className={styles.center}>
        {data.tags.length >= 1 ? (
          data.tags?.map((item, index) => (
            <div style={{ margin: "0 0.5em 0.5em 0" }} key={index}>
              {formatOptionKey(item)}
            </div>
          ))
        ) : (
          <div>{t("component.locationDetail.noTags")}</div>
        )}
      </div>

      <div className={styles.line} />

      {edit ? (
        <OperationalHoursForm
          edit
          data={data.openingHours}
          onChange={(value) => onChange("openingHours", value)}
        />
      ) : (
        <>
          <FlexContainer padding="0 0 1em 0" justifyContent="space-between">
            <ContainerTitle size="xsmall" label="Operational Hours" />
            {!data.openingHours.length ? (
              <Button label="Add Hours" theme="white" />
            ) : (
              <Button label="Edit Hours" theme="white" />
            )}
          </FlexContainer>
          {data.openingHours.map((item) => (
            <GridContainer
              key={item.weekday}
              padding="0.2em 0 0 0"
              gap="1em"
              columns="1fr 1fr 4fr"
            >
              <div className={styles.day}>{item.weekday}</div>
              {item.open ? (
                <div className={styles.hour}>
                  {item.openingTime} - {item.closingTime}
                </div>
              ) : (
                <div className={styles.hour}>Closed</div>
              )}
              <div />
            </GridContainer>
          ))}
        </>
      )}
    </div>
  );
};

export default Details;
