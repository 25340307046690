import React, { useState } from "react";

import { EventComment } from "@innomius/ravent-typescript-types";

import styles from "./styles";
import OrderCommentList from "../OrderCommentList";
import { useTranslation } from "react-i18next";
import { SideMenu } from "../SideMenu/SideMenu";
import { ContainerTitle } from "../ContainerTitle";
import { EmptyList } from "../List/EmptyList";
import { Button } from "../Button";
import calendar from "../../assets/cal-gray.svg";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import Text from "../Text";
import { InputTextarea } from "../Inputs/InputTextArea";

interface Props {
  comments: EventComment[];
  sendMessage: (message: string) => void;
  removeMessage: (id: string) => void;
  error?: string;
  open: boolean;
  onClose: () => void;
  eventId: string;
  shortId: string;
  eventName: string;
}

const EventComments: React.FC<Props> = ({
  sendMessage,
  comments,
  removeMessage,
  error,
  open,
  onClose,
  eventId,
  eventName,
  shortId
}) => {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  return (
    <SideMenu width="45%" open={open} onClose={() => onClose()}>
      <ContainerTitle
        padding="0em 0 0.5em 0"
        size="medium"
        label={t("component.orderComments.eventMessages")}
      />
      <FlexContainer
        padding="0 0 0.5em 0"
        justifyContent="flex-start"
        alignItems="center"
      >
        <img src={calendar} alt="calendar" className={styles.calendar} />
        <Text
          color="#333"
          padding="0 0.3em 0 0.5em"
          fontWeight={700}
          fontSize="16px"
          text={`Event ID ${shortId} • `}
        />
        <Text
          color="#333"
          fontWeight={400}
          fontSize="16px"
          text={`${eventName}`}
        />
      </FlexContainer>

      <div className={styles.background}>
        <div className={styles.commentList}>
          {error ? (
            <EmptyList
              instructions=""
              text={t("component.orderComments.error")}
              error={error}
            />
          ) : null}

          {comments.length ? (
            <div className={styles.containerList}>
              <OrderCommentList
                data={comments}
                removeMessage={(id) => removeMessage(id)}
              />
            </div>
          ) : (
            <EmptyList
              instructions={t("component.orderComments.empty")}
              text={t("component.orderComments.noComments")}
            />
          )}
        </div>

        <div className={styles.footer}>
          <InputTextarea
            containerClassname={styles.widht}
            value={message}
            inputClassname={styles.noBorder}
            onChange={setMessage}
          />

          <FlexContainer justifyContent="flex-end">
            <Button
              label={loading ? "..." : t("component.orderComments.send")}
              onClick={async () => {
                if (loading) {
                  return;
                }
                try {
                  setLoading(true);
                  await sendMessage(message);
                  setLoading(false);

                  setMessage("");
                } catch (err) {
                  setLoading(false);
                }
              }}
            />
          </FlexContainer>
        </div>
      </div>
    </SideMenu>
  );
};

export default EventComments;
