const translation = {
  en: {
    goBack: "Go Back",
    updateDiveShop: "Update Team",
    notificationDiveShop:
      "A notification will be send to the selected Team with the order assignment.",
    diveShops: "Teams",
    setState: "Set State",
    status: "Status",
    timestamp: "Timestamp",
    locationName: "Location Name",
    user: "User",
    name: "Name",
    diveshop: "Team",
    setDiveshop: "Set Fulfiller",
    fulfilledTimestamp: "Fulfilled Timestamp",
    currentState: "Current State",
    selectVenue: "Select Venue",
    search: "Search",
    selectLocation: "Select Locations",
    cancel: "Cancel",
    save: "Save",
    deleteMessage: "Are you sure you want to delete",
    setStatus: "set state",
    fulfillmentStatus: "Fulfillment State",
    activating: "You are activating",
    on: "on",
    fromLocation: "from location:",
    geoLocationNotSupported: "Geolocation is not supported by your browser",
    states: "States",
    fulfilled: "Fulfilled",
    clickOnActivate: "Click on a state to activate following",
    setFulfillment: "Set Definition",
    notAssigned: "Not Assigned",
    assign: "Set",
    disabled: "Disabled",
    orderLine: "Order Line",
    fulfillmentDefinition: "Definition",
    setDefinition: "Select a Fulfillment Definition for this Orderline.",
    updateDefinition: "Update Definition",
    tags: "Tags",
    date: "Date/Time",
    noFulfillment: "The list is empty until you select  a definition",
    noAssignedDefinition:
      "No Fulfillment Definition is assigned for this orderline.",

    history: "States Historic",
    locations: "Events",
    noStateActivated:
      "The States historic list will be empty until you start activating states",
    noStateConfigure: "No State was configured",
    empty: "Empty List",
    instructionsLocations: "Add new location for your fulfillment.",
    locationsNotEnabled: "Locations are not enabled for this orderline. ",
    ticketing: "Ticketing",
    type: "Type",
    fulfillmentDetails: "Fulfillment Details",
    select: "Set",
    setTeam: "Set Fulfiller",
    selectState: "Click on a state to activate following",
    fulfiller: "Fulfiller",
    link: "Link to Definition",
    phone: "Phone",
    callTeam: "Call Team",
    callFulfiller: "Call Fulfiller",
    sendMessage: "Send Message",
    updateFulfiller: "Set Fulfiller",
    fulfillers: "Fulfillers",
    notificationfulfiller: "Select a Fulfiller to assign it to the orderline",
    noLocations: "No locations added",
    details: "Details",
    tickets: "Tickets",
    fulfillmentStates: "Fulfillment States",
    certificates: "Passes",
    teams: "Teams",
    fulfillment: "Fulfillment",
    events: "Events",
  },

  es: {
    events: "Eventos",

    fulfillment: "Entrega",
    certificates: "Pases",
    teams: "Equipos de Entrega",

    fulfillmentStates: "Estados del Cumplimiento",
    tickets: "Boletos",
    details: "Detalles",
    tags: "Tags",
    setState: "Asignar Estado",
    noLocations: "No se han agregado Lugares",
    noFulfillment: "La lista está vacía hasta que seleccione una definición",
    updateFulfiller: "Establecer cumplidor",
    fulfillers: "Fulfillers",
    notificationfulfiller: "Seleccione un Cumplidor para asignarlo a la línea",
    phone: "Teléfono",
    callTeam: "Llamar Equipo",
    callFulfiller: "Llamar Realizador",
    sendMessage: "Mandar Mensaje",
    link: "Link de la Definición",
    fulfiller: "Realizador",
    date: "Fecha/Hora",
    setTeam: "Seleccionar Equipo",
    selectState: "Selecciona un estado para activarlo",
    select: "Activar Estado",
    goBack: "Regresar",
    updateDiveShop: "Actualizar Team",
    notificationDiveShop:
      "Se enviará una notificación al Team seleccionado con la asignación del pedido.",
    diveShops: "Team",
    status: "Estados",
    timestamp: "Marca de tiempo",
    locationName: "Nombre de la ubicación",
    user: "Usuario",
    name: "Nombre",
    diveshop: "Team",
    setDiveshop: "Establecer Team",
    fulfilledTimestamp: "Marca de tiempo del Entrega",
    currentState: "Estado Actual",
    selectVenue: "Seleccionar lugar",
    search: "Buscar",
    selectLocation: "Seleccionar ubicación",
    cancel: "Cancelar",
    save: "Guardar",

    deleteMessage: "¿Está seguro de que desea eliminar?",
    setStatus: "Establecer Estado",
    fulfillmentStatus: "Estado del cumplimento",
    activating: "Está activando",
    on: "a",
    states: "Estados",
    fulfilled: "Cumplido",
    clickOnActivate: "Haga clic en un estado para activar el siguiente",
    setFulfillment: "Establecer Entrega",
    notAssigned: "No asignado",
    assign: "Asignar",
    noLocationAdded: "Ninguna ubicación agregada",
    disabled: "Deshabilitado",
    orderLine: "Línea de orden",
    fulfillmentDefinition: "Definición de entrega",
    setDefinition:
      "Seleccione una definición de entrega para esta línea de orden",
    updateDefinition: "Actualizar definición de entrega",
    noAssignedDefinition:
      "No se asignan definiciones de entrega para esta línea de pedido.",

    history: "Historia",
    locations: "Eventos",
    noStateActivated:
      "La lista histórica de estados estará vacía hasta que comience a activar estados",
    noStateConfigure: "No se configuró ningún estado",
    empty: "Lista vacía",

    ticketing: "Boletos",
    type: "Tipo",
  },
  de: {},
};

export default translation;
