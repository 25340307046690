const translation = {
  en: {
    name: "Definition Name",
    activationType: "Activation Type",
    actions: "",
    ticketing: "Ticketing",
    maxEvents: "Max Activations",
    customerNameReq: "Customer Name Required",
    req: "Required",
    notReq: "Not Required",
    description: "Description",
  },
  es: {
    name: "Nombre",
    description: "Descripción",
    activationType: "Tipo de activación",
    actions: "",
    ticketing: "Boletos",
    orderlineStates: "Estados de línea de orden",
    maxEvents: "No. max de activaciones",
    customerNameReq: "Nombre Requerido",
    req: "Requerido",
    notReq: "No Requerido",
  },
  de: {},
};

export default translation;
