

import React from "react";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { CreateUserParams } from "../../services/users";
import { GroupWithValues } from "../Inputs/InputCheckboxGroup/GroupWithValue";
import { Role } from "../../services/roles";
import { Modal } from "../Modal/Modal";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { ContainerTitle } from "../ContainerTitle";
import { FlexContainer } from "../FlexContainer/FlexContainer";

interface Props {
  onAssignRoles: (roles: string[]) => void;
  loading: boolean;
  onCancel: () => void;
  open: boolean;
  data: CreateUserParams;
  onChange: <P extends keyof CreateUserParams>(
    prop: P,
    value: CreateUserParams[P]
  ) => void;
  roles: Role[];
}

const RoleModal: React.FC<Props> = ({
  onAssignRoles,
  loading,
  onCancel,
  open,
  data,
  onChange,
  roles,
}) => {
  const { t } = useTranslation();

  const options = roles.map((role) => ({ label: role.name, value: role.id }));

  const rolesReplaced = data.roles.map((value) => value.replace("role:", ""));

  return (
    <div>
      <Modal
        width="600px"
        buttons={
          <div>
            <ButtonsContainer justifyContent="flex-end">
              <Button
                theme="white"
                label={t("component.userForm.cancel")}
                onClick={() => onCancel()}
              />
              <Button
                label={loading ? "..." : `${t("component.userForm.assign")}`}
                onClick={() => onAssignRoles(data.roles)}
              />
            </ButtonsContainer>
          </div>
        }
        open={open}
        onClose={onCancel}
      >
        <div className={styles.modalContainer}>
          <ContainerTitle
            size="small"
            label={t("component.userForm.selectRole")}
          />
          <p className={styles.instructions}>
            {t("component.userForm.instructions")}
          </p>
          {options.length === 0 ? (
            <div className={styles.notEnrollContainer}>
              {t("component.userForm.noRoles")}
            </div>
          ) : (
            <FlexContainer justifyContent="flex-start" flexWrap="wrap">
              <GroupWithValues
                onChange={(checkedValues) => {
                  const selected = checkedValues.map((item) => item.toString());
                  onChange("roles", selected);
                }}
                value={rolesReplaced}
                options={options}
              />
            </FlexContainer>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default RoleModal;
