import React from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import MapInput from ".";

import { ThreeWordAddress, Location } from "../../services/maps";

interface Grid {
  icon: any;
  text: string;
}

interface Props {
  onChange: (w3a: ThreeWordAddress) => void;
  value: Location;
  showGrid?: Grid;
  type?: string;
  setZoom?: boolean;
  height?: string;
}

const MapLoader: React.FC<Props> = ({
  onChange,
  value,
  setZoom,
  showGrid,
  type,
  height
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBnUmMfyn4yxmKUBh-xP8U9k7GYmMp-oyo",
  });

  return isLoaded ? (
    <MapInput
      onChange={onChange}
      value={value}
      setZoom={setZoom}
      showGrid={showGrid}
      type={type}
      height={height}
    />
  ) : (
    <></>
  );
};

export default React.memo(MapLoader);
