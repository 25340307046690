import React from 'react';
import { classes } from 'typestyle';
import styles from './styles.module.css';
interface HourTagProps {
  hour: string;
  id?: string;
  display: boolean;
  selected?: boolean;
  onClick?: () => void;
}

export const HourTag: React.FC<HourTagProps> = ({ hour, id, display, selected, onClick }) => {
  if (!display) {
    return <div />;
  }
  return (
    <div
      id={id}
      onClick={onClick}
      className={classes(styles.HourTag, {
        [styles.selectedTag]: selected
      })}
    >
      {hour}
    </div>
  );
};
