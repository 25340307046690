import { get, isNil, omitBy } from "lodash";
import { getAccessToken } from "./auth";
import fetch from "../utils/fetch";

export interface Permission {
  id: string;
  name: string;
  description: string;
  allowAll: boolean;
  endpoint: string;
  group: string;
}

export interface Role {
  id: string;
  organizationId: string;
  name: string;
  permissions: string[];
  description: string;
  scope: string;
  adminRole: boolean;
}
export function parsePermission(data: unknown): Permission {
  return {
    id: get(data, "_id", ""),
    name: get(data, "name", ""),
    endpoint: get(data, "endpoint", ""),
    group: get(data, "group", ""),
    description: get(data, "description", ""),
    allowAll: get(data, "allowAll", false),
  };
}

export function parseRole(data: unknown): Role {
  return {
    organizationId: get(data, "organization_id", ""),
    id: get(data, "_id", ""),
    scope: get(data, "scope", ""),
    permissions: get(data, "permissions", []),
    name: get(data, "name", ""),
    description: get(data, "description", ""),
    adminRole: get(data, "adminRole", false),
  };
}

export interface RolListParams {
  page?: number;
  records_per_page?: number;
  group?: string;
}

export interface RoleListResponse {
  data: Role[];
  page: number;
  count: number;
}

export interface PermissionListResponse {
  hits: Permission[];
  page: number;
  count: number;
}
function mapSorts(data: RolListParams) {
  return omitBy(
    {
      page: data.page,
      records_per_page: data.records_per_page,
      group: data.group || null,
    },
    isNil
  );
}

export async function roleList(
  params: RolListParams
): Promise<RoleListResponse> {
  const token = await getAccessToken();
  const res = await fetch("/roles", token, "GET", null, mapSorts(params));

  return {
    data: res.body ? res.body.hits.map(parseRole) : [],
    page: get(res.body, "page", 0),
    count: get(res.body, "count", 0),
  };
}

export async function getRole(id: string): Promise<Role> {
  const token = await getAccessToken();
  const res = await fetch(`/roles/${id}`, token);
  const data = parseRole(res.body);
  return data;
}

export async function createRol(data: Role): Promise<Role> {
  const body = {
    name: data.name,
    description: data.description,
    permissions: data.permissions,
    scope: data.scope,
  };
  const token = await getAccessToken();
  const res = await fetch("/roles", token, "POST", body);
  return res.body;
}

export async function updateRole(data: Role): Promise<Role> {
  const body = {
    name: data.name,
    description: data.description,
    permissions: data.permissions,
    scope: data.scope,
  };
  const token = await getAccessToken();

  const res = await fetch(`/roles/${data.id}`, token, "PATCH", body);
  return res.body;
}

export async function deleteRol(id: string): Promise<void> {
  const token = await getAccessToken();
  await fetch(`/roles/${id}`, token, "DELETE");
}

export async function permissionList(
  params: RolListParams
): Promise<PermissionListResponse> {
  const token = await getAccessToken();
  const res = await fetch("/permissions", token, "GET", null, mapSorts(params));
  return {
    hits: res.body ? res.body.hits.map(parsePermission) : [],
    page: get(res.body, "page", 0),
    count: get(res.body, "count", 0),
  };
}

export async function getPermission(id: string): Promise<Permission> {
  const token = await getAccessToken();
  const res = await fetch(`/permissions/${id}`, token);
  return res.body;
}

export const defaultRole: Role = {
  id: "",
  organizationId: "",
  name: "",
  permissions: [],
  description: "",
  scope: "",
  adminRole: false,
};

export interface PermissionGroup {
  name: string;
  id: string;
}

export async function permissionGroupList(): Promise<string[]> {
  const token = await getAccessToken();
  const res = await fetch("/permissions/groups", token);
  return res.body ? res.body.groups : [];
}
