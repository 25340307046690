import { stylesheet } from "typestyle";
import { black } from "../../../utils/colors";

const styles = stylesheet({
  container: {
    padding: "2em",
  },
  modalContainer: {
    padding: "0em 0em",
  },
  selectContainer: {
    width: "50%",
  },
  notEnrollContainer: {
    background: "rgba(250, 173, 21, 0.1)",
    border: "1px solid #FAAD15",
    padding: "1.1em",
    borderRadius: "6px",
  },
  image: {
    height: "20em",
    width: "100%",
  },

  center: {
    color: black,
    fontStyle: "normal",
    justifyContent: "center",
    padding: "1em",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
  },
  normal: {
    fontSize: "1em",
    fontWeight: 600,
    alignItems: "center",
    display: "flex",
    padding: "0 0.5em",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    width: 23,
    marginLeft: "0.4em",
  },
  link: {
    textDecoration: "none",
  },
  paginationContainer: {
    padding: "2em 0",
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  listContainer: {
    marginTop: "0",
  },
});

export default styles;
