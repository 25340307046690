import {
  FulfillmentEvent,
  Load,
  Order,
  OrderLine,
  Placement,
} from "@innomius/ravent-typescript-types";
import React from "react";
import Item from "./Item";
import styles from "./styles";
import { numberFormat } from "../../utils/format";
import { useTranslation } from "react-i18next";
import ActionRow from "./ActionRow";
import { useHistory } from "react-router-dom";
import { Asset, CreateOrderline } from "../../utils/types";
import { GridContainer } from "../GridContainer/GridContainer";

interface Props {
  sortable: boolean;
  path: string;
  teams: { label: string; value: string }[];
  fulfillmentDefinitions: { label: string; value: string }[];
  updateTeam: (id: string, diveshopId: string) => void;
  updateFulfillmentDefinition: (
    id: string,
    diveshopId: string,
    list: boolean
  ) => void;
  load: () => void;
  errorFulfillment: string;
  orderState: string;
  setState: (id: string, name: string, w3w: string, images: string[]) => void;
  onDeleteEvent: (id: string) => void;
  onCreateEvent: (location: FulfillmentEvent, orderlineId: string) => void;
  onSaveEvent: (location: FulfillmentEvent) => void;
  updateFulfiller: (
    eventId: string,
    fulfillerId: string,
    orderlineId: string
  ) => void;
  fulfillmentLevel: string;
  onCloseOrderline: (id: string) => void;
  orderlines: OrderLine[];
  data: Order;
  onEditPlacement: (event: FulfillmentEvent, placement: Placement) => void;
  onDeletePlacement: (event: FulfillmentEvent, id: string) => void;
  assets: Asset[];
  updateAsset: (event: FulfillmentEvent, id: string) => void;
  onSaveLoad: (eventId: string, data: Load[]) => void;
  onEditOrderline: (ol: CreateOrderline) => void;
  saveLoc: (p: Placement, type: string, group: string) => void;
}

const OrderDetailItemList: React.FC<Props> = ({
  orderlines,
  onDeleteEvent,
  onCreateEvent,
  updateFulfiller,
  updateTeam,
  updateFulfillmentDefinition,
  errorFulfillment,
  load,
  fulfillmentDefinitions,
  teams,
  path,
  setState,
  orderState,
  fulfillmentLevel,
  onCloseOrderline,
  data,
  onSaveEvent,
  onDeletePlacement,
  onEditPlacement,
  assets,
  updateAsset,
  onSaveLoad,
  onEditOrderline,
  saveLoc,
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  //jalar fulfillment Events de los orderlines

  return (
    <div className={styles.container}>
      {orderlines.map((item) => (
        <div className={styles.row} key={item.id}>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (fulfillmentLevel === "orderline") {
                history.replace(`${path}/orderlines/${item.id}/events`);
              }
            }}
          >
            <Item
              onEditOrderline={onEditOrderline}
              state={item.state}
              onCloseOrderline={onCloseOrderline}
              details={item.details}
              key={item.id}
              id={item.id}
              currency={item.currency}
              sku={item.sku}
              total={item.total}
              subTotal={item.subTotal}
              description={item.description}
              quantity={item.quantity}
              unitPrice={item.unitPrice}
              discounts={item.discounts}
              surcharges={item.surcharges}
              taxes={item.taxes}
              fulfillmentLevel={fulfillmentLevel}
            />
          </div>

          <ActionRow
            saveLoc={saveLoc}
            onSaveLoad={onSaveLoad}
            assets={assets}
            orderlineDetails={item.details || "-"}
            updateAsset={updateAsset}
            onSaveEvent={(event) => onSaveEvent(event)}
            state={item.state}
            fulfillmentDate={data.fulfillmentDate as string}
            fulfillmentDefinitionId={item.fulfillmentDefinitionId}
            locationLevel={data.locationLevel}
            fulfillmentLevel={fulfillmentLevel}
            onDeleteEvent={(id) => onDeleteEvent(id)}
            onCreateEvent={(location) => onCreateEvent(location, item.id)}
            updateFulfiller={updateFulfiller}
            orderlineDescription={item.description}
            errorFulfillment={errorFulfillment}
            fulfillmentDefintions={fulfillmentDefinitions}
            load={load}
            onDeletePlacement={onDeletePlacement}
            onEditPlacement={onEditPlacement}
            teams={teams}
            updateFulfillmentDefinition={(
              id: string,
              fulfillmentId: string,
              list: boolean
            ) => updateFulfillmentDefinition(id, fulfillmentId, list)}
            updateTeam={(id: string, teamId: string) => updateTeam(id, teamId)}
            path={path}
            id={item.id}
            key={item.id}
            fulfillmentState={item.fulfillmentState}
            orderState={orderState}
            setState={(id, name, w3w, images) =>
              setState(id, name, w3w, images)
            }
          />
        </div>
      ))}
      <div className={styles.amountRow}>
        <GridContainer alignItems="center" columns="auto 3fr 1fr">
          <div />
          <div className={styles.quantity}>
            <p className={styles.numbers}>
              {t("component.orderDetailItemList.subtotal")}
            </p>
            <p className={styles.numbers}>
              {t("component.orderDetailItemList.discounts")}
            </p>
            <p className={styles.numbers}>
              {t("component.orderDetailItemList.surcharge")}
            </p>
            <p className={styles.numbers}>
              {t("component.orderDetailItemList.tax")}
            </p>
            <p className={styles.total}>
              {t("component.orderDetailItemList.total")}
            </p>
          </div>
          <div className={styles.quantity}>
            <p className={styles.numbers}>
              {data.currency} {numberFormat(data.subTotalAmount || 0)}
            </p>
            <p className={styles.numbers}>
              {data.currency} {numberFormat(data.totalDiscount || 0)}
            </p>
            <p className={styles.numbers}>
              {data.currency} {numberFormat(data.surcharges || 0)}
            </p>
            <p className={styles.numbers}>
              {data.currency} {numberFormat(data.totalTaxes || 0)}
            </p>
            <p className={styles.total}>
              {data.currency} {numberFormat(data.totalAmount || 0)}
            </p>
          </div>
        </GridContainer>
      </div>
    </div>
  );
};

export default OrderDetailItemList;
