import React from "react";

import { useTranslation } from "react-i18next";
import ScheduleComponent from "../ScheduleComponent";
import styles from "../VenueStates/styles";
import {
  Action,
  Schedule,
} from "@innomius/ravent-typescript-types";
import CreateSchedule from "../ScheduleComponent/CreateSchedule";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { Card } from "../Card/Card";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { Button } from "../Button";
import { EmptyList } from "../List/EmptyList";

interface Props {
  states: Schedule[];
  onAddIntegration: (data: Schedule) => void;
  loading: string;
  loadingActions: string;
  error: string;
  onDeleteState: (id: string) => void;
  onEditSchedule: (data: Schedule) => void;
  onRemoveSchedule: (id: string) => void;
  actions: Action[];
}

const Schedules: React.FC<Props> = ({
  states,
  onAddIntegration,
  onDeleteState,
  onEditSchedule,
  loading,
  onRemoveSchedule,
  actions,
}) => {
  const { t } = useTranslation();

  const defaultSchedule: Schedule = {
    id: "",
    url: "",
    type: "at",
    timezone: "",
    cronTabExpression: "",
    date: dayjs(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
    actionId: "",
    prettyName: "",
    lastStatus: "",
    description: "",
    notificationBody: "",
  };

  const history = useHistory();

  return (
    <>
      <CreateSchedule
        data={defaultSchedule}
        onSaveScheduling={onAddIntegration}
        actions={actions}
        onClose={() => history.push(`/integrations/schedules`)}
      />

      <Card containerClassname={styles.wrapperCard}>
        <FlexContainer padding="0 0 1em 0" justifyContent="space-between">
          <ContainerTitle
            size="small"
            label={t("component.schedules.schedules")}
          />
          <Button
            label={t("component.schedules.addSchedule")}
            onClick={() => history.push(`/integrations/schedules/new`)}
          />
        </FlexContainer>

        {states.length ? (
          <ScheduleComponent
            actions={actions}
            onDeleteSchedule={onDeleteState}
            onRemoveSchedule={onRemoveSchedule}
            onEditSchedule={onEditSchedule}
            loadingSchedules={loading}
            onDelete={onDeleteState}
            onAddState={onAddIntegration}
            data={states}
          />
        ) : (
          <EmptyList
            instructions=""
            text={t("component.schedules.noSchedules")}
          />
        )}
      </Card>
    </>
  );
};

export default Schedules;
