const translation = {
  en: {
    goBack: "Go Back",
    cancel: "Cancel Order",
    warning: "Are you sure you want to cancel this Order?",
  },
  es: {
    goBack: "Volver",
    cancel: "Cancelar pedido",
    warning: "¿Está seguro de que desea cancelar esta orden??",
  },
  de: {},
};

export default translation;
