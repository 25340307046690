import React, { useState } from "react";
import { FOP, RegisterPaymentRequest } from "../../utils/types";

import styles from "./styles";
import CardForm from "./CardForm";
import WiretransferForm from "./WiretransferForm";
import { Payment } from "@innomius/ravent-typescript-types";
import { checkRegister, defaultRegisterPayment } from "../../services/payments";
import CustomError from "../../utils/CustomError";
import get from "lodash/get";
import OverPaidModal from "../OverPaidModal";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import { supplierOptions } from "../../utils/payment";
import { InputTime } from "../Inputs/InputTime/InputTime";
import InputDate from "../Inputs/Date";
import { SideMenu } from "../SideMenu/SideMenu";
import Text from "../Text";
import { ContainerTitle } from "../ContainerTitle";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { Button } from "../Button";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputText } from "../Inputs/InputText";
import { InputSelect } from "../Inputs/InputSelect";
import { InputTextarea } from "../Inputs/InputTextArea";

interface Props {
  onCancel: () => void;
  onRegister: (
    data: RegisterPaymentRequest,
    amount: string,
    requestId: string
  ) => void;
  load: () => void;
  loading: boolean;
  requestsPayments: Payment[];
  overPaid: boolean;
  error: string;
  shortId: string;
  dueAmount: string;
  fops: FOP[];
  currency: string;
}

const RegisterPaymentForm: React.FC<Props> = ({
  onCancel,
  onRegister,
  loading,
  load,
  requestsPayments,
  overPaid,
  error,
  shortId,
  fops,
  dueAmount,
  currency,
}) => {
  const [registerPayment, setPayment] = useState(defaultRegisterPayment);
  const [amount, setPrice] = useState(dueAmount);
  const [requestId, setRequestId] = useState("");
  const [open, setOpen] = useState("");
  const [validationError, setError] = useState<CustomError>();

  const { t } = useTranslation();

  const onChange = <P extends keyof RegisterPaymentRequest>(
    prop: P,
    value: RegisterPaymentRequest[P]
  ) => {
    setPayment({ ...registerPayment, [prop]: value });
  };

  const requestsOptions = requestsPayments
    .filter((item) => item.paymentType === registerPayment.type)
    .map((item) => ({
      label: `${item.shortId} - ${item.paymentType} - ${item.amount.currency} ${item.amount.value}`,
      value: item.id,
    }));

  const typeOptions = () => {
    let types = [
      { label: "Card", value: "card-reader" },
      { label: "Cash", value: "cash" },
    ];

    if (requestsPayments.find((item) => item.paymentType === "wire-transfer")) {
      types = [...types, { label: "Wire Transfer", value: "wire-transfer" }];
    }

    if (requestsPayments.find((item) => item.paymentType === "payment-link")) {
      types = [...types, { label: "Payment Link", value: "payment-link" }];
    }

    if (requestsPayments.find((item) => item.paymentType === "in-store")) {
      types = [...types, { label: "In Store", value: "in-store" }];
    }
    return types;
  };

  return (
    <SideMenu
      width="65%"
      open
      onClose={() => {
        onCancel();
        setPayment(defaultRegisterPayment);
        setPrice("");
        load();
      }}
    >
      <OverPaidModal
        open={open === "overpaid"}
        onPay={async () => {
          if (loading) {
            return;
          }
          setOpen("");
          await onRegister(registerPayment, amount, requestId);
          if (!error) {
            setPayment(defaultRegisterPayment);
            setPrice("");
            setRequestId("");
            setError(undefined);
          }
        }}
        onClose={() => setOpen("")}
      />

      <div className={styles.container}>
        <FlexContainer
          padding="1em 0 2em 0"
          alignItems="center"
          justifyContent="space-between"
        >
          <ContainerTitle
            label={t("component.registerPaymentForm.registerPayment")}
            size="medium"
          />
          <FlexContainer justifyContent="flex-end">
            <Button
              onClick={() => {
                onCancel();
                setPayment(defaultRegisterPayment);
              }}
              containerClassname={styles.button}
              theme="white"
              label={t("component.registerPaymentForm.cancel")}
            />
            <Button
              onClick={async () => {
                setError(undefined);
                if (loading) {
                  return;
                }
                if (parseFloat(amount) - parseFloat(dueAmount) >= 1) {
                  message.warning(t("component.registerPaymentForm.overPaid"));
                  return;
                }
                if (registerPayment.type !== "cash") {
                  if (
                    fops.find((item) => item.id === requestId)?.round ===
                      "down" &&
                    parseFloat(amount) % 1 !== 0
                  ) {
                    message.warning(
                      t("component.registerPaymentForm.downWarning")
                    );
                    return;
                  }
                }
                if (overPaid) {
                  try {
                    checkRegister(registerPayment, amount);
                    setOpen("overpaid");
                  } catch (error) {
                    if (error instanceof CustomError) {
                      setError(error);
                    }
                  }
                  return;
                }
                try {
                  setError(undefined);
                  checkRegister(registerPayment, amount);
                  await onRegister(registerPayment, amount, requestId);
                  if (!error) {
                    setPayment(defaultRegisterPayment);
                    setPrice("");
                    setRequestId("");
                    setError(undefined);
                  }
                  onCancel();
                  load();
                } catch (error) {
                  if (error instanceof CustomError) {
                    setError(error);
                  }
                }
              }}
              theme="blue"
              label={
                loading ? "..." : t("component.registerPaymentForm.register")
              }
            />
          </FlexContainer>
        </FlexContainer>

        <GridContainer
          gap="1em"
          alignItems="center"
          columns={
            registerPayment.type === "in-store" ||
            registerPayment.type === "payment-link" ||
            registerPayment.type === "wire-transfer"
              ? "1.5fr 1.5fr 1fr 0.4fr"
              : "1.5fr 1.5fr 1fr 0.4fr"
          }
        >
          <div>
            <InputSelect
              label={t("component.registerPaymentForm.paymentType")}
              options={typeOptions()}
              onDropdownClick={async () => setRequestId("")}
              value={registerPayment.type}
              onChange={(value) => onChange("type", value)}
            />
          </div>
          {registerPayment.type === "in-store" ||
          registerPayment.type === "payment-link" ||
          registerPayment.type === "wire-transfer" ? (
            <InputSelect
              label={t("component.registerPaymentForm.paymentRequest")}
              options={requestsOptions}
              value={requestId}
              onChange={(value) => {
                const selectedRequest = requestsPayments.find(
                  (item) => item.id === value
                );
                if (
                  selectedRequest &&
                  selectedRequest.paymentType === registerPayment.type
                ) {
                  setRequestId(value);
                  if (selectedRequest) {
                    setPrice(selectedRequest.amount.value.toString());
                  }
                } else {
                  message.error(
                    t("component.registerPaymentForm.requestError")
                  );
                }
              }}
            />
          ) : (
            <div />
          )}
          <InputText
            label="Amount"
            value={amount}
            onChange={(value) => setPrice(value)}
            error={
              !amount && get(validationError, "errors.register-amount", "")
            }
          />
          <Text text={currency} />{" "}
        </GridContainer>

        {registerPayment.type === "card-reader" ? (
          <GridContainer columns="1fr 1fr">
            <InputSelect
              label={t("component.registerPaymentForm.supplier")}
              value={registerPayment.fopId}
              options={supplierOptions(registerPayment.type, fops)}
              onChange={(value) => {
                setRequestId(value);
                onChange("fopId", value);
              }}
            />
          </GridContainer>
        ) : null}

        {registerPayment.type === "card-reader" ? (
          <CardForm
            onChange={onChange}
            error={validationError}
            registerPayment={registerPayment}
          />
        ) : null}
        {registerPayment.type === "wire-transfer" ? (
          <WiretransferForm
            registerPayment={registerPayment}
            onChange={onChange}
            error={validationError}
          />
        ) : null}

        <GridContainer columns="1fr 1fr">
          {registerPayment.type === "wire-transfer" ? (
            <InputText
              label={t("component.registerPaymentForm.reference")}
              value={registerPayment.reference || shortId}
              onChange={(value) => onChange("reference", value)}
            />
          ) : null}
        </GridContainer>

        <GridContainer padding="0 0 2em 0" gap="1em" columns="1fr 1fr 2fr">
          <InputDate
            id="paymentDate"
            label={t("component.registerPaymentForm.paymentDate")}
            value={registerPayment.timestamp as Date}
            onChange={(value) => onChange("timestamp", value)}
          />
          <InputTime
            id="paymentTime"
            value={registerPayment.timestamp as Date}
            onChange={(value) => onChange("timestamp", value.value)}
          />
        </GridContainer>
        <GridContainer padding="0 0.5em 0 0" gap="1em" columns="1fr">
          <InputTextarea
            label={t("component.registerPaymentForm.comments")}
            value={registerPayment.comments}
            onChange={(value) => onChange("comments", value)}
          />
        </GridContainer>
      </div>
    </SideMenu>
  );
};

export default RegisterPaymentForm;
