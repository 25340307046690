import { stylesheet } from "typestyle";
import { black, linkBlue } from "../../utils/colors";

const styles = stylesheet({
  container: {
    padding: "2em",
  },

  labelContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    marginRight: "0.5em",
    padding: "1em",
  },
  paginationContainerFixed: {
    padding: "2em 0 1em 0",
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    position: "fixed",
    bottom: 0,
    background: "#f2f4f8",
  },
  label: {
    margin: "0.2em 0.5em 0.2em 0",
    width: "auto",
    color: linkBlue + " !important",
  },

  searchRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "1em",
  },
  center: {
    color: black,
    fontStyle: "normal",
    justifyContent: "center",
    padding: "1em",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    marginRight: "0.5em",
  },
  space: {
    color: black,
    fontStyle: "normal",
    justifyContent: "space-between",
    padding: "1em",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    marginRight: "0.5em",
  },
  normal: {
    fontSize: "1em",
    fontWeight: 600,
    alignItems: "center",
    display: "flex",
    padding: "0 0.5em",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    width: 23,
  },
  iconType: {
    width: 35,
    marginRight: "0.5em",
  },
  link: {
    textDecoration: "none",
  },
  paginationContainer: {
    padding: "2em 0",
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
  },
  listContainer: {
    paddingBottom: "3em",
  },
});

export default styles;
