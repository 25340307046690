const translation = {
  en: {
    add: "Add Orderline State",
    stateName: "State Name",
    flowUrl: "Flow URL",
    cancel: "Cancel",
    addModal: "Add",
    deleteMessage: "Are you sure you want yo delete ",
    name: "Name",
    schedule: "Schedule",
    details: "Details",
    historyLogs: "History Logs",
    empty: "no logs registered",
    manualTrigger: "Manual Trigger",
    dataCollectionUrl: "Data Collection URL",
    orderOnly: "Order Specific Action",
    notificationBody: "Notification Body",
    run: "Are you sure you want to run the script process from this action",
  },
  es: {
    dataCollectionUrl: "URL de Colección de Datos",
    manualTrigger: "Disparo Manual",
    run: "¿Está seguro de que deseas ejecutar el proceso del script desde esta acción",
    empty: "No existen registros",
    historyLogs: "Registros Históricos",
    details: "Detalles",
    notificationBody: "Cuerpo de Notificación",
    schedule: "Calendarización",
    name: "Nombre",
    add: "Agregar estado de línea de orden",
    stateName: "Nombre del estado",
    flowUrl: "Flow URL",
    cancel: "Cancelar",
    addModal: "Agregar",
    orderOnly: "Orden relacionada",
    deleteMessage: "¿Está seguro de que desea eliminarlo?",
  },
  de: {},
};

export default translation;
