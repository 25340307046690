import { getAccessToken } from "./auth";

export async function getAssetImage(id: string): Promise<Uint8Array> {
  const token = await getAccessToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const options = {
    method: "GET",
    headers,
  };

  const res = await fetch(`/api/v1/assets/${id}/image`, options);

  if (res.ok) {
    const arrayBuffer = await res.arrayBuffer();
    const binaryData = new Uint8Array(arrayBuffer);
    return binaryData;
  } else {
    throw new Error("Failed to fetch asset image");
  }
}

export async function getFulfillmentStateImage(
  fulfillmentStateId: string,
  fileId: string
): Promise<Uint8Array> {
  const token = await getAccessToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const options = {
    method: "GET",
    headers,
  };

  const res = await fetch(
    `/api/v1/fulfillmentStates/id/${fulfillmentStateId}/files/${fileId}`,
    options
  );

  if (res.ok) {
    const arrayBuffer = await res.arrayBuffer();
    const binaryData = new Uint8Array(arrayBuffer);
    return binaryData;
  } else {
    throw new Error("Failed to fetch image");
  }
}
