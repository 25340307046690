const translation = {
  en: {
    cancel: "Cancel",
    delete: "Delete",
    confirm: "Confirm",
    invite: "Invite and Register Users",
    question: "Are you sure you want to reset this user's password?",
    instructions: "You are about to register and invite",
    instructions2: "users to access the platform.",
  },
  es: {
    confirm: "Confirmar",
    instructions: "Estás a punto de registrar e invitar",
    instructions2: "usuarios para acceder a la plataforma.",
    question:
      "¿Estás seguro de que deseas restablecer la contraseña de este usuario?",
    cancel: "Cancelar",
    delete: "Eliminar",
    invite: "Invitar y Registrar Usuarios",
  },
  de: {},
};

export default translation;
