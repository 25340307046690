import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { BackLink } from "../../components/BackLink";
import { ButtonsContainer } from "../../components/ButtonsContainer";
import { Button } from "../../components/Button";
import { ContainerTitle } from "../../components/ContainerTitle";
import { Card } from "../../components/Card/Card";
import styles from "./styles.module.css";
import UserForm from "../../components/UserForm";
import {
  createUser,
  CreateUserParams,
  defaultUser,
} from "../../services/users";
import message from "antd/lib/message";
import CustomError from "../../utils/CustomError";
import { useDispatch } from "react-redux";
import { venueList } from "../../store/venues";
import APIError from "../../utils/APIError";
import { useTranslation } from "react-i18next";
import { Role, roleList } from "../../services/roles";
import { EmptyList } from "../../components/List/EmptyList";

interface RouteParams {
  id: string;
}

interface Props extends RouteComponentProps<RouteParams> {}

const CreateUser: React.FC<Props> = ({ history }) => {
  const [user, setUser] = useState<CreateUserParams>(defaultUser);
  const [roles, setRoles] = useState<Role[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState("");
  const [validateError, setValidateError] = useState<CustomError>();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const load = useCallback(async () => {
    dispatch(venueList({}));

    try {
      const res = await roleList({ records_per_page: 100, page: 0 });
      setRoles(res.data);
    } catch (err) {
      if (err instanceof APIError) {
        setError(err.message);
      }
    }
  }, [dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    document.title = "RAVENT APP :: Create User";
  });

  const onChange = <P extends keyof CreateUserParams>(
    prop: P,
    value: CreateUserParams[P]
  ) => setUser({ ...user, [prop]: value });

  const onCreate = async () => {
    setValidateError(undefined);
    try {
      // checkUser(user);
    } catch (err) {
      if (err instanceof CustomError) {
        setValidateError(err);
      }
      return;
    }
    try {
      setLoading(true);
      await createUser(user);
      setLoading(false);
      message.success(t("container.createUser.successCreation"));
      history.push("/users");
    } catch (err) {
      setLoading(false);
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  return (
    <LayouWithSideBar>
      <div className={styles.container}>
        <FlexContainer
          padding="1em 0 0em 0"
          justifyContent="space-between"
          alignItems="center"
        >
          <BackLink
            label={t("container.createUser.back")}
            onClick={() => history.push("/users")}
          />
          <ButtonsContainer>
            <Button
              theme="white"
              label={t("container.createUser.cancel")}
              onClick={() => {
                if (loading) {
                  return;
                }
                history.push("/users");
              }}
            />
            <Button
              label={loading ? "..." : `${t("container.createUser.save")}`}
              onClick={() => {
                setValidateError(undefined);
                if (loading) {
                  return;
                }
                onCreate();
              }}
            />
          </ButtonsContainer>
        </FlexContainer>

        <ContainerTitle
          padding={"1em 0 1em 0"}
          size="medium"
          label={t("container.createUser.createUserLabel")}
        />
        {roles ? (
          <UserForm
            roles={roles}
            permission={true}
            onChange={onChange}
            data={user}
            editing={false}
            error={validateError}
          />
        ) : null}
        {error ? (
          <Card>
            <EmptyList instructions={""} text={error} error={error} />
          </Card>
        ) : null}
      </div>
    </LayouWithSideBar>
  );
};

export default CreateUser;
