const translation = {
  en: {
    cardBrand: "Card Brand",
    last4Digits: "Last 4 Digits",
    customerName: "Customer Name",
    authNumber: "Authorization Number",
    registerPayment: "Register Payment",
    cancel: "Cancel",
    register: "Register",
    paymentType: "Payment Type",
    paymentRequest: "Payment Request",
    requestError:
      "You can't select a payment request that differs from the register payment type.",
    reference: "Reference",
    paymentDate: "Payment Date",
    comments: "Comments",
    bank: "Bank",
    accountNumber: "Account Number",
    supplier: "Supplier Name / Service",
    overPaid: "The amount exceeds the due amount. Please correct the amount.",
    downWarning:
      "This form of payment accepts only round down amount , please erase all decimals and round down.",
    upWarning:
      "This form of payment accepts only round up values, please erase all decimals and and round up.",
  },
  es: {
    cardBrand: "Marca de la tarjeta",
    last4Digits: "Últimos 4 dígitos",
    customerName: "Nombre del cliente",
    authNumber: "Número de autorización",
    registerPayment: "Registrar Pago",
    cancel: "Cancelar",
    register: "Registrar",
    paymentType: "Tipo de pago",
    paymentRequest: "Solicitud de pago",
    requestError:
      "No puede seleccionar una solicitud de pago que difiera del tipo de pago registrado.",
    reference: "Referencia",
    paymentDate: "Fecha de Pago",
    comments: "Comentarios",
    bank: "Banco",
    accountNumber: "Número de cuenta",
    supplier: "Nombre del proveedor / Servicio",
    overPaid: "La cantidad excede la cantidad adeudada",
    upWarning:
      "Esta forma de pago solo acepta valores redondeados hacia arriba, borre los decimales.",
    downWarning:
      "Esta forma de pago solo acepta valores de montos redondeados hacia abajo, por favor borre las decimales.",
  },
  de: {},
};

export default translation;
