import { stylesheet } from "typestyle";

const styles = stylesheet({
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
  modalContainer: {
    padding: "1em",
  },
  selectContainer: {
    width: "100%",
  },
  notEnrollContainer: {
    background: "rgba(250, 173, 21, 0.1)",
    border: "1px solid #FAAD15",
    padding: "1.1em",
    borderRadius: "6px",
  },
  icon: {
    width: "30px",
    cursor: "pointer",
  },
  container: {
    $nest: {
      "div > div": {
        alignItems: "center !important",
      },
    },
  },
});

export default styles;
