import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import { RootState } from "../../store";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { Button } from "../../components/Button";
import { ContainerTitle } from "../../components/ContainerTitle";

import CrossIcon from "../../components/Icons/CrossIcon";
import styles from "./styles.module.css";
import qs from "qs";
import {
  parseQueryParameter,
  rearrangeFacetsEvents,
  removeEmptyStringFromObject,
} from "../../utils/order";
import { useTranslation } from "react-i18next";
import { venueList } from "../../store/venues";
import { InputSelect } from "../../components/Inputs/InputSelect";
import EventList from "../../components/EventList";
import { eventTextSearchList } from "../../store/eventTextSearch";
import {
  Filter,
  notifyWebHook,
  scheduleWebHook,
  updateOrder,
  updateOrderLine,
} from "../../services/orders";
import dayjs from "dayjs";
import { numberFormatNoFractions } from "../../utils/format";
import TabNavBar from "../../components/TabNavBar";
import Missing from "../../components/MissingPlacements";
import APIError from "../../utils/APIError";
import {
  assignAsset,
  assignFulfillment,
  missingListEvents,
  setEventFulfillmentDefState,
  setEventState,
  updateElementEvent,
} from "../../services/events";
import {
  Action,
  FulfillmentEvent,
  Schedule,
} from "@innomius/ravent-typescript-types";
import { message, Radio, Button as AntdButton, Popover } from "antd";
import { assetList } from "../../store/assets";
import { teamList } from "../../store/teams";
import ScheduleFlow from "../../components/SendModal/ScheduleFlow";
import flow from "../../assets/sendflows4.svg";
import { listActions } from "../../services/actions";
import Text from "../../components/Text";
import { fulfillmentList } from "../../store/fulfillments";
import DiveShopModal from "../../components/OrderFulfillment/DiveShopModal";
import AssetModal from "../../components/AssetModal";
import fulfillerIcon from "../../assets/fulfiller.svg";
import assetIcon from "../../assets/sidebar/assets.svg";
import SideFiltersEvent from "../../components/SideFilters/SideFiltersEvent";
import SaveFilterModal from "../../components/SaveFilterModal";
import DeleteModal from "../../components/DeleteModal";
import cardIcon from "../../assets/view-list-card.svg";
import cardIconActive from "../../assets/view-list-card-active.svg";

import listIcon from "../../assets/view-list.svg";
import listIconActive from "../../assets/view-list-active.svg";
import Search from "antd/es/input/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faFilter, faMinus } from "@fortawesome/pro-light-svg-icons";
import FilterQuery from "../../components/FilterQuery";
import { Field } from "react-querybuilder";
import { determineFieldType, getOperatorsForType } from "../../utils/query";
import { GridContainer } from "../../components/GridContainer/GridContainer";
import { ButtonsContainer } from "../../components/ButtonsContainer";
import { AdvancedFilter, SavedData } from "../../utils/types";
import { getAdvancedFilters } from "../../services/queries";

interface Props {}

const Events: React.FC<Props> = () => {
  function useQuery() {
    const { search } = useLocation();
    return search;
  }
  const query = useQuery();
  const [sort, setSortBy] = useState("_score:desc,datetime:desc");

  const history = useHistory();
  const { t } = useTranslation();

  const location = useLocation();

  const dispatch = useDispatch();

  const auth = useSelector((state: RootState) => state.auth);
  const { profile } = useSelector((state: RootState) => state.profile);

  const events = useSelector((state: RootState) => state.eventTextSearch);
  const fulfillments = useSelector((state: RootState) => state.fulfillments);

  const [page, setPage] = useState(
    (qs.parse(query.substring(1)).page as string) ||
      events.fulfillmentEvents.page
  );
  const [resize, setSize] = useState(false);

  const [records_per_page, setHitsPerPage] = useState(
    (qs.parse(query.substring(1)).records_per_page as string) || 100
  );
  const [schedule, setSchedule] = useState(false);

  const [venueId, setVenueId] = useState(
    (qs.parse(query.substring(1)).venueId as string) ||
      (localStorage.getItem("venueId") as string) ||
      ""
  );

  const [savedFilter, setSavedFilter] = useState<string>("");

  const [search, setSearch] = useState(
    (qs.parse(query.substring(1)).search as string) || ""
  );
  const [assets, setAssets] = useState<Filter>(
    parseQueryParameter(query, "assets")
  );

  const [passengers, setPassengers] = useState<Filter>(
    parseQueryParameter(query, "passengers")
  );
  const [from, setFrom] = useState<string>(
    (qs.parse(query.substring(1)).from as string) ||
      dayjs(new Date()).format("YYYY-MM-DD")
  );

  const [to, setTo] = useState<string>(
    (qs.parse(query.substring(1)).to as string) || ""
  );
  const [datetimeHourFrom, setHourFrom] = useState<string>(
    (qs.parse(query.substring(1)).datetimeHourFrom as string) || ""
  );

  const [datetimeHourTo, setHourTo] = useState<string>(
    (qs.parse(query.substring(1)).datetimeHourTo as string) || ""
  );

  const [dateType, setDateType] = useState<string>(
    (qs.parse(query.substring(1)).dateType as string) || "datetime"
  );

  const [timezone, setTimeZone] = useState<string>(
    localStorage.getItem("timeZone") ||
      Intl.DateTimeFormat().resolvedOptions().timeZone ||
      ""
  );

  const [errorFlows, setErrorFlows] = useState<string>("");
  const [errorM, setErrorM] = useState<string>("");
  const [loadingState, setLoadingState] = useState<{
    eventId: string;
    state: string;
  }>({ eventId: "", state: "" });

  const [loading, setLoading] = useState<string>("");
  const assetsList = useSelector((state: RootState) => state.assets);

  const teams = useSelector((state: RootState) => state.teams);

  const [tags, setTags] = useState<Filter>(parseQueryParameter(query, "tags"));
  const [placementGroup, setPlacementGroup] = useState<Filter>(
    parseQueryParameter(query, "placementGroup")
  );
  const [datetimeHour, setDatetimeHour] = useState<Filter>(
    parseQueryParameter(query, "datetimeHour")
  );
  const [fulfillmentTeamName, setFulfillmentTeamName] = useState<Filter>(
    parseQueryParameter(query, "fulfillmentTeamName")
  );
  const [fulfillerUsername, setFulfillerUserName] = useState<Filter>(
    parseQueryParameter(query, "fulfillerUsername")
  );
  const [fulfillerName, setFulfillerName] = useState<Filter>(
    parseQueryParameter(query, "fulfillerName")
  );

  const [groupCode, setGroupCode] = useState<Filter>(
    parseQueryParameter(query, "groupCode")
  );
  const [state, setState] = useState<Filter>(
    parseQueryParameter(query, "state")
  );
  const [fulfillmentLevel, setFulfillmentLevel] = useState<Filter>(
    parseQueryParameter(query, "fulfillmentLevel")
  );

  const [selected, setSelected] = useState<{ id: string }[]>([]);

  const [missingL, setMissing] = useState<FulfillmentEvent[]>([]);
  const [loadingM, setLoadingM] = useState(false);
  const [searchM, setSearchM] = useState("");
  const [openFlows, setOpenFlows] = useState("");
  const [actions, setActions] = useState<Action[]>([]);
  const [type, setType] = useState(
    (qs.parse(query.substring(1)).type as string) || "card"
  );

  const [open, setOpen] = useState("");
  const [deletedFilter, setDeletedFilter] = useState("");
  const [advancedFilters, setAdvancedFilters] = useState("");

  const [savedData, setSavedData] = useState<Record<string, SavedData>>({});

  const [hitsPerPageM, setHitsPerPageM] = useState(
    (qs.parse(query.substring(1)).hitsPerPageM as string) || 10
  );

  const [catalog, setCatalog] = useState<AdvancedFilter[]>([]);

  const [textM, setTextM] = useState(
    (qs.parse(query.substring(1)).searchM as string) || ""
  );

  const [text, setText] = useState(
    (qs.parse(query.substring(1)).search as string) || ""
  );

  const [pageM, setPageM] = useState(
    (qs.parse(query.substring(1)).pageM as string) || 0
  );
  const [count, setCount] = useState(0);

  type StateMap = {
    [key: string]: [Filter, React.Dispatch<React.SetStateAction<Filter>>];
  };

  const stateMap: StateMap = {
    state: [state, setState],
    groupCode: [groupCode, setGroupCode],
    tags: [tags, setTags],
    placementGroup: [placementGroup, setPlacementGroup],
    assets: [assets, setAssets],
    fulfillerName: [fulfillerName, setFulfillerName],

    fulfillerUsername: [fulfillerUsername, setFulfillerUserName],
    fulfillmentTeamName: [fulfillmentTeamName, setFulfillmentTeamName],
    fulfillmentLevel: [fulfillmentLevel, setFulfillmentLevel],
    datetimeHour: [datetimeHour, setDatetimeHour],
    passengers: [passengers, setPassengers],
  };

  const venues = useSelector((state: RootState) => state.venues);
  const venueArray = [
    ...venues.list.data.map((item) => ({ label: item.name, value: item.id })),
    { label: "all", value: "" },
  ];

  useEffect(() => {
    try {
      const data = localStorage.getItem("eventSaveFilter");
      if (data) {
        setSavedData(JSON.parse(data) as Record<string, SavedData>);
      } else {
        setSavedData({});
      }
    } catch (error) {
      console.error("Error parsing orderSaveFilter from localStorage:", error);
      setSavedData({});
    }
  }, []);

  const loadActions = useCallback(async () => {
    try {
      const res = await listActions({
        page: 0,
        records_per_page: 100,
        manualTrigger: true,
        venue_id: venueId as string,
      });
      setActions(res.data);
    } catch (err) {
      if (err instanceof APIError) {
        console.error(`${err.message}: ${err.messages}`);
      }
    }
  }, [venueId]);

  useEffect(() => {
    loadActions();
  }, [loadActions]);

  const loadAssets = useCallback(async () => {
    dispatch(assetList({ records_per_page: 500 }));
    dispatch(teamList({ records_per_page: 100 }));
    dispatch(fulfillmentList({ records_per_page: 20 }));
  }, [dispatch]);

  useEffect(() => {
    loadAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadAdvanceFilters = useCallback(async () => {
    try {
      const res = await getAdvancedFilters();
      if (res) {
        const filtersArray = Object.entries(res).map(([_key, value]) => ({
          ...value,
        }));
        setCatalog(filtersArray);
      }
    } catch (err) {
      if (err instanceof APIError) {
        console.error(`${err.message}: ${err.messages}`);
      }
    }
  }, []);

  useEffect(() => {
    loadAdvanceFilters();
  }, [loadAdvanceFilters]);

  const loadMissing = useCallback(async () => {
    try {
      setLoadingM(true);
      const res = await missingListEvents({
        venueId: venueId,
        search: searchM,
        page: pageM,
        records_per_page: hitsPerPageM,
        tags: tags,
      });
      setMissing(res.hits);
      setCount(res.count);
      setPageM(res.page);
      setLoadingM(false);
      const statesMissing = {
        hitsPerPageM: hitsPerPageM as string,
        page: pageM as string,
        type: type,
        tags: tags.include + "|" + tags.items.map((item) => item.key).join(","),
      };
      const paramsMissing = new URLSearchParams(
        removeEmptyStringFromObject(statesMissing)
      );
      if (location.pathname.includes("/missing")) {
        history.replace(`/events/missing?${paramsMissing.toString()}`);

        return;
      }
    } catch (err) {
      setLoadingM(false);

      if (err instanceof APIError) setErrorM(err.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchM, venueId, pageM, hitsPerPageM, tags]);

  useEffect(() => {
    loadMissing();
  }, [loadMissing]);

  const loadVenues = useCallback(async () => {
    dispatch(venueList({ records_per_page: 20 }));
  }, [dispatch]);

  useEffect(() => {
    loadVenues();
  }, [loadVenues]);

  const loadEvents = useCallback(async () => {
    dispatch(
      eventTextSearchList({
        page,
        records_per_page,
        from,
        to,
        search,
        timezone,
        venueId,
        dateType,
        tags,
        fulfillerUsername,
        placementGroup,
        fulfillmentTeamName,
        state,
        fulfillmentLevel,
        assets,
        datetimeHourFrom,
        datetimeHourTo,
        passengers,
        sort,
        groupCode,
        advancedFilters,
        fulfillerName,
      })
    );

    const states = {
      search: search,
      from: from,
      to: to,
      sort: sort,
      datetimeHourFrom: datetimeHourFrom,
      datetimeHourTo: datetimeHourTo,
      type: type,
      fulfillerName:
        fulfillerName.include +
        "|" +
        fulfillerName.items.map((item) => item.key).join(","),
      state:
        state.include + "|" + state.items.map((item) => item.key).join(","),
      fulfillmentLevel:
        fulfillmentLevel.include +
        "|" +
        fulfillmentLevel.items.map((item) => item.key).join(","),
      page: page as string,
      records_per_page: records_per_page as string,
      venueId: venueId as string,
      dateType: dateType,
      tags: tags.include + "|" + tags.items.map((item) => item.key).join(","),
      passengers:
        passengers.include +
        "|" +
        passengers.items.map((item) => item.key).join(","),
      groupCode:
        groupCode.include +
        "|" +
        groupCode.items.map((item) => item.key).join(","),
      fulfillerUsername:
        fulfillerUsername.include +
        "|" +
        fulfillerUsername.items.map((item) => item.key).join(","),
      assets:
        assets.include + "|" + assets.items.map((item) => item.key).join(","),
      fulfillmentTeamName:
        fulfillmentTeamName.include +
        "|" +
        fulfillmentTeamName.items.map((item) => item.key).join(","),
      placementGroup:
        placementGroup.include +
        "|" +
        placementGroup.items.map((item) => item.key).join(","),
    };

    const statesMissing = {
      hitsPerPageM: hitsPerPageM as string,
      page: pageM as string,
      type: type,
      tags: tags.include + "|" + tags.items.map((item) => item.key).join(","),
    };

    const params = new URLSearchParams(removeEmptyStringFromObject(states));
    const paramsMissing = new URLSearchParams(
      removeEmptyStringFromObject(statesMissing)
    );

    if (location.pathname.includes("/missing")) {
      history.replace(`/events/missing?${paramsMissing.toString()}`);
      return;
    } else {
      history.replace(`/events?${params.toString()}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    page,
    records_per_page,
    from,
    to,
    timezone,
    search,
    history,
    state,
    fulfillmentLevel,
    venueId,
    dateType,
    tags,
    fulfillerUsername,
    fulfillmentTeamName,
    placementGroup,
    assets,
    datetimeHourFrom,
    datetimeHourTo,
    passengers,
    sort,
    type,
    groupCode,
    advancedFilters,
    fulfillerName,
  ]);

  useEffect(() => {
    loadEvents();
  }, [loadEvents]);

  const hasNonEmptyState = Object.values(stateMap).some(
    ([filter]) => filter.items.length > 0
  );

  const closeAllTags = () => {
    Object.values(stateMap).forEach(([_filter, setFilter]) =>
      setFilter((prevFilter) => ({ ...prevFilter, items: [] }))
    );
    setHourFrom("");
    setHourTo("");
    setFrom("");
    setTo("");
  };

  useEffect(() => {
    document.title = "RAVENT APP :: Events";
  });

  const onUpdateEvent = async (
    id: string,
    record: Record<string, any>,
    msg?: string
  ) => {
    try {
      await updateElementEvent(id, record);
      message.success(
        msg ? msg : t("container.orderDetail.successEventUpdate")
      );
      setTimeout(() => {
        loadEvents();
      }, 500);
    } catch (err) {
      if (err instanceof APIError) {
        message.error(err.message);
      }
    }
  };

  if (!auth.loading && !auth.user) {
    return <Redirect from="*" to="/" />;
  }

  const handleTagClose = (facetGroup: string, option: string) => {
    const [currentState, setState] = stateMap[facetGroup];
    const itemToRemoveIndex = currentState.items.findIndex(
      (item) => item.key === option
    );
    if (itemToRemoveIndex !== -1) {
      setState({
        ...currentState,
        items: currentState.items.filter(
          (item, index) => index !== itemToRemoveIndex
        ),
      });
    }
  };

  function generateTag(label: Filter, _onClose: (label: string) => void) {
    if (!label || !label.items || label.items.length === 0) {
      return null;
    }
    return label.items.map((tagItem: { key: string }, index: number) => (
      <div
        className={styles.tag}
        key={index}
        style={{ margin: "0 0.3em 0.3em 0" }}
      >
        <div style={{ color: "#1890ff" }}>{tagItem.key.trim()}</div>
        <button
          className={styles.button}
          name="remove"
          onClick={() => handleTagClose(label.name, tagItem.key)}
        >
          <CrossIcon color={"#1890ff"} />
        </button>
      </div>
    ));
  }

  const selectedEvents: Filter[] = [];
  Object.values(stateMap).forEach(([filter]) => {
    selectedEvents.push(filter);
  });

  const onSendToFlow = async (actionId: string, schedule: Schedule) => {
    try {
      setLoading("schedule");
      if (schedule.timezone) {
        await scheduleWebHook({
          actionId: actionId,
          eventsIds: selected.map((item) => item.id),
          date: schedule.date,
          timezone: schedule.timezone,
          prettyName: schedule.prettyName,
        });
        message.success(`Events were sent successfully`);
      } else {
        const res = await notifyWebHook({
          actionId: actionId,
          eventsIds: selected.map((item) => item.id),
        });
        if (res.successful === false) {
          message.error({
            content: `${res.statusCode} ${res.message}`,
          });
        }
        if (res.successful === true) {
          message.success(`Events were sent successfully`);
        }
      }

      setLoading("");
      setSelected([]);
      setErrorFlows("");
      setOpenFlows("");
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        setErrorFlows(`Err: ${err.messages}`);
      }
    }
  };

  const onSetState = async (
    eventId: string,
    state: string,
    w3w: string,
    images: string[]
  ) => {
    try {
      setLoading("state-orderline");
      await setEventFulfillmentDefState(eventId, state, w3w, images);
      message.success(t("container.orderDetail.stateSuccess"));
      setLoading("");
      loadEvents();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onSetStateOrder = async (
    eventId: string,
    state: string,
    w3w: string,
    images: string[]
  ) => {
    try {
      setLoading("state-orderline");
      await setEventFulfillmentDefState(eventId, state, w3w, images);
      message.success(t("container.orderDetail.stateSuccess"));
      setLoading("");
      loadEvents();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onUpdateOrder = async (
    id: string,
    record: Record<string, any>,
    msg?: string
  ) => {
    try {
      await updateOrder(id, record);
      message.success(
        msg ? msg : t("container.orderDetail.successOrderUpdate")
      );
      loadEvents();
    } catch (err) {
      if (err instanceof APIError) {
        message.error(err.message);
      }
    }
  };

  const onUpdateOrderline = async (id: string, fulfillmentId: string) => {
    try {
      await updateOrderLine(id, {
        fulfillmentDefinitionId: fulfillmentId,
      });
      message.success(t("container.orderDetail.successFulfillment"));
      loadEvents();
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`Error ${err.message}: ${err.messages}`);
      }
    }
  };
  const updateAsssetMultipleEvents = async (assetId: string) => {
    try {
      await assignAsset(
        assetId,
        selected.map((item) => item.id)
      );
      message.success(t("container.orderDetail.successFulfillment"));
      loadEvents();
      setSelected([]);
      setOpen("");
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`Error ${err.message}: ${err.messages}`);
      }
    }
  };

  const filters = Object.keys(savedData)
    .map((key) => {
      const name = savedData[key].name;
      return name ? { label: name, value: name } : undefined;
    })
    .filter(
      (item): item is { label: string; value: string } => item !== undefined
    );

  const updateTeamMultipleEvents = async (
    teamId?: string,
    fulfillerId?: string
  ) => {
    try {
      await assignFulfillment(
        selected.map((item) => item.id),
        teamId,
        fulfillerId
      );
      message.success(t("container.orderDetail.successAsset"));
      setOpen("");
      setSelected([]);
      loadEvents();
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`Error ${err.message}: ${err.messages}`);
      }
    }
  };

  const flows = actions.map((item) => ({
    name: `${item.name} - ${item.url}`,
    actionId: item.id,
    url: item.id,
  }));

  const onChangeEventState = async (eventId: string, state: string) => {
    try {
      setLoadingState({ eventId, state });
      await setEventState(eventId, state);

      loadEvents();
      if (state === "running") {
        message.success(`Run now`);
      } else message.success(`Marked as ${state}`);
      setLoadingState({ eventId: "", state: "" });
    } catch (err) {
      setLoadingState({ eventId: "", state: "" });
      console.log("ERR CHANGE STATE", err);
    }
  };

  const mapFiltersToFields = (filters: AdvancedFilter[]): Field[] => {
    return filters.map((filter) => {
      const fieldType = determineFieldType(filter.type);
      return {
        name: filter.fieldToSearchOn, // Use 'fieldToSearchOn' for the search field
        label: filter.name, // Use 'name' for display label
        type: fieldType, // Type is determined based on the AdvancedFilter type
        operators: getOperatorsForType(fieldType), // Operators based on the type
      };
    });
  };

  return (
    <LayouWithSideBar overflowY="none" shrink={true}>
      <DeleteModal
        value={`${t("container.events.warningDelete")} ${deletedFilter} ${t(
          "container.events.filter"
        )} `}
        open={open === "deleteFilter"}
        onCancel={() => setOpen("")}
        onDelete={() => {
          const saved = { ...savedData };
          delete saved[deletedFilter];
          setSavedData(saved);
          localStorage.setItem("eventSaveFilter", JSON.stringify(saved));
          setDeletedFilter("");
          setOpen("");
        }}
      />
      <SaveFilterModal
        open={open === "save"}
        onSave={(value) => {
          const dataToStore = {
            filters: selectedEvents,
            name: value,
            search: search,
            type: search ? "query" : "normal",
          };
          const saved = {
            ...savedData,
            [value]: dataToStore,
          };
          setSavedData(saved);
          localStorage.setItem("eventSaveFilter", JSON.stringify(saved));
        }}
        onClose={() => setOpen("")}
      />
      <GridContainer columns={resize ? "0.24fr 1fr" : "3em 1fr"}>
        <div
          style={{
            position: "sticky",
            backgroundColor: "#fff",
            height: "100%",
            borderLeft: "1px solid #dedede",
          }}
        >
          <div
            style={{
              overflowY: "scroll",
              scrollbarWidth: "none", // Works for Firefox
              msOverflowStyle: "none", // Works for IE and Edge
              WebkitOverflowScrolling: "touch", // For iOS webkit browsers
            }}
          >
            <SideFiltersEvent
              datetimeHourFrom={datetimeHourFrom}
              datetimeHourTo={datetimeHourTo}
              tab={"events"}
              dateType={dateType}
              setDateType={(value) => {
                setDateType(value);
              }}
              filters={filters}
              savedFilter={savedFilter}
              onSaveFilter={(value) => {
                setSavedFilter(value);

                const matchingObject = savedData[value];

                if (matchingObject && savedData[value].type === "query") {
                  setText(savedData[value].search || "");
                  setSearch(savedData[value].search || "");
                  return;
                }

                if (matchingObject && matchingObject.filters) {
                  matchingObject.filters.forEach((filter) => {
                    const stateAndSetter = stateMap[filter.name];
                    if (stateAndSetter) {
                      const setFilter = stateAndSetter[1];
                      setFilter((prevFilter) => ({
                        ...prevFilter,
                        items: filter.items,
                      }));
                    }
                  });
                }
              }}
              onDeleteFilter={(value) => {
                setOpen("deleteFilter");
                setDeletedFilter(value);
              }}
              facets={rearrangeFacetsEvents(events.fulfillmentEvents.facets)}
              selected={selectedEvents}
              from={from}
              to={to}
              setSize={
                location.pathname === "/events/missing"
                  ? () => {}
                  : (value) => setSize(value)
              }
              shrink={resize}
              timezone={timezone}
              onChangeTimeZone={(value) => {
                setPage(0);
                setFrom(from);
                setTo(to);
                setTimeZone(value);
              }}
              onChange={(facetGroup, option) => {
                setPage(0);
                const map = stateMap;
                if (map[facetGroup]) {
                  const [currentState, setState] = map[facetGroup];
                  const existingIndex = currentState.items.findIndex(
                    (item) => item.key === option
                  );
                  let updatedItems;
                  if (existingIndex !== -1) {
                    updatedItems = currentState.items.filter(
                      (item) => item.key !== option
                    );
                  } else {
                    updatedItems = [
                      ...currentState.items,
                      {
                        key: option,
                        include: true,
                      },
                    ];
                  }
                  setState({
                    ...currentState,
                    items: updatedItems,
                  });
                }
              }}
              onChangeHour={(from, to) => {
                setHourFrom(from);
                setHourTo(to);
              }}
              onChangeExlusion={(facetGroup, value) => {
                const map = stateMap;
                const [currentState, setState] = map[facetGroup];
                if (currentState && currentState.items) {
                  setState((prevState) => ({
                    ...prevState,
                    items: prevState.items.map((item) => ({
                      ...item,
                      include: value,
                    })),
                  }));
                }
              }}
              onChangeSelect={(facetGroup, value) => {
                const map = stateMap;
                const [currentState, setState] = map[facetGroup];
                if (currentState) {
                  setState({
                    ...currentState,
                    include: value as "Any" | "All",
                  });
                }
              }}
              onChangeDate={(value) => {
                setPage(0);
                setFrom(value[0]);
                setTo(value[1]);
              }}
            />
          </div>
        </div>

        <div className={styles.container}>
          <DiveShopModal
            fulfillerId={""}
            orderlineId={""}
            updateFulfiller={() => {}}
            teamId={""}
            teams={
              teams.list.data.map((item) => ({
                label: item.name,
                value: item.id,
              })) || []
            }
            updateBoth={(teamId, fulfillerId) =>
              updateTeamMultipleEvents(teamId, fulfillerId)
            }
            updateTeam={() => {}}
            open={open === "teams"}
            onClose={() => setOpen("")}
          />
          <AssetModal
            open={open === "assets"}
            assets={assetsList.list.data}
            onClose={() => setOpen("")}
            assetId={""}
            updateAsset={(assetId) => updateAsssetMultipleEvents(assetId)}
          />

          <ScheduleFlow
            schedule={schedule}
            setSchedule={setSchedule}
            flows={flows || []}
            onSend={(flow, schedule) => onSendToFlow(flow, schedule)}
            error={errorFlows}
            loading={loading === "flows"}
            onCancel={() => {
              setOpenFlows("");
              setErrorFlows("");
            }}
            title={`${t("container.orders.orderToFlows")}`}
            open={openFlows === "flows"}
            id=""
          />
          <FlexContainer
            padding="0 0 1em 0"
            alignItems="center"
            justifyContent="space-between"
          >
            <ContainerTitle
              size="medium"
              label={t("container.orders.eventLabel")}
            />

            <FlexContainer justifyContent="flex-end">
              {profile?.adminRole ? (
                <InputSelect
                  containerClassname={styles.inputSelect}
                  options={venueArray}
                  value={venueId}
                  showError={false}
                  showLabel={false}
                  onChange={(value) => {
                    setPage(0);
                    setVenueId(value);
                  }}
                />
              ) : null}
              <div style={{ margin: "0 1em" }}>
                <Radio.Group
                  style={{ margin: "0em 1em", display: "flex" }}
                  value={type}
                  onChange={(value) => setType(value.target.value)}
                >
                  <Radio.Button value="card">
                    <div className={styles.imageContainer}>
                      <img
                        className={styles.icon}
                        src={type === "card" ? cardIconActive : cardIcon}
                        alt=""
                      />
                    </div>
                  </Radio.Button>
                  <Radio.Button value="list">
                    <div className={styles.imageContainer}>
                      <img
                        className={styles.icon}
                        src={type === "list" ? listIconActive : listIcon}
                        alt=""
                      />
                    </div>
                  </Radio.Button>
                  <Radio.Button value="mini">
                    <div className={styles.imageContainer}>
                      <FontAwesomeIcon
                        color={type === "mini" ? "#1FA0FF" : "#333"}
                        icon={faMinus as IconProp}
                      />
                    </div>
                  </Radio.Button>
                </Radio.Group>
              </div>
              <Popover
                trigger="click"
                placement="bottomLeft"
                content={
                  <div style={{ width: "600px", padding: 0 }}>
                    <FilterQuery
                      onSendQuery={(query) => setAdvancedFilters(query)}
                      filters={catalog}
                      fields={mapFiltersToFields(catalog)}
                    />
                  </div>
                }
              >
                <AntdButton type="default">
                  <div className={styles.imageContainer}>
                    <FontAwesomeIcon icon={faFilter as IconProp} />
                  </div>
                </AntdButton>
              </Popover>
            </FlexContainer>
          </FlexContainer>

          <FlexContainer
            padding="1em 0 0 0"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <FlexContainer
              width="50%"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              {location.pathname === "/events/missing" ? (
                <Search
                  style={{ marginBottom: "1em" }}
                  placeholder={t("component.orderFilters.search")}
                  onSearch={() => setSearchM(textM)}
                  value={textM}
                  onChange={(text) => {
                    setTextM(text.target.value);
                  }}
                />
              ) : (
                <Search
                  style={{ marginBottom: "1em" }}
                  placeholder={t("component.orderFilters.search")}
                  onSearch={() => setSearch(text)}
                  value={text}
                  onChange={(text) => {
                    setText(text.target.value);
                  }}
                />
              )}
              {search ? (
                <div style={{ marginLeft: "1em" }}>
                  <Button
                    theme="white"
                    onClick={() => setOpen("save")}
                    label={t("container.orders.save")}
                  />
                </div>
              ) : null}
            </FlexContainer>
          </FlexContainer>

          <FlexContainer
            flexWrap="wrap"
            justifyContent="flex-start"
            padding="1em 0 1em 0"
          >
            {from || to ? (
              <div className={styles.tag} style={{ margin: "0 0.3em 0.3em 0" }}>
                <div style={{ color: "#1890ff" }}>{`${dayjs(from).format(
                  "YYYY-MM-DD"
                )} ${to ? "-": ""}${to ? dayjs(to).format("YYYY-MM-DD") : ""}`}</div>
                <button
                  className={styles.button}
                  name="remove"
                  onClick={() => {
                    setTo("");
                    setFrom("");
                  }}
                >
                  <CrossIcon color={"#1890ff"} />
                </button>
              </div>
            ) : null}
            {datetimeHourFrom && datetimeHourTo ? (
              <div className={styles.tag} style={{ margin: "0 0.3em 0.3em 0" }}>
                <div
                  style={{ color: "#1890ff" }}
                >{`${datetimeHourFrom} - ${datetimeHourTo}`}</div>
                <button
                  className={styles.button}
                  name="remove"
                  onClick={() => {
                    setHourFrom("");
                    setHourTo("");
                  }}
                >
                  <CrossIcon color={"#1890ff"} />
                </button>
              </div>
            ) : null}

            {fulfillerName.items.filter((item) => item.key.trim()).length > 0 &&
              generateTag(fulfillerName, (label) =>
                setFulfillerName((prevFilter) => ({
                  ...prevFilter,
                  items: prevFilter.items.filter((item) => item.key !== label),
                }))
              )}

            {placementGroup.items.filter((item) => item.key.trim()).length >
              0 &&
              generateTag(placementGroup, (label) =>
                setPlacementGroup((prevFilter) => ({
                  ...prevFilter,
                  items: prevFilter.items.filter((item) => item.key !== label),
                }))
              )}

            {assets.items.filter((item) => item.key.trim()).length > 0 &&
              generateTag(assets, (label) =>
                setAssets((prevFilter) => ({
                  ...prevFilter,
                  items: prevFilter.items.filter((item) => item.key !== label),
                }))
              )}

            {tags.items.filter((item) => item.key.trim()).length > 0 &&
              generateTag(tags, (label) =>
                setTags((prevFilter) => ({
                  ...prevFilter,
                  items: prevFilter.items.filter((item) => item.key !== label),
                }))
              )}

            {fulfillerUsername.items.filter((item) => item.key.trim()).length >
              0 &&
              generateTag(fulfillerUsername, (label) =>
                setFulfillerUserName((prevFilter) => ({
                  ...prevFilter,
                  items: prevFilter.items.filter((item) => item.key !== label),
                }))
              )}

            {passengers.items.filter((item) => item.key.trim()).length > 0 &&
              generateTag(passengers, (label) =>
                setPassengers((prevFilter) => ({
                  ...prevFilter,
                  items: prevFilter.items.filter((item) => item.key !== label),
                }))
              )}
            {groupCode.items.filter((item) => item.key.trim()).length > 0 &&
              generateTag(groupCode, (label) =>
                setGroupCode((prevFilter) => ({
                  ...prevFilter,
                  items: prevFilter.items.filter((item) => item.key !== label),
                }))
              )}

            {fulfillmentTeamName.items.filter((item) => item.key.trim())
              .length > 0 &&
              generateTag(fulfillmentTeamName, (label) =>
                setFulfillmentTeamName((prevFilter) => ({
                  ...prevFilter,
                  items: prevFilter.items.filter((item) => item.key !== label),
                }))
              )}

            {state.items.filter((item) => item.key.trim()).length > 0 &&
              generateTag(state, (label) =>
                setState((prevFilter) => ({
                  ...prevFilter,
                  items: prevFilter.items.filter((item) => item.key !== label),
                }))
              )}

            {fulfillmentLevel.items.filter((item) => item.key.trim()).length >
              0 &&
              generateTag(fulfillmentLevel, (label) =>
                setFulfillmentLevel((prevFilter) => ({
                  ...prevFilter,
                  items: prevFilter.items.filter((item) => item.key !== label),
                }))
              )}

            {hasNonEmptyState && (
              <>
                <button
                  style={{ cursor: "pointer", textDecorationLine: "underline" }}
                  onClick={closeAllTags}
                >
                  Clear All
                </button>
                <div style={{ marginLeft: "1em" }}>
                  <Button
                    theme="white"
                    onClick={() => setOpen("save")}
                    label={t("container.orders.save")}
                  />
                </div>
              </>
            )}
          </FlexContainer>

          <TabNavBar
            options={[
              {
                id: "eventsList",
                label: `${t(
                  "container.events.events"
                )} (${numberFormatNoFractions(
                  events.fulfillmentEvents.count
                )})`,
                path: `/events`,
                onClick: () => (document.title = "RAVENT APP :: Events"),
              },
              {
                id: "missignList",
                label: `${t(
                  "container.events.missingPlacements"
                )} (${numberFormatNoFractions(count)})`,
                path: `/events/missing`,
                onClick: () =>
                  (document.title = "RAVENT APP :: Events :: Missing"),
              },
            ]}
          >
            <Switch>
              <Route path={`/events`} exact>
                <div className={styles.content}>
                  <>
                    {!selected.length ? null : (
                      <FlexContainer
                        padding="0 0 1em 0"
                        justifyContent="space-between"
                      >
                        <ButtonsContainer>
                          {" "}
                          <Button
                            theme="white"
                            label={t("container.orders.sendSelection")}
                            icon={flow}
                            disable={!selected.length}
                            onClick={() => setOpenFlows("flows")}
                          />
                          <Button
                            theme="white"
                            label={t("container.events.setTeam")}
                            icon={fulfillerIcon}
                            disable={!selected.length}
                            onClick={() => setOpen("teams")}
                          />
                          <Button
                            theme="white"
                            label={t("container.events.setAsset")}
                            icon={assetIcon}
                            disable={!selected.length}
                            onClick={() => setOpen("assets")}
                          />
                        </ButtonsContainer>
                        {selected.length ? (
                          <Text
                            padding="0 1em 0 0"
                            text={`${selected.length} ${t(
                              "container.events.selected"
                            )}`}
                          />
                        ) : null}
                      </FlexContainer>
                    )}
                    <EventList
                      loadingState={loadingState}
                      type={type}
                      setNew={(id, state) => onChangeEventState(id, state)}
                      errorState={""}
                      updateFulfillmentName={(
                        id,
                        diveshopId,
                        fulfillmentLevel
                      ) => {
                        fulfillmentLevel === "order"
                          ? onUpdateOrder(id, { fulfillmentDefinitionId: id })
                          : onUpdateOrderline(id, diveshopId);
                      }}
                      fulfillmentNames={fulfillments.list.data.map((item) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                      setState={(id, fulfillmentLevel, status, w3w, images) => {
                        fulfillmentLevel === "order"
                          ? onSetStateOrder(id, status, w3w, images)
                          : onSetState(id, status, w3w, images);
                      }}
                      assets={assetsList.list.data}
                      onSaveLoad={(eventId, load) =>
                        onUpdateEvent(eventId, { loads: load })
                      }
                      teams={teams.list.data.map((item) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                      updateFulfiller={(eventId, fulfillerId) =>
                        onUpdateEvent(eventId, { fulfillerId: fulfillerId })
                      }
                      updateTeam={(eventId, teamId) =>
                        onUpdateEvent(eventId, { fulfillmentTeamId: teamId })
                      }
                      updateAsset={async (e: FulfillmentEvent, id: string) => {
                        onUpdateEvent(
                          e.id,
                          { assetId: id },
                          "Asset was successfully updated"
                        );
                      }}
                      onChangeSortOrder={(sort) => {
                        setPage(0);
                        setSortBy(sort);
                      }}
                      onTagClick={(tag) => {
                        setPage(0);
                        const existingIndex = tags.items.findIndex(
                          (item) => item.key === tag
                        );
                        let updatedItems;
                        if (existingIndex !== -1) {
                          updatedItems = tags.items.filter(
                            (item) => item.key !== tag
                          );
                        } else {
                          updatedItems = [
                            ...tags.items,
                            {
                              key: tag,
                              include: tags?.items.every(
                                (item) => item.include === true
                              ),
                            },
                          ];
                        }
                        setTags({
                          ...tags,
                          items: updatedItems,
                        });
                      }}
                      page={parseInt(page as string)}
                      total={events.fulfillmentEvents.count}
                      hitsPerPage={parseInt(records_per_page as string)}
                      onPageChange={setPage}
                      onHitsPerPageChange={(number) => setHitsPerPage(number)}
                      pagination
                      error={events.error}
                      loading={events.loading}
                      data={events.fulfillmentEvents.hits}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </>
                </div>
              </Route>
              <Route path={`/events/missing`} exact>
                <div className={styles.content}>
                  <Missing
                    onTagClick={(tag) => {
                      setPage(0);
                      const existingIndex = tags.items.findIndex(
                        (item) => item.key === tag
                      );
                      let updatedItems;
                      if (existingIndex !== -1) {
                        updatedItems = tags.items.filter(
                          (item) => item.key !== tag
                        );
                      } else {
                        updatedItems = [
                          ...tags.items,
                          {
                            key: tag,
                            include: tags?.items.every(
                              (item) => item.include === true
                            ),
                          },
                        ];
                      }
                      setTags({
                        ...tags,
                        items: updatedItems,
                      });
                    }}
                    type={type}
                    error={errorM}
                    // search={searchM}
                    // setSearch={setSearchM}
                    count={count.toString()}
                    locations={missingL}
                    loading={loadingM}
                    page={pageM as number}
                    total={count}
                    hitsPerPage={hitsPerPageM as number}
                    setHitsPerPage={setHitsPerPageM}
                    setPage={setPageM}
                    setNew={(id, state) => onChangeEventState(id, state)}
                    errorState={""}
                    updateFulfillmentName={(
                      id,
                      diveshopId,
                      fulfillmentLevel
                    ) => {
                      fulfillmentLevel === "order"
                        ? onUpdateOrder(id, { fulfillmentDefinitionId: id })
                        : onUpdateOrderline(id, diveshopId);
                    }}
                    fulfillmentNames={fulfillments.list.data.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    setState={(id, fulfillmentLevel, status, w3w, images) => {
                      fulfillmentLevel === "order"
                        ? onSetStateOrder(id, status, w3w, images)
                        : onSetState(id, status, w3w, images);
                    }}
                    assets={assetsList.list.data}
                    onSaveLoad={(eventId, load) =>
                      onUpdateEvent(eventId, { loads: load })
                    }
                    teams={teams.list.data.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    updateFulfiller={(eventId, fulfillerId) =>
                      onUpdateEvent(eventId, { fulfillerId: fulfillerId })
                    }
                    updateTeam={(eventId, teamId) =>
                      onUpdateEvent(eventId, { fulfillmentTeamId: teamId })
                    }
                    updateAsset={async (e: FulfillmentEvent, id: string) => {
                      onUpdateEvent(
                        e.id,
                        { assetId: id },
                        "Asset was successfully updated"
                      );
                    }}
                  />
                </div>
              </Route>
            </Switch>
          </TabNavBar>
        </div>
      </GridContainer>
    </LayouWithSideBar>
  );
};

export default Events;
