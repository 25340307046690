import React from "react";
import { fopIconTypeMap, fopTitleMap } from "../../utils/fop";
import Text from "../Text";

import styles from "./styles";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { Card } from "../Card/Card";

interface Props {
  type: string;
  onSelectType: (type: string) => void;
  active: boolean;
}

const PaymentCard: React.FC<Props> = ({ type, onSelectType, active }) => {
  return (
    <div className={styles.wrapperCard} onClick={() => onSelectType(type)}>
      <Card containerClassname={active ? styles.active : styles.card}>
        <FlexContainer alignItems="center" justifyContent="flex-start">
          <img src={fopIconTypeMap[type]} alt="" className={styles.icon} />
          <Text text={fopTitleMap[type]} fontSize="1.4em" fontWeight={600} />
        </FlexContainer>
      </Card>
    </div>
  );
};

export default PaymentCard;
