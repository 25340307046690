import React, { useCallback, useEffect, useState } from "react";
import { Route, RouteComponentProps, useLocation } from "react-router-dom";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import {
  Action,
  FulfillmentDefinition,
  FulfillmentStateDef,
} from "@innomius/ravent-typescript-types";
import TabNavBar from "../../components/TabNavBar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import styles from "./styles.module.css";
import { Loading } from "../../components/Loading";
import message from "antd/lib/message";
import APIError from "../../utils/APIError";
import { useTranslation } from "react-i18next";
import { fulfillmentList } from "../../store/fulfillments";
import VenueFulfillmentStates from "../../components/VenueFulfillmentStates";
import {
  addStateFulfillment,
  checkFulfillment,
  createFulfillment,
  defaultFulfillment,
  deleteFulfillment,
  updateFulfillment,
} from "../../services/fulfilments";
import CustomError from "../../utils/CustomError";
import { venueList } from "../../store/venues";
import secureLocalStorage from "react-secure-storage";
import { InputSelect } from "../../components/Inputs/InputSelect";
import { listActions } from "../../services/actions";
import qs from "qs";
import { removeEmptyStringFromObject } from "../../utils/order";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { ContainerTitle } from "../../components/ContainerTitle";

interface RouteParams {
  id: string;
}

interface Props extends RouteComponentProps<RouteParams> {}

const Fulfillment: React.FC<Props> = ({ history }) => {
  const [loading, setLoading] = useState("");
  const [open, setModal] = useState("");
  const [openModal, setOpenModal] = useState(false);
  function useQuery() {
    const { search } = useLocation();
    return search;
  }
  const query = useQuery();

  const [venueId, setVenueId] = useState(
    (qs.parse(query.substring(1)).venueId as string) ||
      (secureLocalStorage.getItem("venueId") as string)
  );
  const [actions, setActions] = useState<Action[]>([]);

  const { t } = useTranslation();

  const fulfillments = useSelector((state: RootState) => state.fulfillments);
  const [records_per_page_fulfillment, setHitsPerPageF] = useState(10);
  const [pageF, setPageF] = useState(0);
  const dispatch = useDispatch();

  const [fulfillment, setFulfillment] =
    useState<FulfillmentDefinition>(defaultFulfillment);
  const [loadingF, setloadingF] = useState<boolean>(false);
  const [validateError, setValidateError] = useState<CustomError>();
  const venues = useSelector((state: RootState) => state.venues);

  const loadVenues = useCallback(async () => {
    dispatch(venueList({ page: 0, records_per_page: 100 }));
  }, [dispatch]);

  useEffect(() => {
    loadVenues();
  }, [loadVenues]);

  const loadActions = useCallback(async () => {
    try {
      const res = await listActions({
        page: 0,
        records_per_page: 100,
        venue_id: venueId,
      });
      setActions(res.data);
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  }, [venueId]);

  useEffect(() => {
    loadActions();
  }, [loadActions]);

  const onChangeFulfillment = <P extends keyof FulfillmentDefinition>(
    prop: P,
    value: FulfillmentDefinition[P]
  ) => setFulfillment({ ...fulfillment, [prop]: value });

  const onCreateFulfillment = async () => {
    setValidateError(undefined);
    try {
      checkFulfillment(fulfillment);
    } catch (err) {
      if (err instanceof CustomError) {
        setValidateError(err);
      }
      return;
    }
    try {
      setloadingF(true);
      const res = await createFulfillment(fulfillment);
      setloadingF(false);
      setFulfillment(defaultFulfillment);
      message.success(t("container.createFulfillment.successCreation"));
      setValidateError(undefined);
      setOpenModal(true);
      history.push(`/fulfillments/${res.id}`);
      setLoading("");
      loadF();
    } catch (err) {
      setloadingF(false);
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const loadF = useCallback(async () => {
    dispatch(
      fulfillmentList({
        page: pageF,
        records_per_page: records_per_page_fulfillment,
        venue_id: venueId as string,
      })
    );
    const states = {
      page: pageF.toString(),
      venueId: venueId,
    };
    const params = new URLSearchParams(removeEmptyStringFromObject(states));
    history.replace(`/fulfillments?${params}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, records_per_page_fulfillment, pageF, venueId]);

  useEffect(() => {
    loadF();
  }, [loadF]);

  useEffect(() => {
    document.title = "RAVENT APP :: Fulfillment :: States";
  });

  if (loading === "detail") {
    return <Loading />;
  }

  //   if (!ful) {
  //     return <EmptyList text="Error" instructions={""} error={error} />;
  //   }

  const onUpdateFulfillmentState = async (
    fulfillment: FulfillmentDefinition
  ) => {
    try {
      setLoading("update");
      await updateFulfillment(fulfillment);
      message.success(t("container.fulfillmentDetail.successUpdated"));
      history.push(`/fulfillments/${fulfillment.id}`);
      setOpenModal(true);
      setLoading("");
      loadF();
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`Error: ${err.messages}`);
      }
      setLoading("");
    }
  };

  const onDeleteFulfillmentState = async (id: string) => {
    try {
      setLoading("delete");
      await deleteFulfillment(id);
      message.success(t("container.fulfillmentDetail.successDeleted"));
      history.push("/fulfillments");
      setLoading("");
      loadF();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onAddFulfillmentState = async (
    fulfillment: FulfillmentDefinition,
    data: FulfillmentStateDef,
    edit: boolean,
    index?: number
  ) => {
    try {
      setLoading("state");
      await addStateFulfillment(
        fulfillment,
        data,
        fulfillment.fulfillmentStatesDefinitions,
        edit,
        index
      );
      message.success(t("container.fulfillment.success"));
      setLoading("");
      loadF();
      setOpenModal(true);
      history.push(`/fulfillments/${fulfillment.id}`);
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`Error: ${err.messages}`);
      }
      setLoading("");
    }
  };

  const tabStatesDefinitions = {
    id: "statesDefinitions",
    label: `${t("container.fulfillment.statesDef")}`,
    path: `/fulfillments`,
    onClick: () => (document.title = "RAVENT APP :: Fulfillment :: States"),
  };

  return (
    <LayouWithSideBar>
      <div className={styles.container}>
        <FlexContainer
          alignItems="flex-start"
          width="100%"
          justifyContent="space-between"
        >
          <ContainerTitle
            size="medium"
            label={t("container.fulfillment.fulfillment")}
          />
          <InputSelect
            containerClassname={styles.inputSelect}
            options={venues.list.data.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            value={venueId as string}
            label={t("container.fulfillment.venue")}
            showError={false}
            showLabel={false}
            onChange={(value) => {
              setPageF(0);
              setVenueId(value);
            }}
          />
        </FlexContainer>

        <TabNavBar options={[tabStatesDefinitions]}>
          <Route path={`/fulfillments`}>
            <VenueFulfillmentStates
              load={loadF}
              onDelete={onDeleteFulfillmentState}
              actions={actions}
              openModal={openModal}
              setOpen={setOpenModal}
              path={`/fulfillments`}
              onDeleteFulfillmentState={onDeleteFulfillmentState}
              onAddState={onAddFulfillmentState}
              setModal={setModal}
              open={open}
              onEditFulfillmentDefinition={onUpdateFulfillmentState}
              validateError={validateError}
              loadingF={loadingF}
              onCreate={onCreateFulfillment}
              venueId={venueId as string}
              hitsPerPage={records_per_page_fulfillment}
              page={pageF}
              setHitsPerPage={setHitsPerPageF}
              setPage={setPageF}
              total={fulfillments.list.total}
              recordsPerPage={records_per_page_fulfillment}
              fulfillments={fulfillments.list}
              error={fulfillments.error}
              loading={fulfillments.loading}
              fulfillment={fulfillment}
              onChangeFulfillment={onChangeFulfillment}
            />
          </Route>
        </TabNavBar>
      </div>
    </LayouWithSideBar>
  );
};

export default Fulfillment;
