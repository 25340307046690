import { stylesheet } from "typestyle";
import { black, linkBlue } from "../../../utils/colors";

const styles = stylesheet({
  container: {
    padding: "2em",
  },
  title: {
    fontSize: "1.5em",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "left",
    color: "#141322",
    paddingTop: "1em",
  },

  big: {
    color: "#005EA2",
    fontSize: "1.1em",
    fontStyle: "normal",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "1em",
    fontWeight: 700,
    display: "flex",
    flexWrap: "wrap",
  },
  chevron: {
    color: "#333333",
  },
  border: {
    borderRight: "1px solid #DCE1E6",
    padding: "1.2em",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    alignItems: "flex-end",
    background: "#005EA2",
  },
  placementCard: {
    padding: "1em",
    border: "1px solid #E6E9ED",
    borderRadius: 6,
    background: "#fff",
    height: "100%",
    marginTop: "1em",
  },
  notEnrollContainer: {
    background: "rgba(250, 173, 21, 0.1)",
    border: "1px solid #FAAD15",
    padding: "0.5em",
    borderRadius: "6px",
  },

  placement: {
    borderBottom: "1px solid #DCE1E6",
    borderLeft: "1px solid #DCE1E6",
    borderRight: "1px solid #DCE1E6",
    padding: "1em",
    background: "#F3F9FE",
  },

  borderPlacement: {},

  filtersContainer: {
    paddingBottom: "2em",
  },
  filtersContainerW: {
    paddingBottom: "1.5em",
  },
  editContainer: {
    borderRadius: 3,
    border: "1px solid #DCE1E6",
    display: "flex",
    justifyContent: "center",
    padding: "0em 0.6em",
    marginLeft: "1em",
    background: "white",
    cursor: "pointer",
    height: "2.5em",
  },
  iconEdit: {
    width: "1.3em",
  },

  loading: {
    color: black,
    fontWeight: 600,
    padding: "6em 1em",
  },

  labelContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    marginRight: "0.5em",
    padding: "1em",
  },
  label: {
    margin: "0.2em 0.5em 0.2em 0",
    width: "auto",
    color: linkBlue + " !important",
  },

  center: {
    color: black,
    fontStyle: "normal",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "1em",
    display: "flex",
    flexWrap: "wrap",
  },

  centerBig: {
    color: black,
    fontSize: "1.1em",
    fontStyle: "normal",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "1em",
    fontWeight: 700,
    display: "flex",
    flexWrap: "wrap",
  },

  spaceBetween: {
    color: black,
    fontStyle: "normal",
    justifyContent: "space-evenly",
    padding: "1em",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
  },
  normal: {
    padding: "1em",
    color: black,
    display: "flex",
    alignItems: "center",
    fontSize: "1em",
    fontWeight: 500,
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  normalButton: {
    cursor: "pointer",
    fontSize: "1em",
    fontWeight: 600,
    padding: "0 0.5em",
    border: "1px solid #dce1e6",
    borderRadius: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "2.9em",
    minWidth: "9em",
    background: "#fff",
  },
  icon: {
    width: 24,
    cursor: "pointer",
  },
  iconBig: {
    width: 40,
    cursor: "pointer",
  },
  smallIcon: {
    width: "1.2em",
    cursor: "pointer",
    margin: "0 0.5em 0 0.1em",
  },
  borderTop: {
    borderTop: "1px solid #DCE1E6",
    margin: "0.5em 0",
  },
  iconDelete: {
    width: 30,
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
  },
  paginationContainer: {
    padding: "2em 0",
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  listContainer: {
    marginTop: "0",
  },
  blueCircle: {
    background: "#00BFBF",
    width: "10px",
    height: "10px",
    borderRadius: 5,
    position: "absolute",
    left: "-1.38em",
    top: "45%"
  },
  line: {
    top: "60%",
    position: 'absolute',
    width: '1px',
    height: '100%',
    left: "-1.1em",
    borderLeft: '1px dashed #dedede'
  },
  relative: {
    position: 'relative',
  },
  cutDiv: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
  },
  placementList: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
  },
  cutDivInner: {
    position: 'relative',
    height: '100%',
  },
  borderLeftHalf: {
    position: 'absolute',
    content: '""',
    left: '50%',
    borderLeft: '1px dashed #dedede',
    height: '100%',
  },

});

export default styles;
