const translation = {
  en: {
    details: "Details",
    legalName: "Name",
    type: "Type",
    internal: "Internal",
    phone: "Phone",
    email: "Email",
    enabled: "Enabled",
    data: "Data",
    address: "Address",
    addressType: "Address Type",
    appConfig: "Fulfiller App Configuration",
    fulfilmentType: "Fulfillment Type",
    service: "Service",
    allowChat: "Allow Fulfillers to Chat with Customers",
    allowDetails: "Allow Fulfiller to see Order Details",
    venue: "Venue",
    orders: "Orders",
    orderlines: "Order Lines",
    invoices: "Invoices",
    infoOrderE:
      "All of the fields which belong to the Order object will be visible to this team excepting the ones listed in the following box",
    infoOrderI:
      "The only fields belonging to the Order object this team will have access to are listed below",
    infoInvoiceE:
      "All of the fields which belong to the Invoice object will be visible to this team excepting the ones listed in the following box",
    infoInvoiceI:
      "The only fields belonging to the Invoice object this team will have access to are listed below",
    infoLinesE:
      "All of the fields which belong to the Orderline object will be visible to this team excepting the ones listed in the following box",
    infoLinesI:
      "The only fields belonging to the Orderline object this team will have access to are listed below",
  },
  es: {
    data: "Datos",
    internal: "Interno",
    infoOrderE:
      "Todos los campos que pertenecen al objeto Orden serán visibles para este equipo, excepto los que se enumeran en el siguiente cuadro",
    infoOrderI:
      "Los únicos campos pertenecientes al objeto Orden a los que este equipo tendrá acceso se enumeran a continuación",
    infoInvoiceE:
      "Todos los campos que pertenecen al objeto Invoice serán visibles para este equipo, excepto los que se enumeran en el siguiente cuadro",
    infoInvoiceI:
      "Los únicos campos pertenecientes al objeto Invoice a los que este equipo tendrá acceso se enumeran a continuación",
    InfoLinesE:
      "Todos los campos que pertenecen al objeto Orderline serán visibles para este equipo, excepto los que se enumeran en el siguiente cuadro",
    infoLineasI:
      "Los únicos campos que pertenecen al objeto Orderline a los que este equipo tendrá acceso se enumeran a continuación",
    orders: "Órdenes",
    orderlines: "Líneas de Orden",
    invoices: "Facturas",
    details: "Detalles",
    venue: "Recinto",

    legalName: "Nombre",
    type: "Tipo",
    phone: "Número de teléfono",
    email: "Correo electrónico",
    enabled: "Habilitado",
    address: "Dirección",
    addressType: "Tipo de dirección",
    appConfig: "Configuración de la aplicación Fulfiller",
    fulfilmentType: "Tipo de entrega",
    service: "Servicio",
    allowChat: "Permitir a los fulfillers chatear con los clientes",
    allowDetails: "Permitir a los fulfillers ver los detalles del pedido",
  },
  de: {},
};

export default translation;
