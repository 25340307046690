import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles";
import logo from "../../assets/logo.svg";
import { InputText } from "../Inputs/InputText";
import { Button } from "../Button";

export interface Props {
  password: string;
  confirm: string;
  onChangePassword: (value: string) => void;
  onChangeConfirm: (value: string) => void;
  setError: () => void;
  error: string;
  sendMail: () => void;
  loading: boolean;
}

export const SetPasswordMobile: React.FC<Props> = ({
  password,
  confirm,
  onChangeConfirm,
  onChangePassword,
  setError,
  error,
  sendMail,
  loading,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className={styles.inputsContainer}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="logo" className={styles.logo} />
        </div>
        <div className={styles.subtitle}>
          {t("container.setPassword.setPasswordLabel")}
        </div>
        <div className={styles.text}>
          {t("container.setPassword.instructions")}
        </div>
        <div>
          <InputText
            label={t("container.setPassword.password")}
            onChange={(value: string) => onChangePassword(value)}
            value={password}
            password
          />
          <InputText
            onFocus={() => setError()}
            label={t("container.setPassword.confirm")}
            onChange={(value: string) => onChangeConfirm(value)}
            value={confirm}
            error={error}
            password
          />
          <div>
            <Button
              width="100%"
              label={
                loading
                  ? "..."
                  : `${t("container.setPassword.setPasswordLabel")}`
              }
              onClick={() => {
                if (loading) {
                  return;
                }
                sendMail();
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div>
          © 2022 Ravent
          <span className={styles.footerText}>
            {" "}
            | {t("container.setPassword.rightsReserved")}
          </span>{" "}
        </div>
      </div>
    </div>
  );
};
