const translation = {
  en: {
    successBank: "FOP Bank successfully updated.",
    successCreated: "FOP successfully created.",
    successDeleted: "FOP successfully deleted.",
    back: "Back to Payments",
    createNew: "+ Create FOP",
    fops: "Form of Payments",
    search: "Search",
    instructions: "Start creating FOP",
  },
  es: {
    successBank: "Banco FDP se actualizó con éxito.",
    successCreated: "FDP creado con éxito.",
    successDeleted: "FDP eliminado con éxito.",
    back: "Regresar a Configuración",
    createNew: "+ Crear FDP",
    fops: "Forma de Pagos",
    search: "Buscar",
    instructions: "Empezar a crear FDP",
    error: "Error",
    cancel: "Cancelar",
    save: "Guardar",
    delete: "Eliminar",
    edit: "Editar",
  },
  de: {
    successBank: "FOP Bank successfully updated.",
    successCreated: "FOP successfully created.",
    successDeleted: "FOP successfully deleted.",
    back: "Back to Payments",
    createNew: "+ Create FOP",
    fops: "Form of Payments",
    search: "Search",
    instructions: "Start creating FOP",
    error: "Error",
    cancel: "Cancel",
    save: "Save",
    delete: "Delete",
    edit: "Edit",
  },
};

export default translation;
