const translation = {
  en: {
    userInfo: "User Information",
    name: "Name",
    lastName: "Last Name",
    scope: "Scopes for Resource Management",
    type: "Scope",
    details: "Details",
    adminRole: "Admin Role",
    description: "Description",
    endpoint: "Endpoint",
    allowAll: "Allow All",
    group: "Group",
    roleId: "Role ID",
  },
  es: {
    roleId: "ID de Rol",
    description: "Descripción",
    endpoint: "Punto final",
    allowAll: "Permitir todo",
    group: "Grupo",
    type: "Alcance",
    scope: "Alcances para la Gestión de Recursos",
    details: "Detalles",
    adminRole: "Roles de Administrador",
    tags: "Etiquetas",
    associatedTeam: "Equipo Asociado",
    userInfo: "Información del usuario",
    noTags: "No tiene etiquetas para este usuario",
    name: "Nombre",
    lastName: "Apellidos",
    email: "Correo electrónico",
    phone: "Teléfono",
    roles: "Roles",
  },
  de: {},
};

export default translation;
