import { stylesheet } from "typestyle";

const styles = stylesheet({
  container: {
    padding: "1em",
  },
  iconStyle: {
    width: "46px",
  },
  chevron: {
    width: "24px",
  },
  card: {
    padding: "2em",
  },
  cardMiddle: {
    margin: "1em 0em",
    padding: "2em",
  },
  lastCard: {
    margin: "1em 0em",
    padding: "2em",
  },
});

export default styles;
