import React, { useCallback, useEffect, useState } from "react";
import { Route } from "react-router-dom";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";

import { Card } from "../../components/Card/Card";
import styles from "./styles.module.css";
import EditProfileForm from "../../components/EditProfileForm";
import ChangePassword from "../../components/ChangePassword";
import { getProfile } from "../../services/profile";
import { Loading } from "../../components/Loading";
import {
  changePassword,
  CreateUserParams,
  updateUser,
  updateUserAuthz,
} from "../../services/users";
import message from "antd/lib/message";
import { User } from "@innomius/ravent-typescript-types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import APIError from "../../utils/APIError";
import { venueList } from "../../store/venues";
import { readOrg } from "../../services/entities";
import GlobalDetail, { Props } from "../../components/GlobalDetail";
import EditGlobal from "../../components/EditGlobal";
import ProfileComponent from "../../components/ProfileComponent";
import { useTranslation } from "react-i18next";
import ErrorBox from "../../components/ErrorBox";
import { ButtonsContainer } from "../../components/ButtonsContainer";
import { ContainerTitle } from "../../components/ContainerTitle";
import { Button } from "../../components/Button";
import TabNavBar from "../../components/TabNavBar";
import { SectionProperty } from "../../components/Section/SectionProperty";
import { Profile } from "../../utils/types";

const ProfileDetail: React.FC<Props> = () => {
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const venues = useSelector((state: RootState) => state.venues);

  const [edit, setEdit] = useState("");
  const [profile, setUser] = useState<Profile | null>();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const [current, setCurrent] = useState("");
  const [confirmedPassword, setConfirm] = useState("");
  const [newPassword, setNew] = useState("");
  const [orgName, setOrgName] = useState("");
  const [open, setOpen] = useState("");
  const [numberFormat, setNumberFormat] = useState(
    localStorage.getItem("numberFormat") || "en-US"
  );

  const [timeZone, setTimeZone] = useState(
    localStorage.getItem("timeZone") ||
      Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [dateFormat, setDateFormat] = useState(
    localStorage.getItem("dateFormat") || "es-MX"
  );

  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "en"
  );

  const changeLanguage = (lang: string) => {
    localStorage.setItem("language", lang);
    i18n.changeLanguage(lang);
  };

  const load = useCallback(async () => {
    dispatch(venueList({}));

    try {
      setLoading("details");
      const res = await getProfile();
      setUser(res);
      const resOrg = await readOrg(res.organizationId);
      setOrgName(resOrg.legalName);

      setLoading("");
    } catch (err) {
      setLoading("");
      if (err instanceof Error) {
        setError(err.message);
        console.log(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    document.title = "RAVENT APP :: Profile";
  });

  if (loading === "details") {
    return <Loading />;
  }

  if (!profile) {
    return <ErrorBox error={error} goBackButton />;
  }

  const onUserUpdate = async () => {
    try {
      setLoading("update");
      await updateUser(profile as unknown as User);
      message.success(t("container.profileDetail.successUpdated"));
      setEdit("");
      setLoading("");
      load();
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`Error: ${err.message}`);
      }
      setLoading("");
    }
  };

  const onChangePassword = async () => {
    if (newPassword !== confirmedPassword) {
      message.error(t("container.profileDetail.differentPassword"));
      return;
    }
    try {
      setLoading("password");
      await changePassword(profile.id, current, newPassword);
      message.success(t("container.profileDetail.successPassword"));
      setEdit("");
      setLoading("");
      setCurrent("");
      setConfirm("");
      setNew("");
      load();
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`Error: ${err.message}`);
      }
      setLoading("");
    }
  };

  const onChange = <P extends keyof CreateUserParams>(
    prop: P,
    value: CreateUserParams[P]
  ) => {
    setUser({ ...profile, [prop]: value });
  };

  const onAssign = async (venueId: string) => {
    try {
      setLoading("assign");
      await updateUserAuthz(profile.id, venueId, profile.roles);
      setOpen("");
      message.success("Venue assignment successfull");
      setLoading("");
    } catch (err) {
      if (err instanceof APIError) {
        message.error(err.message);
      }
      setLoading("");
    }
  };

  const preferenceTab = {
    id: "profile",
    label: `${t("container.profileDetail.userPreferences")}`,
    path: `/profile/preferences`,
    onClick: () => (document.title = "RAVENT APP :: Profile :: Preferences"),
  };

  const profileTab = {
    id: "profile",
    label: `${t("container.profileDetail.profile")}`,
    path: `/profile`,
    onClick: () => (document.title = "RAVENT APP :: Profile"),
  };

  return (
    <LayouWithSideBar>
      <div className={styles.container}>
        <FlexContainer padding="0 0 1em 0" justifyContent="space-between">
          <ContainerTitle
            padding="0 0 0.5em 0"
            size="medium"
            label={
              edit === "profile"
                ? t("container.profileDetail.editProfile")
                : edit === "password"
                ? t("container.profileDetail.changePassword")
                : t("container.profileDetail.profile")
            }
          />
          {edit === "profile" ? (
            <ButtonsContainer>
              <Button
                label={t("container.profileDetail.cancel")}
                onClick={() => setEdit("")}
                theme="white"
              />
              <Button
                label={t("container.profileDetail.save")}
                onClick={async () => {
                  localStorage.setItem("dateFormat", dateFormat);
                  localStorage.setItem("numberFormat", numberFormat);
                  localStorage.setItem("timeZone", timeZone);
                  await onUserUpdate();
                  setEdit("");
                  load();
                }}
                theme="blue"
              />
            </ButtonsContainer>
          ) : edit === "password" ? (
            <ButtonsContainer>
              <Button
                label={t("container.profileDetail.cancel")}
                onClick={() => {
                  setEdit("");
                  setCurrent("");
                  setConfirm("");
                  setNew("");
                }}
                theme="white"
              />
              <Button
                label={
                  loading === "password"
                    ? "..."
                    : t("container.profileDetail.save")
                }
                onClick={() => onChangePassword()}
                theme="blue"
              />
            </ButtonsContainer>
          ) : (
            <Button
              label={t("container.profileDetail.editInfo")}
              onClick={() => setEdit("profile")}
              theme="white"
            />
          )}
        </FlexContainer>

        <TabNavBar options={[preferenceTab, profileTab]}>
          <Route exact path={`/profile`}>
            <Card containerClassname={styles.cardContainer}>
              {edit === "profile" ? (
                <EditProfileForm
                  onChange={onChange}
                  data={profile as unknown as User}
                />
              ) : edit === "password" ? (
                <ChangePassword
                  setConfirm={(value) => setConfirm(value)}
                  setCurrent={(value) => setCurrent(value)}
                  setNew={(value) => setNew(value)}
                  current={current}
                  newPassword={newPassword}
                  confirmedPassword={confirmedPassword}
                />
              ) : (
                <ProfileComponent
                  profile={profile}
                  onAssign={(value) => onAssign(value)}
                  open={open}
                  loading={loading}
                  setOpen={(value) => setOpen(value)}
                  orgName={orgName}
                  onChange={onChange}
                  venues={venues.list.data}
                />
              )}
            </Card>
            {!edit ? (
              <Card containerClassname={styles.card}>
                <FlexContainer justifyContent="space-between">
                  <ContainerTitle
                    padding="0 0 1em 0"
                    size="small"
                    label={t("container.profileDetail.password")}
                  />
                  <Button
                    label={t("container.profileDetail.changePassword")}
                    onClick={() => setEdit("password")}
                  />
                </FlexContainer>
                <SectionProperty
                  label={t("container.profileDetail.currentPassword")}
                  value="• • • • • • •"
                />
              </Card>
            ) : null}
          </Route>
          <Route path={`/profile/preferences`} exact>
            <div className={styles.top}>
              {edit === "profile" ? (
                <EditGlobal
                  numberFormat={numberFormat}
                  dateFormat={dateFormat}
                  timeZone={timeZone}
                  language={language}
                  onChangeDateFormat={(value) => setDateFormat(value)}
                  onChangeNumberFormat={(value) => setNumberFormat(value)}
                  onChangeTimeZone={(value) => setTimeZone(value)}
                  onChangeLanguage={(value) => {
                    changeLanguage(value);
                    setLanguage(value);
                  }}
                />
              ) : (
                <GlobalDetail
                  dateFormat={dateFormat}
                  numberFormat={numberFormat}
                  timeZone={timeZone}
                  language={language}
                />
              )}
            </div>
          </Route>
        </TabNavBar>
      </div>
    </LayouWithSideBar>
  );
};

export default ProfileDetail;
