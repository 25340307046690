import React from "react";
import { OfferListSkeleton } from "../ListSkeletons";
import { Offer } from "@innomius/ravent-typescript-types";
import OfferListItem from "./OfferListItem";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { ListHeader } from "../List/ListHeader";
import { List } from "../List";

interface OfferListProps {
  error: string;
  loading: boolean;
  data: Offer[];
  path: string;
  onChange: <P extends keyof Offer>(prop: P, value: Offer[P]) => void;
}

const OfferListComponent: React.FC<OfferListProps> = ({
  error,
  loading,
  data,
  path,
  onChange,
}) => {
  const header = (
    <div>
      <ListHeader template="2fr 1fr 1.5fr 1fr 1fr">
        <ListHeaderLabel value="Offer Name" justifyContent="flex-start" />
        <ListHeaderLabel value="Ticketing" justifyContent="flex-start" />
        <ListHeaderLabel value="Tags" justifyContent="flex-start" />
        <ListHeaderLabel value="Modifiers" justifyContent="center" />
        <ListHeaderLabel value="" justifyContent="center" />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <List
        loading={loading}
        loadingElement={() => <OfferListSkeleton />}
        error={error}
        data={data}
        keyExtractor={(item) => item.id}
        renderHeader={() => header}
        renderItem={(item) => (
          <OfferListItem
            onChange={onChange}
            path={path}
            data={item}
            key={item.id}
          />
        )}
      />
    </div>
  );
};

export default OfferListComponent;
