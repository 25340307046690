const translation = {
  en: {
    reasonForRefund: "Reason for Refund",
    refund: "Refund",
    paymentId: "Payment ID",
    paymentType: "Payment Type",
    paymentDetails: "Payment Details",
    amount: "Amount",
    sent: "Receipt Sent To",
    close: "Close",
  },
  es: {
    reasonForRefund: "Motivo del reembolso",
    refund: "Reembolso",
    paymentId: "ID de pago",
    paymentType: "Tipo de pago",
    paymentDetails: "Detalles de pago",
    amount: "Cantidad",
    sent: "Recibo enviado a",
    close: "Cerrar",
  },
  de: {},
};

export default translation;
