import React from "react";
import { formatDate, formatDateDNS } from "../../utils/times";
import { useHistory } from "react-router-dom";
import styles from "./styles";
import { Order } from "@innomius/ravent-typescript-types";
import { numberFormat, trimString } from "../../utils/format";
import { StatusBadgePoint } from "../StatusBadgePoint/StatusBagePoint";
import { Checkbox, Tag, Tooltip } from "antd";
import { Tag as TagRavent } from "../Tag/Tag";
import { ListRow } from "../List/ListRow";
import fontColorContrast from "font-color-contrast";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ListLabel } from "../List/ListLabel";
import { StatusCatalog } from "../StatusBadge";

interface OrdersListProps {
  data: Order;
  onTagClick: (item: string) => void;
  markFavourite: (id: string) => void;
  onCustomerClick: (item: string) => void;
  onCheckboxChange?: () => void;
  isSelected?: boolean;
}

const OrderListItem: React.FC<OrdersListProps> = ({
  data,
  onTagClick,
  isSelected,
  onCheckboxChange,
}) => {
  const history = useHistory();

  const formatOptionKey = (key: string, color: string) => {
    if (key.includes("::")) {
      const [firstPart, secondPart] = key.split("::");
      return (
        <Tooltip title={firstPart}>
          <Tag
            style={{
              color: color
                ? color === "blue"
                  ? "blue"
                  : fontColorContrast(color, 0.5)
                : "blue",
            }}
            color={color || "blue"}
          >
            {secondPart}
          </Tag>
        </Tooltip>
      );
    } else {
      return <Tag color={color || "blue"}>{key}</Tag>;
    }
  };

  return (
    <ListRow
      boxShadow
      borderRadius="6px"
      onClick={() => {
        history.push(`orders/${data.id}`);
        document.title = "RAVENT APP :: Order :: Details";
      }}
      template="2em 0.7fr 0.8fr 1fr 1fr 1.2fr 0.3fr 1fr"
      hover={true}
      key={data.id}
    >
      <div className={styles.normal} onClick={(e) => e.stopPropagation()}>
        <Checkbox checked={isSelected} onChange={onCheckboxChange} />
      </div>

      <div className={styles.normalBold}>
        <FlexContainer
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection="column"
        >
          <div
            style={{ padding: data.externalId ? "0 0 0.2em 0" : "" }}
            className={styles.bold}
          >{`#${data.shortId}`}</div>
          <div className={styles.gray}>{data.externalId}</div>
        </FlexContainer>
      </div>

      <ListLabel
        value={formatDateDNS(new Date(data.created), "MIDDLEWHOURYEAR")}
        textAlign="left"
      />
      <div className={styles.normal}>
        {` ${
          data.customer.fullName && data.customer.fullName !== " "
            ? trimString(data.customer.fullName, 40)
            : data.customer.phone
            ? data.customer.phone
            : "anonymous"
        }`}
      </div>

      <ListLabel
        value={data.description ? trimString(data.description, 40) : "-"}
        textAlign="left"
      />
      <div className={styles.labelContainer}>
        {data.colorizedTags?.map(
          (
            item: {
              key: string;
              settings: { tagClassification: string; color: string };
            },
            index
          ) => (
            <div
              onClick={(e) => {
                e.stopPropagation();
                onTagClick(item.key);
              }}
              style={{ margin: "0 0.2em 0.2em 0" }}
              key={index}
            >
              {formatOptionKey(
                item.key,
                item.settings && item.settings.color
                  ? item.settings.color
                  : "blue"
              )}
            </div>
          )
        )}
      </div>
      <div className={styles.normalCol}>
        <StatusBadgePoint
          status={
            data.state === "cancelled" && data.paymentState !== "refunded"
              ? data.state
              : (data.paymentState as StatusCatalog)
          }
        />
        <div style={{ paddingTop: "0.2em" }}>
          {data.state === "cancelled" ? (
            <TagRavent
              width="5em"
              theme="red"
              size="small"
              flexWrap="nowrap"
              label={formatDate(new Date(data.paymentDueDate), "DAYMONTH")}
            />
          ) : data.paymentState === "expired" ? (
            <TagRavent
              width="5em"
              flexWrap="nowrap"
              theme={data.paymentState === "expired" ? "purpleState" : "yellow"}
              size="small"
              label={formatDate(new Date(data.paymentDueDate), "DAYMONTH")}
            />
          ) : data.paymentState === "paid" ? (
            <TagRavent
              width="5em"
              flexWrap="nowrap"
              theme="bluePaid"
              size="small"
              label={formatDate(
                new Date(data.paymentDate as string),
                "DAYMONTH"
              )}
            />
          ) : data.paymentState === "unpaid" && data.paymentDueDate ? (
            <TagRavent
              width="5em"
              theme="yellow"
              size="small"
              flexWrap="nowrap"
              label={formatDate(new Date(data.paymentDueDate), "DAYMONTH")}
            />
          ) : null}
        </div>
      </div>

      <div className={styles.center}>
        <FlexContainer
          justifyContent="flex-end"
          alignItems="flex-end"
          flexDirection="column"
        >
          <div className={styles.bold}>
            {`${data.currency} ${numberFormat(data.totalAmount)}`}
          </div>
        </FlexContainer>
      </div>
    </ListRow>
  );
};

export default OrderListItem;
