import React from "react";
import { OfferSet } from "@innomius/ravent-typescript-types";

import { OfferSetParams } from "../../services/offersets";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { BackLink } from "../BackLink";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { InputSwitch } from "../Inputs/InputSwitch";
import { ContainerTitle } from "../ContainerTitle";
import { EditableTitle } from "../EditableTitle";

interface Props {
  data: OfferSet;
  onChange: <P extends keyof OfferSetParams>(
    prop: P,
    value: OfferSetParams[P]
  ) => void;
  edit?: boolean;
  onEdit?: () => void;
  onCancel?: () => void;
}

const OfferSetDetailHeader: React.FC<Props> = ({
  data,
  onChange,
  edit,
  onCancel,
  onEdit,
}) => {
  let history = useHistory();
  const { t } = useTranslation();


  return (
    <div>
      <FlexContainer alignItems="center" justifyContent="space-between">
        <BackLink
          label={t("container.offerDetail.backToOffers")}
          onClick={() => history.push("/offersets")}
        />
        {edit ? (
          <ButtonsContainer itemsPadding="0 0 0 1em">
            <Button
              label={t("container.offerDetail.cancel")}
              onClick={onCancel}
              theme="white"
            />
            <Button label={t("container.offerDetail.save")} />
          </ButtonsContainer>
        ) : (
          <ButtonsContainer itemsPadding="0 0 0 1em">
            <FlexContainer>
              <InputSwitch value={data.enabled} />
              <p className={styles.text}>
                {data.enabled
                  ? t("container.offerDetail.active")
                  : t("container.offerDetail.inactive")}
              </p>
            </FlexContainer>
            <Button theme="red" label={t("container.offerDetail.delete")} />
            <Button
              label={t("container.offerDetail.edit")}
              onClick={() => {
                history.push(`/offersets/${data.id}`);
                onEdit?.();
              }}
              theme="white"
            />
          </ButtonsContainer>
        )}
      </FlexContainer>

      <FlexContainer
        padding="1em 0 0 0"
        justifyContent="space-between"
        alignItems="center"
      >
        {edit ? (
          <>
            <FlexContainer alignItems="center" justifyContent="flex-start">
              <ContainerTitle size="medium" label="Offer Set:" />
              <EditableTitle
                padding="0"
                titleClassname={styles.sizeName}
                value={data.name}
              />
            </FlexContainer>
            <FlexContainer>
              <ContainerTitle size="medium" label="From: $" />
              <EditableTitle
                titleClassname={styles.sizeAmount}
                value={data.startingPrice.value.toString()}
              />
            </FlexContainer>
          </>
        ) : (
          <>
            <ContainerTitle size="small" label={`Offer Set: ${data.name}`} />
            <ContainerTitle
              size="small"
              label={`From: ${data.startingPrice.currency} ${data.startingPrice.value}`}
            />
          </>
        )}
      </FlexContainer>
    </div>
  );
};

export default OfferSetDetailHeader;
