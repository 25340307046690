const translation = {
  en: {
    bankAccount: "Bank Account",
    addOrgAccount: "Add Organization Account",
    cancel: "Cancel",
    save: "Save",
    bankName: "Bank Name",
    legalName: "Legal Name",
    alias: "Alias",
    qrCodeType: "QR Code Type",
    none: "None",
    epc: "EPC QR Code",
    codi: "Codi",
    addAccount: "Add Account",
  },
  es: {
    bankAccount: "Cuenta Bancaria",
    addOrgAccount: "Agregar Cuenta",
    cancel: "Cancelar",
    save: "Guardar",
    bankName: "Nombre del Banco",
    legalName: "Nombre Legal",
    alias: "Alias",
    qrCodeType: "Tipo de código QR",
    none: "Ninguno",
    epc: "Código QR EPC",
    codi: "Codi",
    addAccount: "Agregar Cuenta",
  },
  de: {},
};

export default translation;
