import { InputColors, black } from "../../constants/colors";
import { lightGrey } from "../../utils/colors";

export const Sizes = {
  labelFontSize: "1em",
  borderRadius: 3,
  borderWidth: 1,
  inputHeight: "3em",
};

export const BaseInput = {
  container: {
    padding: "0 0 0 0",
  },
  labelContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "2em",
  },
  label: {
    padding: "0 0 0.6em 0",
    color: black,
    fontSize: Sizes.labelFontSize,
    fontWeight: 600,
  },
  errorContaienr: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "2em",
  },
  error: {
    padding: "0 0 .4em 0",
    color: "red",
    fontSize: Sizes.labelFontSize,
    fontWeight: 600,
  },
  wrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderColor: "transparent",
    transition: ".2s all ease",
  },
  wrapperFocused: {
    borderColor: InputColors.inputFocusBorderColor,
    boxShadow: "0 0 0 2px rgb(24 144 255 / 20%);",
  },
  input: {
    width: "100%",
    minHeight: "2.3em",
    fontSize: "1em",
    background: "#FEFEFE",
    borderRadius: "2px",
    padding: "0.2em 0.5em",
    outline: 0,
    borderColor: "#d9d9d9",
    borderWidth: "1px",
    borderStyle: "solid",
    $nest: {
      "&:disabled": {
        backgroundColor: InputColors.inputBackgroundColor,
        color: lightGrey,
      },
      "&::-webkit-input-placeholder": {
        color: "#BFBFBF",
      },
      "&:focus": {
        borderColor: InputColors.inputFocusBorderColor,
        boxShadow: "0 0 0 2px rgb(24 144 255 / 20%)",
      },
    },
  },
};
