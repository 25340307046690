import { stylesheet } from "typestyle";

const styles = stylesheet({
  container: {
    minHeight: "100vh",
  },
  wrapperCard: {
    padding: "2em",
  },

  padding: {
    padding: "0 1em 0 1em",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center !important",
    cursor: "pointer",
    width: "1em",
    marginLeft: "1em",
  },
  maxEvents: {
    width: "20%",
    marginLeft: "5%",
  },

  icon: {
    width: "52px",
    display: "flex",
    justifyContent: "center",
  },
  tagContainer: {
    margin: "0em 1em 0 0",
  },
  switch: {
    padding: "0 0 0 1em",
  },
  switchText: {
    padding: "0 1em 0 1em",
  },
  radioContainer: {
    marginRight: "3em",
    padding: "1em 0",
  },
  status: {
    minWidth: "40%",
  },
  propertyInstructions: {
    padding: "0 0 1.5em 0",
    textAlign: "justify",
  },
});

export default styles;
