const translation = {
  en: {
    fulfillerNotAssigned: "Fulfiller not assigned",
    goBack: "Go Back",
    updateDiveShop: "Update Team",
    notificationDiveShop:
      "A notification will be send to the selected Team with the order assignment.",
    diveShops: "Teams",
    status: "Status",
    timestamp: "Timestamp",
    locationName: "Location Name",
    fulfillment: "Fulfillment",
    user: "User",
    name: "Name",
    diveshop: "Team",
    setDiveshop: "Set Fulfiller",
    fulfilledTimestamp: "Fulfilled Timestamp",
    currentState: "Current State",
    selectVenue: "Select Venue",
    search: "Search",
    selectLocation: "Select Locations",
    cancel: "Cancel",
    save: "Save",
    noLocation: "This venue doesn't have locations.",
    setLocation: "Set Location",
    setExistingLocation: "Set Exisiting Location",
    createNewLocation: "Create New Location",
    location: "Location",
    venueName: "Venue Name",
    locationType: "Location Type",
    w3w: "What Three Words",
    postalAddress: "Postal Address",
    wellKnownAddress: "Well Known Address",
    notes: "Notes",
    addressLine1: "Address Line 1",
    addressLine2: "Address Line 2",
    city: "City",
    state: "State",
    zipCode: "Zip Code",
    country: "Country",
    reference: "Reference",
    action: "Action",
    deleteMessage: "Are you sure you want to delete",
    setStatus: "set state",
    fulfillmentStatus: "Fulfillment State",
    activating: "You are activating",
    on: "on",
    fromLocation: "from location:",
    geoLocationNotSupported: "Geolocation is not supported by your browser",
    states: "States",
    fulfilled: "Fulfilled",
    clickOnActivate: "Click on a state to activate following",
    setFulfillment: "Set Definition",
    notAssigned: "Not Assigned",
    assign: "Set",
    noLocationAdded: "No Location added",
    disabled: "Disabled",
    orderLine: "Order Line",
    fulfillmentDefinition: "Definition",
    setDefinition: "Select a Fulfillment Definition for this Orderline.",
    updateDefinition: "Update Definition",
    tags: "Tags",
    date: "Date/Time",
    noFulfillment: "The list is empty until you select  a definition",
    noAssignedDefinition:
      "No Fulfillment Definition is assigned for this orderline.",
    lastName: "Last Name",
    files: "Files",

    history: "States Historic",
    locations: "Locations",
    noStateActivated:
      "The States historic list will be empty until you start activating states",
    noStateConfigure: "No State was configured",
    empty: "Empty List",
    instructionsLocations: "Add new location for your fulfillment.",
    locationsNotEnabled: "Locations are not enabled for this orderline. ",
    ticketing: "Ticketing",
    type: "Type",
    fulfillmentDetails: "Fulfillment Details",
    select: "Set",
    setTeam: "Set Fulfiller",
    selectState: "Click on a state to activate following",
    fulfiller: "Fulfiller",
    link: "Link to Definition",
    phone: "Phone",
    callTeam: "Call Team",
    callFulfiller: "Call Fulfiller",
    sendMessage: "Send Message",
    updateFulfiller: "Set Fulfiller",
    fulfillers: "Fulfillers",
    notificationfulfiller: "Assign fulfiller to be in charge of this event",
    noLocations: "No Locations added",
    setState: "Set State",
    fulfillmentTeam: "Fulfillment Team",
    setTeams: "Set Team",
    noDefinition: "The list is empty until you assign a team.",
    definitions: "State Definitions",
    noDefinitionF: "No Fulfiller assigned.",
    instructionsF: "Please select a fulfiller to be in charge of fulfillment",
    instructions: "Please select a team to be able to set fulfillers.",
    setFulfiller: "Set Fulfiller",
    close: "Close",
    fulfillmentStates: "Fulfillment States",
    changeDefinition:
      "Changing the definition will affect the states of this order. Are you sure you want to change it?",
  },
  es: {
    fulfillmentStates: "Estados de Cumplimiento",
    fulfillerNotAssigned: "Fulfiller no asignado",
    lastName: "Apellido",
    close: "Cerrar",
    files: "Archivos",

    noDefinitionF: "Ningún Fulfiller ha sido asignado.",
    setFulfiller: "Asignar Fulfiller",
    instructionsF: "Seleccione un fulfiller para que se encarge de la entrega",
    instructions: "Seleccione un equipo para poder establecer fulfillers.",
    setTeams: "Asignar Team",
    noDefinition: "La lista esta vacía hasta que asigne un team. ",
    fulfillmentTeam: "Equipo de Entrega",
    changeDefinition:
      "Cambiar la definición afectará los estados de esta orden. ¿Seguro que quieres cambiarlo?",
    setState: "Establecer Estado",
    tags: "Tags",
    noLocations: "No se han agregado Lugares",
    fulfillment: "Cumplimiento",

    noFulfillment: "La lista está vacía hasta que seleccione una definición",
    updateFulfiller: "Establecer cumplidor",
    fulfillers: "Cumplidores",
    notificationfulfiller: "Seleccione un Cumplidor para asignarlo a la línea",
    phone: "Teléfono",
    callTeam: "Llamar Equipo",
    callFulfiller: "Llamar Realizador",
    sendMessage: "Mandar Mensaje",
    link: "Link de la Definición",
    fulfiller: "Realizador",
    date: "Fecha/Hora",
    setTeam: "Seleccionar Equipo",
    selectState: "Selecciona un estado para activarlo",
    select: "Activar Estado",
    goBack: "Regresar",
    updateDiveShop: "Actualizar Team",
    notificationDiveShop:
      "Se enviará una notificación al Team seleccionado con la asignación del pedido.",
    diveShops: "Team",
    status: "Estados",
    timestamp: "Marca de tiempo",
    locationName: "Nombre de la ubicación",
    user: "Usuario",
    name: "Nombre",
    diveshop: "Team",
    setDiveshop: "Establecer Team",
    fulfilledTimestamp: "Marca de tiempo del Entrega",
    currentState: "Estado Actual",
    selectVenue: "Seleccionar lugar",
    search: "Buscar",
    selectLocation: "Seleccionar ubicación",
    cancel: "Cancelar",
    save: "Guardar",
    noLocation: "Este lugar no tiene ubicaciones.",
    setLocation: "Set Location",
    setExistingLocation: "Establecer ubicación",
    createNewLocation: "Crear nueva Ubicación",
    location: "Ubicación",
    venueName: "Nombre del lugar",
    locationType: "Tipo de Ubicación",
    w3w: "What Three Words",
    postalAddress: "Dirección Postal",
    wellKnownAddress: "Dirección conocida",
    notes: "Notas",
    addressLine1: "Línea de dirección 1",
    addressLine2: "Línea de dirección 2",
    city: "Ciudad",
    state: "Estado",
    zipCode: "Código Postal",
    country: "País",
    reference: "Referencia",
    action: "Acción",
    deleteMessage: "¿Está seguro de que desea eliminar?",
    setStatus: "Establecer Estado",
    fulfillmentStatus: "Estado del cumplimento",
    activating: "Está activando",
    on: "a",
    fromLocation: "de la ubicación:",
    geoLocationNotSupported:
      "La geolocalización no es compatible con su navegador",
    states: "Estados",
    fulfilled: "Cumplido",
    clickOnActivate: "Haga clic en un estado para activar el siguiente",
    setFulfillment: "Establecer Entrega",
    notAssigned: "No asignado",
    assign: "Asignar",
    noLocationAdded: "Ninguna ubicación agregada",
    disabled: "Deshabilitado",
    orderLine: "Línea de orden",
    fulfillmentDefinition: "Definición de entrega",
    setDefinition:
      "Seleccione una definición de entrega para esta línea de orden",
    updateDefinition: "Actualizar definición de entrega",
    noAssignedDefinition:
      "No se asignan definiciones de entrega para esta línea de pedido.",

    history: "Historia",
    locations: "Ubicaciones",
    noStateActivated:
      "La lista histórica de estados estará vacía hasta que comience a activar estados",
    noStateConfigure: "No se configuró ningún estado",
    empty: "Lista vacía",
    instructionsLocations:
      "Agregue ubicaciones existentes o cree una ubicación para su entrega.",
    locationsNotEnabled:
      "Las ubicaciones no están habilitadas para esta línea de orden ",
    ticketing: "Boletos",
    type: "Tipo",

  },
  de: {},
};

export default translation;
