import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import Components from "../components/i18n";
import Containers from "../containers/i18n";

i18n
  .use(LanguageDetector) // passes i18n down to react-i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          component: Components.en,
          container: Containers.en,
        },
      },
      es: {
        translation: {
          component: Components.es,
          container: Containers.es,
        },
      },
      de: {
        translation: {
          component: Components.de,
          container: Containers.de,
        },
      },
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
