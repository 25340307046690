const translation = {
  en: {
    close: "Close",
    refund: "Refund",
    confirm: "Confirm Refund",
    warning: "Are you sure you want to issue a refund of ",
  },
  es: {
    close: "Cerrar",
    refund: "Reembolso",
    confirm: "Confirmar reembolso",
    warning: "¿Estás seguro de que quieres emitir un reembolso?",
  },
  de: {},
};

export default translation;
