import { Checkbox } from "antd";
import React from "react";

import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { GridContainer } from "../../GridContainer/GridContainer";

interface InputCheckboxProps {
  options: { label: string; value: string }[];
  value: CheckboxValueType[];
  onChange: (checkedValues: CheckboxValueType[]) => void;
  gridTemplate?: string;
}

export const GroupWithValues: React.FC<InputCheckboxProps> = ({
  options,
  onChange,
  value,
  gridTemplate,
}) => {
  return (
    <Checkbox.Group value={value} onChange={onChange}>
      <GridContainer
        gap="1em"
        columns={gridTemplate ? gridTemplate : "1fr 1fr"}
      >
        {options.map((item) => (
          <Checkbox
            key={item.value}
            style={{ margin: 0, padding: 0 }}
            value={item.value}
          >
            {item.label}
          </Checkbox>
        ))}
      </GridContainer>
    </Checkbox.Group>
  );
};
