import React, { useState } from "react";
import styles from "./styles";

import { Placement } from "@innomius/ravent-typescript-types";

import Text from "../../Text";
import { useTranslation } from "react-i18next";
import MapSideMenu from "../PlacementList/MapSideMenu";
import { GridContainer } from "../../GridContainer/GridContainer";
import { FlexContainer } from "../../FlexContainer/FlexContainer";

interface PlacementCardProps {
  data: Placement;
  onClickEdit: () => void;
  lastIndex: boolean;
  edit?: boolean;
}

const PlacementCard: React.FC<PlacementCardProps> = ({
  data,
  onClickEdit,
  lastIndex,
  edit,
}) => {
  const { t } = useTranslation();
  const [map, setMap] = useState("");

  return (
    <div>
      <div className={styles.relative}>
        {data.id && map === data.id && (
          <div style={{ display: map === data.id ? "inline" : "none" }}>
            <MapSideMenu
              w3w={
                data.coordinates?.latitude
                  ? data.w3w || ""
                  : "restrict.firmly.move"
              }
              onClose={() => setMap("")}
              open={map === data.id}
              name={data.name || ""}
              line1={data.line1 || ""}
              markers={{
                lat: parseFloat(data.coordinates?.latitude || "0"),
                lng: parseFloat(data.coordinates?.longitude || "0"),
                title: data.name || "",
              }}
            />
          </div>
        )}
        <div className={styles.blueCircle} />
        <div
          className={styles.line}
          style={{ display: lastIndex ? "none" : "block" }}
        />
        <div className={styles.placementCard}>
          <GridContainer alignItems="center" columns="1fr 0.3fr">
            <div>
              <FlexContainer
                alignItems="flex-start"
                flexDirection="column"
                justifyContent="flex-start"
              >
                <Text
                  padding="0 0 0.2em 0"
                  color={"#333"}
                  text={
                    data.externalId
                      ? `${data.externalId} - ${data.name}`
                      : data.name || ""
                  }
                  fontWeight={500}
                  fontSize="1.1em"
                />
              </FlexContainer>
              {data.line1 ? (
                <Text
                  color={"#858D8F"}
                  text={data.line1 ? data.line1 : data.w3w ? data.w3w : ""}
                />
              ) : (
                <div className={styles.notEnrollContainer}>
                  {t("component.orderLocation.noAddress")}
                </div>
              )}
            </div>
            <GridContainer
              justifyContent="center"
              alignItems="center"
              columns="1fr 1px 0.8fr"
            >
              {edit && (
                <>
                  {!data.line1 ? (
                    <div />
                  ) : (
                    <FlexContainer>
                      <Text
                        color={"#505050"}
                        text={t("component.orderLocation.view")}
                        fontWeight={500}
                        fontSize="1.1em"
                        padding="0 0.5em 0 0.5em"
                        textAlign="center"
                        onClick={() => {
                          setMap(data.id);
                        }}
                      />
                    </FlexContainer>
                  )}
                  <div
                    style={{
                      background: "#dedede",
                      height: "100%",
                      width: "1px",
                    }}
                  />
                  <FlexContainer>
                    <Text
                      textAlign="center"
                      color={"#505050"}
                      text={t("component.orderLocation.edit")}
                      fontWeight={500}
                      fontSize="1.1em"
                      padding="0 0.5em 0 0.5em"
                      onClick={onClickEdit}
                    />
                  </FlexContainer>
                </>
              )}
            </GridContainer>
          </GridContainer>
        </div>
      </div>
    </div>
  );
};

export default PlacementCard;
