import React, { useState } from "react";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { useHistory } from "react-router-dom";
import { capitalize } from "../../utils/format";
import deleteIcon from "../../assets/deleteBlack.svg";

import FulfillmentStateDetail from "../FulfillmentStateDetail";
import {
  Action,
  FulfillmentDefinition,
  FulfillmentStateDef,
} from "@innomius/ravent-typescript-types";
import { typeIconMap } from "../../utils/fulfillments";
import styles from "./styles";
import { Popover, Tooltip } from "antd";
import DeletePopUp from "../DeletePopUp";
import { useTranslation } from "react-i18next";
import { ListLabel } from "../List/ListLabel";
import { ListRow } from "../List/ListRow";

interface FulfillmentProps {
  onEditFulfillmentDefinition: (data: FulfillmentDefinition) => void;
  fulfillmentId: string;
  fulfillmentList: FulfillmentDefinition;
  path: string;
  onAddState: (
    fulfillment: FulfillmentDefinition,
    data: FulfillmentStateDef,
    edit: boolean,
    index?: number
  ) => void;
  onDeleteFulfillmentState: (id: string) => void;
  load: () => void;
  open: boolean;
  setOpen: (value: boolean) => void;
  actions: Action[];
  onDelete: (id: string) => void;
}

const FulfillmentItem: React.FC<FulfillmentProps> = ({
  onEditFulfillmentDefinition,
  path,
  onAddState,
  onDeleteFulfillmentState,
  load,
  fulfillmentId,
  fulfillmentList,
  open,
  setOpen,
  actions,
  onDelete,
}) => {
  const history = useHistory();
  const [deleteUser, setDelete] = useState("");
  const { t } = useTranslation();

  return (
    <>
      <FulfillmentStateDetail
        load={load}
        actions={actions}
        id={fulfillmentId}
        onAddState={(fulfillment, data, edit, index) =>
          onAddState(fulfillment, data, edit, index)
        }
        openModal={open}
        onDeleteFulfillmentState={() => onDeleteFulfillmentState(fulfillmentId)}
        onEditFulfillmentDefinition={(data) =>
          onEditFulfillmentDefinition(data)
        }
        onClose={() => {
          history.push(`${path}`);
          setOpen(false);
        }}
      />
      <ListRow
        onClick={() => {
          setOpen(true);
          history.push(`${path}/${fulfillmentId}`);
        }}
        template="1fr 1fr 1fr 0.5fr"
        hover={true}
      >
        <div className={styles.normal}>
          <img
            className={styles.iconType}
            src={typeIconMap[fulfillmentList.type]}
            alt=""
          />
          {fulfillmentList.name}
        </div>
        <ListLabel value={capitalize(fulfillmentList.type)} textAlign="left" />
        <ListLabel
          value={fulfillmentList.fulfillmentStatesDefinitions.length.toString()}
          textAlign="left"
        />
        <div className={styles.space}>
          <EditIcon
            className={styles.icon}
            onClick={() => {
              setOpen(true);

              history.push(`/fulfillments/${fulfillmentId}`);
            }}
          />
          <img
            src={deleteIcon}
            alt="userDelete"
            className={styles.icon}
            onClick={(e) => {
              e.stopPropagation();
              setDelete("delete");
            }}
          />
          <Tooltip title={t("component.assetList.delete")}>
            <Popover
              placement="right"
              content={
                <div onClick={() => setDelete("")}>
                  <DeletePopUp
                    value={`Are you sure you want to delete ${fulfillmentList.name}?`}
                    onClose={(e) => {
                      e.stopPropagation();
                      setDelete("");
                    }}
                    onDelete={(e) => {
                      e.stopPropagation();
                      onDelete(fulfillmentList.id);
                    }}
                  />
                </div>
              }
              trigger="click"
              open={deleteUser === "delete"}
              onOpenChange={() => setDelete(deleteUser)}
            />
          </Tooltip>
        </div>
      </ListRow>
    </>
  );
};

export default FulfillmentItem;
