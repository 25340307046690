import React, { useState } from "react";
import { Card } from "../Card/Card";
import styles from "./styles";
import { FlexContainer } from "../FlexContainer/FlexContainer";

import { useTranslation } from "react-i18next";
import VenueModal from "../UserForm/VenueModal";
import { CreateUserParams } from "../../services/users";
import { Team, Venue } from "@innomius/ravent-typescript-types";
import chevron from "../../assets/chevron-down.svg";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import RoleModal from "../UserForm/RoleModal";
import { Tooltip, Tag } from "antd";
import { Role } from "../../services/roles";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";
import { SectionProperty } from "../Section/SectionProperty";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
import { Button } from "../Button";
import { ButtonsContainer } from "../ButtonsContainer";
import { StatusBadgePoint } from "../StatusBadgePoint/StatusBagePoint";
import Text from "../Text";
import info from "../../assets/info.svg";
import fontColorContrast from "font-color-contrast";

interface Props {
  venue?: string;
  roles: Role[];
  data: CreateUserParams;
  onChange: <P extends keyof CreateUserParams>(
    prop: P,
    value: CreateUserParams[P]
  ) => void;
  venues: Venue[];
  onAssign: (id: string) => void;
  loading: string;
  onChangeRoles: (newRoles: string[]) => void;
  setModal: (value: boolean) => void;
  openModal: boolean;
  load: () => void;
  team: string;
  teamId: string;
  teams: Team[];
  onAssignTeam: (teamId: string) => void;
  permission: boolean;
}

const UserDetailComponent: React.FC<Props> = ({
  data,
  venues,
  onAssign,
  loading,
  venue,
  onChange,
  onChangeRoles,
  openModal,
  setModal,
  load,
  team,
  roles,
}) => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState("");

  const formatOptionKey = (key: string, color: string) => {
    if (key && key.includes("::")) {
      const [firstPart, secondPart] = key.split("::");
      return (
        <Tooltip title={firstPart}>
          <Tag
            style={{
              color: color
                ? color === "blue"
                  ? "blue"
                  : fontColorContrast(color, 0.5)
                : "blue",
            }}
            color={color || "blue"}
          >
            {secondPart}
          </Tag>
        </Tooltip>
      );
    } else {
      return <Tag color={color || "blue"}>{key}</Tag>;
    }
  };

  return (
    <>
      <RoleModal
        onChange={onChange}
        data={data}
        loading={loading === "roles"}
        open={openModal}
        onCancel={() => {
          setModal(false);
          load();
        }}
        roles={roles}
        onAssignRoles={onChangeRoles}
      />
      <div className={styles.container}>
        <Card>
          <div className={styles.wrapperCard}>
            <FlexContainer justifyContent="space-between">
              <ContainerTitle
                size="small"
                padding="0 0 1em 0"
                label={t("component.userDetailComponent.userInfo")}
              />
              <ButtonsContainer justifyContent="flex-end">
                <FlexContainer alignItems="center" justifyContent="flex-start">
                  <FlexContainer
                    padding="0 1em 0 1em"
                    flexDirection="column"
                    justifyContent="flex-start"
                  >
                    <StatusBadgePoint
                      point
                      status={data.loginWithPhone ? "ENABLED" : "DISABLED"}
                    />
                  </FlexContainer>
                  <InputLabel
                    label={t("component.userDetailComponent.loginWithPhone")}
                  />
                </FlexContainer>

                <FlexContainer alignItems="center" justifyContent="flex-start">
                  <div style={{ marginRight: "1em" }}>
                    <StatusBadgePoint
                      point
                      status={data.adminRole ? "ENABLED" : "DISABLED"}
                    />
                  </div>
                  <FlexContainer justifyContent="flex-start">
                    <InputLabel
                      label={`${t("component.userForm.adminRole")} (${t(
                        "component.userForm.superUser"
                      )})`}
                    />
                    <Tooltip title={t("component.userForm.superUserInfo")}>
                      <img style={{ marginLeft: "0.2em" }} src={info} alt="" />
                    </Tooltip>
                  </FlexContainer>
                </FlexContainer>

                <FlexContainer alignItems="center" justifyContent="flex-start">
                  <FlexContainer
                    padding="0 1em 0 1em"
                    flexDirection="column"
                    justifyContent="flex-start"
                  >
                    <StatusBadgePoint
                      point
                      status={
                        data.disabled
                          ? "DISABLED"
                          : !data.emailVerified
                          ? "un-paid"
                          : "active"
                      }
                    />
                  </FlexContainer>
                  <InputLabel
                    label={
                      data.disabled
                        ? t("component.userDetailComponent.disabled")
                        : !data.emailVerified
                        ? t("component.userDetailComponent.notEnrolled")
                        : t("component.userDetailComponent.enabled")
                    }
                  />
                </FlexContainer>
              </ButtonsContainer>
            </FlexContainer>
            <GridContainer gap="1em" columns="1fr 1fr">
              <SectionProperty
                label={t("component.userDetailComponent.externalId")}
                value={data.externalId || "-"}
              />
            </GridContainer>

            <GridContainer padding="0em 0 0 0" gap="1em" columns="1fr 1fr">
              <SectionProperty
                label={t("component.userDetailComponent.phone")}
                value={data.phone}
              />
              <SectionProperty
                label={t("component.userDetailComponent.email")}
                value={data.email}
              />
            </GridContainer>

            <InputLabel label={t("component.userDetailComponent.tags")} />

            <div className={styles.center}>
              {data.colorizedTags && data.colorizedTags.length >= 1 ? (
                data.colorizedTags?.map(
                  (
                    item: {
                      key: string;
                      settings: {
                        tagClassification: string;
                        color: string;
                      };
                    },
                    index
                  ) => (
                    <div style={{ margin: "0 0.2em 0.2em 0" }} key={index}>
                      {formatOptionKey(
                        item.key,
                        item.settings && item.settings.color
                          ? item.settings.color
                          : "blue"
                      )}
                    </div>
                  )
                )
              ) : data.tags ? (
                data.tags?.map((item) => (
                  <div style={{ margin: "0 0.2em 0.2em 0" }} key={item}>
                    {formatOptionKey(item, "blue")}
                  </div>
                ))
              ) : (
                <div>{t("component.userDetailComponent.noTags")}</div>
              )}
            </div>

            <FlexContainer justifyContent="flex-start">
              <ContainerTitle
                size="small"
                padding="1em 0.5em 1em 0"
                label={t("component.userDetailComponent.roles")}
              />
              <EditIcon
                onClick={() => setModal(true)}
                style={{ width: "1.5em", cursor: "pointer" }}
              />
            </FlexContainer>
            <ButtonsContainer padding="0 0 0 0" justifyContent="flex-start">
              {data.roles.map((item, index) => {
                const role = roles.find((role) => role.id === item);
                return role ? <Tag key={index}>{role.name}</Tag> : null;
              })}
            </ButtonsContainer>

            <GridContainer gap="1em" columns="1fr 1fr">
              <div>
                <VenueModal
                  open={edit === "venue"}
                  onAssign={async (id) => {
                    try {
                      await onAssign(id);
                      setEdit("");
                      load();
                    } catch (err) {}
                  }}
                  venues={venues}
                  loading={loading === "assign"}
                  data={data}
                  onChange={onChange}
                  onCancel={() => {
                    setEdit("");
                    load();
                  }}
                />
                <ContainerTitle
                  size="small"
                  padding="2em 0 1em 0"
                  label={t("component.userDetailComponent.assignedVenue")}
                />

                <Button
                  theme="white"
                  label={venue || "-"}
                  onClick={() => setEdit("venue")}
                >
                  <div className={styles.iconContainer}>
                    <img alt="" src={chevron} />
                  </div>
                </Button>
              </div>
              <div>
                {/* <TeamModal
                  open={edit === "team"}
                  onAssign={async (id) => {
                    try {
                      await onAssignTeam(id);
                      setEdit("");
                      load();
                    } catch (err) {}
                  }}
                  teams={teams}
                  loading={loading === "assign-team"}
                  teamId={teamId}
                  onCancel={() => {
                    setEdit("");
                    load();
                  }}
                /> */}
                <ContainerTitle
                  size="small"
                  padding="2em 0 1em 0"
                  label={t("component.userDetailComponent.fulfillmentTeam")}
                />

                <Text text={team || "-"} />
              </div>
            </GridContainer>
          </div>
        </Card>
      </div>
    </>
  );
};

export default UserDetailComponent;
