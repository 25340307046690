import React, { useState } from "react";

import { FOP, RequestPaymentRequest } from "../../utils/types";
import styles from "./styles";
import { checkRequest, defaultRequestPayment } from "../../services/payments";
import CustomError from "../../utils/CustomError";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { supplierOptions } from "../../utils/payment";
import { message } from "antd";
import dayjs from "dayjs";
import { InputTime } from "../Inputs/InputTime/InputTime";
import InputDate from "../Inputs/Date";
import { SideMenu } from "../SideMenu/SideMenu";
import Text from "../Text";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { Button } from "../Button";
import { InputText } from "../Inputs/InputText";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputSelect } from "../Inputs/InputSelect";
import { InputCheckbox } from "../Inputs/InputCheckbox";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
import InputRadio from "../Inputs/InputRadio";

interface Props {
  onCancel: () => void;
  onRequest: (
    data: RequestPaymentRequest,
    amount: string,
    fopId: string,
    send: boolean,
    email: string,
    lang: string
  ) => void;
  open: boolean;
  shortId: string;
  loading: boolean;
  dueAmount: number;
  fops: FOP[];
  paymentDueDate: Date;
  customerEmail: string;
  currency: string;
}

const RequestPaymentForm: React.FC<Props> = ({
  onCancel,
  onRequest,
  loading,
  dueAmount,
  fops,
  paymentDueDate,
  customerEmail,
  currency,
}) => {
  const defaultDate = new Date(
    new Date(paymentDueDate).getTime() + 0 * 60 * 1000
  );
  const [requestPayment, setPayment] = useState<RequestPaymentRequest>(
    defaultRequestPayment(defaultDate)
  );
  const [amount, setAmount] = useState(dueAmount.toString());
  const [error, setError] = useState<CustomError>();
  const [supplier, setSupplier] = useState("");
  const [send, setSend] = useState(false);
  const [customer, setCustomer] = useState(customerEmail);
  const [lang, setLang] = useState(localStorage.getItem("language") || "en");
  const { t } = useTranslation();

  const onChange = <P extends keyof RequestPaymentRequest>(
    prop: P,
    value: RequestPaymentRequest[P]
  ) => {
    setPayment({ ...requestPayment, [prop]: value });
  };

  const typesOptions = fops
    .filter(
      (item) =>
        item.type === "wire-transfer" ||
        item.type === "payment-link" ||
        item.type === "in-store"
    )
    .map((item) => ({
      label: item.type,
      value: item.type,
    }))
    .filter(
      (tag, index, array) =>
        array.findIndex((t) => t.label === tag.label) === index
    );

  const disableTime = (current: any) => {
    if (
      new Date(requestPayment.expiredDate).getDate() <
      new Date(paymentDueDate).getDate()
    ) {
      return false;
    }
    return current.valueOf() > dayjs(defaultDate);
  };

  const disabledDate = (current: any) => {
    return current.valueOf() > dayjs(defaultDate);
  };

  return (
    <SideMenu
      width="50%"
      open
      onClose={() => {
        setPayment(defaultRequestPayment(defaultDate));
        onCancel();
      }}
    >
      <div className={styles.container}>
        <FlexContainer padding="1em 0 2em 0" justifyContent="space-between">
          <ContainerTitle
            label={t("component.requestPaymentForm.requestPayment")}
            size="medium"
          />
          <FlexContainer justifyContent="flex-end">
            <Button
              onClick={() => {
                setPayment(defaultRequestPayment(defaultDate));
                onCancel();
                setSend(false);
              }}
              containerClassname={styles.button}
              theme="white"
              label={t("component.requestPaymentForm.cancel")}
            />
            <Button
              onClick={async () => {
                if (loading) {
                  return;
                }
                if (parseFloat(amount) - dueAmount >= 1) {
                  message.warning(t("component.registerPaymentForm.overPaid"));
                  return;
                }
                if (
                  fops.find((item) => item.id === supplier)?.round === "down" &&
                  parseFloat(amount) % 1 !== 0
                ) {
                  message.warning(
                    t("component.requestPaymentForm.downWarning")
                  );
                  return;
                }
                try {
                  setError(undefined);
                  checkRequest(requestPayment, amount, dueAmount, supplier);
                  await onRequest(
                    requestPayment,
                    amount,
                    supplier,
                    send,
                    customer,
                    lang
                  );
                  // setPayment(defaultRequestPayment(defaultDate));
                  setError(undefined);
                } catch (err) {
                  if (err instanceof CustomError) {
                    message.error(err.message);
                  }
                }
              }}
              theme="blue"
              label={
                loading ? "..." : `${t("component.requestPaymentForm.request")}`
              }
            />
          </FlexContainer>
        </FlexContainer>

        <GridContainer
          gap="1em"
          alignItems="center"
          justifyContent="flex-start"
          columns="1fr 0.5fr 0.4fr"
        >
          <InputSelect
            label={t("component.requestPaymentForm.paymentType")}
            value={requestPayment.type}
            options={typesOptions}
            onChange={(value) => {
              setSupplier(supplierOptions(value, fops)[0].value);
              onChange("type", value);
            }}
          />
          <InputText
            label={t("component.requestPaymentForm.amount")}
            value={amount}
            onChange={(value) => setAmount(value)}
            error={
              (!amount && get(error, "errors.request-amount", "")) ||
              get(error, "errors.paid-request-amount", "")
            }
          />
          <Text text={currency} />
        </GridContainer>

        <GridContainer gap="1em" columns="1.5fr 1fr">
          <InputSelect
            error={
              !requestPayment.fopId && get(error, "errors.supplierName", "")
            }
            label={t("component.requestPaymentForm.supplierName")}
            value={supplier}
            options={supplierOptions(requestPayment.type, fops)}
            onChange={(value) => {
              setSupplier(value);
              onChange("fopId", value);
            }}
          />
          {requestPayment.type === "wire-transfer" ? (
            <InputText
              label={t("component.requestPaymentForm.bankAccount")}
              disabled
              value={
                fops.find((item) => item.id === supplier)?.data.account
                  .bankAccount
              }
            />
          ) : null}
        </GridContainer>

        <GridContainer padding="0 0 2em 0" gap="1em" columns="1fr 1fr">
          <InputDate
            disableDate={disabledDate}
            id="expiredDate"
            value={requestPayment.expiredDate as Date}
            defaultValue={defaultDate}
            label={t("component.requestPaymentForm.expDate")}
            onChange={(value) => onChange("expiredDate", value)}
          />
          <InputTime
            id="time"
            value={requestPayment.expiredDate as Date}
            disableDate={disableTime}
            defaultValue={
              new Date(
                new Date(requestPayment.expiredDate).getTime() + 0 * 60 * 1000
              )
            }
            onChange={(value) => onChange("expiredDate", value.value)}
          />
        </GridContainer>
        <FlexContainer
          padding="0 0 1em 0"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <InputCheckbox
            value={send}
            text={t("component.requestPaymentForm.sendViaEmail")}
            onChange={(value) => setSend(value)}
          />
        </FlexContainer>
        {send ? (
          <>
            {" "}
            <InputText
              value={customer}
              label="Email"
              onChange={(value) => setCustomer(value)}
            />
            <div>
              <InputLabel label={t("component.sendModal.language")} />
              <FlexContainer justifyContent="flex-start">
                <div className={styles.radioContainer}>
                  <InputRadio
                    name="language"
                    checked={lang === "en"}
                    value="en"
                    text={t("component.sendModal.en")}
                    onChange={() => setLang("en")}
                  />
                </div>
                <div className={styles.radioContainer}>
                  <InputRadio
                    name="language"
                    value="es-mx"
                    checked={lang === "es-mx"}
                    text={t("component.sendModal.es")}
                    onChange={() => setLang("es-mx")}
                  />
                </div>
              </FlexContainer>
            </div>
          </>
        ) : null}
      </div>
    </SideMenu>
  );
};

export default RequestPaymentForm;
