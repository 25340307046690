import React from "react";

import styles from "./styles";
import { Location } from "@innomius/ravent-typescript-types";
import { InputTags } from "../Inputs/InputTags";
import { useTranslation } from "react-i18next";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputText } from "../Inputs/InputText";
import { InputTextarea } from "../Inputs/InputTextArea";

interface Props {
  data: Location;
  onChange: <P extends keyof Location>(prop: P, value: Location[P]) => void;
  edit?: boolean;
  onEdit?: () => void;
  onCancel?: () => void;
  error: string;
}

const LocationDetailsForm: React.FC<Props> = ({ data, onChange, edit }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className={styles.containerDetails}>
        <FlexContainer
          padding={edit ? "0 0 2em 0" : "0 0 1em 0"}
          alignItems="center"
          justifyContent="space-between"
        >
          <ContainerTitle size="xsmall" label="Location Information" />
        </FlexContainer>

        <GridContainer gap="1em" columns="1fr 1fr 1fr">
          <InputText
            value={data.capacity}
            label="Location Capacity"
            onChange={(value) => onChange("capacity", value)}
          />
          <InputText
            value={data.pointOfContact}
            label="Point of Contact"
            onChange={(value) => onChange("pointOfContact", value)}
          />
        </GridContainer>
        <GridContainer gap="2em" columns="1fr 1fr 1fr">
          <InputText
            value={data.email}
            label="Email"
            onChange={(value) => onChange("email", value)}
          />
          <InputText
            value={data.phone}
            label="Phone"
            onChange={(value) => onChange("phone", value)}
          />
        </GridContainer>

        <GridContainer gap="2em" columns="3fr 1fr" justifyContent="flex-start">
          <InputTextarea
            label="Description"
            value={data.description}
            onChange={(value) => onChange("description", value)}
          />
        </GridContainer>
        <InputTags
          label={t("component.assetForm.tags")}
          value={data.tags}
          onChange={(value) => onChange("tags", value)}
        />
      </div>
    </div>
  );
};

export default LocationDetailsForm;
