import React from "react";
import { ScheduleLog } from "@innomius/ravent-typescript-types";
import { StatusBadgePoint } from "../StatusBadgePoint/StatusBagePoint";
import { ReactComponent as LinkIcon } from "../../assets/link.svg";

import { formatDate } from "../../utils/times";
import styles from "./styles";
import { StatusCatalog } from "../StatusBadge";
import { useHistory } from "react-router-dom";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";

interface Props {
  data: ScheduleLog;
  action?: boolean;
}

const ScheduleLogItem: React.FC<Props> = ({ data, action }) => {
  const history = useHistory();
  return (
    <div>
      <ListRow
        template={!action ? "1.5fr 3fr 1fr 1fr" : "1.5fr 3fr 1fr"}
        hover={true}
      >
        <ListLabel
          fontFormat="bold"
          value={`${formatDate(data.timestamp, "MIDDLEWHOUR")}`}
          textAlign="left"
        />
        <ListLabel value={data.url} textAlign="left" />

        {!action ? (
          <div
            className={styles.center}
            onClick={() =>
              history.push(
                `/integrations/schedules/${data.scheduleNotificationId}/logs`
              )
            }
          >
            <LinkIcon style={{ width: "1.5em" }} />
            {data.scheduleNotificationId}
          </div>
        ) : null}

        <div className={styles.center}>
          <StatusBadgePoint
            status={data.statusCode.toString() as StatusCatalog}
          />
        </div>
      </ListRow>
    </div>
  );
};

export default ScheduleLogItem;
