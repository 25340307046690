import * as React from "react";
import { classes } from "typestyle";
import { ReactComponent as Show } from "./show.svg";
import { ReactComponent as Hide } from "./hide.svg";
import styles from "./styles.module.css";
import { InputLabel } from "../InputLabel/InputLabel";
import { InputError } from "../InputError/InputError";

export interface InputPasswordProps {
  id?: string;
  value?: string | number | string[] | undefined;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  containerClassname?: string;
  inputClassname?: string;
  buttonIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  showIcon?: boolean;
  error?: false | '' | string[] | string;
  showError?: boolean;
  showLabel?: boolean;
  onChange?: (value: string, id?: string) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onButtonClick?: () => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const InputPassword: React.FC<InputPasswordProps> = (props) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const {
    id,
    value,
    label,
    onChange,
    placeholder,
    disabled,
    readOnly,
    containerClassname,
    inputClassname,
    onFocus,
    onBlur,
    onClick,
    error,
    showError,
    showIcon,
    showLabel,
    onKeyUp,
  } = props;

  const [isFocus, setFocus] = React.useState<boolean>(false);
  const change = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value, id);
    }
  };

  return (
    <div className={classes(styles.container, containerClassname)}>
      <InputLabel showLabel={showLabel} label={label} id={id} />
      <div
        className={classes(styles.wrapper, {
          [styles.wrapperError]: error,
        })}
      >
        <input
          id={id}
          className={classes(styles.input, inputClassname)}
          value={value}
          type={showPassword ? "text" : "password"}
          onChange={change}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          onFocus={(e) => {
            setFocus(true);
            onFocus?.(e);
          }}
          onBlur={(e) => {
            setFocus(false);
            onBlur?.(e);
          }}
          onClick={onClick}
          autoComplete="off"
          onKeyUp={onKeyUp}
          style={{
            borderColor: error
              ? "red"
              : isFocus
              ? "rgba(5, 178, 245, 0.2)"
              : "",
            boxShadow: isFocus ? "0px 0px 1px 1px rgba(5, 178, 245, 0.2)" : "",
          }}
        />

        {showIcon ? (
          <div
            className={styles.iconContainer}
            onClick={() => setShowPassword(!showPassword)}
            style={{ cursor: "pointer" }}
          >
            {showPassword ? (
              <Hide className={styles.icon} />
            ) : (
              <Show className={styles.icon} />
            )}
          </div>
        ) : null}
      </div>
      <InputError error={error} showError={showError} />
    </div>
  );
};
