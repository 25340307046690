import React from "react";

import { Offer } from "@innomius/ravent-typescript-types";
import styles from "./styles";
import TicketingForm from "./TicketingForm";
import { useTranslation } from "react-i18next";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { InputSwitch } from "../Inputs/InputSwitch";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";

interface Props {
  data: Offer;
  onChange: <P extends keyof Offer>(prop: P, value: Offer[P]) => void;
  edit?: boolean;
  onEdit?: () => void;
  onCancel?: () => void;
}

const OfferTicketingForm: React.FC<Props> = ({
  data,
  onChange,
  edit,
  onEdit,
  onCancel,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.containerDetails}>
        <FlexContainer
          padding={edit ? "0 0 2em 0" : "0 0 1em 0"}
          alignItems="center"
          justifyContent="space-between"
        >
          <ContainerTitle
            size="xsmall"
            label={t("component.offerVariantForm.ticketing")}
          />
          <FlexContainer justifyContent="flex-start">
            <p>{t("component.offerVariantForm.enableTickets")}</p>
            <InputSwitch
              onChange={(value) => onChange("ticketingEnabled", value)}
              value={data.ticketingEnabled}
            />
          </FlexContainer>
        </FlexContainer>
        <TicketingForm
          data={data.ticketingFulfillment}
          onChange={(value) => onChange("ticketingFulfillment", value)}
        />

        {edit ? (
          <ButtonsContainer padding="2em 0 0 0" justifyContent="flex-start">
            <Button
              label={t("component.offerVariantForm.cancel")}
              theme="white"
              onClick={() => onCancel?.()}
            />
            <Button
              label={t("component.offerVariantForm.save")}
              onClick={() => onEdit?.()}
            />
          </ButtonsContainer>
        ) : null}
      </div>
    </div>
  );
};

export default OfferTicketingForm;
