import React from "react";
import { useTranslation } from "react-i18next";
import { InputPassword } from "../Inputs/InputPassword";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";

interface Props {
  current: string;
  newPassword: string;
  confirmedPassword: string;
  setCurrent: (current: string) => void;
  setNew: (current: string) => void;
  setConfirm: (current: string) => void;
}

const ChangePassword: React.FC<Props> = ({
  current,
  setCurrent,
  newPassword,
  setNew,
  confirmedPassword,
  setConfirm,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <ContainerTitle
        padding="0 0 1em 0"
        size="xsmall"
        label={t("component.changePassword.password")}
      />
      <GridContainer gap="1em" columns="1fr 1fr 1fr">
        <InputPassword
          showIcon
          label={t("component.changePassword.confirmCurrent")}
          value={current}
          onChange={(value) => setCurrent(value)}
        />
        <div />
        <div />

        <InputPassword
          showIcon
          label={t("component.changePassword.newPassword")}
          value={newPassword}
          onChange={(value) => setNew(value)}
        />
        <div />
        <div />

        <InputPassword
          showIcon
          label={t("component.changePassword.confirmNew")}
          value={confirmedPassword}
          onChange={(value) => setConfirm(value)}
        />
      </GridContainer>
    </div>
  );
};

export default ChangePassword;
