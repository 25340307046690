const translation = {
  en: {
    cancel: "Cancel",
    noCertificates: "You don't have passes yet.",
    definition: "Definition",
    certificate: "Pass",
    issueCertificate: "Issue Pass",
    setDefinition: "Select a Certificate Definition for this Order",
    goBack: "Go Back",
    tickets: "Tickets",
    save: "Save",
    issue: "Issue",
    atIssuance: "The pass is going to be active from now on until",
    type: "Activation Type: ",
    share: "Share Pass",
    shareTicket: "Share Pass",
    setDef: "Set Definition",
    maxEvents: "Max Activations",
    noTickets: "You don't have tickets yet.",
    instructions: "Please select a definition to be able to issue tickets.",
    noDefinitions:
      "No definitions are configured for this venue. Please create a certificate definition.",
    warning: "Pass is going to be issued with the following caracteristics:",
  },
  es: {
    cancel: "Cancelar",
    tickets: "Boletos",
    noTickets: "Todavía no tiene boletos.",
    noCertificates: "Aún no tiene constancias.",
    definition: "Definición",
    certificate: "Constancia",
    issueCertificate: "Emitir constancia",
    setDefinition: "Seleccione una definición de certificado para esta orden",
    goBack: "Volver",
    save: "Guardar",
    issue: "Emitir",
    atIssuance: "La constancia va a estar activa a partir de ahora hasta",
    type: "Tipo de activación:",
    compartir: "Compartir Constancia",
    share: "Compartir constancia",
    setDef: "Definición de entrega",
    maxEvents: "Activaciones máximas",
    instructions: "Seleccione una definición para poder emitir constancias.",
    noDefinitions:
      "No hay definiciones configuradas para este lugar. Cree definiciones de certificados.",
    warning: "La constancia se emitirá con las siguientes características:",
  },
  de: {},
};

export default translation;
