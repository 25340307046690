import fetch from "cross-fetch";
import decode from "jwt-decode";
import get from "lodash/get";
import { store } from "../App";
import { authUser, cleanExpired, signOutUser } from "../store/auth";
import CustomError from "../utils/CustomError";
import { AccessTokenParsed, RefreshTokenParsed } from "../utils/types";
import secureLocalStorage from "react-secure-storage";

export interface LoginResponse {
  accessToken: string;
  expiresIn: number;
  refreshExpiresIn: number;
  refreshToken: string;
  scope: string;
  sessionState: string;
  tokenType: string;
  accessTokenParsed: AccessTokenParsed;
  refreshTokenParsed: RefreshTokenParsed;
}

export interface RefreshTokenResponse {
  refreshExpiresIn: number;
  refreshToken: string;

  refreshTokenParsed: RefreshTokenParsed;
}

export async function login(
  username: string,
  password: string
): Promise<LoginResponse> {
  const res = await fetch("/auth/realms/ravent/protocol/openid-connect/token", {
    method: "post",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: new URLSearchParams({
      grant_type: "password",
      client_id: "administrator-app",
      username,
      password,
    }).toString(),
  });

  if (res.status !== 200) {
    const json = await res.json();
    throw new Error(json.error_description || "Unexpected error");
  }

  const json = await res.json();

  const data = {
    accessToken: json.access_token,
    expiresIn: json.expires_in,
    idToken: json.id_token,
    refreshExpiresIn: json.refresh_expires_in,
    refreshToken: json.refresh_token,
    scope: json.scope,
    sessionState: json.session_state,
    tokenType: json.token_type,
    accessTokenParsed: (await decode(json.access_token)) as AccessTokenParsed,
    refreshTokenParsed: (await decode(
      json.refresh_token
    )) as RefreshTokenParsed,
  };

  try {
    await secureLocalStorage.setItem("token", JSON.stringify(data));
  } catch (err) {
    console.log(err);
  }

  return data;
}

export async function refresh(token: string): Promise<LoginResponse> {
  try {
    const res = await fetch(
      "/auth/realms/ravent/protocol/openid-connect/token",
      {
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          grant_type: "refresh_token",
          client_id: "administrator-app",
          refresh_token: token,
        }).toString(),
      }
    );
    if (res.status !== 200) {
      const json = await res.json();
      console.error("ERROR respuesta no 200");
      throw new Error(
        json.message || json.error || "An unexpected error ocurred"
      );
    }

    const json = await res.json();

    const data = {
      accessToken: json.access_token,
      expiresIn: json.expires_in,
      idToken: json.id_token,
      refreshExpiresIn: json.refresh_expires_in,
      refreshToken: json.refresh_token,
      scope: json.scope,
      sessionState: json.session_state,
      tokenType: json.token_type,
      accessTokenParsed: decode(json.access_token) as AccessTokenParsed,
      refreshTokenParsed: decode(json.refresh_token) as RefreshTokenParsed,
    };

    return data;
  } catch (err) {
    throw err;
  }
}

let refreshPromise: null | Promise<LoginResponse> = null;

export async function getAuthData(): Promise<LoginResponse> {
  try {
    const data = await secureLocalStorage.getItem("token");

    if (!data) {
      throw new Error("no auth data");
    }

    const parsed = JSON.parse(data as string) as LoginResponse;

    const expVal =
      parsed.accessTokenParsed.exp < parsed.refreshTokenParsed.exp
        ? parsed.accessTokenParsed.exp
        : parsed.refreshTokenParsed.exp;

    const exp = new Date(expVal * 1000);
    // const accessExp = new Date(parsed.accessTokenParsed.exp * 1000);
    // const refreshExp = new Date(parsed.refreshTokenParsed.exp * 1000);
    const now = new Date();
    const expired = exp.getTime() < now.getTime();

    // console.debug({
    //   expired,
    //   accessTokenExp: (accessExp.getTime() - now.getTime()) / 1000,
    //   refreshTokenExp: (refreshExp.getTime() - now.getTime()) / 1000,
    //   accessTokenDuration: parsed.expiresIn,
    //   refreshTokenDuration: parsed.refreshExpiresIn,
    // });

    if (expired) {
      if (!refreshPromise) {
        console.debug("refreshing");
        refreshPromise = refresh(parsed.refreshToken);
      }

      try {
        const res = await refreshPromise;
        refreshPromise = null;

        store.dispatch(authUser(res));

        try {
          await secureLocalStorage.setItem("token", JSON.stringify(res));
        } catch (err) {
          console.warn(err);
        }

        return res;
      } catch (err) {
        console.warn(err);
        throw new Error("expired");
      }
    }

    if (!store.getState().auth.user) {
      console.debug("rehydrating auth from localstorage");
      store.dispatch(authUser(parsed));
    }

    return parsed;
  } catch (err) {
    await logout("", true);
    throw err;
  }
}

export async function getAccessToken() {
  const data = await getAuthData();
  return data.accessToken;
}

export function parseAccessToken(token: string): AccessTokenParsed {
  const data = decode(token);

  return {
    exp: get(data, "exp", 0),
    iat: get(data, "iat", 0),
    jti: get(data, "jti", ""),
    iss: get(data, "iss", ""),
    aud: get(data, "aud", ""),
    sub: get(data, "sub", ""),
    typ: get(data, "typ", ""),
    azp: get(data, "azp", ""),
    sessionState: get(data, "session_state", ""),
    acr: get(data, "acr", ""),
    allowedOrigins: get(data, "allowed-origins", []),
    scope: get(data, "scope", ""),
    emailVerified: get(data, "email_verified", false),
    organization_id: get(data, "organization_id", ""),
    email: get(data, "email", ""),
    preferred_username: get(data, "preferred_username", ""),
  };
}

export async function logout(token: string, expired?: boolean) {
  if (expired) {
    store.dispatch(cleanExpired());
  } else {
    store.dispatch(signOutUser());
    await fetch("/auth/realms/ravent/protocol/openid-connect/logout", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        client_id: "administrator-app",
        refresh_token: token,
      }).toString(),
    });
  }

  secureLocalStorage.removeItem("venueName");
  secureLocalStorage.removeItem("venueId");
  localStorage.removeItem("venueId");
  secureLocalStorage.removeItem("orgName");
  secureLocalStorage.removeItem("userName");
  await secureLocalStorage.removeItem("initialRefresh");
  await secureLocalStorage.removeItem("token");
}

export async function getEnrollIdToken(token: string): Promise<LoginResponse> {
  const res = await fetch(
    process.env.NODE_ENV === "development"
      ? "https://api.dev.ravent.com/otts/user/auth"
      : "https://api.ravent.com/otts/user/auth",
    {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        charset: "utf-8",
      },
      body: new URLSearchParams({
        token: token,
        usage: "ENROLL_USER",
      }).toString(),
    }
  );

  if (res.status === 200 || res.status === 201) {
    const json = await res.json();
    const data = {
      accessToken: json.access_token,
      expiresIn: json.expires_in,
      refreshExpiresIn: json.refresh_expires_in,
      refreshToken: json.refresh_token,
      scope: json.scope,
      sessionState: json.session_state,
      tokenType: json.token_type,
      accessTokenParsed: decode(json.access_token) as AccessTokenParsed,
      refreshTokenParsed: decode(json.refresh_token) as RefreshTokenParsed,
    };

    try {
      await secureLocalStorage.setItem("token", JSON.stringify(data));
    } catch (err) {
      console.log(err);
    }
    return data;
  }

  const json = await res.json();
  throw new Error(json.message || json.error || "An unexpected error ocurred");
}

export const emailRegExp = /\S+@\S+\.\S+/;
export const isValidEmail = (email: string) => emailRegExp.test(email);

export function checkLogin(active: string, email: string, password: string) {
  const error = new CustomError("Error Login", "formError");

  if (active === "email" && !isValidEmail(email)) {
    error.setError("login-email", "Please enter a valid email");
  }

  if (!password) {
    error.setError("login-password", "Password can't be empty");
  }

  if (!error.length) {
    return true;
  }
  throw error;
}
