import { stylesheet } from "typestyle";
import { darkBlack } from "../../utils/colors";

const styles = stylesheet({
  container: {
    minHeight: "100vh",
  },
  cardContainer: {
    minHeight: "50vH",
  },
  title: {
    color: darkBlack,
    fontWeight: "bold",
    fontSize: "1.1em",
  },
  tagsWrapper: {
    padding: "2em",
  },

  itemsWrapper: {
    padding: "0em 1em 0em 1em",
  },
  containerSelect: {
    width: "15em !important",
  },
  userContainer: {
    marginTop: "0em",
  },
  notifications: {
    fontWeight: 600,
  },
  textContainer: {
    width: "100%",
    border: "0 !important",
    resize: "none",
    minHeight: "1em !important",
  },
  containerList: {
    margin: "0.3em",
  },
  attachIcon: {
    width: "28px",
    cursor: "pointer",
    marginTop: "1em",
  },
  fileContainer: {
    width: "24%",
  },
  spinner: {
    fontSize: "1em",
    color: "#999",
  },
  checkboxContainer: {
    marginRight: "1em",
  },
  footer: {
    width: "55%",
    position: "fixed",
    bottom: "2em",
    padding: "1em 0 0 0",
  },
  commentList: {
    height: "30%",
    overflowY: "auto",
  },
});

export default styles;
