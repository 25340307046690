import { Modifier } from "@innomius/ravent-typescript-types";

export const defaultModifier: Modifier = {
  name: "",
  selectionType: "single",
  minSelection: 0,
  maxSelection: 0,
  options: [],
  enabled: false,
  default: false,
};
