import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/en-gb";
import "dayjs/locale/de";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { format } from "date-fns-tz";

export enum dateFormats {
  SHORT = "DD/MM/YYYY",
  MONTH = "MMM, YYYY",
  MIDDLEALONE = "dd MMMM, YYYY",
  MIDDLEWHOURYEAR = "D MMM-HH:mm YYYY",
  MIDDLE = "DD MMMM, YYYY HH:mm",
  MIDDLEWHOUR = "DD MMM, HH:mm",
  YEARFIRST = "YYYY/MM/DD",
  UTC = "DD MMMM, YYYY  LT",
  TIMEANDDAY = "llll",
  HOUR = "HH:mm",
  DAYMONTH = "D MMM",
  DAYMONTHYEAR = "D.MMM.YYYY",
}

export function timeLeft(data: Date): string {
  return `${formatDate(data)} (${daysRemaining(data)} days left)`;
}

export function daysRemaining(data: string | Date): number {
  return dayjs(data).diff(dayjs(), "days");
}

export enum dateFormatsFNS {
  SHORT = "dd/MM/yyyy",
  SHORTUSA = "dd/MM/YY",

  MIDDLE = "dd MMMM, yyyy HH:mm",
  MIDDLEWHOUR = "ddMMM-HH:mm",
  MIDDLEWHOURYEAR = "ddMMM-HH:mm yyyy",

  YEARFIRST = "yyyy/MM/dd",
  UTC = "dd MMMM, yyyy  LT",
  TIMEANDDAY = "llll",
  HOUR = "HH:mm",
  DAYMONTH = "d MMM",
  DOWNLOAD = "yyyy.MM.dd-HH.mm",
  EXPRESSION = "yyyy-mm-ddThh:mm:ss",
}

export function formatDateDNS(
  date: Date | null | undefined | string,
  formatExpre?: keyof typeof dateFormatsFNS,
  timezone?: string
): string {
  if (!date) {
    return "";
  }

  const timeZone = localStorage.getItem("timeZone") || timezone || "UTC";
  return format(
    new Date(date),
    formatExpre ? dateFormatsFNS[formatExpre] : dateFormatsFNS.MIDDLE,
    { timeZone }
  );
}

export function formatDate(
  date: Date | null | undefined | string,
  format?: keyof typeof dateFormats
): string {
  if (!date) {
    return "";
  }
  dayjs.locale(localStorage.getItem("dateFormat") || "en-us");
  dayjs.extend(utc);
  dayjs.extend(timezone);

  if (localStorage.getItem("timeZone")) {
    return dayjs(date)
      .tz(localStorage.getItem("timeZone") || "")
      .format(format ? dateFormats[format] : dateFormats.MIDDLE);
  }

  return dayjs(date).format(format ? dateFormats[format] : dateFormats.MIDDLE);
}

export function dateToIso(date: string, hour: string, timezone: string) {
  return dayjs.tz(`${date} ${hour}`, timezone).toISOString();
}

export function dateFilters(date: string, hour: string) {
  return `${date}T${hour}`;
}

export const formatExpiration = (date: Date, exp: number) => {
  let secs = Math.floor(exp / 1000);
  let mins = Math.floor(secs / 60);
  let hours = Math.floor(mins / 60);

  secs = secs % 60;
  mins = mins % 60;

  function splitHours(numberOfHours: number) {
    var days = Math.floor(numberOfHours / 24);
    var hours = Math.floor(numberOfHours % 24);
    return { days: days, hours: hours, mins: mins, secs: secs };
  }
  var timeResult = splitHours(hours);
  return secs < 0
    ? "expired"
    : `${timeResult.days}.${timeResult.hours}:${timeResult.mins}:${timeResult.secs}`;
};
