const translation = {
  en: {
    name: "Name",
    externalId: "External Id",
    type: "Type",
    tags: "Tags",
    status: "Status",
    actions: "Actions",
    delete: "Delete",
    createdAt: "Created",
    subtype: "Subtype",
  },
  es: {
    subtype: "Subtipo",
    createdAt: "Creación",
    name: "Nombre",
    externalId: "Identificación externa",
    type: "Tipo",
    tags: "Etiquetas",
    status: "Estado",
    actions: "Acciones",
    delete: "Eliminar",
  },
  de: {},
};

export default translation;
