
import React, { useState } from "react";
import SendButton from "./SendButton";
import sms from "../../assets/deliveryMethods/sms.svg";
import whatsapp from "../../assets/deliveryMethods/whatsapp.svg";
import emailIcon from "../../assets/deliveryMethods/email.svg";
import PhoneInput from "react-phone-input-2";
import styles from "./styles";
import "react-phone-input-2/lib/style.css";
import download from "../../assets/download.svg";

import Text from "../Text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import { bluishGrey } from "../../utils/colors";
import { useTranslation } from "react-i18next";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Modal } from "../Modal/Modal";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
import InputRadio from "../Inputs/InputRadio";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputText } from "../Inputs/InputText";
import { InputError } from "../Inputs/InputError/InputError";

interface Props {
  onCancel: () => void;
  open: boolean;
  title: string;
  id: string;
  onSend: (medium: string, contact: string, lang: string) => void;
  contactEmail?: string;
  contactPhone?: string;
  onDownload?: (lang: string) => void;
  loading?: boolean;
  loadingDownload?: boolean;
  onOpenPdf?: (lang: string) => void;
}

const SendModal: React.FC<Props> = ({
  onCancel,
  onSend,
  open,
  contactEmail,
  contactPhone,
  title,
  onDownload,
  loading,
  loadingDownload,
  onOpenPdf,
}) => {
  const [deliveryMethod, setMethod] = useState("whatsapp");
  const [email, setEmail] = useState(contactEmail ? contactEmail : "");
  const [error, setError] = useState("");
  const [focus, setFocus] = useState("phone");
  const [lang, setLang] = useState(localStorage.getItem("language") || "ena");
  const [phone, setPhone] = useState(
    contactPhone ? contactPhone.replace(/\D/g, "") : ""
  );

  const validPhone = (inputtxt: string) => {
    const numbers = /^[-+.0-9]{8,}$/;
    if (inputtxt.match(numbers)) {
      return true;
    } else {
      return false;
    }
  };
  const { t } = useTranslation();

  return (
    <Modal
      width="650px"
      buttons={
        <FlexContainer alignItems="center" justifyContent="flex-end">
          <ButtonsContainer>
            <Button
              onClick={onCancel}
              theme="white"
              label={t("component.sendModal.cancel")}
            />
            <Button
              onClick={() => {
                if (loading) {
                  return;
                }
                if (phone && !validPhone(phone)) {
                  setError(t("component.sendModal.invalidPhone"));
                  return;
                }
                onSend(
                  deliveryMethod,
                  deliveryMethod === "email" ? email : `+${phone}`,
                  lang
                );
              }}
              theme="blue"
              label={loading ? "..." : `${t("component.sendModal.send")}`}
              disable={!validPhone}
            />
          </ButtonsContainer>
        </FlexContainer>
      }
      open={open}
      onClose={onCancel}
    >
      <div className={styles.container}>
        <FlexContainer justifyContent="space-between">
          <Text text={`Share ${title}`} fontSize="1.9em" fontWeight={700} />
          {loadingDownload ? (
            <FontAwesomeIcon
              color={bluishGrey}
              icon={faSpinner as IconProp}
              width={"2em"}
              spin
            />
          ) : (
            <ButtonsContainer>
              <Button
                onClick={() => onOpenPdf?.(lang)}
                label={t("component.sendModal.open")}
                theme="white"
              />
              <Button
                icon={download}
                onClick={() => onDownload?.(lang)}
                label={t("component.sendModal.download")}
                theme="white"
              />
            </ButtonsContainer>
          )}
        </FlexContainer>

        <div className={styles.pad}>
          <InputLabel label={t("component.sendModal.language")} />
          <FlexContainer padding="0.5em 0 0 0" justifyContent="flex-start">
            <div className={styles.radioContainer}>
              <InputRadio
                name="language"
                checked={lang === "en"}
                value="en"
                text={t("component.sendModal.en")}
                onChange={() => setLang("en")}
              />
            </div>
            <div className={styles.radioContainer}>
              <InputRadio
                name="language"
                value="es"
                checked={lang === "es"}
                text={t("component.sendModal.es")}
                onChange={() => setLang("es")}
              />
            </div>
          </FlexContainer>
        </div>

        <Text
          text={t("component.sendModal.shareVia")}
          fontSize="1em"
          padding="1.2em 0 0.4em 0"
          fontWeight={700}
        />

        <div className={styles.box}>
          <GridContainer gap="0px" columns="1fr 1fr 1fr">
            <SendButton
              label="SMS"
              image={sms}
              active={deliveryMethod === "sms"}
              onClick={() => {
                setMethod("sms");
                setFocus("phone");
              }}
            />
            <SendButton
              label="Whatsapp"
              image={whatsapp}
              active={deliveryMethod === "whatsapp"}
              onClick={() => {
                setFocus("phone");
                setMethod("whatsapp");
              }}
            />
            <SendButton
              label="Email"
              image={emailIcon}
              active={deliveryMethod === "email"}
              onClick={() => {
                setMethod("email");
                setFocus("email");
              }}
            />
          </GridContainer>
        </div>
        {deliveryMethod === "email" ? (
          <div className={styles.marginBottom}>
            <InputLabel label={t("component.sendModal.contact")} />
            <div
              className={focus === "email" ? styles.focusEmail : styles.none}
            >
              <InputText
                showLabel={false}
                containerClassname={styles.email}
                placeholder="Email"
                value={email}
                showError={false}
                onFocus={() => setFocus("")}
                onChange={(value) => setEmail(value)}
              />
            </div>
          </div>
        ) : (
          
          <div>
            <InputLabel label={t("component.sendModal.contact")} />
            <div className={focus === "phone" ? styles.focus : styles.none}>
              <PhoneInput
                disableDropdown={true}
                country={"mx"}
                onBlur={() => setFocus("")}
                containerClass={styles.phone}
                value={phone}
                onChange={(phoneNumber: string) => {
                  setError("");
                  setFocus("");
                  setPhone(phoneNumber);
                }}
              />
            </div>
            <InputError error={error} />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SendModal;
