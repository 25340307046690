import { stylesheet } from "typestyle";

const styles = stylesheet({
  container: {
    padding: "2em",
  },
  headerCard: {
    padding: "1em",
    borderBottom: "1px solid #DCE1E6",
  },
  card: {
    padding: 0,
  },
  cardButtom: {
    padding: "1em",
  },
  icon: { width: "1.5em" },
});

export default styles;
