const translation = {
  en: {
    goBack: "Go Back",
    error: "Error",
    userLabel: "User",
    successCreation: "successfully updated",
    backToUsers: "Back to Users",
    cancel: "Cancel",
    save: "Save",
    delete: "Delete",
    edit: "Edit",
    deleteMessage: "Are you sure you want to delete",
    userDetailsLabel: "Details",
  },
  es: {
    goBack: "Regresar",
    error: "error",
    userLabel: "Usuario",
    successCreation: "actualizado exitosament",
    backToUsers: "Regresar a Usuarios",
    cancel: "Cancelar",
    save: "Guardar",
    delete: "Borrar",
    edit: "Editar",
    deleteMessage: "¿Está seguro de que quiere eliminar ?",
    userDetailsLabel: "Detalles",
  },
  de: {},
};

export default translation;
