import transportIcon from "../assets/fulfilment/service.svg";

import serviceIcon from "../assets/fulfilment/service2.svg";
import accessIcon from "../assets/fulfilment/access.svg";
import deliveryIcon from "../assets/fulfilment/delivery.svg";
import digitalIcon from "../assets/fulfilment/digital.svg";
import lockerIcon from "../assets/fulfilment/locker.svg";
import pickUpIcon from "../assets/fulfilment/pickup.svg";
import hospitalityIcon from "../assets/fulfilment/hospitality.svg";
import fulfillerIcon from "../assets/fulfiller.svg";

export const typeIconMap: Record<string, string> = {
  service: serviceIcon,
  access: accessIcon,
  delivery: deliveryIcon,
  locker: lockerIcon,
  digital: digitalIcon,
  "pick-up": pickUpIcon,
  transportation: transportIcon,
  hospitality: hospitalityIcon,
  fulfiller: fulfillerIcon
};
