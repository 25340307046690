import React from "react";
import { classes } from "typestyle";
import { InputError } from "../InputError/InputError";
import { InputLabel } from "../InputLabel/InputLabel";
import styles from "./styles.module.css";

export interface Props {
  id?: string;
  name: string;
  value: string;
  label?: string;
  text?: string;
  defaultChecked?: boolean;
  onChange?: (value: string) => void;
  checked?: boolean;
  showLabel?: boolean;
  containerClassname?: string;
  error?: false | "" | string[] | string;
  showError?: boolean;
  disabled?: boolean;
  textColor?: string;
}

export const InputRadio: React.FC<Props> = ({
  id,
  name,
  value,
  label,
  onChange,
  defaultChecked,
  text,
  checked,
  showLabel = true,
  containerClassname,
  error,
  showError = true,
  disabled,
  textColor,
}) => {
  return (
    <div className={classes(styles.container, containerClassname)}>
      {label ? (
        <InputLabel showLabel={showLabel} label={label} id={id} />
      ) : null}
      <div className={styles.radioContainer}>
        <input
          id={id}
          type="radio"
          name={name}
          disabled={disabled}
          defaultChecked={defaultChecked}
          value={value}
          onChange={(e) => onChange?.(e.target.value)}
          checked={checked}
          className={styles.radio}
        />
        <p style={{ color: textColor }} className={styles.text}>
          {text}
        </p>
      </div>
      {error ? <InputError error={error} showError={showError} /> : null}
    </div>
  );
};

export default InputRadio;
