import React from "react";
import { Marker } from "@react-google-maps/api";

interface CustomMarkerOverlayProps {
  position: google.maps.LatLngLiteral;
  onClick?: () => void;
  externalId: string;
  onTime: boolean;
  type: string;
}

const CustomMarkerOverlay: React.FC<CustomMarkerOverlayProps> = ({
  position,
  onClick,
  externalId,
  onTime,
  type,
}) => {
  const generateCustomMarkerSvg = (externalId: string) => `
  <svg width="105" height="51" viewBox="0 0 105 51" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d_7085_260546)">
  <rect x="10" y="6" width="85" height="31" rx="15.5" fill="#E9FFEE"/>
  <rect x="11" y="7" width="83" height="29" rx="14.5" stroke="white" stroke-width="2"/>
  <circle cx="29.5" cy="21.5" r="3.5" fill="#10A841"/>

  <text class="text" x="40%" y="50%" textAnchor="middle" alignmentBaseline="middle" fill="#000" fontSize="12" fontWeight="bold">
  ${externalId}
    </text>

  </g>
  <defs>
  <filter id="filter0_d_7085_260546" x="0" y="0" width="105" height="51" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="4"/>
  <feGaussianBlur stdDeviation="5"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0.0784314 0 0 0 0 0.0745098 0 0 0 0 0.133333 0 0 0 0.1 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7085_260546"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7085_260546" result="shape"/>
  </filter>
  </defs>
  </svg>  
  `;

  const generateCustomMarkerSvgRed = (
    externalId: string
  ) => `<svg width="105" height="51" viewBox="0 0 105 51" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d_7085_260087)">
  <rect x="10" y="6" width="85" height="31" rx="15.5" fill="#FFD0D0"/>
  <rect x="11" y="7" width="83" height="29" rx="14.5" stroke="white" stroke-width="2"/>
  <circle cx="29.5" cy="21.5" r="3.5" fill="#FF2929"/>
  <text x="40%" y="50%" textAnchor="start" alignmentBaseline="middle" fill="#000" fontSize="12" fontWeight="bold" fontFamily="Lato">
  ${externalId}
</text>

</g>
  </g>
  <defs>
  <filter id="filter0_d_7085_260087" x="0" y="0" width="105" height="51" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="4"/>
  <feGaussianBlur stdDeviation="5"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0.0784314 0 0 0 0 0.0745098 0 0 0 0 0.133333 0 0 0 0.1 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7085_260087"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7085_260087" result="shape"/>
  </filter>
  </defs>
  </svg>
  `;

  const generateCustomMarkerSvgGray = (
    externalId: string
  ) => `<svg width="101" height="51" viewBox="0 0 101 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_7085_258745)">
    <rect x="10" y="6" width="81" height="31" rx="15.5" fill="#F5F7FA"/>
    <rect x="11" y="7" width="79" height="29" rx="14.5" stroke="white" stroke-width="2"/>
    <circle cx="29.5" cy="21.5" r="3.5" fill="#858D8F"/>
    <text x="40%" y="50%" textAnchor="start" alignmentBaseline="middle" fill="#000" fontSize="12" fontWeight="bold" fontFamily="Lato">
    ${externalId}
  </text>
  </g>
    <defs>
    <filter id="filter0_d_7085_258745" x="0" y="0" width="101" height="51" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="5"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.0784314 0 0 0 0 0.0745098 0 0 0 0 0.133333 0 0 0 0.1 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7085_258745"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7085_258745" result="shape"/>
    </filter>
    </defs>
    </svg>
    `;

  const customIconSvgGreen = generateCustomMarkerSvg(externalId);
  const customIconSvgRed = generateCustomMarkerSvgRed(externalId);
  const customIconSvgGray = generateCustomMarkerSvgGray(externalId);

  return (
    <Marker
      position={position}
      onClick={onClick}
      icon={{
        url: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
          type === "running"
            ? onTime
              ? customIconSvgGreen
              : customIconSvgRed
            : customIconSvgGray
        )}`,
        scaledSize: new window.google.maps.Size(105, 51),
      }}
    />
  );
};

export default CustomMarkerOverlay;
