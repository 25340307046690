import { stylesheet } from "typestyle";

const styles = stylesheet({
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    height: "93vH",
  },
  header: {
    background: "white",
    height: "60px",
  },
  layout: {
    backgroundColor: "#f2f4f8",
    width: "100%",
    height: "100%",
  },
  normal: {
    $nest: {
      "@media screen and (max-width: 701px)": {
        display: "none",
      },
    },
  },

  cardContainer: {
    padding: "11%",
    height: "auto",
    $nest: {
      "@media screen and (min-width: 701px)": {
        width: "50%",
        padding: "3em !important",
      },
    },
    textAlign: "center",
  },
  text: {
    fontWeight: 600,
    fontSize: "2em",
    color: "#000000",
  },
  textMobile: {
    fontWeight: 600,
    fontSize: "1.8em",
    color: "#000000",
  },
  textThank: {
    fontWeight: 600,
    fontSize: "2em",
    color: "#000000",
    marginTop: "1em",
  },
  blueText: {
    fontWeight: 600,
    color: "#1FA0FF",
  },
  button: {
    height: "40px",
    cursor: "pointer",
  },

  card: {
    position: "absolute",
    width: "60%",
    height: "auto",
    backgroundColor: "#fff",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 100,
    borderRadius: "10px",
    boxShadow: "0px 4px 7px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
  },

  cardMobile: {
    padding: "1em 2em 2em 2em",
  },

  loginButton: {
    $nest: {
      "@media screen and (max-width: 700px)": {
        display: "none",
      },
    },
  },
  mobileContainer: {
    padding: "1em",
    background: "#f2f4f8",
    $nest: {
      "@media screen and (min-width: 701px)": {
        display: "none",
      },
    },
  },
  logo: {
    width: "60%",
    padding: "1em",
  },
  webContainer: {
    position: "absolute",
    width: "60%",
    height: "auto",
    backgroundColor: "#fff",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 100,
    padding: "2em",
    borderRadius: "10px",
    boxShadow: "0px 4px 7px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    $nest: {
      "@media screen and (max-width: 700px)": {
        display: "none",
      },
    },
  },
});

export default styles;
