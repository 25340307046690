
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Text from "../../Text";
import styles from "./styles";
import { getFulfillmentStateImage } from "../../../services/images";
import APIError from "../../../utils/APIError";
import { listFiles } from "../../../services/fulfilments";
import { Modal } from "../../Modal/Modal";
import { Image, Spin, message } from "antd";
import view from "../../../assets/emptymedia.svg";
import { EmptyList } from "../../List/EmptyList";
import { ButtonsContainer } from "../../ButtonsContainer";
import { Button } from "../../Button";
import { FlexContainer } from "../../FlexContainer/FlexContainer";
import { GridContainer } from "../../GridContainer/GridContainer";

interface Props {
  onClose: () => void;
  open: string;
  id: string;
}

const FileModal: React.FC<Props> = ({ onClose, open, id }) => {
  const { t } = useTranslation();
  const [img, setImg] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const load = useCallback(async () => {
    try {
      setLoading(true);
      const res = await listFiles(id);
      const imageUrls: string[] = [];

      for (const item of res) {
        const image = await getFulfillmentStateImage(id, item.id);

        if (image) {
          const blob = new Blob([image], { type: "image/jpeg" });
          const imageUrl = URL.createObjectURL(blob);
          imageUrls.push(imageUrl);
        }
      }

      setImg(imageUrls);
      setLoading(false);
    } catch (err) {
      setLoading(false);

      if (err instanceof APIError) {
        message.error(err.message);
      }
    }
  }, [id]);

  useEffect(() => {
    if (open === id) load();
  }, [load, id, open]);
  return (
    <div className={styles.container}>
      <Modal
        padding="1em"
        width="600px"
        buttons={
          <div>
            <ButtonsContainer justifyContent="flex-end">
              <Button
                onClick={onClose}
                theme="white"
                label={t("component.orderFulfillment.close")}
              />
            </ButtonsContainer>
          </div>
        }
        open={open === id}
        onClose={onClose}
      >
        <div className={styles.modalContainer}>
          <Text
            padding="0em 0 1em 0"
            text={t("component.orderFulfillment.files")}
            color="#000000"
            fontSize="1.4em"
            fontWeight={700}
          />
          {loading ? (
            <FlexContainer>
              <Spin />
            </FlexContainer>
          ) : img.length ? (
            <GridContainer gap="1em" columns="1fr 1fr 1fr">
              {img.map((item) => (
                <Image src={item} className={styles.image} />
              ))}
            </GridContainer>
          ) : (
            <EmptyList instructions="No Files added" image={view} />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default FileModal;
