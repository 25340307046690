import React from "react";
import { ReactComponent as Empty } from "../../assets/empty-list.svg";
import styles from "./styles.module.css";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { Button } from "../Button";
import Text from "../Text";
import restrictedIcon from "../../assets/restricted3.png";

import { ContainerTitle } from "../ContainerTitle";
import { useTranslation } from "react-i18next";
export interface EmptyListProps {
  instructions: string;
  buttonText?: string;
  text?: string;
  onNewClick?: () => void;
  onClick?: () => void;
  error?: string;
  image?: string;
  label?: string;
}

export const EmptyList: React.FC<EmptyListProps> = ({
  image,
  instructions,
  onClick,
  onNewClick,
  text,
  buttonText,
  error,
  label,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.containerEmptyList}>
      {error && error.includes("Forbidden") ? (
        <FlexContainer padding="8% 0" flexDirection="column">
          <img src={restrictedIcon} alt="" className={styles.icon} />
          <ContainerTitle
            padding="1em 0 1em 0"
            size="small"
            label={t("component.errorBox.hidden")}
          />
          <Text
            padding="0 0 1em 0"
            color="#000000"
            text={t("component.errorBox.warningHidden")}
          />
          {label ? <Button onClick={onClick} label={label} /> : null}
        </FlexContainer>
      ) : (
        <>
          {image ? (
            <img src={image} className={styles.image} alt="" />
          ) : (
            <Empty className={styles.image} />
          )}
          <p className={styles.instructions}>{instructions}</p>
          <p className={styles.instructionsSubEmpty}>{text}</p>
          <FlexContainer justifyContent="center" alignItems="center">
            {buttonText ? (
              <Button
                onClick={onNewClick}
                containerClassname={styles.button}
                label={buttonText}
              />
            ) : null}
            {label ? <Button onClick={onClick} label={label} /> : null}
          </FlexContainer>
          {error ? <p className={styles.error}>{error}</p> : null}
        </>
      )}
    </div>
  );
};
