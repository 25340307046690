import React from "react";
import { ReactComponent as Edit } from "../../assets/edit.svg";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";
import { FlexContainer } from "../FlexContainer/FlexContainer";

interface RequestProps {
  legalName: string;
  alias: string;
  bank: string;
  bankAccount: string;
  id: string;
  qrCodeType: string;
  onEdit: () => void;
}

const AccountItem: React.FC<RequestProps> = ({
  legalName,
  bank,
  bankAccount,
  alias,
  id,
  qrCodeType,
  onEdit,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <ListRow
        onClick={() => onEdit()}
        template="1.3fr 1fr 0.7fr 1fr 0.7fr 0.3fr"
        hover={false}
        key={id}
      >
        <ListLabel
          value={
            legalName.length < 1
              ? t("component.accountList.addAccount")
              : legalName
          }
          textAlign="left"
        />
        <ListLabel value={alias} textAlign="left" />
        <ListLabel value={bank} textAlign="left" />
        <ListLabel value={bankAccount} textAlign="left" />
        <ListLabel value={qrCodeType} textAlign="left" />
        <FlexContainer justifyContent="flex-end">
          <Edit
            onClick={(e) => {
              onEdit();
            }}
            className={styles.shareContainer}
          />
        </FlexContainer>
      </ListRow>
    </div>
  );
};

export default AccountItem;
