import { stylesheet } from "typestyle";
import { hoverList } from "../../utils/colors";

const styles = stylesheet({
  container: {
    border: "1px solid #DCE1E6",
    borderRadius: "6px",
    marginBottom: "1.5em",
    background: "white",
    $nest: {
      "&:hover": {
        background: hoverList,
      },
    },
  },
  listContainer: {
    paddingTop: "1em",
  },
  icon: {
    width: "45px",
    marginRight: "1em",
  },
  header: {
    padding: "1em",
    cursor: "pointer",
    // $nest: {
    //   "&:hover": {
    //     background: hoverList,
    //   },
    // },
  },
  bottm: {
    borderTop: "1px solid #DCE1E6",
  },
});

export default styles;
