import { stylesheet } from "typestyle";

const styles = stylesheet({
  container: {
    borderRadius: "5px",
    padding: "1em",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "1em 2em",
    background: "rgba(220, 225, 230, 0.3)",
    margin: "0 !important",
    width: "100%",
    justifyContent: "center",
  },
  box: {
    background: "#F6F8FA",
    height: "15em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  label: {
    color: "#666",
    textAlign: "center",
    fontSize: "1.1em",
    padding: "0.5em 0",
  },
  icon: {
    height: "2em",
  },
  borderTop: {
    borderTop: "1px solid #DCE1E6",
    padding: '1.5em 0'
  },
});

export default styles;
