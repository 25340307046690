import * as React from 'react';
import { FlexContainer, FlexContainerProps } from '../FlexContainer/FlexContainer';
import styles from './styles.module.css';

export interface ButtonsContainerProps extends FlexContainerProps {
  itemsPadding?: string;
  padding?: string;
  flexWrap?: '-moz-initial' | 'inherit' | 'initial' | 'revert' | 'unset' | 'nowrap' | 'wrap' | 'wrap-reverse';
  children?: React.ReactNode[] | React.ReactNode;
}

export const ButtonsContainer: React.FC<ButtonsContainerProps> = ({
  children,
  itemsPadding = '0 1em 0 0',
  ...props
}) => (
  <FlexContainer {...props}>
    {React.Children.map(children, (child) => (
      <div className={styles.wrapper} style={{ padding: itemsPadding }}>
        {child}
      </div>
    ))}
  </FlexContainer>
);
