import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import { useHistory } from "react-router-dom";
import DuplicateLocationList from "../DuplicateLocationList";
import { DuplicateLocation } from "../../utils/types";
import { EmptyList } from "../List/EmptyList";

interface Props {
  venueId: string;
  locations: DuplicateLocation[];
  locationsLoading: boolean;
  count: string;
  errorLocations: string;
  load: () => void;
  sort: string;
  setSearchLocation: (value: string) => void;
  pagination?: boolean;
  page: number;
  total: number;
  error: string;
  hitsPerPage: number;
  onPageChange?: (page: number) => void;
  onHitsPerPageChange?: (hitsPerPage: number) => void;
}

const Duplicate: React.FC<Props> = ({
  locations,
  venueId,
  locationsLoading,
  error,

  setSearchLocation,
  pagination,
  page,
  onHitsPerPageChange,
  onPageChange,
  total,
  hitsPerPage,
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  return (
    <div>
      {!locations.length && !locationsLoading ? (
        <div className={styles.routeContainerEmpty}>
          <EmptyList
            instructions={""}
            text={`${t("component.venueLocations.instructionsD")}`}
          />
        </div>
      ) : (
        <div className={styles.routeContainer}>
          <div>
            <DuplicateLocationList
              venueId={venueId}
              onClickItem={(id) => {
                history.replace(
                  `/venueLocations?search=${id}&view=list&page=0&venueId=${venueId}`
                );
                setSearchLocation(id);
                // load();
              }}
              onPageChange={onPageChange}
              page={page}
              total={total}
              hitsPerPage={hitsPerPage}
              onHitsPerPageChange={onHitsPerPageChange}
              pagination={pagination}
              error={error}
              loading={locationsLoading}
              data={locations}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Duplicate;
