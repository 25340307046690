import { Order, RefundTypes } from "@innomius/ravent-typescript-types";
import React from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import OrderTitle from "../OrderDetail/OrderTitle";
import OrderRefundList from "../OrderRefundList";
import RefundForm from "../RefundForm";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { SideMenu } from "../SideMenu/SideMenu";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { Button } from "../Button";
import { EmptyList } from "../List/EmptyList";

interface Props {
  refunds: RefundTypes[];
  error: string;
  loading: boolean;
  path: string;
  order: Order;
}

const OrderRefunds: React.FC<Props> = ({
  refunds,
  error,
  loading,
  path,
  order,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <div>
      <Route exact path={`${path}/refunds`}>
        <SideMenu
          onClose={() => history.push(`${path}`)}
          open={location.pathname === `${path}/refunds`}
        >
          <div className={styles.itemsWrapper}>
            <FlexContainer
              width="auto !important"
              padding="0 0 1em 0"
              justifyContent="space-between"
            >
              <OrderTitle
                label={`${t("component.orderRefunds.refundsIssued")} (${
                  refunds.length
                })`}
              />
              <Button
                size="medium"
                containerClassname={styles.buttonsContainer}
                buttonClassname={styles.buttonIssue}
                label={t("component.orderRefunds.issueRefund")}
                onClick={() => history.push(`${path}/refunds/new`)}
              />
            </FlexContainer>
            {refunds.length >= 1 ? (
              <div>
                <OrderRefundList
                  error={error}
                  loading={loading}
                  data={refunds}
                />
              </div>
            ) : (
              <EmptyList
                instructions={""}
                text={t("component.orderRefunds.noRefunds")}
              />
            )}
          </div>
        </SideMenu>
      </Route>
      <Route exact path={`${path}/refunds/new`}>
        <RefundForm
          order={order}
          open={location.pathname === `${path}/refunds/new`}
          onCancel={() => history.push(`${path}`)}
        />
      </Route>
    </div>
  );
};

export default OrderRefunds;
