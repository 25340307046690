import React from "react";

import { TimePicker } from "antd";
import produce from "immer";
import styles from "./styles";
import { OpeningHours } from "@innomius/ravent-typescript-types";
import dayjs from "dayjs";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputSwitch } from "../Inputs/InputSwitch";

interface Props {
  data: OpeningHours[];
  onChange: (data: OpeningHours[]) => void;
  edit?: boolean;
}

const OperationalHoursForm: React.FC<Props> = ({ data, onChange, edit }) => {
  return (
    <div>
      <div className={styles.containerDetails}>
        <FlexContainer
          padding={edit ? "0 0 1em 0" : "0 0 0 0"}
          justifyContent="space-between"
        >
          <ContainerTitle size="xsmall" label="Operational Hours" />
        </FlexContainer>
        {!edit ? (
          <p className={styles.text}>Add operational hours for your location</p>
        ) : null}
        {data.map((item, index) => (
          <div key={index} className={styles.officeHourRow}>
            <GridContainer
              padding="1em 0 0 0"
              alignItems="center"
              columns="0.5fr 1fr 3fr"
            >
              <FlexContainer
                padding="0 2em 0 0"
                alignItems="center"
                justifyContent="flex-start"
              >
                <InputSwitch
                  value={item.open}
                  onChange={(value) =>
                    onChange(
                      produce(data, (draft) => {
                        draft[index].open = value;
                      })
                    )
                  }
                />
                <p className={styles.bold}>{item.open ? "Open" : "Close"}</p>
              </FlexContainer>
              <p className={styles.bold}>{item.weekday}</p>
              <TimePicker.RangePicker
                format="HH:mm"
                minuteStep={15}
                order
                defaultValue={[
                  dayjs("08:00", "HH:mm"),
                  dayjs("18:00", "HH:mm"),
                ]}
                value={[
                  dayjs(item.openingTime, "HH:mm"),
                  dayjs(item.closingTime, "HH:mm"),
                ]}
                onChange={(value, dateString) => {
                  onChange(
                    produce(data, (draft) => {
                      draft[index].openingTime = dateString[0];
                      draft[index].closingTime = dateString[1];
                    })
                  );
                }}
              />
            </GridContainer>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OperationalHoursForm;
