import React, { useState } from "react";
import EntityItem from "./EntityItem";
import { DiveShopsListSkeleton } from "../ListSkeletons";
import { Team } from "@innomius/ravent-typescript-types";
import { RouterProps } from "react-router";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { PaginationRavent } from "../Pagination/Pagination";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { ListHeader } from "../List/ListHeader";
import { List } from "../List";

interface EntityProps extends RouterProps {
  error: string;
  loading: boolean;
  data: Team[];
  page: number;
  total: number;
  hitsPerPage: number;
  onPageChange?: (page: number) => void;
  onHitsPerPageChange?: (hitsPerPage: number) => void;
  onChangeSortOrder?: (value: string) => void;
  sortable: boolean;
  pagination?: boolean;
}

const EntityList: React.FC<EntityProps> = ({
  error,
  loading,
  data,
  history,
  page,
  total,
  hitsPerPage,
  onHitsPerPageChange,
  onPageChange,
  onChangeSortOrder,
}) => {
  const { t } = useTranslation();
  const [order, setOrder] = useState<string>("asc");
  const [sort, setSort] = useState<string | null>(null);

  const header = (
    <div>
      <ListHeader template="1.3fr 1fr 1fr 1fr 1fr 0.7fr">
        <ListHeaderLabel
          value={t("component.entityList.legalName")}
          justifyContent="flex-start"
          sortable
          sort={sort === "name" ? (order === "desc" ? "asc" : "desc") : null}
          onClick={() => {
            setSort("name");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`name:${order}`);
            }
          }}
        />
        <div />
        <ListHeaderLabel
          value={t("component.entityList.userCount")}
          justifyContent="flex-start"
          sortable
          sort={
            sort === "usersStats.count:asc"
              ? order === "desc"
                ? "asc"
                : "desc"
              : null
          }
          onClick={() => {
            setSort("usersStats.count:asc");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`usersStats.count:asc:${order}`);
            }
          }}
        />
        <ListHeaderLabel
          value={t("component.entityList.internal")}
          justifyContent="flex-start"
          sortable
          sort={
            sort === "internal" ? (order === "desc" ? "asc" : "desc") : null
          }
          onClick={() => {
            setSort("internal");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`internal:${order}`);
            }
          }}
        />
        <ListHeaderLabel
          value={t("component.entityList.enabled")}
          justifyContent="flex-start"
          sortable
          sort={sort === "enabled" ? (order === "desc" ? "asc" : "desc") : null}
          onClick={() => {
            setSort("enabled");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`enabled:${order}`);
            }
          }}
        />

        <ListHeaderLabel
          value={t("component.entityList.enabled")}
          justifyContent="center"
        />
        <ListHeaderLabel value={""} justifyContent="center" />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <div className={styles.listContainer}>
        <List
          loading={loading}
          loadingElement={() => <DiveShopsListSkeleton />}
          error={error}
          data={data}
          keyExtractor={(item) => item.id}
          renderHeader={() => header}
          renderItem={(item) => (
            <EntityItem history={history} data={item} key={item.id} />
          )}
        />
      </div>

      <div className={styles.paginationContainerUp}>
        <PaginationRavent
          current={page + 1}
          defaultCurrent={page + 1}
          total={total}
          showSizeChanger
          onShowSizeChange={(page, size) => {
            if (onHitsPerPageChange) {
              onHitsPerPageChange(size);
            }
          }}
          onChange={(page) => {
            if (onPageChange) {
              onPageChange(page - 1);
            }
          }}
          pageSize={hitsPerPage}
        />
      </div>
    </div>
  );
};

export default EntityList;
