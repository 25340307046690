import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as CalIcon } from "../../assets/cal.svg";
import { ReactComponent as LogIcon } from "../../assets/log.svg";
import deleteIcon from "../../assets/officialMedia/default-delete.svg";
import DeletePopUp from "../DeletePopUp";
import { Popover, message } from "antd";
import Text from "../Text";
import { useTranslation } from "react-i18next";
import styles from "./styles";
import SchedulingForm from "./SchedulingForm";
import {
  Action,
  Schedule,
  ScheduleLog,
} from "@innomius/ravent-typescript-types";
import dayjs from "dayjs";
import { useHistory, useLocation } from "react-router-dom";
import { listSchedulesLogs } from "../../services/schedules";
import APIError from "../../utils/APIError";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";

interface Props {
  data: Schedule;
  index: number;
  onDelete: (id: number) => void;
  loading: string;
  onAdd: (data: Schedule) => void;
  onDeleteSchedule: (id: string) => void;
  onEditSchedule: (data: Schedule) => void;
  actions: Action[];
}

const ScheduleItem: React.FC<Props> = ({
  data,
  onDelete,
  index,
  loading,
  onDeleteSchedule,
  actions,
}) => {
  const [deleteOrderState, setDelete] = useState("");
  const [logs, setLogs] = useState<ScheduleLog[]>([]);

  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();

  const loadLogs = useCallback(async () => {
    if (
      location.pathname === `/integrations/schedules/${data.id}` ||
      location.pathname === `/integrations/schedules/${data.id}/logs`
    )
      try {
        const res = await listSchedulesLogs({
          scheduleNotificationId: data.id,
        });
        setLogs(res.data);
      } catch (err) {
        if (err instanceof APIError) {
          message.error(`${err.message}: ${err.messages}`);
        }
      }
  }, [data.id, location.pathname]);

  useEffect(() => {
    loadLogs();
  }, [loadLogs]);

  const content = (
    <div style={{ padding: "0.5em" }}>
      <Text
        text={actions.find((item) => item.id === data.actionId)?.url || ""}
      />
    </div>
  );

  return (
    <>
      <SchedulingForm
        data={data}
        logs={logs}
        actions={actions}
        onDeleteSchedule={onDeleteSchedule}
      />
      <ListRow template="2fr 1fr 1fr 1fr 1fr" hover={false}>
        <Popover placement="right" content={content} title="Flow URL">
          <div className={styles.center}>
            {actions.find((item) => item.id === data.actionId)?.name || ""}
          </div>
        </Popover>
        <ListLabel
          value={
            data.type === "at"
              ? `${dayjs(data.date)
                  .tz(data.timezone)
                  .format("DD MMM, YYYY HH:mm")}`
              : (data.cronTabExpression as string)
          }
          textAlign="left"
        />
        <ListLabel value={data.timezone} textAlign="left" />
        <ListLabel value={data.lastStatus} textAlign="left" />

        <div className={styles.iconContainer}>
          <LogIcon
            className={styles.icon3}
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/integrations/schedules/${data.id}/logs`);
            }}
          />

          <CalIcon
            className={styles.iconDelete}
            onClick={() => {
              history.push(`/integrations/schedules/${data.id}`);
            }}
          />
          <Popover
            placement="left"
            content={
              <div onClick={() => setDelete("")}>
                <DeletePopUp
                  loading={loading === "delete"}
                  value={`${t("component.orderlineStateList.deleteMessage")}?`}
                  onClose={(e) => {
                    e.stopPropagation();
                    setDelete("");
                  }}
                  onDelete={(e) => {
                    e.stopPropagation();
                    onDelete(index);
                  }}
                />
              </div>
            }
            trigger="click"
            open={deleteOrderState === "delete"}
            onOpenChange={() => setDelete(deleteOrderState)}
          />
          <img
            src={deleteIcon}
            alt="userDelete"
            onClick={(e) => {
              setDelete("delete");
            }}
          />
        </div>
      </ListRow>
    </>
  );
};

export default ScheduleItem;
