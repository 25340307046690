import React from "react";

import { OrderListSkeleton } from "../ListSkeletons";
import { FulfillmentEvent, Load } from "@innomius/ravent-typescript-types";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { PaginationRavent } from "../Pagination/Pagination";
import EventItem from "./EventItem";
import { Asset } from "../../utils/types";
import EventItemNormal from "./EventItemNormal";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { ListHeader } from "../List/ListHeader";
import { List } from "../List";

interface EventListProps {
  error: string;
  loading: boolean;
  data: FulfillmentEvent[];
  page: number;
  total: number;
  hitsPerPage: number;
  onPageChange?: (page: number) => void;
  onHitsPerPageChange?: (hitsPerPage: number) => void;
  pagination?: boolean;
  updateAsset: (event: FulfillmentEvent, id: string) => void;
  onSaveLoad: (eventId: string, data: Load[]) => void;
  teams: { label: string; value: string }[];
  updateFulfiller: (eventId: string, fulfillerId: string) => void;
  updateTeam: (eventId: string, teamId: string) => void;
  assets: Asset[];
  selected: { id: string }[];
  setSelected: (value: any) => void;
  setState: (
    id: string,
    fulfillmentLevel: string,
    status: string,
    w3w: string,
    images: string[]
  ) => void;
  updateFulfillmentName: (
    id: string,
    diveshopId: string,
    fulfillmentLevel: string
  ) => void;
  fulfillmentNames: { label: string; value: string }[];
  errorState: string;
  setNew: (id: string, state: string) => void;
  type: string;
  onTagClick: (tag: string) => void;
}

const EventList: React.FC<EventListProps> = ({
  error,
  loading,
  data,
  pagination,
  page,
  total,
  hitsPerPage,
  onHitsPerPageChange,
  onPageChange,
  updateAsset,
  updateFulfiller,
  updateTeam,
  teams,
  onSaveLoad,
  assets,
  selected,
  errorState,
  updateFulfillmentName,
  setState,
  fulfillmentNames,
  setNew,
  type,
  onTagClick,
}) => {
  const { t } = useTranslation();

  const header = (
    <div>
      <ListHeader template="0.3fr 0.7fr 0.4fr 1fr 0.7fr">
        <ListHeaderLabel
          value={t("component.orderLocation.id")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderLocation.name")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderLocation.date")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderLocation.tags")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel value={""} justifyContent="flex-start" />
      </ListHeader>
    </div>
  );

  return (
    <div>
      {pagination && data.length >= 3 ? (
        <div className={styles.paginationContainerUp}>
          <PaginationRavent
            current={page + 1}
            showSizeChanger={true}
            defaultCurrent={page + 1}
            total={total}
            onShowSizeChange={(page, size) => {
              if (onHitsPerPageChange) {
                onHitsPerPageChange(size);
              }
            }}
            onChange={(page) => {
              if (onPageChange) {
                onPageChange(page - 1);
              }
            }}
            pageSize={hitsPerPage}
          />
        </div>
      ) : null}
      <div className={styles.listContainer}>
        <List
          loading={loading}
          loadingElement={() => <OrderListSkeleton />}
          error={error}
          data={data}
          empty={!data.length}
          keyExtractor={(item) => item.id}
          renderHeader={() => header}
          renderItem={(item) =>
            type === "card" ? (
              <EventItemNormal
                setNew={setNew}
                onTagClick={onTagClick}
                updateFulfillmentName={updateFulfillmentName}
                updateAsset={updateAsset}
                teams={teams}
                onSaveLoad={onSaveLoad}
                updateFulfiller={updateFulfiller}
                data={item}
                errorState={errorState}
                fulfillmentNames={fulfillmentNames}
                setState={setState}
                assets={assets}
                updateTeam={updateTeam}
                key={item.id}
                isSelected={selected.some((loc) => loc.id === item.id)}
              />
            ) : (
              <EventItem
                setNew={setNew}
                updateFulfillmentName={updateFulfillmentName}
                updateAsset={updateAsset}
                teams={teams}
                onSaveLoad={onSaveLoad}
                updateFulfiller={updateFulfiller}
                data={item}
                errorState={errorState}
                fulfillmentNames={fulfillmentNames}
                setState={setState}
                assets={assets}
                updateTeam={updateTeam}
                key={item.id}
                isSelected={selected.some((loc) => loc.id === item.id)}
              />
            )
          }
        />
      </div>
    </div>
  );
};

export default EventList;
