
import React, { useState } from "react";
import styles from "./styles";

import { useTranslation } from "react-i18next";
import lock from "../../assets/lock.svg";
import { StatusBadgePoint } from "../StatusBadgePoint/StatusBagePoint";
import Text from "../Text";
import { capitalize } from "../../utils/format";
import StatesModal from "../OrderFulfillment/StatesModal";

import { ReactComponent as StatesIcon } from "../../assets/statesBlack.svg";
import { ReactComponent as StatesIconBlue } from "../../assets/statesB.svg";
import { useHistory } from "react-router-dom";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { GridContainer } from "../GridContainer/GridContainer";
import { Button } from "../Button";

interface OrderLineItemProps {
  description: string;
  details?: string;
  fulfillmentLevel: string;
  createEvent: () => void;
  currentState: string;
  setState: (
    id: string,
    fulfillmentLevel: string,
    status: string,
    w3w: string,
    images: string[]
  ) => void;
  updateFulfillmentName: (
    id: string,
    diveshopId: string,
    fulfillmentLevel: string
  ) => void;
  fulfillmentNames: { label: string; value: string }[];
  fulfillmentDefinitionId: string;
  orderlineId: string;
  orderId: string;
}

const OrderlineItem: React.FC<OrderLineItemProps> = ({
  description,
  details,
  fulfillmentLevel,
  createEvent,
  currentState,
  setState,
  updateFulfillmentName,
  fulfillmentNames,
  fulfillmentDefinitionId,
  orderlineId,
  orderId,
}) => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const history = useHistory();
  return (
    <>
      <StatesModal
        updateFulfillmentName={(id, diveshopId) =>
          updateFulfillmentName(id, diveshopId, fulfillmentLevel)
        }
        fulfillmentNames={fulfillmentNames}
        fulfillmentDefinitionId={fulfillmentDefinitionId}
        error={""}
        orderlineId={orderlineId}
        setState={(name, w3w, images) => {
          try {
            setState(orderlineId || "", fulfillmentLevel, name, w3w, images);
            setOpen(false);
          } catch (err) {}
        }}
        open={open}
        onClose={() => setOpen(false)}
        state={currentState}
      />
      <div className={styles.orderlineCard}>
        <FlexContainer justifyContent="space-between" alignItems="flex-start">
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push(
                `/orders/${orderId}/orderlines/${orderlineId}/states`
              )
            }
          >
            <FlexContainer alignItems="flex-start" flexDirection="column">
              <p className={styles.title}>{description}</p>
              <div />
              <GridContainer
                gap="1em"
                padding="0.2em 0 0 0"
                alignItems="flex-start"
                columns="3fr 2em 1.5fr"
              >
                <div>
                  <FlexContainer
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <img src={lock} className={styles.lock} alt="" />
                    <span className={styles.info}>{details}</span>
                  </FlexContainer>
                  <FlexContainer
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <div className={styles.lock} />
                  </FlexContainer>
                </div>
              </GridContainer>
            </FlexContainer>
          </div>

          <FlexContainer justifyContent="flex-end">
            <div onClick={() => setOpen(true)} className={styles.borderRight}>
              <StatusBadgePoint
                point
                status={currentState ? "states" : "DISABLED"}
              />
              {currentState ? (
                <Text
                  color="#333"
                  padding="0 0 0 0.5em"
                  text={capitalize(currentState)}
                />
              ) : (
                <Text
                  color="#858D8F"
                  padding="0 0 0 0.5em"
                  text={t("component.orderFulfillment.setState")}
                />
              )}
            </div>
            {currentState ? (
              <StatesIconBlue
                onClick={() => setOpen(true)}
                style={{
                  marginRight: "1em",
                  width: "1.5em",
                  cursor: "pointer",
                }}
              />
            ) : (
              <StatesIcon
                onClick={() => setOpen(true)}
                style={{
                  marginRight: "1em",
                  width: "1.5em",
                  cursor: "pointer",
                }}
              />
            )}
            {fulfillmentLevel === "orderline" ? (
              <Button
                onClick={() => createEvent()}
                buttonClassname={styles.button}
                label={t("component.orderLocation.setLocation")}
              />
            ) : null}
          </FlexContainer>
        </FlexContainer>
      </div>
    </>
  );
};

export default OrderlineItem;
