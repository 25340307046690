const translation = {
  en: {
    import: "Import CSV File",
    locations: "Locations",
    upload: "Upload",
    drag: "Drag and drop a CSV file here",
    rows: "Rows",
    close: "Close",
    create: "Create",
    export: "Export",
    exportFile: "Export to CSV File",
    total: "Total",
  },
  es: {
    total: "Total",
    export: "Exportar",
    exportFile: "Exportar a Archivo CSV",
    close: "Cerrar",
    create: "Crear",
    rows: "Filas",
    import: "Importar Archivo CSV",
    locations: "Lugares",
    upload: "Subir",
    drag: "Arrastra y suelta un archivo CSV aquí",
  },
  de: {},
};

export default translation;
