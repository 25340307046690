import { RefundTypes } from "@innomius/ravent-typescript-types";
import { Schema } from "../utils/types";

export const refunds: RefundTypes[] = [
  {
    id: "s492f0bbe-e748-43mc-8fc6-1af7b2ec6381",
    amount: {
      currency: "$",
      value: 150,
    },
    shortPaymentId: "PA1234",
    status: "pending",
    type: "card",
    timestamp: new Date().toString(),
    panToken: "12321323",
    paymentId: "c6b9n821-dcbe-2221-a955-0be4178971f7",
    created: new Date(),
    updated: new Date(),
    deleted: false,
    cardBrand: "Visa",
    lastFourDigits: "4343",
    customerName: "Astrid Bosch",
    reason: "Order was cancelled.",
  },
];

export const refundInvoiceMock: Schema = {
  componentName: "refund-mx",
  componentLabel: "Reembolso Mexicano",
  elements: [
    {
      label: "Método de Pago",
      name: "paymentMethod",
      value: "",
      type: "list",
      elements: [
        {
          value: "01",
          label: "Efectivo",
        },
        { value: "02", label: "Cheque nominativo" },
        { value: "03", label: "Transferencia electrónica de fondos" },
        { value: "04", label: "Tarjeta de crédito" },
        { value: "05", label: "Monedero electrónico" },
        { value: "06", label: "Dinero electrónico" },
        { value: "08", label: "Vales de despensa" },
        { value: "12", label: "Dación en pago" },
        { value: "13", label: "Pago por subrogación" },
        { value: "14", label: "Pago por consignación" },
        { value: "15", label: "Condonación" },
        { value: "17", label: "Compensación" },
        { value: "23", label: "Novación" },
        { value: "24", label: "Confusión" },
        { value: "25", label: "Remisión de deuda" },
        { value: "26", label: "Prescripción o caducidad" },
        { value: "27", label: "A satisfacción del acreedor" },
        { value: "28", label: "Tarjeta de débito" },
        { value: "29", label: "Tarjeta de servicios" },
        { value: "30", label: "Aplicación de anticipos" },
        { value: "31", label: "Intermediario pago" },
        { value: "99", label: "Por definir" },
      ],
    },
  ],
};
