const translation = {
  en: {
    search: "Search",
    state: "Order States",
    clearAll: "Clear all",
    timezone: "Timezone",
    favourites: "View Favourites",
    externalCustomerFullName: "Customer Name",
    externalCustomerId: "Customer ID",
    shortId: "Order ID",
    filter: "Select Search Filter",
    description: "Description",
    paymentState: "Payment States",
    date: "Date",
    filters: "Filters"
  },
  es: {
    filters: "Filtros",

    date: "Fecha",
    search: "Buscar",
    state: "Estados de la Orden",
    clearAll: "Borrar todo",
    timezone: "Zona Horaria",
    favourites: "Ver Favoritos",
    externalCustomerFullName: "Nombre Cliente",
    externalCustomerId: "ID Cliente",
    shortId: "ID Orden",
    filter: "Filtros de búsqueda",
    description: "Descripción",
    paymentState: "Estados de pago",
  },
  de: {},
};

export default translation;
