import { stylesheet } from "typestyle";

const styles = stylesheet({
  container: {
    padding: "1em",
    fontSize: '1em',
    borderRadius: '7px' 
  },
});

export default styles;
