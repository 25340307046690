import { stylesheet } from "typestyle";
import { black } from "../../utils/colors";

const styles = stylesheet({
  container: {
    padding: "2em",
  },
  title: {
    fontSize: "1.5em",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "left",
    color: "#141322",
    paddingTop: "1em",
  },
  listContainer: {
    padding: "0em",
  },
  loading: {
    color: black,
    fontWeight: 600,
    padding: "6em 1em",
  },
  item: {
    fontSize: "1em",
    width: "100%",
    boxSizing: "border-box",
    breakInside: "avoid",
    textDecoration: "none",
    color: black,
    display: "flex",
    paddingLeft: "0.5em",
    flexWrap: "wrap",
    alignItems: "center",
    minHeight: "4em",
    height: "auto",
    $nest: {
      "&:hover": {
        backgroundColor: "#e4e9f0",
        color: black,
      },
      "& > div": {
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        color: black,
        padding: "1em",
      },
      "& > div > div": {
        display: "flex",
        alignItems: "center",
      },
    },
  },

  center: {
    color: black,
    fontStyle: "normal",
    justifyContent: "center",
    padding: "1em",
    display: "flex",
    alignItems: "center",
    marginRight: "0.5em",
    fontSize: "0.9em",
  },
  spaceBetween: {
    color: black,
    fontStyle: "normal",
    justifyContent: "space-between",
    padding: "1em",
    display: "flex",
    alignItems: "center",
    marginRight: "0.5em",
    fontSize: "0.9em",
  },
  normal: {
    fontSize: "1em",

    fontWeight: 600,
    alignItems: "center",
    display: "flex",
    padding: "0 0.5em",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    cursor: "pointer",
  },
  iconContainerQR: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    zIndex: 80,
  },
  icon: {
    width: 23,
  },
  visitorIcon: {
    width: 23,
    marginRight: "0.5em",
  },
  imageIcon: {
    width: 60,
    borderRadius: "2px",
    marginRight: "1em",
  },
  link: {
    textDecoration: "none",
  },
  paginationContainer: {
    padding: "2em 0",
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  paginationContainerUp: {
    padding: "2em 0 1em 0",
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    position: "fixed",
    bottom: 0,
    background: "#f2f4f8",
  },
});

export default styles;
