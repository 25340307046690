import React, { useCallback, useEffect, useState } from "react";

import OfficialMediaList from "../OfficialMediaList";
import styles from "./styles";
import Text from "../Text";
import { useTranslation } from "react-i18next";
import FileItem from "../AddInvoice/FileItem";
import APIError from "../../utils/APIError";
import { message } from "antd";
import {
  createLocationMedia,
  deleteMedia,
  updateLocationMedia,
} from "../../services/media";
import { mediaList } from "../../store/media";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { useHistory } from "react-router-dom";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { GridContainer } from "../GridContainer/GridContainer";
import { EmptyList } from "../List/EmptyList";

interface Props {
  onEnable?: (enabled: boolean) => string;
  load: () => void;
  venueId: string;
  locationId: string;
  path: string;
}

const LocationOfficialMedia: React.FC<Props> = ({
  venueId,
  locationId,
  load,
  path,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const officialMedia = useSelector((state: RootState) => state.media);
  const inputFile = React.useRef<HTMLInputElement>(null);

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);

  const loadMedia = useCallback(async () => {
    dispatch(mediaList({ venue_id: venueId, location_id: locationId }));
  }, [locationId, venueId, dispatch]);

  const history = useHistory();

  useEffect(() => {
    loadMedia();
  }, [loadMedia]);

  const handleUploadFiles = (files: File[]) => {
    const uploaded = [...uploadedFiles];

    if (files) {
      // eslint-disable-next-line array-callback-return
      files.some((file: File) => {
        if (uploaded.findIndex((f: File) => f.name === file.name) === -1) {
          uploaded.push(file);
        }
      });
    }
    setUploadedFiles(uploaded);
  };

  const handleFileEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const chosenFiles = Array.prototype.slice.call(event.target.files);
    if (chosenFiles) {
      handleUploadFiles(chosenFiles);
    }
  };

  const openInputFile = () => inputFile.current?.click();

  const onSave = async () => {
    try {
      setLoading(true);
      await createLocationMedia(venueId, uploadedFiles, locationId);
      setLoading(false);
      message.success(t("component.locationDetail.successFileUpload"));
      setUploadedFiles([]);
      load();
    } catch (err) {
      setLoading(false);
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  }; 

  const onEdit = async (id: string, officialPublic: boolean) => {
    try {
      await updateLocationMedia(id, { public: officialPublic });
      message.success(t("component.locationDetail.successUpdate"));
      setUploadedFiles([]);
      loadMedia();
      history.push(path);
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onDelete = async (id: string) => {
    try {
      await deleteMedia(id);
      message.success(t("component.locationDetail.successUpdate"));
      setUploadedFiles([]);
      loadMedia();
      history.push(path);
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  return (
    <div>
      <div className={styles.container}>
        <FlexContainer padding="1em 0 2em 0" justifyContent="space-between">
          {uploadedFiles.length ? (
            <ContainerTitle size="small" label="Upload File" />
          ) : (
            <ContainerTitle
              size="small"
              label={t("component.locationDetail.officialMedia")}
              count={officialMedia.list.data.length.toString()}
            />
          )}

          <ButtonsContainer justifyContent="flex-end">
            <input
              id="fileUpload"
              type="file"
              ref={inputFile}
              multiple
              style={{ display: "none" }}
              onChange={handleFileEvent}
            />

            {uploadedFiles.length ? (
              <Button
                onClick={async () => onSave()}
                theme="blue"
                label={loading ? "..." : t("component.locationDetail.save")}
              />
            ) : (
              <Button
                onClick={async () => openInputFile()}
                theme="white"
                label={t("component.locationDetail.uploadFiles")}
              />
            )}
          </ButtonsContainer>
        </FlexContainer>
      </div>
      {uploadedFiles.length ? (
        <div className={styles.container}>
          <GridContainer
            padding="1em 0 1em 0"
            gap="0.5em"
            columns="1fr 0.1fr 0.3fr"
          >
            <Text
              color="#000000"
              text={t("component.addInvoice.description")}
              fontWeight={700}
            />
            <div />
            <Text
              color="#000000"
              text={t("component.addInvoice.fileSize")}
              fontWeight={700}
            />
          </GridContainer>
          <div>
            {uploadedFiles.map((file: File) => (
              <FileItem type={file.type} name={file.name} size={file.size} />
            ))}
          </div>
        </div>
      ) : null}

      {uploadedFiles.length ? null : !officialMedia.list.data.length &&
        !officialMedia.loading ? (
        <EmptyList
          instructions=""
          text={t("component.locationDetail.noFiles")}
          onNewClick={() => {}}
        />
      ) : (
        <OfficialMediaList
          error={""}
          deleteMedia={onDelete}
          loading={officialMedia.loading}
          sortable
          editOfficialMedia={(id, value) => onEdit(id, value)}
          data={officialMedia.list.data}
        />
      )}

      {/* {uploadingPercentage && uploadingPercentage < 100 ? (
        <UploadRow
          percentageNumber={uploadingPercentage}
          fileName={fileName}
          percentage={uploadingPercentage}
        />
      ) : null} */}
    </div>
  );
};

export default LocationOfficialMedia;
