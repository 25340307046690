import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import {
  Location,
  Message,
  Placement,
  Visitor,
} from "@innomius/ravent-typescript-types";
import LocationsList from "../LocationList";
import LocationForm from "../LocationForm";
import { useHistory, useLocation } from "react-router-dom";
import { DoubleButton } from "../DoubleButton";
import mapIcon from "../../assets/map.svg";
import listIcon from "../../assets/view-list.svg";
import LocationsMapView from "../LocationsMapView";
import { removeEmptyStringFromObject } from "../../utils/order";
import LocationDetail from "../LocationDetail";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { Button } from "../Button";
import { EmptyList } from "../List/EmptyList";

interface Props {
  venueId: string;
  locations: Location[];
  locationsLoading: boolean;
  count: string;
  venueLocation: Location;
  venuePlacement: Placement;
  error: string;

  onChangeLocation: <P extends keyof Location>(
    prop: P,
    value: Location[P]
  ) => void;

  onDeleteLocation: (id: string) => void;
  onEditLocation: (data: Location, placement: Placement) => void;
  onCreateLocation: (
    venueLocation: Location,
    venuePlacement: Placement
  ) => void;
  visitors: Visitor[];
  messages: Message[];
  errorLocations: string;
  page: number;
  total: number;
  hitsPerPage: number;
  setPage?: (page: number) => void;
  setHitsPerPage?: (hitsPerPage: number) => void;
  open: string;
  setModal: (value: string) => void;
  load: () => void;
  selectedLocations: { id: string; name: string }[];
  setSelectedLocations: (value: any) => void;
  groups: string[];
  selectedGroup: string;
  setGroup: (value: string) => void;
  view: string;
  setView: (value: string) => void;
  onChange: (facetGroup: string, option: string) => void;
  selected: string[];
  search: string;
  setSearch: (value: string) => void;
  sort: string;
  onChangeSort: (value: string) => void;
  setDefaultValue: () => void;
}

const VenueLocations: React.FC<Props> = ({
  locations,
  messages,
  hitsPerPage,
  page,
  setPage,
  setHitsPerPage,
  venuePlacement,
  count,
  onChangeLocation,
  onCreateLocation,
  onDeleteLocation,
  onEditLocation,
  venueLocation,
  visitors,
  venueId,
  locationsLoading,
  setModal,
  load,
  total,
  selectedLocations,
  setSelectedLocations,
  groups,
  selectedGroup,
  view,
  setView,

  onChangeSort,
  setDefaultValue,
  error,
}) => {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const alphaSorted =
    groups
      ?.map((item) => ({ label: item, value: item }))
      ?.sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  // const grupos = [{ label: "All Groups", value: "" }, ...alphaSorted];
  const [open, setOpen] = useState("");
  const [locationId, setLocationId] = useState<string>("");

  return (
    <div>
      <LocationDetail
        load={load}
        groups={alphaSorted}
        venueId={venueId}
        onEditLocation={(data, placement) => onEditLocation(data, placement)}
        messages={messages}
        visitors={[]}
        data={venueLocation}
        open={open === locationId}
        onDelete={() => onDeleteLocation(locationId)}
        onClose={() => {
          history.push(`/venueLocations`);
          setOpen("");
        }}
      />
      {!locations.length ? (
        <div className={styles.routeContainerEmpty}>
          <FlexContainer padding="0 0 2em 0" justifyContent="space-between">
            {view === "map" ? (
              <Button
                icon={listIcon}
                theme="white"
                onClick={() => {
                  setView("list");
                  const params = new URLSearchParams(
                    removeEmptyStringFromObject({
                      view: "list",
                      group: selectedGroup,
                    })
                  );

                  history.replace(`/venueLocations?${params.toString()}`);
                }}
                label={t("component.venueDetailHeader.listView")}
              />
            ) : (
              <Button
                icon={mapIcon}
                theme="white"
                onClick={() => {
                  setView("map");
                  const params = new URLSearchParams(
                    removeEmptyStringFromObject({
                      view: "map",
                      group: selectedGroup,
                    })
                  );
                  history.replace(`/venueLocations?${params.toString()}`);
                }}
                label={t("component.venueDetailHeader.mapView")}
              />
            )}

            <FlexContainer justifyContent="flex-end">
              <div style={{ margin: "0 1em" }}>
                <Button
                  disable={venueId === ""}
                  theme="white"
                  label={`${t("component.venueLocations.importMultiple")}`}
                  onClick={() => {
                    setModal("multiple");
                  }}
                  buttonClassname={styles.buttonsContainer}
                />
              </div>
              <Button
                disable={venueId === ""}
                label={`${t("container.venueDetail.createNewLocations")}`}
                onClick={() => {
                  setModal("create-location");
                  history.push(`/venueLocations/new`);
                }}
              />
            </FlexContainer>
          </FlexContainer>
          <EmptyList
            instructions={""}
            text={`${t("container.venueDetail.instructions")}`}
          />
        </div>
      ) : (
        <div className={styles.routeContainer}>
          <div>
            <FlexContainer padding="0 0 1em 0" justifyContent="space-between">
              <div>
                {view === "map" ? (
                  <Button
                    icon={listIcon}
                    theme="white"
                    onClick={() => {
                      setView("list");
                      const params = new URLSearchParams(
                        removeEmptyStringFromObject({
                          view: "list",
                          group: selectedGroup,
                        })
                      );

                      history.replace(`/venueLocations?${params.toString()}`);
                    }}
                    label={t("component.venueDetailHeader.listView")}
                  />
                ) : (
                  <Button
                    icon={mapIcon}
                    theme="white"
                    onClick={() => {
                      setView("map");
                      const params = new URLSearchParams(
                        removeEmptyStringFromObject({
                          view: "map",
                          group: selectedGroup,
                        })
                      );
                      history.replace(`/venueLocations?${params.toString()}`);
                    }}
                    label={t("component.venueDetailHeader.mapView")}
                  />
                )}
              </div>
              <FlexContainer justifyContent="flex-end">
                <div style={{ margin: "0 1em" }}>
                  <DoubleButton
                    disable={venueId === ""}
                    theme="white"
                    options={[
                      {
                        label: `${t(
                          "component.venueLocations.importMultiple"
                        )}`,
                        click: () => setModal("multiple"),
                      },
                      {
                        label: `${t("component.venueLocations.export")}`,
                        click: () => setModal("export"),
                      },

                      {
                        label: `${t("component.venueLocations.delete")}`,
                        click: () => setModal("delete"),
                      },
                    ]}
                    buttonClassname={styles.buttonsContainer}
                    label={t("component.venueLocations.actions")}
                  />
                </div>
                <Button
                  disable={venueId === ""}
                  label={`${t("container.venueDetail.createNewLocations")}`}
                  onClick={() => {
                    setModal("create-location");
                    history.push(`/venueLocations/new`);
                  }}
                />
              </FlexContainer>
            </FlexContainer>

            {view === "map" ? (
              <LocationsMapView
                data={locations}
                load={load}
                groups={alphaSorted}
                onDelete={onDeleteLocation}
                venueId={venueId}
                onEditLocation={onEditLocation}
                path={"/venueLocations"}
              />
            ) : (
              <LocationsList
                venueId={venueId}
                onClickItem={(id) => setLocationId(id)}
                checkbox={true}
                onDelete={(id) => onDeleteLocation(id)}
                visitors={visitors}
                messages={messages}
                error={error}
                loading={locationsLoading}
                sortable
                onChangeSortOrder={onChangeSort}
                selectedLocations={selectedLocations}
                setSelectedLocations={setSelectedLocations}
                data={locations}
                path={"/venueLocations"}
                page={page}
                load={load}
                total={total}
                hitsPerPage={hitsPerPage}
                onPageChange={setPage}
                onHitsPerPageChange={setHitsPerPage}
                pagination
                onEditLocation={(data, placement) =>
                  onEditLocation(data, placement)
                }
              />
            )}
          </div>
          {/* </GridContainer> */}
        </div>
      )}
      <LocationForm
        groups={alphaSorted}
        name={venueLocation.name}
        open={
          location.pathname === `/venueLocations/new` ||
          location.pathname === `/venueLocations/new/placement` ||
          location.pathname === `/venueLocations/new/checkin`
        }
        onChangeName={(value) => onChangeLocation("name", value)}
        path={`/venueLocations`}
        data={venueLocation}
        onChange={onChangeLocation}
        onCreate={() => onCreateLocation(venueLocation, venuePlacement)}
        onClose={() => {
          history.push(`/venueLocations`);
          load();
          setDefaultValue();
        }}
      />
    </div>
  );
};

export default VenueLocations;
