import { stylesheet } from "typestyle";
import { black } from "../../utils/colors";

const styles = stylesheet({
  modalContainer: {
    padding: "1em",
  },
  selectContainer: {
    width: "100%",
    paddingBottom: "2em",
  },
  notEnrollContainer: {
    background: "rgba(250, 173, 21, 0.1)",
    border: "1px solid #FAAD15",
    padding: "1.1em",
    borderRadius: "6px",
  },
  icon: {
    width: "30px",
    cursor: "pointer",
  },
  container: {
    $nest: {
      "div > div": {
        alignItems: "center !important",
      },
    },
  },
  wrapperCard: {
    padding: "0em 2em 1em 1em",
  },
  bold: {
    fontSize: "1em",
    fontWeight: 700,
    fontStyle: "normal",
    letterSpacing: 0,
    margin: "0",
  },
  listContainer: {
    padding: "1em",
    border: "1px solid #DCE1E6",
    borderRadius: 4,
  },

  instructions: {
    color: black,
    padding: "0.5em 0 0em 0",
    fontWeight: 400,
    fontSize: "1.1em",
  },
  contentContainer: {
    padding: "1em 0",
  },
  card: {
    maxHeight: "40%",
  },
  bigCard: {
    maxHeight: "70%",
  },
  definitionContainer: {
    padding: "1em 1em",
  },
  definitionContainerBorder: {
    padding: "1em 1em",
    border: "1px solid #DCE1E6",
  },
  iconContainer: {
    cursor: "pointer",
  },
  iconType: {
    width: 35,
  },
  deleteIcon: {
    borderRadius: 2,
    border: "1px solid #DCE1E6",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: ''
  },
});

export default styles;
