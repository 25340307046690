import React from "react";
import { FulfillmentListSkeleton } from "../ListSkeletons";
import TicketingDefItem from "./Item";
import { PassDefinition } from "../../utils/types";
import { useTranslation } from "react-i18next";
import { PaginationRavent } from "../Pagination/Pagination";
import styles from "./styles";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { List } from "../List";

interface Props {
  error: string;
  loading: boolean;
  data: PassDefinition[];
  page: number;
  total: number;
  hitsPerPage: number;
  onPageChange?: (page: number) => void;
  onHitsPerPageChange?: (hitsPerPage: number) => void;
  sortable: boolean;
  pagination?: boolean;
  loadingEditT: string;
  onEditTicketingDef: (data: PassDefinition) => void;
  onDeleteTicketing: (id: string) => void;
  open: boolean;
  setOpen: (value: boolean) => void;
  path: string;
}

const TicketingDefList: React.FC<Props> = ({
  error,
  loading,
  data,
  pagination,
  page,
  total,
  hitsPerPage,
  onHitsPerPageChange,
  onPageChange,
  onDeleteTicketing,
  onEditTicketingDef,
  loadingEditT,
  open,
  setOpen,
  path,
}) => {
  const { t } = useTranslation();

  const header = (
    <div>
      <ListHeader template="1fr 1.5fr 0.2fr">
        <ListHeaderLabel
          value={t("component.ticketingDefList.name")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.ticketingDefList.description")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel value={""} justifyContent="center" />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <div className={styles.listContainer}>
        <List
          loading={loading}
          loadingElement={() => <FulfillmentListSkeleton />}
          error={error}
          data={data}
          keyExtractor={(item) => item.id}
          renderHeader={() => header}
          renderItem={(item) => (
            <TicketingDefItem
              path={path}
              key={item.id}
              onDeleteTicketing={onDeleteTicketing}
              onEditTicketingDef={onEditTicketingDef}
              ticketingDef={item}
              id={item.id}
              open={open}
              setOpen={setOpen}
              loadingEditT={loadingEditT}
            />
          )}
        />
      </div>

      {pagination && data.length >= 5 ? (
        <div className={styles.paginationContainer}>
          <PaginationRavent
            current={page + 1}
            defaultCurrent={page + 1}
            total={total}
            showSizeChanger
            onShowSizeChange={(page, size) => {
              if (onHitsPerPageChange) {
                onHitsPerPageChange(size);
              }
            }}
            onChange={(page) => {
              if (onPageChange) {
                onPageChange(page - 1);
              }
            }}
            pageSize={hitsPerPage}
          />
        </div>
      ) : null}
    </div>
  );
};

export default TicketingDefList;
