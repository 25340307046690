import React from "react";

import styles from "./styles";
import { Location } from "@innomius/ravent-typescript-types";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputText } from "../Inputs/InputText";
import { InputCheckbox } from "../Inputs/InputCheckbox";

interface Props {
  data: Location;
  onChange: <P extends keyof Location>(prop: P, value: Location[P]) => void;
  edit: boolean;
  error: string;
}

const LocationCheckinForm: React.FC<Props> = ({ data, onChange, error }) => {
  return (
    <div className={styles.containerDetailsCheckin}>
      <FlexContainer padding="1em 0 1.5em 0" justifyContent="space-between">
        <ContainerTitle size="xsmall" label="Check-In" />
      </FlexContainer>
      <div className={styles.instructions}>
        Enabling the check-in feature allows you to communicate with customers
        during their visit period.
      </div>
      <>
        <GridContainer
          gap="1em"
          padding="2em 0 0em 0"
          columns="1fr 0.7fr 0.7fr"
          justifyContent="flex-start"
        >
          <InputText
            value={data.checkinFlowUrl}
            label="Checkin Flow URL"
            onChange={(value) => onChange("checkinFlowUrl", value)}
          />
          <InputText
            containerClassname={styles.inputContainer}
            label="Check-In Period (min)"
            value={data.checkinPeriodinMinutes}
            onChange={(value) => onChange("checkinPeriodinMinutes", value)}
            error={error === "checkin" ? "This is value is mandatory" : ""}
          />
        </GridContainer>
        <InputCheckbox
          value={data.publicCheckin}
          text="Public Check-in"
          onChange={(value) => onChange("publicCheckin", value)}
        />
      </>
    </div>
  );
};

export default LocationCheckinForm;
