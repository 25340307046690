const translation = {
  en: {
    paymentStates: "Payment States",
    statesDef: "States",
    cancel: "Cancel",
    save: "Save",
    fulfillment: "Fulfillment",
    venue: "Venue",
    success: "State was successfuly added"
  },
  es: {
    statesDef: "Estados",
    cancel: "Cancelar",
    save: "Guardar",
    fulfillment: "Entrega",
    venue: "Recinto",
    success: "Estado se agrego éxitosamente"

  },

  de: {},
};

export default translation;
