import React from "react";

import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import TicketingDefForm from "../PassDefForm";
import { PassDefinition } from "../../utils/types";
import { SideMenu } from "../SideMenu/SideMenu";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ButtonsContainer } from "../ButtonsContainer";
import { EditableTitle } from "../EditableTitle";
import { Button } from "../Button";

interface Props {
  ticketingDef: PassDefinition;
  onChangeTicketingDef: <P extends keyof PassDefinition>(
    prop: P,
    value: PassDefinition[P]
  ) => void;
  path: string;
  onCreateTicketingDef: () => void;
  open: boolean;
  onClose: () => void;
  loadingT: boolean;
}

const CreateTicketingDef: React.FC<Props> = ({
  ticketingDef,
  onChangeTicketingDef,
  path,
  onCreateTicketingDef,
  open,
  onClose,
  loadingT,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <SideMenu
      onClose={() => {
        history.push(`${path}`);
        onClose();
      }}
      open={open}
    >
      <FlexContainer
        padding="1em 0 2em 0"
        justifyContent="space-between"
        alignItems="center"
      >
        <EditableTitle
          value={ticketingDef.name}
          placeholder={t(
            "container.createTicketingDef.ticketingDefNamePlaceholder"
          )}
          onChange={(value) => onChangeTicketingDef("name", value)}
        />
        <ButtonsContainer>
          <Button
            theme="white"
            label={t("container.createTicketingDef.cancel")}
            onClick={() => {
              onClose();
              history.push(`${path}`);
            }}
          />
          <Button
            label={loadingT ? "..." : t("container.createTicketingDef.save")}
            onClick={() => onCreateTicketingDef()}
          />
        </ButtonsContainer>
      </FlexContainer>

      <TicketingDefForm data={ticketingDef} onChange={onChangeTicketingDef} />
    </SideMenu>
  );
};

export default CreateTicketingDef;
