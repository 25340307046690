import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { Button } from "../../components/Button";
import { ContainerTitle } from "../../components/ContainerTitle";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { offerSetsList } from "../../store/offersets";
import OfferListComponent from "../../components/OfferSetListComponent";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import Search from "antd/es/input/Search";
import { EmptyList } from "../../components/List/EmptyList";

interface Props extends RouteComponentProps {}

const OfferSets: React.FC<Props> = ({ history }) => {
  const dispatch = useDispatch();
  const offerSets = useSelector((state: RootState) => state.offerSets);
  const [hitsPerPage, setHitsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const { t } = useTranslation();

  const load = useCallback(async () => {
    dispatch(offerSetsList());
  }, [dispatch]);

  useEffect(() => {
    load();
  }, [load]);
  return (
    <LayouWithSideBar>
      <div className={styles.container}>
        <FlexContainer justifyContent="space-between">
          <ContainerTitle
            size="small"
            label={t("container.offers.offers")}
            count={offerSets.list.length.toString()}
          />
          <Button
            onClick={() => history.push("/offersets/new")}
            label={t("container.offers.newOffer")}
          />
        </FlexContainer>

        <div className={styles.searchContainer}>
          <Search
            style={{ marginBottom: "1em" }}
            onSearch={() => {}}
            placeholder={t("container.offers.search")}
          />
        </div>

        {offerSets.list.length ? (
          <div>
            <OfferListComponent
              loading={offerSets.loading}
              error={offerSets.error}
              data={offerSets.list}
              page={page}
              total={offerSets.list.length}
              hitsPerPage={hitsPerPage}
              onPageChange={setPage}
              onHitsPerPageChange={setHitsPerPage}
              pagination
              sortable
            />
          </div>
        ) : (
          <div className={styles.container}>
            <EmptyList
              instructions={t("container.offers.instructions")}
              text={t("container.offers.addOfferList")}
              buttonText={t("container.offers.newOffer")}
              onNewClick={() => {}}
            />
          </div>
        )}
      </div>
    </LayouWithSideBar>
  );
};

export default OfferSets;
