const translation = {
  en: {
    goBack: "Go Back",
    updateDiveShop: "Update Team",
    notificationDiveShop:
      "A notification will be send to the selected Team with the order assignment.",
    diveShops: "Teams",
    status: "Status",
    timestamp: "Timestamp",
    locationName: "Location Name",
    related: "Related Events",
    user: "User",
    name: "Name",
    diveshop: "Team",
    setDiveshop: "Set Fulfiller",
    fulfilledTimestamp: "Fulfilled Timestamp",
    currentState: "Current State",
    selectVenue: "Select Venue",
    search: "Search",
    selectLocation: "Select Event",
    cancel: "Cancel",
    save: "Save",
    noLocation: "This venue doesn't have events.",
    setLocation: "+ Add Event",
    setExistingLocation: "Set Exisiting Event",
    createNewLocation: "Create New Event",
    location: "Event",
    venueName: "Venue Name",
    locationType: "Type",
    w3w: "What Three Words",
    postalAddress: "Postal Address",
    wellKnownAddress: "Well Known Address",
    notes: "Notes",
    addressLine1: "Address",
    addressLine2: "Address Line 2",
    city: "City",
    state: "State",
    zipCode: "Zip Code",
    country: "Country",
    reference: "Reference",
    action: "Action",
    deleteMessage: "Are you sure you want to delete",
    setStatus: "set state",
    fulfillmentStatus: "Fulfillment State",
    activating: "You are activating",
    on: "on",
    fromLocation: "from location:",
    geoLocationNotSupported: "Geolocation is not supported by your browser",
    states: "States",
    fulfilled: "Fulfilled",
    clickOnActivate: "Click on a state to activate following",
    setFulfillment: "Set Definition",
    notAssigned: "Not Assigned",
    assign: "Set",
    noLocationAdded: "No Location added",
    disabled: "Disabled",
    orderLine: "Order Line",
    fulfillmentDefinition: "Definition",
    setDefinition: "Select a Fulfillment Definition for this Orderline.",
    updateDefinition: "Update Definition",
    tags: "Tags",
    date: "Date/Time",
    noFulfillment: "The list is empty until you select  a definition",
    noAssignedDefinition:
      "No Fulfillment Definition is assigned for this orderline.",

    history: "States Historic",
    locations: "Events",
    noStateActivated:
      "The States historic list will be empty until you start activating states",
    noStateConfigure: "No State was configured",
    empty: "Empty List",
    instructionsLocations: "Add new location for your fulfillment.",
    locationsNotEnabled: "Events are not enabled for this orderline. ",

    noLocations: "No Events added",
    type: "Type",
    placement: "Placement",
    eventDetails: "Event Details",
    placementDetails: "Placements",
    addPlacement: "+ Add Placement",
    placements: "Placements",
    coordinates: "Coordinates",
    edit: "Edit",
    details: "Details",
    end: "Finish Date/Time",
    delete: "Delete",
    createEvent: "Create Event",
    addEnd: "+ Add finish date/ time",
    deleteEnd: "- Delete finish date/ time",
    address: "Address",
    id: "Order ID",
    people: "People",
    asset: "Asset",
    addEdit: "Edit",
    noTeamAssigned: "You don't have any team assigned to this event",
    noAssetAssigned: "You don't have any assset assigned to this event",
    remove: "Remove",
    team: "Team",
    set: "Set",
    noFulfillerAssigned: "You don't have any fulfiller assigned to this event",
    fulfiller: "Fulfiller",
    externalId: "External Id",
    fulfillEvent: "Event Fulfilled",
    noAddress: "No Address defined",
    saveAddress: "Save this address in locations list",
    view: "View Map",
    noPlacements: "No Placements Added",
    noTagsAssigned: "No Tags Added",
    eventFulfilled: "Event Fulfilled",
    directions: "Directions",
    map: "Map",
    newLocation: "New Location",
    existing: "Existing Location",
    locationLabel: "Location",
    venue: "Venue",
    considerTime: "Consider Time",
    event: "Event",
    notTags: "You don't have any tags for this event",
    load: "Load",
  },
  es: {
    event: "Evento",
    noTags: "No tienes etiquetas para este evento",
    load: "Carga",
    venue: "Recinto",
    considerTime: "Considerar Tiempo",
    related: "Eventos Relacionados",
    locationLabel: "Ubicación",
    newLocation: "Nueva Ubicación",
    existing: "Ubicación Existente",
    map: "Mapa",
    directions: "Ruta",
    eventFulfilled: "Cumplimiento del Evento",
    noTagsAssigned: "No se han agregado etiquetas",
    noPlacements: "No hay Ubicaciones agregadas",
    view: "Ver mapa",
    saveAddress: "Salvar dirección en locations",
    noAddress: "No se definió dirección",
    fulfillEvent: "Completar Evento",
    externalId: "ID Externo",
    fulfiller: "Fulfiller",
    noFulfillerAssigned: "No tiene ningún fulfiller asignado",
    set: "Asignar",
    team: "Equipo",
    remove: "Eliminar",
    noTeamAssigned: "No tienes ningún equipo asignado a este evento",
    noAssetAssigned: "No tienes ningún activo asignado a esta evento",
    addEdit: "Editar",
    asset: "Activo",
    people: "Personas",
    id: "Order ID",
    address: "Dirección",
    deleteEnd: "- Eliminar fecha/hora de terminación",
    addEnd: "+ Agregar fecha/hora de terminación",
    createEvent: "Crear Evento",
    delete: "Eliminar",
    details: "Detalles",
    edit: "Editar",
    end: "Fecha/Hora de Finalización",
    coordinates: "Coordenadas",
    placements: "Ubicaciones",
    placement: "Ubicación",
    tags: "Tags",
    link: "Link de la Definición",
    date: "Fecha/Hora",
    eventDetails: "Detalles del Evento",
    placementDetails: "Lugares",
    addPlacement: "+ Agregar un Lugar",
    setTeam: "Seleccionar Equipo",
    selectState: "Selecciona un estado para activarlo",
    select: "Activar Estado",
    goBack: "Regresar",
    updateDiveShop: "Actualizar Team",
    noLocations: "No se han agregado Eventos",
    notificationDiveShop:
      "Se enviará una notificación al Team seleccionado con la asignación del pedido.",
    diveShops: "Team",
    status: "Estados",
    timestamp: "Marca de tiempo",
    locationName: "Nombre del Evento",
    user: "Usuario",
    name: "Nombre",
    diveshop: "Team",
    setDiveshop: "Establecer Team",
    fulfilledTimestamp: "Marca de tiempo del Entrega",
    currentState: "Estado Actual",
    selectVenue: "Seleccionar lugar",
    search: "Buscar",
    selectLocation: "Seleccionar Evento",
    cancel: "Cancelar",
    save: "Guardar",
    noLocation: "Este lugar no tiene eventos.",
    setLocation: "+ Agregar Evento",
    setExistingLocation: "Establecer evento",
    createNewLocation: "Crear nuevo evento",
    location: "Evento",
    venueName: "Nombre del lugar",
    locationType: "Tipo",
    w3w: "What Three Words",
    postalAddress: "Dirección Postal",
    wellKnownAddress: "Dirección conocida",
    notes: "Notas",
    addressLine1: "Línea de dirección 1",
    addressLine2: "Línea de dirección 2",
    city: "Ciudad",
    state: "Estado",
    zipCode: "Código Postal",
    country: "País",
    reference: "Referencia",
    action: "Acción",
    deleteMessage: "¿Está seguro de que desea eliminar?",
    setStatus: "Establecer Estado",
    fulfillmentStatus: "Estado del cumplimento",
    activating: "Está activando",
    on: "a",
    fromLocation: "del evento:",
    geoLocationNotSupported:
      "La geolocalización no es compatible con su navegador",
    states: "Estados",
    fulfilled: "Cumplido",
    clickOnActivate: "Haga clic en un estado para activar el siguiente",
    setFulfillment: "Establecer Entrega",
    notAssigned: "No asignado",
    assign: "Asignar",
    noLocationAdded: "Ninguna evento agregado",

    orderLine: "Línea de orden",

    empty: "Lista vacía",
    instructionsLocations:
      "Agregue eventos existentes o cree un evento para su entrega.",
    locationsNotEnabled:
      "Los eventos no están habilitadas para esta línea de orden ",
    ticketing: "Boletos",
    type: "Tipo",
  },
  de: {},
};

export default translation;
