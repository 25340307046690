import React from "react";
import styles from "./styles.module.css";
import Text from "../Text";
import { numberFormatNoDecimals } from "../../utils/format";
import { GridContainer } from "../GridContainer/GridContainer";

interface Props {
  range: {
    from: number;
    to: number;
  }[];
  content: string;
}

const Range: React.FC<Props> = ({ range, content }) => {
  const colors = [
    "#E2F3FF",
    "#C7E7FF",
    "#9AD4FF",
    "#79C6FF",
    "#4CB3FF",
    "#1FA0FF",
    "#1A88D9",
    "#136099",
    "#0C4066",
    "#09304D",
  ];

  const getColorsInRange = () => {
    const colorsInRange = [];

    for (let i = 0; i < range.length; i++) {
      const colorIndex = Math.min(i, colors.length - 1);
      colorsInRange.push(colors[colorIndex]);
    }

    return colorsInRange;
  };

  const colorsInRange = getColorsInRange();

  const gridColumns: string[] = range.reduce<string[]>((acc, _, index) => {
    acc.push("1fr", "1px");

    return acc;
  }, []);

  const secondGridColumns = gridColumns
    .filter((_, index) => index % 2 === 0)
    .join(" ");

  return (
    <div>
      <Text padding="0 0 0.5em 0" fontWeight={700} text={`${content}`} />
      <GridContainer columns={gridColumns.join(" ")}>
        {range.map((_rangeItem, index) => (
          <React.Fragment key={index}>
            <div
              className={styles.box}
              style={{ background: colorsInRange[index] }}
            />
            <div className={styles.line} />
          </React.Fragment>
        ))}
      </GridContainer>

      <GridContainer columns={secondGridColumns} justifyContent="center">
        {range.map((_, index) => (
          <div
            style={{
              marginLeft: range.length === 1 ? "95%" : "55%",
            }}
            key={index}
          >
            <Text text={`${numberFormatNoDecimals(range[index].to)}`} />
          </div>
        ))}
      </GridContainer>
    </div>
  );
};

export default Range;
