import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./styles";

import { ListRow } from "../List/ListRow";
import { Role } from "../../services/roles";
import editBlack from "../../assets/editBlack.svg";
import deleteBlack from "../../assets/deletBlack.svg";
import { ListLabel } from "../List/ListLabel";

interface OrdersListProps {
  data: Role;
  deleteRol: (id: string) => void;
}

const Item: React.FC<OrdersListProps> = ({ data, deleteRol }) => {
  const history = useHistory();

  return (
    <ListRow
      boxShadow
      borderRadius="6px"
      onClick={() => {
        history.push(`/organization/roles/details/${data.id}`);
        document.title = "RAVENT APP :: Org :: Role Details";
      }}
      template="1.5fr 1fr 1.5fr 1fr"
      hover={true}
      key={data.id}
    >
      <ListLabel value={data.name} textAlign="left" />
      <ListLabel value={data.scope} textAlign="left" />
      <ListLabel value={data.description} textAlign="left" />

      <div className={styles.center}>
        <img
          src={editBlack}
          alt="edit"
          className={styles.icon}
          onClick={() => history.push(`/organization/roles/${data.id}`)}
        />
        <img
          src={deleteBlack}
          alt="deelete"
          className={styles.icon}
          onClick={() => deleteRol(data.id)}
        />
      </div>
    </ListRow>
  );
};

export default Item;
