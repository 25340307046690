const translation = {
  en: {
    successUpdated: "Profile successfully updated.",
    backToSettings: "Back to Payments",
    cancel: "Cancel",
    save: "Save",
    editInfo: "Edit Information",
    editProfile: "Edit Profile",
    changePassword: "Change Password",
    profile: "Profile",
    personalInfo: "Personal Information",
    name: "Name",
    lastName: "Last Name",
    email: "Email",
    phone: "Phone Number",
    password: "Password",
    venue: "Venue",
    currentPassword: "Current Password",
    successPassword: "Password succesfully updated.",
    differentPassword: "New password differs from confirmed password.",
    org: "Operator",
    userPreferences: "User Preferences",
    details: "Details",
    edit: "Edit",
    successUpdatedOrg: "Org successfully updated",
    successUpdateSettings: "Settings successfully updated.",
  },
  es: {
    successUpdateSettings: "Configuración actualizada con éxito.",
    successUpdatedOrg: "Org actualizada con éxito",

    successUpdated: "Perfil actualizado con éxito.",
    backToSettings: "Volver a Pagos",
    cancel: "Cancelar",
    save: "Guardar",
    venue: "Venue",
    edit: "Editar",
    editInfo: "Editar Información",
    editProfile: "Editar Perfil",
    changePassword: "Cambiar Contraseña",
    profile: "Perfil",
    personalInfo: "Información Personal",
    name: "Nombre",
    lastName: "Apellido",
    email: "Correo Electrónico",
    phone: "Número de teléfono",
    password: "Contraseña",
    currentPassword: "Contraseña actual",
    successPassword: "Contraseña actualizada con éxito.",
    differentPassword:
      "La nueva contraseña difiere de la contraseña confirmada",
    org: "Operadora",
    userPreferences: "Preferencias de Usuario",
    details: "Detalles",
  },
  de: {},
};

export default translation;
