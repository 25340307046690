const translation = {
  en: {
    firstNameMandatory: "Mandatory",
    firstNameDescription: "The first name of the user. Example: John, Maria.",
    tagsMandatory: "(Optional)",
    tagsDescription: "Comma-separated tags for the user. Example: tag1, tag2.",
    lastNameMandatory: "(Optional)",
    lastNameDescription: "The last name of the user. Example: Doe, Smith.",
    rolesMandatory: "Mandatory",
    rolesDescription: "Roles assigned to the user. Example: admin, editor.",
    enabledMandatory: "Mandatory",
    enabledDescription: "Whether the user is enabled. Example: true, false.",
    externalIdMandatory: "(Optional)",
    externalIdDescription:
      "An external identifier for the user. Example: 12345.",
    adminMandatory: "Mandatory",
    adminDescription:
      "Whether the user has admin privileges. Example: true, false.",
    fulfillmentTeamIdMandatory: "(Optional)",
    fulfillmentTeamIdDescription:
      "The ID of the fulfillment team the user belongs to.",
    loginWithPhoneMandatory:
      "(Mandatory if user will login using a phone number otherwise optional)",
    loginWithPhoneDescription:
      "Whether the user logs in with their phone. Example: true, false.",
    typeMandatory: "Mandatory",
    typeDescription: "The type of the user. Example: fulfiller.",
    divers: "Fulfillers",
    users: "Users",
    addFulfiller: "Create Fulfiller",
    addUser: "+ Add User",
    empty: "Empty List",
    noRegister: "You don't have any Users registered.",
    addMultipleDivers: "Import from CSV",
    drag: "Drag a File here",
    or: "or",
    selectFile: "Select File",
    close: "Close",
    import: "Import",
    search: "Search",
    preview: "Preview CSV (first 3 rows)",
    addExistingUser: "Exisiting User",
    exisitingUsers: "Existing Users",
    name: "Name",
    role: "Role",
    add: "Add",
    showMore: "Show More",
    email: "Email/Phone",
    cancel: "Cancel",
    type: "Type",
    columnHeaders: "Column Headers",
    firstInstruction: "1.	First Line Must Contain Headers",
    seeLess: "See Less",
    firstContent:
      "Ensure that the first line of the CSV contains the exact field headers as described below. These headers are case-sensitive and should not have additional spaces or variations.",
    instructionsTitle: "Instructions for CSV File Upload",
    ensure:
      "Ensure your CSV file includes the required fields (firstName, lastName, email, and roles) for successful processing. Optional fields can be left blank if not applicable. ",
  },
  es: {
    search: "Buscar",
    seeLess: "Ver Menos",
    firstNameMandatory: "Obligatorio",
    firstNameDescription: "El nombre del usuario. Ejemplo: John, María.",
    tagsMandatory: "(Opcional)",
    tagsDescription:
      "Etiquetas separadas por comas para el usuario. Ejemplo: tag1, tag2.",
    lastNameMandatory: "(Opcional)",
    lastNameDescription: "El apellido del usuario. Ejemplo: Doe, Smith.",
    rolesMandatory: "Obligatorio",
    rolesDescription: "Roles asignados al usuario. Ejemplo: admin, editor.",
    enabledMandatory: "Obligatorio",
    enabledDescription: "Si el usuario está habilitado. Ejemplo: true, false.",
    externalIdMandatory: "(Opcional)",
    externalIdDescription:
      "Un identificador externo para el usuario. Ejemplo: 12345.",
    adminMandatory: "Obligatorio",
    adminDescription:
      "Si el usuario tiene privilegios de administrador. Ejemplo: true, false.",
    fulfillmentTeamIdMandatory: "(Opcional)",
    fulfillmentTeamIdDescription:
      "El ID del equipo de cumplimiento al que pertenece el usuario.",
    loginWithPhoneMandatory:
      "(Obligatorio si el usuario iniciará sesión con un número de teléfono, de lo contrario opcional)",
    loginWithPhoneDescription:
      "Si el usuario inicia sesión con su teléfono. Ejemplo: true, false.",
    typeMandatory: "Obligatorio",
    typeDescription: "El tipo de usuario. Ejemplo: fulfiller.",
    columnHeaders: "Encabezados de Columna",
    showMore: "Ver Más",
    firstContent:
      "Asegúrate de que la primera línea del CSV contenga los encabezados de campo exactos como se describe a continuación. Estos encabezados distinguen entre mayúsculas y minúsculas y no deben tener espacios adicionales o variaciones.",
    firstInstruction: "1.	La primera línea debe contener los encabezados",
    instructionsTitle: "Instrucciones para la carga de archivos CSV",
    ensure:
      "Asegúrate de que tu archivo CSV incluya los campos requeridos (nombre, apellido, correo electrónico y roles) para un procesamiento exitoso. Los campos opcionales pueden dejarse en blanco si no son aplicables.",
    type: "Tipo",
    divers: "Fulfillers",
    email: "Email/Teléfono",
    cancel: "Cancelar",
    users: "Usuarios",
    addFulfiller: "Crear Fulfiller",
    addUser: "+ Agregar Usuario",
    empty: "Lista vacía",
    noRegister: "No tienes ningun usuario registrada.",
    addMultipleDivers: "Importar de CSV",
    drag: "Arrastrar Archivo",
    or: "o",
    selectFile: "Seleccionar Archivo",
    close: "Cerrar",
    import: "Importar",
    preview: "Vista previa CSV (primeras 3 filas)",
    addExistingUser: "Usuario Existente",
    exisitingUsers: "Usuarios Existentes",
    name: "Nombre",
    role: "Rol",
    add: "Agregar",
  },
  de: {},
};

export default translation;
