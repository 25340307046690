import React, { useState } from "react";
import { Location, Message, Visitor } from "@innomius/ravent-typescript-types";

import VisitorList from "../VisitorsList";
import visitorIcon from "../../assets/icon-set-visitors.svg";
import noVisitorsIcon from "../../assets/graphics-no-visitors.svg";

import styles from "./styles";
import { EmptyList } from "../List/EmptyList";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import Search from "antd/es/input/Search";

interface Props {
  data: Location;
  messages: Message[];
  visitors: Visitor[];
  path?: string;
}

const LocationVisitors: React.FC<Props> = ({
  data,
  path,
  messages,
  visitors,
}) => {
  const [hitsPerPage, setHitsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  return (
    <div>
      {!data.currentVisitors ? (
        <EmptyList
          image={noVisitorsIcon}
          instructions=""
          text="No visitors checked in."
        />
      ) : (
        <>
          <FlexContainer alignItems="center" justifyContent="space-between">
            <ContainerTitle size="xsmall" label="Total Visitors Checked-In" />
            <FlexContainer>
              <img src={visitorIcon} className={styles.visitorsIcon} alt="" />
              <p className={styles.numberOfVisitors}>
                {data.currentVisitors.toString()}
              </p>
            </FlexContainer>
          </FlexContainer>
          <div className={styles.inputSearchContainer}>
            <Search
              style={{ marginBottom: "1em" }}
              onSearch={() => {}}
              placeholder="Search"
            />
          </div>
          <VisitorList
            officialMedia={data.officialMedia}
            messages={messages}
            data={visitors}
            error=""
            loading={false}
            sortable={false}
            path={`${path}/${data.id}/visitors`}
            page={page}
            total={visitors.length}
            hitsPerPage={hitsPerPage}
            onPageChange={setPage}
            onHitsPerPageChange={setHitsPerPage}
            pagination
          />
        </>
      )}
    </div>
  );
};

export default LocationVisitors;
