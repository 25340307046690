import React from "react";
import { classes, style } from "typestyle";
import { black, clearBlue, darkBlack } from "../../utils/colors";
import { GridContainer } from "../GridContainer/GridContainer";
import { FlexContainer } from "../FlexContainer/FlexContainer";

interface Props {
  label: string;
  value?: string;
  image?: string;
  mandatory?: boolean;
  noCaps?: boolean;
  size?: "xsmall";
  multipleValues?: string[];
  component?: React.ReactChild;
  buttonComponent?: React.ReactChild;
}

const labelStyle = style({
  color: darkBlack,
  fontWeight: "bold",
  fontSize: "1.4em",
});

const valueStyle = style({
  color: black,
  fontWeight: 400,
  fontSize: "1.1em",
  paddingTop: "0.2em",
});

const xsmallValue = style({
  fontSize: "1em",
});

const valueNoCaps = style({
  color: black,
  fontWeight: 400,
  fontSize: "1.1em",
  paddingTop: "0.2em",
  textTransform: "none",
});

const star = style({
  color: clearBlue,
  fontWeight: "bold",
  fontSize: "1.2em",
  paddingTop: "0.2em",
});

const icon = style({
  fontSize: "1.1em",
  fontWeight: 500,
  color: darkBlack,
  width: 26,
  height: 26,
});

const imageContainer = style({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "1em",
});

const OrderTitle: React.FC<Props> = ({
  noCaps,
  image,
  label,
  value,
  mandatory,
  size,
  multipleValues,
  component,
  buttonComponent,
}) => {
  return (
    <GridContainer alignItems="center" columns={image ? "2em 2fr" : "3fr"}>
      {image ? (
        <div className={imageContainer}>
          <img src={image} alt="" className={icon} />
        </div>
      ) : null}
      <FlexContainer justifyContent="space-between">
        <FlexContainer justifyContent="flex-start" alignItems="center">
          <p
            className={classes(labelStyle, {
              [xsmallValue]: size === "xsmall",
            })}
          >
            {label}
          </p>
          {mandatory ? <p className={star}>*</p> : null}
        </FlexContainer>
        {buttonComponent}
      </FlexContainer>

      <div />
      <div>
        <p
          className={classes(valueStyle, {
            [valueNoCaps]: noCaps,
            [xsmallValue]: size === "xsmall",
          })}
        >
          {value}
        </p>
        {multipleValues?.length
          ? multipleValues.map((item, index) => (
              <p
                key={index}
                className={classes(valueStyle, {
                  [valueNoCaps]: noCaps,
                  [xsmallValue]: size === "xsmall",
                })}
              >
                {item}
              </p>
            ))
          : null}
        {component ? (
          <>
            <div />
            {component}
          </>
        ) : null}
      </div>
    </GridContainer>
  );
};

export default OrderTitle;
