const translation = {
  en: {
    events: "Order Events",
    eventTriggers: "Event Triggers",
    paymentEvents: "Payment Events",
    actionName: "Action Name",
    explanation:
      "Event states are pre-defined by Ravent and may not be changed by customers",
    url: "Event states are pre-defined by Ravent and may not be changed by customers, the URL allows organizations to configure the URL of a web-hook that Ravent will send notifications to. Every time an event happens, a call to the web-hook will be generated and your platform will be notified.",
  },
  es: {
    eventTriggers: "Desencadenadores de Eventos",
    events: "Eventos de Orden",
    paymentEvents: "Eventos de Pago",
    actionName: "Nombre de la Acción",
    explanation:
      "Los estados de eventos están predefinidos por Ravent y los clientes no pueden cambiarlos",
    url: "Los estados de los eventos están predefinidos por Ravent y los clientes no pueden cambiarlos. La URL permite a las organizaciones configurar la URL de un enlace web al que Ravent enviará notificaciones. Cada vez que suceda un evento, se generará una llamada al web hook y se notificará a tu plataforma.",
  },
  de: {},
};

export default translation;
