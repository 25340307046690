const translation = {
  en: {
    error: "Error",
    backToOffers: "Back to Offer Sets",
    cancel: "Cancel",
    delete: "Delete",
    save: "Save",
    from: "From",
    offerDetails: "Offer Set Details",
    offers: "Offers",
    edit: "Edit Offer Set",
    active: "Active",
    inactive: "Inactive",
  },
  es: {
    error: "Error",
    backToOffers: "Volver a conjuntos de ofertas",
    cancel: "Cancelar",
    delete: "Eliminar",
    save: "Guardar",
    from: "De",
    offerDetails: "Detalles del conjunto de ofertas",
    offers: "Ofertas",
    edit: "Editar conjunto de ofertas",
    active: "Activo",
    inactive: "Inactivo",
  },
  de: {},
};

export default translation;
