import { AdvancedFilters } from "../utils/types";
import fetch from "../utils/fetch";
import { getAccessToken } from "./auth";

export function parseAdvancedFilters(data: unknown): AdvancedFilters {
  if (typeof data !== "object" || data === null) {
    throw new Error("Invalid data format");
  }

  const filters: AdvancedFilters = {};
  Object.keys(data).forEach((key) => {
    const filter = (data as Record<string, any>)[key];
    filters[key] = {
      name: key,
      fieldToSearchOn: filter.fieldToSearchOn,
      type: filter.type,
      options: filter.options || [],
    };
  });

  return filters;
}

export async function getAdvancedFilters(): Promise<AdvancedFilters> {
  const token = await getAccessToken();
  const res = await fetch("/events/advancedFilters", token, "GET");
  return parseAdvancedFilters(res.body);
}
