const translation = {
    en: {
      error: "Error",
      backToOffers: "Back to Offer Sets",
      cancel: "Cancel",
      delete: "Delete",
      save: "Save",
      from: "From",
      offerDetails: "Offer Set Details",
      offers: "Offers",
      edit: "Edit Offer Set",
      active: "Active",
      inactive: "Inactive"
    },
    es: {},
    de: {},
  };
  
  export default translation;
  