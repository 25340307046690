const translation = {
  en: {
    orgsLabel: "Teams",
    createOrg: "+ Create Team",
    search: "Search",
    instructions: "Start creating Teams",
    addOrg: "+ Add Team",
  },
  es: {
    orgsLabel: "Equipos de Entrega",
    createOrg: "+ Crear Equipo de Entrega",
    search: "Buscar",
    instructions: "Empiece a crear Equipos de Entrega",
    addOrg: "+ Agregar Equipo",
  },
  de: {
    orgsLabel: "Teams",
    createOrg: "+ Create Team",
    search: "Search",
    instructions: "Start creating Teams",
    addOrg: "+ Add Team",
  },
};

export default translation;
