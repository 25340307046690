import {
  RefundEvent,
  Refund,
  CashRefund,
  CardRefund,
} from "@innomius/ravent-typescript-types";
import React, { useState } from "react";
import { formatDate } from "../../utils/times";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { linkBlue } from "../../utils/colors";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { GridContainer } from "../GridContainer/GridContainer";
import { FlexContainer } from "../FlexContainer/FlexContainer";

interface Props {
  data: Refund;
  history?: RefundEvent[];
  onClose: () => void;
}

const RefundDetail: React.FC<Props> = ({ data, history, onClose }) => {
  const [open, setOpen] = useState(false);

  const cashRefund = data as CashRefund;
  const cardRefund = data as CardRefund;

  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.containerDetail}>
        <div className={styles.commentsContainer}>
          <div className={styles.bold}>
            {t("component.refundDetail.reasonForRefund")}
          </div>
          <div>{data.reason}</div>
        </div>
        <div className={styles.bold}>{t("component.refundDetail.refund")}</div>
        <GridContainer
          gap="1em"
          columns="1fr 2fr 3fr"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <GridContainer columns="1">
            <div className={styles.bold}>
              {t("component.refundDetail.paymentId")}
            </div>
            <div className={styles.bold}>
              {t("component.refundDetail.paymentType")}
            </div>
            <div className={styles.bold}>
              {t("component.refundDetail.paymentDetails")}
            </div>
            <div className={styles.bold}>
              {t("component.refundDetail.amount")}
            </div>
          </GridContainer>
          <GridContainer columns="1fr">
            <div className={styles.big}>{data.shortPaymentId}</div>
            <div>{data.type}</div>
            <div>
              {data.type === "card"
                ? `${cardRefund.cardBrand} ****${cardRefund.lastFourDigits}`
                : null}
            </div>
            <div>{data.type === "wire-transfer" ? "Bank Account" : null}</div>
            <div>
              {data.type === "cash" ? `${cashRefund.employeeId}` : null}
            </div>
            <div>
              {data.amount.currency} {data.amount.value}
            </div>
          </GridContainer>
          {history ? (
            <div>
              <div className={styles.bold}>
                {t("component.refundDetail.sent")}
              </div>
              {history?.map((item) => (
                <FlexContainer>
                  {item?.email || item?.phone}
                  {formatDate(item?.timestamp, "MIDDLE")}
                </FlexContainer>
              ))}
            </div>
          ) : null}
        </GridContainer>
      </div>
      <div
        className={styles.closeButton}
        onClick={() => {
          setOpen(!open);
          onClose();
        }}
      >
        {t("component.refundDetail.close")}
        <FontAwesomeIcon
          icon={faChevronUp as IconProp}
          className={styles.chevron}
          color={linkBlue}
        />
      </div>
    </div>
  );
};

export default RefundDetail;
