
import React from "react";
import Text from "../Text";
import deleteIcon from "../../assets/warning.svg";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { Modal } from "../Modal/Modal";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { InputError } from "../Inputs/InputError/InputError";

interface Props {
  onClose: () => void;
  open: boolean;
  closeOrder: () => void;
  loading?: boolean;
  error?: string;
}

const CloseOrderModal: React.FC<Props> = ({
  onClose,
  closeOrder,
  open,
  error,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Modal
        width="600px"
        buttons={
          <div>
            <ButtonsContainer justifyContent="flex-end">
              <Button
                onClick={onClose}
                theme="white"
                label={t("component.closeOrderModal.goBack")}
              />
              <Button
                onClick={() => closeOrder()}
                label={loading ? "..." : t("component.closeOrderModal.cancel")}
              />
            </ButtonsContainer>
            {error ? (
              <FlexContainer justifyContent="flex-end" padding="0.5em 1em 0 0">
                <InputError error={error} />
              </FlexContainer>
            ) : null}
          </div>
        }
        open={open}
        onClose={onClose}
      >
        <FlexContainer flexDirection="column">
          <img src={deleteIcon} alt="" className={styles.icon} />
          <Text
            padding="1em 0 2em 0"
            text={t("component.closeOrderModal.warning")}
            color="#000000"
            fontSize="1.4em"
            fontWeight={700}
          />
        </FlexContainer>
      </Modal>
    </div>
  );
};

export default CloseOrderModal;
