import InputDate from "../../Inputs/Date";
import { InputTags } from "../../Inputs/InputTags";
import { InputTime } from "../../Inputs/InputTime/InputTime";
import styles from "./styles";
import dayjs from "dayjs";
import React, { useState } from "react";
import { FulfillmentEvent } from "@innomius/ravent-typescript-types";
import { useTranslation } from "react-i18next";
import InputTextDropdown from "../../Inputs/InputTextDropdown";
import { timezones } from "../../../constants/timezones";
import timezone from "dayjs/plugin/timezone";
import Text from "../../Text";
import { Modal } from "../../Modal/Modal";
import { Checkbox } from "antd";
import { FlexContainer } from "../../FlexContainer/FlexContainer";
import { Button } from "../../Button";
import { ButtonsContainer } from "../../ButtonsContainer";
import { ContainerTitle } from "../../ContainerTitle";
import { InputText } from "../../Inputs/InputText";
import { InputTextarea } from "../../Inputs/InputTextArea";

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
dayjs.extend(timezone);

interface OrderEditFormProps {
  event: FulfillmentEvent;
  onChangeEvent: <P extends keyof FulfillmentEvent>(
    prop: P,
    value: FulfillmentEvent[P]
  ) => void;
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  onDelete: () => void;
}

const OrderEditForm: React.FC<OrderEditFormProps> = ({
  event,
  onChangeEvent,
  open,
  onClose,
  onSave,
  onDelete,
}) => {
  const { t } = useTranslation();

  const [end, setEnd] = useState(event.endDatetime ? true : false);

  return (
    <Modal
      width={"60%"}
      buttons={
        <FlexContainer justifyContent="space-between">
          <Button
            onClick={onDelete}
            theme="red"
            label={t("component.orderLocation.delete")}
          />
          <ButtonsContainer justifyContent="flex-end">
            <Button
              onClick={onClose}
              theme="white"
              label={t("component.orderLocation.cancel")}
            />
            <Button
              onClick={() => {
                onSave();
              }}
              theme="blue"
              label={t("component.orderLocation.save")}
            />
          </ButtonsContainer>
        </FlexContainer>
      }
      open={open}
      onClose={onClose}
    >
      <ContainerTitle
        padding="0 0 1em 0"
        label={t("component.orderLocation.edit")}
        size="medium"
      />
      <InputText
        value={event.name}
        label={t("component.orderLocation.name")}
        onChange={(value) => onChangeEvent("name", value)}
        showError={false}
      />
      <InputTags
        value={event.tags}
        height={event.tags?.length <= 1 ? "2.4em" : ""}
        label={t("component.orderLocation.tags")}
        onChange={(value) => {
          onChangeEvent("tags", value);
        }}
        showError={false}
      />
      <Checkbox
        style={{ padding: "1em 0 0 0" }}
        checked={event.considerTime}
        onChange={(value) =>
          onChangeEvent("considerTime", value.target.checked)
        }
      >
        <Text fontSize="1em" text={t("component.orderLocation.considerTime")} />
      </Checkbox>
      <FlexContainer
        padding="1em 0 1em 0"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <InputDate
          label={t("component.orderLocation.date")}
          dateClassname={styles.date}
          id="dateTime"
          value={
            new Date(
              dayjs(event.datetime).format(
                "YYYY-MM-DDTHH:mm:ss"
              ) as unknown as Date
            ) || new Date()
          }
          onChange={(value) => {
            onChangeEvent(
              "datetime",
              dayjs(value).format(
                event.considerTime
                  ? "YYYY-MM-DDT00:00:00"
                  : "YYYY-MM-DDTHH:mm:ss"
              )
            );
          }}
        />

        <div style={{ margin: "0 3em 0 0" }}>
          {event.considerTime ? (
            <InputTime
              id=""
              value={
                new Date(dayjs(event.datetime).format("YYYY-MM-DDTHH:mm:ss")) ||
                new Date(event.datetime)
              }
              onChange={(value) => {
                onChangeEvent(
                  "datetime",
                  dayjs(value.value).format("YYYY-MM-DDTHH:mm:ss")
                );
              }}
            />
          ) : null}
        </div>
        <div style={{ marginLeft: "1em" }}>
          <InputTextDropdown
            label={t("component.orderFilters.timezone")}
            placeholder={t("component.orderFilters.timezone")}
            value={event.timezone || ""}
            top={"calc(100% + 2.3em)"}
            options={timezones.map((item) => ({
              value: item,
              label: item,
            }))}
            showError={false}
            onChange={(value) => {
              onChangeEvent("timezone", value);
            }}
          />
        </div>
      </FlexContainer>

      <div
        onClick={() => {
          if (end) {
            setEnd(false);
            onChangeEvent("endDatetime", "");
          } else setEnd(true);
        }}
        style={{ width: "100%", cursor: "pointer" }}
      >
        <Text
          padding="1em 0 1em 0"
          text={
            end
              ? t("component.orderLocation.deleteEnd")
              : t("component.orderLocation.addEnd")
          }
          color={"#1FA0FF"}
        />
      </div>

      {end ? (
        <FlexContainer justifyContent="flex-start" padding="0 0 1em 0">
          <InputDate
            label={t("component.orderLocation.end")}
            dateClassname={styles.date}
            id="dateTime"
            value={
              event.endDatetime
                ? new Date(
                    dayjs(event.endDatetime).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ) as unknown as Date
                  )
                : new Date(event.datetime)
            }
            onChange={(value) => {
              onChangeEvent(
                "endDatetime",
                dayjs(value).format(
                  event.considerTime
                    ? "YYYY-MM-DDT00:00:00"
                    : "YYYY-MM-DDTHH:mm:ss"
                )
              );
            }}
            disableDate={(current) => {
              return current < dayjs(event.datetime);
            }}
          />
          {event.considerTime ? (
            <InputTime
              id=""
              value={
                new Date(
                  dayjs(event.endDatetime).format("YYYY-MM-DDTHH:mm:ss")
                ) || new Date(event.datetime)
              }
              onChange={(value) => {
                onChangeEvent(
                  "endDatetime",
                  dayjs(value.value).format("YYYY-MM-DDTHH:mm:ss")
                );
              }}
            />
          ) : null}
        </FlexContainer>
      ) : null}

      <InputTextarea
        value={event.notes}
        label="Notes"
        showError={false}
        onChange={(value) => onChangeEvent("notes", value)}
      />
    </Modal>
  );
};

export default OrderEditForm;
