import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { createDocumentationOrder } from "./orderPDF";

export const downloadPDF = async (
  id: string,
  shortId: string,
  creation: string
) => {
  (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
  (pdfMake as any)
    .createPdf(createDocumentationOrder(id, shortId, creation))
    .download(`order-${shortId}.pdf`);
};
