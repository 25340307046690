import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import MissingPlacementList from "../MissingPlacementList";
import { FulfillmentEvent, Load } from "@innomius/ravent-typescript-types";
import { Asset } from "../../utils/types";
import { EmptyList } from "../List/EmptyList";

interface Props {
  locations: FulfillmentEvent[];
  loading: boolean;
  count: string;
  error: string;
  page: number;
  total: number;
  hitsPerPage: number;
  setPage?: (page: number) => void;
  setHitsPerPage?: (hitsPerPage: number) => void;
  updateAsset: (event: FulfillmentEvent, id: string) => void;
  onSaveLoad: (eventId: string, data: Load[]) => void;
  teams: { label: string; value: string }[];
  updateFulfiller: (eventId: string, fulfillerId: string) => void;
  updateTeam: (eventId: string, teamId: string) => void;
  assets: Asset[];
  setState: (
    id: string,
    fulfillmentLevel: string,
    status: string,
    w3w: string,
    images: string[]
  ) => void;
  updateFulfillmentName: (
    id: string,
    diveshopId: string,
    fulfillmentLevel: string
  ) => void;
  fulfillmentNames: { label: string; value: string }[];
  errorState: string;
  setNew: (id: string, state: string) => void;
  type: string;
  onTagClick: (tag: string) => void;
}

const Missing: React.FC<Props> = ({
  locations,
  loading,
  error,
  setHitsPerPage,
  setPage,
  hitsPerPage,
  total,
  page,
  updateAsset,
  updateFulfiller,
  updateTeam,
  teams,
  onSaveLoad,
  assets,
  errorState,
  updateFulfillmentName,
  setState,
  fulfillmentNames,
  setNew,
  type,
  onTagClick,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {!locations.length ? (
        <div className={styles.routeContainerEmpty}>
          <EmptyList
            instructions={""}
            text={`${t("container.events.instructions")}`}
          />
        </div>
      ) : (
        <div className={styles.routeContainer}>
          <div>
            <MissingPlacementList
              selected={[]}
              onTagClick={onTagClick}
              setSelected={() => {}}
              error={error}
              loading={loading}
              data={locations}
              page={page}
              type={type}
              onPageChange={setPage}
              hitsPerPage={hitsPerPage}
              onHitsPerPageChange={setHitsPerPage}
              pagination
              total={total}
              setNew={setNew}
              updateFulfillmentName={updateFulfillmentName}
              updateAsset={updateAsset}
              teams={teams}
              onSaveLoad={onSaveLoad}
              updateFulfiller={updateFulfiller}
              errorState={errorState}
              fulfillmentNames={fulfillmentNames}
              setState={setState}
              assets={assets}
              updateTeam={updateTeam}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Missing;
