import { stylesheet } from "typestyle";
import { black, darkBlack, lightGrey } from "../../utils/colors";

const styles = stylesheet({
  container: {},
  button: {
    marginRight: "1em",
  },
  line: {
    height: "1px",
    margin: "1em 0",
    background: lightGrey,
  },
  wrapperCard: {
    padding: "1em",
    marginBottom: "1em",
  },
  instructions: {
    color: black,
    marginTop: "0.5em",
  },
  editorDetails: {
    height: "20em",
    marginBottom: "1em",
    width: "100%",
  },

  editorBox: {
    width: "100%",
    height: "15em",
    border: "1px solid #dce1e6",
  },
  wrapperMargin: {
    padding: "2em",
    marginTop: "1em",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  radioContainer: {
    marginRight: "3em",
    padding: "1em 0",
  },
  logoContainer: {
    objectFit: "cover",
    height: "14em",
  },
  text: {
    fontSize: "1.1em",
    color: darkBlack,
    fontWeight: 400,
  },
  label: {
    fontSize: "1.1em",
    color: "#000000",
    padding: "0.4em 1em",
  },
  addressContainer: {
    padding: "1em 0",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  buttonLegal: {
    margin: "1em 0",
  },
  margin: {
    padding: "0 0 1em 0",
  },
  legalFulfillerContainer: {
    padding: "1em 0",
  },
  preview: {
    contain: "cover",
    height: "11em",
    width: "13em",
    border: "2px dashed",
    borderColor: "#0091fa",
  },
  deliveredButton: {
    padding: "1em 0",
  },
});

export default styles;
