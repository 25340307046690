import { FulfillmentEvent } from "@innomius/ravent-typescript-types";

export const convertEventsToMarkers = (events: FulfillmentEvent[]) => {
  return events.flatMap((event, index) => {
    return event.placements
      .filter(
        (item) =>
          item.coordinates?.latitude !== undefined &&
          item.coordinates?.longitude !== undefined &&
          item.coordinates.latitude !== null &&
          item.coordinates.longitude !== null &&
          item.coordinates.latitude !== "" &&
          item.coordinates.longitude !== ""
      )
      .map((placement, placementIndex) => ({
        title: `${index + 1}.${placementIndex + 1}`,
        coordinates: {
          latitude: parseFloat(placement.coordinates?.latitude || "0"),
          longitude: parseFloat(placement.coordinates?.longitude || "0"),
        },
        eventId: event.id,
        placementIndex: placementIndex,
        event: event,
      }));
  });
};
