import React, { useCallback, useEffect, useState } from "react";

import { CloseCircleOutlined } from "@ant-design/icons";

import { Asset } from "../../utils/types";
import { useTranslation } from "react-i18next";
import { Route, useParams } from "react-router-dom";
import Editor from "@monaco-editor/react";
import { Placement } from "@innomius/ravent-typescript-types";
import DeleteModal from "../DeleteModal";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { ReactComponent as MapIcon } from "../../assets/google.svg";

import MapLoader from "../MapInput/MapLoader";
import { defaultAsset, readAsset } from "../../services/assets";
import APIError from "../../utils/APIError";
import AssetDetailsForm from "../AssetForm/AssetDetailsForm";
import { coordinatesToW3w, defaultW3W } from "../../services/maps";
import styles from "./styles";
import AssetForm from "../AssetForm";
import AssetType from "./Type";
import UploadImage from "../AssetForm/UploadImage";
import { getAssetImage } from "../../services/images";
import MapForm from "../MapForm";
import { StatusBadgePoint } from "../StatusBadgePoint/StatusBagePoint";
import { SideMenu } from "../SideMenu/SideMenu";
import { Badge, Tag, Tooltip } from "antd";
import { Modal } from "../Modal/Modal";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { EditableTitle } from "../EditableTitle";
import { ContainerTitle } from "../ContainerTitle";
import TabNavBar from "../TabNavBar";
import { GridContainer } from "../GridContainer/GridContainer";
import { SectionProperty } from "../Section/SectionProperty";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
import VehicleDetailsForm from "../AssetForm/VehicleDetailsForm";
import dayjs from "dayjs";
import fontColorContrast from "font-color-contrast";
import ErrorBox from "../ErrorBox";
import restrictedIcon from "../../assets/restricted3.png";

interface RouteParams {
  id: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
  loading: string;
  path: string;
  onDelete: (id: string) => void;
  onSave: (data: Asset, placement: Placement) => void;
  edit: boolean;
  setEdit: (value: boolean) => void;
  load: () => void;
  onDeleteImage: (id: string) => void;
  d: boolean;
  setD: (value: boolean) => void;
  errorDetails: string;
}

const AssetDetail: React.FC<Props> = ({
  onClose,
  open,
  loading,
  onDelete,
  onSave,
  edit,
  setEdit,
  onDeleteImage,
  d,
  setD,
  errorDetails,
}) => {
  const [data, setAsset] = useState(defaultAsset);
  const [locationW3W, setLocationW3W] = useState(defaultW3W);
  const [openModal, setOpenModal] = useState(false);

  const [img, setImg] = useState<string>("");

  const { id } = useParams<RouteParams>();

  const loadDetails = useCallback(async () => {
    try {
      if (id) {
        const res = await readAsset(id);
        setAsset(res);

        if (res.placement.coordinates?.latitude) {
          const resPlacement = await coordinatesToW3w(
            parseFloat(res.placement.coordinates.latitude || ""),
            parseFloat(res.placement.coordinates.longitude || "")
          );
          setLocationW3W(resPlacement);
          return;
        }
        if (res.imageKey) {
          const resImage = await getAssetImage(res.id);
          if (resImage) {
            const blob = new Blob([resImage]); // Adjust the MIME type accordingly
            const imageUrl = URL.createObjectURL(blob);
            setImg(imageUrl);
          }
        }
      }
    } catch (err) {
      if (err instanceof APIError) {
      }
    }
  }, [id]);

  useEffect(() => {
    loadDetails();
  }, [loadDetails]);

  const loadImage = useCallback(async () => {
    try {
      if (data.id && data.hasImage) {
        const res = await getAssetImage(data.id);
        if (res) {
          const blob = new Blob([res], { type: "image/jpeg" });
          const imageUrl = URL.createObjectURL(blob);
          setImg(imageUrl);
        }
      }
    } catch (err) {
      if (err instanceof APIError) {
        console.log(err);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.id, edit]);

  useEffect(() => {
    loadImage();
  }, [loadImage]);

  const { t } = useTranslation();

  const [openDelete, setDelete] = useState(false);

  const onChange = <P extends keyof Asset>(prop: P, value: Asset[P]) =>
    setAsset({ ...data, [prop]: value });

  const optionsVehicle = [
    {
      id: "variantInfo",
      label: t("component.assetDetail.details"),
      path: `/assets/${id}`,
    },
    {
      id: "variantVehicle",
      label: t("component.assetDetail.vehicleDetails"),
      path: `/assets/${id}/vehicleDetails`,
    },
    {
      id: "variantLocation",
      label: t("component.assetDetail.placement"),
      path: `/assets/${id}/placement`,
    },
  ];

  const optionsGeneral = [
    {
      id: "variantInfo",
      label: t("component.assetDetail.details"),
      path: `/assets/${id}`,
    },

    {
      id: "variantLocation",
      label: t("component.assetDetail.placement"),
      path: `/assets/${id}/placement`,
    },
  ];

  const formatOptionKey = (key: string, color: string) => {
    if (key && key.includes("::")) {
      const [firstPart, secondPart] = key.split("::");
      return (
        <Tooltip title={firstPart}>
          <Tag
            style={{
              color: color
                ? color === "blue"
                  ? "blue"
                  : fontColorContrast(color, 0.5)
                : "blue",
            }}
            color={color || "blue"}
          >
            {secondPart}
          </Tag>
        </Tooltip>
      );
    } else {
      return <Tag color={color || "blue"}>{key}</Tag>;
    }
  };

  if (errorDetails) {
    return (
      <SideMenu
        width="60%"
        open={open}
        onClose={() => {
          onClose();
          setEdit(false);
          setAsset(defaultAsset);
          setLocationW3W(defaultW3W);
          setImg("");
        }}
      >
        {errorDetails.includes("forbidden") ? (
          <FlexContainer padding="8% 0" flexDirection="column">
            <img src={restrictedIcon} alt="" className={styles.icon} />
            <ContainerTitle
              padding="1em 0 1em 0"
              size="small"
              label={t("component.errorBox.hidden")}
            />
            <p
              style={{
                padding: "0 0 1em 0",
                color: "#000000",
              }}
            >
              {t("component.errorBox.warningHidden")}
            </p>
          </FlexContainer>
        ) : (
          <ErrorBox error={errorDetails} />
        )}
      </SideMenu>
    );
  }

  return (
    <SideMenu
      width="60%"
      open={open}
      onClose={() => {
        onClose();
        setEdit(false);
        setAsset(defaultAsset);
        setLocationW3W(defaultW3W);
        setImg("");
      }}
    >
      <Modal
        width={"70%"}
        open={openModal}
        onClose={() => setOpenModal(false)}
        buttons={
          <ButtonsContainer justifyContent="flex-end">
            <Button
              label="Cancel"
              theme="white"
              onClick={() => {
                setOpenModal(false);
              }}
            />
            <Button
              label="Save"
              onClick={() => {
                onSave(data, data.placement);
                setOpenModal(false);
              }}
            />
          </ButtonsContainer>
        }
      >
        <AssetForm
          current={0}
          setCurrent={() => {}}
          next={() => {}}
          prev={() => {}}
          data={data}
          onChange={onChange}
        />
      </Modal>
      <DeleteModal
        value={t("component.assetDetail.warning")}
        onCancel={() => setDelete(false)}
        open={openDelete}
        loading={loading === "delete"}
        onDelete={() => onDelete(data.id)}
      />
      <DeleteModal
        value={t("component.assetDetail.warningImage")}
        onCancel={() => setD(false)}
        open={d}
        loading={loading === "delete"}
        onDelete={() => onDeleteImage(data.id)}
      />
      <FlexContainer
        padding="1.5em 0 1em 0"
        alignItems="center"
        justifyContent="space-between"
      >
        {edit ? (
          <EditableTitle
            titleClassname={styles.editTitle}
            value={data.name}
            onChange={(value) => onChange("name", value)}
          />
        ) : (
          <ContainerTitle label={data.name} size="medium" />
        )}
        {edit ? (
          <ButtonsContainer>
            <Button
              label={t("component.assetDetail.cancel")}
              theme="white"
              onClick={() => {
                setEdit(false);
                setAsset(data);
              }}
            />
            <Button
              label={
                loading === "edit" ? "..." : t("component.assetDetail.save")
              }
              theme="blue"
              onClick={() => {
                if (loading === "edit") {
                  return;
                }
                onSave(data, data.placement);
              }}
            />
          </ButtonsContainer>
        ) : (
          <ButtonsContainer>
            <Button
              label={t("component.assetDetail.delete")}
              theme="red"
              onClick={() => {
                onChange("data", data.data);
                setDelete(true);
              }}
            />
            <Button
              label={t("component.assetDetail.edit")}
              theme="blue"
              onClick={() => {
                setEdit(true);
              }}
            />
          </ButtonsContainer>
        )}
      </FlexContainer>

      <div>
        <TabNavBar
          options={data.type === "vehicle" ? optionsVehicle : optionsGeneral}
        >
          <>
            <Route exact path={`/assets/${id}`}>
              {edit ? (
                <AssetDetailsForm data={data} onChange={onChange} />
              ) : (
                <>
                  <FlexContainer justifyContent="space-between">
                    <ContainerTitle
                      size="xsmall"
                      label={t("component.assetDetail.assetDetails")}
                      padding="1em 0 1em 0"
                    />
                  </FlexContainer>
                  <GridContainer gap="1em" columns="1fr 0.3fr">
                    <div>
                      <GridContainer gap="1em" columns="1fr 1fr 0.5fr">
                        <SectionProperty
                          label={t("component.assetDetail.externalId")}
                          value={data.externalID || "-"}
                        />
                        <SectionProperty
                          label={t("component.assetDetail.type")}
                          value={data.type || "-"}
                        />
                        <FlexContainer
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          flexDirection="column"
                        >
                          <p
                            style={{ fontWeight: 700, padding: "0 0 0.5em 0" }}
                          >
                            {t("component.assetDetail.status")}
                          </p>
                          <StatusBadgePoint
                            status={data.status ? "active" : "DISABLED"}
                          />
                        </FlexContainer>
                      </GridContainer>

                      <div
                        className={styles.box}
                        onClick={() => setOpenModal(true)}
                      >
                        <FlexContainer justifyContent="space-between">
                          <AssetType type={data.subtype} />
                          <EditIcon className={styles.icon} />
                        </FlexContainer>
                      </div>
                    </div>

                    {data.imageKey && img ? (
                      <Badge
                        count={
                          <CloseCircleOutlined
                            onClick={(e) => {
                              e.stopPropagation();
                              setD(true);
                            }}
                          />
                        }
                      >
                        <div style={{ cursor: "poiner" }}>
                          <img src={img} alt="Asset" className={styles.image} />
                        </div>
                      </Badge>
                    ) : (
                      <div style={{ cursor: "poiner" }}>
                        <UploadImage
                          load={() => {
                            loadDetails();
                            loadImage();
                          }}
                          id={id}
                          onClose={() => {}}
                        />
                      </div>
                    )}
                  </GridContainer>

                  <InputLabel label={t("component.assetDetail.tags")} />
                  <ButtonsContainer
                    padding="0 0 2em 0"
                    justifyContent="flex-start"
                  >
                    {data.colorizedTags && data.colorizedTags.length >= 1 ? (
                      data.colorizedTags?.map(
                        (
                          item: {
                            key: string;
                            settings: {
                              tagClassification: string;
                              color: string;
                            };
                          },
                          index
                        ) => (
                          <div
                            style={{ margin: "0 0.2em 0.2em 0" }}
                            key={index}
                          >
                            {formatOptionKey(
                              item.key,
                              item.settings && item.settings.color
                                ? item.settings.color
                                : "blue"
                            )}
                          </div>
                        )
                      )
                    ) : data.tags ? (
                      data.tags?.map((item) => (
                        <div style={{ margin: "0 0.2em 0.2em 0" }} key={item}>
                          {formatOptionKey(item, "blue")}
                        </div>
                      ))
                    ) : (
                      <div>{t("component.orderLocation.notTags")}</div>
                    )}
                  </ButtonsContainer>
                  <SectionProperty
                    value={data.notes || "-"}
                    label={t("component.assetDetail.notes")}
                  />
                  <SectionProperty
                    value={data.description || "-"}
                    label={t("component.assetDetail.description")}
                  />
                  <InputLabel label={t("component.assetDetail.data")} />
                  <Editor
                    className={styles.editorBox}
                    height="100%"
                    defaultLanguage="json"
                    value={JSON.stringify(data.data)}
                    options={{
                      readOnly: true,
                      selectOnLineNumbers: true,
                      scrollbar: {
                        vertical: "hidden",
                      },
                      overviewRulerLanes: 0,
                      hideCursorInOverviewRuler: true,
                      overviewRulerBorder: false,
                      foldingHighlight: false,
                    }}
                  />
                </>
              )}
            </Route>
            <Route path={`/assets/${id}/placement`} exact>
              {edit ? (
                <>
                  <FlexContainer justifyContent="space-between">
                    <ContainerTitle
                      size="xsmall"
                      label="Placement"
                      padding="0 0 1em 0"
                    />
                    <SectionProperty
                      label={t("component.assetDetail.locationTimestamp")}
                      value={
                        data.locationTimestamp
                          ? dayjs(data.locationTimestamp).format(
                              "DD/MM/YYYY HH:mm"
                            )
                          : "-"
                      }
                    />
                  </FlexContainer>

                  <MapForm
                    setW3W={(value) => {
                      onChange("placement", {
                        id: "",
                        w3w: value,
                        chronologicalIndex: 0,
                        name: data.placement.name,
                        type: "w3w",
                        line1: data.placement.line1,
                        coordinates: {
                          latitude: data.placement.coordinates?.latitude || "",
                          longitude:
                            data.placement.coordinates?.longitude || "",
                        },
                      });
                    }}
                    w3w={locationW3W}
                    setData={(p, name) => {
                      onChange("placement", {
                        id: "",
                        chronologicalIndex: 0,
                        w3w: p.words,
                        type: "w3w",
                        name: p.name,
                        line1: name,
                        coordinates: {
                          latitude: p.coordinates.lat.toString(),
                          longitude: p.coordinates.lng.toString(),
                        },
                      });
                      setLocationW3W(p);
                    }}
                  />
                </>
              ) : (
                <>
                  <ContainerTitle
                    padding="1em 0 1em 0"
                    size="xsmall"
                    label="Placement"
                  />

                  <SectionProperty
                    value={data.placement.line1 || "-"}
                    label={t("component.orderLocation.address")}
                  />
                  <GridContainer gap="1em" columns="1fr 1fr">
                    <SectionProperty
                      value={data.placement.w3w || "-"}
                      label={t("component.orderLocation.w3w")}
                    />
                    <SectionProperty
                      value={
                        `${data.placement.coordinates?.latitude || ""} , ${
                          data.placement.coordinates?.longitude || ""
                        }` || "-"
                      }
                      label={t("component.orderLocation.coordinates")}
                    />
                  </GridContainer>

                  <FlexContainer justifyContent="flex-end">
                    <div
                      onClick={(e) => {
                        e.stopPropagation();

                        window.open(
                          "https://maps.google.com?q=" +
                            data.placement.coordinates?.latitude +
                            "," +
                            data.placement.coordinates?.longitude
                        );
                      }}
                    >
                      <MapIcon className={styles.iconBig} />
                    </div>
                  </FlexContainer>
                  {data.placement.coordinates?.latitude ? (
                    <MapLoader
                      onChange={() => {}}
                      value={locationW3W.coordinates}
                    />
                  ) : null}
                </>
              )}
            </Route>

            <Route path={`/assets/${id}/vehicleDetails`} exact>
              {edit ? (
                <VehicleDetailsForm data={data} onChange={onChange} />
              ) : (
                <>
                  {data.type === "vehicle" && (
                    <>
                      <ContainerTitle
                        size="xsmall"
                        label={t("component.assetDetail.vehicleDetails")}
                        padding="1em 0"
                      />
                      <GridContainer gap="1em" columns="1fr 1fr">
                        <SectionProperty
                          label={t("component.assetForm.vehicleBrand")}
                          value={data.vehicleBrand || "-"}
                        />
                        <SectionProperty
                          label={t("component.assetForm.vehicleModel")}
                          value={data.vehicleModel || "-"}
                        />
                        <SectionProperty
                          label={t("component.assetForm.vehicleLicensePlate")}
                          value={data.vehicleLicensePlate || "-"}
                        />
                        <SectionProperty
                          label={t("component.assetForm.vehicleSerial")}
                          value={data.vehicleSerial || "-"}
                        />
                      </GridContainer>

                      <ContainerTitle
                        size="xsmall"
                        label={t("component.assetForm.vehicleCapacity")}
                        padding="1em 0 1em 0"
                      />
                      <GridContainer gap="1em" columns="1fr 1fr">
                        <SectionProperty
                          label={t("component.assetForm.quantity")}
                          value={
                            data.vehicleCapacity.quantity.toString() || "-"
                          }
                        />
                        <SectionProperty
                          label={t("component.assetForm.units")}
                          value={data.vehicleCapacity.units || "-"}
                        />
                      </GridContainer>

                      <ContainerTitle
                        size="xsmall"
                        label={t("component.assetForm.vehicleWeight")}
                        padding="1em 0 1em 0"
                      />
                      <GridContainer gap="1em" columns="1fr 1fr 1fr">
                        <SectionProperty
                          label={t("component.assetForm.max")}
                          value={data.vehicleWeight.max.toString() || "-"}
                        />
                        <SectionProperty
                          label={t("component.assetForm.current")}
                          value={data.vehicleWeight.current.toString() || "-"}
                        />
                        <SectionProperty
                          label={t("component.assetForm.units")}
                          value={data.vehicleWeight.units || "-"}
                        />
                      </GridContainer>
                    </>
                  )}
                </>
              )}
            </Route>
          </>
        </TabNavBar>
      </div>
    </SideMenu>
  );
};

export default AssetDetail;
