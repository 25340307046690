import React from "react";

import { Offer } from "@innomius/ravent-typescript-types";
import UploadThumbnail from "../Upload";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { ContainerTitle } from "../ContainerTitle";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputTextarea } from "../Inputs/InputTextArea";
import { InputText } from "../Inputs/InputText";
import InputRadio from "../Inputs/InputRadio";
import { InputTags } from "../Inputs/InputTags";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";

interface Props {
  data: Offer;
  onChange: <P extends keyof Offer>(prop: P, value: Offer[P]) => void;
  edit?: boolean;
  onEdit?: () => void;
  onCancel?: () => void;
}

const OfferDetailForm: React.FC<Props> = ({
  data,
  onChange,
  edit,
  onEdit,
  onCancel,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.containerDetails}>
        <FlexContainer
          padding={edit ? "0 0 2em 0" : "0 0 1em 0"}
          alignItems="center"
          justifyContent="space-between"
        >
          <ContainerTitle
            size="xsmall"
            label={t("component.offerVariantForm.details")}
          />
        </FlexContainer>
        <GridContainer gap="2em" columns="3fr 1fr">
          <div>
            <InputTextarea
              label={t("component.offerVariantForm.details")}
              value={data.description}
              onChange={(value) => onChange("description", value)}
            />
          </div>
          <UploadThumbnail upload={() => {}} image={data.image} edit={edit} />
        </GridContainer>
        {edit ? null : (
          <>
            <ContainerTitle
              padding="0 0 0 0"
              label={t("component.offerVariantForm.startingPrice")}
              size="xsmall"
            />
            <InputText
              containerClassname={styles.amountContainer}
              label={t("component.offerVariantForm.amount")}
            />
          </>
        )}

        <ContainerTitle
          padding="0 0 0 0"
          label={t("component.offerVariantForm.availability")}
          size="xsmall"
        />
        <FlexContainer padding="1.5em 0 1em 0" justifyContent="flex-start">
          <InputRadio
            name="availability"
            value={data.availability}
            checked={data.availability === "free-sell"}
            text={t("component.offerVariantForm.freeSell")}
            containerClassname={styles.radioContainer}
            onChange={() => onChange("availability", "free-sell")}
          />
          <InputRadio
            name="availability"
            value={data.availability}
            checked={data.availability === "confirmation-required"}
            text={t("component.offerVariantForm.confirmationDiveShop")}
            onChange={() => onChange("availability", "confirmation-required")}
          />
        </FlexContainer>
        <ContainerTitle
          padding="1em 0 1em 0"
          label={t("component.offerVariantForm.tags")}
          size="xsmall"
        />
        <InputTags
          value={data.tags}
          onChange={(value) => onChange("tags", value)}
        />

        {edit ? (
          <ButtonsContainer padding="2em 0 0 0" justifyContent="flex-start">
            <Button
              label={t("component.offerVariantForm.cancel")}
              theme="white"
              onClick={() => onCancel?.()}
            />
            <Button
              label={t("component.offerVariantForm.save")}
              onClick={() => onEdit?.()}
            />
          </ButtonsContainer>
        ) : null}
      </div>
    </div>
  );
};

export default OfferDetailForm;
