import React from "react";
import bike from "../../assets/assets/bike.svg";
import ferry from "../../assets/assets/ferry.svg";
import IOT from "../../assets/assets/IOT.svg";
import locker from "../../assets/assets/locker.svg";
import moped from "../../assets/assets/moped.svg";
import motorcycle from "../../assets/assets/motorcycle.svg";
import other from "../../assets/assets/other.svg";
import scooter from "../../assets/assets/scooter.svg";
import service from "../../assets/assets/VAN.svg";
import ship from "../../assets/assets/ship.svg";
import tools from "../../assets/assets/tools.svg";
import truck from "../../assets/assets/truck.svg";
import vehicle from "../../assets/assets/vehicle.svg";
import bus from "../../assets/assets/bus.svg";
import suv from "../../assets/assets/SUV.svg";

import limo from "../../assets/assets/limo.svg";
import styles from "./styles";

interface Props {
  type: string;
}

const IconAsset: React.FC<Props> = ({ type }) => {
  const typeIconMap: Record<string, string> = {
    vehicle: vehicle,
    van: service,
    truck: truck,
    moped: moped,
    motorcycle: motorcycle,
    scooter: scooter,
    bike: bike,
    ferry: ferry,
    ship: ship,
    IOT: IOT,
    locker: locker,
    tools: tools,
    other: other,
    bus: bus,
    limo: limo,
    suv: suv,
  };

  const iconSrc = typeIconMap[type] || vehicle;

  return <img className={styles.iconBUtton} src={iconSrc} alt="" />;
};

export default IconAsset;
