import React, { useCallback, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { useTranslation } from "react-i18next";
import TabNavBar from "../../components/TabNavBar";
import APIError from "../../utils/APIError";
import { message } from "antd";
import {
  addFOP,
  createFOPSet,
  deleteFOP,
  deleteFOPSet,
  updateFOP,
} from "../../services/fop";
import { fopSetsList } from "../../store/fopSets";
import FOPSets from "../../components/FOPSets";
import FOPs from "../../components/FOPs";
import { BankAccount } from "@innomius/ravent-typescript-types";
import { fopsCatalogList } from "../../store/fopsCatalog";
import { fopsList } from "../../store/fops";
import { integrationList } from "../../store/integrations";
import styles from "./styles.module.css";
import { ContainerTitle } from "../../components/ContainerTitle";

interface Props {}

const Payments: React.FC<Props> = () => {
  const auth = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const fopSets = useSelector((state: RootState) => state.fopSets);
  const [records_per_page_set, setHitsPerPageSet] = useState(10);
  const [pageSet, setPageSet] = useState(fopSets.list.page);
  const [addFOPSet, setAddFops] = useState(false);
  const [loadingSet, setLoadingSet] = useState("");

  const catalogs = useSelector((state: RootState) => state.fopsCatalog);
  const fops = useSelector((state: RootState) => state.fops);
  const [add, setAdd] = useState(false);
  const [loading, setLoading] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const integrations = useSelector((state: RootState) => state.integrations);

  const load = useCallback(async () => {
    dispatch(fopsList());
    dispatch(fopsCatalogList());
    dispatch(
      integrationList({
        page: 0,
        records_per_page: 1000,
        supplierType: "payment",
        supplierName: supplierName,
      })
    );
  }, [dispatch, supplierName]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    document.title = "RAVENT APP :: FOPs";
  });

  const onChangeBankAccount = async (id: string, bankData: BankAccount) => {
    try {
      setLoading("account");
      await updateFOP(id, {
        data: {
          account: bankData,
        },
      });
      message.success({
        content: t("container.fops.successBank"),
        className: "custom-class",
        style: {
          marginTop: "3em",
        },
      });
      setLoading("");
      load();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onCreate = async (
    catalogId: string,
    round: string,
    account: BankAccount
  ) => {
    try {
      setLoading("create");

      await addFOP(catalogId, round, account);
      message.success({
        content: t("container.fops.successCreated"),
        className: "custom-class",
        style: {
          marginTop: "3em",
        },
      });
      setLoading("");

      load();
    } catch (err) {
      setLoading("");

      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onRemove = async (id: string) => {
    try {
      setLoading(id);
      await deleteFOP(id);
      message.success(t("container.fops.successDeleted"));
      setLoading("");
      load();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const loadFOPSets = useCallback(async () => {
    dispatch(
      fopSetsList({ page: pageSet, records_per_page: records_per_page_set })
    );
  }, [dispatch, pageSet, records_per_page_set]);

  useEffect(() => {
    loadFOPSets();
  }, [loadFOPSets]);

  useEffect(() => {
    document.title = "RAVENT APP :: FOP Sets";
  });

  const onCreateFOPSet = async (name: string) => {
    try {
      setLoadingSet("add");
      await createFOPSet(name);
      setLoadingSet("");
      message.success(t("container.fopsets.successCreated"));
      setAddFops(false);
      loadFOPSets();
    } catch (err) {
      setLoadingSet("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onDeletFOPSet = async (id: string) => {
    try {
      setLoadingSet("delete");
      await deleteFOPSet(id);
      setLoadingSet("");
      message.success(t("container.fopsets.successDeleted"));
      loadFOPSets();
    } catch (err) {
      setLoadingSet("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };
  useEffect(() => {
    document.title = "RAVENT APP :: Payments";
  });

  if (!auth.loading && !auth.user) {
    return <Redirect from="*" to="/" />;
  }

  if (!auth.loading && !auth.user) {
    return <Redirect from="*" to="/" />;
  }
  return (
    <LayouWithSideBar>
      <div className={styles.containerPayment}>
        <ContainerTitle
          padding="0 0 1em 0"
          size="medium"
          label={t("container.settings.settingsLabel")}
        />
        <TabNavBar
          options={[
            {
              id: "fopSets",
              label: t("container.settings.fopSetsLabel"),
              path: "/payments",
              onClick: () =>
                (document.title = "RAVENT APP :: Payments :: FOP Sets"),
            },
            {
              id: "fops",
              label: t("container.settings.fopLabel"),
              path: "/payments/fops",
              onClick: () =>
                (document.title = "RAVENT APP :: Payments :: FOPs"),
            },
          ]}
        >
          <Switch>
            <Route path={"/payments"} exact>
              <FOPSets
                add={addFOPSet}
                setAdd={setAddFops}
                setPageSet={setPageSet}
                records_per_page_set={records_per_page_set}
                fopSets={fopSets}
                loadingSet={loadingSet}
                pageSet={pageSet}
                setHitsPerPageSet={setHitsPerPageSet}
                onCreateFOPSet={onCreateFOPSet}
                onDeletFOPSet={onDeletFOPSet}
              />
            </Route>
            <Route path={"/payments/fops"} exact>
              <FOPs
                load={load}
                setSupplierName={setSupplierName}
                onCreate={onCreate}
                onRemove={onRemove}
                add={add}
                supplierName={supplierName}
                setAdd={setAdd}
                fops={fops}
                loading={loading}
                setLoading={setLoading}
                catalogs={catalogs}
                integrations={integrations}
                onChangeBankAccount={onChangeBankAccount}
              />
            </Route>
          </Switch>
        </TabNavBar>
      </div>
    </LayouWithSideBar>
  );
};

export default Payments;
