import React from "react";
import Text from "../Text";
import ferry from "../../assets/assets/ferry.svg";
import IOT from "../../assets/assets/IOT.svg";
import locker from "../../assets/assets/locker.svg";
import moped from "../../assets/assets/moped.svg";
import motorcycle from "../../assets/assets/motorcycle.svg";
import other from "../../assets/assets/other.svg";
import scooter from "../../assets/assets/scooter.svg";
import van from "../../assets/assets/VAN.svg";
import ship from "../../assets/assets/ship.svg";
import tools from "../../assets/assets/tools.svg";
import truck from "../../assets/assets/truck.svg";
import vehicle from "../../assets/assets/vehicle.svg";
import bike from "../../assets/assets/bike.svg";
import bus from "../../assets/assets/bus.svg";
import limo from "../../assets/assets/limo.svg";
import suv from "../../assets/assets/SUV.svg";

import styles from "./styles";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { useTranslation } from "react-i18next";

interface Props {
  type: string;
  noText?: boolean
}

const AssetType: React.FC<Props> = ({ type }) => {

  const {t} = useTranslation();
  const typeIconMap: Record<string, string> = {
    vehicle: vehicle,
    van: van,
    truck: truck,
    moped: moped,
    motorcycle: motorcycle,
    scooter: scooter,
    bike: bike,
    ferry: ferry,
    ship: ship,
    IOT: IOT,
    locker: locker,
    tools: tools,
    other: other,
    bus: bus,
    limo: limo,
    suv: suv,
  };

  return (
    <FlexContainer justifyContent="flex-start" alignItems="center">
      <img className={styles.iconBUtton} src={typeIconMap[type]} alt="" />
      <Text padding="0 0 0 1em" text={type || t("component.assetDetail.setType")} fontSize="1.1em" fontWeight={700} />
    </FlexContainer>
  );
};

export default AssetType;
