import React, { useState } from "react";
import Item from "./Item";
import { TeamOverview } from "../../utils/types";

import { OrderListSkeleton } from "../ListSkeletons";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { List } from "../List";

interface Props {
  error: string;
  loading: boolean;
  data: TeamOverview[];
  from: string;
  to: string;
  onChangeSortOrder?: (sort: string) => void;
}

const TeamList: React.FC<Props> = ({
  error,
  loading,
  data,
  from,
  to,
  onChangeSortOrder,
}) => {
  const { t } = useTranslation();
  const totalSum = data.reduce((acc, current) => acc + current.total, 0);
  const [order, setOrder] = useState("asc");
  const [sort, setSort] = useState<string | null>(null);

  const header = (
    <div>
      <ListHeader template="2fr 1fr 1fr 1fr 1fr 1fr">
        <ListHeaderLabel
          value={t("component.teamList.teamName")}
          justifyContent="flex-start"
          sortable={true}
          sort={
            sort === "fulfillmentTeamName"
              ? order === "asc"
                ? "asc"
                : "desc"
              : null
          }
          onClick={() => {
            setSort("fulfillmentTeamName");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`fulfillmentTeamName:${order}`);
            }
          }}
        />
        <ListHeaderLabel
          value={t("component.teamList.pending")}
          justifyContent="flex-start"
          sortable={true}
          sort={
            sort === "Pending"
              ? order === "asc"
                ? "asc"
                : "desc"
              : null
          }
          onClick={() => {
            setSort("Pending");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`Pending:${order}`);
            }
          }}
        />
        <ListHeaderLabel
          value={t("component.teamList.completed")}
          justifyContent="flex-start"
          sortable={true}
          sort={
            sort === "Completed"
              ? order === "asc"
                ? "asc"
                : "desc"
              : null
          }
          onClick={() => {
            setSort("Completed");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`Completed:${order}`);
            }
          }}
        />
        <ListHeaderLabel
          sortable={true}
          sort={
            sort === "Total"
              ? order === "asc"
                ? "asc"
                : "desc"
              : null
          }
          onClick={() => {
            setSort("Total");
            setOrder(order === "asc" ? "desc" : "asc");
            if (onChangeSortOrder) {
              onChangeSortOrder(`Total:${order}`);
            }
          }}
          value={t("component.teamList.total")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.teamList.percentage")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel value={""} justifyContent="flex-start" />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <div className={styles.listContainer}>
        <List
          loading={loading}
          loadingElement={() => <OrderListSkeleton />}
          error={error}
          data={data}
          empty={!data.length}
          keyExtractor={(item) => item.fulfillmenTeamId}
          renderHeader={() => header}
          renderItem={(item) => (
            <Item
              total={totalSum}
              from={from}
              to={to}
              data={item}
              key={item.fulfillmenTeamId}
            />
          )}
        />
      </div>
    </div>
  );
};

export default TeamList;
