const translation = {
  en: {
    orderStates: "Order States",
    orderlinesStates: "States",
    allowedTransitions: "Allowed Transitions",
    emptyInstructions: "States are not configured yet",
    addInstructions: "+ Add State",
    add: "Add State",
    edit: "Edit State",
    stateName: "State Name",
    flowUrl: "Flow URL",
    cancel: "Cancel",
    addModal: "Add State",
    update: "Update",
    type: "Type",
  },
  es: {
    orderStates: "Estados de Orden",
    orderlinesStates: "Estados",
    allowedTransitions: "Transiciones Permitidas",
    emptyInstructions: "Estados no se han configurado",
    addInstructions: "Agregue su lista de estados aquí",
    add: "+ Agregar estado",
    edit: "Editar estado",
    stateName: "Nombre del estado",
    flowUrl: "Flow URL",
    cancel: "Cancelar",
    addModal: "+ Agregar estado",
    update: "Actualizar",
    type: "Tipo",
  },
  de: {},
};

export default translation;
