import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  teamList as teamListService,
  TeamParams,
  TeamSearchResponse,
} from "../services/entities";

type TeamSliceService = {
  loading: boolean;
  error: string;
  list: TeamSearchResponse;
};

const initialState: TeamSliceService = {
  loading: true,
  error: "",
  list: {
    page: 0,
    total: 0,
    data: [],
  },
};

export const teamList = createAsyncThunk(
  "teams/list",
  async (params: TeamParams) => {
    const response = await teamListService(params);
    return response;
  }
);

const teamsSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(teamList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(teamList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.list = action.payload;
    });
    builder.addCase(teamList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An unexpected error ocurred";
    });
  },
});

export default teamsSlice.reducer;
