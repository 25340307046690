const translation = {
  en: {
    teamName: "Team Name",
    pending: "Pending",
    completed: "Completed",
    total: "Total Events",
    actions: "Actions",
    percentage: "Percentage",
  },
  es: {
    percentage: "Porcentaje",
    teamName: "Nombre del Equipo",
    pending: "Pendiente",
    completed: "Cumplidos",
    total: "Eventos Totales",
    actions: "Acciones",
  },
  de: {
    teamName: "Team Name",
    pending: "Pending",
    fulfilled: "Fulfilled",
    total: "Total Events",
    actions: "Actions",
  },
};

export default translation;
