
import React, { useState } from "react";
import Text from "../Text";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { Modal } from "../Modal/Modal";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { InputError } from "../Inputs/InputError/InputError";
import { ContainerTitle } from "../ContainerTitle";
import InputRadio from "../Inputs/InputRadio";

interface Props {
  onClose: () => void;
  open: boolean;
  onSend: () => void;
  loading?: boolean;
  error?: string;
  email?: string;
  phone?: string;
}

const MagicLinkModal: React.FC<Props> = ({
  onClose,
  onSend,
  open,
  error,
  loading,
  email,
  phone,
}) => {
  const { t } = useTranslation();
  const [method, setMethod] = useState("phone");

  return (
    <div className={styles.mcontainer}>
      <Modal
        width="600px"
        buttons={
          <div>
            <ButtonsContainer justifyContent="flex-end">
              <Button
                onClick={onClose}
                theme="white"
                label={t("component.diverDetail.close")}
              />
              <Button
                onClick={() => onSend()}
                theme="blue"
                label={loading ? "..." : t("component.diverDetail.send")}
              />
            </ButtonsContainer>
            {error ? (
              <FlexContainer justifyContent="flex-end" padding="0.5em 1em 0 0">
                <InputError error={error} />
              </FlexContainer>
            ) : null}
          </div>
        }
        open={open}
        onClose={onClose}
      >
        <div className={styles.wrapper}>
          <ContainerTitle
            size="medium"
            label={t("component.diverDetail.sendMagic")}
          />
          <Text
            padding="1em 0 1em 0"
            text={t("component.diverDetail.how")}
            color="#000000"
            fontSize="1.1em"
            fontWeight={500}
          />

          <div className={styles.radioContainer}>
            <InputRadio
              name="addressType"
              value="phone"
              checked={method === "phone"}
              text={t("component.diverDetail.phone")}
              onChange={() => setMethod("phone")}
            />
            <Text
              padding="0 0 0 0.5em"
              text={phone || ""}
              color="#858D8F"
              fontSize="1em"
              fontWeight={400}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MagicLinkModal;
