import React from "react";
import PaymentItem from "./PaymentItem";
import { PaymentListSkeleton } from "../ListSkeletons";
import { Payment } from "@innomius/ravent-typescript-types";
import { useTranslation } from "react-i18next";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { List } from "../List";

interface PaymentListProps {
  error: string;
  loading: boolean;
  data: Payment[];
  onIssueInvoice: (id: string) => void;
  contactEmail: string;
  orderShortId: string;
  orderDescription: string;
}

const OrderPaymentList: React.FC<PaymentListProps> = ({
  error,
  data,
  loading,
  onIssueInvoice,
  contactEmail,
  orderShortId,
  orderDescription,
}) => {
  const { t } = useTranslation();

  const header = (
    <div>
      <ListHeader template="0.8fr 0.8fr 1fr 1fr 1fr 1fr 0.5fr">
        <ListHeaderLabel
          value={t("component.orderPaymentList.paymentId")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderPaymentList.requestId")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderPaymentList.paymentType")}
          textAlign="center"
          justifyContent="flex-start"
        />

        <ListHeaderLabel
          value={t("component.orderPaymentList.paid")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderPaymentList.state")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderPaymentList.amount")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderPaymentList.actions")}
          justifyContent="center"
        />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <List
        loading={loading}
        loadingElement={() => <PaymentListSkeleton />}
        error={error}
        data={data}
        keyExtractor={(item) => item.id}
        renderHeader={() => header}
        renderItem={(item) => (
          <PaymentItem
            shortId={orderShortId}
            description={orderDescription}
            contactEmail={contactEmail}
            onIssueInvoice={() => onIssueInvoice(item.id)}
            data={item}
            key={item.id}
          />
        )}
      />
    </div>
  );
};

export default OrderPaymentList;
