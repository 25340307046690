import React, { useState } from "react";
import { ReactComponent as Share } from "../../assets/share.svg";
import { OrderInvoice } from "@innomius/ravent-typescript-types";
import { numberFormat } from "../../utils/format";
import { message, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import InvoiceDetail from "../InvoiceDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { normalGray } from "../../utils/colors";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { formatDate } from "../../utils/times";
import { downloadInvoicePDF } from "../../services/pdfs";
import SendMail from "../SendModal/SendMail";
import APIError from "../../utils/APIError";
import { sendMessage } from "../../services/messaging";
import { updateInvoice } from "../../services/invoices";
import AddFile from "../AddInvoice/AddFile";
import styles from "./styles";
import { StatusBadgePoint } from "../StatusBadgePoint/StatusBagePoint";
import { ListRow } from "../List/ListRow";
import { InputSelect } from "../Inputs/InputSelect";
import { ListLabel } from "../List/ListLabel";

interface InvoiceProps {
  data: OrderInvoice;
  payments: { label: string; value: string }[];
  requestIds: { label: string; value: string }[];
  orderId: string;
  contactEmail: string;
  load: () => void;
  refundActionId: string;
  cancelInvoiceActionId: string;
}

const InvoiceItem: React.FC<InvoiceProps> = ({
  data,
  payments,
  requestIds,
  orderId,
  contactEmail,
  load,
  refundActionId,
  cancelInvoiceActionId,
}) => {
  const [open, setOpen] = useState("");
  const [chevron, setChevron] = useState(false);
  const [loading, setLoading] = useState("");
  const [invoice, setInvoice] = useState(data);

  const { t } = useTranslation();

  const onUpdate = async () => {
    try {
      await updateInvoice(orderId, data.id, invoice);
      message.success(t("component.orderInvoiceList.updateSuccess"));
      setOpen("");
      load();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onChange = <P extends keyof OrderInvoice>(
    prop: P,
    value: OrderInvoice[P]
  ) => {
    setInvoice({ ...invoice, [prop]: value });
  };

  return (
    <>
      <AddFile
        load={load}
        id={data.id}
        description={data.description}
        onSkip={() => setOpen("")}
        orderId={orderId}
        open={open === "file"}
        list
      />
      <ListRow
        template="0.8fr 1fr 1fr 0.8fr 1fr 0.8fr 0.8fr 0.2fr"
        hover={true}
        key={data.id}
        showDetail={open === "details"}
        detail={
          <InvoiceDetail
            refundActionId={refundActionId}
            load={load}
            onUpdate={onUpdate}
            payments={payments}
            requestIds={requestIds}
            orderId={orderId}
            onChange={onChange}
            data={invoice}
            onUpload={() => setOpen("file")}
            cancelInvoiceActionId={cancelInvoiceActionId}
            onClose={() => {
              setOpen("");
              setChevron(!chevron);
            }}
          />
        }
      >
        <div
          className={styles.normal}
          onClick={() => {
            setChevron(!chevron);
            setOpen(chevron ? "" : "details");
          }}
        >
          <FontAwesomeIcon
            icon={
              chevron ? (faChevronUp as IconProp) : (faChevronDown as IconProp)
            }
            className={styles.icon}
            color={normalGray}
          />
          {data.description}
        </div>
        <div className={styles.left}>
          <InputSelect
            placeholder={t("component.orderInvoiceList.selectOption")}
            containerClassname={styles.width}
            value={data.paymentRequestID}
            options={[...requestIds, { value: "", label: "Not Assigned" }]}
            onChange={(value) => {
              onChange("paymentRequestID", value);
              onUpdate();
            }}
          />
        </div>
        <div className={styles.left}>
          <InputSelect
            placeholder={t("component.orderInvoiceList.selectOption")}
            containerClassname={styles.width}
            value={data.paymentID}
            options={[...payments, { value: "", label: "Not Assigned" }]}
            onChange={(value) => {
              onChange("paymentID", value);
              onUpdate();
            }}
            showLabel={false}
            showError={false}
          />
        </div>
        <ListLabel value={data.issuer} textAlign="center" />
        <ListLabel
          value={formatDate(data.issuedDate) || "-"}
          textAlign="left"
        />
        <ListLabel
          value={`${data.amount.currency} ${numberFormat(data.amount.value)}`}
          textAlign="left"
        />
        <div className={styles.normal}>
          <StatusBadgePoint status={data.status} />
        </div>
        <div className={styles.normal}>
          <Tooltip title={t("component.orderInvoiceList.share")}>
            <Share
              onClick={(e) => {
                e.stopPropagation();
                setOpen("share");
              }}
              className={styles.shareContainer}
            />
          </Tooltip>
        </div>
      </ListRow>

      <SendMail
        title={t("component.orderInvoiceList.invoice")}
        onDownload={async (lang) => {
          setLoading("download");
          await downloadInvoicePDF(
            orderId,
            lang,
            "paymentInvoice",
            data.id,
            false
          );
          setLoading("");
        }}
        onOpenPdf={async (lang) => {
          setLoading("open");
          await downloadInvoicePDF(
            orderId,
            lang,
            "paymentInvoice",
            data.id,
            true
          );
          setLoading("");
        }}
        loading={loading === "send"}
        loadingDownload={loading === "download" || loading === "open"}
        contactEmail={contactEmail}
        id={data.id}
        onSend={async (medium: string, contact: string, language: string) => {
          try {
            setLoading("send");
            await sendMessage(
              orderId,
              language,
              medium,
              contact,
              "paymentInvoice",
              "",
              data.id
            );
            message.success(`Invoice successfully via ${medium} shared.`);
            setOpen("");
            setLoading("");
          } catch (err) {
            setLoading("");
            if (err instanceof APIError) {
              message.error(`${err.message}: ${err.messages}`);
            }
          }
        }}
        onCancel={() => setOpen("")}
        open={open === "share"}
      />
    </>
  );
};

export default InvoiceItem;
