const translation = {
  en: {
    periodicity: "Peiodicity",
    checkedInPeriod: "Checked-in period",
    description: "Description",
    diveShop: "Dive Shop",
    uploadImage: "Upload Image",
    availability: "Availability",
    freeSell: "Free Sell",
    confirmationDiveShop: "Get Confirmation from Dive Shop",
    ticketing: "Ticketing",
    customerName: "Customer name is mandatory",
    locationOrVenueRelated: "Location or venue related to this offer",
    addAVenue: "+ Add a Venue",
    addMap: "Add map location",
    addVenue: "Add Venue",
    availableLanguages: "Available languages for this offer",
    addLanguage: "Add a Language",
    cancel: "Cancel",
    add: "Add",
    selectLanguage: "Select language",
    search: "Search",
    tagsForThisOffer: "Tags for this Offer",
    addLabelEnter: "Add label and press enter",
    details: "Details",
    editButtonLabel: "Edit",
    labelDescription: "Description",
    languages: "Available Languages",
    location: "Location",
    ticketingTitle: "Ticketing",
    enableTicketInstructions: "Tickets enabled for this offer",
    checkinPeriod: "Checkin-in period",
    lifeBased: "Life Based",
    lifeBasedDescription: "(How many times the ticket can be used)",
    totalLives: "Total Lives",
    timeBased: "Time Based",
    lifeTime: "Life Time",
    minutes: "minutes",
    activationRequired: "Activation Required",
    properties: "Properties",
    addPropertyLabel: "Add Property",
    propertyName: "Property Name",
    valueType: "Value Type",
    actions: "",
    ticketTypes: "Ticket type(s)",
    modifieres: "Modifiers",
    addNewModifier: "Add a New Modifier",
    modifierName: "Modifier Name",
    additionalOptions: "Values",
    status: "Status",
    optionName: "Value Name",
    price: "Price",
    emptyList: "Empty List",
    emptyListInstructions: "Please start to add Modifieres",
    emptyButtonTextModifier: "Add a New Modifier",
    active: "Active",
    inactive: "Inactive",
    emptyFrequencyInstructions: "Please add available days and hours.",
    addButtonFrequency: "Add Frequency List",
    freqList: "Frequency Lists",
    frequency: "Frequency",
    freqName: "List Name",
    freqDateRange: "Date Range",
    ticketingNotEnabled: "Ticketing not enabled",
    maxEvents: "Max Usage Events",
    activation: "Activation",
    atIssuance: "At Issuance",
    atDateTime: "At Date Time",
    atCheckin: "At Check-in",
    activationType: "Type",
    validFrom: "Valid From",
    lifeTimeType: "Type",
    duration: "Duration",
    endOf: "End Of",
    unit: "Unit",
    amount: "Amount",
    value: "Value",
    propertyValue: "Property Value",
    propertyDescription:
      "The properties may be set from this interface assuming that a hard coded value is needed for all orders containing this offer. If your offering system generates a dynamic value and you would like to overwrite it for each order, please set a value here and make sure your api sends the externally generated value",
  },
  es: {},
  de: {},
};

export default translation;
