import React, { useState } from "react";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { DatePicker, TimePicker } from "antd";
import { useTranslation } from "react-i18next";
import { timezones } from "../../constants/timezones";
import InputTextDropdown from "../Inputs/InputTextDropdown";
import Text from "../Text";
import { Checkbox } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { ReactComponent as Shrink } from "../../assets/chevron-left.svg";
import styles from "./styles";
import { numberFormatNoDecimals } from "../../utils/format";
import { FacetEvent, Filter } from "../../services/orders";
import { InputSelect } from "../Inputs/InputSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import fontColorContrast from "font-color-contrast";

interface Props {
  onChangeDate?: (date: any) => void;
  onChangeTimeZone?: (value: string) => void;
  from?: string;
  datetimeHourFrom?: string;
  datetimeHourTo?: string;
  to?: string;
  timezone?: string;
  facets: FacetEvent[];
  onChange: (target: string, option: string) => void;
  selected: Filter[];
  shrink: boolean;
  setSize: (value: boolean) => void;
  dateType?: string;
  setDateType?: (value: string) => void;
  tab?: string;
  onChangeExlusion: (target: string, value: boolean) => void;
  onChangeSelect: (target: string, value: string) => void;
  onChangeHour?: (from: any, to: any) => void;
  savedFilter: string;
  onSaveFilter: (value: string) => void;
  filters: { value: string; label: string }[];
  onDeleteFilter: (value: string) => void;
}

const SideFilters: React.FC<Props> = ({
  onChangeDate,
  from,
  to,
  onChangeTimeZone,
  timezone,
  onChange,
  facets = [],
  shrink,
  selected,
  dateType,
  setDateType,
  setSize,
  tab,
  onChangeHour,
  filters,
  savedFilter,
  onSaveFilter,
  onDeleteFilter,
  datetimeHourFrom,
  datetimeHourTo,
}) => {
  const { t } = useTranslation();
  const [visibleFacets, setVisibleFacets] = useState<Record<string, boolean>>(
    {}
  );
  const datetimeFromDayjs = datetimeHourFrom
    ? dayjs().hour(parseInt(datetimeHourFrom)).minute(0).second(0)
    : null;
  const datetimeToDayjs = datetimeHourTo
    ? dayjs().hour(parseInt(datetimeHourTo)).minute(0).second(0)
    : null;

  const [open, setOpen] = useState<Record<string, boolean>>({});
  const dateOrderOptions = [
    { label: "Created", value: "createdAt" },
    { label: "Channel Created", value: "order.channel.createdAt" },
    { label: "Payment", value: "paidAt" },
    { label: "Payment Due Date", value: "paymentDueDate" },
  ];

  const eventOptions = [
    { label: "Event Date", value: "datetime" },
    { label: "Created", value: "createdAt" },
  ];

  function camelCaseToWords(input: string) {
    const separatedWords = input.replace(/([A-Z])/g, " $1").trim();
    return separatedWords.charAt(0).toUpperCase() + separatedWords.slice(1);
  }

  function totalRangeFormat(value: string) {
    const parts = value.split("-");
    if (parts.length === 2) {
      const formattedFirstPart = formatNumberPart(parts[0]);
      const formattedSecondPart = formatNumberPart(parts[1]);
      return `${formattedFirstPart}-${formattedSecondPart}`;
    } else if (parts.length === 3) {
      const formattedFirstPart = formatNumberPart(parts[0] + "-" + parts[1]);
      const formattedSecondPart = formatNumberPart(parts[2]);
      return `${formattedFirstPart}-${formattedSecondPart}`;
    } else {
      return formatNumberPart(value);
    }
  }

  function formatNumberPart(part: string) {
    if (part.includes("*")) {
      return "*";
    }
    return numberFormatNoDecimals(part);
  }

  return (
    <div
      className={
        !shrink ? styles.filterContainerShrink : styles.filterContainer
      }
    >
      <FlexContainer padding="0 0 2em 0" justifyContent="space-between">
        {shrink ? (
          <Text
            fontSize="20px"
            fontWeight={700}
            text={t("component.orderFilters.filters")}
          />
        ) : null}
        <Shrink
          className={shrink ? styles.iconShrink : styles.iconShrinkTransform}
          onClick={() => setSize(!shrink)}
        />
      </FlexContainer>

      {!shrink ? (
        <div className={styles.textHorizonatl}>
          {t("component.orderFilters.filters")}
        </div>
      ) : (
        <>
          <FlexContainer padding="0 0 1em 0" justifyContent="space-between">
            <Text fontWeight={700} text={t("component.orderFilters.date")} />
          </FlexContainer>
          <InputSelect
            showLabel={false}
            options={tab === "events" ? eventOptions : dateOrderOptions}
            value={dateType}
            onChange={setDateType}
          />
          <DatePicker.RangePicker
            style={{ fontFamily: "Lato", fontSize: "0.1em" }}
            className={styles.calendar}
            format="YYYY-MM-DD"
            value={[from ? dayjs(from) : null, to ? dayjs(to) : null]}
            onChange={(date, dateString) => {
              onChangeDate?.(dateString);
            }}
          />
          <InputTextDropdown
            placeholder={t("component.orderFilters.timezone")}
            value={timezone}
            top={"calc(100% - 1em)"}
            options={[
              { label: "No Timezone Set", value: "" },
              ...timezones.map((item) => ({
                value: item,
                label: item,
              })),
            ]}
            onChange={(value) => onChangeTimeZone?.(value)}
          />

          {filters.length > 0 && (
            <div style={{ paddingTop: "1em" }}>
              <InputSelect
                erase
                onDelete={(value) => onDeleteFilter(value)}
                options={filters}
                label={t("container.orders.savedFilters")}
                value={savedFilter}
                onChange={(value) => onSaveFilter(value)}
              />
            </div>
          )}

          <div style={{ paddingTop: "1em" }} />
          {facets
            ?.slice()
            ?.sort((a, b) => a.chronologicalIndex - b.chronologicalIndex)
            ?.map(
              (item, index) =>
                item.data.length > 0 && (
                  <div key={item.name + index}>
                    <FlexContainer
                      padding={
                        !open[item.name] ? "1.5em 0 1em 0" : "2em 0 1em 0"
                      }
                      justifyContent="flex-start"
                    >
                      <FontAwesomeIcon
                        color={"#333"}
                        style={{ cursor: "pointer", marginRight: "1em" }}
                        onClick={() =>
                          setOpen((prev) => ({
                            ...prev,
                            [item.name]: !prev[item.name],
                          }))
                        }
                        icon={
                          !open[item.name]
                            ? (faChevronRight as IconProp)
                            : (faChevronDown as IconProp)
                        }
                      />
                       <div
                        className={styles.dot}
                        style={{
                          background: item.color,
                          marginRight: "1em",
                          color: fontColorContrast(item.color),
                        }}
                      >
                        {item.field === "tags" ? "T" : "P"}
                      </div>
                      <Text
                        fontWeight={700}
                        text={camelCaseToWords(item.name)}
                      />
                      {/* {open[item.name] ? (
                        <div
                          className={styles.link}
                          onClick={(e) => {
                            e.preventDefault();
                            const currentInclude = selected.find(
                              (filter) => filter.name === item.field
                            )?.include;
                            const newValue =
                              currentInclude === "Any" ? "All" : "Any";
                            onChangeSelect(item.field, newValue);
                          }}
                        >
                          <Space>
                            {
                              selected.find(
                                (filter) => filter.name === item.field
                              )?.include
                            }
                          </Space>
                        </div>
                      ) : null} */}
                    </FlexContainer>
                    <>
                      {open[item.name] ? (
                        <>
                          {/* <div style={{ padding: "0 0 1em 0" }}>
                            <Radio.Group
                              onChange={(e) => {
                                const value = e.target.value;
                                onChangeExlusion(item.field, value);
                              }}
                              value={selected
                                .find((filter) => filter.name === item.field)
                                ?.items.every((item) => item.include === true)}
                            >
                              <Radio value={true}>Include</Radio>
                              <Radio value={false}>Exclude</Radio>
                            </Radio.Group>
                          </div> */}

                          {item.field.includes("datetimeHour") ? (
                            <></>
                          ) : item.data.length > 4 ? (
                            <div
                              style={{ marginBottom: "1em", marginTop: "1em" }}
                              className={styles.buttonText}
                              onClick={() =>
                                setVisibleFacets((prev) => ({
                                  ...prev,
                                  [item.name]: !prev[item.name],
                                }))
                              }
                            >
                              {visibleFacets[item.name]
                                ? "See Less"
                                : "See More"}
                            </div>
                          ) : null}
                          {item.field.includes("datetimeHour") ? (
                            <>
                              <TimePicker.RangePicker
                                onChange={(
                                  value: [Dayjs | null, Dayjs | null] | null
                                ) => {
                                  if (value && value[0] && value[1]) {
                                    const hour1 = value[0].hour();
                                    const hour2 = value[1].hour();
                                    onChangeHour?.(hour1, hour2);
                                  }
                                }}
                                format="HH"
                                showMinute={false}
                                showSecond={false}
                                value={[datetimeFromDayjs, datetimeToDayjs]}
                              />
                            </>
                          ) : null}

                          {item.data
                            .slice(
                              0,
                              visibleFacets[
                                item.name as keyof typeof visibleFacets
                              ]
                                ? item.data.length
                                : 4
                            )
                            .map((option, index) =>
                              item.field.includes("datetimeHour") ? (
                                <></>
                              ) : (
                                <div
                                  style={{ paddingTop: "0.5em" }}
                                  key={option.key + index + "n"}
                                >
                                  <Checkbox
                                    checked={selected
                                      .find(
                                        (filter) => filter.name === item.field
                                      )
                                      ?.items.some(
                                        (item) => item.key === option.key
                                      )}
                                    style={{ marginRight: "0.5em" }}
                                    onChange={() => {
                                      onChange(item.field, option.key);
                                    }}
                                  />
                                  {item.field.includes("totalRanges")
                                    ? totalRangeFormat(option.text)
                                    : option.text}
                                  {option.doc_count === 0
                                    ? ""
                                    : ` (${numberFormatNoDecimals(
                                        option.doc_count
                                      )})`}
                                </div>
                              )
                            )}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  </div>
                )
            )}
        </>
      )}
    </div>
  );
};

export default SideFilters;
