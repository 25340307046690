import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fulfillmentList as fulfillmentListService,
  FulfillmentListParams,
  FulfillmentSearchResponse,
} from "../services/fulfilments";

type FulfillmentsSliceState = {
  loading: boolean;
  error: string;
  list: FulfillmentSearchResponse;
};

const initialState: FulfillmentsSliceState = {
  loading: true,
  error: "",
  list: {
    page: 0,
    total: 0,
    data: [],
  },
};

export const fulfillmentList = createAsyncThunk(
  "fulfillment/list",
  async (params: FulfillmentListParams) => {
    const response = await fulfillmentListService(params);
    return response;
  }
);

const fulfillmentsSlice = createSlice({
  name: "fulfillment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fulfillmentList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(fulfillmentList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.list = action.payload;
    });
    builder.addCase(fulfillmentList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An unexpected error ocurred";
    });
  },
});

export default fulfillmentsSlice.reducer;
