const translation = {
  en: {
    rol: "Role",
    scope: "Scope",
    description: "Description",
    createRole: "+ Create Role",
    users: "Users",
  },
  es: {
    rol: "Rol",
    users: "Usuarios",

    scope: "Alcance",
    description: "Descripción",
    createRol: "+ Crear Rol",
  },
  de: {},
};

export default translation;
