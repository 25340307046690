
import React, { useState } from "react";
import NewEvent from "./NewEvent";
import { useTranslation } from "react-i18next";
import { FulfillmentEvent } from "@innomius/ravent-typescript-types";
import { defaultFulfillmentEvent } from "../../../services/fulfilments";
import { message } from "antd";
import APIError from "../../../utils/APIError";
import { Asset } from "../../../utils/types";
import { Modal } from "../../Modal/Modal";
import { ButtonsContainer } from "../../ButtonsContainer";
import { Button } from "../../Button";
import { FlexContainer } from "../../FlexContainer/FlexContainer";
import { ContainerTitle } from "../../ContainerTitle";

interface OrderEventFormProps {
  onClose: () => void;
  open: boolean;
  id: string;
  onCreateEvent: (placement: FulfillmentEvent) => void;
  loading?: string;
  data: FulfillmentEvent;
  assets: Asset[];
}

const OrderEventForm: React.FC<OrderEventFormProps> = ({
  onClose,
  onCreateEvent,
  data,
  open,
  assets,
}) => {
  const { t } = useTranslation();
  const [location, setLocation] = useState<FulfillmentEvent>(data);
  const [error, setError] = useState("");

  const onChangeEvent = <P extends keyof FulfillmentEvent>(
    prop: P,
    value: FulfillmentEvent[P]
  ) => {
    setLocation({ ...location, [prop]: value });
  };

  // poner lat y lng a la hora que se crea

  return (
    <Modal
      buttons={
        <ButtonsContainer padding="1em 0 0 0" justifyContent="flex-end">
          <Button
            label={t("component.orderLocation.cancel")}
            theme="white"
            onClick={() => {
              onClose();
              setError("");
              setLocation(defaultFulfillmentEvent);
            }}
          />
          <Button
            label={t("component.orderLocation.save")}
            onClick={async () => {
              if (!location.name) {
                setError("This field is mandatory");
                return;
              }
              try {
                await onCreateEvent(location);
                onClose();
                setLocation(defaultFulfillmentEvent);
              } catch (err) {
                if (err instanceof APIError) message.error(err.message);
              }
            }}
          />
        </ButtonsContainer>
      }
      width="60%"
      open={open}
    >
      <FlexContainer justifyContent="space-between">
        <ContainerTitle
          padding="0em 0 0 0"
          size="medium"
          label={t("component.orderLocation.createEvent")}
        />
      </FlexContainer>
      <NewEvent
        error={error}
        setError={setError}
        event={location}
        onChangeEvent={onChangeEvent}
      />
    </Modal>
  );
};

export default OrderEventForm;
