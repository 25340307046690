const translation = {
  en: {
    successCreation: "Role succesfully created.",
    back: "Back to Roles",
    cancel: "Cancel",
    save: "Save",
    createUserLabel: "+ Create Role",
    details: "Details",
    permissions: "Permissions",
    roleName: "Role Name",
  },
  es: {
    roleName: "Nombre del Rol",
    details: "Detalles",
    permissions: "Permisos",
    successCreation: "Rol creado con éxito",
    back: "Regresar a Roles",
    cancel: "Cancelar",
    save: "Guardar",
    createUserLabel: "+ Crear Rol",
  },
  de: {},
};

export default translation;
