const translation = {
  en: {
    import: "Import from CSV",
    locations: "Locations",
    upload: "Upload",
    drag: "Drag and drop or select a file here",
    rows: "Rows",
    close: "Close",
    create: "Upload",
  },
  es: {
    close: "Cerrar",
    create: "Subir",
    rows: "Filas",
    import: "Importar Archivo CSV",
    locations: "Lugares",
    upload: "Subir",
    drag: "Arrastra y suelta o selecciona un archivo aquí",
  },
  de: {},
};

export default translation;
