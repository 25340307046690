import {
  Order,
  OrderLine,
  OrderState,
} from "@innomius/ravent-typescript-types";
import get from "lodash/get";
import {
  parseTax,
  parseDiscounts,
  parseSurcharges,
  parsePayment,
} from "./payments";
import fetch from "../utils/fetch";
import { getAccessToken } from "./auth";
import { isNil, omitBy } from "lodash";
import { parseOrderComment } from "./comments";
import { dateFilters } from "../utils/times";
import {
  CreateOrder,
  CreateOrderline,
  Customer,
  OrderStateSummary,
  OrderStateSummaryCount,
  WebHookNotifications,
} from "../utils/types";
import { parseCertificate } from "./passes";
import { parseFEvent } from "./events";
import { uploadFiles } from "./fulfilments";
import { RunParams } from "./actions";
import multiPartFetch from "../utils/multiPartFetch";

export function parseOrderItem(data: unknown): OrderLine {
  return {
    id: get(data, "_id", ""),
    created: get(data, "createdAt", ""),
    createdAt: get(data, "createdAt", ""),
    shortId: get(data, "shortId", ""),

    updated: get(data, "updated", ""),
    updatedAt: get(data, "updatedAt", ""),
    state: get(data, "state", ""),
    deleted: get(data, "deleted", false),
    orderId: get(data, "order_id", ""),
    totalDiscount: get(data, "totalDiscount", 0),
    fulfillmentDate: get(data, "fulfillmentDate", ""),
    totalSurcharge: get(data, "totalSurcharge", 0),
    currency: get(data, "currency", ""),

    totalTaxes: get(data, "totalTaxes", 0),

    details: get(data, "details", ""),
    organizationId: get(data, "organizationId", ""),
    sku: get(data, "sku", ""),
    fulfillmentDefinitionId: get(data, "fulfillmentDefinitionId", ""),
    fulfillerId: get(data, "fulfillerId", ""),
    fulfillmentTeamId: get(data, "fulfillmentTeamId", ""),
    fulfillmentTeamName: get(data, "fulfillmentTeamName", ""),
    quantity: get(data, "quantity", 0),
    discounts: get(data, "discounts", []).map(parseDiscounts),
    total: get(data, "total", 0),
    unitPrice: get(data, "unitPrice", 0),

    surcharges: get(data, "surcharges", []).map(parseSurcharges),
    taxes: get(data, "taxes", []).map(parseTax),
    subTotal: get(data, "subTotal", 0),

    ticketingStatus: get(
      data,
      "ticketingStatus",
      "" as "not-required" | "issued" | "not-issued"
    ),
    description: get(data, "description", ""),

    fulfillmentState: get(data, "fulfillmentState", ""),

    certificateDefinitionId: get(data, "certificateDefinitionId", ""),
    tickets: get(data, "tickets", []).map(parseCertificate),
  };
}

export function parseOrder(data?: unknown): Order {
  return {
    id: get(data, "_id", ""),
    colorizedTags: get(data, "colorizedTags", []),
    currency: get(data, "currency", ""),
    externalId: get(data, "externalId", ""),
    venueName: get(data, "venueName", ""),
    notes: get(data, "notes", ""),
    paymentDate: get(data, "paidAt", ""),
    fulfillmentDate: get(data, "fulfillmentDate", ""),
    organizationId: get(data, "organization_id", ""),
    customer: {
      fullName: get(data, "customer.fullName", ""),
      email: get(data, "customer.email", ""),
      phone: get(data, "customer.phone", ""),
      externalId: get(data, "customer.externalId", ""),
    },
    description: get(data, "description", ""),
    paymentDueDate: get(data, "paymentDueDate", ""),
    bookmarked: get(data, "bookmarked", false),
    totalAmount: get(data, "total", 0),

    totalTaxes: get(data, "totalTaxes", 0),

    subTotalAmount: get(data, "subTotal", 0),

    surcharges: get(data, "totalSurcharge", 0),

    dueAmount: get(data, "due", 0),

    paymentState: get(data, "paymentState", "unpaid"),
    createdBy: get(data, "createdBy", ""),
    fulfillmentLevel: get(data, "fulfillmentLevel", "none"),
    locationLevel: get(data, "locationLevel", "none"),
    fulfillmentDefinitionId: get(data, "fulfillmentDefinitionId", ""),

    fulfillerId: get(data, "fulfillerId", ""),
    fulfillmentTeamId: get(data, "fulfillmentTeamId", ""),
    fulfillmentTeamName: get(data, "fulfillmentTeamName", ""),

    fulfillmentState: get(data, "fulfillmentState", ""),
    tags: get(data, "tags", []),
    state: get(data, "state", "" as OrderState),
    shortId: get(data, "shortId", ""),
    payments: get(data, "payments", []).map(parsePayment),
    paymentRequests: get(data, "paymentRequests", []).map(parsePayment),
    comments: get(data, "comments", []).map(parseOrderComment),
    refunds: [],
    totalDiscount: get(data, "totalDiscount", 0),

    shippingAmount: get(data, "shippingAmount", 0),

    taxAmount: get(data, "taxAmount", 0),

    paidAmount: get(data, "paid", 0),

    walletId: get(data, "walletId", ""),
    channel: {
      data: get(data, "channel.data", {}),
      name: get(data, "channel.name", ""),
      id: get(data, "channel.id", ""),
      employeeId: get(data, "channel.employeeId", ""),
      employeeName: get(data, "channel.employeeName", ""),
      createdAt: get(data, "channel.createdAt", ""),
      createdBy: get(data, "channel.createdBy", ""),
      orderCreationDate: get(data, "channel.creationDate", ""),
    },
    orderlines: get(data, "orderlines", []).map(parseOrderItem),
    createdAt: get(data, "createdAt", ""),
    created: get(data, "createdAt", ""),
    review: {
      collectPayment: get(data, "review.collectPayment", false),
      issueTickets: get(data, "review.issueTickets", false),
      replyMessage: get(data, "review.replyMessage", false),
      readyOrder: get(data, "review.readyOrder", false),
      resetOrder: get(data, "review.resetOrder", false),
      refundOrder: get(data, "review.refundOrder", false),
    },
    archived: get(data, "archived", false),
    updated: get(data, "updatedAt", ""),
    deleted: get(data, "deleted", false),
    erp: {
      name: get(data, "erp.name", ""),
      created: get(data, "erp.created", ""),
      data: {
        clientId: get(data, "erp.orderData.ClientID", ""),
        cdfiUse: get(data, "erp.orderData.CFDIUse", ""),
      },
      integrationTemplateId: get(data, "erp.integrationTemplateId", ""),
      integrationConnectionId: get(data, "erp.integrationConnectionId", ""),
      referenceId: get(data, "erp.orderId", ""),
      clientId: get(data, "erp.customerId", ""),
      customerUrl: get(data, "erp.customerUrl", ""),
      orderUrl: get(data, "erp.orderUrl", ""),
    },
    certificateDefinitionId: get(data, "certificateDefinitionId", ""),
    pass: parseCertificate(get(data, "pass", "")),
    tickets: get(data, "tickets", []).map(parseCertificate),
  };
}

export interface OrderSearchResponse {
  data: Order[];
  page: number;
  total: number;
}

export interface OrderListParams {
  page?: number;
  records_per_page?: number;
  sort?: string;
  stateIn?: string;
  paymentStateIn?: string;
  text?: string;
  externalCustomerId?: string;
  from?: string;
  to?: string;
  timezone?: string;
  shortId?: string;
  externalCustomerFullName?: string;
  description?: string;
  venueId?: string;
}

function mapSorts(data: OrderListParams) {
  return omitBy(
    {
      page: data.page,
      records_per_page: data.records_per_page,
      stateIn: data.stateIn || null,
      paymentStateIn: data.paymentStateIn || null,
      sort: data.sort || null,
      text: data.text || null,
      venue_id: data.venueId || null,
      externalCustomerId: data.externalCustomerId || null,
      from: data.from ? dateFilters(data.from, "00:00:00.000") : null,
      to: data.to ? dateFilters(data.to, "23:59:00.000") : null,
      timezone: data.timezone || null,
      shortId: data.shortId || null,
      externalCustomerFullName: data.externalCustomerFullName || null,
    },
    isNil
  );
}

export async function orderList(
  params: OrderListParams
): Promise<OrderSearchResponse> {
  const token = await getAccessToken();
  const res = await fetch("/orders", token, "GET", null, mapSorts(params));
  return {
    data: res.body ? res.body.hits.map(parseOrder) : [],
    page: get(res.body, "page", 0),
    total: get(res.body, "count", 0),
  };
}

export async function orderRead(id: string): Promise<Order> {
  const token = await getAccessToken();
  const res = await fetch(`/orders/${id}`, token);
  const data = parseOrder(res.body);

  return data;
}

export async function updateOrder(
  id: string,
  update: Record<string, any>
): Promise<Order> {
  const token = await getAccessToken();
  const body = update;
  const res = await fetch(`/orders/${id}`, token, "PATCH", body);
  const data = parseOrder(res.body);
  return data;
}

export async function updateCustomerOrder(
  id: string,
  customer: Customer
): Promise<Order> {
  const token = await getAccessToken();
  const body = {
    customer: {
      fullName: customer.fullName,
      phone: customer.phone,
      email: customer.email,
    },
  };
  const res = await fetch(`/orders/${id}`, token, "PATCH", body);
  const data = parseOrder(res.body);
  return data;
}

export async function cancelOrder(id: string): Promise<Order> {
  const token = await getAccessToken();
  const body = {
    state: "cancelled",
  };
  const res = await fetch(`/orders/${id}/state`, token, "PATCH", body);
  const data = parseOrder(res.body);
  return data;
}

export async function closeOrder(id: string): Promise<Order> {
  const token = await getAccessToken();
  const body = {
    state: "fulfilled",
  };
  const res = await fetch(`/orders/${id}/state`, token, "PATCH", body);
  const data = parseOrder(res.body);
  return data;
}

export async function closeOrderline(id: string): Promise<Order> {
  const token = await getAccessToken();
  const body = {
    state: "fulfilled",
  };
  const res = await fetch(`/orderlines/${id}/state`, token, "PATCH", body);
  const data = parseOrder(res.body);
  return data;
}

export async function updateOrderLine(
  id: string,
  update: Record<string, unknown>
): Promise<Order> {
  const token = await getAccessToken();
  const body = update;
  const res = await fetch(`/orderlines/${id}`, token, "PATCH", body);
  const data = parseOrder(res.body);
  return data;
}

export async function updatePriceOrderline(
  id: string,
  ol: CreateOrderline
): Promise<Order> {
  const token = await getAccessToken();
  const body = {
    orderlines: [
      {
        id: id,
        updateOrderlineDto: {
          discounts: ol.discounts,
          unitPrice: parseFloat(ol.unitPrice),
          surcharges: ol.surcharges,
          taxes: ol.taxes,
        },
      },
    ],
  };
  const res = await fetch(`/orderlines/batch/price`, token, "PATCH", body);
  const data = parseOrder(res.body);
  return data;
}

export const numberValidation = (inputtxt: string) => {
  const numbers = /^[-+]?[0-9]+$/;
  if (inputtxt.match(numbers)) {
    return true;
  } else {
    return false;
  }
};

export async function deleteOrder(id: string): Promise<void> {
  const token = await getAccessToken();
  await fetch(`/orders/${id}`, token, "DELETE");
  return;
}

export async function replicateOrderERP(id: string): Promise<Order> {
  const token = await getAccessToken();
  const res = await fetch(`/orders/erp`, token, "POST", { order_id: id });
  const data = parseOrder(res.body);
  return data;
}

export function parseWebHookNotification(data?: unknown): WebHookNotifications {
  return {
    id: get(data, "_id", ""),
    event: get(data, "data.event", ""),
    eventTimestamp: get(data, "data.timestamp", ""),
    createdAt: get(data, "createdAt", ""),
    createdBy: get(data, "createdBy", ""),
    deleted: get(data, "deleted", false),
    error: get(data, "error", false),
    orderId: get(data, "orderId", ""),
    type: get(data, "type", ""),
    orderlineId: get(data, "orderline_id", ""),

    url: get(data, "url", ""),
    responseStatusCode: get(data, "responseStatusCode", 0),
  };
}

export async function getOrderWebHookNotifications(
  orderId: string
): Promise<WebHookNotifications[]> {
  const token = await getAccessToken();
  const res = await fetch(
    `/orders/${orderId}/webhookNotifications`,
    token,
    "GET"
  );
  return res.body ? res.body.hits.map(parseWebHookNotification) : [];
}

export function parseFulfillmentStateDefSummary(data?: any): OrderStateSummary {
  if (!data) {
    return {
      state: [],
      paymentState: [],
    };
  }

  const stateKeys = Object.keys(data.state);
  const paymentStateKeys = Object.keys(data.paymentState);

  const stateArray: OrderStateSummaryCount[] = stateKeys.map((key) => ({
    id: key,
    total: data.state[key].total,
    count: data.state[key].count,
    countPercent: data.state[key].countPercent,
  }));

  const paymentStateArray: OrderStateSummaryCount[] = paymentStateKeys.map(
    (key) => ({
      id: key,
      total: data.paymentState[key].total,
      count: data.paymentState[key].count,
      countPercent: data.paymentState[key].countPercent,
    })
  );

  return {
    state: stateArray,
    paymentState: paymentStateArray,
  };
}

export interface SummaryParams {
  groupingField: string;
  from: string;
  to: string;
  stateIn: string;
  currency: string;
  venueId?: string;
  fulfillmentLevel?: string;
  fulfillmentDefinitionId?: string;
  timezone?: string;
}

function mapSortsState(data: SummaryParams) {
  return omitBy(
    {
      groupingFields: "paymentState,state",
      stateValues: data.stateIn || null,
      from: data.from ? dateFilters(data.from, "00:00:00.000") : null,
      to: data.to ? dateFilters(data.to, "23:59:00.000") : null,
      currency: data.currency || null,
      venue_id: data.venueId,
      timezone: data.timezone,
      fulfillmentLevel: data.fulfillmentLevel,
      fulfillmentDefinitionId: data.fulfillmentDefinitionId || null,
    },
    isNil
  );
}

export async function getOrderStateSummary(
  params: SummaryParams
): Promise<OrderStateSummary> {
  const token = await getAccessToken();
  const res = await fetch(
    "/orders/summary/financial",
    token,
    "GET",
    null,
    mapSortsState(params)
  );
  return parseFulfillmentStateDefSummary(res.body);
}

export async function expireOrder(id: string, userId: string): Promise<void> {
  await fetch(`/orders/${id}/expire/${userId}`, null);
  return;
}

// export async function notifyWebHook(id: string, url: string) {
//   const token = await getAccessToken();
//   const res = await fetch(`/orders/${id}/webhookNotifications`, token, "POST", {
//     url: url,
//   });

//   return res.body;
// }

export async function setOrderlineState(
  id: string,
  state: string,
  w3w: string,
  images: string[]
): Promise<Order | void> {
  const token = await getAccessToken();
  const body = {
    name: state,
    location: {
      type: "w3w",
      w3w: w3w,
    },
  };
  const res = await fetch(
    `/orderlines/${id}/fulfillmentState`,
    token,
    "POST",
    body
  );
  const data = parseOrder(res.body);
  if (images.length) {
    await uploadFiles(data.id, images);
    return;
  }
  return data;
}

export async function setOrderState(
  orderId: string,
  state: string,
  w3w: string,
  images: string[]
): Promise<Order | void> {
  const token = await getAccessToken();
  const body = {
    name: state,
    location: {
      type: "w3w",
      w3w: w3w,
    },
  };
  const res = await fetch(
    `/orders/${orderId}/fulfillmentState`,
    token,
    "POST",
    body
  );
  const data = parseOrder(res.body);
  if (images.length) {
    await uploadFiles(data.id, images);
    return;
  }
  return data;
}

export const defaultCreateOrderline: CreateOrderline = {
  details: "",
  id: "",
  currency: "",
  description: "",
  sku: "",
  fulfillmentDefinitionId: "",
  quantity: "",
  discounts: [],
  unitPrice: "",
  surcharges: [],
  taxes: [],
};

export const defaultCreateOrder: CreateOrder = {
  description: "",
  customer: {
    externalId: "",
    fullName: "",
    phone: "",
    email: "",
  },
  channel: {
    id: "",
    name: "",
  },
  externalId: "",
  tags: [],
  fulfillmentDefinitionId: "",
  orderLines: [defaultCreateOrderline],
  locationLevel: "",
  fulfillmentLevel: "",
  archived: false,
};

export const defaultCustomer: Customer = {
  fullName: "",
  email: "",
  phone: "",
};

export interface ListParams {
  page?: number;
  records_per_page?: number;
  orderId?: string;
}

function mapSortsOrderline(data: ListParams) {
  return omitBy(
    {
      page: data.page || null,
      records_per_page: data.records_per_page || null,
      order_id: data.orderId || null,
    },
    isNil
  );
}

export async function listOrderlines(params: ListParams): Promise<OrderLine[]> {
  const token = await getAccessToken();
  const res = await fetch(
    "/orderlines",
    token,
    "GET",
    null,
    mapSortsOrderline(params)
  );
  return res.body ? res.body.hits.map(parseOrderItem) : [];
}

export interface Facet {
  key: string;
  doc_count: number;
  text: string;
}

export interface FacetEvent {
  data: Facet[];
  field: string;
  name: string;
  chronologicalIndex: number;
  color: string;
}

export interface TextSearchList {
  count: number;
  page: number;
  hits: any[];
  totalRangesParams?: string;
  facets: {
    [key: string]: Facet[];
  };
}

export interface TextSearchListEvent {
  count: number;
  page: number;
  hits: any[];
  totalRangesParams?: string;
  facets: FacetEvent[];
}

export interface TextSearchResponse {
  orders: TextSearchList;
  orderlines: TextSearchList;
  fulfillmentEvents: TextSearchList;
  assets: TextSearchList;
}

export interface OrderListParamsFT {
  totalRangesParams: string;
  tags?: Filter;
  channelName?: Filter;
  page?: number | string;
  records_per_page?: number | string;
  search?: string;
  from?: string;
  to?: string;
  timezone?: string;
  total?: Filter;
  state?: Filter;
  paymentState?: Filter;
  fulfillmentState?: Filter;
  fulfillmentLevel?: Filter;
  venueId?: string;
  sort?: string;
  dateType?: string;
  currency?: Filter;
  customerName?: Filter;
}

export interface Filter {
  name: string;
  include: "Any" | "All";
  items: {
    key: string;
    from?: string;
    to?: string;
    include: boolean;
    // since:<delta range: 1 day | 1 week | ...>, // ONLY FOR DATES
    // until:<delta range: 1 day | 1 week | ...>, // ONLY FOR DATES // if it is a date
  }[];
}

export interface FilterBoolean {
  name: string;

  include: "Any" | "All";
  items: {
    key: boolean | string;
    from?: string;
    to?: string;
    include: boolean;
    // since:<delta range: 1 day | 1 week | ...>, // ONLY FOR DATES
    // until:<delta range: 1 day | 1 week | ...>, // ONLY FOR DATES // if it is a date
  }[];
}

export interface FulfilmentEventListParamsFT {
  advancedFilters?: string;
  state?: Filter;
  fulfillmentLevel?: Filter;
  tags?: Filter;
  fulfillmentTeamName?: Filter;
  fulfillerUsername?: Filter;
  placementGroup?: Filter;
  groupCode?: Filter;
  passengers?: Filter;
  fulfillerName?: Filter;
  fulfillerId?: string;

  assets?: Filter;

  datetimeHourFrom?: string;
  datetimeHourTo?: string;
  from?: string;
  to?: string;
  dateType?: string; //
  page?: number | string; //
  records_per_page?: number | string; //
  search?: string; //
  sort?: string; //
  timezone?: string; //
  venueId?: string; //
  allPlacementsHasCoordinates?: boolean;
}

interface Filters {
  total: Filter | null;
  state: Filter | null;
  paymentState: Filter | null;
  fulfillmentState: Filter | null;
  fulfillmentLevel: Filter | null;
  tags: Filter | null;
  channelName: Filter | null;
  currency: Filter | null;
  createdAt: Filter | null;
  paidAt: Filter | null;
  paymentDueDate: Filter | null;
  "channel.createdAt": Filter | null;
  customerName: Filter | null;
}

function mapSortsFullText(data: OrderListParamsFT) {
  const filters: Filters = {
    customerName: data.customerName?.items.length ? data.customerName : null,
    total: data.total?.items.length ? data.total : null,
    state: data.state?.items.length ? data.state : null,
    paymentState: data.paymentState?.items.length ? data.paymentState : null,
    fulfillmentState: data.fulfillmentState?.items.length
      ? data.fulfillmentState
      : null,
    fulfillmentLevel: data.fulfillmentLevel?.items.length
      ? data.fulfillmentLevel
      : null,
    tags: data.tags?.items.length ? data.tags : null,
    channelName: data.channelName?.items.length ? data.channelName : null,
    currency: data.currency?.items.length ? data.currency : null,
    "channel.createdAt":
      data.from && data.to && data.dateType === "order.channel.createdAt"
        ? {
            include: "All",
            name: "channel.createdAt",
            items: [
              {
                key: "channel.createdAt",
                from: dateFilters(data.from, "00:00:00.000"),
                to: dateFilters(data.to, "23:59:00.000"),
                include: true,
              },
            ],
          }
        : null,
    createdAt:
      data.from && data.to && data.dateType === "createdAt"
        ? {
            include: "All",
            name: "createdAt",
            items: [
              {
                key: "createdAt",
                from: dateFilters(data.from, "00:00:00.000"),
                to: dateFilters(data.to, "23:59:00.000"),
                include: true,
              },
            ],
          }
        : null,
    paidAt:
      data.from && data.to && data.dateType === "paidAt"
        ? {
            include: "All",
            name: "paidAt",
            items: [
              {
                key: "paidAt",
                from: dateFilters(data.from, "00:00:00.000"),
                to: dateFilters(data.to, "23:59:00.000"),
                include: true,
              },
            ],
          }
        : null,
    paymentDueDate:
      data.from && data.to && data.dateType === "paymentDueDate"
        ? {
            include: "All",
            name: "paymentDueDate",
            items: [
              {
                key: "paymentDueDate",

                from: dateFilters(data.from, "00:00:00.000"),
                to: dateFilters(data.to, "23:59:00.000"),
                include: true,
              },
            ],
          }
        : null,
  };

  const filteredFilters: Partial<Filters> = {};
  for (const key in filters) {
    if (filters[key as keyof Filters] !== null) {
      filteredFilters[key as keyof Filters] = filters[key as keyof Filters]!;
    }
  }

  const jsonString = JSON.stringify(filteredFilters);

  return omitBy(
    {
      records_per_page: data.records_per_page,
      page: data.page,
      venue_id: data.venueId || null,
      search: data.search || null,
      timezone: data.timezone || null,
      totalRangesParams: data.totalRangesParams || "",
      sort: data.sort || "_score:desc,datetime:desc",
      filters: jsonString === "{}" ? "{}" : jsonString,
    },
    isNil
  );
}

interface FiltersEvents {
  tags: Filter | null;
  state: Filter | null;
  placementGroup: Filter | null;
  fulfillerUsername: Filter | null;
  fulfillmentTeamName: Filter | null;
  groupCode: Filter | null;
  fulfillmentLevel: Filter | null;
  createdAt: Filter | null;
  datetime: Filter | null;
  assets: Filter | null;
  fulfillerId: Filter | null;
  passengers: Filter | null;
  fulfillerName: Filter | null;
  datetimeHour: Filter | null;
  "channel.createdAt": Filter | null;
  allPlacementsHasCoordinates: FilterBoolean | null;
}

function isFilterBoolean(filter: any): filter is FilterBoolean {
  return (
    (filter as FilterBoolean).items?.[0]?.key === true ||
    (filter as FilterBoolean).items?.[0]?.key === false
  );
}

function mapSortsEventFT(data: FulfilmentEventListParamsFT) {
  const filters: FiltersEvents = {
    fulfillerName: data.fulfillerName?.items.length ? data.fulfillerName : null,
    assets: data.assets?.items.length ? data.assets : null,
    tags: data.tags?.items.length ? data.tags : null,
    state: data.state?.items.length ? data.state : null,
    passengers: data.passengers?.items.length ? data.passengers : null,
    placementGroup: data.placementGroup?.items.length
      ? data.placementGroup
      : null,
    groupCode: data.groupCode?.items.length ? data.groupCode : null,
    fulfillerUsername: data.fulfillerUsername?.items.length
      ? data.fulfillerUsername
      : null,
    fulfillmentTeamName: data.fulfillmentTeamName?.items.length
      ? data.fulfillmentTeamName
      : null,
    fulfillmentLevel: data.fulfillmentLevel?.items.length
      ? data.fulfillmentLevel
      : null,
    allPlacementsHasCoordinates: data.allPlacementsHasCoordinates
      ? {
          name: "allPlacementsHasCoordinates",
          include: "Any",
          items: [
            {
              key: data.allPlacementsHasCoordinates,
              include: true,
            },
          ],
        }
      : null,
    "channel.createdAt":
      data.from && data.to && data.dateType === "order.channel.createdAt"
        ? {
            include: "All",
            name: "channel.createdAt",
            items: [
              {
                key: "channel.createdAt",
                from: dateFilters(data.from, "00:00:00.000"),
                to: dateFilters(data.to, "23:59:00.000"),
                include: true,
              },
            ],
          }
        : null,
    fulfillerId: data.fulfillerId
      ? {
          include: "All",
          name: "fulfillerId",
          items: [
            {
              key: data.fulfillerId,

              include: true,
            },
          ],
        }
      : null,
    createdAt:
      (data.from || data.to) && data.dateType === "createdAt"
        ? {
            include: "All",
            name: "createdAt",
            items: [
              {
                key: "createdAt",
                from: data.from && dateFilters(data.from, "00:00:00.000"),
                to: data.to && dateFilters(data.to, "23:59:00.000"),
                include: true,
              },
            ],
          }
        : null,
    datetimeHour: data.datetimeHourFrom
      ? {
          include: "All",
          name: "datetimeHour",
          items: [
            {
              key: "datetimeHour",
              from: data.datetimeHourFrom,
              to: data.datetimeHourTo,
              include: true,
            },
          ],
        }
      : null,
    datetime:
      data.from && data.to && data.dateType === "datetime"
        ? {
            include: "All",
            name: "datetime",
            items: [
              {
                key: "datetime",
                from: data.from && dateFilters(data.from, "00:00:00.000"),
                to: data.to && dateFilters(data.to, "23:59:00.000"),
                include: true,
              },
            ],
          }
        : data.from && data.dateType === "datetime"
        ? {
            include: "All",
            name: "datetime",
            items: [
              {
                key: "datetime",
                from: data.from && dateFilters(data.from, "00:00:00.000"),
                include: true,
              },
            ],
          }
        : null,
  };

  const filteredFilters: Partial<FiltersEvents> = {};
  for (const key in filters) {
    const filter = filters[key as keyof FiltersEvents];
    if (filter !== null) {
      if (isFilterBoolean(filter)) {
        filter as FilterBoolean;
      } else {
        filteredFilters[key as keyof FiltersEvents] = filter as Filter;
      }
    }
  }

  const jsonString = JSON.stringify(filteredFilters);
  return omitBy(
    {
      page: data.page,
      venue_id: data.venueId || null,
      records_per_page: data.records_per_page,
      timezone: data.timezone || null,
      search: data.search || null,
      filters: jsonString === "{}" ? "{}" : jsonString,
      sort: data.sort || "_score:desc,datetime:desc",
      advancedFilters: data.advancedFilters || null,
    },
    isNil
  );
}

export function parseFacet(data?: unknown): Facet {
  return {
    key: get(data, "key", ""),
    text: get(data, "text", ""),
    doc_count: get(data, "doc_count", 0),
  };
}

export function parseFacetEvent(data?: unknown): FacetEvent {
  return {
    data: get(data, "data", []).map(parseFacet),
    field: get(data, "field", ""),
    name: get(data, "name", ""),
    chronologicalIndex: get(data, "chronologicalIndex", 0),
    color: get(data, "color", ""),
  };
}

export async function orderListFullText(
  params: OrderListParamsFT
): Promise<TextSearchListEvent> {
  const token = await getAccessToken();
  const res = await fetch(
    "/orders/textSearch",
    token,
    "GET",
    null,
    mapSortsFullText(params)
  );
  return {
    totalRangesParams: "",
    hits: res.body ? res.body.hits.map(parseOrder) : [],
    page: get(res.body, "page", 0),
    count: get(res.body, "count", 0),
    facets: res.body.facets ? res.body.facets.map(parseFacetEvent) : [],
  };
}

export async function fulfillmentEventListFullText(
  params: FulfilmentEventListParamsFT
): Promise<TextSearchListEvent> {
  const token = await getAccessToken();
  const res = await fetch(
    "/events/textSearch",
    token,
    "GET",
    null,
    mapSortsEventFT(params)
  );
  return {
    hits: res.body ? res.body.hits.map(parseFEvent) : [],
    page: get(res.body, "page", 0),
    count: get(res.body, "count", 0),
    facets: res.body.facets ? res.body.facets.map(parseFacetEvent) : [],
  };
}

export interface WebHookResponse {
  message: string;
  statusCode: number;
  successful: boolean;
}

export async function notifyWebHook(data: RunParams): Promise<WebHookResponse> {
  const token = await getAccessToken();

  const body = {
    actionId: data.actionId,
    notificationBody: {
      orders: data.ordersIds || [],
      events: data.eventsIds || [],
    },
  };

  const res = await fetch("/webhookNotifications/manual", token, "POST", body);
  return res.body.webhookResponse;
}

interface ScheduleParams {
  date: Date | string;
  timezone: string;
  actionId: string;
  prettyName: string;
  ordersIds?: string[];
  eventsIds?: string[];
}

export async function scheduleWebHook(
  data: ScheduleParams
): Promise<WebHookResponse> {
  const body = {
    type: "at",
    expression: data.date,
    timezone: data.timezone,
    actionId: data.actionId,
    prettyName: data.prettyName,
    notificationBody: {
      orders: data.ordersIds || [],
      events: data.eventsIds || [],
    },
  };
  const token = await getAccessToken();
  const res = await fetch("/scheduleNotifications", token, "POST", body);
  return res.body;
}

export async function createOrderBatch(file: File, venue_id: string) {
  const token = await getAccessToken();
  const res = await multiPartFetch(
    `/orders/createBatchAsync`,
    token,
    file,
    null,
    "POST",
    venue_id
  );
  return res.body;
}

export interface OverviewCurrency {
  count: number;
  currency: string;
}

export function parseCurrencies(data?: unknown): OverviewCurrency {
  return {
    count: get(data, "count", 0),
    currency: get(data, "currency", ""),
  };
}

export async function getCurrencies(): Promise<OverviewCurrency[]> {
  const token = await getAccessToken();
  const res = await fetch("/orders/currencies", token);
  return res.body.map((item: OverviewCurrency) => parseCurrencies(item));
}
