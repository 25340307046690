import { Message } from "@innomius/ravent-typescript-types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { messageList as messageListService } from "../services/visitors";

type MessagesSliceState = {
  loading: boolean;
  error: string;
  list: Message[];
};

const initialState: MessagesSliceState = {
  loading: true,
  error: "",
  list: [],
};

export const messageList = createAsyncThunk("messages/list", async () => {
  const response = await messageListService();
  return response;
});

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(messageList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(messageList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.list = action.payload;
    });
    builder.addCase(messageList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An unexpected error ocurred";
    });
  },
});

export default messagesSlice.reducer;
