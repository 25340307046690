const translation = {
  en: {
    success: "Manual Invoice was successfully issued.",
  },
  es: {
    success: "La factura manual se emitió con éxito.",
  },
  de: {},
};

export default translation;
