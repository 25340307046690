import React from "react";
import { ListRow } from "../../List/ListRow";
import { Checkbox, Tooltip } from "antd";
import styles from "./styles";
import { ListLabel } from "../../List/ListLabel";
import { formatDate } from "../../../utils/times";
import { FulfillmentEvent } from "@innomius/ravent-typescript-types";
import { StatusBadgePoint } from "../../StatusBadgePoint/StatusBagePoint";
import { StatusCatalog } from "../../StatusBadge";

interface Props {
  data: FulfillmentEvent;
  isSelected: boolean;
  onCheckboxChange: (event: FulfillmentEvent) => void; // Accept the full FulfillmentEvent
  index: number;
}

const Item: React.FC<Props> = ({
  data,
  isSelected,
  onCheckboxChange,
  index,
}) => {
  return (
    <>
      <ListRow
        template="2em 0.5fr 2fr 0.5fr 0.5fr 0.5fr 1.5fr"
        hover={true}
        key={data.id}
        border
      >
        <div className={styles.normal} onClick={(e) => e.stopPropagation()}>
          <Checkbox
            checked={isSelected} // This should reflect the selection state
            onChange={() => {
              onCheckboxChange(data);
            }} // Pass the full event object
          />
        </div>
        <Tooltip title={data.state}>
          <div
            onClick={() => {
              window.open(`/events/${data.id}`, 'target="_blank"');
            }}
            className={styles.normalCol}
          >
            <div>{`${data.shortId}`}</div>
            <div style={{color: '#949494'}}>{`${data.orderExternalId}`}</div>
          </div>
        </Tooltip>
        <div className={styles.normalBold}>{`${data.name}`}</div>
        <ListLabel
          value={`${formatDate(data.datetime, "HOUR")} ${
            data.endDatetime ? `- ${formatDate(data.endDatetime, "HOUR")}` : ""
          }`}
          textAlign="left"
        />
        <div className={styles.center}>
          <StatusBadgePoint point status={data.state as StatusCatalog} />
        </div>

        <ListLabel value={data.loads?.length.toString()} textAlign="left" />
        <ListLabel value={data.assetName} textAlign="left" />
      </ListRow>
    </>
  );
};

export default Item;
