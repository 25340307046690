import React from "react";

import { ModifierOption } from "@innomius/ravent-typescript-types";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { InputSwitch } from "../Inputs/InputSwitch";

interface Props {
  data: ModifierOption[];
}

const OptionList: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div>
      <ListHeader background="#f3f9fe" template="1.5fr 1fr 1fr 1fr">
        <ListHeaderLabel
          value={t("component.offerVariantDetail.optionName")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.offerVariantDetail.price")}
          justifyContent="center"
        />
      </ListHeader>
      {data.map((item) => (
        <ListRow
          background="#f3f9fe"
          key={item.name}
          hover
          paddingDetail="1em"
          template="1.5fr 1fr 1fr 1fr"
        >
          <ListLabel value={item.name} />
          {item.percentage ? (
            <ListLabel
              justifyContent="center"
              value={`${
                item.operation === "substract" ? "-" : "+"
              }% ${item.amount.toString()}`}
            />
          ) : (
            <ListLabel
              justifyContent="center"
              value={`${
                item.finalPrice.currency
              } ${item.finalPrice.value.toString()}`}
            />
          )}

          <FlexContainer justifyContent="flex-start">
            <InputSwitch value={item.enabled} />
            {item.enabled ? (
              <p className={styles.green}>
                {t("component.offerVariantDetail.active")}
              </p>
            ) : (
              <p className={styles.gray}>
                {t("component.offerVariantDetail.inactive")}
              </p>
            )}
          </FlexContainer>
        </ListRow>
      ))}
    </div>
  );
};
export default OptionList;
