import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { checkLogin, login } from "../../services/auth";
import { authUser } from "../../store/auth";
import logo from "../../assets/logo.svg";
import loginStyles from "./styles";
import { InputPassword } from "../../components/Inputs/InputPassword";
import CustomError from "../../utils/CustomError";
import { get } from "lodash";
import beach from "../../assets/login/beach.jpg";
import boats from "../../assets/login/boats.jpg";
import bridge from "../../assets/login/bridge.jpg";
import city from "../../assets/login/city.jpg";
import mountains from "../../assets/login/mountains.jpg";
import road from "../../assets/login/road.jpg";
import snow from "../../assets/login/snow.jpg";
import street from "../../assets/login/street.jpg";
import { animated, useTransition } from "react-spring/web";
import { useTranslation } from "react-i18next";
// import dayjs from "dayjs";
import { Button } from "../../components/Button";
import { InputText } from "../../components/Inputs/InputText";
import { message } from "antd";

const slides = [
  { id: 8, src: road },
  { id: 7, src: mountains },
  { id: 9, src: street },
  { id: 5, src: bridge },
  { id: 6, src: city },
  { id: 3, src: beach },
  { id: 4, src: boats },
  { id: 1, src: snow },
];

interface Props {}

const Login: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [validateError, setValidateError] = useState<CustomError>();
  const [index, set] = useState(0);
  const { t } = useTranslation();
  const history = useHistory();

  const location = useLocation();

  const transitions = useTransition(slides[index], (item) => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 500,
    },
  });

  useEffect(() => {
    document.title = "RAVENT APP :: Login";
  });

  useEffect(() => {
    const interval = setInterval(async () => {
      if (index === slides.length - 1) {
        set(0);
      } else {
        set(index + 1);
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [index]);

  async function executeLogin(
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.FormEvent<HTMLFormElement>
  ) {
    if (loading) {
      return;
    }

    setValidateError(undefined);

    const queryParams = new URLSearchParams(location.search);

    const targetURL = queryParams.get("targetURL") || `/orders`;
    // const targetURL =
    //   queryParams.get("targetURL") ||
    //   `/orders?from=${dayjs(new Date()).format("YYYY-MM-DD")}&to=${dayjs(
    //     new Date()
    //   ).format("YYYY-MM-DD")}`; // Si no hay targetURL, redirige a /orders por defecto

    try {
      const active = /^\d+$/.test(username) ? "phone" : "email";

      checkLogin(active, username, password);
    } catch (err) {
      if (err instanceof CustomError) {
        setValidateError(err);
      }
      return;
    }

    try {
      const regex = /^\+?\d+$/;

      e.preventDefault();
      setLoading(true);

      const response = await login(
        regex.test(username) ? `${username}@ravent.com` : username,
        password
      );

      setLoading(false);
      dispatch(authUser(response));

      history.push(targetURL);

      return response;
    } catch (err) {
      setLoading(false);
      if (err instanceof Error) {
        message.error(err.message || "An unexpected error occurred");
      }
    }
  }

  return (
    <div className={loginStyles.container}>
      <div className={loginStyles.grid}>
        {/* <GridContainer
          columns="1fr 1fr"
          justifyContent="center"
          alignItems="center"
          gap="3em"
        > */}
        <div className={loginStyles.imageContainer}>
          {transitions.map(({ item, props, key }) => (
            <animated.div
              key={key}
              style={{
                ...props,
              }}
            >
              <img src={item.src} alt="" className={loginStyles.image} />
            </animated.div>
          ))}
        </div>
        <div className={loginStyles.inputsContainer}>
          <div>
            <img src={logo} alt="logo" className={loginStyles.logo} />
            <div className={loginStyles.subtitle}>
              {t("container.login.welcome")}
            </div>
            <div className={loginStyles.text}>
              {t("container.login.loginAccount")}
            </div>

            <InputText
              containerClassname={loginStyles.emailContainer}
              label={t("container.login.emailPhone")}
              onChange={setUsername}
              error={get(validateError, "errors.login-email", "")}
            />
            <InputPassword
              label={t("container.login.password")}
              onChange={setPassword}
              showIcon
              error={get(validateError, "errors.login-password", "")}
            />
            <Button
              containerClassname={loginStyles.emailContainer}
              width="100%"
              onClick={(e) => {
                if (loading) {
                  return;
                }
                executeLogin(e);
              }}
              label={loading ? "..." : `${t("container.login.login")}`}
            />

            <div className={loginStyles.paddingTop}>
              <div
                className={loginStyles.link}
                onClick={() => history.push("/pub/resetPassword")}
              >
                {t("container.login.forgotPassword")}
              </div>
            </div>

            <div className={loginStyles.footer}>
              <div>
                <span className={loginStyles.footerText}>
                  {t("container.login.rightsReserved")}
                </span>
              </div>
            </div>
            <div className={loginStyles.footer}>
              <div>
                <span className={loginStyles.footerText}>
                  {t("container.login.private")}
                </span>
                <span onClick={() => window.open("https://ravent.com/privacy-policy", "_blank")} className={loginStyles.footerTextUnderline}>
                  {t("container.login.policy")}
                </span>
                <span className={loginStyles.footerText}>
                  {t("container.login.understand")}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* </GridContainer> */}
      </div>
    </div>
  );
};

export default Login;
