import React, { useState } from "react";

import { Offer } from "@innomius/ravent-typescript-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { normalGray } from "../../utils/colors";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import OptionList from "./OptionList";
import deleteIcon from "../../assets/officialMedia/default-delete.svg";
import editIcon from "../../assets/officialMedia/default-edit.svg";
import styles from "./styles";
import { Popover } from "antd";
import DeletePopUp from "../DeletePopUp";
import { useTranslation } from "react-i18next";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { ListHeader } from "../List/ListHeader";
import { ListRow } from "../List/ListRow";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { InputSwitch } from "../Inputs/InputSwitch";
import { ListLabel } from "../List/ListLabel";

interface Props {
  data: Offer;
  onEdit: (id: string) => void;
}

const ModifiersList: React.FC<Props> = ({ data, onEdit }) => {
  const [open, setOpen] = useState("");
  const [deleteItem, setDelete] = useState("");

  const { t } = useTranslation();

  return (
    <div>
      <ListHeader template="1.5fr 1fr 1fr 1fr">
        <ListHeaderLabel
          value={t("component.offerVariantDetail.modifierName")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.offerVariantDetail.additionalOptions")}
          justifyContent="center"
        />
        <ListHeaderLabel
          value={t("component.offerVariantDetail.status")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.offerVariantDetail.actions")}
          justifyContent="center"
        />
      </ListHeader>
      {data.modifiers.map((item, index) => (
        <ListRow
          key={`${item.name}${index}`}
          onClick={() => {
            if (open) {
              return setOpen("");
            }
            setOpen(item.name);
          }}
          showDetail={item.name !== "" && item.name === open}
          detail={<OptionList data={item.options} />}
          hover
          template="1.5fr 1fr 1fr 1fr"
        >
          <FlexContainer justifyContent="flex-start">
            <FontAwesomeIcon
              icon={
                item.name !== "" && item.name === open
                  ? (faChevronUp as IconProp)
                  : (faChevronDown as IconProp)
              }
              className={styles.iconChevron}
              color={normalGray}
            />
            <ListLabel value={item.name} />
          </FlexContainer>
          <ListLabel
            value={item.options.length.toString()}
            justifyContent="center"
          />
          <FlexContainer justifyContent="flex-start">
            <InputSwitch value={item.enabled} />
            {item.enabled ? (
              <p className={styles.green}>
                {t("component.offerVariantDetail.active")}
              </p>
            ) : (
              <p className={styles.gray}>
                {t("component.offerVariantDetail.inactive")}
              </p>
            )}
          </FlexContainer>
          <FlexContainer>
            <img
              src={editIcon}
              alt="editIcon"
              onClick={(e) => {
                e.stopPropagation();
                onEdit(item.name);
              }}
              className={styles.iconShare}
            />
            <Popover
              placement="right"
              content={
                <div onClick={() => setDelete("")}>
                  <DeletePopUp
                    value={`Are you sure you want to delete ${item.name} Modifier?`}
                    onClose={(e) => {
                      e.stopPropagation();
                      setDelete("");
                    }}
                    onDelete={(e) => {
                      e.stopPropagation();
                      setDelete("delete");
                    }}
                  />
                </div>
              }
              trigger="click"
              open={deleteItem === item.name}
              onOpenChange={() => setDelete(deleteItem)}
            />
            <img
              src={deleteIcon}
              onClick={(e) => {
                e.stopPropagation();
                setDelete(item.name);
              }}
              alt="delete"
              className={styles.iconList}
            />
          </FlexContainer>
        </ListRow>
      ))}
    </div>
  );
};
export default ModifiersList;
