import React, { useCallback, useEffect, useState } from "react";

import { Placement } from "@innomius/ravent-typescript-types";
import { useTranslation } from "react-i18next";
import MapForm from "../../MapForm";
import { Modal } from "../../Modal/Modal";
import { Checkbox } from "antd";
import Text from "../../Text";
import InputTextDropdown from "../../Inputs/InputTextDropdown";
import { venueTypes } from "../../../constants/venues";
import styles from "./styles";
import InputTextAutoDropdown from "../../Inputs/InputTextAutoDropdown";
import { coordinatesToW3w } from "../../../services/maps";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { locationTextSearchList } from "../../../store/locationsTextSearch";
import { FlexContainer } from "../../FlexContainer/FlexContainer";
import { Button } from "../../Button";
import { ButtonsContainer } from "../../ButtonsContainer";
import { InputText } from "../../Inputs/InputText";
import { ContainerTitle } from "../../ContainerTitle";
import { GridContainer } from "../../GridContainer/GridContainer";
interface Props {
  placement: Placement;
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  onDelete: () => void;
  setLocationW3W: (location: any) => void;
  locW3W: any;
  onChange: (value: Placement) => void;
  saveLoc: (p: Placement, type: string, group: string) => void;
}

const EditPlacement: React.FC<Props> = ({
  onChange,
  placement,
  locW3W,
  onClose,
  onSave,
  onDelete,
  open,
  setLocationW3W,
  saveLoc,
}) => {
  const { t } = useTranslation();
  const [check, setChecked] = useState(false);
  const [type, setType] = useState("");
  const [group, setGroup] = useState("");
  const dispatch = useDispatch();
  const [search, setSearch] = useState(false);

  const locations = useSelector((state: RootState) => state.locationTextSearch);
  const loadLocations = useCallback(async () => {
    dispatch(
      locationTextSearchList({
        records_per_page: 5000,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (open) {
      loadLocations();
    }
  }, [loadLocations, open, search]);

  return (
    <Modal
      padding="1em 1em 1em 1em"
      buttons={
        <FlexContainer justifyContent="space-between">
          <Button
            onClick={onDelete}
            theme="red"
            label={t("component.orderLocation.delete")}
          />
          <ButtonsContainer justifyContent="flex-end">
            <Button
              onClick={() => {
                onClose();
                setSearch(false);
              }}
              theme="white"
              label={t("component.orderLocation.cancel")}
            />
            <Button
              onClick={async () => {
                try {
                  await onSave();
                  if (check) {
                    saveLoc(placement, type, group);
                  }
                  onClose();
                  setSearch(false);
                } catch (err) {}
              }}
              theme="blue"
              label={t("component.orderLocation.save")}
            />
          </ButtonsContainer>
        </FlexContainer>
      }
      width={"60%"}
      open={open}
      onClose={onClose}
    >
      <div>
        <FlexContainer justifyContent="space-between">
          <ContainerTitle
            size="medium"
            padding="0 0 1em 0"
            label={t("component.orderLocation.placement")}
          />
        </FlexContainer>

        <div className={styles.scroll}>
          <GridContainer padding="0 0 0.5em 0" gap="1em" columns="0.5fr 1fr">
            <InputTextAutoDropdown
              value={placement.externalId}
              showError={false}
              top={"calc(100% + 0em)"}
              label={t("component.orderLocation.externalId")}
              onChange={async (value) => {
                setSearch(true);
                const selectedLoc = await locations.locations.hits.find(
                  (item) => value === item.externalId
                );
                if (selectedLoc) {
                  const res = await coordinatesToW3w(
                    parseFloat(
                      selectedLoc.placement.coordinates?.latitude || ""
                    ),
                    parseFloat(
                      selectedLoc.placement.coordinates?.longitude || ""
                    )
                  );
                  setLocationW3W(res);
                  onChange({
                    w3w: selectedLoc.placement.w3w,
                    type: "w3w",
                    chronologicalIndex: 0,
                    externalId: selectedLoc.externalId,
                    id: placement.id,
                    reference: selectedLoc.placement.reference,
                    line1: selectedLoc.placement.line1,
                    name: selectedLoc.name,
                    coordinates: {
                      latitude:
                        selectedLoc.placement.coordinates?.latitude.toString() ||
                        "0",
                      longitude:
                        selectedLoc.placement.coordinates?.longitude.toString() ||
                        "0",
                    },
                  });
                } else {
                  onChange({ ...placement, externalId: value });
                }
              }}
              options={locations.locations.hits
                .filter((item) => item.externalId)
                .map((item) => ({
                  label: item.externalId,
                  value: item.externalId,
                }))}
            />
            <InputText
              value={placement.name}
              showError={false}
              label={t("component.orderLocation.name")}
              onChange={(value) => {
                onChange({
                  w3w: placement.w3w,
                  type: "w3w",
                  chronologicalIndex: 0,
                  externalId: placement.externalId,
                  id: placement.id,
                  reference: placement.reference,
                  line1: placement.line1,
                  name: value,
                  coordinates: {
                    latitude: placement.coordinates?.latitude.toString() || "0",
                    longitude:
                      placement.coordinates?.longitude.toString() || "0",
                  },
                });
              }}
            />
          </GridContainer>
          <InputText
            value={placement.reference}
            label={t("component.orderLocation.reference")}
            onChange={(value) => {
              onChange({
                w3w: placement.w3w,
                type: "w3w",
                chronologicalIndex: 0,
                externalId: placement.externalId,
                id: placement.id,
                reference: value,
                line1: placement.line1,
                name: placement.name,
                coordinates: {
                  latitude: placement.coordinates?.latitude.toString() || "0",
                  longitude: placement.coordinates?.longitude.toString() || "0",
                },
              });
            }}
          />
          <MapForm
            setW3W={(value) => {
              onChange({
                w3w: value,
                type: "w3w",
                id: placement.id,
                chronologicalIndex: 0,
                externalId: placement.externalId,
                reference: placement.reference,
                line1: placement.line1,
                name: placement.name,
                coordinates: {
                  latitude: placement.coordinates?.latitude.toString() || "0",
                  longitude: placement.coordinates?.longitude.toString() || "0",
                },
              });
            }}
            line1={placement.line1}
            w3w={locW3W}
            setData={(data, name) => {
              onChange({
                w3w: data.words,
                type: "w3w",
                chronologicalIndex: 0,
                externalId: placement.externalId,
                id: placement.id,
                reference: placement.reference,
                line1: name,
                name: placement.name,
                coordinates: {
                  latitude: data.coordinates.lat.toString(),
                  longitude: data.coordinates.lng.toString(),
                },
              });
              setLocationW3W(data);
            }}
          />
          <FlexContainer padding="0.5em 0 0 0" justifyContent="space-between">
            <FlexContainer justifyContent="flex-start">
              <Checkbox
                checked={check}
                onChange={(value) => setChecked(value.target.checked)}
              />
              <Text
                padding="0 0 0 1em"
                text={t("component.orderLocation.saveAddress")}
              />
            </FlexContainer>
          </FlexContainer>
          {check ? (
            <GridContainer gap="1em" columns="1fr 1fr">
              <InputTextDropdown
                label={"Type"}
                value={type}
                showError={false}
                top={"calc(100% + 1px)"}
                options={venueTypes}
                onChange={(value) => setType(value)}
              />
              <InputTextAutoDropdown
                top={"calc(100% - 1.5em)"}
                options={
                  locations.locations.facets
                    ?.find((item) => item.field === "group")
                    ?.data?.map((item) => ({
                      label: item.key,
                      value: item.key,
                    })) || []
                }
                value={group}
                label="Group"
                onChange={(value) => setGroup(value)}
              />
            </GridContainer>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default EditPlacement;
