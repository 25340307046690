import {
  Location,
  LocationW3W,
  OfficialMedia,
  OpeningHours,
  Placement,
  Venue,
} from "@innomius/ravent-typescript-types";
import get from "lodash/get";
import { officialMediaMock } from "../mocks/locations";
import {
  AddressParams,
  DuplicateLocation,
  MissingLocation,
  VenueStats,
} from "../utils/types";
import { getAccessToken } from "./auth";
import fetch from "../utils/fetch";
import { defaultFOPSet, parseFOPSet } from "./fop";
import { isNil, omitBy } from "lodash";
import CustomError from "../utils/CustomError";
import Papa from "papaparse";
import { Filter, TextSearchListEvent, parseFacetEvent } from "./orders";
import multiPartFetch from "../utils/multiPartFetch";

export function parseLocation(data: unknown): Location {
  return {
    coordinates: {
      lat: get(data, "coordinates.lat", 0),
      lng: get(data, "coordinates.lng", 0),
    },
    fulfillmentEventCounts: {
      doc_count: get(data, "fulfillmentEventCounts.doc_count", 0),
      placements: {
        chronologicalIndex: {
          0: {
            doc_count: get(
              data,
              "fulfillmentEventCounts.placements.chronologicalIndex.0.doc_count",
              0
            ),
          },
          1: {
            doc_count: get(
              data,
              "fulfillmentEventCounts.placements.chronologicalIndex.1.doc_count",
              0
            ),
          },
        },
      },
    },

    id: get(data, "_id", ""),
    created: get(data, "createdAt", ""),
    externalId: get(data, "externalId", ""),
    group: get(data, "group", ""),
    updated: get(data, "updated", ""),
    deleted: get(data, "deleted", false),
    threeWords: get(data, "threeWords", ""),
    addressLine1: get(data, "addressLine1", ""),
    pointOfContact: get(data, "pointOfContact", ""),
    email: get(data, "email", ""),
    phone: get(data, "phone", ""),
    tags: get(data, "tags", []),
    groupCode: get(data, "groupCode", ""),

    addressLine2: get(data, "addressLine2", ""),
    zipCode: get(data, "zipCode", ""),
    city: get(data, "city", ""),
    state: get(data, "state", ""),
    country: get(data, "country", ""),
    capacity: get(data, "capacity", ""),
    name: get(data, "name", ""),
    image: get(data, "image", ""),
    type: get(data, "type", ""),
    publicCheckin: get(data, "publicCheckin", false),
    checkinPeriodinMinutes: get(data, "checkinPeriodInMinutes", ""),
    enabled: get(data, "enabled", false),
    openingHours: get(data, "openingHours", []),
    description: get(data, "description", ""),
    officialMedia: get(data, "officialMedia", []),
    venueId: get(data, "venue_id", ""),
    organizationId: get(data, "organizationId", ""),
    currentVisitors: get(data, "currentVisitors", 0),
    checkinFlowUrl: get(data, "checkinFlowUrl", ""),
    officialContent: get(data, "officialContent", []),
    placement: {
      id: get(data, "placement._id", ""),
      name: get(data, "placement.name", ""),
      chronologicalIndex: get(data, "placement.chronologicalIndex", 0),
      type: get(data, "placement.type", ""),
      line1: get(data, "placement.line1", ""),
      line2: get(data, "placement.line2", ""),
      externalId: get(data, "placement.externalId", ""),
      w3w: get(data, "placement.w3w", ""),
      reference: get(data, "placement.reference", ""),
      country: get(data, "placement.country", ""),
      state: get(data, "placement.state", ""),
      city: get(data, "placement.city", ""),
      zipCode: get(data, "placement.zipCode", ""),
      coordinates: {
        latitude: get(data, "placement.coordinates.latitude", ""),
        longitude: get(data, "placement.coordinates.longitude", ""),
      },
    },
  };
}

export function parseOfficialMedia(data: unknown): OfficialMedia {
  return {
    id: get(data, "id", ""),
    name: get(data, "name", ""),
    image: get(data, "image", ""),
    type: get(data, "type", ""),
    tags: get(data, "tags", []),
    fileType: get(data, "fileType", ""),
    qrCode: get(data, "qrCode", ""),
    locationId: get(data, "locationId", ""),
    locationName: get(data, "locationName", ""),
    venueId: get(data, "venueId", ""),
  };
}

export function parseVenue(data: unknown): Venue {
  return {
    id: get(data, "_id", ""),
    organizationId: get(data, "organization_id", ""),
    created: get(data, "created", ""),
    updated: get(data, "updated", ""),
    data: get(data, "data", {}),
    deleted: get(data, "deleted", false),
    name: get(data, "name", ""),
    fopSet: parseFOPSet(get(data, "fop_set", "")),
    locations: get(data, "locations", []),
    websiteLink: get(data, "websiteLink", ""),
    secretLastSet: get(data, "secretLastSet", ""),
    secretLastSetBy: get(data, "secretLastSetBy", ""),
    visitors: get(data, "currentVisitors", 0),
    timezone: get(data, "timezone", ""),
    numberFormatting: get(data, "numberFormat", ""),
    dateFormatting: get(data, "dateFormat", ""),
    companyEmail: get(data, "companyEmail", ""),
    companyPhoneNumber: get(data, "companyPhoneNumber", ""),
    venueCapacity: get(data, "maxCapacity", ""),
    type: get(data, "type", ""),
    description: get(data, "description", ""),
    invoiceIssuance: get(data, "invoiceIssuance", ""),
    fopSetId: get(data, "fop_set", ""),
    notificationsSecret: get(data, "notificationsSecret", ""),
    paymentEvents: {
      payment: {
        actionName: get(data, "orderEvents.payment.actionName", ""),
        actionId: get(data, "orderEvents.payment.actionId", ""),
        automatic: get(data, "orderEvents.payment.automatic", false),
      },
      refund: {
        actionName: get(data, "orderEvents.refund.actionName", ""),
        actionId: get(data, "orderEvents.refund.actionId", ""),
        automatic: get(data, "orderEvents.refund.automatic", false),
      },
      expiration: {
        actionName: get(data, "orderEvents.expiration.actionName", ""),
        actionId: get(data, "orderEvents.expiration.actionId", ""),
        automatic: get(data, "orderEvents.expiration.automatic", false),
      },
      paymentExtension: {
        actionName: get(data, "orderEvents.paymentExtension.actionName", ""),
        actionId: get(data, "orderEvents.paymentExtension.actionId", ""),
        automatic: get(data, "orderEvents.paymentExtension.automatic", false),
      },
      paymentRequest: {
        actionName: get(data, "orderEvents.paymentRequest.actionName", ""),
        actionId: get(data, "orderEvents.paymentRequest.actionId", ""),
        automatic: get(data, "orderEvents.paymentRequest.automatic", false),
      },
    },
    eventTriggers: {
      creation: {
        actionName: get(data, "eventTriggers.creation.actionName", ""),
        actionId: get(data, "eventTriggers.creation.actionId", ""),
        automatic: get(data, "eventTriggers.creation.automatic", false),
      },
      update: {
        actionName: get(data, "eventTriggers.update.actionName", ""),
        actionId: get(data, "eventTriggers.update.actionId", ""),
        automatic: get(data, "eventTriggers.update.automatic", false),
      },
      stateTransition: {
        actionName: get(data, "eventTriggers.stateTransition.actionName", ""),
        actionId: get(data, "eventTriggers.stateTransition.actionId", ""),
        automatic: get(data, "eventTriggers.stateTransition.automatic", false),
      },
    },
    orderEvents: {
      creation: {
        actionName: get(data, "orderEvents.creation.actionName", ""),
        actionId: get(data, "orderEvents.creation.actionId", ""),
        automatic: get(data, "orderEvents.creation.automatic", false),
      },
      fulfillment: {
        actionName: get(data, "orderEvents.fulfillment.actionName", ""),
        actionId: get(data, "orderEvents.fulfillment.actionId", ""),
        automatic: get(data, "orderEvents.fulfillment.automatic", false),
      },

      exception: {
        actionName: get(data, "orderEvents.exception.actionName", ""),
        actionId: get(data, "orderEvents.exception.actionId", ""),
        automatic: get(data, "orderEvents.exception.automatic", false),
      },

      cancellation: {
        actionName: get(data, "orderEvents.cancellation.actionName", ""),
        actionId: get(data, "orderEvents.cancellation.actionId", ""),
        automatic: get(data, "orderEvents.cancellation.automatic", false),
      },
    },
    invoiceEvents: {
      invoiceCancellation: {
        actionName: get(data, "invoicing.invoiceCancellation.actionName", ""),
        actionId: get(data, "invoicing.invoiceCancellation.actionId", ""),
        automatic: get(data, "invoicing.invoiceCancellation.automatic", false),
      },
      invoiceIssuance: {
        actionName: get(data, "invoicing.invoiceIssuance.actionName", ""),
        actionId: get(data, "invoicing.invoiceIssuance.actionId", ""),
        automatic: get(data, "invoicing.invoiceIssuance.automatic", false),
      },
      invoiceRefund: {
        actionName: get(data, "invoicing.invoiceRefund.actionName", ""),
        actionId: get(data, "invoicing.invoiceRefund.actionId", ""),
        automatic: get(data, "invoicing.invoiceRefund.automatic", false),
      },
    },

    defaultLanguage: get(data, "defaultLanguage", ""),
    paymentStates: {
      unpaid: {
        actionName: get(data, "orderStates.unpaid.actionName", ""),
        actionId: get(data, "orderStates.unpaid.actionId", ""),
        automatic: get(data, "orderStates.unpaid.automatic", false),
      },

      partial: {
        actionName: get(data, "orderStates.partial.actionName", ""),
        actionId: get(data, "orderStates.partial.actionId", ""),
        automatic: get(data, "orderStates.partial.automatic", false),
      },
      paid: {
        actionName: get(data, "orderStates.paid.actionName", ""),
        actionId: get(data, "orderStates.paid.actionId", ""),
        automatic: get(data, "orderStates.paid.automatic", false),
      },
      expired: {
        actionName: get(data, "orderStates.expired.actionName", ""),
        actionId: get(data, "orderStates.expired.actionId", ""),
        automatic: get(data, "orderStates.expired.automatic", false),
      },
      refunded: {
        actionName: get(data, "orderStates.refunded.actionName", ""),
        actionId: get(data, "orderStates.refunded.actionId", ""),
        automatic: get(data, "orderStates.refunded.automatic", false),
      },
    },
    orderStates: {
      fulfilled: {
        actionName: get(data, "orderStates.fulfilled.actionName", ""),
        actionId: get(data, "orderStates.fulfilled.actionId", ""),
        automatic: get(data, "orderStates.fulfilled.automatic", false),
      },
      created: {
        actionName: get(data, "orderStates.created.actionName", ""),
        actionId: get(data, "orderStates.created.actionId", ""),
        automatic: get(data, "orderStates.created.automatic", false),
      },
      cancelled: {
        actionName: get(data, "orderStates.cancelled.actionName", ""),
        actionId: get(data, "orderStates.cancelled.actionId", ""),
        automatic: get(data, "orderStates.cancelled.automatic", false),
      },
    },
  };
}

export async function locationRead(id: string): Promise<Location> {
  const token = await getAccessToken();
  const res = await fetch(`/locations/${id}`, token);
  const data = parseLocation(res.body);
  return data;
}

export async function deleteLocation(id: string): Promise<void> {
  const token = await getAccessToken();
  const body = {
    ids: [id],
  };
  await fetch(`/locations`, token, "DELETE", body);
  return;
}

export async function updateLocation(
  id: string,
  location: Location,
  placement: Placement
): Promise<Location> {
  const token = await getAccessToken();
  const body = {
    name: location.name,
    description: location.description,
    type: location.type,
    externalId: location.externalId,
    capacity: parseInt(location.capacity),
    checkinFlowUrl: location.checkinFlowUrl,
    publicCheckin: location.publicCheckin,
    openingHours: location.openingHours,
    placement: placement,
    phone: location.phone || "",
    email: location.email || "",
    tags: location.tags || [],
    groupCode: location.groupCode || "",

    pointOfContact: location.pointOfContact || "",
    group: location.group,
    venueId: location.venueId,
    officialMedia: location.officialMedia,
    officialContent: location.officialContent,
    checkinPeriodInMinutes: parseInt(location.checkinPeriodinMinutes),
  };
  const res = await fetch(`/locations/${id}`, token, "PATCH", body);
  const data = parseLocation(res.body);
  return data;
}

export async function createLocation(
  venueId: string,
  data: Location,
  placement: Placement
): Promise<Location> {
  const body = {
    name: data.name,
    venue_id: venueId,
    description: data.description,
    type: data.type,
    tags: data.tags || [],
    capacity: parseInt(data.capacity),
    checkinFlowUrl: data.checkinFlowUrl,
    publicCheckin: data.publicCheckin,
    openingHours: data.openingHours,
    placement: placement,
    group: data.group,
    groupCode: data.groupCode || "",
    phone: data.phone || "",
    email: data.email || "",
    pointOfContact: data.pointOfContact || "",
    officialMedia: [],
    officialContent: [],
    externalId: data.externalId,
    checkinPeriodInMinutes: parseInt(data.checkinPeriodinMinutes),
  };

  const token = await getAccessToken();
  const res = await fetch("/locations", token, "POST", body);
  return parseLocation(res.body);
}

export async function createMiniLocation(
  venueId: string,
  placement: Placement,
  type: string,
  group: string
): Promise<Location> {
  const body = {
    name: placement.name,
    venue_id: venueId,
    placement: placement,
    type: type,
    group: group,
    description: placement.name,
    officialMedia: [],
    officialContent: [],
    externalId: placement.externalId,
  };

  const token = await getAccessToken();
  const res = await fetch("/locations", token, "POST", body);
  return parseLocation(res.body);
}

export interface LocationsSearchResponse {
  data: Location[];
  page: number;
  total: number;
}

export interface LocParamList {
  venueId: string;
  group?: string;
  records_per_page?: number;
  page?: number;
  view?: string;
  count?: number;
}

function mapSortsLocation(data: LocParamList) {
  return omitBy(
    {
      page: data.page,
      records_per_page:
        data.view === "map" ? data.count : data.records_per_page,
      venue_id: data.venueId,
      group: data.group || null,
    },
    isNil
  );
}

export async function locationList(
  params: LocParamList
): Promise<LocationsSearchResponse> {
  const token = await getAccessToken();
  const res = await fetch(
    "/locations",
    token,
    "GET",
    null,
    mapSortsLocation(params)
  );

  return {
    data: res.body ? res.body.hits.map(parseLocation) : [],
    page: get(res.body, "page", 0),
    total: get(res.body, "count", 0),
  };
}

export async function officialMediaList(
  locationId: string
): Promise<OfficialMedia[]> {
  const res = officialMediaMock.filter(
    (item) => item.locationId === locationId
  );
  const data = res.map(parseOfficialMedia);
  return data;
}

export interface CreateVenueParams {
  name: string;
  description: string;
  type: string;
  fopSetId: string;
  currentVisitors: string;
  maxCapacity: string;
}

export async function updateCheckinLocation(
  locationId: string,
  checkinFlowUrl: string,
  checkinPeriodInMinutes: string,
  publicCheckin: boolean
): Promise<Location> {
  const token = await getAccessToken();
  const body = {
    checkinFlowUrl: checkinFlowUrl,
    publicCheckin: publicCheckin,
    checkinPeriodInMinutes: parseInt(checkinPeriodInMinutes),
  };
  const res = await fetch(`/locations/${locationId}`, token, "PATCH", body);
  const data = parseLocation(res.body);
  return data;
}

export async function createVenue(data: Venue): Promise<Venue> {
  const body = {
    name: data.name,
    description: data.description,
    type: data.type,
    fop_set: data.fopSetId || null,
    data: data.data ? JSON.parse(JSON.stringify(data.data)) : {},
    currentVisitors: data.visitors || 0,
    maxCapacity: parseInt(data.venueCapacity as string),
    invoiceIssuance: data.invoiceIssuance,
    review: {},
    defaultLanguage: data.defaultLanguage,
    notificationsSecret: data.notificationsSecret,

    orderEvents: {
      creation: {
        actionId: "",
        automatic: true,
      },
      fulfillment: {
        actionId: "",
        automatic: true,
      },
      payment: {
        actionId: "",
        automatic: true,
      },
      paymentExtension: {
        actionId: "",
        automatic: true,
      },
      cancellation: {
        actionId: "",
        automatic: true,
      },
      expiration: {
        actionId: "",
        automatic: true,
      },
      closure: {
        actionId: "",
        automatic: true,
      },
      refund: {
        actionId: "",
        automatic: true,
      },
      paymentRequest: {
        actionId: "",
        automatic: true,
      },
    },
    invoicing: {
      invoiceCancellation: {
        actionId: "",
        automatic: false,
      },
      invoiceIssuance: {
        actionId: "",
        automatic: false,
      },
      invoiceRefund: {
        actionId: "",
        automatic: false,
      },
    },
    orderStates: {
      partial: {
        actionId: "",
        automatic: true,
      },
      unpaid: {
        actionId: "",
        automatic: true,
      },
      refunded: {
        actionId: "",
        automatic: true,
      },
      paid: {
        actionId: "",
        automatic: true,
      },
      expired: {
        actionId: "",
        automatic: true,
      },
      exception: {
        actionId: "",
        automatic: true,
      },
      fulfilled: {
        actionId: "",
        automatic: true,
      },
      cancelled: {
        actionId: "",
        automatic: true,
      },
    },

    timezone: data.timezone ? data.timezone : null,
    dateFormat: data.dateFormatting ? data.dateFormatting : null,
    numberFormat: data.numberFormatting ? data.numberFormatting : null,
  };
  const token = await getAccessToken();

  const res = await fetch("/venues", token, "POST", body);
  return res.body;
}

export async function getVenue(id: string): Promise<Venue> {
  const token = await getAccessToken();
  const res = await fetch(`/venues/${id}`, token);
  const data = parseVenue(res.body);
  return data;
}

export interface VenueListParams {
  page?: number;
  records_per_page?: number;
}

function mapSorts(data: VenueListParams) {
  return omitBy(
    {
      page: data.page,
      recordsPerPage: data.records_per_page,
    },
    isNil
  );
}

export interface VenueSearchResponse {
  data: Venue[];
  page: number;
  total: number;
}

export async function venueList(
  params: VenueListParams
): Promise<VenueSearchResponse> {
  const token = await getAccessToken();
  const res = await fetch("/venues", token, "GET", null, mapSorts(params));

  return {
    data: res.body ? res.body.hits.map(parseVenue) : [],
    page: get(res.body, "page", 0),
    total: get(res.body, "count", 0),
  };
}

export interface LocationByGroupsResponse {
  name: string;
  locations: Location[];
}

export async function locationListByGroups(
  venueId: string
): Promise<LocationByGroupsResponse[]> {
  const token = await getAccessToken();
  const res = await fetch(
    `/venues/${venueId}/locations-by-groups`,
    token,
    "GET"
  );

  return res.body.map((item: LocationByGroupsResponse) => ({
    name: item.name,
    locations: item.locations.map(parseLocation) || [],
  }));
}

export async function updateVenue(venue: Venue, key?: string): Promise<Venue> {
  const token = await getAccessToken();
  const body = {
    name: venue.name,
    data: venue.data ? JSON.parse(JSON.stringify(venue.data)) : {},
    description: venue.description,
    type: venue.type,
    fop_set: venue.fopSetId ? venue.fopSetId : null,
    currentVisitors: venue.visitors || 0,
    defaultLanguage: venue.defaultLanguage,
    maxCapacity: parseInt(venue.venueCapacity as string),
    invoiceIssuance: venue.invoiceIssuance,
    notificationsSecret: key,
    orderEvents: {
      creation: {
        automatic: venue.orderEvents.creation.automatic,
        actionId: venue.orderEvents.creation.actionId,
      },
      fulfillment: {
        automatic: venue.orderEvents.fulfillment.automatic,
        actionId: venue.orderEvents.fulfillment.actionId,
      },
      payment: {
        automatic: venue.paymentEvents.payment.automatic,
        actionId: venue.paymentEvents.payment.actionId,
      },
      paymentExtension: {
        automatic: venue.paymentEvents.paymentExtension.automatic,
        actionId: venue.paymentEvents.paymentExtension.actionId,
      },
      cancellation: {
        automatic: venue.orderEvents.cancellation.automatic,
        actionId: venue.orderEvents.cancellation.actionId,
      },
      expiration: {
        automatic: venue.paymentEvents.expiration.automatic,
        actionId: venue.paymentEvents.expiration.actionId,
      },
      refund: {
        automatic: venue.paymentEvents.refund.automatic,
        actionId: venue.paymentEvents.refund.actionId,
      },
      paymentRequest: {
        automatic: venue.paymentEvents.paymentRequest.automatic,
        actionId: venue.paymentEvents.paymentRequest.actionId,
      },
      exception: {
        automatic: venue.orderEvents.exception.automatic,
        actionId: venue.orderEvents.exception.actionId,
      },
    },
    invoicing: {
      invoiceCancellation: {
        actionId: venue.invoiceEvents.invoiceCancellation.actionId,
        automatic: false,
      },
      invoiceIssuance: {
        actionId: venue.invoiceEvents.invoiceIssuance.actionId,
        automatic: false,
      },
      invoiceRefund: {
        actionId: venue.invoiceEvents.invoiceRefund.actionId,
        automatic: false,
      },
    },
    orderStates: {
      created: {
        automatic: venue.orderStates.created.automatic,
        actionId: venue.orderStates.created.actionId,
      },
      partial: {
        automatic: venue.paymentStates.partial.automatic,
        actionId: venue.paymentStates.partial.actionId,
      },
      refunded: {
        automatic: venue.paymentStates.refunded.automatic,
        actionId: venue.paymentStates.refunded.actionId,
      },
      unpaid: {
        automatic: venue.paymentStates.unpaid.automatic,
        actionId: venue.paymentStates.unpaid.actionId,
      },
      paid: {
        automatic: venue.paymentStates.paid.automatic,
        actionId: venue.paymentStates.paid.actionId,
      },
      expired: {
        automatic: venue.paymentStates.expired.automatic,
        actionId: venue.paymentStates.expired.actionId,
      },
      fulfilled: {
        automatic: venue.orderStates.fulfilled.automatic,
        actionId: venue.orderStates.fulfilled.actionId,
      },
      cancelled: {
        automatic: venue.orderStates.cancelled.automatic,
        actionId: venue.orderStates.cancelled.actionId,
      },
    },
    eventTriggers: {
      creation: {
        automatic: venue.eventTriggers.creation.automatic,
        actionId: venue.eventTriggers.creation.actionId,
      },
      update: {
        automatic: venue.eventTriggers.update.automatic,
        actionId: venue.eventTriggers.update.actionId,
      },
      stateTransition: {
        automatic: venue.eventTriggers.stateTransition.automatic,
        actionId: venue.eventTriggers.stateTransition.actionId,
      },
    },
    timezone: venue.timezone ? venue.timezone : null,
    dateFormat: venue.dateFormatting ? venue.dateFormatting : null,
    numberFormat: venue.numberFormatting ? venue.numberFormatting : null,
    review: {},
  };

  const res = await fetch(`/venues/${venue.id}`, token, "PATCH", body);
  const data = parseVenue(res.body);
  return data;
}

export async function deleteVenue(id: string): Promise<void> {
  const token = await getAccessToken();
  await fetch(`/venues/${id}`, token, "DELETE");
  return;
}

export interface AddLocationParams {
  name: string;
  description: string;
  type: string;
  capacity: string | number;
  openingHours: OpeningHours[];
  image: string;
  checkin: boolean;
  conciergeCheckinFlowUrl: string;
  publicCheckin: boolean;
  checkinPeriodinMinutes: number | string;
  enabled: boolean;
  address: AddressParams;
}

export const defaultOfficialMedia: OfficialMedia = {
  id: "",
  name: "",
  image: "",
  tags: [],
  type: "",
  fileType: "",
  qrCode: "",
  locationId: "",
  venueId: "",
  locationName: "",
};

export const defaultW3W = {
  country: "MX",
  square: {
    southwest: {
      lng: -99.167668,
      lat: 19.427016,
    },
    northeast: {
      lng: -99.167639,
      lat: 19.427043,
    },
  },
  nearestPlace: "Manteuffelstr 62",
  coordinates: {
    lng: -73.989308,
    lat: 40.741895,
  },
  words: "restrict.firmly.move",
  language: "en",
  map: "https://w3w.co/restrict.firmly.move",
};

export const defaultLocationW3w: LocationW3W = {
  name: "Manteuffelstr 62",
  addressNotes: "",
  image: "",
  type: "",
  w3w: defaultW3W,
};

export const defaultVenue: Venue = {
  name: "",
  data: {},
  description: "",
  type: "",
  venueCapacity: "",
  fopSet: defaultFOPSet,
  visitors: 0,
  companyEmail: "",
  companyPhoneNumber: "",
  organizationId: "",
  defaultLanguage: "",
  timezone: "",
  dateFormatting: "",
  numberFormatting: "",
  locations: [],
  websiteLink: "",
  id: "",
  invoiceIssuance: "",
  created: new Date(),
  updated: new Date(),
  deleted: false,
  fopSetId: "",
  notificationsSecret: "",
  eventTriggers: {
    creation: {
      actionName: "",
      actionId: "",
      automatic: false,
    },
    update: {
      actionName: "",
      actionId: "",
      automatic: false,
    },
    stateTransition: {
      actionName: "",
      actionId: "",
      automatic: false,
    },
  },

  paymentEvents: {
    payment: {
      actionName: "",
      actionId: "",
      automatic: false,
    },
    paymentExtension: {
      actionName: "",
      actionId: "",
      automatic: false,
    },
    expiration: {
      actionName: "",
      actionId: "",
      automatic: false,
    },
    refund: {
      actionName: "",
      actionId: "",
      automatic: false,
    },
    paymentRequest: {
      actionName: "",
      actionId: "",
      automatic: false,
    },
  },
  orderEvents: {
    creation: {
      actionName: "",
      actionId: "",
      automatic: false,
    },
    cancellation: {
      actionName: "",
      actionId: "",
      automatic: false,
    },
    fulfillment: {
      actionName: "",
      actionId: "",
      automatic: false,
    },
    exception: {
      actionName: "",
      actionId: "",
      automatic: false,
    },
  },
  invoiceEvents: {
    invoiceCancellation: {
      actionName: "",
      actionId: "",
      automatic: false,
    },
    invoiceIssuance: {
      actionName: "",
      actionId: "",
      automatic: false,
    },
    invoiceRefund: {
      actionName: "",
      actionId: "",
      automatic: false,
    },
  },
  paymentStates: {
    unpaid: {
      actionName: "",
      actionId: "",
      automatic: false,
    },
    partial: {
      actionName: "",
      actionId: "",
      automatic: false,
    },
    paid: {
      actionName: "",
      actionId: "",
      automatic: false,
    },
    expired: {
      actionName: "",
      actionId: "",
      automatic: false,
    },
    refunded: {
      actionName: "",
      actionId: "",
      automatic: false,
    },
  },
  orderStates: {
    fulfilled: {
      actionName: "",
      actionId: "",
      automatic: false,
    },
    cancelled: {
      actionName: "",
      actionId: "",
      automatic: false,
    },
    created: {
      actionName: "",
      actionId: "",
      automatic: false,
    },
  },
};

export const defaultPlacement = (index?: number): Placement => {
  return {
    type: "w3w",
    name: "",
    id: "",
    chronologicalIndex: index || 0,
  };
};

// export const defaultPlacement: Placement = {
//   type: "w3w",
//   name: "",
//   id: "",
//   chronologicalIndex: 0,
// };

export const defaultLocation: Location = {
  name: "",
  description: "",
  fulfillmentEventCounts: {
    doc_count: 0,
    placements: {
      chronologicalIndex: {
        0: {
          doc_count: 0,
        },
        1: { doc_count: 0 },
      },
    },
  },

  groupCode: "",
  tags: [],
  organizationId: "",
  group: "",
  placement: {
    id: "",
    type: "w3w",
    w3w: "",
    name: "",
    chronologicalIndex: 0,
  },
  externalId: "",
  currentVisitors: 0,
  checkinFlowUrl: "",
  officialContent: [],
  venueId: "",
  officialMedia: [],
  coordinates: {
    lng: -99.167653,
    lat: 19.427029,
  },
  threeWords: "restrict.firmly.move",
  addressLine1: "",
  addressLine2: "",
  zipCode: "",
  country: "",
  pointOfContact: "",
  email: "",
  phone: "",
  threeWordAddress: defaultLocationW3w,
  city: "",
  state: "",
  created: new Date(),
  updated: new Date(),
  id: "",
  deleted: false,
  image: "",
  type: "",
  publicCheckin: false,
  enabled: false,
  checkinPeriodinMinutes: "100",
  capacity: "",
  openingHours: [
    {
      weekday: "monday",
      open: false,
      openingTime: "08:00",
      closingTime: "18:00",
    },
    {
      weekday: "tuesday",
      open: false,
      openingTime: "08:00",
      closingTime: "18:00",
    },
    {
      weekday: "wednesday",
      open: false,
      openingTime: "08:00",
      closingTime: "18:00",
    },
    {
      weekday: "thursday",
      open: false,
      openingTime: "08:00",
      closingTime: "18:00",
    },
    {
      weekday: "friday",
      open: false,
      openingTime: "08:00",
      closingTime: "18:00",
    },
    {
      weekday: "saturday",
      open: false,
      openingTime: "08:00",
      closingTime: "18:00",
    },
    {
      weekday: "sunday",
      open: false,
      openingTime: "08:00",
      closingTime: "18:00",
    },
  ],
};

export function checkVenue(input: Venue) {
  const error = new CustomError("Error saving Org Form", "formError");
  if (!input.name) {
    error.setError("venue-name", "Name is required");
  }

  if (!input.invoiceIssuance) {
    error.setError(
      "venue-invoiceIssuance",
      "Invoice Issuance Type is required"
    );
  }
  if (!input.timezone) {
    error.setError("venue-timezone", "Timezone is required");
  }

  if (!input.dateFormatting) {
    error.setError("venue-dateFormatting", "Date Formatting is required");
  }
  if (!input.numberFormatting) {
    error.setError("venue-numberFormatting", "Number Formatting is required");
  }

  if (!error.length) {
    return true;
  }
  throw error;
}

export async function createMultipleLocations(venueId: string, data: any[]) {
  for (const locationData of data) {
    let placement: any = {
      type: locationData.placement.w3w ? "w3w" : "postal-address",
      reference: locationData.placement.reference || "",
      line2: locationData.placement.line2 || "",
      line1: locationData.placement.line1 || "",
      state: locationData.placement.state || "",
      city: locationData.placement.city || "",
      w3w: locationData.placement.w3w || "",
    };
    let body = {
      name: locationData.name || "",
      phone: locationData.phone || "",
      email: locationData.email || "",
      pointOfContact: locationData.pointOfContact || "",
      venue_id: venueId,
      description: locationData.description || "",
      type: locationData.type || "other",
      capacity: parseInt(locationData.capacity) || 100,
      openingHours: [],
      placement: placement,
      group: locationData.group || "",
      externalId: locationData.externalId || "",
      checkinPeriodInMinutes:
        parseInt(locationData.checkinPeriodinMinutes) || 100,
    };
    if (
      locationData.placement.coordinates.latitude &&
      locationData.placement.coordinates.longitude
    ) {
      body = {
        ...body,
        placement: {
          ...body.placement,
          coordinates: {
            latitude: locationData.placement.coordinates.latitude,
            longitude: locationData.placement.coordinates.longitude,
          },
        },
      };
    }

    const token = await getAccessToken();
    await fetch("/locations", token, "POST", body)
      .then((res) => parseLocation(res.body))
      .catch((error) => {
        // Handle error for a specific location if needed
        console.error(`Error creating location: ${error}`);
        return null; // Return null or handle error according to your needs
      });
  }

  return;
}

export async function exportMultipleLocations(locationsData: Location[]) {
  const csvRows = locationsData.map((locationData) => {
    const body = {
      venueId: locationData.venueId,
      externalId: locationData.externalId || "",
      name: locationData.name || "",
      enabled: locationData.enabled ? 1 : 0,
      description: locationData.description || "",
      groupCode: locationData.groupCode || "",
      group: locationData.group || "",
      type: locationData.type || "other",
      capacity: parseInt(locationData.capacity) || 0,
      currentVisitors: locationData.currentVisitors || 0,
      checkinFlowUrl: locationData.checkinFlowUrl || "",
      publicCheckin: locationData.publicCheckin ? 1 : 0,
      checkinPeriodInMinutes:
        parseInt(locationData.checkinPeriodinMinutes) || 0,
      "p.type": locationData.placement.w3w ? "w3w" : "postal-address",
      "p.fullAddress": locationData.placement.line1 || "",
      "p.line1": locationData.placement.line1 || "",
      "p.line2": locationData.placement.line2 || "",
      "p.zipcode": locationData.placement.zipCode || "",
      "p.city": locationData.placement.city || "",
      "p.state": locationData.placement.state || "",
      "p.country": locationData.placement.country || "",
      "p.w3w": locationData.placement.w3w || "",
      "p.reference": locationData.placement.reference || "",
      "p.lat": locationData.placement.coordinates?.latitude || "",
      "p.long": locationData.placement.coordinates?.longitude || "",
      phone: locationData.phone || "",
      email: locationData.email || "",
      pointofcontact: locationData.pointOfContact || "",
      tags: [],
    };

    return body;
  });

  const csv = Papa.unparse(csvRows);

  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");

  link.href = URL.createObjectURL(blob);
  link.setAttribute("download", "locations_data.csv");
  link.click();

  return;
}

export async function deleteMultipleLocations(data: string[]): Promise<void> {
  const token = await getAccessToken();
  const body = {
    ids: data,
  };
  await fetch(`/locations`, token, "DELETE", body);
  return;
}

export interface LocationsListParamsFTS {
  page?: number | string;
  records_per_page?: number;
  search?: string;
  enabled?: boolean;
  publicCheckin?: boolean;
  type?: Filter;
  group?: Filter;
  venueId?: string;
  view?: string;
  count?: number;
  sort?: string;
  groupCode?: Filter;
}

interface FiltersLocations {
  group: Filter | null;
  type: Filter | null;
  groupCode: Filter | null;
}

function mapSortsFullText(data: LocationsListParamsFTS) {
  const filters: FiltersLocations = {
    group: data.group?.items.length ? data.group : null,
    groupCode: data.groupCode?.items.length ? data.groupCode : null,
    type: data.type?.items.length ? data.type : null,
  };

  const filteredFilters: Partial<FiltersLocations> = {};
  for (const key in filters) {
    if (filters[key as keyof FiltersLocations] !== null) {
      filteredFilters[key as keyof FiltersLocations] =
        filters[key as keyof FiltersLocations]!;
    }
  }

  const jsonString = JSON.stringify(filteredFilters);
  return omitBy(
    {
      page: data.page,
      venue_id: data.venueId || null,
      records_per_page:
        data.view === "map" ? data.count : data.records_per_page,
      search: data.search || null,
      filters: jsonString === "{}" ? "{}" : jsonString,
      sort: data.sort || "_score:desc,datetime:desc",
    },
    isNil
  );
}

export async function locationsFullTextSearch(
  params: LocationsListParamsFTS
): Promise<TextSearchListEvent> {
  const token = await getAccessToken();
  const res = await fetch(
    "/events/textSearchLocations",
    token,
    "GET",
    null,
    mapSortsFullText(params)
  );
  return {
    hits: res.body ? res.body.hits.map(parseLocation) : [],
    page: get(res.body, "page", 0),
    count: get(res.body, "count", 0),
    facets: res.body.facets ? res.body.facets.map(parseFacetEvent) : [],
  };
}

export interface DuplicateParams {
  venueId: string;
  search?: string;
  page?: string | number;
  records_per_page?: number;
}

function mapSortDuplicate(data: DuplicateParams) {
  return omitBy(
    {
      search: data.search || null,
      venue_id: data.venueId,
      records_per_page: data.records_per_page,
      page: data.page,
    },
    isNil
  );
}

export function parseDuplicate(data: unknown): DuplicateLocation {
  return {
    duplicates: get(data, "count", 0),
    location: {
      id: get(data, "location._id", ""),
      name: get(data, "location.name", ""),
      group: get(data, "location.group", ""),
      externalId: get(data, "location.externalId", ""),
      line1: get(data, "location.placement.line1", ""),
    },
  };
}

export interface DuplicateResponse {
  count: number;
  hits: DuplicateLocation[];
  page: number;
}

export async function duplicateList(
  params: DuplicateParams
): Promise<DuplicateResponse> {
  const token = await getAccessToken();
  const res = await fetch(
    "/events/textSearchLocationsDuplicated",
    token,
    "GET",
    null,
    mapSortDuplicate(params)
  );

  return {
    page: get(res.body, "page", 0),
    count: get(res.body, "count", 0),
    hits: res.body.hits ? res.body.hits.map(parseDuplicate) : [],
  };
}

export function parseMissing(data: unknown): MissingLocation {
  return {
    id: get(data, "_id", ""),
    name: get(data, "name", ""),
    count: get(data, "count", 0),
    externalId: get(data, "externalId", ""),
  };
}

export interface SearchList {
  count: number;
  page: number;
  hits: MissingLocation[];
}

export async function missingList(
  params: DuplicateParams
): Promise<SearchList> {
  const token = await getAccessToken();
  const res = await fetch(
    "/events/textSearchLocationsMissing",
    token,
    "GET",
    null,
    mapSortDuplicate(params)
  );

  return {
    hits: res.body ? res.body.hits.map((item: any) => parseMissing(item)) : [],
    page: get(res.body, "page", 0),
    count: get(res.body, "count", 0),
  };
}

export async function uploadLocationFile(file: File, venue_id: string) {
  const token = await getAccessToken();
  await multiPartFetch(`/locations/file`, token, file, null, "POST", venue_id);
  return;
}

export function parseVenueStats(data: unknown): VenueStats {
  return {
    id: get(data, "venueIdKey", ""),
    name: get(data, "venueNameKey", ""),
    counts: {
      fulfillmentDefinitions: get(data, "counts.fulfillmentDefinitions", 0),
      fulfillmentEvents: get(data, "counts.fulfillmentEvents", 0),
      locations: get(data, "counts.locations", 0),
      orders: get(data, "counts.orders", 0),
      users: get(data, "counts.users", 0),
    },
  };
}

export interface VenueStatsResponse {
  hits: VenueStats[];
  page: number;
  count: number;
}

export async function listVenuesStats(
  params: VenueListParams
): Promise<VenueStatsResponse> {
  const token = await getAccessToken();
  const res = await fetch("/events/venuesStats", token, "GET", null);

  return {
    hits: res.body ? res.body.map(parseVenueStats) : [],
    page: 0, //res.body.page,
    count: res.body.lenght ? res.body.lenght : 10, //res.body.count,
  };
}

export const defaultVenueStats: VenueStats = {
  name: "",
  id: "",
  counts: {
    fulfillmentDefinitions: 0,
    fulfillmentEvents: 0,
    locations: 0,
    orders: 0,
    users: 0,
  },
};
