import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  mediaList as mediaListService,
  MediaParamsList,
  MediaSearchResponse,
} from "../services/media";

type MediaSliceState = {
  loading: boolean;
  error: string;
  list: MediaSearchResponse;
};

const initialState: MediaSliceState = {
  loading: true,
  error: "",
  list: {
    page: 0,
    total: 0,
    data: [],
  },
};

export const mediaList = createAsyncThunk(
  "media/list",
  async (params: MediaParamsList) => {
    const response = await mediaListService(params);
    return response;
  }
);

const mediaSlice = createSlice({
  name: "media",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(mediaList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(mediaList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.list = action.payload;
    });
    builder.addCase(mediaList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An unexpected error ocurred";
    });
  },
});

export default mediaSlice.reducer;
