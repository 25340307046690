import { BankAccount, FOP, FOPCatalog, FOPSet } from "../utils/types";
import { get } from "lodash";
import { getAccessToken } from "./auth";
import fetch from "../utils/fetch";

export const defaultAccount: BankAccount = {
  legalName: "",
  bank: "",
  bankAccount: "",
  qrCodeType: "",
  alias: "",
  enabled: true,
};

export interface FOPSearchResponse {
  data: FOP[];
  page: number;
  total: number;
}

export interface FOPSetSearchResponse {
  data: FOPSet[];
  page: number;
  total: number;
}

export interface FOPCatalogSearchResponse {
  data: FOPCatalog[];
  page: number;
  total: number;
}

export function parseBankAccount(data: unknown): BankAccount {
  return {
    bankAccount: get(data, "bankAccount", ""),
    bank: get(data, "bank", ""),
    alias: get(data, "alias", ""),
    legalName: get(data, "legalName", ""),
    qrCodeType: get(data, "qrCodeType", ""),
    enabled: get(data, "enabled", false),
  };
}

export function parseFOP(data: unknown): FOP {
  return {
    id: get(data, "_id", ""),
    round: get(data, "round", ""),
    supplierName: get(data, "supplierName", ""),
    supplierService: get(data, "supplierService", ""),
    integrationRequired: get(data, "integrationRequired", false),
    type: get(data, "type", "card-reader"),
    active: get(data, "active", false),
    data: {
      account: parseBankAccount(get(data, "data.account", {})),
    },
  };
}

export function parseFOPCatalog(data: unknown): FOPCatalog {
  return {
    id: get(data, "_id", ""),
    supplierName: get(data, "supplierName", ""),
    supplierService: get(data, "supplierService", ""),
    integrationRequired: get(data, "supplierService", false),
    sampleData: get(data, "sampleData", ""),
    supplierLogo: get(data, "supplierLogo", ""),
    type: get(data, "type", "card-reader"),
  };
}

export function parseFOPSet(data: unknown): FOPSet {
  return {
    id: get(data, "_id", ""),
    name: get(data, "name", ""),
    fops: get(data, "fops", []).map(parseFOP),
  };
}

export interface FOPListParams {
  page?: number;
  records_per_page?: number;
  text?: string;
}

export async function fopSetList(
  params: FOPListParams
): Promise<FOPSetSearchResponse> {
  const token = await getAccessToken();

  const res = await fetch("/fops/sets", token);
  return {
    data: res.body ? res.body.hits.map(parseFOPSet) : [],
    page: get(res.body, "page", 0),
    total: get(res.body, "count", 0),
  };
}

export async function listFOPCatalog(): Promise<FOPCatalogSearchResponse> {
  const token = await getAccessToken();
  const res = await fetch("/fops/catalog", token);
  return {
    data: res.body ? res.body.hits.map(parseFOPCatalog) : [],
    page: get(res.body, "page", 0),
    total: get(res.body, "count", 0),
  };
}

export async function fopList(): Promise<FOPSearchResponse> {
  const token = await getAccessToken();
  const res = await fetch("/fops/org", token, "GET");
  return {
    data: res.body ? res.body.hits.map(parseFOP) : [],
    page: get(res.body, "page", 0),
    total: get(res.body, "count", 0),
  };
}

export async function getFOPSet(id: string): Promise<FOPSet> {
  const token = await getAccessToken();
  const res = await fetch(`/fops/sets/id/${id}`, token, "GET");
  const data = parseFOPSet(res.body);
  return data;
}

export async function createFOPSet(name: string): Promise<FOPSet> {
  const token = await getAccessToken();

  const body = {
    name: name,
    fops: [],
  };

  const res = await fetch("/fops/sets", token, "POST", body);
  const data = parseFOPSet(res.body);
  return data;
}

export async function updateFOPSetName(
  id: string,
  name: string
): Promise<FOPSet> {
  const token = await getAccessToken();

  const body = {
    name: name,
  };

  const res = await fetch(`/fops/sets/id/${id}`, token, "PATCH", body);
  const data = parseFOPSet(res.body);
  return data;
}

export async function addFOPtoFOPSet(
  id: string,
  fopId: string
): Promise<FOPSet> {
  const token = await getAccessToken();

  const fops = await getFOPSet(id);
  const fopArray = [fopId];

  const body = {
    fops: fops.fops.map((item) => item.id).concat(fopArray),
  };

  const res = await fetch(`/fops/sets/id/${id}`, token, "PATCH", body);
  const data = parseFOPSet(res.body);
  return data;
}

export async function deleteFOPfromFOPSet(
  id: string,
  fopId: string
): Promise<FOPSet> {
  const token = await getAccessToken();

  const fops = await getFOPSet(id);

  const fopsIds = fops.fops.map((item) => item.id);

  const body = {
    fops: fopsIds.filter((e) => e !== fopId),
  };

  const res = await fetch(`/fops/sets/id/${id}`, token, "PATCH", body);
  const data = parseFOPSet(res.body);
  return data;
}

export async function deleteFOPSet(id: string): Promise<void> {
  const token = await getAccessToken();
  await fetch(`/fops/sets/id/${id}`, token, "DELETE");
  return;
}

export async function getFOP(): Promise<FOP> {
  const token = await getAccessToken();
  const res = await fetch("/fops/org", token);
  const data = parseFOP(res.body);
  return data;
}

export async function getCatalog(id: string): Promise<FOP> {
  const token = await getAccessToken();
  const res = await fetch(`/fops/catalog/${id}`, token);
  const data = parseFOP(res.body);
  return data;
}

export async function addFOP(
  catalogId: string,
  round: string,
  account?: BankAccount
): Promise<FOP> {
  const token = await getAccessToken();

  let body;

  if (catalogId) {
    const catalogRes = await getCatalog(catalogId);
    body = {
      type: catalogRes?.type,
      round: round,
      supplierName: catalogRes?.supplierName,
      supplierService: catalogRes?.supplierService,
      integrationRequired: catalogRes?.integrationRequired,
      data: {
        account: account?.bank ? account : null,
      },
      active: false,
    };
  } else {
    body = {
      type: "wire-transfer",
      supplierName: account?.bank,
      supplierService: "transfer",
      integrationRequired: false,
      data: {
        account: account?.bank ? account : null,
      },
      active: false,
    };
  }

  const res = await fetch("/fops/org", token, "POST", body);
  const data = parseFOP(res.body);

  return data;
}

export async function updateFOP(
  id: string,
  update: Record<string, any>
): Promise<FOP> {
  const token = await getAccessToken();
  const body = update;
  const res = await fetch(`/fops/org/${id}`, token, "PATCH", body);
  const data = parseFOP(res.body);
  return data;
}

export async function deleteFOP(id: string): Promise<void> {
  const token = await getAccessToken();
  await fetch(`/fops/org/${id}`, token, "DELETE");
  return;
}

export const defaultFOP: FOP = {
  type: "wire-transfer",
  id: "",
  round: "none",
  supplierName: "",
  supplierService: "",
  integrationRequired: false,
  active: true,
  data: {
    account: {
      legalName: "",
      alias: "",
      bank: "",
      bankAccount: "",
      qrCodeType: "",
      enabled: true,
    },
  },
};

export const defaultFOPSet: FOPSet = {
  id: "",
  name: "",
  fops: [],
};
