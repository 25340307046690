import React from "react";

import { Role } from "../../services/roles";
import { Button } from "../Button";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import List from "./List";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { ButtonsContainer } from "../ButtonsContainer";
import { BackLink } from "../BackLink";
interface OrdersListProps {
  data: Role[];
  deleteRol: (id: string) => void;
  error: string;
  loading: boolean;
  page: number;
  total: number;
  hitsPerPage: number;
  onPageChange?: (page: number) => void;
  onHitsPerPageChange?: (hitsPerPage: number) => void;
  pagination?: boolean;
}

const RoleList: React.FC<OrdersListProps> = ({
  data,
  deleteRol,
  error,
  loading,
  page,
  total,
  hitsPerPage,
  onPageChange,
  onHitsPerPageChange,
  pagination,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <>
      <FlexContainer justifyContent="space-between" padding="1em 0 1em 0">
        <ContainerTitle size="small" label="Roles" />

        <ButtonsContainer justifyContent="flex-end">
          <Button
            label={t("component.roleList.createRole")}
            onClick={() => history.push("/organization/roles/new/details")}
          />
          <BackLink
            noIcon
            label={t("component.roleList.users")}
            onClick={() => history.push("/users")}
          />
        </ButtonsContainer>
      </FlexContainer>
      <List
        hitsPerPage={hitsPerPage}
        onPageChange={onPageChange}
        pagination={pagination}
        loading={loading}
        page={page}
        total={total}
        onHitsPerPageChange={onHitsPerPageChange}
        data={data}
        deleteRol={deleteRol}
        error={error}
      />
    </>
  );
};

export default RoleList;
