import React from "react";
import { classes } from "typestyle";
import styles from "./styles.module.css";

export interface Props {
  containerClassname?: string;
  children: React.ReactNode | React.ReactNode[];
  onClick?: () => void;
}

export const Card: React.FC<Props> = ({
  children,
  containerClassname,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={classes(styles.cardStyle, containerClassname)}
    >
      {children}
    </div>
  );
};
