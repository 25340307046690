import { stylesheet } from "typestyle";

const styles = stylesheet({
  itemsWrapper: {
    padding: "1em 0",
  },
  buttonsContainer: {
    marginRight: "1.1em",
  },
  chevron: {
    width: "36px",
  },
  buttonIssue: {
    minWidth: "10em !important",
  },
});

export default styles;
