import { Payment } from "@innomius/ravent-typescript-types";
import React, { useState } from "react";
import { Card } from "../Card/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OrderTitle from "../OrderDetail/OrderTitle";
import PaymentRequestList from "../PaymentRequestList";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { normalGray } from "../../utils/colors";
import styles from "./styles";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { EmptyList } from "../List/EmptyList";

interface Props {
  errorPayment: string;
  loading: boolean;
  requests: Payment[];
  path: string;
  contactEmail: string;
  contactPhone: string;
  orderDescription: string;
  orderShortId: string;
  onDelete: (id: string) => void;
}

const OrderRequestPayment: React.FC<Props> = ({
  errorPayment,
  loading,
  requests,
  path,
  contactPhone,
  contactEmail,
  orderDescription,
  orderShortId,
  onDelete,
}) => {
  const [chevronRequest, setChevronRequest] = useState(false);

  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Card>
      <div
        className={styles.itemsWrapper}
        onClick={() => setChevronRequest(!chevronRequest)}
      >
        <FlexContainer alignItems="flex-start">
          <OrderTitle
            label={`${t("component.orderRequestPayment.paymentRequested")} (${
              requests.length
            })`}
          />
          <FontAwesomeIcon
            icon={chevronRequest ? faChevronUp as IconProp : faChevronDown as IconProp}
            className={styles.chevron}
            color={normalGray}
          />
        </FlexContainer>
      </div>
      {chevronRequest ? (
        <>
          <div className={styles.itemsWrapperDetail}>
            {requests.length < 1 ? (
              <EmptyList
              instructions={""}
              text={t("component.orderRequestPayment.instructions")}
              />
            ) : (
              <PaymentRequestList
                onDelete={(id) => onDelete(id)}
                orderShortId={orderShortId}
                orderDescription={orderDescription}
                contactEmail={contactEmail}
                contactPhone={contactPhone}
                error={errorPayment}
                loading={loading}
                data={requests}
                onIssueInvoice={(id: string) => {
                  history.push({
                    pathname: `${path}/invoices/new`,
                    state: { paymentId: id },
                  });
                }}
              />
            )}
          </div>
        </>
      ) : null}
    </Card>
  );
};

export default OrderRequestPayment;
