const translation = {
  en: {
    cancel: "Cancel",
    save: "Save",
    details: "Details",
    placement: "Placement",
    externalId: "External ID",
    status: "Status",
    type: "Type",
    tags: "Tags",
    locationTimestamp: "Location Timestamp",
    notes: "Notes",
    data: "Data (Notification Body)",
    delete: "Delete",
    warning: "Are you sure you want to delete this asset permanently?",
    edit: "Edit",
    assetDetails: "Asset Details",
    enabled: "Enabled",
    description: "Description",
    warningImage: "Do you want to delete this asset image ?",
    vehicleDetails: "Vehicle Details",
    setType: "Set Type",
  },
  es: {
    setType: "Asignar Tipo",
    locationTimestamp: "Fecha de Ubicación",
    vehicleDetails: "Detalles de Vehículo",
    warningImage: "Está seguro que quiere eliminar la imágen de este activo ?",
    cancel: "Cancelar",
    assetDetails: "Detalles de Activos",
    enabled: "Activo",
    description: "Descripción",
    save: "Guardar",
    details: "Detalles",
    placement: "Lugar",
    externalId: "ID Externo",
    status: "Estado",
    type: "Tipo",
    tags: "Etiquetas",
    notes: "Notas",
    data: "Datos (Cuerpo de Notificación)",
    delete: "Eliminar",
    warning: "Está seguro que quiere eliminar este activo?",
    edit: "Editar",
  },
  de: {},
};

export default translation;
