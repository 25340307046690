const translation = {
  en: {
    venueInfo: "Venue Information",
    type: "Type",
    fopSet: "FOP Set",
    description: "Description",
    venueCapacity: "Max Capacity",
    invoice: "Invoice Issuance Type",
    email: "Email",
    phone: "Phone",
    events: "Order Events",
    eventName: "Event Name",
    eventTriggers: "Event Triggers",
    flowUser: "Automated Flow User",
    automatic: "Manual / Automatic",
    language: "Default Language",
    invoices: "Invoicing",
    action: "Action",
    actionId: "Action",
  },
  es: {
    actionId: "Acción",
    eventTriggers: "Desencadenadores de Eventos",
    action: "Acción",
    venueInfo: "Información del lugar",
    type: "Tipo",
    fopSet: "Conjunto de FOP",
    description: "Descripción",
    venueCapacity: "Máxima Capacidad",
    invoice: "Tipo de emisión de factura",
    email: "Correo electrónico",
    phone: "Teléfono",
    events: "Eventos",
    eventName: "Nombre del Evento",
    flowUser: "Usario de flujos automatizado",
    automatic: "Manual/Automático",
    language: "Lenguaje Predeterminando",
    invoices: "Facturación",
  },
  de: {},
};

export default translation;
