import { Payment, FirstDataPayment } from "@innomius/ravent-typescript-types";
import React from "react";

import styles from "./styles";
import { GridContainer } from "../GridContainer/GridContainer";

interface Props {
  data: Payment;
}

const FirstData: React.FC<Props> = ({ data }) => {
  const requestPayment = data as FirstDataPayment;

  return (
    <div>
      <GridContainer
        gap="1em"
        columns="0.4fr 1fr"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <div className={styles.bold}>Link</div>
        <div onClick={() => window.open(requestPayment.link, "_blank")}>
          {requestPayment.link}
        </div>
      </GridContainer>
    </div>
  );
};

export default FirstData;
