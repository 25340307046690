import React from "react";
import Text from "../Text";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import passBlue from "../../assets/passBlue.svg";
import PassHistoricList from "./PassHistoricList";
import { StatusBadgePoint } from "../StatusBadgePoint/StatusBagePoint";
import { StatusCatalog } from "../StatusBadge";
import { Pass } from "../../utils/types";
import { Modal } from "../Modal/Modal";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { SectionProperty } from "../Section/SectionProperty";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
interface Props {
  onClose: () => void;
  open: boolean;
  data: Pass;
}

const OrderPassModal: React.FC<Props> = ({ onClose, open, data }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Modal
        width="600px"
        buttons={
          <div>
            <ButtonsContainer justifyContent="flex-end">
              <Button
                onClick={onClose}
                theme="white"
                label={t("component.assetModal.cancel")}
              />
            </ButtonsContainer>
          </div>
        }
        open={open}
        onClose={onClose}
      >
        <div className={styles.modalContainer}>
          <Text
            padding="0 0 0.5em 0"
            fontWeight={700}
            fontSize="1.6em"
            text={`${t("component.orderPasses.pass")} ${data.shortId} `}
          />
          <Text fontSize="1.1em" text={`${data.displayName}`} />

          <div className={styles.box}>
            <GridContainer gap="0.2em" columns="0.5fr 1fr 2px 0.5fr 1fr">
              <img src={passBlue} alt="" className={styles.big} />
              <div>
                <InputLabel label={t("component.orderPasses.state")} />
                <StatusBadgePoint status={data.state as StatusCatalog} />
              </div>
              <div className={styles.verticalLine} />
              <div style={{ marginLeft: "1em", marginTop: "0.5em" }}>
                <SectionProperty
                  value={data.ownership_level}
                  label={t("component.orderPasses.level")}
                />
              </div>
              <div style={{ marginLeft: "1em", marginTop: "0.5em" }}>
                <SectionProperty
                  value={
                    data.ownership_level === "event"
                      ? data.fulfillmentEventName
                      : data.ownership_level === "load"
                      ? data.loadName
                      : data.ownership_level === "orderline"
                      ? data.orderlineName
                      : data.orderName
                  }
                  label={t("component.orderPasses.name")}
                />
              </div>
            </GridContainer>
          </div>
          <PassHistoricList data={data.history} />
        </div>
      </Modal>
    </div>
  );
};

export default OrderPassModal;
