import React, { useState } from "react";
import EventItem from "./EventItem";
import { OrderListSkeleton } from "../../ListSkeletons";
import {
  FulfillmentEvent,
  Load,
  OrderLine,
  Placement,
} from "@innomius/ravent-typescript-types";
import { Asset } from "../../../utils/types";
import OrderlineItem from "../../OrderlineItem/OrdelineItem";
import OrderEventForm from "../OrderEventForm";
import { defaultFulfillmentEvent } from "../../../services/fulfilments";
import { ListHeader } from "../../List/ListHeader";
import { List } from "../../List";

interface Props {
  events: FulfillmentEvent[];
  sortable: boolean;
  onDelete: (id: string) => void;
  onEditEvent: (event: FulfillmentEvent) => void;
  description: string;
  onDeletePlacement: (event: FulfillmentEvent, id: string) => void;
  onEditPlacement: (event: FulfillmentEvent, placement: Placement) => void;
  path: string;
  assets: Asset[];
  updateAsset: (event: FulfillmentEvent, id: string) => void;
  onSaveLoad: (eventId: string, data: Load[]) => void;
  teams: { label: string; value: string }[];
  updateTeam: (eventId: string, teamId: string) => void;
  updateFulfiller: (eventId: string, fulfillerId: string) => void;
  loading?: boolean;
  orderEvent?: boolean;
  fulfillmentLevel: string;
  orderlines: OrderLine[];
  onCreateEvent: (event: FulfillmentEvent, orderlineId: string) => void;
  saveLoc: (p: Placement, type: string, group: string) => void;
  onChangeEventState: (eventId: string) => void;
  flows: { name: string; actionId: string; url: string }[];
  setState: (
    id: string,
    fulfillmentLevel: string,
    status: string,
    w3w: string,
    images: string[]
  ) => void;
  updateFulfillmentName: (
    id: string,
    diveshopId: string,
    fulfillmentLevel: string
  ) => void;
  fulfillmentNames: { label: string; value: string }[];
  venues: { value: string; label: string }[];
  loadEvent: () => void;
  setNew: (eventId: string, state: string) => void;
}

const OrderEventList: React.FC<Props> = ({
  events,
  flows,
  onDelete,
  onEditEvent,
  description,
  onDeletePlacement,
  onEditPlacement,
  path,
  updateAsset,
  assets,
  onSaveLoad,
  teams,
  updateTeam,
  updateFulfiller,
  loading,
  orderEvent,
  fulfillmentLevel,
  orderlines,
  onCreateEvent,
  saveLoc,
  onChangeEventState,
  updateFulfillmentName,
  fulfillmentNames,
  setState,
  venues,
  loadEvent,
  setNew,
}) => {
  const [openForm, setForm] = useState("");

  const header = (
    <div>
      <ListHeader template={"1fr 0.5fr 1fr"}></ListHeader>
    </div>
  );

  const sortedOrderlines = orderlines.slice().sort((a, b) => {
    const earliestEventA = events.find((e) => e.orderlineId === a.id);
    const earliestEventB = events.find((e) => e.orderlineId === b.id);

    if (earliestEventA && earliestEventB) {
      return (
        new Date(earliestEventA.datetime).getTime() -
        new Date(earliestEventB.datetime).getTime()
      );
    }
    return earliestEventA ? -1 : 1;
  });

  return (
    <div>
      {fulfillmentLevel === "orderline" && orderEvent ? (
        <>
          {sortedOrderlines.map((ord) => (
            <div key={ord.id}>
              <OrderEventForm
                data={defaultFulfillmentEvent}
                onCreateEvent={(event) => onCreateEvent(event, ord.id)}
                id={ord.id}
                assets={assets}
                open={openForm === "event"}
                onClose={() => setForm("")}
              />
              <div style={{ margin: "0em 0 2em 0" }}>
                <List
                  loadingElement={() => <OrderListSkeleton />}
                  data={events.filter((e) => e.orderlineId === ord.id)}
                  loading={loading}
                  keyExtractor={(item) => item.id}
                  renderHeader={() => header}
                  renderItem={(item) => (
                    <EventItem
                      flows={flows}
                      setNew={setNew}
                      loadEvent={loadEvent}
                      venues={venues}
                      onChangeEventState={onChangeEventState}
                      index={events
                        .filter((e) => e.orderlineId === ord.id)
                        .indexOf(item)}
                      saveLoc={saveLoc}
                      updateFulfiller={updateFulfiller}
                      updateTeam={updateTeam}
                      teams={teams}
                      assets={assets}
                      updateAsset={updateAsset}
                      path={path}
                      onSaveLoad={onSaveLoad}
                      onDeletePlacement={onDeletePlacement}
                      onEditPlacement={onEditPlacement}
                      key={item.id}
                      description={description}
                      onEditEvent={(event) => onEditEvent(event)}
                      onDelete={(id) => onDelete(id)}
                      data={item}
                      orderEvent={orderEvent}
                      fulfillmentLevel={fulfillmentLevel}
                    />
                  )}
                />
                <OrderlineItem
                  orderId={ord.orderId}
                  updateFulfillmentName={updateFulfillmentName}
                  fulfillmentNames={fulfillmentNames}
                  setState={setState}
                  currentState={ord.fulfillmentState}
                  fulfillmentDefinitionId={ord.fulfillmentDefinitionId}
                  orderlineId={ord.id}
                  description={ord.description}
                  details={ord.details}
                  fulfillmentLevel={fulfillmentLevel}
                  createEvent={() => setForm("event")}
                />
              </div>
            </div>
          ))}
        </>
      ) : (
        <List
          loadingElement={() => <OrderListSkeleton />}
          data={events}
          loading={loading}
          keyExtractor={(item) => item.id}
          renderHeader={() => header}
          renderItem={(item) => (
            <EventItem
              flows={flows}
              setNew={setNew}
              loadEvent={loadEvent}
              venues={venues}
              index={events.indexOf(item)}
              saveLoc={saveLoc}
              updateFulfiller={updateFulfiller}
              updateTeam={updateTeam}
              teams={teams}
              onChangeEventState={onChangeEventState}
              assets={assets}
              updateAsset={updateAsset}
              path={path}
              onSaveLoad={onSaveLoad}
              onDeletePlacement={onDeletePlacement}
              onEditPlacement={onEditPlacement}
              key={item.id}
              description={description}
              onEditEvent={(event) => onEditEvent(event)}
              onDelete={(id) => onDelete(id)}
              data={item}
              fulfillmentLevel={fulfillmentLevel}
            />
          )}
        />
      )}
    </div>
  );
};

export default OrderEventList;
