const translation = {
  en: {
    successCreation: "Integration succesfully created.",
    successUpdate: "Integration succesfully updated.",
    successDeleted: "Integration succesfully deleted.",
    backToSettings: "Back to Payments",
    addIntegration: "+ Add Integration",
    integrationsLabel: "Integrations",
    noIntegrations: "No integrations have been added",
  },
  es: {
    successCreation: "Integración creada con éxito.",
    successUpdate: "Integración actualizada con éxito.",
    successDeleted: "Integración eliminada con éxito.",
    backToSettings: "Volver a Pagos",
    addIntegration: "+ Agregar Integración",
    integrationsLabel: "Integraciones",
    noIntegrations: "No se han agregado nigúna integración",

  },
  de: {
    successCreation: "Integration succesfully created.",
    successUpdate: "Integration succesfully updated.",
    successDeleted: "Integration succesfully deleted.",
    backToSettings: "Back to Settings",
    addIntegration: "+ Add Integration",
    integrationsLabel: "Integrations",
  },
};

export default translation;
