import React from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { classes } from "typestyle";
import styles from "./styles.module.css";
import { InputLabel } from "../InputLabel/InputLabel";

export interface Change {
  id: string;
  value: Date;
}

export interface Props {
  id: string;
  defaultValue?: Date | null;
  label?: string;
  placeholder?: string;
  onChange?: (value: Date, id?: string) => void;
  disabled?: boolean;
  dateClassname?: string;
  disableDate?: (date: any) => boolean;
  value?: Date | null;
  showTime?: any;
  format?: string;
  disabledTime?: () => any;
  showLabel?: boolean;
}

export const InputDate: React.FC<Props> = ({
  id,
  label,
  onChange,
  disabled,
  placeholder,
  dateClassname,
  defaultValue,
  disableDate,
  value,
  showTime,
  format,
  disabledTime,
  showLabel,
}) => {
  const datePickerValue = value !== null ? dayjs(value) : null;

  return (
    <div className={classes(styles.inputContainer, dateClassname)}>
      <InputLabel showLabel={showLabel} label={label} />
      <DatePicker
        value={datePickerValue}
        format={format}
        disabledDate={disableDate}
        className={styles.input}
        defaultValue={dayjs(defaultValue)}
        onChange={(value) =>
          onChange?.(value ? value.toDate() : new Date(), id)
        }
        disabled={disabled}
        placeholder={placeholder}
        showTime={showTime}
        disabledTime={disabledTime}
      />
    </div>
  );
};

export default InputDate;
