const translation = {
  en: {
    ordersLabel: "Orders",
    createOrder: "Create Order",
    orderlines: "Orderlines",
    events: "Events",
    sendSelection: "Send Selection to Flow",
    uploadOrders: "Upload Orders",

    orderToFlows: "Send to Flow",
    eventLabel: "Events",
    success: "Upload was successfull",
    selected: "orders selected",
    save: "Save",
    savedFilters: "Applied Saved Filters",
  },
  es: {
    savedFilters: "Filtros Guardados Aplicados",
    selected: "órdenes seleccionadas",
    orderToFlows: "Mandar Selección a Flujo",
    uploadOrders: "Importar Órdenes",
    sendSelection: "Mandar Selecciones a Flujo",
    success: "Archivo se subió exitosamente",
    ordersLabel: "Órdenes",
    createOrder: "Crear Orden",
    orderlines: "Orderlines",
    events: "Eventos",
    eventLabel: "Eventos",
    save: "Guardar",
  },
  de: {
    ordersLabel: "Bestellungen",
  },
};

export default translation;
