import { WeeklySchedule } from "@innomius/ravent-typescript-types";
import React, { useState } from "react";
import { weekdays } from "../../constants/days";
import styles from "./styles.module.css";
import { HourTag } from "../Calendar/HourTag";

export interface Props {
  frecuencies: WeeklySchedule[];
  onEditHour?: (freq: WeeklySchedule) => void;
  edit?: boolean;
}

const WeeklyCalendar: React.FC<Props> = ({ frecuencies, onEditHour, edit }) => {
  const [selected, setSelected] = useState<string>("");

  return (
    <div className={styles.gridContainer} style={{}}>
      {weekdays.map((day, index) => (
        <div key={index}>
          <div key={day.value} className={styles.day}>
            {day.value}
          </div>
          {frecuencies
            .filter((item) => item.weekday === day.day)
            .map((frequency, index) => (
              <div
                key={`${frequency.hour}${index}`}
                className={styles.hourLabelContainer}
              >
                <HourTag
                  onClick={() => {
                    setSelected(
                      `${frequency.weekday}${frequency.hour}${index}`
                    );
                    onEditHour?.(frequency);
                  }}
                  key={`${frequency.weekday}${frequency.hour}${index}`}
                  hour={frequency.hour}
                  display
                  selected={
                    selected === `${frequency.weekday}${frequency.hour}${index}`
                  }
                />
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};

export default WeeklyCalendar;
