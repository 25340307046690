import React from "react";

import produce from "immer";
import quitIcon from "../../assets/deletBlack.svg";
import { Surcharge } from "@innomius/ravent-typescript-types";

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import styles from "./styles";
import { InputNumber } from "antd";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputText } from "../Inputs/InputText";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
import { InputError } from "../Inputs/InputError/InputError";

interface Props {
  data: Surcharge[];
  onChange: (data: Surcharge[]) => void;
}

const SurchargesForm: React.FC<Props> = ({ data, onChange }) => {
  const { t } = useTranslation();
  return (
    <div style={{ paddingTop: "1em", paddingBottom: "1em" }}>
      <FlexContainer padding="0 0 1em 0" justifyContent="space-between">
        <ContainerTitle
          size="xsmall"
          label={t("component.addOrderline.surcharges")}
        />
        <FontAwesomeIcon
          style={{ cursor: "pointer" }}
          color="#333"
          icon={faPlus as IconProp}
          className={styles.chevron}
          onClick={() =>
            onChange(
              produce(data, (draft) => {
                draft.push({
                  name: "",
                  value: 0,
                });
              })
            )
          }
        />
      </FlexContainer>

      {data.map((property, index) => (
        <div key={`property-${index}`}>
          <GridContainer
            justifyContent="flex-start"
            alignItems="center"
            gap="1em"
            columns="1fr 0.5fr 2em"
          >
            <InputText
              label={t("component.addOrderline.description")}
              id={`optionName${index}`}
              value={property.name}
              onChange={(value) =>
                onChange(
                  produce(data, (draft) => {
                    draft[index].name = value;
                  })
                )
              }
            />
            <div>
              <InputLabel label={t("component.addOrderline.amount")} />
              <InputNumber
                id={`amount${index}`}
                value={property.value}
                onChange={(value) => {
                  if (value) {
                    onChange(
                      produce(data, (draft) => {
                        draft[index].value = value;
                      })
                    );
                  }
                }}
              />
              <InputError />
            </div>
            <div>
              <img
                style={{ cursor: "pointer" }}
                src={quitIcon}
                alt="quit"
                onClick={() =>
                  onChange(
                    produce(data, (draft) => {
                      draft.splice(index, 1);
                    })
                  )
                }
              />
            </div>
          </GridContainer>
        </div>
      ))}
    </div>
  );
};

export default SurchargesForm;
