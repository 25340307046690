import { FlexContainer } from "../FlexContainer/FlexContainer";

import React from "react";
import Text from "../Text";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { CreateUserParams } from "../../services/users";
import { Venue } from "@innomius/ravent-typescript-types";
import { Modal } from "../Modal/Modal";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { InputSelect } from "../Inputs/InputSelect";

interface Props {
  data: CreateUserParams;
  onChange: <P extends keyof CreateUserParams>(
    prop: P,
    value: CreateUserParams[P]
  ) => void;
  venues: Venue[];
  onAssign: (id: string) => void;
  loading: boolean;
  onCancel: () => void;
  open: boolean;
}

const VenueModal: React.FC<Props> = ({
  data,
  onChange,
  venues,
  onAssign,
  loading,
  onCancel,
  open,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Modal
        width="600px"
        buttons={
          <div>
            <ButtonsContainer justifyContent="flex-end">
              <Button
                theme="white"
                label={t("component.userForm.cancel")}
                onClick={() => onCancel()}
              />
              <Button
                label={loading ? "..." : `${t("component.userForm.assign")}`}
                onClick={() => onAssign(data.venueId)}
              />
            </ButtonsContainer>
          </div>
        }
        open={open}
        onClose={onCancel}
      >
        <div className={styles.modalContainer}>
          <FlexContainer alignItems="flex-start" flexDirection="column">
            <Text
              padding="0em 0 1em 0"
              text={t("component.userForm.assignVenue")}
              color="#000000"
              fontSize="1.4em"
              fontWeight={700}
            />
          </FlexContainer>
          <div className={styles.selectContainer}>
            <InputSelect
              maxHeightOptions="10em"
              label={t("component.userForm.venue")}
              value={data.venueId}
              options={venues.map((item) => ({
                label: `${item.name}`,
                value: item.id,
              }))}
              onChange={(value) => onChange("venueId", value)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default VenueModal;
