import React from "react";
import styles from "./styles.module.css";
import { Popover } from "antd";
import { useTranslation } from "react-i18next";
import Text from "../Text";
import { GridContainer } from "../GridContainer/GridContainer";

interface Props {
  range: {
    from: number;
    to: number;
  }[];
  heatmap: {
    count: number;
    year: number | string;
    weekOfYear: number;
  }[];
  year: string;
}

const Weekly: React.FC<Props> = ({ range, heatmap, year }) => {
  const colors = [
    "#E2F3FF",
    "#C7E7FF",
    "#9AD4FF",
    "#79C6FF",
    "#4CB3FF",
    "#1FA0FF",
    "#1A88D9",
    "#136099",
    "#0C4066",
    "#09304D",
  ];

  const { t } = useTranslation();

  // Filter heatmap data based on the year prop
  const filteredHeatmap = heatmap.filter((data) => data.year === year);

  const getWeekColor = (count: number) => {
    for (let i = 0; i < range.length; i++) {
      if (count >= range[i].from && count <= range[i].to) {
        return colors[i];
      }
    }
    return "#eee";
  };

  const getWeekDates = (week: number, year: number | string) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Get the first day of the year
    const januaryFirst = new Date(Number(year), 0, 1);
    // Calculate the first day of the week
    const firstDay = new Date(
      januaryFirst.setDate(januaryFirst.getDate() + (week - 1) * 7)
    );
    // Create a new Date object for the last day calculation
    const lastDay = new Date(firstDay);
    lastDay.setDate(lastDay.getDate() + 6);
    return `${firstDay.getDate()} ${
      months[firstDay.getMonth()]
    } - ${lastDay.getDate()} ${months[lastDay.getMonth()]}`;
  };

  const weekCountMap = new Map();
  filteredHeatmap.forEach((data) => {
    weekCountMap.set(data.weekOfYear, data.count);
  });

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div>
      <GridContainer gap="0.2em" columns={`repeat(12, 1fr)`}>
        {months.map((month, index) => (
          <Text
            key={index}
            color="#aaa"
            padding="0 0 0.5em 0"
            text={month || ""}
          />
        ))}
      </GridContainer>
      <GridContainer gap="0.2em" columns={`repeat(52, 1fr)`}>
        {Array.from({ length: 52 }, (_, index) => index + 1).map((week) => (
          <Popover
            key={week}
            title={`Week: ${week}`}
            content={
              <div>
                <div>{`${t("container.dashboard.date")}:${getWeekDates(
                  week,
                  year
                )}`}</div>
                {`${t("container.dashboard.count")}: ${
                  weekCountMap.get(week) || 0
                }`}
              </div>
            }
          >
            <div
              className={styles.circle}
              style={{
                background: getWeekColor(weekCountMap.get(week) || 0),
              }}
            />
          </Popover>
        ))}
      </GridContainer>
    </div>
  );
};

export default Weekly;
