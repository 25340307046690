const translation = {
  en: {
    by: "By:",
    deliveryType: "Delivery Type",
    method: "Method",
    format: "Format",
    email: "Email",
    phone: "Phone",
    wallet: "Wallet",
    history: "Actions",
    invoiceId: "Invoice ID",
    legalName: "Legal Name",
    amount: "Amount",
    via: "Via",
    empty: "Empty Order History",
    notFound: "No Register was found",
  },
  es: {
    by: "Por:",
    deliveryType: "Tipo de Entrega",
    method: "Método",
    format: "Formato",
    email: "Correo electrónico",
    phone: "Número de teléfono",
    wallet: "Cartera",
    history: "Historial de Acciones",
    invoiceId: "ID de Factura",
    legalName: "Nombre Legal",
    amount: "Cantidad",
    via: "Vía",
    empty: "historial de orden vacío",
    notFound: "Ningún registro fue encontrado",
  },
  de: {},
};

export default translation;
