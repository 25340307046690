import { stylesheet } from "typestyle";
import { black, darkBlack, normalGray } from "../../utils/colors";

const styles = stylesheet({
  container: {},

  wrapperCard: {
    padding: "1em",
    marginBottom: "1em",
  },
  scroll: { overflow: "hidden", width: "100%" },
  form: {
    padding: "1.5em 0",
  },
  wrapper: {
    overflowY: "hidden",
  },
  iconContainer: {
    width: 32,
    marginLeft: "1em",
    cursor: "pointer",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  logoContainer: {
    objectFit: "cover",
    height: "14em",
  },
  center: {
    color: black,
    fontStyle: "normal",
    justifyContent: "flex-start",
    padding: "0.5em",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    marginRight: "0.5em",
    flexWrap: "wrap",
  },
  text: {
    fontSize: "1.1em",
    color: darkBlack,
    fontWeight: 400,
  },
  label: {
    fontSize: "1em",
    color: normalGray,
    fontWeight: 600,
    paddingBottom: "0.4em",
  },
  addressContainer: {
    padding: "1em 0",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  buttonLegal: {
    margin: "1em 0",
  },

  preview: {
    contain: "cover",
    height: "11em",
    width: "13em",
    border: "2px dashed",
    borderColor: "#0091fa",
  },
  deliveredButton: {
    padding: "1em 0",
  },
  modalContainer: {
    padding: "0 1em",
  },
  box: {
    border: "1px dashed #DFE0EB",
    borderRadius: "2px",
    padding: "3em 2em",
    background: "#F5F7FA",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "20%",
  },
  dropArea: {},
  background: {
    backgroundColor: "#F3F9FE",
  },
  nothing: {},
  list: {
    height: "10em",
    marginBottom: "1em",
    overflowY: "scroll",
  },
  buttonsContainer: {
    marginRight: "1.1em",
  },
});

export default styles;
