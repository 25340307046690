import { stylesheet } from "typestyle";
import { black, linkBlue } from "../../utils/colors";

const styles = stylesheet({
  container: {
    padding: "0",
  },
  title: {
    fontSize: "1.5em",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "left",
    color: "#141322",
    paddingTop: "1em",
  },
  noPadding: { padding: 0 },
  background: {
    background: "#F5F7FA",
    marginTop: "1em",
    padding: "1em",
  },
  lineDown: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #DCE1E6",
    padding: "0.5em",
  },
  noLine: {
    padding: "0.5em",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  separation: {
    borderRight: "1px solid #DCE1E6",
    alignItems: "center",
    padding: "0.5em",
    display: "flex",
    justifyContent: "flex-start",
  },
  bookMark: {
    color: linkBlue,
    fill: linkBlue,
    cursor: "pointer",
    width: "1em",
    marginRight: "0.5em",
  },
  filtersContainer: {
    paddingBottom: "2em",
  },
  filtersContainerW: {
    paddingBottom: "1.5em",
  },

  loading: {
    color: black,
    fontWeight: 600,
    padding: "6em 1em",
  },

  labelContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    marginRight: "0.5em",
    padding: "1em",
  },
  label: {
    cursor: "pointer",
    margin: "0.2em 0.5em 0.2em 0",
    width: "auto",
    color: linkBlue + " !important",
  },

  center: {
    color: black,
    fontStyle: "normal",
    justifyContent: "flex-end",
    padding: "1em",
    display: "flex",
    alignItems: "center",
    marginRight: "0.5em",
  },
  normal: {
    fontSize: "1em",
    fontWeight: 500,
    alignItems: "center",
    justifyContent: "flex-start",
    display: "flex",
    padding: "0 0.5em",
  },
  normalBold: {
    fontSize: "1em",
    fontWeight: 600,
    alignItems: "center",
    justifyContent: "flex-start",
    display: "flex",
    padding: "0 0.5em",
  },
  normalCol: {
    fontSize: "1em",
    fontWeight: 600,
    alignItems: "flex-start",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    padding: "0 0.5em",
  },
  flexStart: {
    fontSize: "1em",
    fontWeight: 600,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    padding: "0 0.5em",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    width: "1.5em",
  },
  link: {
    textDecoration: "none",
  },
  paginationContainer: {
    padding: "2em 0",
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  paginationContainerUp: {
    padding: "2em 0 1em 0",
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    position: "fixed",
    bottom: 0,
    background: "#f2f4f8",
  },
  listContainer: {
    paddingBottom: "3em",
  },
  red: { color: "#FF2929" },
  gray: { color: "#858D8F" },
  headerLabel: {
    color: "#949494",
    fontSize: "0.9em",
    fontWeight: 600,
    textTransform: "uppercase",
  },
  bold: {
    fontWeight: 700,
  },
  yellowBox: {
    borderRadius: "4px",
    background: "#FFF0DE",
    padding: 4,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  greenBox: {
    borderRadius: "4px",
    background: "#EBFFDB",
    padding: 4,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  padding: { padding: "1em" },
  greenPoint: {
    background: "#00A9A6",
    width: "0.5em",
    marginLeft: "0.5em",
    height: "0.5em",
    borderRadius: 14 / 2,
  },
  yellowPoint: {
    background: "#FAAD15",
    width: "0.5em",
    marginLeft: "0.5em",
    height: "0.5em",
    borderRadius: 14 / 2,
  },
});

export default styles;
