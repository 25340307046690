import React, { useState } from "react";
import { paymentTypeIconMap } from "../../utils/payment";

import { formatDate } from "../../utils/times";
import { ReactComponent as Receipt } from "../../assets/receipt.svg";
import { Payment } from "@innomius/ravent-typescript-types";
import styles from "./styles";
import PaymentDetail from "../PaymentDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { normalGray } from "../../utils/colors";
import { numberFormat } from "../../utils/format";
import { downloadPaymentReceipe } from "../../services/pdfs";
import { message, Tooltip } from "antd";
import SendMail from "../SendModal/SendMail";
import { sendMessagePayment } from "../../services/messaging";
import APIError from "../../utils/APIError";
import { useTranslation } from "react-i18next";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { StatusBadgePoint } from "../StatusBadgePoint/StatusBagePoint";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";
import { StatusCatalog } from "../StatusBadge";
import { FlexContainer } from "../FlexContainer/FlexContainer";

interface PaymentsListProps {
  data: Payment;
  onIssueInvoice: (paymentId: string) => void;
  contactEmail: string;
  shortId: string;
  description: string;
}

const PaymentItem: React.FC<PaymentsListProps> = ({
  data,
  onIssueInvoice,
  contactEmail,
}) => {
  const [open, setOpen] = useState("");
  const [chevron, setChevron] = useState(false);
  const [loading, setLoading] = useState("");

  const { t } = useTranslation();

  return (
    <>
      <ListRow
        template="0.8fr 0.8fr 1fr 1fr 1fr 1fr 0.5fr"
        hover={true}
        key={data.id}
        onClick={() => {
          setChevron(!chevron);
          setOpen(chevron ? "" : "details");
        }}
        showDetail={open === "details"}
        detail={
          <PaymentDetail
            onDelete={(id: string) => {}}
            data={data}
            onClose={() => {
              setOpen("");
              setChevron(!chevron);
            }}
          />
        }
      >
        <div className={styles.normal}>
          <FontAwesomeIcon
            icon={
              chevron ? (faChevronUp as IconProp) : (faChevronDown as IconProp)
            }
            className={styles.icon}
            color={normalGray}
          />
          {data.shortId}
        </div>
        <ListLabel
          value={data.paymentRequestShortId || "-"}
          textAlign="center"
        />
        <div className={styles.iconContainer}>
          <img
            className={styles.icon}
            src={paymentTypeIconMap[data.paymentType]}
            alt=""
          />
          {data.paymentType}
        </div>
        <ListLabel
          value={formatDate(data.paymentTimestamp, "MIDDLE")}
          textAlign="left"
        />
        <div className={styles.normal}>
          {data.paymentType === "cash" ? (
            ""
          ) : (
            <StatusBadgePoint status={data.status as StatusCatalog} />
          )}
        </div>
        <ListLabel
          value={`${data.amount.currency} ${numberFormat(data.amount.value)}`}
          textAlign="center"
        />
        <FlexContainer justifyContent="space-evenly">
          <SendMail
            loading={loading === "send"}
            loadingDownload={loading === "download" || loading === "open"}
            id={data.id}
            title={t("component.orderPaymentList.receipt")}
            onDownload={async (lang) => {
              try {
                setLoading("download");
                await downloadPaymentReceipe(
                  data.orderId,
                  lang,
                  "paymentReceipt",
                  data.id,
                  false
                );
                setLoading("");
              } catch (err) {
                setLoading("");
                if (err instanceof APIError) {
                  message.error(`${err.message}: ${err.messages}`);
                }
              }
            }}
            onOpenPdf={async (lang) => {
              try {
                setLoading("open");
                await downloadPaymentReceipe(
                  data.orderId,
                  lang,
                  "paymentReceipt",
                  data.id,
                  true
                );
                setLoading("");
              } catch (err) {
                setLoading("");
                if (err instanceof APIError) {
                  message.error(`${err.message}: ${err.messages}`);
                }
              }
            }}
            contactEmail={contactEmail}
            onSend={async (medium: string, contact: string, lang: string) => {
              try {
                setLoading("send");
                await sendMessagePayment(
                  data.orderId,
                  lang,
                  medium,
                  contact,
                  "paymentReceipt",
                  data.id
                );
                setLoading("");
                setOpen("");
                message.success(
                  `${t("component.orderPaymentList.payment")} ${
                    data.shortId
                  } ${t("component.orderPaymentList.shared")}`
                );
              } catch (err) {
                setLoading("");
                if (err instanceof APIError) {
                  message.error(`${err.message}: ${err.messages}`);
                }
              }
            }}
            onCancel={() => setOpen("")}
            open={open === "send"}
          />
          <Tooltip title={t("component.orderPaymentList.share")}>
            <Receipt
              onClick={(e) => {
                e.stopPropagation();
                setOpen("send");
              }}
              className={styles.receiptContainer}
            />
          </Tooltip>
        </FlexContainer>
      </ListRow>
    </>
  );
};

export default PaymentItem;
