import React, { useState } from "react";
import { ReactComponent as Edit } from "../../assets/edit.svg";
import { ReactComponent as Delete } from "../../assets/delete.svg";

import {
  Location,
  Message,
  Placement,
  Visitor,
} from "@innomius/ravent-typescript-types";

import { Checkbox, Popover } from "antd";
import DeletePopUp from "../DeletePopUp";
import styles from "./styles";
import { useHistory } from "react-router-dom";
import { ReactComponent as MapIcon } from "../../assets/map.svg";
import { formatDate } from "../../utils/times";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";

interface LocationsListProps {
  data: Location;
  path?: string;
  messages: Message[];
  visitors: Visitor[];
  onDelete: (id: string) => void;
  onEditLocation: (data: Location, placement: Placement) => void;
  load: () => void;
  checkbox?: boolean;
  onCheckboxChange?: () => void;
  isSelected?: boolean;
  venueId: string;
  onClickItem: (id: string) => void;
}

const LocationItemList: React.FC<LocationsListProps> = ({
  data,
  onDelete,
  isSelected,
  onCheckboxChange,
  onClickItem,
}) => {
  const [deleteLocation, setDelete] = useState("");
  const history = useHistory();

  // const formatOptionKey = (key: string, color: string) => {
  //   if (key && key.includes("::")) {
  //     const [firstPart, secondPart] = key.split("::");
  //     return (
  //       <Tooltip title={firstPart}>
  //         <Tag
  //           style={{
  //             color: color
  //               ? color === "blue"
  //                 ? "blue"
  //                 : fontColorContrast(color, 0.5)
  //               : "blue",
  //           }}
  //           color={color || "blue"}
  //         >
  //           {secondPart}
  //         </Tag>
  //       </Tooltip>
  //     );
  //   } else {
  //     return <Tag color={color || "blue"}>{key}</Tag>;
  //   }
  // };
  return (
    <div>
      <ListRow
        boxShadow
        borderRadius="6px"
        template="2em 0.4fr 0.7fr 0.4fr 0.5fr 0.7fr 0.5fr"
        hover={true}
        key={data.id}
        onClick={() => {
          onClickItem(data.id);
          history.push(`/venueLocations/${data.id}/placement`);
        }}
      >
        <div className={styles.center} onClick={(e) => e.stopPropagation()}>
          <Checkbox checked={isSelected} onChange={onCheckboxChange} />
        </div>
        <ListLabel
          value={
            `${data.externalId} (${data.fulfillmentEventCounts.doc_count})` ||
            "-"
          }
          textAlign="left"
        />
        <ListLabel value={data.name} textAlign="left" />
        <ListLabel
          value={formatDate(data.created, "MIDDLEWHOUR")}
          textAlign="left"
        />

        <ListLabel value={data.group} textAlign="left" />
        <ListLabel value={data.placement.line1 || "-"} textAlign="left" />

        {/* <div className={styles.center} onClick={() => downloadQrCode()}>
          <img className={styles.visitorIcon} src={visitorIcon} alt="" />
          {data.currentVisitors.toString()}
        </div> */}
        {/* <div
          className={styles.iconContainerQR}
          onClick={async (e) => {
            e.stopPropagation();
            downloadQrCode();
          }}
        >
          <FontAwesomeIcon
            style={{ width: 23 }}
            icon={faQrcode as IconProp}
            color={black}
          />
          <FontAwesomeIcon
            style={{ width: 23 }}
            icon={faArrowToBottom as IconProp}
            color={black}
          />
        </div> */}
        {/* <div className={styles.labelContainer}>
          {data.tags?.map(
            (
              item: {
                key: string;
                settings: { tagClassification: string; color: string };
              },
              index
            ) => (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                style={{ margin: "0 0.2em 0.2em 0" }}
                key={index}
              >
                {formatOptionKey(
                  item.key,
                  item.settings && item.settings.color
                    ? item.settings.color
                    : "blue"
                )}
              </div>
            )
          )}
        </div> */}

        <div className={styles.iconContainer}>
          <MapIcon
            className={styles.icon}
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/venueLocations/${data.id}/placement`);
            }}
          />
          <Edit className={styles.icon} />
          <div>
            <Popover
              placement="left"
              content={
                <div onClick={() => setDelete("")}>
                  <DeletePopUp
                    value={`Are you sure you want to delete ${data.name}?`}
                    onClose={(e) => {
                      e.stopPropagation();
                      setDelete("");
                    }}
                    onDelete={(e) => {
                      e.stopPropagation();
                      onDelete(data.id);
                      setDelete("");
                    }}
                  />
                </div>
              }
              trigger="click"
              open={deleteLocation === "delete"}
              onOpenChange={() => setDelete(deleteLocation)}
            />
            <Delete
              className={styles.icon}
              onClick={(e) => {
                e.stopPropagation();
                setDelete("delete");
              }}
            />
          </div>
        </div>
      </ListRow>
    </div>
  );
};

export default LocationItemList;
