import React from "react";

import { Route, Switch, useHistory } from "react-router-dom";
import { Offer, Venue } from "@innomius/ravent-typescript-types";
import VariantDetailForm from "./OfferDetailForm";
import VariantTicketingForm from "./OfferTicketingForm";
import OfferSelectVenue from "./OfferSelectVenue";
import { useTranslation } from "react-i18next";
import { SideMenu } from "../SideMenu/SideMenu";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { EditableTitle } from "../EditableTitle";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import TabNavBar from "../TabNavBar";
interface Props {
  data: Offer;
  onChange: <P extends keyof Offer>(prop: P, value: Offer[P]) => void;
  edit?: boolean;
  path: string;
  onCreate: () => void;
  venues: Venue[];
  onClose: () => void;
  open: boolean;
}

const OfferForm: React.FC<Props> = ({
  data,
  onChange,
  path,
  onCreate,

  venues,
  open,
  onClose,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <SideMenu
      onClose={() => {
        history.push(`${path}`);
        onClose();
      }}
      open={open}
    >
      <FlexContainer justifyContent="space-between">
        <EditableTitle
          padding="1em 0 0 0"
          value={data.name}
          placeholder="Offer Name"
          onChange={(value) => onChange("name", value)}
        />
        <ButtonsContainer itemsPadding="0 0 0 1em" justifyContent="flex-end">
          <Button
            theme="white"
            label="Cancel"
            onClick={() => history.push(`${path}`)}
          />
          <Button
            theme="blue"
            label="Save"
            onClick={() => {
              onCreate();
              history.push(`${path}`);
            }}
          />
        </ButtonsContainer>
      </FlexContainer>
      <TabNavBar
        options={[
          {
            id: "variantInfo",
            label: t("component.offerVariantForm.details"),
            path: `${path}/new`,
          },
          {
            id: "variantLocation",
            label: t("component.offerVariantForm.location"),
            path: `${path}/new/location`,
          },
          {
            id: "variantTicketing",
            label: t("component.offerVariantForm.ticketing"),
            path: `${path}/new/ticketing`,
          },
        ]}
      >
        <Switch>
          <Route path={`${path}/new`} exact>
            <VariantDetailForm data={data} onChange={onChange} />
            <ButtonsContainer padding="3em 0" justifyContent="flex-start">
              <Button
                theme="blue"
                label={t("component.offerVariantForm.continue")}
                onClick={() => history.push(`${path}/new/location`)}
              />
            </ButtonsContainer>
          </Route>
          <Route path={`${path}/new/location`} exact>
            <OfferSelectVenue data={data} onChange={onChange} venues={venues} />
            <ButtonsContainer padding="3em 0" justifyContent="flex-start">
              <Button
                theme="white"
                label={t("component.offerVariantForm.back")}
                onClick={() => history.push(`${path}/new`)}
              />
              <Button
                theme="blue"
                label={t("component.offerVariantForm.continue")}
                onClick={() => history.push(`${path}/new/ticketing`)}
              />
            </ButtonsContainer>
          </Route>
          <Route path={`${path}/new/ticketing`} exact>
            <VariantTicketingForm data={data} onChange={onChange} />
            <ButtonsContainer padding="3em 0" justifyContent="flex-start">
              <Button
                theme="white"
                label={t("component.offerVariantForm.back")}
                onClick={() => history.push(`${path}/new/location`)}
              />
            </ButtonsContainer>
          </Route>
        </Switch>
      </TabNavBar>
    </SideMenu>
  );
};

export default OfferForm;
