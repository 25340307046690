import React from "react";

import styles from "./styles";
import { useTranslation } from "react-i18next";
import { VenuePaymentEvent } from "../../utils/types";
import Text from "../../components/Text";
import { black } from "../../utils/colors";
import { StatusBadgePoint } from "../StatusBadgePoint/StatusBagePoint";
import EditButtonsContainer from "../EditButtonsContainer";
import { Action } from "@innomius/ravent-typescript-types";
import { Card } from "../Card/Card";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
import { StatusCatalog } from "../StatusBadge";
import { InputSelect } from "../Inputs/InputSelect";
import { InputSwitch } from "../Inputs/InputSwitch";
import { FlexContainer } from "../FlexContainer/FlexContainer";

interface Props {
  data: VenuePaymentEvent;
  onChange: (data: VenuePaymentEvent) => void;
  setEdit: (value: string) => void;
  onEdit: () => void;
  loading: string;
  actions: Action[];
}

const VenuePaymentEvents: React.FC<Props> = ({
  data,
  onChange,
  onEdit,
  setEdit,
  loading,
  actions,
}) => {
  const { t } = useTranslation();

  return (
    <Card containerClassname={styles.wrapperCard}>
      <FlexContainer padding="0 0 1em 0" justifyContent="space-between">
        <ContainerTitle size="small" label={t("component.venueForm.events")} />
        <EditButtonsContainer
          loading={loading}
          setEdit={() => setEdit("")}
          onEdit={onEdit}
        />
      </FlexContainer>

      <Text
        textAlign="justify"
        padding="0 0 2em 0"
        color={black}
        text={t("component.venueEvents.url")}
      />
      <GridContainer padding="0 0 1em 0" gap="1em" columns="1fr 3fr 1fr">
        <InputLabel label={t("component.venueForm.eventName")} />
        <InputLabel label={t("component.fulfillmentForm.actionId")} />
        <InputLabel label={t("component.venueForm.automatic")} />
      </GridContainer>
      <GridContainer
        alignItems="center"
        justifyContent="center"
        gap="1em"
        padding="0 0 1em 0"
        columns="1fr 3fr 1fr"
      >
        <StatusBadgePoint status={"payment" as StatusCatalog} />
        <InputSelect
          showError={false}
          showLabel={false}
          options={[
            ...actions.map((item) => ({
              value: item.id,
              label: item.name,
            })),
            { label: "Unassigned Action", value: "" },
          ]}
          value={data.payment.actionId}
          onChange={(actionId) =>
            onChange({
              ...data,
              payment: {
                actionId,
                automatic: data.payment.automatic,
              },
            })
          }
        />
        <InputSwitch
          value={data.payment.automatic}
          onChange={(automatic) =>
            onChange({
              ...data,
              payment: {
                actionId: data.payment.actionId,
                automatic: automatic,
              },
            })
          }
        />
        <StatusBadgePoint status={"payment request" as StatusCatalog} />
        <InputSelect
          showError={false}
          showLabel={false}
          options={[
            ...actions.map((item) => ({
              value: item.id,
              label: item.name,
            })),
            { label: "Unassigned Action", value: "" },
          ]}
          value={data.paymentRequest.actionId}
          onChange={(actionId) =>
            onChange({
              ...data,
              paymentRequest: {
                actionId,
                automatic: data.paymentRequest.automatic,
              },
            })
          }
        />
        <InputSwitch
          value={data.paymentRequest.automatic}
          onChange={(automatic) =>
            onChange({
              ...data,
              paymentRequest: {
                actionId: data.paymentRequest.actionId,
                automatic: automatic,
              },
            })
          }
        />
        <StatusBadgePoint status={"payment extension" as StatusCatalog} />
        <InputSelect
          showError={false}
          showLabel={false}
          options={[
            ...actions.map((item) => ({
              value: item.id,
              label: item.name,
            })),
            { label: "Unassigned Action", value: "" },
          ]}
          value={data.paymentExtension.actionId}
          onChange={(actionId) =>
            onChange({
              ...data,
              paymentExtension: {
                actionId,
                automatic: data.paymentExtension.automatic,
              },
            })
          }
        />
        <InputSwitch
          value={data.paymentExtension.automatic}
          onChange={(automatic) =>
            onChange({
              ...data,
              paymentExtension: {
                actionId: data.paymentExtension.actionId,
                automatic: automatic,
              },
            })
          }
        />
        <StatusBadgePoint status={"refund" as StatusCatalog} />
        <InputSelect
          showError={false}
          showLabel={false}
          options={[
            ...actions.map((item) => ({
              value: item.id,
              label: item.name,
            })),
            { label: "Unassigned Action", value: "" },
          ]}
          value={data.refund.actionId}
          onChange={(actionId) =>
            onChange({
              ...data,
              refund: {
                actionId,
                automatic: data.refund.automatic,
              },
            })
          }
        />
        <InputSwitch
          value={data.refund.automatic}
          onChange={(automatic) =>
            onChange({
              ...data,
              refund: {
                actionId: data.refund.actionId,
                automatic: automatic,
              },
            })
          }
        />
        <StatusBadgePoint status={"expiration" as StatusCatalog} />
        <InputSelect
          showError={false}
          showLabel={false}
          value={data.expiration.actionId}
          options={[
            ...actions.map((item) => ({
              value: item.id,
              label: item.name,
            })),
            { label: "Unassigned Action", value: "" },
          ]}
          onChange={(actionId) =>
            onChange({
              ...data,
              expiration: {
                actionId,
                automatic: data.expiration.automatic,
              },
            })
          }
        />
        <InputSwitch
          value={data.expiration.automatic}
          onChange={(automatic) =>
            onChange({
              ...data,
              expiration: {
                actionId: data.expiration.actionId,
                automatic: automatic,
              },
            })
          }
        />{" "}
      </GridContainer>
      <div />
    </Card>
  );
};

export default VenuePaymentEvents;
