import React, { useState } from "react";
import Text from "../Text";
import styles from "./styles";
import info from "../../assets/infoBlue.svg";
import { useTranslation } from "react-i18next";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { Tag } from "../Tag/Tag";

const Instructions: React.FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const fields = [
    {
      label: "firstName",
      mandatory: t("component.entityDivers.firstNameMandatory"),
      description: t("component.entityDivers.firstNameDescription"),
    },
    {
      label: "tags",
      mandatory: t("component.entityDivers.tagsMandatory"),
      description: t("component.entityDivers.tagsDescription"),
    },
    {
      label: "lastName",
      mandatory: t("component.entityDivers.lastNameMandatory"),
      description: t("component.entityDivers.lastNameDescription"),
    },
    {
      label: "roles",
      mandatory: t("component.entityDivers.rolesMandatory"),
      description: t("component.entityDivers.rolesDescription"),
    },
    {
      label: "enabled",
      mandatory: t("component.entityDivers.enabledMandatory"),
      description: t("component.entityDivers.enabledDescription"),
    },
    {
      label: "externalId",
      mandatory: t("component.entityDivers.externalIdMandatory"),
      description: t("component.entityDivers.externalIdDescription"),
    },
    {
      label: "admin",
      mandatory: t("component.entityDivers.adminMandatory"),
      description: t("component.entityDivers.adminDescription"),
    },
    {
      label: "fulfillmentTeamId",
      mandatory: t("component.entityDivers.fulfillmentTeamIdMandatory"),
      description: t("component.entityDivers.fulfillmentTeamIdDescription"),
    },
    {
      label: "loginWithPhone",
      mandatory: t("component.entityDivers.loginWithPhoneMandatory"),
      description: t("component.entityDivers.loginWithPhoneDescription"),
    },
    {
      label: "type",
      mandatory: t("component.entityDivers.typeMandatory"),
      description: t("component.entityDivers.typeDescription"),
    },
  ];

  return (
    <div className={styles.instructionsContainer}>
      <div
        style={{
          overflowY: !open ? "hidden" : "scroll",
          height: !open ? "140px" : "250px",
          direction: "ltr",
        }}
      >
        <FlexContainer padding="0 0 1em 0" justifyContent="flex-start">
          <img src={info} alt="" className={styles.icon} />
          <Text
            padding="0 0 0 0.5em"
            fontWeight={600}
            fontSize="1.2em"
            text={t("component.entityDivers.instructionsTitle")}
          />
        </FlexContainer>

        <Text
          fontSize="1.1em"
          fontWeight={600}
          text={t("component.entityDivers.firstInstruction")}
        />
        <Text
          fontSize="1.1em"
          fontWeight={400}
          text={t("component.entityDivers.firstContent")}
        />
        {open ? (
          <>
            <Text
              fontSize="1.1em"
              padding="1em 0 0.5em 0"
              fontWeight={600}
              text={t("component.entityDivers.columnHeaders")}
            />
            <div className={styles.fieldContainer}>
              {fields.map((field) => (
                <div className={styles.fieldItem}>
                  <FlexContainer
                    padding="0 0 0.5em 0"
                    justifyContent="flex-start"
                  >
                    <Tag theme="blue" label={field.label} />
                    <Text
                      padding="0 0.5em 0 0.5em"
                      color="#858D8F"
                      fontSize="0.9em"
                      text={field.mandatory}
                    />
                  </FlexContainer>
                  <Text fontSize="1.05em" text={field.description} />
                </div>
              ))}
            </div>
          </>
        ) : null}
        <div onClick={() => setOpen(!open)} style={{ cursor: "poiner" }}>
          <FlexContainer justifyContent="center">
            <Text
              fontSize="1.05em"
              fontWeight={600}
              color="#8A898A"
              padding="0 0.5em 0 0"
              text={
                open
                  ? t("component.entityDivers.seeLess")
                  : t("component.entityDivers.showMore")
              }
            />
            <FontAwesomeIcon color="#8A898A" icon={faChevronDown} />
          </FlexContainer>
        </div>
      </div>
    </div>
  );
};

export default Instructions;
