import React, { useCallback, useEffect, useState } from "react";

import Text from "../../Text";
import { defaultPlacement } from "../../../services/venues";
import { defaultW3W } from "../../../services/maps";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Placement } from "@innomius/ravent-typescript-types";
import { Checkbox, Radio, message } from "antd";
import APIError from "../../../utils/APIError";
import MapForm from "../../MapForm";
import { Modal } from "../../Modal/Modal";
import { venueTypes } from "../../../constants/venues";
import InputTextDropdown from "../../Inputs/InputTextDropdown";
import styles from "./styles";
import InputTextAutoDropdown from "../../Inputs/InputTextAutoDropdown";
import secureLocalStorage from "react-secure-storage";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { locationTextSearchList } from "../../../store/locationsTextSearch";
import { FlexContainer } from "../../FlexContainer/FlexContainer";
import { ButtonsContainer } from "../../ButtonsContainer";
import { Button } from "../../Button";
import { GridContainer } from "../../GridContainer/GridContainer";
import { InputText } from "../../Inputs/InputText";
import { ContainerTitle } from "../../ContainerTitle";
import { InputSelect } from "../../Inputs/InputSelect";

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

interface Props {
  data: Placement;
  onCreatePlacement: (placement: Placement) => void;
  open: boolean;
  setOpen: (value: boolean) => void;
  index: number;
  saveLoc: (p: Placement, type: string, group: string) => void;
  venues: { value: string; label: string }[];
}

const CreatePlacement: React.FC<Props> = ({
  data,
  open,
  setOpen,
  onCreatePlacement,
  index,
  saveLoc,
  venues,
}) => {
  const [placement, setPlacement] = useState(data);
  const [check, setChecked] = useState(false);
  const dispatch = useDispatch();

  const locations = useSelector((state: RootState) => state.locationTextSearch);

  const [locationW3W, setLocationW3W] = useState(defaultW3W);
  const [type, setType] = useState("");

  const [group, setGroup] = useState("");
  const [form, setForm] = useState("new");

  const [venueId, setVenueId] = useState(
    (secureLocalStorage.getItem("venueId") as string) || ""
  );
  const [id, setId] = useState<string>("");

  const { t } = useTranslation();

  const loadLocations = useCallback(async () => {
    dispatch(
      locationTextSearchList({
        records_per_page: 4000,
        venueId: venueId,
      })
    );
  }, [dispatch, venueId]);

  useEffect(() => {
    if (open) {
      loadLocations();
    }
  }, [loadLocations, open]);

  return (
    <Modal
      padding="1em"
      buttons={
        <FlexContainer justifyContent="space-between">
          <FlexContainer justifyContent="space-between"></FlexContainer>

          <ButtonsContainer justifyContent="flex-end">
            <Button
              theme="white"
              label={t("component.orderLocation.cancel")}
              onClick={() => {
                setOpen(false);
                setPlacement(data);
              }}
            />
            <Button
              label={t("component.orderLocation.save")}
              onClick={async () => {
                try {
                  await onCreatePlacement(placement);
                  if (check) {
                    await saveLoc(placement, type, group);
                  }
                  setOpen(false);
                  setPlacement(defaultPlacement(index));
                } catch (err) {
                  if (err instanceof APIError) {
                    message.error(err.message);
                  }
                }
              }}
            />
          </ButtonsContainer>
        </FlexContainer>
      }
      width={"70%"}
      open={open}
      onClose={() => setOpen(false)}
    >
      <div>
        {" "}
        <FlexContainer
          padding="0em 0 1em 0"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <ContainerTitle
            size="small"
            label={t("component.orderLocation.placement")}
          />
        </FlexContainer>
        <FlexContainer justifyContent="space-between">
          <Text
            padding="0 0 1em 0"
            fontWeight={700}
            fontSize="1.1em"
            text={
              form === "new"
                ? t("component.orderLocation.newLocation")
                : t("component.orderLocation.existing")
            }
          />
          <Radio.Group
            value={form}
            onChange={(value) => setForm(value.target.value)}
            style={{ marginBottom: 16 }}
          >
            <Radio.Button value="new">
              {t("component.orderLocation.newLocation")}
            </Radio.Button>
            <Radio.Button value="existing">
              {t("component.orderLocation.existing")}
            </Radio.Button>
          </Radio.Group>
        </FlexContainer>
        <div className={styles.scroll}>
          {form === "new" ? (
            <>
              <GridContainer
                padding="0 0em 1em 0"
                gap="0.5em"
                columns="0.5fr 1fr"
              >
                <InputText
                  value={placement.externalId}
                  error={
                    locations.locations.hits.find(
                      (item) => item.externalId === placement.externalId
                    )
                      ? "This external id exists already in your locations catalog"
                      : ""
                  }
                  label={t("component.orderLocation.externalId")}
                  onChange={(value) =>
                    setPlacement({
                      w3w: placement.w3w,
                      type: "w3w",
                      chronologicalIndex: 0,
                      externalId: value,
                      id: "",
                      reference: placement.reference,
                      line1: placement.line1,
                      name: placement.name,
                      coordinates: {
                        latitude:
                          placement.coordinates?.latitude.toString() || "0",
                        longitude:
                          placement.coordinates?.longitude.toString() || "0",
                      },
                    })
                  }
                />
                <InputText
                  value={placement.name}
                  label={t("component.orderLocation.name")}
                  onChange={(value) => {
                    setPlacement({
                      w3w: placement.w3w,
                      type: "w3w",
                      chronologicalIndex: 0,
                      externalId: placement.externalId,
                      id: "",
                      reference: placement.reference,
                      line1: placement.line1,
                      name: value,
                      coordinates: {
                        latitude:
                          placement.coordinates?.latitude.toString() || "0",
                        longitude:
                          placement.coordinates?.longitude.toString() || "0",
                      },
                    });
                  }}
                  showError={false}
                />
              </GridContainer>

              <InputText
                value={placement.reference}
                label={t("component.orderLocation.reference")}
                onChange={(value) =>
                  setPlacement({
                    w3w: placement.w3w,
                    type: "w3w",
                    chronologicalIndex: 0,
                    externalId: placement.externalId,
                    id: "",
                    reference: value,
                    line1: placement.line1,
                    name: placement.name,
                    coordinates: {
                      latitude:
                        placement.coordinates?.latitude.toString() || "0",
                      longitude:
                        placement.coordinates?.longitude.toString() || "0",
                    },
                  })
                }
              />
              <MapForm
                setW3W={(value) => {
                  setPlacement({
                    w3w: value,
                    type: "w3w",
                    chronologicalIndex: 0,
                    externalId: placement.externalId,
                    id: "",
                    reference: placement.reference,
                    line1: placement.line1,
                    name: placement.name,
                    coordinates: {
                      latitude:
                        placement.coordinates?.latitude.toString() || "0",
                      longitude:
                        placement.coordinates?.longitude.toString() || "0",
                    },
                  });
                }}
                w3w={locationW3W}
                setData={(data, name) => {
                  setPlacement({
                    w3w: data.words,
                    type: "w3w",
                    chronologicalIndex: 0,
                    externalId: placement.externalId,
                    id: "",
                    reference: placement.reference,
                    line1: name,
                    name: placement.name,
                    coordinates: {
                      latitude: data.coordinates.lat.toString(),
                      longitude: data.coordinates.lng.toString(),
                    },
                  });

                  setLocationW3W(data);
                }}
              />
              <FlexContainer padding="1em 0 1em 0" justifyContent="flex-start">
                <Checkbox
                  checked={check}
                  onChange={(value) => setChecked(value.target.checked)}
                />
                <Text
                  padding="0 0 0 1em"
                  text={t("component.orderLocation.saveAddress")}
                />
              </FlexContainer>
              {check ? (
                <div>
                  <GridContainer gap="1em" columns="1fr 1fr">
                    <InputTextDropdown
                      label={"Type"}
                      value={type}
                      top={"calc(100% + 1px)"}
                      options={venueTypes}
                      onChange={(value) => setType(value)}
                    />
                    <InputTextAutoDropdown
                      top={"calc(100% - 1.5em)"}
                      options={
                        locations.locations.facets
                          ?.find((item) => item.field === "group")
                          ?.data?.map((item) => ({
                            label: item.key,
                            value: item.key,
                          })) || []
                      }
                      value={group}
                      label="Group"
                      onChange={(value) => setGroup(value)}
                    />
                  </GridContainer>
                </div>
              ) : null}
            </>
          ) : (
            <>
              <InputSelect
                options={venues}
                value={venueId}
                onChange={(value) => setVenueId(value)}
                label={t("component.orderLocation.venue")}
              />
              {venueId ? (
                <InputTextDropdown
                  label={t("component.orderLocation.locationLabel")}
                  top={"calc(100% - 1.2em)"}
                  onChange={(value) => {
                    setId(value);
                    const selectedLocation = locations.locations.hits.find(
                      (item) => item.id === value
                    );
                    setPlacement({
                      w3w: selectedLocation?.placement.w3w,
                      type: "w3w",
                      chronologicalIndex: 0,
                      externalId: selectedLocation?.externalId,
                      id: "",
                      reference: selectedLocation?.placement.reference,
                      line1: selectedLocation?.placement.line1,
                      name: selectedLocation?.name,
                      coordinates: {
                        latitude:
                          selectedLocation?.placement.coordinates?.latitude.toString() ||
                          "",
                        longitude:
                          selectedLocation?.placement.coordinates?.longitude.toString() ||
                          "",
                      },
                    });
                  }}
                  options={locations.locations.hits
                    .filter((item) => item.venueId === venueId)
                    .map((item) => ({
                      label: `${item.externalId} ${item.name}`,
                      value: item.id,
                    }))}
                  value={id}
                />
              ) : null}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CreatePlacement;
