import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles";

import { useTranslation } from "react-i18next";
import lock from "../../assets/lock.svg";
import Text from "../Text";
import StatesModal from "../OrderFulfillment/StatesModal";

import statesIcon from "../../assets/states.svg";
import { useHistory } from "react-router-dom";
import { FulfillmentState } from "@innomius/ravent-typescript-types";
import dayjs from "dayjs";
import { listFulfillmentStates } from "../../services/fulfilments";
import APIError from "../../utils/APIError";
import { message } from "antd";
import { Button } from "../Button";
import HistoryItem from "../OrderFulfillment/FulfillmentHistoryList/HistoryItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { GridContainer } from "../GridContainer/GridContainer";

interface OrderlineFulfillmentItemProps {
  description: string;
  details?: string;
  fulfillmentLevel: string;
  currentState: string;
  setState: (
    id: string,
    fulfillmentLevel: string,
    status: string,
    w3w: string,
    images: string[]
  ) => void;
  updateFulfillmentName: (
    id: string,
    diveshopId: string,
    fulfillmentLevel: string
  ) => void;
  fulfillmentNames: { label: string; value: string }[];
  fulfillmentDefinitionId: string;
  orderlineId: string;
  orderId: string;
}

const OrderlineFulfillmentItem: React.FC<OrderlineFulfillmentItemProps> = ({
  description,
  details,
  fulfillmentLevel,
  currentState,
  setState,
  updateFulfillmentName,
  fulfillmentNames,
  fulfillmentDefinitionId,
  orderlineId,
  orderId,
}) => {
  const [open, setOpen] = useState(false);
  const [openDetails, setDetails] = useState(false);

  const { t } = useTranslation();

  const history = useHistory();
  const [fulfillmentHistoric, setFulfillmentHistoric] = useState<
    FulfillmentState[]
  >([]);

  const sortedDescData = fulfillmentHistoric?.sort(
    (objA, objB) =>
      Number(dayjs(objB.timestamp, "SSS")) -
      Number(dayjs(objA.timestamp, "SSS"))
  );

  const loadFulfillmentHistoric = useCallback(async () => {
    try {
      const res = await listFulfillmentStates({ orderlineId: orderlineId });
      setFulfillmentHistoric(res.data);
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  }, [orderlineId]);

  useEffect(() => {
    loadFulfillmentHistoric();
  }, [loadFulfillmentHistoric]);

  return (
    <>
      <StatesModal
        updateFulfillmentName={(id, diveshopId) =>
          updateFulfillmentName(id, diveshopId, fulfillmentLevel)
        }
        fulfillmentNames={fulfillmentNames}
        fulfillmentDefinitionId={fulfillmentDefinitionId}
        error={""}
        orderlineId={orderlineId}
        setState={(name, w3w, images) => {
          try {
            setState(orderlineId || "", fulfillmentLevel, name, w3w, images);
            setOpen(false);
          } catch (err) {}
        }}
        open={open}
        onClose={() => setOpen(false)}
        state={currentState}
      />
      {currentState === "not-set" ? null : (
        <div className={styles.historicCard}>
          <div onClick={() => setDetails(!openDetails)}>
            <FlexContainer justifyContent="space-between">
              <FlexContainer justifyContent="flex-start">
                <div className={styles.center}>
                  <FontAwesomeIcon
                    icon={
                      openDetails
                        ? (faChevronUp as IconProp)
                        : (faChevronDown as IconProp)
                    }
                    className={styles.chevron}
                    color={"#333"}
                  />
                </div>
                <Text
                  fontSize="1.2em"
                  fontWeight={700}
                  text={currentState.toUpperCase()}
                  color="#005EA2"
                  padding="1em 0 1em 0"
                />
              </FlexContainer>
              <FlexContainer
                padding="0 1em 0 0"
                alignItems="flex-start"
                flexDirection="column"
              >
                {/* <InputLabel
                label={t("component.orderFulfilmentComponent.fulfillmentState")}
              /> */}
                <Text
                  fontSize="1.1em"
                  fontWeight={700}
                  text={
                    fulfillmentNames.find(
                      (item) => item.value === fulfillmentDefinitionId
                    )?.label || ""
                  }
                  color="#000"
                />
              </FlexContainer>
            </FlexContainer>
          </div>

          {openDetails ? (
            <div style={{ background: "#F3F9FE", padding: "1em" }}>
              <Text
                fontSize="1.2em"
                fontWeight={700}
                text={t("component.orderFulfilmentComponent.historic")}
                color="#000"
                padding="0em 0 1em 0"
              />
              {fulfillmentHistoric.length ? (
                <div>
                  {sortedDescData.map((item) => (
                    <HistoryItem
                      key={item.name + item.timestamp}
                      status={item.name}
                      w3w={item.location?.w3w || ""}
                      user={item.createdByFullName || ""}
                      timestamp={item.timestamp}
                      numberOfFiles={item.numberOfFiles}
                      fulfillmentName={
                        fulfillmentNames.find(
                          (i) => i.value === item.fulfillmentDefinitionId
                        )?.label || "-"
                      }
                      current={currentState === item.name}
                      id={item.id}
                    />
                  ))}
                </div>
              ) : (
                <Text text={t("component.orderlineDetail.noStateActivated")} />
              )}
            </div>
          ) : null}
        </div>
      )}

      <div
        style={{
          borderRadius:
            currentState === "not-set" ? "6px 6px 6px 6px" : "0px 0px 6px 6px",
          borderTop: currentState === "not-set" ? "1px solid #DCE1E6" : "none",
        }}
        className={styles.orderlineCard}
      >
        <FlexContainer justifyContent="space-between" alignItems="flex-start">
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push(
                `/orders/${orderId}/orderlines/${orderlineId}/states`
              )
            }
          >
            <FlexContainer alignItems="flex-start" flexDirection="column">
              <p className={styles.title}>{description}</p>
              <div />
              <GridContainer
                gap="1em"
                padding="0.2em 0 0 0"
                alignItems="flex-start"
                columns="3fr 2em 1.5fr"
              >
                <div>
                  <FlexContainer
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <img src={lock} className={styles.lock} alt="" />
                    <span className={styles.info}>{details}</span>
                  </FlexContainer>
                  <FlexContainer
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <div className={styles.lock} />
                  </FlexContainer>
                </div>
              </GridContainer>
            </FlexContainer>
          </div>

          <FlexContainer
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <FlexContainer justifyContent="flex-end">
              <Button
                onClick={() => setOpen(true)}
                icon={statesIcon}
                label={t("component.orderFulfillment.setState")}
              />
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </div>
    </>
  );
};

export default OrderlineFulfillmentItem;
