import { stylesheet } from "typestyle";
import { azure, black, clearBlue, darkBlack } from "../../utils/colors";

const styles = stylesheet({
  container: {
    marginTop: "1em",
    minHeight: "100vh",
  },
  wrapperCard: {
    padding: "2em",
    marginBottom: "1em",
  },
  card: {
    marginBottom: "1em",
  },
  title: {
    color: darkBlack,
    fontWeight: "bold",
    fontSize: "1.1em",
  },
  pad: {
    paddingTop: "0.2em",
  },
  icon: {
    width: "1.5em",
    marginLeft: '1em',

    cursor: "pointer",
  },
  checkIcon: {
    width: "1.5em",
    marginTop: "2em",
    marginLeft: "0.2em",
    cursor: "pointer",
  },
  amount: {
    color: darkBlack,
    fontWeight: "bold",
    fontSize: "1.3em",
  },
  amountRed: {
    color: "#FF2929",
    fontWeight: "bold",
    fontSize: "1.3em",
  },
  paymentWrapper: {
    display: "flex",
    alignItems: "center",
  },
  sectionWrapper: {
    padding: "1.2em",
    marginBottom: "1em",
  },
  walletWrapper: {
    padding: "2em 2em 1em 2em",
    marginTop: "1em",
  },
  customerContainer: {
    marginTop: "1em",
  },
  tagsWrapper: {
    padding: "2em",
  },
  offeringWrapper: {
    padding: "0em 2em 2em 2em",
  },
  tagsContainer: {
    marginBottom: "1em",
  },
  itemsWrapper: {
    padding: "0 0.2em 1em 0.2em",
  },
  verticalLine: {
    height: "100%",
    width: 1,
    backgroundColor: "#dce1e6",
  },
  borderTag: {
    border: "1px solid #dce1e6",
    padding: "0.6em",
    borderRadius: "2px",
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    background: clearBlue,
    color: "#fff",
    fontSize: "1em",
    fontWeight: 500,
    borderRadius: 2,
    padding: "10px 21px 10px 22px",
    cursor: "pointer",
    $nest: {
      "&:hover": {
        backgroundColor: azure,
      },
    },
  },
  tagContainer: {
    margin: "0.5em 0.5em 0 0",
  },
  valueSmall: {
    color: black,
    fontWeight: 400,
    fontSize: "1.1em",
    paddingTop: "0.2em",
    cursor: "pointer",
  },
  buttonContainer: {
    width: "100%",
  },
  buttonIssuedContainer: {
    padding: "0 0em 0 0",
  },
  copy: {
    width: "1em",
    marginRight: "0.5em",
    marginTop: "0.5em",
  },
  iconReview: {
    marginLeft: "0.5em",
    cursor: "pointer",
  },
  commentIcon: {
    cursor: "pointer",
  },
  shareIcon: {
    cursor: "pointer",
    marginRight: "1em",
  },
});

export default styles;
