const translation = {
  en: {
    languages: "Languages",
    preferredLanguage: "Preferred Language",
    locale: "Locale",
    dateFormating: "Date Formatting",
    numberFormating: "Number Formatting",
    currency: "Currency",
    baseCurrency: "Currency",
    timeZone: "Time-Zone",
  },
  es: {
    languages: "Idiomas",
    preferredLanguage: "Idioma preferido",
    locale: "Configuración regional",
    dateFormating: "Formato de fecha",
    numberFormating: "Formato de número",
    currency: "Moneda",
    baseCurrency: "Moneda",
    timeZone: "Zona horaria",
  },
  de: {},
};

export default translation;
