const translation = {
  en: {
    successCreation: "Definition succesfully created.",
    back: "Back to Definitions",
    cancel: "Cancel",
    save: "Save",
    ticketingDefNamePlaceholder: "Definition Name",
    label: "Details",
    ticketingLabel: "Pass Definitions",
    createNew: "+ Add Definition",
    instructions: "Start creating new definitions",
  },
  es: {
    successCreation: "Definición de pase creada con éxito.",
    back: "Volver a Definiciones de pase",
    cancel: "Cancelar",
    save: "Guardar",
    ticketingDefNamePlaceholder: "Nombre de Pase",
    label: "Detalles",
    ticketingLabel: "Definiciones de Pases",
    createNew: "+ Agregar Pase",
    instructions: "Empieza a crear nuevas definiciones de certifcados",
  },
  de: {},
};

export default translation;
