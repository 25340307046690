const translation = {
  en: {
    currency: "Currency",
    preferedLanguage: "Preferred Language",
    locale: "Locale",
    dateFormating: "Date Formatting",
    numberFormating: "Number Formatting",
    language: "Language",
    baseCurrency: "Currency",
    timeZone: "Time Zone",
    userPreferences: "User Preferences",
  },
  es: {
    currency: "Moneda",
    preferedLanguage: "Idioma preferido",
    locale: "Configuración regional",
    dateFormating: "Formato de fecha",
    numberFormating: "Formato de número",
    language: "Idioma",
    baseCurrency: "Moneda",
    timeZone: "Zona horaria",
    userPreferences: "Preferencias de Usuario",
  },
  de: {},
};

export default translation;
