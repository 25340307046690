
import React from "react";
import Text from "../Text";
import warningIcon from "../../assets/warning.svg";
import styles from "./styles";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { Modal } from "../Modal/Modal";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { InputError } from "../Inputs/InputError/InputError";

interface Props {
  onClose: () => void;
  open: boolean;
  loading?: boolean;
  error?: string;
}

const IntegrationModal: React.FC<Props> = ({ onClose, open, error }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className={styles.containerModal}>
      <Modal
        width="600px"
        buttons={
          <div>
            <ButtonsContainer justifyContent="flex-end">
              <Button onClick={onClose} theme="white" label="Go Back" />

              <Button
                label={t("component.paymentSupplierList.createNew")}
                onClick={() => {
                  onClose();
                  history.push("/payments/integrations/new");
                }}
              />
            </ButtonsContainer>
            {error ? (
              <FlexContainer justifyContent="flex-end" padding="0.5em 1em 0 0">
                <InputError error={error} />
              </FlexContainer>
            ) : null}
          </div>
        }
        open={open}
        onClose={onClose}
      >
        <div className={styles.modalContainer}>
          <div>
            <FlexContainer flexDirection="column">
              <img src={warningIcon} alt="" className={styles.icon} />
              <Text
                padding="1em 0 2em 0"
                text={t("component.paymentSupplierList.instructions")}
                color="#000000"
                fontSize="1.4em"
                fontWeight={700}
              />
            </FlexContainer>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default IntegrationModal;
