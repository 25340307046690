import { stylesheet } from "typestyle";
import { azure, black, clearBlue, lightGrey } from "../../utils/colors";

const styles = stylesheet({
  container: {
    width: "100%",
    minHeight: "100vh",
    $nest: {
      "@media screen and (max-width: 700px)": {
        display: "none",
      },
    },
  },
  wrapper: {
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "25px",
  },
  link: {
    fontSize: "1em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1.4em",
    textDecoration: "none",
    color: clearBlue,
    padding: "3em 0 0 0",
    $nest: {
      "& > a": {
        color: "currentColor",
      },
    },
  },
  spinner: {
    fontSize: "1em",
    color: "#999",
  },
  footer: {
    fontSize: "0.8em",
    paddingBottom: "4em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1.4em",
    color: clearBlue,
    padding: "4em 0 0 0",
    $nest: {
      "& > a": {
        color: "currentColor",
      },
    },
  },

  title: {
    fontSize: "2.5em",
    fontWeight: "bold",
    paddingBottom: "1.5em",
  },
  subtitle: {
    fontSize: "1.6em",
    fontWeight: "bold",
    padding: "1em 0 1em 0",
    textAlign: "center",
    color: "#000000",
  },
  text: {
    fontSize: "1em",
    textAlign: "left",
    padding: "0 0 1.5em 0",
    color: "#000000",
  },
  containerInput: {
    paddingBottom: "1.5em",
  },
  inputsContainer: {
    padding: "4em 3em 4em 2em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "#FFF",
    $nest: {
      "& > div": {
        maxWidth: "27em",
        width: "100%",
        margin: "0 auto",
      },
    },
  },

  error: {
    minHeight: "2em",
    color: "red",
    textAlign: "center",
    marginTop: "1em",
  },

  button: {
    background: lightGrey,
    color: "#fff",
    padding: "10px 21px 10px 22px",
    cursor: "pointer",
    marginTop: "2em",
    width: "100%",
    $nest: {
      "&:hover": {
        backgroundColor: azure,
      },
    },
  },
  forgotContainer: {
    paddingBottom: "2em",
    textAlign: "center",
  },
  logo: {
    height: "29px",
  },
  imageContainer: {
    width: "100%",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "1.5em",
  },
  inputContainer: {
    paddingBottom: "2em",
  },

  footerText: {
    color: black,
  },
  image: {
    display: "block",
    backgroundSize: "cover",
    height: "100vh",
    width: "100%",
    objectFit: "cover",
    top: 0,
  },
  backContainer: {
    width: "50%",
    display: "flex",
    textDecoration: "none",
    position: "absolute",
    top: "",
  },
  mobileContainer: {
    $nest: {
      "@media screen and (min-width: 701px)": {
        display: "none",
      },
    },
  },
});

export default styles;
