const translation = {
  en: {
    deleteWarning: "Are you sure you want to delete this venue?",
    back: "Back to Venues",
    cancel: "Cancel",
    save: "Save",
    delete: "Delete",
    edit: "Edit Venue",
    selectAGroup: "Select a Group",
    mapView: "Map View",
    listView: "List View",
  },
  es: {
    deleteWarning: "¿Está seguro de que desea eliminar este lugar?",
    back: "Volver a los lugares",
    cancel: "Cancelar",
    save: "Guardar",
    delete: "Eliminar",
    edit: "Editar lugar",
    mapView: "Vista de mapa",
    listView: "Vista de Lista",
    selectAGroup: "Selecciona un grupo",
  },
  de: {},
};

export default translation;
