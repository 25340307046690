import React from "react";
import { Route, Switch } from "react-router-dom";
import { Offer } from "@innomius/ravent-typescript-types";

import styles from "./styles";
import Detail from "./Detail";
import Ticketing from "./Ticketing";
import ModifiersDetail from "./ModifiersDetail";
import FrequencyDetail from "./FrequencyDetail";
import { SideMenu } from "../SideMenu/SideMenu";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { EditableTitle } from "../EditableTitle";
import { ContainerTitle } from "../ContainerTitle";
import TabNavBar from "../TabNavBar";

interface Props {
  data: Offer;
  path: string;
  onClose: () => void;
  open: boolean;
  onChange: <P extends keyof Offer>(prop: P, value: Offer[P]) => void;
}

const OfferDetail: React.FC<Props> = ({
  data,
  path,
  open,
  onClose,
  onChange,
}) => {
  return (
    <SideMenu onClose={onClose} open={open}>
      <div className={styles.itemsWrapper}>
        <FlexContainer padding="0 0 1em 0" justifyContent="space-between">
          <EditableTitle
            value={data.name}
            onChange={(value) => onChange("name", value)}
            titleClassname={styles.titleContainer}
          />
          <FlexContainer>
            <ContainerTitle
              size="medium"
              label={`From: ${data.price.currency}`}
            />
            <EditableTitle
              value={data.price.value.toString()}
              titleClassname={styles.titlePrice}
            />
          </FlexContainer>
        </FlexContainer>
        <TabNavBar
          options={[
            {
              id: "variantsModifieres",
              label: "Modifiers",
              path: `${path}/${data.id}/modifiers`,
            },
            {
              id: "variantsTicketing",
              label: "Ticketing",
              path: `${path}/${data.id}/ticketing`,
            },
            {
              id: "variantsFrequencies",
              label: "Frequencies",
              path: `${path}/${data.id}/frequencies`,
            },
            {
              id: "variantsDetails",
              label: "Details",
              path: `${path}/${data.id}/details`,
            },
          ]}
        >
          <Switch>
            <Route exact path={`${path}/${data.id}/modifiers`}>
              <ModifiersDetail data={data} />
            </Route>
            <Route exact path={`${path}/${data.id}/ticketing`}>
              <Ticketing onChange={onChange} data={data} />
            </Route>
            <Route exact path={`${path}/${data.id}/frequencies`}>
              <FrequencyDetail data={data} />
            </Route>
            <Route exact path={`${path}/${data.id}/details`}>
              <Detail onChange={onChange} data={data} />
            </Route>
          </Switch>
        </TabNavBar>
      </div>
    </SideMenu>
  );
};

export default OfferDetail;
