import { stylesheet } from "typestyle";
import { darkBlack, hoverList } from "../../utils/colors";

const styles = stylesheet({
  container: {
    padding: "1em 2.2em",
    height: "100vh",
  },

  textContainer: {
    width: "100%",
    marginRight: "1em",
    color: "#777",
    fontWeight: 400,
  },
  containerList: {
    padding: "0em 0.1em",
    background: hoverList,
  },
  editIcon: {
    width: "34px",
    marginRight: "1em",
    cursor: "pointer",
  },
  deleteIcon: {
    width: "1.5em",
    cursor: "pointer",
    display: "none",
  },
  name: {
    fontWeight: 700,
    fontSize: "1.1em",
    color: darkBlack,
    width: "100%",
  },
  box: {
    width: "100%",
    borderRadius: 3,
    fontSize: "1.1em",
    paddingTop: "0.5em",
  },
  text: {
    display: "flex",
    fontSize: "1em",
  },
  imageContainer: {
    width: "24%",
  },
  iconAlert: {
    width: "20px",
  },
  buttonContainer: {
    width: "65px",
    marginRight: "1em",
  },
  margin: {
    margin: "0 0 1  em 0",
  },
});

export default styles;
