import email from "../assets/deliveryMethods/email.svg";
import nfc from "../assets/deliveryMethods/nfc.svg";
import whatsapp from "../assets/deliveryMethods/whatsapp.svg";
import sms from "../assets/deliveryMethods/sms.svg";
import { ReactComponent as PaymentIconActive } from "../assets/review/collect_pymentactive.svg";
import { ReactComponent as ChatIconActive } from "../assets/review/conversationactive.svg";
import { ReactComponent as TicketIconActive } from "../assets/review/issueticketactive.svg";
import { ReactComponent as AssambleIconActive } from "../assets/review/assemble_orderactive.svg";
import { ReactComponent as CancelIconActive } from "../assets/review/cancel-active.svg";
import { ReactComponent as PaymentIcon } from "../assets/review/collect pymentdefault.svg";
import { ReactComponent as ChatIcon } from "../assets/review/conversationdefault.svg";
import { ReactComponent as TicketIcon } from "../assets/review/issueticket.svg";
import { ReactComponent as AssambleIcon } from "../assets/review/assemble_order.svg";
import { ReactComponent as CancelIcon } from "../assets/review/cancel.svg";
import { ReactComponent as ResetIcon } from "../assets/review/reset-order.svg";
import { ReactComponent as ResetIconActive } from "../assets/review/reset-order-active.svg";

import { placeholderGray } from "../constants/colors";
import {
  pointBlue,
  pointBrown,
  pointGray,
  pointGreen,
  pointOrange,
  pointPurple,
  pointRed,
  pointYellow,
} from "./colors";
import { FacetEvent, Filter } from "../services/orders";
import qs from "qs";

export const deliveryMethodIconMap: Record<string, string> = {
  Whatsapp: whatsapp,
  SMS: sms,
  Email: email,
  Wearable: nfc,
};
type IconComponent = React.ComponentType<React.SVGProps<SVGSVGElement>>;

type IconMap = {
  [key: string]: IconComponent;
};

export const orderReviewIconMap: IconMap = {
  collectPayment: PaymentIconActive,
  readyOrder: AssambleIconActive,
  issueTickets: TicketIconActive,
  replyMessage: ChatIconActive,
  refundOrder: CancelIconActive,
  resetOrder: ResetIconActive,
};

export const orderReviewIconNormalMap: IconMap = {
  collectPayment: PaymentIcon,
  readyOrder: AssambleIcon,
  issueTickets: TicketIcon,
  replyMessage: ChatIcon,
  refundOrder: CancelIcon,
  resetOrder: ResetIcon,
};

export function removeEmptyStringFromObject(obj: Record<string, string>) {
  return Object.entries(obj)
    .filter(([_, v]) => v !== "")
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
}

export const getOrderStatusColor: (status: string) => string = (
  status = "closed"
) => {
  switch (status.toLocaleLowerCase()) {
    case "created":
      return pointGray;
    case "complete":
    case "completed":
    case "active":
    case "issued":
    case "closed":
    case "fulfilled":
      return pointGreen;
    case "failed":
    case "cancelled":
    case "canceled":
      return pointRed;
    case "pending":
    case "enabled":
    case "paid":
      return pointBlue;
    case "review":
    case "unpaid":
      return pointYellow;
    case "expired":
      return pointPurple;
    case "partial":
      return pointOrange;
    case "refunded":
      return pointBrown;
    case "disabled":
      return placeholderGray;
    default:
      return pointGray;
  }
};

export function processStrings(original: string, input: string) {
  if (original === input) {
    return "";
  }
  if (!original.length) {
    return input;
  }
  const originalArray = original.split(",");
  if (originalArray.includes(input)) {
    originalArray.splice(originalArray.indexOf(input), 1);
    return originalArray.join(",");
  }
  originalArray.push(input);
  return originalArray.join(",");
}

export function splitRangeString(
  rangeString: string
): { from: string; to: string } | null {
  const parts = rangeString.split("-");
  if (parts.length === 2) {
    return { from: parts[0], to: parts[1] };
  } else {
    return null; // Return null if the input string is not in the expected format
  }
}

export function removeAndJoinString(originalString: string, array: string[]) {
  const index = array.indexOf(originalString);
  if (index !== -1) {
    array.splice(index, 1);
  }
  const resultString = array.join(", ");
  return resultString;
}

export function rearrangeFacets(facets: { [key: string]: any }) {
  const keysOrder = [
    "tags",
    "currency",
    "totalRanges",
    "state",
    "paymentState",
    "channelName",
    "fulfillmentLevel",
  ];

  const rearrangedFacets: { [key: string]: any } = {};

  const sortedKeys = Object.keys(facets).sort((a, b) => {
    const indexA = keysOrder.indexOf(a);
    const indexB = keysOrder.indexOf(b);
    if (indexA === -1 && indexB === -1) {
      return 0;
    }
    if (indexA === -1) {
      return 1;
    }
    if (indexB === -1) {
      return -1;
    }
    return indexA - indexB;
  });

  sortedKeys.forEach((key) => {
    rearrangedFacets[key] = facets[key];
  });

  return rearrangedFacets;
}

const keysOrder = [
  "tags",
  "fulfillmentTeamName",
  "fulfillerUsername",
  "assets",
  "placementGroup",
  "state",
  "fulfillmentLevel",
  "fulfillerName",
  "createdAtHour",
  "datetimeHour",
];

export function rearrangeFacetsEvents(facets: FacetEvent[]): FacetEvent[] {
  const rearranged = [...facets];

  rearranged.sort((a, b) => {
    const indexA = keysOrder.indexOf(a.name);
    const indexB = keysOrder.indexOf(b.name);

    if (indexA === -1 && indexB === -1) {
      return 0; // both elements are not in keysOrder, so they are equal
    } else if (indexA === -1) {
      return 1; // a is not in keysOrder, so it goes after b
    } else if (indexB === -1) {
      return -1; // b is not in keysOrder, so it goes after a
    }

    return indexA - indexB; // both elements are in keysOrder, so sort them based on their indices
  });

  return rearranged;
}

export function parseQueryParameter(query: string, paramName: string): Filter {
  const paramValue = qs.parse(query.substring(1))[paramName] as string;
  const [include, ...items] = paramValue ? paramValue.split("|") : [];
  return {
    name: paramName,
    include: (include || "Any") as "Any" | "All",
    items:
      items[0]?.trim() ? items[0].split(",").map((item) => ({ key: item, include: true })) : [],
  };
}