const translation = {
  en: {
    goBack: "Go Back",
    close: "Close",
    save: "Save",
    name: "Name",
    cancel: "Cancel Order",
    warning: "Are you sure you want to cancel this Order?",
    saveFilter: "Save Filter",
  },
  es: {
    saveFilter: "Guardar Filtro",
    name: "Nombre",
    close: "Cerrar",
    save: "Guardar",
    goBack: "Volver",
    cancel: "Cancelar pedido",
    warning: "¿Está seguro de que desea cancelar esta orden??",
  },
  de: {},
};

export default translation;
