import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ThreeWordAddress,
  w3wCoordinatesTo3wa,
  w3wToCoordinates,
} from "../../services/maps";
import styles from "./styles.module.css";
import MapLoader from "../MapInput/MapLoader";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTh } from "@fortawesome/free-solid-svg-icons";
import changeIcon from "../../assets/change.svg";
import { message } from "antd";
import GooglePlacesAutocomplete from "../Inputs/GeoPlacesAutocomplete";
import mapImage from "../../assets/image/map.jpg";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputText } from "../Inputs/InputText";
interface Props {
  w3w: ThreeWordAddress;
  setW3W: (w3w: string) => void;
  setData: (data: ThreeWordAddress, name: string) => void;
  line1?: string;
}

interface State {
  mode: string;
  words: string;
  coordinates: string;
  finishWords: boolean;
  turn: string;
  line1?: string;
}

class MapForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      mode: "address",
      words:
        this.props.w3w.words === "restrict.firmly.move"
          ? ""
          : this.props.w3w.words,
      coordinates:
        this.props.w3w.words === "restrict.firmly.move"
          ? ""
          : `${this.props.w3w.coordinates.lat},${this.props.w3w.coordinates.lng}`,
      finishWords: true,
      turn: "words",
      line1: this.props.line1,
    };
  }
  componentDidMount() {
    if (this.props.w3w && this.props.line1) {
      this.props.setData(this.props.w3w, this.state.line1 || ""); // Initial data update
    }
  }

  updateW3W = (newW3W: ThreeWordAddress, line1: string) => {
    this.props.setData(newW3W, line1);
  };

  componentDidUpdate(prevProps: Props, _prevState: State) {
    if (this.props.w3w !== prevProps.w3w) {
      this.setState({
        words: this.props.w3w.words,
        coordinates: `${this.props.w3w.coordinates.lat},${this.props.w3w.coordinates.lng}`,
      });

      this.props.setW3W(this.props.w3w.words);
      this.updateW3W(this.props.w3w, this.state.line1 || "");
      // Call the method to update w3w
    }
    if (this.props.line1 !== prevProps.line1) {
      this.setState({ line1: this.props.line1 || "" });
    }
  }

  search = async (text: string) => {
    if (text) {
      try {
        const address = await w3wToCoordinates(this.state.words.trim());

        this.updateW3W(address, this.state.line1 || "");

        // this.setState({ w3w: address });
      } catch (err) {
        if (err instanceof Error) {
          message.error(err.message);
        }
      }
    }
  };

  searchCoordinates = async (text: string) => {
    const match = text
      .trim()
      .match(/^([-+]?[\d]{1,2}\.\d+),\s*([-+]?[\d]{1,3}\.\d+?)$/);
    if (match) {
      const location = {
        lat: Number(match?.[1]) || 0,
        lng: Number(match?.[2]) || 0,
      };
      const threeWordAddress = await w3wCoordinatesTo3wa(location);
      this.updateW3W(threeWordAddress, this.state.line1 || "");
    }
  };

  render() {
    const { w3w } = this.props;

    return (
      <>
        <div style={{ marginBottom: "4em" }} className={styles.mapInputs}>
          <GooglePlacesAutocomplete
            line1={this.state.line1}
            onChange={(w3a) => {
              this.setState({ line1: w3a.name });
              this.updateW3W(w3a, w3a.name);
            }}
          />
        </div>

        <GridContainer
          justifyContent="center"
          alignItems="center"
          columns="1fr 4em 1fr"
        >
          <InputText
            id=""
            containerClassname={styles.minWidth}
            label={"w3w"}
            value={this.state.words}
            onFocus={() => {
              this.setState({ turn: "words" });
              this.setState({ coordinates: "" });
            }}
            onChange={(value) => {
              this.setState({ words: value });
            }}
          />
          <img
            alt=""
            src={changeIcon}
            style={{ cursor: "pointer", width: "23px", margin: "0 1em" }}
            onClick={() => {
              if (this.state.turn === "words") {
                this.search(this.state.words);
              } else {
                this.searchCoordinates(this.state.coordinates);
              }
            }}
          />
          <InputText
            containerClassname={styles.minWidth}
            label="Coordinates (lat,lng)"
            onFocus={() => {
              this.setState({ words: "" });
              this.setState({ turn: "coordinates" });
            }}
            value={this.state.coordinates}
            onChange={(value) => {
              console.log("value", value);
              this.setState({ coordinates: value });
            }}
          />
        </GridContainer>

        <div className={styles.map}>
          {this.props.w3w.words === "restrict.firmly.move" ? (
            <img src={mapImage} className={styles.mapContainer} alt="map" />
          ) : (
            <MapLoader
              type="w3w"
              showGrid={{
                icon: (
                  <FontAwesomeIcon
                    icon={faTh as IconProp}
                    className={styles.icon}
                  />
                ),
                text: "Show Grid",
              }}
              height="400px"
              value={w3w.coordinates}
              onChange={(w3a) => {
                this.props.setW3W(w3a.words);
                this.updateW3W(w3a, this.state.line1 || "");

                // this.setState({ w3w: w3a });
              }}
            />
          )}
        </div>
      </>
    );
  }
}

export default MapForm;
