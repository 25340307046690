import * as React from 'react';
import { EmptyList } from './EmptyList';
import styles from './styles.module.css';

export interface ListProps<T> {
  data: T[];
  keyExtractor: (item: T) => string;
  renderItem: (item: T) => JSX.Element;
  renderHeader: () => JSX.Element;
  loadingElement?: () => JSX.Element;
  error?: string;
  loading?: boolean;
  empty?: boolean;
  children?: React.ReactNode[] | React.ReactNode;
}

export function List<T>(props: React.PropsWithChildren<ListProps<T>>): JSX.Element {
  const { data, renderItem, renderHeader, loadingElement, error, loading, empty } = props;

  return (
    <div className={styles.list}>
      {renderHeader()}
      {loading ? (
        loadingElement?.()
      ) : error ? (
        <div className={styles.emptyPadding}>
          <EmptyList text="An error occurred." instructions="Error" error={error} />
        </div>
      ) : empty ? (
        <EmptyList instructions="Empty List" text="You don't have any items." />
      ) : (
        data.map((data) => renderItem(data))
      )}
    </div>
  );
}
