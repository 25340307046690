import React, { useState } from "react";

import { FOP } from "../../utils/types";
import ExistingFOP from "./ExistingFOP";
import { useTranslation } from "react-i18next";
import { SideMenu } from "../SideMenu/SideMenu";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { GridContainer } from "../GridContainer/GridContainer";

interface Props {
  open: boolean;
  loading: boolean;
  fops: FOP[];
  onClose: () => void;
  onAddToSet: (fopId: string) => void;
  load?: () => void;
}

const ExistingForm: React.FC<Props> = ({
  onClose,
  open,
  loading,
  onAddToSet,
  fops,
}) => {
  const [fopId, setFOPId] = useState("");
  const { t } = useTranslation();

  return (
    <SideMenu width="55%" open={open} onClose={() => onClose()}>
      <FlexContainer
        alignItems="flex-start"
        justifyContent="space-between"
        padding="2em 0 2em 0"
      >
        <FlexContainer alignItems="flex-start" flexDirection="column">
          <ContainerTitle
            size="medium"
            label={t("component.fopForm.existingForm")}
          />
        </FlexContainer>

        <ButtonsContainer>
          <Button
            label={t("component.fopForm.cancel")}
            onClick={() => onClose()}
            theme="white"
          />
          <Button
            label={loading ? "..." : t("component.fopForm.save")}
            onClick={async () => await onAddToSet(fopId)}
          />
        </ButtonsContainer>
      </FlexContainer>
      <GridContainer gap="2em" columns="1fr 1fr">
        {fops.map((item, index) => (
          <ExistingFOP
            account={item.data.account.bankAccount}
            alias={item.data.account.alias}
            key={index}
            id={item.id}
            type={item.type}
            supplierName={item.supplierName}
            supplierService={item.supplierService}
            active={fopId === item.id}
            onSelectType={(id) => setFOPId(id)}
          />
        ))}
      </GridContainer>
    </SideMenu>
  );
};

export default ExistingForm;
