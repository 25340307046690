const translation = {
  en: {
    goBack: "Go Back",
    cancel: "Fulfilled Order",
    warning: "Are you sure you want to fulfilled this Order?",
  },
  es: {
    goBack: "Volver",
    cancel: "Completar Orden",
    warning: "¿Está seguro de que desea completar esta orden??",
  },
  de: {},
};

export default translation;
