import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { classes } from "typestyle";
import styles from "./styles";

export interface ButtonProps {
  label?: string;
  size?: "small" | "medium";
  buttonClassname?: string;
  containerClassname?: string;
  theme?: "blue" | "white" | "link" | "orange";
  width?: string;
  minWidth?: string;
  height?: string;
  padding?: string;
  display?: "flex" | "block" | "none";
  justifyContent?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-evenly"
    | "space-around ";
  alignItems?: "center" | "flex-start" | "flex-end" | "space-between";
  disable?: boolean;
  type?: "button" | "submit";
  onFocus?: () => void;
  onBlur?: () => void;
  options: { label: string; click: () => void }[];
}

export const DoubleButton: React.FC<ButtonProps> = (props) => {
  const {
    label = "Text",
    size = "medium",
    buttonClassname,
    containerClassname,
    theme = "blue",
    width,
    minWidth,
    height,
    padding,
    display,
    justifyContent,
    alignItems,
    disable,
    type,
    onFocus,
    onBlur,
    options,
  } = props;

  const [open, setOpen] = React.useState(false);

  return (
    <>
      <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
        <div className={styles.wrapper}>
          <div className={containerClassname}>
            <button
              type={type || "button"}
              style={{
                width,
                minWidth,
                height,
                padding,
                display,
                justifyContent,
                alignItems,
              }}
              className={classes(buttonClassname, {
                [styles.buttonBlue]: theme === "blue",
                [styles.buttonWhite]: theme === "white",
                [styles.buttonOrange]: theme === "orange",
                [styles.buttonLink]: theme === "link",
                [styles.smallSize]: size === "small",
                [styles.mediumSize]: size === "medium",
                [styles.disable]: disable,
              })}
              onFocus={onFocus}
              onClick={() => setOpen(!open)}
              onBlur={onBlur}
            >
              <div>{label}</div>
              <div className={styles.iconContainer}>
                <FontAwesomeIcon
                  color={theme === "white" ? "#333" : "#fff"}
                  icon={
                    !open
                      ? (faChevronDown as IconProp)
                      : (faChevronUp as IconProp)
                  }
                />
              </div>
            </button>
          </div>
          <div
            style={{ display: open ? "block" : "none" }}
            className={styles.options}
          >
            {options?.map((option, index) => (
              <div
                onClick={() => option.click()}
                key={option.label + index}
                className={styles.option}
              >
                {option.label}
              </div>
            ))}
          </div>
        </div>
      </OutsideClickHandler>
    </>
  );
};
