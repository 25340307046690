const translation = {
  en: {
    deleteComment: "Are you sure you want to remove this comment?",
    cancel: "Cancel",
  },
  es: {
    deleteComment: "¿Está seguro de que desea eliminar este comentario?",
    cancel: "Cancelar",
  },
  de: {},
};

export default translation;
