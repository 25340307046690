import type { TransferProps } from "antd/es/transfer";
import type { ColumnsType, TableRowSelection } from "antd/es/table/interface";
import type { TransferItem } from "antd/es/transfer";
import difference from "lodash/difference";
import { Table, Transfer } from "antd";
import { TransferListBodyProps } from "antd/es/transfer/ListBody";

export interface RecordType {
  key: string;
  title: string;
  description: string;
  disabled: boolean;
  externalId: string;
  type: string;
  actionId: string;
}

export interface DataType {
  key: string;
  title: string;
  description: string;
  disabled: boolean;
  externalId: string;
  type: string;
  actionId: string;
}

export interface TableTransferProps extends TransferProps<DataType> {
  dataSource: DataType[];
  leftColumns: ColumnsType<TransferItem>;
  rightColumns: ColumnsType<TransferItem>;
}

const ProjectionTransfer = ({
  leftColumns,
  rightColumns,
  ...restProps
}: TableTransferProps) => (
  <Transfer {...restProps}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }: TransferListBodyProps<DataType>) => {
      const columns: ColumnsType<TransferItem> =
        direction === "left"
          ? leftColumns
          : (rightColumns as ColumnsType<TransferItem>);

      const rowSelection: TableRowSelection<TransferItem> = {
        getCheckboxProps: (item) => ({
          disabled: listDisabled || item.disabled,
        }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys as string[], selected);
        },
        onSelect({ key }, selected) {
          onItemSelect(key as string, selected);
        },
        selectedRowKeys: listSelectedKeys,
      };

      return (
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredItems}
          size="small"
          scroll={{ y: 170 }}
          pagination={{ pageSize: 40 }} // Pagination with 40 records per page
          style={{ pointerEvents: listDisabled ? "none" : undefined }}
          onRow={({ disabled: itemDisabled, key }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) return;
              onItemSelect(
                key as string,
                !listSelectedKeys.includes(key as string)
              );
            },
          })}
        />
      );
    }}
  </Transfer>
);

export default ProjectionTransfer;
