import React, { useState } from "react";
import "react-phone-input-2/lib/style.css";
import Text from "../Text";
import { useTranslation } from "react-i18next";
import styles from "./styles";
import { Alert, Checkbox } from "antd";
import InputTextDropdown from "../Inputs/InputTextDropdown";
import { timezones } from "../../constants/timezones";
import { Schedule } from "@innomius/ravent-typescript-types";
import dayjs from "dayjs";
import InputDate from "../Inputs/Date";
import { InputTime } from "../Inputs/InputTime/InputTime";
import { Modal } from "../Modal/Modal";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { InputText } from "../Inputs/InputText";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputSelect } from "../Inputs/InputSelect";

interface Props {
  onCancel: () => void;
  open: boolean;
  id: string;
  onSend: (actionId: string, schedule: Schedule) => void;
  contactEmail?: string;
  loading?: boolean;
  flows: { name: string; actionId: string; url: string }[];
  error: string;
  schedule: boolean;
  title?: string;
  setSchedule: (value: boolean) => void;
}

const defaultSchedule: Schedule = {
  id: "",
  url: "",
  type: "at",
  timezone: "",
  cronTabExpression: "",
  date: dayjs(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
  actionId: "",
  prettyName: "",
  lastStatus: "",
  description: "",
  notificationBody: "",
};

const ScheduleFlow: React.FC<Props> = ({
  onCancel,
  onSend,
  open,
  loading,
  flows,
  error,
  schedule,
  title,
  setSchedule,
}) => {
  const [flow, setFlow] = useState("");

  const [sched, setSched] = useState<Schedule>(defaultSchedule);

  const onChange = <P extends keyof Schedule>(prop: P, value: Schedule[P]) => {
    setSched({ ...sched, [prop]: value });
  };
  const flowsOptions = flows
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter((item) => item.url !== "")
    .map((item) => ({
      label: `${item.name.replace("_hook_url", " ")}`,
      value: item.actionId,
    }));

  const validPhone = (inputtxt: string) => {
    const numbers = /^[-+.0-9]{8,}$/;
    if (inputtxt.match(numbers)) {
      return true;
    } else {
      return false;
    }
  };

  const { t } = useTranslation();

  return (
    <Modal
      width="800px"
      buttons={
        <div>
          <ButtonsContainer justifyContent="flex-end">
            <Button
              onClick={onCancel}
              theme="white"
              label={t("component.sendModal.cancel")}
            />
            <Button
              onClick={async () => {
                if (loading) {
                  return;
                }
                try {
                  await onSend(flow, sched);
                  setFlow("");
                } catch (err) {}
              }}
              theme="blue"
              label={
                loading
                  ? "..."
                  : schedule
                  ? `${t("component.sendModal.schedule")}`
                  : `${t("component.sendModal.sendManually")}`
              }
              disable={!validPhone || !flowsOptions?.length}
            />
          </ButtonsContainer>
        </div>
      }
      open={open}
      onClose={() => {
        setFlow("");
        onCancel();
      }}
    >
      {error ? (
        <div className={styles.alertContainer}>
          <Alert showIcon message={error} type="error" />
        </div>
      ) : null}

      <div className={styles.container}>
        <Text
          text={title ? title : t("component.sendModal.sendToFlow")}
          fontSize="1.9em"
          padding="0 0 0.2em 0"
          fontWeight={700}
        />
        <Text text={t("component.sendModal.instructions")} fontSize="1em" />

        {flowsOptions?.length ? (
          <>
            <InputSelect
              maxHeightOptions="10em"
              containerClassname={styles.select}
              label={t("component.sendModal.flows")}
              options={flowsOptions}
              value={flow}
              onChange={(value) => setFlow(value)}
            />
            <FlexContainer padding="0 0 1em 0" justifyContent="flex-start">
              <Checkbox
                value={schedule}
                onChange={(value) => setSchedule(value.target.checked)}
              />
              <Text padding="0 0 0 1em" text={t("component.sendModal.later")} />
            </FlexContainer>

            {schedule ? (
              <>
                <InputText
                  label={t("component.sendModal.name")}
                  value={sched.prettyName}
                  onChange={(value) => onChange("prettyName", value)}
                />
                <GridContainer
                  padding="0 0 2em 0"
                  gap="1em"
                  columns="1fr 1fr 1fr"
                >
                  <InputDate
                    label={t("component.orderStateList.when")}
                    id="issued"
                    value={(sched.date as Date) || new Date()}
                    onChange={(value) => {
                      onChange(
                        "date",
                        dayjs(value).format("YYYY-MM-DDTHH:mm:ss")
                      );
                    }}
                    disableDate={(current: any) => {
                      return current < dayjs(new Date());
                    }}
                  />
                  <InputTime
                    id="issuedDate"
                    value={(sched.date as Date) || new Date()}
                    onChange={(value) => {
                      onChange(
                        "date",
                        dayjs(value.value).format("YYYY-MM-DDTHH:mm:ss")
                      );
                    }}
                  />
                  <InputTextDropdown
                    label={t("component.orderFilters.timezone")}
                    value={sched.timezone}
                    error={
                      !sched.timezone && error ? "This field is mandatory" : ""
                    }
                    top={"calc(100% + 2em)"}
                    options={timezones.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                    onChange={(value) => {
                      onChange("timezone", value);
                    }}
                  />
                </GridContainer>
              </>
            ) : null}
          </>
        ) : (
          <div className={styles.notEnrollContainer}>
            {t("component.sendModal.noActions")}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ScheduleFlow;
