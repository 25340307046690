import {
  PaymentEvent,
  Payment,
  PayNetPayment,
} from "@innomius/ravent-typescript-types";

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { linkBlue } from "../../utils/colors";
import styles from "./styles";
import {
  isFirstData,
  isPayNet,
  isStorePayment,
  isWire,
} from "../../utils/typeGuards";
import Store from "./Store";
import FirstData from "./FirstData";
import WireData from "./WireData";
import PayNetData from "./PayNetData";
import { useTranslation } from "react-i18next";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ContainerTitle } from "../ContainerTitle";
import { Button } from "../Button";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { GridContainer } from "../GridContainer/GridContainer";
import { SectionProperty } from "../Section/SectionProperty";
import { SectionSpacer } from "../Section/SectionSpacer";
import { ButtonsContainer } from "../ButtonsContainer";

interface Props {
  data: Payment;
  paymentHistory?: PaymentEvent[];
  onClose: () => void;
  onDelete: (id: string) => void;
}

const RequestPaymentDetail: React.FC<Props> = ({ data, onClose, onDelete }) => {
  const [open, setOpen] = useState(false);

  const remoteDate = data as PayNetPayment;

  const { t } = useTranslation();

  const linkURL = window.location.host.includes("dev.ravent.com")
    ? `https://app.dev.ravent.com`
    : `https://app.ravent.com`;

  const openRavent = (link: string) => {
    window.location.host.includes("dev.ravent.com")
      ? window.open(`https://app.dev.ravent.com${link}`, "_blank")
      : window.open(`https://app.ravent.com${link}`, "_blank");
  };
  return (
    <div>
      <div className={styles.containerDetail}>
        <FlexContainer
          padding="0 0 1em 0"
          alignItems="center"
          justifyContent="space-between"
        >
          <ContainerTitle
            size="xsmall"
            label={t("component.requestPaymentDetail.requestDetails")}
          />
          <Button
            label={t("component.requestPaymentDetail.delete")}
            theme="red"
            onClick={() => onDelete(data.id)}
          />
        </FlexContainer>
        <GridContainer
          gap="1em"
          columns="1fr 1fr"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <SectionProperty
            label={t("component.requestPaymentDetail.paymentReqId")}
            value={remoteDate.id}
          />
          <SectionProperty
            label={t("component.requestPaymentDetail.supplier")}
            value={remoteDate.supplierName}
          />
          <div
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              if (remoteDate.publicPaymentLink) {
                openRavent(remoteDate.publicPaymentLink);
              }
            }}
          >
            <SectionProperty
              label={t("component.requestPaymentDetail.link")}
              value={`${linkURL}${remoteDate.publicPaymentLink}`}
            />
          </div>
        </GridContainer>
        <SectionSpacer />
        <ContainerTitle
          size="xsmall"
          padding="0 0 1em 0"
          label={t("component.requestPaymentDetail.requestData")}
        />
        {isStorePayment(data) ? <Store data={data} /> : null}
        {isFirstData(data) ? <FirstData data={data} /> : null}
        {isWire(data) ? <WireData data={data} /> : null}
        {isPayNet(data) ? <PayNetData data={data} /> : null}
      </div>
      <ButtonsContainer justifyContent="flex-end">
        <div
          className={styles.closeButton}
          onClick={() => {
            setOpen(!open);
            onClose();
          }}
        >
          {t("component.requestPaymentDetail.close")}
          <FontAwesomeIcon
            icon={faChevronUp as IconProp}
            className={styles.chevron}
            color={linkBlue}
          />
        </div>
      </ButtonsContainer>
    </div>
  );
};

export default RequestPaymentDetail;
