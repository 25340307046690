import { stylesheet } from "typestyle";
import { black, darkBlack, linkBlue } from "../../utils/colors";

const styles = stylesheet({
  container: {
    borderTop: "1px solid #dce1e6",
  },
  row: {
    padding: "1em 2em 1em 2em",
    borderBottom: "1px solid #dce1e6",
  },
  amountRow: {
    padding: "2em 2em 1em 2em",
  },
  info: {
    fontSize: "1em",
    fontWeight: 400,
    color: black,
    paddingBottom: "0.2em",
  },
  infoBlack: {
    fontSize: "1em",
    fontWeight: 400,
    color: "#767676",
    paddingBottom: "0.2em",
  },
  lock: {
    width: "14px",
    margin: "0em 0.5em 0.7em 0",
  },
  infoBold: {
    fontSize: "1em",
    fontWeight: 600,
    color: black,
    paddingBottom: "0.2em",
  },
  infoSmallGray: {
    fontSize: "0.8em",
    fontWeight: 400,
    color: "#858d8f",
    paddingBottom: "0.2em",
  },

  infoGray: {
    fontSize: "1em",
    fontWeight: 400,
    color: "#858d8f",
    paddingBottom: "0.2em",
  },
  infoRed: {
    fontSize: "1em",
    fontWeight: 400,
    color: "#FF2929",
    paddingBottom: "0.2em",
  },
  title: {
    color: darkBlack,
    fontWeight: "bold",
    fontSize: "1.1em",
    paddingBottom: "0.3em",
    $nest: {
      "&:hover": {
        color: linkBlue,
      },
    },
  },
  hover: {
    $nest: {
      "&:hover > div": {
        color: linkBlue,
      },
    },
  },
  quantity: {
    fontSize: "1.1em",
    color: darkBlack,
    fontWeight: 400,
    textAlign: "center",
  },
  numbers: {
    fontSize: "1em",
    textAlign: "right",
    color: darkBlack,
    fontWeight: 400,
    marginBottom: "0.5em",
  },
  sectionRight: {
    fontSize: "1.1em",
    textAlign: "right",
    color: darkBlack,
    fontWeight: 400,
  },
  sectionBold: {
    paddingTop: "0.5em",
    fontSize: "1.2em",
    textAlign: "right",
    color: darkBlack,
    fontWeight: 700,
  },
  sectionGray: {
    fontSize: "1.1em",
    textAlign: "right",
    color: "#858d8f",
    fontWeight: 400,
  },
  sectionBlack: {
    fontSize: "1.1em",
    textAlign: "right",
    color: "#767676",
    fontWeight: 400,
  },
  sectionRed: {
    fontSize: "1.1em",
    textAlign: "right",
    color: "#FF2929",
    fontWeight: 400,
  },
  total: {
    marginTop: "1em",
    fontSize: "1.2em !important",
    fontWeight: "bold",
    color: darkBlack + "!!important",
    textAlign: "right",
  },
  chevron: {
    width: "1em",
    color: linkBlue,
    marginLeft: "1em",
  },
  chevronGray: {
    width: "1em",
    color: "#858d8f",
    marginLeft: "1em",
  },
  select: {
    cursor: "pointer",
  },
  blueInputSelect: {
    color: linkBlue + "!important",
    border: "0px solid !important",
  },
  options: {
    position: "relative",
    top: "calc(0em + 3px)",
    width: "100%",
    boxShadow: "0px 0px 2px 1px rgba(235, 235, 235, 1)",
    borderRadius: " 2",
    overflow: "hidden",
    scrollPaddingTop: "2em",
    overflowY: "scroll",
    maxHeight: "12em",
    zIndex: 3,
  },
  option: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    padding: "0.5em",
    height: "2.5em",
    background: "#fff",
    color: "#333",
    $nest: {
      "&:hover": {
        backgroundColor: "#dedede",
      },
    },
  },
});

export default styles;
