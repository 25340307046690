import get from "lodash/get";
import { OfferSet, Price } from "@innomius/ravent-typescript-types";
import { offersets } from "../mocks/offersets";

export function parseOfferSet(data: unknown): OfferSet {
  return {
    id: get(data, "id", ""),
    created: get(data, "created", ""),
    updated: get(data, "updated", ""),
    deleted: get(data, "deleted", false),
    enabled: get(data, "enabled", false),
    organizationId: get(data, "organizationId", ""),
    name: get(data, "name", ""),
    startingPrice: {
      currency: get(data, "startingPrice.currency", ""),
      value: get(data, "startingPrice.amount", 0),
    },
    image: get(data, "image", ""),
    description: get(data, "description", ""),
    offers: [],
  };
}

export async function offerSetList(): Promise<OfferSet[]> {
  const res = offersets; // this is the fetch response;
  const data = res.map(parseOfferSet);
  return data;
}

export async function offerSetRead(id: string): Promise<OfferSet> {
  const res = offersets.find((item) => item.id === id);
  const data = parseOfferSet(res);
  return data;
}

export async function getOfferItem(id: string): Promise<OfferSet> {
  const res = offersets.find((item) => item.id === id);
  const data = parseOfferSet(res);
  return data;
}

export const defaultOfferSet: OfferSet = {
  id: "",
  created: new Date(),
  deleted: false,
  enabled: true,
  updated: new Date(),
  organizationId: "",
  name: "",
  startingPrice: { currency: "", value: 0 },
  image: "",
  description: "",
  offers: [],
};

export interface OfferSetParams {
  name: string;
  image: string;
  description: string;
  startingPrice: Price;
}

export const defaultAddOfferSet: OfferSetParams = {
  name: "",
  image: "",
  description: "",
  startingPrice: {
    value: 0,
    currency: "$",
  },
};
