import React, { useEffect, useState } from "react";

import {
  defaultAccount,
  FOPCatalogSearchResponse,
  FOPSearchResponse,
} from "../../services/fop";
import { BankAccount } from "../../utils/types";
import FOPForm from "../../components/FOPForm";
import FOPCard from "../../components/FOPCard";
import { useTranslation } from "react-i18next";
import { IntegrationsSearchResponse } from "../../services/integrations";
import styles from "./styles.module.css";
import { ContainerTitle } from "../ContainerTitle";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { Button } from "../Button";
import Search from "antd/es/input/Search";
import { EmptyList } from "../List/EmptyList";
import { Card } from "../Card/Card";

interface Props {
  add: boolean;
  setAdd: (value: boolean) => void;
  catalogs: {
    loading: boolean;
    error: string;
    list: FOPCatalogSearchResponse;
  };
  fops: {
    loading: boolean;
    error: string;
    list: FOPSearchResponse;
  };
  integrations: {
    loading: boolean;
    error: string;
    list: IntegrationsSearchResponse;
  };
  load: () => void;
  loading: string;
  setLoading: (value: string) => void;
  onChangeBankAccount: (id: string, bankData: BankAccount) => void;
  onCreate: (
    catalogId: string,
    round: string,
    bankAccount: BankAccount
  ) => void;
  onRemove: (id: string) => void;
  supplierName: string;
  setSupplierName: (value: string) => void;
}

const FOPs: React.FC<Props> = ({
  add,
  setAdd,
  catalogs,
  fops,
  loading,
  onCreate,
  onChangeBankAccount,
  onRemove,
  setSupplierName,
  integrations,
}) => {
  const { t } = useTranslation();
  const [account, setAccount] = useState<BankAccount>(defaultAccount);

  useEffect(() => {
    document.title = "RAVENT APP :: FOPs";
  });

  const onChange = <P extends keyof BankAccount>(
    prop: P,
    value: BankAccount[P]
  ) => {
    setAccount({ ...account, [prop]: value });
  };

  return (
    <Card>
      <div className={styles.container}>
        <FOPForm
          onCreate={(id: string, round: string) => onCreate(id, round, account)}
          loading={loading === "create"}
          account={account}
          onChangeAccount={onChange}
          fopCatalog={catalogs.list.data}
          open={add}
          onClose={() => {
            setAccount(defaultAccount);
            setAdd(false);
          }}
        />

        <FlexContainer
          padding="0 0 0.5em 0"
          alignItems="center"
          justifyContent="space-between"
        >
          <ContainerTitle
            size="small"
            label={t("container.fops.fops")}
            count={fops.list.total.toString()}
          />
          <Button
            label={t("container.fops.createNew")}
            onClick={() => setAdd(true)}
          />
        </FlexContainer>

        <div className={styles.searchContainer}>
          <Search
            style={{ marginBottom: "1em" }}
            onSearch={() => {}}
            placeholder={t("container.fops.search")}
          />
        </div>
        {!fops.list.data.length && !fops.error && !fops.loading ? (
          <div className={styles.wrapperCard}>
            <EmptyList
              instructions={""}
              text={t("container.fops.instructions")}
              buttonText={t("container.fops.createNew")}
              onNewClick={() => setAdd(true)}
            />
          </div>
        ) : (
          fops.list.data.map((item) => (
            <FOPCard
              noBorder
              list={false}
              loading={loading === item.id ? item.id : loading}
              setSupplierName={() => setSupplierName(item.supplierName)}
              key={item.id}
              onEditBank={(id, bankAccount) =>
                onChangeBankAccount(id, bankAccount)
              }
              round={item.round}
              onRemove={(id: string) => onRemove(item.id)}
              bankAccountData={item.data.account}
              integrationRequired={item.integrationRequired}
              integrationName={
                integrations.list.data.find(
                  (integration) =>
                    integration.supplierName === item.supplierName
                )?.supplierName
              }
              supplierName={item.supplierName}
              supplierService={item.supplierService}
              type={item.type}
              active={item.active}
              id={item.id}
            />
          ))
        )}
      </div>
    </Card>
  );
};

export default FOPs;
