import React from "react";
import { TimePicker } from "antd";
import styles from "./styles.module.css";
import dayjs, { Dayjs } from "dayjs";
import { InputLabel } from "../InputLabel/InputLabel";

export interface Change {
  id: string;
  value: Date;
}
export interface Props {
  id: string;
  defaultValue?: Date | null;
  label?: string;
  placeholder?: string;
  onChange?: (change: Change) => void;
  disabled?: boolean;
  disableDate?: (date: any) => boolean;
  value?: Date;
  showLabel?: boolean;
}

export const InputTime: React.FC<Props> = (props) => {
  const {
    id,
    defaultValue,
    label,
    onChange,
    disabled,
    placeholder,
    disableDate,
    value,
    showLabel
  } = props;
  const toMomentValue = dayjs(defaultValue);
  const change = (value: Dayjs | null) => {
    if (onChange) {
      const toDateValue: Date = dayjs(value).toDate();
      onChange({ id, value: toDateValue });
    }
  };
  return (
    <div className={styles.inputContainer}>
      <InputLabel showLabel={showLabel} label={label} />
      <TimePicker
        disabledDate={disableDate}
        className={styles.input}
        defaultValue={toMomentValue}
        onChange={change}
        disabled={disabled}
        value={dayjs(value)}
        placeholder={placeholder}
        use12Hours
        format="hh:mm A"
      />
    </div>
  );
};
