import OfferSetDetail from "./OfferSetDetail/i18n";
import OfferList from "./OfferListComponent/i18n";
import OfferForm from "./OfferForm/i18n";
import OfferSetForm from "./OfferSetForm/i18n";
import OfferSetDetailHeader from "./OfferSetDetailHeader/i18n";
import OfferDetail from "./OfferDetail/i18n";
import VenueList from "./VenueList/i18n";
import VenueForm from "./VenueForm/i18n";
import VenueDetailHeader from "./VenueDetailHeader/i18n";
import VenueDetail from "./VenueDetail/i18n";
import UserMenu from "./UserMenu/i18n";
import UserList from "./UserList/i18n";
import UserForm from "./UserForm/i18n";
import UserDetailComponent from "./UserDetailComponent/i18n";
import Ticketing from "./Ticketing/i18n";
import SendModal from "./SendModal/i18n";
import RequestPaymentForm from "./RequestPaymentForm/i18n";
import RequestPaymentDetail from "./RequestPaymentDetail/i18n";
import RegisterPaymentForm from "./RegisterPaymentForm/i18n";
import RefundForm from "./RefundForm/i18n";
import RefundDetail from "./RefundDetail/i18n";
import RefundConfirmationModal from "./RefundConfirmationModal/i18n";
import PaymentSupplierList from "./PaymentSupplierList/i18n";
import PaymentRequestList from "./PaymentRequestList/i18n";
import PaymentDetail from "./PaymentDetail/i18n";
import PartialRefundForm from "./PartialRefundForm/i18n";
import OverPaidModal from "./OverPaidModal/i18n";
import OrderRequestPayment from "./OrderRequestPayment/i18n";
import OrderRefunds from "./OrderRefunds/i18n";
import OrderRefundList from "./OrderRefundList/i18n";
import OrderPayments from "./OrderPayments/i18n";
import OrderPaymentList from "./OrderPaymentList/i18n";
import OrderList from "./OrderList/i18n";
import OrderInvoiceF from "./OrderInvoiceF/i18n";
import OrderHistory from "./OrderHistory/i18n";
import OrderHeader from "./OrderHeader/i18n";
import OrderFulfilmentComponent from "./OrderFulfillmentComponent/i18n";
import OrderFulfillment from "./OrderFulfillment/i18n";
import OrderFilters from "./OrderFilters/i18n";
import OrderDetailItemList from "./OrderDetailItemList/i18n";
import OrderDetail from "./OrderDetail/i18n";
import OrderComments from "./OrderComments/i18n";
import OrderCommentList from "./OrderCommentList/i18n";
import OperationalHoursForm from "./OperationlHoursForm/i18n";
import OfficialMediaList from "./OfficialMediaList/i18n";
import LocationMap from "./LocationMap/i18n";
import LocationList from "./LocationList/i18n";
import LocationForm from "./LocationForm/i18n";
import LocationDetail from "./LocationDetail/i18n";
import Loading from "./Loading/i18n";
import LayoutWithSideBar from "./LayoutWithSideBar/i18n";
import InvoiceForm from "./InvoiceForm/i18n";
import IntegrationList from "./IntegrationList/i18n";
import IntegrationForm from "./IntegrationForm/i18n";
import IntegrationFilters from "./IntegrationFilters/i18n";
import IntegrationDetail from "./IntegrationDetail/i18n";
import GlobalDetail from "./GlobalDetail/i18n";
import TicketingDefDetails from "./PassDefDetails/i18n";
import FulfillmentStates from "./FulfillmentStates/i18n";
import FulfillmentList from "./FulfillmentList/i18n";
import FulfillmentForm from "./FulfillmentForm/i18n";
import FulfillmentDetailComponent from "./FulfillmentDetailComponent/i18n";
import FOPSetList from "./FOPSetList/i18n";
import FOPSetForm from "./FOPSetForm/i18n";
import FOPForm from "./FOPForm/i18n";
import FOPDetail from "./FOPDetail/i18n";
import FOPCard from "./FOPCard/i18n";
import EntityList from "./EntityList/i18n";
import EntityHeader from "./EntityHeader/i18n";
import EntityForm from "./EntityForm/i18n";
import EntityDivers from "./EntityFulfillers/i18n";
import EntityDetailComponent from "./TeamDetailComponent/i18n";
import EditProfileForm from "./EditProfileForm/i18n";
import EditGlobal from "./EditGlobal/i18n";
import DiversForm from "./FulfillersForm/i18n";
import DiverList from "./FulfillerList/i18n";
import DiverDetail from "./FulfillerDetail/i18n";
import DeliveryForm from "./DeliveryForm/i18n";
import DeletePopUp from "./DeletePopUp/i18n";
import DeleteModal from "./DeleteModal/i18n";
import ChangePassword from "./ChangePassword/i18n";
import CancelOrderModal from "./CancelOrderModal/i18n";
import CancelInvoiceModal from "./CancelInvoiceModal/i18n";
import AddressForm from "./AddressForm/i18n";
import AccountList from "./AccountList/i18n";
import OrderlineStateList from "./OrderlineStateList/i18n";
import OrderStateList from "./OrderStateList/i18n";
import VenueStates from "./VenueStates/i18n";
import ErrorBox from "./ErrorBox/i18n";
import DisableDiverModal from "./FulfillerStateModal/i18n";
import VenueEvents from "./VenueEvents/i18n";
import OrderInvoiceList from "./OrderInvoiceList/i18n";
import InvoiceDetail from "./InvoiceDetail/i18n";
import AddInvoice from "./AddInvoice/i18n";
import TicketingDefList from "./PassDefList/i18n";
import TicketingDefForm from "./PassDefForm/i18n";
import OrderLocation from "./OrderEvent/i18n";
import OrderlineDetail from "./OrderlineDetail/i18n";
import OrderCertificate from "./OrderCertificate/i18n";
import TicketComponent from "./TicketComponent/i18n";
import OrderlineTicket from "./OrderlineTicket/i18n";
import InvoiceStateList from "./InvoiceStateList/i18n";
import VenueLocations from "./VenueLocations/i18n";
import VenueFulfillmentStates from "./VenueFulfillmentStates/i18n";
import VenueTicketing from "./VenueTicketing/i18n";
import AddIntegration from "./AddIntegration/i18n";
import OrderTeam from "./OrderTeam/i18n";
import CheckinListInt from "./CheckinListInt/i18n";
import CloseOrderModal from "./CloseOrderModal/i18n";
import SchedulingInt from "./SchedulingInt/i18n";
import AddOrderline from "./AddOrderline/i18n";
import IssueRefundDyn from "./IssueRefundDyn/i18n";
import CancelInvoiceDyn from "./CancelInvoiceDyn/i18n";
import OrderActionList from "./OrderActionList/i18n";
import ActionListLogs from "./ScheduleLogList/i18n";
import Schedules from "./Schedules/i18n";
import AssetList from "./AssetList/i18n";
import AssetForm from "./AssetForm/i18n";
import AssetDetail from "./AssetDetail/i18n";
import AssetModal from "./AssetModal/i18n";
import PeopleModal from "./PeopleModal/i18n";
import CVSFileUploader from "./CVSFileUploader/i18n";
import ExportCVSFile from "./ExportCVSFile/i18n";
import DeleteLocationsModal from "./DeleteLocationsModal/i18n";
import DeleteAssetsModal from "./DeleteAssetsModal/i18n";
import TeamList from "./TeamList/i18n";
import FulfillerOverviewList from "./FulfillerOverviewList/i18n";
import BillingDetail from "./BillingDetail/i18n";
import OrgTags from "./OrgTags/i18n";
import LoadModal from "./LoadModal/i18n";
import SaveFilterModal from "./SaveFilterModal/i18n";
import OrderPasses from "./OrderPasses/i18n";
import RoleList from "./RoleList/i18n";
import RoleDetailComponent from "./RoleDetailComponent/i18n";
import RoleForm from "./RoleForm/i18n";
import ResetPasswordModal from "./ResetPasswordModal/i18n";
import InviteModal from "./InviteModal/i18n";
import MissingModal from "./MissingModal/i18n";


const translation = {
  en: {
    missingModal: MissingModal.en,
    inviteModal: InviteModal.en,
    resetPasswordModal: ResetPasswordModal.en,
    roleForm: RoleForm.en,
    roleDetailComponent: RoleDetailComponent.en,
    roleList: RoleList.en,
    orderPasses: OrderPasses.en,
    saveFilterModal: SaveFilterModal.en,
    loadModal: LoadModal.en,
    orgTags: OrgTags.en,
    billingDetail: BillingDetail.en,
    fulfillerOverviewList: FulfillerOverviewList.en,

    teamList: TeamList.en,
    deleteAssetsModal: DeleteAssetsModal.en,

    deleteLocationsModal: DeleteLocationsModal.en,
    exportCVSFile: ExportCVSFile.en,
    cvsFileUploader: CVSFileUploader.en,
    peopleModal: PeopleModal.en,
    assetModal: AssetModal.en,
    assetDetail: AssetDetail.en,
    assetForm: AssetForm.en,
    assetList: AssetList.en,
    schedules: Schedules.en,
    actionListLogs: ActionListLogs.en,
    orderActionList: OrderActionList.en,
    cancelInvoiceDyn: CancelInvoiceDyn.en,
    issueRefundDyn: IssueRefundDyn.en,
    addOrderline: AddOrderline.en,
    schedulingInt: SchedulingInt.en,
    closeOrderModal: CloseOrderModal.en,
    orderTeam: OrderTeam.en,
    checkinListInt: CheckinListInt.en,

    addIntegration: AddIntegration.en,
    venueTicketing: VenueTicketing.en,

    venueFulfillmentStates: VenueFulfillmentStates.en,
    venueLocations: VenueLocations.en,

    invoiceStateList: InvoiceStateList.en,
    orderlineTicket: OrderlineTicket.en,
    orderCertificate: OrderCertificate.en,
    ticketComponent: TicketComponent.en,
    orderlineDetail: OrderlineDetail.en,
    orderLocation: OrderLocation.en,
    ticketingDefForm: TicketingDefForm.en,
    ticketingDefList: TicketingDefList.en,
    addInvoice: AddInvoice.en,
    invoiceDetail: InvoiceDetail.en,
    orderInvoiceList: OrderInvoiceList.en,
    venueEvents: VenueEvents.en,
    disableDiverModal: DisableDiverModal.en,
    errorBox: ErrorBox.en,
    orderStateList: OrderStateList.en,
    offerSetDetail: OfferSetDetail.en,
    offerVariantDetail: OfferDetail.en,
    offerVariantForm: OfferForm.en,
    offerVariantList: OfferList.en,
    offerSetForm: OfferSetForm.en,
    offerSetDetailHeader: OfferSetDetailHeader.en,
    venueList: VenueList.en,
    venueForm: VenueForm.en,
    venueDetailHeader: VenueDetailHeader.en,
    venueDetail: VenueDetail.en,
    userMenu: UserMenu.en,
    userList: UserList.en,
    userForm: UserForm.en,
    userDetailComponent: UserDetailComponent.en,
    ticketing: Ticketing.en,
    sendModal: SendModal.en,
    requestPaymentForm: RequestPaymentForm.en,
    requestPaymentDetail: RequestPaymentDetail.en,
    registerPaymentForm: RegisterPaymentForm.en,
    refundForm: RefundForm.en,
    refundDetail: RefundDetail.en,
    refundModal: RefundConfirmationModal.en,
    paymentSupplierList: PaymentSupplierList.en,
    requestList: PaymentRequestList.en,
    paymentDetail: PaymentDetail.en,
    partialRefundForm: PartialRefundForm.en,
    overPaidModal: OverPaidModal.en,
    orderRequestPayment: OrderRequestPayment.en,
    orderlineStateList: OrderlineStateList.en,
    orderRefunds: OrderRefunds.en,
    orderRefundList: OrderRefundList.en,
    orderPayments: OrderPayments.en,
    orderPaymentList: OrderPaymentList.en,
    orderList: OrderList.en,
    orderInvoice: OrderInvoiceF.en,
    orderHistory: OrderHistory.en,
    orderHeader: OrderHeader.en,
    orderFulfilmentComponent: OrderFulfilmentComponent.en,
    orderFulfillment: OrderFulfillment.en,
    orderFilters: OrderFilters.en,
    orderDetailItemList: OrderDetailItemList.en,
    orderDetail: OrderDetail.en,
    orderComments: OrderComments.en,
    commentList: OrderCommentList.en,
    operationalHours: OperationalHoursForm.en,
    officialMediaList: OfficialMediaList.en,
    locationMap: LocationMap.en,
    locationList: LocationList.en,
    locationForm: LocationForm.en,
    locationDetail: LocationDetail.en,
    loading: Loading.en,
    layoutWithSideBar: LayoutWithSideBar.en,
    invoiceForm: InvoiceForm.en,
    integrationList: IntegrationList.en,
    integrationForm: IntegrationForm.en,
    integrationFilters: IntegrationFilters.en,
    integrationDetail: IntegrationDetail.en,
    globalDetail: GlobalDetail.en,
    ticketingDefDetails: TicketingDefDetails.en,
    fulfillmentStates: FulfillmentStates.en,
    fulfillmentList: FulfillmentList.en,
    fulfillmentForm: FulfillmentForm.en,
    fulfillmentDetail: FulfillmentDetailComponent.en,
    fopSetList: FOPSetList.en,
    fopSetForm: FOPSetForm.en,
    fopForm: FOPForm.en,
    fopDetail: FOPDetail.en,
    fopCard: FOPCard.en,
    entityList: EntityList.en,
    entityHeader: EntityHeader.en,
    entityForm: EntityForm.en,
    entityDivers: EntityDivers.en,
    entityDetail: EntityDetailComponent.en,
    editProfileForm: EditProfileForm.en,
    editGlobal: EditGlobal.en,
    diversForm: DiversForm.en,
    diverList: DiverList.en,
    diverDetail: DiverDetail.en,
    deliveryForm: DeliveryForm.en,
    deletePopUp: DeletePopUp.en,
    deleteModal: DeleteModal.en,
    changePassword: ChangePassword.en,
    cancelOrderModal: CancelOrderModal.en,
    cancelInvoiceModal: CancelInvoiceModal.en,
    addressForm: AddressForm.en,
    accountList: AccountList.en,
    venueStates: VenueStates.en,
  },
  es: {
    missingModal: MissingModal.es,
    inviteModal: InviteModal.es,
    resetPasswordModal: ResetPasswordModal,
    roleForm: RoleForm.es,
    roleDetailComponent: RoleDetailComponent.es,
    roleList: RoleList.es,
    orderPasses: OrderPasses.es,
    saveFilterModal: SaveFilterModal.es,
    loadModal: LoadModal.es,
    orgTags: OrgTags.es,
    billingDetail: BillingDetail.en,
    fulfillerOverviewList: FulfillerOverviewList.es,
    teamList: TeamList.es,
    deleteAssetsModal: DeleteAssetsModal.es,
    deleteLocationsModal: DeleteLocationsModal.es,
    exportCVSFile: ExportCVSFile.es,
    cvsFileUploader: CVSFileUploader.es,
    peopleModal: PeopleModal.es,
    assetModal: AssetModal.es,
    assetDetail: AssetDetail.es,
    assetForm: AssetForm.es,
    assetList: AssetList.es,
    schedules: Schedules.es,
    actionListLogs: ActionListLogs.es,
    orderActionList: OrderActionList.es,
    cancelInvoiceDyn: CancelInvoiceDyn.es,
    issueRefundDyn: IssueRefundDyn.es,
    addOrderline: AddOrderline.es,
    schedulingInt: SchedulingInt.es,
    closeOrderModal: CloseOrderModal.es,
    orderTeam: OrderTeam.es,
    checkinListInt: CheckinListInt.es,
    venueTicketing: VenueTicketing.es,
    addIntegration: AddIntegration.es,
    venueFulfillmentStates: VenueFulfillmentStates.es,
    venueLocations: VenueLocations.es,
    invoiceStateList: InvoiceStateList.es,
    orderlineTicket: OrderlineTicket.es,
    orderlineDetail: OrderlineDetail.es,
    orderLocation: OrderLocation.es,
    addInvoice: AddInvoice.es,
    ticketingDefList: TicketingDefList.es,
    ticketingDefForm: TicketingDefForm.es,
    invoiceDetail: InvoiceDetail.es,
    orderInvoiceList: OrderInvoiceList.es,
    venueEvents: VenueEvents.es,
    disableDiverModal: DisableDiverModal.es,
    errorBox: ErrorBox.es,
    orderStateList: OrderStateList.es,
    offerSetDetail: OfferSetDetail.es,
    orderlineStateList: OrderlineStateList.es,
    offerVariantDetail: OfferDetail.es,
    offerVariantForm: OfferForm.es,
    offerVariantList: OfferList.es,
    offerSetForm: OfferSetForm.es,
    offerSetDetailHeader: OfferSetDetailHeader.es,
    venueList: VenueList.es,
    venueForm: VenueForm.es,
    venueDetailHeader: VenueDetailHeader.es,
    venueDetail: VenueDetail.es,
    userMenu: UserMenu.es,
    userList: UserList.es,
    userForm: UserForm.es,
    userDetailComponent: UserDetailComponent.es,
    ticketing: Ticketing.es,
    sendModal: SendModal.es,
    requestPaymentForm: RequestPaymentForm.es,
    requestPaymentDetail: RequestPaymentDetail.es,
    registerPaymentForm: RegisterPaymentForm.es,
    refundForm: RefundForm.es,
    refundDetail: RefundDetail.es,
    refundModal: RefundConfirmationModal.es,
    paymentSupplierList: PaymentSupplierList.es,
    requestList: PaymentRequestList.es,
    paymentDetail: PaymentDetail.es,
    partialRefundForm: PartialRefundForm.es,
    overPaidModal: OverPaidModal.es,
    orderRequestPayment: OrderRequestPayment.es,
    orderRefunds: OrderRefunds.es,
    orderRefundList: OrderRefundList.es,
    orderPayments: OrderPayments.es,
    orderPaymentList: OrderPaymentList.es,
    orderList: OrderList.es,
    orderInvoice: OrderInvoiceF.es,
    orderHistory: OrderHistory.es,
    orderHeader: OrderHeader.es,
    orderFulfilmentComponent: OrderFulfilmentComponent.es,
    orderFulfillment: OrderFulfillment.es,
    orderFilters: OrderFilters.es,
    orderDetailItemList: OrderDetailItemList.es,
    orderDetail: OrderDetail.es,
    orderComments: OrderComments.es,
    commentList: OrderCommentList.es,
    operationalHours: OperationalHoursForm.es,
    officialMediaList: OfficialMediaList.es,
    locationMap: LocationMap.es,
    locationList: LocationList.es,
    locationForm: LocationForm.es,
    locationDetail: LocationDetail.es,
    loading: Loading.es,
    layoutWithSideBar: LayoutWithSideBar.es,
    invoiceForm: InvoiceForm.es,
    integrationList: IntegrationList.es,
    integrationForm: IntegrationForm.es,
    integrationFilters: IntegrationFilters.es,
    integrationDetail: IntegrationDetail.es,
    globalDetail: GlobalDetail.es,
    ticketingDefDetails: TicketingDefDetails.es,
    fulfillmentStates: FulfillmentStates.es,
    fulfillmentList: FulfillmentList.es,
    fulfillmentForm: FulfillmentForm.es,
    fulfillmentDetail: FulfillmentDetailComponent.es,
    fopSetList: FOPSetList.es,
    fopSetForm: FOPSetForm.es,
    fopForm: FOPForm.es,
    fopDetail: FOPDetail.es,
    fopCard: FOPCard.es,
    entityList: EntityList.es,
    entityHeader: EntityHeader.es,
    entityForm: EntityForm.es,
    entityDivers: EntityDivers.es,
    entityDetail: EntityDetailComponent.es,
    editProfileForm: EditProfileForm.es,
    editGlobal: EditGlobal.es,
    diversForm: DiversForm.es,
    diverList: DiverList.es,
    diverDetail: DiverDetail.es,
    deliveryForm: DeliveryForm.es,
    deletePopUp: DeletePopUp.es,
    deleteModal: DeleteModal.es,
    changePassword: ChangePassword.es,
    cancelOrderModal: CancelOrderModal.es,
    cancelInvoiceModal: CancelInvoiceModal.es,
    addressForm: AddressForm.es,
    accountList: AccountList.es,
    venueStates: VenueStates.es,
    orderCertificate: OrderCertificate.es,
    ticketComponent: TicketComponent.es,
  },
  de: {
    roleList: RoleList.de,
    saveFilterModal: SaveFilterModal.de,
    fulfillerOverviewList: FulfillerOverviewList.de,
    teamList: TeamList.de,
    deleteAssetsModal: DeleteAssetsModal.de,
    deleteLocationsModal: DeleteLocationsModal.de,
    exportCVSFile: ExportCVSFile.de,
    cvsFileUploader: CVSFileUploader.de,
    peopleModal: PeopleModal.de,
    assetModal: AssetModal.de,
    assetDetail: AssetDetail.de,
    assetForm: AssetForm.de,
    assetList: AssetList.de,
    schedules: Schedules.de,
    cancelInvoiceDyn: CancelInvoiceDyn.de,
    issueRefundDyn: IssueRefundDyn.de,
    addOrderline: AddOrderline.de,
    schedulingInt: SchedulingInt.de,
    closeOrderModal: CloseOrderModal.de,
    checkinListInt: CheckinListInt.de,
    venueTicketing: VenueTicketing.de,
    addIntegration: AddIntegration.de,
    venueFulfillmentStates: VenueFulfillmentStates.de,
    venueLocations: VenueLocations.de,
    invoiceStateList: InvoiceStateList.de,
    orderlineTicket: OrderlineTicket.de,
    orderCertificate: OrderCertificate.de,
    ticketComponent: TicketComponent.de,
    orderlineDetail: OrderlineDetail.de,
    ticketingDefList: TicketingDefList.de,
    ticketingDefForm: TicketingDefForm.de,
    orderLocation: OrderLocation.de,
    addInvoice: AddInvoice.de,
    invoiceDetail: InvoiceDetail.de,
    orderInvoiceList: OrderInvoiceList.de,
    venueEvents: VenueEvents.de,
    disableDiverModal: DisableDiverModal.de,
    erSetDetail: OfferSetDetail.de,
    errorBox: ErrorBox.de,
    orderStateList: OrderStateList.de,
    orderlineStateList: OrderlineStateList.de,
    offerVariantDetail: OfferDetail.de,
    offerVariantForm: OfferForm.de,
    offerVariantList: OfferList.de,
    offerSetForm: OfferSetForm.de,
    offerSetDetailHeader: OfferSetDetailHeader.de,
    venueList: VenueList.de,
    venueForm: VenueForm.de,
    venueDetailHeader: VenueDetailHeader.de,
    venueDetail: VenueDetail.de,
    userMenu: UserMenu.de,
    userList: UserList.de,
    userForm: UserForm.de,
    userDetailComponent: UserDetailComponent.de,
    ticketing: Ticketing.de,
    sendModal: SendModal.de,
    requestPaymentForm: RequestPaymentForm.de,
    requestPaymentDetail: RequestPaymentDetail.de,
    registerPaymentForm: RegisterPaymentForm.de,
    refundForm: RefundForm.de,
    refundDetail: RefundDetail.de,
    refundModal: RefundConfirmationModal.de,
    paymentSupplierList: PaymentSupplierList.de,
    requestList: PaymentRequestList.de,
    paymentDetail: PaymentDetail.de,
    partialRefundForm: PartialRefundForm.de,
    overPaidModal: OverPaidModal.de,
    orderRequestPayment: OrderRequestPayment.de,
    orderRefunds: OrderRefunds.de,
    orderRefundList: OrderRefundList.de,
    orderPayments: OrderPayments.de,
    orderPaymentList: OrderPaymentList.de,
    orderList: OrderList.de,
    orderInvoice: OrderInvoiceF.de,
    orderHistory: OrderHistory.de,
    orderHeader: OrderHeader.de,
    orderFulfilmentComponent: OrderFulfilmentComponent.de,
    orderFulfillment: OrderFulfillment.de,
    orderFilters: OrderFilters.de,
    orderDetailItemList: OrderDetailItemList.de,
    orderDetail: OrderDetail.de,
    orderComments: OrderComments.de,
    commentList: OrderCommentList.de,
    operationalHours: OperationalHoursForm.de,
    officialMediaList: OfficialMediaList.de,
    locationMap: LocationMap.de,
    locationList: LocationList.de,
    locationForm: LocationForm.de,
    locationDetail: LocationDetail.de,
    loading: Loading.de,
    layoutWithSideBar: LayoutWithSideBar.de,
    invoiceForm: InvoiceForm.de,
    integrationList: IntegrationList.de,
    integrationForm: IntegrationForm.de,
    integrationFilters: IntegrationFilters.de,
    integrationDetail: IntegrationDetail.de,
    globalDetail: GlobalDetail.de,
    ticketingDefDetails: TicketingDefDetails.de,
    fulfillmentStates: FulfillmentStates.de,
    fulfillmentList: FulfillmentList.de,
    fulfillmentForm: FulfillmentForm.de,
    fulfillmentDetail: FulfillmentDetailComponent.de,
    fopSetList: FOPSetList.de,
    fopSetForm: FOPSetForm.de,
    fopForm: FOPForm.de,
    fopDetail: FOPDetail.de,
    fopCard: FOPCard.de,
    entityList: EntityList.de,
    entityHeader: EntityHeader.de,
    entityForm: EntityForm.de,
    entityDivers: EntityDivers.de,
    entityDetail: EntityDetailComponent.de,
    editProfileForm: EditProfileForm.de,
    editGlobal: EditGlobal.de,
    diversForm: DiversForm.de,
    diverList: DiverList.de,
    diverDetail: DiverDetail.de,
    deliveryForm: DeliveryForm.de,
    deletePopUp: DeletePopUp.de,
    deleteModal: DeleteModal.de,
    changePassword: ChangePassword.de,
    cancelOrderModal: CancelOrderModal.de,
    cancelInvoiceModal: CancelInvoiceModal.de,
    addressForm: AddressForm.de,
    accountList: AccountList.de,
    venueStates: VenueStates.de,
  },
};

export default translation;
