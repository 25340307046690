import { Action } from "@innomius/ravent-typescript-types";
import fetch from "../utils/fetch";
import { getAccessToken } from "./auth";
import { get, isNil, omitBy } from "lodash";

export function parseAction(data: unknown): Action {
  return {
    id: get(data, "_id", ""),
    notificationBody: get(data, "notificationBody", ""),
    orderOnly: get(data, "orderOnly", false),
    manualTrigger: get(data, "manualTrigger", false),
    name: get(data, "name", ""),
    url: get(data, "url", ""),
    description: get(data, "description", ""),
    dataCollectionUrl: get(data, "dataCollectionUrl", ""),
  };
}

export interface ActionParams {
  page?: number;
  records_per_page?: number;
  venue_id?: string;
  manualTrigger?: boolean;
}

function mapSortsFullText(data: ActionParams) {
  return omitBy(
    {
      page: data.page,
      records_per_page: data.records_per_page,
      venue_id: data.venue_id || null,
    },
    isNil
  );
}

export interface ActionSearchResponse {
  data: Action[];
  page: number;
  total: number;
}

export async function listActionsOld(
  params: ActionParams
): Promise<ActionSearchResponse> {
  const token = await getAccessToken();

  const res = await fetch(
    "/actions",
    token,
    "GET",
    null,
    mapSortsFullText(params)
  );
  return {
    data: res.body ? res.body.hits.map(parseAction) : [],
    page: get(res.body, "page", 0),
    total: get(res.body, "count", 0),
  };
}

export async function listActions(
  params: ActionParams
): Promise<ActionSearchResponse> {
  const token = await getAccessToken();

  const bodyVenue = {
    page: params.page,
    records_per_page: params.records_per_page,
    venue_id: params.venue_id,
    manualTrigger: params.manualTrigger,
  };

  const body = {
    page: params.page,
    records_per_page: params.records_per_page,
    manualTrigger: params.manualTrigger,
  };

  const res = await fetch(
    "/actions/paginate",
    token,
    "POST",
    params.venue_id ? bodyVenue : body
  );
  return {
    data: res.body ? res.body.hits.map(parseAction) : [],
    page: get(res.body, "page", 0),
    total: get(res.body, "count", 0),
  };
}

export async function deleteAction(id: string) {
  const token = await getAccessToken();

  await fetch(`/actions/${id}`, token, "DELETE");
  return;
}

export async function removeAction(id: string) {
  const token = await getAccessToken();

  await fetch(`/actions/${id}/removeSchedule`, token, "PATCH");
  return;
}

export async function updateAction(
  id: string,
  update: Action
): Promise<Action> {
  const token = await getAccessToken();

  const body = {
    name: update.name,
    url: update.url,
    description: update.description,
    notificationBody: JSON.parse(JSON.stringify(update.notificationBody)),
    orderOnly: update.orderOnly,
    manualTrigger: update.manualTrigger,
    dataCollectionUrl: update.dataCollectionUrl,
  };

  const res = await fetch(`/actions/${id}`, token, "PATCH", body);
  const data = parseAction(res.body);

  return data;
}

export async function getAction(id: string): Promise<Action> {
  const token = await getAccessToken();
  const res = await fetch(`/actions/${id}`, token);
  const data = parseAction(res.body);
  return data;
}

export async function createAction(data: Action): Promise<Action> {
  const body = {
    name: data.name,
    url: data.url,
    description: data.description,
    notificationBody: data.notificationBody,
    orderOnly: data.orderOnly,
    manualTrigger: data.manualTrigger,
    dataCollectionUrl: data.dataCollectionUrl,
  };
  const token = await getAccessToken();
  const res = await fetch("/actions", token, "POST", body);
  return res.body;
}

export interface RunParams {
  actionId?: string;
  ordersIds?: string[];
  eventsIds?: string[];

  notificationBody?: Record<string, string>;
  url?: string;
}

export async function runAction(data: RunParams): Promise<void> {
  const token = await getAccessToken();

  const body = {
    actionId: data.actionId,
    notificationBody: data.notificationBody,
    ordersIds: data.ordersIds,
  };

  await fetch("/webhookNotifications/manual", token, "POST", body);
  return;
}
