import React from "react";
import Text from "../Text";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { Modal } from "../Modal/Modal";
import { Button } from "../Button";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import info from "../../assets/infoBlue.svg";
import failed from "../../assets/failed.svg";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { GridContainer } from "../GridContainer/GridContainer";

interface Props {
  onCancel: () => void;
  open: boolean;
  total: number;
  affected: {
    id: string;
    name: string;
    loginWithPhone: boolean;
    phone?: string;
    email?: string;
  }[];
}

const MissingModal: React.FC<Props> = ({ onCancel, open, total, affected }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Modal
        width="600px"
        buttons={
          <div>
            <FlexContainer justifyContent="flex-end">
              <Button
                onClick={onCancel}
                theme="white"
                label={t("component.missingModal.close")}
              />
            </FlexContainer>
          </div>
        }
        open={open}
        onClose={onCancel}
      >
        <div style={{ padding: "0 1em" }}>
          <Text
            padding="1em 0 1em 0"
            text={t("component.missingModal.missing")}
            color="#000000"
            fontSize="1.5em"
            fontWeight={700}
          />
          <Text
            padding="0em 0 2em 0"
            text={t("component.missingModal.instructions")}
            color="#000000"
            fontSize="1.1em"
            fontWeight={400}
          />
          <div className={styles.instructionsContainer}>
            <FlexContainer
              padding="0 0 1em 0"
              alignItems="center"
              justifyContent="flex-start"
            >
              <img src={info} alt="" className={styles.icon} />
              <Text
                padding="0 0 0 0.5em"
                text={`${t("component.missingModal.affectedUsers")}: ${
                  affected?.length
                } ${t("component.missingModal.of")} ${total} ${t(
                  "component.missingModal.selected"
                )}`}
                color="#000000"
                fontSize="1.1em"
                fontWeight={700}
              />
            </FlexContainer>
            <GridContainer justifyContent="flex-start" columns="1fr 2fr">
              <ListHeaderLabel
                justifyContent="flex-start"
                textAlign="left"
                value={`${t("component.missingModal.user")}`}
              />
              <ListHeaderLabel
                justifyContent="flex-start"
                textAlign="left"
                value={`${t("component.missingModal.status")}`}
              />
            </GridContainer>
            <div className={styles.fieldContainer}>
              <div style={{ maxHeight: "300px", overflowY: "scroll" }}>
                <GridContainer columns="1fr 2fr">
                  {affected.map((item, index) => (
                    <>
                      <div className={styles.fieldItem}>
                        <Text text={`${item.name}`} />
                      </div>
                      <div className={styles.field}>
                        <img src={failed} alt="" className={styles.smallIcon} />
                        <Text
                          padding="0 0 0 0.5em"
                          text={
                            item.loginWithPhone
                              ? t("component.missingModal.missingEmail")
                              : t("component.missingModal.missingPhone")
                          }
                        />
                      </div>
                    </>
                  ))}
                </GridContainer>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MissingModal;
