import React, { useState } from "react";

import acceptIcon from "../../assets/officialMedia/default-checkmark.svg";
import quitIcon from "../../assets/quit.svg";
import { Media } from "@innomius/ravent-typescript-types";
import styles from "./styles";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { normalGray } from "../../utils/colors";
import { faArrowToBottom, faQrcode } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "react-i18next";
import { InputText } from "../Inputs/InputText";
import { ListRow } from "../List/ListRow";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import InputRadio from "../Inputs/InputRadio";

interface OfficialMediasListProps {
  data: Media;
  finishEditing: () => void;
  editOfficialMedia: (officialMediaType: boolean) => void;
}

const OfficialMediaItemEdit: React.FC<OfficialMediasListProps> = ({
  data,
  finishEditing,
  editOfficialMedia,
}) => {
  const [officialMedia, setMedia] = useState<Media>(data);

  const { t } = useTranslation();

  const onChange = <P extends keyof Media>(prop: P, value: Media[P]) => {
    setMedia({ ...officialMedia, [prop]: value });
  };

  return (
    <ListRow template="1fr 1fr 0.5fr 0.5fr 1fr" hover={true} key={data.id}>
      <div className={styles.normalWrap}>{data.id}</div>

      <div className={styles.spaceBetween}>
        <InputText
          onChange={(value) => onChange("fileName", value)}
          value={officialMedia.fileName}
        />
      </div>
      <FlexContainer justifyContent="flex-start" alignItems="center">
        <div style={{ marginRight: "1em" }}>
          <InputRadio
            name="public"
            value="regular"
            checked={officialMedia.public === true}
            text={t("component.officialMediaList.public")}
            onChange={() => onChange("public", true)}
          />
        </div>

        <InputRadio
          name="public"
          value="regular"
          checked={officialMedia.public === false}
          text={t("component.officialMediaList.private")}
          onChange={() => onChange("public", false)}
        />
        {/* <InputSelect
          onChange={(value) => onChange("public", value)}
          containerClassname={styles.inputSelect}
          options={officialMediaType}
        /> */}
      </FlexContainer>

      <Link
        className={styles.iconContainerQR}
        to={officialMedia.public_url}
        target="_blank"
        download
      >
        <FontAwesomeIcon
          style={{ width: 23 }}
          icon={faQrcode as IconProp}
          className={styles.qrIcon}
          color={normalGray}
        />
        <FontAwesomeIcon
          style={{ width: 23 }}
          icon={faArrowToBottom as IconProp}
          className={styles.qrIcon}
          color={normalGray}
        />
      </Link>
      <div className={styles.iconContainer}>
        <img
          src={acceptIcon}
          alt="accept"
          className={styles.iconShare}
          onClick={() => {
            editOfficialMedia(officialMedia.public);
          }}
        />
        <img
          src={quitIcon}
          alt="quit"
          className={styles.icon}
          onClick={() => {
            finishEditing();
            setMedia(data);
          }}
        />
      </div>
    </ListRow>
  );
};

export default OfficialMediaItemEdit;
