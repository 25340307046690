import React from "react";
import styles from "./styles";
import { FOPSet, Venue } from "@innomius/ravent-typescript-types";
import { FlexContainer } from "../FlexContainer/FlexContainer";

// import userIcon from "../../assets/user.svg";
import capacityIcon from "../../assets/sidebar/vendor.svg";
import { ReactComponent as Orders } from "../../assets/sidebar/orders.svg";
import { ReactComponent as Events } from "../../assets/sidebar/cal.svg";
import { ReactComponent as UserIcon } from "../../assets/sidebar/user.svg";
import { ReactComponent as Locations } from "../../assets/sidebar/location.svg";
import { ReactComponent as Fulfillment } from "../../assets/sidebar/states.svg";

import { useTranslation } from "react-i18next";
import {
  languagesOptions,
  numberFormatingOptions,
} from "../../constants/settings";
import Text from "../Text";
import { VenueStats } from "../../utils/types";
import { numberFormatNoDecimals } from "../../utils/format";
import { Card } from "../Card/Card";
import { useHistory } from "react-router-dom";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";
import { SectionProperty } from "../Section/SectionProperty";
import Editor from "@monaco-editor/react";

interface Props {
  data: Venue;
  fopSets: FOPSet[];
  stats: VenueStats;
}

const VenueDetail: React.FC<Props> = ({ data, fopSets, stats }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const stringBody = JSON.stringify(data.data);

  return (
    <div>
      <GridContainer
        padding="0 0 1em 0"
        gap="1em"
        columns="1fr 1fr 1fr 1fr 1fr"
      >
        <Card
          containerClassname={styles.card}
          onClick={() => history.push(`/orders?venueId=${data.id}`)}
        >
          <Text
            text={t("component.venueDetail.orders")}
            fontWeight={700}
            color="#000"
            fontSize="1em"
            padding="0 0 0.5em 0"
          />
          <FlexContainer justifyContent="flex-start">
            <Orders style={{ width: "1.5em" }} />

            <Text
              text={numberFormatNoDecimals(stats.counts.orders)}
              fontWeight={700}
              padding="0 0 0 0.5em"
              color="#000"
            />
          </FlexContainer>
        </Card>
        <Card
          containerClassname={styles.card}
          onClick={() => history.push(`/events?venueId=${data.id}`)}
        >
          <Text
            text={t("component.venueDetail.events")}
            fontWeight={700}
            color="#000"
            fontSize="1em"
            padding="0 0 0.5em 0"
          />
          <FlexContainer justifyContent="flex-start">
            <Events style={{ width: "1.5em" }} />

            <Text
              text={numberFormatNoDecimals(stats.counts.fulfillmentEvents)}
              fontWeight={700}
              padding="0 0 0 0.5em"
              color="#000"
            />
          </FlexContainer>
        </Card>
        <Card
          containerClassname={styles.card}
          onClick={() => history.push(`/venueLocations?venueId=${data.id}`)}
        >
          <Text
            text={t("component.venueDetail.locations")}
            fontWeight={700}
            color="#000"
            fontSize="1em"
            padding="0 0 0.5em 0"
          />
          <FlexContainer justifyContent="flex-start">
            <Locations style={{ width: "1.5em" }} />

            <Text
              text={numberFormatNoDecimals(stats.counts.locations)}
              fontWeight={700}
              padding="0 0 0 0.5em"
              color="#000"
            />
          </FlexContainer>
        </Card>
        <Card
          containerClassname={styles.card}
          onClick={() => history.push(`/fulfillments?venueId=${data.id}`)}
        >
          <Text
            text={t("component.venueDetail.fulfillments")}
            fontWeight={700}
            color="#000"
            fontSize="1em"
            padding="0 0 0.5em 0"
          />
          <FlexContainer justifyContent="flex-start">
            <Fulfillment style={{ width: "1.5em" }} />

            <Text
              text={numberFormatNoDecimals(stats.counts.fulfillmentDefinitions)}
              fontWeight={700}
              padding="0 0 0 0.5em"
              color="#000"
            />
          </FlexContainer>
        </Card>
        <Card
          containerClassname={styles.card}
          onClick={() => history.push(`/users?venueId=${data.id}`)}
        >
          <Text
            text={t("component.venueDetail.users")}
            fontWeight={700}
            color="#000"
            fontSize="1em"
            padding="0 0 0.5em 0"
          />
          <FlexContainer justifyContent="flex-start">
            <UserIcon style={{ width: "1.5em" }} />

            <Text
              text={numberFormatNoDecimals(stats.counts.users)}
              fontWeight={700}
              padding="0 0 0 0.5em"
              color="#000"
            />
          </FlexContainer>
        </Card>
      </GridContainer>
      <Card containerClassname={styles.wrapperCard}>
        <div>
          <ContainerTitle
            padding="0 0 1em 0"
            size="small"
            label={t("component.venueDetail.venueInfo")}
          />
          <GridContainer padding="0 0 1em 0" gap="1em" columns="1fr 1fr 0.5fr">
            <div>
              <Text
                text={t("component.venueDetail.maxCapacity")}
                fontWeight={700}
                color="#000"
                fontSize="1em"
                padding="0 0 0.5em 0"
              />
              <FlexContainer justifyContent="flex-start">
                <img src={capacityIcon} alt="" style={{ width: "1.5em" }} />

                <Text
                  text={data.venueCapacity?.toString() || ""}
                  fontWeight={700}
                  padding="0 0 0 0.5em"
                  color="#000"
                />
              </FlexContainer>
            </div>

            <SectionProperty
              label={t("component.venueDetail.description")}
              value={data.description}
            />
            {/* <OrderTitle
              label={t("component.venueDetail.currentVisitors")}
              image={userIcon}
              value={data.visitors.toString()}
            /> */}
          </GridContainer>

          <ContainerTitle
            padding="1em 0 1em 0"
            size="small"
            label={t("component.venueDetail.config")}
          />
          <GridContainer gap="1em" columns="1fr 1fr 0.5fr">
            <SectionProperty
              label={t("component.venueDetail.fopSet")}
              value={
                fopSets.find((item) => item.id === data.fopSetId)?.name ||
                "not-assigned"
              }
            />
            <SectionProperty
              label={t("component.venueDetail.invoice")}
              value={data.invoiceIssuance || "-"}
            />
            <div />

            <SectionProperty
              padding="1em 0 1em 0"
              label={t("component.venueDetail.timezone")}
              value={data.timezone || "-"}
            />

            <SectionProperty
              padding="1em 0 1em 0"
              label={t("component.venueDetail.dateFormatting")}
              value={data.dateFormatting || "-"}
            />
            <div />

            <SectionProperty
              padding="1em 0 1em 0"
              label={t("component.venueDetail.numberFormatting")}
              value={
                numberFormatingOptions.find(
                  (item) => item.value === data.numberFormatting
                )?.label || "-"
              }
            />
            <SectionProperty
              padding="1em 0 1em 0"
              label={t("component.venueDetail.defaultLanguage")}
              value={
                languagesOptions.find(
                  (item) => item.value === data.defaultLanguage
                )?.label || "-"
              }
            />
          </GridContainer>
        </div>

        <ContainerTitle
          size="small"
          padding="0 0 1em 0"
          label={t("component.entityForm.data")}
        />
        <Editor
          className={styles.editorBox}
          height="100%"
          // theme="vs-dark"
          defaultLanguage="json"
          defaultValue={stringBody}
          options={{
            selectOnLineNumbers: true,
            // minimap: { enabled: false },
            scrollbar: {
              vertical: "hidden",
            },
            readOnly: true,
            overviewRulerLanes: 0,
            hideCursorInOverviewRuler: true,
            overviewRulerBorder: false,
            foldingHighlight: false,
          }}
        />
        {/* {data.image ? (
          <div className={styles.imageWrapper}>
            <img src={data.image} alt="" className={styles.imageContainer} />
          </div>
        ) : null} */}
      </Card>
    </div>
  );
};

export default VenueDetail;
