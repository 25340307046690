import React from "react";
import RequestItem from "./RequestItem";
import { PaymentListSkeleton } from "../ListSkeletons";
import { Payment } from "@innomius/ravent-typescript-types";
import { useTranslation } from "react-i18next";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { List } from "../List";

interface RequestsListProps {
  error: string;
  loading: boolean;
  data: Payment[];
  onIssueInvoice: (requestId: string) => void;
  contactEmail: string;
  contactPhone: string;
  orderDescription: string;
  orderShortId: string;
  onDelete: (requestId: string) => void;
}

const PaymentRequestList: React.FC<RequestsListProps> = ({
  error,
  data,
  loading,
  onIssueInvoice,
  contactEmail,
  contactPhone,
  orderDescription,
  orderShortId,
  onDelete,
}) => {
  const { t } = useTranslation();

  const header = (
    <div>
      <ListHeader template="0.8fr 1.2fr 1.5fr 1.5fr 0.7fr 1fr">
        <ListHeaderLabel
          justifyContent="flex-start"
          value={t("component.requestList.requestId")}
        />
        <ListHeaderLabel
          value={t("component.requestList.paymentType")}
          textAlign="center"
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.requestList.issued")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.requestList.expires")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.requestList.amount")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.requestList.actions")}
          justifyContent="center"
        />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <List
        loading={loading}
        loadingElement={() => <PaymentListSkeleton />}
        error={error}
        data={data}
        keyExtractor={(item) => item.id}
        renderHeader={() => header}
        renderItem={(item) => (
          <RequestItem
            shortId={orderShortId}
            description={orderDescription}
            contactEmail={contactEmail}
            contactPhone={contactPhone}
            onDelete={() => onDelete(item.id)}
            onIssueInvoice={() => onIssueInvoice(item.id)}
            data={item}
            key={item.id}
            cancelled={item.cancelled}
          />
        )}
      />
    </div>
  );
};

export default PaymentRequestList;
