import React, { useCallback, useEffect, useState } from "react";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import {
  Action,
  EventComment,
  FulfillmentDefinition,
  FulfillmentEvent,
  Placement,
  Schedule,
} from "@innomius/ravent-typescript-types";
import { useDispatch, useSelector } from "react-redux";
import { teamList } from "../../store/teams";
import message from "antd/lib/message";
import styles from "./styles.module.css";
import APIError from "../../utils/APIError";
import ErrorBox from "../../components/ErrorBox";
import Text from "../../components/Text";
import {
  deleteEvent,
  getEvent,
  listRelatedEvents,
  RelatedSearch,
  setEventFulfillmentDefState,
  setEventState,
  updateElementEvent,
  updateEvent,
} from "../../services/events";
import { assetList } from "../../store/assets";
import secureLocalStorage from "react-secure-storage";
import { venueList } from "../../store/venues";
import { Route, useHistory, useParams } from "react-router-dom";
import { Popover, Result, Spin, Tabs, Tooltip } from "antd";
import { createMiniLocation } from "../../services/venues";
import { RootState } from "../../store";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { BackLink } from "../../components/BackLink";
import { fulfillmentList } from "../../services/fulfilments";
import EventDetailComponent from "../../components/OrderEvent/EventDetailComponent";
import { passList } from "../../services/passes";
import { Pass } from "../../utils/types";
import { useTranslation } from "react-i18next";
import TabNavBar from "../../components/TabNavBar";
import OrderPasses from "../../components/OrderPasses";
import { ContainerTitle } from "../../components/ContainerTitle";
import sendIcon from "../../assets/sendflows4.svg";
import ScheduleFlow from "../../components/SendModal/ScheduleFlow";
import {
  notifyWebHook,
  scheduleWebHook,
  updateOrder,
  updateOrderLine,
} from "../../services/orders";
import { listActions } from "../../services/actions";
import { GridContainer } from "../../components/GridContainer/GridContainer";
import { Button } from "../../components/Button";
import { createEventComment, getEventComments } from "../../services/comments";
import { StatusBadgePoint } from "../../components/StatusBadgePoint/StatusBagePoint";
import { StatusCatalog } from "../../components/StatusBadge";

type RouteParams = Record<string, string | undefined>;

const EventDetail: React.FC = () => {
  const { id } = useParams<RouteParams>();
  const navigate = useHistory();
  const dispatch = useDispatch();
  const [errorFlows, setErrorFlows] = useState<string>("");

  const [event, setEvent] = useState<FulfillmentEvent>();
  const [passes, setPasses] = useState<Pass[]>([]);
  const [messages, setMessages] = useState<EventComment[]>([]);

  const [open, setOpen] = useState<string>("");
  const [sched, setSched] = useState(false);
  const [fulfillmentDefinitions, setDefinitions] = useState<
    FulfillmentDefinition[]
  >([]);
  const [relatedEvents, setRelatedEvents] = useState<RelatedSearch>({
    hits: [],
    count: 0,
    page: 0,
    tagColors: [],
    facets: [],
  });

  const teams = useSelector((state: RootState) => state.teams);
  const [loading, setLoading] = useState<string>("");
  const [all, setAll] = useState<boolean>(false);

  const [error, setError] = useState("");
  const assets = useSelector((state: RootState) => state.assets);
  const [actions, setActions] = useState<Action[]>([]);

  const venues = useSelector((state: RootState) => state.venues);
  const venueArray = [
    ...venues.list.data.map((item) => ({ label: item.name, value: item.id })),
    { label: "all", value: "" },
  ];

  const [activeKey, setActiveKey] = useState<string>(event?.id || "");

  const loadActions = useCallback(async () => {
    try {
      const res = await listActions({
        page: 0,
        records_per_page: 100,
        manualTrigger: true,
        venue_id: event?.venueId,
      });
      setActions(res.data);
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  }, [event]);

  const loadDefs = useCallback(async () => {
    try {
      const res = await fulfillmentList({
        page: 0,
        records_per_page: 100,
      });
      setDefinitions(res.data);
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  }, []);
  useEffect(() => {
    loadDefs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation();

  const loadVenues = useCallback(async () => {
    dispatch(venueList({ records_per_page: 20 }));
  }, [dispatch]);

  useEffect(() => {
    loadVenues();
  }, [loadVenues]);

  const loadAssets = useCallback(async () => {
    dispatch(assetList({ records_per_page: 500 }));
  }, [dispatch]);

  useEffect(() => {
    loadAssets();
  }, [loadAssets]);

  const load = useCallback(async () => {
    dispatch(teamList({ records_per_page: 100 }));
    try {
      setLoading("detail");
      if (id) {
        const event = await getEvent(id);
        setEvent(event);
        setActiveKey(event.id);
      }

      setLoading("");
    } catch (err) {
      if (err instanceof APIError) {
        setError(err.message);
      }
      setLoading("");
    }
  }, [id, dispatch]);

  useEffect(() => {
    load();
  }, [id, load]);

  const loadRelated = useCallback(async () => {
    try {
      setLoading("related");
      if (id) {
        const event = await listRelatedEvents({
          id,
          page: 0,
          records_per_page: 50,
        });
        setRelatedEvents(event);
      }

      setLoading("");
    } catch (err) {
      if (err instanceof APIError) {
        setError(err.message);
      }
      setLoading("");
    }
  }, [id]);

  useEffect(() => {
    loadRelated();
  }, [id, loadRelated]);

  const loadPasses = useCallback(async () => {
    try {
      if (event?.id) {
        const res = await passList(event.order);
        setPasses(res);
      }
    } catch (err) {
      if (err instanceof APIError) {
        console.error(`${err.message}: ${err.messages}`);
      }
    }
  }, [event?.id, event?.order]);

  useEffect(() => {
    if (event) {
      loadPasses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  const loadMessages = useCallback(async () => {
    try {
      if (event?.id) {
        const res = await getEventComments(event.id);
        setMessages(res.hits);
      }
    } catch (err) {
      if (err instanceof APIError) {
        console.error(`${err.message}: ${err.messages}`);
      }
    }
  }, [event?.id]);

  useEffect(() => {
    if (event?.id) {
      loadMessages();
    }
  }, [event?.id, loadMessages]);

  const onUpdateOrder = async (
    id: string,
    record: Record<string, any>,
    msg?: string
  ) => {
    try {
      await updateOrder(id, record);
      message.success(
        msg ? msg : t("container.orderDetail.successOrderUpdate")
      );
      load();
    } catch (err) {
      if (err instanceof APIError) {
        message.error(err.message);
      }
    }
  };

  const onUpdateOrderline = async (id: string, fulfillmentId: string) => {
    try {
      await updateOrderLine(id, {
        fulfillmentDefinitionId: fulfillmentId,
      });
      message.success(t("container.orderDetail.successFulfillment"));
      load();
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`Error ${err.message}: ${err.messages}`);
      }
    }
  };

  if (loading) {
    return <Spin />;
  } else {
    <Result
      status="error"
      title={"Error"}
      subTitle={error}
      extra={<Button label="Regresar" />}
    />;
  }

  if (error) {
    return <ErrorBox error={error} />;
  }

  const flows = actions
    .filter((item) => item.orderOnly)
    .map((item) => ({ name: item.name, url: item.url, actionId: item.id }));

  const onUpdateEvent = async (
    id: string,
    record: Record<string, any>,
    msg?: string,
    route?: () => void
  ) => {
    try {
      await updateElementEvent(id, record);
      message.success(msg ? msg : "Evento actualizado correctamente");
      setTimeout(() => {
        load();
        route?.();
      }, 500);
    } catch (err) {
      if (err instanceof APIError) {
        message.error(err.message);
      }
    }
  };

  const onCreateLocation = async (
    placement: Placement,
    type: string,
    group: string
  ) => {
    try {
      setLoading("create-location");
      await createMiniLocation(
        secureLocalStorage.getItem("venueId") as string,
        placement,
        type,
        group
      );
      setLoading("");
      message.success("Ubicación creada correctamente");
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const passesTab = {
    id: "passRavent",
    label: t("container.orderDetail.passLabel"),
    path: `/events/${id}/passes`,
    onClick: () => (document.title = "RAVENT APP :: Event :: Passes"),
  };

  const detailsTab = {
    id: "orderDetailRavent",
    label: t("container.orderDetail.detailsLabel"),
    path: `/events/${id}`,
    onClick: () => (document.title = "RAVENT APP :: Event :: Details"),
  };

  const onSetState = async (
    _eventId: string,
    state: string,
    w3w: string,
    images: string[]
  ) => {
    try {
      setLoading("state-orderline");
      await setEventFulfillmentDefState(id || "", state, w3w, images);
      message.success(t("container.orderDetail.stateSuccess"));
      setLoading("");
      load();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onSetStateOrder = async (
    _eventId: string,
    state: string,
    w3w: string,
    images: string[]
  ) => {
    try {
      setLoading("state-orderline");
      await setEventFulfillmentDefState(id || "", state, w3w, images);
      message.success(t("container.orderDetail.stateSuccess"));
      setLoading("");
      load();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };
  const onSendToFlow = async (actionId: string) => {
    try {
      setLoading("flows");
      if (event) {
        const res = await notifyWebHook({
          actionId: actionId,
          eventsIds: all
            ? relatedEvents?.hits?.map((item) => item.id)
            : [event.id],
        });

        if (res.successful === false) {
          message.error({
            content: `${res.statusCode} ${res.message}`,
          });
        }
        if (res.successful === true) {
          message.success(
            `${t("container.orderDetail.event")} ${event.shortId} ${t(
              "component.orderDetail.shared"
            )}`
          );
        }

        setLoading("");
        setOpen("");

        setAll(false);

        setErrorFlows("");
      }
    } catch (err) {
      setLoading("");
      setAll(false);

      if (err instanceof APIError) {
        setErrorFlows(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onScheduleFlow = async (actionId: string, schedule: Schedule) => {
    try {
      if (event) {
        setLoading("flows");
        await scheduleWebHook({
          actionId: actionId,
          eventsIds: all
            ? [event, ...relatedEvents.hits].map((item) => item.id)
            : [event.id],
          date: schedule.date,
          timezone: schedule.timezone,
          prettyName: schedule.prettyName,
        });
        setLoading("");
        setOpen("");
        setAll(false);
        if (event)
          message.success(
            `${t("container.orderDetail.event")} ${event.shortId} ${t(
              "container.orderDetail.scheduled"
            )}`
          );
        setErrorFlows("");
      }
    } catch (err) {
      setLoading("");
      setAll(false);

      if (err instanceof APIError) {
        setErrorFlows(`${err.message}: ${err.messages}`);
      }
    }
  };

  const relatedActiveEvent = [event, ...relatedEvents.hits].find(
    (item) => item?.id === activeKey
  ) as FulfillmentEvent;

  return (
    <LayouWithSideBar>
      <ScheduleFlow
        setSchedule={setSched}
        schedule={sched}
        flows={flows || []}
        id={event?.id || ""}
        onSend={async (actionId, s) => {
          if (sched) {
            await onScheduleFlow(actionId, s);
          } else onSendToFlow(actionId);
        }}
        error={errorFlows}
        // loading={loading === "schedule" || loading === "flows"}
        onCancel={() => {
          setOpen("");
        }}
        title={
          all
            ? t("container.orderDetail.sendAll")
            : t("container.orderDetail.sendEventToFlow")
        }
        open={open === "flows"}
        contactEmail={event?.customer?.email || ""}
      />
      <div className={styles.container}>
        <FlexContainer padding="0 0 1.2em 0" justifyContent="space-between">
          <BackLink
            label="Regresar a Eventos"
            onClick={() => navigate.push("/events")}
          />
          <Tooltip title={t("container.orderDetail.sendAll")}>
            <Button
              theme="white"
              onClick={() => {
                setOpen("flows");
                setAll(true);
              }}
              icon={sendIcon}
              label={t("container.orderDetail.sendAll")}
            />
          </Tooltip>
        </FlexContainer>

        <Route path={`/events/:id`} exact>
          <GridContainer columns="0.1fr 1fr">
            {relatedEvents.count > 0 && (
              <Tabs
                tabPosition="left"
                defaultActiveKey={activeKey}
                size={"small"}
                onChange={(activeKey) => {
                  setActiveKey(activeKey);
                  navigate.push(`/events/${activeKey}`);
                }}
                items={[
                  { label: `${event?.shortId}`, key: event?.id },
                  ...relatedEvents.hits
                    .filter((item) => item.id !== event?.id)
                    .map((item) => {
                      return {
                        label: `${item.shortId}`,
                        key: item.id,
                      };
                    }),
                ]}
              />
            )}
            {(event || relatedActiveEvent) && (
              <div style={{ paddingBottom: "3em" }}>
                <FlexContainer justifyContent="space-between">
                  <FlexContainer justifyContent="flex-start">
                    <ContainerTitle
                      padding="0 0 0.2em 0em"
                      size="medium"
                      label={event?.name || ""}
                    />
                  </FlexContainer>
                  <Popover trigger={"hover"} content={event?.state || ""}>
                    <StatusBadgePoint
                      status={(event?.state as StatusCatalog) || ""}
                    />
                  </Popover>
                </FlexContainer>
                <FlexContainer justifyContent="space-between">
                  <Text
                    color="#000000"
                    fontWeight={500}
                    onClick={() => navigate.push(`/orders/${event?.order}`)}
                    fontSize="1.2em"
                    text={`Event ID: ${relatedActiveEvent.shortId} ${
                      relatedActiveEvent?.externalId && "•"
                    } ${
                      relatedActiveEvent.externalId &&
                      relatedActiveEvent.externalId
                    }`}
                  />
                  <FlexContainer
                    padding="0.5em 0 0.5em 0"
                    justifyContent="flex-start"
                  >
                    <Text
                      color="#000000"
                      fontWeight={500}
                      onClick={() =>
                        navigate.push(`/orders/${relatedActiveEvent.order}`)
                      }
                      fontSize="1.2em"
                      text={`Order: ${relatedActiveEvent.orderShortId} ${
                        relatedActiveEvent.orderExternalId && "•"
                      }`}
                    />
                    <Text
                      color="#333"
                      padding="0 0 0 0.5em"
                      fontWeight={500}
                      fontSize="1em"
                      onClick={() => navigate.push(`/orders/${event?.order}`)}
                      text={`${event?.orderExternalId}`}
                    />
                  </FlexContainer>
                </FlexContainer>
                <TabNavBar options={[detailsTab, passesTab]}>
                  {event && (
                    <EventDetailComponent
                      messages={messages}
                      sendMessage={async (eventId, msg) => {
                        try {
                          await createEventComment(eventId, msg);
                          message.success({
                            content: t("container.orderDetail.successComment"),
                            className: "custom-class",
                            style: {
                              marginTop: "10vh",
                            },
                          });
                          loadMessages();
                        } catch (err) {
                          if (err instanceof APIError) {
                            message.error(err.message);
                          }
                        }
                      }}
                      errorState=""
                      updateFulfillmentName={(
                        id,
                        diveshopId,
                        fulfillmentLevel
                      ) => {
                        fulfillmentLevel === "order"
                          ? onUpdateOrder(id, { fulfillmentDefinitionId: id })
                          : onUpdateOrderline(id, diveshopId);
                      }}
                      setState={(id, fulfillmentLevel, status, w3w, images) => {
                        fulfillmentLevel === "order"
                          ? onSetStateOrder(id, status, w3w, images)
                          : onSetState(id, status, w3w, images);
                      }}
                      fulfillmentNames={fulfillmentDefinitions.map((item) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                      sendToFlows={() => {
                        setOpen("flows");
                      }}
                      setNew={async (eventId, state) => {
                        try {
                          await setEventState(eventId, state);
                          message.success(
                            `Estado cambio correctamente a ${state}`
                          );
                          load();
                        } catch (err) {
                          if (err instanceof APIError) {
                            message.error(err.message);
                          }
                        }
                      }}
                      loadEvent={() => {}}
                      venues={venueArray}
                      index={0}
                      saveLoc={(p, t, g) => {
                        onCreateLocation(p, t, g);
                      }}
                      updateFulfiller={(eventId, fulfillerId) =>
                        onUpdateEvent(eventId, { fulfillerId: fulfillerId })
                      }
                      onSaveLoad={(eventId, load) =>
                        onUpdateEvent(eventId, { loads: load })
                      }
                      fulfillmentLevel={event?.fulfillmentLevel || ""}
                      description={`Order ${event?.shortId}`}
                      onChangeEventState={async (eventId) => {
                        try {
                          await setEventState(eventId, "completed");
                          message.success(
                            "Estado del evento actualizado correctamente"
                          );
                          load();
                        } catch (err) {
                          if (err instanceof APIError) {
                            message.error(err.message);
                          }
                        }
                      }}
                      assets={assets.list.data}
                      path={`/events/${id}`}
                      teams={teams.list.data.map((item) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                      updateTeam={(eventId, teamId) =>
                        onUpdateEvent(eventId, { fulfillmentTeamId: teamId })
                      }
                      updateAsset={async (e: FulfillmentEvent, id: string) => {
                        onUpdateEvent(
                          e.id,
                          { assetId: id },
                          "Asset actualizado correctamente"
                        );
                      }}
                      onDeletePlacement={async (
                        e: FulfillmentEvent,
                        id: string
                      ) => {
                        if (id) {
                          const newLocationArray = e.placements.filter(
                            (item) => item.id !== id
                          );
                          e.placements = newLocationArray;
                          try {
                            await updateEvent(e);
                            message.success(
                              "Ubicación eliminada correctamente"
                            );
                            load();
                          } catch (err) {
                            if (err instanceof APIError) {
                              message.error(err.message);
                            }
                          }
                        }
                      }}
                      onEditPlacement={async (event, placement) => {
                        if (placement.id) {
                          const index = event.placements.findIndex((object) => {
                            return object.id === placement.id;
                          });
                          if (index !== -1) {
                            event.placements[index] = placement;
                            try {
                              await updateEvent(event);
                              message.success(
                                "Ubiación actualizada correctamente"
                              );
                              load();
                            } catch (err) {
                              if (err instanceof APIError) {
                                message.error(err.messages);
                              }
                            }
                          }
                          return;
                        } else
                          try {
                            event.placements = [...event.placements, placement];
                            await updateEvent(event);
                            message.success("Ubicación creada correctamente");
                            load();
                          } catch (err) {
                            if (err instanceof APIError) {
                              message.error(err.messages);
                            }
                          }
                      }}
                      onEditEvent={async (event) => {
                        try {
                          await updateEvent(event);
                          message.success("Evento actualizado correctamente");
                          load();
                        } catch (err) {
                          if (err instanceof APIError) {
                            message.error(err.message);
                          }
                        }
                      }}
                      data={event}
                      onDelete={async (id) => {
                        try {
                          await deleteEvent(id);
                          message.success("Evento eliminado correctamente");
                          load();
                        } catch (err) {
                          if (err instanceof APIError) {
                            message.error(err.message);
                          }
                        }
                      }}
                    />
                  )}
                </TabNavBar>
              </div>
            )}
          </GridContainer>
        </Route>
        <Route path={`/events/:id/passes`} exact>
          <GridContainer columns="0.1fr 1fr">
            {relatedEvents.count > 0 && (
              <Tabs
                tabPosition="left"
                defaultActiveKey={activeKey}
                size={"small"}
                onChange={(activeKey) => {
                  setActiveKey(activeKey);
                  navigate.push(`/events/${activeKey}`);
                }}
                items={[
                  { label: `${event?.shortId}`, key: event?.id },
                  ...relatedEvents.hits
                    .filter((item) => item.id !== event?.id)
                    .map((item) => {
                      return {
                        label: `${item.shortId}`,
                        key: item.id,
                      };
                    }),
                ]}
              />
            )}
            {(event || relatedActiveEvent) && (
              <div style={{ paddingBottom: "3em" }}>
                <FlexContainer justifyContent="space-between">
                  <FlexContainer justifyContent="flex-start">
                    <ContainerTitle
                      padding="0 0 0.2em 0em"
                      size="medium"
                      label={event?.name || ""}
                    />
                  </FlexContainer>
                  <Popover trigger={"hover"} content={event?.state || ""}>
                    <StatusBadgePoint
                      status={(event?.state as StatusCatalog) || ""}
                    />
                  </Popover>
                </FlexContainer>
                <FlexContainer
                  padding="0.5em 0 0 0"
                  justifyContent="space-between"
                >
                  <Text
                    color="#000000"
                    fontWeight={500}
                    onClick={() => navigate.push(`/orders/${event?.order}`)}
                    fontSize="1.2em"
                    text={`Event ID: ${relatedActiveEvent.shortId} ${
                      relatedActiveEvent?.externalId && "•"
                    } ${
                      relatedActiveEvent.externalId &&
                      relatedActiveEvent.externalId
                    }`}
                  />
                  <FlexContainer
                    padding="0 0 0.5em 0"
                    justifyContent="flex-start"
                  >
                    <Text
                      color="#000000"
                      fontWeight={500}
                      onClick={() =>
                        navigate.push(`/orders/${relatedActiveEvent.order}`)
                      }
                      fontSize="1.2em"
                      text={`Order: ${relatedActiveEvent.orderShortId} ${
                        relatedActiveEvent.orderExternalId && "•"
                      }`}
                    />
                    <Text
                      color="#333"
                      padding="0 0 0 0.5em"
                      fontWeight={500}
                      fontSize="1em"
                      onClick={() => navigate.push(`/orders/${event?.order}`)}
                      text={`${event?.orderExternalId}`}
                    />
                  </FlexContainer>
                </FlexContainer>
                <TabNavBar options={[detailsTab, passesTab]}>
                  <OrderPasses passes={passes} onSharePasses={() => {}} />
                </TabNavBar>
              </div>
            )}
          </GridContainer>
        </Route>
      </div>
    </LayouWithSideBar>
  );
};

export default EventDetail;
