import React from "react";
import InvoiceItem from "./InvoiceItem";
import { PaymentListSkeleton } from "../ListSkeletons";
import { OrderInvoice } from "@innomius/ravent-typescript-types";
import { useTranslation } from "react-i18next";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { ListHeader } from "../List/ListHeader";
import { List } from "../List";

interface PaymentListProps {
  error: string;
  loading: boolean;
  data: OrderInvoice[];
  payments: { label: string; value: string }[];
  requestIds: { label: string; value: string }[];
  orderId: string;
  contactEmail: string;
  load: () => void;
  refundActionId: string;
  cancelInvoiceActionId: string;
}

const OrderInvoiceList: React.FC<PaymentListProps> = ({
  error,
  data,
  loading,
  payments,
  requestIds,
  orderId,
  contactEmail,
  load,
  refundActionId,
  cancelInvoiceActionId,
}) => {
  const { t } = useTranslation();

  const header = (
    <div>
      <ListHeader template="0.8fr 1fr 1fr 0.8fr 1fr 0.8fr 0.8fr 0.2fr">
        <ListHeaderLabel
          value={t("component.orderInvoiceList.description")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderInvoiceList.requestId")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderInvoiceList.paymentId")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderInvoiceList.issuer")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderInvoiceList.issuedDate")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderInvoiceList.amount")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderInvoiceList.status")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel value={""} justifyContent="flex-start" />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <List
        loading={loading}
        loadingElement={() => <PaymentListSkeleton />}
        error={error}
        data={data}
        keyExtractor={(item) => item.id}
        renderHeader={() => header}
        renderItem={(item) => (
          <InvoiceItem
            cancelInvoiceActionId={cancelInvoiceActionId}
            refundActionId={refundActionId}
            orderId={orderId}
            contactEmail={contactEmail}
            key={item.id}
            data={item}
            payments={payments}
            requestIds={requestIds}
            load={load}
          />
        )}
      />
    </div>
  );
};

export default OrderInvoiceList;
