import React from "react";
import { PaymentListSkeleton } from "../ListSkeletons";
import { useTranslation } from "react-i18next";
import OrderlineStateItem from "./OrderlineStateItem";
import { Action, FulfillmentStateDef } from "@innomius/ravent-typescript-types";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { List } from "../List";

interface StateProps {
  error: string;
  loading: boolean;
  data: FulfillmentStateDef[];
  onAddState: (data: FulfillmentStateDef) => void;
  loadingActions: string;
  onDelete: (id: number) => void;
  update: (item: FulfillmentStateDef, index: number) => void;
  actions: Action[];
}

const OrderlineStateList: React.FC<StateProps> = ({
  error,
  data,
  loading,
  loadingActions,
  onDelete,
  onAddState,
  update,
  actions,
}) => {
  const { t } = useTranslation();

  const header = (
    <div>
      <ListHeader template="1.5fr 3fr 1fr">
        <ListHeaderLabel
          justifyContent="flex-start"
          value={t("component.orderlineStateList.stateName")}
        />
        <ListHeaderLabel
          value={t("component.orderlineStateList.actionName")}
          textAlign="center"
          justifyContent="flex-start"
        />
        <ListHeaderLabel value={""} justifyContent="flex-start" />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <List
        loading={loading}
        loadingElement={() => <PaymentListSkeleton />}
        error={error}
        data={data}
        keyExtractor={(item) => item.name}
        renderHeader={() => header}
        renderItem={(item) => (
          <OrderlineStateItem
            data={item}
            actions={actions}
            key={item.name}
            onAdd={(item) => onAddState(item)}
            loading={loadingActions}
            index={data.indexOf(item)}
            onEdit={(item, index) => update(item, index)}
            onDelete={() => onDelete(data.indexOf(item))}
          />
        )}
      />
    </div>
  );
};

export default OrderlineStateList;
