import React from "react";

import UploadThumbnail from "../Upload";
import styles from "./styles";
import { OfferSetParams } from "../../services/offersets";
import { currencies } from "../../constants/currencies";
import { useTranslation } from "react-i18next";
import { Card } from "../Card/Card";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputTextarea } from "../Inputs/InputTextArea";
import { InputSelect } from "../Inputs/InputSelect";
import { InputText } from "../Inputs/InputText";

interface Props  {
  data: OfferSetParams;
  onChange: <P extends keyof OfferSetParams>(
    prop: P,
    value: OfferSetParams[P]
  ) => void;
  edit?: boolean;
}

const OfferSetForm: React.FC<Props> = ({ data, onChange, edit }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Card containerClassname={styles.container}>
        <div className={styles.containerDetails}>
          <ContainerTitle
            size="xsmall"
            label={t("component.offerSetForm.setInfo")}
            padding="0 0 1em 0"
          />
          <GridContainer gap="2em" columns="3fr 1fr">
            <InputTextarea
              value={data.description}
              label={t("component.offerSetForm.description")}
              onChange={(value) => onChange("description", value)}
            />

            <UploadThumbnail upload={() => {}} image={data.image} edit={edit} />
          </GridContainer>
          {!edit ? (
            <>
              <ContainerTitle
                size="xsmall"
                label={t("component.offerSetForm.startingPrice")}
                padding="0 0 1em 0"
              />
              <GridContainer gap="1em" columns="1fr 1fr 4fr">
                <InputSelect
                  value={data.startingPrice.currency}
                  options={currencies}
                  label={t("component.offerSetForm.currency")}
                />
                <InputText
                  value={data.startingPrice.value}
                  label={t("component.offerSetForm.amount")}
                />
              </GridContainer>
            </>
          ) : null}
        </div>
      </Card>
    </div>
  );
};

export default (OfferSetForm);
