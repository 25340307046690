import React, { useState } from "react";
import TypeButton from "./TypeButton";
import { useTranslation } from "react-i18next";
import { FulfillmentDefinition } from "@innomius/ravent-typescript-types";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";

interface Props {
  data: FulfillmentDefinition;
  onChange: <P extends keyof FulfillmentDefinition>(
    prop: P,
    value: FulfillmentDefinition[P]
  ) => void;
}

const DetailForm: React.FC<Props> = ({ data, onChange }) => {
  const [type, setType] = useState<string>(data.type);

  const fulfillmentTypes = [
    "access",
    "digital",
    "pick-up",
    "service",
    "delivery",
    "locker",
    "transportation",
    "hospitality",
  ];

  const { t } = useTranslation();

  return (
    <div>
      <div>
        <ContainerTitle
          padding="0em 0 1em 0"
          size="small"
          label={t("component.fulfillmentForm.type")}
        />

        <GridContainer
          alignItems="center"
          justifyContent="center"
          gap="1em"
          columns="1fr 1fr 1fr"
        >
          {fulfillmentTypes.map((item) => (
            <TypeButton
              key={item}
              selectedType={type}
              type={item}
              onClick={(value) => {
                setType(value);
                onChange(
                  "type",
                  value as
                    | "locker"
                    | "pickup"
                    | "delivery"
                    | "access"
                    | "digital"
                    | "service"
                );
              }}
            />
          ))}
        </GridContainer>
      </div>
    </div>
  );
};

export default DetailForm;
