import * as React from "react";
import { black } from "../../utils/colors";

interface Props {
  color?: string;
  activeColor?: string;
  sort?: "asc" | "desc" | null;
}

const SortIcon: React.FC<Props> = ({
  sort,
  color = "rgb(191, 190, 191)",
  activeColor = black,
}) => {
  return (
    <svg
      id="Group_3"
      data-name="Group 3"
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="10"
      viewBox="0 0 6 10"
    >
      <path
        id="Path"
        d="M0,4H6L3,0Z"
        fill={sort === "asc" ? activeColor : color}
      />
      <path
        id="Path_Copy"
        data-name="Path Copy"
        d="M0,4H6L3,0Z"
        transform="translate(6 10) rotate(180)"
        fill={sort === "desc" ? activeColor : color}
      />
    </svg>
  );
};

export default SortIcon;
