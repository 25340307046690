import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { CreateOrderline } from "../../utils/types";
import SurchargesForm from "./SurchargesForm";
import TaxForm from "./TaxForm";
import Text from "../Text";
import DiscountsForm from "./DiscountsForm";
import styles from "./styles";

import lock from "../../assets/lock.svg";
import { InputNumber, Radio } from "antd";
import { Modal } from "../Modal/Modal";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ContainerTitle } from "../ContainerTitle";
import { InputSelect } from "../Inputs/InputSelect";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
import { InputError } from "../Inputs/InputError/InputError";

interface Props {
  onSave: (o: CreateOrderline) => void;
  oline: CreateOrderline;
  open: string;
  onClose: () => void;
  loading?: boolean;
}

const AddOrderline: React.FC<Props> = ({
  onSave,
  oline,
  open,
  onClose,
  loading,
}) => {
  const { t } = useTranslation();
  const [orderlines, setOrderlines] = useState(oline);
  const [content, setContent] = useState("taxes");

  const onChange = <P extends keyof CreateOrderline>(
    prop: P,
    value: CreateOrderline[P]
  ) => {
    setOrderlines({ ...orderlines, [prop]: value });
  };

  return (
    <Modal
      width={"60%"}
      open={open === oline.id}
      onClose={onClose}
      buttons={
        <ButtonsContainer justifyContent="flex-end">
          <Button
            theme="white"
            label={t("component.addOrderline.cancel")}
            onClick={() => {
              onClose();
              setOrderlines(oline);
            }}
          />
          <Button
            label={loading ? "..." : `${t("component.addOrderline.save")}`}
            onClick={() => onSave(orderlines)}
          />
        </ButtonsContainer>
      }
    >
      <div>
        <FlexContainer justifyContent="space-between">
          <ContainerTitle
            padding="1em 0 1em 0"
            size="medium"
            label={`${t("component.addOrderline.edit")} ${oline.description} `}
          />
        </FlexContainer>

        <FlexContainer justifyContent="space-between">
          <FlexContainer alignItems="flex-start" justifyContent="flex-start">
            <img src={lock} className={styles.lock} alt="" />
            <span className={styles.info}>{oline.details}</span>
          </FlexContainer>
          <ButtonsContainer justifyContent="flex-end">
            <div className={styles.select}>
              <Text text={`x ${orderlines.quantity} `} />
            </div>
            <InputSelect
              containerClassname={styles.select}
              value={orderlines.currency}
              options={[
                { value: "EUR", label: "EUR" },
                { value: "MXN", label: "MXN" },
                { value: "USD", label: "USD" },
              ]}
            />
            <div>
              <InputLabel label="Unit Price" />
              <InputNumber
                value={orderlines.unitPrice}
                onChange={(value) => {
                  if (value) onChange("unitPrice", value);
                }}
              />
              <InputError />
            </div>
          </ButtonsContainer>
        </FlexContainer>

        <FlexContainer justifyContent="space-between">
          <ContainerTitle
            padding="1em 0 1em 0"
            size="medium"
            label={`${t("component.addOrderline.taxesVariant")} `}
          />
          <Radio.Group
            value={content}
            onChange={(value) => setContent(value.target.value)}
            style={{ marginBottom: 16 }}
          >
            <Radio.Button value="taxes">
              {t("component.addOrderline.taxes")}
            </Radio.Button>
            <Radio.Button value="discounts">
              {t("component.addOrderline.discounts")}
            </Radio.Button>
            <Radio.Button value="surcharges">
              {t("component.addOrderline.surcharges")}
            </Radio.Button>
          </Radio.Group>
        </FlexContainer>
        <>
          {content === "discounts" ? (
            <DiscountsForm
              data={orderlines.discounts}
              onChange={(value) => onChange("discounts", value)}
            />
          ) : null}
          {content === "surcharges" ? (
            <SurchargesForm
              data={orderlines.surcharges}
              onChange={(value) => onChange("surcharges", value)}
            />
          ) : null}

          {content === "taxes" ? (
            <TaxForm
              data={orderlines.taxes}
              onChange={(value) => onChange("taxes", value)}
            />
          ) : null}
        </>
      </div>
    </Modal>
  );
};

export default AddOrderline;
