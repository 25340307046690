import React from "react";
import { useTranslation } from "react-i18next";
import OrderStateItem from "./OrderStateItem";
import { VenueWebHook } from "../../utils/types";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { List } from "../List";

interface StateProps {
  data: VenueWebHook[];
  event?: boolean;
}

const OrderStateList: React.FC<StateProps> = ({ data, event }) => {
  const { t } = useTranslation();

  const header = (
    <div>
      <ListHeader template="1fr 3fr 1fr">
        <ListHeaderLabel
          justifyContent="flex-start"
          value={
            event
              ? t("component.orderStateList.eventName")
              : t("component.orderlineStateList.stateName")
          }
        />
        <ListHeaderLabel
          value={t("component.orderlineStateList.actionName")}
          textAlign="center"
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderStateList.automatic")}
          textAlign="center"
          justifyContent="flex-start"
        />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <List
        data={data}
        keyExtractor={(item) => item.name}
        renderHeader={() => header}
        renderItem={(item) => <OrderStateItem data={item} key={item.name} />}
      />
    </div>
  );
};

export default OrderStateList;
