import { stylesheet } from "typestyle";

const styles = stylesheet({
  container: {
    marginTop: "1em",
  },
  wrapperCard: {
    padding: "1em 2em 1em 1em",
  },
  wrapperCardEvent: {
    padding: "0em 0em 0em 0em",
  },
  bold: {
    fontSize: "1em",
    fontWeight: 700,
    fontStyle: "normal",
    letterSpacing: 0,
    margin: "0",
  },
  listContainer: {
    padding: "0 2em 2em 2em",
  },
  iconEmpty: {
    width: "90px",
  },
});

export default styles;
