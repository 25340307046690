import React from "react";
import Header, { Option } from "./Header";
import styles from "./styles.module.css";

interface Props {
  options?: Option[];
  children?: React.ReactNode | React.ReactNode[];
  margin?: string;
}

const TabNavBar: React.FC<Props> = ({ options, children, margin }) => {
  return (
    <div className={styles.wrapper}>
      <Header margin={margin} options={options || []} />
      <div
        className={styles.bodyMenu}
        style={{ padding: "0 0 0 0 !important" }}
      >
        {children}
      </div>
    </div>
  );
};

export default TabNavBar;
