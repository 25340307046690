
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as AlertIcon } from "../../../assets/alertBlue.svg";

import Text from "../../Text";
import styles from "./styles";
import { Modal } from "../../Modal/Modal";
import { ButtonsContainer } from "../../ButtonsContainer";
import { Button } from "../../Button";
import { FlexContainer } from "../../FlexContainer/FlexContainer";
import { InputSelect } from "../../Inputs/InputSelect";

interface Props {
  onClose: () => void;
  open: boolean;
  loading?: boolean;
  error: string;
  updateFulfillmentName: (name: string, orderlineId?: string) => void;
  fulfillmentNames: { label: string; value: string }[];
  fulfillmentNameId?: string;
  orderlineId?: string;
}

const ChangeModal: React.FC<Props> = ({
  onClose,
  updateFulfillmentName,
  open,
  error,
  loading,
  fulfillmentNames,
  orderlineId,
  fulfillmentNameId,
}) => {
  const [fulfillmentName, setFulfillmentName] = useState(
    fulfillmentNameId !== "-" && fulfillmentNameId ? fulfillmentNameId : ""
  );

  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Modal
        width="600px"
        buttons={
          <div>
            <ButtonsContainer justifyContent="flex-end">
              <Button
                onClick={onClose}
                theme="white"
                label={t("component.orderFulfillment.goBack")}
              />
              <Button
                onClick={() =>
                  updateFulfillmentName(fulfillmentName, orderlineId)
                }
                theme="blue"
                label={
                  loading
                    ? "..."
                    : t("component.orderFulfillment.updateDefinition")
                }
              />
            </ButtonsContainer>
          </div>
        }
        open={open}
        onClose={onClose}
      >
        <div className={styles.modalContainer}>
          <FlexContainer flexDirection="column">
            <AlertIcon />
            <Text
              padding="1em 0 1em 0"
              text={t("component.orderFulfillment.changeDefinition")}
              color="#000000"
              fontSize="1.4em"
              fontWeight={700}
            />
          </FlexContainer>

          {!fulfillmentNames.length ? (
            <div className={styles.notEnrollContainer}>
              No States Definitions configured. Please add them to your
              Organization
            </div>
          ) : (
            <div className={styles.selectContainer}>
              <InputSelect
                maxHeightOptions="8em"
                label={t("component.orderFulfillment.fulfillmentDefinition")}
                options={fulfillmentNames}
                value={fulfillmentName}
                onChange={(value) => {
                  setFulfillmentName(value);
                }}
                error={error}
              />
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ChangeModal;
