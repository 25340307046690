import * as React from "react";
import { classes, style } from "typestyle";
import { black } from "../../constants/colors";
import SortIcon from "../Icons/Sort";
import { Dropdown } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface ListHeaderLabelProps {
  value: string;
  textAlign?: "center" | "left" | "right";
  justifyContent?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-evenly"
    | "space-around ";
  alignItems?: "center" | "flex-start" | "flex-end" | "space-between";
  sortable?: boolean;
  sort?: "asc" | "desc" | null;
  onClick?: () => void;
  fontFormat?: "bold" | "normal" | "medium" | "semiBold";
  changeHeader?: boolean;
  items?: any[];
}

const bold = style({ fontWeight: 700 });
const semiBold = style({ fontWeight: 600 });

const rowColumn = style({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0.5em 1em",
  color: black,
  userSelect: "none",
  textTransform: "uppercase",
});

const rowColumnLabel = style({
  fontSize: "0.9em",
  fontWeight: 600,
  color: "#949494",
  margin: "0 .5em 0 0",
});

export const ListHeaderLabel: React.FC<ListHeaderLabelProps> = ({
  textAlign,
  value,
  sortable,
  sort,
  justifyContent,
  alignItems,
  onClick,
  fontFormat,
  changeHeader,
  items,
}) =>
  changeHeader ? (
    <div
      className={rowColumn}
      style={{
        textAlign,
        justifyContent,
        alignItems,
        cursor: onClick ? "pointer" : sortable ? "not-allowed" : "initial",
      }}
      onClick={onClick}
    >
      <span
        className={classes(rowColumnLabel, {
          [bold]: fontFormat === "bold",
          [semiBold]: fontFormat === "semiBold",
        })}
      >
        {value}
      </span>
      {sortable ? <SortIcon sort={sort} /> : null}
      <Dropdown
        placement="bottomRight"
        menu={{
          items,
        }}
        trigger={["click"]}
      >
        <FontAwesomeIcon
          style={{ cursor: "pointer" }}
          icon={faChevronDown as IconProp}
          color={"#949494"}
          size="1x"
        />
      </Dropdown>
    </div>
  ) : (
    <div
      className={rowColumn}
      style={{
        textAlign,
        justifyContent,
        alignItems,
        cursor: onClick ? "pointer" : sortable ? "not-allowed" : "initial",
      }}
      onClick={onClick}
    >
      <span
        className={classes(rowColumnLabel, {
          [bold]: fontFormat === "bold",
          [semiBold]: fontFormat === "semiBold",
        })}
      >
        {value}
      </span>
      {sortable ? <SortIcon sort={sort} /> : null}
    </div>
  );
