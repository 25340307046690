

import { stylesheet } from "typestyle";
import { black, linkBlue } from "../../utils/colors";

const styles = stylesheet({
  container: {
    padding: "2em",
  },
  normalButton: {
    cursor: "pointer",
    fontSize: "1em",
    fontWeight: 600,
    padding: "0 0.5em",
    border: "1px solid #dce1e6",
    borderRadius: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "2.9em",
    minWidth: "9em",
    background: "#fff",
  },
  icon: {
    width: 24,
    cursor: "pointer",
  },
  iconBig: {
    width: 40,
    cursor: "pointer",
  },
  smallIcon: {
    width: "1.2em",
    cursor: "pointer",
    margin: "0 0.5em 0 0.1em",
  },
  borderTop: {
    borderTop: "1px solid #DCE1E6",
    margin: "0.5em 0",
  },
  iconDelete: {
    width: 30,
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
  },

  title: {
    fontSize: "1.5em",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "left",
    color: "#141322",
    paddingTop: "1em",
  },
  bookMark: {
    color: linkBlue,
    fill: linkBlue,
    cursor: "pointer",
    width: "1em",
    marginRight: "0.5em",
  },
  filtersContainer: {
    paddingBottom: "2em",
  },
  filtersContainerW: {
    paddingBottom: "1.5em",
  },

  loading: {
    color: black,
    fontWeight: 600,
    padding: "6em 1em",
  },

  labelContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    marginRight: "0.5em",
    padding: "1em",
  },
  label: {
    cursor: "pointer",
    margin: "0.2em 0.5em 0.2em 0",
    width: "auto",
    color: linkBlue + " !important",
  },

  center: {
    color: black,
    fontStyle: "normal",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    padding: "1em",
    display: "flex",
    alignItems: "center",
    marginRight: "0.5em",
  },
  normal: {
    fontSize: "1em",
    fontWeight: 500,
    alignItems: "center",
    justifyContent: "flex-start",
    display: "flex",
    padding: "0 0.5em",
  },
  normalBold: {
    fontSize: "1em",
    fontWeight: 600,
    alignItems: "center",
    justifyContent: "flex-start",
    display: "flex",
    padding: "0 0.5em",
  },
  normalCol: {
    fontSize: "1em",
    fontWeight: 600,
    alignItems: "flex-start",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    padding: "0 0.5em",
  },
  flexStart: {
    fontSize: "1em",
    fontWeight: 600,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    padding: "0 0.5em",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  spacebetween: {
    color: black,
    fontStyle: "normal",
    justifyContent: "space-evenly",
    padding: "1em",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
  },

  paginationContainer: {
    padding: "2em 0",
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  paginationContainerUp: {
    padding: "2em 0 1em 0",
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    position: "fixed",
    bottom: 0,
    background: "#f2f4f8",
  },
  listContainer: {
    paddingBottom: "3em",
  },
  red: { color: "#FF2929" },
  gray: { color: "#858D8F" },
  headerLabel: {
    color: "#949494",
    fontSize: "0.9em",
    fontWeight: 600,
    textTransform: "uppercase",
  },
  bold: {
    fontWeight: 700,
  },
});

export default styles;
