import React from "react";
import { useTranslation } from "react-i18next";
import { Radio, Spin } from "antd";

import { Category } from "../../utils/types";
import Item from "./Item";
import { useHistory } from "react-router-dom";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import styles from "./styles";

interface Props {
  orderCategories: Category[];
  eventCategories: Category[];
  assetCategories: Category[];
  userCategories: Category[];
  locationsCategories: Category[];

  setCategories: (content: string, categories: Category[]) => void;
  loading: string;
  load: () => void;
  content: string;
  setContent: (content: string) => void;
}

const OrgTags: React.FC<Props> = ({
  orderCategories,
  eventCategories,
  assetCategories,
  userCategories,
  locationsCategories,
  setCategories,
  loading,
  load,
  content,
  setContent,
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  return (
    <>
      <FlexContainer justifyContent="flex-start">
        <Radio.Group
          value={content}
          onChange={(value) => setContent(value.target.value)}
          style={{ marginBottom: 16 }}
        >
          <Radio.Button
            value="orders"
            onClick={() => history.push("/organization/tags?content=orders")}
          >
            {t("component.orgTags.orders")}
          </Radio.Button>
          <Radio.Button
            value="fulfillmentEvents"
            onClick={() =>
              history.push("/organization/tags?content=fulfillmentEvents")
            }
          >
            {t("component.orgTags.events")}
          </Radio.Button>
          <Radio.Button
            value="assets"
            onClick={() => history.push("/organization/tags?content=assets")}
          >
            {t("component.orgTags.assets")}
          </Radio.Button>

          <Radio.Button
            value="users"
            onClick={() => history.push("/organization/tags?content=users")}
          >
            {t("component.orgTags.users")}
          </Radio.Button>
          <Radio.Button
            value="locations"
            onClick={() => history.push("/organization/tags?content=locations")}
          >
            {t("component.orgTags.locations")}
          </Radio.Button>
        </Radio.Group>
      </FlexContainer>
      {loading === "details" ? (
        <Spin />
      ) : (
        <div className={styles.scroll}>
          {content === "orders" && (
            <Item
              load={load}
              loading={loading === "save"}
              content="orders"
              data={orderCategories}
              onSave={setCategories}
            />
          )}
          {content === "fulfillmentEvents" && (
            <Item
              load={load}
              loading={loading === "save"}
              content="fulfillmentEvents"
              data={eventCategories}
              onSave={setCategories}
            />
          )}
          {content === "assets" && (
            <Item
              load={load}
              loading={loading === "save"}
              content="assets"
              data={assetCategories}
              onSave={setCategories}
            />
          )}
          {content === "users" && (
            <Item
              load={load}
              loading={loading === "save"}
              content="users"
              data={userCategories}
              onSave={setCategories}
            />
          )}
          {content === "locations" && (
            <Item
              load={load}
              loading={loading === "save"}
              content="locations"
              data={locationsCategories}
              onSave={setCategories}
            />
          )}
        </div>
      )}
    </>
  );
};

export default OrgTags;
