const translation = {
  en: {
    name: "Name",
    pending: "Pending",
    fulfilled: "Fulfilled",
    total: "Total",
    actions: "Actions",
    sendMessage: "Send Message",
    map: "View Map",
    tomorrow: "Tomorrow",
    today: "Today",
    events: "Events",
    list: "View List",
    plannedRoute: "Planned Route",
    realRoute: "Real Route",
    type: "Type",
    subtype: "Subtype",
    asset: "Asset",
    timestamp: "Timestamp",
    points: "Points",
    time: "Time",
    load: "Load",
    running: "Running",
    failed: "Failed",
    status: "Status",
    shortId: "ID",
    states: "Fulfillment States",

  },
  es: {
    states: "Estados de Fulfillment",
    shortId: "ID",
    status: "Estado",
    runnning: "Corriendo",
    failed: "Fallido",
    time: "Hora",
    load: "Carga",
    type: "Tipo",
    subtype: "Subtipo",
    asset: "Activo",
    timestamp: "Fecha",
    points: "Puntos",
    plannedRoute: "Ruta Planeada",
    realRoute: "Ruta Real",
    list: "Vista Lista",
    events: "Eventos",
    map: "Vista Mapa",
    tomorrow: "Mañana",
    today: "Hoy",
    name: "Nombre",
    pending: "Pendiente",
    fulfilled: "Cumplidos",
    total: "Eventos Totales",
    actions: "Acciones",
    sendMessage: "Mandar mensaje",
  },
  de: {
    name: "Name",
    pending: "Pending",
    fulfilled: "Fulfilled",
    total: "Total Events",
    actions: "Actions",
  },
};

export default translation;
