import React, { useState } from "react";
import { Team } from "@innomius/ravent-typescript-types";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { ReactComponent as ChatIcon } from "../../assets/chat.svg";

import { Link, RouterProps } from "react-router-dom";
import styles from "./styles";
import SendMessage from "./SendMessage";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";
import { StatusBadgePoint } from "../StatusBadgePoint/StatusBagePoint";

interface EntityListProps extends RouterProps {
  data: Team;
}

const EntityItem: React.FC<EntityListProps> = ({ data, history }) => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <SendMessage
        onSend={() => {}}
        open={open}
        onClose={() => setOpen(false)}
      />
      <ListRow
        boxShadow
        borderRadius="6px"
        template="1.3fr 1fr 1fr 1fr 1fr 0.7fr"
        hover={true}
        key={data.id}
        onClick={() => {
          document.title = "RAVENT APP :: Team :: Details";
          history.push(`/teams/${data.id}/users`);
        }}
      >
        <ListLabel fontFormat="bold" value={data.name} textAlign="left" />

        <div />

        <ListLabel
          fontFormat="bold"
          value={data.userCounts?.doc_count?.toString() || "0"}
          textAlign="left"
        />

        <div className={styles.center}>
          {data.internal ? (
            <StatusBadgePoint
              point
              status={data.internal ? "active" : "DISABLED"}
            />
          ) : null}
        </div>

        <div className={styles.center}>
          <StatusBadgePoint
            point
            status={data.enabled ? "active" : "cancelled"}
          />
        </div>
        <div className={styles.even}>
          <Tooltip title={t("component.entityList.sendMessage")}>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setOpen(true);
              }}
            >
              <ChatIcon className={styles.icon} />
            </div>
          </Tooltip>

          <Link to={`/teams/${data.id}`}>
            <EditIcon className={styles.icon} />
          </Link>
        </div>
      </ListRow>
    </>
  );
};

export default EntityItem;
