import React, { useState } from "react";
import { User } from "@innomius/ravent-typescript-types";
import styles from "./styles";
import { Checkbox, Popover, Tag, Tag as TagANTD, Tooltip } from "antd";
import { useHistory } from "react-router";
import { ListRow } from "../List/ListRow";
import fontColorContrast from "font-color-contrast";
import { StatusBadgePoint } from "../StatusBadgePoint/StatusBagePoint";
import { formatDate } from "../../utils/times";
import { trimString } from "../../utils/format";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import userIcon from "../../assets/user-gray.svg";
import fulfillerIcon from "../../assets/fulfiller-gray.svg";
import phoneIcon from "../../assets/phone-gray.svg";
import emailIcon from "../../assets/user-email.svg";
import deleteIcon from "../../assets/deleteBlack.svg";
import { Role } from "../../services/roles";
import DeletePopUp from "../DeletePopUp";
import resetIcon from "../../assets/reset.svg";
import { useTranslation } from "react-i18next";
import ResetPasswordModal from "../ResetPasswordModal";
import Text from "../Text";

interface UserListProps {
  data: User;
  onDelete: (id: string) => void;
  loadingDelete: boolean;
  permission: boolean;
  onCheckboxChange?: () => void;
  isSelected?: boolean;
  roles: Role[];
  resetPassword: (id: string) => void;
}

const UserItem: React.FC<UserListProps> = ({
  data,
  onCheckboxChange,
  isSelected,
  roles,
  loadingDelete,
  onDelete,
  resetPassword,
}) => {
  const history = useHistory();
  const formatOptionKey = (key: string, color: string) => {
    if (key && key.includes("::")) {
      const [firstPart, secondPart] = key.split("::");
      return (
        <Tooltip title={firstPart}>
          <TagANTD
            style={{
              color: color
                ? color === "blue"
                  ? "blue"
                  : fontColorContrast(color, 0.5)
                : "blue",
            }}
            color={color || "blue"}
          >
            {secondPart}
          </TagANTD>
        </Tooltip>
      );
    } else {
      return <TagANTD color={color || "blue"}>{key}</TagANTD>;
    }
  };
  const [deleteUser, setDelete] = useState("");
  const [open, setOpen] = useState("");

  const { t } = useTranslation();

  return (
    <>
      <ResetPasswordModal
        onReset={() => {
          let contact = data.loginWithPhone ? data.phone : data.email;
          if (data.loginWithPhone && contact.startsWith("+")) {
            contact = contact.replace("+", "") + "@ravent.com";
          }
          resetPassword(contact);
          setOpen("");
        }}
        open={open === "reset"}
        onCancel={() => setOpen("")}
      />
      <ListRow
        template="2em 0.3fr 0.5fr 1fr 0.6fr 0.8fr 1.5fr 0.2fr"
        hover={true}
        boxShadow
        borderRadius="6px"
        onClick={() => history.push(`/users/${data.id}`)}
      >
        <div
          className={styles.center}
          style={{ padding: "0.4em" }}
          onClick={(e) => e.stopPropagation()}
        >
          <Checkbox checked={isSelected} onChange={onCheckboxChange} />
        </div>
        <div className={styles.justy}>{data.externalId}</div>
        <div className={styles.normal}>
          <div style={{ marginRight: "0.5em" }}>
            <StatusBadgePoint
              point
              status={
                data.disabled
                  ? "DISABLED"
                  : !data.emailVerified
                  ? "un-paid"
                  : "active"
              }
            />
          </div>
          {formatDate(data.created, "DAYMONTHYEAR") || "-"}
        </div>

        <div className={styles.normal}>
          <FlexContainer
            justifyContent="flex-start"
            alignItems="flex-start"
            flexDirection="column"
          >
            <FlexContainer padding="0 0 0.5em 0" justifyContent="flex-start">
              <img
                src={data.type === "fulfiller" ? fulfillerIcon : userIcon}
                alt="user"
                className={styles.icon}
              />
              {trimString(`${data.lastName}, ${data.firstName} `, 28)}
            </FlexContainer>
            {data.phone?.length && data.email?.length ? (
              <FlexContainer justifyContent="flex-start">
                <img
                  src={
                    data.phone?.length && data.loginWithPhone
                      ? phoneIcon
                      : emailIcon
                  }
                  className={styles.icon}
                  alt=""
                />
                {trimString(
                  data.phone?.length && data.loginWithPhone
                    ? data.phone
                    : data.email,
                  25
                )}{" "}
              </FlexContainer>
            ) : null}
          </FlexContainer>
        </div>

        <div className={styles.normalBold}>{data.fulfillmentTeamName}</div>
        <div className={styles.center}>
          <Popover
            content={
              data.adminRole ? (
                <FlexContainer justifyContent="flex-start">
                  <StatusBadgePoint point status={"fulfilled"} />

                  <Text text={t("component.userList.adminRole")} />
                </FlexContainer>
              ) : (
                <FlexContainer justifyContent="flex-start">
                  <StatusBadgePoint point status={"DISABLED"} />

                  <Text text={t("component.userList.adminRole")} />
                </FlexContainer>
              )
            }
          >
            {data.roles.map((item, index) => {
              const role = roles.find((role) => role.id === item);
              return role ? (
                <Tag style={{ margin: "0 0.2em 0.2em 0" }} key={index}>
                  {role.name}
                </Tag>
              ) : null;
            })}
          </Popover>
        </div>

        <div className={styles.labelContainer}>
          {data.colorizedTags?.map(
            (
              item: {
                key: string;
                settings: { tagClassification: string; color: string };
              },
              index
            ) => (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                style={{ margin: "0 0.2em 0.2em 0" }}
                key={index}
              >
                {formatOptionKey(
                  item.key,
                  item.settings && item.settings.color
                    ? item.settings.color
                    : "blue"
                )}
              </div>
            )
          )}
        </div>

        <div className={styles.iconContainer}>
          <img
            src={resetIcon}
            alt=""
            className={styles.icon}
            onClick={(e) => {
              e.stopPropagation();
              setOpen("reset");
            }}
          />
          <Popover
            placement="left"
            content={
              <div onClick={() => setDelete("")}>
                <DeletePopUp
                  loading={loadingDelete}
                  value={`${t("component.userList.deleteMessage")} ${
                    data.firstName
                  } ${data.lastName}?`}
                  onClose={(e) => {
                    e.stopPropagation();
                    setDelete("");
                  }}
                  onDelete={(e) => {
                    e.stopPropagation();
                    onDelete(data.id);
                  }}
                />
              </div>
            }
            trigger="click"
            open={deleteUser === "delete"}
            onOpenChange={() => setDelete(deleteUser)}
          />
          <img
            src={deleteIcon}
            alt="userDelete"
            onClick={(e) => {
              e.stopPropagation();
              setDelete("delete");
            }}
          />
        </div>
      </ListRow>
    </>
  );
};

export default UserItem;
