const translation = {
  en: {
    invoiceState: "Invoice State",
    flowUrl: "Flow URL",
    manualAutomatic: "Off/On",
    actionName: "Action",
  },
  es: {
    invoiceState: "Estado de la factura",
    flowUrl: "URL de flujo",
    manualAutomatic: "Apagado/Prendido",
    actionName: "Acción",
  },

  de: {},
};

export default translation;
