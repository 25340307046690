import * as React from 'react';
import { stylesheet } from 'typestyle';
import { lightGrey } from '../../utils/colors';

interface SectionSpacerProps {
  color?: string;
  padding?: string | number;
  height?: string | number;
}

const styles = stylesheet({
  spacer: {
    padding: '1em 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto'
  },
  line: {
    width: '100%',
    height: 1,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: lightGrey
  }
});

export const SectionSpacer: React.FC<SectionSpacerProps> = ({
  padding = '1em 0',
  height = 'auto',
  color = lightGrey
}) => (
  <div className={styles.spacer} style={{ padding, height }}>
    <div className={styles.line} style={{ borderColor: color }} />
  </div>
);
