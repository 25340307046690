
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  assetList as assetListService,
  AssetsParams,
  AssetsSearchResponse,
} from "../services/assets";

type AssetsSliceService = {
  loading: boolean;
  error: string;
  list: AssetsSearchResponse;
};

const initialState: AssetsSliceService = {
  loading: true,
  error: "",
  list: {
    page: 0,
    total: 0,
    data: [],
  },
};

export const assetList = createAsyncThunk(
  "assets/list",
  async (params: AssetsParams) => {
    const response = await assetListService(params);
    return response;
  }
);

const assetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(assetList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(assetList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.list = action.payload;
    });
    builder.addCase(assetList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An unexpected error ocurred";
    });
  },
});

export default assetsSlice.reducer;
