import React from "react";
import { classes, style } from "typestyle";
import chevronDown from "../../../assets/chevron-down.svg";
import { black } from "../../../utils/colors";
import styles from "./styles.module.css";
import { InputText } from "../InputText";

interface InputTextSelectOption {
  label: string;
  value: string;
  id?: string;
}

export interface InputTextSelectProps {
  id?: string;
  value?: string | number;
  placeholder?: string;
  options?: InputTextSelectOption[];
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: (value: string, id?: string) => void;
  containerClassname?: string;
  label?: string;
  error?: false | "" | string[] | string;
  showError?: boolean;
  showLabel?: boolean;
}

const selectButton = style({
  zIndex: 2,
  borderRadius: 2,
  position: "relative",
  display: "block",
  boxSizing: "border-box",
  color: black,
  background: "#FFF",
  $nest: {
    "&:focus": {
      borderColor: "rgba(5, 178, 245, 0.2)",
      $nest: {
        "& > div": {
          borderColor: "rgba(5, 178, 245, 0.2)",
        },
      },
    },
  },
});

const arrowIconContainer = style({
  position: "absolute",
  zIndex: 1,
  top: 0,
  right: 0,
  height: "100%",
  width: "2.7em",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const InputTextSelect: React.FC<InputTextSelectProps> = (props) => {
  const [focus, setFocus] = React.useState(false);
  const {
    value,
    disabled,
    readOnly,
    onChange,
    options,
    containerClassname,
    error,
    label,
  } = props;

  const ref = React.useRef<HTMLButtonElement>(null);

  return (
    <div className={classes(styles.container, containerClassname)}>
      <div className={styles.selectWrapper}>
        <div
          className={selectButton}
          style={{ borderColor: error ? "red" : "" }}
        >
          <InputText
            label={label}
            value={value}
            onChange={(value) => onChange?.(value)}
          />
          <button
            onClick={() => {
              ref.current?.focus();
            }}
            onFocus={() => {
              setFocus(true);
            }}
            onMouseUp={(e) => e.preventDefault()}
            ref={ref}
            onBlur={() => setTimeout(() => setFocus(false), 150)}
            className={arrowIconContainer}
            style={{ borderColor: error ? "red" : "" }}
          >
            <img src={chevronDown} alt="" className={styles.icon} />
          </button>
        </div>

        <div
          className={styles.options}
          style={{
            display: focus && !disabled && !readOnly ? "block" : "none",
          }}
        >
          {options?.map((option, index) => (
            <div
              onClick={() => onChange?.(option.value)}
              key={option.label + index}
              className={styles.option}
            >
              {option.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
