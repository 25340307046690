import React from "react";
import styles from "./styles";
import { OrderLine } from "@innomius/ravent-typescript-types";
import OrderlineFulfillmentItem from "./Item";
import { useTranslation } from "react-i18next";
import { Card } from "../Card/Card";
import { ContainerTitle } from "../ContainerTitle";

interface Props {
  setState: (
    id: string,
    fulfillmentLevel: string,
    status: string,
    w3w: string,
    images: string[]
  ) => void;
  updateFulfillmentName: (
    id: string,
    diveshopId: string,
    fulfillmentLevel: string
  ) => void;
  fulfillmentNames: { label: string; value: string }[];

  orderlines: OrderLine[];
}

const OrderlineFulfillment: React.FC<Props> = ({
  orderlines,
  setState,
  fulfillmentNames,
  updateFulfillmentName,
}) => {
  const { t } = useTranslation();
  return (
    <Card>
      <div className={styles.wrapperCard}>
        <ContainerTitle
          size="small"
          label={t("component.orderFulfilmentComponent.states")}
        />
        {orderlines.map((item) => (
          <div style={{ marginTop: "1.5em" }} key={item.id}>
            <OrderlineFulfillmentItem
              orderId={item.orderId}
              currentState={item.fulfillmentState}
              fulfillmentDefinitionId={item.fulfillmentDefinitionId}
              fulfillmentLevel={"orderline"}
              fulfillmentNames={fulfillmentNames}
              updateFulfillmentName={updateFulfillmentName}
              orderlineId={item.id}
              setState={setState}
              description={item.description}
            />
          </div>
        ))}
      </div>
    </Card>
  );
};

export default OrderlineFulfillment;
