import React from "react";
import { OrderListSkeleton } from "../ListSkeletons";
import { useTranslation } from "react-i18next";
import { ListRow } from "../List/ListRow";
import { ListLabel } from "../List/ListLabel";
import { formatDate } from "../../utils/times";
import { HistoryPass } from "../../utils/types";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { List } from "../List";

interface Props {
  data: HistoryPass[];
}

const PassHistoricList: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  const header = (
    <div>
      <ListHeader template={"1fr 1fr 1fr 1fr"}>
        <ListHeaderLabel
          value={t("component.orderPasses.action")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderPasses.date")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderPasses.by")}
          justifyContent="flex-start"
        />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <List
        loadingElement={() => <OrderListSkeleton />}
        data={data}
        keyExtractor={(item) => item.timestamp}
        renderHeader={() => header}
        renderItem={(item) => (
          <>
            <ListRow template={"1fr 1fr 1fr 1fr"} background="#fff">
              <ListLabel value={item.action || "-"} textAlign="left" />
              <ListLabel
                value={formatDate(item.timestamp, "MIDDLEWHOUR")}
                textAlign="left"
              />
              <ListLabel value={item.userId || "-"} textAlign="left" />
            </ListRow>
          </>
        )}
      />
    </div>
  );
};

export default PassHistoricList;
