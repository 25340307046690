import React, { useCallback, useEffect, useState } from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import { Action, Schedule, Venue } from "@innomius/ravent-typescript-types";
import TabNavBar from "../../components/TabNavBar";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";

import {
  getVenue,
  updateCheckinLocation,
  updateVenue,
} from "../../services/venues";

import { Loading } from "../../components/Loading";
import message from "antd/lib/message";
import APIError from "../../utils/APIError";
import VenueStates from "../../components/VenueStates";
import VenueStatesForm from "../../components/VenueForm/VenueStatesForm";
import VenueEvents from "../../components/VenueEvents";
import VenueEventsForm from "../../components/VenueForm/VenueEventsForm";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { venueList } from "../../store/venues";
import secureLocalStorage from "react-secure-storage";
import { InputSelect } from "../../components/Inputs/InputSelect";
import OrgIntegrations from "../../components/OrgIntegrations";
import { Integration } from "../../utils/types";
import Text from "../../components/Text";
import {
  createIntegration,
  defaultIntegration,
  getIntegration,
  removeIntegration,
  updateIntegration,
} from "../../services/integrations";
import { integrationList } from "../../store/integrations";
import InvoiceStateList from "../../components/InvoiceStateList";
import { Card } from "../../components/Card/Card";
import PaymentStates from "../../components/VenueStates/PaymentStates";
import VenuePaymentStatesForm from "../../components/VenueForm/VenuePaymentStatesForm";
import PaymentEvents from "../../components/VenueEvents/PaymentEvents";
import VenuePaymentEventsForm from "../../components/VenueForm/VenuePaymentEventsForm";
import { black } from "../../utils/colors";
import Schedules from "../../components/Schedules";
import IntAction from "../../components/IntAction";

import CheckinListInt from "../../components/CheckinListInt";
import { locationList } from "../../store/locations";
import VenueInvoiceEventsForm from "../../components/VenueForm/VenueInvoiceEventsForm";
import {
  createAction,
  deleteAction,
  listActions,
  updateAction,
} from "../../services/actions";
import {
  createSchedule,
  deleteSchedule,
  listSchedules,
  updateSchedule,
} from "../../services/schedules";
import { Radio } from "antd";
import styles from "./styles.module.css";
import EventTriggersForm from "../../components/VenueForm/EventTriggersForm";
import EventTriggers from "../../components/VenueEvents/EventTriggers";
import { EmptyList } from "../../components/List/EmptyList";
import { ContainerTitle } from "../../components/ContainerTitle";
import { Button } from "../../components/Button";

interface RouteParams {
  id: string;
}

interface Props extends RouteComponentProps<RouteParams> {}

const RIntegration: React.FC<Props> = ({ history }) => {
  const [venue, setVenue] = useState<Venue | null>();
  const dispatch = useDispatch();

  const [edit, setEdit] = useState<string>("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const [open, setOpen] = useState("");
  const [loadingOrg, setLoadingOrgInt] = useState<string>("");
  const integrations = useSelector((state: RootState) => state.integrations);
  const [records_per_page, setHitsPerPage] = useState(20);
  const locations = useSelector((state: RootState) => state.locations);

  const [page, setPage] = useState(integrations.list.page);
  const [integration, setIntegration] =
    useState<Integration>(defaultIntegration);
  const [supplierType, setSupplierType] = useState("");
  const [type, setType] = useState("");
  const [text, setText] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [content, setContent] = useState("manual");

  const [actions, setActions] = useState<Action[]>([]);

  const [schedules, setSchedules] = useState<Schedule[]>([]);

  const loadSchedules = useCallback(async () => {
    try {
      const res = await listSchedules({ page: 0, records_per_page: 100 });
      setSchedules(res.data);
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  }, []);

  useEffect(() => {
    loadSchedules();
  }, [loadSchedules]);

  const loadOrgIntegrations = useCallback(async () => {
    dispatch(
      integrationList({
        page,
        records_per_page,
        type,
        supplierType,
        text,
        supplierName,
      })
    );
  }, [
    dispatch,
    text,
    supplierType,
    type,
    page,
    records_per_page,
    supplierName,
  ]);

  useEffect(() => {
    loadOrgIntegrations();
  }, [loadOrgIntegrations]);

  useEffect(() => {
    document.title = "RAVENT APP :: Integrations";
  });

  const onChangeOrgInt = <P extends keyof Integration>(
    prop: P,
    value: Integration[P]
  ) => setIntegration({ ...integration, [prop]: value });

  const onCreate = async () => {
    try {
      setLoadingOrgInt("create");
      await createIntegration(integration);
      message.success({
        content: t("container.integrations.successCreation"),
        className: "custom-class",
        style: {
          marginTop: "3em",
        },
      });
      setLoadingOrgInt("");
      setOpen("");
      setIntegration(defaultIntegration);
      loadOrgIntegrations();
    } catch (err) {
      setLoadingOrgInt("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onGetIntegration = async (id: string) => {
    try {
      setLoadingOrgInt("details");
      const integration = await getIntegration(id);
      setIntegration(integration);
      setOpen("details");
      setLoadingOrgInt("");
    } catch (err) {
      setLoadingOrgInt("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onUpdateOrg = async (data: Integration) => {
    try {
      setLoadingOrgInt("edit");
      await updateIntegration(data);
      message.success({
        content: t("container.integrations.successUpdate"),
        className: "custom-class",
        style: {
          marginTop: "3em",
        },
      });
      setLoadingOrgInt("");
      loadOrgIntegrations();
    } catch (err) {
      setLoadingOrgInt("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onRemoveOrgInt = async (id: string) => {
    try {
      setLoadingOrgInt("delete");
      await removeIntegration(id);
      message.success(t("container.integrations.successDeleted"));
      setLoadingOrgInt("");
      loadOrgIntegrations();
    } catch (err) {
      setLoadingOrgInt("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const [venueId, setVenueId] = useState(secureLocalStorage.getItem("venueId"));

  const venues = useSelector((state: RootState) => state.venues);

  const loadActions = useCallback(async () => {
    try {
      const res = await listActions({
        page: 0,
        records_per_page: 100,
        venue_id: venueId as string,
      });
      setActions(res.data);
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  }, [venueId]);

  useEffect(() => {
    loadActions();
  }, [loadActions]);

  const loadVenues = useCallback(async () => {
    dispatch(venueList({}));
  }, [dispatch]);

  useEffect(() => {
    loadVenues();
  }, [loadVenues]);

  const { t } = useTranslation();

  const load = useCallback(async () => {
    try {
      setLoading("detail");
      const venue = await getVenue(venueId as string);
      setVenue(venue);
      dispatch(
        locationList({
          venueId: venue.id,
        })
      );
      setLoading("");
    } catch (err) {
      setLoading("detail");
      if (err instanceof APIError) {
        setError(err.message);
      }
    }
  }, [venueId, dispatch]);

  useEffect(() => {
    load();
  }, [venueId, load]);

  useEffect(() => {
    document.title = "RAVENT APP :: Integrations";
  });

  if (loading === "detail" && !error) {
    return <Loading />;
  }

  if (!venue) {
    return <EmptyList text="Error" instructions={""} error={error} />;
  }

  const onChange = <P extends keyof Venue>(prop: P, value: Venue[P]) => {
    setVenue({ ...venue, [prop]: value });
  };

  const configureKey = async (secret: string) => {
    try {
      setLoading("key");
      await updateVenue(venue, secret);
      message.success({
        content: t("container.rIntegration.successUpdated"),
        className: "custom-class",
        style: {
          marginTop: "3em",
        },
      });
      setLoading("");
      load();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`Error: ${err.messages}`);
      }
    }
  };

  const onEdit = async () => {
    try {
      setLoading("edit");
      await updateVenue(venue);
      message.success({
        content: t("container.rIntegration.successUpdated"),
        className: "custom-class",
        style: {
          marginTop: "3em",
        },
      });
      setLoading("");
      setEdit("");
      load();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`Error: ${err.messages}`);
      }
    }
  };

  const addingOrderAction = async (action: Action) => {
    try {
      await createAction(action);
      message.success({
        content: t("container.rIntegration.successCreation"),
        className: "custom-class",
        style: {
          marginTop: "3em",
        },
      });
      setLoading("");
      setEdit("");
      loadActions();
      history.push("/integrations/actions");
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`Error: ${err.messages}`);
      }
    }
  };

  const addingSchedule = async (action: Schedule) => {
    try {
      await createSchedule(action);
      message.success({
        content: t("container.rIntegration.successCreation"),
        className: "custom-class",
        style: {
          marginTop: "3em",
        },
      });
      setLoading("");
      setEdit("");
      loadSchedules();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`Error: ${err.messages}`);
      }
    }
  };

  const onUpdateAction = async (id: string, action: Action) => {
    try {
      await updateAction(id, action);
      message.success({
        content: t("container.rIntegration.successUpdated"),
        className: "custom-class",
        style: {
          marginTop: "3em",
        },
      });
      setLoading("");
      setEdit("");
      loadActions();
      history.push(`/integrations/actions/${id}`);
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`Error: ${err.messages}`);
      }
    }
  };

  const onUpdateSchedule = async (id: string, schedule: Schedule) => {
    try {
      await updateSchedule(id, schedule);
      message.success({
        content: t("container.rIntegration.successUpdated"),
        className: "custom-class",
        style: {
          marginTop: "3em",
        },
      });
      setLoading("");
      setEdit("");
      loadSchedules();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`Error: ${err.messages}`);
      }
    }
  };

  const onDeleteAction = async (id: string) => {
    try {
      setLoading("delete");
      await deleteAction(id);
      message.success(t("container.rIntegration.successDelete"));
      setLoading("");
      loadActions();
      history.push("/integrations/actions");
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`Error: ${err.messages}`);
      }
      setLoading("");
    }
  };

  const onDeleteSchedule = async (id: string) => {
    try {
      setLoading("delete");
      await deleteSchedule(id);
      message.success(t("container.rIntegration.successDelete"));
      setLoading("");
      loadSchedules();
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`Error: ${err.messages}`);
      }
      setLoading("");
    }
  };

  const onEditLocation = async (
    locationId: string,
    checkinFlowUrl: string,
    checkinPeriodInMinutes: string,
    publicCheckin: boolean
  ) => {
    try {
      await updateCheckinLocation(
        locationId,
        checkinFlowUrl,
        checkinPeriodInMinutes,
        publicCheckin
      );
      message.success(`${t("container.venueDetail.successUpdatedLocation")}`);
      load();
    } catch (err) {
      if (err instanceof APIError) {
        message.error(`Error: ${err.messages}`);
      }
    }
  };

  const tabOrderStates = {
    id: "venueStates",
    label: `${t("container.rIntegration.states")}`,
    path: `/integrations`,
    onClick: () =>
      (document.title = "RAVENT APP :: Integrations :: Order States"),
  };

  const tabEvents = {
    id: "orderEvents",
    label: `${t("container.rIntegration.events")}`,
    path: `/integrations/events`,
    onClick: () =>
      (document.title = "RAVENT APP :: Integrations :: Order Events"),
  };

  const tabTriggers = {
    id: "triggers",
    label: t("component.venueForm.eventTriggers"),
    path: `/integrations/eventTriggers`,
    onClick: () =>
      (document.title = "RAVENT APP :: Integrations :: Event Triggers"),
  };

  const tabPaymentEvents = {
    id: "paymentEvents",
    label: `${t("container.rIntegration.paymentsEvents")}`,
    path: `/integrations/paymentEvents`,
    onClick: () =>
      (document.title = "RAVENT APP :: Integrations :: Payment Events"),
  };

  const tabPaymentStates = {
    id: "paymentStates",
    label: `${t("container.rIntegration.paymentsState")}`,
    path: `/integrations/paymentStates`,
    onClick: () =>
      (document.title = "RAVENT APP :: Integrations :: Payment States"),
  };

  const tabOrgs = {
    id: "orgsTab",
    label: `${t("container.rIntegration.orgs")}`,
    path: `/integrations/orgs`,
    onClick: () =>
      (document.title = "RAVENT APP :: Integrations :: Organization"),
  };

  const tabInvoice = {
    id: "invoiceTab",
    label: `${t("container.rIntegration.invoice")}`,
    path: `/integrations/invoices`,
    onClick: () => (document.title = "RAVENT APP :: Integrations :: Invoices"),
  };

  const checkinTab = {
    id: "checkinTab",
    label: `${t("container.rIntegration.checkin")}`,
    path: `/integrations/checkin`,
    onClick: () => (document.title = "RAVENT APP :: Integrations :: Check-In"),
  };

  const actionTab = {
    id: "actionTab",
    label: `${t("container.rIntegration.actions")}`,
    path: `/integrations/actions`,
    onClick: () => (document.title = "RAVENT APP :: Integrations :: Actions"),
  };

  const scheduleTab = {
    id: "scheduleTab",
    label: `${t("container.rIntegration.schedules")}`,
    path: `/integrations/schedules`,
    onClick: () => (document.title = "RAVENT APP :: Integrations :: Schedules"),
  };

  return (
    <LayouWithSideBar>
      <div className={styles.container}>
        <FlexContainer
          alignItems="flex-start"
          width="100%"
          justifyContent="space-between"
        >
          <FlexContainer
            flexDirection="column"
            alignItems="flex-start"
            padding="0 0 0.5em 0"
            justifyContent="flex-start"
          >
            <ContainerTitle
              size="medium"
              padding="0 0 1em 0"
              label={t("container.rIntegration.integrations")}
            />
            <Radio.Group
              value={content}
              onChange={(value) => setContent(value.target.value)}
              style={{ marginBottom: 16 }}
            >
              <Radio.Button
                value="manual"
                onClick={() => history.push("/integrations/actions")}
              >
                {t("container.rIntegration.manual")}
              </Radio.Button>
              <Radio.Button
                value="scheduled"
                onClick={() => history.push("/integrations/schedules")}
              >
                {t("container.rIntegration.scheduled")}
              </Radio.Button>
              <Radio.Button
                value="machine"
                onClick={() => history.push("/integrations")}
              >
                {t("container.rIntegration.stateMachine")}
              </Radio.Button>
              <Radio.Button
                value="others"
                onClick={() => history.push("/integrations/invoices")}
              >
                {t("container.rIntegration.others")}
              </Radio.Button>
            </Radio.Group>
          </FlexContainer>

          <InputSelect
            containerClassname={styles.inputSelect}
            options={venues.list.data.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            showLabel={false}
            value={venueId as string}
            label={t("container.certificates.venue")}
            showError={false}
            onChange={(value) => {
              setPage(0);
              setVenueId(value);
            }}
          />
        </FlexContainer>

        <TabNavBar
          options={
            content === "manual"
              ? [actionTab]
              : content === "scheduled"
              ? [scheduleTab]
              : content === "machine"
              ? [
                  tabOrderStates,
                  tabEvents,
                  tabTriggers,
                  tabPaymentStates,
                  tabPaymentEvents,
                ]
              : [tabInvoice, checkinTab, tabOrgs]
          }
        >
          <Route path={`/integrations`} exact>
            {edit === "states" ? (
              <VenueStatesForm
                actions={actions}
                onEdit={onEdit}
                setEdit={setEdit}
                loading={loading}
                onChange={(value) => onChange("orderStates", value)}
                data={venue.orderStates}
              />
            ) : (
              <VenueStates
                edit={edit}
                onEdit={onEdit}
                setEdit={setEdit}
                loading={loading}
                secret={venue.notificationsSecret}
                onConfigureHash={(secret) => configureKey(secret)}
                secretLastSetBy={"Mickey Mouse"}
                secretLastSet={new Date()}
                orderStates={venue.orderStates}
              />
            )}
          </Route>
          <Route path={`/integrations/actions`}>
            <IntAction
              onSaveAction={(data) => onUpdateAction(data.id, data)}
              onAddIntegration={(data) => {
                addingOrderAction(data);
              }}
              error=""
              loadingActions=""
              onDeleteState={onDeleteAction}
              states={actions}
              loading={loading}
            />
          </Route>

          <Route path={`/integrations/schedules`}>
            <Schedules
              onEditSchedule={(data) => onUpdateSchedule(data.id, data)}
              onAddIntegration={(data) => {
                addingSchedule(data);
              }}
              onRemoveSchedule={(id) => onDeleteSchedule(id)}
              error=""
              loadingActions=""
              onDeleteState={onDeleteSchedule}
              states={schedules}
              loading={loading}
              actions={actions}
            />
          </Route>

          <Route path={`/integrations/paymentStates`} exact>
            {edit === "payment-states" ? (
              <VenuePaymentStatesForm
                onEdit={onEdit}
                setEdit={setEdit}
                loading={loading}
                actions={actions}
                onChange={(value) => onChange("paymentStates", value)}
                data={venue.paymentStates}
              />
            ) : (
              <PaymentStates
                edit={edit}
                onEdit={onEdit}
                setEdit={setEdit}
                loading={loading}
                secret={venue.notificationsSecret}
                onConfigureHash={(secret) => configureKey(secret)}
                secretLastSetBy={"Mickey Mouse"}
                secretLastSet={new Date()}
                paymentStates={venue.paymentStates}
              />
            )}
          </Route>

          <Route path={`/integrations/invoices`} exact>
            {edit === "invoice" ? (
              <VenueInvoiceEventsForm
                onEdit={onEdit}
                setEdit={setEdit}
                actions={actions}
                loading={loading}
                onChange={(value) => onChange("invoiceEvents", value)}
                data={venue.invoiceEvents}
              />
            ) : (
              <Card containerClassname={styles.wrapperCard}>
                <FlexContainer
                  padding="0 0 1em 0"
                  justifyContent="space-between"
                >
                  <ContainerTitle
                    padding="0 0 0.5em 0"
                    size="small"
                    label={t("component.venueStates.invoices")}
                  />
                  <Button
                    label={t("container.rIntegration.edit")}
                    onClick={() => setEdit("invoice")}
                  />
                </FlexContainer>
                <Text
                  textAlign="justify"
                  padding="0 0 2em 0"
                  color={black}
                  text={t("component.venueEvents.url")}
                />
                <InvoiceStateList data={venue.invoiceEvents} />
              </Card>
            )}
          </Route>

          <Route path={`/integrations/events`} exact>
            {edit === "events" ? (
              <VenueEventsForm
                onEdit={onEdit}
                actions={actions}
                setEdit={setEdit}
                loading={loading}
                onChange={(value) => onChange("orderEvents", value)}
                data={venue.orderEvents}
              />
            ) : (
              <VenueEvents
                edit={edit}
                onEdit={onEdit}
                setEdit={setEdit}
                loading={loading}
                orderEvents={venue.orderEvents}
              />
            )}
          </Route>

          <Route path={`/integrations/eventTriggers`} exact>
            {edit === "event-triggers" ? (
              <EventTriggersForm
                onEdit={onEdit}
                setEdit={setEdit}
                loading={loading}
                actions={actions}
                onChange={(value) => onChange("eventTriggers", value)}
                data={venue.eventTriggers}
              />
            ) : (
              <EventTriggers
                edit={edit}
                onEdit={onEdit}
                setEdit={setEdit}
                loading={loading}
                orderEvents={venue.eventTriggers}
              />
            )}
          </Route>

          <Route path={`/integrations/paymentEvents`} exact>
            {edit === "payment-events" ? (
              <VenuePaymentEventsForm
                onEdit={onEdit}
                actions={actions}
                setEdit={setEdit}
                loading={loading}
                onChange={(value) => onChange("paymentEvents", value)}
                data={venue.paymentEvents}
              />
            ) : (
              <PaymentEvents
                edit={edit}
                onEdit={onEdit}
                setEdit={setEdit}
                loading={loading}
                orderEvents={venue.paymentEvents}
              />
            )}
          </Route>

          <Route path={`/integrations/checkin`} exact>
            <CheckinListInt
              data={locations.list.data}
              onEdit={onEditLocation}
              actions={actions}
            />
          </Route>
          <Route path={`/integrations/orgs`}>
            <OrgIntegrations
              text={text}
              page={page}
              records_per_page={records_per_page}
              setText={setText}
              listLoading={integrations.loading}
              error={integrations.error}
              supplierName={supplierName}
              supplierType={supplierType}
              onUpdate={onUpdateOrg}
              onRemove={onRemoveOrgInt}
              onGetIntegration={onGetIntegration}
              onCreate={onCreate}
              loading={loadingOrg}
              onChange={onChangeOrgInt}
              load={loadOrgIntegrations}
              setOpen={(value) => setOpen(value)}
              open={open}
              type={type}
              setHitsPerPage={setHitsPerPage}
              setPage={setPage}
              setIntegration={setIntegration}
              setSupplierName={setSupplierName}
              setSupplierType={setSupplierType}
              setType={setType}
              integration={integration}
              integrations={integrations.list.data}
              total={integrations.list.total}
            />
          </Route>
        </TabNavBar>
      </div>
    </LayouWithSideBar>
  );
};

export default RIntegration;
