import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import UserList from "../../components/UserList";
import { RootState } from "../../store";
import { Redirect, RouteComponentProps, useLocation } from "react-router-dom";
import { roles as rolesMock } from "../../mocks/roles";
import { EmptyList } from "../../components/List/EmptyList";
import { Button } from "../../components/Button";
import { ContainerTitle } from "../../components/ContainerTitle";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { GridContainer } from "../../components/GridContainer/GridContainer";
import styles from "./styles.module.css";
import { message } from "antd";
import {
  deleteUser,
  registerUsers,
  sendResetUsername,
} from "../../services/users";
import APIError from "../../utils/APIError";
import { useTranslation } from "react-i18next";
import secureLocalStorage from "react-secure-storage";
import qs from "qs";
import {
  parseQueryParameter,
  removeEmptyStringFromObject,
} from "../../utils/order";
import { InputSelect } from "../../components/Inputs/InputSelect";
import { venueList } from "../../store/venues";
import { userTextSearchList } from "../../store/userTextSearch";
import SideFilterUsers from "../../components/SideFilters/SideFiltersUsers";
import { Filter } from "../../services/orders";
import CrossIcon from "../../components/Icons/CrossIcon";
import Search from "antd/es/input/Search";
import { BackLink } from "../../components/BackLink";
import { ButtonsContainer } from "../../components/ButtonsContainer";
import inviteIcon from "../../assets/invite.svg";
import { Role, roleList } from "../../services/roles";
import SaveFilterModal from "../../components/SaveFilterModal";
import { SavedData } from "../../utils/types";
import DeleteModal from "../../components/DeleteModal";
import InviteModal from "../../components/InviteModal";
import MissingModal from "../../components/MissingModal";

interface RouteParams {
  id: string;
}

interface Props extends RouteComponentProps<RouteParams> {}

const Users: React.FC<Props> = ({ history }) => {
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const users = useSelector((state: RootState) => state.userTextSearch);

  const venues = useSelector((state: RootState) => state.venues);
  const [sort, setSortBy] = useState("");
  const [resize, setSize] = useState(false);
  const [open, setOpen] = useState("");

  const [savedData, setSavedData] = useState<Record<string, SavedData>>({});

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  function useQuery() {
    const { search } = useLocation();
    return search;
  }

  const query = useQuery();
  const [search, setSearch] = useState(
    (qs.parse(query.substring(1)).search as string) || ""
  );
  const [page, setPage] = useState(
    (qs.parse(query.substring(1)).page as string) || users.list.page
  );

  const [records_per_page, setHitsPerPage] = useState(
    (qs.parse(query.substring(1)).records_per_page as string) || 50
  );
  // const { profile } = useSelector((state: RootState) => state.profile);

  const [venueName, setVenueName] = useState<Filter>(
    parseQueryParameter(query, "venueName")
  );

  const [text, setText] = useState(
    (qs.parse(query.substring(1)).search as string) || ""
  );

  const [savedFilter, setSavedFilter] = useState<string>("");

  const [roles, setRoles] = useState<Role[]>([]);

  const [tags, setTags] = useState<Filter>(parseQueryParameter(query, "tags"));
  const [deletedFilter, setDeletedFilter] = useState("");

  const [fulfillmentTeamName, setFulfillmentTeamName] = useState<Filter>(
    parseQueryParameter(query, "fulfillmentTeamName")
  );
  const [type, setType] = useState<Filter>(parseQueryParameter(query, "type"));
  const [selectedUsers, setSelectedUsers] = useState<
    {
      id: string;
      name: string;
      loginWithPhone: boolean;
      phone?: string;
      email?: string;
    }[]
  >([]);

  const [affected, setAffected] = useState<
    {
      id: string;
      name: string;
      loginWithPhone: boolean;
      phone?: string;
      email?: string;
    }[]
  >([]);
  const [venueId, setVenueId] = useState(
    (qs.parse(query.substring(1)).venueId as string) ||
      (secureLocalStorage.getItem("venueId") as string)
  );

  type StateMap = {
    [key: string]: [Filter, React.Dispatch<React.SetStateAction<Filter>>];
  };

  const stateMap: StateMap = {
    venueName: [venueName, setVenueName],
    tags: [tags, setTags],
    type: [type, setType],
    fulfillmentTeamName: [fulfillmentTeamName, setFulfillmentTeamName],
  };

  const loadVenues = useCallback(async () => {
    dispatch(venueList({ records_per_page: 50 }));
  }, [dispatch]);

  useEffect(() => {
    loadVenues();
  }, [loadVenues]);

  useEffect(() => {
    try {
      const data = localStorage.getItem("userSaveFilter");
      if (data) {
        setSavedData(JSON.parse(data) as Record<string, SavedData>);
      } else {
        setSavedData({});
      }
    } catch (error) {
      console.error("Error parsing orderSaveFilter from localStorage:", error);
      setSavedData({});
    }
  }, []);

  const loadRoles = useCallback(async () => {
    try {
      const res = await roleList({ records_per_page: 100, page: 0 });
      setRoles(res.data);
    } catch (err) {
      if (err instanceof APIError) {
        console.log(err.message);
      }
    }
  }, []);

  useEffect(() => {
    loadRoles();
  }, [loadRoles]);

  const load = useCallback(async () => {
    dispatch(
      userTextSearchList({
        page,
        records_per_page,
        venue_id: venueId,
        search: search,
        sort,
        tags,
        type,
        venueName,
        fulfillmentTeamName,
      })
    );
    const states = {
      page: page as string,
      venueId: venueId,
      search: search,
      records_per_page: records_per_page as string,

      fulfillmentTeamName:
        fulfillmentTeamName.include +
        "|" +
        fulfillmentTeamName.items.map((item) => item.key).join(","),
      venueName:
        venueName.include +
        "|" +
        venueName.items.map((item) => item.key).join(","),
      type: type.include + "|" + type.items.map((item) => item.key).join(","),
      tags: tags.include + "|" + tags.items.map((item) => item.key).join(","),
    };

    const params = new URLSearchParams(removeEmptyStringFromObject(states));
    history.replace(`/users?${params}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    page,
    records_per_page,
    venueId,
    search,
    sort,
    tags,
    type,
    venueName,
    fulfillmentTeamName,
  ]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    document.title = "RAVENT APP :: Users";
  });

  const onDelete = async (id: string) => {
    try {
      setLoading(true);

      await deleteUser(id);
      message.success(`${t("container.users.deleteUserMessage")}`);
      load();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onInviteUsers = async () => {
    try {
      setLoading(true);

      await registerUsers(selectedUsers);
      message.success(`${t("container.users.inviteSuccess")}`);
      setLoading(false);
      setOpen("");
    } catch (err) {
      setLoading(false);
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onResetPasswrod = async (userName: string) => {
    try {
      setLoading(true);

      await sendResetUsername(userName);
      message.success(`${t("container.users.resetSuccess")}`);
      setLoading(false);
      setOpen("");
    } catch (err) {
      setLoading(false);
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const selected: Filter[] = [];
  Object.values(stateMap).forEach(([filter]) => {
    selected.push(filter);
  });

  const hasNonEmptyState = Object.values(stateMap).some(
    ([filter]) => filter.items.length > 0
  );

  const handleTagClose = (facetGroup: string, option: string) => {
    const [currentState, setState] = stateMap[facetGroup];
    const itemToRemoveIndex = currentState.items.findIndex(
      (item) => item.key === option
    );
    if (itemToRemoveIndex !== -1) {
      setState({
        ...currentState,
        items: currentState.items.filter(
          (_item, index) => index !== itemToRemoveIndex
        ),
      });
    }
  };

  const filters = Object.keys(savedData)
    .map((key) => {
      const name = savedData[key].name;
      return name ? { label: name, value: name } : undefined;
    })
    .filter(
      (item): item is { label: string; value: string } => item !== undefined
    );

  function generateTag(label: Filter, _onClose: (label: string) => void) {
    if (!label || !label.items || label.items.length === 0) {
      return null;
    }
    return label.items.map((tagItem: { key: string }, index: number) => (
      <div
        className={styles.tag}
        key={index}
        style={{ margin: "0 0.3em 0.3em 0" }}
      >
        <div style={{ color: "#1890ff" }}>{tagItem.key.trim()}</div>
        <button
          className={styles.button}
          name="remove"
          onClick={() => handleTagClose(label.name, tagItem.key)}
        >
          <CrossIcon color={"#1890ff"} />
        </button>
      </div>
    ));
  }

  const closeAllTags = () => {
    Object.values(stateMap).forEach(([_filter, setFilter]) =>
      setFilter((prevFilter) => ({ ...prevFilter, items: [] }))
    );
  };

  // const options = [
  //   {
  //     id: "usersID",
  //     label: `${t("container.users.users")}`,
  //     path: `/users`,
  //     onClick: () => (document.title = "RAVENT APP :: Users"),
  //   },
  //   {
  //     id: "roles",
  //     label: t("container.users.roles"),
  //     path: `/users/roles`,
  //     onClick: () => (document.title = "RAVENT APP :: Users :: Roles"),
  //   },
  // ];

  if (!auth.loading && !auth.user) {
    return <Redirect from="*" to="/" />;
  }
  return (
    <LayouWithSideBar>
      <div>
        <MissingModal
          affected={affected}
          total={selectedUsers?.length}
          open={open === "missing"}
          onCancel={() => setOpen("")}
        />
        <InviteModal
          amount={selectedUsers?.length.toString()}
          onCancel={() => setOpen("")}
          onInvite={() => {
            const invalidUsers = selectedUsers.filter((user) =>
              user.loginWithPhone ? !user.phone : !user.email
            );
            if (invalidUsers.length > 0) {
              setAffected(invalidUsers);
              setOpen("missing");
            } else {
              onInviteUsers();
            }
          }}
          open={open === "invite"}
        />
        <DeleteModal
          value={`${t("container.events.warningDelete")} ${deletedFilter} ${t(
            "container.events.filter"
          )} `}
          open={open === "deleteFilter"}
          onCancel={() => setOpen("")}
          onDelete={() => {
            const saved = { ...savedData };
            delete saved[deletedFilter];
            setSavedData(saved);
            localStorage.setItem("userSaveFilter", JSON.stringify(saved));
            setDeletedFilter("");
            setOpen("");
          }}
        />
        <SaveFilterModal
          open={open === "save"}
          onSave={(value) => {
            const dataToStore = {
              filters: selected,
              name: value,
              search: search,
              type: search ? "query" : "normal",
            };
            const saved = {
              ...savedData,
              [value]: dataToStore,
            };
            setSavedData(saved);
            localStorage.setItem("userSaveFilter", JSON.stringify(saved));
          }}
          onClose={() => setOpen("")}
        />
        <GridContainer columns={resize ? "0.24fr 1fr" : "3em 1fr"}>
          <div
            style={{
              backgroundColor: "#fff",
              height: "100%",
              borderLeft: "1px solid #dedede",
            }}
          >
            <SideFilterUsers
              onDeleteFilter={(value) => {
                setOpen("deleteFilter");
                setDeletedFilter(value);
              }}
              filters={filters}
              setSize={setSize}
              selected={selected}
              facets={users.list.facets}
              shrink={resize}
              savedFilter={savedFilter}
              onSaveFilter={(value) => {
                setSavedFilter(value);

                const matchingObject = savedData[value];

                if (matchingObject && savedData[value].type === "query") {
                  setText(savedData[value].search || "");
                  setSearch(savedData[value].search || "");
                  return;
                }

                if (matchingObject && matchingObject.filters) {
                  matchingObject.filters.forEach((filter) => {
                    const stateAndSetter = stateMap[filter.name];
                    if (stateAndSetter) {
                      const setFilter = stateAndSetter[1];
                      setFilter((prevFilter) => ({
                        ...prevFilter,
                        items: filter.items,
                      }));
                    }
                  });
                }
              }}
              onChange={(facetGroup, option) => {
                setPage(0);
                const map = stateMap;
                if (map[facetGroup]) {
                  const [currentState, setState] = map[facetGroup];
                  const existingIndex = currentState.items.findIndex(
                    (item) => item.key === option
                  );
                  let updatedItems;
                  if (existingIndex !== -1) {
                    updatedItems = currentState.items.filter(
                      (item) => item.key !== option
                    );
                  } else {
                    updatedItems = [
                      ...currentState.items,
                      {
                        key: option,
                        include: currentState?.items.every(
                          (item) => item.include === true
                        ),
                      },
                    ];
                  }
                  setState({
                    ...currentState,
                    items: updatedItems,
                  });
                }
              }}
              onChangeExlusion={(facetGroup, value) => {
                const map = stateMap;
                const [currentState, setState] = map[facetGroup];
                if (currentState && currentState.items) {
                  setState((prevState) => ({
                    ...prevState,
                    items: prevState.items.map((item) => ({
                      ...item,
                      include: value, // Set all items to the same value
                    })),
                  }));
                }
              }}
              onChangeSelect={(facetGroup, value) => {
                const map = stateMap;
                const [currentState, setState] = map[facetGroup];
                if (currentState) {
                  setState({
                    ...currentState,
                    include: value as "Any" | "All",
                  });
                }
              }}
            />
          </div>
          <div className={styles.container}>
            <FlexContainer alignItems="center" justifyContent="space-between">
              <ContainerTitle
                size="medium"
                label={`${t("container.users.usersLabel")}`}
                count={users.list.count.toString()}
              />
              <FlexContainer justifyContent="space-between">
                <InputSelect
                  containerClassname={styles.inputSelect}
                  options={venues.list.data.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  value={venueId as string}
                  label={t("container.fulfillment.venue")}
                  showError={false}
                  showLabel={false}
                  onChange={(value) => {
                    setPage(0);
                    setVenueId(value);
                  }}
                />
                <div style={{ margin: "0 1em" }}>
                  <Button
                    label={`${t("container.users.createUser")}`}
                    onClick={() => history.push("/users/new")}
                  />
                </div>
                <div style={{ margin: "0 0 0 0em" }}>
                  <BackLink
                    noIcon
                    label={`${t("container.users.roles")}`}
                    onClick={() => history.push("/organization/roles")}
                  />
                </div>
              </FlexContainer>
            </FlexContainer>

            <div className={styles.searchContainer}>
              <FlexContainer alignItems="center">
                <Search
                  style={{ margin: "1em 0" }}
                  placeholder={t("component.orderFilters.search")}
                  onSearch={() => setSearch(text)}
                  value={text}
                  onChange={(text) => {
                    setText(text.target.value);
                  }}
                />
                {search ? (
                  <div style={{ marginLeft: "1em" }}>
                    <Button
                      size="small"
                      theme="white"
                      onClick={() => setOpen("save")}
                      label={t("container.orders.save")}
                    />
                  </div>
                ) : null}
              </FlexContainer>
            </div>
            {selectedUsers.length > 0 ? (
              <ButtonsContainer justifyContent="flex-start">
                <Button
                  theme="white"
                  size="medium"
                  onClick={() => setOpen("invite")}
                  label={t("container.users.invite")}
                  icon={inviteIcon}
                />

                {/* <Button
                  theme="white"
                  size="medium"
                  label={t("container.users.reset")}
                  icon={resetIcon}
                /> */}
              </ButtonsContainer>
            ) : null}

            <FlexContainer
              flexWrap="wrap"
              justifyContent="flex-start"
              padding="0 0 1em 0"
            >
              {venueName.items.filter((item) => item.key.trim()).length > 0 &&
                generateTag(venueName, (label) =>
                  setVenueName((prevFilter) => ({
                    ...prevFilter,
                    items: prevFilter.items.filter(
                      (item) => item.key !== label
                    ),
                  }))
                )}

              {tags.items.filter((item) => item.key.trim()).length > 0 &&
                generateTag(tags, (label) =>
                  setTags((prevFilter) => ({
                    ...prevFilter,
                    items: prevFilter.items.filter(
                      (item) => item.key !== label
                    ),
                  }))
                )}

              {type.items.filter((item) => item.key.trim()).length > 0 &&
                generateTag(type, (label) =>
                  setType((prevFilter) => ({
                    ...prevFilter,
                    items: prevFilter.items.filter(
                      (item) => item.key !== label
                    ),
                  }))
                )}

              {fulfillmentTeamName.items.filter((item) => item.key.trim())
                .length > 0 &&
                generateTag(fulfillmentTeamName, (label) =>
                  setFulfillmentTeamName((prevFilter) => ({
                    ...prevFilter,
                    items: prevFilter.items.filter(
                      (item) => item.key !== label
                    ),
                  }))
                )}
              {hasNonEmptyState && (
                <button
                  style={{
                    cursor: "pointer",
                    textDecorationLine: "underline",
                  }}
                  onClick={closeAllTags}
                >
                  Clear All
                </button>
              )}
            </FlexContainer>

            {users.list.hits.length && !users.loading ? (
              <UserList
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                loadingDelete={loading}
                onDelete={(id) => onDelete(id)}
                error={users.error}
                loading={users.loading}
                data={users.list.hits}
                roles={roles}
                page={parseInt(page as string)}
                total={users.list.count}
                hitsPerPage={parseInt(records_per_page as string)}
                onPageChange={setPage}
                onHitsPerPageChange={setHitsPerPage}
                pagination
                resetPassword={(username) => onResetPasswrod(username)}
                sortable
                permission={
                  // (profile &&
                  //   profile.roles.some((role) =>
                  //     role.includes("role:organization:owner")
                  //   )) ??
                  false
                }
                onChangeSortOrder={setSortBy}
              />
            ) : users.loading || users.error ? (
              <UserList
                resetPassword={(username) => onResetPasswrod(username)}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                permission={
                  // (profile &&
                  //   profile.roles.some((role) =>
                  //     role.includes("role:organization:owner")
                  //   )) ??
                  false
                }
                loadingDelete={loading}
                onDelete={(id) => onDelete(id)}
                error={users.error}
                loading={users.loading}
                data={users.list.hits}
                roles={rolesMock}
                page={parseInt(page as string)}
                total={users.list.count}
                hitsPerPage={parseInt(records_per_page as string)}
                onPageChange={setPage}
                onHitsPerPageChange={setHitsPerPage}
                onChangeSortOrder={setSortBy}
                sortable
                pagination
              />
            ) : (
              <div style={{ padding: "1em", background: "#fff" }}>
                <EmptyList
                  instructions=""
                  text={`${t("container.users.no")}`}
                />
              </div>
            )}
          </div>
        </GridContainer>
      </div>
    </LayouWithSideBar>
  );
};

export default Users;
