import React from "react";
import SetItem from "./SetItem";
import { UserListSkeleton } from "../ListSkeletons";
import styles from "./styles";
import { FOPSet } from "../../utils/types";
import { useTranslation } from "react-i18next";
import { PaginationRavent } from "../Pagination/Pagination";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { ListHeader } from "../List/ListHeader";
import { List } from "../List";

interface FOPSetProps {
  error: string;
  loading: boolean;
  data: FOPSet[];
  page: number;
  total: number;
  hitsPerPage: number;
  onPageChange?: (page: number) => void;
  onHitsPerPageChange?: (hitsPerPage: number) => void;
  onChangeSortOrder?: (order: number, sortBy: number) => void;
  sortable: boolean;
  pagination?: boolean;
  onDelete: (id: string) => void;
  loadingDelete: boolean;
}

const FOPSetList: React.FC<FOPSetProps> = ({
  error,
  loading,
  data,
  pagination,
  page,
  total,
  hitsPerPage,
  onHitsPerPageChange,
  onPageChange,
  onDelete,
  loadingDelete,
}) => {
  const { t } = useTranslation();

  const header = (
    <div>
      <ListHeader template="1fr 1fr 1fr 1fr">
        <ListHeaderLabel
          value={t("component.fopSetList.setName")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.fopSetList.fops")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel value="" justifyContent="flex-start" />
        <ListHeaderLabel value="" justifyContent="center" />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <List
        loading={loading}
        loadingElement={() => <UserListSkeleton />}
        error={error}
        data={data}
        keyExtractor={(item) => item.id}
        renderHeader={() => header}
        renderItem={(item) => (
          <SetItem
            key={item.id}
            loading={loadingDelete}
            data={item}
            onDelete={() => onDelete(item.id)}
          />
        )}
      />

      {pagination ? (
        <div className={styles.paginationContainer}>
          <PaginationRavent
            current={page + 1}
            defaultCurrent={page + 1}
            total={total}
            showSizeChanger
            onShowSizeChange={(page, size) => {
              if (onHitsPerPageChange) {
                onHitsPerPageChange(size);
              }
            }}
            onChange={(page) => {
              if (onPageChange) {
                onPageChange(page - 1);
              }
            }}
            pageSize={hitsPerPage}
          />
        </div>
      ) : null}
    </div>
  );
};

export default FOPSetList;
