import * as React from 'react';
import styles from './styles.module.css';
import { classes } from 'typestyle';
export interface InputSwitchProps {
  label?: string;
  onText?: string;
  offText?: string;
  showText?: boolean;
  error?: string;
  value?: boolean;
  showLabel?: boolean;
  showError?: boolean;
  padding?: string;
  onChange?: (value: boolean, e?: Event) => void;
  containerClassname?: string;
}

export const InputSwitch: React.FC<InputSwitchProps> = ({
  onText = 'Active',
  offText,
  showText,

  padding,
  value = false,
  onChange,
  containerClassname
}) => (
  <div className={classes(styles.container, containerClassname)} style={{ padding }}>
    <button
      className={styles.notch}
      style={{ background: value ? '#72bd03' : '#858d8f' }}
      onClick={() => onChange?.(!value)}
      type="button"
    >
      <div
        className={styles.circle}
        style={{
          transform: value ? 'translateX(calc(2em - 1em + 1px))' : 'translateX(0)',
          borderColor: value ? '#72bd03' : '#858d8f'
        }}
      />
    </button>
    {showText ? (
      <span className={styles.label} style={{ color: value ? '#72bd03' : '#858d8f' }}>
        {value ? onText : offText}
      </span>
    ) : null}
  </div>
);
