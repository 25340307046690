import React, { useState } from "react";

import logo from "../../assets/logo.svg";
import loginImage from "../../assets/loginImg.jpg";
import styles from "./styles";
import Text from "../../components/Text";
import { GridContainer } from "../../components/GridContainer/GridContainer";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { InputText } from "../../components/Inputs/InputText";
import { Button } from "../../components/Button";

const Workspace: React.FC = () => {
  const [orgName, setOrgName] = useState<string>("");

  return (
    <div className={styles.container}>
      <GridContainer
        columns="1fr 1fr"
        justifyContent="center"
        alignItems="center"
        gap="3em"
      >
        <div className={styles.imageContainer}>
          <img src={loginImage} alt="" className={styles.image} />
        </div>
        <div className={styles.inputsContainer}>
          <div>
            <img src={logo} alt="logo" className={styles.logo} />
            <div className={styles.subtitle}>Sign in to your workspace</div>
            <div className={styles.text}>
              Please enter the domain from your work.
            </div>
            <FlexContainer justifyContent="space-evenly" width="100%">
              <InputText
                containerClassname={styles.fieldContainer}
                label="Organization ID"
                placeholder="domain"
                value={orgName}
                onChange={(orgName) => setOrgName(orgName)}
              />
              <Text text=".ravent.com" />
            </FlexContainer>

            <Button width="100%" label="Continue" />

            <div className={styles.footer}>
              <div>
                © 2022 Ravent
                <span className={styles.footerText}>
                  Rights Reserved Version 0.1
                </span>
              </div>
            </div>
          </div>
        </div>
      </GridContainer>
    </div>
  );
};

export default Workspace;
