import React from "react";
import { formatDate } from "../../utils/times";
import styles from "./styles";
import { ListRow } from "../List/ListRow";

import {
  numberFormat,
  numberFormatMoreDecimals,
  numberFormatNoDecimals,
} from "../../utils/format";
import hereIcon from "../../assets/here.svg";
import oag from "../../assets/oag.svg";
import twilio from "../../assets/twilio.svg";
import { ListLabel } from "../List/ListLabel";

interface Props {
  data: {
    name: string;
    usingStartDate: string | Date;
    requests: number;
    costPerRequest: number;
  };
}

const Item: React.FC<Props> = ({ data }) => {
  return (
    <>
      <ListRow template="1fr 1fr 1fr 1fr 1fr" key={data.name}>
        <div className={styles.normal}>
          <img
            className={styles.iconBig}
            alt=""
            src={
              data.name === "HERE"
                ? hereIcon
                : data.name === "OAG"
                ? oag
                : twilio
            }
          />
          {data.name}
        </div>
        <ListLabel
          value={formatDate(data.usingStartDate, "MIDDLEWHOUR")}
          textAlign="left"
        />
        <ListLabel
          value={numberFormatNoDecimals(data.requests)}
          textAlign="right"
        />
        <ListLabel
          value={numberFormatMoreDecimals(data.costPerRequest)}
          textAlign="right"
          justifyContent="flex-end"
        />
        <ListLabel
          value={numberFormat(data.requests * data.costPerRequest)}
          textAlign="right"
          justifyContent="flex-end"
        />
      </ListRow>
    </>
  );
};

export default Item;
