import React from "react";
import { style } from "typestyle";
import { darkBlack } from "../../utils/colors";
import { FlexContainer } from "../FlexContainer/FlexContainer";

interface Props {
  label: string;
  value?: string;
  color?: string;
  children?: React.ReactNode | React.ReactNode[];
}

const labelStyle = style({
  fontSize: "1em",
  fontWeight: 700,
  color: darkBlack,
  marginLeft: "0.2em",
});

const text = style({
  fontSize: "1.1em",
  fontWeight: 500,
  color: darkBlack,
  marginLeft: "0.2em",
});

const redText = style({
  fontSize: "1.1em",
  fontWeight: 500,
  color: "#FF2929",
  marginLeft: "0.2em",
});

const InfoDetail: React.FC<Props> = ({ value, label, children, color }) => {
  return (
    <FlexContainer
      alignItems="flex-start"
      justifyContent="flex-start"
      flexDirection="column"
    >
      <p className={labelStyle}>{label}</p>
      {value ? (
        <p className={color === "red" ? redText : text}>{value}</p>
      ) : null}
      {children}
    </FlexContainer>
  );
};

export default InfoDetail;
