import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { venueList as venueListService, VenueListParams } from "../services/venues";
import { VenueSearchResponse } from "../services/venues";

type VenuesSliceState = {
  loading: boolean;
  error: string;
  list: VenueSearchResponse;
};

const initialState: VenuesSliceState = {
  loading: true,
  error: "",
  list: {
    page: 0,
    total: 0,
    data: [],
  },
};

export const venueList = createAsyncThunk(
  "venues/list",
  async (params: VenueListParams) => {
    const response = await venueListService(params);
    return response;
  }
);

const venuesSlice = createSlice({
  name: "venues",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(venueList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(venueList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.list = action.payload;
    });
    builder.addCase(venueList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An unexpected error ocurred";
    });
  },
});

export default venuesSlice.reducer;
