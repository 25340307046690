const translation = {
  en: {
    goBack: "Go Back",
    error: "Error",
    label: "Role",
    successCreation: "successfully updated",
    backToUsers: "Back to Roles",
    cancel: "Cancel",
    save: "Save",
    delete: "Delete",
    edit: "Edit",
    deleteMessage: "Are you sure you want to delete",
    details: "Details",
    permissions: "Permissions",
  },
  es: {
    permissions: "Permisos",
    goBack: "Regresar",
    error: "error",
    label: "Role",
    successCreation: "actualizado exitosamente",
    backToUsers: "Regresar a Roles",
    cancel: "Cancelar",
    save: "Guardar",
    delete: "Borrar",
    edit: "Editar",
    deleteMessage: "¿Está seguro de que quiere eliminar ?",
    details: "Detalles",
  },
  de: {},
};

export default translation;
