const translation = {
  en: {
    description: "Description",
    requestId: "Request Id",
    paymentId: "Payment Id",
    issuer: "Issuer",
    issuedDate: "Issued Date",
    amount: "Amount",
    status: "Status",
    actions: "Actions",
    invoiceDetails: "Invoice Details",
    invoiceId: "Invoice ID",
    invoiceData: "Invoice Data",
    issuerId: "Issuer ID",
    authority: "Authority",
    authorityId: "Authority ID",
    issueTimestamp: "Issued timestamp",
    files: "Files",
    uploadFiles: "Upload Files",
    selectOption: "Select Option",
    share: "Share Invoice",
    invoice: "Invoice",
    successDeleted: "Invoice cancelled",
    updateSuccess: "Invoice updated successfully",
  },
  es: {
    description: "Descripción",
    invoice: "Factura",
    requestId: "Solicitud de identificación",
    paymentId: "Id de pago",
    issuer: "Emisor",
    issuedDate: "Fecha de emisión",
    amount: "Cantidad",
    status: "Estado",
    actions: "Acciones",
    invoiceDetails: "Detalles de la factura",
    invoiceId: "ID de la factura",
    invoiceData: "Datos de la factura",
    issuerId: "ID del emisor",
    authority: "Autoridad",
    authorityId: "ID de la autoridad",
    issueTimestamp: "Marca de tiempo emitida",
    files: "Archivos",
    uploadFiles: "Subir archivos",
    selectOption: "Seleccionar opción",
    share: "Compartir Factura",
    successDeleted: "Factura cancelada éxistosamente",
    updateSuccess: "Factura actualizada con éxito",
  },
  de: {},
};

export default translation;
