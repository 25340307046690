import { format, utcToZonedTime } from "date-fns-tz";

export const lowerCase = (value: string) => {
  return value.replace(/\S*/g, function (word) {
    return word.charAt(0) + word.slice(1).toLowerCase();
  });
};

export const capitalize = (str: string) => {
  var string = str.replace("-", " ");
  return str.charAt(0).toUpperCase() + string.slice(1);
};

export const capitalizeEachWord = (sentence: string) => {
  const words = sentence.split(" ");
  words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");
};

export const numberFormat = (amount: string | number, country?: string) => {
  return parseFloat(amount as string).toLocaleString(
    localStorage.getItem("numberFormat") || "en-US",
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  );
};

export const numberFormatMoreDecimals = (
  amount: string | number,
  country?: string
) => {
  return parseFloat(amount as string).toLocaleString(
    localStorage.getItem("numberFormat") || "en-US",
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 3,
    }
  );
};

export const numberFormatNoFractions = (
  amount: string | number,
  country?: string
) => {
  return parseFloat(amount as string).toLocaleString(
    localStorage.getItem("numberFormat") || "en-US",
    {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }
  );
};

export const numberFormatNoDecimals = (
  amount: string | number,
  country?: string
) => {
  return parseFloat(amount as string).toLocaleString(
    localStorage.getItem("numberFormat") || "en-US",
    {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }
  );
};

export const dateFormat = (date: Date, country?: string) => {
  return date.toLocaleDateString(country);
};

export const trimString = (string: string, length: number) => {
  const trimed =
    string.length > length ? string.substring(0, length - 3) + "..." : string;
  return trimed;
};

export function maskString(str: string): string {
  if (str.length <= 3) {
    return "xxxx";
  }
  return str.substring(0, 3) + "xxxx";
}

export const formatDateTimeZone = (
  dateString: string,
  formatString: string,
  timezone: string
) => {
  const date = new Date(dateString);
  const zonedDate = utcToZonedTime(date, timezone);

  return format(zonedDate, formatString, { timeZone: timezone });
};
