import { stylesheet } from "typestyle";

const styles = stylesheet({
  filterContainer: {
    padding: "1em 0",
  },
  dropdownWidth: {
    width: "12em",
    marginRight: "1em",
  },
  dropdownWidthOffering: {
    width: "14em",
    marginRight: "1em",
  },
  buttonText: {
    textDecoration: "underline",
    cursor: "pointer",
    paddingTop: "1em",
    minWidth: "10%",
  },
  searchContainer: {
    width: "25%",
  },
  dateContainer: {
    paddingRight: "1em",
  },
  searchContainerText: {
    width: "70%",
  },
});

export default styles;
