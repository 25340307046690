import React, { useState } from "react";

import { useHistory } from "react-router-dom";
import LocationDetailsForm from "./LocationDetailsForm";
import OperationalHoursForm from "./OperationalHoursForm";
import PlacementForm from "./PlacementForm";
import LocationCheckinForm from "./LocationCheckinForm";
import { Location } from "@innomius/ravent-typescript-types";
import { Steps } from "antd";
import { SideMenu } from "../SideMenu/SideMenu";
import styles from "./styles";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { EditableTitle } from "../EditableTitle";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";
interface Props {
  data: Location;
  onChange: <P extends keyof Location>(prop: P, value: Location[P]) => void;
  path: string;
  onCreate: () => void;
  open: boolean;
  onClose: () => void;
  groups: { value: string; label: string }[];
  onChangeName: (name: string) => void;
  name: string;
}

const steps = [
  {
    title: "Placement",
  },
  {
    title: "Details",
  },
  {
    title: "Check-in",
  },
];

const LocationForm: React.FC<Props> = ({
  data,
  onChange,
  path,
  onCreate,
  open,
  onClose,
  groups,
  onChangeName,
  name
}) => {
  const history = useHistory();
  const [error, setError] = useState("");
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <SideMenu
      onClose={() => {
        history.push(`${path}`);
        onClose();
      }}
      open={open}
    >
      <FlexContainer justifyContent="space-between">
        <EditableTitle
          padding="1em 0 0 0"
          value={name}
          titleClassname={styles.min}
          placeholder="Location Name"
          onChange={(value) => {
            onChange("name", value);
            onChangeName(value);
          }}
        />
        {current === 2 ? (
          <ButtonsContainer>
            <Button
              theme="white"
              label="Back"
              onClick={() => {
                prev();
              }}
            />
            <Button
              theme="blue"
              label="Save"
              onClick={async () => {
                if (!data.checkinPeriodinMinutes) {
                  setError("checkin");
                }
                setError("");
                try {
                  await onCreate();
                  setCurrent(0);
                } catch (err) {
                  console.log(err);
                }
              }}
            />
          </ButtonsContainer>
        ) : current === 1 ? (
          <ButtonsContainer justifyContent="flex-end">
            <Button
              theme="white"
              label="Back"
              onClick={() => {
                prev();
              }}
            />
            <Button
              theme="blue"
              label="Continue"
              onClick={() => {
                next();
              }}
            />
          </ButtonsContainer>
        ) : (
          <Button
            theme="blue"
            label="Continue"
            onClick={() => {
              next();
              setError("");
            }}
          />
        )}
      </FlexContainer>

      <div style={{ margin: "1.2em 0 1.5em 0" }}>
        <Steps current={current} items={steps} />
      </div>

      {current === 2 ? (
        <LocationCheckinForm
          error={error}
          edit={false}
          onChange={onChange}
          data={data}
        />
      ) : current === 1 ? (
        <>
          <LocationDetailsForm error={error} data={data} onChange={onChange} />
          <OperationalHoursForm
            data={data.openingHours}
            onChange={(value) => onChange("openingHours", value)}
          />
        </>
      ) : (
        <PlacementForm
          groups={groups}
          onChangeLocation={onChange}
          data={data}
          placement={data.placement}
          onChange={(value) => onChange("placement", value)}
        />
      )}
    </SideMenu>
  );
};

export default LocationForm;
