import { User } from "@innomius/ravent-typescript-types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  userList as userListService,
  UserListParams,
  UserListResponse,
} from "../services/users";

type UsersSliceState = {
  loading: boolean;
  error: string;
  list: UserListResponse;
};

const initialState: UsersSliceState = {
  loading: true,
  error: "",
  list: {
    page: 0,
    total: 0,
    data: [],
  },
};

export const userList = createAsyncThunk(
  "users/list",
  async (params: UserListParams) => {
    const response = await userListService(params);
    return response;
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    addUser(state, action: PayloadAction<User>) {
      state.list.data.unshift(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(userList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.list = action.payload;
    });
    builder.addCase(userList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An unexpected error ocurred";
    });
  },
});

export const { addUser } = usersSlice.actions;

export default usersSlice.reducer;
