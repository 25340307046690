const translation = {
  en: {
    subtotal: "Subtotal",
    discounts: "Discounts",
    tax: "Tax",
    total: "Total",
    surcharge: "Surcharges",
    markAsDone: "Mark as fulfilled",
    warning: "Are you sure you want to mark as fulfilled?",
  },
  es: {
    subtotal: "Subtotal",
    discounts: "Descuentos",
    tax: "Impuestos",
    total: "Total",
    surcharge: "Recargos",
    markAsDone: "Marcar como completada",
    warning: "Desea marcar como completa esta porción de la orden ?",
  },
  de: {},
};

export default translation;
