import React from "react";
import HistoryItem from "./HistoryItem";
import { OrderListSkeleton } from "../../ListSkeletons";
import { formatDate } from "../../../utils/times";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { FulfillmentState } from "@innomius/ravent-typescript-types";
import { ListHeader } from "../../List/ListHeader";
import { ListHeaderLabel } from "../../List/ListHeaderLabel";
import { List } from "../../List";

interface Props {
  error: string;
  loading: boolean;
  data: FulfillmentState[];
  current: string;
}

const FulfillmentHistory: React.FC<Props> = ({
  error,
  loading,
  data,
  current,
}) => {
  const { t } = useTranslation();

  const sortedDescData = data?.sort(
    (objA, objB) =>
      Number(dayjs(objB.timestamp)) - Number(dayjs(objA.timestamp))
  );

  const header = (
    <div>
      <ListHeader template="0.7fr 1fr 1fr 1fr 1fr 0.3fr 0.3fr">
        <ListHeaderLabel
          value={t("component.orderFulfillment.status")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderFulfillment.timestamp")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderFulfillment.locationName")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderFulfillment.user")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderFulfillment.fulfillmentDefinition")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.orderFulfillment.files")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel value={""} justifyContent="flex-start" />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <List
        loading={loading}
        loadingElement={() => <OrderListSkeleton />}
        error={error}
        data={sortedDescData}
        keyExtractor={(item) => formatDate(item.timestamp, "UTC")}
        renderHeader={() => header}
        renderItem={(item) => (
          <HistoryItem
            key={item.name + item.timestamp}
            status={item.name}
            w3w={item.location?.w3w || ""}
            user={item.createdByFullName || ""}
            timestamp={item.timestamp}
            numberOfFiles={item.numberOfFiles}
            fulfillmentName={item.fulfillmentDefinitionId || "-"}
            current={current === item.name}
            id={item.id}
          />
        )}
      />
    </div>
  );
};

export default FulfillmentHistory;
