import React from "react";
import xmlIcon from "../../assets/XML.svg";
import pdfIcon from "../../assets/pdf.svg";
import zipIcon from "../../assets/ZIP.svg";
import pngIcon from "../../assets/PNG.svg";
import normal from "../../assets/DOC.svg";
import styles from "./styles";

import Text from "../Text";
import { GridContainer } from "../GridContainer/GridContainer";

interface Props {
  name: string;
  type: string;
  size: number;
}

const FileItem: React.FC<Props> = ({ type, name, size }) => {
  function formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }
  return (
    <div className={styles.borderTop}>
      <GridContainer gap="0.5em" columns="0.1fr 1fr 0.3fr">
        <img
          className={styles.icon}
          src={
            type === "application/xml"
              ? xmlIcon
              : type === "application/pdf"
              ? pdfIcon
              : type === "application/zip"
              ? zipIcon
              : type === "application/png"
              ? pngIcon
              : normal
          }
          alt=""
        />
        <Text color="#000000" text={name} fontWeight={700} />
        <Text color="#858D8F" text={formatBytes(size)} fontWeight={400} />
      </GridContainer>
    </div>
  );
};

export default FileItem;
