const translation = {
  en: {
    type: "Type",
    legalName: "Name",
    email: "Email",
    phone: "Phone",
    enabled: "Enabled",
    edit: "Edit",
    fulfillers: "Fulfillers",
    location: "Location",
    send: "Send",
    internal: "Internal",
    close: "Close",
    how: "This message will be send to the entire team",
    titleSend: "Send Message to Fulfillers",
    venue: "Venue",
    sendMessage: "Send Message",
    userCount: "Users Count",
  },
  es: {
    internal: "Interno",
    userCount: "Usuarios",
    send: "enviar",
    close: "Cerrar",
    how: "Este mensaje se enviará a todo el equipo",
    titleSend: "Enviar mensaje a cumplimentadores",
    type: "Tipo",
    legalName: "Nombre",
    email: "Correo electrónico",
    phone: "Teléfono",
    enabled: "Habilitado",
    edit: "Editar",
    fulfillers: "Fulfillers",
    location: "Lugar",
    venue: "Recinto",
    sendMessage: "Mandar mensaje",
  },

  de: {},
};

export default translation;
