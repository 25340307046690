const translation = {
  en: {
    newOffer: "Create New Offer Set",
    save: "Save",
    cancel: "Cancel",
    backButton: "Back to Offer Sets",
    placeHolder: "Offer Set Name",
  },
  es: {
    newOffer: "Crear nuevo conjunto de ofertas",
    save: "Guardar",
    cancel: "Cancelar",
    backButton: "Regresar a Conjunto de Ofertas",
    placeHolder: "Nombre de Conjunto de Oferta",
  },
  de: {},
};

export default translation;
