import React from "react";
import chevronLeft from "../../assets/chevron-left.svg";
import styles from "./styles.module.css";

export interface Props {
  label: string;
  onClick: () => void;
  noIcon?: boolean;
}

export const BackLink: React.FC<Props> = ({ label, onClick, noIcon }) => {
  return (
    <div className={styles.container} onClick={onClick}>
      {noIcon ? null : (
        <img src={chevronLeft} alt="" className={styles.icon} />
      )}
      <div className={styles.label}>{label}</div>
    </div>
  );
};
