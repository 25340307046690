import { stylesheet } from "typestyle";
import { darkBlack } from "../../utils/colors";

const styles = stylesheet({
  container: {
    minHeight: "100vh",
  },
  wrapperCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "2em",
    marginBottom: "1em",
  },
  title: {
    color: darkBlack,
    fontWeight: "bold",
    fontSize: "1.1em",
  },
  amount: {
    color: darkBlack,
    fontWeight: "bold",
    fontSize: "1.3em",
  },
  paymentWrapper: {
    padding: "2em",
    marginBottom: "1em",
  },
  deliveryWrapper: {
    padding: "2em",
  },
  tagsWrapper: {
    padding: "2em",
  },
  tagsContainer: {
    marginTop: "1em",
  },
  itemsWrapper: {
    padding: "1em",
  },
  itemsWrapperDetail: {
    padding: "0em 2em 2em 2em",
  },
  refundsWrapper: {
    padding: "2em 0",
  },
  buttonsContainer: {
    marginRight: "1.1em",
  },
  chevron: {
    width: "36px",
    cursor: "pointer",
  },
  buttonIssue: {
    minWidth: "10em !important",
  },
});

export default styles;
