const translation = {
  en: {
    setPasswordLabel: "Set Password",
    instructions: "Please enter your new password.",
    password: "Password",
    confirm: "Confirm Password",
    rightsReserved: "2022 RAVENT • version 0.1.120 • All rights reserved ",
  },
  es: {
    setPasswordLabel: "Establecer contraseña",
    instructions: "Por favor ingrese su nueva contraseña.",
    password: "Contraseña",
    confirm: "Confirmar Contraseña",
    rightsReserved: "2022 RAVENT • version 0.1.120 • Derechos Reservados ",
  },
  de: {},
};

export default translation;
