const translation = {
  en: {
    details: "Details",
    orders: "Orders",
    events: "Events",
    assets: "Assets",
    locations: "Locations",
    users: "Users",
    edit: "Edit",
    description: "Drag and drop to sort and edit the table",
    addTag: "Add Tag",
    cancel: "Cancel",
    save: "Save",
    color: "Color",
    categoryName: "Category Name",

  },
  es: {
    color: "Color",
    categoryName: "Nombre de la Categoría",
    cancel: "Cancelar",
    save: "Guardar",
    addTag: "Agregar Etiqueta",
    edit: "Editar",
    description: "Arrastre y suelte para ordenar y editar la tabla",
    details: "Detalles",
    orders: "Órdenes",
    events: "Eventos",
    assets: "Activos",
    locations: "Ubicaciones",
    users: "Usuarios",
  },
  de: {},
};

export default translation;
