import * as React from 'react';
interface Props {
  color: string;
}

export const CrossIcon: React.FC<Props> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="0.7em"
      height="0.7em"
      viewBox="0 0 20 20"
    >
      <defs>
        <path
          id="prefix__a"
          d="M11.474 9.722l7.485-7.485A1.238 1.238 0 1017.207.485L9.722 7.97 2.237.485a1.237 1.237 0 00-1.752 0 1.237 1.237 0 000 1.752L7.97 9.722.485 17.207a1.238 1.238 0 00.877 2.116c.316 0 .634-.12.875-.363l7.485-7.485 7.485 7.485c.242.243.559.363.876.363a1.238 1.238 0 00.876-2.115l-7.485-7.485z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <g mask="url(#prefix__b)">
          <path fill={color} fillRule="nonzero" d="M-4.8-4.8h39.2v39.2H-4.8z" />
        </g>
      </g>
    </svg>
  );
};

export default CrossIcon;
