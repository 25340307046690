import * as React from 'react';
import styles from './styles.module.css';

export interface Props {
  gap?: string;
  columns?: string;
  padding?: string | number;
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-evenly' | 'space-around ';
  alignItems?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
  children?: React.ReactNode[] | React.ReactNode;
}

export const GridContainer: React.FC<Props> = ({
  children,
  padding,
  gap = '0',
  columns = '1fr',
  justifyContent = 'flex-start',
  alignItems = 'flex-start'
}) => (
  <div
    className={styles.gridContainer}
    style={{
      display: 'grid',
      width: '100%',
      gap: gap || '0em',
      gridTemplateColumns: columns,
      padding,
      justifyContent,
      alignItems
    }}
  >
    {children}
  </div>
);
