import {
  AddressType,
  Certificate,
  FulfillmentDefinition,
  Ticket,
  TicketingState,
} from "@innomius/ravent-typescript-types";
import { get } from "lodash";
import fetch from "../utils/fetch";
import { getAccessToken } from "./auth";
import { HistoryPass, Pass } from "../utils/types";

export function parseTicket(data: unknown): Ticket {
  return {
    id: get(data, "_id", ""),
    type: get(data, "type", []),
    organizationId: get(data, "organizationId", ""),
    orderLineId: get(data, "orderLineId", ""),
    activationDateTime: get(data, "actfrivationDateTime", ""),
    checkins: get(data, "checkins", []),
    checkouts: get(data, "checkouts", []),
    fulfillment: get(data, "fulfillment", {} as FulfillmentDefinition),
    fulfillmentType: get(
      data,
      "fulfillmentType",
      "" as "service" | "locker" | "delivery" | "access" | "digital" | "pickup"
    ),
    state: get(data, "state", "" as TicketingState),
    remainingCheckins: get(data, "remainingCheckins", 0),
    created: get(data, "created", ""),
    updated: get(data, "updated", ""),
    deleted: get(data, "deleted", false),
    createdAt: get(data, "createdAt", ""),
    customerName: get(data, "customerName", ""),
    fulfillmentLocation: get(data, "fulfillmentLocation", {} as AddressType),
    validFrom: get(data, "validFrom", ""),
    validUntil: get(data, "validUntil", ""),
    activationType: get(
      data,
      "activationType",
      "" as "at-issuance" | "at-date-time" | "at-first-use"
    ),
    maxEvents: get(data, "maxEvents", 0),
    lifeTime: {
      amount: get(data, "lifeTime.amount", 0),
      type: get(data, "lifeTime.type", "" as "duration" | "end-of"),
      unit: get(data, "lifeTime.unit", "" as "month" | "year" | "day" | "hour"),
    },
    description: get(data, "description", ""),
    modifiers: get(data, "modifiers", ""),
    customerNameRequired: get(data, "customerNameRequired", false),
    properties: get(data, "properties", []),
  };
}

export function parseHistory(data: unknown): HistoryPass {
  return {
    action: get(data, "action", ""),
    userName: get(data, "userName", ""),
    userId: get(data, "userId", ""),
    timestamp: get(data, "timestamp", ""),
  };
}

export function parsePass(data: unknown): Pass {
  return {
    _id: get(data, "_id", ""),
    organization_id: get(data, "organization_id", ""),
    order_id: get(data, "order_id", ""),
    orderline_id: get(data, "orderline_id", ""),
    orderlineName: get(data, "orderlineName", ""),
    orderlineShortId: get(data, "orderlineShortId", ""),
    orderName: get(data, "orderName", ""),
    orderShortId: get(data, "orderShortId", ""),
    fulfillmentEventName: get(data, "fulfillmentEventName", ""),
    fulfillmentEventShortId: get(data, "fulfillmentEventShortId", ""),
    loadName: get(data, "loadName", ""),
    load_id: get(data, "load_id", ""),
    fulfillmentEvent_id: get(data, "fulfillmentEvent_id", ""),
    displayName: get(data, "displayName", ""),
    shortId: get(data, "shortId", ""),
    history: get(data, "history", []).map(parseHistory),
    ownership_level: get(data, "ownership_level", ""),
    state: get(data, "state", ""),
    data: {
      external_id: get(data, "data.external_id", ""),
    },
    deleted: get(data, "deleted", false),
    createdAt: get(data, "createdAt", ""),
    updatedAt: get(data, "updatedAt", ""),
  };
}

export function parseCertificate(data: unknown): Certificate {
  return {
    id: get(data, "_id", ""),
    organizationId: get(data, "organizationId", ""),
    type: get(data, "type", ""),
    orderId: get(data, "orderId", ""),
    status: get(data, "status", ""),
    activationDateTime: get(data, "actfrivationDateTime", ""),
    remainingCheckins: get(data, "remainingCheckins", 0),
    issuedDate: get(data, "issuedDate", ""),
    customerName: get(data, "customerName", ""),
    validFrom: get(data, "validFrom", ""),
    validUntil: get(data, "validUntil", ""),
    activationType: get(
      data,
      "activationType",
      "" as "at-issuance" | "at-date-time" | "at-first-use"
    ),
    maxEvents: get(data, "maxEvents", 0),
    lifeTime: {
      amount: get(data, "lifeTime.amount", 0),
      type: get(data, "lifeTime.type", "" as "duration" | "end-of"),
      unit: get(data, "lifeTime.unit", "" as "month" | "year" | "day" | "hour"),
    },
    description: get(data, "description", ""),
    customerNameRequired: get(data, "customerNameRequired", false),
  };
}

export async function ticketList(orderId: string): Promise<Ticket[]> {
  const token = await getAccessToken();
  const res = await fetch("/tickets", token);
  return res.body.hits.map(parseTicket);
}

export async function ticketRead(id: string): Promise<Ticket> {
  const token = await getAccessToken();
  const res = await fetch(`/tickets/${id}`, token);
  const data = parseTicket(res.body);
  return data;
}

export async function getEventPasses(eventId: string): Promise<Pass[]> {
  const token = await getAccessToken();
  const res = await fetch(
    `/ticketing/related-to-fulfillment-event/${eventId}`,
    token
  );
  return res.body.hits.map(parsePass);
}

export async function passList(orderId: string): Promise<Pass[]> {
  const token = await getAccessToken();
  const res = await fetch(`/passes/listByOrder/${orderId}`, token);
  return res.body.passes.map(parsePass);
}

export async function passListEvents(eventId: string): Promise<Pass[]> {
  const token = await getAccessToken();
  const res = await fetch(`/passes/listByEvent/${eventId}`, token);
  return res.body.passes.map(parsePass);
}

export async function readPass(id: string): Promise<Pass> {
  const token = await getAccessToken();
  const res = await fetch(`/passes/${id}`, token);
  return parsePass(res.body);
}
