import React from "react";

import { OfferListSkeleton } from "../ListSkeletons";
import { OfferSet } from "@innomius/ravent-typescript-types";
import OfferSetListItem from "./OfferSetListItem";
import styles from "./styles";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { ListHeader } from "../List/ListHeader";
import { List } from "../List";
import { PaginationRavent } from "../Pagination/Pagination";

interface OfferSetListProps {
  error: string;
  loading: boolean;
  data: OfferSet[];
  onChangeSort?: (order: number, sortBy: number) => void;
  page: number;
  total: number;
  hitsPerPage: number;
  onPageChange?: (page: number) => void;
  onHitsPerPageChange?: (hitsPerPage: number) => void;
  sortable: boolean;
  pagination?: boolean;
}

const OfferSetListComponent: React.FC<OfferSetListProps> = ({
  error,
  loading,
  data,
  pagination,
  page,
  total,
  hitsPerPage,
  onHitsPerPageChange,
  onPageChange,
}) => {
  const header = (
    <div>
      <ListHeader template="2fr 1fr 1fr 1.5fr">
        <ListHeaderLabel value="Offer Set Name" justifyContent="flex-start" />
        <ListHeaderLabel value="Offers" justifyContent="center" />
        <ListHeaderLabel value="Status" justifyContent="center" />
        <ListHeaderLabel value="" justifyContent="center" />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <div className={styles.listWrapper}>
        <List
          loading={loading}
          loadingElement={() => <OfferListSkeleton />}
          error={error}
          data={data}
          keyExtractor={(item) => item.id}
          renderHeader={() => header}
          renderItem={(item) => <OfferSetListItem data={item} key={item.id} />}
        />
      </div>
      {pagination ? (
        <div className={styles.paginationContainer}>
          <PaginationRavent
            current={page + 1}
            defaultCurrent={page + 1}
            total={total}
            showSizeChanger
            onShowSizeChange={(page, size) => {
              if (onHitsPerPageChange) {
                onHitsPerPageChange(size);
              }
            }}
            onChange={(page) => {
              if (onPageChange) {
                onPageChange(page - 1);
              }
            }}
            pageSize={hitsPerPage}
          />
        </div>
      ) : null}
    </div>
  );
};

export default OfferSetListComponent;
