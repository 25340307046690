const translation = {
  en: {
    cancel: "Cancel",
    save: "Save",

    assetType: "AssetType",
    notification: "Assign Asset to Event",
    assets: "Assets",
    asset: "Asset",
  },
  es: {
    cancel: "Cancelar",
    save: "Guardar",
    asset: "Activo",

    assetType: "Tipo de Activo",
    notification: "Asignar Activo a Evento",
    assets: "Activos",
  },
  de: {},
};

export default translation;
