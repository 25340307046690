import React from "react";

import styles from "./styles";
import { useTranslation } from "react-i18next";
import { Role } from "../../services/roles";
import { Card, Radio } from "antd";
import org from "../../assets/roles/organization.svg";
import venueIcon from "../../assets/roles/venues.svg";
import fulfillerIcon from "../../assets/roles/fulfiller.svg";
import teamIcon from "../../assets/roles/teams.svg";
// import info from "../../assets/info.svg";
import Text from "../Text";
import { ContainerTitle } from "../ContainerTitle";
import { GridContainer } from "../GridContainer/GridContainer";
import { InputText } from "../Inputs/InputText";

interface Props {
  data: Role;
  onChange: <P extends keyof Role>(prop: P, value: Role[P]) => void;
}

const RoleForm: React.FC<Props> = ({ data, onChange }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Card>
        <div style={{ paddingBottom: "1em" }}>
          <ContainerTitle
            size="small"
            padding="0 0 1em 0"
            label={t("component.roleForm.details")}
          />
          <GridContainer gap="1em" columns="1fr 1fr">
            <InputText
              label={t("component.roleForm.description")}
              value={data.description}
              onChange={(value) => onChange("description", value)}
            />
          </GridContainer>
          <ContainerTitle
            size="small"
            padding="0 0 0.5em 0"
            label={t("component.roleDetailComponent.scope")}
          />
          <Text
            fontSize="1em"
            padding="0 0 1.5em 0"
            text={t("component.roleForm.scopeInfo")}
          />

          <Radio.Group
            className={styles.width}
            value={data.scope}
            onChange={(value) => onChange("scope", value.target.value)}
          >
            <Card style={{ marginBottom: "1em" }}>
              <GridContainer
                alignItems="center"
                justifyContent="center"
                gap="1em"
                columns="0.3fr 3fr 0.1fr"
              >
                <img src={org} alt="" className={styles.image} />
                <div>
                  <Text
                    fontWeight={600}
                    text={t("component.roleForm.organization")}
                  />
                  <Text
                    text={t("component.roleForm.organizationDescription")}
                  />
                </div>
                <Radio value="organization" />
              </GridContainer>
            </Card>
            <Card style={{ marginBottom: "1em" }}>
              <GridContainer
                alignItems="center"
                justifyContent="center"
                gap="1em"
                columns="0.3fr 3fr 0.1fr"
              >
                <img src={venueIcon} alt="" className={styles.image} />
                <div>
                  <Text fontWeight={600} text={t("component.roleForm.venue")} />
                  <Text text={t("component.roleForm.venueDescription")} />
                </div>
                <Radio value="venue" />
              </GridContainer>
            </Card>
            <Card style={{ marginBottom: "1em" }}>
              <GridContainer
                alignItems="center"
                justifyContent="center"
                gap="1em"
                columns="0.3fr 3fr 0.1fr"
              >
                <img src={teamIcon} alt="" className={styles.image} />
                <div>
                  <Text
                    fontWeight={600}
                    text={t("component.roleForm.fulfillmentTeam")}
                  />
                  <Text
                    text={t("component.roleForm.fulfillmentTeamDescription")}
                  />
                </div>

                <Radio value="team" />
              </GridContainer>
            </Card>

            <Card style={{ marginBottom: "1em" }}>
              <GridContainer
                alignItems="center"
                justifyContent="center"
                gap="1em"
                columns="0.3fr 3fr 0.1fr"
              >
                <img src={fulfillerIcon} alt="" className={styles.image} />
                <div>
                  <Text
                    fontWeight={600}
                    text={t("component.roleForm.fulfiller")}
                  />
                  <Text text={t("component.roleForm.fulfillerDescription")} />
                </div>
                <Radio value="fulfiller" />
              </GridContainer>
            </Card>
          </Radio.Group>
        </div>
      </Card>
    </div>
  );
};

export default RoleForm;
