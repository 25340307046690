
import {
  Action,
  FulfillmentDefinition,
  FulfillmentStateDef,
} from "@innomius/ravent-typescript-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FulfillmentSearchResponse } from "../../services/fulfilments";
import CustomError from "../../utils/CustomError";
import CreateFulfillment from "../CreateFulfillment";
import FulfillmentList from "../FulfillmentList";
import styles from "./styles.module.css";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { Button } from "../Button";
import { ContainerTitle } from "../ContainerTitle";
import { EmptyList } from "../List/EmptyList";
import { Card } from "../Card/Card";

interface Props {
  fulfillments: FulfillmentSearchResponse;
  error: string;
  loading: boolean;
  page: number;
  total: number;
  hitsPerPage: number;
  setPage?: (page: number) => void;
  setHitsPerPage?: (hitsPerPage: number) => void;
  recordsPerPage: number;
  setModal: (value: string) => void;
  open: string;
  venueId: string;
  fulfillment: FulfillmentDefinition;
  onChangeFulfillment: <P extends keyof FulfillmentDefinition>(
    prop: P,
    value: FulfillmentDefinition[P]
  ) => void;
  onCreate: () => void;
  loadingF: boolean;
  validateError: CustomError | undefined;
  onEditFulfillmentDefinition: (data: FulfillmentDefinition) => void;
  onAddState: (
    fulfillment: FulfillmentDefinition,
    data: FulfillmentStateDef,
    edit: boolean,
    index?: number
  ) => void;
  onDeleteFulfillmentState: (id: string) => void;
  load: () => void;
  path: string;
  openModal: boolean;
  setOpen: (value: boolean) => void;
  actions: Action[];
  onDelete: (id: string) => void;
}

const VenueFulfillmentStates: React.FC<Props> = ({
  fulfillments,
  error,
  loadingF,
  loading,
  page,
  setHitsPerPage,
  setPage,
  recordsPerPage,
  setModal,
  fulfillment,
  onChangeFulfillment,
  onCreate,
  validateError,
  path,
  onEditFulfillmentDefinition,
  load,
  onAddState,
  onDeleteFulfillmentState,
  openModal,
  setOpen,
  actions,
  onDelete,
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  return (
    <div>
      <Card>
        <div className={styles.routeContainer}>
          <FlexContainer
            padding="0 0 2em 0"
            justifyContent="space-between"
            alignItems="center"
          >
            <ContainerTitle
              size="small"
              label={t("component.venueFulfillmentStates.label")}
              count={fulfillments.total.toString()}
            />
            <Button
              label={t("component.venueFulfillmentStates.createNew")}
              onClick={() => {
                setModal("create-fulfillment");
                history.push(`/fulfillments/fulfillmentStates/new`);
              }}
            />
          </FlexContainer>

          {fulfillments.data.length < 1 && !loading && !error ? (
            <div className={styles.wrapperCard}>
              <EmptyList
                instructions={""}
                text={t("component.venueFulfillmentStates.empty")}
              />
            </div>
          ) : (
            <FulfillmentList
              actions={actions}
              onAddState={onAddState}
              onDeleteFulfillmentState={onDeleteFulfillmentState}
              onEditFulfillmentDefinition={onEditFulfillmentDefinition}
              load={load}
              sortable
              onDelete={onDelete}
              error={error}
              loading={loading}
              data={fulfillments.data}
              page={page}
              total={fulfillments.total}
              hitsPerPage={recordsPerPage}
              onPageChange={setPage}
              onHitsPerPageChange={setHitsPerPage}
              pagination
              path={path}
              open={openModal}
              setOpen={setOpen}
            />
          )}
        </div>
      </Card>
      <CreateFulfillment
        fulfillment={fulfillment}
        path={`/fulfillments`}
        onChangeFulfillment={onChangeFulfillment}
        onCreate={() => onCreate()}
        onClose={() => setModal("")}
        loadingF={loadingF}
        validateError={validateError}
      />
    </div>
  );
};

export default VenueFulfillmentStates;
