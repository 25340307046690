import React from "react";
import { ColorPicker } from "antd";
import { Category } from "../../utils/types";
import { ListRow } from "../List/ListRow";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { ListLabel } from "../List/ListLabel";

interface Props {
  data: Category[];
  onChange: (index: number, newData: Partial<Category>) => void;
}

const EditTags: React.FC<Props> = ({ data, onChange }) => {
  return (
    <>
      {data.map((property, index) => (
        <div style={{ marginBottom: "1em" }} key={`property-${index}`}>
          <ListRow
            hover={false}
            key={property.chronologicalIndex}
            template="1fr 2fr 0.5fr"
          >
            <FlexContainer justifyContent="flex-start" padding="0.5em">
              <ColorPicker
                value={property.color}
                onChange={(value) =>
                  onChange(index, { color: `#${value.toHex()}` })
                }
              />
            </FlexContainer>
            <ListLabel value={property.name} textAlign="left" />
          </ListRow>
        </div>
      ))}
    </>
  );
};

export default EditTags;
