import React, { useCallback, useEffect, useState } from "react";
import {
  Route,
  RouteComponentProps,
  Switch,
  useLocation,
} from "react-router-dom";
import CrossIcon from "../../components/Icons/CrossIcon";
import LayouWithSideBar from "../../components/LayoutWithSideBar";
import {
  Location,
  Message,
  Placement,
  Venue,
} from "@innomius/ravent-typescript-types";
import TabNavBar from "../../components/TabNavBar";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { ContainerTitle } from "../../components/ContainerTitle";

import { venueList } from "../../store/venues";
import {
  createLocation,
  defaultLocation,
  deleteLocation,
  deleteMultipleLocations,
  duplicateList,
  getVenue,
  missingList,
  updateLocation,
  uploadLocationFile,
} from "../../services/venues";
import { messageList } from "../../services/visitors";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { visitorList } from "../../store/visitors";
import styles from "./styles.module.css";
import { Loading } from "../../components/Loading";
import message from "antd/lib/message";
import { fopSetsList } from "../../store/fopSets";
import APIError from "../../utils/APIError";
import { useTranslation } from "react-i18next";
import VenueLocations from "../../components/VenueLocations";
import qs from "qs";
import {
  parseQueryParameter,
  removeEmptyStringFromObject,
} from "../../utils/order";
import ExportCVSFile from "../../components/ExportCVSFile";
import DeleteLocationsModal from "../../components/DeleteLocationsModal";
import { locationTextSearchList } from "../../store/locationsTextSearch";
import Duplicate from "../../components/VenueLocations/Duplicate";
import { DuplicateLocation, MissingLocation } from "../../utils/types";
import Missing from "../../components/VenueLocations/Missing";
import CVSFUploader from "../../components/CVSFUploader";
import secureLocalStorage from "react-secure-storage";
import { InputSelect } from "../../components/Inputs/InputSelect";
import SideFiltersLocations from "../../components/SideFilters/SideFiltersLocations";
import { Filter } from "../../services/orders";
import { numberFormatNoDecimals } from "../../utils/format";
import { exportMultipleLocationsCVS } from "../../services/export";
import { GridContainer } from "../../components/GridContainer/GridContainer";
import Search from "antd/es/input/Search";

interface RouteParams {
  id: string;
}

interface Props extends RouteComponentProps<RouteParams> {}

const inputString =
  "_id;name;organization_id;venue_id;externalId;enabled;type;capacity;currentVisitors;publicCheckin;checkinPeriodInMinutes;placement.type;placement.fullAddress;placement.line1;placement.coordinates.latitude;placement.coordinates.longitude;placement.fullAddressVerified;group;groupCode;createdBy;deleted;createdAt;updatedAt;__v;placement.w3w;pointOfContact;placement.reference;description;checkinFlowUrl;openingHours;phone;email;placement.line2;placement.country;placement.state;placement.city;placement.zipCode";

const Locations: React.FC<Props> = ({ history, location }) => {
  const [venue, setVenue] = useState<Venue | null>();
  function useQuery() {
    const { search } = useLocation();
    return search;
  }
  const venues = useSelector((state: RootState) => state.venues);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const [open, setModal] = useState("");
  const dispatch = useDispatch();
  const locations = useSelector((state: RootState) => state.locationTextSearch);
  const [venueLocation, setLocation] = useState<Location>(defaultLocation);
  const query = useQuery();
  const [sort, setSortBy] = useState("");

  const [messages, setMessages] = useState<Message[]>([]);
  const { t } = useTranslation();
  // const visitors = useSelector((state: RootState) => state.visitors);
  const [hitsPerPage, setHitsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [hitsPerPageM, setHitsPerPageM] = useState(10);
  const [pageM, setPageM] = useState(0);
  const [count, setCount] = useState(0);

  const [hitsPerPageD, setHitsPerPageD] = useState(10);
  const [pageD, setPageD] = useState(0);
  const [countD, setCountD] = useState(0);

  const [selectedGroup, setSelectedGroup] = useState(
    (qs.parse(query.substring(1)).group as string) || ""
  );
  const [view, setView] = useState<string>(
    (qs.parse(query.substring(1)).view as string) || "list"
  );
  const [venueId, setVenueId] = useState(
    (qs.parse(query.substring(1)).venueId as string) ||
      (secureLocalStorage.getItem("venueId") as string)
  );

  const [duplicates, setDuplicates] = useState<DuplicateLocation[]>([]);
  const [loadingD, setLoadingD] = useState(false);
  const [errorD, setErrorD] = useState("");

  const [missingL, setMissing] = useState<MissingLocation[]>([]);
  const [loadingM, setLoadingM] = useState(false);
  const [errorM, setErrorM] = useState("");
  const [searchM, setSearchM] = useState("");

  const [selectedLocations, setSelectedLocations] = useState<
    { id: string; name: string }[]
  >([]);
  const [resize, setSize] = useState(false);

  const [group, setGroup] = useState<Filter>(
    parseQueryParameter(query, "group")
  );
  const [type, setType] = useState<Filter>(parseQueryParameter(query, "type"));
  const [groupCode, setGroupCode] = useState<Filter>(
    parseQueryParameter(query, "groupCode")
  );

  const [search, setSearch] = useState(
    (qs.parse(query.substring(1)).search as string) || ""
  );

  const [projection, setProjection] = useState<string[]>([]);

  const [searchD, setSearchD] = useState("");

  type StateMap = {
    [key: string]: [Filter, React.Dispatch<React.SetStateAction<Filter>>];
  };

  const stateMap: StateMap = {
    group: [group, setGroup],
    type: [type, setType],
    groupCode: [groupCode, setGroupCode],
  };

  const loadVenues = useCallback(async () => {
    dispatch(venueList({}));
  }, [dispatch]);

  useEffect(() => {
    loadVenues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const load = useCallback(async () => {
    dispatch(
      locationTextSearchList({
        venueId: venueId || "",
        records_per_page: hitsPerPage,
        page: page,
        group: group,
        view: view,
        count: locations.locations.count,
        search: search,
        sort: sort,
        groupCode: groupCode,
        type: type,
      })
    );

    const states = {
      search: search,
      view: view,
      sort: sort,
      hitsPerPage: hitsPerPage.toString(),
      group:
        group.include + "|" + group.items.map((item) => item.key).join(","),
      groupCode:
        groupCode.include +
        "|" +
        groupCode.items.map((item) => item.key).join(","),
      type: type.include + "|" + type.items.map((item) => item.key).join(","),
      page: page.toString(),
      venueId: venueId,
    };

    if (location.pathname.includes("placement")) {
      history.replace(location.pathname);
    } else {
      const params = new URLSearchParams(removeEmptyStringFromObject(states));
      history.replace(`/venueLocations?${params}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    venueId,
    view,
    dispatch,
    hitsPerPage,
    page,
    selectedGroup,
    search,
    sort,
    group,
    type,
    groupCode,
  ]);

  useEffect(() => {
    load();
  }, [load]);

  const loadDuplicates = useCallback(async () => {
    try {
      setLoadingD(true);
      const res = await duplicateList({
        venueId: venueId as string,
        search: searchD,
        page: pageD,
        records_per_page: hitsPerPageD,
      });
      setDuplicates(res.hits);
      setPageD(res.page);
      setCountD(res.count);
      setLoadingD(false);
    } catch (err) {
      setLoadingD(false);
      if (err instanceof APIError) {
        setErrorD(err.message);
      }
    }
  }, [venueId, searchD, pageD, hitsPerPageD]);

  useEffect(() => {
    loadDuplicates();
  }, [loadDuplicates]);

  const loadMissing = useCallback(async () => {
    try {
      setLoadingM(true);
      const res = await missingList({
        venueId: venueId,
        search: searchM,
        page: pageM,
        records_per_page: hitsPerPageM,
      });
      setMissing(res.hits);
      setPageM(res.page);
      setCount(res.count);

      setLoadingM(false);
    } catch (err) {
      setLoadingM(false);
      if (err instanceof APIError) {
        setErrorM(err.message);
      }
    }
  }, [venueId, searchM, pageM, hitsPerPageM]);

  useEffect(() => {
    loadMissing();
  }, [loadMissing]);

  const loadSettings = useCallback(async () => {
    dispatch(visitorList());
    dispatch(fopSetsList({}));

    try {
      setLoading("detail");
      const venue = await getVenue(venueId);
      setVenue(venue);
      setLoading("");
    } catch (err) {
      setLoading("detail");
      if (err instanceof APIError) {
        setError(err.message);
      }
    }
    try {
      setLoading("messages");

      const messages = await messageList();
      setMessages(messages);
      setLoading("");
    } catch (err) {
      setLoading("");

      if (err instanceof APIError) {
        setError(err.message);
      }
    }
  }, [dispatch, venueId]);

  useEffect(() => {
    loadSettings();
  }, [venueId, loadSettings]);

  useEffect(() => {
    document.title = "RAVENT APP :: Locations";
  });

  if (loading === "detail" && !error) {
    return <Loading />;
  }

  const onChangeLocation = <P extends keyof Location>(
    prop: P,
    value: Location[P]
  ) => {
    setLocation({ ...venueLocation, [prop]: value });
  };

  const onCreateLocation = async (
    _venueId: string,
    data: Location,
    placement: Placement
  ) => {
    try {
      setLoading("create-location");
      await createLocation(venue?.id || "", data, placement);
      setLoading("");
      message.success(t("container.venueDetail.successCreationLocation"));
      setModal("");
      setLocation(defaultLocation);
      load();
      history.push(`/venueLocations`);
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onDeleteLocation = async (id: string) => {
    try {
      setLoading("delete-location");
      await deleteLocation(id);
      message.success(t("container.venueDetail.locationDeleted"));
      setLoading("");
      load();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`${err.message}: ${err.messages}`);
      }
    }
  };

  const onEditLocation = async (data: Location, placement: Placement) => {
    try {
      setLoading("edit-location");
      await updateLocation(data.id, data, placement);
      message.success(`${t("container.venueDetail.successUpdatedLocation")}`);
      setLoading("");
      load();
    } catch (err) {
      setLoading("");
      if (err instanceof APIError) {
        message.error(`Error: ${err.messages}`);
      }
    }
  };

  const duplicateTab = {
    id: "duplicateLocations",
    label: `${t("container.venueDetail.duplicates")} (${numberFormatNoDecimals(
      countD
    )})`,
    path: `/duplicates`,
    onClick: () => (document.title = "RAVENT APP :: Locations :: Duplicates"),
  };

  const missingTab = {
    id: "missingLocations",
    label: `${t("container.venueDetail.missing")} (${numberFormatNoDecimals(
      count
    )})`,
    path: `/missing`,
    onClick: () => (document.title = "RAVENT APP :: Locations :: Missing"),
  };

  const selected: Filter[] = [];
  Object.values(stateMap).forEach(([filter]) => {
    selected.push(filter);
  });

  const handleTagClose = (facetGroup: string, option: string) => {
    const [currentState, setState] = stateMap[facetGroup];
    const itemToRemoveIndex = currentState.items.findIndex(
      (item) => item.key === option
    );
    if (itemToRemoveIndex !== -1) {
      setState({
        ...currentState,
        items: currentState.items.filter(
          (item, index) => index !== itemToRemoveIndex
        ),
      });
    }
  };

  function generateTag(label: Filter, _onClose: (label: string) => void) {
    if (!label || !label.items || label.items.length === 0) {
      return null;
    }
    return label.items.map((tagItem: { key: string }, index: number) => (
      <div
        className={styles.tag}
        key={index}
        style={{ margin: "0 0.3em 0.3em 0" }}
      >
        <div style={{ color: "#1890ff" }}>{tagItem.key.trim()}</div>
        <button
          className={styles.button}
          name="remove"
          onClick={() => handleTagClose(label.name, tagItem.key)}
        >
          <CrossIcon color={"#1890ff"} />
        </button>
      </div>
    ));
  }

  return (
    <LayouWithSideBar>
      <GridContainer columns={resize ? "0.24fr 1fr" : "3em 1fr"}>
        <div
          style={{
            backgroundColor: "#fff",
            height: "100%",
            borderLeft: "1px solid #dedede",
          }}
        >
          <SideFiltersLocations
            facets={locations.locations.facets}
            selected={selected}
            setSize={setSize}
            shrink={resize}
            onChange={(facetGroup, option) => {
              setPage(0);
              const map = stateMap;
              if (map[facetGroup]) {
                const [currentState, setState] = map[facetGroup];
                const existingIndex = currentState.items.findIndex(
                  (item) => item.key === option
                );
                let updatedItems;
                if (existingIndex !== -1) {
                  updatedItems = currentState.items.filter(
                    (item) => item.key !== option
                  );
                } else {
                  updatedItems = [
                    ...currentState.items,
                    {
                      key: option,
                      include: currentState?.items.every(
                        (item) => item.include === true
                      ),
                    },
                  ];
                }
                setState({
                  ...currentState,
                  items: updatedItems,
                });
              }
            }}
            onChangeExlusion={(facetGroup, value) => {
              const map = stateMap;
              const [currentState, setState] = map[facetGroup];
              if (currentState && currentState.items) {
                setState((prevState) => ({
                  ...prevState,
                  items: prevState.items.map((item) => ({
                    ...item,
                    include: value, // Set all items to the same value
                  })),
                }));
              }
            }}
            onChangeSelect={(facetGroup, value) => {
              const map = stateMap;
              const [currentState, setState] = map[facetGroup];
              if (currentState) {
                setState({
                  ...currentState,
                  include: value as "Any" | "All",
                });
              }
            }}
          />
        </div>
        <div>
          <CVSFUploader
            loading={loading === "multiple"}
            handleSubmit={async (file) => {
              try {
                setLoading("multiple");
                await uploadLocationFile(
                  file,
                  secureLocalStorage.getItem("venueId") as string
                );
                setLoading("");
                setModal("");
                load();
              } catch (err) {
                setLoading("");
                if (err instanceof APIError) {
                  message.error(err.message);
                }
              }
            }}
            open={open === "multiple"}
            onClose={() => setModal("")}
          />

          <DeleteLocationsModal
            loading={loading === "delete"}
            data={selectedLocations}
            open={open === "delete"}
            onClose={() => setModal("")}
            onDelete={async () => {
              try {
                setLoading("delete");
                await deleteMultipleLocations(
                  selectedLocations?.map((item) => item.id)
                );
                setLoading("");
                load();
                setModal("");
                setSelectedLocations([]);
              } catch (err) {
                setLoading("");
                if (err instanceof APIError) {
                  message.error(`${err.message}: ${err.messages}`);
                }
              }
            }}
          />

          <ExportCVSFile
            inputString={inputString}
            setExcludedIds={(values) => setProjection(values)}
            loading={loading === "export"}
            data={
              selectedLocations.length >= 1
                ? selectedLocations
                : locations.locations.hits
            }
            all={!selectedLocations.length}
            count={
              selectedLocations.length >= 1
                ? selectedLocations.length
                : locations.locations.count
            }
            open={open === "export"}
            onClose={() => setModal("")}
            onExport={async () => {
              try {
                setLoading("export");
                selectedLocations.length
                  ? await exportMultipleLocationsCVS({
                      ids: selectedLocations.map((item) => item.id),
                      all: false,
                      venue_id: venueId,
                      projection: projection,
                      venueName:
                        venues.list.data.find((item) => item.id === venueId)
                          ?.name || "",
                    })
                  : await exportMultipleLocationsCVS({
                      venue_id: venueId,
                      all: true,
                      projection: projection,
                      venueName:
                        venues.list.data.find((item) => item.id === venueId)
                          ?.name || "",
                    });
                setLoading("");
                setModal("");
                load();
              } catch (err) {
                setLoading("");
                if (err instanceof APIError) {
                  message.error(err.message);
                }
              }
            }}
          />

          <div className={styles.container}>
            <FlexContainer
              alignItems="flex-start"
              width="100%"
              padding="0em 0 1em 0"
              justifyContent="space-between"
            >
              <ContainerTitle
                size="medium"
                label={t("container.venueDetail.locations")}
              />

              <InputSelect
                containerClassname={styles.inputSelect}
                options={venues.list.data.map((item: any) => ({
                  label: item.name,
                  value: item.id,
                }))}
                value={venueId as string}
                label={t("container.fulfillment.venue")}
                showError={false}
                showLabel={false}
                onChange={(value) => {
                  setPage(0);
                  setVenueId(value);
                }}
              />
            </FlexContainer>

            <div className={styles.searchContainer}>
              {location.pathname.includes("missing") ? (
                <Search
                  style={{ marginBottom: "1em" }}
                  placeholder={t("component.orderFilters.search")}
                  onSearch={loadMissing}
                  value={searchM}
                  onChange={(text) => {
                    setPageM(0);
                    setSearchM(text.target.value);
                  }}
                />
              ) : location.pathname.includes("duplicates") ? (
                <Search
                  style={{ marginBottom: "1em" }}
                  placeholder={t("component.orderFilters.search")}
                  onSearch={loadDuplicates}
                  value={searchD}
                  onChange={(text) => {
                    setPageD(0);

                    setSearchD(text.target.value);
                  }}
                />
              ) : (
                <Search
                  style={{ marginBottom: "1em" }}
                  placeholder={t("component.orderFilters.search")}
                  onSearch={load}
                  value={search}
                  onChange={(text) => {
                    setPage(0);
                    setSearch(text.target.value);
                  }}
                />
              )}
            </div>
            <FlexContainer
              flexWrap="wrap"
              justifyContent="flex-start"
              padding="1em 0 1em 0"
            >
              {group.items.filter((item) => item.key.trim()).length > 0 &&
                generateTag(group, (label) =>
                  setGroup((prevFilter) => ({
                    ...prevFilter,
                    items: prevFilter.items.filter(
                      (item) => item.key !== label
                    ),
                  }))
                )}
              {type.items.filter((item) => item.key.trim()).length > 0 &&
                generateTag(type, (label) =>
                  setType((prevFilter) => ({
                    ...prevFilter,
                    items: prevFilter.items.filter(
                      (item) => item.key !== label
                    ),
                  }))
                )}
              {groupCode.items.filter((item) => item.key.trim()).length > 0 &&
                generateTag(groupCode, (label) =>
                  setGroupCode((prevFilter) => ({
                    ...prevFilter,
                    items: prevFilter.items.filter(
                      (item) => item.key !== label
                    ),
                  }))
                )}
            </FlexContainer>
            <TabNavBar
              options={[
                {
                  id: "venueLocations",
                  label: `${t(
                    "container.venueDetail.locations"
                  )} (${numberFormatNoDecimals(locations.locations.count)})`,
                  path: `/venueLocations`,
                  onClick: () =>
                    (document.title = "RAVENT APP :: Venue :: Locations"),
                },
                duplicateTab,
                missingTab,
              ]}
            >
              <Switch>
                <Route path={`/venueLocations`}>
                  <VenueLocations
                    selected={[]}
                    error={locations.error}
                    setDefaultValue={() => setLocation(defaultLocation)}
                    search={search}
                    setSearch={(value) => {
                      setSearch(value);
                    }}
                    open={open}
                    sort={sort}
                    onChangeSort={setSortBy}
                    onChange={() => {}}
                    view={view}
                    setView={setView}
                    selectedLocations={selectedLocations}
                    setSelectedLocations={setSelectedLocations}
                    setModal={setModal}
                    venuePlacement={venueLocation.placement}
                    venueLocation={venueLocation}
                    onChangeLocation={onChangeLocation}
                    onCreateLocation={() =>
                      onCreateLocation(
                        venue?.id || "",
                        venueLocation,
                        venueLocation.placement
                      )
                    }
                    venueId={venueId}
                    groups={
                      locations.locations.facets
                        ?.find((item) => item.field === "group")
                        ?.data?.map((item) => item.key) ?? []
                    }
                    selectedGroup={group.items
                      .map((item) => item.key)
                      .join(", ")}
                    setGroup={(value) => {
                      const params = new URLSearchParams(
                        removeEmptyStringFromObject({ group: value })
                      );
                      history.replace(`/venueLocations?${params.toString()}`);
                      setSelectedGroup(value);
                    }}
                    load={load}
                    onDeleteLocation={(id) => onDeleteLocation(id)}
                    visitors={[]}
                    messages={messages}
                    errorLocations={locations.error}
                    locationsLoading={locations.loading}
                    locations={locations.locations.hits}
                    page={locations.locations.page}
                    total={locations.locations.count}
                    hitsPerPage={hitsPerPage}
                    setPage={setPage}
                    count={locations.locations.count.toString()}
                    setHitsPerPage={setHitsPerPage}
                    onEditLocation={(data, placement) =>
                      onEditLocation(data, placement)
                    }
                  />
                </Route>
                <Route path={`/duplicates`} exact>
                  <Duplicate
                    error={errorD}
                    page={pageD}
                    pagination
                    total={countD}
                    hitsPerPage={hitsPerPageD}
                    onHitsPerPageChange={setHitsPerPageD}
                    onPageChange={setPageD}
                    sort={sort}
                    venueId={venueId}
                    load={load}
                    errorLocations={errorD}
                    locationsLoading={loadingD}
                    locations={duplicates}
                    count={countD.toString()}
                    setSearchLocation={(value) => {
                      setPage(0);
                      setView("list");
                      setSearch(value);
                    }}
                  />
                </Route>
                <Route path={`/missing`} exact>
                  <Missing
                    error={errorM}
                    onChangeLocation={onChangeLocation}
                    venueLocation={venueLocation}
                    onCreateLocation={() =>
                      onCreateLocation(
                        venue?.id || "",
                        venueLocation,
                        venueLocation.placement
                      )
                    }
                    groups={
                      locations.locations.facets
                        ?.find((item) => item.field === "group")
                        ?.data?.map((item) => item.key) ?? []
                    }
                    page={pageM}
                    total={count}
                    hitsPerPage={hitsPerPageM}
                    setHitsPerPage={setHitsPerPageM}
                    setPage={setPageM}
                    venuePlacement={venueLocation.placement}
                    sort={sort}
                    venueId={venueId}
                    load={loadMissing}
                    locationsLoading={loadingM}
                    locations={missingL}
                    count={missingL.length.toString()}
                  />
                </Route>
              </Switch>
            </TabNavBar>
          </div>
        </div>
      </GridContainer>
    </LayouWithSideBar>
  );
};

export default Locations;
