import get from "lodash/get";
import APIError from "../utils/APIError";
import CustomError from "../utils/CustomError";
import fetch from "../utils/fetch";
import { Profile } from "../utils/types";
import { getAccessToken, getAuthData } from "./auth";

export function parseProfile(data: unknown): Profile {
  return {
    id: get(data, "_id", ""),
    venueId: get(data, "venue_id", ""),
    venueName: get(data, "venueName", ""),
    fullName: get(data, "fullName", ""),
    invoiceIssuanceType: get(data, "invoiceIssuanceType", ""),
    firstName: get(data, "firstName", ""),
    lastName: get(data, "lastName", ""),
    userName: get(data, "userName", ""),
    phone: get(data, "phone", ""),
    email: get(data, "email", ""),
    organizationId: get(data, "organization_id", ""),
    roles: get(data, "roles", []),
    orgName: get(data, "organizationName", ""),
    type: get(data, "type", ""),
    adminRole: get(data, "admin", false),
  };
}

export async function profileSetPass(
  oldPassword: string,
  password: string,
  confirmPassword: string
) {
  const token = await getAccessToken();
  const error = new CustomError("Error changing password", "formError");
  const validations = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_])\S{8,20}$/;
  try {
    if (!password.match(validations)) {
      error.setError("password", "Invalid password length or format");
    }
    if (password !== confirmPassword) {
      error.setError("notSame", "Passwords do not match");
    }
    await fetch("/users/v1/changePass", token, "POST", {
      old_password: oldPassword,
      new_password: password,
    });
  } catch (err) {
    if (err instanceof APIError) {
      if (
        err.message ===
        "Your email or password was incorrect. Please try again."
      ) {
        error.setError("oldPasswordValid", "Wrong password");
      }
    }

    throw error;
  }
}

export async function getProfile(): Promise<Profile> {
  try {
    const data = await getAuthData();

    const res = await fetch(`/users/profile`, data.accessToken);

    const parseData = parseProfile(res.body);
    return parseData;
  } catch (err) {
    console.debug("getProfileError", err);
    throw err;
  }
}

export async function sendResetPasswordMail(username: string): Promise<void> {
  await fetch("/users/v1/requestResetPass", null, "POST", { email: username });
}
