import { stylesheet } from "typestyle";

const styles = stylesheet({
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
  modalContainer: {
    padding: "1em",
  },
  selectContainer: {
    width: "50%",
  },
  select: { minWidth: "15em" },
  notEnrollContainer: {
    background: "rgba(250, 173, 21, 0.1)",
    border: "1px solid #FAAD15",
    padding: "1.1em",
    borderRadius: "6px",
  },
  box: {
    minHeight: "2.5em",
    padding: "0.5em",
    borderRadius: 2,
    border: "1px solid #d9d9d9",
    display: "flex",
    flexWrap: "wrap",
  },
  circle: {
    width: "20px",
    height: "20px",
    borderRadius: 20,
    border: "1px solid #333",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    width: "30px",
    cursor: "pointer",
  },
  container: {
    $nest: {
      "div > div": {
        alignItems: "center !important",
      },
    },
  },
  scroll: {
    height: "65vh",
    overflowY: "scroll",
  },
});

export default styles;
