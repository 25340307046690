import React, { useState } from "react";

import {
  OfferFrequency,
  WeeklySchedule,
} from "@innomius/ravent-typescript-types";
import styles from "./styles";
import WeeklyCalendar from "../WeeklyCalendar";
import WeeklyHourForm from "../WeeklyCalendar/WeeklyHourForm";
import { useTranslation } from "react-i18next";
import InputDate from "../Inputs/Date";
import { ContainerTitle } from "../ContainerTitle";
import { InputText } from "../Inputs/InputText";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { InputCheckbox } from "../Inputs/InputCheckbox";
import { ButtonsContainer } from "../ButtonsContainer";
import { Button } from "../Button";

interface Props {
  edit?: boolean;
  onCancel: () => void;
  onSave: () => void;
  data: OfferFrequency;
  onChange: <P extends keyof OfferFrequency>(
    prop: P,
    value: OfferFrequency[P]
  ) => void;
}

const OfferFrequencyListForm: React.FC<Props> = ({
  edit,

  data,
  onCancel,
  onChange,
  onSave,
}) => {
  const { t } = useTranslation();

  const [schedule, setSchedule] = useState<WeeklySchedule[]>(
    data.weeklySchedule || []
  );
  const [editFreq, setEdit] = useState<WeeklySchedule | null>();

  const addFrequencyToCalendar = (frequencies: WeeklySchedule[]) => {
    const schedules = [...frequencies, ...schedule];
    const unique: WeeklySchedule[] = [];
    schedules.map((x) =>
      unique.filter((a) => a.hour === x.hour && a.weekday === x.weekday)
        .length > 0
        ? null
        : unique.push(x)
    );
    const sorted = unique.sort((a, b) => {
      return (
        new Date(`Mar 12 2012 ${a.hour}`).getTime() -
        new Date(`Mar 12 2012 ${b.hour}`).getTime()
      );
    });

    setSchedule(sorted);
  };

  return (
    <div>
      <div className={styles.containerDetails}>
        <ContainerTitle
          padding="0 0 1em 0"
          size="xsmall"
          label={
            !edit
              ? t("component.offerVariantForm.addFrequencyList")
              : t("component.offerVariantForm.editFrequencyList")
          }
        />
        <InputText
          label={t("component.offerVariantForm.listName")}
          value={data.name}
          onChange={(value) => onChange("name", value)}
        />
        <FlexContainer
          padding="0 0 1em 0"
          alignItems="center"
          justifyContent="flex-start"
        >
          <InputDate
            id="startDateFrequency"
            label={t("component.offerVariantForm.startDate")}
            onChange={(value: Date) => onChange("startDate", value)}
            dateClassname={styles.startDateContainer}
          />
          <p className={styles.checkboxContainer}>
            {t("component.offerVariantForm.to")}
          </p>
          <InputDate
            id="endDateFrequency"
            label={t("component.offerVariantForm.endDate")}
            onChange={(value: Date) => onChange("startDate", value)}
            dateClassname={styles.startDateContainer}
          />
          <InputCheckbox
            containerClassname={styles.checkboxContainer}
            text={t("component.offerVariantForm.yearLong")}
          />
        </FlexContainer>
        <ContainerTitle
          padding="1em 0 0 0"
          size="xsmall"
          label={t("component.offerVariantForm.schedule")}
        />
        <WeeklyHourForm
          onAddHour={(frecuencies) => addFrequencyToCalendar(frecuencies)}
          onCancel={() => setEdit(null)}
          onDelete={() => {}}
          onUpdate={(hour: string) => {
            //id that has to be updated
            const updatedData = schedule
              .map((item) =>
                item === editFreq ? { ...item, hour: hour } : item
              )
              .sort((a, b) => {
                return (
                  new Date(`Mar 12 2012 ${a.hour}`).getTime() -
                  new Date(`Mar 12 2012 ${b.hour}`).getTime()
                );
              });
            setSchedule(updatedData);
            setEdit(null);
          }}
          editItem={editFreq}
        />
        <WeeklyCalendar
          frecuencies={schedule}
          onEditHour={(wSchdule: WeeklySchedule) => setEdit(wSchdule)}
        />
        <ButtonsContainer padding="2em 0 0 0" justifyContent="flex-start">
          <Button
            theme="white"
            label={t("component.offerVariantForm.cancel")}
            onClick={onCancel}
          />
          <Button
            onClick={() => {
              onChange("weeklySchedule", schedule);
              onSave();
            }}
            theme="blue"
            label={t("component.offerVariantForm.save")}
          />
        </ButtonsContainer>
      </div>
    </div>
  );
};

export default OfferFrequencyListForm;
