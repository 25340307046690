import React, { useState, useEffect } from "react";
import { GoogleMap, useJsApiLoader, InfoWindow } from "@react-google-maps/api";
import Text from "../Text";
import { formatDate } from "../../utils/times";
import CustomMarkerOverlay from "./CustomerMarker";
import styles from "./styles";
import late from "../../assets/late.svg";
import ontime from "../../assets/ontime.svg";
import IconAsset from "../AssetList/IconAsset";
import { EventItemMap } from "../../services/assets";
import { ReactComponent as FulfillerIcon } from "../../assets/fulfiller.svg";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { GridContainer } from "../GridContainer/GridContainer";

interface MapMarkersAssetProps {
  markers: MarkerData[];
}

interface MarkerData {
  lat: number;
  lng: number;
  id: string;
  name: string;
  externalId: string;
  type: string;
  eventData: EventItemMap | {};
  responseType: string;
}

const MapMarkersAsset: React.FC<MapMarkersAssetProps> = ({ markers }) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [selectedMarker, setSelectedMarker] = useState<MarkerData | null>(null);

  const containerStyle = {
    width: "100%",
    height: "350px",
  };

  const defaultCenter = { lat: 21.0417033, lng: -86.8740062 };

  const onLoad = (mapInstance: google.maps.Map) => {
    setMap(mapInstance);
  };

  useEffect(() => {
    if (map && markers.length > 0) {
      const bounds = new google.maps.LatLngBounds();

      markers.forEach((marker) => {
        bounds.extend(new google.maps.LatLng(marker.lat, marker.lng));
      });

      map.fitBounds(bounds);
    }
  }, [map, markers, selectedMarker]);

  const handleMarkerClick = (marker: MarkerData) => {
    setSelectedMarker(marker);

    if (map) {
      map.panTo({ lat: marker.lat, lng: marker.lng });
      map.setZoom(15);
    }
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBnUmMfyn4yxmKUBh-xP8U9k7GYmMp-oyo",
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={defaultCenter}
      zoom={10} // Initial zoom level
      onLoad={onLoad}
    >
      {markers.map((marker, index) => (
        <CustomMarkerOverlay
          key={index}
          type={marker.responseType}
          onTime={
            marker.eventData && "onTime" in marker.eventData
              ? marker.eventData.onTime
              : false
          }
          position={{ lat: marker.lat, lng: marker.lng }}
          onClick={() => handleMarkerClick(marker)}
          externalId={marker.externalId}
        />
      ))}
      {selectedMarker && (
        <InfoWindow
          onCloseClick={() => setSelectedMarker(null)}
          position={{
            lat: selectedMarker.lat + 0.001,
            lng: selectedMarker.lng + 0.001,
          }}
        >
          <div style={{ minWidth: "20em" }}>
            <GridContainer gap="1em" columns="0.1fr 1fr">
              <div className={styles.blueBlackground}>
                <IconAsset type={selectedMarker.type} />
              </div>
              <FlexContainer
                justifyContent="flex-start"
                alignItems="flex-start"
                flexDirection="column"
              >
                <Text
                  fontSize="1.4em"
                  text={selectedMarker.name}
                  padding="0 0 0.2em 0"
                  fontWeight={700}
                />
                <FlexContainer justifyContent="flex-start">
                  <Text
                    fontSize="1em"
                    text={`Ext.ID: ${selectedMarker.externalId}`}
                    fontWeight={400}
                    padding="0 0.5em 0 0"
                  />
                  {selectedMarker.responseType === "running" ? (
                    <div
                      style={{
                        backgroundColor:
                          selectedMarker.eventData &&
                          "onTime" in selectedMarker.eventData
                            ? selectedMarker.eventData.onTime
                              ? "#EAF8DF"
                              : "#FFD0D0"
                            : "",
                        borderRadius: 4,
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        padding: "0.2em",
                      }}
                    >
                      <img
                        src={
                          selectedMarker.eventData &&
                          "onTime" in selectedMarker.eventData
                            ? selectedMarker.eventData.onTime
                              ? ontime
                              : late
                            : ""
                        }
                        alt=""
                      />
                      <Text
                        padding="0 0 0 0.5em"
                        fontSize="1em"
                        text={
                          selectedMarker.eventData &&
                          "onTime" in selectedMarker.eventData
                            ? selectedMarker.eventData.onTime
                              ? "On Time"
                              : "Late"
                            : ""
                        }
                        fontWeight={400}
                      />
                    </div>
                  ) : null}
                </FlexContainer>
              </FlexContainer>
            </GridContainer>

            {selectedMarker.responseType === "running" ? (
              <>
                <div
                  style={{
                    borderBottom: "1px solid #dedede",
                    padding: "1em 0 0.5em 0",
                    marginBottom: "1em",
                  }}
                />
                <Text
                  fontSize="1.4em"
                  text={
                    selectedMarker.eventData &&
                    "datetime" in selectedMarker.eventData
                      ? formatDate(selectedMarker.eventData.datetime, "HOUR")
                      : ""
                  }
                  fontWeight={600}
                  color={
                    selectedMarker.eventData &&
                    "onTime" in selectedMarker.eventData &&
                    selectedMarker.eventData.onTime
                      ? "#10A841"
                      : "#FF2929"
                  }
                />
                <FlexContainer justifyContent="flex-start">
                  <Text
                    fontSize="1.2em"
                    text={`Order ${
                      selectedMarker.eventData &&
                      "orderShortId" in selectedMarker.eventData
                        ? selectedMarker.eventData.orderShortId
                        : ""
                    }`}
                    padding="0 0.5em 0 0"
                    fontWeight={600}
                    color={"#333333"}
                  />
                  <Text
                    fontSize="1.1em"
                    text={`• ${
                      selectedMarker.eventData &&
                      "orderExternalId" in selectedMarker.eventData
                        ? selectedMarker.eventData.orderExternalId
                        : ""
                    }`}
                    padding="0 0.5em 0 0"
                    fontWeight={400}
                    color={"#858D8F"}
                  />
                </FlexContainer>

                <FlexContainer justifyContent="flex-start">
                  <FulfillerIcon className={styles.icon} />
                  <Text
                    fontSize="1.2em"
                    text={`${
                      selectedMarker.eventData &&
                      "fulfillerTeamName" in selectedMarker.eventData
                        ? selectedMarker.eventData.fulfillerTeamName
                        : ""
                    }:`}
                    padding="0 0.5em 0 0"
                    fontWeight={600}
                    color={"#333333"}
                  />
                  <Text
                    fontSize="1.2em"
                    text={`${
                      selectedMarker.eventData &&
                      "fulfillerName" in selectedMarker.eventData
                        ? selectedMarker.eventData.fulfillerName
                        : ""
                    }`}
                    padding="0 0.5em 0 0"
                    fontWeight={600}
                    color={"#333333"}
                  />
                </FlexContainer>
              </>
            ) : null}
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default MapMarkersAsset;
