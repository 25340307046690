import React, { createRef } from "react";
import throttle from "lodash/throttle";
import isEqual from "lodash/isEqual";
import style from "./styles.module.css";

import {
  ThreeWordAddress,
  w3wCoordinatesTo3wa,
  w3wGetGridSection,
  Location,
} from "../../services/maps";

interface Grid {
  icon: any;
  text: string;
}
interface Props {
  onChange: (w3a: ThreeWordAddress) => void;
  value: Location;
  showGrid?: Grid;
  type?: string;
  setZoom?: boolean;
  height?: string;
}
interface State {
  grid: [];
}

export default class MapInput extends React.Component<Props, State> {
  map: any;
  marker: any;
  getGrid: () => void = () => null;
  mapDiv: React.RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);
    this.state = {
      grid: [],
    };

    this.mapDiv = createRef<HTMLDivElement>();
  }

  async componentDidMount() {
    const google: any = window.google;
    this.map = new google.maps.Map(this.mapDiv.current, {
      center: this.props.value,
      zoom: this.props.type === "w3w" ? 20 : 15,
    });
    this.marker = new google.maps.Marker({
      position: this.props.value,
      map: this.map,
      title: "Tu Ubicación",
    });

    this.map.addListener("click", this.onChangeMarker);

    this.getGrid = throttle(this.drawGrid, 250);
    await this.map.addListener("bounds_changed", this.getGrid);
  }
  componentDidUpdate(prevProps: Props) {
    if (this.props.setZoom) {
      this.map.setZoom(40);
    }
    if (!isEqual(this.props.value, prevProps.value)) {
      this.map.panTo(this.props.value);
      this.marker.setPosition(this.props.value);
    }
  }
  onChangeMarker = async (event: any) => {
    const location = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    const address = await w3wCoordinatesTo3wa(location);
    this.props.onChange(address);
  };
  drawGrid = async () => {
    const zoom = this.map.getZoom();
    const loadFeatures = zoom > 19;

    if (loadFeatures) {
      // Zoom level is high enough
      const bounds = this.map.getBounds();
      if (!bounds) {
        return null;
      }
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();

      // Call the what3words Grid API to obtain the grid squares within the current visble bounding box
      const grid = await w3wGetGridSection(
        sw.lat(),
        sw.lng(),
        ne.lat(),
        ne.lng(),
        "geojson"
      );

      this.state.grid &&
        this.state.grid.forEach((d) => this.map.data.remove(d));

      this.setState({ grid: this.map.data.addGeoJson(grid) });
    }

    // Set the grid display style
    this.map.data.setStyle({
      visible: loadFeatures,
      strokeColor: "#777",
      strokeWeight: 0.5,
      clickable: false,
    });
  };
  render() {
    return (
      <>
        {this.props.showGrid ? (
          <div className={style.wrapper}>
            <div className={style.mapGrid}>{this.props.showGrid?.icon}</div>
            <div
              className={style.mapGrid}
              onClick={() => {
                this.map.setZoom(20);
              }}
            >
              {this.props.showGrid?.text}
            </div>
          </div>
        ) : null}
        <div className={style.geoInput}>
          <div
            style={{ height: this.props.height }}
            className={style.mapContainer}
            ref={this.mapDiv}
          >
            MAP
          </div>
        </div>
      </>
    );
  }
}
