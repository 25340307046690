import React from "react";
import IntegrationItem from "./IntegrationItem";
import { UserListSkeleton } from "../ListSkeletons";
import { Integration } from "../../utils/types";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { PaginationRavent } from "../Pagination/Pagination";
import { ListHeader } from "../List/ListHeader";
import { ListHeaderLabel } from "../List/ListHeaderLabel";
import { List } from "../List";

interface Props {
  data: Integration[];
  sortable: boolean;
  error: string;
  loading: boolean;
  loadingForm: boolean;
  onUpdate: (data: Integration) => void;
  onRemove: (id: string) => void;
  page: number;
  total: number;
  hitsPerPage: number;
  onPageChange?: (page: number) => void;
  onHitsPerPageChange?: (hitsPerPage: number) => void;
  pagination?: boolean;
  onOpen: (id: string) => void;
}

const IntegrationList: React.FC<Props> = ({
  data,
  error,
  loading,
  loadingForm,
  onUpdate,
  onRemove,
  pagination,
  onHitsPerPageChange,
  onPageChange,
  hitsPerPage,
  page,
  total,
  onOpen,
}) => {
  const { t } = useTranslation();

  const header = (
    <div>
      <ListHeader template="1fr 1fr 1fr 1.5fr 0.8fr 0.5fr">
        <ListHeaderLabel
          value={t("component.integrationList.supplierType")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.integrationList.supplierName")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.integrationList.intType")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.integrationList.intName")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel
          value={t("component.integrationList.enabled")}
          justifyContent="flex-start"
        />
        <ListHeaderLabel value="" justifyContent="center" />
      </ListHeader>
    </div>
  );

  return (
    <div>
      <List
        error={error}
        loading={loading}
        loadingElement={() => <UserListSkeleton />}
        data={data}
        empty={!data.length}
        keyExtractor={(item) => item.id}
        renderHeader={() => header}
        renderItem={(item) => (
          <IntegrationItem
            onRemove={(id) => onRemove(id)}
            onUpdate={(data) => onUpdate(data)}
            active={item.active}
            loading={loadingForm}
            key={item.id}
            id={item.id}
            supplierType={item.supplierType}
            supplierReference={item.supplierReference}
            supplierName={item.supplierName}
            name={item.name}
            type={item.type}
            onOpen={(id) => onOpen(id)}
          />
        )}
      />
      {pagination ? (
        <div className={styles.paginationContainer}>
          <PaginationRavent
            current={page + 1}
            defaultCurrent={page + 1}
            total={total}
            showSizeChanger
            onShowSizeChange={(page, size) => {
              if (onHitsPerPageChange) {
                onHitsPerPageChange(size);
              }
            }}
            onChange={(page) => {
              if (onPageChange) {
                onPageChange(page - 1);
              }
            }}
            pageSize={hitsPerPage}
          />
        </div>
      ) : null}
    </div>
  );
};

export default IntegrationList;
